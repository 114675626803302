import { useEffect } from 'react'

import { $generateNodesFromSerializedNodes, $insertGeneratedNodes } from '@lexical/clipboard'
import { $generateNodesFromDOM } from '@lexical/html'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import {
  $getSelection,
  $isRangeSelection,
  CommandPayloadType,
  COMMAND_PRIORITY_CRITICAL,
  LexicalEditor,
  PASTE_COMMAND,
  RangeSelection,
} from 'lexical'

import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'

const numberFormat = new Intl.NumberFormat('en-US')

function getContentsLength(contents: string, asHtml: boolean) {
  if (asHtml) {
    const parser = new DOMParser()
    const dom = parser.parseFromString(contents, 'text/html')
    return dom.body.textContent?.length ?? 0
  }

  return contents.length
}

// From lexical source, modified to paste tables from Excel / Google Sheets
// with tabs between the cell values
function $insertDataTransferForRichText(
  dataTransfer: DataTransfer,
  selection: RangeSelection,
  editor: LexicalEditor
): void {
  const lexicalString = dataTransfer.getData('application/x-lexical-editor')

  if (lexicalString) {
    try {
      const payload = JSON.parse(lexicalString)
      if (payload.namespace === editor._config.namespace && Array.isArray(payload.nodes)) {
        const nodes = $generateNodesFromSerializedNodes(payload.nodes)
        return $insertGeneratedNodes(editor, nodes, selection)
      }
      // eslint-disable-next-line no-empty
    } catch {}
  }

  const htmlString = dataTransfer.getData('text/html')

  if (htmlString) {
    try {
      const parser = new DOMParser()
      const dom = parser.parseFromString(htmlString, 'text/html')

      for (const table of dom.querySelectorAll('table')) {
        const pre = dom.createElement('pre')
        pre.innerText = Array.from(table.querySelectorAll('tr'))
          .map((tr) => {
            return Array.from(tr.querySelectorAll('td,th'))
              .map((td) => td.textContent)
              .join('\t')
          })
          .join('\n')
        table.replaceWith(pre)
      }

      const nodes = $generateNodesFromDOM(editor, dom)
      return $insertGeneratedNodes(editor, nodes, selection)
      // eslint-disable-next-line no-empty
    } catch {}
  }

  // Multi-line plain text in rich text mode pasted as separate paragraphs
  // instead of single paragraph with linebreaks.
  const text = dataTransfer.getData('text/plain')

  if (text != null) {
    const lines = text.split(/\r?\n/)
    const linesLength = lines.length

    for (let i = 0; i < linesLength; i += 1) {
      selection.insertText(lines[i])
      if (i < linesLength - 1) {
        selection.insertParagraph()
      }
    }
  }

  return undefined
}

function onPasteForRichText(event: CommandPayloadType<typeof PASTE_COMMAND>, editor: LexicalEditor): void {
  event.preventDefault()
  editor.update(
    () => {
      const selection = $getSelection()
      const clipboardData = event instanceof InputEvent || event instanceof KeyboardEvent ? null : event.clipboardData
      if (clipboardData != null && $isRangeSelection(selection)) {
        $insertDataTransferForRichText(clipboardData, selection, editor)
      }
    },
    {
      tag: 'paste',
    }
  )
}

export default function MaxPasteLengthPlugin({ maxLength }: { maxLength: number }): null {
  const [editor] = useLexicalComposerContext()
  const dispatch = useDispatch()

  useEffect(() => {
    return editor.registerCommand(
      PASTE_COMMAND,
      (e: ClipboardEvent) => {
        const hasHtml = e.clipboardData?.types.includes('text/html') ?? false
        const data = (e.clipboardData?.getData('text/html') || e.clipboardData?.getData('text/plain')) ?? ''

        if (getContentsLength(data, hasHtml) > maxLength) {
          e.preventDefault()

          dispatch(
            setFlashError(
              `Paste content is too long! You pasted ${numberFormat.format(
                data?.length ?? 0
              )} characters, but the maximum is ${numberFormat.format(maxLength)} characters.`
            )
          )

          return true
        }

        onPasteForRichText(e, editor)

        return true
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [dispatch, editor, maxLength])

  return null
}

import { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { useDispatch } from 'actions/store'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import { BusinessBasicInfo } from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ComparisonStep/Businesses'
import {
  Disclaimer,
  useCompareStepStyles,
} from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ComparisonStep/ComparisonStepDialog'
import {
  FinancialInstitutionBasicInfo,
  LinkedFinancialInstitutionBanner,
} from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ComparisonStep/FinancialInstitutions'
import { PersonBasicInfo } from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ComparisonStep/People'
import {
  CompareTableLayout,
  DetailsSection,
  SharedEntityHeader,
  SimpleDetail,
} from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ComparisonStep/shared'
import { ConfirmStepDialog } from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/ConfirmStepDialog'
import { InitialStepDialog } from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/InitialStepDialog'
import {
  MERGE_BUSINESS_FRAGMENT,
  MERGE_FINANCIAL_INSTITUTION_FRAGMENT,
  MERGE_PERSON_FRAGMENT,
} from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/MergeSubjectsDialog'

import {
  ContentComponentsMap,
  MergeBusinessEntity,
  MergeEntity,
  MergeFinancialInstitutionEntity,
  MergePersonEntity,
  MergeStep,
  SharedStepProps,
} from 'components/cases/Tabs/Overview/Subjects/MergeSubjects/types'
import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { ManagedObjectIcon } from 'components/library/ManagedObjectIcon'
import { useProfileMergingContext } from 'dashboards/profiles/ProfileMergingContext'
import { useIsLinkedFi } from 'dashboards/profiles/hooks/useIsLinkedFi'
import {
  BatchActionContext,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { usePrevious } from 'hooks'
import { CalendarIcon } from 'icons/CalendarIcon'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { BatchActionTypeEnum, LibraryTypeEnum } from 'types/api'

import { GetProfilesQuery, GetProfilesQueryVariables } from './__generated__/BatchMergeDialog.generated'

type BatchMergeComponent = DialogComponent<
  { libraryEntityType: LibraryGqlQueryType; libraryEntityToken: string },
  'profile'
>

type SetSelectedPrimaryEntityToken = (selectedPrimaryEntityToken: string | null) => void

const useMergeDialogSteps = () => {
  const [mergeStep, setMergeStep] = useState<MergeStep>('initial')
  return { mergeStep, setMergeStep }
}

interface PeopleCompareTableProps {
  selectedEntities: MergePersonEntity[]
  selectedPrimaryEntityToken: string | null
  setSelectedPrimaryEntityToken: SetSelectedPrimaryEntityToken
}

const PeopleCompareTable = ({
  selectedEntities,
  selectedPrimaryEntityToken,
  setSelectedPrimaryEntityToken,
}: PeopleCompareTableProps) => {
  const rows = ['header', 'basicInfo', 'createdAt', 'updatedAt'] as const
  const contentComponents = useMemo<ContentComponentsMap<typeof rows, MergePersonEntity>>(
    () => ({
      header: ({ datum }) => (
        <SharedEntityHeader
          isSelected={datum.token === selectedPrimaryEntityToken}
          setSelected={() => setSelectedPrimaryEntityToken(datum.token)}
          entity={datum}
        />
      ),
      basicInfo: ({ datum }) => <PersonBasicInfo entity={datum} />,
      createdAt: ({ datum }) => (
        <DetailsSection heading="Created At">
          <SimpleDetail Icon={CalendarIcon} value={datum.createdAt} />
        </DetailsSection>
      ),
      updatedAt: ({ datum }) => (
        <DetailsSection heading="Updated At">
          <SimpleDetail Icon={CalendarIcon} value={datum.updatedAt} />
        </DetailsSection>
      ),
    }),
    [selectedPrimaryEntityToken, setSelectedPrimaryEntityToken]
  )

  return (
    <CompareTableLayout
      contentComponents={contentComponents}
      data={selectedEntities}
      rows={rows}
      selectedIndex={selectedEntities.findIndex((e) => e.token === selectedPrimaryEntityToken)}
    />
  )
}

interface BusinessesCompareTableProps {
  selectedEntities: MergeBusinessEntity[]
  selectedPrimaryEntityToken: string | null
  setSelectedPrimaryEntityToken: SetSelectedPrimaryEntityToken
}

const BusinessesCompareTable = ({
  selectedEntities,
  selectedPrimaryEntityToken,
  setSelectedPrimaryEntityToken,
}: BusinessesCompareTableProps) => {
  const rows = ['header', 'basicInfo', 'createdAt', 'updatedAt']

  const [minLegalNamesHeight, setMinLegalNamesHeight] = useState(0)
  const [minDBANamesHeight, setMinDBANamesHeight] = useState(0)

  const contentComponents = useMemo<ContentComponentsMap<typeof rows, MergeBusinessEntity>>(
    () => ({
      header: ({ datum }) => (
        <SharedEntityHeader
          isSelected={datum.token === selectedPrimaryEntityToken}
          setSelected={() => setSelectedPrimaryEntityToken(datum.token)}
          entity={datum}
        />
      ),
      basicInfo: ({ datum }) => (
        <BusinessBasicInfo
          entity={datum}
          minLegalNamesHeight={minLegalNamesHeight}
          setMinLegalNamesHeight={setMinLegalNamesHeight}
          minDBANamesHeight={minDBANamesHeight}
          setMinDBANamesHeight={setMinDBANamesHeight}
        />
      ),
      createdAt: ({ datum }) => (
        <DetailsSection heading="Created At">
          <SimpleDetail Icon={CalendarIcon} value={datum.createdAt} />
        </DetailsSection>
      ),
      updatedAt: ({ datum }) => (
        <DetailsSection heading="Updated At">
          <SimpleDetail Icon={CalendarIcon} value={datum.updatedAt} />
        </DetailsSection>
      ),
    }),
    [minDBANamesHeight, minLegalNamesHeight, selectedPrimaryEntityToken, setSelectedPrimaryEntityToken]
  )

  return (
    <CompareTableLayout
      contentComponents={contentComponents}
      data={selectedEntities}
      rows={rows}
      selectedIndex={selectedEntities.findIndex((e) => e.token === selectedPrimaryEntityToken)}
    />
  )
}

interface FinancialInstitutionsCompareTableProps {
  selectedEntities: MergeFinancialInstitutionEntity[]
  selectedPrimaryEntityToken: string | null
  setSelectedPrimaryEntityToken: (selectedPrimaryEntityToken: string | null) => void
}

const FinancialInstitutionsCompareTable = ({
  selectedEntities,
  selectedPrimaryEntityToken,
  setSelectedPrimaryEntityToken,
}: FinancialInstitutionsCompareTableProps) => {
  const isLinkedFi = useIsLinkedFi()
  const linkedInstitutionToken = selectedEntities.find((e) => isLinkedFi(e.token))?.token

  const rows = useMemo(() => {
    if (!linkedInstitutionToken) return ['header', 'basicInfo', 'createdAt', 'updatedAt'] as const
    // only show the banner row if one of the selected entities is a linked fi
    return ['isLinked', 'header', 'basicInfo', 'createdAt', 'updatedAt'] as const
  }, [linkedInstitutionToken])

  const contentComponents = useMemo<ContentComponentsMap<typeof rows, MergeFinancialInstitutionEntity>>(
    () => ({
      isLinked: ({ datum }) => {
        if (!linkedInstitutionToken) return null
        const isInstitutionLinked = isLinkedFi(datum.token)
        if (!isInstitutionLinked) return null
        return <LinkedFinancialInstitutionBanner />
      },
      header: ({ datum }) => {
        const isLinkedInstitution = linkedInstitutionToken === datum.token
        const isLinkedInstitutionSelected = !!linkedInstitutionToken
        const canBePrimary = !isLinkedInstitutionSelected || isLinkedInstitution
        return (
          <SharedEntityHeader
            adornment={
              isLinkedInstitution ? (
                <ManagedObjectIcon
                  managedObjectSubtitle={datum.displayName}
                  managedObjectTitle="Filing Institution"
                  tooltipNoticeText="The filing institution must be the primary profile."
                />
              ) : null
            }
            disabled={!canBePrimary}
            isSelected={datum.token === selectedPrimaryEntityToken}
            setSelected={() => setSelectedPrimaryEntityToken(datum.token)}
            entity={datum}
          />
        )
      },
      basicInfo: ({ datum }) => <FinancialInstitutionBasicInfo entity={datum} />,
      createdAt: ({ datum }) => (
        <DetailsSection heading="Created At">
          <SimpleDetail Icon={CalendarIcon} value={datum.createdAt} />
        </DetailsSection>
      ),
      updatedAt: ({ datum }) => (
        <DetailsSection heading="Updated At">
          <SimpleDetail Icon={CalendarIcon} value={datum.updatedAt} />
        </DetailsSection>
      ),
    }),
    [isLinkedFi, linkedInstitutionToken, selectedPrimaryEntityToken, setSelectedPrimaryEntityToken]
  )

  return (
    <CompareTableLayout
      contentComponents={contentComponents}
      data={selectedEntities}
      rows={rows}
      selectedIndex={selectedEntities.findIndex((e) => e.token === selectedPrimaryEntityToken)}
    />
  )
}

interface CompareTableProps {
  libraryType: LibraryTypeEnum
  selectedEntities: MergeEntity[]
  selectedPrimaryEntityToken: string | null
  setSelectedPrimaryEntityToken: (selectedPrimaryEntityToken: string | null) => void
}

const CompareTable = ({
  libraryType,
  selectedEntities,
  setSelectedPrimaryEntityToken,
  selectedPrimaryEntityToken,
}: CompareTableProps) => {
  const sharedProps = {
    setSelectedPrimaryEntityToken,
    selectedPrimaryEntityToken,
  }
  switch (libraryType) {
    case LibraryTypeEnum.People: {
      return <PeopleCompareTable selectedEntities={selectedEntities as MergePersonEntity[]} {...sharedProps} />
    }
    case LibraryTypeEnum.Businesses: {
      return <BusinessesCompareTable selectedEntities={selectedEntities as MergeBusinessEntity[]} {...sharedProps} />
    }
    case LibraryTypeEnum.FinancialInstitutions: {
      return (
        <FinancialInstitutionsCompareTable
          selectedEntities={selectedEntities as MergeFinancialInstitutionEntity[]}
          {...sharedProps}
        />
      )
    }
    default:
      return null
  }
}

interface CompareStepDialogProps extends SharedStepProps {
  libraryType: LibraryTypeEnum
  selectedEntities: MergeEntity[]
  selectedPrimaryEntityToken: string | null
  setSelectedPrimaryEntityToken: (primary: string) => void
}

export const CompareStepDialog = ({
  libraryType,
  open,
  onClose,
  selectedEntities,
  selectedPrimaryEntityToken,
  setSelectedPrimaryEntityToken,
  setMergeStep,
}: CompareStepDialogProps) => {
  const classes = useCompareStepStyles()
  return (
    <HbDialog
      id="subjectMergeCompareDialog"
      classes={{ content: classes.content, header: classes.header }}
      confirmText="Continue"
      content={
        <>
          <HbText className={classes.subheading} bold tag="h3" size="md">
            Choose a primary record:
          </HbText>
          <CompareTable
            libraryType={libraryType}
            selectedEntities={selectedEntities}
            selectedPrimaryEntityToken={selectedPrimaryEntityToken}
            setSelectedPrimaryEntityToken={setSelectedPrimaryEntityToken}
          />
          <Disclaimer />
        </>
      }
      open={open}
      onClose={onClose}
      title="Merge profiles"
      onConfirm={() => {
        setMergeStep('confirm')
      }}
      size="lg"
    />
  )
}

const GET_PROFILES_QUERY = gql`
  query GetProfiles($libraryEntityType: LibraryTypeEnum!, $tokens: [String!]!) {
    libraryByTokens(tokens: $tokens, type: $libraryEntityType) {
      ... on LibraryObject {
        ...MergePerson
        ...MergeBusiness
        ...MergeFinancialInstitution
      }
    }
  }

  ${MERGE_PERSON_FRAGMENT}
  ${MERGE_BUSINESS_FRAGMENT}
  ${MERGE_FINANCIAL_INSTITUTION_FRAGMENT}
`

export const BatchMergeDialog: BatchMergeComponent = ({ libraryEntityType, onClose, open }) => {
  const dispatch = useDispatch()
  const { mutate, bidSelector, loading: mergeLoading } = useContext(BatchActionContext)

  const {
    canMergeSelectedItems,
    selectedEntities,
    selectedEntityTokens,
    selectedLibraryType,
    selectedPrimaryEntityToken,
    setSelectedPrimaryEntityToken,
  } = useProfileMergingContext()

  const { mergeStep, setMergeStep } = useMergeDialogSteps()

  const [fetchProfiles, { data, loading }] = useLazyQuery<GetProfilesQuery, GetProfilesQueryVariables>(
    GET_PROFILES_QUERY
  )

  const isLinkedFi = useIsLinkedFi()

  const justOpened = !usePrevious(open) && open

  useEffect(() => {
    if (!justOpened || !canMergeSelectedItems) return
    const initializeMergeData = () => {
      fetchProfiles({ variables: { libraryEntityType, tokens: selectedEntityTokens } })

      const getSelectedPrimaryToken = () => {
        if (selectedLibraryType === LibraryTypeEnum.FinancialInstitutions) {
          const linkedFi = selectedEntities.find((entity) => isLinkedFi(entity.token))
          if (linkedFi) return linkedFi.token
        }
        // default to first selected entity token
        return selectedEntities[0].token
      }

      setSelectedPrimaryEntityToken(getSelectedPrimaryToken())
    }
    initializeMergeData()
  }, [
    canMergeSelectedItems,
    fetchProfiles,
    isLinkedFi,
    libraryEntityType,
    selectedEntities,
    selectedEntityTokens,
    selectedLibraryType,
    setSelectedPrimaryEntityToken,
    justOpened,
  ])

  const selectedEntitiesData = (data?.libraryByTokens ?? []) as MergeEntity[]

  const confirmMerge = useCallback(async () => {
    const primaryEntityToken = selectedPrimaryEntityToken
    const secondaryEntityToken = selectedEntityTokens.find((t) => t !== primaryEntityToken)
    const tokens = [primaryEntityToken, secondaryEntityToken]
    const result = await mutate({
      variables: {
        tokens,
        batchActionName: BatchActionTypeEnum.MergeLibraryProfiles,
        parameters: {
          type: libraryEntityType,
        },
      },
    })

    if (result?.data) {
      const bid = bidSelector(result.data)

      if (bid) {
        dispatch(batchActionsActions.jobStatus.set({ bid }))
      }
    }
    dispatch(
      batchActionsActions.recentlyUpdatedItems.set({
        tokens: selectedEntityTokens,
        value: true,
      })
    )
    onClose()
  }, [bidSelector, dispatch, libraryEntityType, mutate, onClose, selectedEntityTokens, selectedPrimaryEntityToken])

  const sharedDialogProps = {
    libraryType: libraryEntityType,
    mergeStep,
    open,
    onClose,
    selectedEntities: selectedEntitiesData,
    setMergeStep,
  }

  if (mergeStep === 'initial') {
    return <InitialStepDialog {...sharedDialogProps} loading={loading} />
  }

  if (mergeStep === 'compare') {
    return (
      <CompareStepDialog
        {...sharedDialogProps}
        selectedPrimaryEntityToken={selectedPrimaryEntityToken}
        setSelectedPrimaryEntityToken={setSelectedPrimaryEntityToken}
      />
    )
  }

  if (mergeStep === 'confirm') {
    return (
      <ConfirmStepDialog
        {...sharedDialogProps}
        confirmMerge={confirmMerge}
        mergeLoading={mergeLoading}
        selectedPrimaryEntityToken={selectedPrimaryEntityToken}
      />
    )
  }

  return null
}

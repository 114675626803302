import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  linkContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    gap: theme.spacing(),
    marginTop: theme.spacing(4),
  },
  link: {
    color: theme.palette.styleguide.darkGray,
    textDecoration: 'none',
    outline: 'none',
    ...theme.typography.sizes.lg,
  },
}))

interface Props {
  links?: { title: string; href: string }[]
  children?: ReactNode
}

export const AdditionalActions = (props: Props) => {
  const { links, children } = props
  const classes = useStyles()

  return (
    <div className={classes.linkContainer}>
      {children}
      {links?.map((link) => (
        <a key={link.title} className={classes.link} href={link.href}>
          {link.title}
        </a>
      ))}
    </div>
  )
}

import React, { useMemo } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { TermsTypeEnum } from 'types/api'

const useStyles = makeStyles(() => ({
  filterFields: {
    height: 40,
  },
}))

// Filterable fields, by apiName parameter
export const supportedTypes: readonly TermsTypeEnum[] = [
  TermsTypeEnum.String,
  TermsTypeEnum.Relation,
  TermsTypeEnum.Date,
  TermsTypeEnum.Number,
  TermsTypeEnum.Boolean,
] as const

// TODO seems likely that selecting an item will need to fetch the supported operators and actions
function FilterFieldSelect({ filterField, onChange }: { filterField?: string; onChange: (newField: string) => void }) {
  const { filterableColumns: rawColumns } = useDashboardColumns()
  const filterableColumns = useMemo(
    () =>
      rawColumns
        .filter((c) => c.filterPath && c.type && supportedTypes.includes(c.type))
        .sort((a, b) => a.title.localeCompare(b.title)),
    [rawColumns]
  )
  const { filterFields } = useStyles()

  return (
    <Select
      variant="outlined"
      className={classNames(filterFields)}
      value={filterField || ''}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      data-testid={`filter:field:${filterField || '__blank__'}`}
    >
      {filterableColumns.map((c) => (
        <MenuItem value={c.apiName} key={c.apiName} data-testid={`filter:field:option:${c.title}`}>
          {c.title}
        </MenuItem>
      ))}
    </Select>
  )
}

export default FilterFieldSelect

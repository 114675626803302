import { isEmpty, isNil, camelCase, isObject } from 'lodash'
import moment from 'moment-timezone'

import { SnakeDataTypeKey } from 'actions/importingFields.types'

export function dig(object: any, path: string) {
  return path.split('.').reduce((accum, key) => accum && accum[key], object)
}

export function findNthOccurrence(value: string, token: string, n: number) {
  let start = 0
  let found = 0
  let i = value.indexOf(token, start)
  while (i > -1) {
    found += 1
    if (found >= n) {
      break
    }

    start = i + 1
    i = value.indexOf(token, start)
  }

  return i
}

export function deepTransformValues(object: any, fn: (object: any) => any): unknown {
  if (Array.isArray(object)) {
    return object.map((value) => deepTransformValues(value, fn))
  }

  if (isObject(object)) {
    return Object.fromEntries(Object.entries(object).map(([key, value]) => [key, deepTransformValues(value, fn)]))
  }

  return fn(object)
}

export function needsRefresh(object: { lastFetchedAt: number; isMerging?: boolean }, refreshRate = 5) {
  if (!object) {
    return true
  }

  if (object.isMerging) {
    return true
  }

  const { lastFetchedAt } = object
  return isNil(lastFetchedAt) || moment.utc().diff(lastFetchedAt, 'minutes', true) > refreshRate
}

export function flattenOptions(options: any[]) {
  return options.reduce((accum: any[], option) => {
    if (option.display) {
      accum.push(option)
    }

    if (option.options) {
      option.options.forEach((opt: any) => {
        accum.push({ ...opt, group: option })
      })
    }
    return accum
  }, [])
}

export function isEmptyObject(object: any, skipKeys: string[] = []) {
  if (isEmpty(object)) {
    return true
  }
  return Object.keys(object).every((key) => {
    const value = object[key]
    const skip = skipKeys.includes(key)
    const nil = isNil(value)
    if (value instanceof Date) {
      return false
    }

    return skip || nil || value === '' || ((Array.isArray(value) || isObject(value)) && isEmpty(value))
  })
}

export function withEmpty<T>(list: T[], empty: T = {} as T) {
  const returnValue = (list || []).slice(0)
  if (isEmpty(returnValue)) {
    returnValue.push(empty)
  }

  return returnValue
}

export function convertDataTypeToClassName(dataType: SnakeDataTypeKey) {
  let className
  if (dataType === 'individual' || dataType === 'business') {
    className = 'entity'
  } else {
    className = camelCase(dataType)
  }
  return className
}

function removeProperties<T>(obj: T, properties: string[], removeNulls = false): unknown {
  if (Array.isArray(obj)) {
    return (obj as Array<unknown>).map((o) => removeProperties(o, properties, removeNulls))
  }

  if (typeof obj !== 'object' || !obj) {
    return obj
  }

  const rtn: any = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    const subobj = obj[key]
    if (!properties.includes(key) && (!removeNulls || (subobj !== null && subobj !== undefined))) {
      rtn[key] = removeProperties(subobj, properties, removeNulls)
    }
  }

  return rtn
}

export function processGqlForForm<T extends Record<string, unknown>>(obj: T | null) {
  if (!obj) {
    return null
  }

  // Remove __typename, token, & geopoint since mutations don't expect these
  // attributes to be present. This function only removes the token from
  // the root of the object, not any other inner objects it might be holding.
  // Also remove null attributes to avoid deletions during the mutation
  const { token, addresses, ...rest } = obj

  let newObj = rest

  // Due to weirdness with address types and polymorphism, we have to alias the addressType
  // field based on the parent entity type. Formik requires the output/input field names to be
  // equal, this re-aliases them back for the form
  if (addresses && addresses instanceof Array) {
    newObj = {
      addresses: addresses.map(({ personAddressType, businessAddressType, ...address }: Record<string, unknown>) => ({
        addressType: personAddressType || businessAddressType || undefined,
        ...address,
      })),
      ...newObj,
    }
  }

  return removeProperties(newObj, ['__typename', 'geopoint'], true)
}

export const filterNullItem = <I>(item: I): item is NonNullable<I> => !!item

export const upsertArrayElement = <I>(arr: I[], item: I, matcher: (i: I) => boolean) => {
  const updatedArr = [...arr]
  const itemIndex = updatedArr.findIndex(matcher)
  if (itemIndex < 0) {
    updatedArr.push(item)
  } else {
    updatedArr.splice(itemIndex, 1, item)
  }
  return updatedArr
}

import * as Yup from 'yup'

export const YupValidations = {
  onlyLetters: Yup.string().matches(/[A-Z]+/i, { message: 'Must only contain letters' }),
  onlyNumbers: Yup.string().matches(/[0-9]+/i, { message: 'Must only contain numbers' }),
  ssn: Yup.string().matches(/[\dX]{3}-?[\dX]{2}-?[\dX]{4}/, {
    excludeEmptyString: true,
    message: "Must be 9 digits. Use 'X' for partial eg XXX-XX-1234 or 123-45-6789",
  }),
  pastDate: Yup.lazy(() => Yup.date().max(new Date(), 'Must be in the past')),
  futureDate: Yup.lazy(() => Yup.date().min(new Date(), 'Must be in the future')),
}

export type Fields = Record<string, string | undefined | null | number | boolean>

export const containsNonEmptyField = (fields: Fields) => {
  const values = Object.values(fields)
  if (!values.length) {
    return false
  }
  return !!values.filter((value) => {
    switch (typeof value) {
      case 'string':
        return value.trim() !== ''
      case 'undefined':
        return false
      default:
        return true
    }
  }).length
}

import ErrorBoundary from 'components/ErrorBoundary'

export type LexicalErrorBoundaryProps = {
  children: JSX.Element
  onError: (error: Error) => void
}

export default function LexicalErrorBoundary({ children, onError }: LexicalErrorBoundaryProps): JSX.Element {
  return <ErrorBoundary onError={onError}>{children}</ErrorBoundary>
}

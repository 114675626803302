import { useState, useRef, MouseEvent, MouseEventHandler } from 'react'

import { MenuItem } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton, HbButtonSizes } from 'components/HbComponents/HbButton'
import { HbPopper, HbPopperProps } from 'components/HbComponents/HbPopper'
import { mergeOverrideStyles } from 'components/utils/styles'
import { MoreHorizIcon } from 'icons'

const useStyles = makeStyles(() => ({
  root: { position: 'relative' },
  button: {},
}))

export interface EllipsisAction {
  display: string
  onClick: (event: MouseEvent) => void
}

type Props = {
  actions: EllipsisAction[]
  classes?: Partial<ReturnType<typeof useStyles>>
  onButtonClick?: MouseEventHandler<HTMLButtonElement>
  label?: string
  menuPlacement?: HbPopperProps['placement']
  size?: HbButtonSizes
} & Pick<HbPopperProps, 'zIndex' | 'id'>

export default function EllipsisMenu(props: Props) {
  const {
    actions,
    classes: overrideClasses,
    onButtonClick,
    label,
    menuPlacement = 'left',
    zIndex,
    id,
    size = 'small',
  } = props
  const baseClasses = useStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)
  const menuTrigger = useRef(null)
  const [open, setOpen] = useState(false)

  return (
    <div className={classes.root}>
      <HbButton
        iconOnly
        label={label || 'menu'}
        Icon={MoreHorizIcon}
        className={classes.button}
        ref={menuTrigger}
        size={size}
        onClick={(e: MouseEvent<HTMLButtonElement>) => {
          onButtonClick?.(e)
          setOpen(true)
        }}
      />
      <HbPopper
        id={id}
        isOpen={open}
        anchorEl={menuTrigger.current}
        placement={menuPlacement}
        onClose={() => setOpen(false)}
        zIndex={zIndex}
      >
        {actions.map((action) => (
          <MenuItem
            key={action.display}
            onClick={(e: MouseEvent) => {
              e.stopPropagation()
              action.onClick(e)
              setOpen(false)
            }}
          >
            {action.display}
          </MenuItem>
        ))}
      </HbPopper>
    </div>
  )
}

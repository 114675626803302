import { FormControlLabel, FormControlLabelProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { Theme } from 'types/hb'

const useStyles = makeStyles<Theme, { error?: boolean; hasBorder?: boolean; disabled?: boolean }>((theme) => {
  return {
    boxed: ({ error, hasBorder, disabled }) => {
      let background = theme.palette.background.contrastMedium
      if (error) {
        background = theme.palette.styleguide.lightRed
      }
      if (disabled) {
        background = theme.palette.styleguide.greyF
      }
      return {
        background,
        border: hasBorder ? `1px solid ${theme.palette.styleguide.greyD}` : '1px solid transparent',
        '&:hover': {
          backgroundColor: theme.palette.styleguide.greyF,
        },
        '&:focus-within': {
          border: `1px solid ${theme.palette.styleguide.blue3}`,
        },
      }
    },
    root: {
      paddingLeft: theme.spacing(0.75),
      paddingRight: theme.spacing(0.75),
      borderRadius: theme.spacing(),
      display: 'flex',
      flex: '1 1 320px',
      justifyContent: 'flex-start',
      margin: theme.spacing(1, 1, 0, 0),
    },
    selected: ({ error, disabled }) => {
      let background = theme.palette.styleguide.lightPurple
      if (error) {
        background = theme.palette.styleguide.lightRed
      }
      if (disabled) {
        background = theme.palette.styleguide.greyF
      }
      return {
        background,
        marginTop: theme.spacing(),
      }
    },
    label: {
      padding: `${theme.spacing()} 0`,
    },
  }
})

interface Props extends FormControlLabelProps {
  hasBorder?: boolean
  error?: boolean
  isBoxed?: boolean
}

export const BoxedFormControlLabel = ({
  hasBorder,
  isBoxed = true,
  checked,
  className,
  error,
  disabled,
  ...rest
}: Props) => {
  const classes = useStyles({ hasBorder, error, disabled })

  const labelClassNames = classnames(
    classes.root,
    {
      [classes.selected]: checked && isBoxed,
      [classes.boxed]: isBoxed,
    },
    className
  )

  return (
    <FormControlLabel
      className={labelClassNames}
      classes={{
        label: classes.label,
      }}
      checked={checked}
      disabled={disabled}
      data-testid={rest.label}
      {...rest}
    />
  )
}

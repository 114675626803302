import { useCallback, useMemo } from 'react'

import { Box, ClassNameMap } from '@mui/material'

import { DatePicker, DatePickerProps, TimePicker, TimePickerProps } from '@mui/x-date-pickers'

import { Field, FieldAttributes, FieldProps } from 'formik'
import moment, { Moment } from 'moment-timezone'

import { useOrgTimeZone } from 'hooks/DateFormatHooks'

import { InputContainer, InputContainerProps } from '.'

interface DateOrTimePickerProps {
  label: string
  hideLabel?: boolean
  datePickerSlotProps?: DatePickerProps<Moment>['slotProps']
  timePickerSlotProps?: TimePickerProps<Moment>['slotProps']
  disableFuture?: boolean
  disableTime?: boolean
  requireTime?: boolean
}

export function getOnDateChangeHandler(requireTime: boolean, onChange: (date: string | null) => void) {
  return (value: string | null, orgTimeZone: string, newDate: Moment | null) => {
    if (!newDate) {
      onChange(null)
      return
    }

    const date = moment(newDate).format('YYYY-MM-DD')
    const parts = (value || '').split('T')
    parts[0] = date

    if (requireTime && parts.length < 2) {
      parts[1] = moment.tz([parts[0], '00:00:00'].join('T'), orgTimeZone).format('HH:mm:ssZ')
    }

    onChange(parts.join('T'))
  }
}

export function getOnTimeChangeHandler(requireTime: boolean, onChange: (date: string | null) => void) {
  return (value: string | null, orgTimeZone: string, newDate: Moment | null) => {
    // If the value is a datetime, ensure it is in the org time zone
    const orgValue = value?.indexOf('T') ? moment.tz(value, orgTimeZone).format() : value || ''

    // Force to the org time zone, we should ignore the local timezone which
    // is provided by default in the browser. Any 'time' values input via forms
    // should be interpreted as being in the org time zone.
    const newOrgDate = newDate?.isValid() ? moment(newDate).tz(orgTimeZone, true) : null

    const parts = orgValue.split('T')
    const hasDate = orgValue?.length > 0

    // When the newOrgDate is not present, we clear the time component
    if (!newOrgDate) {
      if (hasDate) {
        if (requireTime) {
          onChange(null)
        } else {
          onChange(parts[0])
        }
      }
      return
    }

    if (hasDate) {
      const date = newOrgDate.format('HH:mm:ssZ')

      parts[1] = date
      onChange(moment.tz(parts.join('T'), orgTimeZone).format())
    } else {
      onChange(newOrgDate.format())
    }
  }
}

// If the value is a datetime, format it in the org time zone, then force
// to local time to ensure the org time zone values are displayed.
//
// The above callbacks perform the reverse to ensure the correct timezone
// is recorded.
function getLocalTime(value: string, orgTimeZone: string) {
  return value?.indexOf('T') !== -1 ? moment(value).tz(orgTimeZone).tz(moment.tz.guess(), true).format() : value
}

const getValue = (localValue: string | null) => (localValue ? moment(localValue) : null)

const useFormikHandlers = ({
  field,
  form,
  requireTime = false,
}: {
  field: FieldProps<string>['field']
  form: FieldProps<string>['form']
  requireTime?: boolean
}) => {
  const { name, value } = field
  const onBlur = useCallback(() => form.setFieldTouched(name, true), [form, name])

  const setValue = useCallback((newValue: string) => form.setFieldValue(name, newValue), [form, name])

  const onDateChange = useMemo(() => getOnDateChangeHandler(requireTime, setValue), [requireTime, setValue])
  const onTimeChange = useMemo(() => getOnTimeChangeHandler(requireTime, setValue), [requireTime, setValue])
  const orgTimeZone = useOrgTimeZone()

  const localValue = useMemo(() => (value ? getLocalTime(value, orgTimeZone) : null), [value, orgTimeZone])

  return { localValue, onBlur, onDateChange, onTimeChange, orgTimeZone, value }
}

interface FormikDatePickerProps {
  disableFuture?: boolean
  disabled?: boolean
  field: FieldProps<string>['field']
  form: FieldProps<string>['form']
  label?: DatePickerProps<Moment>['label']
  requireTime?: boolean
  slotProps?: DatePickerProps<Moment>['slotProps']
}

const FormikDatePicker = ({
  disableFuture,
  disabled,
  field,
  form,
  label,
  requireTime = false,
  slotProps: propSlotProps,
}: FormikDatePickerProps) => {
  const { localValue, onBlur, onDateChange, orgTimeZone, value } = useFormikHandlers({
    field,
    form,
    requireTime,
  })

  const slotProps = {
    ...propSlotProps,
    textField: {
      onBlur,
      fullWidth: true,
      ...propSlotProps?.textField,
    },
  }

  return (
    <DatePicker<Moment>
      disableFuture={disableFuture}
      format="MM/DD/YYYY"
      label={label}
      onChange={(date) => onDateChange(value, orgTimeZone, date)}
      slotProps={slotProps}
      value={getValue(localValue)}
      disabled={disabled}
    />
  )
}

type HbFormikDatePickerProps = {
  error?: boolean
  field: FieldProps<string>['field']
  form: FieldProps<string>['form']
  hasTime?: boolean
  inputContainerClassName?: string
  name?: string
  requireTime?: boolean
  datePickerSlotProps?: Omit<FormikDatePickerProps, 'field' | 'form'>
  disabled?: boolean
} & Omit<InputContainerProps, 'htmlFor'>

const HbFormikDatePicker = ({
  className,
  error = false,
  errors,
  field,
  form,
  hasTime,
  helperText,
  hideLabel,
  inputContainerClassName,
  label,
  name,
  noSpacing,
  required,
  requireTime,
  datePickerSlotProps: propDatePickerSlotProps,
  sublabel,
  testId,
  disabled,
  ...rest
}: HbFormikDatePickerProps) => {
  const hasErrors = error || Boolean(errors?.length) || Boolean(typeof errors === 'string' && errors)
  const datePickerProps = {
    ...propDatePickerSlotProps,
    slotProps: {
      ...propDatePickerSlotProps?.slotProps,
      textField: {
        ...propDatePickerSlotProps?.slotProps?.textField,
        variant: 'outlined' as const,
      },
    },
  }
  return (
    <InputContainer
      isErroneous={hasErrors}
      required={required}
      htmlFor={name}
      label={label}
      sublabel={sublabel}
      className={inputContainerClassName}
      noSpacing={noSpacing}
      errors={errors}
      helperText={helperText}
      testId={testId}
      hideLabel={hideLabel}
      {...rest}
    >
      <FormikDatePicker field={field} form={form} {...datePickerProps} disabled={disabled} />
    </InputContainer>
  )
}

type HbFormikDatePickerFieldProps = Omit<HbFormikDatePickerProps, 'field' | 'form'>

export const HbFormikDatePickerField = (props: HbFormikDatePickerFieldProps) => (
  <Field {...props} component={HbFormikDatePicker} />
)

interface FormikTimePickerProps {
  field: FieldProps<string>['field']
  form: FieldProps<string>['form']
  label?: TimePickerProps<Moment>['label']
  requireTime?: boolean
  disabled?: boolean
  slotProps?: TimePickerProps<Moment>['slotProps']
}

const FormikTimePicker = ({
  field,
  form,
  label,
  requireTime,
  disabled,
  slotProps: propSlotProps,
}: FormikTimePickerProps) => {
  const { localValue, onBlur, onTimeChange, orgTimeZone, value } = useFormikHandlers({
    field,
    form,
    requireTime,
  })

  const hasTime = value?.indexOf('T') > -1

  const slotProps = {
    ...propSlotProps,
    textField: {
      onBlur,
      fullWidth: true,
      ...propSlotProps?.textField,
    },
  }

  return (
    <TimePicker<Moment>
      label={label}
      onChange={(date) => onTimeChange(value, orgTimeZone, date)}
      slotProps={slotProps}
      value={hasTime ? getValue(localValue) : null}
      disabled={disabled}
    />
  )
}

type HbFormikTimePickerProps = {
  error?: boolean
  field: FieldProps<string>['field']
  form: FieldProps<string>['form']
  disabled?: boolean
  inputContainerClassName?: string
  name?: string
  requireTime?: boolean
  timePickerSlotProps?: Omit<FormikTimePickerProps, 'field' | 'form'>
} & Omit<InputContainerProps, 'htmlFor'>

export const HbFormikTimePicker = ({
  className,
  error = false,
  errors,
  field,
  form,
  disabled,
  helperText,
  hideLabel,
  inputContainerClassName,
  label,
  name,
  noSpacing,
  required,
  requireTime,
  timePickerSlotProps: propTimePickerSlotProps,
  sublabel,
  testId,
  ...rest
}: HbFormikTimePickerProps) => {
  const hasErrors = error || Boolean(errors?.length) || Boolean(typeof errors === 'string' && errors)
  const timePickerProps = {
    ...propTimePickerSlotProps,
    slotProps: {
      ...propTimePickerSlotProps?.slotProps,
      textField: {
        ...propTimePickerSlotProps?.slotProps?.textField,
        variant: 'outlined' as const,
      },
    },
  }
  return (
    <InputContainer
      isErroneous={hasErrors}
      required={required}
      htmlFor={name}
      label={label}
      sublabel={sublabel}
      className={inputContainerClassName}
      noSpacing={noSpacing}
      errors={errors}
      helperText={helperText}
      testId={testId}
      hideLabel={hideLabel}
      {...rest}
    >
      <FormikTimePicker field={field} form={form} {...timePickerProps} disabled={disabled} />
    </InputContainer>
  )
}

type HbFormikTimePickerFieldProps = Omit<HbFormikTimePickerProps, 'field' | 'form'>

export const HbFormikTimePickerField = (props: HbFormikTimePickerFieldProps) => (
  <Field {...props} component={HbFormikTimePicker} />
)

// Picker that allows selecting a date and optionally a time component; we use MUI components, but it has Formik props
export const FormikDateOrTimePicker = ({
  field,
  form,
  label,
  hideLabel,
  disableFuture,
  disableTime,
  requireTime = false,
  datePickerSlotProps,
  timePickerSlotProps,
}: DateOrTimePickerProps & FieldProps<string>) => {
  const sharedProps = { field, form, requireTime }
  const dateLabelProp = hideLabel ? {} : { label: `${label} date` }
  const timeLabelProp = hideLabel ? {} : { label: `${label} time${requireTime ? '' : ' (optional)'}` }

  return (
    <Box display="flex" flexDirection="row" width="100%" pb={2} columnGap={2}>
      <Box flex="1">
        <FormikDatePicker
          {...sharedProps}
          disableFuture={disableFuture}
          {...dateLabelProp}
          slotProps={datePickerSlotProps}
        />
      </Box>
      {!disableTime ? (
        <Box flex="1">
          <FormikTimePicker {...sharedProps} {...timeLabelProp} slotProps={timePickerSlotProps} />
        </Box>
      ) : null}
    </Box>
  )
}

type DateOrTimeFieldProps = DateOrTimePickerProps & FieldAttributes<unknown>

export const DateOrTimeField = (props: DateOrTimeFieldProps) => <Field {...props} component={FormikDateOrTimePicker} />

interface HbFormikDateOrTimePickerProps {
  label: string
  name: string
  className?: string
  classes?: Partial<ClassNameMap<'dateInputContainer' | 'timeInputContainer'>>
  datePickerSlotProps?: HbFormikDatePickerProps['datePickerSlotProps']
  timePickerSlotProps?: HbFormikTimePickerProps['timePickerSlotProps']
  disableFuture?: boolean
  disableTime?: boolean
  requireTime?: boolean
  disabled?: boolean
}

export const HbFormikDateOrTimePicker = ({
  label,
  name,
  classes,
  className,
  disableFuture,
  disableTime,
  disabled,
  requireTime = false,
  datePickerSlotProps: propDatePickerSlotProps,
  timePickerSlotProps,
}: HbFormikDateOrTimePickerProps) => {
  const sharedProps = { requireTime }

  const datePickerSlotProps = {
    disableFuture,
    ...propDatePickerSlotProps,
  }

  return (
    <Box display="flex" flexDirection="row" width="100%" columnGap={2} className={className}>
      <Box flex="1">
        <HbFormikDatePickerField
          datePickerSlotProps={datePickerSlotProps}
          disabled={disabled}
          inputContainerClassName={classes?.dateInputContainer}
          label={`${label} date`}
          name={name}
          {...sharedProps}
        />
      </Box>
      {!disableTime ? (
        <Box flex="1">
          <HbFormikTimePickerField
            disabled={disabled}
            inputContainerClassName={classes?.timeInputContainer}
            label={`${label} time${requireTime ? '' : ' (optional)'}`}
            name={name}
            timePickerSlotProps={timePickerSlotProps}
            {...sharedProps}
          />
        </Box>
      ) : null}
    </Box>
  )
}

import React, { useCallback, useState } from 'react'

import { CircularProgress } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { PanAndZoom } from 'components/library/PanAndZoom'
import { ZoomInIcon, ZoomOutIcon } from 'icons'
import { Theme } from 'types/hb'

import { AttachmentViewerControls, AttachmentViewerButton } from './AttachmentViewerControls'
import { useViewerRootStyle } from './UseAttachmentViewerRootStyle'

export type AttachmentImageViewerProps = {
  imageUrl?: string | null
  altText: string
  sidePaneVisible?: boolean
}

const useStyles = makeStyles((_theme: Theme) => ({
  image: {
    maxWidth: '94%',
    maxHeight: '94%',
  },
  hidden: {
    display: 'none',
  },
}))

export default function AttachmentImageViewer({ imageUrl, altText, sidePaneVisible }: AttachmentImageViewerProps) {
  const { root, loader, hasSidePane } = useViewerRootStyle()
  const { image, hidden } = useStyles()
  const [loading, setLoading] = useState(true)

  const onLoad = useCallback((_event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    setLoading(false)
  }, [])

  return (
    <>
      {loading && <CircularProgress className={loader} />}
      <PanAndZoom
        containerClassName={classnames(root, { [hasSidePane]: sidePaneVisible })}
        contentClassName={root}
        utilities={({ zoomIn, zoomOut }) => (
          <AttachmentViewerControls>
            <AttachmentViewerButton onClick={zoomIn}>
              <ZoomInIcon />
            </AttachmentViewerButton>
            <AttachmentViewerButton onClick={zoomOut}>
              <ZoomOutIcon />
            </AttachmentViewerButton>
          </AttachmentViewerControls>
        )}
      >
        {imageUrl && (
          <img
            className={classnames(image, {
              [hidden]: loading,
            })}
            src={imageUrl}
            alt={altText}
            onLoad={onLoad}
          />
        )}
      </PanAndZoom>
    </>
  )
}

import { AuthenticationType } from 'components/onboarding/account/AccountTypes'
import { EmptyResult } from 'types/hb'

import { Thunk } from './store'

export function setPassword({
  confirmationToken,
  password,
  passwordConfirmation,
}: {
  confirmationToken: string
  password: string
  passwordConfirmation: string
}): Thunk<Promise<boolean>> {
  return (dispatch, getState, { api }) =>
    api
      .put('updateCredentialConfirmationPath', {
        data: {
          confirmationToken,
          credential: { password, passwordConfirmation },
        },
      })
      .then((json) => !!json && json.success)
}

export function resetPassword(email: string): Thunk<Promise<{ success: boolean }>> {
  return async (dispatch, getState, { api }) => {
    const { success } = await api.post('credentialPasswordPath', {
      data: {
        credential: {
          email,
        },
      },
    })

    return {
      success,
    }
  }
}

export function changePassword(
  password: string,
  passwordConfirmation: string,
  resetPasswordToken: string
): Thunk<Promise<EmptyResult>> {
  return async (dispatch, getState, { api }) => {
    const result: EmptyResult = await api.put('credentialPasswordPath', {
      data: {
        credential: {
          password,
          passwordConfirmation,
          resetPasswordToken,
        },
      },
    })

    return result
  }
}

export function signIn(email: string, password: string): Thunk<Promise<{ authenticated: boolean; location: string }>> {
  return async (dispatch, getState, { api }) => {
    const { json, headers } = await api.post('credentialSessionPath', {
      data: {
        credential: { email, password },
      },
      returnResponseDetails: true,
    })

    return {
      authenticated: !!json.credential?.isAuthenticated,
      location: headers.get('Location'),
    }
  }
}

export function sendMfaCode(code: string): Thunk<Promise<EmptyResult>> {
  return async (dispatch, getState, { api }) => {
    const result: EmptyResult = await api.post('credentialTwoFactorAuthenticationPath', {
      data: {
        code,
      },
    })

    return result
  }
}

export interface AuthenticationProvider {
  authenticationType: AuthenticationType
  redirectUrl: string
}

export function checkEmail(email: string): Thunk<Promise<{ authentication?: AuthenticationProvider }>> {
  return async (dispatch, getState, { api }) => {
    const { json } = await api.post('checkAuthenticationTypesPath', {
      data: {
        email,
      },
      returnResponseDetails: true,
    })

    return {
      authentication: json?.authentication,
    }
  }
}

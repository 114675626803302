import { DropResult } from 'react-beautiful-dnd'

export function moveItemInList<T>(list: T[], srcIdx: number, dstIdx: number): T[] {
  const newList = list.slice()
  const [item] = newList.splice(srcIdx, 1)
  newList.splice(dstIdx, 0, item)
  return newList
}

export function createDragHandler<T>(list: T[], onNewOrder: (newOrder: T[]) => void) {
  return (result: DropResult) => {
    if (!result.destination) {
      return
    }
    const newList = moveItemInList(list, result.source.index, result.destination.index)
    onNewOrder(newList)
  }
}

import { useState, useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'
import { OutlinedInput, Select, MenuItem } from '@mui/material'

import { groupBy } from 'lodash'

import invariant from 'tiny-invariant'

import { GQLError } from 'components/GQLError'
import { HbDialog } from 'components/HbComponents/HbDialog'
import Loader from 'components/library/Loader'
import {
  Root,
  SectionHeader,
  StyledSearchIcon,
  TemplateSearch,
  TemplateSection,
  TemplatesList,
} from 'components/pages/automations/editor/TriggerTemplateSelector.styles'
import { AutomationRuleType, AutomationDomainType } from 'types/api'

import { AutomationIcon, ICON_MAPPING } from '../AutomationIcon'

import { ActionCard } from './ActionCard'
import { AutomationDomain, useDomainFieldSpecs } from './TriggerFilterEditor/fieldConfig'
import { TriggerTemplateSelectorQuery } from './__generated__/TriggerTemplateSelector.generated'
import { FormSchemaReturnType, getDefaultTriggerFilters } from './formSchema'
import { transformTriggerFilters, resetKeepDirty } from './util'

const PAGE_QUERY = gql`
  query TriggerTemplateSelector {
    automationTriggerTemplates {
      domainType
      description
      name
      slug
      snapshotFilters
    }
  }
`

interface Category {
  display: string
  value: AutomationDomainType
}

const categories: Record<AutomationDomainType, Category> = {
  case: {
    display: 'Case',
    value: AutomationDomainType.Case,
  },
  datasource: {
    display: 'Datasource',
    value: AutomationDomainType.Datasource,
  },
  filing: {
    display: 'Filing',
    value: AutomationDomainType.Filing,
  },
  review: {
    display: 'Review',
    value: AutomationDomainType.Review,
  },
  person: {
    display: 'Person',
    value: AutomationDomainType.Person,
  },
  business: {
    display: 'Business',
    value: AutomationDomainType.Business,
  },
  middesk_business: {
    display: 'Middesk Business',
    value: AutomationDomainType.MiddeskBusiness,
  },
}

type AutomationTriggerTemplate = TriggerTemplateSelectorQuery['automationTriggerTemplates'][number]

type TemplateData = Array<{
  template: AutomationTriggerTemplate
  searchValue: string
}>

interface Props {
  open: boolean
  onClose: () => void
  form: FormSchemaReturnType
}

export function TriggerTemplateSelector({ open, onClose, form }: Props) {
  const [search, setSearch] = useState<string | null>(null)
  const [selectedCategory, setSelectedCategory] = useState('all')

  const { loading, data, error } = useQuery<TriggerTemplateSelectorQuery>(PAGE_QUERY)

  const { loading: domainFieldSpecsLoading, getDomainFieldSpec } = useDomainFieldSpecs()

  const templateData = useMemo(
    (): TemplateData =>
      data?.automationTriggerTemplates.map((template: AutomationTriggerTemplate) => {
        const searchValue = [template.name, template.description, template.domainType].join(' ').toLowerCase()

        return {
          template,
          searchValue,
        }
      }) || [],
    [data?.automationTriggerTemplates]
  )

  const filteredTemplateData = useMemo(() => {
    let newTemplateData = templateData

    if (selectedCategory !== 'all') {
      newTemplateData = newTemplateData.filter(({ template }) => selectedCategory === template.domainType)
    }

    if (search) {
      const lowerCaseSearch = search.toLowerCase()
      newTemplateData = newTemplateData.filter(({ searchValue }) => searchValue.includes(lowerCaseSearch))
    }

    return newTemplateData
  }, [search, selectedCategory, templateData])

  const templateGroups = useMemo(
    (): Array<[Category, TemplateData]> =>
      Object.entries(groupBy(filteredTemplateData, ({ template }) => template.domainType)).map(
        ([domainType, templates]) => [categories[domainType as AutomationDomainType], templates]
      ),
    [filteredTemplateData]
  )

  function resetToDomain(domain: AutomationDomain, template?: AutomationTriggerTemplate) {
    const domainFieldSpec = getDomainFieldSpec(domain)
    invariant(domainFieldSpec)

    const triggerFilters = template
      ? transformTriggerFilters(template.snapshotFilters)
      : getDefaultTriggerFilters(domain.type, domainFieldSpec)

    resetKeepDirty(form, (existingValues) => ({
      ...existingValues,
      automationType: AutomationRuleType.Trigger,
      domain: {
        ...('domain' in existingValues ? existingValues.domain : {}),
        type: domain.type,
      },
      triggerFilters,
    }))
  }

  const content = templateGroups.map(([category, templates]) => {
    const { Icon, color } = ICON_MAPPING[category.value as AutomationDomainType]

    return (
      <TemplateSection key={category.value}>
        <SectionHeader size="md" bold>
          {category.display}
        </SectionHeader>
        <TemplatesList>
          <ActionCard
            title="Blank trigger"
            description={`Build your ${category.display} automation from the ground up`}
            onClick={() => resetToDomain({ type: category.value as AutomationDomainType, datasourceToken: null })}
            icon={<AutomationIcon Icon={Icon} color={color} />}
          />
          {templates.map(({ template }) => (
            <ActionCard
              key={template.slug}
              title={template.name}
              description={template.description}
              onClick={() => resetToDomain({ type: template.domainType, datasourceToken: null }, template)}
              icon={<AutomationIcon Icon={Icon} color={color} />}
            />
          ))}
        </TemplatesList>
      </TemplateSection>
    )
  })

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value as string)
  }

  const handleSelectCategory = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedCategory(event.target.value as string)
  }

  if (loading || domainFieldSpecsLoading || !data) {
    return <Loader variant="global" />
  }

  if (error) {
    return <GQLError error={error} />
  }

  return (
    <HbDialog
      id="automation_trigger_select"
      open={open}
      title="Add Trigger"
      onClose={onClose}
      content={
        <Root>
          <TemplateSearch>
            <Select sx={{ width: '30%', margin: 2 }} value={selectedCategory} onChange={handleSelectCategory}>
              <MenuItem value="all">All Categories</MenuItem>
              {Object.values(categories).map(({ display, value }) => (
                <MenuItem key={value} value={value} sx={{ textTransform: 'capitalize' }}>
                  {display}
                </MenuItem>
              ))}
            </Select>
            <OutlinedInput
              startAdornment={<StyledSearchIcon />}
              sx={{ width: '60%', margin: 2 }}
              placeholder="Search for triggers..."
              onChange={handleSearch}
              value={search}
              size="medium"
            />
          </TemplateSearch>
          {content}
        </Root>
      }
      actions={<div />}
    />
  )
}

import { MenuItem, Select, SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { FieldPathByValue, Controller, ControllerRenderProps, ControllerFieldState } from 'react-hook-form'
import invariant from 'tiny-invariant'

import Loader from 'components/library/Loader'
import { Theme } from 'types/hb'

import { FormSchema, TriggerSchemaReturnType } from '../formSchema'

import { VariableEditor, type FieldPath as VariableFieldPath } from './VariableEditor'
import {
  fieldSpecConfigForVariablePath,
  operatorLabels,
  type FieldSpec,
  isListOperator,
  isExistenceOperator,
  isSingleOperator,
  AutomationDomain,
  useDomainFieldSpecs,
} from './fieldConfig'
import { useThinControlStyles } from './styles'

import type { StrictExpressionValue, VariableNode, LiteralNode, TypeCastLiteral } from 'types/automations'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    gap: theme.spacing(1),
    display: 'flex',
    flexGrow: 1,
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  operator: {
    flexShrink: 1,
    minWidth: 90,
  },
  right: {
    flexGrow: 1,
  },
}))

export type FieldPath = FieldPathByValue<FormSchema, StrictExpressionValue>

interface Props {
  name: FieldPath
  domain: AutomationDomain
  form: TriggerSchemaReturnType
  parentVariableNode?: VariableNode | null
  onChangeToListExpression: (newPath: string[], defaultPath: string[] | null | undefined) => void
  onChangeToLinkedTrigger: (newPath: string[], subDomain: AutomationDomain) => void
  onChangeToBinaryExpression: (defaultPath: string[] | null | undefined) => void
  onChangeToTypeCast: (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => void
  onChangeFromTypeCast: (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => void
}

type LiteralFieldPath =
  | FieldPathByValue<FormSchema, LiteralNode | TypeCastLiteral>
  | `${FieldPathByValue<FormSchema, LiteralNode | TypeCastLiteral>}.value`

export default function BinaryExpressionEditor({
  name,
  domain,
  form,
  parentVariableNode,
  onChangeToListExpression,
  onChangeToBinaryExpression,
  onChangeToLinkedTrigger,
  onChangeToTypeCast,
  onChangeFromTypeCast,
}: Props) {
  const styles = useStyles()
  const controlStyles = useThinControlStyles()
  const { watch, setValue } = form

  const node = watch(name)

  invariant(node.type === 'binary_expression')
  // left side can either be variable or type casted variable. Extract the variable node
  invariant(node.left.type === 'variable' || node.left.type === 'type_cast')
  const variableName: VariableFieldPath = node.left.type === 'type_cast' ? `${name}.left.value` : `${name}.left`
  const variableNode = watch(variableName) as VariableNode

  // right side can either be literal or type casted literal. Extract the literal node
  invariant(node.right.type === 'literal' || node.right.type === 'type_cast')
  const literalName: LiteralFieldPath = node.right.type === 'type_cast' ? `${name}.right.value` : `${name}.right`
  const literalNode = watch(literalName) as LiteralNode

  const lastFieldName = variableNode.path.at(-1) || parentVariableNode?.path.at(-1)

  const { loading, getDomainFieldSpec } = useDomainFieldSpecs()

  const fieldConfig = fieldSpecConfigForVariablePath(
    domain,
    getDomainFieldSpec,
    (parentVariableNode?.path || []).concat(variableNode.path)
  )

  invariant(lastFieldName && fieldConfig[lastFieldName], `Expected field spec for ${lastFieldName}`)
  const lastFieldSpec = fieldConfig[lastFieldName].fieldSpec

  const handleChangeToTypeCast = (newPath: string[], newFieldSpec: FieldSpec) => {
    onChangeToTypeCast(newPath, lastFieldSpec, newFieldSpec)
  }

  const handleChangeFromTypeCast = (newPath: string[], newFieldSpec: FieldSpec) => {
    onChangeFromTypeCast(newPath, lastFieldSpec, newFieldSpec)
  }

  const showEditor = lastFieldSpec?.type === 'field' || lastFieldSpec?.type === 'fieldList'

  // A bit of a hack, but we want to use existence operators as part of binary expressions
  // But that means the right value is no longer relevant
  const showRightValue = !!node.operator && !isExistenceOperator(node.operator)

  if (loading) {
    return <Loader variant="global" />
  }

  return (
    <div className={styles.root}>
      <VariableEditor
        config={fieldConfig}
        name={variableName}
        domain={domain}
        onChangeToListExpression={onChangeToListExpression}
        onChangeToBinaryExpression={onChangeToBinaryExpression}
        onChangeToLinkedTrigger={onChangeToLinkedTrigger}
        onChangeToTypeCast={handleChangeToTypeCast}
        onChangeFromTypeCast={handleChangeFromTypeCast}
      />
      {showEditor && (
        <>
          <Controller
            name={`${name}.operator`}
            render={({ field }) => {
              const handleOperatorChange = (e: SelectChangeEvent) => {
                const newValue = e.target.value
                const oldValue = field.value

                const isBecomingListOperator = !isListOperator(oldValue) && isListOperator(newValue)
                const isBecomingSingularOperator = !isSingleOperator(oldValue) && isSingleOperator(newValue)
                const isBecomingExistenceOperator = !isExistenceOperator(oldValue) && isExistenceOperator(newValue)

                if (isBecomingListOperator) {
                  // This should only happen if in a string edit field
                  invariant(typeof literalNode.value === 'string')

                  const newLiteralValue = literalNode.value ? [literalNode.value] : []

                  setValue(`${literalName}.value`, newLiteralValue)
                } else if (isBecomingSingularOperator) {
                  // This should only happen from a string list edit field
                  invariant(
                    Array.isArray(literalNode.value) &&
                      (typeof literalNode.value[0] === 'string' || !literalNode.value[0])
                  )

                  const newLiteralValue = literalNode.value[0] || ''

                  setValue(`${literalName}.value`, newLiteralValue)
                } else if (isBecomingExistenceOperator) {
                  // We don't use this value if we're in existence operator mode
                  setValue(`${literalName}.value`, '')
                }

                field.onChange(e)
              }

              return (
                <Select
                  className={classNames(styles.operator, controlStyles.control)}
                  required
                  inputProps={field}
                  onChange={handleOperatorChange}
                  variant="outlined"
                >
                  {lastFieldSpec.operators.map((o) => (
                    <MenuItem key={o} value={o}>
                      {operatorLabels[o]}
                    </MenuItem>
                  ))}
                </Select>
              )
            }}
          />
          {showRightValue && (
            <Controller
              name={`${literalName}.value`}
              render={({ field, fieldState }: { field: ControllerRenderProps; fieldState: ControllerFieldState }) => (
                <lastFieldSpec.Component
                  className={styles.right}
                  inputProps={field}
                  operator={node.operator}
                  label={lastFieldName}
                  fieldState={fieldState}
                  data-testid="last-field"
                />
              )}
            />
          )}
        </>
      )}
    </div>
  )
}

import React, { ReactNode } from 'react'

import { Fab, FabProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import VisuallyHidden from '@reach/visually-hidden'

import { Theme } from 'types/hb'

import { ButtonCircularProgress } from '../library/Primitives/ButtonCircularProgress'

// HbButtonTypes correspond to button designs in Figma
export const HbFabVariantOptions = ['primary', 'secondary'] as const
export type HbFabVariants = (typeof HbFabVariantOptions)[number]
export type HbFabSizes = 'small' | 'medium' | 'large'
type MuiVariantTypes = 'extended' | 'circular'
type ColorTypes = 'primary' | 'secondary' | 'inherit'

interface NewProps {
  size?: HbFabSizes
  variant: HbFabVariants
  Icon?: React.ComponentType<{ className?: string }>
  iconPosition?: 'start' | 'end'
  iconSizePx?: number
  label: ReactNode // label is always required for accessibility, but can be hidden via `iconOnly` prop
  iconOnly?: boolean
  loading?: boolean
}

export type Props = Omit<FabProps, 'variant' | 'size' | 'children'> & NewProps

interface StyleProps {
  color: ColorTypes
  variant: MuiVariantTypes
}

const getMuiVariantAndColor = (variant?: HbFabVariants, iconOnly?: boolean, icon?: ReactNode): StyleProps => {
  let muiVariant: MuiVariantTypes = 'extended'
  let color: ColorTypes = 'primary'

  if (variant === 'secondary') {
    color = 'secondary'
  }

  if (iconOnly && icon) {
    muiVariant = 'circular'
  }

  return {
    variant: muiVariant,
    color,
  }
}

interface FabStyleProps {
  isExtended: boolean
  iconPosition: 'start' | 'center' | 'end'
  iconSizePx?: number
}

const useStyles = makeStyles((theme: Theme) => ({
  icon: ({ isExtended, iconPosition, iconSizePx }: FabStyleProps) => ({
    width: iconSizePx ?? 24,
    height: iconSizePx ?? 24,
    marginLeft: isExtended && iconPosition === 'end' ? theme.spacing() : 0,
    marginRight: isExtended && iconPosition === 'start' ? theme.spacing() : 0,
  }),
  label: ({ iconPosition }: FabStyleProps) => ({
    marginLeft: iconPosition === 'start' ? theme.spacing(0.25) : 0,
    marginRight: iconPosition === 'end' ? theme.spacing(0.25) : 0,
  }),
}))

export const HbFab = React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    className,
    label,
    variant,
    Icon,
    iconOnly,
    loading,
    disabled,
    iconPosition: iconPositionProp = 'end',
    iconSizePx,
    ...otherProps
  } = props

  const iconPosition = iconOnly ? 'center' : iconPositionProp

  const styleProps = getMuiVariantAndColor(variant, iconOnly, Icon)
  const classes = useStyles({ isExtended: styleProps.variant === 'extended', iconPosition, iconSizePx })

  const iconElement = Icon ? <Icon className={classes.icon} /> : undefined

  const renderedLabel = iconOnly ? (
    <VisuallyHidden>{label}</VisuallyHidden>
  ) : (
    <span className={classes.label}>{label}</span>
  )

  return (
    <Fab
      ref={ref}
      className={className}
      {...otherProps}
      {...styleProps}
      disabled={disabled || loading}
      disableFocusRipple
    >
      {iconPosition === 'end' ? (
        <>
          {renderedLabel}
          {iconElement}
        </>
      ) : (
        <>
          {iconElement}
          {renderedLabel}
        </>
      )}
      {loading && <ButtonCircularProgress />}
    </Fab>
  )
})

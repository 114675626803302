import { AnyAction } from 'redux'

import { setLoading } from 'actions/viewActions'
import { poll, fetchHeadStatus } from 'helpers/PollingHelper'

import { GeneratedDocumentType } from 'types/api'

import { AsyncThunk } from './store'

/**
 * * `TIMEOUT` indicates the document generation could not finish within an
 * appropriate timeframe.
 * * `UNKNOWN` is for all other errors.
 */
export type ErrorType = 'TIMEOUT' | 'UNKNOWN'
export const SET_DOCUMENT_URL = 'SET_DOCUMENT_URL'
export const SET_DOCUMENT_ERROR = 'SET_DOCUMENT_ERROR'
export const DOCUMENT_LOADING_TOKEN = 'generateDocument'

export function setDocumentURL(downloadURL: string): AnyAction {
  return { type: SET_DOCUMENT_URL, downloadURL }
}

export function setDocumentError(messages: string[], type: ErrorType = 'UNKNOWN'): AnyAction {
  return { type: SET_DOCUMENT_ERROR, error: { type, messages } }
}

export function setTimeoutError(documentName: string) {
  // only investigation docs can realistically return this. all other docs are
  // generated in-band so if we had a timeout, something went actually wrong.
  if (documentName === 'investigation-documents') {
    return setDocumentError(
      [
        'We are still working on your download. You will receive an email with a download link when it is ready.',
        'Note: this could take some time.',
      ],
      'TIMEOUT'
    )
  }
  return setDocumentError(
    ['Please refresh the page to download your document. If this message persists please contact customer support.'],
    'TIMEOUT'
  )
}

function generateDocument(reviewToken: string, documentName: string): AsyncThunk<any> {
  return (_dispatch, _getState, { api }) => {
    return api.get('generateReviewDocumentPath', {
      urlParams: {
        reviewToken,
        id: documentName,
      },
    })
  }
}

export function pollForDocument(pollUrl: string, getUrl: string, documentName: string): AsyncThunk<void> {
  return async (dispatch, _getState, _props) => {
    // Changed the maxRetries to 180 sconds to support long running document generation, in addition to new
    // error message in case the document is not available in s3 just yet.
    const { success } = await poll(() => fetchHeadStatus(pollUrl), 180)

    dispatch(success ? setDocumentURL(getUrl) : setTimeoutError(documentName))
    dispatch(setLoading(false, DOCUMENT_LOADING_TOKEN))
  }
}

export function waitForDocumentReady(reviewToken: string, documentName: string): AsyncThunk<void> {
  return async (dispatch, _getState, _props) => {
    dispatch(setLoading(true, DOCUMENT_LOADING_TOKEN))
    const document = await dispatch(generateDocument(reviewToken, documentName))

    if (document.success) {
      // Start polling until the document is ready
      await dispatch(pollForDocument(document.presignedHeadUrl, document.presignedGetUrl, documentName))
    } else {
      dispatch(setDocumentError([document.error.message]))
      dispatch(setLoading(false, DOCUMENT_LOADING_TOKEN))
    }
  }
}

function documentTypeToUrlParam(type: GeneratedDocumentType): string {
  // GraphQL doesn't support hyphens in enum values, so we need to convert them
  return type.toLowerCase().replace('_', '-')
}

export function waitForGeneratedDocumentUrl(
  reviewToken: string,
  documentType: GeneratedDocumentType
): AsyncThunk<string> {
  return async (dispatch, _getState, _props) => {
    const document = await dispatch(generateDocument(reviewToken, documentTypeToUrlParam(documentType)))

    if (document.success) {
      // Start polling until the document is ready
      const pollUrl = document.presignedHeadUrl

      // Changed the maxRetries to 180 sconds to support long running document generation, in addition to new
      // error message in case the document is not available in s3 just yet.
      const { success } = await poll(() => fetchHeadStatus(pollUrl), 180)

      if (success) {
        return document.presignedGetUrl
      }
    }

    throw new Error('Failed to generate document')
  }
}

import { gql } from '@apollo/client'

import { RecipePreview } from 'components/pages/automations/RecipePreview'

import RecipeCard from '../RecipeCard'

export const AUTOMATION_RULE_TEMPLATE_CATEGORIES = gql`
  query AutomationRuleTemplateCategories {
    automationRuleTemplateCategories {
      token
      name
    }
  }
`

export const EXPLORE_AUTOMATIONS = gql`
  query ExploreAutomations {
    automationRuleTemplates(enabledOnly: true) {
      ... on AutomationRuleTemplateBase {
        name
        automationType
        actionType
        slug
        automationTemplateCategory {
          token
          name
          style
          slug
        }
        ...RecipeCardAutomationRuleTemplate
        ...RecipePreviewAutomationRuleTemplate
      }
      ... on TriggeredRuleTemplate {
        domainType
      }
      ... on ScheduledRuleTemplate {
        actionType
      }
      ... on ScheduledWithTriggerRuleTemplate {
        domainType
      }
    }
  }
  ${RecipeCard.fragments.template}
  ${RecipePreview.fragments.template}
`

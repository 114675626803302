import { useState } from 'react'

import { CancelDialogBody } from 'components/cases/report/dialogs/CancelDialogBody'
import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionTypeEnum } from 'types/api'

export const BatchCancelDialog: DialogComponent = (props) => {
  const { children, ...dialogProps } = props

  const [reason, setReason] = useState<string>('')

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Cancel}
      batchActionParams={{ reason }}
      confirmEnabled={!!reason}
      title="Cancel reviews"
      confirmText="Yes, cancel reviews."
    >
      <CancelDialogBody setReason={setReason} reason={reason} batch>
        {children}
      </CancelDialogBody>
    </BaseBatchActionDialog>
  )
}

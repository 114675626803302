import React from 'react'

import { gql } from '@apollo/client'

import { omit } from 'lodash'
import { nanoid } from 'nanoid'

import { setFlashError } from 'actions/errorActions'
import { addPendingOperation, clearActiveEntities, removePendingOperation, setLoading } from 'actions/viewActions'

import { getCaseLock } from 'components/cases/hooks/useCaseLock'

import { dataTypeToGqlType, LibrarySubjectTypeName } from 'components/entities/LibraryQueries'
import { createLibraryDataLoadingPrefixSerializer } from 'helpers/loadingTokenHelpers'
import { canNavToLocation, locationTypeToDataType } from 'helpers/locations'
import { getOrganizationToken } from 'helpers/stateHelpers'
import { displayAddress } from 'helpers/uiHelpers'

import {
  Connection,
  FinancialInstitutionBranch,
  Investigation,
  Location,
  LocationState,
} from 'reducers/investigationsReducer.types'
import { getTabId, openTab } from 'reducers/tabReducer'
import { Alert } from 'types/alert'
import { KeyboardOrMouseEvent, OPTION_NO, OPTION_YES, OtherInfo } from 'types/hb'
import { navigateToInternalUrl } from 'utils/navigationHelpers'

import { serializeQuery } from 'utils/query.serializer'

import {
  InvestigationBusinessCaseDataFragment,
  InvestigationFinancialInstitutionBranchFragment,
  InvestigationFinancialInstitutionCaseDataFragment,
  InvestigationPersonCaseDataFragment,
  LibraryObjectPageFetchQuery,
} from '../components/entities/LibraryObject/__generated__/LibraryObject.queries.generated'

import {
  CreateInvestigationMutation,
  CreateInvestigationMutationVariables,
} from './__generated__/investigationsActions.generated'
import {
  GetCaseActionsOverviewQuery,
  GetCaseActionsOverviewQueryVariables,
} from './__generated__/investigationsActions.queries.generated'
import { formatFormikObject } from './importingActions'
import { SnakeDataTypeKey } from './importingFields.types'
import { CASE_ACTIONS_OVERVIEW_QUERY } from './investigationsActions.queries'
import { navigateToCaseWithReview } from './reviewsActions'
import { AsyncThunk, Thunk } from './store'

export const SET_INVESTIGATION_LOCATIONS = 'SET_INVESTIGATION_LOCATIONS'
export const SET_INVESTIGATION_CONNECTIONS = 'SET_INVESTIGATION_CONNECTIONS'
export const UPDATE_INVESTIGATION = 'UPDATE_INVESTIGATION'
export const RECORD_INVESTIGATION_NOT_FOUND = 'RECORD_INVESTIGATION_NOT_FOUND'

type Token = string

export function setInvestigationLocations(token: string, locations: LocationState) {
  return { type: SET_INVESTIGATION_LOCATIONS, token, locations }
}

export function setInvestigationConnections(token: string, connections: Connection) {
  return {
    type: SET_INVESTIGATION_CONNECTIONS,
    token,
    connections: {
      caseIsTooLarge: connections.caseIsTooLarge,
      edges: connections.edges ?? [],
      nodes: connections.nodes ?? [],
    },
  }
}

export function updateInvestigation(investigation: Investigation) {
  return { type: UPDATE_INVESTIGATION, investigation }
}

export function recordInvestigationNotFound(token: Token) {
  return { type: RECORD_INVESTIGATION_NOT_FOUND, token }
}

// Navigate to an investigation page.
export function navigateToInvestigation(
  event: KeyboardOrMouseEvent | undefined,
  investigationToken: Token
): Thunk<Promise<boolean>> {
  return (dispatch, _getState, _props) => {
    dispatch(clearActiveEntities())
    dispatch(
      navigateToInternalUrl(event, `/dashboard/cases/:token`, {
        token: investigationToken,
      })
    )

    return Promise.resolve(true)
  }
}

// Navigate to an investigation page with an information request
export function navigateToInformationRequest({
  event,
  requestToken,
  caseToken,
}: {
  event?: KeyboardOrMouseEvent
  requestToken: string
  caseToken: string
}): Thunk<void> {
  return (dispatch) => {
    const activeTabId = getTabId({
      type: 'informationRequest' as const,
      requestToken,
    })

    const query = serializeQuery('case')({
      tabInfo: {
        activeTabId,
        tabs: [
          {
            type: 'informationRequest',
            requestToken,
          },
          {
            type: 'informationRequests' as const,
            pinned: true,
          },
        ],
      },
    })

    return dispatch(
      navigateToInternalUrl(event, `/dashboard/cases/:caseToken?${query}`, {
        caseToken,
      })
    )
  }
}

export function navigateToInboundRequest({
  event,
  inboundToken,
  caseToken,
  reviewToken,
}: {
  event?: KeyboardOrMouseEvent
  inboundToken: string
  caseToken: string
  reviewToken: string
}): Thunk<void> {
  return (dispatch) => {
    const activeTabId = getTabId({
      type: 'inboundRequest' as const,
      inboundToken,
    })

    const query = serializeQuery('case')({
      tabInfo: {
        activeTabId,
        tabs: [
          {
            type: 'inboundRequest' as const,
            inboundToken,
          },
          {
            type: 'review' as const,
            reviewToken,
            pinned: true,
          },
        ],
      },
    })

    return dispatch(
      navigateToInternalUrl(event, `/dashboard/cases/:caseToken?${query}`, {
        caseToken,
      })
    )
  }
}

export function navigateToCaseAction(
  investigationToken: string,
  action: string,
  event?: React.MouseEvent
): Thunk<void> {
  return (dispatch, getState, { pilot }) =>
    dispatch(
      pilot.go(
        '/dashboard/cases/:token/:action',
        {
          token: investigationToken,
          action,
        },
        {},
        event
      )
    )
}

interface LoadInvestigationProps {
  token: string
  loadingToken?: string
}
export function loadInvestigation(props: LoadInvestigationProps): AsyncThunk<Investigation> {
  const { token, loadingToken } = props
  return (dispatch, getState, { api, pilot, gqlClient }) => {
    // This action is frequently dispatched from within a "loading" block (i.e. a block of code
    // surrounded by setLoading(true) and setLoading(false). In that case, we don't want to trigger
    // loading here, so we allow the caller to dictate wither we should trigger loading state
    // by conditioning it on the presence of loadingToken.
    if (loadingToken) {
      dispatch(setLoading(true, loadingToken))
    }

    const state = getState()

    return api
      .get('apiOrganizationInvestigationPath', {
        urlParams: {
          organizationToken: getOrganizationToken(state),
          token,
        },
      })
      .then((json: any) => {
        if (json.success) {
          const result = json.investigation

          // Refetch the case files tab.
          // Necessary if we're performing REST case updates (e.g. deleting an attachment)
          // that do not update the GQL cache.
          // This behavior should be removed as we switch to GQL for the case page.
          gqlClient.refetchQueries({
            include: [
              'GetCaseFiles',
              'GetPeople',
              'GetBusinesses',
              'GetFinancialInstitutions',
              'GetProducts',
              'GetSecondaryCaseOverviewSubjects',
              'CaseReviewTabReview', // If there's a case review tab open, refetch it
            ],
          })
          return gqlClient
            .query<GetCaseActionsOverviewQuery, GetCaseActionsOverviewQueryVariables>({
              // eslint-disable-next-line @typescript-eslint/no-use-before-define
              query: CASE_ACTIONS_OVERVIEW_QUERY,
              variables: {
                caseToken: token,
              },
              fetchPolicy: 'network-only', // this is effectively a refresh; get the latest!
            })
            .then(() => {
              dispatch(updateInvestigation(result))
              return result
            })
            .finally(() => {
              if (loadingToken) {
                dispatch(setLoading(false, loadingToken))
              }
            })
        }

        if (json.status === 500) {
          dispatch(setFlashError("Sorry, something went wrong. We've been notified and are looking into it."))
          dispatch(pilot.go('/dashboard'))
        } else {
          dispatch(setFlashError(json.notice || 'Sorry, we could not find that case.'))
          dispatch(recordInvestigationNotFound(token))
        }

        return null
      })
  }
}

type CreateInvestigationData = {
  name: string
  review: {
    reviewTypeCanonicalId: string
    queueToken?: string
    alerts?: Alert[]
    otherInfo?: OtherInfo
  }
}

const CREATE_INVESTIGATION_MUTATION = gql`
  mutation CreateInvestigation($input: CreateInvestigationInput!) {
    createInvestigation(input: $input) {
      investigation {
        token
        reviews {
          token
        }
      }
    }
  }
`

export function startInvestigation(params: CreateInvestigationData): AsyncThunk<void> {
  return async (dispatch, _getState, { gqlClient }) => {
    dispatch(setLoading(true, 'dashboards'))

    const { errors, data } = await gqlClient.mutate<CreateInvestigationMutation, CreateInvestigationMutationVariables>({
      mutation: CREATE_INVESTIGATION_MUTATION,
      variables: {
        input: params,
      },
      errorPolicy: 'all',
    })

    dispatch(setLoading(false, 'dashboards'))

    if (data?.createInvestigation) {
      const reviewToken = data.createInvestigation.investigation.reviews[0]?.token
      dispatch(navigateToCaseWithReview({ caseToken: data.createInvestigation.investigation.token, reviewToken }))
    } else if (errors) {
      dispatch(setFlashError(errors.map((e) => e.message).join(', ')))
    }
  }
}

export function fetchInvestigationLocations(token: string): Thunk<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'locations'))
    return api
      .get('apiInvestigationLocationsPath', {
        urlParams: {
          investigationToken: token,
          organizationToken: getOrganizationToken(getState()),
        },
      })
      .then((json: any) => {
        dispatch(setLoading(false, 'locations'))
        if (json.success) {
          dispatch(setInvestigationLocations(token, json.locations))
        }
        // TODO(rg): Figure out error condition
      })
  }
}

export function fetchInvestigationConnections(token: string): Thunk<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'connections'))
    return api
      .get('apiInvestigationConnectionsPath', {
        urlParams: {
          investigationToken: token,
          organizationToken: getOrganizationToken(getState()),
        },
      })
      .then((json: any) => {
        dispatch(setLoading(false, 'connections'))
        if (json.success) {
          dispatch(setInvestigationConnections(token, json.connections))
        }
        // TODO(rg): Figure out error condition
      })
  }
}

export function getAttachingKey(investigationToken: string, dataType: SnakeDataTypeKey) {
  return `${investigationToken}-attaching-${dataType}`
}

export type InstitutionLinkingFormData = {
  roleInTransaction?: string
  internalControlNumber?: string
  lossToInstitution?: string
  libraryFinancialInstitutionToken: Token
  branches?: FinancialInstitutionBranch[]
}

type PersonLinkingFormData = {
  roleInActivity?: string
  roleInActivityOther?: string
  corroborativeStatement?: string
  libraryPersonToken: Token
}

type BusinessLinkingFormData = {
  libraryBusinessToken: Token
}

type ProductLinkingFormData = {
  libraryProductToken: Token
}

type BankAccountLinkingFormData = {
  libraryBankAccountToken: Token
}
type PaymentCardLinkingFormData = {
  libraryPaymentCardToken: Token
}
type CryptoAddressLinkingFormData = {
  libraryCryptoAddressToken: Token
}
type DeviceLinkingFormData = {
  libraryDeviceToken: Token
}

export type LibraryLinkingFormData =
  | InstitutionLinkingFormData
  | PersonLinkingFormData
  | BusinessLinkingFormData
  | ProductLinkingFormData
  | BankAccountLinkingFormData
  | PaymentCardLinkingFormData
  | CryptoAddressLinkingFormData
  | DeviceLinkingFormData
  | Record<string, never>

export function getLibraryTokenAsFormFieldData(
  libraryToken: string,
  type?: LibrarySubjectTypeName
): LibraryLinkingFormData {
  switch (type) {
    case 'LibraryPerson':
      return { libraryPersonToken: libraryToken }
    case 'LibraryBusiness':
      return { libraryBusinessToken: libraryToken }
    case 'LibraryProduct':
      return { libraryProductToken: libraryToken }
    case 'LibraryFinancialInstitution':
      return { libraryFinancialInstitutionToken: libraryToken }
    case 'LibraryBankAccount':
      return { libraryBankAccountToken: libraryToken }
    case 'LibraryCryptoAddress':
      return { libraryCryptoAddressToken: libraryToken }
    case 'LibraryPaymentCard':
      return { libraryPaymentCardToken: libraryToken }
    case 'LibraryDevice':
      return { libraryDeviceToken: libraryToken }
    default:
      return {}
  }
}

type LibraryEntity = NonNullable<LibraryObjectPageFetchQuery['libraryByToken']>

export function getCaseBindingData(entity: LibraryEntity): LibraryLinkingFormData {
  if (
    entity.__typename === 'LibraryFinancialInstitution' ||
    entity.__typename === 'LibraryPerson' ||
    entity.__typename === 'LibraryBusiness'
  ) {
    if (entity.relatedCaseSubject) {
      if (entity.relatedCaseSubject.__typename === 'InvestigationSubjectFinancialInstitution') {
        const fi = entity.relatedCaseSubject as InvestigationFinancialInstitutionCaseDataFragment
        const branches: FinancialInstitutionBranch[] =
          fi.branches?.map((branch: InvestigationFinancialInstitutionBranchFragment) => ({
            externalId: branch.externalId ?? '',
            roleInTransaction: branch.roleInTransaction ?? '',
            token: branch.token ?? '',
            rssdNumber: branch.rssdNumber ?? '',
            // For the concatenated address in the case data panel
            address: displayAddress(branch.address),
            // For populating the edit/add address modal
            addressLine1: branch.address.addressLine1 ?? '',
            addressLine2: branch.address.addressLine2 ?? '',
            locality: branch.address.locality ?? '',
            administrativeDistrictLevel1: branch.address.administrativeDistrictLevel1 ?? '',
            country: branch.address.country ?? '',
            postalCode: branch.address.postalCode ?? '',
            // sublocality is not presented in the modal, but it's required as part of type FinancialInstitutionBranch
            sublocality: '',
          })) ?? []
        return {
          roleInTransaction: fi.roleInTransaction ?? undefined,
          internalControlNumber: fi.internalControlNumber ?? undefined,
          lossToInstitution: fi.lossToInstitution ?? undefined,
          libraryFinancialInstitutionToken: entity.token,
          branches,
        }
      }
      if (entity.relatedCaseSubject.__typename === 'InvestigationSubjectPerson') {
        const person = entity.relatedCaseSubject as InvestigationPersonCaseDataFragment
        return {
          roleInActivity: person.roleInActivity ?? undefined,
          roleInActivityOther: person.roleInActivityOther ?? undefined,
          corroborativeStatement: person.corroborativeStatement ? OPTION_YES : OPTION_NO,
          libraryPersonToken: entity.token,
        }
      }
      if (entity.relatedCaseSubject.__typename === 'InvestigationSubjectBusiness') {
        const business = entity.relatedCaseSubject as InvestigationBusinessCaseDataFragment
        return {
          roleInActivity: business.roleInActivity ?? undefined,
          roleInActivityOther: business.roleInActivityOther ?? undefined,
          libraryBusinessToken: entity.token,
        }
      }
    }
  }
  return getLibraryTokenAsFormFieldData(entity.token, entity.__typename)
}

function getAttachCallParamsForDataType(
  dataType: SnakeDataTypeKey,
  dataToken: string | undefined,
  attachFormData: LibraryLinkingFormData
) {
  if (dataType === 'institution') {
    const formData = formatFormikObject({
      ...attachFormData,
      token: dataToken,
    })
    const formDataWithComputedAddressStripped = {
      ...formData,
    }
    if (formDataWithComputedAddressStripped.branches) {
      formDataWithComputedAddressStripped.branches = formDataWithComputedAddressStripped.branches.map(
        (branch: FinancialInstitutionBranch) => omit(branch, 'address')
      )
    }

    return {
      urlName: 'upsertApiFinancialInstitutionsPath',
      formDataKey: 'institution',
      formData: formDataWithComputedAddressStripped,
    }
  }
  if (dataType === 'individual') {
    return {
      urlName: 'upsertApiIndividualEntitiesPath',
      formDataKey: 'individual',
      formData: formatFormikObject({
        ...attachFormData,
        token: dataToken,
        corroborativeStatement: (attachFormData as PersonLinkingFormData).corroborativeStatement === OPTION_YES,
      }),
    }
  }
  if (dataType === 'business') {
    return {
      urlName: 'upsertApiBusinessEntitiesPath',
      formDataKey: 'business',
      formData: formatFormikObject({
        ...attachFormData,
        token: dataToken,
      }),
    }
  }
  if (dataType === 'product') {
    return {
      urlName: 'upsertApiProductsPath',
      formDataKey: 'products',
      formData: [
        formatFormikObject({
          ...attachFormData,
          token: dataToken,
        }),
      ],
    }
  }
  if (dataType === 'bank_account') {
    return {
      urlName: 'upsertApiBankAccountsPath',
      formDataKey: 'bankAccounts',
      formData: [
        formatFormikObject({
          ...attachFormData,
          token: dataToken,
        }),
      ],
    }
  }
  if (dataType === 'payment_card') {
    return {
      urlName: 'upsertApiPaymentCardsPath',
      formDataKey: 'paymentCards',
      formData: [
        formatFormikObject({
          ...attachFormData,
          token: dataToken,
        }),
      ],
    }
  }
  if (dataType === 'crypto_address') {
    return {
      urlName: 'upsertApiCryptoAddressesPath',
      formDataKey: 'cryptoAddresses',
      formData: [
        formatFormikObject({
          ...attachFormData,
          token: dataToken,
        }),
      ],
    }
  }
  if (dataType === 'device') {
    return {
      urlName: 'upsertApiDevicesPath',
      formDataKey: 'devices',
      formData: [
        formatFormikObject({
          ...attachFormData,
          token: dataToken,
        }),
      ],
    }
  }

  return null
}

const getLibraryDataFromResponse = (dataType: SnakeDataTypeKey, res: any) => {
  if (!res) return null
  switch (dataType) {
    case 'bank_account':
      return res.bankAccounts[0]
    case 'business':
      return res.entity
    case 'crypto_address':
      return res.cryptoAddresses[0]
    case 'device':
      return res.devices[0]
    case 'individual':
      return res.entity
    case 'institution':
      return res.financialInstitution
    case 'payment_card':
      return res.paymentCards[0]
    case 'product':
      return res.products[0]
    default:
      return null
  }
}

export const getAttachLibraryDataToInvestigationLoadingToken = (investigationToken: Token, loadingId = nanoid()) =>
  `${createLibraryDataLoadingPrefixSerializer.serialize(investigationToken)}${loadingId}`

export function attachLibraryDataToInvestigation(
  investigationToken: Token,
  dataToken: Token | undefined,
  dataType: SnakeDataTypeKey,
  attachFormData: LibraryLinkingFormData,
  loadingId = nanoid()
): AsyncThunk<{ token: string } | null> {
  return async (dispatch, _getState, { api, gqlClient }) => {
    const loadingToken = getAttachLibraryDataToInvestigationLoadingToken(investigationToken, loadingId)

    const { isLocked } = await getCaseLock(gqlClient, investigationToken)
    if (isLocked) {
      return null
    }

    let opKey = ''
    if (!dataToken) {
      // If we're attaching a new item, track the operation
      opKey = getAttachingKey(investigationToken, dataType)
      dispatch(addPendingOperation(opKey))
    }

    const params = getAttachCallParamsForDataType(dataType, dataToken, attachFormData)
    if (!params) {
      dispatch(setFlashError('Entity type is not supported.'))
      return null
    }

    const { urlName, formDataKey, formData } = params
    dispatch(setLoading(true, loadingToken))

    const res = await api.post(urlName, {
      data: {
        investigationToken,
        [formDataKey]: formData,
      },
    })

    const { success, error } = res
    if (success) {
      await dispatch(loadInvestigation({ token: investigationToken }))
    }

    if (!dataToken) {
      dispatch(removePendingOperation(opKey))
    }

    if (error) {
      dispatch(setFlashError(error.message))
    }
    dispatch(setLoading(false, loadingToken))
    return getLibraryDataFromResponse(dataType, res)
  }
}

export function openTabForLocation(location: Location): Thunk<void> {
  return async (dispatch) => {
    if (canNavToLocation(location.type)) {
      dispatch(
        openTab({
          tab: {
            type: 'library',
            entityToken: location.libraryToken,
            entityType: dataTypeToGqlType(locationTypeToDataType(location.type)),
            linkToken: location.token,
            tab: 'information',
          },
        })
      )
    }
  }
}

import { unionBy } from 'lodash'
import { AnyAction } from 'redux'

import * as actions from 'actions/notificationsActions'
import { BasicAccount } from 'types/hb'

export interface Notification {
  token: string
  text: string
  createdAt: string
  // Can be a string or a number depending on whether the client
  // or the server populated it
  actionedAt: string | number
  actionPath: string
  sender: BasicAccount
}

export interface NotificationsState {
  all: Notification[]
  fetchingStarted: boolean
}

const getInitialState = (): NotificationsState => ({
  all: [],
  fetchingStarted: false,
})

const notificationsReducer = (state = getInitialState(), action: AnyAction): NotificationsState => {
  switch (action.type) {
    case actions.SET_NOTIFICATIONS: {
      const byToken = (notification: Notification) => notification.token
      return {
        ...state,
        all: unionBy(action.notifications, state.all, byToken),
      }
    }
    case actions.MARK_NOTIFICATION_ACTIONED: {
      const newNotifications = state.all.slice()
      const notification = newNotifications.find((n) => n.token === action.notification.token)
      if (!notification) {
        return state
      }

      notification.actionedAt = Date.now()

      return { ...state, all: newNotifications }
    }
    case actions.SET_FETCHING_STARTED: {
      return { ...state, fetchingStarted: true }
    }
    default: {
      return state
    }
  }
}

export default notificationsReducer

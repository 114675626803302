import React, { ReactNode, useMemo } from 'react'

interface FormConfigurationContextValue {
  contextName: string
  adornmentRedesign?: boolean
  readOnly?: boolean
}

export const FormConfigurationContext = React.createContext<FormConfigurationContextValue>({
  contextName: '',
  readOnly: false,
})

export interface Props {
  contextName: string
  readOnly?: boolean
  adornmentRedesign?: boolean
  children: ReactNode
}

export function FormConfiguration(props: Props) {
  const { contextName, readOnly, adornmentRedesign, children } = props

  const value = useMemo(
    () => ({
      contextName,
      adornmentRedesign,
      readOnly,
    }),
    [adornmentRedesign, contextName, readOnly]
  )

  return <FormConfigurationContext.Provider value={value}>{children}</FormConfigurationContext.Provider>
}

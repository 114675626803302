import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const RemoveFileIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 9 9">
    <path d="M8.85.85l-.7-.7L4.5 3.79.85.15l-.7.7L3.79 4.5.15 8.15l.7.7L4.5 5.21l3.65 3.64.7-.7L5.21 4.5 8.85.85z" />
  </SvgIcon>
)

export default RemoveFileIcon

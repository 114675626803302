export class TimeSpentTimer {
  private startTime = -1
  private _elapsedTime = 0 // in seconds

  public get elapsedTime(): number {
    return this._elapsedTime
  }

  public reset() {
    this.startTime = -1
    this._elapsedTime = 0
  }

  public start() {
    if (this.startTime === -1) {
      this.startTime = performance.now()
    }

    return this
  }

  public stop() {
    if (this.startTime === -1) {
      return this
    }

    this._elapsedTime += (performance.now() - this.startTime) / 1000
    this.startTime = -1
    return this
  }
}

import { useState } from 'react'

import { Typography, TextField } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'
import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { useGetSelectedInvestigationRowsInformation } from 'components/entities/hooks/UseGetIsInvestigationRowSelected'
import { useGetSelectedRowsInformation } from 'dashboards/reviews/hooks/useGetIsRowSelected'
import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { NoUndoWarning } from 'dashboards/shared/components/BatchActionsMenu/NoUndoWarning'
import { DashboardEntry } from 'reducers/reviewsReducer'
import { BatchActionTypeEnum } from 'types/api'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  headline: {
    fontWeight: 400,
    color: theme.palette.styleguide.mediumGray2,
    marginBottom: theme.spacing(),
    marginTop: theme.spacing(3),
    fontSize: 12,
  },
  list: {
    paddingLeft: theme.spacing(2.5),
  },
  listItem: {
    listStyle: 'square',
  },
  moreItemsText: {
    fontStyle: 'italic',
  },
  input: {
    marginBottom: theme.spacing(1),
    '& fieldset': {
      top: '-4.5px',
    },
  },
}))

const BaseBatchMergeDialog: DialogComponent<{
  selectedRows: unknown[]
  numBatchSelectedNotOnPage: number
  getKey: (row: unknown) => string
  renderName: (row: unknown) => string
  supplementaryText?: string
}> = (props) => {
  const { selectedRows, numBatchSelectedNotOnPage, getKey, renderName, supplementaryText = '', ...dialogProps } = props
  const classes = useStyles()

  const [step, setStep] = useState<0 | 1>(0)
  const [caseName, setCaseName] = useState('')

  return [
    <BaseBatchActionDialog
      key={0}
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Merge}
      confirmEnabled
      title="Are you sure you want to merge?"
      primaryText="Please confirm that you want to merge these cases. Any unsaved changes in the cases will be discarded."
      confirmText="Yes, merge cases"
      onConfirm={() => setStep(1)}
    >
      {supplementaryText && (
        <Typography variant="body2" color="textPrimary" paragraph>
          {supplementaryText}
        </Typography>
      )}
      <NoUndoWarning />
      <Typography className={classes.headline}> Cases </Typography>
      <ul className={classes.list}>
        {selectedRows.map((row) => (
          <li className={classes.listItem} key={getKey(row)}>
            {renderName(row)}
          </li>
        ))}
        {numBatchSelectedNotOnPage > 0 && (
          <li key="num-batch-selected" className={classnames(classes.listItem, classes.moreItemsText)}>
            and {numBatchSelectedNotOnPage} more...
          </li>
        )}
      </ul>
    </BaseBatchActionDialog>,
    <BaseBatchActionDialog
      key={1}
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Merge}
      confirmEnabled
      title="Rename your merged cases"
      confirmText="Save"
      batchActionParams={{ caseName }}
      shouldSetRecentlyUpdatedItems
    >
      <TextField
        variant="outlined"
        fullWidth
        placeholder='e.g. "Merged Case 1 + 2"'
        value={caseName}
        onChange={(e) => setCaseName(e.target.value)}
        className={classes.input}
      />
      <HbText>Or save without renaming for a default name.</HbText>
    </BaseBatchActionDialog>,
  ][step]
}

const reviewKeyGetter = (row: DashboardEntry) => row.reviewToken
const reviewNameRenderer = (row: DashboardEntry) => row.investigationName || ''
const BatchMergeReviewsDialog: DialogComponent = (props) => {
  const { selectedRows, numBatchSelectedNotOnPage } = useGetSelectedRowsInformation()

  return (
    <BaseBatchMergeDialog
      {...props}
      selectedRows={selectedRows}
      numBatchSelectedNotOnPage={numBatchSelectedNotOnPage}
      getKey={reviewKeyGetter}
      renderName={reviewNameRenderer}
    />
  )
}

const investigationKeyGetter = (row: { name: string; token: string }) => row.token
const investigationNameRenderer = (row: { name: string; token: string }) => row.name || ''

type BatchMergeComponent = DialogComponent<
  { libraryEntityType: LibraryGqlQueryType; libraryEntityToken: string },
  'review' | 'investigation'
>

const BatchMergeInvestigationsDialog: BatchMergeComponent = (props) => {
  const { libraryEntityToken, libraryEntityType, ...rest } = props

  const { selectedRows, numBatchSelectedNotOnPage } = useGetSelectedInvestigationRowsInformation(
    libraryEntityType,
    libraryEntityToken
  )

  return (
    <BaseBatchMergeDialog
      {...rest}
      selectedRows={selectedRows}
      numBatchSelectedNotOnPage={numBatchSelectedNotOnPage}
      getKey={investigationKeyGetter}
      renderName={investigationNameRenderer}
      supplementaryText="Once merged, you will automatically be redirected to the merged case."
    />
  )
}

const mergeComponents = {
  review: BatchMergeReviewsDialog,
  investigation: BatchMergeInvestigationsDialog,
}

export const BatchMergeDialog: BatchMergeComponent = (props) => {
  const { entityType } = props
  const MergeDialog = mergeComponents[entityType]

  return MergeDialog ? <MergeDialog {...props} /> : null
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const IncompleteCircleIcon: FC<SvgProps> = (props) => (
  <SvgIcon width="24" height="24" {...props}>
    <path d="M22 12A10 10 0 1 1 5 5l7 7V2a10 10 0 0 1 10 10Z" fill="currentColor" />
  </SvgIcon>
)

export default IncompleteCircleIcon

import { createElement } from 'react'

import classnames from 'classnames'
import { isFunction } from 'lodash'
import PropTypes from 'prop-types'

const ROOT = 'hb-menu-list'
const ITEM_ROOT = `${ROOT}__list-item`

export const MenuListItem = ({
  children,
  placeholder,
  disabled,
  selectable,
  title,
  link,
  className,
  onClick,
  selected,
  testId,
  noXPadding,
  ...otherProps
}) => {
  const classes = classnames(
    ITEM_ROOT,
    {
      [`${ITEM_ROOT}--title`]: title,
      [`${ITEM_ROOT}--link`]: link,
      [`${ITEM_ROOT}--disabled`]: disabled,
      [`${ITEM_ROOT}--placeholder`]: placeholder,
      [`${ITEM_ROOT}--selectable`]: selectable && !title && !placeholder && !link,
      [`${ITEM_ROOT}--selected`]: selected,
      [`${ITEM_ROOT}--no-x-padding`]: noXPadding,
    },
    className
  )

  const clickHandler = (event) => {
    if (!disabled && isFunction(onClick)) {
      return onClick(event)
    }

    return true
  }

  return createElement(
    'li',
    {
      className: classes,
      'data-testid': testId,
      onClick: clickHandler,
      ...otherProps,
    },
    children
  )
}

MenuListItem.propTypes = {
  children: PropTypes.node.isRequired,
  placeholder: PropTypes.bool,
  selectable: PropTypes.bool,
  title: PropTypes.bool,
  link: PropTypes.bool,
  disabled: PropTypes.bool,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  noXPadding: PropTypes.bool,
}

MenuListItem.defaultProps = {
  selectable: true,
  link: false,
  disabled: false,
  placeholder: false,
  title: false,
  selected: false,
  onClick: null,
  noXPadding: false,
}

export default MenuListItem

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import HbTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Theme } from 'types/hb'

import type { FormSchema } from '../formSchema'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    width: '100%',
  },
}))

export default function CustomizeGoogleSheetsAction() {
  const classes = useStyles()

  return (
    <Controller<FormSchema>
      name="actionParams.googleSheetsParams.sheetId"
      render={({ field, fieldState }) => (
        <>
          <HbText bold size="s" color="secondary">
            Sheet Id
          </HbText>
          <HbTextInput
            className={classes.root}
            label="Sheet Id"
            {...field}
            error={Boolean(fieldState.error)}
            hideLabel
          />
        </>
      )}
    />
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const DeviceFingerprintIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 21 20">
    <g>
      <path d="M11 10a1 1 0 0 0-2 0 14.31 14.31 0 0 0 3.24 9.09 1 1 0 1 0 1.55-1.26A12.33 12.33 0 0 1 11 10z" />
      <path d="M16.34 16.23A9.48 9.48 0 0 1 14 10a4 4 0 0 0-8 0 17.41 17.41 0 0 0 2.86 9.53 1 1 0 0 0 1.68-1.09A15.41 15.41 0 0 1 8 10a2 2 0 0 1 4 0 11.41 11.41 0 0 0 2.79 7.48l.09.13a1 1 0 0 0 1.42.17 1 1 0 0 0 .16-1.38z" />
      <path d="M17 10a7 7 0 0 0-14 0 20.3 20.3 0 0 0 1.79 8.32 1 1 0 0 0 .91.59 1 1 0 0 0 .41-.09 1 1 0 0 0 .51-1.32A18.31 18.31 0 0 1 5 10a5 5 0 0 1 10 0 8.51 8.51 0 0 0 1.88 5.3 1 1 0 1 0 1.56-1.3A6.5 6.5 0 0 1 17 10z" />
      <path d="M20.55 11.85A3.46 3.46 0 0 1 20 10a10 10 0 0 0-20 0 23.41 23.41 0 0 0 .76 5.87 1 1 0 0 0 1 .75 1 1 0 0 0 .25 0 1 1 0 0 0 .72-1.22A21.43 21.43 0 0 1 2 10a8 8 0 0 1 16 0 5.44 5.44 0 0 0 .87 2.92 1 1 0 1 0 1.69-1.07z" />
    </g>
  </SvgIcon>
)

export default DeviceFingerprintIcon

import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { ScreenOverlayPortal } from 'components/library/Portals/ScreenOverlayPortal'
import { opacify } from 'helpers/colors'
import { useBlockPageScrollEffect } from 'hooks/BlockPageScrollEffect'
import { Theme } from 'types/hb'

export const ATTACHMENT_OVERLAY_Z_INDEX = 1000

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'fixed',
    zIndex: ATTACHMENT_OVERLAY_Z_INDEX,
    backgroundColor: opacify(theme.palette.background.dark, 0.9),
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}))

interface Props {
  children: ReactNode
}

export function AttachmentContainer({ children }: Props) {
  const { root } = useStyles()
  useBlockPageScrollEffect()

  return (
    <ScreenOverlayPortal>
      <div className={root}>{children}</div>
    </ScreenOverlayPortal>
  )
}

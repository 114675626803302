import { useMemo } from 'react'

import { BatchActionTypeEnum } from 'types/api'

import actionMap from '../BatchActionsMenu/batchActionMap'

import { useGetAllowedProfileTypesForMerging } from './useGetAllowedProfileTypesForMerging'

/**
 * Gets supported batch actions for the profiles dashboard
 */
export const useAvailableBatchActions = () => {
  const { hasEnabledTypesForMerging } = useGetAllowedProfileTypesForMerging()
  const availableBatchActions = useMemo(() => {
    const actions: Set<BatchActionTypeEnum> = new Set(Object.keys(actionMap))
    if (!hasEnabledTypesForMerging) actions.delete(BatchActionTypeEnum.MergeLibraryProfiles)
    return actions
  }, [hasEnabledTypesForMerging])
  const hasAvailableBatchActions = !!availableBatchActions.size
  return { availableBatchActions, hasAvailableBatchActions }
}

import { useEffect } from 'react'

import { gql, useQuery } from '@apollo/client'

import { useSnackbar } from 'notistack'

import { useDispatch, useSelector } from 'actions/store'
import { usePrevious } from 'hooks'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'

import { BatchActionStatusQuery, BatchActionStatusQueryVariables } from './__generated__/useBatchActions.generated'

export const BATCH_ACTION_STATUS_QUERY = gql`
  query BatchActionStatus($bid: ID!) {
    batchActionStatus(bid: $bid) {
      complete
      failures
      failureMessage
    }
  }
`

const PROGRESS_SNACKBAR_ID = 'batch-action-snackbar-progress'

export function useRefetchOnBatchActionComplete(refetch: () => void): void {
  const batchActionJobId = useSelector(batchActionsSelectors.mostRecentBatchJobId)
  const prevBatchActionJobId = usePrevious(batchActionJobId)

  useEffect(() => {
    if (prevBatchActionJobId && !batchActionJobId) {
      refetch()
    }
  }, [refetch, batchActionJobId, prevBatchActionJobId])
}

export function useBatchActionsSnackbars() {
  const dispatch = useDispatch()
  const batchJobId = useSelector(batchActionsSelectors.mostRecentBatchJobId)
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const { data } = useQuery<BatchActionStatusQuery, BatchActionStatusQueryVariables>(BATCH_ACTION_STATUS_QUERY, {
    variables: {
      bid: batchJobId as string, // The 'skip' parameter guarantees this only executes when defined
    },
    pollInterval: 500,
    skip: !batchJobId,
  })

  const previousComplete = usePrevious(data?.batchActionStatus?.complete)
  const complete = data?.batchActionStatus?.complete
  const failures = data?.batchActionStatus?.failures
  const failureMessage = data?.batchActionStatus?.failureMessage || ''

  useEffect(() => {
    if (!previousComplete && complete && batchJobId) {
      dispatch(batchActionsActions.jobStatus.clear())
    }
  }, [dispatch, previousComplete, complete, batchJobId, failures, closeSnackbar])

  useEffect(() => {
    if (batchJobId) {
      enqueueSnackbar('Batch Action in Progress', {
        persist: true,
        key: PROGRESS_SNACKBAR_ID,
        testId: PROGRESS_SNACKBAR_ID,
      })
    } else {
      closeSnackbar(PROGRESS_SNACKBAR_ID)
    }

    if (complete && failures) {
      const allowedFailureMessages = ['Source is in too many investigations/reviews to merge.']
      const snackbarMessage = allowedFailureMessages.includes(failureMessage) ? failureMessage : 'Batch Action Error'

      enqueueSnackbar(snackbarMessage, {
        variant: 'error',
        testId: 'batch-action-snackbar-failure',
      })
    }

    if (complete && !failures) {
      enqueueSnackbar('Batch Action Complete', {
        variant: 'success',
        testId: 'batch-action-snackbar-complete',
        autoHideDuration: 3_000,
      })
    }
  }, [batchJobId, failures, complete, failureMessage, enqueueSnackbar, closeSnackbar])
}

// Based on https://github.com/facebook/lexical/blob/fc5c5b13b070fc747e0ee549453243fed792f65b/packages/lexical-playground/src/nodes/MentionNode.ts
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from 'lexical'

import { Theme } from 'types/hb'

import type { Spread } from 'lexical'

export type SerializedMentionNode = Spread<
  {
    value: string
    type: 'mention'
    version: 1
  },
  SerializedTextNode
>

export function $createMentionNode(value: string): MentionNode {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const mentionNode = new MentionNode(`@${value}`)
  // token - acts as immutable node, can't change its content and is deleted all at once
  // inert - similar to token, but also set contenteditable=false so can't put cursor inside or partially select it
  // segmented - its content deleted by segments (one word at a time), it is editable although node becomes non-segmented once its content is updated
  mentionNode.setMode('token')
  return mentionNode
}

function convertMentionElement(domNode: HTMLSpanElement): DOMConversionOutput {
  // `HTMLSpanElements`s which represent mentions contain the '@' character, let's slice it off
  const node = $createMentionNode(domNode.textContent?.slice(1) || '')
  return {
    node,
  }
}

export const useMentionClasses = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'rgba(41, 121, 255, 0.05)',
    color: theme.palette.styleguide.blue,
    borderRadius: '2px',
    paddingLeft: '2px',
    paddingRight: '2px',
  },
}))

export default class MentionNode extends TextNode {
  __value: string

  static getType(): string {
    return 'mention'
  }

  static clone(node: MentionNode): MentionNode {
    return new MentionNode(node.__value, node.__text, node.__key)
  }

  static importJSON(serializedNode: SerializedMentionNode): MentionNode {
    const node = $createMentionNode(serializedNode.value)
    node.setTextContent(serializedNode.text)
    node.setFormat(serializedNode.format)
    node.setDetail(serializedNode.detail)
    node.setMode(serializedNode.mode)
    node.setStyle(serializedNode.style)
    return node
  }

  constructor(value: string, text?: string, key?: NodeKey) {
    super(text ?? value, key)
    this.__value = value
  }

  exportJSON(): SerializedMentionNode {
    return {
      ...super.exportJSON(),
      value: this.__value,
      type: 'mention',
      version: 1,
    }
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config)
    if (typeof config.theme.mention === 'string') {
      dom.classList.add(config.theme.mention)
    }
    dom.dataset.hbMention = 'true'
    return dom
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span')
    element.dataset.hbMention = 'true'
    element.textContent = this.__text
    return { element }
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (domNode.dataset.hbMention !== 'true') {
          return null
        }
        return {
          conversion: convertMentionElement,
          priority: 1,
        }
      },
    }
  }

  // eslint-disable-next-line class-methods-use-this
  isTextEntity(): true {
    return true
  }
}

export function $isMentionNode(node: LexicalNode | null | undefined): node is MentionNode {
  return node instanceof MentionNode
}

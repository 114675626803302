import { useEffect } from 'react'

import { Avatar, ButtonBase } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { isInteger, upperCase } from 'lodash'
import { Controller, ControllerFieldState } from 'react-hook-form'

import NumberFormat, { NumberFormatValues, SourceInfo } from 'react-number-format'

import invariant from 'tiny-invariant'

import HbTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'

import { HbAlert } from 'components/HbComponents/HbAlert'
import { Theme } from 'types/hb'

import { ScheduleSchemaReturnType } from '../formSchema'

const useStyles = makeStyles((theme: Theme) => ({
  weekContainer: {
    display: 'flex',
    alignItems: 'center',
    columnGap: theme.spacing(),
  },
  dayButton: {
    borderRadius: '50%',
  },
  day: {
    color: theme.palette.text.primary,
    backgroundColor: theme.palette.background.medium,
  },
  daySelected: {
    // Active styles
    backgroundColor: theme.palette.action.active,
    color: theme.palette.text.white,
  },
  dayError: {
    margin: 0,
    padding: 0,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
  },
}))

const SCHEDULE_CONFIG_PATH_SLUG = 'scheduleConfig'

interface SharedProps {
  form: ScheduleSchemaReturnType
}

interface CadenceNumberInputProps {
  name: string
  value: string | number | null | undefined
  isAllowed?: (values: NumberFormatValues) => boolean
  onValueChange?: (value: NumberFormatValues, sourceInfo: SourceInfo) => void
  disabled?: boolean
  fieldState: ControllerFieldState
}
const CadenceNumberInput = ({ fieldState, ...props }: CadenceNumberInputProps) => (
  <NumberFormat
    customInput={HbTextInput}
    label=""
    isAllowed={({ floatValue }) => !floatValue || (floatValue >= 1 && isInteger(floatValue))}
    {...props}
    error={fieldState.invalid}
    helperText={fieldState.error?.message}
  />
)

export function CadenceEditor({ form }: SharedProps) {
  const { control } = form

  return (
    <Controller
      name={`${SCHEDULE_CONFIG_PATH_SLUG}.interval`}
      control={control}
      render={({ field, fieldState }) => (
        <CadenceNumberInput
          name="interval"
          value={field.value || null}
          onValueChange={({ floatValue }) => {
            field.onChange(!floatValue ? 0 : floatValue)
          }}
          fieldState={fieldState}
        />
      )}
    />
  )
}

const DAY_OF_WEEK_PATH = `${SCHEDULE_CONFIG_PATH_SLUG}.byday` as const
const EMPTY_DAY_ERROR = 'At least one day is required to enable a weekly automation'
export function DayOfWeekSelector({ form }: SharedProps) {
  const classes = useStyles()

  const { watch, control, getFieldState, setError, clearErrors } = form
  const { byday } = watch(SCHEDULE_CONFIG_PATH_SLUG)
  invariant(byday, 'missing byday')

  const emptySelection = Object.values(byday).every((v) => v === false)

  useEffect(() => {
    if (emptySelection) {
      setError(DAY_OF_WEEK_PATH, {
        type: 'custom',
        message: EMPTY_DAY_ERROR,
      })
    } else if (getFieldState(DAY_OF_WEEK_PATH).error) clearErrors(DAY_OF_WEEK_PATH)
  }, [clearErrors, control, byday, emptySelection, getFieldState, setError])

  if (!byday) return null

  return (
    <div className={classes.weekContainer}>
      {Object.keys(byday).map((key) => (
        <Controller
          key={key}
          name={`${DAY_OF_WEEK_PATH}.${key}`}
          control={control}
          render={({ field }) => (
            <ButtonBase
              className={classes.dayButton}
              onClick={() => {
                const currentKeyVal = byday[key]
                field.onChange(!currentKeyVal)
              }}
            >
              <Avatar className={classNames(classes.day, byday[key] && classes.daySelected)}>{upperCase(key)}</Avatar>
            </ButtonBase>
          )}
        />
      ))}
      {emptySelection && (
        <HbAlert className={classes.dayError} severity="error">
          {EMPTY_DAY_ERROR}
        </HbAlert>
      )}
    </div>
  )
}

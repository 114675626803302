import React, { useState, useEffect, MouseEvent, ReactNode, useCallback, useMemo, ReactElement } from 'react'

// eslint-disable-next-line no-restricted-imports
import { StyleRules, useTheme, withStyles } from '@mui/styles'

import classnames from 'classnames'

import { isFunction, clamp } from 'lodash'

import { render } from 'react-dom'
import { useStore } from 'react-redux'

import { HbNonIdealState, HbNonIdealStateProps } from 'components/HbComponents/HbNonIdealState'
import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'

import { TableRowOverrides } from 'components/library/Table/TableRow.styles'
import { StyleClass } from 'components/utils/styles'
import { stopEvent } from 'helpers/uiHelpers'
import { useCallbackRef, useLocalStorage } from 'hooks'
import { DashboardDensity } from 'reducers/userSettingsReducer'
import { Theme, WithStyles } from 'types/hb'

import HorizontalScrollContainer from '../HorizontalScrollContainer'

import DefaultHeaderCell, { DefaultHeaderCellOverrides } from './DefaultHeaderCell'
import { MeasurementColumn } from './MeasurementColumn'
import TableColumn, { TableColumnElement } from './TableColumn'
import TableRow from './TableRow'
import { TableWithReorderableColumns, TableWithReorderableColumnsOverrides } from './TableWithReorderableColumns'
import { sumAllColWidths, TableColWidthsState } from './helpers'

type TableClassKey =
  | 'container'
  | 'innerContainer'
  | 'header'
  | 'cellContent'
  | 'rounded'
  | 'table'
  | 'rowControlHeaderCellContent'
  | 'loadingContainer'
  | 'emptyCell'

export type TableOverrides = StyleClass<TableClassKey>

export type TableComponentOverrides = Partial<{
  Table: TableOverrides
  DefaultHeaderCell: DefaultHeaderCellOverrides
  TableRow: TableRowOverrides
  TableWithReorderableColumns: TableWithReorderableColumnsOverrides
}>

const styles = (theme: Theme): StyleRules<object, TableClassKey> => ({
  container: {
    position: 'relative',
  },
  innerContainer: {
    display: 'block',
    paddingBottom: 2,
    paddingRight: 2,
  },
  header: {},
  cellContent: {},
  rounded: {},

  table: {
    minWidth: '100%',
    '&$rounded tbody': {
      borderRadius: theme.shape.largeContainer.borderRadius,

      '& tr:first-child': {
        '& td:first-child': {
          borderTopLeftRadius: theme.shape.largeContainer.borderRadius,
        },
        '& td:last-child': {
          borderTopRightRadius: theme.shape.largeContainer.borderRadius,
        },
      },
      '& tr:last-child': {
        borderBottom: 'none',

        '& td:first-child': {
          borderBottomLeftRadius: theme.shape.largeContainer.borderRadius,
        },
        '& td:last-child': {
          borderBottomRightRadius: theme.shape.largeContainer.borderRadius,
        },
      },
    },
  },

  rowControlHeaderCellContent: {
    padding: 0,
  },

  loadingContainer: {
    display: 'flex',
    minHeight: '150px',
    justifyContent: 'center',
    alignItems: 'center',
    '& > *': {
      marginTop: '-20px',
    },
  },

  emptyCell: {
    textAlign: 'center',
    textWrap: 'balance',
    maxWidth: 800,
    padding: theme.spacing(15),
  },
})

interface KeyboardAction {
  keyCodes: number[]
  keys: string[]
  func: (...args: any[]) => any
}

export type ChangedColumns = { display: string; value: string }[]

export type OnColumnChange = (changedColumns: ChangedColumns) => void

interface BaseTableProps extends WithStyles<typeof styles> {
  uniqueKey: string
  data: any[]
  children:
    | (TableColumnElement | undefined)[]
    | ((styleOverrides?: TableComponentOverrides) => TableColumnElement[])
    | TableColumnElement
  density?: DashboardDensity
  rightStickyColumn?: TableColumnElement
  rowControl?: (entry: any) => ReactNode
  className?: string
  emptyMessage?: string | HbNonIdealStateProps
  fixedColumnSizes?: TableColWidthsState
  onColumnChange?: (columns: ChangedColumns) => void
  onRowClick?: (row: any, event: MouseEvent) => void
  onRowDoubleClick?: (row: any, event: MouseEvent) => void
  keyboardActions?: KeyboardAction[]
  getIsRowSelected?: (row: any) => boolean
  getIsRowClickDisabled?: (row: any) => boolean
  reorderableColumns?: boolean
  batchColumnEnabled?: boolean
  loading?: boolean
  rounded?: boolean
  hideArrows?: boolean
  styleOverrides?: TableComponentOverrides
  scrollToFocused?: boolean
  id?: string
}

type NonResizableColumnsTableProps = { id?: string; columnResizingEnabled?: false }

type ResizableColumnsTableProps = { id?: string; columnResizingEnabled: true }

export type TableProps = BaseTableProps & (NonResizableColumnsTableProps | ResizableColumnsTableProps)

const tableColSizesKey = (uniqueTableId: string) => `tableColSizes_${uniqueTableId}`
const DEFAULT_NON_RESIZABLE_TABLE_ID = 'defaultTable'

function Table(props: TableProps) {
  const [kbCursorIndex, setKbCursorIndex] = useState(0)

  const {
    children,
    emptyMessage = 'No data found.',
    keyboardActions = [],
    data = [],
    onRowClick,
    getIsRowSelected = () => false,
    getIsRowClickDisabled = () => false,
    uniqueKey,
    className,
    reorderableColumns = false,
    rounded = true,
    classes: propClasses,
    loading = false,
    fixedColumnSizes,
    rowControl,
    onColumnChange,
    hideArrows = false,
    styleOverrides,
    batchColumnEnabled,
    columnResizingEnabled: columnResizingEnabledForTable = false,
    density,
    scrollToFocused = true,
    // Explicitly pass down a unique table id for resizable tables
    // so the column width values can be stored in local storage.
    // Any non resizable table can just overwrite a default object of values in local storage,
    // as it shouldn't affect anything
    id = DEFAULT_NON_RESIZABLE_TABLE_ID,
    onRowDoubleClick,
  } = props
  const [tableColWidths, setTableColWidths] = useLocalStorage<TableColWidthsState>(tableColSizesKey(id), {})
  const allColsWidth = useMemo(() => sumAllColWidths(tableColWidths), [tableColWidths])
  // Using a callback ref to know when the node is populated, so it can be checked in an initialization effect.
  const [containerEl, getContainerRef] = useCallbackRef<HTMLDivElement>()

  const store = useStore()
  const theme = useTheme<Theme>()
  const renderMeasurementColumn = useCallback(
    (column: TableColumnElement, container: HTMLDivElement) =>
      new Promise((res) => {
        render(<MeasurementColumn column={column} data={data} store={store} theme={theme} />, container, () =>
          res(null)
        )
      }),
    [data, store, theme]
  )

  const classes = { ...propClasses, ...styleOverrides?.Table }

  const handleRowClick = (row: any, event: MouseEvent) => {
    if (isFunction(onRowClick)) {
      onRowClick(row, event)
    }
  }

  const handleRowDoubleClick = (row: any, event: MouseEvent) => {
    if (isFunction(onRowDoubleClick)) {
      onRowDoubleClick(row, event)
    }
  }

  const columns = React.Children.toArray(typeof children === 'function' ? children(styleOverrides) : children).filter(
    (child: ReactElement) => child && child.type === TableColumn
  ) as TableColumnElement[]

  const setKbFocus = useCallback(
    (idx: number) => {
      const boundedIndex = Math.min(Math.max(idx, 0), data.length - 1)
      setKbCursorIndex(boundedIndex)
    },
    [data]
  )

  const changeKbCursor = (change: any, event: any) => {
    const newIndex = clamp(kbCursorIndex + change, data.length)
    if (newIndex !== kbCursorIndex) {
      // Kill event so page doesn't move from arrow key usage
      stopEvent(event)
      setKbFocus(newIndex)
    }
  }

  /**
   * Handle all keydown events that propagate to the root
   * level.  We expect all keydown events not relevent to the
   * table component to be stopped before reaching the root event listener.
   */
  const handleKeydown = (event: any) => {
    // ignore arrow keys if Meta (Cmd/Windows), Ctrl, or Alt are enabled
    const isModified = event.metaKey || event.ctrlKey || event.altKey
    // handle arrow keys
    const isDown = event.key === 'ArrowDown' || event.keyCode === 40 || event.key === 'KeyJ' || event.keyCode === 74
    const isUp = event.key === 'ArrowUp' || event.keyCode === 33 || event.key === 'KeyK' || event.keyCode === 75

    if (!isModified && (isDown || isUp)) {
      const direction = isDown ? 1 : -1
      changeKbCursor(direction, event)
    }

    // handle keyboardActions
    const row = data[kbCursorIndex]
    if (row) {
      keyboardActions.forEach(({ keyCodes = [], keys = [], func }) => {
        if (keys.includes(event.key) || keyCodes.includes(event.keyCode)) {
          func(row, event)
        }
      })
    }
  }

  useEffect(() => {
    if (keyboardActions.length > 0) {
      document.addEventListener('keydown', handleKeydown)

      return () => {
        document.removeEventListener('keydown', handleKeydown)
      }
    }
    return undefined
  })

  useEffect(() => {
    if (keyboardActions.length > 0) {
      const newIndex = clamp(kbCursorIndex, data.length)
      if (newIndex !== kbCursorIndex) {
        setKbFocus(newIndex)
      }
    }
  }, [keyboardActions, data, kbCursorIndex, setKbFocus])

  const tableClassName = classnames(
    'hb-table',
    {
      'hb-table--selectable': isFunction(onRowClick),
      [classes.rounded ?? '']: rounded,
    },
    classes.table
  )

  const containerClass = classnames('hb-table__container', className, classes.container)

  if (loading) {
    return (
      <div className={classnames(classes.loadingContainer, containerClass)}>
        <Loader variant="local" indicatorType="circular" />
      </div>
    )
  }

  const rightStickyColumn = rowControl && (
    <TableColumn
      header={(headerProps) => (
        <DefaultHeaderCell
          classes={{
            headerCellContent: classes.rowControlHeaderCellContent,
          }}
          styleOverrides={styleOverrides?.DefaultHeaderCell}
          {...headerProps}
        />
      )}
      field="__stickyRightColumn__"
      value={(entry: any) => rowControl(entry)}
    />
  )

  return (
    <div className={containerClass} ref={getContainerRef}>
      <HorizontalScrollContainer
        scrollAmount={340}
        className={classes.innerContainer}
        snapOverlayToWindowScroll
        hideArrows={hideArrows}
      >
        <TableWithReorderableColumns
          allColsWidth={allColsWidth}
          batchColumnEnabled={batchColumnEnabled}
          classes={{ visibleTableHeader: classes.header }}
          className={tableClassName}
          columns={columns}
          columnResizingEnabled={columnResizingEnabledForTable}
          containerEl={containerEl}
          fixedColumnSizes={fixedColumnSizes}
          onColumnChange={onColumnChange}
          rightStickyColumn={rightStickyColumn}
          renderMeasurementColumn={renderMeasurementColumn}
          reorderableColumns={reorderableColumns}
          styleOverrides={styleOverrides}
          setTableColWidths={setTableColWidths}
          tableColWidths={tableColWidths}
          uniqueKey={uniqueKey}
        >
          {data.length === 0 ? (
            <tbody>
              <tr>
                <td className={classnames(classes.emptyCell, 'hb-table__cell')} colSpan={columns.length}>
                  {typeof emptyMessage === 'string' ? (
                    <HbText tag="h2" bold>
                      {emptyMessage}
                    </HbText>
                  ) : (
                    <HbNonIdealState {...emptyMessage} />
                  )}
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="hb-table__body">
              {data.map((row, idx) => {
                const isClickAllowed = !getIsRowClickDisabled(row)
                return (
                  <TableRow
                    key={row[uniqueKey]}
                    allColsWidth={allColsWidth}
                    availableWidth={containerEl?.clientWidth}
                    columnResizingEnabled={columnResizingEnabledForTable}
                    density={density}
                    focused={keyboardActions.length > 0 && idx === kbCursorIndex}
                    selectable={isClickAllowed && isFunction(onRowClick)}
                    onClick={isClickAllowed ? (e: MouseEvent) => handleRowClick(row, e) : undefined}
                    onDoubleClick={isClickAllowed ? (e: MouseEvent) => handleRowDoubleClick(row, e) : undefined}
                    rightStickyColumn={rightStickyColumn}
                    row={row}
                    uniqueKey={row[uniqueKey]}
                    columns={columns}
                    styleOverrides={styleOverrides?.TableRow}
                    isSelected={getIsRowSelected(row)}
                    batchColumnEnabled={batchColumnEnabled}
                    scrollToFocused={scrollToFocused}
                    tableColWidths={tableColWidths}
                  />
                )
              })}
            </tbody>
          )}
        </TableWithReorderableColumns>
      </HorizontalScrollContainer>
    </div>
  )
}

export default withStyles(styles)(Table)

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const BusinessIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g transform="translate(2.000000, 3.000000)">
      <path d="M19,4 C19.5522847,4 20,4.44771525 20,5 L20,17 C20,17.5522847 19.5522847,18 19,18 L1,18 C0.44771525,18 6.76353751e-17,17.5522847 0,17 L0,5 C6.76353751e-17,4.44771525 0.44771525,4 1,4 L19,4 Z M4,16 L4,6 L2,6 L2,16 L4,16 Z M14,16 L14,6 L6,6 L6,16 L14,16 Z M18,16 L18,6 L16,6 L16,16 L18,16 Z M10,2 C9.15191681,2.00455333 8.34538472,2.36785607 7.78,3 L5.42,3 C6.21665065,1.18102808 8.01422279,0.00589132308 10,0.00589132308 C11.9857772,0.00589132308 13.7833493,1.18102808 14.58,3 L12.22,3 C11.6546153,2.36785607 10.8480832,2.00455333 10,2 Z" />
    </g>
  </SvgIcon>
)

export default BusinessIcon

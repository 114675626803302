import React, { useState, ReactNode, useEffect } from 'react'

// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import classnames from 'classnames'

import { stopEvent } from 'helpers/uiHelpers'
import { CreateIcon } from 'icons'
import { WithStyles, Theme } from 'types/hb'

const styles: Styles<Theme, object> = (theme: Theme) => ({
  root: {
    position: 'relative',
    overflow: 'visible',
    fontWeight: 'inherit',
  },
  display: {
    '& $icon': {
      opacity: 0,
    },
    '&:not($editable)': {
      cursor: 'default',
    },
    '&$editable:hover': {
      borderBottom: `1px solid ${theme.palette.action.active}`,
      marginBottom: -1,
      '& $icon': {
        opacity: 1,
      },
    },
    '& $content': {
      display: 'block',
      zIndex: 1,
      position: 'relative',
    },
    '& $input': {
      position: 'absolute',
      left: 0,
      top: 0,
      opacity: 0,
      zIndex: -1,
    },
  },
  shadow: {
    width: 'auto',
    whiteSpace: 'pre',
    opacity: 0,
    zIndex: -1,
    position: 'absolute',
    '& $inverted': {
      color: theme.palette.text.white,
    },
  },
  editing: {
    borderBottom: `1px solid ${theme.palette.action.active}`,
    marginBottom: -1,
    '& $content': {
      display: 'none',
    },
    '& $input': {
      position: 'relative',
      opacity: 1,
      zIndex: 1,
      minWidth: '5em',
    },
  },
  content: {
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    color: ({ variant }: { variant?: string }) => (variant === 'inverted' ? theme.palette.text.white : 'inherit'),
  },
  input: {
    fontSize: 'inherit',
    fontFamily: 'inherit',
    lineHeight: 'inherit',
    border: 0,
    marginLeft: -1,
    outline: 0,
    width: 'auto',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
  icon: {
    position: 'absolute',
    top: theme.spacing(),
    right: theme.spacing(-4),
  },
  editable: {},
  inverted: {
    color: 'white',
  },
})

interface EditableComponentProps extends WithStyles<typeof styles> {
  children: ReactNode
  onChange?: (value: string) => void
  value: string
  editable?: boolean
  variant?: 'normal' | 'inverted'
}

export function EditableComponent(props: EditableComponentProps) {
  const { classes, children, onChange, value, editable, variant } = props
  const [isEditing, setIsEditing] = useState(false)
  const [editedValue, setEditedValue] = useState(value)
  const inputRef = React.createRef<HTMLInputElement>()
  const shadowRef = React.createRef<HTMLDivElement>()

  // If the initial value updates and the user is not actively editing,
  // reset the state to the new value.
  useEffect(() => {
    if (!isEditing && editedValue !== value) {
      setEditedValue(value)
    }
  }, [editedValue, isEditing, value])

  const onBlur = () => {
    setIsEditing(false)
    if (onChange && editedValue !== value) {
      onChange(editedValue.trim())
    }
  }

  const onKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const target: HTMLInputElement = event.target as HTMLInputElement
    const { key, keyCode } = event
    // Treat 'enter' (charCode 13) as a submission
    if (key === 'Enter' || keyCode === 13) {
      stopEvent(event)
      target.blur()
      return false
    }

    return true
  }

  const setWidth = (newWidth: string) => {
    if (shadowRef.current && inputRef.current) {
      shadowRef.current.innerText = newWidth
      const { width } = shadowRef.current.getBoundingClientRect()
      inputRef.current.style.width = `${width}px`
    }
  }

  const onClick = () => {
    setIsEditing(true)
    setWidth(editedValue)
    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const onInputChange = (event: React.FormEvent) => {
    const target: HTMLInputElement = event.target as HTMLInputElement
    setWidth(target.value)
    setEditedValue(target.value)
  }

  const onFocus = (event: React.FormEvent) => {
    const target: HTMLInputElement = event.target as HTMLInputElement
    target.select()
  }

  const className = classnames(classes.root, isEditing ? classes.editing : classes.display, {
    [classes.editable]: editable,
  })

  return (
    <button type="button" onClick={editable ? onClick : () => {}} className={className}>
      <input
        ref={inputRef}
        className={classes.input}
        onBlur={onBlur}
        onKeyPress={onKeyPress}
        value={editedValue || ''}
        onFocus={onFocus}
        onChange={onInputChange}
      />
      <div ref={shadowRef} className={classnames(classes.shadow, { [classes.inverted]: variant === 'inverted' })}>
        {editedValue}
      </div>
      <div className={classes.content}>{children}</div>
      {editable && <CreateIcon className={classes.icon} color="action" />}
    </button>
  )
}

export default withStyles(styles)(EditableComponent)

import { isNil } from 'lodash'
import { JsonPrimitive } from 'type-fest'

export const jsonPrimitiveToDisplayString = (value: JsonPrimitive | undefined, fallback = '-') =>
  isNil(value) ? fallback : String(value).trim() || fallback

export const humanizeList = (array: Array<string>, conjunction: 'or' | 'and' = 'and') => {
  const len = array.length
  if (len === 0) {
    return ''
  }
  if (len === 1) {
    return array[0]
  }
  const i = len - 1
  return `${array.slice(0, i).join(', ')}${len >= 3 ? ',' : ''} ${conjunction} ${array[i]}`
}

export const filterStringifyArray = (arr: Array<JsonPrimitive | undefined>, delimiter = ' ') => {
  return arr
    .map((val) => (isNil(val) ? val : typeof val === 'string' ? val.trim() : String(val)))
    .filter(Boolean)
    .join(delimiter)
}

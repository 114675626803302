import { ComponentProps, ComponentType, PropsWithChildren, ReactElement } from 'react'

import { Route, RouteComponentProps, RouteProps } from 'react-router-dom'

import { useUserViewing } from 'helpers/SessionTracking/UsageTracker'

type RouteRenderProps = Pick<RouteProps, 'render' | 'component'> & {
  disableTimeTracking?: boolean
}

function TrackedRoute(props: RouteComponentProps & RouteRenderProps) {
  const { render, component: Component, disableTimeTracking, ...routeProps } = props
  useUserViewing(
    // Remove 24-char long path segments which are (probably) our tokens
    {
      view: props.location.pathname.replace(/\/[a-zA-Z0-9]{24,}(\/|$)/gm, '_'),
    },
    !disableTimeTracking
  )

  if (Component) {
    return <Component {...routeProps} />
  }

  if (render) {
    return render(routeProps) as ReactElement
  }

  return null
}

export function HbRoute<C extends ComponentType<PropsWithChildren<any>>>(
  props: RouteProps & {
    disableTimeTracking?: boolean
    wrapper?: { Component: C; props: Omit<ComponentProps<C>, 'children'> }
  }
) {
  const { component, render, disableTimeTracking = true, wrapper, ...otherProps } = props
  return (
    <Route
      {...otherProps}
      render={(routeProps) =>
        wrapper ? (
          // trust me on this cast...typescript with react is silly sometimes
          <wrapper.Component {...(wrapper.props as any)}>
            <TrackedRoute
              {...routeProps}
              component={component}
              render={render}
              disableTimeTracking={disableTimeTracking}
            />
          </wrapper.Component>
        ) : (
          <TrackedRoute
            {...routeProps}
            component={component}
            render={render}
            disableTimeTracking={disableTimeTracking}
          />
        )
      }
    />
  )
}

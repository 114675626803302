import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const InformationIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 22">
    <g id="Bounding_Boxes">
      <path fill="none" d="M0,0h24v24H0V0z" />
    </g>
    <g id="Rounded" display="none">
      <g display="inline">
        <path
          d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,17L12,17c-0.55,0-1-0.45-1-1v-4
          c0-0.55,0.45-1,1-1h0c0.55,0,1,0.45,1,1v4C13,16.55,12.55,17,12,17z M13,9h-2V7h2V9z"
        />
      </g>
    </g>
    <g id="Sharp" display="none">
      <g display="inline">
        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z" />
      </g>
    </g>
    <g id="Outline">
      <g>
        <rect x="11" y="7" width="2" height="2" />
        <rect x="11" y="11" width="2" height="6" />
        <path
          d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8
          c0-4.41,3.59-8,8-8s8,3.59,8,8C20,16.41,16.41,20,12,20z"
        />
      </g>
    </g>
    <g id="Duotone" display="none">
      <g display="inline">
        <path
          opacity="0.3"
          d="M12,4c-4.41,0-8,3.59-8,8c0,4.41,3.59,8,8,8s8-3.59,8-8C20,7.59,16.41,4,12,4z M13,17h-2v-6h2V17z M13,9h-2
          V7h2V9z"
        />
        <rect x="11" y="7" width="2" height="2" />
        <rect x="11" y="11" width="2" height="6" />
        <path
          d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.41,0-8-3.59-8-8
          c0-4.41,3.59-8,8-8s8,3.59,8,8C20,16.41,16.41,20,12,20z"
        />
      </g>
    </g>
    <g id="Material" display="none">
      <g display="inline">
        <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M13,17h-2v-6h2V17z M13,9h-2V7h2V9z" />
      </g>
    </g>
  </SvgIcon>
)

export default InformationIcon

// Added casts to these helpers because if T === number, extract is not needed, but if T is not number, then extract is required.
// Not super easy to express this with types.

export function sum<T>(
  values: T[],
  extract: ((value: T) => number) | null = null,
  filter: ((value: T) => boolean) | null = null
) {
  let filtered = values
  if (filter) {
    filtered = values.filter(filter)
  }
  return filtered.reduce((accum, entry) => accum + ((extract ? extract(entry) : entry) as unknown as number), 0)
}

export function mean<T>(
  values: T[],
  extract: ((value: T) => number) | null = null,
  filter: ((value: T) => boolean) | null = null
) {
  let filtered = values
  if (filter) {
    filtered = values.filter(filter)
  }
  return sum(filtered, extract) / filtered.length
}

export function max<T>(
  values: T[],
  extract: ((value: T) => number) | null = null,
  filter: ((value: T) => boolean) | null = null
) {
  let filtered = values
  if (filter) {
    filtered = values.filter(filter)
  }
  return filtered.reduce(
    (accum, entry) => Math.max(accum, (extract ? extract(entry) : entry) as unknown as number),
    -Infinity
  )
}

export function min<T>(
  values: T[],
  extract: ((value: T) => number) | null = null,
  filter: ((value: T) => boolean) | null = null
) {
  let filtered = values
  if (filter) {
    filtered = values.filter(filter)
  }
  return filtered.reduce(
    (accum, entry) => Math.min(accum, (extract ? extract(entry) : entry) as unknown as number),
    Infinity
  )
}

export function stats<T>(
  entities: T[],
  extract: ((value: T) => number) | null = null,
  filter: ((value: T) => boolean) | null = null
) {
  let values = entities
  if (filter) {
    values = entities.filter(filter)
  }

  if (extract) {
    values = values.map(extract) as unknown as T[]
  }

  return {
    sum: sum(values as unknown as number[]),
    max: max(values as unknown as number[]),
    mean: mean(values as unknown as number[]),
    count: values.length,
  }
}

// Exposes a wrapped implementation of the Material-UI TextField component that supports
// formatted embedding in Formik Fields as a component.

import { ForwardedRef, forwardRef } from 'react'

import { Field, FieldAttributes, FieldProps } from 'formik'

import { formikFieldToHbInput } from '../hbInputUtils'

import HbTextInput, { HbTextInputProps as BaseHbTextInputProps } from './HbTextInput'

export interface HbFormikTextInputProps extends FieldProps, Omit<BaseHbTextInputProps, 'name' | 'value' | 'error'> {}

export const HbFormikTextInput = forwardRef<HTMLDivElement, HbFormikTextInputProps>((props, ref) => {
  const _props = formikFieldToHbInput<HTMLInputElement>(props)
  return <HbTextInput {..._props} ref={ref} />
})

type HbFormikTextFieldProps = BaseHbTextInputProps & FieldAttributes<unknown> & { name: string }

const HbFormikTextFieldInner = <E extends HTMLElement = HTMLElement>(
  props: HbFormikTextFieldProps,
  ref: ForwardedRef<E>
) => <Field {...props} innerRef={ref} component={HbFormikTextInput} />

export const HbFormikTextField = forwardRef(HbFormikTextFieldInner)

import { ReactNode } from 'react'

import { Tooltip as MUITooltip, TooltipProps as BaseTooltipProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

export enum Placement {
  Top = 'top',
  Right = 'right',
  Bottom = 'bottom',
  Left = 'left',
  TopEnd = 'top-end',
  TopStart = 'top-start',
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {},
  tooltipPlacementTop: {
    marginBottom: theme.spacing(1),
  },
  arrow: {
    color: theme.palette.styleguide.black,
  },
  popper: {},
}))

export type TooltipProps = Omit<BaseTooltipProps, 'title'> & {
  showArrow?: boolean
  title: ReactNode
  classes?: Partial<ReturnType<typeof useStyles>>
  className?: string
}

export const HbTooltip = (props: TooltipProps) => {
  const {
    classes: overrideClasses,
    className,
    title,
    children,
    enterDelay,
    enterNextDelay,
    placement = Placement.Bottom,
    open = null,
    disableTouchListener = false,
    disableHoverListener = false,
    disableFocusListener = false,
    showArrow = false,
    PopperProps = {},
  } = props

  const classes = useStyles()
  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)

  // So this is weird. If false is provided then the underlying MUITooltip will not track
  // internal open state, but if we do provide it then it will be driven by props. This preserves
  // that behavior.
  const optionalProps: any = {}
  if (open !== null) {
    optionalProps.open = open
  }

  return (
    <MUITooltip
      className={className}
      classes={mergedClasses}
      arrow={showArrow}
      title={title}
      enterDelay={enterDelay}
      enterNextDelay={enterNextDelay}
      placement={placement}
      disableHoverListener={disableHoverListener}
      disableTouchListener={disableTouchListener}
      disableFocusListener={disableFocusListener}
      PopperProps={PopperProps}
      {...optionalProps}
    >
      {children}
    </MUITooltip>
  )
}

import { AnyAction } from 'redux'

import * as actions from 'actions/narrativeTemplatesActions'

export interface Template {
  token?: string
  name: string
  content: string
  applicableReviewTypeToken?: string
  aiEnabled?: boolean
}

export type TemplateSkeleton = Pick<Template, 'name' | 'token' | 'aiEnabled'>

export interface NarrativeTemplatesState {
  generic: Template[]
  byReview: { [reviewToken: string]: Template[] }
  activeTemplate?: Template | null
  error?: string | null
}

export interface TemplateSaveResult {
  templates?: Template[]
  error?: TemplateError
}

export type TemplateError = string

const getInitialState = (): NarrativeTemplatesState => ({
  generic: [],
  byReview: {},
  activeTemplate: null,
})

const narrativeTemplatesReducer = (state = getInitialState(), action: AnyAction): NarrativeTemplatesState => {
  switch (action.type) {
    case actions.SET_TEMPLATES: {
      const updates: Partial<NarrativeTemplatesState> = {}

      if (action.reviewToken) {
        updates.byReview = {
          ...state.byReview,
          [action.reviewToken]: action.templates,
        }
      } else {
        updates.generic = action.templates
      }
      return { ...state, ...updates }
    }
    case actions.SET_ACTIVE_TEMPLATE: {
      return { ...state, activeTemplate: action.template, error: null }
    }
    case actions.SET_TEMPLATES_ERROR: {
      return { ...state, error: action.error }
    }
    default: {
      return state
    }
  }
}

export default narrativeTemplatesReducer

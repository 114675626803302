import { FormControl, Select, ClickAwayListener } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useFormContext } from 'react-hook-form'

import { useSelector } from 'actions/store'
import { HbTag } from 'components/HbComponents/HbTag'
import { FilterableAccountList } from 'components/library/FilterableAccountList'
import { FormSchema } from 'components/pages/automations/editor/formSchema'
import { getActionParamsPath } from 'components/pages/automations/editor/util'
import { getOrganizationTeammates } from 'helpers/stateHelpers'

import { shortName } from 'helpers/uiHelpers'
import { useToggle } from 'hooks'
import { Account } from 'types/hb'

import CheckboxSection from './CheckboxSection'

export type Props = {
  className?: string
  automationAdminAccounts?: Account[]
}

const useStyles = makeStyles((theme) => ({
  users: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(),
  },
}))

export default function AccountSelect({ className, automationAdminAccounts }: Props) {
  const accounts = useSelector(getOrganizationTeammates)
  const useAccounts = automationAdminAccounts || accounts
  const { setValue, watch } = useFormContext<FormSchema>()
  const actionType = watch('actionType')
  const accountTokensPath = `${getActionParamsPath(actionType)}.accountTokens` as const
  const selectedAccountTokens = watch(accountTokensPath)
  const selectUserOpen = useToggle(false)
  const classes = useStyles()

  const handleAccountClick = (account: Account) => {
    if (!selectedAccountTokens.includes(account.token)) {
      setValue(accountTokensPath, [...selectedAccountTokens, account.token], {
        shouldDirty: true,
      })
    }
    selectUserOpen.toggle()
  }

  const handleDelete = (index: number) => {
    setValue(accountTokensPath, [...selectedAccountTokens.slice(0, index), ...selectedAccountTokens.slice(index + 1)], {
      shouldDirty: true,
    })
  }

  return (
    <CheckboxSection
      label="Accounts"
      handleUncheck={() => setValue(accountTokensPath, [])}
      isChecked={selectedAccountTokens.length > 0}
    >
      <FormControl className={className} size="small">
        <Select
          multiple
          variant="outlined"
          onClick={() => !selectUserOpen.value && selectUserOpen.toggle()}
          open={selectUserOpen.value}
          value={selectedAccountTokens}
          renderValue={(tokens: string[]) => (
            <div className={classes.users}>
              {tokens.map((token, index) => {
                const selectedAccount = useAccounts.find((account) => account.token === token)
                if (!selectedAccount) return null
                return (
                  <HbTag
                    onDelete={() => handleDelete(index)}
                    key={token}
                    color={selectedAccount.avatarColor}
                    label={shortName(selectedAccount)}
                  />
                )
              })}
            </div>
          )}
        >
          <ClickAwayListener onClickAway={() => selectUserOpen.toggle()}>
            <FilterableAccountList
              accounts={useAccounts}
              onClickItem={handleAccountClick}
              placeholder="Search account"
            />
          </ClickAwayListener>
        </Select>
      </FormControl>
    </CheckboxSection>
  )
}

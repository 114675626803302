import React, { useState } from 'react'

import { ErrorOutline } from '@mui/icons-material'
import { Collapse } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { BUTTON_SHADOW } from 'components/themeRedesign'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    boxShadow: BUTTON_SHADOW,
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.white,
    boxSizing: 'border-box',
    width: 500,
    padding: theme.spacing(),
    marginTop: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    ...theme.typography.sizes.md,
  },

  message: {
    display: 'flex',

    '& > svg': {
      width: 22,
      height: 22,
      marginRight: theme.spacing(0.5),
    },
  },
}))

type ShowMessage = {
  message: string
  showUntil: Date
}
type HiddenMessage = {
  message: null
  showUntil: null
}
type Props = ShowMessage | HiddenMessage

function MaintenanceWindowMessage({ message, showUntil }: Props) {
  const classes = useStyles()
  const messageExpiredOrNonExistent = !message || new Date() > showUntil
  const [open, setOpen] = useState(() => {
    if (!message) {
      return false
    }
    return !messageExpiredOrNonExistent
  })

  if (messageExpiredOrNonExistent) {
    return null
  }
  const close = () => setOpen(false)

  const containerClass = classnames(classes.root)

  return (
    <Collapse in={open} timeout="auto">
      <div className={containerClass} onClick={close}>
        <span className={classes.message}>
          <ErrorOutline />
          <span>{message}</span>
        </span>
      </div>
    </Collapse>
  )
}

export default React.memo(MaintenanceWindowMessage)

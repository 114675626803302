import { useMemo } from 'react'

import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import SettingsIcon from '@mui/icons-material/Settings'
import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import TimelineIcon from '@mui/icons-material/Timeline'

import { useHistory, useRouteMatch } from 'react-router-dom'

import { AutomationSidebar, ButtonConfig } from 'components/pages/automations/AutomationSidebar'
import { type FormSchemaReturnType } from 'components/pages/automations/editor/formSchema'
import { TrashOutlineIcon } from 'icons'

import { AutomationRuleType } from 'types/api'

import { AutomationActivityLogPanel } from './AutomationActivityLogPanel/AutomationActivityLogPanel'

import { AutomationOverviewPanel } from './AutomationOverviewPanel/AutomationOverviewPanel'
import { AutomationSettingsPanel } from './AutomationSettingsPanel'
import { DeleteAutomationPanel } from './DeleteAutomationPanel'
import { HelpAutomationPanel } from './HelpAutomationPanel'
import { PreviewModePanel } from './PreviewModePanel/PreviewModePanel'
import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from './__generated__/ViewAutomation.queries.generated'

const SidebarDrawerPanelOptions = [
  'delete_automation',
  'overview',
  'activity_log',
  'settings',
  'help',
  'preview_mode',
] as const
type SidebarDrawerPanel = (typeof SidebarDrawerPanelOptions)[number]

const TopButtonConfig: Array<ButtonConfig<SidebarDrawerPanel>> = [
  {
    type: 'overview',
    label: 'Overview',
    Icon: AssignmentOutlinedIcon,
  },
  {
    type: 'activity_log',
    label: 'Activity Log',
    Icon: TimelineIcon,
  },
  {
    type: 'preview_mode',
    label: 'Preview Mode',
    Icon: SlowMotionVideoIcon,
  },
  {
    type: 'settings',
    label: 'Settings',
    Icon: SettingsIcon,
  },
  {
    type: 'help',
    label: 'Help',
    Icon: HelpOutlineOutlinedIcon,
  },
]

const BottomButtonConfig: Array<ButtonConfig<SidebarDrawerPanel>> = [
  {
    type: 'delete_automation',
    label: 'Delete Automation',
    Icon: TrashOutlineIcon,
  },
]

export const ViewAutomationSidebar = ({
  form,
  loading,
  deleteRule,
  automationRule,
  loadMoreActivity,
  setEnablingOrDisabling,
}: {
  form: FormSchemaReturnType
  loading: boolean
  deleteRule: () => void
  automationRule: AutomationRule
  loadMoreActivity: (cursor: string | null) => void
  setEnablingOrDisabling: (x: boolean) => void
}) => {
  const history = useHistory()
  const match = useRouteMatch<{ panel: SidebarDrawerPanel }>('/automations/:token/:panel')

  const { formState, watch } = form
  const { isDirty, errors, isValid } = formState

  const automationType = watch('automationType')
  const actionType = watch('actionType')

  const isPreviewModeSupported =
    automationType === AutomationRuleType.Trigger || automationType === AutomationRuleType.ScheduleWithTrigger

  const filteredOptions = SidebarDrawerPanelOptions.filter((opt) => {
    if (opt === 'preview_mode' && !isPreviewModeSupported) {
      return false
    }

    return true
  })

  const currentPanel = useMemo(() => {
    if (match && filteredOptions.includes(match.params.panel)) {
      return match.params.panel
    }
    return null
  }, [filteredOptions, match])

  const setCurrentPanel = (panel: SidebarDrawerPanel | null) => {
    if (!panel || panel === currentPanel) {
      history.push(`/automations/${automationRule.token}`)
    } else {
      history.push(`/automations/${automationRule.token}/${panel}`)
    }
  }

  const getPanelComponent = (panel: SidebarDrawerPanel | null): React.ReactNode | null => {
    switch (panel) {
      case 'delete_automation':
        return <DeleteAutomationPanel loading={loading} deleteRule={deleteRule} />
      case 'overview':
        return <AutomationOverviewPanel automationRule={automationRule} />
      case 'activity_log':
        return <AutomationActivityLogPanel automationRule={automationRule} loadMoreActivity={loadMoreActivity} />
      case 'settings':
        return <AutomationSettingsPanel automationRule={automationRule} />
      case 'help':
        return <HelpAutomationPanel />
      case 'preview_mode':
        return isPreviewModeSupported ? (
          <PreviewModePanel
            disabled={loading || isDirty || !isValid || Object.keys(errors).length > 0 || !actionType}
            automationRule={automationRule}
            setEnablingOrDisabling={setEnablingOrDisabling}
          />
        ) : null
      default:
        return null
    }
  }

  let filteredTopButtonConfig = TopButtonConfig
  if (!isPreviewModeSupported) {
    filteredTopButtonConfig = TopButtonConfig.filter((button) => button.type !== 'preview_mode')
  }

  return (
    <AutomationSidebar
      currentPanel={currentPanel}
      setCurrentPanel={setCurrentPanel}
      topButtonConfig={filteredTopButtonConfig}
      bottomButtonConfig={BottomButtonConfig}
    >
      {getPanelComponent(currentPanel)}
    </AutomationSidebar>
  )
}

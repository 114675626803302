// Processes an other info object array for the server.

import { keyBy } from 'lodash'

import { CustomFieldDatatypeEnum, OtherInfoEntry, OtherInfoLabelDisplayAsEnum } from 'types/api'
import { OtherInfo } from 'types/hb'

import { isEmptyObject } from './objHelpers'

// A generic interface for one of the many objects that use `otherInfo`
export interface DataWithOtherInfo {
  otherInfo: OtherInfoEntry[]
}

/*
 * Define an explicit interface for Custom Info
 * to help clearly label which other info is being used,
 * since custom info is currently derived from underlying other info
 * and has to be reintegrated before persistence.
 */
export interface BasicInfoEntry extends OtherInfoEntry {
  displayAs: OtherInfoLabelDisplayAsEnum.CustomInfo
}

/**
 * Including the original index of the custom info entry
 * so we can reintegrate its updated values in-place
 * in the underlying other info data.
 */
export interface BasicInfoEntryWithIndex extends BasicInfoEntry {
  index: number
}

/**
 * Explicit interface for non-"custom field" other info entries
 */
export interface OrdinaryOtherInfoEntry extends OtherInfoEntry {
  displayAs: OtherInfoLabelDisplayAsEnum.OtherInfo
}

export interface DataWithOrdinaryOtherInfo extends DataWithOtherInfo {
  otherInfo: OrdinaryOtherInfoEntry[]
}

// TODO(bwe) use CustomFieldLabelRowFragment where possible https://thecharm.atlassian.net/browse/PROD-18927
export interface CustomFieldLabel {
  label: string
  managed?: boolean | null
  token: string
  datatype: CustomFieldDatatypeEnum
  options: string[]
  allowDecimal?: boolean | null
  allowNegative?: boolean | null
  allowTime?: boolean | null
}

export interface CustomField extends CustomFieldLabel {
  displayAs: OtherInfoLabelDisplayAsEnum.CustomInfo
}

// Applicable to case/review other info. Entity info has different requirements.
export const otherInfoFromFormState = (otherInfo: OtherInfo) => {
  return otherInfo
    .filter((i) => !isEmptyObject(i))
    .map((i) => {
      // Valid info has a defined label
      if (i.label) {
        // Ensure we send at least an empty string for the value to the server
        return { label: i.label, value: i.value ?? '' }
      }

      return i
    })
}

export const isBasicInfo = (entry: OtherInfoEntry): entry is BasicInfoEntry =>
  entry.displayAs === OtherInfoLabelDisplayAsEnum.CustomInfo
export const isOrdinaryOtherInfo = (entry: OtherInfoEntry): entry is OrdinaryOtherInfoEntry =>
  entry.displayAs === OtherInfoLabelDisplayAsEnum.OtherInfo

export const extractBasicInfo = (otherInfo: OtherInfoEntry[]) => otherInfo.filter(isBasicInfo)
export const excludeBasicInfo = (otherInfo: OtherInfoEntry[]) => otherInfo.filter(isOrdinaryOtherInfo)

const labelAlphabeticalComparator = <LabeledData extends { label: string }>(a: LabeledData, b: LabeledData) =>
  a.label.localeCompare(b.label)

export const getAllCustomFields = ({
  customFieldLabels,
  populatedCustomFields,
}: {
  customFieldLabels?: CustomFieldLabel[]
  populatedCustomFields: BasicInfoEntry[]
}) => {
  const fetchedLabelsData = customFieldLabels ?? []

  const fetchedLabelsMap = keyBy(fetchedLabelsData, 'label')

  const fetchedLabelMissingForEntry = (entry: BasicInfoEntry) => !fetchedLabelsMap[entry.label]
  const mismatchedEntries = populatedCustomFields.filter(fetchedLabelMissingForEntry)

  const populatedCustomFieldsMap = keyBy(populatedCustomFields, 'label')

  const labelsAsCustomFields: BasicInfoEntry[] = fetchedLabelsData.map(
    ({ label, managed, token, datatype, options, allowDecimal, allowNegative, allowTime }) => {
      const populatedEntry = populatedCustomFieldsMap[label]
      return (
        populatedEntry ?? {
          displayAs: OtherInfoLabelDisplayAsEnum.CustomInfo,
          label,
          managed,
          token,
          value: '',
          datatype,
          options,
          allowDecimal,
          allowNegative,
          allowTime,
        }
      )
    }
  )

  return (
    ([] as BasicInfoEntry[])
      .concat(labelsAsCustomFields, mismatchedEntries)
      // for now, we're sorting entries alphabetically to have a deterministic order
      // but we will need to revisit this when users want to reorder custom fields
      .sort(labelAlphabeticalComparator)
  )
}

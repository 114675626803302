/* eslint-disable import/no-extraneous-dependencies */

// eslint-disable-next-line no-restricted-imports
import { makeStyles, ClassNameMap, WithStylesOptions, Styles, StyleRules } from '@mui/styles'

import classNames from 'classnames'

import { Theme } from 'types/hb'

/**
 * Helper type for overridable class styles
 */
export type OverrideClasses<T extends ReturnType<typeof makeStyles>> = Partial<ReturnType<T>>

export type StyleClass<T extends string> = { [key in T]?: string }

export const makeRequiredStyles = <ClassKey extends string, Props extends AnyObject = never>(
  style: Styles<Theme, Props, ClassKey>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
) => makeStyles<Theme, AnyObject, ClassKey>(style, options) as (props?: Props) => ClassNameMap<ClassKey>

// Below comment is for MUI 4, may no longer apply with new types.
// The MUI types kinda stink...so I overrode some stuff here to clean these up for our purposes
export const makeStyleOverrides = <ClassKey extends string, Props extends AnyObject = never>(
  style: (theme: Theme) => Partial<StyleRules<Props, ClassKey>>,
  options?: Omit<WithStylesOptions<Theme>, 'withTheme'>
) =>
  makeStyles<Theme, AnyObject, ClassKey>(style as (theme: Theme) => StyleRules<Props, ClassKey>, options) as (
    props?: Props
  ) => ClassNameMap<ClassKey>

export const mergeOverrideStyles = <T extends ClassNameMap>(local: T, override?: Partial<T>): T => {
  if (!override) return local
  const merged = { ...local }
  Object.keys(override).forEach((next: keyof T) => {
    const className = merged[next] ? classNames(local[next], override[next]) : override[next]
    merged[next] = className as T[keyof T]
  })
  return merged
}

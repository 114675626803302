import { useEffect } from 'react'

import { $getHtmlContent, $getLexicalContent, type $generateJSONFromSelectedNodes } from '@lexical/clipboard'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { $getSelection, COMMAND_PRIORITY_CRITICAL, COPY_COMMAND } from 'lexical'

type LexicalJson = ReturnType<typeof $generateJSONFromSelectedNodes>

// Remove comment marks from lexical json
export function cleanLexicalJson(nodes: LexicalJson['nodes']): LexicalJson['nodes'] {
  return nodes.flatMap((node) => {
    if (node.type === 'commentmark') {
      return cleanLexicalJson(node.children ?? [])
    }

    if ('children' in node) {
      return {
        ...node,
        children: cleanLexicalJson(node.children ?? []),
      }
    }

    return node
  })
}

// Remove comment marks from copied HTML
export function cleanLexicalHtml(html: string): string {
  const parsed = new DOMParser().parseFromString(html, 'text/html')

  for (const mark of parsed.querySelectorAll('mark[data-hb-comment-tokens]')) {
    const childrenFragment = document.createDocumentFragment()
    childrenFragment.append(...mark.children)
    mark.replaceWith(childrenFragment)
  }

  return parsed.body.innerHTML
}

// Based on https://github.com/facebook/lexical/blob/d1fb9419bf34a34d0d4fbf387c60da2fd9f6463f/packages/lexical-clipboard/src/clipboard.ts

export default function CleanCopyPlugin() {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    return editor.registerCommand(
      COPY_COMMAND,
      (event) => {
        if (!(event instanceof ClipboardEvent)) {
          return false
        }

        event.preventDefault()
        const { clipboardData } = event

        if (clipboardData === null) {
          return false
        }

        const selection = $getSelection()
        const htmlString = $getHtmlContent(editor)
        const lexicalString = $getLexicalContent(editor)

        let plainString = ''

        if (selection !== null) {
          plainString = selection.getTextContent()
        }

        if (htmlString !== null) {
          clipboardData.setData('text/html', cleanLexicalHtml(htmlString))
        }

        if (lexicalString !== null) {
          const json = JSON.parse(lexicalString) as LexicalJson
          json.nodes = cleanLexicalJson(json.nodes)
          clipboardData.setData('application/x-lexical-editor', JSON.stringify(json))
        }

        clipboardData.setData('text/plain', plainString)

        return true
      },
      COMMAND_PRIORITY_CRITICAL
    )
  }, [editor])

  return null
}

import { Chip, styled, Tooltip, tooltipClasses } from '@mui/material'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { Placement } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import { CountPill } from 'components/library/CountPill'
import { useContainerDimensions } from 'components/library/Resize/hooks'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useSearchRequestInfo } from 'dashboards/shared/react/getNamedQueryGroups'
import { FilterListIcon } from 'icons'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { Theme } from 'types/hb'
import { mapSearchRequestFilterKeys } from 'utils/query/api.types'

import CustomViewControls from './CustomViewControls'

const MAX_FILTERS_SHOWN = 3

const StyledChipContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  padding: `0 ${theme.spacing(0)} 0 ${theme.spacing()}`,
}))

const StyledCountPill = styled(CountPill)(({ theme }) => ({
  borderRadius: theme.shape.countPill.borderRadius,
  justifyContent: 'center',
  padding: `${theme.spacing(0.5, 1)}`,
  backgroundColor: theme.palette.background.dark,
  color: theme.palette.text.white,
}))

const StyledFilteredByContainer = styled('div')(() => ({
  display: 'flex',
  color: 'inherit',
  '& > span': { display: 'flex', alignItems: 'center' },
}))

const StyledFilterChip = styled(Chip)(({ theme }) => ({
  color: 'inherit',
  borderColor: 'inherit',
  ...theme.typography.sizes.md,
  height: 26,
}))

const editingCustom = (theme: Theme) => ({
  backgroundColor: theme.palette.action.active,
  color: theme.palette.text.white,
})

const StyledHbButton = styled(HbButton)<{ isEditing?: boolean }>(({ theme, isEditing }) => ({
  pointerEvents: 'none',
  // Conditional styles based on the `isEditing` state
  ...(isEditing && editingCustom(theme)),
}))

export const StyledBannerContainer = styled('div')<{ isEditingCustom?: boolean }>(({ theme, isEditingCustom }) => ({
  height: theme.spacing(7.875), // aka 63 px; 1 spacing = 8px
  backgroundColor: theme.palette.background.medium,
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(3),
  paddingLeft: theme.spacing(3),
  // Conditional styles based on `isEditingCustom` state
  ...(isEditingCustom && editingCustom(theme)),
}))

const StyledCountTooltip = styled(Tooltip)(({ theme }) => ({
  // use tooltipClasses to access the tooltip class, for consistency
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.largeContainer.borderRadius,
    color: 'inherit',
    display: 'flex',
    gap: theme.spacing(),
    paddingTop: theme.spacing(),
    paddingBottom: theme.spacing(),
    boxShadow: '0px 3px 26px 0px rgba(0, 0, 0, 0.12)',
  },
}))

export const useColumnTitles = () => {
  return useDashboardColumns().filterableColumns.reduce((acc, c) => {
    acc[c.apiName] = c.title
    return acc
  }, {} as { [apiName: string]: string })
}

interface FilterSummaryBannerProps {
  showCustomViewControls?: boolean
}
export const FilterSummaryBanner = ({ showCustomViewControls = true }: FilterSummaryBannerProps) => {
  const { containerWidth, getContainerRef } = useContainerDimensions()

  const dashboardsSelectors = useDashboardSelectors()
  const appliedFilters = useSelector(dashboardsSelectors.filters.applied.all)
  const filterKeys = mapSearchRequestFilterKeys(appliedFilters).filter((key) => !!key)

  const currentViewTitle = useSelector(dashboardsSelectors.currentViewTitle)

  const { isCustom, isEditing, isCreating } = useSearchRequestInfo()

  const columnTitles = useColumnTitles()

  // if there aren't any defined filter keys, then there's nothing to show in the banner
  if (filterKeys.length === 0) return null
  const isEditingCustom = isCustom && isEditing
  return (
    <StyledBannerContainer isEditingCustom={isEditingCustom} ref={getContainerRef}>
      <StyledFilteredByContainer>
        {isCustom && !isCreating ? (
          <StyledHbButton
            label={currentViewTitle}
            variant="textSecondary"
            Icon={FilterListIcon}
            isEditing={isEditing}
          />
        ) : (
          <>
            <HbText size="lg" color={isEditingCustom ? 'white' : undefined}>
              Filtered by{' '}
            </HbText>
            <StyledChipContainer>
              {filterKeys.slice(0, MAX_FILTERS_SHOWN).map((filterKey) => {
                return <StyledFilterChip key={filterKey} variant="outlined" label={columnTitles[filterKey]} />
              })}
              {filterKeys.length > MAX_FILTERS_SHOWN && (
                <StyledCountTooltip
                  title={
                    <>
                      {filterKeys.slice(MAX_FILTERS_SHOWN).map((filterKey) => {
                        return <StyledFilterChip key={filterKey} variant="outlined" label={columnTitles[filterKey]} />
                      })}
                    </>
                  }
                  placement={Placement.Top}
                >
                  <StyledCountPill>
                    <HbText size="md" color="inherit">
                      +{filterKeys.length - MAX_FILTERS_SHOWN}
                    </HbText>
                  </StyledCountPill>
                </StyledCountTooltip>
              )}
            </StyledChipContainer>
          </>
        )}
      </StyledFilteredByContainer>
      {showCustomViewControls && <CustomViewControls isIconOnlyButtons={!!containerWidth && containerWidth < 930} />}
    </StyledBannerContainer>
  )
}

export default FilterSummaryBanner

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

interface Props extends SvgProps {
  clipPathFill?: string
}

export const CalendarIcon = ({ clipPathFill = '#fff', id = 'iconCalendar', ...restProps }: Props) => (
  <SvgIcon
    id={id}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g clipPath={`url(#${id}_clipPath)`}>
      <path
        d="M19 4.5h-1v-2h-2v2H8v-2H6v2H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2Zm0 16H5v-10h14v10Zm0-12H5v-2h14v2Zm-7 5h5v5h-5v-5Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id={`${id}_clipPath`}>
        <path fill={clipPathFill} d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

import { gql } from '@apollo/client'

export const DOWNLOAD_SURVEY_FILE_UPLOAD_QUERY = gql`
  query FileUploadQuestionFileUrl($questionToken: ID!, $clientId: ID!) {
    surveyFileUpload(questionToken: $questionToken, clientId: $clientId) {
      url
      mimeType
      size
      id
      originalFilename
    }
  }
`

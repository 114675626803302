import { EnumEntry } from 'reducers/applicationReducer'
import {
  BankAccountSignatoryRoleEnum,
  BlockchainTypeEnum,
  FederalRegulatorEnum,
  FinancialInstitutionSubtypeEnum,
  FinancialInstitutionTypeEnum,
  IdentityDocumentTypeEnum,
  InstitutionIdTypeEnum,
  InstitutionRelationshipStatusEnum,
  InstitutionRelationshipTypeEnum,
  LibraryGenderEnum,
  PhoneNumberEnum,
  TinTypeEnum,
  PersonAddressTypeEnum,
  BusinessAddressTypeEnum,
} from 'types/api'

function toEnumEntry(obj: Record<string, string>): EnumEntry[] {
  return Object.entries(obj).map(([key, value]) => ({
    display: value,
    value: key,
  }))
}

export const LibraryGenderStrings: Record<LibraryGenderEnum, string> = {
  [LibraryGenderEnum.Female]: 'Female',
  [LibraryGenderEnum.Male]: 'Male',
  [LibraryGenderEnum.Other]: 'Other',
  [LibraryGenderEnum.Unknown]: 'Unknown',
}

export const LibraryGenderEnums = toEnumEntry(LibraryGenderStrings)

export const TinTypeStrings: Record<TinTypeEnum, string> = {
  [TinTypeEnum.Ein]: 'EIN',
  [TinTypeEnum.Ssn]: 'SSN/ITIN',
  [TinTypeEnum.Foreign]: 'Foreign',
}

export const TinTypeEnums = toEnumEntry(TinTypeStrings)

export const PhoneNumberStrings: Record<PhoneNumberEnum, string> = {
  [PhoneNumberEnum.Home]: 'Home',
  [PhoneNumberEnum.Work]: 'Work',
  [PhoneNumberEnum.Mobile]: 'Mobile',
  [PhoneNumberEnum.Fax]: 'Fax',
}

export const PhoneNumberEnums = toEnumEntry(PhoneNumberStrings)

export const IdentityDocumentTypeStrings: Record<IdentityDocumentTypeEnum, string> = {
  [IdentityDocumentTypeEnum.Passport]: 'Passport',
  [IdentityDocumentTypeEnum.DriversLicense]: "Driver's License/State ID",
  [IdentityDocumentTypeEnum.UkCarRegistration]: 'UK Car Registration',
  [IdentityDocumentTypeEnum.UkNationalHealthServiceNumber]: 'UK NHS Number',
  [IdentityDocumentTypeEnum.UkNationalInsuranceNumber]: 'UK NI Number',
  [IdentityDocumentTypeEnum.UkProofOfAgeStandardSchemeNumber]: 'UK PASS Number',
  [IdentityDocumentTypeEnum.UsAlienRegistration]: 'US Alien registration',
  [IdentityDocumentTypeEnum.Unknown]: 'Unknown',
  [IdentityDocumentTypeEnum.Other]: 'Other',
}

export const IdentityDocumentTypeEnums = toEnumEntry(IdentityDocumentTypeStrings)

export const PersonAddressTypeStrings: Record<PersonAddressTypeEnum, string> = {
  [PersonAddressTypeEnum.Home]: 'Home',
  [PersonAddressTypeEnum.Work]: 'Work',
  [PersonAddressTypeEnum.MailingAddress]: 'Mailing Address',
  [PersonAddressTypeEnum.UsBillingAddress]: 'US Billing Address',
  [PersonAddressTypeEnum.UsShippingAddress]: 'US Shipping Address',
  [PersonAddressTypeEnum.Unknown]: 'Unknown',
  [PersonAddressTypeEnum.Other]: 'Other',
  [PersonAddressTypeEnum.Birthplace]: 'Birthplace',
}

export const PersonAddressTypeEnums = toEnumEntry(PersonAddressTypeStrings)

export const BusinessAddressTypeStrings: Record<BusinessAddressTypeEnum, string> = {
  [BusinessAddressTypeEnum.Branch]: 'Branch',
  [BusinessAddressTypeEnum.MailingAddress]: 'Mailing Address',
  [BusinessAddressTypeEnum.Office]: 'Office',
  [BusinessAddressTypeEnum.Home]: 'Home',
  [BusinessAddressTypeEnum.Work]: 'Work',
  [BusinessAddressTypeEnum.UsBillingAddress]: 'US Billing Address',
  [BusinessAddressTypeEnum.UsShippingAddress]: 'US Shipping Address',
  [BusinessAddressTypeEnum.Unknown]: 'Unknown',
  [BusinessAddressTypeEnum.Other]: 'Other',
}

export const BusinessAddressTypeEnums = toEnumEntry(BusinessAddressTypeStrings)

export const InstitutionRelationshipTypeStrings: Record<InstitutionRelationshipTypeEnum, string> = {
  [InstitutionRelationshipTypeEnum.Accountant]: 'Accountant',
  [InstitutionRelationshipTypeEnum.Agent]: 'Agent',
  [InstitutionRelationshipTypeEnum.Attorney]: 'Attorney',
  [InstitutionRelationshipTypeEnum.Appraiser]: 'Appraiser',
  [InstitutionRelationshipTypeEnum.Borrower]: 'Borrower',
  [InstitutionRelationshipTypeEnum.Customer]: 'Customer',
  [InstitutionRelationshipTypeEnum.Director]: 'Director',
  [InstitutionRelationshipTypeEnum.Employee]: 'Employee',
  [InstitutionRelationshipTypeEnum.None]: 'No Relationship',
  [InstitutionRelationshipTypeEnum.Officer]: 'Officer',
  [InstitutionRelationshipTypeEnum.Other]: 'Other',
  [InstitutionRelationshipTypeEnum.Owner]: 'Owner or Controlling Shareholder',
}

export const InstitutionRelationshipTypeEnums = toEnumEntry(InstitutionRelationshipTypeStrings)

export const InstitutionRelationshipStatusStrings: Record<InstitutionRelationshipStatusEnum, string> = {
  [InstitutionRelationshipStatusEnum.RelationshipContinues]: 'Relationship Continues',
  [InstitutionRelationshipStatusEnum.Resigned]: 'Resigned',
  [InstitutionRelationshipStatusEnum.Suspended]: 'Suspended',
  [InstitutionRelationshipStatusEnum.Terminated]: 'Terminated',
}

export const InstitutionRelationshipStatusEnums = toEnumEntry(InstitutionRelationshipStatusStrings)

export const InstitutionIdTypeStrings: Record<InstitutionIdTypeEnum, string> = {
  [InstitutionIdTypeEnum.Crd]: 'Central Registration Depository Number (CRD)',
  [InstitutionIdTypeEnum.Fintrac]: 'Canadian Financial Institution Number (FINTRAC)',
  [InstitutionIdTypeEnum.Iard]: 'Investment Adviser Registration Depository Number (IARD)',
  [InstitutionIdTypeEnum.Naic]: 'National Association of Insurance Commissioners Code (NAIC)',
  [InstitutionIdTypeEnum.Nmls]: 'Nationwide Mortgage Licensing System ID (NMLS)',
  [InstitutionIdTypeEnum.Rssd]: 'Research, Statistics, Supervision and Discount Number (RSSD)',
  [InstitutionIdTypeEnum.Sec]: 'Securities and Exchange Commission Number (SEC)',
  [InstitutionIdTypeEnum.Gcr]: 'German Commercial Register (Handelsregister)',
}

export const InstitutionIdTypeEnums = toEnumEntry(InstitutionIdTypeStrings)

export const InstitutionTypeStrings: Record<FinancialInstitutionTypeEnum, string> = {
  CASINO_OR_CARD_CLUB: 'Casino/Card Club',
  DEPOSITORY_INSTITUTION: 'Depository Institution',
  INSURANCE_COMPANY: 'Insurance Company',
  MSB: 'Money Services Business (MSB)',
  SECURITIES_OR_FUTURES: 'Securities/Futures',
  LOAN_OR_FINANCE_COMPANY: 'Loan or Finance Company',
  HOUSING_GSE: 'Housing Government Sponsored Enterprise (GSE)',
  OTHER: 'Other',
}

export const InstitutionTypeEnums = toEnumEntry(InstitutionTypeStrings)

export const InstitutionSubtypeStrings: Record<FinancialInstitutionSubtypeEnum, string> = {
  STATE_LICENSED_CASINO: 'State Licensed Casino',
  TRIBAL_AUTHORIZED_CASINO: 'Tribal Authorized Casino',
  CARD_CLUB: 'Card Club',
  CLEARING_BROKER_SECURITIES: 'Clearing Broker-Securities',
  EXECUTION_ONLY_BROKER_SECURITIES: 'Execution-Only Broker Securities',
  FUTURES_COMMISSION_MERCHANT: 'Futures Commission Merchant',
  HOLDING_COMPANY: 'Holding Company',
  INTRODUCING_BROKER_COMMODITIES: 'Introducing Broker-Commodities',
  INTRODUCING_BROKER_SECURITIES: 'Introducing Broker-Securities',
  INVESTMENT_ADVISER: 'Investment Advisor',
  INVESTMENT_COMPANY: 'Investment company',
  RETAIL_FOREIGN_EXCHANGE_DEALER: 'Retail Foreign Exchange Dealer',
  SELF_CLEARING_BROKER_SECURITIES: 'Self-Clearing Broker Securities',
  SUBSIDIARY_OF_BANK: 'Subsidiary of Financial/Bank Holding Company',
  OTHER: 'Other',
}

export const InstitutionSubtypeEnums = toEnumEntry(InstitutionSubtypeStrings)

export const BlockchainTypeStrings: Record<BlockchainTypeEnum, string> = {
  [BlockchainTypeEnum.Bitcoin]: 'Bitcoin',
  [BlockchainTypeEnum.BitcoinCash]: 'Bitcoin Cash',
  [BlockchainTypeEnum.Cardano]: 'Cardano',
  [BlockchainTypeEnum.Eos]: 'EOS',
  [BlockchainTypeEnum.Ethereum]: 'Ethereum',
  [BlockchainTypeEnum.Litecoin]: 'Litecoin',
  [BlockchainTypeEnum.Monero]: 'Monero',
  [BlockchainTypeEnum.Ripple]: 'Ripple',
  [BlockchainTypeEnum.Other]: 'Other',
  [BlockchainTypeEnum.Unknown]: 'Unknown',
}

export const BlockchainTypeEnums = toEnumEntry(BlockchainTypeStrings)

export const FederalRegulatorStrings: Record<FederalRegulatorEnum, string> = {
  BAFIN: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
  FINTRAC: 'Financial Transactions and Reports Analysis Centre of Canada (FINTRAC)',
  FSPK: 'Finansinspektionen (FI)',
  CFTC: 'Commodity Futures Trading Commission (CFTC)',
  FRB: 'Federal Reserve Board (FRB)',
  FDIC: 'Federal Deposit Insurance Corporation (FDIC)',
  IRS: 'Internal Revenue Service (IRS)',
  NCUA: 'National Credit Union Administration (NCUA)',
  OCC: 'Office of the Comptroller of the Currency (OCC)',
  SEC: 'Securities and Exchange Commission (SEC)',
  FHFA: 'Federal Housing Finance Agency (FHFA)',
  NOT_APPLICABLE: 'Not Applicable',
}

export const FederalRegulatorEnums = toEnumEntry(FederalRegulatorStrings)

export const BankAccountSignatoryRoleStrings: Record<BankAccountSignatoryRoleEnum, string> = {
  JOINT: 'Joint',
  NON_ACCOUNT_HOLDER: 'Non-Account Holder',
  PRIMARY: 'Primary',
  UNKNOWN: 'Unknown',
}

export const BankAccountSignatoryRoleEnums = toEnumEntry(BankAccountSignatoryRoleStrings)

/** ****************************************************************************
 * The following enums don't have strict GraphQL types to be based on.
 * There are server-side enums that match these (e.g. DataIngestion::Data::PersistenceEnums),
 * but we don't currently generate TS types for those that we could use here.
 */

export const OperatingSystemStrings: Record<string, string> = {
  WINDOWS: 'Windows',
  ANDROID: 'Android',
  IOS: 'iOS',
  MAC_OS: 'Mac OS/OSX',
  LINUX: 'Linux',
  OTHER: 'Other',
}

export const OperatingSystemEnums = toEnumEntry(OperatingSystemStrings)

export const BrowserStrings: Record<string, string> = {
  FIREFOX: 'Firefox',
  INTERNET_EXPLORER: 'Internet Explorer',
  SAFARI: 'Safari',
  CHROME: 'Chrome',
  TOR: 'Tor Browser',
  OTHER: 'Other',
}

export const BrowserEnums = toEnumEntry(BrowserStrings)

export const CardTypeStrings: Record<string, string> = {
  CREDIT: 'Credit',
  DEBIT: 'Debit',
  PREPAID: 'Prepaid',
  GIFT: 'Gift',
  OTHER: 'Other',
}

export const CardTypeEnums = toEnumEntry(CardTypeStrings)

export const CardNetworkStrings: Record<string, string> = {
  AMERICAN_EXPRESS: 'American Express',
  CARDGUARD: 'Cardguard',
  CHINA_UNIONPAY: 'China UnionPay',
  DANKORT: 'Dankort',
  DINERS_CLUB_INTL: 'Diners Club (International)',
  DINERS_CLUB_US_CAN: 'Diners Club (US & Canada)',
  DISCOVER: 'Discover',
  INSTAPAYMENT: 'Instapayment',
  INTERPAYMENT: 'Interpayment',
  JCB: 'JCB',
  MAESTRO: 'Maestro',
  MASTERCARD: 'Mastercard',
  RUPAY: 'RuPay',
  TROY: 'Troy',
  UATP: 'UATP',
  VERVE: 'Verve',
  VISA: 'Visa',
  OTHER: 'Other',
}

export const CardNetworkEnums = toEnumEntry(CardNetworkStrings)

export const BankAccountTypeStrings: Record<string, string> = {
  DEPOSITORY: 'Depository',
  LOAN: 'Loan',
  MORTGAGE: 'Mortgage',
  BROKERAGE: 'Brokerage',
  OTHER: 'Other',
  UNKNOWN: 'Unknown',
}

export const BankAccountTypeEnums = toEnumEntry(BankAccountTypeStrings)

export const BankAccountSubtypeStrings: Record<string, string> = {
  CHECKING: 'Checking',
  SAVINGS: 'Savings',
  MONEY_MARKET: 'Money Market',
  AUTO_LOAN: 'Auto Loan',
  HOME_LOAN: 'Home Loan',
  HOME_EQUITY_LOAN: 'Home Equity Loan',
  INSTALLMENT_LOAN: 'Installment Loan',
  MORTGAGE_LOAN: 'Mortgage Loan',
  HOME_MORTGAGE: 'Home Mortgage',
  BROKERAGE: 'Brokerage',
  CASH_MANAGEMENT: 'Cash Management',
  IRA: 'IRA',
  CD: 'CD',
  CERTIFICATE_OF_DEPOSIT: 'Certificate of Deposit',
  MUTUAL_FUND: 'Mutual Fund',
  OTHER: 'Other',
  UNKNOWN: 'Unknown',
}

export const BankAccountSubtypeEnums = toEnumEntry(BankAccountSubtypeStrings)

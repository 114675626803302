import { Button, DialogActions, DialogContent, Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles, createStyles } from '@mui/styles'

import classnames from 'classnames'

import { Form, Formik } from 'formik'

import { Moment } from 'moment'

import Loader from 'components/library/Loader'
import DialogTitle from 'components/material/DialogTitle'
import { CheckboxWithLabelField, DateField, SelectField, SplitFieldContainer } from 'components/material/Form'
import KeyCaptureDialog from 'components/material/KeyCaptureDialog'
import { DateRangeType, ExportType } from 'dashboards/shared/react/export'
import { CheckCircleFilledIcon, ErrorIcon } from 'icons'

import { Theme, WithStyles } from 'types/hb'

const messageStyles = (theme: Theme) =>
  createStyles({
    root: {
      flex: 1,
      display: 'flex',
    },
    text: {
      margin: 4,
    },
    icon: {
      margin: 4,
    },
    success: {
      color: theme.palette.styleguide.green,
    },
    error: {
      color: theme.palette.styleguide.orange,
    },
    empty: {},
  })

type MessageState = 'success' | 'error' | 'empty'
interface MessageProps extends WithStyles<typeof messageStyles> {
  state: MessageState
}

const messageByState = (state: MessageState) => {
  if (state === 'success') {
    return 'Download complete.'
  }
  if (state === 'error') {
    return "Your download is taking a while to generate. We'll send you an email when it's done."
  }

  return ''
}

const iconByState = (state: MessageState, className: string) => {
  if (state === 'success') {
    return <CheckCircleFilledIcon className={className} />
  }
  if (state === 'error') {
    return <ErrorIcon className={className} />
  }

  return null
}

export const DialogMessage = withStyles(messageStyles)((props: MessageProps) => {
  const { classes, state } = props
  const rootClasses = classnames(classes.root, {
    [classes.success]: state === 'success',
    [classes.error]: state === 'error',
  })
  const textClasses = classnames(classes.text, {
    [classes.success]: state === 'success',
    [classes.error]: state === 'error',
  })

  return (
    <div className={rootClasses}>
      {iconByState(state, classes.icon)}
      <Typography className={textClasses} variant="body1">
        {messageByState(state)}
      </Typography>
    </div>
  )
})

const getMessageState = (isDownloaded: boolean, isErrored: boolean) => {
  if (isDownloaded) {
    return 'success'
  }
  if (isErrored) {
    return 'error'
  }
  return 'empty'
}

interface Props {
  open: boolean
  loading: boolean
  isDownloaded: boolean
  isErrored: boolean
  onClose: () => void
  onSubmit: (fromDate: string, toDate: string, dateRangeType: DateRangeType, type: ExportType) => void
}

interface FormValues {
  fromDate?: Moment
  toDate?: Moment
  dateRangeType?: DateRangeType
  type?: ExportType
  acceptConsequences?: boolean
}

export default function ExportCSVDialog(props: Props) {
  const { open, onClose, onSubmit, loading, isDownloaded, isErrored } = props

  const onValidate = (values: FormValues) => {
    const errors: {
      fromDate?: string
      toDate?: string
      dateRangeType?: string
      type?: string
      acceptConsequences?: string
    } = {}

    if (!values.type) {
      errors.type = 'Required'
    }

    if (!values.fromDate) {
      errors.fromDate = 'Required'
    }

    if (!values.toDate) {
      errors.toDate = 'Required'
    }

    if (!values.dateRangeType) {
      errors.dateRangeType = 'Required'
    }

    if (!values.acceptConsequences) {
      errors.acceptConsequences = 'Required'
    }

    return errors
  }

  const initialValues: FormValues = {}

  const renderDownload = !loading && !isDownloaded && !isErrored

  return (
    <KeyCaptureDialog open={open} onClose={onClose} fullWidth>
      <Formik
        onSubmit={(values: Required<FormValues>, formikActions) => {
          onSubmit(
            values.fromDate.format('YYYY-MM-DD'),
            values.toDate.format('YYYY-MM-DD'),
            values.dateRangeType,
            values.type
          )
          formikActions.setSubmitting(false)
        }}
        initialValues={initialValues}
        validate={onValidate}
        validateOnMount
      >
        {({ handleSubmit, isValidating, isValid, isSubmitting }) => (
          <>
            {loading && <Loader variant="local" />}
            <DialogTitle value="Download Case Data" />
            <DialogContent>
              <Form>
                <SelectField
                  options={[
                    { display: 'Review Detail', value: 'review' },
                    { display: 'Case Summary', value: 'case' },
                  ]}
                  label="Type"
                  placeholder="Select one"
                  name="type"
                  margin="normal"
                  fullWidth
                  required
                  testId="field_select_type"
                />
                <SelectField
                  options={[
                    { display: 'Created', value: 'created' },
                    { display: 'Completed', value: 'completed' },
                  ]}
                  label="Review has been"
                  placeholder="Select one"
                  name="dateRangeType"
                  margin="normal"
                  fullWidth
                  required
                  testId="field_select_review_status"
                />
                <SplitFieldContainer>
                  <DateField
                    name="fromDate"
                    label="From Date"
                    margin="normal"
                    required
                    fullWidth
                    testId="field_date_picker_case_data_from"
                  />
                  <DateField
                    name="toDate"
                    label="To Date"
                    margin="normal"
                    required
                    fullWidth
                    testId="field_date_picker_case_data_to"
                  />
                </SplitFieldContainer>
                <CheckboxWithLabelField
                  name="acceptConsequences"
                  color="primary"
                  Label={{
                    label:
                      'I understand that the data I am about to download may include sensitive or confidential information.',
                  }}
                />
              </Form>
            </DialogContent>
            <DialogActions>
              <DialogMessage state={getMessageState(isDownloaded, isErrored)} />
              <Button disabled={isValidating} onClick={onClose}>
                Close
              </Button>
              {renderDownload && (
                <Button
                  disabled={!isValid || isValidating || isSubmitting || loading}
                  variant="outlined"
                  color="primary"
                  type="button"
                  autoFocus
                  onClick={() => handleSubmit()}
                >
                  Download
                </Button>
              )}
            </DialogActions>
          </>
        )}
      </Formik>
    </KeyCaptureDialog>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const AutoAwesomeAiGradientIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M15.9219 6.66666L16.5803 5.20833L18.0386 4.55C18.3636 4.4 18.3636 3.94166 18.0386 3.79166L16.5803 3.13333L15.9219 1.66666C15.7719 1.34166 15.3136 1.34166 15.1636 1.66666L14.5053 3.125L13.0386 3.78333C12.7136 3.93333 12.7136 4.39166 13.0386 4.54166L14.4969 5.2L15.1553 6.66666C15.3053 6.99166 15.7719 6.99166 15.9219 6.66666ZM9.28861 7.91666L7.96361 5C7.67194 4.35 6.73861 4.35 6.44694 5L5.12194 7.91666L2.20527 9.24166C1.55527 9.54166 1.55527 10.4667 2.20527 10.7583L5.12194 12.0833L6.44694 15C6.74694 15.65 7.67194 15.65 7.96361 15L9.28861 12.0833L12.2053 10.7583C12.8553 10.4583 12.8553 9.53333 12.2053 9.24166L9.28861 7.91666ZM15.1553 13.3333L14.4969 14.7917L13.0386 15.45C12.7136 15.6 12.7136 16.0583 13.0386 16.2083L14.4969 16.8667L15.1553 18.3333C15.3053 18.6583 15.7636 18.6583 15.9136 18.3333L16.5719 16.875L18.0386 16.2167C18.3636 16.0667 18.3636 15.6083 18.0386 15.4583L16.5803 14.8L15.9219 13.3333C15.7719 13.0083 15.3053 13.0083 15.1553 13.3333Z"
        fill="url(#linear_gradient)"
      />
      <defs>
        <linearGradient
          id="linear_gradient"
          x1="1.71777"
          y1="1.42291"
          x2="15.3641"
          y2="-1.91845"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF8989" />
          <stop offset="1" stopColor="#7F00FF" />
        </linearGradient>
      </defs>
    </g>
  </SvgIcon>
)

export default AutoAwesomeAiGradientIcon

import { getIconForDataType } from './helpers'
import { DataIconProps } from './types'

export default function DataIcon({ className, dataType }: DataIconProps) {
  const IconForType = getIconForDataType(dataType)

  if (!IconForType) {
    return null
  }

  return <IconForType className={className} />
}

import { useEffect, useMemo } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { fetchTagGroups } from 'actions/organizationActions'
import { useDispatch } from 'actions/store'

import {
  GetReviewTypePrepopulatedFieldsQuery,
  GetReviewTypePrepopulatedFieldsQueryVariables,
  TaskTypeTaggingFragment,
} from './__generated__/useReviewTypePrepopulatedFields.generated'

const REVIEW_TYPE_PREPOPULATED_FIELDS_QUERY = gql`
  query GetReviewTypePrepopulatedFields($canonicalId: ID!) {
    canonicalReviewType(canonicalId: $canonicalId) {
      canonicalId
      token
      currentReviewType {
        token
        actions {
          token
          title
          slug
          choices {
            token
            label
            key
            accountType
          }
          tasks {
            __typename
            token
            slug
            ... on TaskTypeTextEntry {
              params {
                sarNarrative
              }
            }
            ...TaskTypeTagging
          }
        }
      }
    }
  }

  fragment TaskTypeTagging on TaskTypeTagging {
    __typename
    token
    title
    params {
      categories
      groups
      excludeGroups
      includeOnly
    }
  }
`

export const useReviewTypePrepopulatedFields = ({ canonicalId }: { canonicalId?: string }) => {
  const dispatch = useDispatch()
  const [getReviewType, { data }] = useLazyQuery<
    GetReviewTypePrepopulatedFieldsQuery,
    GetReviewTypePrepopulatedFieldsQueryVariables
  >(REVIEW_TYPE_PREPOPULATED_FIELDS_QUERY)

  useEffect(() => {
    if (canonicalId) {
      getReviewType({ variables: { canonicalId }, fetchPolicy: 'cache-first' })
    }
  }, [canonicalId, getReviewType])

  const hasNarrative = useMemo(
    () =>
      data?.canonicalReviewType?.currentReviewType?.actions.some((action) =>
        action.tasks.some((task) => task.__typename === 'TaskTypeTextEntry')
      ),
    [data?.canonicalReviewType?.currentReviewType?.actions]
  )

  const tagggingTasks = useMemo(() => {
    if (!data) return []
    const currentReviewType = data.canonicalReviewType?.currentReviewType
    if (!currentReviewType) return []
    const tasks: TaskTypeTaggingFragment[] = []
    currentReviewType.actions.forEach((action) => {
      action.tasks.forEach((task) => {
        if (task.__typename === 'TaskTypeTagging') {
          tasks.push(task)
        }
      })
    })
    return tasks
  }, [data])

  const actions = useMemo(() => {
    if (!data) return []
    const currentReviewType = data.canonicalReviewType?.currentReviewType
    if (!currentReviewType) return []
    return currentReviewType.actions
  }, [data])

  useEffect(() => {
    tagggingTasks.forEach((task) => {
      const { params, token: taskToken } = task
      const { categories, groups, excludeGroups, includeOnly } = params
      dispatch(fetchTagGroups(taskToken, categories, groups, excludeGroups, includeOnly, ''))
    })
  }, [dispatch, tagggingTasks])

  return useMemo(() => ({ hasNarrative, tagggingTasks, actions }), [hasNarrative, tagggingTasks, actions])
}

// Disabling this since we want to assign to state in reducers
/* eslint-disable no-param-reassign */
import { useCallback } from 'react'

import { ActionCreatorWithPayload, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { matchPath } from 'react-router-dom'

import { EntityTabType } from 'actions/entityActions.helpers'
import { SnakeLibraryDataTypeKey } from 'actions/importingFields.types'
import { State, Thunk, useDispatch, useSelector } from 'actions/store'
import { getEntityCreateTabTitle, LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { useFeatureFlag } from 'hooks'
import { getQueryTabFromTabInfo } from 'reducers/tabReducer.utils'
import { FeatureFlag, GeneratedDocumentType, LibraryEntity, ScreeningSearchProvider } from 'types/api'
import { assertUnreachable } from 'types/hb'
import { readQuery, updateQuery } from 'utils/history'

interface TabBase {
  pinned?: boolean
  permanent?: boolean
  size?: 'small' | 'medium' | 'large' | 'xl'
  popOutHref?: string
  highlight?: boolean
}

export interface LibraryTabDefinition extends TabBase {
  type: 'library'
  entityToken: string
  entityType: LibraryGqlQueryType
  linkToken?: string
  tab: EntityTabType
  attachmentToken?: string | null
}

export interface LibraryCreateTabDefinition extends TabBase {
  type: 'libraryCreate'
  currentStep?: 'create-entity' | 'attach-to-case'
  entityType: SnakeLibraryDataTypeKey
  libraryToken?: string
}

export interface OverviewTabDefinition extends TabBase {
  type: 'overview'
  size?: 'small'
}

export interface FilesTabDefinition extends TabBase {
  type: 'files'
  size?: 'medium'
}

export interface AlertsTabDefinition extends TabBase {
  type: 'alerts'
}

export interface TransactionsTabDefinition extends TabBase {
  type: 'transactions'
  viewing?: 'transactions' | 'overview'
}

export function buildTransactionsTab(
  caseToken: string,
  tab: Omit<TransactionsTabDefinition, 'type' | 'popOutHref'> = {}
): TransactionsTabDefinition {
  return {
    type: 'transactions',
    popOutHref: `/dashboard/cases/${caseToken}/transactions`,
    ...tab,
  }
}

export interface LocationsTabDefinition extends TabBase {
  type: 'locations'
}
export interface ConnectionsTabDefinition extends TabBase {
  type: 'connections'
}

export interface OtherInfoTabDefinition extends TabBase {
  type: 'otherInfo'
}

export interface NewInformationRequestTabDefinition extends TabBase {
  type: 'newInformationRequest'
}
export interface InformationRequestTabDefinition extends TabBase {
  type: 'informationRequest'
  requestToken: string
  showSubmissionSuccess?: boolean
}
export interface InboundRequestTabDefinition extends TabBase {
  type: 'inboundRequest'
  inboundToken: string
}

export interface InformationRequestsTabDefinition extends TabBase {
  type: 'informationRequests'
}

export interface ReviewTabDefinition extends TabBase {
  type: 'review'
  reviewToken: string
  // slug for a selected task or action
  action?: string
  task?: string
}

export interface ReviewsTabDefinition extends TabBase {
  type: 'reviews'
}

export interface ThomsonReutersClearTabDefinition extends TabBase {
  type: 'thomsonReutersClear'
  entityToken?: string
  entityType?: LibraryEntity['__typename']
}

export interface MiddeskTabDefinition extends TabBase {
  type: 'middesk'
  businessEntityToken: string
}

export interface SanctionsScreeningTabDefinition extends TabBase {
  type: 'sanctionsScreening'
  entityToken: string
  provider: ScreeningSearchProvider
  label: string
}
export interface KybAssistantTabDefinition extends TabBase {
  type: 'kybAssistant'
  name: string
}

export type AttachmentTypeAttachmentProps = {
  attachmentToken: string
}

export type AttachmentTypeReviewDocumentProps = {
  displayName: string
  documentName: GeneratedDocumentType
  reviewToken: string
}

export type AttachmentTypeSurveyUploadFileProps = {
  questionToken: string
  clientId: string
}

export type AttachmentTypeLibraryProps = {
  attachmentToken: string
  attachmentFilename: string
  ownerToken?: string
  ownerType?: LibraryGqlQueryType
}

export type AttachmentTabDefinition = TabBase & {
  type: 'attachment'
  summaryOpen?: boolean
} & (
    | ({
        attachmentType: 'attachment'
      } & AttachmentTypeAttachmentProps)
    | ({
        attachmentType: 'library'
      } & AttachmentTypeLibraryProps)
    | ({
        attachmentType: 'surveyFileUpload'
      } & AttachmentTypeSurveyUploadFileProps)
    | ({
        attachmentType: 'reviewDocument'
      } & AttachmentTypeReviewDocumentProps)
  )

export type AITabDefinition = TabBase & {
  type: 'ai'
  reviewToken: string
} & {
  // We'll eventually be adding more actions here
  action: 'generateReviewNarrative'
}

export type PageTabDefinition =
  | LibraryTabDefinition
  | LibraryCreateTabDefinition
  | OverviewTabDefinition
  | FilesTabDefinition
  | AlertsTabDefinition
  | TransactionsTabDefinition
  | LocationsTabDefinition
  | ConnectionsTabDefinition
  | OtherInfoTabDefinition
  | NewInformationRequestTabDefinition
  | InformationRequestTabDefinition
  | InboundRequestTabDefinition
  | InformationRequestsTabDefinition
  | ReviewTabDefinition
  | ReviewsTabDefinition
  | ThomsonReutersClearTabDefinition
  | MiddeskTabDefinition
  | SanctionsScreeningTabDefinition
  | KybAssistantTabDefinition
  | AttachmentTabDefinition
  | AITabDefinition

type PageTabBase = { title: string; id: string }

export type PageTab = PageTabBase & PageTabDefinition

export type AlertsTab = PageTab & AlertsTabDefinition

export type ConnectionsTab = PageTab & ConnectionsTabDefinition

export type FilesTab = PageTab & FilesTabDefinition

export type InformationRequestTab = PageTab & InformationRequestTabDefinition

export type InboundRequestTab = PageTab & InboundRequestTabDefinition

export type InformationRequestsTab = PageTab & InformationRequestsTabDefinition

export type LibraryPageTab = PageTabBase & LibraryTabDefinition

export type LibraryCreatePageTab = PageTabBase & LibraryCreateTabDefinition

export type LocationsTab = PageTab & LocationsTabDefinition

export type NewInformationRequestTab = PageTab & NewInformationRequestTabDefinition

export type OtherInfoTab = PageTab & OtherInfoTabDefinition

export type ReviewTab = PageTab & ReviewTabDefinition

export type ReviewsTab = PageTab & ReviewsTabDefinition

export type ThomsonReutersClearTab = PageTab & ThomsonReutersClearTabDefinition

export type MiddeskTab = PageTab & MiddeskTabDefinition

export type SanctionsScreeningTab = PageTab & SanctionsScreeningTabDefinition

export type TransactionsTab = PageTab & TransactionsTabDefinition

export type KybAssistantTab = PageTab & KybAssistantTabDefinition

export type AttachmentTab = PageTab & AttachmentTabDefinition

export type AITab = PageTab & AITabDefinition

interface FeatureFlagsMeta {
  featureFlags?: Partial<Record<keyof typeof FeatureFlag, boolean>>
}

export interface PageMeta extends FeatureFlagsMeta {
  caseToken: string
}

export interface TabInfo {
  // Tabs stores the currently open tabs on this page
  tabs: PageTab[]
  // The focused tab id
  activeTabId: string
  // Meta information about the page
  meta: PageMeta
}

export interface TabState {
  tabsByPage: {
    // Pathname represents the URL of the page
    [pathname: string]: TabInfo
  }
}

export function doesCurrentPageSupportTabs(state: State): boolean {
  const path = state.router.location.pathname
  return path in state.tabs.tabsByPage
}

export function getTabsForCurrentPage(state: State): TabInfo | undefined {
  const { pathname } = state.router.location
  return state.tabs.tabsByPage?.[pathname]
}

export function getCasePagePinnedTab(state: State): PageTab | undefined {
  const tabInfo = getTabsForCurrentPage(state)
  return tabInfo?.tabs?.find((tab) => !!tab.pinned)
}

interface ReviewMatchParams {
  token: string
}
interface ReviewWithStepMatchParams extends ReviewMatchParams {
  type: 'actions' | 'tasks'
  slug: string
}

// If the given internal link corresponds to a valid tab definition, returns that.
// If it doesn't, returns null.
// TODO: doesn't handle query parameters
export function getTabForLink(state: State, url: string): PageTabDefinition | null {
  if (!doesCurrentPageSupportTabs(state)) {
    return null
  }

  const currentPage = getTabsForCurrentPage(state)

  const queryIndex = url.indexOf('?')
  const to = queryIndex > -1 ? url.substring(0, queryIndex) : url

  const libMatch = matchPath<{
    token: string
    type: LibraryGqlQueryType
    tab: EntityTabType
    linkToken?: string
  }>(to, {
    path: [
      '/dashboard/library/:type/:token/:tab/:linkToken',
      '/dashboard/library/:type/:token/:tab',
      '/dashboard/library/:type/:token',
    ],
  })

  if (libMatch) {
    return {
      type: 'library',
      entityToken: libMatch.params.token,
      entityType: libMatch.params.type,
      linkToken: libMatch.params.linkToken,
      tab: libMatch.params.tab ?? 'information',
    }
  }

  const reviewWithStepMatch = matchPath<ReviewWithStepMatchParams>(to, {
    path: ['/dashboard/reviews/:token/:type/:slug'],
  })
  if (reviewWithStepMatch) {
    return {
      type: 'review',
      reviewToken: reviewWithStepMatch.params.token,
      action: reviewWithStepMatch.params.type === 'actions' ? reviewWithStepMatch.params.slug : undefined,
      task: reviewWithStepMatch.params.type === 'tasks' ? reviewWithStepMatch.params.slug : undefined,
    }
  }
  const reviewOverviewMatch = matchPath<ReviewMatchParams>(to, {
    path: ['/dashboard/reviews/:token/overview'],
  })
  if (reviewOverviewMatch) {
    return {
      type: 'review',
      reviewToken: reviewOverviewMatch.params.token,
    }
  }

  if (!currentPage) {
    return null
  }

  const caseMatch = matchPath<{ caseToken: string }>(to, {
    path: '/dashboard/cases/:caseToken/information',
  })

  if (caseMatch && caseMatch.params.caseToken === currentPage.meta.caseToken) {
    return { type: 'overview' }
  }

  const insightsMatch = matchPath<{ caseToken: string }>(to, {
    path: '/dashboard/cases/:caseToken/insights/transactions',
  })

  if (insightsMatch && insightsMatch.params.caseToken === currentPage.meta.caseToken) {
    return { type: 'transactions', viewing: 'transactions' }
  }

  return null
}

export const getAttachmentTypeSurveyTabId = (tab: AttachmentTypeSurveyUploadFileProps) =>
  `${tab.questionToken}-${tab.clientId}`

const getAttachmentTabId = (tab: AttachmentTabDefinition) => {
  if (tab.attachmentType === 'reviewDocument') {
    return `${tab.reviewToken}-${tab.documentName}`
  }

  return tab.attachmentType === 'surveyFileUpload' ? getAttachmentTypeSurveyTabId(tab) : tab.attachmentToken
}

const getAITabId = (tab: AITabDefinition) => {
  // For each review, an AI tab can exist for each action
  return `${tab.action}-${tab.reviewToken}`
}

function getInitialTitle(tab: PageTabDefinition, meta?: FeatureFlagsMeta): string {
  switch (tab.type) {
    case 'library':
    case 'review':
    case 'informationRequest':
    case 'inboundRequest':
    case 'attachment':
      return 'Loading…'
    case 'ai':
      return 'Hummingbird AI'
    case 'alerts':
      return 'Alerts'
    case 'overview':
      return ''
    case 'files':
      return 'Files'
    case 'transactions':
      return 'Transactions'
    case 'libraryCreate':
      return getEntityCreateTabTitle(tab.entityType)
    case 'locations':
      return 'Locations'
    case 'connections':
      return 'Connections'
    case 'otherInfo':
      return meta?.featureFlags?.RenameOtherInfo ? 'Additional Details' : 'Other Info'
    case 'newInformationRequest':
      return 'New request'
    case 'informationRequests':
      return 'Requests'
    case 'reviews':
      return 'Reviews'
    case 'thomsonReutersClear':
      return 'Thomson Reuters CLEAR'
    case 'middesk':
      return 'Middesk'
    case 'sanctionsScreening':
      return tab.label
    case 'kybAssistant':
      return `KYB Search (${tab.name})`
    default:
      return assertUnreachable(tab)
  }
}

const getLibraryCreateTabKey = (entityType: SnakeLibraryDataTypeKey) => `${entityType}Create` as const

export const getTabId = (tab: PageTabDefinition): string => {
  switch (tab.type) {
    case 'library':
      return `${tab.entityToken}${tab.linkToken ?? ''}`
    case 'libraryCreate':
      return getLibraryCreateTabKey(tab.entityType)
    case 'informationRequest':
      return tab.requestToken
    case 'inboundRequest':
      return tab.inboundToken
    case 'review':
      return tab.reviewToken
    case 'kybAssistant':
      return tab.name
    case 'sanctionsScreening':
      return `${tab.entityToken}${tab.provider}`
    case 'attachment':
      return getAttachmentTabId(tab)
    case 'ai':
      return getAITabId(tab)
    case 'files':
    case 'transactions':
    case 'locations':
    case 'connections':
    case 'otherInfo':
    case 'newInformationRequest':
    case 'informationRequests':
    case 'reviews':
    case 'thomsonReutersClear':
    case 'middesk':
    case 'alerts':
    case 'overview':
      return tab.type
    default:
      return assertUnreachable(tab)
  }
}

const getUpdatedPageTabs = (stateTabs: PageTab[], newTabs: PageTab[]) => {
  // prioritize a pinned tab from the new tabs data
  let pinnedTab: PageTab | undefined

  // reference map for merging new tabs with current state tabs
  const newTabsMap = {} as { [key: string]: PageTab }
  newTabs.forEach((tab) => {
    let t = tab
    // only select the first pinned tab in the new data, ignore the others
    if (!pinnedTab && t.pinned) pinnedTab = t
    // weeding out remaining invalid pinned query tabs
    else if (pinnedTab && t.pinned) {
      const { pinned, ...rest } = tab
      t = rest
    }

    switch (t.type) {
      case 'library':
        newTabsMap[t.entityToken] = t
        break
      case 'libraryCreate':
        newTabsMap[getLibraryCreateTabKey(t.entityType)] = t
        break
      case 'review':
        newTabsMap[t.reviewToken] = t
        break
      case 'informationRequest':
        newTabsMap[t.requestToken] = t
        break
      case 'inboundRequest':
        newTabsMap[t.inboundToken] = t
        break
      case 'kybAssistant':
        newTabsMap[t.name] = t
        break
      case 'sanctionsScreening':
        newTabsMap[t.provider] = t
        break
      case 'attachment':
        newTabsMap[getAttachmentTabId(t)] = t
        break
      case 'ai':
        newTabsMap[getAITabId(t)] = t
        break
      case 'overview':
      case 'files':
      case 'alerts':
      case 'transactions':
      case 'locations':
      case 'reviews':
      case 'connections':
      case 'otherInfo':
      case 'newInformationRequest':
      case 'informationRequests':
      case 'thomsonReutersClear':
      case 'middesk':
        newTabsMap[t.type] = t
        break
      default:
        assertUnreachable(t)
    }
  })

  const updatedTabs: PageTab[] = [] // return value

  stateTabs.forEach((t) => {
    let newTab: PageTab | undefined
    // creating a shallow copy so we can manipulate it below
    let mergedTab = { ...t }

    switch (t.type) {
      case 'library':
        newTab = newTabsMap[t.entityToken]
        if (newTab) {
          delete newTabsMap[t.entityToken]
          mergedTab = { ...t, ...newTab } as LibraryPageTab
        }
        break
      case 'libraryCreate': {
        const libraryCreateKey = getLibraryCreateTabKey(t.entityType)
        newTab = newTabsMap[libraryCreateKey]
        if (newTab) {
          delete newTabsMap[libraryCreateKey]
          mergedTab = { ...t, ...newTab } as LibraryCreatePageTab
        }
        break
      }
      case 'review':
        newTab = newTabsMap[t.reviewToken]
        if (newTab) {
          delete newTabsMap[t.reviewToken]
          mergedTab = { ...t, ...newTab } as ReviewTab
        }
        break
      case 'informationRequest':
        newTab = newTabsMap[t.requestToken]
        if (newTab) {
          delete newTabsMap[t.requestToken]
          mergedTab = { ...t, ...newTab } as InformationRequestTab
        }
        break
      case 'inboundRequest':
        newTab = newTabsMap[t.inboundToken]
        if (newTab) {
          delete newTabsMap[t.inboundToken]
          mergedTab = { ...t, ...newTab } as InboundRequestTab
        }
        break
      case 'kybAssistant':
        newTab = newTabsMap[t.name]
        if (newTab) {
          delete newTabsMap[t.name]
          mergedTab = { ...t, ...newTab } as KybAssistantTab
        }
        break
      case 'sanctionsScreening':
        newTab = newTabsMap[t.provider]
        if (newTab) {
          delete newTabsMap[t.provider]
          mergedTab = { ...t, ...newTab } as SanctionsScreeningTab
        }
        break
      case 'attachment':
        newTab = newTabsMap[getAttachmentTabId(t)]
        if (newTab) {
          delete newTabsMap[getAttachmentTabId(t)]
          mergedTab = { ...t, ...newTab } as AttachmentTab
        }
        break
      case 'ai':
        newTab = newTabsMap[getAITabId(t)]
        if (newTab) {
          delete newTabsMap[getAITabId(t)]
          mergedTab = { ...t, ...newTab } as AITab
        }
        break
      case 'overview':
      case 'files':
      case 'alerts':
      case 'transactions':
      case 'locations':
      case 'reviews':
      case 'connections':
      case 'otherInfo':
      case 'newInformationRequest':
      case 'informationRequests':
      case 'thomsonReutersClear':
      case 'middesk':
        newTab = newTabsMap[t.type]
        if (newTab) {
          delete newTabsMap[t.type]
          mergedTab = { ...t, pinned: newTab.pinned }
        }
        break
      default:
        assertUnreachable(t)
    }

    // strip the old pinned tab
    if (pinnedTab && newTab !== pinnedTab && mergedTab.pinned) delete mergedTab.pinned

    updatedTabs.push(mergedTab)
  })

  Object.values(newTabsMap).forEach((t) => updatedTabs.push(t))

  return updatedTabs
}

const tabSlice = createSlice({
  name: 'tabs',

  initialState: {
    tabsByPage: {},
  } as TabState,

  reducers: {
    setTabs: {
      reducer(
        state,
        action: PayloadAction<{
          pathname: string
          tabs: PageTab[]
          meta: PageMeta
          shouldOverwrite?: boolean
          activeTabId?: string
        }>
      ) {
        const { pathname, tabs: newTabs, meta, shouldOverwrite, activeTabId } = action.payload
        // existing tabs for the page to merge the new tabs data with
        // if overwriting, then we ignore current state tab values
        const currentPageTabs = shouldOverwrite ? [] : state.tabsByPage[pathname]?.tabs || []

        const updatedTabs = getUpdatedPageTabs(currentPageTabs, newTabs)

        state.tabsByPage[pathname] = {
          tabs: updatedTabs,
          meta,
          activeTabId: (activeTabId || updatedTabs[0]?.id) ?? 'overview',
        }
      },
      prepare(payload: {
        pathname: string
        tabs: PageTabDefinition[]
        meta: PageMeta
        shouldOverwrite?: boolean
        activeTabId?: string
      }) {
        return {
          payload: {
            pathname: payload.pathname,
            tabs: payload.tabs.map((tab) => ({
              ...tab,
              title: getInitialTitle(tab, payload.meta),
              id: getTabId(tab),
            })),
            meta: payload.meta,
            shouldOverwrite: payload.shouldOverwrite,
            activeTabId: payload.activeTabId,
          },
        }
      },
    },

    setActiveTab(state, action: PayloadAction<{ pathname: string; tabId: string }>) {
      const { pathname, tabId } = action.payload
      const tabState = state.tabsByPage[pathname]
      if (tabState) {
        tabState.activeTabId = tabId
      }
    },

    setTabTitle(state, action: PayloadAction<{ pathname: string; tabId: string; title: string }>) {
      const { pathname, tabId, title } = action.payload
      const tabs = state.tabsByPage[pathname]?.tabs
      if (tabs) {
        const tab = tabs.find((t) => t.id === tabId)
        if (tab) {
          tab.title = title
        }
      }
    },

    openTab: {
      reducer(
        state,
        action: PayloadAction<{
          meta?: FeatureFlagsMeta
          pathname: string
          tab: PageTab
          openInBackground?: boolean
        }>
      ) {
        const { pathname, tab, openInBackground = false } = action.payload
        const tabState = state.tabsByPage[pathname]

        // some default tab selection logic for tabs:
        const handleForegroundOpen = (newOrMatch: PageTab) => {
          // do not update active tab if opening in background or if pinning the opened tab
          if (openInBackground || !!newOrMatch.pinned) return
          tabState.activeTabId = newOrMatch.id
        }

        // some default tab selection logic for tabs:
        let isMatch = (t: PageTab) => t.type === tab.type
        let handleMatch = (match: PageTab) => {
          if (match.pinned) return // if already pinned, ignore
          handleForegroundOpen(match)
        }
        const handleNew = () => {
          tabState.tabs.push(tab)
          handleForegroundOpen(tab)
        }

        // small variations in default logic based on tab type
        if (tab.type === 'library') {
          isMatch = (t: PageTab) =>
            t.type === 'library' &&
            t.entityToken === tab.entityToken &&
            (t.linkToken === tab.linkToken || !t.linkToken || !tab.linkToken)
        } else if (tab.type === 'libraryCreate') {
          isMatch = (t: PageTab) => t.type === 'libraryCreate' && t.entityType === tab.entityType
        } else if (tab.type === 'review') {
          isMatch = (t: PageTab) => t.type === 'review' && t.reviewToken === tab.reviewToken
        } else if (tab.type === 'ai') {
          isMatch = (t: PageTab) => t.type === 'ai' && t.reviewToken === tab.reviewToken
        } else if (tab.type === 'informationRequest') {
          isMatch = (t: PageTab) => t.type === 'informationRequest' && t.requestToken === tab.requestToken
        } else if (tab.type === 'kybAssistant') {
          isMatch = (t: PageTab) => t.type === 'kybAssistant' && t.name === tab.name
        } else if (tab.type === 'sanctionsScreening') {
          isMatch = (t: PageTab) =>
            t.type === 'sanctionsScreening' && t.provider === tab.provider && t.entityToken === tab.entityToken
        } else if (tab.type === 'attachment') {
          isMatch = (t: PageTab) => {
            if (t.type !== 'attachment') {
              return false
            }
            if (t.attachmentType === 'attachment' && tab.attachmentType === 'attachment') {
              return t.attachmentToken === tab.attachmentToken
            }
            if (t.attachmentType === 'library' && tab.attachmentType === 'library') {
              return t.attachmentToken === tab.attachmentToken
            }
            if (t.attachmentType === 'surveyFileUpload' && tab.attachmentType === 'surveyFileUpload') {
              return getAttachmentTypeSurveyTabId(t) === getAttachmentTypeSurveyTabId(tab)
            }
            if (t.attachmentType === 'reviewDocument' && tab.attachmentType === 'reviewDocument') {
              return getAttachmentTabId(t) === getAttachmentTabId(tab)
            }
            return false
          }
        }

        // handle a match where the tab's secondary details may get updated,
        // e.g. for a library tab, the `tab.tab` might change, i.e. EntityTabType ('information', 'alerts', etc)
        // e.g. for a review tab, the workflow `tab.action` or `tab.task` might change
        // e.g. for a transactions tab, the sort/filter `tab.criteria` might change
        // this logic should be common to updatable tabs:
        const updatableTabs = ['library', 'review', 'thomsonReutersClear', 'transactions', 'middesk']
        const tabIsUpdatableType = (t: PageTab): t is LibraryPageTab | ReviewTab => updatableTabs.includes(tab.type)
        if (tabIsUpdatableType(tab)) {
          handleMatch = (match: LibraryPageTab | ReviewTab) => {
            const i = tabState.tabs.indexOf(match)
            // update secondary details in tab if the tab is updatable
            if (match.type === 'library' && tab.type === 'library') {
              tabState.tabs[i] = {
                ...match,
                ...tab,
                // Keep existing linkToken if new one isn't provided
                linkToken: tab.linkToken ?? match.linkToken,
                pinned: match.pinned,
                id: match.id,
              }
            } else {
              tabState.tabs[i] = { ...match, ...tab, pinned: match.pinned, id: match.id }
            }

            if (match.pinned) return // if already pinned, no need to open
            handleForegroundOpen(match)
          }
        }

        const tabMatch = tabState.tabs.find(isMatch)
        if (tabMatch) {
          handleMatch(tabMatch)
          return
        }
        handleNew()
      },
      prepare(payload: {
        meta?: FeatureFlagsMeta
        pathname: string
        tab: PageTabDefinition
        openInBackground?: boolean
      }) {
        return {
          payload: {
            meta: payload.meta,
            pathname: payload.pathname,
            tab: {
              ...payload.tab,
              title: getInitialTitle(payload.tab, payload.meta),
              id: getTabId(payload.tab),
            },
            openInBackground: !!payload.openInBackground,
          },
        }
      },
    },

    closeTab(state, action: PayloadAction<{ pathname: string; tabId: string }>) {
      const { pathname, tabId } = action.payload
      const tabState = state.tabsByPage[pathname]
      tabState.tabs = tabState.tabs.filter((t) => t.id !== tabId)

      // if closing the current active tab
      // focus on the `overview` tab
      if (tabState.activeTabId === tabId && tabState.tabs.length > 0) {
        tabState.activeTabId = 'overview'
      }
    },

    setPinnedTab(state, action: PayloadAction<{ pathname: string; tabId: string | null }>) {
      const { pathname, tabId } = action.payload

      const tabState = state.tabsByPage[pathname]
      if (!tabState) return

      // switch out the active tab id if affected while pinning
      let newActiveTabId = null

      // the currently pinned tab(s) always get updated
      // should only have one for now, but updates all in case there are multiple
      const prevPinnedTabs = tabState.tabs.filter((tab) => !!tab.pinned)
      if (prevPinnedTabs.length) {
        prevPinnedTabs.forEach((t) => {
          t.pinned = false
        })
        // make the first unpinned tab the new active tab
        const [prev] = prevPinnedTabs
        if (prev) newActiveTabId = prev.id
      }

      // if not pinning a new tab,
      // we've already toggled the currently pinned tab above
      // so we're done
      if (tabId) {
        const selectedIdx = tabState.tabs.findIndex((tab) => tab.id === tabId)
        if (selectedIdx < 0) return // shouldn't happen, but just ignoring as a precaution
        const target = tabState.tabs[selectedIdx]
        target.pinned = true

        // if we haven't already selected a new active tab,
        // pick the tab immediately to the left (or the first one as a fallback)
        if (!newActiveTabId && target.id === tabState.activeTabId) {
          const prevTab = tabState.tabs[Math.max(selectedIdx - 1, 0)]
          newActiveTabId = prevTab.id
        }
      }

      // lastly we just set the active tab id to whatever the new one is
      if (newActiveTabId) tabState.activeTabId = newActiveTabId
    },

    updateTab(state, action: PayloadAction<{ pathname: string; tab: Partial<PageTab> & Pick<PageTab, 'id'> }>) {
      const { pathname, tab } = action.payload
      const tabs = state.tabsByPage[pathname]?.tabs
      if (!tabs) return

      const tabIndex = tabs.findIndex((t: PageTab) => t.id === tab.id)
      if (tabIndex === -1) return

      const prevTab = tabs[tabIndex]
      const nextTab = { ...prevTab, ...tab } as PageTab
      tabs[tabIndex] = nextTab
    },
  },
})

// Wraps an action and includes the current page's pathname in its payload
function wrapWithPathname<P extends { pathname: string }>(
  action: ActionCreatorWithPayload<P>
): (payload: Omit<P, 'pathname'>) => Thunk<void> {
  return (payload: Omit<P, 'pathname'>) => (dispatch, getState) => {
    const { pathname } = getState().router.location
    const cleanedPath = pathname.split('?')[0]
    dispatch(action({ ...payload, pathname: cleanedPath } as P))
    // This MUST be after the dispatch, or it will store the previous values...
    const { tabsByPage } = getState().tabs ?? {}
    const tabInfo = tabsByPage?.[cleanedPath]
    if (tabInfo) {
      updateQuery('case')({
        ...readQuery('case'),
        tabInfo: {
          activeTabId: tabInfo.activeTabId,
          tabs: tabInfo.tabs.filter((tab) => !tab.permanent).map(getQueryTabFromTabInfo),
          pathname: cleanedPath,
        },
      })
    }
  }
}

export const tabReducer = tabSlice.reducer
export const tabActions = tabSlice.actions

export const setActiveTab = wrapWithPathname(tabSlice.actions.setActiveTab)
export const setTabTitle = wrapWithPathname(tabSlice.actions.setTabTitle)
export const setTabs = wrapWithPathname(tabSlice.actions.setTabs)
export const openTab = wrapWithPathname(tabSlice.actions.openTab)
export const closeTab = wrapWithPathname(tabSlice.actions.closeTab)
export const setPinnedTab = wrapWithPathname(tabSlice.actions.setPinnedTab)
export const updateTab = wrapWithPathname(tabSlice.actions.updateTab)

/** Opens tab in pinned state if no other tabs are pinned */
export function useOpenTabAutoPin() {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const dispatch = useDispatch()
  const noCurrentPinnedTab = !useSelector(getCasePagePinnedTab)
  return useCallback(
    (tabDefinition: PageTabDefinition) => {
      dispatch(
        openTab({
          tab: {
            ...tabDefinition,
            pinned: noCurrentPinnedTab,
          },
          meta: {
            featureFlags: {
              RenameOtherInfo: isRenameOtherInfoEnabled,
            },
          },
        })
      )
    },
    [dispatch, isRenameOtherInfoEnabled, noCurrentPinnedTab]
  )
}

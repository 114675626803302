import { SyntheticEvent } from 'react'

import { gql } from '@apollo/client'
import { DateTimeValidationError } from '@mui/x-date-pickers'

import classnames from 'classnames'
import { FormikHelpers } from 'formik'

import { isArray, isEmpty, isFunction, isNil, isString, orderBy, startCase } from 'lodash'
import moment, { Moment, MomentInput } from 'moment-timezone'
import pluralize from 'pluralize'
import { unmountComponentAtNode } from 'react-dom'
import { sentenceCase } from 'sentence-case'

import { dataTypeOf } from 'actions/importingFields'
import { SnakeDataTypeKey } from 'actions/importingFields.types'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'
import { DateFormatter } from 'hooks/DateFormatHooks'

import { Investigation, ReviewPreview } from 'reducers/investigationsReducer.types'
import { DashboardEntry, Review } from 'reducers/reviewsReducer'
import { BusinessAddressTypeStrings, PersonAddressTypeStrings } from 'strings/strings'
import { BusinessAddressTypeEnum, PersonName, PersonAddressTypeEnum, Tin, TinTypeEnum } from 'types/api'

import { Account, BasicAccount, TimeWithParts, TimeWithPartsSimple } from 'types/hb'

function isUpperCase(input: string): boolean {
  return input === input.toUpperCase()
}

function isSpecial(input: string): boolean {
  return input === input.toUpperCase() && input === input.toLowerCase()
}

// Pretty brute force implementation of title case.
export function titleCase(input: string): string {
  const inputChars: string[] = input.split('')
  const outputChars: string[] = []
  let lastChar: string = ' '
  inputChars.forEach((c) => {
    if (['_', '-', ' '].includes(c)) {
      // Replace well defined delimiters with spaces, but don't double spaces.
      if (lastChar !== ' ') {
        outputChars.push(' ')
      }
      // We prevent leading spaces in the case of leading delimiters by not setting lastChar to
      // anything but a space in the case of a delimiter.
      lastChar = ' '
      return
    }

    if (isSpecial(c)) {
      // If this char is special, but the last was not, we're at a word boundary. Add a space,
      // but don't double up spaces.
      if (lastChar !== ' ' && !isSpecial(lastChar)) {
        outputChars.push(' ')
      }
      // And add the special character.
      outputChars.push(c)
    } else if (isUpperCase(c)) {
      // If this char is upper case, but the last was not, we're at a word boundary. Add a space,
      // but don't double up spaces.
      if (lastChar !== ' ' && !isUpperCase(lastChar)) {
        outputChars.push(' ')
      }
      // And add the upper case character.
      outputChars.push(c)
    } else if (isSpecial(lastChar)) {
      // If the last character was special, and this character is not, we're at a word boundary.
      // Add a space, but don't double up spaces.
      if (lastChar !== ' ') {
        outputChars.push(' ')
      }
      // And add this character, upper cased.
      outputChars.push(c.toUpperCase())
    } else {
      // Otherwise, we're mid word, just add the character.
      outputChars.push(c)
    }

    lastChar = outputChars[outputChars.length - 1]
  })

  // With trailing delimiters, there's no easy way to avoid a space at the end. Trim here to
  // account for that case.
  return outputChars.join('').trimEnd()
}

export function tokenize(...list: string[]): string {
  return list.filter((string) => !isEmpty(string)).join('-')
}

export function displayTimeWithParts(
  timeWithParts: Partial<TimeWithPartsSimple> | null | undefined,
  // narrow the DateFormatter generic with `string`
  // due to the default value of '' passed into formatDate below
  formatDate: DateFormatter<string>,
  { dateFormat = 'l', datetimeFormat = 'l LT' } = {}
) {
  if (!timeWithParts) return ''

  if (timeWithParts.localize && timeWithParts.time) {
    return formatDate(timeWithParts.string, '', datetimeFormat)
  }

  if (timeWithParts.time) {
    return moment(timeWithParts.string).tz('UTC').format(datetimeFormat)
  }

  return moment(timeWithParts.string).tz('UTC').format(dateFormat)
}

export function timeWithPartsSortKey(timeWithParts: TimeWithParts | undefined) {
  if (!timeWithParts) {
    return ''
  }

  return timeWithParts.string
}

type PlainModifier = string | { [key: string]: boolean | undefined }
type Modifier = Modifier[] | PlainModifier
function singleBem(base: string, element: string | null = null, modifierLists: Modifier[]) {
  const bare = element ? `${base}__${element}` : base

  const modifiers = modifierLists.reduce<PlainModifier[]>((accum, list) => {
    if (isEmpty(list)) {
      return accum
    }

    if (isString(list)) {
      accum.push(list)
      return accum
    }

    if (isArray(list)) {
      return accum.concat(list as PlainModifier[])
    }

    Object.keys(list).forEach((key) => {
      if (list[key]) {
        accum.push(key)
      }
    })
    return accum
  }, [])

  if (isEmpty(modifiers)) {
    return bare
  }

  return classnames(
    bare,
    modifiers.map((m) => `${bare}--${m}`)
  )
}

export function bem(bases: string, element: string | null = null, ...modifierLists: Modifier[]) {
  return bases
    .split(' ')
    .reduce((accum, base) => {
      accum.push(singleBem(base, element, modifierLists))
      return accum
    }, [] as string[])
    .join(' ')
}

export function fromNow(ts: MomentInput) {
  return moment.utc(ts).fromNow()
}

export function smartFromNow(ts: MomentInput, formatDate: DateFormatter) {
  if (moment().unix() - moment(ts).unix() < 24 * 60 * 60) {
    return fromNow(ts)
  }

  return formatDate(ts)
}

export function copyDate(from: Moment, to: Moment) {
  if (isNil(from)) {
    return from
  }

  const ret = to.clone()
  ret.year(from.year())
  ret.month(from.month())
  ret.date(from.date())
  return ret
}

export function calcDaysUntilTimestamp(
  data: { [key: string]: string | number | null },
  field: string,
  orgTimeZone: string
) {
  const timestamp = data[field]

  if (isNil(timestamp)) {
    return null
  }

  const nowTz = moment.tz(moment.utc(), orgTimeZone)
  const reviewDueDateTz = moment.tz(timestamp, orgTimeZone)

  return Math.round(copyDate(reviewDueDateTz, nowTz.clone()).diff(copyDate(nowTz, nowTz.clone()), 'd', true))
}

export function investigationName(investigation: { name?: string | null } | DashboardEntry) {
  return (investigation as Investigation).name || (investigation as DashboardEntry).investigationName || 'New Case'
}

export function properCase(value: string) {
  return value.toLowerCase().replace(/^(.)/g, ($1) => $1.toUpperCase())
}

export const calcDaysToFile = (review: Pick<ReviewPreview, 'reviewDueAt'>, orgTimeZone: string) =>
  calcDaysUntilTimestamp(review as any, 'reviewDueAt', orgTimeZone)

export const calcPendingDaysRemaining = (review: Pick<ReviewPreview, 'openAt'>, orgTimeZone: string) =>
  calcDaysUntilTimestamp(review as any, 'openAt', orgTimeZone)

export const TIMELINE_ALERT_THRESHOLD = 5

export function daysRemaining(days: number | null, shorten = true) {
  if (isNil(days)) {
    return ''
  }

  if (days === 0) {
    return 'Due today'
  }

  if (days < 0) {
    const daysOverdue = Math.abs(days)
    return `${daysOverdue} ${pluralize('day', daysOverdue)} overdue`
  }

  if (!shorten || days <= TIMELINE_ALERT_THRESHOLD) {
    return `Due in ${days} ${pluralize('day', days)}`
  }

  return `${days} ${pluralize('day', days)}`
}

export function timeLeft(
  review: Pick<ReviewPreview, 'state' | 'reviewDueAt' | 'openAt'>,
  orgTimeZone: string,
  shorten = true
) {
  if (review.state === 'completed') {
    return 'Completed'
  }

  if (review.state === 'cancelled') {
    return 'Cancelled'
  }

  if (review.state === 'pending') {
    const pendingDaysRemaining = calcPendingDaysRemaining(review, orgTimeZone)
    if (isNil(pendingDaysRemaining) || Number.isNaN(pendingDaysRemaining)) {
      return ''
    }

    return `Opens in ${pendingDaysRemaining} ${pluralize('day', pendingDaysRemaining)}`
  }

  const daysToFile = calcDaysToFile(review, orgTimeZone)
  return daysRemaining(daysToFile, shorten)
}

export function reviewTimeSensitivity(review: Pick<ReviewPreview, 'reviewDueAt' | 'isCompleted'>, orgTimeZone: string) {
  const daysToFile = calcDaysToFile(review, orgTimeZone)

  if (isNil(daysToFile) || review.isCompleted) {
    return null
  }

  if (daysToFile > 0 && daysToFile <= TIMELINE_ALERT_THRESHOLD) {
    return 'alert'
  }

  if (daysToFile <= 0) {
    return 'overdue'
  }

  return null
}

export function timeSensitivityClasses(review: Review, baseClassName: string, orgTimeZone: string) {
  const timeSensitivity = reviewTimeSensitivity(review, orgTimeZone)

  return classnames(baseClassName, {
    [`${baseClassName}--${timeSensitivity}`]: timeSensitivity,
  })
}

// Pulled and adapted from https://davidwalsh.name/vendor-prefix
export function cssValue(value: string) {
  const styles = window.getComputedStyle(document.documentElement, '')
  const match =
    Array.prototype.slice
      .call(styles)
      .join('')
      .match(/-(moz|webkit|ms)-/) ||
    ((styles as any).OLink === '' && ['', 'o'])
  if (!match) {
    return value
  }
  const pre = match[1]
  return `-${pre}-${value}`
}

export function fileSize(bytes: number) {
  if (!bytes) {
    return ''
  }

  const sizes = ['bytes', 'kb', 'mb', 'gb']
  let readableSize = bytes
  let sizeIndex = 0
  // If sizeIndex >= 4, we will stop and just display the value in GB
  while (sizeIndex < 3) {
    if (readableSize / 1000 < 1) {
      break
    }
    readableSize /= 1000
    sizeIndex += 1
  }
  return [readableSize.toFixed(2), sizes[sizeIndex]].join(' ')
}

export function userInitials(user: Pick<BasicAccount, 'firstName' | 'lastName'>) {
  const { firstName, lastName } = user
  return [firstName, lastName].map((s) => s[0].toUpperCase()).join('')
}

export function shortName(user: OmitStrict<BasicAccount, 'avatarColor' | 'avatarVariant'> | undefined | null) {
  if (isNil(user)) {
    return ''
  }
  if (user.shortName) {
    return user.shortName
  }
  const { firstName, lastName } = user
  if (isNil(firstName) || isEmpty(firstName) || isNil(lastName) || isEmpty(lastName)) {
    throw new Error('Invalid account provided to shortName, missing firstName and lastName')
  }

  return `${startCase(`${firstName} ${lastName[0]}`)}.`
}

// This is way oversimplified. There are alternative approaches including
// http://home.nerbonne.org/A-vs-An/AvsAn.js and https://github.com/chadkirby/Articles
// but for the purposes at the time of writing, this is sufficient. Feel free to revisit.
const VOWELS = ['a', 'e', 'i', 'o', 'u']
export function indefiniteArticle(noun: string) {
  const firstLetter = noun.toLowerCase()[0]

  if (VOWELS.includes(firstLetter)) {
    return 'an'
  }

  return 'a'
}

// Basic account search.
export function filterAccountsByName(accounts: Account[], filter: string) {
  const filtered = accounts.filter((account) => {
    const { firstName, lastName, username } = account
    const searchString = `${firstName.toLowerCase()}${lastName.toLowerCase()}`
    const rectifiedFilter = filter.toLowerCase().replace(/\s/g, '')
    return searchString.includes(rectifiedFilter) || username.toLowerCase().includes(rectifiedFilter)
  })

  return orderBy(filtered, ['firstName', 'lastName'])
}

// Basic helper to stop event propagation and default action.
export function stopEvent(event: React.MouseEvent<any> | React.KeyboardEvent<any> | SyntheticEvent) {
  if (isNil(event)) {
    return
  }

  if (isFunction(event.preventDefault)) {
    event.preventDefault()
  }
  if (isFunction(event.stopPropagation)) {
    event.stopPropagation()
  }
}

export function stopEventPropagation(event: React.SyntheticEvent<unknown>) {
  if (event) {
    event.stopPropagation()
    event.nativeEvent?.stopImmediatePropagation()
  }
}

export const noPropagate = (fn: () => void) => (event: React.SyntheticEvent<unknown>) => {
  stopEventPropagation(event)
  fn()
}

// Scroll element into view if necessary
export function scrollToDOMElement(elem?: HTMLElement | null) {
  // non-standard (but nicer) WebKit only method
  if (isFunction((elem as any).scrollIntoViewIfNeeded)) {
    ;(elem as any).scrollIntoViewIfNeeded()
  } else {
    // assume smoothscroll-polyfill
    elem?.scrollIntoView({ behavior: 'smooth' })
  }
}

export function dueInDisplay(dueDate: string) {
  const date = moment.utc(dueDate)
  const diff = date.diff(moment.utc(), 'days')

  if (diff > 0) return `Due in ${date.fromNow(true)}`

  if (diff < 0) return `Due ${date.fromNow()}`

  return 'Due Today'
}

export function safeClearSubmitting(actions: FormikHelpers<any>) {
  return () => actions && actions.setSubmitting && actions.setSubmitting(false)
}

export function orgDateTime(date: Moment, orgtime: string, orgTimeZone: string) {
  return copyDate(date, moment.tz(orgtime, 'HH:mm', orgTimeZone))
}

export function stampDateTimeWithOrgTz(date: Moment, orgTimeZone: string) {
  return moment.tz(date.format('YYYY-MM-DDTHH:mm:ss'), orgTimeZone)
}

export function orgDate(date: Moment, orgtime: string, orgTimeZone: string) {
  return orgDateTime(date, orgtime, orgTimeZone).format('YYYY-MM-DD')
}

/**
 * A partial date is a date formatted as YYYY-MM-DD, where
 * either the year, month, or day can be zeros to indicate an unknown value.
 */
export function parsePartialDate(date: string) {
  // Format is YYYY-MM-DD, but we support 00 values for unknowns which is not supported by moment
  const dateParts = date.split('-')
  const year = parseInt(dateParts[0], 10)
  const month = parseInt(dateParts[1], 10)
  const day = parseInt(dateParts[2], 10)

  return { day, month, year }
}

function partialDateToMoment(date: string) {
  const { day, month, year } = parsePartialDate(date)

  const rtn = moment()

  if (year !== 0) {
    rtn.year(year)
  }

  if (month !== 0) {
    rtn.month(month - 1)
  }

  if (day !== 0) {
    rtn.date(day)
  }

  return rtn
}

export function validPartialDate(date: string | null | undefined) {
  if (typeof date === 'string') {
    const [strYear, strMonth, strDay, ...rest] = date.replace(/[^\d-]/, '').split('-')

    if (strYear.length === 4 && strMonth.length === 2 && strDay.length === 2 && rest.length === 0) {
      const YYYY = strYear === '0000' ? '2000' : strYear
      const MM = strMonth === '00' ? '01' : strMonth
      const DD = strDay === '00' ? '01' : strDay
      const convertedPartialDate = moment(`${YYYY}-${MM}-${DD}`, 'YYYY-MM-DD')

      return convertedPartialDate.isValid()
    }
  }

  return false
}

export const isValidDateString = (dateString?: string) =>
  dateString ? !Number.isNaN(new Date(dateString).getTime()) : false

// Making the MUI picker errors human readable
export const formatDateTimeValidationError = (muiError: DateTimeValidationError, fieldName?: string) => {
  let error
  switch (muiError) {
    case 'disablePast':
      error = 'must be in the future'
      break
    case 'disableFuture':
      error = 'must be in the past'
      break
    case 'minTime':
      error = 'must be after start time'
      break
    case 'maxTime':
      error = 'must be before end time'
      break
    case 'minDate':
      error = 'must be after start date'
      break
    case 'maxDate':
      error = 'must be before end date'
      break
    default: {
      if (!muiError) {
        return ''
      }
      error = 'invalid'
      break
    }
  }
  return sentenceCase([fieldName, error].filter(Boolean).join(' '))
}

export function displayPartialDate(date: string) {
  if (!date) {
    return null
  }

  const { day, month, year } = parsePartialDate(date)

  // Format as MM/DD/YYYY, replace missing parts with '?'
  return [month, day, year].map((n) => (n === 0 ? '?' : n)).join('/')
}

export function ageString(birthdate: string | null) {
  if (!birthdate) {
    return null
  }

  const { year } = parsePartialDate(birthdate)
  if (year === 0) {
    return 'Age unknown'
  }

  const birth = partialDateToMoment(birthdate)
  const age = moment().diff(birth, 'years')
  return `${age} ${pluralize('year', age)} old`
}

// @deprecated This should not be used.
// Please use the displayName property of LibraryObject (in the GQL schema) instead.
export function legacyDisplayName(
  dataType: SnakeDataTypeKey,
  data: {
    displayName?: string | null
    name?: PersonName | string | null
    nameStr?: string | null
    legalNames?: ReadonlyArray<string> | null
    accountNumber?: string | null
    maskedPan?: string | null
    cryptoAddress?: string | null
    productName?: string | null
    deviceName?: string | null
    externalId?: string | null
  } | null
) {
  const type = dataTypeOf(dataType)
  const unknown = `Unknown ${type?.name}`

  if (!data) {
    return unknown
  }

  const {
    displayName,
    name,
    nameStr,
    legalNames,
    accountNumber,
    maskedPan,
    cryptoAddress,
    productName,
    deviceName,
    externalId,
  } = data

  if (displayName) {
    return displayName
  }

  if (productName) {
    return productName
  }

  if (deviceName) {
    return deviceName
  }

  if (nameStr) {
    return nameStr
  }

  if (legalNames) {
    return legalNames[0] || unknown
  }

  if (accountNumber) {
    return accountNumber
  }

  if (maskedPan) {
    return maskedPan
  }

  if (cryptoAddress) {
    return cryptoAddress
  }

  if (typeof name === 'string') {
    return name
  }

  if (externalId) {
    return externalId
  }

  if (!name) {
    return unknown
  }

  const { firstName, middleName, lastName, suffix } = name
  const composedName = [firstName, middleName, lastName].filter((s) => !!s).join(' ')
  return `${composedName}${composedName && suffix ? `, ${suffix}` : ''}` || unknown
}

export const displayAddressFragments = {
  address: gql`
    fragment displayAddressAddress on BaseAddress {
      ... on PersonAddress {
        personAddressType: addressType
        addressTypeOther
      }
      ... on BusinessAddress {
        businessAddressType: addressType
        addressTypeOther
      }
      addressLine1
      addressLine2
      geopoint {
        latitude
        longitude
      }
      locality
      postalCode
      administrativeDistrictLevel1
      country
    }
  `,
}

interface displayableAddress {
  addressLine1: string | null
  addressLine2: string | null
  locality: string | null
  postalCode: string | null
  administrativeDistrictLevel1: string | null
  country: string | null
}

export function displayAddress(address: displayableAddress, cityOnly = false) {
  const { addressLine1, addressLine2, locality, postalCode, administrativeDistrictLevel1, country } = address

  const parts = [addressLine1, addressLine2, locality, postalCode, administrativeDistrictLevel1, country]
  if (cityOnly) {
    parts.shift()
    parts.shift()
  }
  return parts.filter((part) => !!part).join(', ')
}

export function displayAddressType(address: DisplayAddressAddressFragment) {
  if ('personAddressType' in address && 'addressTypeOther' in address) {
    const { personAddressType, addressTypeOther } = address

    if (personAddressType === PersonAddressTypeEnum.Other && addressTypeOther) {
      return addressTypeOther
    }

    if (personAddressType) {
      return PersonAddressTypeStrings[personAddressType]
    }
  }

  if ('businessAddressType' in address && 'addressTypeOther' in address) {
    const { businessAddressType, addressTypeOther } = address

    if (businessAddressType === BusinessAddressTypeEnum.Other && addressTypeOther) {
      return addressTypeOther
    }

    if (businessAddressType) {
      return BusinessAddressTypeStrings[businessAddressType]
    }
  }

  return null
}

// Based on app/domain/payment_cards/account_number_parser.rb:masked_pan
export function maskPan(pan?: string | null): string | null {
  if (pan === '') {
    return pan
  }

  if (!pan) {
    return null
  }

  const cleansedPan = pan.replace(/[^\d*•]+/g, '')
  const bin = cleansedPan.length < 10 ? '' : cleansedPan.slice(0, 6)
  const maskedLength = Math.max(2, cleansedPan.length - 10)
  const lastFour = cleansedPan.slice(-4)
  return `${bin}${Array(maskedLength).fill('*').join('')}${lastFour}`
}

export const contactUs = () => window.open('mailto:support@hummingbird.co', '_blank')

// Click handler that goes to an external link
export function getExternalLinkHandler(url: string, newTab = true) {
  return () => {
    const a = document.createElement('a')
    a.href = url
    if (newTab) {
      a.target = '_blank'
    }
    a.rel = 'noopener noreferrer'
    a.click()
  }
}

export function valueIsHyperlink(value: unknown): value is string {
  if (typeof value === 'string') {
    let url: URL
    try {
      url = new URL(value)
    } catch (_) {
      return false
    }

    return url.protocol === 'http:' || url.protocol === 'https:'
  }

  return false
}

export function formatTin(tin: Tin) {
  if (tin.type === TinTypeEnum.Ssn) {
    // Assume the SSN is only valid if it has a valid length
    if (tin.number && tin.number.length === 9) {
      return `${tin.number.slice(0, 3)}-${tin.number.slice(3, 5)}-${tin.number.slice(5)}`
    }
  }

  return tin.number
}

export const joinClean = (text?: Array<string | undefined | null> | null, delimiter = ' ') =>
  text ? text.filter(Boolean).join(delimiter).trim() : ''

export const PASSWORD_MASK = '***************'

/**
 * Tear down a disposable container when done measuring contents
 */
export const teardownMeasurableContainer = (container: HTMLDivElement) => {
  unmountComponentAtNode(container)
  container.parentNode?.removeChild(container)
}

/**
 * Get an invisible disposable container into which the temporary measurable column will be portaled.
 * Using a portal for rendering cell contents should enable retaining necessary React context, e.g. theme, store, etc.
 * Should be followed up with `teardownMeasurableContainer` to clean up temporary rendered content.
 */
export const getMeasurableContainer = () => {
  const container = document.createElement('div')
  container.style.cssText = `
    display: inline-block;
    position: absolute;
    visibility: hidden;
    zIndex: -1;
  `
  document.body.appendChild(container)
  return { container, teardownMeasurableContainer }
}

import { Box } from '@mui/material'

import { Section, SectionProps } from './EntityInformationLayout'

export function BasicInformationSection(props: Omit<SectionProps, 'title' | 'testId'>) {
  const { children, ...rest } = props
  return (
    <Section testId="basicInformationSection" title="Basic Information" {...rest}>
      <Box px={2} pb={2}>
        {children}
      </Box>
    </Section>
  )
}

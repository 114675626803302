import { styled } from '@mui/material'

import { clamp } from 'lodash'

import { HbText, HBNonInteractiveTextProps } from '../Text/HbText'

import { HbLinearProgress, Props as HbLinearProgressProps } from './HbLinearProgress'

export const getPercentage = ({ count, total }: { count: number; total: number }) =>
  clamp(Math.round((count / total) * 100), 0, 100)

export type ProgressCountFormat = 'numeric' | 'percentage'

interface ProgressCountProps {
  className?: string
  count: number
  format: ProgressCountFormat
  slotProps?: Partial<{
    text: Partial<Exclude<HBNonInteractiveTextProps, 'className'>>
  }>
  total: number
}

export const StyledCountText = styled(HbText)``

const ProgressCount = ({ className, count, format, slotProps, total }: ProgressCountProps) => {
  return (
    <StyledCountText className={className} {...slotProps?.text}>
      {format === 'numeric' ? `${count} of ${total}` : `${getPercentage({ count, total })}%`}
    </StyledCountText>
  )
}

export type Alignment = 'start' | 'end'

interface ProgressContainerStyleProps {
  alignment?: Alignment
}

const ProgressContainer = styled('div')<ProgressContainerStyleProps>(({ alignment, theme }) => ({
  alignItems: 'center',
  columnGap: theme.spacing(),
  display: 'flex',
  flexDirection: alignment === 'start' ? 'row' : 'row-reverse',
  flexWrap: 'nowrap',
  padding: theme.spacing(0, 0.5, 0, 0),
  width: '100%',
}))

const StyledProgress = styled(HbLinearProgress)(() => ({
  flexGrow: 1,
}))

export interface Props {
  alignment?: Alignment
  className?: string
  count: number
  format?: ProgressCountProps['format']
  showCount?: boolean
  slotProps?: Partial<{
    count?: Partial<Exclude<ProgressCountProps, 'count' | 'format' | 'total'>>
    progress: Partial<Exclude<HbLinearProgressProps, 'value'>>
  }>
  total?: number
}

export const HbLinearProgressWithCount = ({
  alignment = 'start',
  className,
  count,
  format = 'percentage',
  showCount = true,
  total = 100,
  slotProps,
}: Props) => {
  const value = getPercentage({ count, total })
  return (
    <ProgressContainer className={className} alignment={alignment}>
      <StyledProgress {...slotProps?.progress} value={value} />
      {showCount && <ProgressCount count={count} total={total} format={format} />}
    </ProgressContainer>
  )
}

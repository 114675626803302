import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const DownloadIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 16 17">
    <path d="M13.71 7.21l-1.42-1.42L9 9.09V0H7v9.09l-3.29-3.3-1.42 1.42L8 12.91l5.71-5.7M0 15h16v2H0z" />
  </SvgIcon>
)

export default DownloadIcon

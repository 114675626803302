import { capitalize } from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import { RRule, Frequency as RRuleFrequency } from 'rrule'

export const DEFAULT_DAY_OF_WEEK_OBJECT = {
  su: false,
  mo: true,
  tu: false,
  we: false,
  th: false,
  fr: false,
  sa: false,
}
export type Frequency = 'day' | 'week' | 'month'

export const getFrequencyValue = (frequency: Frequency, interval: number) => {
  const frequencyStr = capitalize(frequency.toString())
  return interval > 1 ? pluralize(frequencyStr) : frequencyStr
}

export const getFormattedScheduleHours = () => {
  return Array.from(Array(24), (_, i) => moment().startOf('day').add(i, 'hours').format('h:mm A'))
}

export type DaysOfWeek = {
  su: boolean
  mo: boolean
  tu: boolean
  we: boolean
  th: boolean
  fr: boolean
  sa: boolean
}

export type ScheduleConfigObject = {
  freq: RRuleFrequency
  interval: number
  byday: DaysOfWeek | null
  bymonthday: number | null
  byhour: number
}

const toWeekObject = (days: number[]): DaysOfWeek | null => {
  const dayOfWeek: DaysOfWeek = { su: false, mo: false, tu: false, we: false, th: false, fr: false, sa: false }

  days.forEach((day) => {
    switch (day) {
      case 0:
        dayOfWeek.mo = true
        break
      case 1:
        dayOfWeek.tu = true
        break
      case 2:
        dayOfWeek.we = true
        break
      case 3:
        dayOfWeek.th = true
        break
      case 4:
        dayOfWeek.fr = true
        break
      case 5:
        dayOfWeek.sa = true
        break
      case 6:
        dayOfWeek.su = true
        break
      default:
    }
  })

  return dayOfWeek
}

export const parseScheduleConfigIntoScheduleRule = (scheduleConfig: ScheduleConfigObject, dtstart: Date): RRule => {
  const dayMapping = { mo: 0, tu: 1, we: 2, th: 3, fr: 4, sa: 5, su: 6 } as const

  const weekdays = []
  if (scheduleConfig.freq === RRule.WEEKLY && scheduleConfig.byday) {
    for (const [day, selected] of Object.entries(scheduleConfig.byday) as Array<[keyof typeof dayMapping, boolean]>) {
      if (selected) {
        weekdays.push(dayMapping[day])
      }
    }
  }

  // RRule is does not take into account the timezone in Date objects and assumes whatever
  // time is in UTC. This is a hack to "convert" our time into UTC via offset
  dtstart.setMinutes(dtstart.getMinutes() - dtstart.getTimezoneOffset())
  const rule = new RRule({
    freq: scheduleConfig.freq,
    interval: scheduleConfig.interval,
    byweekday: weekdays,
    bymonthday: scheduleConfig.bymonthday,
    byhour: scheduleConfig.byhour,
    dtstart,
    byminute: 0,
    bysecond: 0,
  })

  return rule
}

export const scheduleConfigToCronString = (config?: ScheduleConfigObject): string | undefined => {
  if (!config) return undefined

  const keys = Object.keys(config) as Array<keyof ScheduleConfigObject>
  const scheduleParts: Array<[string, RRuleFrequency | number | string]> = []

  keys.forEach((key) => {
    switch (key) {
      case 'freq':
        scheduleParts.push([key.toUpperCase(), RRule.FREQUENCIES[config.freq]])
        break
      case 'interval':
        scheduleParts.push([key.toUpperCase(), config.interval])
        break
      case 'byday':
        if (config.freq !== RRule.WEEKLY || !config.byday) break
        scheduleParts.push([
          key.toUpperCase(),
          Object.entries(config.byday)
            .filter(([, val]) => val)
            .map(([day]) => day.toUpperCase())
            .join(','),
        ])
        break
      case 'bymonthday':
        if (config.freq !== RRule.MONTHLY || !config.bymonthday) break
        scheduleParts.push([key.toUpperCase(), config.bymonthday])
        break
      case 'byhour':
        scheduleParts.push([key.toUpperCase(), config.byhour])
        break
      default:
    }
  })

  return scheduleParts.map(([key, value]) => `${key}=${value}`).join(';')
}

// Every week on Monday at 09:00 AM
const DEFAULT_CONFIG = new RRule({
  freq: RRule.WEEKLY,
  interval: 1,
  byweekday: [RRule.MO],
  byhour: 9,
})

export const defaultScheduleConfig = {
  freq: DEFAULT_CONFIG.options.freq,
  interval: DEFAULT_CONFIG.options.interval,
  byday: toWeekObject(DEFAULT_CONFIG.options.byweekday),
  bymonthday: null,
  byhour: DEFAULT_CONFIG.options.byhour[0],
}

export const scheduleStringToScheduleConfig = (scheduleStr: string): ScheduleConfigObject => {
  const rule = scheduleStr ? RRule.fromString(scheduleStr) : DEFAULT_CONFIG

  const byday = rule.options.freq === RRule.WEEKLY ? toWeekObject(rule.options.byweekday) : null
  const bymonthday = rule.options.freq === RRule.MONTHLY ? rule.options.bymonthday[0] : null

  return {
    freq: rule.options.freq,
    interval: rule.options.interval,
    byday,
    bymonthday,
    byhour: rule.options.byhour[0],
  }
}

export const SCHEDULE_CONFIG_PATH_SLUG = 'scheduleConfig' as const
export const SUPPORTED_SCHEDULE_HOURS = getFormattedScheduleHours()
export const SUPPORTED_FREQUENCIES: Frequency[] = ['month', 'week']
export const MONTHLY_INTERVAL_ERROR = 'Monthly interval cannot exceed 3'
export const WEEKLY_INTERVAL_ERROR = 'Weekly interval cannot exceed 12'

export const generateScheduleDescription = (schedule: RRule, timezone?: string): string => {
  if (!schedule) return ''

  const time = SUPPORTED_SCHEDULE_HOURS[schedule.options.byhour[0]]
  const unformattedDurationText = schedule.toText()
  const formattedDurationText = unformattedDurationText.charAt(0).toUpperCase() + unformattedDurationText.slice(1)

  return `${formattedDurationText} at ${time}${timezone ? ` ${moment.tz(timezone).format('z')}` : ''}`
}

import { KeyboardEvent, ReactNode } from 'react'

function stopKeyEvent(event: KeyboardEvent<HTMLDivElement>) {
  if (event.nativeEvent && event.nativeEvent.stopImmediatePropagation) {
    event.nativeEvent.stopImmediatePropagation()
  }

  if (event.stopPropagation) {
    event.stopPropagation()
  }
}

interface Props {
  children: ReactNode
  active?: boolean
}

export function KeyCapture({ children = null, active = true }: Props) {
  if (!active) {
    return <>{children}</>
  }

  return <div onKeyDown={stopKeyEvent}>{children}</div>
}

export default KeyCapture

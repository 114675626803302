/**
 * This applies Report table styling to the existing
 * DashboardTable component.
 */
import { ComponentPropsWithoutRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { StickyTable } from 'components/library/Table/StickyTable'
import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

const BORDER_RADIUS = 10
const DEFAULT_HEADER_CELL_HEIGHT = 37

type MaskColor = string | ((theme: Theme) => string)

const handleMaskColor = (t: Theme, m?: MaskColor) => (m === undefined ? m : typeof m === 'function' ? m(t) : m)

export type Props = ComponentPropsWithoutRef<typeof StickyTable> & { maskColor?: MaskColor }

const getBorder = (theme: Theme) => `1px solid ${theme.palette.dividers.light}`

const useTableStyles = makeStyles((theme: Theme) => ({
  innerContainer: {
    overflow: 'auto',
    height: '100%',
    '& .hb-table__body': {
      boxShadow: 'none',
    },
  },
  emptyCell: {
    borderBottomLeftRadius: BORDER_RADIUS,
    borderBottomRightRadius: BORDER_RADIUS,
    borderLeft: getBorder(theme),
    padding: theme.spacing(6, 2),
    textWrap: 'balance',
    textAlign: 'center',
    maxWidth: 800,
  },
}))

const useTableWithReordableColumnsStyles = makeStyles((theme: Theme) => ({
  visibleTableHeader: ({ maskColor }: Pick<Props, 'maskColor'>) => {
    const background = handleMaskColor(theme, maskColor)
    return {
      border: 'none',
      position: 'sticky',
      '& th': {
        overflow: 'hidden',
      },
      '& th:not(:first-child):not(:last-child)': {
        borderTop: getBorder(theme),
        background: theme.palette.styleguide.backgroundMedium,
        '&:hover': {
          background: `${theme.palette.styleguide.backgroundLight} !important`,
        },
      },
      '& th:first-child, & th:last-child': {
        background,
        '&:hover, &:focus': {
          background,
        },
        '& > div': {
          position: 'relative',
          zIndex: 2,
          borderTop: getBorder(theme),
          // + 1 accounts for extra top border on the first rounded header cell
          height: DEFAULT_HEADER_CELL_HEIGHT + 1,
        },
      },
      '& th:first-child > div': {
        borderLeft: getBorder(theme),
        borderTopLeftRadius: BORDER_RADIUS,
      },
      '& th:last-child > div': {
        borderRight: getBorder(theme),
        borderTopRightRadius: BORDER_RADIUS,
      },
    }
  },
}))

const useTableRowStyles = makeStyles((theme: Theme) => ({
  bodyCell: {
    color: theme.palette.styleguide.textGreyDark,

    '&:first-child': {
      borderLeft: `1px solid ${theme.palette.dividers.light}`,
      color: theme.palette.text.primary,
    },
  },
  cellContent: {
    minHeight: '48px',
  },
  root: ({ maskColor }: Pick<Props, 'maskColor'>) => {
    const background = handleMaskColor(theme, maskColor)
    return {
      '&:hover:last-child td:first-child': {
        backgroundColor: `${background} !important`,
      },
      '&:last-child td:first-child': {
        background,
        border: 'none',
        // See https://stackoverflow.com/questions/3542090/how-to-make-div-fill-td-height
        height: '1px',
        '& > div': {
          overflow: 'hidden',
          position: 'relative',
          height: '100%',
          borderBottomLeftRadius: BORDER_RADIUS,
          borderBottom: getBorder(theme),
          borderRight: getBorder(theme),
          borderLeft: getBorder(theme),
          background: 'white',
        },
      },
      '&:last-child td:last-child': {
        borderBottomRightRadius: BORDER_RADIUS,
      },
    }
  },
}))

const useDefaultHeaderCellStyles = makeStyles((theme: Theme) => ({
  root: {
    '&:hover $headerCellContent': {
      background: theme.palette.dividers.light,
    },
    '&:focus': {
      outline: 'none',
      '& $headerCellContent': {
        background: theme.palette.dividers.light,
      },
    },
  },
  headerCellContent: {
    minHeight: DEFAULT_HEADER_CELL_HEIGHT,
    height: DEFAULT_HEADER_CELL_HEIGHT,
    color: theme.palette.styleguide.textGreyDark,
    background: theme.palette.styleguide.backgroundMedium,
  },
}))

/**
 *  The rounded corners create convex negative space that exposes content that's behind it when in sticky position.
 *  The `maskColor` is used to mask the negative space.
 */
export const RoundedTable = ({ styleOverrides, children, maskColor = 'white', ...rest }: Props) => {
  const tableClasses = useTableStyles()
  const tableWithReorderableColumns = useTableWithReordableColumnsStyles({ maskColor })
  const tableRowClasses = useTableRowStyles({ maskColor })
  const defaultHeaderCellClasses = useDefaultHeaderCellStyles()

  return (
    <StickyTable
      scrollToFocused={false}
      styleOverrides={{
        Table: mergeOverrideStyles(tableClasses, styleOverrides?.Table),
        TableWithReorderableColumns: mergeOverrideStyles(
          tableWithReorderableColumns,
          styleOverrides?.TableWithReorderableColumns
        ),
        TableRow: mergeOverrideStyles(tableRowClasses, styleOverrides?.TableRow),
        DefaultHeaderCell: mergeOverrideStyles(defaultHeaderCellClasses, styleOverrides?.DefaultHeaderCell),
      }}
      {...rest}
    >
      {children}
    </StickyTable>
  )
}

import { useSelector } from 'actions/store'
import { getCurrentAccount, getCurrentOrganization } from 'helpers/stateHelpers'
import { useEffectOnce, useFeatureFlag } from 'hooks'
import { FeatureFlag } from 'types/api'

declare global {
  interface Window {
    wootricSettings: {
      email: string
      created_at: number
      account_token: string
      properties: Record<string, string>
    }
    wootric: (action: string) => void
  }
}

export function useWootricIntegration() {
  const user = useSelector((state) => getCurrentAccount(state))
  const organization = useSelector((state) => getCurrentOrganization(state))
  const enabled = useFeatureFlag(FeatureFlag.EnableWootricSurveys)
  const validEnvironment = useSelector((state) =>
    ['sandbox', 'production'].includes(state.application.environment ?? '')
  )

  useEffectOnce(() => {
    if (!enabled || !user || !validEnvironment) {
      return
    }

    window.wootricSettings = {
      email: user.email,
      // User's sign-up date as a 10 digit Unix timestamp in seconds
      created_at: Math.floor(new Date(user.createdAt).getTime() / 1000),
      account_token: 'NPS-afbf482d',

      properties: {
        organization: organization.customerId,
      },
    }

    // Beacon
    const beacon = document.createElement('script')
    beacon.type = 'text/javascript'
    beacon.id = 'wootric-beacon'
    beacon.async = true

    beacon.src = 'https://cdn.wootric.com/wootric-sdk.js'
    beacon.onload = function () {
      window.wootric('run')
    }

    if (document.getElementById('wootric-beacon') == null) {
      document.body.appendChild(beacon)
    }
  })
}

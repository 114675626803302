// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { AuthoredContent } from 'components/library/AuthoredContent'
import { MenuListItem } from 'components/library/Menu'
import { opacify } from 'helpers/colors'
import { Notification as NotificationObj } from 'reducers/notificationsReducer'
import { ColorNameEnum } from 'types/api'
import { Account, AccountCredential, Theme } from 'types/hb'

const HUMMINGBIRD_AUTHOR_CREDENTIAL: AccountCredential = {
  isWorkOSCredential: false,
  unconfirmedEmail: null,
  nameId: null,
}

const HUMMINGBIRD_AUTHOR: Account = {
  token: '',
  shortName: 'H. Bot',
  fullName: 'Hummingbird Bot',
  initials: 'HB',
  username: '',
  avatarColor: ColorNameEnum.Blue,
  avatarVariant: 0,
  email: '',
  outOfOfficeUntil: new Date(),
  badges: [],
  firstName: 'Hummingbird',
  lastName: 'Bot',
  credential: HUMMINGBIRD_AUTHOR_CREDENTIAL,
  queues: [],
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.smallContainer.borderRadius,
    padding: `${theme.spacing()} ${theme.spacing(2)}`,
    margin: theme.spacing(),
    background: opacify(theme.palette.styleguide.blue, 0.1),
    position: 'relative',

    '&$actioned': {
      background: 'transparent',
      border: `1px solid ${theme.palette.dividers.medium}`,

      '&:hover': {
        background: theme.palette.background.medium,
      },
    },
    '& .MuiAvatar-root': {
      position: 'absolute',
      left: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  },

  actioned: {},
}))

interface Props {
  notification: NotificationObj
  notificationClassName?: string
  onClick: (notification: NotificationObj) => void
}

const Notification = ({ notification, onClick, notificationClassName }: Props) => {
  const classes = useStyles()
  return (
    <MenuListItem
      className={classnames(classes.root, {
        [classes.actioned]: notification.actionedAt,
      })}
      onClick={() => onClick(notification)}
    >
      <AuthoredContent
        author={notification.sender || HUMMINGBIRD_AUTHOR}
        createdAt={notification.createdAt}
        component="div"
        content={notification.text}
        className={notificationClassName}
      />
    </MenuListItem>
  )
}

export default Notification

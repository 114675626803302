import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import CollapseRegion from 'components/HbComponents/CollapseRegion'
import { HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useToggle } from 'hooks'
import { Theme } from 'types/hb'

const useCheckboxSectionStyles = makeStyles((theme: Theme) => ({
  section: {
    '& + &': {
      marginTop: theme.spacing(2),
    },
  },
  collapseContent: {
    padding: theme.spacing(1, 2, 0, 0),
  },
  checkbox: {
    padding: theme.spacing(0, 1.5, 0, 0),
    margin: 0,
  },
}))

/*
    A section with a checkbox that can be expanded to show more content
    This behaves KIND OF like a typical toggle selection.
    If the checkbox is checked, the content (probably a form input) is shown.
    If the checkbox is unchecked, the content is hidden and the nested form inputs
    should be cleared of their values (hence the `handleUncheck` prop)
 */
const CheckboxSection = ({
  label,
  children,
  handleUncheck,
  handleCheck,
  isChecked,
}: {
  label: string
  children: ReactNode
  isChecked: boolean
  handleUncheck: () => void
  handleCheck?: () => void
}) => {
  const { value, toggle } = useToggle(isChecked)
  const classes = useCheckboxSectionStyles()
  return (
    <div className={classes.section}>
      <HbCheckbox
        classes={{ root: classes.checkbox }}
        checked={value}
        onChange={() => {
          const nextStateIsUnchecked = value
          // If we are unchecking the box, we want to clear out the selected values
          if (nextStateIsUnchecked) {
            handleUncheck()
            // If the handleCheck function is passed in, call it if the box is being checked
          } else if (handleCheck) {
            handleCheck()
          }
          toggle()
        }}
      />
      <HbText size="s">{label}</HbText>
      <CollapseRegion isExpanded={value} id={label} className={classes.collapseContent}>
        {children}
      </CollapseRegion>
    </div>
  )
}

export default CheckboxSection

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const IPAddressIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 22">
    <g>
      <path d="M18 6a6 6 0 0 0-6 6c0 4.13 5.11 8.83 5.33 9a1 1 0 0 0 1.35 0c.22-.2 5.33-4.89 5.33-9A6 6 0 0 0 18 6zm0 12.89c-1.53-1.56-4-4.58-4-6.89a4 4 0 0 1 8 0c0 2.31-2.47 5.33-4 6.89z" />
      <circle cx="18" cy="12" r="2" />
      <path d="M10 18c-.3 0-1-1-1.43-2.86A7.85 7.85 0 0 1 10 15h.61a9.53 9.53 0 0 1-.53-2H10a9.93 9.93 0 0 0-1.8.18C8.11 12.52 8 11.79 8 11h2.05a7.93 7.93 0 0 1 .52-2H8c0-.79.1-1.52.18-2.18A9.93 9.93 0 0 0 10 7a9.93 9.93 0 0 0 1.8-.18v.12A8 8 0 0 1 18 4a10 10 0 1 0-8 16 9.92 9.92 0 0 0 3.2-.53 19.18 19.18 0 0 1-2.09-3.28C10.7 17.38 10.24 18 10 18zm4.65-14.5a8.13 8.13 0 0 1-1.3.74 12.12 12.12 0 0 0-.57-1.74 8 8 0 0 1 1.87 1zM10 2c.31 0 1 1 1.43 2.86A7.85 7.85 0 0 1 10 5a7.85 7.85 0 0 1-1.43-.14C9 3 9.69 2 10 2zM3.86 4.88a10 10 0 0 0 2.4 1.38A25.72 25.72 0 0 0 6 9H2.07a8 8 0 0 1 1.79-4.12zM2.07 11H6a25.72 25.72 0 0 0 .24 2.74 10 10 0 0 0-2.4 1.38A8 8 0 0 1 2.07 11zm3.28 5.5a8.13 8.13 0 0 1 1.3-.74 12.12 12.12 0 0 0 .57 1.74 8 8 0 0 1-1.87-1zM6.66 4.24a8.13 8.13 0 0 1-1.3-.74 8 8 0 0 1 1.88-1 12.12 12.12 0 0 0-.58 1.74z" />
    </g>
  </SvgIcon>
)

export default IPAddressIcon

import { useEffect } from 'react'

import { useDispatch, useSelector } from 'actions/store'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { SearchReviewsResultV2 } from 'types/api'

export const useSyncPagination = (
  pageInfo?: Pick<SearchReviewsResultV2, 'page' | 'pageSize' | 'pageCount'> | null
): void => {
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const { count } = useSelector(dashboardsSelectors.page)
  const pageCount = pageInfo?.pageCount

  const dispatch = useDispatch()

  useEffect(() => {
    if (!pageCount || count === pageCount) return

    dispatch(dashboardsActions.page.setCount(pageCount))
  }, [count, dashboardsActions.page, dispatch, pageCount])
}

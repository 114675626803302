import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const ReportsIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g id="content">
      <path
        d="M10.894 11.927H7.727a.792.792 0 1 0 0 1.583h3.167a.792.792 0 1 0 0-1.583Zm3.168-7.918h-.935a2.375 2.375 0 0 0-2.233-1.584H9.311A2.375 2.375 0 0 0 7.078 4.01h-.934a2.375 2.375 0 0 0-2.376 2.375v9.501a2.375 2.375 0 0 0 2.376 2.376h7.918a2.375 2.375 0 0 0 2.375-2.376V6.384a2.376 2.376 0 0 0-2.376-2.375ZM8.518 4.8a.792.792 0 0 1 .792-.791h1.583a.792.792 0 0 1 .792.791v.792H8.52V4.8Zm6.334 11.085a.792.792 0 0 1-.791.792H6.144a.792.792 0 0 1-.792-.792V6.384a.792.792 0 0 1 .792-.792h.791v.792a.792.792 0 0 0 .792.792h4.75a.792.792 0 0 0 .793-.792v-.792h.792a.792.792 0 0 1 .791.792v9.501ZM12.478 8.76h-4.75a.792.792 0 1 0 0 1.584h4.75a.792.792 0 1 0 0-1.584Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)

export default ReportsIcon

import classnames from 'classnames'

interface Props {
  content: number | string
  className?: string
}

const NotificationBadge = ({ content, className }: Props) => (
  <span className={classnames('badge', className)}>{content}</span>
)

export default NotificationBadge

import { memo, useCallback, useMemo, useRef, useState } from 'react'

import { Button, Checkbox, FormControlLabel, FormGroup, Radio, RadioGroup, TextField } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { ListChildComponentProps, VariableSizeList as List } from 'react-window'

import { Placement, HbTooltip } from 'components/HbComponents/HbTooltip'
import { CheckIcon, CloseIcon, SearchIcon } from 'icons'
import { Stringable, Theme } from 'types/hb'

import { DynamicVirtualList, DynamicVirtualListItem } from '../Virtualized/List'
import { EnhancedItemData } from '../Virtualized/types'

const useValuesSelectionStyles = makeStyles((theme: Theme) => ({
  valuesSelection: {
    maxHeight: '250px',
    flexWrap: 'nowrap',
    paddingLeft: theme.spacing(3),
  },
  multipleValuesSelection: {
    width: '100%',
    height: 250,
    boxSizing: 'border-box',
  },
  valuesSelectionLabelRoot: {
    display: 'flex',
  },
  valuesSelectionLabelLabel: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  valuesSelectionButtons: {
    display: 'flex',
    justifyContent: 'space-around',
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(2),
  },
  valuesSelectionButton: {
    width: '45%',
    fontWeight: 400,
  },
  valuesSelectionButtonIcon: {
    fontSize: '16px',
    color: theme.palette.styleguide.mediumGray2,
    paddingLeft: theme.spacing(),
  },
  filterInput: {
    margin: `0 ${theme.spacing(2)}`,
    marginBottom: theme.spacing(),
  },
}))

export interface Option {
  display: string
  value: Stringable
}

interface ValueSelectorProps {
  values: Option[]
  selectedValues: Stringable[]
  setSelectedValues: (values: Stringable[]) => void
  handleValueSelectionChange: (option: Option) => void
  multiple?: boolean
  clearOnAll?: boolean // Valid for multiple select, returns an empty array if selecting all values
}

const ALL_VALUE = '__all__'
function SingleSelector(props: ValueSelectorProps) {
  const { values, selectedValues, setSelectedValues } = props
  const classes = useValuesSelectionStyles()

  return (
    <RadioGroup
      className={classnames('visible-scrollbars', classes.valuesSelection)}
      value={selectedValues[0] ?? ALL_VALUE}
      onChange={(e, value) => setSelectedValues(value === ALL_VALUE ? [] : [value])}
    >
      <FormControlLabel
        classes={{
          root: classes.valuesSelectionLabelRoot,
          label: classes.valuesSelectionLabelLabel,
        }}
        value={ALL_VALUE}
        control={<Radio />}
        label="All"
      />
      {values.map((option) => (
        <HbTooltip
          key={option.value ? option.value.toString() : null}
          title={option.display}
          placement={Placement.Top}
          enterDelay={500}
          showArrow
        >
          <FormControlLabel
            classes={{
              root: classes.valuesSelectionLabelRoot,
              label: classes.valuesSelectionLabelLabel,
            }}
            value={option.value}
            control={<Radio />}
            label={option.display}
          />
        </HbTooltip>
      ))}
    </RadioGroup>
  )
}

interface ListData {
  listItems: Option[]
  handleValueSelectionChange: (option: Option) => void
  isChecked: (value: Stringable) => boolean
}

type RowProps = ListChildComponentProps<EnhancedItemData<ListData>>

const ValueItemContent = memo(({ data: { handleValueSelectionChange, isChecked, listItems }, index }: RowProps) => {
  const classes = useValuesSelectionStyles()
  const option = listItems[index]
  return (
    <HbTooltip
      key={option.value ? option.value.toString() : null}
      title={option.display}
      placement={Placement.Top}
      enterDelay={500}
      showArrow
    >
      <FormControlLabel
        classes={{
          root: classes.valuesSelectionLabelRoot,
          label: classes.valuesSelectionLabelLabel,
        }}
        control={
          <Checkbox
            color="primary"
            checked={isChecked(option.value)}
            aria-checked={isChecked(option.value)}
            onChange={() => handleValueSelectionChange(option)}
            data-testid={`checkbox-${option.display}`}
          />
        }
        label={option.display}
      />
    </HbTooltip>
  )
})

const DEFAULT_ESTIMATED_ITEM_HEIGHT = 40

const ValueItemContainer = memo((props: RowProps) => (
  <DynamicVirtualListItem {...props}>{ValueItemContent}</DynamicVirtualListItem>
))

function MultipleSelector(props: ValueSelectorProps) {
  const { values, clearOnAll, selectedValues, setSelectedValues, handleValueSelectionChange } = props
  const classes = useValuesSelectionStyles()
  const [filter, setFilter] = useState('')

  const isChecked = useCallback(
    (value: Stringable) => {
      // If clearOnAll is set, an empty selection set implies that all values are selected
      if (clearOnAll) {
        return selectedValues.length === 0 || selectedValues.includes(value)
      }

      return selectedValues.includes(value)
    },
    [clearOnAll, selectedValues]
  )

  const listRef = useRef<List<EnhancedItemData<ListData>> | null>(null)

  const itemData = useMemo(
    () => ({
      listItems: values.filter((v) => !filter || v.display.toLowerCase().includes(filter.toLowerCase())),
      isChecked,
      handleValueSelectionChange,
    }),
    [filter, handleValueSelectionChange, isChecked, values]
  )

  const estimatedListHeight = itemData.listItems.length * DEFAULT_ESTIMATED_ITEM_HEIGHT

  return (
    <>
      <div className={classes.valuesSelectionButtons}>
        <Button
          className={classes.valuesSelectionButton}
          onClick={() => setSelectedValues(clearOnAll ? [] : values.map((v) => v.value))}
        >
          Select All
          <CheckIcon className={classes.valuesSelectionButtonIcon} />
        </Button>
        {!clearOnAll && (
          <Button className={classes.valuesSelectionButton} onClick={() => setSelectedValues([])}>
            Clear All
            <CloseIcon className={classes.valuesSelectionButtonIcon} />
          </Button>
        )}
      </div>
      <TextField
        className={classes.filterInput}
        variant="outlined"
        size="small"
        InputProps={{ startAdornment: <SearchIcon /> }}
        inputProps={{ 'aria-label': 'Filter values' }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      />
      <FormGroup
        className={classnames(classes.valuesSelection, classes.multipleValuesSelection)}
        style={{ height: estimatedListHeight }}
      >
        <DynamicVirtualList<ListData>
          ref={listRef}
          estimatedItemSize={DEFAULT_ESTIMATED_ITEM_HEIGHT}
          fallbackItemSize={DEFAULT_ESTIMATED_ITEM_HEIGHT}
          itemData={itemData}
        >
          {ValueItemContainer}
        </DynamicVirtualList>
      </FormGroup>
    </>
  )
}

export function ValueSelector({ multiple, ...props }: ValueSelectorProps) {
  if (multiple) {
    return <MultipleSelector {...props} />
  }
  return <SingleSelector {...props} />
}

import { useState, useEffect, EffectCallback, useRef, useCallback, ForwardedRef } from 'react'

import { InputBaseProps } from '@mui/material'

import { capitalize } from 'lodash'

import { useSelector } from 'actions/store'
import { getOrganizationFeatureFlag, hasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'

export function useEffectOnce(effect: EffectCallback) {
  useEffect(() => {
    return effect()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export function useToggle(initial: boolean, handleSetValue?: (value: boolean) => void, handleToggle?: () => void) {
  const [value, _setValue] = useState(initial)

  const setValue = useCallback(
    (val: boolean) => {
      if (handleSetValue) handleSetValue(val)
      _setValue(val)
    },
    [handleSetValue]
  )

  const toggle = useCallback(() => {
    if (handleToggle) handleToggle()
    setValue(!value)
  }, [handleToggle, setValue, value])

  return {
    value,
    toggle,
    setValue,
  }
}

export function useFeatureFlag(flag: FeatureFlag) {
  return useSelector((state) => getOrganizationFeatureFlag(state, flag))
}

export function useEnabledAndHasPermission(enabledFlag: FeatureFlag, permissionBadge: BadgePermissionsEnum) {
  return useSelector(
    (state) => !!(getOrganizationFeatureFlag(state, enabledFlag) && hasPermission(state, permissionBadge))
  )
}

export const useFeatureFlags = (...flags: FeatureFlag[]) =>
  useSelector((state) => flags.every((flag) => getOrganizationFeatureFlag(state, flag)))

// https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref: any = useRef<T>()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useIsMountedRef() {
  const mounted = useRef(false)

  useEffect(() => {
    mounted.current = true

    return () => {
      mounted.current = false
    }
  }, [])
  return mounted
}

type HandleError = (error: Error) => unknown

// https://usehooks.com/useLocalStorage/
export function useLocalStorage<T>(
  key: string,
  initialValue?: T,
  handleParseError?: HandleError,
  handleSetError?: HandleError
) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key)
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      // If error also return initialValue
      if (handleParseError) handleParseError(error)
      return initialValue
    }
  })

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = useCallback(
    (value: T | ((value: T) => T)) => {
      try {
        // Allow value to be a function so we have same API as useState
        const valueToStore = value instanceof Function ? value(storedValue) : value
        // Save state
        setStoredValue(valueToStore)
        // Save to local storage
        if (typeof window !== 'undefined') {
          window.localStorage.setItem(key, JSON.stringify(valueToStore))
        }
      } catch (error) {
        if (handleSetError) handleSetError(error)
      }
    },
    [storedValue, key, handleSetError]
  )

  return [storedValue, setValue] as const
}

/**
 * Note: prefer using object refs
 * unless you need to accommodate backwards compatibility
 * or be notified of an updated ref value
 * https://github.com/facebook/react/issues/15600
 */
export function useCallbackRef<T extends HTMLElement | any>(ref?: ForwardedRef<T>) {
  const [_ref, setRef] = useState<T | null>(null)
  const getRef = useCallback(
    (node: T) => {
      setRef(node)

      if (ref) {
        if (typeof ref === 'function') {
          ref(node)
        } else {
          ref.current = node
        }
      }
    },
    [ref]
  )

  return [_ref, getRef] as const
}

/**
 * Force autofocus when native autofocus does not suffice.
 */
export const useAutoFocus = <T extends { autoFocus?: boolean } & { InputProps?: InputBaseProps }>(props: T) => {
  // accommodates and updates mui text field props
  const { autoFocus, InputProps: { inputRef: forwardedInputRef, ...InputProps } = {} } = props

  // forwards optional input ref passed down from a parent
  const [inputRef, getInputRef] = useCallbackRef<HTMLInputElement | null>(forwardedInputRef)

  // Apply focus on initial render only, to avoid stealing focus that the user has applied later
  useEffectOnce(() => {
    if (!autoFocus || !inputRef) return
    // state-based value allows focusing the input when the ref is populated
    inputRef.focus()
  })

  const updatedProps = {
    ...props,
    InputProps: {
      ...InputProps,
      inputRef: getInputRef,
    },
  }

  return updatedProps
}

/**
 * Note: for use with `Element` values, including:
 * - `HTMLElement`s like when using React `ref`s,
 * - `ResizeObserverEntry['target']`s in observer callbacks
 */
const overflowDimensions = ['width', 'height'] as const
type OverflowDimension = (typeof overflowDimensions)[number]
const overflowProperties = ['scroll', 'client'] as const
type OverflowProperty = (typeof overflowProperties)[number]
const getOverflowProperty = <P extends OverflowProperty, D extends OverflowDimension>(p: P, d: D) =>
  `${p}${capitalize(d)}` as `${P}${Capitalize<D>}`
export const getElementHasOverflow = <E extends Element>(ref: E, dimension: OverflowDimension = 'width') => {
  const [scroll, available] = overflowProperties.map((property) => getOverflowProperty(property, dimension))
  return ref[scroll] > ref[available]
}

/**
 * Utility to check if an element has exceeded its container dimension.
 * Checking the overflow is memoized to avoid excess reflow.
 * @param dimension - which direction the overflow should be checked
 * @param _ref - optional ref chaining if needed
 */
export const useContentHasOverflow = <E extends HTMLElement>(
  dimension: OverflowDimension = 'width',
  _ref: ForwardedRef<E> = null
) => {
  const [ref, getRef] = useCallbackRef<E | null>(_ref)
  const [hasOverflowed, setHasOverflowed] = useState(false)

  const check = useCallback(() => {
    if (!ref) return
    setHasOverflowed(getElementHasOverflow(ref, dimension))
  }, [dimension, ref])

  useEffect(check, [check])

  return { check, getRef, hasOverflowed, ref }
}

/**
 * @note if the callback is defined within your component, make sure that it's
 * memoized (useCallback) so that it's not called more frequently than desired.
 */
export const useInterval = <T>(cb: () => T, ms: number) => {
  const [state, setState] = useState<T>()
  useEffect(() => {
    setState(cb())
    const id = window.setInterval(() => {
      setState(cb())
    }, ms)
    return () => window.clearInterval(id)
  }, [ms, cb])
  return state
}

import { HTMLProps, useMemo, useRef, useState } from 'react'

import { OutlinedInput, Popover, popoverClasses, styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports

import { HbButton } from 'components/HbComponents/HbButton'
import { MenuList, MenuListItem } from 'components/library/Menu'
import NarrativeTemplateDialog from 'components/narrativeTemplates/NarrativeTemplateDialog'
import { AddIcon, CloseIcon, SearchIcon } from 'icons'
import { TemplateSkeleton } from 'reducers/narrativeTemplatesReducer'

const StyledButton = styled(HbButton)(({ theme }) => ({
  paddingLeft: theme.spacing(0.5),

  '& svg': {
    width: 18,
    height: 18,
    marginRight: 1,
  },
}))

const StyledPopover = styled(Popover)(() => ({
  [`& .${popoverClasses.paper}`]: {
    display: 'flex',
    flexDirection: 'column',
    width: 400,
  },
}))

const StyledList = styled(MenuList)(() => ({
  flex: 1,
  overflowY: 'auto',
}))

const StyledInput = styled(OutlinedInput)(({ theme }) => ({
  margin: theme.spacing(1),
}))

const ManageButton = styled(HbButton)(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: 0,
}))

type Props<T extends TemplateSkeleton> = {
  templates: T[]
  onSelect: (template: T) => void
  onNewTemplate?: () => void
  disabled?: boolean
  loading?: boolean
} & Pick<HTMLProps<HTMLButtonElement>, 'className'>

export function TemplateButton<T extends TemplateSkeleton>(props: Props<T>) {
  const { templates, onSelect, onNewTemplate, disabled, loading, className } = props

  const [expanded, setExpanded] = useState(false)
  const [managerOpen, setManagerOpen] = useState(false)
  const closeMenu = () => setExpanded(false)
  const [query, setQuery] = useState('')

  const openManager = () => {
    closeMenu()
    setManagerOpen(true)
  }

  const onTemplateSelect = (template: T) => {
    onSelect(template)
    closeMenu()
  }

  const closeManager = () => setManagerOpen(false)

  const container = useRef(null)

  const filteredTemplates = useMemo(() => {
    const lowerQuery = query.toLowerCase()
    return templates.filter((template) => template.name.toLowerCase().includes(lowerQuery))
  }, [query, templates])

  return (
    <div ref={container}>
      <StyledButton
        className={className}
        Icon={AddIcon}
        label="Insert template"
        size="small"
        variant="textPrimary"
        disabled={disabled}
        loading={loading}
        onClick={() => setExpanded(!expanded)}
      />

      <StyledPopover open={expanded} anchorEl={container.current} onClose={closeMenu}>
        <StyledInput
          autoFocus
          startAdornment={<SearchIcon />}
          endAdornment={
            query && (
              <HbButton label="Clear input" iconOnly size="small" onClick={() => setQuery('')}>
                <CloseIcon />
              </HbButton>
            )
          }
          placeholder="Search"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <StyledList>
          <MenuListItem placeholder>Insert Template</MenuListItem>
          {filteredTemplates.map((template) => (
            <MenuListItem key={template.token} onClick={() => onTemplateSelect(template)}>
              {template.name}
            </MenuListItem>
          ))}
        </StyledList>

        <ManageButton label="Manage Templates" onClick={openManager} variant="textSecondary" />
      </StyledPopover>

      <NarrativeTemplateDialog open={managerOpen} onClose={closeManager} onNewTemplate={onNewTemplate} />
    </div>
  )
}

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import VisuallyHidden from '@reach/visually-hidden'

import AccountAvatar from 'components/accounts/AccountAvatar'
import { MenuListItem } from 'components/library/Menu'
import OutOfOfficeBadge from 'components/library/OutOfOfficeBadge'
import { CircleRemoveIcon } from 'icons'
import { Account, Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  iconWrap: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  accountName: {
    marginLeft: theme.spacing(1.5),
    flex: '1 1 auto',
    textAlign: 'left',
  },
}))

export type Props = {
  account: Account
  onClickRemove?: () => void
  canRemove?: boolean
} & any // TODO should be <MenuListItem props when that component is converted to TS

export const AccountMenuItem = ({ account, onClickRemove, canRemove, ...otherProps }: Props) => {
  const classes = useStyles()

  return (
    <MenuListItem {...otherProps}>
      <div className={classes.iconWrap}>
        <AccountAvatar color={account.avatarColor} variant={account.avatarVariant}>
          {account.initials}
        </AccountAvatar>
        <div className={classes.accountName} data-testid={`account_${account.fullName}`}>
          {account.fullName}
          <OutOfOfficeBadge account={account} />
        </div>
        {canRemove && (
          <button type="button" onClick={onClickRemove} tabIndex={0}>
            <CircleRemoveIcon fontSize="small" />
            <VisuallyHidden>Un-Assign</VisuallyHidden>
          </button>
        )}
      </div>
    </MenuListItem>
  )
}

export default AccountMenuItem

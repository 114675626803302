import { useCallback, useRef } from 'react'

import { SnackbarKey, useSnackbar } from 'notistack'

import { AllVariants } from './HbNotistackSnackbar'

type UseLongProcessSnackbars =
  | {
      status: 'loading'
      message: React.ReactNode
      onCancel?: () => void
    }
  | {
      status: 'success'
      message: React.ReactNode
      buttonProps?: {
        text: string
        onClick: () => void
      }
    }
  | {
      status: 'error'
      message?: React.ReactNode
    }

type UseLongProcessSnackbarsWithOverrides = UseLongProcessSnackbars & {
  variant?: AllVariants
  iconOverride?: React.FC<{ className?: string }>
}

// If a process that a user is initiating could take a long time (> 10 seconds),
// consider using a snackbar to show the status of that task instead of a showing
// a loading screen so that the user isn't blocked from doing other tasks while waiting.
export function useLongProcessSnackbars() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const loadingSnackbarIDMap = useRef<Record<string, SnackbarKey>>({})

  return useCallback(
    (uniqueyKey: string, props: UseLongProcessSnackbarsWithOverrides) => {
      const { message, status, variant, iconOverride } = props
      const loadingSnackbarID = loadingSnackbarIDMap.current[uniqueyKey]
      // Close loading snackbar corresponding to unique key
      if (status !== 'loading' && loadingSnackbarID && uniqueyKey in loadingSnackbarIDMap.current) {
        closeSnackbar(loadingSnackbarID)
        delete loadingSnackbarIDMap.current[uniqueyKey]
      }
      const sharedProps = {
        testId: `${uniqueyKey}:${status}`,
        variant: variant ?? status,
        iconOverride,
      }
      switch (status) {
        case 'loading': {
          const newLoadingSnackbarID = enqueueSnackbar(message, {
            ...sharedProps,
            persist: true,
            buttonProps: props.onCancel
              ? {
                  text: 'Cancel',
                  onClick: (id) => {
                    closeSnackbar(id)
                    if (props.onCancel) {
                      props.onCancel()
                    }
                  },
                }
              : undefined,
          })
          loadingSnackbarIDMap.current[uniqueyKey] = newLoadingSnackbarID
          return newLoadingSnackbarID
        }
        case 'success': {
          return enqueueSnackbar(message, {
            ...sharedProps,
            persist: true,
            buttonProps: props.buttonProps
              ? {
                  text: props.buttonProps.text,
                  onClick: (id) => {
                    closeSnackbar(id)
                    props.buttonProps?.onClick()
                  },
                }
              : undefined,
          })
        }
        case 'error': {
          // Usually error snackbars are handled by Apollo, so showing
          // an error message here could be redundant. Calling this function
          // with {status: 'error'} even without a message results in the
          // corresponding loading snackbar to be hidden.
          if (!message) {
            return null
          }
          return enqueueSnackbar(message, {
            ...sharedProps,
          })
        }
        default:
          return null
      }
    },
    [closeSnackbar, enqueueSnackbar]
  )
}

export type UpdateLongProcessSnackbars = ReturnType<typeof useLongProcessSnackbars>

import { ReactElement, useCallback, useEffect, useRef, useState } from 'react'

import { useTheme } from '@emotion/react'
import { ChevronLeft, ChevronRight } from '@mui/icons-material'
import { styled } from '@mui/material'

import { throttle } from 'lodash'

import { HbFab } from 'components/HbComponents/HbFab'

import { Gap, HorizontalOverflowList } from './LayoutHelpers'

const FAB_SIZE = 40

type CardCarouselBaseProps = {
  cardWidthPx: number
  gap?: Gap
  stepMultiplier?: 1 | 2 | 3
}

export type CardCarouselProps<Data> = {
  cardData: Array<Data>
  renderCard: (data: Data) => ReactElement
  getKey: (data: Data, i: number) => string | number
  showScrollButtons?: boolean
} & CardCarouselBaseProps

const Root = styled('div')(() => ({
  position: 'relative',
}))

const StyledScrollButton = styled(HbFab)<{ direction: 'right' | 'left'; disabled?: boolean }>(
  ({ theme, direction, disabled }) => ({
    '&&': {
      minWidth: FAB_SIZE,
      minHeight: FAB_SIZE,
      width: FAB_SIZE,
      height: FAB_SIZE,
    },
    position: 'absolute',
    top: '50%',
    transform: `translateY(-${FAB_SIZE / 2}px)`,
    right: direction === 'right' ? theme.spacing(3) : 'auto',
    left: direction === 'left' ? theme.spacing(3) : 'auto',
    opacity: disabled ? 0 : 1,
  })
)

const StyledHorizontalOverflowList = styled(HorizontalOverflowList)(({ theme }) => ({
  padding: theme.spacing(3),
  boxSizing: 'border-box',
  position: 'relative',
}))

const StyledLi = styled('li')<Pick<CardCarouselBaseProps, 'cardWidthPx'>>(({ cardWidthPx }) => ({
  flex: `0 0 ${cardWidthPx}px`,
}))

const useGapValueToNumber = (gap: Gap) => {
  const theme = useTheme()
  // See https://github.com/mui/material-ui/issues/29086
  return Number.parseInt(theme.spacing(gap), 10)
}

export function CardCarousel<Data>({
  cardData,
  renderCard,
  getKey,
  cardWidthPx,
  gap = 2,
  stepMultiplier = 1,
  showScrollButtons = true,
}: CardCarouselProps<Data>) {
  // Used for conditionally showing/hiding scroll buttons
  const [scrollPosition, setScrollPosition] = useState<'start' | 'middle' | 'end'>('start')

  const scrollContainer = useRef<HTMLUListElement | null>(null)

  const gapValueNumber = useGapValueToNumber(gap)

  const handleScrollClick = (direction: 'left' | 'right') => () => {
    const difference = (gapValueNumber + cardWidthPx) * (direction === 'right' ? 1 : -1) * stepMultiplier
    if (!scrollContainer.current) return
    scrollContainer.current.scrollTo({
      left: scrollContainer.current.scrollLeft + difference,
      behavior: 'smooth',
    })
  }

  const setScrollPositionFromContainer = useCallback(() => {
    if (!scrollContainer.current) return
    const { offsetWidth, scrollLeft, scrollWidth } = scrollContainer.current
    if (scrollLeft <= 0) {
      setScrollPosition('start')
      return
    }
    const scrollRight = scrollWidth - (offsetWidth + scrollLeft)
    if (scrollRight <= 0) {
      setScrollPosition('end')
      return
    }
    setScrollPosition('middle')
  }, [setScrollPosition])

  useEffect(() => {
    // Set scroll position initially
    setScrollPositionFromContainer()
  }, [setScrollPositionFromContainer])

  const handleScroll = () => {
    // Update scroll position on scroll
    setScrollPositionFromContainer()
  }

  return (
    <Root>
      <StyledScrollButton
        direction="left"
        label="Scroll back"
        onClick={handleScrollClick('left')}
        disabled={scrollPosition === 'start'}
        Icon={ChevronLeft}
        iconOnly
        variant="primary"
      />
      <StyledHorizontalOverflowList gap={gap} ref={scrollContainer} onScroll={throttle(handleScroll, 200)}>
        {cardData.map((d, i) => (
          <StyledLi key={getKey(d, i)} cardWidthPx={cardWidthPx}>
            {renderCard(d)}
          </StyledLi>
        ))}
      </StyledHorizontalOverflowList>
      {showScrollButtons && (
        <StyledScrollButton
          direction="right"
          label="Scroll forward"
          onClick={handleScrollClick('right')}
          disabled={scrollPosition === 'end'}
          Icon={ChevronRight}
          iconOnly
          variant="primary"
        />
      )}
    </Root>
  )
}

import { useEffect, useState } from 'react'

import { Box, Chip } from '@mui/material'
import { styled } from '@mui/material/styles'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AttachmentSummaryFeedbackForm } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/AttachmentSummaryFeedbackForm'
import { Feedback } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/AttachmentSummaryFooter.types'
import { ThumbsDownIcon, ThumbsDownRedIcon } from 'icons/ThumbsDownIcon'
import { ThumbsUpGreenIcon, ThumbsUpIcon } from 'icons/ThumbsUpIcon'
import { AttachmentSummary, AttachmentSummaryFeedbackRatingEnum } from 'types/api'

export interface AttachmentSummaryFeedbackProps {
  caseToken: string
  attachmentToken: string
  summary: AttachmentSummary
}

const FeedbackContainer = styled(Box)(({ theme }) => ({
  width: '100%',
  alignContent: 'center',
  borderRadius: theme.spacing(1),
  background: '#FFF',
  padding: `0 ${theme.spacing(2.5)}`,
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.16)',
}))

const FeedbackHeader = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(0.5)} 0`,
  alignItems: 'center',
}))

const FeedbackChip = styled(Chip)(({ theme }) => ({
  borderRadius: theme.spacing(1),
  userSelect: 'none',
}))

const RatingButton = styled(HbButton)<{
  rating?: AttachmentSummaryFeedbackRatingEnum
  textColor: 'green' | 'red'
}>(({ theme, rating, textColor }) => {
  const coloring: { color?: string } = {}

  if (rating === 'Yay') coloring.color = textColor === 'green' ? theme.palette.success.main : undefined
  if (rating === 'Nay') coloring.color = textColor === 'red' ? theme.palette.error.main : undefined

  return {
    ...coloring,
    '&:focus:hover': coloring,
    '&:hover': coloring,
    '&:focus': coloring,
    padding: '8px 20px',
  }
})

const buildFeedback = ({
  attachmentToken,
  summary,
}: {
  attachmentToken: string
  summary: AttachmentSummaryFeedbackProps['summary']
}): Feedback =>
  summary.userFeedback
    ? { attachmentToken, rating: summary.userFeedback.rating, submitted: !!summary.userFeedback.rating }
    : { attachmentToken, submitted: false }

export const AttachmentSummaryFeedback = ({ caseToken, summary, attachmentToken }: AttachmentSummaryFeedbackProps) => {
  const [feedback, setFeedback] = useState<Feedback>(buildFeedback({ attachmentToken, summary }))
  const { submitted } = feedback

  const isThumbsUpSelected = feedback?.rating === AttachmentSummaryFeedbackRatingEnum.Yay
  const isThumbsDownSelected = feedback?.rating === AttachmentSummaryFeedbackRatingEnum.Nay

  useEffect(() => {
    // if the token changes, we should reset the state, since this can stay mounted even when the attachment changes
    if (feedback.attachmentToken !== attachmentToken) {
      setFeedback(buildFeedback({ attachmentToken, summary }))
    }
  }, [attachmentToken, feedback.attachmentToken, summary])

  return (
    <FeedbackContainer>
      <FeedbackHeader>
        <FeedbackChip
          color="default"
          size="small"
          label={
            <HbText bold size="s">
              Feedback
            </HbText>
          }
        />
        <HbTooltip title="The summary was good" placement="top">
          <RatingButton
            rating={feedback.rating}
            textColor="green"
            label="Good"
            variant="textSecondary"
            disabled={submitted}
            Icon={isThumbsUpSelected ? ThumbsUpGreenIcon : ThumbsUpIcon}
            onClick={() => {
              if (feedback.rating !== 'Yay') {
                setFeedback((original) => ({ ...original, rating: AttachmentSummaryFeedbackRatingEnum.Yay }))
              } else {
                setFeedback((original) => ({ ...original, rating: undefined }))
              }
            }}
          />
        </HbTooltip>
        <HbTooltip title="The summary was poor" placement="top">
          <RatingButton
            rating={feedback.rating}
            textColor="red"
            label="Poor"
            variant="textSecondary"
            disabled={submitted}
            Icon={isThumbsDownSelected ? ThumbsDownRedIcon : ThumbsDownIcon}
            onClick={() => {
              if (feedback.rating !== 'Nay') {
                setFeedback((original) => ({ ...original, rating: AttachmentSummaryFeedbackRatingEnum.Nay }))
              } else {
                setFeedback((original) => ({ ...original, rating: undefined }))
              }
            }}
          />
        </HbTooltip>
      </FeedbackHeader>
      {feedback.rating && (
        <AttachmentSummaryFeedbackForm
          caseToken={caseToken}
          summary={summary}
          attachmentToken={attachmentToken}
          feedback={feedback}
          setFeedback={setFeedback}
        />
      )}
    </FeedbackContainer>
  )
}

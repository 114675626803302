import { ClassNames } from '@emotion/react'

import { ComponentPropsWithoutRef } from 'react-markdown/lib/ast-to-react'

import FilterControls from 'dashboards/reviews/components/Header/filtering/FilterControls'
import { DashboardViewSelector } from 'dashboards/shared/components/Dashboard/DashboardViewSelector'
import { DashboardHeader } from 'dashboards/shared/components/Header/DashboardHeader'

const FilterControlsWrapper = () => <FilterControls size="small" iconOnly={false} />
const DashboardViewSelectorWrapper = () => <DashboardViewSelector size="small" />
export const ReportDashboardHeader = (props: ComponentPropsWithoutRef<typeof DashboardHeader>) => {
  return (
    <ClassNames>
      {({ css, theme }) => (
        <DashboardHeader
          {...props}
          titleTag="h1"
          styleOverrides={{
            PageHeader: {
              main: css({ paddingRight: theme.spacing() }),
              title: css({
                ...theme.typography.sizes.xl,
                fontWeight: 500,
              }),
            },
          }}
          FilterControlsComponent={FilterControlsWrapper}
          ViewSelectorComponent={DashboardViewSelectorWrapper}
        />
      )}
    </ClassNames>
  )
}

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

const FadeBarContainer = styled(Box)<{ variant: 'top' | 'bottom' }>(({ variant }) => ({
  position: 'sticky',
  ...(variant === 'bottom' ? { bottom: 0 } : { top: 0 }),
  width: '100%',
  height: 0,
}))

const FadeBarOverlay = styled(Box)<{ variant: 'top' | 'bottom' }>(({ variant, theme }) => ({
  position: 'absolute',
  ...(variant === 'bottom' ? { bottom: 0 } : { top: 0 }),
  width: '100%',
  height: theme.spacing(5),
  backgroundImage:
    variant === 'bottom'
      ? `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0),
          rgba(255, 255, 255, 1) 100%
        )`
      : `linear-gradient(
          to bottom,
          rgba(255, 255, 255, 1),
          rgba(255, 255, 255, 0) 100%
        )`,
}))

export const FadeBar = ({ position = 'bottom' }: { position: 'top' | 'bottom' }) => (
  <FadeBarContainer variant={position}>
    <FadeBarOverlay variant={position} />
  </FadeBarContainer>
)

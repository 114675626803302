import { gql } from '@apollo/client'

export const ATTACHMENT_FRAGMENT = gql`
  fragment Attachment on Attachment {
    token
    contentType
    dataSource
    filename
    fileSizeBytes
    contentType
    path
    updatedAt
    uploadedAt
    uploadedBy {
      token
      fullName
    }
    summarizable
    summary @include(if: $enableAttachmentSummarization) {
      token
      tooBig
      status
      statements
      createdAt
      inProgress
      userFeedback {
        token
        rating
        createdAt
        updatedAt
      }
    }
  }
`

export const GET_CASE_ATTACHMENT = gql`
  query GetCaseAttachment($caseToken: String!, $attachmentToken: String!, $enableAttachmentSummarization: Boolean!) {
    investigation(token: $caseToken) {
      token
      attachment(attachmentToken: $attachmentToken) {
        ...Attachment
      }
    }
  }

  ${ATTACHMENT_FRAGMENT}
`

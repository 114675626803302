import { HbButton } from 'components/HbComponents/HbButton'

export const DashboardExportActions = ({
  isLoading,
  closeModal,
  submitForm,
  disableSubmit,
  isSubmitting,
  closeModalLabel = 'Return to Dashboard',
}: {
  isLoading: boolean
  isSubmitting: boolean
  closeModal: () => void
  submitForm: () => void
  disableSubmit: boolean
  closeModalLabel?: string
}) => {
  if (isLoading) {
    return <HbButton label={closeModalLabel} onClick={closeModal} />
  }
  return (
    <>
      <HbButton label="Cancel" variant="textSecondary" onClick={closeModal} />
      <HbButton label="Export" onClick={submitForm} disabled={disableSubmit || isSubmitting} loading={isSubmitting} />
    </>
  )
}

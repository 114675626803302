import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { HbColorsByHue } from 'components/colors'

import { SvgProps } from '../SvgProps'

export const MIDDESK_BACKGROUND_COLOR = HbColorsByHue.thirdPartyMiddeskGreen.dark
export const MIDDESK_FOREGROUND_COLOR = HbColorsByHue.thirdPartyMiddeskGreen.light

export const MiddeskIcon: FC<SvgProps> = (props) => {
  const { fill, ...rest } = props
  return (
    <SvgIcon {...rest} viewBox="0 0 200.09 114.3">
      <g fill={fill}>
        <rect y="84.08" width="30.22" height="30.22" />
        <polygon points="7.95 19.97 7.95 0 27.92 0 120.09 94.33 120.12 94.33 120.12 94.36 120.12 94.36 120.12 94.36 120.12 114.3 100.14 114.3 100.14 114.27 7.95 19.97" />
        <polygon points="87.92 19.97 87.92 0 107.89 0 200.06 94.33 200.09 94.33 200.09 94.36 200.09 94.36 200.09 94.36 200.09 114.3 180.12 114.3 180.12 114.27 87.92 19.97" />
      </g>
    </SvgIcon>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const FilePDFIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M4.59 15.5a1 1 0 0 1-.59-.19 1.23 1.23 0 0 1-.5-.9A1.67 1.67 0 0 1 4.11 13a9 9 0 0 1 2.19-1.38q.26-.57.51-1.13A25.76 25.76 0 0 0 8.06 7l.08-.31-.2-.46a4.61 4.61 0 0 1-.56-2.78 1.15 1.15 0 0 1 1.08-.95 1 1 0 0 1 .77.36 3 3 0 0 1 .22 2.25v.18c-.05.41-.13.83-.22 1.26a22.69 22.69 0 0 0 1.22 2.17q.29.46.6.91a3.56 3.56 0 0 0 .32.41h.06l.38-.09a4.44 4.44 0 0 1 1.66-.21 1.33 1.33 0 0 1 .87.49 1 1 0 0 1 .19.75 1.34 1.34 0 0 1-1.53 1.1 3.62 3.62 0 0 1-2-.94l-1.23.33a22.76 22.76 0 0 0-2.77.91q-.25.52-.52 1l-.17.35a7.92 7.92 0 0 1-.36.72H6a1.94 1.94 0 0 1-1.14 1 1.06 1.06 0 0 1-.27.05zm.95-2.33a6.66 6.66 0 0 0-.76.57.67.67 0 0 0-.27.59.22.22 0 0 0 .09.17 1 1 0 0 0 .54-.5 6.87 6.87 0 0 0 .31-.62zm6.78-2.34a2 2 0 0 0 .75.26c.15 0 .41 0 .44-.27a.34.34 0 0 0-.21-.1 2.83 2.83 0 0 0-.99.11zM8.81 8a29.88 29.88 0 0 1-1.09 2.9l-.09.2c.64-.22 1.28-.41 1.89-.58l.73-.2-.08-.11q-.31-.47-.61-.94C9.28 8.8 9 8.38 8.81 8zm-.35-4.5a.31.31 0 0 0-.11.18 2.18 2.18 0 0 0 .13 1.15c.15-1.05.03-1.28-.02-1.33z" />
    <path d="M18 18H0V0h18zM1 17h16V1H1z" />
  </SvgIcon>
)

export default FilePDFIcon

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const NarrativeIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M19.71,8.29001674 C19.8971865,8.47873557 20.0015368,8.73421375 20,9.00001674 L20,21.0000167 C20,21.5523015 19.5522847,22.0000167 19,22.0000167 L6,22.0000167 C5.44771525,22.0000167 5,21.5523015 5,21.0000167 L5,5.00001674 C5,4.44773199 5.44771525,4.00001674 6,4.00001674 L15,4.00001674 C15.265803,3.99847998 15.5212812,4.10283022 15.71,4.29001674 L19.71,8.29001674 Z M7,20.0000167 L18,20.0000167 L18,10.0000167 L15,10.0000167 C14.4477153,10.0000167 14,9.55230149 14,9.00001674 L14,6.00001674 L7,6.00001674 L7,20.0000167 Z M9,12.0000167 L16,12.0000167 L16,14.0000167 L9,14.0000167 L9,12.0000167 Z M9,8.00001674 L12,8.00001674 L12,10.0000167 L9,10.0000167 L9,8.00001674 Z M9,16.0000167 L16,16.0000167 L16,18.0000167 L9,18.0000167 L9,16.0000167 Z" />
  </SvgIcon>
)

export default NarrativeIcon

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const ErrorOutlineIcon = ({ width = '24', height = '24', id = 'iconErrorOutline', ...restProps }: SvgProps) => (
  <SvgIcon id={id} width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" {...restProps}>
    <path
      d="M12 7c.6 0 1 .5 1 1v4c0 .6-.4 1-1 1a1 1 0 0 1-1-1V8c0-.5.4-1 1-1Zm0-5a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm0 18a8 8 0 1 1 0-16 8 8 0 0 1 0 16Zm1-3h-2v-2h2v2Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default ErrorOutlineIcon

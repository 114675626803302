import { gql } from '@apollo/client'

import { HbText } from 'components/HbComponents/Text/HbText'
import ConfirmDialog from 'components/library/ConfirmDialog'

import { AutomationRuleExecutionMode } from 'types/api'

import { SaveEnableDisableDialogAutomationRuleFragment as AutomationRule } from './__generated__/SaveEnableDisableDialog.generated'

interface Props {
  onSaveAndEnable: () => void
  onDisable: () => void
  onEnable: () => void
  onClose: () => void
  onReset: () => void
  open: boolean
  automationRuleDirty: boolean
  automationRule: AutomationRule
  loading: boolean
}

export default function SaveEnableDisableDialog(props: Props) {
  const { onSaveAndEnable, onDisable, onEnable, onClose, onReset, open, automationRule, automationRuleDirty, loading } =
    props

  const handleConfirmSaveAndEnable = () => {
    onSaveAndEnable()
    onClose()
  }

  const handleConfirmDisable = () => {
    onDisable()
    onClose()
  }

  const handleConfirmEnable = () => {
    onEnable()
    onClose()
  }

  const handleResetAndClose = () => {
    onReset()
    onClose()
  }

  const getSettings = () => {
    if (
      automationRule.enabled &&
      automationRuleDirty &&
      automationRule.executionMode === AutomationRuleExecutionMode.Preview
    ) {
      return {
        confirmText: 'Yes, Disable Preview Mode',
        titleText: 'Preview Mode Enabled',
        bodyText:
          'To continue editing, you must disable preview mode. Any pending runs will be discarded and can not be recovered. Would you like to disable it now?',
        handleConfirm: handleConfirmDisable,
        handleClose: handleResetAndClose,
      }
    }

    if (automationRule.enabled && automationRule.executionMode === AutomationRuleExecutionMode.Preview) {
      return {
        confirmText: 'Yes, Disable Preview Mode',
        titleText: 'Disable Preview Mode',
        bodyText:
          'Are you sure you want to disable preview mode? Any pending runs will be discarded and can not be recovered.',
        handleConfirm: handleConfirmDisable,
        handleClose: onClose,
      }
    }

    if (automationRule.enabled && automationRuleDirty) {
      return {
        confirmText: 'Yes, Disable Automation',
        titleText: 'Automation Already Enabled',
        bodyText: 'To continue editing, you must disable this automation recipe. Would you like to disable it now?',
        handleConfirm: handleConfirmDisable,
        handleClose: handleResetAndClose,
      }
    }

    if (automationRule.disabled && automationRuleDirty) {
      return {
        confirmText: 'Yes, Save and Enable Automation',
        titleText: 'Save and Enable Automation',
        bodyText: 'Are you sure you want to save and enable this automation recipe?',
        handleConfirm: handleConfirmSaveAndEnable,
        handleClose: onClose,
      }
    }

    if (automationRule.disabled) {
      return {
        confirmText: 'Yes, Enable Automation',
        titleText: 'Enable Automation',
        bodyText: 'Are you sure you want to enable this automation recipe?',
        handleConfirm: handleConfirmEnable,
        handleClose: onClose,
      }
    }

    return {
      confirmText: 'Yes, Disable Automation',
      titleText: 'Disable Automation',
      bodyText: 'Are you sure you want to disable this automation recipe?',
      handleConfirm: handleConfirmDisable,
      handleClose: onClose,
    }
  }

  const confirmVariant = automationRule.disabled ? 'primary' : 'warning'

  const settings = getSettings()

  return (
    <ConfirmDialog
      confirmText={settings.confirmText}
      confirmVariant={confirmVariant}
      onConfirm={settings.handleConfirm}
      open={open}
      onClose={settings.handleClose}
      title={settings.titleText}
      loading={loading}
    >
      <HbText tag="p">{settings.bodyText}</HbText>
    </ConfirmDialog>
  )
}

SaveEnableDisableDialog.fragments = {
  automationRule: gql`
    fragment SaveEnableDisableDialogAutomationRule on CanonicalAutomationRule {
      ... on CanonicalAutomationRuleBase {
        disabled
        enabled
        executionMode
      }
    }
  `,
}

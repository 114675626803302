type NonFalsy<T> = Exclude<T, false | 0 | '' | null | undefined | 0n | typeof NaN>

export function isTruthy<T>(t: T): t is NonFalsy<T> {
  return Boolean(t)
}

export function assertTruthy<T>(t: T): NonFalsy<T> {
  if (!isTruthy(t)) {
    throw new Error('Expected a truthy value')
  }

  return t
}

import { ClearRounded } from '@mui/icons-material'

import { useDispatch } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { TitleTag } from 'components/HbComponents/Text/HbText'
import { PageHeader, PageHeaderOverrides } from 'components/library/Page/PageHeader'

import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'

export interface Props {
  query: string | undefined
  styleOverrides?: PageHeaderOverrides
  titleTag?: TitleTag
}

export function SearchDashboardHeader(props: Props) {
  const dashboardsActions = useDashboardActions()
  const { query, styleOverrides, titleTag } = props
  const dispatch = useDispatch()

  return (
    <PageHeader
      title={`Search results for "${query}"`}
      titleTag={titleTag}
      styleOverrides={styleOverrides}
      titleRow={
        <HbButton
          variant="textSecondary"
          label="Clear search"
          Icon={ClearRounded}
          onClick={() => dispatch(dashboardsActions.query.set.action(''))}
        />
      }
    />
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef, useState } from 'react'

import { ButtonBase } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import classnames from 'classnames'

import { Link } from 'react-router-dom'

import { useSelector } from 'actions/store'
import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'
import AccountAvatar from 'components/accounts/AccountAvatar'
import ExternalLink from 'components/library/ExternalLink'
import { Menu, MenuList, MenuListItem, MenuListItemDivider } from 'components/library/Menu'
import { BUTTON_OUTLINE_STYLE } from 'components/themeRedesign'
import { getCurrentAccount, getCurrentOrganizationToken, getOrganizationFeatureFlag } from 'helpers/stateHelpers'
import UrlResolver from 'helpers/urlResolver'

import { FeatureFlag } from 'types/api'
import { Theme, WithStyles } from 'types/hb'

import { broadcastMessage } from 'utils/broadcastChannel'

import { useHeaderMenuStyles } from './HeaderMenuStyles'
import OrganizationSwapper from './OrganizationSwapper'

const styles = (theme: Theme) => ({
  link: {
    color: theme.palette.styleguide.darkGray,
    textDecoration: 'none',
    outline: 'none',
  },

  popper: {
    zIndex: theme.zIndex.modal + 1,
    marginLeft: `${theme.spacing(1)} !important`,
  },

  list: {
    minWidth: 280,
    padding: `${theme.spacing()} 0px`,
  },

  signOut: {
    '& $link': {
      // ughhh
      paddingLeft: `${theme.spacing(2)} !important`,
    },
  },
  accountButton: {
    borderRadius: '50%',
    '&:focus': {
      outline: BUTTON_OUTLINE_STYLE,
    },
  },
})

type Props = WithStyles<typeof styles>

function HeaderAccountMenu(props: Props) {
  const { classes } = props
  const menuClasses = useHeaderMenuStyles()

  const urls = useSelector((state) => state.application.urls)
  const currentAccount = useSelector(getCurrentAccount)
  const showSARVerificationSuite = useSelector((state) =>
    getOrganizationFeatureFlag(state, FeatureFlag.EnableDocumentValidationTestReport)
  )
  const currentOrganizationToken = useSelector(getCurrentOrganizationToken)

  const urlResolver = new UrlResolver()
  const [isOpen, setOpen] = useState<boolean>(false)
  const menuToggleRef = useRef<HTMLButtonElement | null>(null)
  const close = () => setOpen(false)

  const linkClass = classnames(menuClasses.link, classes.link)

  return (
    <div>
      <HbTooltip title="Account" placement={Placement.Left}>
        <ButtonBase
          className={classes.accountButton}
          ref={menuToggleRef}
          onClick={() => setOpen(!isOpen)}
          aria-label="Account"
        >
          <AccountAvatar color={currentAccount.avatarColor}>{currentAccount.initials}</AccountAvatar>
        </ButtonBase>
      </HbTooltip>
      <Menu
        popperMenu
        trigger={menuToggleRef.current}
        open={isOpen}
        onClose={close}
        classes={{ popper: classes.popper }}
      >
        <MenuList className={classes.list} separated>
          <MenuListItem className={menuClasses.header} title>
            {currentAccount.fullName}
          </MenuListItem>
          {import.meta.env.DEV ? (
            <MenuListItem className={menuClasses.item} link>
              <Link
                className={linkClass}
                href={urls.graphiqlPagePath}
                to={urls.graphiqlPagePath}
                tabIndex={-1}
                onClick={close}
              >
                GraphiQL
              </Link>
            </MenuListItem>
          ) : null}
          <MenuListItem className={menuClasses.item} link>
            <Link className={linkClass} href={urls.settingsPath} to={urls.settingsPath} tabIndex={-1} onClick={close}>
              Settings
            </Link>
          </MenuListItem>
          <MenuListItem className={menuClasses.item} link>
            <ExternalLink classes={{ root: linkClass }} to="http://help.hummingbird.co">
              Help Center
            </ExternalLink>
          </MenuListItem>
          {showSARVerificationSuite && (
            <MenuListItem className={menuClasses.item} link>
              <a
                href={urlResolver.resolve(urls.organizationDocumentValidationIndexPath, {
                  organizationToken: currentOrganizationToken,
                })}
                className={linkClass}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={-1}
              >
                SAR Verification Suite
              </a>
            </MenuListItem>
          )}
          <OrganizationSwapper />
          <MenuListItemDivider className={menuClasses.divider} />
          <MenuListItem className={classes.signOut} link>
            <a
              onClick={() => {
                broadcastMessage('SIGN_OUT')
              }}
              href={urls.destroyCredentialSessionPath}
              className={linkClass}
              tabIndex={-1}
            >
              Sign Out
            </a>
          </MenuListItem>
        </MenuList>
      </Menu>
    </div>
  )
}

export default withStyles(styles)(HeaderAccountMenu)

import { difference } from 'lodash'

import { ITagState } from './ApplicableTags'

const getTagsDiff = (oldTags: string[], newTags: string[]) => {
  const removedTagTokens = difference(oldTags, newTags)
  const addedTagTokens = difference(newTags, oldTags)

  return [
    ...addedTagTokens.map((token) => ({ token, applied: true })),
    ...removedTagTokens.map((token) => ({ token, applied: false })),
  ]
}

export const getNewTagState = (values: ITagState[], newValues: string[]) => {
  const tagDiff = getTagsDiff(
    values.filter((v) => v.applied).map((v) => v.token),
    newValues
  )

  const modifiedTags = tagDiff.map((t) => t.token)

  return [
    // Include prior state, minus deltas
    ...values.filter((f) => modifiedTags.indexOf(f.token) === -1),
    // Include deltas
    ...tagDiff,
  ].sort((a, b) => (a.token > b.token ? 1 : -1))
}

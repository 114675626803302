export enum FormErrorMessages {
  Required = 'Please provide an answer.',
  InvalidMimeType = 'File type is invalid. Please remove and try again.',
  UploadMissing = 'File is missing. Please upload and try again.',
  Email = 'Invalid email',
  UniqueEmail = 'Email addresses must be unique',
  ValidDate = 'Please provide a valid date',
  DueDatePast = 'Due date cannot be in the past',
  TextExceeds256Characters = 'Exceeds maximum limit of 256 characters',
}

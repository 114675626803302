// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import ErrorOutlineIcon from 'icons/ErrorOutlineIcon'

import { LibraryTypeEnum } from 'types/api'
import { Theme } from 'types/hb'

import {
  MergeBusinessSubject,
  MergeFinancialInstitutionSubject,
  MergePersonSubject,
  MergeSubject,
  SharedStepProps,
} from '../types'
import { SetSelectedPrimarySubjectToken } from '../useSubjectMerging'

import { BusinessesCompareTable } from './Businesses'
import { FinancialInstitutionsCompareTable } from './FinancialInstitutions'
import { PeopleCompareTable } from './People'

const useDisclaimerStyles = makeStyles((theme: Theme) => ({
  root: {
    background: theme.palette.styleguide.lemonLight,
    display: 'flex',
    padding: theme.spacing(2, 3),
    position: 'sticky',
    bottom: 0,
    zIndex: 1,
  },
  text: {
    marginLeft: theme.spacing(),
    lineHeight: '22px',
  },
}))

export const Disclaimer = () => {
  const classes = useDisclaimerStyles()
  return (
    <div className={classes.root}>
      <ErrorOutlineIcon />
      <HbText tag="p" className={classes.text}>
        The fields shown here are those in which only one value is accepted in a SAR/STR filing. All of the values will
        be retained after these profiles are merged, but those from the selected primary account will be used to
        populate filings.
      </HbText>
    </div>
  )
}

interface CompareTableProps {
  libraryType: LibraryTypeEnum
  selectedSubjects: MergeSubject[]
  selectedPrimarySubjectToken: string | null
  setSelectedPrimarySubjectToken: SetSelectedPrimarySubjectToken
}

const CompareTable = ({
  libraryType,
  selectedSubjects,
  setSelectedPrimarySubjectToken,
  selectedPrimarySubjectToken,
}: CompareTableProps) => {
  const sharedProps = {
    setSelectedPrimarySubjectToken,
    selectedPrimarySubjectToken,
  }
  switch (libraryType) {
    case LibraryTypeEnum.People: {
      return <PeopleCompareTable selectedSubjects={selectedSubjects as MergePersonSubject[]} {...sharedProps} />
    }
    case LibraryTypeEnum.Businesses: {
      return <BusinessesCompareTable selectedSubjects={selectedSubjects as MergeBusinessSubject[]} {...sharedProps} />
    }
    case LibraryTypeEnum.FinancialInstitutions: {
      return (
        <FinancialInstitutionsCompareTable
          selectedSubjects={selectedSubjects as MergeFinancialInstitutionSubject[]}
          {...sharedProps}
        />
      )
    }
    default:
      return null
  }
}

export const useCompareStepStyles = makeStyles((theme: Theme) => ({
  content: {
    '&&': {
      padding: 0,
    },
  },
  header: {
    padding: theme.spacing(2),
  },
  subheading: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    background: theme.palette.background.light,
    paddingLeft: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
}))

interface CompareStepDialogProps extends SharedStepProps {
  libraryType: LibraryTypeEnum
  selectedSubjects: MergeSubject[]
  selectedPrimarySubjectToken: string | null
  setSelectedPrimarySubjectToken: (primary: string) => void
}

export const CompareStepDialog = ({
  libraryType,
  open,
  onClose,
  selectedSubjects,
  selectedPrimarySubjectToken,
  setSelectedPrimarySubjectToken,
  setMergeStep,
}: CompareStepDialogProps) => {
  const classes = useCompareStepStyles()
  return (
    <HbDialog
      id="subjectMergeCompareDialog"
      classes={{ content: classes.content, header: classes.header }}
      confirmText="Continue"
      content={
        <>
          <HbText className={classes.subheading} bold tag="h3" size="md">
            Choose a primary record:
          </HbText>
          <CompareTable
            libraryType={libraryType}
            selectedSubjects={selectedSubjects}
            selectedPrimarySubjectToken={selectedPrimarySubjectToken}
            setSelectedPrimarySubjectToken={setSelectedPrimarySubjectToken}
          />
          <Disclaimer />
        </>
      }
      open={open}
      onClose={onClose}
      title="Merge profiles"
      onConfirm={() => {
        setMergeStep('confirm')
      }}
      size="lg"
    />
  )
}

import { useCallback } from 'react'

import { fieldHasUpperBound } from 'dashboards/reviews/components/Header/filtering/FilterButton/helpers'
import { DashboardTypeEnum } from 'types/api'

import { useDashboardContext } from '../components/DashboardContextProvider'
import makeDashboardConfig from '../react/makeDashboardConfig'

export const searchableFiltersMap = makeDashboardConfig<Record<string, string>>(
  {},
  {
    [DashboardTypeEnum.Reviews]: {
      tags: 'tags',
      investigationTags: 'investigation_tags',
      triggers: 'alerts',
    },
  }
)

export const useSearchableFiltersMap = () => {
  const dashboardContextValue = useDashboardContext()
  return searchableFiltersMap[dashboardContextValue]
}

export const useSearchableField = (fieldApiName: string) => {
  const filtersMap = useSearchableFiltersMap()

  return filtersMap[fieldApiName]
}

export const useIsSearchableField = () => {
  const filtersMap = useSearchableFiltersMap()

  const getIsSearchable = useCallback(
    (fieldApiName: string) => !!filtersMap[fieldApiName] || fieldHasUpperBound(fieldApiName),
    [filtersMap]
  )

  return getIsSearchable
}

import { ComponentPropsWithoutRef } from 'react'

import { gql } from '@apollo/client'

import { Switch } from '@mui/material'

import { DisableAutomationSwitchAutomationRuleFragment as AutomationRule } from './__generated__/DisableAutomationSwitch.generated'
import { fragment as toggleFragment, useAutomationRuleToggle } from './hooks/useAutomationRuleToggle'

interface Props extends Omit<ComponentPropsWithoutRef<typeof Switch>, 'checked' | 'onChange'> {
  automationRule: AutomationRule
}

export default function DisableAutomationSwitch({ automationRule, ...restProps }: Props) {
  const { toggleRule } = useAutomationRuleToggle(automationRule)

  return <Switch {...restProps} checked={!automationRule.disabled} onChange={toggleRule} />
}

DisableAutomationSwitch.fragments = {
  automationRule: gql`
    fragment DisableAutomationSwitchAutomationRule on CanonicalAutomationRule {
      ...UseAutomationRuleToggleAutomationRule
    }
    ${toggleFragment}
  `,
}

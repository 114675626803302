import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import HbRHFTextArea from 'components/HbComponents/Form/HbInputs/HbTextArea/HbRHFTextArea'

import { useAutomationRuleTemplateCategories } from '../../ExploreAutomations/ExploreAutomations.hooks'

import { FormSchemaReturnTypeForTemplate } from '../../editor/formSchema'

import { Panel } from './Panel'

export const InfoPanel = ({ form }: { form: FormSchemaReturnTypeForTemplate }) => {
  const { data: categoryData } = useAutomationRuleTemplateCategories()

  const { control } = form

  const categoryOptions =
    categoryData?.automationRuleTemplateCategories.map((category) => ({
      value: category.token,
      display: category.name,
    })) || []

  return (
    <Panel>
      <HbRHFDropdown name="categoryToken" label="Category" control={control} options={categoryOptions} />
      <HbRHFTextArea name="description" label="Decription" control={control} minRows={2} maxRows={4} />
      <HbRHFTextArea name="setup" label="Setup info" control={control} minRows={2} maxRows={4} />
    </Panel>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const UnusualTransactionOn: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 28 28">
    <g id="unusual-trx-on" stroke="none" strokeWidth="1" fillRule="evenodd">
      <g id="Group-11">
        <path
          d="M14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C21.7319865,0 28,6.2680135 28,14 C28,21.7319865 21.7319865,28 14,28 Z M14,9 C10.6666667,9 7.82,11.0733333 6.66666667,14 C7.82,16.9266667 10.6666667,19 14,19 C17.3333333,19 20.18,16.9266667 21.3333333,14 C20.18,11.0733333 17.3333333,9 14,9 Z M14,17.3333333 C12.16,17.3333333 10.6666667,15.84 10.6666667,14 C10.6666667,12.16 12.16,10.6666667 14,10.6666667 C15.84,10.6666667 17.3333333,12.16 17.3333333,14 C17.3333333,15.84 15.84,17.3333333 14,17.3333333 Z M14,12 C12.8933333,12 12,12.8933333 12,14 C12,15.1066667 12.8933333,16 14,16 C15.1066667,16 16,15.1066667 16,14 C16,12.8933333 15.1066667,12 14,12 Z"
          id="Combined-Shape"
        />
      </g>
    </g>
  </SvgIcon>
)

export default UnusualTransactionOn

import { memo } from 'react'

export const HbAnimatedLogo = memo(function InnerHbAnimatedLogo() {
  return (
    <svg viewBox="0 0 1080 1080" width={300} height={300}>
      <defs>
        <linearGradient id="hblg0" x1={-422.3} x2={447.4} y1={-337.8} y2={341.1} gradientUnits="userSpaceOnUse">
          <stop offset={0.9} stopColor="#dce0e5" />
          <stop offset={0.9} stopColor="#d1e8f0" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={1} stopColor="#d2d4fc" />
          <stop offset={1} stopColor="#dfb9fd" />
          <stop offset={1} stopColor="#dfb9fd" />
          <stop offset={1} stopColor="#dfb9fd" />
        </linearGradient>
        <linearGradient id="hblg1" x1={-422.3} x2={447.4} y1={-337.8} y2={341.1} gradientUnits="userSpaceOnUse">
          <stop offset={0.1} stopColor="#e7e9ec" stopOpacity={0.19} />
          <stop offset={0.1} stopColor="#dfe6ef" stopOpacity={0.22} />
          <stop offset={0.1} stopColor="#d8e2f1" stopOpacity={0.25} />
          <stop offset={0.2} stopColor="#e1e1f2" stopOpacity={0.12} />
          <stop offset={0.2} stopColor="#ebe0f3" stopOpacity={0} />
          <stop offset={0.2} stopColor="#f2e9f9" />
          <stop offset={0.3} stopColor="#f9f2ff" />
          <stop offset={0.8} stopColor="#f0edf5" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
        <linearGradient id="hblg2" x1={-335.5} x2={203.9} y1={-217.6} y2={259.5} gradientUnits="userSpaceOnUse">
          <stop offset={0.7} stopColor="#e7e9ec" />
          <stop offset={0.7} stopColor="#ebeff4" />
          <stop offset={0.7} stopColor="#eff4fc" />
          <stop offset={0.8} stopColor="#ebeff4" />
          <stop offset={0.8} stopColor="#e7e9ec" />
          <stop offset={0.8} stopColor="#f3f4f6" />
          <stop offset={0.8} stopColor="#fff" />
          <stop offset={0.9} stopColor="#e2f7fd" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={0.9} stopColor="#d2d4fc" />
          <stop offset={0.9} stopColor="#dfb9fd" />
          <stop offset={0.9} stopColor="#efdcfe" />
          <stop offset={1} stopColor="#fff" />
          <stop offset={1} stopColor="#f3f4f6" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
        <linearGradient id="hblg3" x1={-338.8} x2={155.8} y1={-173.2} y2={208.8} gradientUnits="userSpaceOnUse">
          <stop offset={0.7} stopColor="#e7e9ec" />
          <stop offset={0.7} stopColor="#ebeff4" />
          <stop offset={0.7} stopColor="#eff4fc" />
          <stop offset={0.8} stopColor="#ebeff4" />
          <stop offset={0.8} stopColor="#e7e9ec" />
          <stop offset={0.8} stopColor="#f3f4f6" />
          <stop offset={0.8} stopColor="#fff" />
          <stop offset={0.9} stopColor="#e2f7fd" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={0.9} stopColor="#d2d4fc" />
          <stop offset={0.9} stopColor="#dfb9fd" />
          <stop offset={0.9} stopColor="#efdcfe" />
          <stop offset={1} stopColor="#fff" />
          <stop offset={1} stopColor="#f3f4f6" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
        <linearGradient id="hblg4" x1={-378.9} x2={194.9} y1={-184.9} y2={263.3} gradientUnits="userSpaceOnUse">
          <stop offset={0.7} stopColor="#e7e9ec" />
          <stop offset={0.7} stopColor="#ebeff4" />
          <stop offset={0.7} stopColor="#eff4fc" />
          <stop offset={0.8} stopColor="#ebeff4" />
          <stop offset={0.8} stopColor="#e7e9ec" />
          <stop offset={0.8} stopColor="#f3f4f6" />
          <stop offset={0.8} stopColor="#fff" />
          <stop offset={0.9} stopColor="#e2f7fd" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={0.9} stopColor="#d2d4fc" />
          <stop offset={0.9} stopColor="#dfb9fd" />
          <stop offset={0.9} stopColor="#efdcfe" />
          <stop offset={1} stopColor="#fff" />
          <stop offset={1} stopColor="#f3f4f6" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
        <linearGradient id="hblg5" x1={-346.5} x2={186.5} y1={-201} y2={282.6} gradientUnits="userSpaceOnUse">
          <stop offset={0.7} stopColor="#e7e9ec" />
          <stop offset={0.7} stopColor="#ebeff4" />
          <stop offset={0.7} stopColor="#eff4fc" />
          <stop offset={0.8} stopColor="#ebeff4" />
          <stop offset={0.8} stopColor="#e7e9ec" />
          <stop offset={0.8} stopColor="#f3f4f6" />
          <stop offset={0.8} stopColor="#fff" />
          <stop offset={0.9} stopColor="#e2f7fd" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={0.9} stopColor="#d2d4fc" />
          <stop offset={0.9} stopColor="#dfb9fd" />
          <stop offset={0.9} stopColor="#efdcfe" />
          <stop offset={1} stopColor="#fff" />
          <stop offset={1} stopColor="#f3f4f6" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
        <linearGradient id="hblg6" x1={-318.2} x2={205.4} y1={-158.2} y2={227.1} gradientUnits="userSpaceOnUse">
          <stop offset={0.7} stopColor="#e7e9ec" />
          <stop offset={0.7} stopColor="#ebeff4" />
          <stop offset={0.7} stopColor="#eff4fc" />
          <stop offset={0.8} stopColor="#ebeff4" />
          <stop offset={0.8} stopColor="#e7e9ec" />
          <stop offset={0.8} stopColor="#f3f4f6" />
          <stop offset={0.8} stopColor="#fff" />
          <stop offset={0.9} stopColor="#e2f7fd" />
          <stop offset={0.9} stopColor="#c5effc" />
          <stop offset={0.9} stopColor="#d2d4fc" />
          <stop offset={0.9} stopColor="#dfb9fd" />
          <stop offset={0.9} stopColor="#efdcfe" />
          <stop offset={1} stopColor="#fff" />
          <stop offset={1} stopColor="#f3f4f6" />
          <stop offset={1} stopColor="#e7e9ec" />
        </linearGradient>
      </defs>
      <style>
        {
          '@keyframes hblka{0%,96.754%,to{visibility:hidden;animation-timing-function:steps(1)}1.623%{visibility:visible;animation-timing-function:steps(1)}}@keyframes hblkb{0%,55.73%{opacity:1}67.241%,to{opacity:0}}@keyframes hblkc{0%,95.081%,to{opacity:0}11.461%,26.217%,52.484%{opacity:.1}34.432%{opacity:.3}}'
        }
      </style>
      <g transform="translate(40,40)">
        <g transform="translate(540,503.8)">
          <path
            fill="url(#hblg0)"
            d="M84.3,167C46.3,204.7,-7.7,225.5,-67.9,225.5C-107.1,225.5,-144,215.7,-176.4,198.4C-171.8,198.8,-167.1,199.1,-162.2,199.1C-148.8,199.1,-134.9,197.6,-120.6,194.7C-47.1,179.9,6.3,118.1,47.6,0.4C50.9,-9,53.7,-17.9,56.4,-26.4C61.8,-43.5,66.5,-58.3,72.8,-70.2C83,-89.4,96.5,-100.3,115.5,-104.4C137.6,-109.2,160.5,-104.4,173.1,-100.7C172.4,-46.3,165.9,2.3,153.9,43.7C139.2,94.2,115.9,135.7,84.3,167ZM-279.6,-100.5C-263.4,-50.9,-229,-8.3,-180.2,21.9C-137.5,48.2,-87.4,62.2,-40.7,62.2C-29.1,62.2,-17.8,61.3,-6.9,59.6C-25.4,77.6,-69.1,90.7,-117.6,92C-193.1,94.3,-260.2,68.8,-297.6,24.1C-298.9,14.1,-299.7,4,-299.7,-6.4C-299.7,-39.3,-292.7,-71.2,-279.6,-100.5ZM-208.1,-190.9C-192.2,-203.2,-177,-211,-166,-215.8C-168.8,-148.3,-153.3,-90,-120.7,-46.4C-91.4,-7.1,-49.2,19.7,2,32C-49.7,44.6,-114.3,32.8,-166.8,0.4C-218.5,-31.6,-251.9,-78.7,-261.7,-133.2C-247.5,-154.9,-229.5,-174.4,-208.1,-190.9ZM-245.1,142.8C-263.4,121.2,-277.8,96.1,-287.2,68.7C-245.9,99.9,-188.9,117.5,-126.4,117.5C-123.2,117.5,-120.1,117.5,-116.9,117.4C-84,116.4,-53.7,110.7,-29.5,101.5C-56.9,140.1,-88.5,162.4,-125.5,169.8C-193.7,183.6,-230.2,157.9,-245.1,142.8ZM325,-198.5C325,-198.5,317.8,-208.9,317.8,-208.9C317.8,-208.9,183.5,-124.1,183.5,-124.1C169,-128.7,139.8,-135.6,110.2,-129.2C83.7,-123.5,64.2,-108.1,50.4,-82.1C43.1,-68.2,38.1,-52.4,32.3,-34.2C29.6,-25.8,26.9,-17.1,23.7,-8C21.6,-2.1,19.5,3.7,17.3,9.4C-93,-12.2,-151.4,-102.3,-139.4,-233.1C-139.4,-233.1,-137.7,-250.8,-137.7,-250.8C-137.7,-250.8,-155,-246.6,-155,-246.6C-156.4,-246.2,-188.5,-238.1,-223.6,-211C-288.1,-161.3,-325,-86.6,-325,-6.3C-325,53.4,-304.5,108.3,-270.3,152C-270.3,152,-270.6,152.2,-270.6,152.2C-268.3,155.2,-265.8,158,-263.2,160.7C-216,215.8,-146,250.8,-67.9,250.8C-1,250.8,59.3,227.5,102.1,184.9C136.7,150.6,162.3,105.4,178.2,50.7C190.9,7.1,197.7,-43.8,198.5,-100.5C198.5,-100.5,325,-198.5,325,-198.5Z"
          />
        </g>
      </g>
      <g visibility="hidden" transform="translate(40,40)" style={{ animation: '2.03s linear infinite forwards hblka' }}>
        <g transform="translate(540,503.8)" style={{ animation: '2.03s linear infinite both hblkb' }}>
          <path
            fill="url(#hblg1)"
            d="M84.3,167C46.3,204.7,-7.7,225.5,-67.9,225.5C-107.1,225.5,-144,215.7,-176.4,198.4C-171.8,198.8,-167.1,199.1,-162.2,199.1C-148.8,199.1,-134.9,197.6,-120.6,194.7C-47.1,179.9,6.3,118.1,47.6,0.4C50.9,-9,53.7,-17.9,56.4,-26.4C61.8,-43.5,66.5,-58.3,72.8,-70.2C83,-89.4,96.5,-100.3,115.5,-104.4C137.6,-109.2,160.5,-104.4,173.1,-100.7C172.4,-46.3,165.9,2.3,153.9,43.7C139.2,94.2,115.9,135.7,84.3,167ZM-279.6,-100.5C-263.4,-50.9,-229,-8.3,-180.2,21.9C-137.5,48.2,-87.4,62.2,-40.7,62.2C-29.1,62.2,-17.8,61.3,-6.9,59.6C-25.4,77.6,-69.1,90.7,-117.6,92C-193.1,94.3,-260.2,68.8,-297.6,24.1C-298.9,14.1,-299.7,4,-299.7,-6.4C-299.7,-39.3,-292.7,-71.2,-279.6,-100.5ZM-208.1,-190.9C-192.2,-203.2,-177,-211,-166,-215.8C-168.8,-148.3,-153.3,-90,-120.7,-46.4C-91.4,-7.1,-49.2,19.7,2,32C-49.7,44.6,-114.3,32.8,-166.8,0.4C-218.5,-31.6,-251.9,-78.7,-261.7,-133.2C-247.5,-154.9,-229.5,-174.4,-208.1,-190.9ZM-245.1,142.8C-263.4,121.2,-277.8,96.1,-287.2,68.7C-245.9,99.9,-188.9,117.5,-126.4,117.5C-123.2,117.5,-120.1,117.5,-116.9,117.4C-84,116.4,-53.7,110.7,-29.5,101.5C-56.9,140.1,-88.5,162.4,-125.5,169.8C-193.7,183.6,-230.2,157.9,-245.1,142.8ZM325,-198.5C325,-198.5,317.8,-208.9,317.8,-208.9C317.8,-208.9,183.5,-124.1,183.5,-124.1C169,-128.7,139.8,-135.6,110.2,-129.2C83.7,-123.5,64.2,-108.1,50.4,-82.1C43.1,-68.2,38.1,-52.4,32.3,-34.2C29.6,-25.8,26.9,-17.1,23.7,-8C21.6,-2.1,19.5,3.7,17.3,9.4C-93,-12.2,-151.4,-102.3,-139.4,-233.1C-139.4,-233.1,-137.7,-250.8,-137.7,-250.8C-137.7,-250.8,-155,-246.6,-155,-246.6C-156.4,-246.2,-188.5,-238.1,-223.6,-211C-288.1,-161.3,-325,-86.6,-325,-6.3C-325,53.4,-304.5,108.3,-270.3,152C-270.3,152,-270.6,152.2,-270.6,152.2C-268.3,155.2,-265.8,158,-263.2,160.7C-216,215.8,-146,250.8,-67.9,250.8C-1,250.8,59.3,227.5,102.1,184.9C136.7,150.6,162.3,105.4,178.2,50.7C190.9,7.1,197.7,-43.8,198.5,-100.5C198.5,-100.5,325,-198.5,325,-198.5Z"
          />
        </g>
      </g>
      <g transform="translate(-2,-0.5)">
        <g opacity="0" transform="translate(327,406.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-0.5,6.7C-0.5,1.9,-0.1,-2.5,0.5,-6.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(327,406.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M0.5,-6.7C-0.1,-2.5,-0.5,1.9,-0.5,6.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(552.5,645.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M0.2,-0.3C0.1,-0.2,0,-0.2,0,-0.2C-0.1,0,-0.1,0.1,-0.2,0.3C-0.1,0.1,0.1,-0.1,0.2,-0.3Z"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(512.3,591)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.9,11.6C-2.3,4.6,4.1,-2.7,8.9,-11.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(503.2,589.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.5,-11.4C3.9,-2.7,-2.1,4.6,-8.5,11.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(494.3,588.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8,11.3C-1.9,4.6,3.8,-2.6,8,-11.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(485.6,586.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.6,-11.2C3.7,-2.6,-1.7,4.6,-7.6,11.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(531.2,592.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-9.6,12.2C-2.5,4.8,4.4,-2.8,9.6,-12.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(521.6,592)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.3,-11.9C4.2,-2.8,-2.4,4.7,-9.3,11.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(468.7,581.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.6,11.2C-1.3,4.7,3.4,-2.5,6.6,-11.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(460.2,578.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M6,-11.3C3.2,-2.5,-1.1,4.8,-6,11.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(476.9,584.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.1,-11.2C3.5,-2.5,-1.5,4.6,-7.1,11.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(451.7,575.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.4,-11.5C3,-2.5,-0.8,4.9,-5.4,11.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(609.4,763.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.5,-11C-1.3,-4.6,3.3,2.5,6.5,11" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(617.3,759.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7,10.7C3.4,2.5,-1.6,-4.4,-7,-10.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(541.1,592.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10,12.6C-2.6,5,4.6,-2.9,10,-12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(545.9,779.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.6,-12.3C0,-5.3,2.5,2.7,3.6,12.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(555.6,778.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.8,12.2C2.6,2.7,-0.1,-5.2,-3.8,-12.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(574.5,774.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.5,-12C-0.4,-5.1,2.8,2.6,4.5,12" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(583.6,772.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.9,11.8C2.9,2.6,-0.6,-5,-4.9,-11.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(565.1,776.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.1,-12.1C-0.3,-5.2,2.7,2.7,4.1,12.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(592.5,769.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.4,11.6C3.1,2.6,-0.8,-4.9,-5.4,-11.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(601.1,766.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-5.9,-11.3C-1,-4.8,3.2,2.5,5.9,11.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(526.6,639.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.1,-14.9C0.8,-6.6,-4.5,2.8,-7.1,14.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(539,635.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.9,16.2C-5.5,2.8,1.2,-7.2,8.9,-16.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(561.8,591.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.6,13.8C-2.7,5.5,5.1,-3,10.6,-13.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(551.3,592.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M10.3,-13.2C4.8,-2.9,-2.7,5.2,-10.3,13.2C-10.1,13.2,-9.9,13.2,-9.7,13.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(443.1,572.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.8,-11.7C2.9,-2.6,-0.5,5,-4.8,11.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(515,642.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M6.1,-14.2C0.5,-6.3,-3.9,2.8,-6.1,14.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(398.2,547.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M0.5,-14.9C0.8,-11.6,1.1,-8.1,1.1,-4.3C1.1,2.8,0.3,9.2,-1.1,14.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(407.5,553.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.8,13.8C0.5,6.8,1.8,-1.3,1.8,-10.7C1.8,-11.8,1.8,-12.8,1.8,-13.8"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(431.7,729.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M10.7,12C3.3,4.3,-4.4,-3.2,-10.7,-12" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(421.9,729.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-11.1,-12.5C-4.6,-3.3,3.5,4.4,11.1,12.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(441.1,728.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M10.2,11.6C3.1,4.2,-4.2,-3,-10.2,-11.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(394.1,642.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-5.1,11.9C-3,2.6,0.6,-5.1,5.1,-11.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(384.9,640)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.5,-11.7C0.8,-5,-3.1,2.6,-5.5,11.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(526.2,781.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.3,-12.5C0.1,-5.4,2.5,2.8,3.3,12.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(536.1,780.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.4,12.4C2.5,2.8,0,-5.3,-3.4,-12.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(449.7,727.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.8,11.3C2.9,4.1,-4.1,-2.9,-9.8,-11.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(403.4,643.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.8,12C-2.9,2.6,0.5,-5.2,4.8,-12" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(411.7,729.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-11.7,-13.3C-4.9,-3.4,3.6,4.7,11.7,13.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(434.5,568.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.1,-12.1C2.7,-2.7,-0.3,5.2,-4.1,12.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(483.2,718.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.8,10.6C1.9,4.2,-3.6,-2.5,-7.8,-10.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(475.2,721.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.4,-10.7C-3.7,-2.6,2.2,4.2,8.4,10.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(467,723.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.9,-10.8C-3.8,-2.7,2.5,4.1,8.9,10.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(458.5,725.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-9.4,-11.1C-4,-2.8,2.7,4.1,9.4,11.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(491.1,715.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.2,10.7C1.6,4.4,-3.4,-2.5,-7.2,-10.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(388,539.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.1,-16.5C0.3,-10.6,1.1,-4.1,1.1,3.3C1.1,8.1,0.8,12.5,0.2,16.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(416.7,559.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-2.6,13.1C0.4,5.6,2.3,-2.9,2.6,-13.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(624.9,756)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.6,10.4C3.4,2.5,-1.9,-4.1,-7.6,-10.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(425.7,564.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.4,-12.5C2.5,-2.8,0,5.4,-3.4,12.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(498.8,711.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M6.5,11C1.3,4.6,-3.3,-2.5,-6.5,-11" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(367.3,635)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.4,11.2C-3.3,2.5,1.2,-4.7,6.4,-11.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(358.9,632.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M6.8,-11C1.4,-4.5,-3.4,2.5,-6.8,11" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(350.8,629)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-7.3,10.7C-3.5,2.5,1.7,-4.3,7.3,-10.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(343,625.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.8,-10.4C2,-4.1,-3.5,2.5,-7.8,10.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(376,637.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.9,-11.5C1,-4.9,-3.2,2.5,-5.9,11.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(335.5,622.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.2,-10.1C2.2,-3.9,-3.5,2.6,-8.2,10.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(781.5,425.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M2.2,14.9C-0.6,7.2,-2.2,-1.6,-2.2,-12.2C-2.2,-13.1,-2.2,-14,-2.2,-14.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(774.9,435.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-5.6,-18.6C-5,-3,-0.6,8.8,5.6,18.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(328.4,618.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.5,-9.8C2.5,-3.6,-3.5,2.6,-8.5,9.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(331.5,498)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M13.3,19.1C7.8,2.9,-2.7,-8.1,-13.3,-19.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(328.4,618.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.5,9.8C-3.5,2.6,2.5,-3.6,8.5,-9.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(321.5,614.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.7,-9.6C2.7,-3.4,-3.4,2.7,-8.7,9.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(315,610.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.8,-9.4C3,-3.1,-3.2,2.9,-8.8,9.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(461.8,495.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M12.2,16.4C6.4,3,-2.8,-6.8,-12.2,-16.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(766.1,485.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M11.7,13.2C4.8,3.4,-3.7,-4.6,-11.7,-13.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(765,497.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-11.2,-15.9C-2.4,-6.7,6,2.9,11.2,15.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(767.3,461.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M12.1,16.1C2.8,6.6,-6.3,-3.1,-12.1,-16.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(766.8,474.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-11.9,-13.1C-4.7,-3.6,4,4.3,11.9,13.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(769.6,442.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.4,-24.8C-9.4,-2.1,-0.5,12.3,10.4,24.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(901.6,348.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.5,4.7C-1.5,1.6,1.5,-1.6,4.5,-4.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(885.9,355.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M15.8,-18.3C5.2,-6.1,-7.2,4.1,-15.8,18.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(869.9,364.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M15.9,-21.3C3.8,-8.6,-9.2,3.1,-15.9,21.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(790.6,419.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M1.3,15.1C-0.3,9,-1.3,2.1,-1.3,-5.7C-1.3,-9,-1.1,-12.1,-0.8,-15.1"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(741.5,642)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M12.5,-27.7C-0.4,-13.9,-11.8,1.4,-12.5,27.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(850.9,377.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.8,-18.4C1,-8.4,-6.6,2.7,-9.8,18.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(422.6,646.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.3,12.3C-2.8,2.7,0.3,-5.3,4.3,-12.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(413,645.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.5,-12.2C0.4,-5.2,-2.9,2.7,-4.5,12.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(811.3,404.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.5,15.4C-1.6,13.2,-1.7,10.9,-1.7,8.6C-1.7,-0.6,-0.4,-8.5,1.7,-15.4"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(800.2,412.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M0.8,-15.2C-0.2,-10.3,-0.8,-4.8,-0.8,1.2C-0.8,6.2,-0.4,10.9,0.3,15.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(835.4,388.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.7,-16.6C-0.1,-7.6,-4.5,2.9,-5.7,16.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(823,396.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.3,-15.8C-0.7,-7.1,-3.2,3.1,-3.3,15.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(441.9,648)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.1,12.5C-2.8,2.7,0.2,-5.4,4.1,-12.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(432,647.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.2,-12.4C0.2,-5.3,-2.8,2.7,-4.2,12.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(702,687.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M3.3,17.1C-0.8,8.2,-3.3,-2.2,-3.3,-15.3C-3.3,-15.9,-3.3,-16.5,-3.3,-17.1"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(694.2,698.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.9,-14.1C-3.5,-2.8,0.2,6.2,4.9,14.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(681,713.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7,11C1.5,4.5,-3.4,-2.5,-7,-11" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(675,719.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-7.7,-10.2C-3.4,-2.5,2,4,7.7,10.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(669.2,725.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.2,9.7C2.4,3.6,-3.4,-2.6,-8.2,-9.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(663.4,730.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.5,-9.4C-3.3,-2.7,2.7,3.3,8.5,9.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(687.4,706.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M6.1,12.2C0.9,5.2,-3.4,-2.6,-6.1,-12.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(657.5,735.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.7,-9.3C-3.1,-2.8,2.9,3,8.7,9.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(632.1,752)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8,-10C-2.2,-3.9,3.5,2.5,8,10" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(639,748)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.4,9.7C3.4,2.6,-2.5,-3.6,-8.4,-9.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(645.5,743.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.6,9.5C3.3,2.7,-2.7,-3.3,-8.6,-9.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(651.8,739.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.7,-9.3C-3,-3.1,3.2,2.9,8.7,9.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(482.6,647.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.5,13.1C-3.1,2.8,0.2,-5.7,4.5,-13.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(472.3,647.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.3,-12.9C0.2,-5.6,-2.9,2.8,-4.3,12.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(710.9,671)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M1.2,25C-0.9,18.1,-2.1,10.4,-2.1,1.5C-2.1,-8.8,-0.5,-17.4,2.1,-25"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(462,648.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.1,12.8C-2.8,2.7,0.1,-5.5,4.1,-12.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(451.9,648.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.1,-12.6C0.2,-5.5,-2.8,2.7,-4.1,12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(739,641.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M20.2,-43.4C2.2,-23.3,-20.2,-7.7,-20.2,31C-20.2,35.4,-19.9,39.5,-19.4,43.4"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(503.9,644.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.3,-13.7C0.4,-6,-3.5,2.8,-5.3,13.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(493.2,646)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.8,13.4C-3.2,2.8,0.3,-5.9,4.8,-13.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(460.7,485.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M27.3,40.3C19.6,2.5,-13.2,-9.2,-27.3,-40.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(539.2,545.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-0.8,13.9C0.2,8.9,0.8,3.5,0.8,-2.6C0.8,-6.6,0.5,-10.4,0.1,-13.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(528.9,539.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-0.9,-14.9C0.3,-9.5,0.9,-3.5,0.9,3.1C0.9,7.3,0.7,11.2,0.2,14.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(548.8,550.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.2,13.3C0.3,7.2,1.2,0.5,1.2,-7.3C1.2,-9.3,1.2,-11.4,1,-13.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(558.4,554.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.7,12.9C0.5,5.9,1.7,-2,1.7,-11.2C1.7,-11.8,1.7,-12.3,1.7,-12.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(588,632.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-19.8,33.9C-17.7,-0.2,2.8,-15.2,19.8,-33.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(589.3,595)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M24.7,-84.2C28.8,-75.4,31.3,-65,31.3,-52C31.3,12.8,-31.3,12.8,-31.3,77.5C-31.3,79.8,-31.2,82.1,-31.1,84.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(517.6,532.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-2.2,-16.6C0.6,-9,2.2,-0.3,2.2,10.2C2.2,12.4,2.1,14.6,2,16.6"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(503.2,521.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-6.5,-21.8C1.1,-10.7,6.5,2.7,6.5,21.7C6.5,21.8,6.5,21.8,6.5,21.8"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(577.4,560.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-2.7,12.4C0.2,5.3,2.2,-2.8,2.7,-12.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(567.9,557.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M2.3,-12.6C2,-2.9,0.4,5.4,-2.3,12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(506.5,707.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.8,11.4C1,4.8,-3.2,-2.5,-5.8,-11.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(579.2,607.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M29.8,-81.2C30.8,-76.2,31.3,-70.7,31.3,-64.6C31.3,-10.6,-12.3,-1.6,-26.7,37.4C-26.7,37.4,-26.6,37.4,-26.5,37.4C-26.6,37.5,-26.8,37.7,-26.9,37.9C-29.7,45.6,-31.3,54.4,-31.3,64.9C-31.3,70.9,-30.8,76.3,-29.8,81.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(464.2,442.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-16.7,-38.5C-17,-35.6,-17.2,-32.5,-17.2,-29.3C-17.2,5.1,0.6,21.2,17.2,38.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(586.9,563.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.2,12.3C0.1,5.3,2.3,-2.8,3.2,-12.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(429.1,334.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-13.1,16.5C-3.4,6.5,6.5,-3.3,13.1,-16.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(427.5,317.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M11.6,-23.4C9.2,-2.2,-0.5,11.3,-11.6,23.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(468.2,458.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-24.6,-77.2C-28.6,-68.3,-31.2,-58,-31.2,-44.9C-31.2,17.3,26.6,19.7,31.2,77.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(391.1,327.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M15.9,-23.9C10.6,-2.5,-3,10.4,-15.9,23.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(409.2,317.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-8.8,17.8C-0.7,8.2,6,-2.8,8.8,-17.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(422.4,312.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-6.2,15.5C-6.2,15.6,-6.2,15.6,-6.2,15.7C-0.3,7.1,4.4,-2.9,6.2,-15.7"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(434.5,390.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.6,-23.3C-1.2,-11.6,-7.6,2.5,-7.6,23.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(400.9,728.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-12.3,-14.2C-5.4,-3.4,3.7,5.1,12.3,14.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(430.5,374.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M11,-18.7C1.6,-8.5,-7,2.9,-11,18.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(429.3,360.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-12,14.3C-5.4,3.3,3.4,-5.4,12,-14.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(428.9,348.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M12.5,-13.6C4.6,-4,-4.5,4.1,-12.5,13.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(632.8,494.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-9.3,-12C-2.4,-4.7,4.3,2.8,9.3,12" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(635.9,485.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.5,10.6C3.8,2.9,-3,-3.7,-9.5,-10.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(657.4,448)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.1,-12.1C-3.4,-2.6,1,5.2,6.1,12.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(664.4,441.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.8,12.5C0.4,5.4,-3,-2.7,-4.8,-12.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(710.4,424.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.6,-12.6C-1.6,-12.1,-1.7,-11.6,-1.7,-11C-1.7,-2,-0.4,5.7,1.7,12.6"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(720.5,424.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M1.7,12.7C-0.4,5.9,-1.7,-1.9,-1.7,-10.9C-1.7,-11.5,-1.6,-12.1,-1.6,-12.7"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(690.8,427.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M2.2,12.6C-0.4,5.4,-2,-2.9,-2.2,-12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(681.4,430.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-2.7,-12.6C-2.3,-2.8,-0.3,5.4,2.7,12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(672.5,435.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.6,-12.7C-2.6,-2.8,0,5.5,3.6,12.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(700.5,425.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.8,-12.5C-1.8,-12.4,-1.8,-12.3,-1.8,-12.1C-1.8,-2.6,-0.5,5.4,1.8,12.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(629.1,505.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.5,14.8C4.9,2.8,-1.3,-6.5,-8.5,-14.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(624.1,520.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.5,-20.9C0.9,-10.1,6.2,2.8,6.5,20.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(762.1,519.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-9.2,-27.5C1.1,-14.9,9.2,0,9.2,23.2C9.2,24.7,9.2,26.1,9.1,27.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(730.7,425)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.8,-12.9C-1.8,-12.5,-1.8,-12,-1.8,-11.5C-1.8,-2.2,-0.5,5.9,1.8,12.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(741.1,426.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M2.1,13.2C-0.5,5.8,-2.1,-2.8,-2.1,-13C-2.1,-13.1,-2.1,-13.2,-2.1,-13.2"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(751.6,428.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M2.6,13.7C-0.5,6,-2.4,-3,-2.6,-13.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(742.9,602.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-23.3,30.1C-9.7,8.1,13.1,-4.5,23.3,-30.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(745.2,556.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M6.5,-51.9C12.3,-41.8,16.1,-29.8,16.1,-13.8C16.1,19.2,-0.1,35.4,-16.1,51.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(639.1,477.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-9.6,-10C-3.4,-3.1,3.3,3.2,9.6,10" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(642.6,470)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.1,10.2C2.8,3.6,-3.6,-2.8,-9.1,-10.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(743.1,557.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-8.2,33.3C1.2,21.3,8.2,6.9,8.2,-14.8C8.2,-21.6,7.4,-27.7,6.2,-33.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(369.4,345)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M26,-35.7C15.6,-4.7,-12.8,7.6,-26,35.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(589.2,634.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7,-12.2C1.3,-5.1,-3.7,2.6,-7,12.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(651.4,455.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-7.4,-11.4C-3.7,-2.6,1.6,4.7,7.4,11.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(646.5,462.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-8.5,-10.7C-3.8,-2.6,2.2,4.2,8.4,10.7C8.4,10.6,8.4,10.5,8.5,10.4"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(586.7,577.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M13.3,-25.9C11.1,-1.8,-0.6,12.6,-13.3,25.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(554.4,629.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M13.3,-19.9C2.5,-8.7,-8.1,2.8,-13.3,19.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(441,770)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.2,15.5C4.7,2.8,-0.7,-7,-7.2,-15.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(427.9,765.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-9.4,-17.1C-1.2,-7.7,6,2.8,9.4,17.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(401.3,744.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-24.9,-34C-10.6,-9,15.8,3.7,24.9,34" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(355.1,358.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M27.5,-41.9C12.9,-10.1,-21.3,1.4,-27.5,41.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(300.6,654.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M4.3,-18.5C-0.9,-8.8,-4.3,2.7,-4.3,17.8C-4.3,18,-4.3,18.2,-4.3,18.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(308.3,668.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-0.1,21.9C-1.3,16.4,-2,10.4,-2,3.7C-2,-6.2,-0.5,-14.5,2,-21.9"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(452.9,773.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-5.8,-14.6C-0.4,-6.5,3.9,2.8,5.8,14.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(464.2,776.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M4.9,14C3.4,2.8,-0.2,-6.2,-4.9,-14" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(376.2,732.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-25.8,-35.4C-15.7,-4.5,12.4,7.9,25.8,35.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(389.1,739.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M25.5,34.4C14.5,5.3,-12.8,-7,-25.5,-34.4" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(322.3,686.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M5.8,27.3C-1.1,16.6,-5.8,3.7,-5.8,-14.1C-5.8,-18.8,-5.5,-23.2,-4.8,-27.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(347.3,710.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M20.8,37.7C2.6,17,-20.7,1.6,-20.8,-37.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(475.1,778.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.3,-13.5C0,-5.9,3,2.8,4.3,13.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(485.3,779.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.8,13.2C2.8,2.8,0,-5.8,-3.8,-13.2" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(362.7,723.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-24.9,-36.8C-18.9,-1.3,8.8,11.7,24.9,36.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(522.3,696)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-4.1,-13.1C-2.9,-2.8,0.1,5.7,4.1,13.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(530.7,688.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3,14.6C-0.5,6.4,-2.8,-3,-3,-14.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(539.6,684.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M1.8,12.1C-0.5,5.1,-1.8,-2.8,-1.8,-12.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(514.3,702)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-5,-12.1C-3,-2.6,0.5,5.2,5,12.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(572.8,590.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.8,14.6C-2.6,5.9,5.4,-3,10.8,-14.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(495.7,780.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.6,-13C0.1,-5.6,2.7,2.8,3.6,13" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(506,781.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M3.4,12.8C2.5,2.8,0.1,-5.5,-3.4,-12.8" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(516.1,781.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-3.3,-12.7C0.1,-5.5,2.5,2.8,3.3,12.7" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(279.6,469.3)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M10,11.3C3,4.1,-4.1,-3,-10,-11.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(277.2,478.2)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.3,-11.1C-3.7,-3.4,3.7,3.4,10.3,11.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(282.6,459.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.2,12.6C2.2,5.1,-4.4,-2.8,-9.2,-12.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(286.5,449.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M7.9,14.9C1.1,6.6,-4.7,-2.8,-7.9,-14.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(331.6,490.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M26.6,40.6C21.3,0.7,-10.8,-11.5,-26.6,-40.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(292,436.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M5.7,18.3C-0.5,8.6,-4.9,-3,-5.7,-18.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(275.1,487.7)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.5,-12C-3.4,-3.5,3.7,5.1,10.5,12" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(341.7,401.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M25.2,-75.3C7,-47.7,-25.2,-34.5,-25.2,11.9C-25.2,43.3,-10.4,59.5,4.8,75.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(308.7,606.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.8,-9.3C3.1,-2.9,-3,3,-8.8,9.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(273.1,499.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-10.5,-15.5C-2.1,-6.6,5.7,2.9,10.5,15.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(337.7,454.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-16.9,-86.6C-25.3,-75.1,-31.3,-61.2,-31.3,-41.1C-31.3,23,30.1,23.7,31.3,86.6"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(375.3,529)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M3.6,20.3C3.7,18.3,3.8,16.2,3.8,14C3.8,0,0.8,-11,-3.8,-20.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(299.2,421.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-1.8,-22.5C-2.5,-18,-2.9,-13.3,-2.9,-8.1C-2.9,4.1,-0.7,14,2.9,22.5"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(289.1,631.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M8.2,-13.1C1.6,-5.5,-4.3,2.7,-8.2,13.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(294,642.4)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-6.6,15.5C-4.5,2.8,0.5,-7,6.6,-15.5" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(284,598.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M14.4,-16.9C6.6,-3.7,-4.5,6,-14.4,16.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(288.6,605.9)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-16.2,18.3C-6.1,5.4,6.7,-4.8,16.2,-18.3" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(279.4,591.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M12.3,-16.1C6.3,-3.1,-3,6.5,-12.3,16.1" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(293.6,613.1)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M-17.6,20.6C-8.1,4.5,6.2,-6.5,17.6,-20.6" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(263.9,541.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-4.7,-36.7C1,-26.6,4.7,-14.7,4.7,1.2C4.7,15.8,1.5,27.1,-3.4,36.7"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(269.9,541.8)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-7.1,48.7C2.1,36.8,8.9,22.5,8.9,1.2C8.9,-21.6,1.1,-36.3,-8.9,-48.7"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
        <g opacity="0" transform="translate(274.8,583.6)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path fill="none" d="M9.9,-15.9C5.7,-2.8,-1.7,6.9,-9.9,15.9" stroke="#000000" strokeMiterlimit="10" />
        </g>
        <g opacity="0" transform="translate(258,541.5)" style={{ animation: '2.03s linear infinite both hblkc' }}>
          <path
            fill="none"
            d="M-0.6,-13.3C0.2,-8.8,0.6,-3.9,0.6,1.5C0.6,5.7,0.3,9.6,-0.2,13.3"
            stroke="#000000"
            strokeMiterlimit="10"
          />
        </g>
      </g>
      <g transform="translate(581.5,543.5)">
        <g>
          <path
            fill="none"
            d="M317.8,-208.9C317.8,-208.9,183.5,-124.1,183.5,-124.1C169,-128.7,139.8,-135.6,110.2,-129.3C83.7,-123.5,64.2,-108.1,50.4,-82.1C43.1,-68.2,38.1,-52.4,32.3,-34.2C29.6,-25.8,26.9,-17.1,23.7,-8.1C21.6,-2.1,19.5,3.7,17.3,9.4C-93,-12.3,-151.4,-102.3,-139.4,-233.2C-139.4,-233.2,-137.7,-250.9,-137.7,-250.9C-137.7,-250.9,-155,-246.6,-155,-246.6C-156.4,-246.3,-188.5,-238.1,-223.6,-211.1C-239.7,-198.6,-254.1,-184.6,-266.6,-169.3C-266.6,-169.3,-266.6,-169.4,-266.6,-169.4C-267.4,-168.5,-268.1,-167.6,-268.8,-166.7C-269.2,-166.1,-269.6,-165.6,-270.1,-165.1C-271.3,-163.6,-272.4,-162,-273.6,-160.5C-274,-159.9,-274.5,-159.3,-274.9,-158.7C-275.7,-157.7,-276.4,-156.7,-277.2,-155.6C-277.7,-154.9,-278.1,-154.3,-278.6,-153.6C-279.3,-152.6,-280,-151.6,-280.6,-150.7C-281.1,-150,-281.6,-149.3,-282,-148.6C-282.7,-147.6,-283.3,-146.6,-284,-145.6C-284.4,-144.9,-284.9,-144.2,-285.3,-143.5C-286,-142.5,-286.6,-141.4,-287.3,-140.4C-287.7,-139.7,-288.1,-139.1,-288.5,-138.4C-289.2,-137.2,-289.9,-135.9,-290.7,-134.7C-290.9,-134.3,-291.1,-133.9,-291.4,-133.5C-291.4,-133.5,-291.4,-133.5,-291.4,-133.5C-313.2,-95.2,-325,-51.7,-325,-6.3C-325,-4.5,-325,-2.7,-324.9,-0.9C-324.9,-0.6,-324.9,-0.3,-324.9,0C-324.9,1.4,-324.8,2.9,-324.8,4.3C-324.8,4.8,-324.7,5.3,-324.7,5.8C-324.6,7.1,-324.6,8.3,-324.5,9.5C-324.5,10.1,-324.4,10.7,-324.4,11.3C-324.3,12.4,-324.2,13.6,-324.1,14.7C-324.1,15.4,-324,16,-324,16.6C-323.9,17.7,-323.8,18.9,-323.6,20C-323.6,20.6,-323.5,21.2,-323.5,21.7C-323.3,23.1,-323.2,24.4,-323,25.7C-323,26.1,-322.9,26.5,-322.9,26.9C-322.7,28.5,-322.4,30.1,-322.2,31.7C-322.2,31.7,-322.2,31.7,-322.2,31.7C-315.5,76.6,-297.1,117.7,-270.3,152C-270.3,152,-270,152.4,-270,152.4C-269.5,153.1,-268.8,153.8,-268.2,154.6C-266.6,156.6,-264.5,159.2,-263.1,160.6C-215.7,215.5,-145.9,250.8,-67.9,250.8C-1,250.9,59.4,227.4,102.1,184.9C136.7,150.5,162.3,105.4,178.2,50.7C190.9,7.1,197.7,-43.8,198.5,-100.5C198.5,-100.5,325,-198.6,325,-198.6C325,-198.6,317.8,-208.9,317.8,-208.9Z"
            stroke="url(#hblg2)"
            strokeWidth="2"
          />
        </g>
        <g>
          <path
            fill="none"
            d="M-208.1,-190.9C-192.2,-203.2,-177,-211.1,-165.9,-215.8C-168.8,-148.3,-153.3,-90.1,-120.7,-46.4C-91.4,-7.1,-49.2,19.7,2,32C-49.7,44.5,-114.3,32.8,-166.8,0.3C-218.5,-31.6,-251.9,-78.8,-261.7,-133.2C-247.5,-154.9,-229.5,-174.4,-208.1,-190.9Z"
            stroke="url(#hblg3)"
            strokeWidth="2"
          />
        </g>
        <g>
          <path
            fill="none"
            d="M-279.6,-100.5C-263.4,-51,-229,-8.3,-180.2,21.9C-137.5,48.2,-87.4,62.2,-40.7,62.2C-29.1,62.2,-17.8,61.3,-6.9,59.6C-25.4,77.6,-69.1,90.7,-117.6,92C-193.1,94.2,-260.2,68.8,-297.6,24.1C-298.9,14.1,-299.7,4,-299.7,-6.4C-299.7,-39.4,-292.7,-71.2,-279.6,-100.5Z"
            stroke="url(#hblg4)"
            strokeWidth="2"
          />
        </g>
        <g>
          <path
            fill="none"
            d="M-287.2,68.7C-245.9,99.9,-188.9,117.6,-126.4,117.6C-123.2,117.6,-120.1,117.5,-116.9,117.4C-84,116.5,-53.7,110.7,-29.5,101.5C-29.9,102.1,-30.4,102.7,-30.8,103.3C-30.9,103.5,-31,103.7,-31.2,103.8C-49.3,128.5,-69.4,146.5,-91.7,158.1C-93,158.8,-94.3,159.4,-95.6,160C-105.1,164.5,-115.1,167.8,-125.5,169.9C-193.7,183.7,-230.2,157.9,-245.1,142.9C-263.4,121.2,-277.8,96.1,-287.2,68.7Z"
            stroke="url(#hblg5)"
            strokeWidth="2"
          />
        </g>
        <g>
          <path
            fill="none"
            d="M153.9,43.7C139.3,94.2,115.9,135.6,84.3,167C46.3,204.7,-7.7,225.5,-67.9,225.5C-107,225.5,-144,215.7,-176.4,198.5C-171.7,198.8,-167,199.1,-162.2,199.1C-148.8,199.1,-134.9,197.7,-120.6,194.7C-56.2,181.8,-7.3,132.9,31.5,42C31.5,42,31.5,42,31.5,42C34.5,35,37.4,27.8,40.3,20.3C42,16.1,42.9,13.3,42.9,13.3C42.9,13.3,42.9,13.3,42.9,13.3C44.5,9.1,46,4.8,47.6,0.4C50.9,-9.1,53.7,-17.9,56.4,-26.5C61.8,-43.5,66.5,-58.3,72.8,-70.2C83,-89.5,96.5,-100.4,115.5,-104.5C137.6,-109.2,160.5,-104.4,173.1,-100.8C172.4,-46.3,165.9,2.3,153.9,43.7Z"
            stroke="url(#hblg6)"
            strokeWidth="2"
          />
        </g>
      </g>
    </svg>
  )
})

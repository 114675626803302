import { useMemo } from 'react'

import InfoOutlined from '@mui/icons-material/InfoOutlined'

import { useHistory, useRouteMatch } from 'react-router-dom'

import { AutomationSidebar, ButtonConfig } from 'components/pages/automations/AutomationSidebar'

import { TrashOutlineIcon } from 'icons'

import { FormSchemaReturnTypeForTemplate } from '../../editor/formSchema'
import { AutomationRuleTemplateQuery } from '../__generated__/AutomationRuleTemplate.queries.generated'

import { DeleteTemplatePanel } from './DeleteTemplatePanel'

import { InfoPanel } from './InfoPanel'

const SidebarDrawerPanelOptions = ['info', 'trigger_and_action', 'delete'] as const
type SidebarDrawerPanel = (typeof SidebarDrawerPanelOptions)[number]

const TopButtonConfig: Array<ButtonConfig<SidebarDrawerPanel>> = [
  {
    type: 'info',
    label: 'Recipe Info',
    Icon: InfoOutlined,
  },
]

const BottomButtonConfig: Array<ButtonConfig<SidebarDrawerPanel>> = [
  {
    type: 'delete',
    label: 'Delete Recipe',
    Icon: TrashOutlineIcon,
  },
]

export const AutomationRuleTemplateSidebar = ({
  loading,
  deleteTemplate,
  automationRuleTemplate,
  form,
}: {
  loading: boolean
  deleteTemplate: () => void
  automationRuleTemplate: AutomationRuleTemplateQuery['automationRuleTemplate']
  form: FormSchemaReturnTypeForTemplate
}) => {
  const basePath = '/automations/recipes'
  const history = useHistory()
  const match = useRouteMatch<{ panel: SidebarDrawerPanel }>(`${basePath}/:token/:panel`)
  const { token } = automationRuleTemplate

  const currentPanel = useMemo(() => {
    if (match && SidebarDrawerPanelOptions.includes(match.params.panel)) {
      return match.params.panel
    }
    return null
  }, [match])

  const setCurrentPanel = (panel: SidebarDrawerPanel | null) => {
    if (!panel || panel === currentPanel) {
      history.push(`${basePath}/${token}`)
    } else {
      history.push(`${basePath}/${token}/${panel}`)
    }
  }

  const getPanelComponent = (panel: SidebarDrawerPanel | null): React.ReactNode | null => {
    switch (panel) {
      case 'info':
        return <InfoPanel form={form} />
      case 'delete':
        return <DeleteTemplatePanel deleteTemplate={deleteTemplate} loading={loading} />
      default:
        return null
    }
  }

  return (
    <AutomationSidebar
      currentPanel={currentPanel}
      setCurrentPanel={setCurrentPanel}
      topButtonConfig={TopButtonConfig}
      bottomButtonConfig={BottomButtonConfig}
    >
      {getPanelComponent(currentPanel)}
    </AutomationSidebar>
  )
}

import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import CollapseCard from 'components/HbComponents/CollapseCard'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  collapse: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  root: {
    margin: 0,
  },
}))

const ToggleSection = ({ toggle, content, id }: { toggle: ReactNode; content: ReactNode; id: string }) => {
  const classes = useStyles()

  return (
    <CollapseCard
      providerProps={{ expanded: false }}
      rootId={id}
      toggleContent={toggle}
      collapseContent={content}
      collapseProps={{ className: classes.collapse }}
      cardProps={{ classes: { root: classes.root } }}
    />
  )
}
export default ToggleSection

import { FormControlLabel, FormControlLabelProps, Switch, SwitchProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  formLabelRoot: {
    margin: theme.spacing(0),
    display: 'flex',
    justifyContent: 'space-between',
  },
  formLabel: {
    fontWeight: theme.fontWeight.bold,
  },
  root: {}, // TODO - add styles
  track: {},
  switchBase: {},
}))

export type HbSwitchProps = SwitchProps & {
  label?: React.ReactNode
  classes?: Partial<SwitchProps['classes']>
  formControlLabelProps?: Partial<FormControlLabelProps> & {
    classes?: Partial<FormControlLabelProps['classes']>
  }
}
function HbSwitch({ formControlLabelProps, label, classes: switchOverrideClasses, ...rest }: HbSwitchProps) {
  const classes = useStyles()
  const formLabelOverrideClasses = formControlLabelProps?.classes
  const mergedformLabelClasses = mergeOverrideStyles(
    { label: classes.formLabel, root: classes.formLabelRoot },
    formLabelOverrideClasses
  )

  const mergedSwitchOverrideClasses = mergeOverrideStyles(
    { root: classes.root, switchBase: classes.switchBase },
    switchOverrideClasses
  )
  return (
    <FormControlLabel
      labelPlacement="start"
      {...formControlLabelProps}
      classes={mergedformLabelClasses}
      label={label || formControlLabelProps?.label}
      control={<Switch color="default" {...rest} classes={mergedSwitchOverrideClasses} />}
    />
  )
}

export default HbSwitch

import React from 'react'

import { DiffType } from 'deep-object-diff'

import { EntityAttributes } from './EntityInformation'

export type EntityInformationLayout = 'singleColumn' | 'twoColumn'

export type EntityInformationSave = (
  values: EntityAttributes, // Entity attributes
  relationships?: Record<string, unknown>, // Entity relationships
  additionalTopLevelData?: Record<string, unknown> // Top level data (e.g. object arrays and flags)
) => Promise<boolean>

export interface EntityInformationContextValue<EntityType, ValuesType = Record<string, unknown>> {
  // Difference to highlight with the current version
  diff?: DiffType<Partial<EntityType>, Partial<EntityType>> | null
  // The latest entity data read from the server.
  data: EntityType
  isHistory?: boolean
  // Previous historical version of the entity data
  previousData?: Partial<EntityType>
  // Sends entity data modifications to the server.
  // If the form is read-only, save will not be provided.
  save?: EntityInformationSave
  // The initial values for populating Formik form state.
  // Intended to be written back to the server when saving.
  values: ValuesType
  // The visual layout of the form
  layout: EntityInformationLayout
}

/**
 * Provides the ability to read and write data for the overarching entity
 * in a library page.
 */
export const EntityInformationContext = React.createContext<EntityInformationContextValue<any, any>>({
  diff: undefined,
  data: undefined,
  isHistory: false,
  previousData: undefined,
  values: {},
  layout: 'singleColumn',
})

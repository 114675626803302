import { Interpolation } from '@emotion/react'

import { HbTheme } from 'components/themeRedesign'
import { Urls } from 'helpers/api'
import { Organization } from 'reducers/applicationReducer'
import { ColorNameEnum, MfaMethodEnum, Queue } from 'types/api'

// Helper that overwrites keys in type T with keys in R
export type Modify<T, R> = Omit<T, keyof R> & R

// Ensures that type A has the exact same properties as type B.
export type Exact<A, B> = A extends B ? (B extends A ? A : never) : never

export type Nullable<T> = { [P in keyof T]: T[P] | null }

export interface ErrorResult {
  success: false
  error: {
    type: string
    message: string
  }
  object?: any
}
export type SuccessResult<Key extends string, T> = { success: true } & {
  [K in Key]: T
}
export type Result<K extends string, T> = ErrorResult | SuccessResult<K, T>
export type EmptyResult = Result<'empty', true>

export function isSuccessResult<P extends string, V>(result: Result<P, V>): result is SuccessResult<P, V> {
  return result.success
}

export type WithToken = {
  token: string
}

export type Theme = HbTheme

export type CSS = Interpolation<Theme>

// @deprecated Takes theme => classes func or classes obj and returns type for classes prop passed to the component
export type WithStyles<T> = T extends (theme: Theme) => { [className: string]: any }
  ? {
      classes: { [K in keyof ReturnType<T>]: string }
    }
  : {
      classes: { [K in keyof T]: string }
    }

// @deprecated
export type WithPartialStyles<T> = T extends (theme: Theme) => { [className: string]: any }
  ? {
      classes?: { [K in keyof ReturnType<T>]?: string }
    }
  : {
      classes?: { [K in keyof T]?: string }
    }
// eslint-enable no-explicit-any

/* WithOptional allows makes it possible to make an existing types properties
 * optional in a similar way to Partial<T>, but it enables specifying specific
 * attributes e.g.
 *
 * type Car = { model: string, color: string, year: number }
 * type CarWithOptionalColorAndYear = WithOptional<Car, 'color' | 'year'>
 *
 * Which is equivalent to:
 *
 * type CarWithOptionalColorAndYear = { model: string, color?: string, year: number }
 *
 * NOTE: We use Pick to preserve the modifiers of original type properties. See
 * https://stackoverflow.com/questions/51580825/typescript-mapped-type-with-exclude-changes-property-modifiers
 * for more info.
 */
export type WithOptional<T extends Record<string, any>, O extends keyof T> = Pick<T, Exclude<keyof T, O>> & {
  [K in O]?: T[K]
}

export type WithRequired<T, K extends keyof T> = T & { [P in K]-?: T[P] }

// TODO(jsu): these TimeWithParts interfaces should be deprecated
export interface TimeWithPartsSimple {
  localize: boolean
  time?: string | null
  string: string
}

export interface TimeWithParts extends TimeWithPartsSimple {
  date: string
  timestamp: string
  zone?: string | null
}

export interface Money {
  formatted: string
  amount: number
  // eslint-disable-next-line camelcase
  currency_code: string
}

export interface Option {
  display: string
  value: string
}

export interface Badge {
  name: string
  color: string
}

export interface AccountCredential {
  isWorkOSCredential: boolean
  unconfirmedEmail: string | null
  nameId: string | null
}

export interface BasicAccount {
  token: string
  initials: string
  firstName: string
  lastName: string
  shortName: string
  fullName: string
  username: string
  avatarColor: ColorNameEnum
  avatarVariant: number
  email: string
}

export interface Account extends BasicAccount {
  outOfOfficeUntil: Date
  badges: Badge[]
  credential: AccountCredential
  mfaEnabled?: boolean
  queues: Queue[]
}

export interface OutOfOfficeInterval {
  token: string
  startsAt: Date
  endsAt: Date
}

export interface CurrentAccount extends Account {
  createdAt: string
  intercomId?: string
  mfaMethod: MfaMethodEnum
  outOfOfficeIntervals: OutOfOfficeInterval[]
}

/* The Brand type constructor makes types of a given Structure
 * unassignable to types with the sames structure without the
 * specified Mark. The tests have some good examples.
 */
export type Brand<Structure, Mark> = Structure & { __mark: Mark }

export interface OtherInfoEntry {
  token?: string
  label: string
  value: string | null
}

export type OtherInfo = OtherInfoEntry[]

export interface Stringable {
  toString: () => string
}

export type InitialData = {
  environment: 'staging' | 'sandbox' | 'production'
  csrfToken: string
  urls: Urls
  flashes: Record<string, string>
  currentAccount: CurrentAccount
  organizations: Organization[]
  userAccounts: Account[]
  currentOrganizationToken: string
  minimumPasswordScore: number
  churnzeroAppkey: string
  gitSha1: string
  sentrySampleRate: number
  intercomAppId: number
  isCustomerDomain: boolean
  disablePersistedQueries: boolean
}

export const OPTION_YES = 'YES'
export const OPTION_NO = 'NO'

export function assertUnreachable<T>(_x: never): T {
  // This code is unreachable, and we don't want to propagate null return types
  return null as unknown as T
}

export type KeyboardOrMouseEvent = React.MouseEvent | React.KeyboardEvent

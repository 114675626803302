import { useRef, useEffect, useCallback } from 'react'

import { FormikProps } from 'formik'

import { nanoid } from 'nanoid'

import { useSnackbar } from 'notistack'

import { useDispatch } from 'actions/store'

import { PersonResult } from 'components/cases/shared/subjects/CaseSubjectSelect'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { usePrevious } from 'hooks'
import { useHbMutation, usePaginatedQuery } from 'hooks/ApolloHelpers'

import { useDateFormatter } from 'hooks/DateFormatHooks'
import { addCurrentSearch, updateUIState as _updateUIState } from 'reducers/MiddeskReducer'
import { closeTab } from 'reducers/tabReducer'
import { EnqueueMiddeskBusinessJobInput } from 'types/api'

import { DEFAULT_REQUEST_ERROR_MSG } from './Middesk.constants'
import { ENQUEUE_MIDDESK_BUSINESS_JOB_MUTATION, MIDDESK_BUSINESS_BY_LIBRARY_TOKEN } from './Middesk.queries'
import { MiddeskCurrentSearch, MiddeskFields, MiddeskUIStateUpdate, SparseMiddeskFields } from './Middesk.types'
import {
  EnqueueMiddeskBusinessJobMutation,
  EnqueueMiddeskBusinessJobMutationVariables,
  MiddeskBusinessByLibraryTokenQuery,
  MiddeskBusinessFragment,
} from './__generated__/Middesk.queries.generated'

export const useHandleSetFieldValueFromPerson = () => {
  return (props: { entity: PersonResult } & Pick<FormikProps<SparseMiddeskFields>, 'values' | 'setFieldValue'>) => {
    const { entity, setFieldValue, values } = props
    const peopleCount = values?.people?.length || 0
    setFieldValue(`people.${peopleCount}.name`, entity.displayName)
    setFieldValue(`people.${peopleCount}._uid`, nanoid())
  }
}

export const useScrollRef = (values: SparseMiddeskFields) => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const peopleCount = values?.people?.length || 0
  const prevPeopleCount = usePrevious(peopleCount)

  useEffect(() => {
    // Scroll to the bottom of this section after a person
    // has been added so that the newly added section is visible
    if (peopleCount > prevPeopleCount) {
      scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [peopleCount, prevPeopleCount])

  return scrollRef
}

export const useLastOrderedText = (libraryToken: string) => {
  const { displayedData } = usePaginatedQuery<MiddeskBusinessByLibraryTokenQuery, MiddeskBusinessFragment>({
    selector: (d) => (d?.middeskBusinessesByLibraryToken ? d.middeskBusinessesByLibraryToken : null),
    query: MIDDESK_BUSINESS_BY_LIBRARY_TOKEN,
    args: { libraryToken },
    pageSizes: [1], // we only care about the most recently ordered business here
    fetchPolicy: 'cache-and-network',
  })
  const formatter = useDateFormatter()
  return displayedData?.length ? `Last ordered on ${formatter(displayedData[0].createdAt)}` : null
}

export const useUpdateUIState = () => {
  const dispatch = useDispatch()
  return useCallback(
    (libraryBusinessToken: string, UIState: MiddeskUIStateUpdate) => {
      dispatch(
        _updateUIState({
          libraryBusinessToken,
          UIState,
        })
      )
    },
    [dispatch]
  )
}

export const useHandleSubmit = ({
  caseToken,
  libraryBusiness,
}: {
  caseToken: string
  libraryBusiness?: MiddeskCurrentSearch['libraryBusiness']
}) => {
  const dispatch = useDispatch()
  const [enqueueJob] = useHbMutation<EnqueueMiddeskBusinessJobMutation, EnqueueMiddeskBusinessJobMutationVariables>(
    ENQUEUE_MIDDESK_BUSINESS_JOB_MUTATION
  )
  const { enqueueSnackbar } = useSnackbar()
  const usage = useUsage()

  const updateUIState = useUpdateUIState()

  return async (searchFields: MiddeskFields, handlers: FormikProps<MiddeskFields>) => {
    const handleError = (message: string) => {
      handlers.setSubmitting(false)
      enqueueSnackbar(message, {
        variant: 'error',
      })
      if (libraryBusiness?.token) {
        updateUIState(libraryBusiness.token, {
          type: 'search',
          searchFields,
        })
      }
    }

    if (!libraryBusiness) {
      handleError(DEFAULT_REQUEST_ERROR_MSG)
      return
    }

    const { people = [], ...rest } = searchFields

    const input: EnqueueMiddeskBusinessJobInput = {
      ...rest,
      personNames: people.map(({ name }) => name),
      investigationToken: caseToken,
      libraryBusinessToken: libraryBusiness.token,
    }

    // Create async job for Middesk business search
    const result = await enqueueJob({
      variables: {
        input,
      },
    })

    if (
      result.data?.enqueueMiddeskBusinessJob?.job.errorMessage ||
      !result.data?.enqueueMiddeskBusinessJob?.job.token
    ) {
      handleError(result.data?.enqueueMiddeskBusinessJob?.job.errorMessage || DEFAULT_REQUEST_ERROR_MSG)
      return
    }

    try {
      const searchFieldsString = JSON.stringify(Object.keys(searchFields))
      usage.logEvent({
        name: 'middesk:searchSubmission:success',
        data: {
          searchFields: searchFieldsString,
        },
      })
    } catch (e) {
      /* eslint-disable-next-line no-console */
      console.error('Could not stringify search fields', e)
    }

    const jobToken = result.data.enqueueMiddeskBusinessJob.job.token
    dispatch(closeTab({ tabId: 'middesk' }))
    // Save the search information that's needed to poll for the async job
    // and to show the job statuses in snackbars. Because these searches can take
    // a long time (up to ~5 minutes), the code that manages the polling and the snackbar state
    // is called at the root of the app so that it can keep running regardless of whether
    // the components for the Middesk tab or the case that it was opened from are mounted.
    dispatch(
      addCurrentSearch({
        libraryBusiness,
        caseToken,
        jobToken,
      })
    )
  }
}

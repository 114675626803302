import { gql } from '@apollo/client'

import { InformationRequestsCard } from 'components/cases/Tabs/Overview/InformationRequestsCard'
import { OtherInfoCard } from 'components/cases/Tabs/Overview/OtherInfoCard'
import { ReviewsCard } from 'components/cases/Tabs/Overview/ReviewsCard/ReviewsCard'
import { CASE_LOCK_FRAGMENT } from 'components/cases/hooks/useCaseLock'
import { TAG_FRAGMENT } from 'components/tags/Tags.queries'

/**
 * !!! Important note !!!
 *
 * These fragments should be alongside the UI components they represent.
 * They are here temporarily, to avoid importing UI code from investigationsActions,
 * which makes it difficult to roll out strict null checks.
 *
 * The fragments should be returned to the UI component files.
 */

export const AlertsCardFragments = {
  entry: gql`
    fragment AlertsCard on Investigation {
      caseAlertCount
      maxCaseAlertTriggeredAt
      frequentCaseAlertRules {
        rule
        count
      }
    }
  `,
}

export const TransactionsCardFragments = {
  entry: gql`
    fragment TransactionsCard on Investigation {
      transactionStatistics {
        totalCount
        netActivity {
          formatted
        }
        earliestTransactionDate
        latestTransactionDate
        countsByBucket
      }
    }
  `,
}

export const CaseOverviewFragments = {
  entry: gql`
    fragment CaseOverview on Investigation {
      ...AlertsCard
      ...TransactionsCard
      ...OtherInfoCard
      ...ReviewsCard
      ...InformationRequestsCard
    }

    ${AlertsCardFragments.entry}
    ${TransactionsCardFragments.entry}
    ${OtherInfoCard.fragments.entry}
    ${ReviewsCard.fragments.entry}
    ${InformationRequestsCard.fragments.entry}
  `,
}

export const CaseHeaderFragments = {
  entry: gql`
    fragment CaseHeader on Investigation {
      name
      taggedWith {
        ...ObjectTag
      }
    }

    ${TAG_FRAGMENT}
  `,
}

export const CASE_ACTIONS_OVERVIEW_QUERY = gql`
  query GetCaseActionsOverview($caseToken: String!) {
    investigation(token: $caseToken) {
      ...CaseOverview
      ...CaseLock
      ...CaseHeader
      token
    }

    currentOrganization {
      tagDefinitions(allowedTypes: [investigations]) {
        token
        label
        color
        archived
      }
    }
  }

  ${CaseOverviewFragments.entry}
  ${CASE_LOCK_FRAGMENT}
  ${CaseHeaderFragments.entry}
`

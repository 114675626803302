import { ReactElement } from 'react'

import {
  TextSnippetOutlined,
  LayersOutlined,
  PlagiarismOutlined,
  ApprovalOutlined,
  NotificationsOutlined,
  LibraryAddCheckOutlined,
  ElectricBoltOutlined,
} from '@mui/icons-material'

import { Hue } from 'components/colors'
import { AutomationTemplateCategoryStyle } from 'types/api'

export type TemplateCategoryStyles = { themeColor: Hue; icon: ReactElement }

export const getTemplateCategoryStyles = (style?: AutomationTemplateCategoryStyle | null): TemplateCategoryStyles => {
  switch (style) {
    case AutomationTemplateCategoryStyle.Digest:
      return {
        themeColor: 'salmon',
        icon: <TextSnippetOutlined />,
      }
    case AutomationTemplateCategoryStyle.Diligence:
      return {
        themeColor: 'lemon',
        icon: <PlagiarismOutlined />,
      }
    case AutomationTemplateCategoryStyle.Management:
      return {
        themeColor: 'plum',
        icon: <LayersOutlined />,
      }
    case AutomationTemplateCategoryStyle.Notification:
      return {
        themeColor: 'rose',
        icon: <NotificationsOutlined />,
      }
    case AutomationTemplateCategoryStyle.Preparation:
      return {
        themeColor: 'linen',
        icon: <LibraryAddCheckOutlined />,
      }
    case AutomationTemplateCategoryStyle.Quality:
      return {
        themeColor: 'cyan',
        icon: <ApprovalOutlined />,
      }
    case AutomationTemplateCategoryStyle.Default:
    default:
      return {
        themeColor: 'lemon',
        icon: <ElectricBoltOutlined />,
      }
  }
}

import { ReactNode } from 'react'

import { useSelector } from 'actions/store'
import { hasAnyPermissions, hasPermissions } from 'helpers/stateHelpers'
import { BadgePermissionsEnum } from 'types/api'

interface Props {
  permissions: BadgePermissionsEnum[]
  allowed: ReactNode
  restricted?: ReactNode
}

export function PermissionSwitch({ permissions, allowed, restricted = null }: Props) {
  const hasAllPermissions = useSelector((state) => hasPermissions(state, permissions))

  return <>{hasAllPermissions ? allowed : restricted}</>
}

export function AnyPermissionSwitch({ permissions, allowed, restricted }: Props) {
  const hasAnyPermission = useSelector((state) => hasAnyPermissions(state, permissions))

  return <>{hasAnyPermission ? allowed : restricted}</>
}

import { useEffect, useMemo, useState } from 'react'

import { gql, useQuery } from '@apollo/client'

import BadgeSelect, { Badge } from 'components/library/BadgeSelect'
import { ColorNameEnum } from 'types/api'

import { Account } from 'types/hb'
import { isTruthy } from 'utils'
import { ArrayElement } from 'utils/typeDerivations'

import { useAutomationAdminOperations } from '../../../hooks/AutomationAdminHelpers'

import AccountSelect from './AccountSelect'
import {
  AdminAutomationRuleOrgQuery,
  AdminAutomationRuleOrgQueryVariables,
} from './__generated__/AdminRuleComponents.generated'

const ADMIN_AUTOMATION_RULE_ORG_QUERY = gql`
  query AdminAutomationRuleOrg($organizationToken: ID!) {
    adminAutomationRuleOrganization(organizationToken: $organizationToken) {
      name
      token
      accounts {
        nodes {
          fullName
          token
          avatarColor
          avatarVariant
          firstName
          lastName
          username
          initials
          shortName
          email
        }
      }
      badges {
        nodes {
          token
          name
          displayName
          color
        }
      }
    }
  }
`

type OrgBadge = NonNullable<
  ArrayElement<
    NonNullable<NonNullable<AdminAutomationRuleOrgQuery['adminAutomationRuleOrganization']>['badges']>['nodes']
  >
>

export function AdminRuleBadgeSelect({
  organizationToken,
  actionParamsBadgeTokens,
  handleSelectBadges,
  className,
  disabled = false,
}: {
  organizationToken: string
  actionParamsBadgeTokens: string[]
  handleSelectBadges: (badges: Array<Badge>) => void
  className?: string
  disabled?: boolean
}) {
  const [selectedBadges, setSelectedBadges] = useState<
    Array<{ token: string; color: ColorNameEnum; displayName: string }>
  >([])
  const { data, loading } = useQuery<AdminAutomationRuleOrgQuery, AdminAutomationRuleOrgQueryVariables>(
    ADMIN_AUTOMATION_RULE_ORG_QUERY,
    { variables: { organizationToken }, fetchPolicy: 'cache-and-network' }
  )

  const availableBadges = useMemo(
    () => data?.adminAutomationRuleOrganization?.badges.nodes?.filter((b): b is OrgBadge => b !== null) || [],
    [data]
  )

  useEffect(() => {
    setSelectedBadges(
      actionParamsBadgeTokens.map((token) => availableBadges.find((ab) => ab.token === token)).filter(isTruthy) ?? []
    )
  }, [actionParamsBadgeTokens, availableBadges])

  if (loading) return null

  return (
    <BadgeSelect
      disabled={disabled}
      helperText="All users under the badge will receive the notification"
      className={className}
      availableBadges={availableBadges}
      selectedBadges={selectedBadges}
      onChange={handleSelectBadges}
    />
  )
}

export function AdminAccountSelect({
  organizationToken,
  className,
}: {
  organizationToken: string
  className?: string
}) {
  const canUseExternalAccounts = useAutomationAdminOperations(organizationToken)
  const { data, loading } = useQuery<AdminAutomationRuleOrgQuery, AdminAutomationRuleOrgQueryVariables>(
    ADMIN_AUTOMATION_RULE_ORG_QUERY,
    { variables: { organizationToken }, fetchPolicy: 'cache-and-network' }
  )

  if (!canUseExternalAccounts || loading || !data?.adminAutomationRuleOrganization.accounts.nodes) return null

  const automationAdminAccounts = data.adminAutomationRuleOrganization.accounts.nodes.reduce(
    (accum, currentAccount) => {
      if (currentAccount) {
        accum.push({
          ...currentAccount,
          avatarColor: currentAccount.avatarColor,
          // Badge, queue, OOO and credential are required for AccountSelect reuse but not needed for this feature
          badges: [],
          queues: [],
          outOfOfficeUntil: new Date(),
          credential: { isWorkOSCredential: false, unconfirmedEmail: null, nameId: null },
        })
      }

      return accum
    },
    [] as Account[]
  )

  return <AccountSelect className={className} automationAdminAccounts={automationAdminAccounts} />
}

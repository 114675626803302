import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { Theme } from 'types/hb'

import { SectionTitle } from '../summary/SectionTitle'

export interface Props {
  children: ReactNode
  lowlight?: boolean
  classes?: { root?: string }
}

const useRedesignStyles = makeStyles((theme: Theme) => ({
  root: {
    ...theme.typography.overline,
    fontWeight: 600,
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
}))

export function SectionHeader(props: Props) {
  const { classes: classesOverrides, children } = props
  const classes = useRedesignStyles()
  return <SectionTitle className={classnames(classes.root, classesOverrides?.root)} title={children} />
}

import { BatchMergeDialog } from 'dashboards/profiles/BatchActionsMenu/dialogs/BatchMergeDialog'
import { ActionMap } from 'dashboards/shared/components/BatchActionsMenu/types'
import MergeIcon from 'icons/MergeIcon'
import { BatchActionTypeEnum } from 'types/api'

const actionMap: ActionMap = {
  [BatchActionTypeEnum.MergeLibraryProfiles]: {
    label: 'Merge',
    Dialog: BatchMergeDialog,
    Icon: MergeIcon,
  },
}
export default actionMap

import { isSnakeLibraryDataTypeKey, SnakeDataTypeKey } from 'actions/importingFields.types'
import { useDispatch, useSelector } from 'actions/store'
import { openNewImportForm, openNewLibraryRecordForm } from 'actions/viewActions'
import { getSupportedLibraryDataTypes, useHasPermission } from 'helpers/stateHelpers'
import { ImportableType } from 'reducers/applicationReducer'
import { BadgePermissionsEnum } from 'types/api'

export function useCreatableLibraryTypes() {
  const canCreateFinancialInstitutions = useHasPermission(BadgePermissionsEnum.CreateFinancialInstitutions)
  const types = useSelector(getSupportedLibraryDataTypes).filter((t: SnakeDataTypeKey): t is ImportableType => {
    if (t === 'case_import') {
      return false
    }

    if (t === 'institution' && !canCreateFinancialInstitutions) {
      return false
    }

    return true
  })

  return types
}

export function useAddToCase(caseToken: string) {
  const dispatch = useDispatch()
  const supportedLibraryDataTypes = useCreatableLibraryTypes()

  const addData = (formType: ImportableType) => {
    if (isSnakeLibraryDataTypeKey(formType)) {
      dispatch(openNewLibraryRecordForm(caseToken, formType))
    } else {
      dispatch(openNewImportForm(caseToken, formType))
    }
  }

  return { dataTypes: supportedLibraryDataTypes, addData }
}

import {
  RefCallback,
  useEffect,
  useRef,
  useState,
  MutableRefObject,
  Dispatch,
  SetStateAction,
  useCallback,
  useLayoutEffect,
} from 'react'

import { Resizable } from 're-resizable'

import { useDebouncedCallback } from 'use-debounce'

import { getElementHasOverflow, useCallbackRef } from 'hooks'

/**
 * Note:
 * Word of caution while deciding whether to use these hooks.
 * Prefer simple css for enforcing layout changes in most cases.
 * These hooks may be useful when needing to go outside the capabilities of css alone.

 * Be sure to assess any performance impacts from using these hooks.
 * Frequent DOM measurements/computations can be expensive and affect rendering performance.
 */

/**
 * Allows observing the resizing of a specified container element
 */
export const useResizeObserver = <ContainerElement extends HTMLElement>(
  handleResize: ResizeObserverCallback
): {
  observer: MutableRefObject<ResizeObserver>
  getRef: RefCallback<ContainerElement>
  ref: ContainerElement | null
} => {
  const [ref, getRef] = useCallbackRef<ContainerElement>()

  const observer = useRef(new ResizeObserver(handleResize))

  // initial container size observer
  useLayoutEffect(() => {
    observer.current = new ResizeObserver(handleResize)
    if (ref) observer.current.observe(ref)
    const currentObserver = observer.current
    return () => {
      if (ref) currentObserver.unobserve(ref)
    }
  }, [handleResize, ref])

  return { observer, getRef, ref }
}

/**
 * Reads and returns the width of a specified DOM element as it resizes.
 */
export const useElementDimensions = <Element extends HTMLElement>(): {
  getRef: RefCallback<Element>
  ref: Element | null
  setWidth: Dispatch<SetStateAction<number | undefined>>
  width: number | undefined
} => {
  const [width, setWidth] = useState<number>()

  const handleResize = useCallback(([{ target }]: ResizeObserverEntry[]) => {
    setWidth(target.clientWidth)
  }, [])

  const { getRef, ref } = useResizeObserver<Element>(handleResize)

  return { getRef, ref, setWidth, width }
}

export const useContainerDimensions = <ContainerElement extends HTMLElement>(): {
  getContainerRef: RefCallback<ContainerElement>
  containerRef: ContainerElement | null
  containerWidth: number | undefined
} => {
  const { getRef, ref, setWidth, width } = useElementDimensions<ContainerElement>()

  // measure the container once on mount
  useEffect(() => {
    setWidth(ref?.clientWidth)
  }, [ref, setWidth])

  return {
    containerWidth: width,
    getContainerRef: getRef,
    containerRef: ref,
  }
}

/**
 * Gets an instance of the Resizable component
 */
export const useResizable = (): {
  getResizableRef: RefCallback<Resizable>
  resizableRef: Resizable | null
  isResizing: boolean
  setIsResizing: Dispatch<SetStateAction<boolean>>
} => {
  const [isResizing, setIsResizing] = useState<boolean>(false)
  // ref: container for resizable contents
  const [resizableRef, getResizableRef] = useCallbackRef<Resizable | null>()

  return { getResizableRef, resizableRef, isResizing, setIsResizing }
}

/**
 * Tracks whether an item overflows when it resizes
 */
export const useContentHasOverflowedOnResize = <E extends HTMLElement>({
  debounceBy = 0,
}: { debounceBy?: number } = {}) => {
  const [hasOverflowed, setHasOverflowed] = useState(false)

  const handleTagResize = useCallback<ResizeObserverCallback>(
    ([{ target }]) => {
      const hasOverflowedNext = getElementHasOverflow(target, 'width')

      if (hasOverflowedNext !== hasOverflowed) {
        setHasOverflowed(hasOverflowedNext)
      }
    },
    [hasOverflowed]
  )

  const debouncedHandleTagResize = useDebouncedCallback(handleTagResize, debounceBy)
  const { getRef } = useResizeObserver<E>(debouncedHandleTagResize)

  return { getRef, hasOverflowed }
}

import type * as Types from '../../../../types/api'

import type { StrictLogicalExpression } from 'types/automations'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type RequestForInformationRecipientCopyQueryVariables = Types.Exact<{
  externalToken: Types.Scalars['String']['input']
}>

export type RequestForInformationRecipientCopyQuery = {
  __typename?: 'Query'
  requestForInformationRecipientCopy: {
    __typename?: 'RequestForInformationRecipientCopy'
    downloadUrl: string | null
  } | null
}

export const RequestForInformationRecipientCopyDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'RequestForInformationRecipientCopy' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'externalToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'requestForInformationRecipientCopy' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'externalToken' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'externalToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'downloadUrl' } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestForInformationRecipientCopyQuery, RequestForInformationRecipientCopyQueryVariables>

import { createSelector } from '@reduxjs/toolkit'

import { uniqBy } from 'lodash'

import { State } from 'actions/store'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { DashboardState, sortIsValid } from 'reducers/dashboards/dashboards.types'
import { simpleSelector } from 'reducers/utils'
import { findFilterComponent } from 'utils/query/api.types'

import { DashboardSlice, dashboardSlices } from './dashboards.constants'
import {
  getNamedQueries,
  getSearchRequestInfo,
  getValidFilterOrDefaultFilterIfEmpty,
  getViewTitle,
  makeGetNamedQueriesList,
} from './selectorHelpers'

const makeDashboardsSelectors = <T extends DashboardSlice>(stateKey: T) => {
  const defaultPage: DashboardState<T>['page'] = { size: 25, count: 1, number: 1 }

  const allFilters = simpleSelector((state) => state.dashboards[stateKey].filters.applied)
  const defaultFilterName = simpleSelector((state) => state.userSettings.recentDashboardViews[stateKey])

  const getNamedQueryLists = createSelector(
    simpleSelector((state) => state.application?.currentAccount),
    simpleSelector((state) =>
      state.application.organizations.find((org) => org.token === state.application.currentOrganizationToken)
    ),
    makeGetNamedQueriesList(stateKey)
  )
  const getNamedQueriesSelector = createSelector(getNamedQueryLists, getNamedQueries)
  const defaultFilters = createSelector(
    defaultFilterName,
    getNamedQueriesSelector,
    (_defaultFilterName, namedQueries) => namedQueries?.find((f) => f.name === _defaultFilterName)
  )

  const filters = {
    applied: {
      all: allFilters,
      valid: createSelector(allFilters, defaultFilters, getValidFilterOrDefaultFilterIfEmpty),
    },
    remoteOpen: simpleSelector((state) => state.dashboards[stateKey].filters.remoteOpen),
    /**
     * To be removed when enhanced feature flag is removed
     * @deprecated
     */
    name: simpleSelector((state) => state.dashboards[stateKey].filters.name),
    /**
     * To be removed when enhanced feature flag is removed
     * @deprecated
     */
    custom: simpleSelector((state) => state.dashboards[stateKey].filters.custom),
  }

  const byGroupId = createSelector(
    filters.applied.all,
    (state: State, groupId: string) => groupId,
    (appliedFilters, groupId) => {
      return findFilterComponent(groupId, false, appliedFilters?.filter)
    }
  )

  const allSorts = simpleSelector((state) => state.dashboards[stateKey].filters.applied.sorts)
  const customViews = simpleSelector((state) => state.dashboards[stateKey].customViews)
  const stateCurrentViewTitle = simpleSelector((state) => state.dashboards[stateKey].currentViewTitle)

  const searchRequestInfo = createSelector(
    filters.applied.valid,
    customViews,
    stateCurrentViewTitle,
    getNamedQueriesSelector,
    getSearchRequestInfo
  )

  const currentViewTitle = createSelector(stateCurrentViewTitle, searchRequestInfo, getViewTitle)

  const dashboardsSelectors = {
    query: createSelector(allFilters, (filter) => filter?.query),
    sorts: {
      all: allSorts,
      valid: createSelector(allSorts, (all) => all?.filter(sortIsValid) ?? []),
    },
    filters: { ...filters, byGroupId },
    customViews,
    getNamedQueryLists,
    namedQueries: getNamedQueriesSelector,
    searchRequestInfo,
    currentViewTitle,
    currentViewToken: createSelector(
      customViews,
      currentViewTitle,
      (views, title) => views?.find((view) => view.name === title)?.token
    ),
    page: simpleSelector((state) => state.dashboards[stateKey].page ?? defaultPage),
    dashboardColumns: createSelector(
      simpleSelector((state) => state.userSettings.dashboardColumns[stateKey]),
      (columns) => uniqBy(columns, (column) => column.apiName)
    ),
  }
  return dashboardsSelectors
}

type DashboardSelectorsMap = Record<DashboardSlice, ReturnType<typeof makeDashboardsSelectors>>

export const dashboardSelectorsMap: DashboardSelectorsMap = dashboardSlices.reduce((acc, cur) => {
  acc[cur] = makeDashboardsSelectors(cur)
  return acc
}, {} as DashboardSelectorsMap)

export const useDashboardSelectors = () => {
  const dashboardContextValue = useDashboardContext()
  return dashboardSelectorsMap[dashboardContextValue]
}

import { remove, isNil, isEmpty } from 'lodash'

import { AnyAction } from 'redux'

import { flattenOptions } from 'helpers/objHelpers'

// Text parsing actions
export const UPDATE_PASTE_TEXT = 'UPDATE_PASTE_TEXT'
export const RESET_PASTE_TEXT = 'RESET_PASTE_TEXT'
export const SET_PARSING_STATUS = 'SET_PARSING_STATUS'
export const SET_PARSING_RESULT = 'SET_PARSING_RESULT'
export const CLEAR_PARSING_RESULT = 'CLEAR_PARSING_RESULT'
export const UPDATE_PARSED_FIELD = 'UPDATE_PARSED_FIELD'
export const SET_PARSED_MESSAGE = 'SET_PARSED_MESSAGE'
export const RESET_PARSED_DATA = 'RESET_PARSED_DATA'
export const ADD_PARSING_HINT = 'ADD_PARSING_HINT'
export const SET_PARSING_OPTION = 'SET_PARSING_OPTION'
export const RESET_PARSING_OPTIONS = 'RESET_PARSING_OPTIONS'
export const SET_ACTIVE_PARSING_TOKEN = 'SET_ACTIVE_PARSING_TOKEN'

export interface ParsingOptions {
  hasHeaders?: boolean
  formatHint?: string
  unlockCases?: boolean
}

export interface ParsingOption {
  display: string
  value: string
}

export interface CombinedOption {
  label: string
  options: ParsingOption[]
}

export interface Field {
  keyword: string
  previewValues: string[]
  fieldName: string
  displayName: string
  isTruncated: boolean
  isUnknown: boolean
}

export interface Statistic {
  count: number
  name: string
  color: string
}

export interface ParsingHint {
  index: number
  field: string
}

export type FieldOption = ParsingOption | CombinedOption

export interface ParsingState {
  statusByType: { [type: string]: 'parsing' | null }
  pastesByType: { [type: string]: string }
  filenamesByType: { [type: string]: string }
  parsingHints: ParsingHint[]
  category: string | null
  headline: string | null
  message: string | null
  summary: string[] | null
  statistics: Statistic[] | null
  fieldOptions: FieldOption[]
  fields: Field[] | null
  optionsByType: {
    [type: string]: ParsingOptions
  }
  activeParsingToken: string | null
  warning: string | null
  issues: Record<string, unknown>
}

const getInitialState = (): ParsingState => ({
  statusByType: {},
  pastesByType: {},
  filenamesByType: {},
  parsingHints: [],
  category: null,
  headline: null,
  message: null,
  summary: [],
  statistics: [],
  fieldOptions: [],
  fields: [],
  optionsByType: {},
  activeParsingToken: null,
  warning: null,
  issues: {},
})

const parsingReducer = (state = getInitialState(), action: AnyAction): ParsingState => {
  switch (action.type) {
    case SET_PARSING_STATUS: {
      const newStatusByType = {
        ...state.statusByType,
        [action.dataType]: action.status,
      }
      return { ...state, statusByType: newStatusByType }
    }
    case SET_PARSING_RESULT: {
      const { category, categoryFields, fieldMappings, headline, message, summary, statistics, warning, issues } =
        action.parseResult
      return {
        ...state,
        category,
        headline,
        message,
        summary,
        statistics,
        warning,
        issues,
        fieldOptions: categoryFields,
        fields: fieldMappings,
      }
    }
    case CLEAR_PARSING_RESULT: {
      return {
        ...state,
        category: null,
        fieldOptions: [],
        fields: [],
      }
    }
    case UPDATE_PARSED_FIELD: {
      const newFields = (state.fields || []).slice()
      // Update the value of the parsed field in the list.
      const element = newFields[action.index]
      element.fieldName = action.newFieldName

      // Update the display name of the parsed field.
      const flatOptions = flattenOptions(state.fieldOptions)
      const option = flatOptions.find((opt: ParsingOption) => opt.value === action.newFieldName)
      element.displayName = option.display

      // Ensure that this field is no longer marked as unknown.
      element.isUnknown = false

      return { ...state, fields: newFields }
    }
    case RESET_PARSED_DATA: {
      return {
        ...state,
        category: null,
        fieldOptions: [],
        fields: [],
        parsingHints: [],
      }
    }
    case RESET_PASTE_TEXT: {
      if (action.dataType) {
        const newPastesByType = { ...state.pastesByType }
        const newFilenamesByType = { ...state.filenamesByType }
        delete newPastesByType[action.dataType]
        return {
          ...state,
          pastesByType: newPastesByType,
          filenamesByType: newFilenamesByType,
        }
      }
      return { ...state, pastesByType: {}, activeParsingToken: null }
    }
    case UPDATE_PASTE_TEXT: {
      const newPastesByType = {
        ...state.pastesByType,
        [action.dataType]: action.value,
      }
      const newFilenamesByType = {
        ...state.filenamesByType,
        [action.dataType]: action.filename,
      }

      return {
        ...state,
        pastesByType: newPastesByType,
        filenamesByType: newFilenamesByType,
        activeParsingToken: null,
      }
    }
    case ADD_PARSING_HINT: {
      const newParsingHints = state.parsingHints.slice(0)
      remove(newParsingHints, (hint) => hint.index === action.index)
      if (!isNil(action.fieldName) && !isEmpty(action.fieldName)) {
        newParsingHints.push({ index: action.index, field: action.fieldName })
      }
      return { ...state, parsingHints: newParsingHints }
    }
    case SET_PARSING_OPTION: {
      const currentOptions = state.optionsByType[action.dataType]
      const newOptions = {
        hasHeaders: true,
        ...currentOptions,
        [action.option]: action.value,
      }
      const newOptionsByType = {
        ...state.optionsByType,
        [action.dataType]: newOptions,
      }
      return { ...state, optionsByType: newOptionsByType }
    }
    case RESET_PARSING_OPTIONS: {
      const newOptionsByType = { ...state.optionsByType }
      delete newOptionsByType[action.dataType]
      return { ...state, optionsByType: newOptionsByType }
    }
    case SET_ACTIVE_PARSING_TOKEN: {
      return { ...state, activeParsingToken: action.token }
    }
    default: {
      return state
    }
  }
}

export default parsingReducer

import { css } from '@emotion/css'

import classNames from 'classnames'

import { DateInput } from 'components/HbComponents/Form/Inputs'

import { otherInfoDatePickerClassName } from './shared'

const baseInputContainerClassName = css({ wordBreak: 'break-word', '&&': { marginBottom: 16 } })

const inputContainerClassName = classNames(baseInputContainerClassName, otherInfoDatePickerClassName)

interface FormikOtherInfoDatePickerProps {
  disabled?: boolean
  inputContainerClassName?: string
  label: string
  name: string
  hideLabel?: boolean
}

export const FormikOtherInfoDatePicker = ({
  disabled,
  inputContainerClassName: propInputContainerClassName,
  label,
  name,
  hideLabel = false,
}: FormikOtherInfoDatePickerProps) => {
  return (
    <DateInput
      name={name}
      label={label}
      disabled={disabled}
      inputContainerClassName={classNames(inputContainerClassName, propInputContainerClassName)}
      hideLabel={hideLabel}
    />
  )
}

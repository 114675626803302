import { Divider } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useSearchRequestInfo } from 'dashboards/shared/react/getNamedQueryGroups'
import { Theme } from 'types/hb'

import FilterBannerActionButton from './FilterBannerActionButton'

const useStyles = makeStyles((theme: Theme) => ({
  divider: {
    width: 1,
    backgroundColor: theme.palette.text.primary,
    height: 31,
  },
  editingDivider: { backgroundColor: theme.palette.action.activeHover },
  filterActionsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    flex: 1,

    '& button': { textDecoration: 'underline', color: 'inherit' },
    '& $options': { maxWidth: 'fit-content', display: 'flex', columnGap: theme.spacing(1) },
    '& $clear': {
      display: 'flex',
      '& button': { color: 'inherit' },
    },
  },
  editing: {
    '& button': { '&:hover': { backgroundColor: theme.palette.action.activeHover } },
  },
  options: {},
  clear: {},
  dialogInput: {
    paddingTop: theme.spacing(),
  },
}))

const CustomViewControls = ({ isIconOnlyButtons }: { isIconOnlyButtons: boolean }) => {
  const classes = useStyles()

  const { isCreating, isCustom, isEditing } = useSearchRequestInfo()

  return (
    <>
      {isCustom && (
        <Divider
          orientation="vertical"
          className={classnames(classes.divider, isCustom && isEditing && classes.editingDivider)}
        />
      )}
      <div className={classnames(classes.filterActionsContainer)}>
        {isCustom && (
          <div className={classnames(classes.options, isCustom && isEditing && classes.editing)}>
            {isCreating && <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="save" />}
            {isCustom && (!isCreating || isEditing) && (
              <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="rename" />
            )}
            {!isCreating && isCustom && <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="delete" />}
            {isCustom && isEditing && <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="update" />}
            {isCustom && isEditing && (
              <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="save-as-new" />
            )}
          </div>
        )}
        <div className={classes.clear}>
          <FilterBannerActionButton iconOnly={isIconOnlyButtons} filterAction="clear" />
        </div>
      </div>
    </>
  )
}

export default CustomViewControls

import { capitalize, last } from 'lodash'

import { sentenceCase } from 'sentence-case'

import { BaseTransaction, Transaction, TransactionDirection } from 'actions/transactionsActions'
import { DownloadableFile, DownloadableFileType } from 'actions/viewActions'
import { contentTypeForReviewDocument } from 'components/attachment/helpers'
import { displayTypeOfEntry, LibraryGqlQueryType } from 'components/entities/LibraryQueries'

import { SearchAutomationRuleExecutionsQuery } from 'dashboards/automationRuleExecutions/gql/__generated__/searchAutomationRuleExecutions.queries.generated'
import {
  CaseFileAttachmentFragment,
  CaseFileInformationRequestResponseFragment,
  CaseFileReviewDocumentFragment,
  CaseFileSurveyResponseFileUploadFragment,
} from 'dashboards/caseFiles/gql/__generated__/searchFiles.fragments.generated'
import { SearchFilesQuery } from 'dashboards/caseFiles/gql/__generated__/searchFiles.queries.generated'
import { SearchCustomFieldsQuery } from 'dashboards/customFields/gql/__generated__/searchCustomFields.queries.generated'
import { SearchFilingsV2Query } from 'dashboards/filings/gql/__generated__/searchFilings.queries.generated'
import { SearchInformationRequestsQuery } from 'dashboards/informationRequests/gql/__generated__/searchInformationRequests.queries.generated'
import { SearchProfilesQuery } from 'dashboards/profiles/gql/__generated__/searchProfiles.queries.generated'
import { SearchReviewsV3Query } from 'dashboards/reviews/gql/__generated__/searchReviews.queries.generated'
import { SearchTransactionsQuery } from 'dashboards/transactions/gql/__generated__/searchTransactions.queries.generated'

import { DateFormatter } from 'hooks/DateFormatHooks'
import { DashboardEntry, ReviewApprovalAssignment } from 'reducers/reviewsReducer'
import {
  InformationRequestStatusEnum,
  TransactionEndpointTypeEnum,
  AutomationRuleExecutionStatus,
  AutomationEvent,
  OtherInfoLabelDisplayAsEnum,
  CustomFieldDatatypeEnum,
  OtherInfoLabelTypeEnum,
  SearchFilesResult,
  GeneratedDocumentType,
} from 'types/api'
import { assertExhaustive } from 'utils/typeAssertions'

export type ReviewKeyedDashboardEntry = DashboardEntry & { reviewToken: string }

// TODO: use the value path to map these values
//  there will still need to be a mapper, but it will be more resilient to API
//  updates
export const mapEntries = (raw?: SearchReviewsV3Query['searchReviewsV3']): Array<ReviewKeyedDashboardEntry> =>
  raw?.entries?.map<ReviewKeyedDashboardEntry>((review) => {
    const entry: ReviewKeyedDashboardEntry = {
      commentCount: review.investigation.commentCount,
      inboundRequest: review.inboundRequest,
      createdAt: review.createdAt,
      updatedAt: review.updatedAt,
      investigationToken: review.investigation.token,
      investigationName: review.investigation.name,
      investigationBlocked: review.investigation.blocked,
      investigationTags: review.investigation.taggedWith,
      netActivity: review.netActivity,
      reviewToken: review.token,
      sumAllTransactions: review.investigation.transactionStatistics.netActivity,
      sumFlaggedTransactions: review.investigation.transactionStatistics.sumFlaggedTransactions,
      approvalAssignments: review.approvalAssignments as ReviewApprovalAssignment[],
      reviews: [
        {
          assignee: review.assignee || undefined,
          approvalAssignments: review.approvalAssignments || [],
          recentAlertExternalId: review.recentAlertExternalId,
          alertCount: review.alertCount,
          queue: review.queue || undefined,
          completedAt: review.completedAt,
          displayState: review.displayState,
          internalControlNumber: review.internalControlNumber,
          openedAt: review.openedAt,
          reviewDueAt: review.dueAt,
          firstActivityAt: review.firstActivityAt,
          status: review.status,
          stage: review.stage,
          state: review.state,
          tags: review.tags,
          token: review.token,
          triggeredAt: review.triggeredAt,
          triggers: review.alertSummary,
          type: review.reviewType.name,
          otherInfo: review.otherInfo,
          openAt: review.openAt || '',
          isCompleted: review.isCompleted,
          filingStatusString: review.filingStatusString || '',
        },
      ],
    }

    return entry
  }) ?? []

export interface TodoProfilesDashboardEntry {
  token: string
  name: string
  type: LibraryGqlQueryType
  displayType: string
  tags: NonNullable<NonNullable<SearchProfilesQuery['searchProfiles']>['entries']>[number]['tags']
  createdAt: string
  updatedAt: string
  alertsCount: number
  attachmentsCount: number
  devicesCount: number
  subAccountsCount: number
  casesCount: number
  otherInfo: { label: string; value: string }[]
}

export const mapProfilesEntries = (raw?: SearchProfilesQuery['searchProfiles']): Array<TodoProfilesDashboardEntry> =>
  raw?.entries?.map((profile) => {
    const entry = {
      token: profile.token,
      name: profile.displayName,
      type: profile.type as LibraryGqlQueryType,
      displayType: displayTypeOfEntry(profile),
      tags: profile.tags,
      createdAt: profile.createdAt,
      updatedAt: profile.updatedAt,
      alertsCount: profile.alertsCount,
      attachmentsCount: profile.attachmentsCount,
      subAccountsCount: profile.subAccountsCount,
      devicesCount: profile.devicesCount,
      casesCount: profile.relatedCases?.totalCount ?? 0,
      otherInfo: [],
    }

    return entry
  }) ?? []

export interface InformationRequestsDashboardEntry {
  token: string
  title: string
  type: string
  createdBy: string
  createdAt: string
  updatedAt: string
  completedBy?: string
  completedAt?: string | null
  cancelledBy?: string
  cancelledAt?: string | null
  lastRespondedAt?: string | null
  dueAt: string
  status: InformationRequestStatusEnum
  recipients: string[]
  recipientEmails: string[]
  subscriberEmails: string[]
  formType: string
  caseToken: string
  caseNames: string[]
}

export const mapInformationRequestEntries = (
  raw?: SearchInformationRequestsQuery['searchInformationRequests']
): Array<InformationRequestsDashboardEntry> =>
  raw?.entries?.map((request) => {
    const entry = {
      token: request.token,
      title: request.title,
      type: 'RFI', // TODO replace with API value when available
      createdBy: request.createdBy.fullName,
      createdAt: request.createdAt,
      updatedAt: request.updatedAt,
      completedAt: request.completedAt,
      completedBy: request.completedBy?.fullName,
      cancelledAt: request.cancelledAt,
      cancelledBy: request.cancelledBy?.fullName,
      lastRespondedAt: request.lastRespondedAt,
      status: request.status,
      dueAt: request.dueAt,
      recipients: request.informationRequestRecipients.map(({ name }) => name),
      recipientEmails: request.informationRequestRecipients.map(({ email }) => email),
      subscriberEmails: request.informationRequestSubscribers.map(({ email }) => email),
      formType: request.informationRequestForm.title,
      caseToken: request.investigations[0]?.token,
      caseNames: request.investigations.map((i) => i.name ?? '') ?? [''],
    }

    return entry
  }) ?? []

const isTransaction = (t: Transaction | null): t is Transaction => !!t

export const mapTransaction = (
  transaction: SearchTransactionsQuery['searchTransactions']['entries'][number]
): BaseTransaction | null => {
  if (!transaction) {
    return null
  }
  const sourceEndpoint = transaction.endpoints.find((e) => e.endpointType === TransactionEndpointTypeEnum.Source)
  const destinationEndpoint = transaction.endpoints.find(
    (e) => e.endpointType === TransactionEndpointTypeEnum.Destination
  )

  // Convert the GQL Transaction to a legacy Transaction type to store in redux.
  // Use BaseTransaction to get some small degree of type safety from Typescript here.
  // This should all go away when the transactions pages use GraphQL and it isn't in redux.

  if (!transaction.completedAt && !transaction.initiatedAt) {
    // We don't expect to ever hit this case, but this check helps TS orient itself
    // github.com/Hummingbird-RegTech/hummingbird-rails/commit/23909addfd5986e08b9f526001d08546ad3e442d#diff-2697d8aeebf768afcf9dc5e375dc2cb2fe1bc8c2e2bbd2a00e8362fbd780195bR76
    return null
  }
  const mappedtransactionBase: BaseTransaction = {
    ...transaction,
    otherInfo: transaction.otherInfo.map((entry) => ({
      ...entry,
      displayAs: entry.displayAs ?? OtherInfoLabelDisplayAsEnum.OtherInfo,
      datatype: entry.datatype ?? CustomFieldDatatypeEnum.Text,
      options: entry.options ?? [],
    })),
    // FIXME: use the GQL enum everywhere instead of this upcase/downcase business
    direction: (transaction.direction?.toLowerCase() as TransactionDirection) || '',
    events: transaction.events.map(
      (e: SearchTransactionsQuery['searchTransactions']['entries'][number]['events'][number]) => ({
        ...e,
        occurredAt: e.occurredAt?.string,
        occurredAtParts: e.occurredAt,
      })
    ),
    importToken: transaction.import?.token || null,
    tagString: transaction.tags.join(', '),
    timestamp: transaction.timestamp.timestamp,
    timestampParts: transaction.timestamp,
    transactionId: transaction.externalId || transaction.token,
    destinationAmount: destinationEndpoint?.amount || null,
    destinationName: destinationEndpoint?.name || null,
    destinationSummary: destinationEndpoint?.summary || null,
    sourceAmount: sourceEndpoint?.amount || null,
    sourceName: sourceEndpoint?.name || null,
    sourceSummary: sourceEndpoint?.summary || null,
    senderName: sourceEndpoint?.entityName || null,
    receiverName: destinationEndpoint?.entityName || null,
    directionDescription: capitalize(transaction.direction?.toString()) || '',
    completedAtParts: transaction.completedAt,
    initiatedAtParts: transaction.initiatedAt,
  }
  return mappedtransactionBase
}
// TODO: replace with dynamic data when available
export type TransactionsDashboardEntry = Transaction
export const mapTransactionsEntries = (
  raw?: SearchTransactionsQuery['searchTransactions']
): TransactionsDashboardEntry[] => {
  return raw?.entries.map(mapTransaction).filter(isTransaction) ?? []
}

export interface FilingsReportDashboardEntry {
  activityStartDate: string | null
  activityEndDate: string | null
  amountInvolved?: string | null
  assignee?: string | null
  reportId?: string | null
  filedAt: string
  filer?: string | null
  filingInstitution?: string
  filingName: string
  filingType?: string
  internalControlNumber?: string | null
  jurisdiction: string
  status?: string | null
  token: string
  updatedAt: string
  investigationToken: string
  reviewToken: string
  subdivisions?: Array<string> | null
}

export const mapFilingsReportEntries = (
  raw?: SearchFilingsV2Query['searchFilingsV2']
): Array<FilingsReportDashboardEntry> =>
  raw?.entries?.map((filing) => {
    const {
      createdAt,
      currentStateString,
      token,
      response,
      investigationReview,
      updatedAt,
      filingInstitution,
      subdivisions,
    } = filing
    const {
      activityEndedOnFromAlgorithm,
      activityStartedOnFromAlgorithm,
      assignee,
      filingType,
      filingName,
      amountInvolvedFromAlgorithmString,
      jurisdiction,
      token: reviewToken,
      investigation,
      internalControlNumber,
      sarFilingDecision,
    } = investigationReview

    const { token: investigationToken } = investigation

    const entry = {
      activityStartDate: activityStartedOnFromAlgorithm,
      activityEndDate: activityEndedOnFromAlgorithm,
      amountInvolved: amountInvolvedFromAlgorithmString,
      assignee: assignee?.fullName,
      reportId: response?.bsaId,
      filedAt: createdAt,
      filer: sarFilingDecision?.arbiter.fullName,
      filingInstitution: filingInstitution.name,
      filingName,
      filingType: sentenceCase(filingType || ''),
      internalControlNumber,
      jurisdiction,
      status: currentStateString,
      token,
      investigationToken,
      reviewToken,
      updatedAt,
      subdivisions,
    }
    return entry
  }) ?? []

export interface AutomationExecutionsDashboardEntry {
  token: string
  status: AutomationRuleExecutionStatus
  ruleName: string
  // An Execution can have a null event when the
  // parent rule is schedule-w/o-trigger-based
  event: AutomationEvent | null
  createdAt: string
  details: string | null
}

export const mapAutomationExecutionsEntries = (
  raw?: SearchAutomationRuleExecutionsQuery['searchAutomationRuleExecutions']
): Array<AutomationExecutionsDashboardEntry> =>
  raw?.entries?.map((execution) => {
    const entry = {
      token: execution.token,
      status: execution.status,
      ruleName: execution.ruleName,
      event: execution.event,
      createdAt: execution.createdAt,
      details: execution.details,
    }

    return entry
  }) ?? []

export interface CustomFieldsDashboardEntry {
  token: string
  label: string
  type: OtherInfoLabelTypeEnum
  datatype: CustomFieldDatatypeEnum
  displayAs: OtherInfoLabelDisplayAsEnum
  dashboardVisible: boolean
  hidden: boolean
  managed: boolean
  description: string
  createdAt: string
  updatedAt: string
}

export const mapCustomFieldsEntries = (
  raw?: SearchCustomFieldsQuery['searchCustomFields']
): Array<CustomFieldsDashboardEntry> =>
  raw?.entries?.map((custom_field) => {
    const entry = {
      token: custom_field.token,
      label: custom_field.label,
      type: custom_field.type,
      displayAs: custom_field.displayAs,
      dashboardVisible: custom_field.dashboardVisible || false,
      hidden: custom_field.hidden || false,
      managed: custom_field.managed || false,
      datatype: custom_field.datatype,
      description: custom_field.description || '',
      createdAt: custom_field.createdAt,
      updatedAt: custom_field.updatedAt,
    }
    return entry
  }) ?? []

/**
 * ---------------------------------------------------------------
 * Case Files Dashboard
 * ---------------------------------------------------------------
 */

interface BaseSearchFilesDashboardEntry {
  createdBy: string
  contentType: string | undefined
  description: string | null
  displayName: string
  documentType: GeneratedDocumentType
  format: string
  meta: Record<string, unknown> & { downloadData: DownloadableFile }
  timestamp: string
  token: string
  typeName: NonNullable<SearchFilesResult['entries'][number]['__typename']>
}

export interface AttachmentSearchFilesDashboardEntry extends BaseSearchFilesDashboardEntry {
  contentType: string
  typeName: 'Attachment'
}

export interface InformationRequestResponseSearchFilesDashboardEntry extends BaseSearchFilesDashboardEntry {
  contentType: undefined
  typeName: 'InformationRequestResponse'
}

export interface ReviewDocumentSearchFilesDashboardEntry extends BaseSearchFilesDashboardEntry {
  contentType: string
  typeName: 'ReviewDocument'
  meta: {
    downloadData: DownloadableFile
    reviewToken: string
  }
}

export interface SurveyResponseFileUploadSearchFilesDashboardEntry extends BaseSearchFilesDashboardEntry {
  contentType: string
  typeName: 'SurveyResponseFileUpload'
  meta: {
    clientId: string
    downloadData: DownloadableFile
    questionToken: string
  }
}

export type SearchFilesDashboardEntry =
  | AttachmentSearchFilesDashboardEntry
  | InformationRequestResponseSearchFilesDashboardEntry
  | ReviewDocumentSearchFilesDashboardEntry
  | SurveyResponseFileUploadSearchFilesDashboardEntry

export type SearchFileResult = SearchFilesQuery['searchFiles']['entries'][number]
const assertTypeName = (caseFile: SearchFileResult): caseFile is SearchFileResult & { __typename: string } =>
  !!caseFile.__typename

const DATE_FORMAT = 'MMM. Do, YYYY'

const getFormatFromContentType = (contentType: string) => last(contentType.split('/')) ?? ''

type CaseFileAttachment = CaseFileAttachmentFragment & {
  __typename: NonNullable<CaseFileAttachmentFragment['__typename']>
}

const mapAttachment = (attachment: CaseFileAttachment, formatDate: DateFormatter) => {
  const format = getFormatFromContentType(attachment.contentType)
  return {
    contentType: attachment.contentType,
    createdBy: attachment.uploadedBy.fullName,
    description: `Uploaded by ${attachment.uploadedBy.fullName} on ${formatDate(
      attachment.uploadedAt,
      '',
      DATE_FORMAT
    )}`,
    displayName: attachment.filename,
    documentType: GeneratedDocumentType.InvestigationAttachment,
    meta: {
      downloadData: {
        downloadFileType: DownloadableFileType.Attachment,
        urlName: 'prepareDownloadApiInvestigationAttachmentPath',
        tokenData: {
          // TODO: include associated investigationToken in response
          investigationToken: '',
          token: attachment.token,
        },
      },
    },
    format,
    timestamp: attachment.uploadedAt,
    token: attachment.token,
    typeName: attachment.__typename,
  }
}

type CaseFileInformationRequestResponse = CaseFileInformationRequestResponseFragment & {
  __typename: NonNullable<CaseFileInformationRequestResponseFragment['__typename']>
}

// TODO: include parent InformationRequest fields in GQL response:
// https://thecharm.atlassian.net/browse/PROD-18801
const mapInformationRequestResponse = (response: CaseFileInformationRequestResponse, formatDate: DateFormatter) => {
  const createdBy = response.informationRequestRecipient.email
  const { createdAt } = response.surveyResponse

  // TODO: use derived values from request data
  const title = 'Request title'
  const formTitle = 'Request form title'
  return {
    contentType: undefined,
    createdBy,
    description: `Response from ${createdBy} on ${formatDate(createdAt, '', DATE_FORMAT)}`,
    displayName: `Request For Information Response - ${title || response.token} - ${formTitle}`,
    documentType: GeneratedDocumentType.InformationRequestResponse,
    format: 'zip',
    meta: {
      downloadData: {
        downloadFileType: DownloadableFileType.InformationRequestResponse,
        urlName: '', // Empty - This is generated the 'generate-information-request-response' page
        tokenData: {
          token: response.token,
          // TODO: include caseToken in GQL response
          // https://thecharm.atlassian.net/browse/PROD-18801
          caseToken: '',
        },
      },
    },
    timestamp: createdAt,
    token: response.token,
    typeName: response.__typename,
  }
}

type CaseFileReviewDocument = CaseFileReviewDocumentFragment & {
  __typename: NonNullable<CaseFileReviewDocumentFragment['__typename']>
}

export const TRANSACTION_CSV_NAME = 'Transactions CSV'
const isTransactionCsvDocument = (title: string) => title === TRANSACTION_CSV_NAME || title === 'Transactions CSV All'

const getReviewDocumentDescription = (document: CaseFileReviewDocument) => {
  const isInvestigationDocument = isTransactionCsvDocument(document.title)
  return isInvestigationDocument ? 'Auto-generated by Hummingbird on demand' : document.description
}

const getReviewDocumentDisplayName = (document: CaseFileReviewDocument) => {
  const displayName = document.title === 'Case Data Summary' ? 'Review Data Summary' : document.title
  // TODO: append the review ICN | token, if not a case-level document
  return displayName
}

// TODO: include derived fields in GQL response
// for attachedTo, i.e. review | investigation
// https://thecharm.atlassian.net/browse/PROD-18808
const mapReviewDocument = (document: CaseFileReviewDocument) => {
  const description = getReviewDocumentDescription(document)
  const displayName = getReviewDocumentDisplayName(document)
  return {
    contentType: contentTypeForReviewDocument(document.type),
    createdBy: 'Hummingbird',
    description,
    displayName,
    documentType: document.type,
    format: document.format,
    meta: {
      downloadData: {
        downloadFileType: DownloadableFileType.Summary, // TODO this is incorrect, it could be other generated review documents
        urlName: document.path,
      },
      // TODO: incorporate reviewToken
      // https://thecharm.atlassian.net/browse/PROD-18808
      reviewToken: '',
    },
    // TODO: use `attachedTo.updatedAt` timestamp
    // https://thecharm.atlassian.net/browse/PROD-18808
    timestamp: new Date().toISOString(),
    token: document.token,
    typeName: document.__typename,
  }
}

// TODO: include derived fields in GQL response:
// for attachedTo, i.e. review
// https://thecharm.atlassian.net/browse/PROD-18802
type CaseFileSurveyResponseFileUpload = CaseFileSurveyResponseFileUploadFragment & {
  __typename: NonNullable<CaseFileSurveyResponseFileUploadFragment['__typename']>
}
const mapSurveyResponseFileUpload = (upload: CaseFileSurveyResponseFileUpload) => {
  const format = getFormatFromContentType(upload.contentType)
  return {
    contentType: upload.contentType,
    createdBy: '',
    description: 'File uploaded through Review Task',
    displayName: upload.title,
    documentType: GeneratedDocumentType.SurveyFile,
    format,
    meta: {
      clientId: upload.clientId,
      downloadData: {
        downloadFileType: DownloadableFileType.SurveyUpload,
        urlName: '', // Empty - This is generated by s3 via pre-sign
        tokenData: {
          questionToken: upload.question.token,
          clientId: upload.clientId,
        },
      },
      questionToken: upload.question.token,
    },
    // TODO: use `review.updatedAt` timestamp
    timestamp: new Date().toISOString(),
    token: upload.token,
    typeName: upload.__typename,
  }
}

export const mapCaseFilesEntries = (
  raw: SearchFilesQuery['searchFiles'] | undefined,
  formatDate: DateFormatter
): SearchFilesDashboardEntry[] =>
  raw?.entries.filter(assertTypeName).map((caseFile): SearchFilesDashboardEntry => {
    switch (caseFile.__typename) {
      case 'Attachment': {
        return mapAttachment(caseFile, formatDate)
      }
      case 'InformationRequestResponse': {
        return mapInformationRequestResponse(caseFile, formatDate)
      }
      case 'ReviewDocument': {
        return mapReviewDocument(caseFile)
      }
      case 'SurveyResponseFileUpload': {
        return mapSurveyResponseFileUpload(caseFile)
      }
      default:
        return assertExhaustive(caseFile)
    }
  }) ?? []

import {
  setCurrentAccount,
  setFlashNotice,
  setOrganizations,
  updateFilingInstitutions,
} from 'actions/applicationActions'
import { clearFormError, clearFormErrorDismissal, setFlashError, setFormErrorMessage } from 'actions/errorActions'
import { setLoading } from 'actions/viewActions'
import { FilingInstitution } from 'reducers/applicationReducer'
import { EmailSetting, History } from 'reducers/settingsReducer'

import { AsyncThunk } from './store'

export type FilingInstitutionErrors = {
  [Prop in keyof FilingInstitution]?: string[]
}

export const SET_HISTORY = 'SET_HISTORY'
export const SET_MFA_PROVISIONING_URI = 'SET_MFA_PROVISIONING_URI'
export const SET_EMAIL_NOTIFICATION_SETTINGS = 'SET_EMAIL_NOTIFICATION_SETTINGS'
export const UPDATE_EMAIL_NOTIFICATION_SETTING = 'UPDATE_EMAIL_NOTIFICATION_SETTING'
export const SET_FILING_INSTITUTION_ERRORS = 'SET_FILING_INSTITUTION_ERRORS'

export function setHistory(history: History) {
  return { type: SET_HISTORY, history }
}

export function setMFAProvisioningURI(uri: string | null) {
  return { type: SET_MFA_PROVISIONING_URI, uri }
}

export function setEmailNotificationSettings(settings: EmailSetting[]) {
  return { type: SET_EMAIL_NOTIFICATION_SETTINGS, settings }
}

export function setFilingInstitutionErrors(errors: FilingInstitutionErrors) {
  return { type: SET_FILING_INSTITUTION_ERRORS, errors }
}

export function updateEmailNotificationSetting(topic: string, setting: 'all' | 'none') {
  return { type: UPDATE_EMAIL_NOTIFICATION_SETTING, topic, setting }
}

export function fetchProvisioningURI(): AsyncThunk<string> {
  return (dispatch, getState, { api }) => {
    dispatch(setMFAProvisioningURI(null))
    dispatch(setLoading(true, 'mfaSetup'))
    return api.get('mfaProvisioningURIApiAccountPath').then((json) => {
      dispatch(setLoading(false, 'mfaSetup'))

      if (json.success) {
        dispatch(setMFAProvisioningURI(json.uri))
        return json.uri
      }
      return null
    })
  }
}

export const SETTINGS_HISTORY_LOADING = 'settingsHistory'
type HistoryQuery = { page?: number; pageSize?: number }
export function fetchHistory({ page, pageSize }: HistoryQuery = {}): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    const query: HistoryQuery = {}

    if (page) {
      query.page = page
    }
    if (pageSize) {
      query.pageSize = pageSize
    }

    dispatch(setLoading(true, SETTINGS_HISTORY_LOADING))
    return api.get('apiHistoryPath', { query }).then((json) => {
      dispatch(setLoading(false, SETTINGS_HISTORY_LOADING))
      if (json.success) {
        const historyData = { ...json.history, lastUpdatedAt: Date.now() }
        dispatch(setHistory(historyData))
      } else {
        // TODO: This shouldnt happen?
      }
    })
  }
}

export function saveAccount(data: any): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'accountSettings'))
    dispatch(clearFormError('accountSettings'))
    dispatch(clearFormErrorDismissal('accountSettings'))
    return api.put('apiAccountPath', { data: { account: data } }).then((json) => {
      dispatch(setLoading(false, 'accountSettings'))
      if (json) {
        if (json.notice) {
          dispatch(setFlashNotice(json.notice))
        }
        if (json.success) {
          dispatch(setCurrentAccount(json.account))
        } else if (json.error) {
          dispatch(setFormErrorMessage(json.error.message, 'accountSettings'))
        }
      }
    })
  }
}

export function saveOrganization(token: string, data: any): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'organizationSettings'))
    return api
      .put('apiOrganizationPath', {
        urlParams: { token },
        data: { organization: data },
      })
      .then((json) => {
        dispatch(setLoading(false, 'organizationSettings'))
        if (json.success) {
          dispatch(clearFormError('organizationSettings'))
          dispatch(setOrganizations(json.organizations))
          dispatch(setFlashNotice('Organization updated!'))
        } else {
          dispatch(setFlashError(json.error.message))
        }
      })
  }
}

export function saveFilingInstitution(
  organizationToken: string,
  token: string,
  data: FilingInstitution
): AsyncThunk<any> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'filingInstitutionSettings'))
    const json = await api.put('apiOrganizationFilingInstitutionPath', {
      urlParams: { organizationToken, token },
      data: { filingInstitution: data },
    })
    dispatch(setLoading(false, 'filingInstitutionSettings'))
    if (json.success) {
      dispatch(clearFormError('filingInstitutionSettings'))
      dispatch(updateFilingInstitutions([json.filingInstitution]))
      dispatch(setFlashNotice('Filing institution updated!'))
    } else {
      dispatch(setFilingInstitutionErrors(json.object || {}))
      dispatch(setFlashError(json.error.message))
    }

    return json
  }
}

export function saveOutOfOffice({ startsAt, endsAt }: { startsAt: Date; endsAt: Date }): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'accountSettings'))
    dispatch(clearFormError('accountSettings'))
    dispatch(clearFormErrorDismissal('accountSettings'))
    const json = await api.post('apiOutOfOfficeIntervalsPath', {
      data: {
        outOfOfficeInterval: {
          startsAt: new Date(Math.max(startsAt.valueOf(), new Date().valueOf())),
          endsAt,
        },
      },
    })
    dispatch(setLoading(false, 'accountSettings'))
    if (json) {
      if (json.notice) {
        dispatch(setFlashNotice(json.notice))
      }
      if (json.success) {
        dispatch(setCurrentAccount(json.account))
      } else if (json.error) {
        dispatch(setFormErrorMessage(json.error.message, 'accountSettings'))
      }
    }
  }
}

export function deleteOutOfOffice(token: string): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'accountSettings'))
    dispatch(clearFormError('accountSettings'))
    dispatch(clearFormErrorDismissal('accountSettings'))
    const json = await api.delete('apiOutOfOfficeIntervalPath', {
      urlParams: { token },
    })
    dispatch(setLoading(false, 'accountSettings'))
    if (json) {
      if (json.notice) {
        dispatch(setFlashNotice(json.notice))
      }
      if (json.success) {
        dispatch(setCurrentAccount(json.account))
      } else if (json.error) {
        dispatch(setFormErrorMessage(json.error.message, 'accountSettings'))
      }
    }
  }
}

export function fetchEmailNotificationSettings(): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'emailSettings'))
    const json = await api.get('apiAccountEmailSettingsPath')
    dispatch(setLoading(false, 'emailSettings'))
    if (json) {
      if (json.notice) {
        dispatch(setFlashNotice(json.notice))
      }
      if (json.success) {
        dispatch(setEmailNotificationSettings(json.settings))
      } else if (json.error) {
        dispatch(setFlashError(json.error.message))
      }
    }
  }
}

export function saveEmailNotificationSetting({ topic, active }: { topic: string; active: boolean }): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    const setting = active ? 'all' : 'none'
    dispatch(updateEmailNotificationSetting(topic, setting))
    dispatch(setLoading(true, 'emailSettings'))
    const json = await api.put('apiAccountEmailSettingPath', {
      urlParams: { topic },
      data: { setting },
    })
    dispatch(setLoading(false, 'emailSettings'))
    if (json) {
      if (json.notice) {
        dispatch(setFlashNotice(json.notice))
      }
      if (json.success) {
        dispatch(setEmailNotificationSettings(json.settings))
      } else if (json.error) {
        dispatch(setFlashError(json.error.message))
      }
    }
  }
}

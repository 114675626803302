import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const ContentCopyIcon: FC<SvgProps> = (props) => (
  <SvgIcon height="20" width="20" {...props} viewBox="-2 -2 24 24">
    <path
      d="M13.75 0.833344H3.74999C2.83333 0.833344 2.08333 1.58334 2.08333 2.50001V14.1667H3.74999V2.50001H13.75V0.833344ZM16.25 4.16668H7.08333C6.16666 4.16668 5.41666 4.91668 5.41666 5.83334V17.5C5.41666 18.4167 6.16666 19.1667 7.08333 19.1667H16.25C17.1667 19.1667 17.9167 18.4167 17.9167 17.5V5.83334C17.9167 4.91668 17.1667 4.16668 16.25 4.16668ZM16.25 17.5H7.08333V5.83334H16.25V17.5Z"
      fill="black"
    />
  </SvgIcon>
)

export default ContentCopyIcon

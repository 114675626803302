import { useRef } from 'react'

import { MoreVert } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { ExportCsvButton } from 'dashboards/shared/components/DashboardExport/ExportCsvButton'
import { usePollExportJob } from 'dashboards/shared/components/DashboardExport/usePollExportJob'
import { useToggle } from 'hooks'
import { Theme } from 'types/hb'

// TODO: Create an Hb dropdown menu component
const useStyles = makeStyles((theme: Theme) => ({
  menuTrigger: {
    margin: theme.spacing(0, 1, 0, -1),
    padding: theme.spacing(),
    '& svg': {
      width: `${theme.spacing(2.5)} !important`,
      height: `${theme.spacing(2.5)} !important`,
    },
  },
  menu: {
    width: 320,
    marginTop: theme.spacing(2),
  },
}))

export const ExportDropdownMenu = () => {
  const classes = useStyles()
  const ref = useRef<HTMLButtonElement>(null)
  const { value: isOpen, toggle, setValue: setIsOpen } = useToggle(false)
  // note that whenever the export dialog is re-opened,
  // we want to reset the `usePollExportJob` hook
  // so that the user can initiate a new export
  const { pollJobStatusWithTimeout } = usePollExportJob()

  const handleClose = () => {
    setIsOpen(false)
  }

  const id = isOpen ? 'action-menu' : undefined

  return (
    <>
      <HbButton
        className={classes.menuTrigger}
        onClick={toggle}
        ref={ref}
        aria-expanded={isOpen}
        Icon={MoreVert}
        label={isOpen ? 'Hide action menu' : 'Show action menu'}
        variant="textSecondary"
        size="small"
        iconOnly
      />
      <HbPopper
        id={id || ''}
        onClose={handleClose}
        isOpen={isOpen}
        anchorEl={ref?.current}
        placement="bottom-end"
        classes={{ content: classes.menu }}
      >
        <span role="menuitem">
          <ExportCsvButton closeMenu={handleClose} startPolling={pollJobStatusWithTimeout} />
        </span>
      </HbPopper>
    </>
  )
}

import React from 'react'

import { gql } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import pluralize from 'pluralize'

import { useDispatch } from 'actions/store'
import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'
import { HbTag } from 'components/HbComponents/HbTag'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useFeatureFlag } from 'hooks'
import { OtherInfoIcon } from 'icons/OtherInfoIcon'
import { openTab } from 'reducers/tabReducer'
import { FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'
import { shouldOpenNewTab } from 'utils/navigationHelpers'

import { OverviewCard } from './OverviewCard'
import { OtherInfoCardFragment } from './__generated__/OtherInfoCard.generated'

const MAX_PREVIEW_ITEMS = 4

interface Props {
  investigation?: OtherInfoCardFragment | null
}

const useStyles = makeStyles((theme: Theme) => ({
  list: {
    marginTop: theme.spacing(3),
  },
  listItem: {
    backgroundColor: theme.palette.styleguide.backgroundMedium,
    display: 'grid',
    gridTemplateColumns: '1fr auto',
    columnGap: theme.spacing(),
    padding: theme.spacing(1.5, 1.75),
    marginTop: theme.spacing(),
    borderRadius: theme.shape.borderRadius,
  },
  numEntriesText: {
    textAlign: 'right',
    background: theme.palette.styleguide.backgroundDark,
  },
}))

export function OtherInfoCard({ investigation }: Props) {
  const reviews = investigation?.reviews
  const classes = useStyles()
  const dispatch = useDispatch()
  const usage = useUsage()

  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)

  const onCardClick = (e: React.MouseEvent) => {
    const openInBackground = shouldOpenNewTab(e)
    usage.logEvent({ name: 'case:openOtherInfo:clicked' })
    dispatch(
      openTab({
        tab: { type: 'otherInfo' },
        meta: { featureFlags: { RenameOtherInfo: isRenameOtherInfoEnabled } },
        openInBackground,
      })
    )
  }

  if (!reviews?.some((r) => !!r.otherInfo.length)) {
    return (
      <OverviewCard
        Icon={OtherInfoIcon}
        subtitle={isRenameOtherInfoEnabled ? 'Additional Details' : 'Other Information'}
        onClick={onCardClick}
      >
        <HbNonIdealState
          Icon={OtherInfoIcon}
          title={`No ${isRenameOtherInfoEnabled ? 'additional details' : 'other information'}`}
          message={`${isRenameOtherInfoEnabled ? 'Additional details' : 'Other information'} will show here`}
        />
      </OverviewCard>
    )
  }

  const totalEntries = reviews.reduce((acc, next) => acc + (next.otherInfo?.length || 0), 0)

  return (
    <OverviewCard
      Icon={OtherInfoIcon}
      title={`${totalEntries} Entries`}
      subtitle={isRenameOtherInfoEnabled ? 'Additional Details' : 'Other Information'}
      onClick={onCardClick}
    >
      <ul className={classes.list}>
        {reviews
          ?.slice(0, MAX_PREVIEW_ITEMS)
          .filter((r) => !!r.otherInfo.length)
          .map(({ otherInfo, internalControlNumber, token }) => (
            <li key={token} className={classes.listItem}>
              <HbText color="primary" noWrap>
                {`#${internalControlNumber || token}`}
              </HbText>
              <HbTag
                themeColor="ivory"
                className={classes.numEntriesText}
                label={`${otherInfo?.length || 0} ${pluralize('entry', otherInfo?.length || 0)}`}
              />
            </li>
          ))}
      </ul>
    </OverviewCard>
  )
}

OtherInfoCard.fragments = {
  entry: gql`
    fragment OtherInfoCard on Investigation {
      token
      reviews {
        ... on Review {
          token
          internalControlNumber
          otherInfo {
            ... on OtherInfoEntry {
              label
              token
              value
            }
          }
        }
      }
    }
  `,
}

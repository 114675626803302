import { gql } from '@apollo/client'

import { AssigneeAvatar } from 'components/cases/reviews/assignment/AssigneeAvatar'
import { queueDetailsFragment } from 'components/cases/reviews/queues/fragments'
import { TAG_FRAGMENT } from 'components/tags/Tags.queries'

export const SearchReviewResultV2Fragment = gql`
  fragment SearchReviewResultV2Fragment on SearchReviewsResultV2 {
    entries {
      token
      alertCount
      alertSummary
      approvalAssignments {
        assignee {
          ...AccountAvatarDetails
        }
        decision
        queue {
          ...QueueDetails
        }
        token
      }
      recentAlertExternalId @include(if: $includeRecentAlertExternalId)
      internalControlNumber
      displayState
      state
      stage
      filingStatusString
      tags
      createdAt
      firstActivityAt
      status
      openAt
      openedAt
      updatedAt
      triggeredAt
      dueAt
      completedAt
      isCompleted
      assignee {
        ...AccountAvatarDetails
      }
      reviewType {
        token
        name
      }
      netActivity {
        amount
        formatted
        currency
        isoCurrency
      }
      investigation {
        name
        token
        commentCount
        blocked
        taggedWith {
          ...ObjectTag
        }
        transactionStatistics {
          netActivity {
            amount
            formatted
            currency
            isoCurrency
          }
          sumFlaggedTransactions {
            amount
            formatted
            currency
            isoCurrency
          }
        }
      }
      queue {
        ...QueueDetails
      }
      inboundRequest {
        token
      }
      otherInfo {
        label
        value
      }
    }
    page
    pageSize
    pageCount
    totalCount
  }
  ${AssigneeAvatar.fragments.assignee}
  ${queueDetailsFragment}
  ${TAG_FRAGMENT}
`

import { createAsyncThunk } from '@reduxjs/toolkit'

import { setFlashError } from 'actions/errorActions'
import { State, ThunkContext } from 'actions/store'
import { PollNarrativeGenerationDocument } from 'components/cases/Tabs/AI/ReviewNarrativeGeneration/graphql/__generated__/PollNarrativeGeneration.generated'
import { narrativeAISelectors } from 'reducers/narrativeAI/narrativeAI.selectors'
import { wait } from 'reducers/utils'
import { JobStatus, ReviewNarrativeGenerationJob } from 'types/api'

import { assertExhaustive } from 'utils/typeAssertions'

import { ApiJobByReviewParams, NarrativeAIGeneration, ReviewTokenParam } from './narrativeAI.types'

type Prefix<T extends string> = `narrativeAI/${T}`
const makeName = <T extends string>(name: T) => `narrativeAI/${name}` satisfies Prefix<T>

const stopPolling = createAsyncThunk<void, ReviewTokenParam, { state: State }>(
  makeName('generation/stopPolling'),
  async ({ reviewToken }, { getState }) => {
    const timeoutId = narrativeAISelectors.generation.job.timeoutId({ reviewToken })(getState())

    if (timeoutId) clearTimeout(timeoutId)
  }
)

const remove = createAsyncThunk<void, ReviewTokenParam, { state: State }>(
  makeName('generation/remove'),
  async ({ reviewToken }, { dispatch }) => {
    dispatch(stopPolling({ reviewToken }))
  }
)

const update = createAsyncThunk<ReviewNarrativeGenerationJob, ApiJobByReviewParams, { state: State }>(
  makeName('generation/update'),
  async ({ reviewToken, job }, { dispatch }) => {
    const { status } = job
    switch (status) {
      case JobStatus.Enqueued:
      case JobStatus.Started:
        break
      case JobStatus.Failed:
        dispatch(stopPolling({ reviewToken }))
        dispatch(setFlashError('Narrative generation error: job failed'))
        break
      case JobStatus.Processed:
        dispatch(stopPolling({ reviewToken }))
        break
      default:
        assertExhaustive(status)
    }

    return job
  }
)

const poll = createAsyncThunk<
  void,
  ReviewTokenParam & { jobToken: string; initial?: boolean },
  { state: State; extra: ThunkContext }
>(
  makeName('generation/poll'),
  async ({ reviewToken, jobToken, initial }, { getState, dispatch, extra: { gqlClient } }) => {
    const { data } = await gqlClient.query({
      query: PollNarrativeGenerationDocument,
      variables: { token: jobToken },
      fetchPolicy: 'network-only',
    })

    const job = data.reviewNarrativeGenerationJob

    dispatch(update({ reviewToken, job }))

    const state = getState()
    const isPolling = narrativeAISelectors.generation.job.isPolling({ reviewToken })(state)

    if (initial || isPolling) {
      await wait(1_000)
      dispatch(poll({ reviewToken, jobToken }))
    }
  }
)

const start = createAsyncThunk<NarrativeAIGeneration, ApiJobByReviewParams, { state: State }>(
  makeName('generation/start'),
  async ({ reviewToken, job }, { getState, dispatch }) => {
    const previousTimeout = narrativeAISelectors.generation.job.timeoutId({ reviewToken })(getState())

    if (previousTimeout) {
      // NB: since this is an async thunk, this results in a rejection action being dispatched which is simply ignored
      // it will show up in breadcrumbs in sentry and/or debugging though, so it's useful to throw something
      throw new Error('Rejecting new start action due to previous generation running.')
    }

    const timeoutId = setTimeout(
      () => {
        const isPolling = narrativeAISelectors.generation.job.isPolling({ reviewToken })(getState())

        // if we're still polling, delete the whole job and notify that we had a timeout
        if (isPolling) {
          dispatch(remove({ reviewToken }))
          dispatch(setFlashError('Narrative generation error: job timed out'))
        }
      },
      // poll for three minutes
      3 * 60 * 1_000
    )

    dispatch(poll({ reviewToken, jobToken: job.token, initial: true }))
    return { job, timeoutId } satisfies NarrativeAIGeneration
  }
)

// packaging

export const narrativeAIActions = {
  generation: {
    start,
    update,
    remove,
    stopPolling,
    poll,
  },
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const HandleBarsIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} width="24" height="18" viewBox="0 0 24 18" fill="none">
    <path
      d="M9.29102 9.75C9.70523 9.75 10.041 9.41421 10.041 9C10.041 8.58579 9.70523 8.25 9.29102 8.25C8.8768 8.25 8.54102 8.58579 8.54102 9C8.54102 9.41421 8.8768 9.75 9.29102 9.75Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.29102 4.5C9.70523 4.5 10.041 4.16421 10.041 3.75C10.041 3.33579 9.70523 3 9.29102 3C8.8768 3 8.54102 3.33579 8.54102 3.75C8.54102 4.16421 8.8768 4.5 9.29102 4.5Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.29102 15C9.70523 15 10.041 14.6642 10.041 14.25C10.041 13.8358 9.70523 13.5 9.29102 13.5C8.8768 13.5 8.54102 13.8358 8.54102 14.25C8.54102 14.6642 8.8768 15 9.29102 15Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.541 9.75C14.9552 9.75 15.291 9.41421 15.291 9C15.291 8.58579 14.9552 8.25 14.541 8.25C14.1268 8.25 13.791 8.58579 13.791 9C13.791 9.41421 14.1268 9.75 14.541 9.75Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.541 4.5C14.9552 4.5 15.291 4.16421 15.291 3.75C15.291 3.33579 14.9552 3 14.541 3C14.1268 3 13.791 3.33579 13.791 3.75C13.791 4.16421 14.1268 4.5 14.541 4.5Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.541 15C14.9552 15 15.291 14.6642 15.291 14.25C15.291 13.8358 14.9552 13.5 14.541 13.5C14.1268 13.5 13.791 13.8358 13.791 14.25C13.791 14.6642 14.1268 15 14.541 15Z"
      stroke="#7B7D82"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

import { useRef, useState } from 'react'

import { NotificationsRounded, NotificationsNoneRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { createStyles, withStyles } from '@mui/styles'

import { actionAllNotifications, notificationAction } from 'actions/notificationsActions'
import { useDispatch, useSelector } from 'actions/store'
import NotificationComponent from 'components/collaboration/Notification'
import Loader from 'components/library/Loader'
import { Menu, MenuList, MenuListItem } from 'components/library/Menu'
import NotificationBadge from 'components/library/NotificationBadge'
import SimpleButton from 'components/library/SimpleButton'
import { DoneAllIcon } from 'icons'
import { Notification } from 'reducers/notificationsReducer'
import { Theme, WithStyles } from 'types/hb'

import AppNavButton from '../AppNavButton'

const doneAllStyles = (theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.secondary,
      marginLeft: theme.spacing(),
    },
  })

const SecondaryDoneAllIcon = withStyles(doneAllStyles)(DoneAllIcon)

const styles = (theme: Theme) =>
  createStyles({
    root: {},

    popper: {
      marginLeft: `${theme.spacing(1)} !important`,
    },

    menuListHeader: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      minWidth: '280px',
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(1),
    },

    menuHeaderActions: {
      flexGrow: 1,
      textAlign: 'right',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      color: theme.palette.text.secondary,
    },

    empty: {
      fontWeight: 500,
      textAlign: 'center',
      padding: `${theme.spacing(2)} 0`,
      color: theme.palette.styleguide.mediumGray1,
      margin: '0 auto',
    },

    allNotifications: {
      textAlign: 'center',
      color: theme.palette.styleguide.blue,
      width: '100%',
    },
    badge: { color: theme.palette.styleguide.blue, backgroundColor: theme.palette.styleguide.blue2 },
  })

type Props = WithStyles<typeof styles>

function HeaderNotifications(props: Props) {
  const { classes } = props

  const dispatch = useDispatch()
  const loading = useSelector((state) => state.view.loading.notifications)
  const notifications = useSelector((state) => state.notifications.all)

  const [open, setOpen] = useState(false)
  const menuControl = useRef<HTMLButtonElement | null>(null)

  const toggleNotificationsList = () => setOpen(!open)

  const handleNotificationClick = (notification: Notification) => {
    dispatch(notificationAction(notification))
    toggleNotificationsList()
  }

  const handleNotificationsClear = () => {
    dispatch(actionAllNotifications())
    toggleNotificationsList()
  }

  const unreadCount = notifications.reduce((accum, notification) => accum + (notification.actionedAt ? 0 : 1), 0)

  const navigateToNotifications = () => {
    dispatch((d, getState, { pilot }) => d(pilot.go('/dashboard/notifications')))
    toggleNotificationsList()
  }

  return (
    <div className={classes.root}>
      <AppNavButton
        ref={menuControl}
        label="Notifications"
        testId="notifications"
        onClick={toggleNotificationsList}
        Icon={unreadCount > 0 ? NotificationsRounded : NotificationsNoneRounded}
      />
      <Menu
        popperMenu
        trigger={menuControl.current}
        open={open}
        onClose={toggleNotificationsList}
        classes={{ popper: classes.popper }}
      >
        {loading && <Loader variant="local" />}
        <MenuList>
          <MenuListItem className={classes.menuListHeader} title>
            <div>Notifications</div>
            {unreadCount > 0 && <NotificationBadge content={unreadCount} className={classes.badge} />}
            <SimpleButton className={classes.menuHeaderActions} onClick={handleNotificationsClear}>
              {unreadCount > 0 && 'Mark all as read'}
              <SecondaryDoneAllIcon />
            </SimpleButton>
          </MenuListItem>
          {notifications.length === 0 && (
            <MenuListItem selectable={false}>
              <div className={classes.empty}>No Notifications</div>
            </MenuListItem>
          )}
          {notifications.slice(0, 5).map((notification) => (
            <NotificationComponent
              key={notification.token}
              onClick={handleNotificationClick}
              notification={notification}
              notificationClassName="notification-content"
            />
          ))}
          <MenuListItem onClick={navigateToNotifications}>
            <div className={classes.allNotifications}>All notifications</div>
          </MenuListItem>
        </MenuList>
      </Menu>
    </div>
  )
}

export default withStyles(styles)(HeaderNotifications)

import PropTypes from 'prop-types'

export const OPTION = PropTypes.shape({
  display: PropTypes.string,
  value: PropTypes.string,
})

export const OPTION_LIST = PropTypes.arrayOf(
  PropTypes.oneOfType([
    OPTION,
    PropTypes.shape({
      label: PropTypes.string,
      options: PropTypes.arrayOf(OPTION).isRequired,
    }),
  ])
)

export const FILING = PropTypes.shape({
  token: PropTypes.string,
})

export const REVIEW = PropTypes.shape({
  token: PropTypes.string,
  isCompleted: PropTypes.bool.isRequired,
  filings: PropTypes.arrayOf(FILING),
  activityTags: PropTypes.arrayOf(PropTypes.shape({ token: PropTypes.string.isRequired })),
})

export const CLASSES = PropTypes.shape({})

export const REVIEW_TYPE = PropTypes.shape({
  name: PropTypes.string,
  token: PropTypes.string,
  displayColor: PropTypes.string,
})

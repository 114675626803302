import { TextField } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { enqueueSnackbar } from 'notistack'

import { useDebouncedCallback } from 'use-debounce'

const useTextFieldStyles = makeStyles((theme) => ({
  root: {
    height: theme.spacing(5),

    '& .MuiInputBase-root': { height: theme.spacing(5) },
  },
}))

interface InOperatorFieldProps {
  currentValue?: string
  onChange(newValue: string): void
}

const InOperatorField = (props: InOperatorFieldProps) => {
  const { currentValue, onChange } = props
  const textFieldStyles = useTextFieldStyles()

  const updateTypedValue = useDebouncedCallback(({ target: { value } }) => {
    const valuesArray = value.split(',')
    if (valuesArray.length > 100) {
      enqueueSnackbar('Cannot include more than 100 search terms per condition.', {
        variant: 'warning',
      })
      onChange(valuesArray.slice(0, 100).join(','))
    } else {
      onChange(value)
    }
  }, 200)
  return (
    <TextField
      variant="outlined"
      placeholder="Separate by comma"
      defaultValue={currentValue}
      classes={textFieldStyles}
      fullWidth
      onChange={updateTypedValue}
    />
  )
}

export default InOperatorField

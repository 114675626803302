import { ReactNode, Component, ErrorInfo } from 'react'

import * as Sentry from '@sentry/react'

import { UnrecoverableErrorMessage } from 'components/library/Errors'
import { UsageContext } from 'helpers/SessionTracking/UsageTracker'

interface ErrorBoundaryProps {
  children: ReactNode
  renderError?: (error?: string) => ReactNode
  onError?: (error: Error) => unknown
  fullPage?: boolean
}

interface ErrorBoundaryState {
  hasError: boolean
  errorDetails?: string
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  static contextType = UsageContext

  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false, errorDetails: undefined }
  }

  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      errorDetails: error ? `${error.name}: ${error.message} --- ${error.stack}` : undefined,
    }
  }

  componentDidCatch(error: Error | null, info: ErrorInfo) {
    // Only log errors that occur when the user is online
    if (error && navigator.onLine) {
      const componentError = new Error(error.message)
      componentError.name = `React ErrorBoundary ${componentError.name}`
      componentError.stack = info.componentStack

      // eslint-disable-next-line no-param-reassign
      error.cause = componentError

      Sentry.captureException(error, { tags: { crash: true } })

      const { onError } = this.props

      if (onError) {
        onError(error)
      }

      this.context.trackChurnZeroEvent('ClientCrash')
    }
  }

  render() {
    const { children, renderError, fullPage } = this.props
    const { hasError, errorDetails } = this.state
    if (hasError) {
      if (renderError) {
        return renderError(errorDetails)
      }
      return <UnrecoverableErrorMessage details={errorDetails} fullPage={fullPage} />
    }

    return children
  }
}

export default ErrorBoundary

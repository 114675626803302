import { styled, useTheme } from '@mui/material'

type HbDashedLineOrientation = 'vertical' | 'horizontal'

type StyleProps = {
  color: string
  orientation: HbDashedLineOrientation
  length: number | string
  block?: boolean
}

export const Line = styled('div')<StyleProps>(({ color, orientation, length }) => {
  const top = 0
  const left = '50%'
  return {
    width: 1.5,
    height: length || 2,
    // use backgroundImage to customize dashed line: see https://css-tricks.com/more-control-over-css-borders-with-background-image/
    backgroundImage: `repeating-linear-gradient(0deg, ${color}, ${color} 3px, transparent 3px, transparent 5px, ${color} 5px)`,
    backgroundSize: '1.5px 100%',
    backgroundPosition: '0 0',
    backgroundRepeat: 'no-repeat',
    ...(orientation === 'horizontal' && {
      rotate: '90deg',
    }),
    position: 'absolute',
    top,
    left,
  }
})

export const Wrapper = styled('div')<Pick<StyleProps, 'orientation' | 'length' | 'block'>>(
  ({ orientation, length, block }) => ({
    position: 'relative',
    width: orientation === 'horizontal' ? length : '100%',
    height: orientation === 'vertical' ? length : '100%',
    display: block ? 'block' : undefined,
  })
)

/**
 * This component renders a dashed line
 */
export const HbDashedLine = ({
  orientation = 'horizontal',
  color: colorProp,
  length = '100%',
  block = false,
}: Partial<StyleProps>) => {
  const theme = useTheme()
  const color = colorProp || theme.palette.styleguide.borderDark

  return (
    <Wrapper orientation={orientation} length={length} block={block}>
      <Line orientation={orientation} color={color} length={length} />
    </Wrapper>
  )
}

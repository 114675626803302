import React, { ReactNode, useRef, HTMLAttributes, useEffect, useCallback } from 'react'

// This is fine since this is the HbPopper!
// eslint-disable-next-line no-restricted-imports
import { ClickAwayListener, Popper, Grow, PopperPlacementType, PopperProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton, Props as ButtonProps } from 'components/HbComponents/HbButton'
import { mergeOverrideStyles } from 'components/utils/styles'
import { stopEventPropagation } from 'helpers/uiHelpers'
import { useToggle } from 'hooks'
import { Theme } from 'types/hb'

const DEFAULT_POPPER_PLACMENT: PopperPlacementType = 'bottom-end'
const DEFAULT_ROLE: HTMLAttributes<HTMLDivElement>['role'] = 'menu'
const DEFAULT_Z_INDEX = 3

type SharedProps = {
  id?: string
  classes?: Partial<typeof useStyles>
  zIndex?: number
  placement?: PopperPlacementType
  role?: HTMLAttributes<HTMLDivElement>['role']
  containerEl?: HTMLElement | null
  modifiers?: PopperProps['modifiers']
}

const useStyles = makeStyles((theme: Theme) => ({
  popper: ({ zIndex }: Pick<SharedProps, 'zIndex'>) => ({
    zIndex,
  }),
  content: {
    background: theme.palette.background.light,
    boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.16)',
    borderRadius: theme.spacing(),
    marginTop: theme.spacing(1),
    overflow: 'hidden',
  },
}))

function useHbPopper<T extends HTMLElement | HTMLButtonElement | null | undefined>(
  props: SharedProps & { anchorEl: T; isOpen: boolean; onClose: () => void }
) {
  const {
    classes: overrideClasses,
    isOpen,
    id,
    anchorEl,
    zIndex = DEFAULT_Z_INDEX,
    placement = DEFAULT_POPPER_PLACMENT,
    role = DEFAULT_ROLE,
    onClose,
    containerEl,
    modifiers,
  } = props

  const classes = useStyles({ zIndex })

  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)

  const contentRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const handleKeyDown = (ev: KeyboardEvent) => {
      // Support tabbing into the popper from the anchor element
      if (ev.key === 'Tab') {
        contentRef.current?.focus()
      }
      if (ev.key === 'Escape' && isOpen) {
        onClose()
      }
      if (ev.key === 'Enter' && isOpen) {
        ev.preventDefault()
      }
      ev.stopPropagation()
    }
    anchorEl?.addEventListener('keydown', handleKeyDown)
    return () => anchorEl?.removeEventListener('keydown', handleKeyDown)
  }, [onClose, anchorEl, isOpen])

  const popperProps: Omit<PopperProps, 'children'> = {
    className: mergedClasses.popper,
    id: isOpen ? id : undefined,
    open: isOpen,
    anchorEl,
    placement,
    transition: true,
    container: containerEl,
    modifiers,
  }

  return {
    popperProps,
    contentProps: {
      className: mergedClasses.content,
      'data-testid': id,
      role,
      tabIndex: isOpen ? 0 : -1,
      onKeyDown: (ev: React.KeyboardEvent) => {
        if (ev.key === 'Escape') {
          onClose()
          anchorEl?.focus()
        }
      },
    },
    contentRef,
  }
}

export type HbPopperWithButtonProps = SharedProps & {
  buttonProps: ButtonProps
  content: ({ handleClose }: { handleClose: () => void }) => ReactNode
  noPropagateOnClick?: boolean
}

// If the popper contains components that render overlays (modals, selects)
// we don't want to close the popper when clicking on those overlays
const isClickingOnBody = (target: Node) => target.nodeName === 'BODY'

export const HbPopperWithButton = ({ buttonProps, content, noPropagateOnClick, ...rest }: HbPopperWithButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const { value: isOpen, toggle, setValue: setIsOpen } = useToggle(false)

  const handleClose = () => {
    setIsOpen(false)
  }

  const onToggleClick = (event: React.SyntheticEvent<unknown>) => {
    if (noPropagateOnClick) {
      stopEventPropagation(event)
    }
    toggle()
  }

  const { popperProps, contentProps, contentRef } = useHbPopper({
    ...rest,
    anchorEl: ref.current,
    isOpen,
    onClose: handleClose,
  })

  return (
    <ClickAwayListener
      onClickAway={({ target }) => {
        if (isClickingOnBody(target as Node)) {
          return
        }
        handleClose()
      }}
    >
      <div>
        <HbButton onClick={onToggleClick} ref={ref} aria-expanded={isOpen} {...buttonProps} />
        <Popper {...popperProps}>
          {({ TransitionProps }) => (
            <Grow {...TransitionProps}>
              <div ref={contentRef} {...contentProps}>
                {content({ handleClose })}
              </div>
            </Grow>
          )}
        </Popper>
      </div>
    </ClickAwayListener>
  )
}

export type HbPopperProps = SharedProps & {
  isOpen: boolean
  anchorEl?: HTMLElement | null
  onClose: () => void
  children: ReactNode
  preventClickAwayCollision?: boolean
}

export const HbPopper = ({
  isOpen,
  anchorEl,
  onClose,
  children,
  preventClickAwayCollision = true,
  ...rest
}: HbPopperProps) => {
  const { popperProps, contentProps, contentRef } = useHbPopper({
    ...rest,
    anchorEl,
    isOpen,
    onClose,
  })

  const handleClickaway = useCallback(
    (ev: MouseEvent | TouchEvent) => {
      const { target: _target } = ev
      const target = _target as Node
      // Don't call the ClickAwayListener's onClose event
      // when the click event to open the popper is called
      if (preventClickAwayCollision && anchorEl?.contains(target)) {
        return
      }
      if (isClickingOnBody(target)) {
        return
      }
      onClose()
    },
    [anchorEl, onClose, preventClickAwayCollision]
  )

  return (
    <ClickAwayListener onClickAway={handleClickaway}>
      <Popper {...popperProps}>
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <div ref={contentRef} {...contentProps}>
              {children}
            </div>
          </Grow>
        )}
      </Popper>
    </ClickAwayListener>
  )
}

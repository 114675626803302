import { Theme } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { useField } from 'formik'
import { isNil } from 'lodash'
import moment from 'moment-timezone'

import { HbText } from 'components/HbComponents/Text/HbText'
import { FormikDateTimePicker, FormikDateTimePickerProps } from 'components/material/Form'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

const useStyles = makeStyles((theme: Theme) => ({
  redesignDatePickerRoot: {
    margin: 0,
  },
  timezoneText: {
    marginBottom: theme.spacing(1),
  },
}))

export type DateTimeInputProps = InputProps & FormikDateTimePickerProps

export const DateTimeInput = (props: DateTimeInputProps) => {
  const {
    readOnly,
    label,
    sublabel,
    name,
    disabled,
    errors,
    isErroneousChecker,
    autosave,
    testId,
    inputContainerClassName,
    className: classNameProp,
    ...rest
  } = props

  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })
  const styles = useStyles()
  const orgTimeZone = useOrgTimeZone()

  if (readOnly) {
    // Display the time in UTC to avoid the ambiguity
    // of different timezones for HB user and form filler,
    // at least for now
    return (
      <InputResponse className={inputContainerClassName} label={label}>
        {isNil(field.value) ? '-' : moment.utc(field.value).tz(orgTimeZone).format('YYYY/MM/DD HH:mm z')}
      </InputResponse>
    )
  }

  const className = classnames(classNameProp, styles.redesignDatePickerRoot)

  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      testId={testId}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={inputContainerClassName}
      htmlFor={name}
    >
      <HbText color="secondary" size="s" className={styles.timezoneText}>
        Please report in {moment().tz(orgTimeZone).format('z')} ({orgTimeZone}) time
      </HbText>
      <FormikDateTimePicker
        field={field}
        form={form}
        meta={meta}
        slotProps={{
          textField: {
            error: isErroneous,
            id: name,
            'aria-describedby': getHelperTextId(name),
          },
        }}
        name={name}
        variant="outlined"
        testId={testId}
        margin="normal"
        disabled={disabled}
        className={className}
        size="small"
        {...rest}
      />
    </InputContainer>
  )
}

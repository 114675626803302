import React from 'react'

import { Button, Dialog, DialogActions, DialogContent } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles, withTheme } from '@mui/styles'

import { History, Location } from 'history'
import { withRouter } from 'react-router-dom'

import { HbText } from 'components/HbComponents/Text/HbText'
import HbLogo from 'images/logomark.png'
import { Theme, WithStyles } from 'types/hb'

const styles: Styles<Theme, object> = {
  logo: {
    display: 'block',
    margin: 'auto',
  },
  headline: {
    marginTop: 16,
    marginBottom: 24,
    textAlign: 'center',
  },
  text: {
    marginLeft: 16,
    marginRight: 16,
  },
  actions: {
    marginTop: 32,
    justifyContent: 'center',
  },
}
interface Props extends WithStyles<typeof styles> {
  theme: Theme
  history: History
  location: Location
}

class WelcomeDialog extends React.PureComponent<Props> {
  state = {
    open: window.location.search.includes('welcome=true'),
  }

  handleClose = () => {
    const { history, location } = this.props

    this.setState({ open: false })
    // Redirect to route without query string
    history.push(location.pathname)
  }

  backdropProps() {
    const { theme } = this.props
    return {
      style: {
        opacity: '.8',
        background: theme.gradients.adminOnboarding,
      },
    }
  }

  render() {
    const { classes } = this.props
    const { open } = this.state

    return (
      <Dialog open={open} BackdropProps={this.backdropProps()}>
        <DialogContent>
          <HbText block bold size="xxl" className={classes.headline}>
            <img src={HbLogo} className={classes.logo} alt="Hummingbird logo" width="54" /> Welcome to Hummingbird
          </HbText>

          <HbText className={classes.text} block>
            When you sign in to Hummingbird, you&apos;ll start in your Cases dashboard.
            <br />
            <br />
            Select &quot;My Cases&quot; in the navigation on the left to see cases that are assigned to you or need your
            attention.
          </HbText>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button variant="contained" color="primary" onClick={this.handleClose}>
            Get Started
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

export default withTheme(withStyles(styles)(withRouter(WelcomeDialog as unknown as any)))

import { DateFormatter } from 'hooks/DateFormatHooks'

interface Props {
  formatDate: DateFormatter
  date?: string | null
  dateFormat?: string
  className?: string
}

export const DateCell = ({ date, formatDate, dateFormat: dateFormatProp, className }: Props) => {
  if (!date) {
    return null
  }
  const dateFormat = dateFormatProp || 'LL'
  return <span className={className}>{formatDate(date, '', dateFormat)}</span>
}

import {
  AccountBalanceRounded,
  AccountBalanceWalletRounded,
  AttachmentRounded,
  CreditCardRounded,
  GetAppRounded,
  LocationOnRounded,
  PersonRounded,
  VpnKeyRounded,
  WorkRounded,
  Web,
  Devices,
} from '@mui/icons-material'

import DeviceFingerprintIcon from 'icons/DeviceFingerprintIcon'
import IPAddressIcon from 'icons/IPAddressIcon'
import { TransactionIcon } from 'icons/TransactionIcon'

import { assertUnreachable } from 'types/hb'

import { DataIconProps } from './types'

export function getIconForDataType(dataType: DataIconProps['dataType']): React.ComponentType<{ className?: string }> {
  switch (dataType) {
    case 'individual':
      return PersonRounded
    case 'business':
      return WorkRounded
    case 'institution':
    case 'institution_relationship':
      return AccountBalanceRounded
    case 'product':
      return Web
    case 'crypto_address':
      return VpnKeyRounded
    case 'bank_account':
      return AccountBalanceWalletRounded
    case 'payment_card':
      return CreditCardRounded
    case 'device':
      return Devices
    case 'transaction':
    case 'transactions':
    case 'transaction_imports':
      return TransactionIcon
    case 'location':
      return LocationOnRounded
    case 'attachment':
      return AttachmentRounded
    case 'import_template':
    case 'case_import':
      return GetAppRounded
    case 'device_fingerprint':
      return DeviceFingerprintIcon
    case 'ip_address':
      return IPAddressIcon
    default:
      return assertUnreachable(dataType)
  }
}

import { InboundRequestStatusEnum, InformationRequestStatusEnum } from 'types/api'

export const isAwaitingResponse = (status: InformationRequestStatusEnum | InboundRequestStatusEnum) => {
  return status === 'request_sent' || status === 'overdue' || status === 'pending'
}

export const hasResponse = (status: InformationRequestStatusEnum | InboundRequestStatusEnum) => {
  return status === 'response_received' || status === 'completed' || status === 'responded'
}

export const isClosed = (status: InformationRequestStatusEnum) => {
  return status === 'cancelled' || status === 'completed'
}

export const isCancelled = (status: InformationRequestStatusEnum | InboundRequestStatusEnum) => {
  return status === 'cancelled' || status === 'cancelled_by_sender'
}

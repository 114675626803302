import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const TimeIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M10 2a8 8 0 1 1-8 8 8 8 0 0 1 8-8m0-2a10 10 0 1 0 10 10A10 10 0 0 0 10 0z" />
    <path d="M12.34 13.4l-3-2.65A1 1 0 0 1 9 10V4h2v5.55l2.66 2.35z" />
  </SvgIcon>
)

export default TimeIcon

/* eslint-disable react/jsx-handler-names */
import { useEffect, useMemo, useRef, useState } from 'react'

import { FormControlLabel, FormHelperText, Switch } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { cloneDeep } from 'lodash'
import pluralize from 'pluralize'
import { Controller } from 'react-hook-form'

import { HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'
import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'
import { HbText } from 'components/HbComponents/Text/HbText'

import BadgeSelect, { Badge } from 'components/library/BadgeSelect'
import LiquidMarkdownEditor, { Api } from 'components/library/LiquidMarkdownEditor'

import TypeAheadEditor from 'components/library/TypeAheadEditor'
import { useSharedStyles } from 'components/pages/automations/editor/actions/shared'
import { useFeatureFlag } from 'hooks'
import { useOrganizationBadges } from 'hooks/gql/useOrganizationBadges'
import { AutomationDomainType, AutomationRuleType, ColorNameEnum, FeatureFlag } from 'types/api'

import { isTruthy } from 'utils'

import { useAutomationAdminOperations } from '../../hooks/AutomationAdminHelpers'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import { useDomainFieldSpecs } from '../TriggerFilterEditor/fieldConfig'
import { getActionParamsPath } from '../util'

import { EmptyTemplateAction } from './EmptyTemplateAction'

import AccountSelect from './components/AccountSelect'

import { AdminAccountSelect, AdminRuleBadgeSelect } from './components/AdminRuleComponents'
import CheckboxSection from './components/CheckboxSection'
import EmailRecipients from './components/EmailRecipients'

import { getDomainSmartValues } from './smartValues'

import type { FormSchemaReturnType } from '../formSchema'
import type { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  fullWidth: { width: '100%' },
  badgeSelect: {
    margin: 0,
  },
  assignmentControl: {
    width: '100%',
    margin: theme.spacing(1.5, 0, 0),

    '&': { width: '100%' },
  },
  editor: {
    marginBottom: 0,
  },
  smartValuesEditor: {
    border: `1px solid ${theme.palette.styleguide.borderDark}`,
    borderRadius: theme.spacing(0.5),
    padding: theme.spacing(1),
    background: 'white',
  },
  smartValuesToggleBar: {
    display: 'flex',
    gap: theme.spacing(1),
    paddingRight: theme.spacing(2),
  },
  smartValuesToggleBarPullUp: {
    marginTop: theme.spacing(-2.5),
  },
  recipients: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
  contentSection: {
    marginTop: theme.spacing(2),
  },
  accountSelect: {
    marginTop: theme.spacing(),
  },
  toAssignee: {
    marginBottom: theme.spacing(2),
  },
  toAssigneeCheckbox: {
    padding: theme.spacing(0, 1.5, 0, 0),
    margin: 0,
  },
}))

const Recipients = ({ form }: { form: FormSchemaReturnType }) => {
  const { getValues, setValue, watch } = form

  const [sendToReviewAssignee, automationType] = watch([
    'actionParams.notificationParams.sendToReviewAssignee',
    'automationType',
  ])
  const { badges: availableBadges } = useOrganizationBadges()
  const ruleOrgToken = getValues('organizationToken')
  const isAdminAutomationRule = useAutomationAdminOperations(ruleOrgToken) && !!ruleOrgToken

  const [selectedBadges, setSelectedBadges] = useState<
    Array<{ token: string; color: ColorNameEnum; displayName: string }>
  >([])

  const { actionType } = getValues()
  const actionParamsPath = getActionParamsPath(actionType)
  const actionParams = getValues(actionParamsPath)
  const invalidNotificationAction = actionType !== 'notification' && actionType !== 'review_digest_notification'
  const isRule = useIsAutomationRule()

  const domainType = watch('domain.type')

  const badgeTokens = useMemo(
    () => (actionParams && 'badgeTokens' in actionParams ? actionParams.badgeTokens || [] : []),
    [actionParams]
  )

  useEffect(() => {
    if (invalidNotificationAction) return

    setSelectedBadges(badgeTokens.map((token) => availableBadges.find((ab) => ab.token === token)).filter(isTruthy))

    if (automationType === AutomationRuleType.ScheduleWithTrigger) {
      setValue(
        'actionText',
        `Send a notification that consolidates all ${pluralize(domainType || 'result')} matching the trigger conditions`
      )
    } else {
      setValue('actionText', 'Send a notification')
    }
  }, [badgeTokens, actionType, availableBadges, invalidNotificationAction, automationType, setValue, domainType])

  const handleSelectBadges = (badges: Array<Badge>) => {
    if (invalidNotificationAction) return

    setValue(
      `${actionParamsPath}.badgeTokens`,
      badges.map(({ token }) => token),
      { shouldDirty: true }
    )
    setSelectedBadges(badges)
  }

  const classes = useStyles()

  if (!availableBadges) {
    return null
  }

  return (
    <div>
      <HbText bold size="s" color="secondary">
        Recipients
      </HbText>
      <div className={classes.recipients}>
        {domainType === AutomationDomainType.Review && (
          <div className={classes.toAssignee}>
            <HbCheckbox
              className={classes.toAssigneeCheckbox}
              checked={sendToReviewAssignee}
              onChange={() =>
                setValue('actionParams.notificationParams.sendToReviewAssignee', !sendToReviewAssignee, {
                  shouldDirty: true,
                })
              }
            />
            <HbText size="s">Review Assignee</HbText>
          </div>
        )}
        <CheckboxSection
          label="Badges"
          isChecked={selectedBadges.length > 0 || badgeTokens.length > 0}
          handleUncheck={() => {
            handleSelectBadges([])
          }}
        >
          {isAdminAutomationRule ? (
            <AdminRuleBadgeSelect
              disabled={!isRule}
              organizationToken={ruleOrgToken}
              actionParamsBadgeTokens={badgeTokens}
              handleSelectBadges={handleSelectBadges}
              className={classnames(classes.fullWidth, classes.badgeSelect)}
            />
          ) : (
            <BadgeSelect
              disabled={!isRule}
              helperText="All users under the badge will receive the notification"
              className={classnames(classes.fullWidth, classes.badgeSelect)}
              availableBadges={availableBadges}
              selectedBadges={selectedBadges}
              onChange={handleSelectBadges}
            />
          )}
        </CheckboxSection>
        {isAdminAutomationRule ? (
          <AdminAccountSelect organizationToken={ruleOrgToken} className={classes.fullWidth} />
        ) : (
          <AccountSelect className={classnames(classes.fullWidth, classes.accountSelect)} />
        )}
        <EmailRecipients className={classes.assignmentControl} />
      </div>
    </div>
  )
}

export default function CustomizeNotificationAction({ form }: { form: FormSchemaReturnType }) {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const { getValues, watch } = form

  const [smartValuesEnabled, automationType] = watch([
    'actionParams.notificationParams.smartValuesEnabled',
    'automationType',
  ])

  const { actionType } = getValues()

  const domainType = watch('domain.type')
  const domainDatasourceToken = watch('domain.datasourceToken')

  const { getDomainFieldSpec } = useDomainFieldSpecs()
  const fieldSpec =
    domainType && domainDatasourceToken
      ? getDomainFieldSpec({ type: domainType, datasourceToken: domainDatasourceToken })
      : null

  const liquidEditorRef = useRef<Api>(null)

  const variables = useMemo(() => {
    if (!domainType) {
      return []
    }

    const smartValuesConfig = getDomainSmartValues(isRenameOtherInfoEnabled)

    const vars = [smartValuesConfig[domainType]]

    if (domainType === AutomationDomainType.Datasource && fieldSpec) {
      const finalVars = cloneDeep(vars)

      finalVars[0].properties.push(
        ...Object.keys(fieldSpec.values).map((k) => ({ name: k, type: 'value' as const, label: k, info: k }))
      )

      return finalVars
    }

    return vars
  }, [domainType, fieldSpec, isRenameOtherInfoEnabled])

  const classes = useStyles()
  const { formControl } = useSharedStyles()
  const isRule = useIsAutomationRule()

  if (!isRule && actionType === 'review_digest_notification') {
    return <EmptyTemplateAction />
  }

  return (
    <>
      {isRule && <Recipients form={form} />}
      {actionType !== 'review_digest_notification' && (
        <div className={classes.contentSection}>
          <HbRHFTextInput
            label="Subject"
            name="actionParams.notificationParams.subject"
            aria-placeholder="Write a subject..."
          />
          <Controller
            name="actionParams.notificationParams.body"
            render={({ field, fieldState }) => (
              <div className={classnames(formControl, classes.editor)} onBlur={field.onBlur}>
                <HbText bold size="s" color="secondary">
                  Message
                </HbText>
                {smartValuesEnabled ? (
                  <LiquidMarkdownEditor
                    ref={liquidEditorRef}
                    initialValue={field.value}
                    variables={variables}
                    domain={domainType}
                    onChange={field.onChange}
                    className={classes.smartValuesEditor}
                  />
                ) : (
                  <TypeAheadEditor
                    initialValue={field.value}
                    onChange={field.onChange}
                    placeholder="Write a message..."
                    richText
                  />
                )}
                {fieldState.error && (
                  <FormHelperText sx={{ marginTop: smartValuesEnabled ? 0 : -2 }} error>
                    {fieldState.error.message}
                  </FormHelperText>
                )}
                <FormHelperText sx={{ marginTop: 0 }}>
                  {smartValuesEnabled ? (
                    <>
                      Add smart values between {'{{ and }}'}.{' '}
                      <a
                        href="https://beta.docs.hummingbird.co/using-hummingbird/features-in-beta/automations/using-smart-values-in-your-automations"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Learn more about Smart Values
                      </a>
                    </>
                  ) : null}
                </FormHelperText>
                {automationType === AutomationRuleType.Trigger ? (
                  <Controller
                    name="actionParams.notificationParams.smartValuesEnabled"
                    render={({ field: smartValueToggleField }) => (
                      <FormControlLabel
                        className={classnames(
                          classes.smartValuesToggleBar,
                          smartValuesEnabled ? null : classes.smartValuesToggleBarPullUp
                        )}
                        control={
                          <Switch
                            onChange={(_, val) => smartValueToggleField.onChange(val)}
                            onBlur={() => smartValueToggleField.onBlur()}
                            checked={smartValueToggleField.value}
                          />
                        }
                        labelPlacement="start"
                        label={<HbText bold>Smart values</HbText>}
                      />
                    )}
                  />
                ) : null}
              </div>
            )}
          />
        </div>
      )}
    </>
  )
}

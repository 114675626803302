import { useCallback, useEffect, useState } from 'react'

import { useQuery } from '@apollo/client'

import { FormProvider, SubmitHandler } from 'react-hook-form'
import { match as IMatch } from 'react-router-dom'

import { GQLError } from 'components/GQLError'
import Loader from 'components/library/Loader'

import { CreateAutomationSelectType } from 'components/pages/automations/editor/CreateAutomationSelectType'
import SaveEnableDisableDialog from 'components/pages/automations/editor/SaveEnableDisableDialog'
import SelectActionDialog from 'components/pages/automations/editor/SelectActionDialog'
import { ViewAutomationSteps } from 'components/pages/automations/editor/ViewAutomationSteps'
import { FormSchemaForRule } from 'components/pages/automations/editor/formSchema'

import { useAutomationRuleToggle } from 'components/pages/automations/hooks/useAutomationRuleToggle'
import { useDeleteAutomationRule } from 'components/pages/automations/hooks/useDeleteAutomationRule'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'

import { useFeatureFlag } from 'hooks'
import { AutomationActionType, FeatureFlag } from 'types/api'

import { AutomationRuleOrTemplateContext } from '../editor/AutomationRuleOrTemplateContext'
import { useAutomationRuleForm } from '../editor/useAutomationRuleOrTemplateForm'
import { setActionParams } from '../editor/util'

import { useAutomationAdminOperations } from '../hooks/AutomationAdminHelpers'

import { PAGE_SIZE as HISTORY_PAGE_SIZE } from './AutomationActivityLog'
import { AUTOMATION_QUERY } from './ViewAutomation.queries'

import * as Styles from './ViewAutomation.styles'
import { ViewAutomationHeader } from './ViewAutomationHeader'
import { ViewAutomationSidebar } from './ViewAutomationSidebar'
import {
  ViewAutomationInnerAutomationRuleFragment as AutomationRule,
  ViewAutomationQuery,
  ViewAutomationQueryVariables,
} from './__generated__/ViewAutomation.queries.generated'

interface Props {
  automationRule: AutomationRule
  tab: string
  loadMoreActivity: (cursor: string | null) => void
}

function ViewAutomationInner({ automationRule, loadMoreActivity }: Props) {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const { form, handleSaveAutomationRule, defaultValues } = useAutomationRuleForm(automationRule)

  const usage = useUsage()
  const [actionModalOpen, setActionModalOpen] = useState(false)
  const [enablingOrDisabling, setEnablingOrDisabling] = useState<boolean>(false)

  const { handleSubmit, reset, formState, getValues, watch } = form
  const { isDirty, isSubmitting } = formState

  const [domainType, automationType] = watch(['domain.type', 'automationType'])

  useEffect(() => {
    if (isDirty && automationRule.enabled) {
      setEnablingOrDisabling(true)
    }
  }, [automationRule.enabled, isDirty])

  const { enableRule, disableRule, loading: toggleRuleLoading } = useAutomationRuleToggle(automationRule)
  const { deleteRule, loading: deleteRuleLoading } = useDeleteAutomationRule(automationRule)

  const loading = isSubmitting || toggleRuleLoading || deleteRuleLoading
  const handleSave: SubmitHandler<FormSchemaForRule> = async (values) => {
    await handleSaveAutomationRule(values)
    setEnablingOrDisabling(false)
  }

  const handleSaveAndEnable: SubmitHandler<FormSchemaForRule> = async (values) => {
    const result = await handleSaveAutomationRule(values)
    setEnablingOrDisabling(false)

    if (result) {
      await enableRule()
      reset(getValues())
    }
  }

  const handleSubmitAndSave = handleSubmit(handleSave)
  const handleSubmitAndSaveAndEnable = handleSubmit(handleSaveAndEnable)

  const handleSelectAction = (newActionType: AutomationActionType) => {
    setActionModalOpen(false)
    setActionParams({ newActionType, form, isRenameOtherInfoEnabled })
  }

  const isAutomationBlank = !domainType && !automationType
  return (
    <Styles.Root>
      <Styles.Container>
        <FormProvider {...form}>
          <Styles.Form onSubmit={handleSubmitAndSave}>
            <SaveEnableDisableDialog
              onSaveAndEnable={handleSubmitAndSaveAndEnable}
              onDisable={disableRule}
              onEnable={enableRule}
              loading={loading}
              onClose={() => setEnablingOrDisabling(false)}
              onReset={() => reset(defaultValues, { keepDirty: false })}
              open={enablingOrDisabling}
              automationRule={automationRule}
              automationRuleDirty={isDirty}
            />
            <SelectActionDialog
              open={actionModalOpen}
              onClose={() => setActionModalOpen(false)}
              onSelectAction={(newActionType) => {
                usage.logEvent({
                  name: 'automations:editorChangeAction:clicked',
                  data: { automationRuleToken: automationRule.token, newAction: newActionType },
                })
                handleSelectAction(newActionType)
              }}
              domainType={domainType || undefined}
              automationType={automationType || undefined}
            />
            <ViewAutomationHeader
              automationRule={automationRule}
              form={form}
              onSubmitAndSave={handleSubmitAndSave}
              setEnablingOrDisabling={setEnablingOrDisabling}
              loading={loading}
            />
            <Styles.Main>
              <Styles.EditorWrapper>
                {isAutomationBlank ? (
                  <CreateAutomationSelectType form={form} />
                ) : (
                  <ViewAutomationSteps form={form} setActionModalOpen={setActionModalOpen} />
                )}
              </Styles.EditorWrapper>
              {!isAutomationBlank && (
                <ViewAutomationSidebar
                  form={form}
                  loading={loading}
                  deleteRule={deleteRule}
                  setEnablingOrDisabling={setEnablingOrDisabling}
                  automationRule={automationRule}
                  loadMoreActivity={loadMoreActivity}
                />
              )}
            </Styles.Main>
          </Styles.Form>
        </FormProvider>
      </Styles.Container>
    </Styles.Root>
  )
}

interface MatchProps {
  match: IMatch<{
    token: string
    tab: string
    organizationToken?: string
  }>
}

export default function ViewAutomation({ match }: MatchProps) {
  const { token, tab, organizationToken } = match.params

  const isAutomationFromAdmin = useAutomationAdminOperations(organizationToken)
  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)

  const { loading, data, error, fetchMore } = useQuery<ViewAutomationQuery, ViewAutomationQueryVariables>(
    AUTOMATION_QUERY,
    {
      variables: {
        token,
        organizationToken: isAutomationFromAdmin ? (organizationToken as string) : null, // cast is needed here b/c of organizationToken typing
        executionsSize: HISTORY_PAGE_SIZE,
        executionsCursor: null,
        datasourcesEnabled,
      },
    }
  )

  const loadMoreActivity = useCallback(
    async (executionsCursor: string | null) => {
      await fetchMore({
        variables: {
          token,
          executionsSize: HISTORY_PAGE_SIZE,
          executionsCursor,
        },
      })
    },
    [fetchMore, token]
  )

  if (loading || !data) {
    return <Loader variant="global" />
  }

  if (error) {
    return <GQLError error={error} />
  }

  return (
    <AutomationRuleOrTemplateContext.Provider value={data.automationRule}>
      <ViewAutomationInner automationRule={data.automationRule} tab={tab} loadMoreActivity={loadMoreActivity} />
    </AutomationRuleOrTemplateContext.Provider>
  )
}

import * as Yup from 'yup'

import { sendMfaCode } from 'actions/accountActions'
import { setFlashError } from 'actions/errorActions'
import { useSelector, useDispatch } from 'actions/store'
import { TextField } from 'components/library/Onboarding'
import { AuthBackground } from 'components/library/Onboarding/Background'
import FormStep from 'components/library/Onboarding/FormStep'
import Steps from 'components/library/Onboarding/Steps'
import { FIELD_REQUIRED } from 'helpers/validations'

import { AdditionalActions } from './AdditionalActions'

function MultiFactorActions() {
  const resendCodeCredentialTwoFactorAuthenticationPath = useSelector(
    (state) => state.application.urls.resendCodeCredentialTwoFactorAuthenticationPath
  )
  const destroyCredentialSessionPath = useSelector((state) => state.application.urls.destroyCredentialSessionPath)

  return (
    <AdditionalActions
      links={[
        {
          title: 'Resend code',
          href: resendCodeCredentialTwoFactorAuthenticationPath,
        },
        {
          title: 'Start over',
          href: destroyCredentialSessionPath,
        },
      ]}
    />
  )
}

interface Props {
  next: (location: string) => unknown
}

function MultiFactorAuthStep({ next }: Props) {
  const dispatch = useDispatch()
  const handleSubmit = async (values: any, actions: any) => {
    try {
      const { success } = await dispatch(sendMfaCode(values.code))
      if (success) {
        next('/')
      } else {
        dispatch(setFlashError('Code invalid.'))
      }
    } catch (error) {
      dispatch(setFlashError('Something went wrong.'))
      throw error
    } finally {
      actions.setSubmitting(false)
      actions.resetForm()
    }
  }

  return (
    <FormStep
      additionalActions={<MultiFactorActions />}
      title="Enter Security Code"
      onNext={handleSubmit}
      nextLabel="Verify"
      validationSchema={Yup.object().shape({
        code: Yup.string().required(FIELD_REQUIRED),
      })}
    >
      <TextField autoFocus fullWidth type="text" name="code" label="Security code" />
    </FormStep>
  )
}

export default function MultiFactorAuthPage() {
  return (
    <AuthBackground>
      <Steps
        onComplete={(location: string) => {
          window.location.href = location
        }}
        path="/auth"
        steps={[
          {
            name: 'mfa',
            Component: MultiFactorAuthStep,
          },
        ]}
      />
    </AuthBackground>
  )
}

import { gql } from '@apollo/client'

const EXPORT_JOB_FRAGMENT = gql`
  fragment ExportJob on DashboardExportJob {
    # Job information
    token
    result
    status
    enqueuedAt
    presignedUrl
    # Error message on failure
    errorMessage
  }
`

export const EXPORT_DASHBOARD = gql`
  mutation EnqueueDashboardExportJob($appliedFilters: SearchRequestInput!, $type: DashboardTypeEnum!) {
    enqueueDashboardExportJob(input: { search: $appliedFilters, type: $type }) {
      job {
        ...ExportJob
      }
    }
  }
  ${EXPORT_JOB_FRAGMENT}
`

export const POLL_EXPORT_JOB = gql`
  query PollExportJob($token: ID!) {
    dashboardExportJob(token: $token) {
      ...ExportJob
    }
  }
  ${EXPORT_JOB_FRAGMENT}
`

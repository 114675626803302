import { gql } from '@apollo/client'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'

import ActivityLog from './AutomationActivityLog'
import ActivityLogDetails from './AutomationActivityLogDetails'

import { ViewAutomationActivityLogAutomationRuleFragment as AutomationRule } from './__generated__/ViewAutomationActivityLog.generated'

import type { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flex: 1,
    flexDirection: 'row',
    height: '100%',
    justifyContent: 'space-between',
  },
  activityLogContainer: {
    height: '100%',
    flexBasis: '75%',
  },
  detailsContainer: {
    flexBasis: 275,
  },
  title: {
    marginBottom: theme.spacing(2),
  },
}))

interface Props {
  automationRule: AutomationRule
  loadMoreItems: (cursor: string | null) => void
}

export default function ViewAutomationActivityLog({ automationRule, loadMoreItems }: Props) {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <div className={styles.activityLogContainer}>
        <HbText className={styles.title} size="lg" tag="h2" bold>
          Activity Log
        </HbText>
        <ActivityLog automationRule={automationRule} loadMoreItems={loadMoreItems} />
      </div>
      <div className={styles.detailsContainer}>
        <ActivityLogDetails automationRule={automationRule} />
      </div>
    </div>
  )
}

ViewAutomationActivityLog.fragments = {
  automationRule: gql`
    fragment ViewAutomationActivityLogAutomationRule on CanonicalAutomationRule {
      ...ActivityLogAutomationRule
      ...ActivityLogDetailsAutomationRule
    }
    ${ActivityLog.fragments.automationRule}
    ${ActivityLogDetails.fragments.automationRule}
  `,
}

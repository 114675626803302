/**
 * This applies Report table styling to the existing
 * DashboardTable component.
 */
import { ComponentProps, ReactNode } from 'react'

import { Paper } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { RoundedTable } from 'components/library/Table/RoundedTable'
import { DashboardTable } from 'dashboards/shared/components/Dashboard/DashboardTable'
import { Theme } from 'types/hb'

type Props = ComponentProps<typeof DashboardTable> & {
  header: ReactNode
  topOffset: number
  dashboardStyleOverrides?: Partial<{
    paper: string | undefined
    innerContainer: string | undefined
  }>
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: ({ topOffset }: Pick<Props, 'topOffset'>) => ({
    padding: theme.spacing(1, 0, 0, 0),
    boxSizing: 'border-box',
    boxShadow: '0px 4px 24px rgba(0, 0, 0, 0.08)',
    overflow: 'auto',
    height: `calc(100vh - ${topOffset + theme.hbUnit(5)})`,
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(3),
  }),
  container: {
    marginTop: theme.spacing(1),
  },
  innerContainer: {
    boxSizing: 'border-box',
    margin: theme.spacing(0, 3, 0, 3),
  },
  pagination: {
    border: 0,
    background: 'none',
  },
}))

export const DashboardTableCard = ({ header, topOffset, dashboardStyleOverrides, ...rest }: Props) => {
  const { paper, pagination, ...tableClasses } = useStyles({ topOffset })
  const innerContainer = classnames(dashboardStyleOverrides?.innerContainer || tableClasses.innerContainer)

  return (
    <Paper className={classnames(paper, dashboardStyleOverrides?.paper)}>
      {header}
      <DashboardTable
        {...rest}
        scrollToFocused={false}
        TableComponent={RoundedTable}
        styleOverrides={{
          Table: {
            innerContainer,
            container: tableClasses.container,
          },
          pagination,
        }}
      />
    </Paper>
  )
}

import { Thunk } from 'actions/store'
import { AccountAvatarDetailsFragment } from 'components/accounts/__generated__/AccountAvatar.generated'
import { ApprovalAssignmentDetails } from 'components/cases/reviews/assignment/types'
import { ReviewStateMachineState } from 'reducers/reviewsReducer'
import { InboundRequest, InvestigationReviewStatusEnum, Queue } from 'types/api'
import { OtherInfo } from 'types/hb'
import { navigateToInternalUrl } from 'utils/navigationHelpers'

export enum ReviewStatusFilter {
  Open = 'OPEN',
  ReadyForReview = 'READY_FOR_REVIEW',
  Warnings = 'WARNINGS',
  Pending = 'PENDING',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED',
}

export interface SearchResultReview {
  token: string
  type: string
  filingStatusString: string
  triggeredAt: any
  isCompleted: boolean
  state: ReviewStateMachineState
  displayState: string
  reviewDueAt: string | null
  openAt: string | null
  openedAt: string | null
  completedAt: string | null
  firstActivityAt: string | null
  triggers: string
  otherInfo: OtherInfo
  assignee?: AccountAvatarDetailsFragment | null
  queue?: Queue | null
  approvalAssignments: ApprovalAssignmentDetails[]
  internalControlNumber: string | null
  stage: string | null
  status: InvestigationReviewStatusEnum
  tags: string[]
  recentAlertExternalId?: string | null
  alertCount: number
  inboundRequest?: Pick<InboundRequest, 'token'> | null
}

export const actions = {
  navigateToSearch(): Thunk<void> {
    return (dispatch) => {
      dispatch(navigateToInternalUrl(undefined, '/search'))
    }
  },
}

import { ComponentProps, useState } from 'react'

import { Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { Marker, Popup } from 'react-mapbox-gl'

import { addressGoogleMapsURL } from 'components/entities/Address/utils'
import ExternalLink from 'components/library/ExternalLink'
import { displayAddress } from 'helpers/uiHelpers'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  marker: {
    padding: theme.spacing(1),
    paddingBottom: 0,
  },
  popupLink: {
    textDecoration: 'none',

    '&, &:link, &:visited, &:active, &:hover': {
      color: theme.palette.text.white,
    },
  },
  popupLinkIcon: {
    height: 16,
    width: 16,
    position: 'relative',
    top: 3,
  },
}))

export type Props = Omit<
  ComponentProps<typeof Marker> & {
    address: DisplayAddressAddressFragment | null
  },
  'coordinates'
>

export function AddressMarker({ address, ...props }: Props) {
  const { onMouseEnter, onMouseLeave } = props
  const { marker, popupLink, popupLinkIcon } = useStyles()
  const [isMarkerHovered, setIsMarkerHovered] = useState(false)
  const [isPopupHovered, setIsPopupHovered] = useState(false)

  if (!address || !address.geopoint) {
    return null
  }
  const coordinates = [address.geopoint.longitude, address.geopoint.latitude]
  return (
    <>
      <Marker
        {...props}
        coordinates={coordinates}
        className={classnames(marker, props.className)}
        onMouseEnter={(e) => {
          onMouseEnter?.(e)
          setIsMarkerHovered(true)
        }}
        onMouseLeave={(e) => {
          onMouseLeave?.(e)
          setIsMarkerHovered(false)
        }}
      />
      {isMarkerHovered || isPopupHovered ? (
        <Popup
          anchor="bottom"
          coordinates={coordinates}
          offset={25}
          onMouseEnter={(_) => setIsPopupHovered(true)}
          onMouseLeave={(_) => setIsPopupHovered(false)}
        >
          <Box p={1}>
            <ExternalLink classes={{ root: popupLink, icon: popupLinkIcon }} to={addressGoogleMapsURL(address)}>
              {displayAddress(address)}
            </ExternalLink>
          </Box>
        </Popup>
      ) : null}
    </>
  )
}

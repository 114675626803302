import { BaseMetadata, GetArrayFieldDiffKey } from './InformationFields.types'

export const CRM_HISTORY_DOCS_LINK = 'https://beta.docs.hummingbird.co/tutorials/how-to-guides/view-crm-history'

export const getReadDataWithDeletionsIncluded = <T>({
  previousReadData,
  readData,
  getDiffKey,
}: {
  previousReadData: T[]
  readData: T[]
  getDiffKey: GetArrayFieldDiffKey<T>
}) => {
  const prevValues = previousReadData.map(getDiffKey)

  const prevValuesIndices = prevValues.reduce((acc, val, i) => {
    const indices = acc[val]
    if (indices) {
      indices.push(i)
    } else {
      acc[val] = [i]
    }
    return acc
  }, {} as Record<string, number[]>)

  const currValues = readData?.map(getDiffKey)

  const currValuesIndices = currValues?.reduce((acc, val, i) => {
    const indices = acc[val]
    if (indices) {
      indices.push(i)
    } else {
      acc[val] = [i]
    }
    return acc
  }, {} as Record<string, number[]>)

  const deletedIndices = prevValues?.reduce((acc, prevVal) => {
    const prevValueIndices = prevValuesIndices[prevVal] ?? []
    const currValueIndices = currValuesIndices[prevVal] ?? []
    const numDeletions = prevValueIndices.length - currValueIndices.length
    acc.push(...prevValueIndices.slice(0, numDeletions))
    return acc
  }, [] as number[])

  const deletedData = deletedIndices.map((i) => previousReadData[i])
  return readData.concat(deletedData)
}

export const getHasUpdatedDataInRevision = (diff: unknown) => {
  if (typeof diff === 'undefined' || diff === null) return false
  if (typeof diff === 'object') {
    return !('txnToken' in diff) || Object.keys(diff).length > 1
  }
  return true
}

export const getHasUpdatedMetadataInRevision = (diff?: Partial<BaseMetadata> | null) => {
  if (typeof diff === 'undefined' || diff === null) return false
  if ('active' in diff) return true
  if (!('value' in diff)) return false
  return getHasUpdatedDataInRevision(diff.value)
}

export const getHasDeletedDataInRevision = (diff: unknown | null) => typeof diff === 'undefined' || diff === null

export const getArrayHasUpdatedDataInRevision = (diff?: Record<string, unknown> | null) => {
  if (typeof diff === 'undefined' || diff === null) return false
  return (Object.keys(diff) as (keyof typeof diff)[]).some((idx) => getHasUpdatedDataInRevision(diff[idx]))
}

export const getArrayHasUpdatedMetadataInRevision = (
  diff?: Record<string, Partial<BaseMetadata | null | undefined>> | null
) => {
  if (typeof diff === 'undefined' || diff === null) return false
  return (Object.keys(diff) as (keyof typeof diff)[]).some((idx) => getHasUpdatedMetadataInRevision(diff[idx]))
}

export const getArrayHasDeletedDataInRevision = (diff?: Record<string, unknown> | null) => {
  return (
    typeof diff === 'undefined' ||
    diff === null ||
    (Object.keys(diff) as (keyof typeof diff)[]).some((idx) => getHasDeletedDataInRevision(diff[idx]))
  )
}

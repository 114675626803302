import { createAsyncThunk } from '@reduxjs/toolkit'

import { State } from 'actions/store'
import { QueryName } from 'dashboards/shared/components/Dashboard/NamedQueryGroups.types'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import {
  generateActionMaker,
  generateNameMaker,
  makePushQueryThunk,
} from 'reducers/dashboards/dashboards.actions.helpers'
import { dashboardSelectorsMap } from 'reducers/dashboards/dashboards.selectors'
import { DashboardState } from 'reducers/dashboards/dashboards.types'
import { CombinatorEnum, SavedSearchRequest, SearchRequestInput, SortEnum, SortValueInput } from 'types/api'
import { ChildrenWithId, SearchFilterWithId, SearchRequest } from 'utils/query/api.types'

import { DashboardSlice, dashboardSlices } from './dashboards.constants'

type Page<T extends DashboardSlice> = DashboardState<T>['page']
type Filters<T extends DashboardSlice> = DashboardState<T>['filters']

type Sorts<T extends DashboardSlice> = Filters<T>['applied']['sorts']
type SearchInputWithParentId = ChildrenWithId & { parentID?: string }
export type ChangeViewPayload = SearchRequestInput & { title?: string }

const makeDashboardsActions = <T extends DashboardSlice>(slice: T) => {
  const makeName = generateNameMaker(slice)
  const makeAction = generateActionMaker(slice)
  const pushQueryThunk = makePushQueryThunk(slice)
  const dashboardsSelectors = dashboardSelectorsMap[slice]

  const dashboardsActions = {
    query: { set: pushQueryThunk<string>('query/set') },
    sorts: {
      reset: pushQueryThunk<void>('sorts/reset'),
      set: pushQueryThunk<Sorts<T>>('sorts/set'),
      addEmpty: makeAction<void>()('sorts/addEmpty'),
      remove: pushQueryThunk<string | undefined>('sorts/remove'),
      update: {
        field: pushQueryThunk<{ newField: string; oldField?: string }>('sorts/update/field'),
        direction: pushQueryThunk<{ field?: string; direction: SortEnum }>('sorts/update/direction'),
      },
    },
    filters: {
      /**
       * This action explictly avoids updating the url and should only be used
       * when we do not want to preserve any information in the url, such as navigating away from a dashboard.
       * Prefer `changeView` for changing filters in a way that should be kept in sync with the url.
       */
      setApplied: createAsyncThunk<
        { request: SearchRequest; name?: QueryName<DashboardSlice> },
        { name?: QueryName<DashboardSlice>; sorts?: SortValueInput[] },
        { state: State }
      >(makeName('filters/applied/set'), ({ name, sorts }, { getState }) => {
        const query = dashboardsSelectors.query(getState())
        return {
          request: {
            filter: {
              combinator: CombinatorEnum.And, // default combinator to And
              children: [],
            },
            query,
            sorts,
          },
          name,
        }
      }),
      changeView: pushQueryThunk<ChangeViewPayload>('filters/view/change'),
      createComponentById: pushQueryThunk<SearchInputWithParentId>('filters/component/createById'),
      updateComponentById: pushQueryThunk<SearchFilterWithId>('filters/component/updateById'),
      deleteComponentById: pushQueryThunk<{ id: string }>('filters/component/deleteById'),
      updateGroupById: makeAction<SearchInputWithParentId>()('filters/group/updateById'),
      remoteOpen: makeAction<boolean>()('filters/view/remoteOpen'),
    },
    currentViewTitle: {
      set: makeAction<string>()('currentViewTitle/set'),
    },
    customViews: {
      set: makeAction<SavedSearchRequest[]>()('customViews/set'),
    },
    page: {
      set: makeAction<Page<T>>()('page/set'),
      setSize: makeAction<Page<T>['size']>()('page/size/set'),
      setNumber: makeAction<Page<T>['number']>()('page/number/set'),
      setCount: makeAction<Page<T>['count']>()('page/count/set'),
    },
  }
  return dashboardsActions
}

export const dashboardActionsMap = dashboardSlices.reduce((acc, cur) => {
  acc[cur] = makeDashboardsActions(cur)
  return acc
}, {} as Record<DashboardSlice, ReturnType<typeof makeDashboardsActions>>)

export const useDashboardActions = () => {
  const dashboardContextValue = useDashboardContext()
  return dashboardActionsMap[dashboardContextValue]
}

import React, { ReactNode, useCallback, useEffect, useMemo } from 'react'

import { gql } from '@apollo/client'

import { Box, CircularProgress } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { partition } from 'lodash'

import { useParams } from 'react-router-dom'

import { loadInvestigation, navigateToInvestigation } from 'actions/investigationsActions'

import { useDispatch, useSelector } from 'actions/store'
import { GQLError } from 'components/GQLError'
import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'
import {
  LibraryObjectRelatedCasesFetchQuery,
  LibraryObjectRelatedCasesFetchQueryVariables,
} from 'components/entities/__generated__/RelatedCases.generated'

import Loader from 'components/library/Loader'
import { TableColumn } from 'components/library/Table'
import { PlainTable } from 'components/library/Table/PlainTable'

import { BatchSelectCell } from 'components/library/Table/SortAndFilterHeaderCell'
import { theme } from 'components/themeRedesign'

import BatchActionSelectCell from 'dashboards/shared/components/BatchActionSelectCell'

import { getBatchActionSelectUIState } from 'dashboards/shared/components/Dashboard/DashboardTable.helper'
import { hasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { NarrowedFetchPolicy, usePaginatedQuery } from 'hooks/ApolloHelpers'
import { useDateFormatter } from 'hooks/DateFormatHooks'
import { useCleanupBatchSelection } from 'hooks/UseCleanupBatchSelection'
import { useIntersectionObserver } from 'hooks/UseIntersectionObserver'

import { OpenInNewIcon } from 'icons'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'
import { ArrayElement } from 'utils/typeDerivations'

import { Section } from './Information/EntityInformationLayout'
import { LibraryGqlQueryType } from './LibraryQueries'
import { MergeRelatedCasesButton } from './MergeRelatedCasesButton'
import { useRedirectToMergedCaseIfOnCase } from './hooks/UseRedirectToMergedCaseIfOnCase'

export type RelatedCase = NonNullable<
  NonNullable<
    ArrayElement<
      NonNullable<
        NonNullable<NonNullable<LibraryObjectRelatedCasesFetchQuery['libraryByToken']>['relatedCases']>['edges']
      >
    >
  >['node']
>

export const LIBRARY_OBJECT_RELATED_CASES = gql`
  query LibraryObjectRelatedCasesFetch(
    $token: String!
    $type: LibraryTypeEnum!
    $caseLocked: Boolean!
    $txnToken: String
    $pageSize: Int!
    $after: String
  ) {
    libraryByToken(token: $token, type: $type, txnToken: $txnToken) {
      ... on LibraryObject {
        txnToken @include(if: $caseLocked)
        token
        relatedCases(first: $pageSize, after: $after) @connection(key: "paginatedTable") {
          totalCount
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
          edges {
            cursor
            node {
              token
              name
              updatedAt
              reviewCount
              blocked
            }
          }
        }
      }
    }
  }
`

export function useRelatedCases(
  type: LibraryGqlQueryType,
  token: string,
  txnToken?: string,
  fetchPolicy: NarrowedFetchPolicy = 'cache-and-network'
) {
  const queryVariables: Omit<LibraryObjectRelatedCasesFetchQueryVariables, 'pageSize'> = {
    txnToken: txnToken ?? null,
    caseLocked: !!txnToken,
    token,
    type,
    after: null,
  }

  const {
    error,
    loading,
    displayedData,
    hasNextPage,
    paginationProps: { onPageChange, onRefresh, page },
  } = usePaginatedQuery<LibraryObjectRelatedCasesFetchQuery, RelatedCase, LibraryObjectRelatedCasesFetchQueryVariables>(
    {
      selector: (data) => data?.libraryByToken?.relatedCases ?? null,
      query: LIBRARY_OBJECT_RELATED_CASES,
      args: queryVariables,
      pageSizes: [20],
      readAll: true,
      fetchPolicy,
    }
  )

  const onFetchMore = useCallback(() => {
    onPageChange(page + 1)
  }, [onPageChange, page])

  return {
    loading,
    error,
    displayedData,
    fetchMore: onFetchMore,
    onFetchMore,
    loadingMore: loading,
    hasNextPage,
    onRefresh,
  }
}

const batchEnabledColumnStyles = {
  headerHeight: null,
  columnWidths: [
    { column: 1, width: theme.spacing(3) },
    { column: 2, width: '33%' },
  ],
}
const batchDisabledColumnStyles = { headerHeight: null, columnWidths: [{ column: 1, width: '33%' }] }

const isRowSelectable = (row: RelatedCase) => !row.blocked

const useStyles = makeStyles((stylesTheme: Theme) => ({
  openInNew: {
    color: stylesTheme.palette.link.primary,
  },
  arrow: {
    color: theme.palette.styleguide.black,
    '&:before': {
      height: theme.spacing(0.3),
    },
  },
}))

function RelatedCasesTable({
  title,
  titleSize,
  cases,
  batchSelectEnabled = false, // not turned on either because feature flag is off or because the table is only showing "this case"
  children,
  caseToken,
  type,
  token,
  onBatchActionComplete,
}: {
  title: string
  titleSize: 'large' | 'normal'
  cases: RelatedCase[]
  caseToken?: string
  batchSelectEnabled?: boolean
  children?: ReactNode
  type: LibraryGqlQueryType
  token: string
  onBatchActionComplete: () => void
}) {
  const styles = useStyles()
  const dispatch = useDispatch()
  const onInvestigationClick = useCallback(
    (investigation: RelatedCase, event: React.MouseEvent) => {
      dispatch(loadInvestigation({ token: investigation.token, loadingToken: undefined })) // refresh case data
      dispatch(navigateToInvestigation(event, investigation.token))
    },
    [dispatch]
  )
  const formatDate = useDateFormatter('ll')
  const extractDate = useCallback(
    (t: RelatedCase) => (batchSelectEnabled ? formatDate(t.updatedAt) : ''),
    [formatDate, batchSelectEnabled]
  )
  const selectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const getIsRowSelected = (investigation: RelatedCase) => !!selectedItems[investigation.token]
  const isAllVisibleRowsSelected = cases.length > 0 && cases.every(getIsRowSelected)
  const isSomeItemBatchSelected = useSelector(batchActionsSelectors.isSomeItemBatchSelected)
  const batchActionSelectUIState = getBatchActionSelectUIState(isAllVisibleRowsSelected, isSomeItemBatchSelected)
  useCleanupBatchSelection()

  return (
    <Section
      title={title}
      titleSize={titleSize}
      borderless
      titleRow={
        batchSelectEnabled ? (
          <MergeRelatedCasesButton
            caseToken={caseToken}
            libraryEntityType={type}
            libraryEntityToken={token}
            onBatchActionComplete={onBatchActionComplete}
          />
        ) : null
      }
    >
      <PlainTable
        data={cases}
        uniqueKey="cases"
        emptyMessage="No related cases."
        rounded={false}
        onRowClick={onInvestigationClick}
        styleProps={batchSelectEnabled ? batchEnabledColumnStyles : batchDisabledColumnStyles}
      >
        {batchSelectEnabled ? (
          <TableColumn
            align="left"
            field="batch"
            header={() => (
              <BatchSelectCell
                onChange={() => {}}
                batchActionSelectUIState={batchActionSelectUIState}
                label="batch"
                field="batch"
                onBatchSelectAll={() => {
                  dispatch(
                    batchActionsActions.batchSelectItems.set({
                      tokens: cases.filter(isRowSelectable).map((entry) => entry.token),
                      value: true,
                    })
                  )
                }}
              />
            )}
            value={(investigation: RelatedCase) => (
              <BatchActionSelectCell
                item={investigation}
                disabled={!isRowSelectable(investigation)}
                disabledMessage="This case cannot be selected. Please contact customer support for assistance."
              />
            )}
          />
        ) : undefined}
        <TableColumn align="left" field="name" header="Name" />
        <TableColumn align="left" field="reviewCount" header="# of Reviews" />
        <TableColumn
          align="left"
          field="updatedAt"
          header={batchSelectEnabled ? 'Last Updated' : ''}
          value={extractDate}
        />
        <TableColumn
          align="center"
          field=""
          value={(args) => (
            <HbTooltip title="Opens a new browser tab" placement={Placement.Top} classes={styles} showArrow>
              {/* No need for no-referrer/no-opener here because we're opening our own page */}
              {/* eslint-disable-next-line react/jsx-no-target-blank */}
              <a
                href={`/dashboard/cases/${args.token}`}
                target="_blank"
                className={styles.openInNew}
                onClick={(e) => e.stopPropagation()}
              >
                <OpenInNewIcon />
              </a>
            </HbTooltip>
          )}
        />
      </PlainTable>
      {children}
    </Section>
  )
}

export function RelatedCases({
  type,
  token,
  refetchEntity,
}: {
  type: LibraryGqlQueryType
  token: string
  refetchEntity: () => void
}) {
  const [loaderRef, needsMore] = useIntersectionObserver()

  const { caseToken } = useParams<{ caseToken?: string }>()

  const { hasNextPage, loadingMore, error, onFetchMore, displayedData, onRefresh } = useRelatedCases(type, token)
  const redirectToNewCase = useRedirectToMergedCaseIfOnCase(caseToken)
  const isBatchSelectEnabled = useFeatureFlag(FeatureFlag.EnableCaseMerging)
  const hasMergeCasesPermission = useSelector((state) => hasPermission(state, BadgePermissionsEnum.MergeCases))
  const canMergeCases = isBatchSelectEnabled && hasMergeCasesPermission

  const onBatchActionComplete = useCallback(() => {
    setTimeout(() => {
      if (onRefresh) {
        onRefresh()
      }
      refetchEntity()
      redirectToNewCase()
    }, 1000) // refetch is powered by elasticsearch - we don't have a good way to definitely wait for it to be up to date.
  }, [onRefresh, refetchEntity, redirectToNewCase])

  useEffect(() => {
    if (needsMore && hasNextPage && !loadingMore) {
      onFetchMore()
    }
  }, [hasNextPage, loadingMore, needsMore, onFetchMore])

  const relatedCases = useMemo(() => displayedData.filter((datum): datum is RelatedCase => !!datum), [displayedData])

  const partitionCases = useCallback((t: RelatedCase) => t.token === caseToken, [caseToken])
  const [thisCaseArray, otherCases] = partition(relatedCases, partitionCases)

  if (loadingMore && !displayedData.length) {
    return <Loader />
  }

  if (error) {
    return <GQLError error={error} />
  }

  return (
    <Section title={null}>
      {thisCaseArray.length > 0 && ( // This table is visible both in and outside of specific case context. We might or might not have a "this" case.
        <RelatedCasesTable
          title="This Case"
          titleSize="large"
          cases={thisCaseArray}
          caseToken={caseToken}
          type={type}
          token={token}
          onBatchActionComplete={onBatchActionComplete}
        />
      )}
      <RelatedCasesTable
        titleSize="normal"
        title={`${caseToken ? 'Other ' : ''}Cases`}
        cases={otherCases}
        batchSelectEnabled={canMergeCases}
        caseToken={caseToken}
        type={type}
        token={token}
        onBatchActionComplete={onBatchActionComplete}
      >
        {hasNextPage && (
          <Box display="flex" justifyContent="center">
            <CircularProgress ref={loaderRef} />
          </Box>
        )}
      </RelatedCasesTable>
    </Section>
  )
}

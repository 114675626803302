import { useEffect, useMemo } from 'react'

import { MenuItem, Select, FormControl, FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { useSelector } from 'actions/store'
import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'

import { HbText } from 'components/HbComponents/Text/HbText'

import { reviewCanonicalTypeOptions } from 'components/pages/automations/editor/actions/shared'

import { getAccountReviewTypes } from 'helpers/stateHelpers'

import { Theme } from 'types/hb'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'
import { useThinControlStyles } from '../TriggerFilterEditor/styles'

import ActivityTagsByTask from './ActivityTagsByTask'
import NarrativePrepopulation from './components/NarrativePrepopulation'
import ToggleSection from './components/ToggleSection'
import { useReviewTypePrepopulatedFields } from './useReviewTypePrepopulatedFields'

import type { FormSchemaReturnType, FormSchema } from '../formSchema'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },
  selectCaseType: {
    paddingTop: theme.spacing(1),
  },
  caseNameField: {
    paddingBottom: theme.spacing(1),
  },
}))

const CREATE_INVESTIGATION_PARAMS_PATH = 'actionParams.createInvestigationParams'

export default function CustomizeCreateInvestigation({ form }: { form: FormSchemaReturnType }) {
  const classes = useStyles()
  const controlClasses = useThinControlStyles()
  const selectClasses = {
    className: controlClasses.control,
    classes: { root: controlClasses.selectRoot },
  }

  const reviewTypes = useSelector(getAccountReviewTypes)
  const options = useMemo(() => reviewCanonicalTypeOptions(reviewTypes), [reviewTypes])
  const { setValue, control, watch } = form

  const reviewTypeCanonicalId = watch(`${CREATE_INVESTIGATION_PARAMS_PATH}.reviewTypeCanonicalId`)
  const { tagggingTasks, hasNarrative: showNarrative } = useReviewTypePrepopulatedFields({
    canonicalId: reviewTypeCanonicalId,
  })

  useEffect(() => {
    if (!showNarrative) {
      setValue(`${CREATE_INVESTIGATION_PARAMS_PATH}.narrative`, null)
    }
  }, [setValue, showNarrative])

  const isRule = useIsAutomationRule()

  if (!options) return null
  return (
    <div className={classes.root}>
      <Controller<FormSchema>
        name={`${CREATE_INVESTIGATION_PARAMS_PATH}.reviewTypeCanonicalId`}
        render={({ field: { name, value, onChange, ...inputProps }, fieldState }) => (
          <FormControl error={fieldState.invalid}>
            <HbText size="s" bold color="secondary">
              Select Case Type
            </HbText>
            <div className={classes.selectCaseType}>
              <Select
                disabled={!isRule}
                name={name}
                value={value}
                {...selectClasses}
                variant="outlined"
                onChange={(e) => {
                  const newToken = e.target.value
                  const reviewType = options.find((def) => def.value === newToken)
                  invariant(reviewType, 'Case type not found')
                  const description = `Create Case of type ${reviewType.display}`
                  setValue('actionText', description)
                  setValue(`${CREATE_INVESTIGATION_PARAMS_PATH}.description`, description)
                  onChange(reviewType.value)
                }}
                inputProps={inputProps}
              >
                {options.map((type) => (
                  <MenuItem value={type.value} key={type.value}>
                    {type.display}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
      <div className={classes.caseNameField}>
        <HbRHFTextInput name={`${CREATE_INVESTIGATION_PARAMS_PATH}.name`} label="Name" />
      </div>
      {showNarrative || !!tagggingTasks.length ? (
        <HbText size="s" bold color="secondary">
          Review Information
        </HbText>
      ) : null}
      {showNarrative ? (
        <ToggleSection
          toggle={<HbText>Narrative</HbText>}
          content={<NarrativePrepopulation paramsPath={CREATE_INVESTIGATION_PARAMS_PATH} form={form} />}
          id="narrative"
        />
      ) : null}
      <Controller
        name={`${CREATE_INVESTIGATION_PARAMS_PATH}.activityTags`}
        control={control}
        defaultValue={[]}
        render={({ field }) => {
          const handleTagChange = field.onChange
          return <ActivityTagsByTask tagggingTasks={tagggingTasks} value={field.value} onChange={handleTagChange} />
        }}
      />
    </div>
  )
}

import { CSSProperties } from 'react'

import { StyledEngineProvider, ThemeProvider } from '@mui/material'
import { ThemeProvider as StylesThemeProvider } from '@mui/styles'

import { Provider } from 'react-redux'
import { Store } from 'redux'

import { Theme } from 'types/hb'

import { TableColumnElement } from './TableColumn'
import { getCellValue } from './TableRow'

interface MeasurementTableProps {
  column: TableColumnElement
  data: any[]
  store: Store
  theme: Theme
}

/**
 * This hidden table element is strictly rendered for measurement
 */
export const MeasurementColumn = ({ column, data, store, theme }: MeasurementTableProps) => {
  const { header } = column.props
  const cellContentStyles: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: '0 16px',
  }
  return (
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        {/* Two theme providers (one for @mui/material and one for @mui/styles),
      because there is some sort of communication issue between the two
      libraries and they're not using the same React context. */}
        <ThemeProvider theme={theme}>
          <StylesThemeProvider theme={theme}>
            <table>
              <thead style={{ visibility: 'collapse' }}>
                <tr>{header && (typeof header === 'string' ? header : header({ column }))}</tr>
              </thead>
              <tbody style={{ visibility: 'collapse' }}>
                {data.map((row) => (
                  <tr key={row.token}>
                    <td>
                      <div style={cellContentStyles}>{getCellValue(column, row)}</div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </StylesThemeProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </Provider>
  )
}

import { addClassNamesToElement, removeClassNamesFromElement } from '@lexical/utils'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { $isElementNode, $isRangeSelection, $isTextNode, ElementNode } from 'lexical'

import { Theme } from 'types/hb'

import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalEditor,
  LexicalNode,
  NodeKey,
  NodeSelection,
  RangeSelection,
  SerializedElementNode,
  Spread,
  TextNode,
} from 'lexical'

export type SerializedCommentMarkNode = Spread<
  {
    ids: Array<string>
    type: 'commentmark'
    version: 1
  },
  SerializedElementNode
>

export function $createCommentMarkNode(ids: Array<string>): CommentMarkNode {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return new CommentMarkNode(ids)
}

export function $isCommentMarkNode(node: LexicalNode | null): node is CommentMarkNode {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  return node instanceof CommentMarkNode
}

function convertCommentMarkElement(domNode: HTMLElement): DOMConversionOutput {
  // `HTMLSpanElements`s which represent mentions contain the '@' character, let's slice it off
  const node = $createCommentMarkNode(domNode.dataset.hbCommentTokens?.split(',') ?? [])
  return {
    node,
  }
}

export const useCommentMarkClasses = makeStyles((theme: Theme) => ({
  base: {
    backgroundColor: theme.palette.hues.lemon.light,
    '&.active': {
      backgroundColor: '#F4E09E',
    },
  },
  overlap: {
    backgroundColor: '#F5D368',
    '&.active': {
      backgroundColor: theme.palette.hues.lemon.medium,
    },
  },
}))

export class CommentMarkNode extends ElementNode {
  /** @internal */
  __ids: Array<string>

  static getType(): string {
    return 'commentmark'
  }

  static clone(node: CommentMarkNode): CommentMarkNode {
    return new CommentMarkNode(Array.from(node.__ids), node.__key)
  }

  static importJSON(serializedNode: SerializedCommentMarkNode): CommentMarkNode {
    const node = $createCommentMarkNode(serializedNode.ids)
    node.setFormat(serializedNode.format)
    node.setIndent(serializedNode.indent)
    node.setDirection(serializedNode.direction)
    return node
  }

  exportJSON(): SerializedCommentMarkNode {
    return {
      ...super.exportJSON(),
      ids: this.getIDs(),
      type: 'commentmark',
      version: 1,
    }
  }

  exportDOM(editor: LexicalEditor): DOMExportOutput {
    const res = super.exportDOM(editor)

    if (!res.element || !(res.element instanceof HTMLElement)) {
      return res
    }

    res.element.dataset.hbCommentTokens = this.getIDs().join(',')

    return res
  }

  static importDOM(): DOMConversionMap | null {
    return {
      mark: (domNode: HTMLElement) => {
        if (!domNode.dataset.hbCommentTokens) {
          return null
        }

        return {
          conversion: convertCommentMarkElement,
          priority: 1,
        }
      },
    }
  }

  constructor(ids: Array<string>, key?: NodeKey) {
    super(key)
    this.__ids = ids || []
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = document.createElement('mark')
    if (this.__ids.length > 1) {
      addClassNamesToElement(element, config.theme.commentMarkOverlap)
    } else {
      addClassNamesToElement(element, config.theme.commentMark)
    }
    return element
  }

  updateDOM(prevNode: CommentMarkNode, element: HTMLElement, config: EditorConfig): boolean {
    const prevIDs = prevNode.__ids
    const nextIDs = this.__ids
    const prevIDsCount = prevIDs.length
    const nextIDsCount = nextIDs.length

    if (prevIDsCount !== nextIDsCount) {
      if (nextIDsCount > 1) {
        removeClassNamesFromElement(element, config.theme.commentMark)
        addClassNamesToElement(element, config.theme.commentMarkOverlap)
      } else {
        removeClassNamesFromElement(element, config.theme.commentMarkOverlap)
        addClassNamesToElement(element, config.theme.commentMark)
      }
    }

    return false
  }

  hasID(id: string): boolean {
    const ids = this.getIDs()
    for (let i = 0; i < ids.length; i += 1) {
      if (id === ids[i]) {
        return true
      }
    }
    return false
  }

  getIDs(): Array<string> {
    const self = this.getLatest()
    return $isCommentMarkNode(self) ? self.__ids : []
  }

  addID(id: string): void {
    const self = this.getWritable()
    if ($isCommentMarkNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i += 1) {
        // If we already have it, don't add again
        if (id === ids[i]) return
      }
      ids.push(id)
    }
  }

  deleteID(id: string): void {
    const self = this.getWritable()
    if ($isCommentMarkNode(self)) {
      const ids = self.__ids
      self.__ids = ids
      for (let i = 0; i < ids.length; i += 1) {
        if (id === ids[i]) {
          ids.splice(i, 1)
          return
        }
      }
    }
  }

  insertNewAfter(selection: RangeSelection): null | ElementNode {
    const element = this.getParentOrThrow().insertNewAfter(selection)
    if ($isElementNode(element)) {
      const markNode = $createCommentMarkNode(this.__ids)
      element.append(markNode)
      return markNode
    }
    return null
  }

  // eslint-disable-next-line class-methods-use-this
  canInsertTextBefore(): false {
    return false
  }

  // eslint-disable-next-line class-methods-use-this
  canInsertTextAfter(): false {
    return false
  }

  // eslint-disable-next-line class-methods-use-this
  canBeEmpty(): false {
    return false
  }

  // eslint-disable-next-line class-methods-use-this
  isInline(): true {
    return true
  }

  extractWithChild(
    _child: LexicalNode,
    selection: RangeSelection | NodeSelection,
    destination: 'clone' | 'html'
  ): boolean {
    if (!$isRangeSelection(selection) || destination === 'html') {
      return false
    }
    const { anchor, focus } = selection
    const anchorNode = anchor.getNode()
    const focusNode = focus.getNode()
    const isBackward = selection.isBackward()
    const selectionLength = isBackward ? anchor.offset - focus.offset : focus.offset - anchor.offset
    return this.isParentOf(anchorNode) && this.isParentOf(focusNode) && this.getTextContent().length === selectionLength
  }
}

export type CommentMarkTarget = {
  nodes: Array<LexicalNode>
  anchorOffset: number
  focusOffset: number
  isBackward: boolean
}

export function wrapTargetWithCommentMarkNode(
  { nodes, anchorOffset, focusOffset, isBackward }: CommentMarkTarget,
  id: string
): void {
  const nodesLength = nodes.length
  const startOffset = isBackward ? focusOffset : anchorOffset
  const endOffset = isBackward ? anchorOffset : focusOffset
  let currentNodeParent
  let lastCreatedMarkNode

  // We only want wrap adjacent text nodes, line break nodes
  // and inline element nodes. For decorator nodes and block
  // element nodes, we step out of their boundary and start
  // again after, if there are more nodes.
  for (let i = 0; i < nodesLength; i += 1) {
    const node = nodes[i]
    if ($isElementNode(lastCreatedMarkNode) && lastCreatedMarkNode.isParentOf(node)) {
      // If the current node is a child of the last created mark node, there is nothing to do here
      // eslint-disable-next-line no-continue
      continue
    }
    const isFirstNode = i === 0
    const isLastNode = i === nodesLength - 1
    let targetNodeToWrap: LexicalNode | null = null

    if ($isTextNode(node)) {
      // Case 1: The node is a text node and we can split it
      const textContentSize = node.getTextContentSize()

      const startTextOffset = isFirstNode ? startOffset : 0
      const endTextOffset = isLastNode ? endOffset : textContentSize
      if (startTextOffset === 0 && endTextOffset === 0) {
        // eslint-disable-next-line no-continue
        continue
      }
      const splitNodes = node.splitText(startTextOffset, endTextOffset)
      targetNodeToWrap =
        splitNodes.length > 1 &&
        (splitNodes.length === 3 || (isFirstNode && !isLastNode) || endTextOffset === textContentSize)
          ? splitNodes[1]
          : splitNodes[0]
    } else if ($isCommentMarkNode(node)) {
      // Case 2: the node is a mark node and we can ignore it as a target,
      // moving on to its children. Note that when we make a mark inside
      // another mark, it may utlimately be unnested by a call to
      // `registerNestedElementResolver<MarkNode>` somewhere else in the
      // codebase.
      // eslint-disable-next-line no-continue
      continue
    } else if ($isElementNode(node) && node.isInline()) {
      // Case 3: inline element nodes can be added in their entirety to the new
      // mark
      targetNodeToWrap = node
    }

    if (targetNodeToWrap !== null) {
      // Now that we have a target node for wrapping with a mark, we can run
      // through special cases.
      if (targetNodeToWrap && targetNodeToWrap.is(currentNodeParent)) {
        // The current node is a child of the target node to be wrapped, there
        // is nothing to do here.
        // eslint-disable-next-line no-continue
        continue
      }
      const parentNode = targetNodeToWrap.getParent()
      if (parentNode == null || !parentNode.is(currentNodeParent)) {
        // If the parent node is not the current node's parent node, we can
        // clear the last created mark node.
        lastCreatedMarkNode = undefined
      }
      currentNodeParent = parentNode
      if (lastCreatedMarkNode === undefined) {
        // If we don't have a created mark node, we can make one
        lastCreatedMarkNode = $createCommentMarkNode([id])
        targetNodeToWrap.insertBefore(lastCreatedMarkNode)
      }

      // Add the target node to be wrapped in the latest created mark node
      lastCreatedMarkNode.append(targetNodeToWrap)
    } else {
      // If we don't have a target node to wrap we can clear our state and
      // continue on with the next node
      currentNodeParent = undefined
      lastCreatedMarkNode = undefined
    }
  }
}

export function $getCommentMarkIDs(node: TextNode, offset: number): null | Array<string> {
  let currentNode: LexicalNode | null = node
  while (currentNode !== null) {
    if ($isCommentMarkNode(currentNode)) {
      return currentNode.getIDs()
    }

    if ($isTextNode(currentNode) && offset === currentNode.getTextContentSize()) {
      const nextSibling = currentNode.getNextSibling()
      if ($isCommentMarkNode(nextSibling)) {
        return nextSibling.getIDs()
      }
    }

    currentNode = currentNode.getParent()
  }
  return null
}

export function $unwrapCommentMarkNode(node: CommentMarkNode): void {
  const children = node.getChildren()
  let target = null
  for (const child of children) {
    if (target === null) {
      node.insertBefore(child)
    } else {
      target.insertAfter(child)
    }
    target = child
  }
  node.remove()
}

import {
  PropsWithChildren,
  ReactElement,
  RefCallback,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { getOverflowAncestors } from '@floating-ui/dom'
import { Portal } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { noop, identity } from 'lodash'

import { Theme } from 'types/hb'

type Coordinates = Pick<DOMRect, 'x' | 'y'>

type TransformFunc = (rect: DOMRect) => Coordinates

type ContextType = {
  setContainerNode: (el: HTMLElement | null) => unknown
  setRootNode: (node: HTMLElement | null, transform: TransformFunc) => unknown
  addLeafNode: (node: HTMLElement, transform: TransformFunc) => () => unknown
}

const Context = createContext<ContextType>({
  setContainerNode: noop,
  setRootNode: noop,
  addLeafNode: () => noop,
})

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    pointerEvents: 'none',
    color: theme.palette.styleguide.borderDark,
  },
}))

export function DottedLineController({ children }: PropsWithChildren<unknown>) {
  const [containerNode, setContainerNode] = useState<HTMLElement | null>(null)
  const [rootNode, setRootNode] = useState<[HTMLElement, TransformFunc] | null>(null)
  const [leafNodes, setLeafNodes] = useState<Array<[HTMLElement, TransformFunc]>>([])

  useEffect(() => {
    if (!containerNode) {
      return noop
    }

    const ro = new ResizeObserver(() => {
      // Force update
      setLeafNodes((leaves) => [...leaves])
    })

    ro.observe(containerNode)

    return () => ro.disconnect()
  }, [containerNode])

  const addLeafNode = useCallback((node: HTMLElement, transform: TransformFunc) => {
    setLeafNodes((currentLeaves) => [...currentLeaves, [node, transform]])

    return function cleanupLeaf() {
      setLeafNodes((currentLeaves) => currentLeaves.filter(([child]) => child !== node))
    }
  }, [])

  const ctx = useMemo<ContextType>(
    () => ({
      setRootNode: (node, transform) => setRootNode(node ? [node, transform] : null),
      addLeafNode,
      setContainerNode,
    }),
    [addLeafNode]
  )

  const lines = useMemo(() => {
    if (!rootNode) {
      return []
    }

    const rootCoords = rootNode[1](rootNode[0].getBoundingClientRect())
    const containerCoords = containerNode?.getBoundingClientRect() ?? { x: 0, y: 0 }

    return leafNodes.map((leafRect) => {
      const leafCoords = leafRect[1](leafRect[0].getBoundingClientRect())

      return (
        <polyline
          key={`${leafCoords.x} ${leafCoords.y}`}
          fill="transparent"
          stroke="currentColor"
          strokeWidth={1}
          strokeDasharray="1 1"
          points={[
            [rootCoords.x - containerCoords.x, rootCoords.y - containerCoords.y],
            [rootCoords.x - containerCoords.x, leafCoords.y - containerCoords.y],
            [leafCoords.x - containerCoords.x, leafCoords.y - containerCoords.y],
          ]
            .map((p) => p.join(','))
            .join(' ')}
        />
      )
    })
  }, [containerNode, leafNodes, rootNode])

  const styles = useStyles()

  return (
    <Context.Provider value={ctx}>
      {children}
      <Portal container={containerNode}>
        <svg width="100%" height="100%" className={styles.root}>
          {lines}
        </svg>
      </Portal>
    </Context.Provider>
  )
}

export function DottedLineRoot({
  children,
  transform = identity,
}: {
  children: (ref: RefCallback<HTMLElement>) => ReactElement
  transform?: TransformFunc
}) {
  const ctx = useContext(Context)

  return children(function renderRoot(el: HTMLElement | null) {
    if (el) {
      ctx.setRootNode(el, transform)

      const ancestor = getOverflowAncestors(el).at(0) ?? null

      if (ancestor instanceof HTMLElement) {
        ctx.setContainerNode(ancestor)
      }
    } else {
      ctx.setRootNode(null, transform)
    }
  })
}

export function DottedLineLeaf({
  children,
  transform = identity,
}: {
  children: (ref: RefCallback<HTMLElement>) => ReactElement
  transform?: TransformFunc
}) {
  const [node, setNode] = useState<HTMLElement | null>(null)
  const ctx = useContext(Context)

  useEffect(() => {
    if (!node) {
      return noop
    }

    return ctx.addLeafNode(node, transform)
  }, [ctx, node, transform])

  return children(setNode)
}

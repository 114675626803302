import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const MergeIcon: FC<SvgProps> = (props) => (
  <SvgIcon viewBox="0 0 16 16" width="16" height="16" {...props}>
    <path
      d="M14.75 4.66h-3.4V1.25A.75.75 0 0 0 10.6.5H1.25a.75.75 0 0 0-.75.75v9.35a.75.75 0 0 0 .75.75h3.4v3.4a.75.75 0 0 0 .75.75h9.35a.75.75 0 0 0 .75-.75V5.41a.75.75 0 0 0-.75-.75ZM14 14H6.16v-3.4a.75.75 0 0 0-.75-.75H2V2h7.85v3.4a.75.75 0 0 0 .75.75H14V14Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default MergeIcon

import { FlagOutlined } from '@mui/icons-material'

import { ActionMap } from 'dashboards/shared/components/BatchActionsMenu/types'

import { DeleteIcon } from 'icons'
import { BatchActionTypeEnum } from 'types/api'

import { BatchDeleteDialog } from './dialogs/BatchDeleteDialog'
import { BatchFlagDialog } from './dialogs/BatchFlagDialog'
import { BatchUnflagDialog } from './dialogs/BatchUnflagDialog'

const actionMap: ActionMap = {
  [BatchActionTypeEnum.Flag]: { label: 'Flag', Dialog: BatchFlagDialog, Icon: FlagOutlined, DialogIcon: FlagOutlined },
  [BatchActionTypeEnum.Unflag]: {
    label: 'Unflag',
    Dialog: BatchUnflagDialog,
    Icon: FlagOutlined,
    DialogIcon: FlagOutlined,
  },
  [BatchActionTypeEnum.Delete]: {
    label: 'Delete',
    Dialog: BatchDeleteDialog,
    Icon: DeleteIcon,
    DialogIcon: DeleteIcon,
  },
}
export default actionMap

import { useState } from 'react'

import SlowMotionVideoIcon from '@mui/icons-material/SlowMotionVideo'
import { Box, styled } from '@mui/material'

import { HbButton } from 'components/HbComponents/HbButton'

import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'

import { useAutomationRuleToggle } from 'components/pages/automations/hooks/useAutomationRuleToggle'
import { useFeatureFlag } from 'hooks'
import { AutomationRuleExecutionMode, FeatureFlag } from 'types/api'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from '../__generated__/ViewAutomation.queries.generated'

import { PreviewModeActivePanel } from './PreviewModeActivePanel'

const StyledLink = styled(({ children, href, className }: { children: string; href: string; className?: string }) => (
  <a href={href} target="_blank" rel="noreferrer" className={className}>
    {children}
  </a>
))(({ theme }) => ({
  color: theme.palette.primary.main,
  textDecoration: 'none',
  fontWeight: 500,
}))

const PreviewModeButton = styled(HbButton)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

export const PreviewModePanel = ({
  automationRule,
  setEnablingOrDisabling,
  disabled,
}: {
  automationRule: AutomationRule
  setEnablingOrDisabling: (x: boolean) => void
  disabled: boolean
}) => {
  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)
  const { enableRule, loading: toggleRuleLoading } = useAutomationRuleToggle(automationRule)

  const isPreviewModeActive =
    automationRule.enabled && automationRule.executionMode === AutomationRuleExecutionMode.Preview

  const [nameError, setNameError] = useState(false)

  const activatePreviewMode = () => {
    if (!automationRule.name) {
      setNameError(true)
      return
    }

    enableRule({
      variables: {
        input: {
          token: automationRule.token,
          executionMode: AutomationRuleExecutionMode.Preview,
        },
        datasourcesEnabled,
      },
    })
  }

  const deactivatePreviewMode = () => {
    setEnablingOrDisabling(true)
  }

  if (!isPreviewModeActive || toggleRuleLoading) {
    return (
      <>
        <Box sx={{ margin: 2 }}>
          <HbText tag="p" block>
            Check the results of this automation in real time without affecting your organization&apos;s data. You will
            be able to manually apply the results after checking them.
          </HbText>
          <HbText tag="p" block>
            In this tab you’ll be able to check the preview results and activate them manually if you want them to run.
          </HbText>
          <StyledLink href="https://beta.docs.hummingbird.co/using-hummingbird/automations/preview-mode">
            Read more about preview mode
          </StyledLink>
          <PreviewModeButton
            disabled={automationRule.enabled || disabled}
            variant="secondary"
            label="Activate preview mode"
            Icon={SlowMotionVideoIcon}
            fullWidth
            onClick={activatePreviewMode}
          />
        </Box>
        <HbDialog
          id="name_error"
          open={nameError}
          onClose={() => setNameError(false)}
          title="Please Add a Title"
          content=""
          actions={<HbButton label="Continue" variant="warning" onClick={() => setNameError(false)} />}
        />
      </>
    )
  }
  return <PreviewModeActivePanel deactivatePreviewMode={deactivatePreviewMode} automationRule={automationRule} />
}

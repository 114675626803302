import { FormControl, MenuItem, Select, FormHelperText } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { HbCheckbox } from 'components/HbComponents/Form/HbCheckbox'

import { HbTag } from 'components/HbComponents/HbTag'
import { HbText } from 'components/HbComponents/Text/HbText'
import { useQueues } from 'components/cases/reviews/queues/useQueues'

import { Theme } from 'types/hb'

import { useIsAutomationRule } from '../AutomationRuleOrTemplateContext'

import type { FormSchema, FormSchemaReturnType } from '../formSchema'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
  },
  selectionContainer: {
    maxHeight: 42,
  },
  label: {
    paddingBottom: theme.spacing(),
  },
}))

function Queues() {
  const classes = useStyles()

  const { queues } = useQueues()

  const isRule = useIsAutomationRule()

  if (!queues) return null

  return (
    <Controller<FormSchema>
      name="actionParams.assignReviewToQueueParams.queueToken"
      render={({ field: { name, value, onChange, ...inputProps }, fieldState }) => (
        <FormControl error={fieldState.invalid}>
          <HbText size="s" bold color="secondary" className={classes.label}>
            Queue
          </HbText>
          <Select
            disabled={!isRule}
            className={classes.selectionContainer}
            name={name}
            value={value}
            variant="outlined"
            onChange={(e) => {
              const queueToken = e.target.value
              const queue = queues.find((def) => def.token === queueToken)
              invariant(queue, 'Queue not found')
              onChange(queue.token)
            }}
            inputProps={inputProps}
          >
            {queues.map((def) => (
              <MenuItem value={def.token} key={def.token}>
                <HbTag color={def.color} label={def.name} />
              </MenuItem>
            ))}
          </Select>
          <FormHelperText error={fieldState.invalid}>
            {fieldState.error?.message ||
              'When assigned to a new queue, any existing queue assignments will be replaced'}
          </FormHelperText>
        </FormControl>
      )}
    />
  )
}

export default function CustomizeAssignReviewToQueueAction({ form }: { form: FormSchemaReturnType }) {
  const classes = useStyles()
  const { control } = form

  return (
    <div className={classes.root}>
      <div>
        <HbText bold size="s" color="secondary">
          Options
        </HbText>
        <Controller
          control={control}
          name="actionParams.assignReviewToQueueParams.unassignAccount"
          render={({ field }) => (
            <div>
              <HbCheckbox {...field} checked={field.value} />
              <HbText size="s">Remove Current Assignee</HbText>
            </div>
          )}
        />
      </div>
      <Queues />
    </div>
  )
}

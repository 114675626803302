import { useMemo } from 'react'

import MaliciousIcon from '@mui/icons-material/GppBadRounded'
import FailedIcon from '@mui/icons-material/GppMaybeRounded'
import WaitingIcon from '@mui/icons-material/SafetyCheckRounded'

import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { HBCOLORS, HBGREYS } from 'components/colors'
import { AttachmentMalwareScanStatus } from 'types/api'
import { assertUnreachable } from 'types/hb'

function getTitleAndIcon(malwareScanStatus: AttachmentMalwareScanStatus) {
  switch (malwareScanStatus) {
    case AttachmentMalwareScanStatus.NoThreatsFound:
      return null
    case AttachmentMalwareScanStatus.Waiting:
      return {
        title: 'Waiting for malware scan to complete',
        icon: <WaitingIcon htmlColor={HBGREYS.textGreyLight} />,
      }
    case AttachmentMalwareScanStatus.Failed:
    case AttachmentMalwareScanStatus.AccessDenied:
    case AttachmentMalwareScanStatus.Unsupported:
      return {
        title: 'Malware scan failed',
        icon: <FailedIcon htmlColor={HBCOLORS.salmonMedium} />,
      }
    case AttachmentMalwareScanStatus.ThreatsFound:
      return {
        title: 'This file is malicious and cannot be downloaded or viewed',
        icon: <MaliciousIcon htmlColor={HBCOLORS.errorMedium} />,
      }
    default:
      assertUnreachable(malwareScanStatus)
      return null
  }
}

interface Props {
  malwareScanStatus: AttachmentMalwareScanStatus
}
export function AttachmentMalwareScanStatusIcon({ malwareScanStatus }: Props) {
  const maybeTitleAndIcon = useMemo(() => getTitleAndIcon(malwareScanStatus), [malwareScanStatus])

  if (!maybeTitleAndIcon) return null

  const { title, icon } = maybeTitleAndIcon

  return <HbTooltip title={title}>{icon}</HbTooltip>
}

import React from 'react'

import { useHistory } from 'react-router-dom'

import { Thunk } from 'actions/store'
import { KeyboardOrMouseEvent } from 'types/hb'

export function shouldOpenNewTab(event: KeyboardOrMouseEvent | undefined) {
  return event ? event.metaKey || event.ctrlKey : false
}

/**
 * Emulate browser on-click behavior for plain old links when
 * triggering navigation from javascript. If a modifier key is
 * pressed, the navigation opens in a new tab, and if not
 * navigation occurs in the same tab, just like links.
 *
 * Only use for internal and trusted links. There are security implications of the underlying
 * window.open if an arbitrary link is used.
 *
 * @param defaultPath If provided, the returned function can only go to this URL. If not provided,
 * the returned function must be provided a path each time it is called.
 */
export function useEmulatedLinkOnClick(
  defaultPath?: string
): ((event: React.MouseEvent) => void) | ((event: React.MouseEvent, path: string) => void) {
  const history = useHistory()

  const handlerFn = (event: React.MouseEvent, path: string) => {
    if (shouldOpenNewTab(event)) {
      window.open(path)
    } else {
      history.push(path)
    }
  }

  if (defaultPath) {
    return (event: React.MouseEvent) => handlerFn(event, defaultPath)
  }

  return handlerFn
}

export function navigateToCompiledUrl(
  event: KeyboardOrMouseEvent | undefined,
  to: string,
  openInNewTab: boolean = false
): Thunk<Promise<boolean>> {
  return (dispatch, getState, { pilot }) => {
    if (openInNewTab || shouldOpenNewTab(event)) {
      // eslint-disable-next-line security/detect-non-literal-fs-filename
      window.open(to)
    } else {
      dispatch(pilot.goToUrl(to))
    }
    return Promise.resolve(true)
  }
}

export function navigateToInternalUrl(
  event: KeyboardOrMouseEvent | undefined,
  urlPattern: string,
  options: Record<string, string> = {}
): Thunk<Promise<boolean>> {
  return (dispatch, getState, { pilot }) => {
    return dispatch(navigateToCompiledUrl(event, pilot.compile(urlPattern, options)))
  }
}

import { isFunction } from 'lodash'

import { DashboardSlice, dashboardSlices } from 'reducers/dashboards/dashboards.constants'

/** Useful for hydrating simpler dashboard configurations, where many entries are identical and/or empty.
 *
 * In order to avoid situations where we forget to add a dashboard config entry for a new type, avoid
 * using this factory in scenarios where we *want* the lack of an entry to cause a TS failure, such as the main dashboard config.
 */
const makeDashboardConfig = <T>(
  fallbackConfig: ((dashboardSlice: DashboardSlice) => T) | T,
  preloadedConfig: Partial<Record<DashboardSlice, T>> = {}
): Record<DashboardSlice, T> =>
  dashboardSlices.reduce(
    (accum, slice) => {
      if (slice in accum) {
        return accum
      }
      accum[slice] = isFunction(fallbackConfig) ? fallbackConfig(slice) : fallbackConfig
      return accum
    },
    { ...preloadedConfig } as Record<DashboardSlice, T>
  )

export default makeDashboardConfig

import React from 'react'

import { Switch, withRouter, RouteComponentProps } from 'react-router-dom'

import { HbRoute } from '../Page/HbRoute'

const getPath = (path: string, { name }: { name: string }) => `${path}/${name}`

interface Step<T> {
  name: string
  props?: T
  Component: React.ComponentType<T>
}

type Props = RouteComponentProps & {
  /* Callback fired when last step of onboarding is completed */
  onComplete: (input?: any) => void
  path: string
  steps: Step<any>[]
}

class Steps extends React.PureComponent<Props> {
  /* Returns callbacks for navigating between onboarding steps (`next`, `back`) */
  navigationCallbacks = (stepIndex: number) => {
    const { steps, onComplete } = this.props

    const isFirstStep = stepIndex === 0
    const isLastStep = stepIndex === steps.length - 1

    return {
      ...(isFirstStep ? {} : { back: this.navigateTo(stepIndex - 1) }),
      ...(isLastStep ? { next: onComplete } : { next: this.navigateTo(stepIndex + 1) }),
    }
  }

  navigateTo(stepIndex: number) {
    const { history, path, steps } = this.props

    if (stepIndex < 0 || stepIndex >= steps.length) {
      throw new Error('Trying to navigate to an onboarding step that is out of range')
    }

    return () => {
      history.push(getPath(path, steps[stepIndex]))
    }
  }

  render() {
    const { path, steps } = this.props

    return (
      <Switch>
        {steps.map(({ name, props, Component }, stepIndex) => (
          <HbRoute
            exact
            key={name}
            path={getPath(path, steps[stepIndex])}
            render={() => <Component {...props} {...this.navigationCallbacks(stepIndex)} />}
          />
        ))}
      </Switch>
    )
  }
}

export default withRouter(Steps)

import invariant from 'tiny-invariant'

import { AutomationRuleType } from 'types/api'

import Step from '../Step'
import { TriggerFilterEditor } from '../TriggerFilterEditor'
import { FormSchemaReturnType, TriggerSchemaReturnType } from '../formSchema'

interface Props {
  form: FormSchemaReturnType
}

export function ViewTriggerAutomationStep({ form }: Props) {
  const { watch } = form

  const [domainType, automationType] = watch(['domain.type', 'automationType'])

  invariant(domainType, 'Missing domain type!')

  return (
    <Step key="whenStep" title="If..." textField="eventText" type={domainType} showCollapseButton>
      {(automationType === AutomationRuleType.Trigger || automationType === AutomationRuleType.ScheduleWithTrigger) && (
        // can't infer type using watch(), but if automationType is trigger, then the form must be a TriggerSchemaReturnType
        <TriggerFilterEditor form={form as TriggerSchemaReturnType} />
      )}
    </Step>
  )
}

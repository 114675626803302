import { ReactNode, useEffect, useRef, useState } from 'react'

import { Email, Lock } from '@mui/icons-material'

import { FormikHelpers } from 'formik'

import * as Yup from 'yup'

import { checkEmail, signIn } from 'actions/accountActions'
import { setFlashNotice } from 'actions/applicationActions'
import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'
import { TextField } from 'components/library/Onboarding'
import FormStep from 'components/library/Onboarding/FormStep'
import { AuthenticationType } from 'components/onboarding/account/AccountTypes'
import { FIELD_REQUIRED } from 'helpers/validations'

const initialValues = {
  email: '',
  password: '',
}

interface Form {
  email?: string
  password?: string
}

interface Props {
  next: (arg: any) => unknown
  additionalActions: ReactNode
}

export default function SignIn(props: Props) {
  const { next, additionalActions } = props

  const dispatch = useDispatch()
  const [email, setEmail] = useState<string | null>(null)
  const pwRef = useRef<HTMLInputElement | null>(null)

  useEffect(() => {
    // Delay focus until after DOM is updated, otherwise it doesn't work
    if (pwRef.current) {
      setTimeout(() => pwRef.current?.focus(), 0)
    }
  })

  const resetForm = (actions: FormikHelpers<any>) => {
    setEmail(null)
    actions.setSubmitting(false)
    actions.resetForm()
  }

  async function lookupAuthenticationByEmail(lookupEmail: string | undefined, actions: FormikHelpers<Form>) {
    if (!lookupEmail) {
      return
    }

    try {
      const { authentication } = await dispatch(checkEmail(lookupEmail))

      if (!authentication) {
        dispatch(setFlashError('There was a problem with login. Please try again.'))
        return
      }

      // prefer redirectUrl if we receive one. That's OAuth, WorkOS.
      if (authentication.redirectUrl) {
        next(authentication.redirectUrl)
      } else if (authentication.authenticationType === AuthenticationType.WorkOSMagicLink) {
        dispatch(setFlashNotice('Check your email for a sign-in link.'))
      } else {
        setEmail(lookupEmail)
      }
    } catch (error) {
      dispatch(setFlashError('Something went wrong.'))
      throw error
    } finally {
      actions.setSubmitting(false)
    }
  }

  async function handleSignIn(values: Form, actions: FormikHelpers<Form>) {
    try {
      if (!email || !values.password) {
        throw new Error('No credentials provided')
      }

      const { authenticated, location } = await dispatch(signIn(email, values.password))

      if (authenticated) {
        next(location)

        // Since we're waiting on a redirect,
        // return an endless promise to formik so the form
        // stays disabled until we are redirected
        return new Promise((_) => {})
      }

      dispatch(setFlashError('Invalid email or password.'))
      resetForm(actions)
    } catch (error) {
      dispatch(setFlashError('Something went wrong.'))
      resetForm(actions)
      throw error
    }

    return null
  }

  /*
   * NOTE: Per UI specs, we want the logo centered with the dialog paper
   * so we ignore showing anchored logo at the top (`logo={false}`) and
   * instead show logo as the title (`title={<WhiteLogo />}`)
   */
  const commonProps = {
    initialValues,
    additionalActions,
    title: 'Welcome back',
  }

  return email === null ? (
    <FormStep
      {...commonProps}
      onNext={(values: Form, actions: FormikHelpers<Form>) => {
        lookupAuthenticationByEmail(values.email, actions)
      }}
      nextLabel="Next"
      validationSchema={Yup.object().shape({
        email: Yup.string().required(FIELD_REQUIRED).email(),
      })}
    >
      <TextField
        key="email"
        autoFocus
        fullWidth
        type="email"
        name="email"
        label="Your email"
        autoComplete="on"
        Icon={Email}
      />
    </FormStep>
  ) : (
    <FormStep
      {...commonProps}
      /* eslint-disable-next-line react/jsx-no-bind */
      onNext={handleSignIn}
      nextLabel="Sign In"
      validationSchema={Yup.object().shape({
        password: Yup.string().required(FIELD_REQUIRED),
      })}
    >
      <TextField
        key="password"
        inputProps={{
          ref: pwRef,
        }}
        fullWidth
        type="password"
        name="password"
        label="Your password"
        Icon={Lock}
      />
    </FormStep>
  )
}

import { useMemo } from 'react'

import { useTheme } from '@emotion/react'
import { ArrowBackRounded } from '@mui/icons-material'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import { getTemplateCategoryStyles } from '../AutomationRuleTemplate/AutomationRuleTemplate.utils'
import { AutomationRuleTemplateIcon } from '../AutomationRuleTemplate/AutomationRuleTemplateIcon'
import { RecipeCategoryCards } from '../RecipeCategoryCards'

import {
  GradientHeader,
  GradientHeaderContent,
  RecipeCategoryCardsWrapper,
  SearchInput,
  SmallSearchWrapper,
  StyledSearchIcon,
} from './ExploreAutomationHeader.styles'
import {
  useCategoryAndSearchChangeHandlers,
  CategoryAndSearchQuery,
  CategoryWithCountBySlug,
} from './ExploreAutomations.hooks'

const getPlaceholderText = (findByTerms: Array<string>) => `Find recipes by ${findByTerms.join(', ')}...`

type HeaderVariantProps = ReturnType<typeof useCategoryAndSearchChangeHandlers> &
  CategoryAndSearchQuery & { categoryWithCountBySlug: CategoryWithCountBySlug }

export const AllCategoriesHeader = ({
  updateCategoryAndSearch,
  handleSearchChange,
  categoryWithCountBySlug,
}: HeaderVariantProps) => {
  const categoryOptions = useMemo(
    () =>
      Object.values(categoryWithCountBySlug).sort(
        (a, b) => a.category?.name.localeCompare(b.category?.name || '') || 0
      ),
    [categoryWithCountBySlug]
  )
  return (
    <GradientHeader>
      <GradientHeaderContent>
        <HbText size="xl" bold tag="h2">
          Automate the work of your team and keep track of everything
        </HbText>
        <SearchInput
          fullWidth
          size="medium"
          onChange={handleSearchChange}
          startAdornment={<StyledSearchIcon />}
          placeholder={getPlaceholderText(['action', 'trigger', 'category'])}
        />
      </GradientHeaderContent>
      <RecipeCategoryCardsWrapper>
        <RecipeCategoryCards
          categories={categoryOptions}
          onClick={(newCategory) => updateCategoryAndSearch(newCategory)}
        />
      </RecipeCategoryCardsWrapper>
    </GradientHeader>
  )
}

export const SingleCategoryHeader = ({
  handleSearchChange,
  updateCategoryAndSearch,
  category: categorySlug,
  categoryWithCountBySlug,
}: HeaderVariantProps) => {
  const theme = useTheme()
  const category = categorySlug ? categoryWithCountBySlug[categorySlug]?.category : null

  const templateIconProps = category ? getTemplateCategoryStyles(category.style) : null

  return (
    <>
      <SmallSearchWrapper>
        <HbButton
          Icon={ArrowBackRounded}
          label="Back to Explore"
          variant="textSecondary"
          onClick={() => {
            updateCategoryAndSearch()
          }}
        />
        <SearchInput
          onChange={handleSearchChange}
          startAdornment={<StyledSearchIcon />}
          placeholder={getPlaceholderText(['action', 'trigger'])}
        />
      </SmallSearchWrapper>
      <HbText
        size="xl"
        bold
        tag="h1"
        css={{ marginBottom: theme.spacing(3), display: 'flex', alignItems: 'center', gap: theme.spacing(2) }}
      >
        {templateIconProps && <AutomationRuleTemplateIcon {...templateIconProps} />}
        {category?.name || 'This category could not be found.'}
      </HbText>
    </>
  )
}

export const ExploreAutomationsHeader = ({
  category,
  search,
  categoryWithCountBySlug,
}: CategoryAndSearchQuery & { categoryWithCountBySlug: CategoryWithCountBySlug }) => {
  const changeHandlers = useCategoryAndSearchChangeHandlers({ category, search })
  const props: HeaderVariantProps = {
    ...changeHandlers,
    search,
    category,
    categoryWithCountBySlug,
  }
  if (!category || category === 'all') {
    return <AllCategoriesHeader {...props} />
  }
  return <SingleCategoryHeader {...props} />
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const ChangeCircleOutlined: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M10.0001 1.6665C5.40008 1.6665 1.66675 5.39984 1.66675 9.99984C1.66675 14.5998 5.40008 18.3332 10.0001 18.3332C14.6001 18.3332 18.3334 14.5998 18.3334 9.99984C18.3334 5.39984 14.6001 1.6665 10.0001 1.6665ZM10.0001 16.6665C6.32508 16.6665 3.33341 13.6748 3.33341 9.99984C3.33341 6.32484 6.32508 3.33317 10.0001 3.33317C13.6751 3.33317 16.6667 6.32484 16.6667 9.99984C16.6667 13.6748 13.6751 16.6665 10.0001 16.6665ZM13.4751 12.2998L12.5584 11.3832C13.1501 10.2748 13.0001 8.87484 12.0667 7.9415C11.4917 7.3665 10.7501 7.08317 10.0001 7.08317C9.97508 7.08317 9.95008 7.0915 9.92508 7.0915L10.8334 7.99984L9.95008 8.88317L7.59175 6.52484L9.95008 4.1665L10.8334 5.04984L10.0334 5.84984C11.0917 5.85817 12.1417 6.24984 12.9501 7.04984C14.3667 8.47484 14.5417 10.6832 13.4751 12.2998ZM12.4084 13.4748L10.0501 15.8332L9.16675 14.9498L9.95842 14.1582C8.90841 14.1498 7.85842 13.7415 7.05842 12.9415C5.63341 11.5165 5.45841 9.3165 6.52508 7.69984L7.44175 8.6165C6.85008 9.72484 7.00008 11.1248 7.93342 12.0582C8.51675 12.6415 9.29175 12.9248 10.0667 12.8998L9.16675 11.9998L10.0501 11.1165L12.4084 13.4748Z"
      fill="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default ChangeCircleOutlined

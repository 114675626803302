import { useMemo, useRef } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { DatePicker, DatePickerProps } from '@mui/x-date-pickers'

import { Form, Formik, FormikHelpers } from 'formik'

import { keyBy } from 'lodash'
import moment, { Moment } from 'moment'
import { useDebouncedCallback } from 'use-debounce'

import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'
import { ShowAfterDelay } from 'components/library/ShowAfterDelay'
import { NumberInputV2, TextInputV2 } from 'components/material/Form'
import {
  isRelation,
  RelationField,
} from 'dashboards/reviews/components/Header/filtering/FilterButton/fields/RelationField'
import { useCaseSubjectField } from 'dashboards/shared/hooks/useCaseSubjectField'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useGetHeaderFilterValuesV2 } from 'dashboards/shared/hooks/useGetDashboardTableHeaderFilterValuesV2'

import { useIsSearchableField } from 'dashboards/shared/hooks/useIsSearchableField'
import { isOtherInfoColumn } from 'dashboards/shared/react/isOtherInfoColumn'
import { titleCase } from 'helpers/uiHelpers'
import { TermsTypeEnum, OperatorEnum } from 'types/api'
import { Option } from 'types/hb'

import { apiNamesSupportingLegacyInOperatorUX } from './OperatorSelect'
import { CaseDataFilterField } from './fields/CaseDataFilterField'
import InOperatorField from './fields/InOperatorField'

import { SearchableFilterField } from './fields/SearchableFilterField'
import {
  canPerformDateSearch,
  canPerformNumericSearch,
  fieldUsesSearchForIsOperator,
  isMultiSelectOperator,
  isRangeOperator,
} from './helpers'

const useStyles = makeStyles((theme) => ({
  searchable: { flexGrow: 1, minWidth: theme.spacing(22) },
  formContainer: { display: 'flex' },
  dateInput: {
    margin: 0,
  },
}))

function FilterValue({
  fieldApiName,
  operator,
  onChange,
  values,
  styles,
  aggsKey,
}: {
  fieldApiName: string
  operator: OperatorEnum
  onChange: (newValue: string) => void
  values?: string[] // maintaining the values array, but for now this will be a single value
  styles?: string
  aggsKey?: string
}) {
  const classes = useStyles()

  const { filterableColumns } = useDashboardColumns()

  const { getHeaderFilterValues, loading } = useGetHeaderFilterValuesV2(aggsKey)
  const createValues = useMemo(() => getHeaderFilterValues(), [getHeaderFilterValues])

  const selectorValues = useMemo(() => createValues?.(), [createValues])
  const currentValue = values?.[0]

  const fieldType = filterableColumns.find((column) => column.apiName === fieldApiName)?.type

  const debouncedOnChange = useDebouncedCallback((value) => onChange(value), 300)
  const isSearchableField = useIsSearchableField()
  const caseSubjectField = useCaseSubjectField()
  const defaultValue = values?.[0] ?? ''
  const initialDefaultValueRef = useRef({ defaultValue, fieldApiName })
  const initialDefaultValue = initialDefaultValueRef.current.defaultValue
  const initialFieldApiName = initialDefaultValueRef.current.fieldApiName

  const pickableValues = useMemo<Option[]>(() => {
    const pickableValuesFromCase = selectorValues ? [...selectorValues] : []
    if (initialDefaultValue && !selectorValues?.some((selectorValue) => selectorValue.value === initialDefaultValue)) {
      // We're looking at a filter that has a value we cannot currently select. Iff the initial value was from the same field, we should
      // 1. Display that value
      // 2. Keep it in the pickable list

      if (fieldApiName === initialFieldApiName) {
        // titleCase is a reasonable best guess.
        pickableValuesFromCase.unshift({ value: initialDefaultValue, display: titleCase(initialDefaultValue) })
      }
    }
    return pickableValuesFromCase
  }, [fieldApiName, initialDefaultValue, initialFieldApiName, selectorValues])

  const pickableValuesByValue = useMemo(() => keyBy(pickableValues, (value) => value.value), [pickableValues])
  const pickedValues = useMemo(
    () => values?.map((value) => pickableValuesByValue[value]),
    [pickableValuesByValue, values]
  )
  const pickedValue = useMemo(() => pickedValues?.[0], [pickedValues])

  const resetFormSubmissionStatus = (_value: unknown, formik: FormikHelpers<unknown>) => {
    formik.setSubmitting(false)
  }

  const handleOnChangeDate: DatePickerProps<Moment>['onChange'] = (newValue) => {
    if (!newValue) {
      return
    }
    const parsedValue: string = newValue.format('YYYY-MM-DD')

    if (canPerformDateSearch(parsedValue)) {
      debouncedOnChange(parsedValue)
    }
  }

  if (isRelation(fieldApiName)) {
    return <RelationField currentValue={currentValue} onChange={onChange} styles={styles} fieldName={fieldApiName} />
  }

  const caseSubjectFieldTypes = caseSubjectField(fieldApiName)
  if (caseSubjectFieldTypes) {
    return (
      <CaseDataFilterField
        fieldApiName={fieldApiName}
        subjectTypes={caseSubjectFieldTypes}
        currentValue={currentValue}
        onChange={onChange}
        styles={styles}
        multiple={false}
      />
    )
  }

  if (isMultiSelectOperator(operator)) {
    // If searchable, use autocomplete UI.
    const fallbackUI = <InOperatorField onChange={onChange} currentValue={values?.join(', ')} />
    const isLegacyInOperatorUX = apiNamesSupportingLegacyInOperatorUX.has(fieldApiName)
    if (isLegacyInOperatorUX) {
      return fallbackUI
    }

    return (
      <SearchableFilterField
        key="multi"
        currentValue={values}
        onChange={onChange}
        styles={styles}
        fieldApiName={fieldApiName}
        aggsKey={aggsKey}
        multiple
      />
    )
  }

  if (fieldType === TermsTypeEnum.Date) {
    return (
      <DatePicker
        className={classes.dateInput}
        name="date"
        minDate={moment('0000-01-01')}
        value={currentValue ? moment(currentValue) : undefined}
        format="MM/DD/YYYY"
        onChange={handleOnChangeDate}
        slotProps={{
          textField: {
            margin: 'normal',
            size: 'small',
            variant: 'outlined',
          },
        }}
      />
    )
  }

  if (fieldType === TermsTypeEnum.Number) {
    return (
      <Formik<{ number?: string }>
        validateOnChange
        onSubmit={resetFormSubmissionStatus}
        validate={({ number }) => {
          // '-' and '.' are not valid things to search on, don't attempt to
          if (number !== currentValue && canPerformNumericSearch(number)) {
            debouncedOnChange(number)
          }

          return {}
        }}
        initialValues={{ number: currentValue || undefined }}
      >
        <Form className={classes.formContainer}>
          <NumberInputV2 name="number" testId={`${fieldApiName}:number:input`} noSpacing />
        </Form>
      </Formik>
    )
  }

  // Only search for options to choose when filter operator requires an option.
  // If "Contains" - user can type anything and we want to search based on what they've typed instead of searching for additional filter options.

  if (isSearchableField(fieldApiName) && operator !== OperatorEnum.Contains) {
    return (
      <SearchableFilterField
        key="singular"
        currentValue={currentValue}
        onChange={onChange}
        styles={styles}
        fieldApiName={fieldApiName}
        aggsKey={aggsKey}
        multiple={false}
      />
    )
  }

  const shouldUseTextInputDueToOtherInfoColumn = isOtherInfoColumn(fieldApiName) && isRangeOperator(operator)

  if (
    shouldUseTextInputDueToOtherInfoColumn ||
    (fieldType === TermsTypeEnum.String &&
      (operator === OperatorEnum.Contains || fieldUsesSearchForIsOperator(fieldApiName)))
  ) {
    return (
      <Formik<{ value?: string }>
        validateOnChange
        onSubmit={resetFormSubmissionStatus}
        validate={({ value }) => {
          if (value && value !== currentValue) {
            // All IS fields that hide aggregations are indexed as lower case, but the searching itself is case sensitive.
            // We need to lower case here. Ideally BE defines/handles this.
            debouncedOnChange(fieldUsesSearchForIsOperator(fieldApiName) ? value.toLowerCase() : value)
          }
          return {}
        }}
        initialValues={{ value: currentValue || undefined }}
      >
        <Form className={classes.formContainer}>
          <TextInputV2 name="value" testId={`${fieldApiName}:value:input`} noSpacing />
        </Form>
      </Formik>
    )
  }

  return (
    <Select
      id="filter-value-selector"
      variant="outlined"
      className={styles}
      value={defaultValue}
      disabled={!selectorValues?.length}
      displayEmpty
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        onChange(e.target.value)
      }}
      data-testid={`filter:value:${defaultValue || '__blank__'}`}
      renderValue={(value) =>
        value === pickedValue?.value
          ? pickedValue?.display
          : value || (
              <ShowAfterDelay>
                <Loader loading={loading} indicatorType="circular" size={20} colorVariant="primary" />
              </ShowAfterDelay>
            )
      }
    >
      {pickableValues.map(({ display, value }) => (
        <MenuItem key={value} value={value} data-testid={`filter:value:option:${display}`}>
          <HbText>{display}</HbText>
        </MenuItem>
      ))}
    </Select>
  )
}

export default FilterValue

import { ChangeEvent } from 'react'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import HbNumberInput, { HbNumberInputProps } from 'components/HbComponents/Form/HbInputs/HbNumberInput/HbNumberInput'

/** *
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
export function HbRHFNumberInput<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ control, name, rules, onChange, ...rest }: UseControllerProps<TFieldValues, TName> & HbNumberInputProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <HbNumberInput
            {...rest}
            {...field}
            onChange={(value: ChangeEvent<HTMLInputElement>) => {
              field.onChange(value)
              if (onChange) {
                onChange(value)
              }
            }}
            ref={null}
            inputRef={field.ref}
            errors={fieldState.error?.message}
          />
        )
      }}
    />
  )
}

export default HbRHFNumberInput

import { HbButton } from 'components/HbComponents/HbButton'

import ExportCSV from './ExportCSV'

export const ExportCases = (props: { className: string }) => {
  const { className } = props
  return (
    <ExportCSV
      renderButton={(onClick, Icon, disabled) => (
        <HbButton
          className={className}
          onClick={onClick}
          Icon={Icon}
          variant="textSecondary"
          label="Download Case Data"
          disabled={disabled}
        />
      )}
    />
  )
}

import { useCallback, useMemo } from 'react'

import { gql } from '@apollo/client'

import { useSelector } from 'actions/store'

import { BatchActionContext } from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionMenuItem } from 'dashboards/shared/components/BatchActionsMenu/BatchActionMenuItem'

import { useBatchActionLookups } from 'dashboards/shared/components/BatchActionsMenu/useBatchActionLookups'
import { useRefetchOnBatchActionComplete } from 'dashboards/shared/hooks/useBatchActions'
import { useHbMutation } from 'hooks/ApolloHelpers'

import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { BatchActionTypeEnum } from 'types/api'

import { LibraryGqlQueryType } from './LibraryQueries'
import {
  PerformInvestigationBatchActionMutation,
  PerformInvestigationBatchActionMutationVariables,
} from './__generated__/MergeRelatedCasesButton.generated'

export const PERFORM_BATCH_ACTION = gql`
  mutation PerformInvestigationBatchAction(
    $tokens: [ID!]!
    $batchActionName: BatchActionTypeEnum!
    $parameters: BatchActionParameters
  ) {
    performInvestigationBatchAction(
      input: { tokens: $tokens, batchActionName: $batchActionName, parameters: $parameters }
    ) {
      bid
    }
  }
`

const bidSelector = (data: PerformInvestigationBatchActionMutation) => data.performInvestigationBatchAction?.bid || null
export const MergeRelatedCasesButton = ({
  caseToken,
  libraryEntityType,
  libraryEntityToken,
  onBatchActionComplete,
}: {
  caseToken?: string
  libraryEntityType: LibraryGqlQueryType
  libraryEntityToken: string
  onBatchActionComplete: () => void
}) => {
  useRefetchOnBatchActionComplete(onBatchActionComplete)
  const numBatchItemsSelected = useSelector(batchActionsSelectors.numBatchItemsSelected)
  const [performBatchAction, { loading }] = useHbMutation<
    PerformInvestigationBatchActionMutation,
    PerformInvestigationBatchActionMutationVariables
  >(PERFORM_BATCH_ACTION)

  const mutateWithThisCaseTokenInjected = useCallback(
    ({ variables }: { variables: PerformInvestigationBatchActionMutationVariables }) => {
      return performBatchAction({
        variables: { ...variables, tokens: [...variables.tokens, caseToken].filter((t): t is string => !!t) },
      })
    },
    [caseToken, performBatchAction]
  )
  const batchActionApiCallContextValue = useMemo(
    () => ({
      mutate: mutateWithThisCaseTokenInjected,
      loading,
      bidSelector,
    }),
    [loading, mutateWithThisCaseTokenInjected]
  )

  const { getIcon } = useBatchActionLookups()
  return (
    <BatchActionContext.Provider value={batchActionApiCallContextValue}>
      <BatchActionMenuItem
        size="small"
        label={caseToken ? 'Merge with This Case' : 'Merge Cases'}
        Icon={getIcon(BatchActionTypeEnum.Merge)}
        actions={[BatchActionTypeEnum.Merge]}
        // If we have a caseToken only one additional case needs to be selected for merge. If we do not, require at least two cases to be selected.
        disabled={numBatchItemsSelected < (caseToken ? 1 : 2)}
        variant="primary"
        entityType="investigation"
        libraryEntityType={libraryEntityType}
        libraryEntityToken={libraryEntityToken}
      />
    </BatchActionContext.Provider>
  )
}

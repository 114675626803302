import { useQuery } from '@apollo/client'

import { FormProvider } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import { GQLError } from 'components/GQLError'
import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'
import Loader from 'components/library/Loader'

import { CreateAutomationSelectType } from 'components/pages/automations/editor/CreateAutomationSelectType'

import * as Styles from '../ViewAutomation/ViewAutomation.styles'

import { AutomationRuleOrTemplateContext } from '../editor/AutomationRuleOrTemplateContext'
import SelectActionDialog from '../editor/SelectActionDialog'
import { ViewAutomationSteps } from '../editor/ViewAutomationSteps'

import { FormSchemaReturnType } from '../editor/formSchema'

import { useFormHandlers } from './AutomationRuleTemplate.hooks'
import { AUTOMATION_RULE_TEMPLATE } from './AutomationRuleTemplate.queries'
import { AutomationRuleTemplateHeader } from './AutomationRuleTemplateHeader'
import { AutomationRuleTemplateSidebar } from './AutomationRuleTemplateSidebar/AutomationRuleTemplateSidebar'
import { AutomationRuleTemplateQuery } from './__generated__/AutomationRuleTemplate.queries.generated'

const AutomationRuleTemplateInner = ({
  automationRuleTemplate,
}: {
  automationRuleTemplate: AutomationRuleTemplateQuery['automationRuleTemplate']
}) => {
  const {
    handleDeleteTemplate,
    handleSelectAction,
    handleSubmitAndSave,
    setActionModalOpen,
    actionModalOpen,
    form,
    loading,
  } = useFormHandlers(automationRuleTemplate)
  const { watch } = form

  const [domainType, automationType] = watch(['domain.type', 'automationType'])

  const isAutomationBlank = !domainType && !automationType

  return (
    <Styles.Root>
      <Styles.Container>
        <FormProvider {...form}>
          <Styles.Form onSubmit={handleSubmitAndSave}>
            <SelectActionDialog
              open={actionModalOpen}
              onClose={() => setActionModalOpen(false)}
              onSelectAction={handleSelectAction}
              domainType={domainType || undefined}
              automationType={automationType || undefined}
            />
            <AutomationRuleTemplateHeader
              template={automationRuleTemplate}
              form={form}
              onSubmitAndSave={handleSubmitAndSave}
              loading={loading}
            />
            <Styles.Main>
              <Styles.EditorWrapper>
                {isAutomationBlank ? (
                  <CreateAutomationSelectType form={form as FormSchemaReturnType} />
                ) : (
                  <ViewAutomationSteps
                    form={form as FormSchemaReturnType}
                    setActionModalOpen={() => setActionModalOpen(true)}
                  />
                )}
              </Styles.EditorWrapper>
              <AutomationRuleTemplateSidebar
                form={form}
                automationRuleTemplate={automationRuleTemplate}
                loading={loading}
                deleteTemplate={handleDeleteTemplate}
              />
            </Styles.Main>
          </Styles.Form>
        </FormProvider>
      </Styles.Container>
    </Styles.Root>
  )
}

export const AutomationRuleTemplate = () => {
  const { token } = useParams<{ token: string }>()

  const { data, loading, error } = useQuery<AutomationRuleTemplateQuery>(AUTOMATION_RULE_TEMPLATE, {
    variables: {
      token,
    },
  })

  if (error) return <GQLError error={error} />
  if (loading) return <Loader variant="global" />
  if (!data) return <HbNonIdealState title="Could not load template" message="" />

  return (
    <AutomationRuleOrTemplateContext.Provider value={data.automationRuleTemplate}>
      <AutomationRuleTemplateInner automationRuleTemplate={data.automationRuleTemplate} />
    </AutomationRuleOrTemplateContext.Provider>
  )
}

interface PollingResult {
  success: boolean
}

/**
 * Executes f() every pollInterval ms until maxRetries is reached
 */
export async function poll(f: () => Promise<boolean>, maxRetries: number, pollInterval = 1000): Promise<PollingResult> {
  return new Promise((resolve) => {
    let checks = 0
    const checkIfReady = async () => {
      if (checks >= maxRetries) {
        resolve({ success: false })
        return
      }

      checks += 1
      const ready = await f()
      if (ready) {
        resolve({ success: true })
      } else {
        setTimeout(checkIfReady, pollInterval)
      }
    }

    checkIfReady()
  })
}

export async function fetchHeadStatus(url: string): Promise<boolean> {
  try {
    const response = await fetch(url, {
      method: 'HEAD',
    })
    return response.ok
  } catch {
    // Network call failed
    return false
  }
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const RejectedIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12,1 L21,5 L21,11 C21,16.55 17.16,21.74 12,23 C6.84,21.74 3,16.55 3,11 L3,5 L12,1 Z M17,8.25318074 L15.7468193,7 L12,10.7468193 L8.25318074,7 L7,8.25318074 L10.7468193,12 L7,15.7468193 L8.25318074,17 L12,13.2531807 L15.7468193,17 L17,15.7468193 L13.2531807,12 L17,8.25318074 Z" />
  </SvgIcon>
)

export default RejectedIcon

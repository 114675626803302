// eslint-disable-next-line  no-restricted-imports
import { PopperProps } from '@mui/material'

// This component can be useful when you want to render the Popper
// component of a Menu or Autocomplete without any positioning styles.
// The MUI class names get passed down via PopperProps, so styling
// from the parent with CSS selectors is possible.
// See "PopperComponent" in https://mui.com/material-ui/api/autocomplete/
export const HbUnstyledPopperComponent = (props: PopperProps) => {
  const { anchorEl: _anchorEl, style: _style, children, ...rest } = props
  return <div {...rest}>{children}</div>
}

import { Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import EditableComponent from 'components/library/EditableComponent'
import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

interface PageTitleProps {
  value: string
  onChange?: (value: string) => void
  variant?: 'normal' | 'inverted'
  label?: string
  styleOverrides?: PageTitleLabelOverrides
}

export type PageTitleLabelOverrides = Partial<ReturnType<typeof useStyles>>
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
  },
  label: {
    display: 'flex',
    fontWeight: 700,
    fontSize: 20,
    marginRight: theme.spacing(1),
  },
}))

function PageTitle({ value, onChange, variant, label, styleOverrides }: PageTitleProps) {
  const classes = useStyles()
  const mergedClasses = mergeOverrideStyles(classes, styleOverrides)

  return (
    <span className={mergedClasses.root}>
      {label && <Typography className={mergedClasses.label}>{label}</Typography>}
      <EditableComponent value={value} onChange={onChange} editable={onChange !== undefined} variant={variant}>
        {value}
      </EditableComponent>
    </span>
  )
}

export default PageTitle

import { VisibleDataFilters } from 'reducers/investigatingReducer'

export const SET_VISIBLE_DATA = 'SET_VISIBLE_DATA'
export const SET_VISIBLE_DATA_FILTERS = 'SET_VISIBLE_DATA_FILTERS'
export const SET_DATA_PAGE = 'SET_DATA_PAGE'
export const SET_DATA_PAGE_SIZE = 'SET_DATA_PAGE_SIZE'
export const SET_TIMESERIES_CHART_TYPE = 'SET_TIMESERIES_CHART_TYPE'
export const UPDATE_VISIBLE_DATA = 'UPDATE_VISIBLE_DATA'
export const RESET = 'RESET'

export function setVisibleData(data?: any, filters?: VisibleDataFilters) {
  return { type: SET_VISIBLE_DATA, data, filters }
}

export function setVisibleDataFilters(filters: VisibleDataFilters) {
  return { type: SET_VISIBLE_DATA_FILTERS, filters }
}

export function setDataPage(page: number) {
  return { type: SET_DATA_PAGE, page }
}

export function setDataPageSize(size: number) {
  return { type: SET_DATA_PAGE_SIZE, size }
}

export function setTimeseriesChartType(chartType: any) {
  return { type: SET_TIMESERIES_CHART_TYPE, chartType }
}

export function updateVisibleData(data: any) {
  return { type: UPDATE_VISIBLE_DATA, data }
}

export function resetTransactionsChartState() {
  return { type: RESET }
}

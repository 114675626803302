import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { ButtonBase } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import VisuallyHidden from '@reach/visually-hidden'

import classnames from 'classnames'

import { BUTTON_OUTLINE_STYLE } from 'components/themeRedesign'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  toggleButton: {
    padding: theme.spacing(0.5),
    borderRadius: '50%',
    '&:focus, &:focus-visible': {
      background: theme.palette.styleguide.backgroundDark,
    },
    '&:focus-visible': {
      outline: BUTTON_OUTLINE_STYLE,
    },
  },
}))

/*
 * This component is a wrapper around the MUI ButtonBase component that adds the
 * role and aria-controls attributes to the button. This is necessary for screen
 * readers to announce the content when the button is clicked. This can be used
 * with the CollapseRegion component to create an accessible expand/collapse section.
 */
function ExpandCollapseToggle({
  className,
  onClick,
  isExpanded,
  toggleId,
  expandedLabel = 'Hide nested content',
  collapsedLabel = 'Show nested content',
  component = 'button',
}: {
  className?: string
  onClick?: () => void
  isExpanded: boolean
  toggleId: string
  expandedLabel?: string
  collapsedLabel?: string
  component?: React.ElementType
}) {
  const classes = useStyles()

  return (
    <ButtonBase
      component={component}
      className={classnames(classes.toggleButton, className)}
      onClick={onClick}
      id={`${toggleId}_toggle`}
      aria-controls={`${toggleId}_content`}
      aria-expanded={isExpanded}
    >
      {isExpanded ? <ExpandLess /> : <ExpandMore />}
      <VisuallyHidden>{isExpanded ? expandedLabel : collapsedLabel}</VisuallyHidden>
    </ButtonBase>
  )
}

export default ExpandCollapseToggle

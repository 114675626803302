import moment from 'moment-timezone'

import { ParentGroup } from 'dashboards/shared/react/queryGroups/parentGroup'
import { DashboardView, NamedQueryListParams, NamedQuerySubList } from 'dashboards/shared/react/queryGroups/types'
import { DashboardTypeEnum, FilterInput, InformationRequestStatusEnum, OperatorEnum, SortEnum } from 'types/api'
import { CurrentAccount } from 'types/hb'

export const defaultInformationRequestsSortOption = {
  direction: SortEnum.Asc,
  field: 'title',
  display: 'Title',
}
export const defaultInformationRequestsSorts = [{ sortDir: SortEnum.Asc, sortField: 'title' }]
export const allRequestsFilter: DashboardView<DashboardTypeEnum.InformationRequests> = {
  name: 'all-requests',
  title: 'All Requests',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultInformationRequestsSorts,
  },
}

const makeStatusFilter: (values: string[]) => FilterInput = (values: string[]) => ({
  field: 'status',
  predicate: {
    operator: OperatorEnum.Is,
    values: [...values], // metadata will control this in Phase 2
  },
  group: 0,
})

const openFilter: DashboardView<DashboardTypeEnum.InformationRequests> = {
  name: 'open',
  title: 'Open Requests',
  request: {
    filters: [
      makeStatusFilter([
        InformationRequestStatusEnum.RequestSent,
        InformationRequestStatusEnum.Overdue,
        InformationRequestStatusEnum.ResponseReceived,
      ]),
    ],
    groups: [ParentGroup],
    sorts: defaultInformationRequestsSorts,
  },
}

const getOverdueFilter: (orgTimezone?: string) => DashboardView<DashboardTypeEnum.InformationRequests> = (
  orgTimezone?: string
) => {
  const today = orgTimezone ? moment().tz(orgTimezone) : moment()
  return {
    name: 'overdue',
    title: 'Overdue Requests',
    request: {
      filters: [
        makeStatusFilter([InformationRequestStatusEnum.RequestSent, InformationRequestStatusEnum.Overdue]),
        {
          field: 'dueAt',
          predicate: {
            operator: OperatorEnum.Lt,
            values: [today.format('YYYY-MM-DD')], // metadata will control this in Phase 2
          },
          group: 0,
        },
      ],
      groups: [ParentGroup],
      sorts: defaultInformationRequestsSorts,
    },
  }
}

const getMyRequestsFilter: (currentAccountToken: string) => DashboardView<DashboardTypeEnum.InformationRequests> = (
  currentAccountToken: string
) => {
  return {
    name: 'my-requests',
    title: 'My Requests',
    request: {
      filters: [
        {
          field: 'createdBy',
          predicate: {
            operator: OperatorEnum.Is,
            values: [currentAccountToken],
          },
          group: 0,
        },
      ],
      groups: [ParentGroup],
      sorts: defaultInformationRequestsSorts,
    },
  }
}

export function getNamedInformationRequestQueries(
  currentAccountToken?: CurrentAccount['token'],
  orgTimezone?: string
): DashboardView<DashboardTypeEnum.InformationRequests>[] {
  if (currentAccountToken) {
    return [allRequestsFilter, getMyRequestsFilter(currentAccountToken), openFilter, getOverdueFilter(orgTimezone)]
  }
  return [allRequestsFilter, openFilter, getOverdueFilter(orgTimezone)]
}

export const getInformationRequestsNamedQueriesList: ({
  currentAccount,
  currentOrganization,
}: NamedQueryListParams) => NamedQuerySubList<DashboardTypeEnum.InformationRequests>[] = ({
  currentAccount,
  currentOrganization,
}) => {
  return [
    {
      title: null,
      queries: getNamedInformationRequestQueries(currentAccount.token, currentOrganization.timezone),
      key: 'defaultQueries',
    },
  ]
}

import { gql } from '@apollo/client'

import { useSnackbar } from 'notistack'

import { useHistory } from 'react-router-dom'

import { useHbMutation } from 'hooks/ApolloHelpers'

import { useAutomationAdminOperations } from './AutomationAdminHelpers'

import {
  UseDeleteAutomationRuleAutomationRuleFragment as AutomationRule,
  DeleteAutomationRuleAsAdminMutation,
  DeleteAutomationRuleAsAdminMutationVariables,
  DeleteAutomationRuleMutation,
  DeleteAutomationRuleMutationVariables,
} from './__generated__/useDeleteAutomationRule.generated'

export const fragment = gql`
  fragment UseDeleteAutomationRuleAutomationRule on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      token
      organizationToken
    }
  }
`

const DELETE_AUTOMATION_RULE = gql`
  mutation DeleteAutomationRule($input: DeleteAutomationRuleInput!) {
    deleteAutomationRule(input: $input) {
      automationRule {
        ...UseDeleteAutomationRuleAutomationRule
      }
    }
  }
  ${fragment}
`

const DELETE_AUTOMATION_RULE_AS_ADMIN = gql`
  mutation DeleteAutomationRuleAsAdmin($input: DeleteAutomationRuleAsAdminInput!) {
    deleteAutomationRuleAsAdmin(input: $input) {
      automationRule {
        ...UseDeleteAutomationRuleAutomationRule
      }
    }
  }
  ${fragment}
`

export function useDeleteAutomationRule(automationRule: AutomationRule) {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()
  const isAutomationAdminRule = useAutomationAdminOperations(automationRule.organizationToken)

  const onCompleted = () => {
    enqueueSnackbar('Automation successfully deleted', {
      variant: 'success',
      preventDuplicate: false,
      autoHideDuration: 1000,
    })
    history.replace(`/automations`)
  }

  const logEvent = {
    name: 'automations:deleteRule:clicked' as const,
    data: {
      token: automationRule.token,
    },
  }

  const [deleteAutomationRule, { loading: deleteAutomationRuleLoading }] = useHbMutation<
    DeleteAutomationRuleMutation,
    DeleteAutomationRuleMutationVariables
  >(DELETE_AUTOMATION_RULE, {
    flashError: true,
    onCompleted,
    variables: { input: { token: automationRule.token } },
    logEvent,
  })

  const [deleteAutomationRuleAsAdmin, { loading: deleteAutomationRuleAsAdminLoading }] = useHbMutation<
    DeleteAutomationRuleAsAdminMutation,
    DeleteAutomationRuleAsAdminMutationVariables
  >(DELETE_AUTOMATION_RULE_AS_ADMIN, {
    flashError: true,
    onCompleted,
    variables: { input: { token: automationRule.token, organizationToken: automationRule.organizationToken } },
    logEvent,
  })

  const handleDelete = () => {
    if (isAutomationAdminRule) {
      deleteAutomationRuleAsAdmin()
    } else {
      deleteAutomationRule()
    }
  }

  return {
    deleteRule: handleDelete,
    loading: deleteAutomationRuleLoading || deleteAutomationRuleAsAdminLoading,
  }
}

import { Block, Done, PersonRounded } from '@mui/icons-material'

import { BatchAssignDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchAssignDialog'
import { BatchCancelDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchCancelDialog'
import { BatchCompleteDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchCompleteDialog'
import { BatchMergeDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchMergeDialog'
import { BatchUnassignDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchUnassignDialog'
import { BatchUncompleteDialog } from 'dashboards/reviews/ReviewsBatchActionsMenu/dialogs/BatchUncompleteDialog'
import { ActionMap } from 'dashboards/shared/components/BatchActionsMenu/types'
import MergeIcon from 'icons/MergeIcon'
import { BatchActionTypeEnum } from 'types/api'

const actionMap: ActionMap = {
  [BatchActionTypeEnum.Assign]: {
    label: 'Assign',
    Dialog: BatchAssignDialog,
    Icon: PersonRounded,
    DialogIcon: PersonRounded,
  },
  [BatchActionTypeEnum.Cancel]: { label: 'Cancel', Dialog: BatchCancelDialog, Icon: Block, DialogIcon: Block },
  [BatchActionTypeEnum.Complete]: { label: 'Complete', Dialog: BatchCompleteDialog, Icon: Done, DialogIcon: Done },
  [BatchActionTypeEnum.Unassign]: {
    label: 'Un-Assign',
    Dialog: BatchUnassignDialog,
    Icon: PersonRounded,
    DialogIcon: PersonRounded,
  },
  [BatchActionTypeEnum.Uncomplete]: { label: 'Reopen', Dialog: BatchUncompleteDialog, Icon: Done, DialogIcon: Done },
  [BatchActionTypeEnum.Merge]: { label: 'Merge', Dialog: BatchMergeDialog, Icon: MergeIcon },
}
export default actionMap

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

const useStyles = makeStyles(() => ({
  listCell: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}))

interface Props {
  list: string[]
  className?: string
}

export const ListCell = ({ list, className }: Props) => {
  const classes = useStyles()
  // If there is sorting on this column,
  // then most likely it is being sorted based
  // on the item that is alphabetically first.
  // So we want to sort the list alphabetically so that
  // the item being sorted on is the first item in the list.
  const sortedList = [...list]
  sortedList.sort((a, b) => {
    return a.localeCompare(b)
  })
  return <div className={classnames(classes.listCell, className)}>{sortedList.join(', ')}</div>
}

import {
  ComponentType,
  createElement,
  CSSProperties,
  ElementType,
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  ReactNode,
  RefCallback,
  useCallback,
  useEffect,
  useMemo,
} from 'react'

import { ClassNameMap } from '@mui/styles'

import AutoSizer from 'react-virtualized-auto-sizer'

import {
  ListChildComponentProps,
  ListOnItemsRenderedProps,
  VariableSizeList as BaseList,
  VariableSizeListProps,
  Layout,
} from 'react-window'

import InfiniteLoader from 'react-window-infinite-loader'

import { useIsAutoSizerResizing, useListItemContentSize, useVariableListItemSizes } from './hooks'
import {
  BaseItemData,
  EnhancedItemData,
  GetItemSize,
  InfiniteLoaderProps,
  ListItemComponent,
  ResizeHandler,
  SetItemSize,
} from './types'

/**
 * Dynamic virtualized list component:

 * Use this component to render virtualized lists containing dynamically sized list items.

 * `AutoSizer` handles fitting the windowed list to the available container size,
 * and can automatically rerender the list based on changes in that container size.
 * Note: the container itself needs defined dimensions, or I believe the AutoSizer component itself can be styled.

 * Inside we render a `VariableSizeList` from `react-window`
 * which manages virtualization (i.e. sizing, positioning) of list items
 * that have [varying sizes](https://react-window.vercel.app/#/examples/list/variable-size).

 * By default, react-window requires us to define the list item's size using `itemSize`.
 * But to make that size dynamic, we can first measure an item's DOM node when it is mounted,
 * and store a reference to it by the item's index.
 * A list item's size is only queried infrequently on triggered updates.
 * We also reset react-window's internal cache of values, updating the list.
 */

// props handled by `DynamicVirtualListInner`
type InjectedProps = 'className' | 'estimatedItemSize' | 'height' | 'itemData' | 'itemCount' | 'itemSize' | 'width'

// other base List api props that can be passed down
type BaseListProps<CustomItemData extends BaseItemData> = Omit<
  VariableSizeListProps<EnhancedItemData<CustomItemData>>,
  InjectedProps
>

export interface InfiniteListProps<CustomItemData extends BaseItemData> extends BaseListProps<CustomItemData> {
  children: ListItemComponent<EnhancedItemData<CustomItemData>>
  className?: string
  // requiring the otherwise-optional `estimatedItemSize` prop for better ux,
  // since this list accommodates dynamic resizable + variable items
  estimatedItemSize: number
  fallbackItemSize: number
  height: number
  infiniteLoaderRef: RefCallback<BaseList<EnhancedItemData<CustomItemData>>>
  itemCount: number
  // enhanced custom item data
  itemData: EnhancedItemData<CustomItemData>
  itemSize: GetItemSize
  onInfiniteLoaderItemsRendered: NonNullable<BaseListProps<CustomItemData>['onItemsRendered']>
  onResize?: ResizeHandler
  width: number
}

const InfiniteListInner = <CustomItemData extends BaseItemData>(
  {
    children,
    className,
    itemData,
    estimatedItemSize,
    fallbackItemSize,
    height,
    infiniteLoaderRef,
    itemCount,
    itemSize,
    layout,
    onResize,
    onInfiniteLoaderItemsRendered,
    onItemsRendered: _onItemsRendered,
    overscanCount,
    width,
    ...listProps
  }: InfiniteListProps<CustomItemData>,
  ref: RefCallback<BaseList<EnhancedItemData<CustomItemData>> | null>
) => {
  // hoist list ref to caller, as well as for the infinite loader wrapper
  const getRef = useCallback(
    (node) => {
      ref(node)
      infiniteLoaderRef(node)
    },
    [ref, infiniteLoaderRef]
  )

  // merge optional `onItemsRendered`, as well as infinite loader `onItemsRendered`
  const onItemsRendered = useCallback(
    (props: ListOnItemsRenderedProps) => {
      if (_onItemsRendered) _onItemsRendered(props)
      onInfiniteLoaderItemsRendered(props)
    },
    [_onItemsRendered, onInfiniteLoaderItemsRendered]
  )

  return (
    <BaseList<EnhancedItemData<CustomItemData>>
      {...listProps}
      className={className}
      estimatedItemSize={estimatedItemSize}
      // subtracting 1px helps avoid a minor rounding bug in AutoSizer:
      // https://github.com/bvaughn/react-virtualized/issues/1287
      // which can cause the slightest unnecessary scroll
      height={height - 1}
      itemData={itemData}
      itemCount={itemCount}
      // variable list item heights stored by index
      itemSize={itemSize}
      layout={layout}
      onItemsRendered={onItemsRendered}
      // overscan count allows a small buffer zone since we are using variable list item heights
      overscanCount={overscanCount}
      // rough average of list item heights to help prevent scrollbar instability
      ref={getRef}
      width={width - 1}
    >
      {children}
    </BaseList>
  )
}

export const InfiniteList = forwardRef(InfiniteListInner) as <CustomItemData extends BaseItemData>(
  props: InfiniteListProps<CustomItemData> & { ref?: ForwardedRef<BaseList<EnhancedItemData<CustomItemData>> | null> }
) => ReturnType<typeof InfiniteListInner>

export interface ListProps<CustomItemData extends BaseItemData> extends BaseListProps<CustomItemData> {
  children: ListItemComponent<EnhancedItemData<CustomItemData>>
  classes?: Partial<ClassNameMap<'autoSizer' | 'list'>>
  // requiring the otherwise-optional `estimatedItemSize` prop for better ux,
  // since this list accommodates dynamic resizable + variable items
  estimatedItemSize: number
  fallbackItemSize: number
  // if extra space is needed for virtualized list reordering
  itemCount?: number
  // the base custom item data is passed down from the caller,
  // and it is enhanced and passed down to the List.
  itemData: CustomItemData
  onResize?: ResizeHandler
  infiniteLoaderProps?: InfiniteLoaderProps
}

const DynamicVirtualListInner = <CustomItemData extends BaseItemData>(
  {
    children,
    classes,
    estimatedItemSize,
    fallbackItemSize,
    infiniteLoaderProps,
    itemCount: itemCountOverride,
    itemData,
    layout = 'vertical',
    onResize,
    overscanCount = 10,
    ...listProps
  }: ListProps<CustomItemData>,
  ref?: ForwardedRef<BaseList<EnhancedItemData<CustomItemData>> | null>
) => {
  const { isResizing, handleResize } = useIsAutoSizerResizing()

  const itemCount = itemCountOverride ?? itemData.listItems.length

  const { getItemSize, listRef, setItemSize } =
    useVariableListItemSizes<EnhancedItemData<CustomItemData>>(fallbackItemSize)

  const _handleResize: ResizeHandler = useCallback(
    (size) => {
      if (onResize instanceof Function) onResize(size)
      handleResize(size)
    },
    [handleResize, onResize]
  )

  // inject variable, resizable item props to the list items
  const enhancedItemData = useMemo(
    () => ({
      ...itemData,
      layout,
      getItemSize,
      isResizing,
      setItemSize,
    }),
    [getItemSize, isResizing, itemData, layout, setItemSize]
  )

  // Hoist the list instance ref in case the parent needs access
  // to any of its [methods](https://react-window.vercel.app/#/api/FixedSizeList#methods)
  const getRef = useCallback(
    (node) => {
      // accommodating forwarded refs (which can be either callbacks or mutable refs)
      // https://github.com/DefinitelyTyped/DefinitelyTyped/blob/master/types/react/index.d.ts#L543
      if (ref instanceof Function) ref(node)
      else if (ref) ref.current = node
      listRef.current = node
    },
    [listRef, ref]
  )

  useEffect(() => {
    listRef.current?.resetAfterIndex(0)
  }, [itemData.listItems, listRef])

  const commonProps = {
    ...listProps,
    className: classes?.list,
    // rough average of list item heights to help prevent scrollbar instability
    estimatedItemSize,
    fallbackItemSize,
    // list item data with injected values
    itemData: enhancedItemData,
    itemCount,
    // variable list item heights stored by index
    itemSize: getItemSize,
    // direction of scroll
    layout,
    ref: getRef,
    // overscan count allows a small buffer zone since we are using variable list item heights
    overscanCount,
  }

  // regular dynamic sized list
  if (!infiniteLoaderProps) {
    return (
      <AutoSizer className={classes?.autoSizer} onResize={_handleResize}>
        {({ width, height }) => (
          <BaseList<EnhancedItemData<CustomItemData>>
            {...commonProps}
            // subtracting 1px helps avoid a minor rounding bug in AutoSizer:
            // https://github.com/bvaughn/react-virtualized/issues/1287
            // which can cause the slightest unnecessary scroll
            height={height - 1}
            width={width - 1}
          >
            {children}
          </BaseList>
        )}
      </AutoSizer>
    )
  }

  // dynamic sized list with infinite loader
  return (
    <AutoSizer className={classes?.autoSizer} onResize={_handleResize}>
      {({ width, height }) => (
        <InfiniteLoader {...infiniteLoaderProps}>
          {({ onItemsRendered: onInfiniteLoaderItemsRendered, ref: infiniteLoaderRef }) => (
            <InfiniteList<EnhancedItemData<CustomItemData>>
              {...commonProps}
              infiniteLoaderRef={infiniteLoaderRef}
              onInfiniteLoaderItemsRendered={onInfiniteLoaderItemsRendered}
              height={height - 1}
              width={width - 1}
            >
              {children}
            </InfiniteList>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  )
}

// assert the original type of the ref-forwarded component, since it uses a generic
// https://stackoverflow.com/a/58473012
// there is a fourth option to redeclare/augment the forwardRef type, but will revisit that
export const DynamicVirtualList = forwardRef(DynamicVirtualListInner) as <CustomItemData extends BaseItemData>(
  props: ListProps<CustomItemData> & { ref?: ForwardedRef<BaseList<EnhancedItemData<CustomItemData>> | null> }
) => ReturnType<typeof DynamicVirtualListInner>

export const getVirtualListItemDimension = (layout: Layout) => (layout === 'vertical' ? 'height' : 'width')

/**
 * Dynamic virtualized list item component:

 * Use this simple wrapper around a virtual list's item
 * to abstract away content measurements for the list item's DOM node.

 * `DynamicVirtualList` alone is able to render a dynamic variable virtual list,
 * but this component adds a bit more polish to the ux.

 * - The outer `div` handles the `style` prop which we get from react-window's
 * [virtualization](https://github.com/bvaughn/react-window#why-is-my-list-blank-when-i-scroll).

 * - The inner `div` handles any spontaneous resizing that occurs in the content itself,
 * e.g. the container/window gets resized and the item's contents wrap around.
 * Although `AutoSizer` can trigger a re-render of the virtualized list too,
 * each list item can also handle its own internal resizing,
 * allowing the list item component to be [memoized](https://react-window.vercel.app/#/examples/list/memoized-list-items),
 * and the interaction to be a little smoother.
 *
 * Note: if resizing the list feels janky, make sure that the list item content
 * that is passed down as `children` to `DynamicVirtualListItem` is memoized.
 */

// forward all props to the list item child render function except 'style', which is intercepted in this wrapper
export type ListItemChildComponentProps<CustomItemData extends BaseItemData> = Omit<
  ListChildComponentProps<EnhancedItemData<CustomItemData>>,
  'style'
>

type ListItemOuterContainerProps = HTMLAttributes<HTMLDivElement> & { [dataAttr: `data-${string}`]: unknown }
type ListItemClasses = Partial<ClassNameMap<'root' | 'inner'>>

export type ListItemProps<CustomItemData extends BaseItemData> = ListChildComponentProps<
  EnhancedItemData<CustomItemData>
> & {
  classes?: ListItemClasses
  children: ComponentType<ListItemChildComponentProps<CustomItemData>>
  innerElementType?: ElementType
  outerElementType?: ElementType
  OuterContainerProps?: ListItemOuterContainerProps
}

interface DynamicVirtualListItemInnerWrapperProps {
  children: ReactNode
  classes?: ListItemClasses
  getItemSize: GetItemSize
  index: number
  innerElementType?: ElementType
  layout: Layout
  OuterContainerProps?: ListItemOuterContainerProps
  outerElementType?: ElementType
  setItemSize: SetItemSize
  style: CSSProperties
}

/**
 * Component for inner html elements which intercept props for:
 * - virtualization styles
 * - content size computation (for variable item size lists).
 * This wrapper can be used directly if a cloned item is needed for drag-and-drop lists.
 */
export const DynamicVirtualListItemInnerWrapper = forwardRef<HTMLDivElement, DynamicVirtualListItemInnerWrapperProps>(
  (
    {
      children,
      classes,
      getItemSize,
      index,
      innerElementType: InnerElement = 'div',
      layout,
      OuterContainerProps,
      outerElementType: OuterElement = 'div',
      setItemSize,
      style,
    },
    ref
  ) => {
    const dimension = getVirtualListItemDimension(layout)
    const listItemContentRef = useListItemContentSize(index, setItemSize, getItemSize, dimension)

    return (
      // the outer div intercepts virtualization styles
      <OuterElement {...OuterContainerProps} className={classes?.root} ref={ref} style={style}>
        {/* the inner container can be used to make the containing virtual list content-aware */}
        <InnerElement className={classes?.inner} ref={listItemContentRef}>
          {children}
        </InnerElement>
      </OuterElement>
    )
  }
)

const DynamicVirtualListItemInner = <CustomItemData extends BaseItemData>(
  {
    children,
    classes,
    data,
    index,
    innerElementType,
    isScrolling,
    outerElementType,
    style,
    OuterContainerProps,
  }: ListItemProps<CustomItemData>,
  ref?: ForwardedRef<HTMLDivElement | null>
) => {
  const { getItemSize, layout, setItemSize } = data

  const childProps = useMemo(
    () => ({
      data,
      index,
      isScrolling,
    }),
    [data, index, isScrolling]
  )

  return (
    <DynamicVirtualListItemInnerWrapper
      classes={classes}
      getItemSize={getItemSize}
      index={index}
      innerElementType={innerElementType}
      layout={layout}
      setItemSize={setItemSize}
      outerElementType={outerElementType}
      OuterContainerProps={OuterContainerProps}
      ref={ref}
      style={style}
    >
      {createElement(children, childProps)}
    </DynamicVirtualListItemInnerWrapper>
  )
}

export const DynamicVirtualListItem = forwardRef(DynamicVirtualListItemInner) as <CustomItemData extends BaseItemData>(
  props: ListItemProps<CustomItemData> & { ref?: ForwardedRef<HTMLDivElement | null> }
) => ReturnType<typeof DynamicVirtualListItemInner>

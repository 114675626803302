import { ForwardedRef, forwardRef } from 'react'

import { Field, FieldAttributes, FieldProps } from 'formik'

import { formikFieldToHbInput } from '../hbInputUtils'

import HbTextArea, { HbTextAreaProps as BaseHbTextAreaProps } from './HbTextArea'

export interface HbFormikTextAreaProps extends FieldProps, Omit<BaseHbTextAreaProps, 'name' | 'value' | 'error'> {}

/**
 * Drop-in component for Formik
 */
export const HbFormikTextArea = forwardRef<HTMLDivElement, HbFormikTextAreaProps>((props, ref) => {
  const _props = formikFieldToHbInput<HTMLTextAreaElement>(props)
  return <HbTextArea {..._props} ref={ref} />
})

type HbFormikTextFieldProps = BaseHbTextAreaProps & FieldAttributes<unknown> & { name: string }

const HbFormikTextAreaFieldInner = <E extends HTMLElement = HTMLElement>(
  props: HbFormikTextFieldProps,
  ref: ForwardedRef<E>
) => <Field {...props} innerRef={ref} component={HbFormikTextArea} />

export const HbFormikTextAreaField = forwardRef(HbFormikTextAreaFieldInner)

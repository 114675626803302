import React, { ReactNode, useContext } from 'react'

import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'
import { DashboardTypeEnum } from 'types/api'

export const DashboardContext = React.createContext<DashboardSlice | null>(null)

export const useDashboardContext = (): DashboardSlice => {
  const dashboardContextValue = useContext(DashboardContext)
  if (!dashboardContextValue) {
    if (DEBUG) {
      // eslint-disable-next-line no-console
      console.error('useDashboardContext must be called within a DashboardContext.')
    }
    // TODO: throw here instead of fallback value throw new Error('useDashboardContext must be called within a DashboardContext.')
    return DashboardTypeEnum.Reviews
  }
  return dashboardContextValue
}

export const DashboardContextProvider = ({ value, children }: { value: DashboardSlice; children: ReactNode }) => {
  return <DashboardContext.Provider value={value}>{children}</DashboardContext.Provider>
}

import { ApolloClient, gql } from '@apollo/client'

import EncHex from 'crypto-js/enc-hex'

import sha1 from 'crypto-js/sha1'

import { get, isEmpty, isNil } from 'lodash'

import {
  ParsingActionsUploadAuthorizationMutation,
  ParsingActionsUploadAuthorizationMutationVariables,
} from 'actions/__generated__/parsingActions.generated'
import { setFlashNotice } from 'actions/applicationActions'

import { clearAllFormErrors, setFlashError, setFormErrorMessage } from 'actions/errorActions'

import { dataTypeOf } from 'actions/importingFields'
import { loadInvestigation } from 'actions/investigationsActions'

import {
  closeCaseImport,
  forceCloseActiveForm,
  LOADING_SLOW_IMPORT,
  setLoading,
  setShowDisplay,
} from 'actions/viewActions'

import { fetchHeadStatus, poll } from 'helpers/PollingHelper'
import { findNthOccurrence } from 'helpers/objHelpers'

import { getOrganizationToken, getParsingOptions } from 'helpers/stateHelpers'

import 'isomorphic-fetch'

import {
  ADD_PARSING_HINT,
  CLEAR_PARSING_RESULT,
  Field,
  ParsingHint,
  ParsingState,
  RESET_PARSED_DATA,
  RESET_PARSING_OPTIONS,
  RESET_PASTE_TEXT,
  SET_ACTIVE_PARSING_TOKEN,
  SET_PARSING_OPTION,
  SET_PARSING_RESULT,
  SET_PARSING_STATUS,
  UPDATE_PARSED_FIELD,
  UPDATE_PASTE_TEXT,
} from 'reducers/importing/parsingReducer'
import { CaseImportCategory, CaseImportState } from 'types/api'

import { DISPLAY_TYPES, SnakeDataTypeKey } from './importingFields.types'
import { AsyncThunk } from './store'

// Line cutoff for import previews
export const TEST_PARSE_LINE_CUTOFF = 100

// Text Parsing action creators
export function setParsingStatus(dataType: string, status: string | undefined) {
  return { type: SET_PARSING_STATUS, dataType, status }
}

export function setParsingResult(parseResult: Partial<ParsingState>) {
  return { type: SET_PARSING_RESULT, parseResult }
}

export function clearParsingResult() {
  return { type: CLEAR_PARSING_RESULT }
}

export function setActiveParsingToken(token: string | null) {
  return { type: SET_ACTIVE_PARSING_TOKEN, token }
}

export function updateParsedField(index: number, newFieldName: string) {
  return { type: UPDATE_PARSED_FIELD, index, newFieldName }
}

export function resetParsedData() {
  return { type: RESET_PARSED_DATA }
}

export function updatePasteText(dataType: string, value: string, filename: string) {
  return { type: UPDATE_PASTE_TEXT, dataType, value, filename }
}

export function resetPasteText(dataType: string) {
  return { type: RESET_PASTE_TEXT, dataType }
}

export function addParsingHint(index: number, fieldName: string) {
  return { type: ADD_PARSING_HINT, index, fieldName }
}

function setParsingOption(dataType: string, option: string, value: string | boolean) {
  return { type: SET_PARSING_OPTION, dataType, option, value }
}

export function resetParsingOptions(dataType: string) {
  return { type: RESET_PARSING_OPTIONS, dataType }
}

export function setParsingHasHeaders(dataType: string, value: boolean) {
  return setParsingOption(dataType, 'hasHeaders', value)
}

export function setParsingFormatHint(dataType: string, value: string) {
  return setParsingOption(dataType, 'formatHint', value)
}

export function setParsingUnlockCases(dataType: string, value: boolean) {
  return setParsingOption(dataType, 'unlockCases', value)
}

const UPLOAD_AUTHORIZATION_MUTATION = gql`
  mutation ParsingActionsUploadAuthorization($uploadCategory: String!) {
    createTransientUploadAuthorization(input: { uploadCategory: $uploadCategory }) {
      transientUploadAuthorization {
        uploadCategory
        uploadToken
        expireTime
        maxContentLength
        presignedPostUrl
        presignedPostFields
      }
    }
  }
`

const GET_CASE_IMPORT = gql`
  query getCaseImport($token: String!) {
    caseImport(token: $token) {
      state
      failureMessage
    }
  }
`

export function uploadFile(uploadCategory: UploadCategory, fileContents: ArrayBuffer): AsyncThunk<string> {
  return async (dispatch, getState, { gqlClient }) => {
    try {
      // 1. request an s3 upload authorization from the server ( createTransientUploadAuthorization )
      const authorizationResult = await gqlClient.mutate<
        ParsingActionsUploadAuthorizationMutation,
        ParsingActionsUploadAuthorizationMutationVariables
      >({
        mutation: UPLOAD_AUTHORIZATION_MUTATION,
        variables: { uploadCategory },
        errorPolicy: 'all', // Do not throw an error, instead display the error message from the result
      })

      if (authorizationResult.errors?.length) {
        dispatch(setFlashError(authorizationResult.errors[0].message))
        return null
      }

      const authorization = authorizationResult.data?.createTransientUploadAuthorization?.transientUploadAuthorization
      if (!authorization) {
        return null
      }

      const fileBody = new FormData()
      Object.keys(authorization.presignedPostFields).forEach((key) => {
        fileBody.append(key, authorization.presignedPostFields[key])
      })

      const fileBlob = new Blob([fileContents])

      fileBody.append('file', fileBlob)

      // 2. upload the attachment to s3
      const uploadRequest = await fetch(authorization.presignedPostUrl, {
        method: 'POST',
        body: fileBody,
      })

      if (!uploadRequest.ok) {
        return null
      }

      // Wait for upload to finish
      await uploadRequest.text()

      return authorization.uploadToken
    } catch (e) {
      return null
    }
  }
}

function getTextPreview(text: string) {
  const endIndex = findNthOccurrence(text, '\n', TEST_PARSE_LINE_CUTOFF)
  const textPreview = endIndex !== -1 ? text.substring(0, endIndex + 1) : text
  const isTextTruncated = endIndex !== -1 && endIndex + 1 < text.length

  return { textPreview, isTextTruncated }
}

export function testParsePastedData(investigationToken: string, dataType: SnakeDataTypeKey): AsyncThunk<boolean> {
  return async (dispatch, getState, { api }) => {
    const dataCategory = get(dataTypeOf(dataType), 'category')
    const { hasHeaders, formatHint } = getParsingOptions(getState().importing.parsing, dataType)
    const text = getState().importing.parsing.pastesByType[dataType] || ''
    const filename = getState().importing.parsing.filenamesByType[dataType] || ''
    if (text.length === 0) {
      dispatch(clearParsingResult())
      return Promise.resolve(null)
    }

    dispatch(setLoading(true, 'textParsing'))
    dispatch(setParsingStatus(dataType, 'parsing'))

    const { textPreview, isTextTruncated } = getTextPreview(text)

    const json = await api.post('interpretPreviewApiInvestigationCaseDataImportsPath', {
      urlParams: { investigationToken },
      data: {
        category: dataCategory,
        hasHeaders,
        formatHint,
        filename,
        isTextTruncated,
        textPreview,
      },
    })

    dispatch(setLoading(false, 'textParsing'))
    dispatch(setParsingStatus(dataType, undefined))
    if (json.success) {
      const { token } = json.parseResult
      dispatch(setActiveParsingToken(token))
      dispatch(setParsingResult(json.parseResult))
      dispatch(setShowDisplay(dataType, DISPLAY_TYPES.IMPORT))
      return true
    }

    if (json.error) {
      dispatch(setFormErrorMessage(json.error.message, 'dataImport'))
    }

    return false
  }
}

function testParseCaseImportAsync(
  text: string,
  dataHasHeaders: boolean,
  category: CaseImportCategory | '',
  formatHint?: string
): AsyncThunk<boolean> {
  return async (dispatch, getState, { api }) => {
    if (text.length === 0) {
      dispatch(clearParsingResult())
      return Promise.resolve(null)
    }

    const typeKey = 'case_import'
    const filename = getState().importing.parsing.filenamesByType[typeKey] || ''
    const { textPreview, isTextTruncated } = getTextPreview(text)

    dispatch(setLoading(true, 'textParsing'))
    dispatch(setParsingStatus(typeKey, 'parsing'))

    const json = await api.post('interpretPreviewApiOrganizationCaseImportsPath', {
      urlParams: { organizationToken: getOrganizationToken(getState()) },
      data: {
        filename,
        isTextTruncated,
        textPreview,
        category,
        hasHeaders: dataHasHeaders,
        formatHint,
      },
    })
    dispatch(setLoading(false, 'textParsing'))
    dispatch(setParsingStatus(typeKey, undefined))
    if (json.success) {
      const { token, fieldMappings } = json.parseResult
      if (category === CaseImportCategory.LibraryData && fieldMappings.length > 0) {
        // custom handling for LibraryData; we should expect all fields to be mapped
        dispatch(setFormErrorMessage('Unexpected headers: please check headers against canonical list', 'case_import'))
        return false
      }
      dispatch(setActiveParsingToken(token))
      dispatch(setParsingResult(json.parseResult))
      dispatch(setShowDisplay(typeKey, DISPLAY_TYPES.IMPORT, category))
      return true
    }

    dispatch(setFormErrorMessage(json.error.message, 'case_import'))
    return false
  }
}

export function testParseCaseImport(
  text: string,
  dataHasHeaders: boolean,
  category: CaseImportCategory | '',
  formatHint?: string
): AsyncThunk<boolean> {
  return (dispatch, _getState, { usage }) => {
    const event = category === CaseImportCategory.LibraryData ? 'CRMBulkImport' : 'CaseBulkImport'
    usage.trackChurnZeroEvent(event)

    return dispatch(testParseCaseImportAsync(text, dataHasHeaders, category, formatHint))
  }
}

type UploadCategory = 'case_data_imports' | 'case_imports' | 'attachments'

export function saveToS3(
  text: string,
  uploadCategory: UploadCategory,
  loadingToken: string,
  importer: (uploadToken: string) => Promise<any>
): AsyncThunk<{
  success: boolean
  timedOut?: boolean
  error?: any
  importToken?: string
}> {
  return async (dispatch) => {
    dispatch(setLoading(true, loadingToken))

    const encoder = new TextEncoder()
    const textBuffer = encoder.encode(text).buffer as ArrayBuffer
    const uploadToken = await dispatch(uploadFile(uploadCategory, textBuffer))

    let json: any
    if (uploadToken) {
      json = await importer(uploadToken)
    } else {
      json = {
        error: {
          message: 'File upload failed',
        },
      }
    }

    if (json.success) {
      // Poll to check if import is done for 10s
      const { success } = await poll(() => fetchHeadStatus(json.result.presignedHeadUrl), 10)

      dispatch(setLoading(false, loadingToken))
      return {
        success: true,
        timedOut: !success,
        importToken: json.result?.importToken,
      }
    }

    if (json.error) {
      dispatch(setLoading(false, loadingToken))
      return { success: false, error: json.error }
    }

    return { success: false }
  }
}

async function fetchCaseImport(importToken: string, gqlClient: ApolloClient<any>) {
  if (!importToken) {
    return false
  }

  const importStatus = await gqlClient.query({
    query: GET_CASE_IMPORT,
    variables: { token: importToken },
  })

  return importStatus.data?.caseImport
}

export function saveParsedData(investigationToken: string, dataType: SnakeDataTypeKey): AsyncThunk<boolean> {
  return async (dispatch, getState, { api, gqlClient }) => {
    const category = get(dataTypeOf(dataType), 'category')
    const hints: ParsingHint[] = getState().importing.parsing.parsingHints || []
    const filename = getState().importing.parsing.filenamesByType[dataType] || ''
    const text = getState().importing.parsing.pastesByType[dataType] || ''
    const { hasHeaders, formatHint } = getParsingOptions(getState().importing.parsing, dataType)

    if (isEmpty(text)) {
      dispatch(setFormErrorMessage('No import text found. Reload and try again.', 'matchingFields'))
      return Promise.resolve(null)
    }

    const digest = sha1(text).toString(EncHex)

    const importer = (uploadToken: string) =>
      api.post('createAndCompleteApiInvestigationCaseDataImportsPath', {
        urlParams: { investigationToken },
        data: {
          category,
          hasHeaders,
          formatHint,
          hints,
          filename,
          uploadToken,
          digest,
        },
      })

    const s3Response = await dispatch(saveToS3(text, 'case_data_imports', 'textParsing', importer))

    if (s3Response?.error) {
      dispatch(setFormErrorMessage(s3Response.error.message, 'matchingFields'))
      return false
    }

    const caseImport = await fetchCaseImport(s3Response?.importToken || '', gqlClient)

    if (caseImport?.state === CaseImportState.Success) {
      dispatch(setActiveParsingToken(null))
      dispatch(loadInvestigation({ token: investigationToken }))
      dispatch(forceCloseActiveForm(investigationToken))
      dispatch(setFlashNotice('Case successfully updated!'))
      return true
    }
    if (caseImport?.failureMessage) {
      dispatch(setFormErrorMessage(caseImport.failureMessage, 'matchingFields'))
    } else {
      // still processing, show message to check import page later
      dispatch(setLoading(true, LOADING_SLOW_IMPORT))
    }

    return false
  }
}

export function saveParsedCasesAsync(hints: ParsingHint[], filename: string, category: string): AsyncThunk<boolean> {
  return async (dispatch, getState, { api }) => {
    const text = getState().importing.parsing.pastesByType.case_import || ''
    const { hasHeaders, unlockCases } = getParsingOptions(getState().importing.parsing, 'case_import')

    if (isEmpty(text)) {
      dispatch(setFormErrorMessage('No import text found. Reload and try again.', 'matchingFields'))
      return Promise.resolve(null)
    }

    const digest = sha1(text).toString(EncHex)

    const importer = (uploadToken: string) =>
      api.post('createAndCompleteApiOrganizationCaseImportsPath', {
        urlParams: { organizationToken: getOrganizationToken(getState()) },
        data: {
          hasHeaders,
          hints,
          filename,
          uploadToken,
          digest,
          category,
          lockedAction: unlockCases ? 'UNLOCK' : 'NOOP',
        },
      })

    const s3Response = await dispatch(saveToS3(text, 'case_imports', 'textParsing', importer))

    if (s3Response?.success && !s3Response.timedOut) {
      dispatch(closeCaseImport())
      dispatch(setActiveParsingToken(null))
      // all we know is that we've successfully uploaded the file. processing may still fail...
      dispatch(setFlashNotice("Data was uploaded successfully. We'll email you if there are any problems importing."))
      return true
    }
    if (s3Response?.success && s3Response.timedOut) {
      dispatch(setActiveParsingToken(null))
      dispatch(setLoading(true, LOADING_SLOW_IMPORT))
    } else if (s3Response?.error) {
      dispatch(setFormErrorMessage(s3Response.error.message, 'matchingFields'))
    }

    return false
  }
}

export function saveParsedCases(category: string): AsyncThunk<boolean> {
  return async (dispatch, getState) => {
    const hints = getState().importing.parsing.parsingHints || []
    const filename = getState().importing.parsing.filenamesByType.case_import || ''

    return dispatch(saveParsedCasesAsync(hints, filename, category))
  }
}

export function saveFieldOverride(
  category: string | null,
  keyword: string,
  matchingField: string
): AsyncThunk<boolean> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'saveField'))
    dispatch(clearAllFormErrors())
    return api
      .post('apiOrganizationFieldsPath', {
        urlParams: { organizationToken: getOrganizationToken(getState()) },
        data: { category, keyword, matchingField },
      })
      .then((json) => {
        dispatch(setLoading(false, 'saveField'))
        if (json.success) {
          return true
        }
        if (json.error) {
          dispatch(setFormErrorMessage(json.error.message, 'matchingFields'))
        }
        return true
      })
  }
}

export function updateFieldAndSaveOverride(
  category: string | null,
  index: number,
  field: Field,
  newFieldName: string
): AsyncThunk<boolean> {
  return (dispatch) => {
    if (isNil(index) || isEmpty(field) || isEmpty(newFieldName)) {
      return new Promise((resolve) => {
        resolve(true)
      })
    }
    const { keyword } = field

    return new Promise((resolve) => {
      dispatch(updateParsedField(index, newFieldName))
      dispatch(addParsingHint(index, newFieldName))
      if (!isNil(keyword) && !isEmpty(keyword)) {
        dispatch(saveFieldOverride(category, keyword, newFieldName))
      }
      resolve(true)
    })
  }
}

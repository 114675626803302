import { ComponentPropsWithoutRef } from 'react'

import { FormControlLabel, Switch, styled } from '@mui/material'

import { HbTooltip } from 'components/HbComponents/HbTooltip'

import { useToggleTemplate } from './AutomationRuleTemplate.hooks'
import { TOGGLE_AUTOMATION_TEMPLATE_VISIBLE } from './AutomationRuleTemplate.queries'
import { ToggleAutomationTemplateVisibleAutomationRuleTemplateFragment as Template } from './__generated__/AutomationRuleTemplate.queries.generated'

const StyledFormControlLabel = styled(FormControlLabel)(({ theme }) => ({
  margin: 0,
  gap: theme.spacing(0.75),
}))
interface Props extends Omit<ComponentPropsWithoutRef<typeof Switch>, 'checked' | 'onChange'> {
  template: Template
  showLabel?: boolean
}
export default function ToggleAutomationTemplateVisible({ template, showLabel = true, ...restProps }: Props) {
  const { handleToggleTemplate, loading: toggleLoading } = useToggleTemplate(template)

  if (!showLabel) {
    return (
      <HbTooltip title="Toggle visibility of template in Explore page for customers">
        <Switch
          color="primary"
          checked={!!template.enabled}
          onChange={handleToggleTemplate}
          disabled={toggleLoading}
          {...restProps}
        />
      </HbTooltip>
    )
  }
  return (
    <StyledFormControlLabel
      disabled={toggleLoading}
      labelPlacement="start"
      label="Visible"
      checked={!!template.enabled}
      onChange={handleToggleTemplate}
      control={<Switch color="primary" {...restProps} />}
    />
  )
}

ToggleAutomationTemplateVisible.fragments = {
  AutomationRuleTemplate: TOGGLE_AUTOMATION_TEMPLATE_VISIBLE,
}

import { useCallback } from 'react'

import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'

type DashboardEventType = 'dashboard:filter:clicked' | 'dashboard:sort:clicked' | 'dashboard:hideColumns:clicked'

const useLogDashboardFilterInteraction = () => {
  const usage = useUsage()
  const dashboardType = useDashboardContext()
  const logAction = useCallback(
    (name: DashboardEventType) => {
      usage.logEvent({ name, data: { dashboardType } })
    },
    [dashboardType, usage]
  )

  return logAction
}
export default useLogDashboardFilterInteraction

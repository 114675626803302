import { gql } from '@apollo/client'

export const SearchInformationRequestsResultFragment = gql`
  fragment SearchInformationRequestsResultFragment on SearchInformationRequestsResult {
    entries {
      token
      title
      createdAt
      completedAt
      cancelledAt
      dueAt
      updatedAt
      lastRespondedAt
      status
      investigations {
        token
        name
      }
      cancelledBy {
        token
        fullName
      }
      createdBy {
        token
        fullName
      }
      completedBy {
        token
        fullName
      }
      informationRequestForm {
        token
        title
      }
      informationRequestRecipients {
        token
        email
        name
      }
      informationRequestSubscribers {
        token
        email
      }
    }
    page
    pageSize
    pageCount
    totalCount
  }
`

/* eslint-disable class-methods-use-this */
/**
 * This file implements a concrete syntax tree visitor to iterate over a concrete syntax tree from the parser and
 * produce diagnostic messages to render in the editor, including missing variables and misused data types (e.g., not
 * using an array after an `in` in a for loop construct).
 *
 * The visitor is desiend to maintain a list of variables which are in scope at any given point in the document.
 */

import type { Diagnostic } from '@codemirror/lint'

import { IToken, MismatchedTokenException } from 'chevrotain'

import parse, { recoveringParser } from './parser'
import {
  ControlAssignCstChildren,
  ControlCaptureCstChildren,
  ControlCaseCstChildren,
  ControlDecrementCstChildren,
  ControlForCstChildren,
  ControlIfCstChildren,
  ControlIncrementCstChildren,
  ControlUnlessCstChildren,
  DocCstChildren,
  ExpressionCstChildren,
  ForInValueCstChildren,
  ObjectCstChildren,
  RenderTagCstChildren,
  RootCstChildren,
} from './types'

import { digPathInVariables, pathDefinedInVariables, type Variable } from './util'

const BaseCstVisitor = recoveringParser.getBaseCstVisitorConstructor()

function tokenToVariable(tok: IToken): Variable {
  return {
    type: 'value',
    name: tok.image,
    label: tok.image,
    info: `Defined on line ${tok.startLine}`,
  }
}

export class DiagnosticVisitor extends BaseCstVisitor {
  activeDomain: string | null

  constructor(activeDomain: string | null = null) {
    super()
    this.validateVisitor()

    this.activeDomain = activeDomain
  }

  root(ctx: RootCstChildren, variables: Array<Variable> = []) {
    return this.visit(ctx.doc, variables)
  }

  doc(ctx: DocCstChildren, variables: Array<Variable> = []): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = []

    const scopeVariables = [...variables]

    // This hoists variables to the top of the scope. If we want to change this behavior in the future we will need to store variable locations as well.
    if (ctx.controlAssign) {
      const visitRes = ctx.controlAssign.flatMap((a) => this.visit(a))
      scopeVariables.push(...visitRes)
    }

    if (ctx.controlCapture) {
      const visitRes = ctx.controlCapture.flatMap((a) => this.visit(a))
      scopeVariables.push(...visitRes)
    }

    if (ctx.object) {
      diagnostics.push(...ctx.object.flatMap((o) => this.visit(o, scopeVariables)))
    }

    if (ctx.controlFor) {
      diagnostics.push(...ctx.controlFor.flatMap((f) => this.visit(f, scopeVariables)))
    }

    if (ctx.controlIf) {
      diagnostics.push(...ctx.controlIf.flatMap((i) => this.visit(i, scopeVariables)))
    }

    if (ctx.controlCase) {
      diagnostics.push(...ctx.controlCase.flatMap((i) => this.visit(i, scopeVariables)))
    }

    if (ctx.controlIncrement) {
      diagnostics.push(...ctx.controlIncrement.flatMap((i) => this.visit(i, scopeVariables)))
    }

    if (ctx.controlDecrement) {
      diagnostics.push(...ctx.controlDecrement.flatMap((i) => this.visit(i, scopeVariables)))
    }

    if (ctx.renderTag) {
      diagnostics.push(...ctx.renderTag.flatMap((r) => this.visit(r, scopeVariables)))
    }

    return diagnostics
  }

  controlCapture(ctx: ControlCaptureCstChildren): Array<Variable> {
    return [tokenToVariable(ctx.Identifier[0])]
  }

  controlAssign(ctx: ControlAssignCstChildren): Array<Variable> {
    return [tokenToVariable(ctx.Identifier[0])]
  }

  object(ctx: ObjectCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    return this.visit(ctx.expression, variables)
  }

  controlFor(ctx: ControlForCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    if (!('Identifier' in ctx)) {
      return []
    }

    const id = tokenToVariable(ctx.Identifier[0])
    const diagnostics: Array<Diagnostic> = [
      ...ctx.doc.flatMap((d) => this.visit(d, [...variables, id])),
      ...this.visit(ctx.forInValue, variables),
    ]
    return diagnostics
  }

  forInValue(ctx: ForInValueCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = []

    if (ctx.Identifier) {
      const variable = digPathInVariables(ctx.Identifier[0].image, variables)

      if (!variable) {
        diagnostics.push({
          severity: 'warning',
          from: ctx.Identifier[0].startOffset,
          to: (ctx.Identifier[0].endOffset ?? ctx.Identifier[0].startOffset) + 1,
          message: `Identifier \`${ctx.Identifier[0].image}\` not found`,
        })
      } else if (variable.type !== 'array') {
        diagnostics.push({
          severity: 'warning',
          from: ctx.Identifier[0].startOffset,
          to: (ctx.Identifier[0].endOffset ?? ctx.Identifier[0].startOffset) + 1,
          message: `Identifier \`${ctx.Identifier[0].image}\` is not an array`,
        })
      }
    }

    return diagnostics
  }

  controlIf(ctx: ControlIfCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = [
      ...ctx.expression.flatMap((e) => this.visit(e, variables)),
      ...ctx.doc.flatMap((d) => this.visit(d, variables)),
    ]

    return diagnostics
  }

  controlUnless(ctx: ControlUnlessCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = [
      ...ctx.expression.flatMap((e) => this.visit(e, variables)),
      ...ctx.doc.flatMap((d) => this.visit(d, variables)),
    ]

    return diagnostics
  }

  controlCase(ctx: ControlCaseCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    return this.visit(ctx.expression[0], variables)
  }

  controlIncrement(ctx: ControlIncrementCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = []

    for (const id of ctx.Identifier) {
      if (!pathDefinedInVariables(id.image, variables)) {
        diagnostics.push({
          severity: 'warning',
          from: id.startOffset,
          to: (id.endOffset ?? id.startOffset) + 1,
          message: `Identifier \`${id.image}\` not found`,
        })
      }
    }

    return diagnostics
  }

  controlDecrement(ctx: ControlDecrementCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = []

    for (const id of ctx.Identifier) {
      if (!pathDefinedInVariables(id.image, variables)) {
        diagnostics.push({
          severity: 'warning',
          from: id.startOffset,
          to: (id.endOffset ?? id.startOffset) + 1,
          message: `Identifier \`${id.image}\` not found`,
        })
      }
    }

    return diagnostics
  }

  renderTag(ctx: RenderTagCstChildren): Array<Diagnostic> {
    const render = ctx.ControlRender[0]
    return [
      {
        severity: 'error',
        from: render.startOffset,
        to: (render.endOffset ?? render.startOffset) + 1,
        message: 'Render tags are not supported',
      },
    ]
  }

  expression(ctx: ExpressionCstChildren, variables: Array<Variable>): Array<Diagnostic> {
    const diagnostics: Array<Diagnostic> = []
    const id = ctx.Identifier?.at(0)

    if (id && this.activeDomain && id.image.startsWith('hb_') && !id.image.startsWith(`hb_${this.activeDomain}`)) {
      diagnostics.push({
        severity: 'error',
        from: id.startOffset,
        to: (id.endOffset ?? id.startOffset) + 1,
        message: `\`${id.image.replace(/\..+$/, '')}\` can only be used for the ${id.image
          .replace('hb_', '')
          .replace(/\..+$/, '')} trigger. Did you mean \`hb_${this.activeDomain}\`?`,
      })
    } else if (id && !pathDefinedInVariables(id.image, variables)) {
      diagnostics.push({
        severity: 'warning',
        from: id.startOffset,
        to: (id.endOffset ?? id.startOffset) + 1,
        message: `Identifier \`${id.image}\` not found`,
      })
    }

    return diagnostics
  }

  controlRaw() {}
  controlComment() {}
  controlElsif() {}
  controlElse() {}
  controlInlineComment() {}
  liquidTag() {}
  primitive() {}
  filterInstance() {}
  operator() {}
  controlBreak() {}
  controlContinue() {}
  controlCycle() {}
}

export default function diagnose(
  input: string,
  activeDomain: string | null = null,
  variables: Array<Variable> = []
): Array<Diagnostic> {
  const visitor = new DiagnosticVisitor(activeDomain)

  const { cst, parseErrors } = parse(input)

  return [
    ...visitor.visit(cst, variables),
    ...parseErrors
      .filter((e) => e instanceof MismatchedTokenException)
      .map((e: MismatchedTokenException) => ({
        severity: 'error',
        from: e.previousToken.endOffset ?? 0,
        to: (e.previousToken.endOffset ?? 0) + 1,
        message: e.message,
      })),
  ]
}

import { ArrowBackRounded, ArrowForwardRounded } from '@mui/icons-material'
import { styled } from '@mui/material'

import { InvestigationReviewStatusEnum } from 'types/api'

export const defaultStatusIconSize = 20

const Separator = styled('div')(({ theme }) => ({
  height: 1,
  width: 12,
  margin: theme.spacing(0.5),
  background: theme.palette.styleguide.textGreyLight,
}))

const StyledArrowForwardRounded = styled(ArrowForwardRounded)(({ theme }) => ({
  color: theme.palette.styleguide.textGreyLight,
  width: defaultStatusIconSize,
}))

const StyledArrowBackRounded = styled(ArrowBackRounded)(({ theme }) => ({
  color: theme.palette.styleguide.textGreyLight,
  width: defaultStatusIconSize,
}))

export const StatusDirection = ({ status }: { status: InvestigationReviewStatusEnum }) => {
  switch (status) {
    case InvestigationReviewStatusEnum.ReadyForReview: {
      return <StyledArrowForwardRounded />
    }
    case InvestigationReviewStatusEnum.ChangesRequested:
    case InvestigationReviewStatusEnum.Approved: {
      return <StyledArrowBackRounded />
    }
    default: {
      return <Separator />
    }
  }
}

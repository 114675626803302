import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

interface Props extends SvgProps {
  clipPathFill?: string
}

export const AccountCalendarIcon = ({ clipPathFill = '#fff', id = 'iconAccountCalendar', ...restProps }: Props) => (
  <SvgIcon
    id={id}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g clipPath={`url(#${id}_clipPath)`}>
      <path
        d="M19 3.5h-1v-1c0-.6-.4-1-1-1a1 1 0 0 0-1 1v1H8v-1c0-.6-.5-1-1-1a1 1 0 0 0-1 1v1H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2v-14a2 2 0 0 0-2-2Zm-7 3a3 3 0 1 1 0 6 3 3 0 0 1 0-6Zm6 12H6v-1c0-2 4-3.1 6-3.1s6 1.1 6 3.1v1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id={`${id}_clipPath`}>
        <path fill={clipPathFill} d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

import { useEffect, useMemo, useRef, useState } from 'react'

import {
  OperationVariables,
  DocumentNode,
  MutationHookOptions,
  ApolloError,
  useMutation,
  useQuery,
  MutationFunctionOptions,
  MutationTuple,
  WatchQueryFetchPolicy,
  TypedDocumentNode,
} from '@apollo/client'
import { NetworkError } from '@apollo/client/errors'
import { QueryFunctionOptions } from '@apollo/client/react/types/types'

import { last } from 'lodash'
import { shallowEqual } from 'react-redux'

import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'

import { HbPaginationProps } from 'components/HbComponents/HbPagination/HbPagination'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { AnalyticsEvent, AnalyticsEventData } from 'helpers/SessionTracking/analytics.types'
import { PageInfo } from 'types/api'

export type HbMutationLogEvent<E = AnalyticsEvent> = {
  name: E
  areas?: string[]
} & ({ data: E extends AnalyticsEvent ? AnalyticsEventData[E] : undefined } | { data?: undefined })

export type HbMutationOptions<E = AnalyticsEvent> = {
  flashError?: boolean
  humanizeNetworkError?: (networkError?: NetworkError) => string | undefined | null
  logEvent?: HbMutationLogEvent<E>
}

export const getHumanizedError = (
  error: ApolloError,
  // XXX In most cases it's recommended to return a GraphQL error instead of parsing
  // network errors. But for Library-related things, it's currently not trivial
  // to bubble up certain errors to be returned as GQL errors.
  humanizeNetworkError?: HbMutationOptions['humanizeNetworkError']
) => {
  const humanizedErrors: Array<string> = []

  if (humanizeNetworkError) {
    const humanizedNetworkError = humanizeNetworkError(error?.networkError)
    if (humanizedNetworkError) {
      humanizedErrors.push(humanizedNetworkError)
    }
  }

  error.graphQLErrors.forEach((v) => {
    if (v.message.trim()) {
      humanizedErrors.push(v.message)
    }
  })

  if (humanizedErrors.length) {
    return humanizedErrors.join(' ')
  }

  return 'Internal Server Error'
}

/**
 * Wraps apollo's useMutation, with additional Hb features.
 * By default, will display a flash error to the user on failure.
 * logEvent option allows logging an event when the mutation is executed.
 */
export function useHbMutation<TData = unknown, TVariables = OperationVariables>(
  mutation: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: MutationHookOptions<TData, TVariables> & HbMutationOptions
): MutationTuple<TData, TVariables> {
  const usage = useUsage()
  const dispatch = useDispatch()
  const flashError = (error: ApolloError) => {
    if (options?.onError) {
      options.onError(error)
    }

    if (options?.flashError === false) {
      throw error
    }

    dispatch(setFlashError(getHumanizedError(error, options?.humanizeNetworkError)))
  }

  const [mutate, result] = useMutation<TData, TVariables>(mutation, {
    ...options,
    onError: flashError,
  })

  const mutateEx = (mutateOptions?: MutationFunctionOptions<TData, TVariables>) => {
    if (options?.logEvent) {
      const { areas, name, data } = options.logEvent
      usage.logEvent({ name, data }, areas)
    }
    return mutate(mutateOptions)
  }

  return [mutateEx, result]
}

export interface PaginatedQuery<TNode> {
  totalCount: number
  pageInfo: PageInfo
  edges?: null | ({ node: TNode | null; cursor?: string } | null)[]
}

interface WithPageSize {
  pageSize: number
}

export type NarrowedFetchPolicy = Extract<WatchQueryFetchPolicy, 'cache-and-network' | 'cache-only' | 'cache-first'>

/**
 * - when adding a new query field, its corresponding type policy in `apolloClient.ts` should be updated
 * - the `selector` function should be memoized
 */
export function usePaginatedQuery<TQuery, TNode = unknown, TArgs extends WithPageSize = WithPageSize>({
  selector,
  query,
  args,
  pageSizes = [10, 25, 50],
  defaultPageSize,
  readAll = false,
  fetchPolicy = 'cache-and-network',
  functionOptions,
}: {
  selector: (data?: TQuery) => PaginatedQuery<TNode> | null
  query: DocumentNode | TypedDocumentNode<TQuery, TArgs>
  args?: Omit<TArgs, 'pageSize'>
  pageSizes?: number[]
  defaultPageSize?: number
  readAll?: boolean
  fetchPolicy?: NarrowedFetchPolicy
  functionOptions?: QueryFunctionOptions
}) {
  const [lastUpdatedAt, setLastUpdatedAt] = useState<number>(Date.now())
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [pageSize, setPageSizeState] = useState<number>(() => {
    if (defaultPageSize && pageSizes.includes(defaultPageSize)) {
      return defaultPageSize
    }
    return pageSizes[0]
  })

  // Keep track of the end cursor for each page so that we can
  // preserve the current page on refresh
  const pageToEndCursorMapRef = useRef<Record<number, string | null>>({})

  const variables = { pageSize, ...(args ?? {}) } as TArgs

  const { error, loading, data, previousData, fetchMore } = useQuery<TQuery, TArgs>(query, {
    variables,
    fetchPolicy,
    notifyOnNetworkStatusChange: true,
    onCompleted: functionOptions?.onCompleted,
    onError: functionOptions?.onError,
    skip: functionOptions?.skip,
  })

  // There won't be benefits of memoizing this unless `selector` is also memoized
  const info = useMemo(() => selector(data), [data, selector])

  const { displayedData, allData, resetPageNumber } = useMemo(() => {
    let _displayedData: TNode[] = []
    let _allData: TNode[] = []
    let _resetPageNumber = false

    if (info) {
      _allData = info.edges?.map((e) => e?.node).filter((n): n is TNode => !!n) ?? []

      const startOffset = readAll ? 0 : (pageNumber - 1) * pageSize
      const endOffset = pageNumber * pageSize
      _displayedData = _allData.slice(startOffset, endOffset)

      const currentPageEndCursor = last(info.edges?.slice(startOffset, endOffset))?.cursor || null
      pageToEndCursorMapRef.current[pageNumber] = currentPageEndCursor

      const resultsChanged = data !== previousData
      const currentPageEmptyButAllDataPopulated = !_displayedData.length && _allData.length

      // If resetPageNumber is true, we have results but the current page is empty.
      // The only way this occurs is if this query was called via `refetchQueries`.
      // If that is the case, it's refetched page 1 and we should
      // 1. Act as if we are on page 1.
      // 2. Set pageNumber to page 1, even if it is not.
      if (resultsChanged && currentPageEmptyButAllDataPopulated && !loading) {
        _resetPageNumber = true
        _displayedData = _allData.slice(0, pageSize)
      }
    }
    return {
      displayedData: _displayedData,
      allData: _allData,
      resetPageNumber: _resetPageNumber,
    }
  }, [data, info, loading, pageNumber, pageSize, previousData, readAll])

  useEffect(() => {
    if (resetPageNumber) {
      setPageNumber(1)
    }
  }, [resetPageNumber])

  // If additional args change, we need to reset paging since
  // it will be returning a different set of data.
  const previousArgs = useRef<unknown>(args)
  const argsChanged = !shallowEqual(args, previousArgs.current)

  useEffect(() => {
    previousArgs.current = args

    if (argsChanged) {
      setPageNumber(1)
    }
  }, [args, argsChanged])

  // set new page number after additional data has been fetched
  const onPageChange = async (newPage: number) => {
    const newStartOffset = (newPage - 1) * pageSize
    if (info && info.edges && newStartOffset > info.edges.length - 1 && info.pageInfo.hasNextPage) {
      await fetchMore({
        variables: {
          ...variables,
          after: info.pageInfo.endCursor,
        },
      })
      setPageNumber(newPage)
    } else {
      setPageNumber(newPage)
    }
  }

  const setPageSize = (newPageSize: number) => {
    setPageNumber(1)
    setPageSizeState(newPageSize)
  }

  /**
   * `refresh` is preferable to `refetchQueries` if you want to preserve pagination state
   */
  const refresh = async (): Promise<boolean> => {
    const after = pageToEndCursorMapRef.current[pageNumber - 1] || null

    // Apollo quirk. Use fetchMore() with initial variables instead of refetch()
    // to reset fields which use typePolicy relayStylePagination.
    const response = await fetchMore({
      variables: {
        ...variables,
        after,
      },
    })

    setLastUpdatedAt(Date.now())

    if (after === null || response.error) {
      setPageNumber(1)
    }

    return !response.error
  }

  const paginationProps = {
    onPageChange,
    page: resetPageNumber ? 1 : pageNumber, // If resetting the page number, report that we're on page one, even if internal state is a different page.
    pageSize,
    onPageSizeChange: setPageSize,
    total: info?.totalCount,
    pageSizeChoices: pageSizes,
    lastUpdated: lastUpdatedAt,
    onRefresh: refresh,
    loading,
  } satisfies HbPaginationProps

  return {
    error,
    loading,
    displayedData,
    allData,
    rawData: data,
    hasNextPage: !!info?.pageInfo?.hasNextPage,
    paginationProps,
    refresh,
  }
}

export type UsePaginatedQueryReturnType<TNode = unknown> =
  // The first argument to this generic (TQuery), doesn't affect
  // the return value, so we can set it to `unknown`
  ReturnType<typeof usePaginatedQuery<unknown, TNode>>

import { DELETE_NARRATIVE_TEMPLATE } from 'components/narrativeTemplates/NarrativeTemplateDialog'
import {
  DeleteNarrativeTemplateMutation,
  DeleteNarrativeTemplateMutationVariables,
} from 'components/narrativeTemplates/__generated__/NarrativeTemplateDialog.generated'

import { useHbMutation } from './ApolloHelpers'

export function useDeleteNarrativeTemplateQuery() {
  const [deleteNarrativeTemplate] = useHbMutation<
    DeleteNarrativeTemplateMutation,
    DeleteNarrativeTemplateMutationVariables
  >(DELETE_NARRATIVE_TEMPLATE, {
    logEvent: { name: 'settings:deleteNarrativeTemplate:clicked' },
    update(cache, result) {
      if (result.data?.deleteNarrativeTemplate?.narrativeTemplate) {
        const id = cache.identify(result.data?.deleteNarrativeTemplate?.narrativeTemplate)
        cache.evict({ id })
        cache.gc()
      }
    },
  })

  return {
    deleteNarrativeTemplate,
  }
}

import { createHeadlessEditor } from '@lexical/headless'
import { $generateHtmlFromNodes, $generateNodesFromDOM } from '@lexical/html'

import { $isListItemNode } from '@lexical/list'
import * as Sentry from '@sentry/react'

import {
  $getRoot,
  $createLineBreakNode,
  $createTextNode,
  LexicalNode,
  $createParagraphNode,
  LexicalEditor,
  EditorState,
  $isElementNode,
} from 'lexical'

import wordsCount from 'words-count'

import { $createMentionNode } from './MentionNode'
import { LexicalConfig } from './TypeAheadEditor'
import { richEditorNodes } from './nodes'

import type { Suggestion } from './TypeAheadPlugin'

export function makeLexicalErrorHandler(
  onError?: (error: Error, editor?: LexicalEditor) => unknown
): (error: Error, editor?: LexicalEditor) => unknown {
  return function onLexicalError(error: Error, editor?: LexicalEditor, ...rest) {
    // eslint-disable-next-line no-console
    console.error({ error, editor })
    Sentry.captureException(error, { tags: { lexical: true } })
    onError?.(error, editor, ...rest)
  }
}

export function editorStateToPlainText(state: EditorState): string {
  return state.read(() => $getRoot().getTextContent())
}

export async function editorStateToHtml(state: EditorState, onError?: LexicalConfig['onError']): Promise<string> {
  const editor = createHeadlessEditor({
    namespace: 'editorStateToHtml',
    nodes: richEditorNodes,
    onError: makeLexicalErrorHandler(onError),
  })

  editor.setEditorState(state)

  return new Promise((resolve) => {
    const { length } = editorStateToPlainText(state)

    if (length === 0) {
      resolve('')
    } else {
      editor.update(() => {
        // Caution: Do not change the tag here without also changing `RichText`
        // in the backend.
        resolve(`<body data-hb-rich-text="true">${$generateHtmlFromNodes(editor, null)}</body>`)
      })
    }
  })
}

const mentionRegex = /(@[a-zA-Z0-9-_]+)/g

// Parses plain text into a single paragraph with line breaks. This is how
// Lexical handles plain text mode, so we avoid creating multiple paragraphs in
// favor of just treating line breaks as line breaks.
function $parsePlainText(input: string, suggestions: Array<Partial<Suggestion>> = []) {
  const availableMentions = suggestions.filter((s) => s.type === 'mention')
  const para = $createParagraphNode()
  para.append(
    ...input.split(/(\r?\n)/g).flatMap((text) => {
      if (text.endsWith('\n')) {
        return $createLineBreakNode()
      }

      return text
        .split(mentionRegex)
        .filter(Boolean)
        .map((chunk) => {
          // RegExp is stateful and so calling `test` can fail after the `split`
          // call above. Let's take care of that. An alternative approach is to
          // call new RegExp(mentionRegex), but eslint doesn't like that.
          // https://stackoverflow.com/a/11477448/355325
          mentionRegex.lastIndex = 0
          if (mentionRegex.test(chunk)) {
            const account = availableMentions.find((m) => m.value === chunk.slice(1))
            if (account) {
              return $createMentionNode(chunk.slice(1))
            }
          }
          return $createTextNode(chunk)
        }) as LexicalNode[]
    })
  )

  return para
}

// Parses plain text into multiple paragraphs for inserting into a _rich_ text editor.
export function $textToParagraphs(input: string, suggestions: Array<Partial<Suggestion>> = []) {
  // (\r?\n){2,} is considered unsafe
  return input.split(/(\r\n|\n){2,}/g).map((para) => $parsePlainText(para, suggestions)) as LexicalNode[]
}

export function $prepopulatePlainText(input: string, suggestions: Array<Partial<Suggestion>> = []) {
  return () => {
    const root = $getRoot()
    const para = $parsePlainText(input, suggestions)
    root.append(para)
  }
}

// This is to work around a bug in Lexical where nested list items result in
// empty list items before each nested list.
// There is a PR open to fix this issue: https://github.com/facebook/lexical/pull/2842
function $removeEmptyListItems(nodes: LexicalNode[]) {
  nodes.forEach((node) => {
    // If we are in an empy list item...
    if ($isListItemNode(node) && node.isEmpty()) {
      const next = node.getNextSibling()
      // And if the next sibling is a list item which contains a list as its first child...
      if ($isListItemNode(next) && next.getChildren()?.[0]?.getType() === 'list') {
        // Remove the empty list item!
        node.remove()
      }
    } else if ($isElementNode(node)) {
      $removeEmptyListItems(node.getChildren())
    }
  })
}

export function $parseHtml(
  editor: LexicalEditor,
  input: string,
  suggestions: Array<Partial<Suggestion>> = []
): LexicalNode[] {
  const parser = new DOMParser()
  const doc = parser.parseFromString(
    input
      .replace(/@([a-zA-Z0-9-_]+)/g, (match, username: string) => {
        if (suggestions.some((s) => s.value === username && s.type === 'mention')) {
          return `<span data-hb-mention="true">${match}</span>`
        }

        return match
      })
      .trim(),
    'text/html'
  )

  let para = null

  // Wrap sibling non-paragraphs and non-lists in paragraph elements
  for (const child of Array.from(doc.body.childNodes)) {
    if (
      !(child instanceof HTMLParagraphElement || child instanceof HTMLUListElement || child instanceof HTMLOListElement)
    ) {
      if (!para) {
        para = document.createElement('p')
      }
      doc.body.removeChild(child)
      para.appendChild(child)
    } else if (para) {
      child.parentNode?.insertBefore(para, child)
      para = null
    }
  }

  // Append final wrapper if we have one
  if (para) {
    doc.body.appendChild(para)
  }

  const nodes = $generateNodesFromDOM(editor, doc)

  $removeEmptyListItems(nodes)

  return nodes
}

function processNonRichTextForRichTextEditor(input: string) {
  // Case 1: process HTML for timeline
  if (input.includes('<ul>')) {
    return (
      input
        .replace(/\s{2,}/g, ' ')
        // (\r?\n){2,} is considered unsafe
        .replace(/(\r\n|\n){2,}/g, '\n')
        // The following two liens fix up the output from timeline_builder.rb
        // Minifying the output on the server side proved to be more complex than necessary.
        // Without this code the spaces between <li> tags result in empty list items.
        // This bug has been reported: https://github.com/facebook/lexical/issues/2807
        .replace(/<ul>\s+/g, '<ul>')
        .replace(/<\/li>\s+/g, '</li>')
    )
  }

  // Case 2: process plain text from previous editor
  return input
    .split(/\r?\n/g)
    .filter((s) => Boolean(s.trim()))
    .map((para) => `<p>${para.trim()}</p>`)
    .join('')
}

export function $prepopulateHtml(input: string, suggestions: Array<Partial<Suggestion>> = []) {
  return (editor: LexicalEditor) => {
    const nodes = $parseHtml(
      editor,
      // Caution: Do not change the tag here without also changing `RichText`
      // in the backend.
      /^<body[^>]+?data-hb-rich-text="true"/.test(input) ? input : processNonRichTextForRichTextEditor(input),
      suggestions
    )
    const rootNode = $getRoot()
    rootNode.append(...(nodes.length > 0 ? nodes : [$createParagraphNode()]))
  }
}

export function countWords(input: string) {
  return wordsCount(input.replace(/^\s*(?:-|\d+\.)/gm, ''))
}

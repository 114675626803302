import { OutlinedInput, styled } from '@mui/material'

import { getHbGradient } from 'components/colors'
import { SearchIcon } from 'icons'

import bg from './ExploreAutomationsBg.svg'

export const GradientHeader = styled('header')(({ theme }) => ({
  position: 'relative',
  background: `url(${bg})`,
  // These negative margins offset the parent's padding, which we don't want
  // to remove b/c the parent is shared by all tabs
  margin: theme.spacing(-3, -3, 0, -3),
  boxSizing: 'border-box',
  padding: theme.spacing(4, 3, 16, 3),
  marginBottom: theme.spacing(12),
  '&::after, &::before': {
    content: '""',
    display: 'block',
    width: '100%',
    bottom: 0,
    left: 0,
    position: 'absolute',
    zIndex: 0,
  },
  '&::after': {
    background: 'linear-gradient(transparent, white)',
    height: '60%',
  },
  '&::before': {
    mixBlendMode: 'multiply', // Makes the underlying background textures visible
    background: getHbGradient(1),
    height: '100%',
  },
}))

export const GradientHeaderContent = styled('div')(({ theme }) => ({
  zIndex: 1,
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  rowGap: theme.spacing(2),
}))

export const RecipeCategoryCardsWrapper = styled('div')(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  bottom: theme.spacing(-9),
  left: 0,
  width: '100%',
}))

export const SmallSearchWrapper = styled('div')(({ theme }) => ({
  margin: theme.spacing(-3, -3, 3, -3),
  padding: theme.spacing(3),
  borderBottom: `1px solid ${theme.palette.divider}`,
  display: 'flex',
  justifyContent: 'space-between',
}))

export const SearchInput = styled(OutlinedInput)(() => ({
  minWidth: 400,
}))

export const StyledSearchIcon = styled(SearchIcon)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}))

import { useState } from 'react'

import { Alarm } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { styled } from '@mui/styles'

import { UseFormWatch } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'
import { Zap } from 'icons/Zap'

import { AutomationRuleType } from 'types/api'

import { AutomationIcon } from '../AutomationIcon'

import { useAutomationAdminOperations } from '../hooks/AutomationAdminHelpers'

import { ActionCard } from './ActionCard'
import { generateTriggerName } from './TriggerFilterEditor'
import { useDomainFieldSpecs } from './TriggerFilterEditor/fieldConfig'
import { TriggerTemplateSelector } from './TriggerTemplateSelector'

import { FormSchemaForRule, getDefaultTriggerFilters, type FormSchemaReturnType } from './formSchema'
import { DEFAULT_DOMAIN_TYPE, getDefaultScheduleValues } from './util'

const AdminWarningText = styled(HbText)(({ theme }) => ({
  color: theme.palette.warning.main,
}))

const ScheduleTriggerIconContainer = styled('div')({
  position: 'relative',
  height: 84,
})

const Content = styled('div')({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  '@media (max-width: 1050px)': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
})

const iconCss = {
  scheduleTriggerAlarmIcon: {
    position: 'absolute' as const,
    top: 0,
    zIndex: 2,
  },
  scheduleTriggerZapIcon: {
    position: 'absolute' as const,
    top: 34,
    zIndex: 1,
  },
}

export function CreateAutomationSelectType({ form }: { form: FormSchemaReturnType }) {
  const orgTimeZone = useOrgTimeZone()

  const [isTriggerSelectorOpen, setIsTriggerSelectorOpen] = useState(false)

  const { watch } = form
  const { organizationToken } = (watch as UseFormWatch<FormSchemaForRule>)()
  const isAdminRuleForOtherOrg = useAutomationAdminOperations(organizationToken || '')
  const { loading, getDomainFieldSpec } = useDomainFieldSpecs()

  const handleResetScheduleValues = () => {
    // Ensure form is dirty and therefore submittable
    // This is a HACK! RHF has no way of setting a form as dirty after a reset
    form.setValue('automationType', AutomationRuleType.Trigger, { shouldDirty: true })

    form.reset(
      {
        ...form.getValues(),
        ...getDefaultScheduleValues(orgTimeZone),
        automationType: AutomationRuleType.Schedule,
      },
      { keepDirty: true }
    )
  }

  const handleResetScheduleWithTriggerValues = () => {
    // Ensure form is dirty and therefore submittable
    // This is a HACK! RHF has no way of setting a form as dirty after a reset
    form.setValue('automationType', AutomationRuleType.Trigger, { shouldDirty: true })
    const values = form.getValues()

    const domainFieldSpec = getDomainFieldSpec({ type: DEFAULT_DOMAIN_TYPE, datasourceToken: null })
    invariant(domainFieldSpec)

    form.reset(
      {
        ...values,
        ...getDefaultScheduleValues(orgTimeZone),
        automationType: AutomationRuleType.ScheduleWithTrigger,
        domain: {
          ...('domain' in values ? values.domain : {}),
          type: DEFAULT_DOMAIN_TYPE,
        },
        eventText: generateTriggerName(DEFAULT_DOMAIN_TYPE),
        triggerFilters: getDefaultTriggerFilters(DEFAULT_DOMAIN_TYPE, domainFieldSpec),
      },
      { keepDirty: true }
    )
  }

  if (loading) {
    return <Loader variant="global" />
  }

  return (
    <>
      <TriggerTemplateSelector
        form={form}
        open={isTriggerSelectorOpen}
        onClose={() => setIsTriggerSelectorOpen(false)}
      />
      {/* WIP (@robocop) will clean up as part of PROD-13411 */}
      {isAdminRuleForOtherOrg && (
        <AdminWarningText bold size="lg">
          You are operating as an automation administrator and are creating a rule for a different organization. The
          types of automations you can create are restricted.
        </AdminWarningText>
      )}
      <Content>
        {!isAdminRuleForOtherOrg && (
          <ActionCard
            title="Start with a trigger"
            description="Select an event from which certain automated actions are to be performed"
            onClick={() => setIsTriggerSelectorOpen(true)}
            icon={<AutomationIcon Icon={Zap} color="mint" />}
          />
        )}
        <ActionCard
          title="Set a schedule"
          description="Setting a schedule will determine when to perform certain automatic actions"
          onClick={handleResetScheduleValues}
          icon={<AutomationIcon Icon={Alarm} color="sea" />}
        />
        {!isAdminRuleForOtherOrg && (
          <ActionCard
            title="Set a schedule with a trigger"
            description="Set a schedule that works with a trigger"
            onClick={handleResetScheduleWithTriggerValues}
            icon={
              <ScheduleTriggerIconContainer>
                <AutomationIcon Icon={Alarm} color="sea" overrideCss={iconCss.scheduleTriggerAlarmIcon} />
                <AutomationIcon Icon={Zap} color="mint" overrideCss={iconCss.scheduleTriggerZapIcon} />
              </ScheduleTriggerIconContainer>
            }
          />
        )}
      </Content>
    </>
  )
}

import { times } from 'lodash'
import moment from 'moment-timezone'

import { State } from 'actions/store'
import { getCurrentOrganization } from 'helpers/stateHelpers'

// Returns the browser timezone
export function getTimezone(): string {
  return moment.tz.guess()
}

export function getOrganizationTimezone(state: State): string {
  return getCurrentOrganization(state).timezone || getTimezone()
}

export function getStartOfWeekOptionsForNPreviousWeeks(
  numberOfWeeks: number,
  includeCurrentWeek: boolean = false
): { display: string; value: string }[] {
  const startingDate = includeCurrentWeek ? moment() : moment().subtract(1, 'week')

  const startOfMostRecentCompletedWeek = startingDate.startOf('week').add(1, 'day')

  return times(numberOfWeeks, (offset) => {
    const date = startOfMostRecentCompletedWeek.clone().subtract(offset, 'weeks')
    return {
      display: date.format('YYYY-MM-DD'),
      value: date.format('YYYY-MM-DD'),
    }
  })
}

import React from 'react'

import { PdfViewerProps } from './PdfViewerInternal.types'

const ViewerConnected = React.lazy(() => import('./PdfViewerInternalConnected'))
export function AttachmentPdfViewerConnected(props: Pick<PdfViewerProps, 'pdfUrl'>) {
  return <ViewerConnected {...props} />
}

const Viewer = React.lazy(() => import('./PdfViewerInternal'))
export function AttachmentPdfViewer(props: PdfViewerProps) {
  return <Viewer {...props} />
}

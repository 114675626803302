// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

import Table, { TableProps } from './Table'

type StyleProps = {
  columnWidths?: { column: number; width: string | number }[]
  headerHeight?: number | string | null
}
const applyColumnWidths = (props?: StyleProps) => {
  if (props?.columnWidths) {
    const columnWidths = props.columnWidths.reduce((acc, cur) => {
      acc[`&:nth-child(${cur.column})`] = { width: cur.width }
      return acc
    }, {} as Record<string, { width: any }>)

    return columnWidths
  }

  return {}
}

const batchSelectCellStyles = (theme: Theme) => ({
  paddingRight: `0`,
  marginLeft: theme.spacing(-1.5),
})

// Restyles the Table component to a plain text design
const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(),
    marginBottom: theme.spacing(3),
  },
  innerContainer: {
    padding: 0,
    overflowX: 'auto',
  },
  header: (props?: StyleProps) => ({
    borderBottom: `1px solid ${theme.palette.dividers.light}`,

    '& th': {
      backgroundColor: theme.palette.background.light,
      ...theme.typography.sm,

      '& > div': {
        height: props?.headerHeight !== undefined ? props.headerHeight : 24,
        padding: `0 ${theme.spacing(3)}`,
      },
    },
    '& th.batch-select-cell > div': batchSelectCellStyles(theme),
  }),
  table: (props?: StyleProps) => ({
    '& tbody': {
      boxShadow: 'none',

      '& tr': {
        '&:last-child': {
          borderBottom: 'none',
        },

        '& td': {
          ...theme.typography.md,
          ...applyColumnWidths(props),
        },
      },
    },
  }),
}))

const useTableRowOverrides = makeStyles((theme: Theme) => ({
  cellContent: {
    minHeight: theme.spacing(6),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: `0 ${theme.spacing(3)}`,

    ...theme.typography.sizes.md,
    color: theme.palette.text.secondary,
    '.hb-table__cell--batch &': batchSelectCellStyles(theme),
  },
}))

export type Props = Omit<TableProps, 'classes'> & {
  styleProps?: StyleProps
  classes?: Partial<ReturnType<typeof useStyles>>
}
export function PlainTable(props: Props) {
  const { styleProps, styleOverrides, classes: classesProp, id, ...rest } = props
  const classes = useStyles(styleProps)
  const mergedClasses = mergeOverrideStyles(classes, classesProp)
  return (
    <Table
      hideArrows
      id={id || 'default-id'}
      {...rest}
      classes={mergedClasses}
      styleOverrides={{
        Table: {},
        DefaultHeaderCell: {},
        TableWithReorderableColumns: {},
        TableRow: useTableRowOverrides(),
      }}
    />
  )
}

import { useCallback, MouseEvent, useState, ReactNode } from 'react'

import { styled } from '@mui/material'
import Breadcrumbs, { breadcrumbsClasses } from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { deleteAttachment } from 'actions/importingActions'
import { useDispatch, useSelector } from 'actions/store'
import { setDisplayAttachment, downloadAttachment, SurveyFileUpload } from 'actions/viewActions'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import AttachmentHeaderActionButtons from 'components/attachment/AttachmentHeaderActionButtons'
import { DeleteFileDialogInternal } from 'components/cases/Tabs/Files/CaseFileListDialogs'
import EllipsisMenu, { EllipsisAction } from 'components/library/EllipsisMenu'
import { useResizeObserver } from 'components/library/Resize/hooks'
import { opacify } from 'helpers/colors'
import { hasPermission } from 'helpers/stateHelpers'
import { fileSize } from 'helpers/uiHelpers'
import { useFeatureFlag } from 'hooks'
import { useDateFormatter } from 'hooks/DateFormatHooks'
import { useOnEscape } from 'hooks/UseOnEscape'
import { CloseIcon } from 'icons'
import { InvestigationAttachment } from 'reducers/investigationsReducer.types'
import { AttachmentMalwareScanStatus, BadgePermissionsEnum, FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'

import { ATTACHMENT_OVERLAY_Z_INDEX } from './AttachmentContainer'
import { AttachmentMalwareScanStatusIcon } from './AttachmentMalwareScanStatusIcon'
import { isDownloadable } from './helpers'
import { PdfViewerProps } from './pdf/PdfViewerInternal.types'

export const ATTACHMENT_HEADER_HEIGHT = 72
export const TITLE_MIN_WIDTH = 35

export type AttachmentHeaderProps = {
  breadcrumbLinkText?: string
  navContent?: ReactNode
  onDelete?: () => void
  onDownload?: (_e: MouseEvent) => void
  onClose?: () => void
  toggleSummary?: () => void
  onBreadcrumbClick?: () => void
  isSummarizable?: boolean
  malwareScanStatus?: AttachmentMalwareScanStatus | null
} & AttachmentMetadata &
  Pick<PdfViewerProps, 'darkMode'>

export const AttachmentHeaderContainer = styled('div')<{ darkMode?: boolean }>(({ theme, darkMode }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  zIndex: 1,
  height: ATTACHMENT_HEADER_HEIGHT,
  padding: theme.spacing(0, 2),
  backgroundColor: darkMode ? opacify(theme.palette.background.dark, 0.9) : 'white',
  display: 'flex',
  flexDirection: 'row',
  borderBottom: darkMode ? undefined : `1px solid ${theme.palette.dividers.medium}`,
}))

export const TitleContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  minWidth: TITLE_MIN_WIDTH,
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'left',
  padding: theme.spacing(0, 1, 0, 0.5),
}))

export const NavContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
}))

export const StyledBreadcrumbs = styled(Breadcrumbs)(() => ({
  minWidth: TITLE_MIN_WIDTH,
  [`& .${breadcrumbsClasses.ol}`]: {
    flexWrap: 'nowrap',
  },
  [`& .${breadcrumbsClasses.li}`]: {
    '&:first-of-type, &:last-of-type': {
      minWidth: TITLE_MIN_WIDTH,
    },
  },
}))

export const LargeLink = styled(Link)(({ theme }) => ({
  display: 'block',
  fontSize: '16px',
  '&:hover': {
    color: theme.palette.styleguide.hbBlueMedium,
  },
}))

export const ActionContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  flex: 1,
  gridGap: theme.spacing(1),
}))

export const FilenameWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(0.5),
}))

const useStyles = makeStyles((theme: Theme) => ({
  button: ({ darkMode }: Pick<AttachmentHeaderProps, 'darkMode'>) =>
    darkMode
      ? {
          background: 'none',
          border: 'none',
          color: 'white',
          '&:hover, &:focus, &:focus:hover': {
            color: theme.palette.styleguide.nearWhite,
            background: 'none',
            border: 'none',
          },
        }
      : {},
}))

interface Props {
  attachment: InvestigationAttachment | SurveyFileUpload
}

export type AttachmentMetadata = {
  fileName?: string
  uploadedAt?: string
  fileSizeBytes?: number
}

export const useMetadataString = ({ uploadedAt, fileSizeBytes }: Omit<AttachmentMetadata, 'fileName'>) => {
  const formatDate = useDateFormatter()
  const uploadTimeString = uploadedAt
    ? `Added ${formatDate(uploadedAt, '', 'MMM D')} at ${formatDate(uploadedAt, '', 'LT')}`
    : ''
  const fileSizeString = fileSizeBytes ? fileSize(fileSizeBytes) : undefined

  return [uploadTimeString, fileSizeString].filter(Boolean).join(' - ')
}

export function AttachmentHeader(props: AttachmentHeaderProps) {
  const {
    onDelete,
    onDownload,
    onClose,
    onBreadcrumbClick,
    breadcrumbLinkText = 'Files',
    uploadedAt,
    fileSizeBytes,
    fileName,
    darkMode,
    toggleSummary,
    isSummarizable,
    navContent,
    malwareScanStatus,
  } = props
  const { button } = useStyles({ darkMode })

  const userCanDownloadCaseData = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.DownloadCaseDocuments)
  )
  const enableAttachmentSummarization = useFeatureFlag(FeatureFlag.EnableAttachmentSummarization)
  const metadataString = useMetadataString({ uploadedAt, fileSizeBytes })

  const [showConfirmDelete, setShowConfirmDelete] = useState(false)

  const actions = [
    userCanDownloadCaseData && onDownload ? { display: 'Download', onClick: onDownload } : undefined,
    onDelete
      ? {
          display: 'Delete',
          onClick: () => setShowConfirmDelete(true),
        }
      : undefined,
  ].filter((a): a is EllipsisAction => !!a)

  const textColor = darkMode ? 'white' : undefined
  const handleBreadcrumbClick = (event: React.MouseEvent) => {
    event.preventDefault()
    if (onBreadcrumbClick) {
      onBreadcrumbClick()
    }
  }

  const [iconOnly, setIconOnly] = useState<boolean>(false)
  const setIconOnlyAfterResize: ResizeObserverCallback = useCallback(
    ([{ target }]) => {
      setIconOnly(target.clientWidth < 500)
    },
    [setIconOnly]
  )
  const { getRef } = useResizeObserver(setIconOnlyAfterResize)

  return (
    <div ref={getRef}>
      <AttachmentHeaderContainer darkMode={darkMode}>
        <TitleContainer>
          {onBreadcrumbClick ? (
            <NavContainer>
              <StyledBreadcrumbs aria-label="breadcrumb">
                <LargeLink noWrap underline="hover" color="inherit" href="." onClick={handleBreadcrumbClick}>
                  {breadcrumbLinkText}
                </LargeLink>
                <FilenameWrapper>
                  {malwareScanStatus && <AttachmentMalwareScanStatusIcon malwareScanStatus={malwareScanStatus} />}
                  <HbText block noWrap color={textColor} bold size="lg">
                    {fileName}
                  </HbText>
                </FilenameWrapper>
              </StyledBreadcrumbs>
              {navContent}
            </NavContainer>
          ) : (
            <HbText block color={textColor} bold size="lg">
              {fileName}
            </HbText>
          )}

          {metadataString && (
            <HbText size="s" color={textColor}>
              {metadataString}
            </HbText>
          )}
        </TitleContainer>
        <ActionContainer>
          <AttachmentHeaderActionButtons
            onDownload={isDownloadable(malwareScanStatus) ? onDownload : undefined}
            toggleSummary={toggleSummary}
            isSummarizable={enableAttachmentSummarization && isSummarizable}
            iconOnly={iconOnly}
          />
          {actions.length && !enableAttachmentSummarization ? (
            <EllipsisMenu
              classes={{ button }}
              actions={actions}
              zIndex={ATTACHMENT_OVERLAY_Z_INDEX + 1}
              menuPlacement="bottom-end"
              size="medium"
            />
          ) : null}
          {onClose && <HbButton className={button} Icon={CloseIcon} label="Close" iconOnly onClick={onClose} />}
        </ActionContainer>
      </AttachmentHeaderContainer>
      {onDelete && (
        <DeleteFileDialogInternal
          open={showConfirmDelete}
          onClose={() => setShowConfirmDelete(false)}
          onConfirm={onDelete}
        />
      )}
    </div>
  )
}

// connected to redux
export function AttachmentHeaderConnected({ attachment }: Props) {
  const dispatch = useDispatch()

  const handleClose = useCallback(() => {
    dispatch(setDisplayAttachment(null))
  }, [dispatch])

  const handleDownload = useCallback(
    (_event: MouseEvent) => {
      if (attachment.type === 'surveyFileUpload') {
        window.open(attachment.url, '_blank')
      } else {
        dispatch(downloadAttachment(attachment))
      }
    },
    [attachment, dispatch]
  )

  const handleDelete = useCallback(() => {
    if (attachment.type === 'attachment') {
      dispatch(deleteAttachment(attachment.investigationToken, attachment.token))
      dispatch(setDisplayAttachment(null))
    }
  }, [attachment, dispatch])

  useOnEscape(handleClose)

  return (
    <AttachmentHeader
      onDelete={attachment.type === 'attachment' ? handleDelete : undefined}
      onDownload={handleDownload}
      onClose={handleClose}
      uploadedAt={attachment.type === 'attachment' ? attachment.uploadedAt : undefined}
      fileSizeBytes={attachment.fileSizeBytes || undefined}
      fileName={attachment.filename}
      darkMode
    />
  )
}

import { Box, styled } from '@mui/system'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import TrashOutlineIcon from 'icons/TrashOutlineIcon'

const DeleteButton = styled(HbButton)(({ theme }) => ({
  color: theme.palette.styleguide.errorMedium,
  border: `1px solid ${theme.palette.styleguide.errorMedium}`,
  '&:hover, &:focus': {
    color: theme.palette.styleguide.errorMedium,
    border: `1px solid ${theme.palette.styleguide.errorMedium}`,
  },
}))

export const DeleteAutomationPanel = ({ loading, deleteRule }: { loading: boolean; deleteRule: () => void }) => {
  return (
    <Box sx={{ px: 2 }}>
      <HbText tag="p">
        Deleting this automation will stop future runs in Hummingbird. All changes made thus far will not be undone.
      </HbText>
      <DeleteButton
        label="Delete automation"
        onClick={deleteRule}
        disabled={loading}
        variant="secondary"
        fullWidth
        Icon={TrashOutlineIcon}
      />
    </Box>
  )
}

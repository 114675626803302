import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionTypeEnum } from 'types/api'

export const BatchUncompleteDialog: DialogComponent = (props) => {
  const { children, ...dialogProps } = props

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Uncomplete}
      confirmEnabled
      title="Reopen reviews"
      primaryText="Would you like to reopen the reviews selected? This action will revert reviews to their non-completed state."
      confirmText="Yes, reopen reviews."
    >
      {children}
    </BaseBatchActionDialog>
  )
}

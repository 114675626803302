import { ReactNode } from 'react'

import Feature from 'components/Feature'
import { PermissionSwitch } from 'components/permissions/PermissionSwitch'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'

const ReadProfileDashboardPermissions = [BadgePermissionsEnum.ReadProfileDashboard]

export function ProfilesDashboardGate({ children, otherwise = null }: { children: ReactNode; otherwise?: ReactNode }) {
  return (
    <Feature name={FeatureFlag.EnableReadProfileDashboardPermission} otherwise={children}>
      <PermissionSwitch permissions={ReadProfileDashboardPermissions} allowed={children} restricted={otherwise} />
    </Feature>
  )
}

import { createReducer } from '@reduxjs/toolkit'

import { v4 as uuidv4 } from 'uuid'

import { SET_INITIAL_STATE } from 'actions/applicationActionTypes'
import { QueryName } from 'dashboards/shared/components/Dashboard/NamedQueryGroups.types'
import { filtersMap } from 'dashboards/shared/react/getNamedQueryGroups'
import { dashboardActionsMap } from 'reducers/dashboards/dashboards.actions'
import { readQueryOrProvideDefaultFilters } from 'reducers/dashboards/queryStringTools'
import { DashboardTypeEnum, SortEnum } from 'types/api'
import { InitialData } from 'types/hb'
import { findFilterComponent, isSearchFilterWithId, SearchFilterWithId } from 'utils/query/api.types'

import { denormalizeRequest } from 'utils/query/normalization'

import { addIdToSearchRequest } from './addIdToSearchRequest'

import { DashboardSlice, getStoredPageSize } from './dashboards.constants'
import { DashboardState, sortIsValid } from './dashboards.types'

export const defaultSortDirection = SortEnum.Asc

export const makeInitialState = <T extends DashboardSlice>(slice: T): DashboardState<T> => {
  const { defaultFilter, defaultSorts, dashboardPathMatches } = filtersMap[slice]

  const query = readQueryOrProvideDefaultFilters(dashboardPathMatches)
  const { applied } = query

  // ----------------------------------------------------------------

  const currentViewTitle = defaultFilter().title
  const initialState: DashboardState<T> = {
    filters: {
      applied: {
        ...applied,
        sorts: applied.sorts?.length ? applied.sorts : defaultSorts.slice(),
      },
      name: undefined,
      custom: false,
      remoteOpen: false,
    },
    page: {
      number: 1,
      size: getStoredPageSize(slice),
      count: 1,
    },
    batchSelectedItems: {},
    recentlyUpdatedItems: {},
    mostRecentlyBatchSelectedItem: null,
    mostRecentBatchJobId: null,
    currentViewTitle,
  }
  return initialState
}

export const makeDashboardReducer = <T extends DashboardSlice>(slice: T, customState?: DashboardState<T>) => {
  const actions = dashboardActionsMap[slice]
  const initialState = makeInitialState(slice)

  const { unfilteredFilter, defaultSorts, defaultFilter, dashboardPathMatches } = filtersMap[slice]
  const unfilteredFilterName = unfilteredFilter.name

  return createReducer<DashboardState<T>>(customState ?? initialState, (builder) => {
    builder
      .addCase(actions.query.set.fulfilled, (state, action) => {
        state.filters.applied = { ...state.filters.applied, query: action.payload }
      })
      .addCase(actions.sorts.set.fulfilled, (state, action) => {
        state.filters.applied.sorts = action.payload
      })
      .addCase(actions.sorts.update.field.fulfilled, (state, { payload: { oldField, newField } }) => {
        const sortToUpdate = state.filters.applied.sorts?.find(({ sortField }) => sortField === oldField)
        if (sortToUpdate) {
          sortToUpdate.sortField = newField
          if (!sortToUpdate.sortDir) sortToUpdate.sortDir = defaultSortDirection
        }
      })
      .addCase(actions.sorts.update.direction.fulfilled, (state, { payload: { direction, field } }) => {
        const sortToUpdate = state.filters.applied.sorts?.find(({ sortField }) => sortField === field)
        if (sortToUpdate) sortToUpdate.sortDir = direction
      })
      .addCase(actions.sorts.reset.fulfilled, (state) => {
        state.filters.applied.sorts = defaultSorts
      })
      .addCase(actions.sorts.remove.fulfilled, (state, action) => {
        const { sorts } = state.filters.applied
        state.filters.applied.sorts = sorts?.filter(({ sortField }) => sortField !== action.payload)
      })
      .addCase(actions.sorts.addEmpty, (state) => {
        state.filters.applied.sorts = [
          ...(state.filters.applied.sorts ?? []).filter(sortIsValid),
          { sortDir: defaultSortDirection },
        ]
      })
      .addCase(actions.filters.createComponentById.fulfilled, (state, action) => {
        // Draft has an issue with conditional types.
        state.filters.name = 'custom' as import('@reduxjs/toolkit').Draft<QueryName<T>>
        const newEntry = { ...action.payload, id: uuidv4() }

        const { id, parentID } = action.payload
        const appliedFilter = state.filters.applied?.filter
        if (parentID) {
          // if parentID provided, create component on parent
          const group = findFilterComponent(parentID, false, appliedFilter)
          group?.children.push(newEntry)
        } else if (id) {
          const parent = findFilterComponent(id, true, appliedFilter)
          parent?.children.push(newEntry)
        } else {
          appliedFilter?.children.push(newEntry)
        }
      })
      .addCase(actions.filters.updateComponentById.fulfilled, (state, action) => {
        // Draft has an issue with conditional types.
        state.filters.name = 'custom' as import('@reduxjs/toolkit').Draft<QueryName<T>>
        const parent = findFilterComponent(action.payload.id, true, state.filters.applied?.filter)

        const target = parent?.children.find((child): child is SearchFilterWithId => child.id === action.payload.id)
        if (target) {
          target.field = action.payload.field
          target.predicate = action.payload.predicate
          target.valid = action.payload.valid
        }
      })
      .addCase(actions.filters.updateGroupById, (state, action) => {
        // Draft has an issue with conditional types.
        state.filters.name = 'custom' as import('@reduxjs/toolkit').Draft<QueryName<T>>
        const group = findFilterComponent(action.payload.id, false, state.filters.applied?.filter)
        if (group && !isSearchFilterWithId(action.payload)) {
          const { combinator, children } = action.payload
          group.combinator = combinator
          group.children = children
        }
      })
      .addCase(actions.filters.deleteComponentById.fulfilled, (state, action) => {
        const parent = findFilterComponent(action.payload.id, true, state.filters.applied?.filter)

        if (parent) {
          parent.children = parent.children.filter((child) => child.id !== action.payload.id)

          // If a saved filter is emptied out, set the current filter to the unfiltered filter name. On reviews this is 'All Cases'
          if (parent.children.length === 0 && state.filters.name !== unfilteredFilterName) {
            // Draft has an issue with conditional types.
            state.filters.name = unfilteredFilterName as import('@reduxjs/toolkit').Draft<QueryName<T>>
          } else {
            // Draft has an issue with conditional types.
            state.filters.name = 'custom' as import('@reduxjs/toolkit').Draft<QueryName<T>>
          }
        }
      })
      .addCase(actions.currentViewTitle.set, (state, action) => {
        state.currentViewTitle = action.payload
      })
      .addCase(actions.customViews.set, (state, action) => {
        state.customViews = action.payload
      })
      .addCase(actions.page.set, (state, action) => {
        state.page = action.payload
      })
      .addCase(actions.page.setNumber, (state, action) => {
        state.page.number = action.payload
      })
      .addCase(actions.page.setSize, (state, action) => {
        state.page.size = action.payload
        state.page.number = 1
      })
      .addCase(actions.page.setCount, (state, action) => {
        state.page.count = action.payload
        state.page.number = 1
      })
      .addCase(actions.filters.changeView.fulfilled, (state, action) => {
        const { sorts: appliedSorts } = state.filters.applied
        const denormalized = denormalizeRequest(action.payload)
        // Unset query, denormalizeRequest only reports query if it exists. This effectively clears the query out if not included in the action payload.
        state.filters.applied = { query: undefined, ...addIdToSearchRequest(denormalized) }

        // fall back to previous behavior of applying an existing sort if one isn't explicitly specified
        if (!denormalized.sorts?.length) {
          state.filters.applied.sorts = appliedSorts
        }

        // If a defined title is provided, update the currentViewTitle
        const { title } = action.payload
        if (title && state.currentViewTitle !== title) state.currentViewTitle = title
        state.page.number = 1
      })
      .addCase(actions.filters.setApplied.fulfilled, (state, action) => {
        state.filters.applied = addIdToSearchRequest(action.payload.request)
        state.filters.custom = false
        // Draft has an issue with conditional types.
        state.filters.name = action.payload.name as import('@reduxjs/toolkit').Draft<QueryName<T>>
        state.page.number = 1
      })
      .addCase(actions.filters.remoteOpen, (state, action) => {
        state.filters.remoteOpen = action.payload
      })
      .addMatcher(
        (action) => action.type === SET_INITIAL_STATE,
        (state, action: { type: string; state: InitialData }) => {
          const { state: actionState } = action
          const { currentAccount, organizations, currentOrganizationToken } = actionState

          if (currentAccount && organizations && currentOrganizationToken) {
            const currentOrganization = organizations.find((org) => org.token === currentOrganizationToken)
            const { applied, name } = readQueryOrProvideDefaultFilters(
              dashboardPathMatches,
              defaultFilter(currentAccount, currentOrganization)
            )
            state.filters.applied = {
              ...state.filters.applied,
              ...applied,
              sorts: applied.sorts?.length ? applied.sorts : defaultSorts.slice(),
            }
            if (name) {
              state.currentViewTitle = name
            }
          }
        }
      )
  })
}

export const initialState: DashboardState<DashboardTypeEnum.Reviews> = makeInitialState(DashboardTypeEnum.Reviews)

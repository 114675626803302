import { InputBaseComponentProps, MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { capitalize } from 'lodash'
import pluralize from 'pluralize'

import { ControllerRenderProps } from 'react-hook-form'

import NumberFormat from 'react-number-format'

import HbTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'
import { ReviewType } from 'reducers/applicationReducer'
import { AutomationDateUnitEnum, SnoozeTimingPolicyEnum } from 'types/api'

import { Option, Theme } from 'types/hb'

import { useThinControlStyles } from '../TriggerFilterEditor/styles'

export const useSharedStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr  2fr 2fr',
    gap: theme.spacing(),
    alignItems: 'baseline',
  },
  formControl: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(),
    margin: theme.spacing(2, 0),
  },
}))

export const DATE_UNIT_SHORT_TO_LONG = [
  AutomationDateUnitEnum.Minutes,
  AutomationDateUnitEnum.Hours,
  AutomationDateUnitEnum.Days,
  AutomationDateUnitEnum.Months,
]

export const SNOOZE_TIMING_POLICY_OPTIONS = [SnoozeTimingPolicyEnum.Reset, SnoozeTimingPolicyEnum.KeepExisting]

export const reviewCanonicalTypeOptions = (reviewTypes: Array<ReviewType>): Array<Option> =>
  reviewTypes
    .filter((rt) => rt.initial && rt.allowManualCreation)
    .map((rt) => ({ display: rt.name, value: rt.canonicalId }))

export function InputRelativeIncrementValue({ valueField }: { valueField: ControllerRenderProps }) {
  const controlClasses = useThinControlStyles()
  return (
    <NumberFormat
      {...valueField}
      inputContainerClassName={controlClasses.numberFormatRoot}
      customInput={HbTextInput}
      defaultValue={0}
      label=""
      onChange={() => null}
      onValueChange={({ floatValue }) => {
        valueField.onChange(floatValue)
      }}
    />
  )
}

export function SelectRelativeIncrementUnit({
  inputProps,
  currentDateValue,
}: {
  inputProps?: InputBaseComponentProps
  currentDateValue?: number | null
}) {
  return (
    <Select inputProps={inputProps} variant="outlined">
      {DATE_UNIT_SHORT_TO_LONG.map((unit) => (
        <MenuItem value={unit} key={unit}>
          {`${pluralize(capitalize(unit), currentDateValue || 0)}`}
        </MenuItem>
      ))}
    </Select>
  )
}

import AccountAvatar, { Props as AccountAvatarProps } from 'components/accounts/AccountAvatar'
import { BasicAccount } from 'types/hb'

type Props = {
  className?: string
  author: BasicAccount
} & Pick<AccountAvatarProps, 'size'>

// TODO storybook
export default function AuthorIcon({ author, className, size = 'small' }: Props) {
  return (
    <AccountAvatar classes={{ root: className }} variant={author.avatarVariant} color={author.avatarColor} size={size}>
      {author.initials}
    </AccountAvatar>
  )
}

import { Key, ReactNode, useEffect, useMemo } from 'react'

import { ApolloError } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { isEmpty, isNil, lowerCase } from 'lodash'

import pluralize from 'pluralize'

import { useDispatch, useSelector } from 'actions/store'
import { HbPagination, PageSizeChoice } from 'components/HbComponents/HbPagination/HbPagination'
import Loader from 'components/library/Loader'
import { TableColumn } from 'components/library/Table'
import { Criteria } from 'components/library/Table/Criteria'
import { StickyTable } from 'components/library/Table/StickyTable'
import { ChangedColumns, TableComponentOverrides, TableProps } from 'components/library/Table/Table'
import { TableColumnElement } from 'components/library/Table/TableColumn'
import { TableColWidthsState } from 'components/library/Table/helpers'
import { useGetIsRowSelected } from 'dashboards/reviews/hooks/useGetIsRowSelected'
import { useToggleBatchSelected } from 'dashboards/shared/components/BatchActionSelectCell'
import { getBatchActionSelectUIState } from 'dashboards/shared/components/Dashboard/DashboardTable.helper'
import { TableContainer, TableContainerProps } from 'dashboards/shared/components/TableContainer'
import { useRefetchOnBatchActionComplete } from 'dashboards/shared/hooks/useBatchActions'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { useCleanupBatchSelection } from 'hooks/UseCleanupBatchSelection'
import useRangeSelection from 'hooks/UseRangeSelection'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { NUM_DASHBOARD_ROWS_UPPER_LIMIT, storePageSize } from 'reducers/dashboards/dashboards.constants'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { DashboardColumn } from 'reducers/reviewsReducer'

import { KeyboardOrMouseEvent, Theme } from 'types/hb'
import { isSearchFilterWithId } from 'utils/query/api.types'

import { useDashboardContext } from '../DashboardContextProvider'
import { ToggleBatchSelect } from '../types'

import { DashboardHeaderCell } from './DashboardHeaderCell'

type ItemKey = (row: unknown) => string

const useStyles = makeStyles((_theme: Theme) => ({ loader: { top: -2 } }))

interface BaseProps {
  loadingColumns: boolean
  initialLoadComplete: boolean
  batchColumnEnabled?: boolean
  handleFilterSortChange?: (newCriteria: Criteria) => void
  clearOnAll?: boolean
  columns: DashboardColumn[]
  containerStyleOverrides?: TableContainerProps['overrideClasses']
  handleColumnOrderChange: (changed: ChangedColumns) => void
  /**
   * allows disabling selecting an entire page of transactions
   */
  disableBatchAction?: boolean
  onEmptyStateButtonClick?: () => void
  onHeaderOpen?: () => void
  emptyStateButtonRef?: React.MutableRefObject<HTMLButtonElement | null>
  styleOverrides?: TableComponentOverrides & { pagination?: string }
  scrollToFocused?: boolean
  showLoader?: boolean
  TableComponent?: React.ComponentType<Omit<TableProps, 'classes'>>
  enablePaginationNumberInput?: boolean
  id?: string
}

type NonResizableColumnsProps = { id?: string; columnResizingEnabled?: false }

type ResizableColumnsProps = { id: string; columnResizingEnabled: true }

export type Props = BaseProps & (NonResizableColumnsProps | ResizableColumnsProps)

// This mirrors the normal defaults, but allows us to safely add larger numbers to the normal defaults without affecting dashboards.
const DEFAULT_DASHBOARD_PAGE_SIZE_CHOICES: PageSizeChoice[] = [25, 50, 100]
export function DashboardTable<Row>(
  props: Props & {
    handleRowClick?: (row: Row, event: KeyboardOrMouseEvent) => void
    keyboardActionsEnabled?: boolean
    columnResizingEnabled?: boolean
    itemKey: ItemKey
    loading: boolean
    error: ApolloError | undefined
    entries: Row[]
    refetch: () => void
    totalCount: number
    isRowSelectable: (row: Row) => boolean
    getCellRenderer: (
      column: DashboardColumn,
      dateFormatter: (token: string) => Key[]
    ) => (entry: Row) => ReactNode | undefined | null
    fixedColumnSizes?: TableColWidthsState
    onBatchSelectAll?: (selectableEntries: Row[]) => void
    shouldCleanupBatchSelection?: boolean
    toggleBatchSelect?: ToggleBatchSelect<Row>
  }
) {
  const {
    loadingColumns,
    initialLoadComplete,
    onEmptyStateButtonClick,
    clearOnAll = true,
    columns,
    containerStyleOverrides,
    disableBatchAction = false,
    handleColumnOrderChange,
    handleFilterSortChange,
    columnResizingEnabled: columnResizingEnabledForTable = false,
    batchColumnEnabled,
    onBatchSelectAll,
    onHeaderOpen,
    handleRowClick,
    itemKey,
    loading,
    error,
    fixedColumnSizes,
    entries,
    refetch,
    totalCount,
    getCellRenderer,
    isRowSelectable,
    emptyStateButtonRef,
    showLoader = true,
    styleOverrides: styleOverridesProp,
    scrollToFocused = true,
    TableComponent: tableComponentProp,
    enablePaginationNumberInput = true,
    id,
    shouldCleanupBatchSelection = true,
    toggleBatchSelect: toggleBatchSelectProp,
    keyboardActionsEnabled = true,
  } = props

  const dashboardsActions = useDashboardActions()
  const dispatch = useDispatch()
  const classes = useStyles()
  const dashboardsSelectors = useDashboardSelectors()
  const { table, header } = useDashboardConfig()
  const dashboardSorts = useSelector(dashboardsSelectors.sorts.valid)
  const validFilters = useSelector(dashboardsSelectors.filters.applied.valid)
  const page = useSelector(dashboardsSelectors.page)

  const dashboardContextValue = useDashboardContext()
  // Store the page size when it changes
  useEffect(() => {
    storePageSize(dashboardContextValue, page.size)
  }, [dashboardContextValue, page.size])

  const lastFetchedAt = new Date().getTime()

  const density = useSelector((state) => state.userSettings.density)

  const selectableEntries = useMemo(() => entries.filter(isRowSelectable), [entries, isRowSelectable])

  useCleanupBatchSelection(shouldCleanupBatchSelection)

  const getIsRowSelected = useGetIsRowSelected()

  const isAllVisibleRowsSelected = entries.length > 0 && entries.every(getIsRowSelected)
  const isSomeItemBatchSelected = useSelector(batchActionsSelectors.isSomeItemBatchSelected)
  const mostRecentlyBatchSelectedItem = useSelector(batchActionsSelectors.mostRecentlyBatchSelectedItem)
  const batchActionJobId = useSelector(batchActionsSelectors.mostRecentBatchJobId)
  const { getRangeSelection: getBatchShiftClickSelection } = useRangeSelection(
    entries,
    mostRecentlyBatchSelectedItem,
    itemKey,
    isRowSelectable
  )

  const batchActionSelectUIState = getBatchActionSelectUIState(isAllVisibleRowsSelected, isSomeItemBatchSelected)
  const toggleBatchSelect = useToggleBatchSelected(getBatchShiftClickSelection)

  const handleClick = (e: React.MouseEvent, column: TableColumnElement, row: Row) => {
    const disabled = !isRowSelectable(row)
    switch (column.props.field) {
      case 'batch': {
        toggleBatchSelect(e, itemKey(row), disabled, table.batchSelectDisabled)
        toggleBatchSelectProp?.(e, row, disabled, table.batchSelectDisabled)
        break
      }
      default: // do nothing, row click handler on propagation navigates
    }
  }

  useRefetchOnBatchActionComplete(refetch)

  const tableLoading = !!(loading || batchActionJobId)

  const TableComponent = tableComponentProp || StickyTable

  const customEntriesText = useMemo(() => {
    if (batchActionSelectUIState === 'all-selected') {
      return `All ${entries.length} ${lowerCase(pluralize(header.title))} on this page are selected.`
    }
    return undefined
  }, [batchActionSelectUIState, entries.length, header.title])

  const { pagination: paginationClassname, ...tableStyles } = styleOverridesProp || {}

  return (
    <>
      {showLoader && <Loader className={classes.loader} variant="local" loading={tableLoading} />}
      <TableContainer
        loading={tableLoading}
        error={error}
        isEmpty={isEmpty(entries) || !initialLoadComplete}
        initialFetchDone={!isNil(lastFetchedAt)}
        onEmptyStateButtonClick={onEmptyStateButtonClick}
        overrideClasses={containerStyleOverrides}
        emptyStateButtonRef={emptyStateButtonRef}
        footer={
          <HbPagination
            maxEntries={NUM_DASHBOARD_ROWS_UPPER_LIMIT}
            className={paginationClassname}
            customEntriesText={customEntriesText}
            page={page.number}
            pageSize={page.size}
            total={totalCount ?? 0}
            onPageChange={(newPage: number) => dispatch(dashboardsActions.page.setNumber(newPage))}
            onPageSizeChange={(pageSize: number) => dispatch(dashboardsActions.page.setSize(pageSize))}
            lastUpdated={lastFetchedAt}
            onRefresh={() => refetch()}
            /*
              Dashboards are the current exception - OpenSearch is currently offset/limit based pagination.
              Note that this directly results in the 10_000 result limitation.
              See https://www.elastic.co/guide/en/elasticsearch/reference/current/paginate-search-results.html
            */
            enableNumberInput_DO_NOT_USE={enablePaginationNumberInput}
            pageSizeChoices={DEFAULT_DASHBOARD_PAGE_SIZE_CHOICES}
          />
        }
      >
        <TableComponent
          data={entries}
          density={density}
          emptyMessage={table.emptyMessage}
          onRowClick={handleRowClick}
          onColumnChange={handleColumnOrderChange}
          columnResizingEnabled={columnResizingEnabledForTable}
          fixedColumnSizes={fixedColumnSizes}
          reorderableColumns
          batchColumnEnabled={batchColumnEnabled}
          uniqueKey={table.uniqueKey}
          keyboardActions={
            handleRowClick && keyboardActionsEnabled
              ? [
                  {
                    func: handleRowClick,
                    keys: ['Enter', 'Space'],
                    keyCodes: [13, 32],
                  },
                ]
              : undefined
          }
          getIsRowSelected={getIsRowSelected}
          styleOverrides={tableStyles}
          scrollToFocused={scrollToFocused}
          id={id}
        >
          {(styleOverrides) =>
            columns.map((column) => {
              const filters = validFilters
                ? validFilters.filter.children.filter(isSearchFilterWithId).filter((f) => f.field === column.apiName)
                : null
              const direction = dashboardSorts.find((sort) => sort.sortField === column.apiName)?.sortDir

              return (
                <TableColumn
                  key={column.apiName}
                  align="left"
                  field={column.apiName}
                  isDragDisabled={column.isDragDisabled}
                  isResizeDisabled={column.isResizeDisabled}
                  isReorderable={column.isReorderable}
                  handleClick={handleClick}
                  header={(headerProps) => (
                    <DashboardHeaderCell
                      headerProps={headerProps}
                      clearOnAll={clearOnAll}
                      column={column}
                      key={column.apiName}
                      batchActionSelectUIState={batchActionSelectUIState}
                      disableBatchAction={disableBatchAction}
                      onBatchSelectAll={() => {
                        onBatchSelectAll?.(selectableEntries)
                        dispatch(
                          batchActionsActions.batchSelectItems.set({
                            tokens: selectableEntries.map(itemKey),
                            value: true,
                          })
                        )
                      }}
                      handleFilterSortChange={handleFilterSortChange}
                      loadingColumns={loadingColumns}
                      onOpen={onHeaderOpen}
                      criteria={{
                        field: column.apiName,
                        display: column.title,
                        direction,
                        values: filters?.flatMap((f) => f.predicate.values),
                      }}
                      styleOverrides={styleOverrides?.DefaultHeaderCell}
                    />
                  )}
                  value={getCellRenderer(column, getBatchShiftClickSelection)}
                />
              )
            })
          }
        </TableComponent>
      </TableContainer>
      {loading && <div style={{ visibility: 'hidden' }} data-testid="dashboard-table-data-loader" />}
    </>
  )
}

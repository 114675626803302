import { useEffect } from 'react'

import { useDispatch } from 'actions/store'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'

export const useCleanupBatchSelection = (shouldCleanupBatchSelection: boolean = true) => {
  const dispatch = useDispatch()

  useEffect(() => {
    return () => {
      if (!shouldCleanupBatchSelection) return
      dispatch(batchActionsActions.batchSelectItems.clear())
      dispatch(batchActionsActions.recentlyUpdatedItems.clear())
    }
  }, [dispatch, shouldCleanupBatchSelection])
}

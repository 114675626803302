import { DashboardContextProvider } from 'dashboards/shared/components/DashboardContextProvider'

import { MinimalDashboardHeader } from 'dashboards/shared/components/Header/MinimalDashboardHeader'
import { useUpdateDashboardColumns } from 'dashboards/shared/hooks/useUpdateDashboardColumns'
import { DashboardTypeEnum } from 'types/api'

import { AutomationRuleExecutionsTable } from './AutomationRuleExecutionsTable'
import { useReadCurrentDateFilter } from './hooks/useReadCurrentDateFilter'

function AutomationRuleExecutionsActivityLogInternal({
  canonicalAutomationRuleToken,
}: {
  canonicalAutomationRuleToken?: string
}) {
  const { loadingColumns, initialLoadComplete, handleColumnOrderChange, filteredColumns } = useUpdateDashboardColumns()
  const dateFilter = useReadCurrentDateFilter()
  return (
    <AutomationRuleExecutionsTable
      canonicalAutomationRuleToken={canonicalAutomationRuleToken}
      header={<MinimalDashboardHeader title={dateFilter || 'Automation Runs'} />}
      loadingColumns={loadingColumns}
      initialLoadComplete={initialLoadComplete}
      columns={filteredColumns}
      batchColumnEnabled={false}
      handleColumnOrderChange={handleColumnOrderChange}
    />
  )
}

export function AutomationRuleExecutionsActivityLog({
  canonicalAutomationRuleToken,
}: {
  canonicalAutomationRuleToken?: string
}) {
  return (
    <DashboardContextProvider value={DashboardTypeEnum.AutomationExecutions}>
      <AutomationRuleExecutionsActivityLogInternal canonicalAutomationRuleToken={canonicalAutomationRuleToken} />
    </DashboardContextProvider>
  )
}

import { useCallback } from 'react'

import { gql } from '@apollo/client'

import { useSnackbar } from 'notistack'

import { useHbMutation } from 'hooks/ApolloHelpers'

import { AutomationFailureNotificationSetting } from 'types/api'

import {
  UseUpdateAutomationRuleSettingsFragment as AutomationRule,
  UpdateAutomationRuleSettingsMutation,
  UpdateAutomationRuleSettingsMutationVariables,
} from './__generated__/useUpdateAutomationRuleSettings.generated'

export const fragment = gql`
  fragment UseUpdateAutomationRuleSettings on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      token
      failureNotificationSetting
    }
  }
`

const UPDATE_AUTOMATION_RULE_SETTING = gql`
  mutation UpdateAutomationRuleSettings($input: UpdateAutomationRuleSettingsInput!) {
    updateAutomationRuleSettings(input: $input) {
      automationRule {
        ...UseUpdateAutomationRuleSettings
      }
    }
  }
  ${fragment}
`

export function useUpdateAutomationRuleSettings(automationRule: AutomationRule) {
  const { enqueueSnackbar } = useSnackbar()

  const [updateAutomationRuleSettings, { loading: updateAutomationRuleSettingsLoading }] = useHbMutation<
    UpdateAutomationRuleSettingsMutation,
    UpdateAutomationRuleSettingsMutationVariables
  >(UPDATE_AUTOMATION_RULE_SETTING, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation settings successfully updated', {
        variant: 'success',
        preventDuplicate: false,
        autoHideDuration: 1000,
      })
    },
    logEvent: {
      name: 'automations:updateRuleSettings:clicked',
      data: {
        failureNotificationSetting: automationRule.failureNotificationSetting,
      },
    },
  })

  const handleUpdateSettings = useCallback(
    (failureNotificationSetting: AutomationFailureNotificationSetting) => {
      updateAutomationRuleSettings({
        variables: {
          input: {
            token: automationRule.token,
            failureNotificationSetting,
          },
        },
      })
    },
    [updateAutomationRuleSettings, automationRule]
  )

  return {
    updateRuleSettings: handleUpdateSettings,
    loading: updateAutomationRuleSettingsLoading,
  }
}

import { ErrorAction } from 'actions/errorActions'

import { createReducer } from 'helpers/reducers'

export interface ErrorState {
  formErrors: { [token: string]: string | undefined }
  formErrorDismissals: { [token: string]: boolean }
}

const getInitialState = (): ErrorState => ({
  formErrors: {},
  formErrorDismissals: {},
})

const errorsReducer = createReducer<ErrorState, ErrorAction>(
  {
    SET_FORM_ERROR_MESSAGE(state: ErrorState, action: ErrorAction) {
      const newErrorMessages = { ...state.formErrors }
      newErrorMessages[action.token || 'global'] = action.message
      const newErrorDismissals = { ...state.formErrorDismissals }
      newErrorDismissals[action.token || 'global'] = false

      return {
        ...state,
        formErrors: newErrorMessages,
        formErrorDismissals: newErrorDismissals,
      }
    },
    DISMISS_FORM_ERROR_MESSAGE(state: ErrorState, action: ErrorAction) {
      const newErrorDismissals = { ...state.formErrorDismissals }
      newErrorDismissals[action.token || 'global'] = true

      return { ...state, formErrorDismissals: newErrorDismissals }
    },
    CLEAR_FORM_ERROR_DISMISSAL(state: ErrorState, action: ErrorAction) {
      const newErrorDismissals = { ...state.formErrorDismissals }
      delete newErrorDismissals[action.token || 'global']

      return { ...state, formErrorDismissals: newErrorDismissals }
    },
    CLEAR_FORM_ERROR(state: ErrorState, action: ErrorAction) {
      const newErrorMessages = { ...state.formErrors }
      delete newErrorMessages[action.token || 'global']
      const newErrorDismissals = { ...state.formErrorDismissals }
      delete newErrorDismissals[action.token || 'global']

      return {
        ...state,
        formErrors: newErrorMessages,
        formErrorDismissals: newErrorDismissals,
      }
    },
    CLEAR_ALL_FORM_ERRORS(state: ErrorState) {
      return { ...state, formErrors: {}, formErrorDismissals: {} }
    },
  },
  getInitialState
)

export default errorsReducer

import { useRef } from 'react'

import { AddOutlined } from '@mui/icons-material'
import { TextField } from '@mui/material'

import { Controller } from 'react-hook-form'
import NumberFormat from 'react-number-format'
import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'

import { TrashOutlineIcon } from 'icons'

import Step from '../Step'
import { FormSchemaReturnType } from '../formSchema'

interface Props {
  form: FormSchemaReturnType
}

export function ViewSamplingAutomationStep({ form }: Props) {
  const menuTrigger = useRef<HTMLButtonElement | null>(null)

  const { setValue, watch, formState, control } = form

  const domainType = watch('domain.type')
  const sampleRate = watch('sampleRate')

  invariant(domainType, 'Missing domain type!')

  const handleRemoveSampling = () => {
    setValue('sampleRate', null, { shouldValidate: true, shouldDirty: true })
  }
  if (sampleRate == null) {
    return (
      <HbButton
        Icon={AddOutlined}
        label="Add Sampling Rate"
        variant="secondary"
        onClick={() => setValue('sampleRate', 100, { shouldValidate: true, shouldDirty: true })}
        ref={menuTrigger}
        size="small"
      />
    )
  }

  return (
    <Step
      key="sampleStep"
      type="sample"
      compact
      size="sm"
      ActionButton={
        <HbButton
          variant="secondary"
          Icon={TrashOutlineIcon}
          label="Remove Sampling"
          size="small"
          onClick={handleRemoveSampling}
          tooltipText="Remove Sampling Rate"
          iconOnly
          tooltip
        />
      }
    >
      <Controller
        name="sampleRate"
        control={control}
        render={({ field }) => {
          return (
            <NumberFormat
              customInput={TextField}
              label="Sampling Rate"
              variant="outlined"
              error={!!formState.errors.sampleRate}
              helperText={!!formState.errors.sampleRate && 'Enter a percentage between 0 and 100'}
              suffix="%"
              onValueChange={({ floatValue }) => {
                field.onChange(floatValue || '')
              }}
              value={field.value}
            />
          )
        }}
      />
    </Step>
  )
}

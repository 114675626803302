import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const ViewColumn: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 25 24">
    <path d="M10 18h5V5h-5v13zm-6 0h5V5H4v13zM16 5v13h5V5h-5z" />
    <path d="M0 0h24v24H0z" fill="none" />
  </SvgIcon>
)

export default ViewColumn

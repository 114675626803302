import { camelCase, capitalize } from 'lodash'

export type Shade = 'dark' | 'medium' | 'light'

export const HbColorsByHue = {
  hbBlue: {
    dark: '#000BA4',
    medium: '#4361E5',
    light: '#E7E9FC',
  },
  success: {
    dark: '#0E5B17',
    medium: '#4BA94E',
    light: '#E7F5E8',
  },
  error: {
    dark: '#D11200',
    medium: '#F5451B',
    light: '#FEE8E7',
  },
  mint: {
    dark: '#083215',
    medium: '#4BB37E',
    light: '#D3EFD9',
  },
  linen: {
    dark: '#371F15',
    medium: '#A25000',
    light: '#F1E1DA',
  },
  rose: {
    dark: '#3F1227',
    medium: '#C12195',
    light: '#FFDDF8',
  },
  cyan: {
    dark: '#0B2E33',
    medium: '#00B2D9',
    light: '#D7F5FE',
  },
  sea: {
    dark: '#102939',
    medium: '#65AFE6',
    light: '#D6E6FF',
  },
  salmon: {
    dark: '#4B1700',
    medium: '#F97A43',
    light: '#FFE0CA',
  },
  coral: {
    dark: '#3F0909',
    medium: '#E6644D',
    light: '#FFD8D8',
  },
  plum: {
    dark: '#2E173D',
    medium: '#7A5BB0',
    light: '#E5D6FF',
  },
  lemon: {
    dark: '#362C07',
    medium: '#EABE34',
    light: '#F8EBC0',
  },
  ivory: {
    dark: '#26241F',
    medium: '#8D887B',
    light: '#E7E3DD',
  },
  thirdPartyMiddeskGreen: {
    dark: '#243636',
    medium: '#2F4646',
    light: '#BFFF6E',
  },
}

export const hues = Object.keys(HbColorsByHue)

export type Hue = (typeof hues)[number]
export function isHbHue(color: string): color is Hue {
  return !!HbColorsByHue[color as Hue]
}

export const HBGREYS = {
  black: '#000000',
  gray900: '#1A1A1A',
  gray800: '#313131',
  textGreyDark: '#484848',
  gray600: '#5F5F5F',
  textGreyLight: '#8C8C8C',
  borderDark: '#BABABA',
  gray300: '#D1D1D1',
  borderLight: '#DDDDDD',
  backgroundDark: '#E8E8E8',
  backgroundMedium: '#F4F4F4',
  backgroundLight: '#FAFAFA',
  white: '#FFFFFF',
}

export const getColorByName = (hue: Hue, shade: Shade = 'medium') => HbColorsByHue[hue][shade]

type ColorKey<T extends string, S extends Shade> = `${T}${Capitalize<S>}`
const makeColorKey = <T extends string, S extends Shade>(hue: T, shade: S) =>
  `${hue}${capitalize(shade)}` as ColorKey<T, S>

/**
 * Map of all colors and their hexcodes in format {`hueShade`: hexcode}
 * ex. { plumDark: '#2E173D', plumMedium: '#7A5BB0'}
 */
const getHBCOLORS = () => {
  const allShades = Object.keys(HbColorsByHue)
    .map((hue) => {
      const shades = HbColorsByHue[hue]
      return Object.keys(shades).map((shade: Shade) => [makeColorKey(hue, shade), shades[shade]] as const)
    })
    .flat()
  const colors = {} as Record<(typeof allShades)[number][0], string>
  allShades.forEach(([colorKey, shade]) => {
    colors[colorKey] = shade
  })
  return colors
}
export const HBCOLORS = getHBCOLORS()

/**
 * Legacy colors from old design guidelines. Should not be used for new UI.
 * @deprecated
 */
const LEGACY_COLORS = {
  // Grayscale
  dark: '#212428',
  nearBlack: '#263238',
  darkGray: '#6D7878',
  mediumGray2: '#979DA0',
  mediumGray1: '#B9BDBF',
  lightGray3: '#FAFAFA',
  lightGray2: '#CDCDCD',
  lightGray1: '#E7E7E7',
  nearWhite: '#F6F7F7',

  // Only redesign, probably unused
  greyA: '#4A4B4D',
  greyB: '#6E6F71',
  greyC: '#929394',
  greyD: '#B7B7B8',
  greyE: '#DBDBDB',
  greyF: '#EDEDED',

  primary50: '#E7E9FC',

  // Only redesign, probably unused
  lightGray4: '#D7DBE0',
  blue2: '#BCECFC',
  blue3: '#3787FB',
  lightRed: '#FCEDEB',
  lightPurple: '#F0F2FD',

  // Primary Colors
  blue: '#3787fb',
  green: '#3b9d3f',
  yellow: '#f1c714',
  orange: '#f57c00',
  red: '#db2004',
  error: '#F73210',

  // Supplemental Colors
  pink: '#ffa1f4',
  tan: '#daa16b',
  lightBlue: '#99dcff',
  teal: '#50e3c2',
  aqua: '#09a9be',
  magenta: '#a90f89',
  brown: '#8b572a',
  darkPurple: '#3f1d72',
  darkBlue: '#044e95',
  darkGreen: '#0a655e',
  darkYellow: '#cbab25',

  ...HBCOLORS,
  ...HBGREYS,
}

/**
 * Legacy colors from old design guidelines. Should not be used for new UI.
 * @deprecated
 */
export const legacyColors = {
  palette: {
    styleguide: LEGACY_COLORS,
    insights: {
      credit: LEGACY_COLORS.green,
      debit: '#78909c',
      transfer: '#5C6BC0',
    },
  },
}

/**
 * Legacy colors from old design guidelines. Should not be used for new UI.
 * @deprecated
 */
export const getLegacyColorByName = (name: string) => (LEGACY_COLORS as Record<string, string>)[camelCase(name)]

/**
 * Legacy colors from old design guidelines. Should not be used for new UI.
 * @deprecated
 */
export const randomLegacyColor = () => {
  const keys = Object.keys(LEGACY_COLORS)
  return keys[Math.floor(keys.length * Math.random())]
}

export const getHbGradient = (variant: 1 | 2 | 3 | 4 | 5 | 6 | 7) => {
  const gradients = {
    1: '122.63deg, #C5EFFC -6.51%, #DFB9FD 123.73%',
    2: '102.25deg, #A2F3FE -59.25%, #FAFFB7 126.71%',
    3: '138.17deg, #BDD9FA 15.31%, #FDDEEB 113.78%',
    4: '349.05deg, #6DC5FC -63.07%, #FFFFFF 94.9%',
    5: '276.15deg, #C5EFFC 28.04%, #DFB9FD 139.66%',
    6: '31.14deg, #B9F1F9 35.41%, #FAEAE8 82%',
    7: '123deg, rgba(197, 239, 252, 0.30) -6.51%, rgba(223, 185, 253, 0.30) 123.73%',
  }
  return `linear-gradient(${gradients[variant]})`
}

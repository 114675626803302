import { Divider } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useLocation } from 'react-router-dom'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AuthBackground } from 'components/library/Onboarding/Background'
import { STEP_BUTTON_HEIGHT } from 'components/library/Onboarding/Step'
import Steps from 'components/library/Onboarding/Steps'
import { SignIn } from 'components/onboarding/account/steps'
import { GoogleIcon } from 'icons/GoogleIcon'
import { Theme } from 'types/hb'

import { AdditionalActions } from './AdditionalActions'

export const useLinkStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to root element */
  ssoForm: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 500,
  },
  or: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
  },
  orText: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
  },
  divider: {
    flex: 1,
  },
  link: {
    color: theme.palette.text.primary,
  },
  button: {
    height: STEP_BUTTON_HEIGHT,
  },
}))

function SignInActions() {
  const classes = useLinkStyles()
  const newCredentialPasswordPath = useSelector((state) => state.application.urls.newCredentialPasswordPath)
  const csrfToken = useSelector((state) => state.application.csrfToken)
  const credentialGoogleOauth2OmniauthAuthorizePath = useSelector(
    (state) => state.application.urls.credentialGoogleOauth2OmniauthAuthorizePath
  )

  return (
    <>
      <div className={classes.or}>
        <Divider className={classes.divider} />
        <HbText className={classes.orText} size="s" bold>
          OR
        </HbText>
        <Divider className={classes.divider} />
      </div>

      <form className={classes.ssoForm} method="post" action={credentialGoogleOauth2OmniauthAuthorizePath}>
        <input type="hidden" name="authenticity_token" value={csrfToken} />

        <HbButton
          className={classes.button}
          type="submit"
          label="Sign in with Google"
          Icon={GoogleIcon}
          iconPlacement="right"
          variant="secondary"
        />
      </form>

      <AdditionalActions>
        <HbText size="lg">
          Forgot your password?{' '}
          <HbText bold color="primary" size="lg">
            <a className={classes.link} href={newCredentialPasswordPath}>
              Reset it here
            </a>
          </HbText>
        </HbText>
      </AdditionalActions>
    </>
  )
}

export default function SignInScreen() {
  const { state } = useLocation<{ from: Location }>()

  return (
    <AuthBackground>
      <Steps
        onComplete={(location: string) => {
          window.location.href = state?.from?.pathname || location
        }}
        path="/auth"
        steps={[
          {
            name: 'sign-in',
            props: {
              additionalActions: <SignInActions />,
            },
            Component: SignIn,
          },
        ]}
      />
    </AuthBackground>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const LibraryAdd: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M4,6H2v14c0,1.1,0.9,2,2,2h14v-2H4V6z" />
    <path d="M20,2H8C6.9,2,6,2.9,6,4v12c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2z M20,16H8V4h12V16z" />
    <polygon points="13,14 15,14 15,11 18,11 18,9 15,9 15,6 13,6 13,9 10,9 10,11 13,11" />
  </SvgIcon>
)

export default LibraryAdd

import React, { Dispatch, SetStateAction } from 'react'

import { CSSObject } from '@emotion/react'

import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { GET_CASE_FILES } from 'components/cases/Tabs/Files/CaseFilesTab.queries'
import { GetCaseFilesQueryVariables } from 'components/cases/Tabs/Files/__generated__/CaseFilesTab.queries.generated'
import { Feedback } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/AttachmentSummaryFooter.types'
import { ProvideSummaryFeedbackDocument } from 'components/cases/Tabs/shared/AttachmentSummary/__generated__/ProvideSummaryFeedbackMutation.generated'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { AttachmentSummary } from 'types/api'

// NB: This breaks all sorts of tests if it's not lazily imported....woof. Same with HbRHFTextArea. Not sure why...
const HbTextArea = React.lazy(() => import('components/HbComponents/Form/HbInputs/HbTextArea/HbTextArea'))

export interface AttachmentSummaryFeedbackFormProps {
  caseToken: string
  attachmentToken: string
  summary: AttachmentSummary
  feedback: Feedback
  setFeedback: Dispatch<SetStateAction<Feedback>>
  overrideCss?: Partial<{ container: CSSObject }>
}

const FeedbackFormContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  width: '100%',
  alignItems: 'center',
  gap: theme.spacing(1),
}))

const FeedbackText = styled(HbTextArea)(() => ({
  flex: 1,
}))

const FeedbackThanks = styled(HbText)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}))

const FeedbackButton = styled(HbButton)(({ theme }) => ({
  flex: 1,
  marginBottom: theme.spacing(1),
}))

export const AttachmentSummaryFeedbackForm = ({
  caseToken,
  attachmentToken,
  summary,
  feedback,
  setFeedback,
}: AttachmentSummaryFeedbackFormProps) => {
  const usage = useUsage()

  const variables: GetCaseFilesQueryVariables = {
    caseToken,
    enableAttachmentSummarization: true,
    cursor: null,
  }
  const [submitFeedback] = useHbMutation(ProvideSummaryFeedbackDocument, {
    flashError: true,
    refetchQueries: [{ query: GET_CASE_FILES, variables }],
    onCompleted: () => {
      setFeedback((old) => ({ ...old, submitted: true }))
    },
  })

  if (feedback.submitted) {
    return (
      <FeedbackFormContainer>
        <FeedbackThanks bold>Thank you for your feedback!</FeedbackThanks>
      </FeedbackFormContainer>
    )
  }

  return (
    <FeedbackFormContainer>
      <FeedbackText
        name="summary_feedback"
        label="Feedback Details"
        inputProps={{ placeholder: 'Add more details here (optional)' }}
        hideLabel
        minRows={3}
        onChange={({ target }) => {
          setFeedback({ ...feedback, content: target.value })
        }}
      />
      <FeedbackButton
        label="Submit feedback"
        onClick={async () => {
          if (!feedback.rating || !summary.token) {
            // TODO: validation?
            // This should actually be RHF now that I think about it
            return
          }

          usage.logEvent({
            name: 'fileSummarization:submitFeedback:clicked',
            data: { attachmentToken, sentiment: feedback.rating },
          })

          await submitFeedback({
            variables: {
              input: {
                attachmentToken,
                summaryToken: summary.token,
                rating: feedback?.rating,
                feedback: feedback?.content ?? '',
              },
            },
          })
        }}
      />
    </FeedbackFormContainer>
  )
}

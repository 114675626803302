export const SET_INITIAL_STATE = 'INITIALIZE_APPLICATION'
export const SET_CURRENT_ACCOUNT = 'SET_CURRENT_ACCOUNT'
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS'
export const UPDATE_FILING_INSTITUTIONS = 'UPDATE_FILING_INSTITUTIONS'
export const DISMISS_LOGIN_BANNER = 'DISMISS_LOGIN_BANNER'
export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR'
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR'
export const REMOVE_SNACKBAR = 'REMOVE_SNACKBAR'
export const SET_INTERCOM_HAS_BOOTED = 'SET_INTERCOM_HAS_BOOTED'
export const UPSERT_ORGANIZATION_CURRENT_REVIEW_TYPE = 'UPSERT_ORGANIZATION_CURRENT_REVIEW_TYPE'

import { AddCircleOutlineOutlined } from '@mui/icons-material'

import { HbButton } from 'components/HbComponents/HbButton'
import { Placement } from 'components/HbComponents/HbTooltip'

class AddCustomFieldLabelButtonProps {
  openNewDialog: () => void
  iconOnly: boolean
}

export const AddCustomField = ({ openNewDialog, iconOnly }: AddCustomFieldLabelButtonProps) => {
  return (
    <HbButton
      Icon={AddCircleOutlineOutlined}
      label="Add Custom Field"
      onClick={openNewDialog}
      size="small"
      variant="primary"
      iconOnly={iconOnly}
      tooltip={iconOnly}
      tooltipPlacement={Placement.Top}
    />
  )
}

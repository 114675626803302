import { useEffect } from 'react'

import { FormHelperText, styled } from '@mui/material'

import { capitalize } from 'lodash'
import pluralize from 'pluralize'

import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import { naturalNumbers } from 'components/HbComponents/Form/HbInputs/HbNumberInput/HbNumberInput'
import HbRHFNumberInput from 'components/HbComponents/Form/HbInputs/HbNumberInput/HbRHFNumberInput'

import { useFeatureFlag } from 'hooks'

import { FeatureFlag } from 'types/api'

import { FormSchemaReturnType } from '../formSchema'

import { DATE_UNIT_SHORT_TO_LONG, SNOOZE_TIMING_POLICY_OPTIONS } from './shared'

const Container = styled('div')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '2fr  3fr',
  gap: theme.spacing(),
  alignItems: 'flex-start',
  paddingTop: theme.spacing(1),
}))

const SNOOZE_REVIEW_PARAMS_PATH = 'actionParams.snoozeReviewParams'
export default function CustomizeSnoozeReviewAction({ form }: { form: FormSchemaReturnType }) {
  const { watch, setValue, control } = form
  const { snoozeUntilDateRelativeIncrementValue: dateVal, snoozeUntilDateRelativeIncrementUnit: timeVal } =
    watch(SNOOZE_REVIEW_PARAMS_PATH) || {}

  const showSnoozeTimingPolicy = useFeatureFlag(FeatureFlag.SnoozeReviewTimingPolicy)

  // @robocop: this is temporary until https://thecharm.atlassian.net/browse/PROD-12145 to save
  // snooze actions since it is the first action to require a description
  useEffect(() => {
    if (dateVal && timeVal) {
      const newActionText = `Snooze review for ${dateVal} ${pluralize(capitalize(timeVal), dateVal || 0)}`
      setValue(`${SNOOZE_REVIEW_PARAMS_PATH}.description`, newActionText)
      setValue('actionText', newActionText)
    }
  }, [dateVal, timeVal, setValue])

  return (
    <div>
      <Container>
        <HbRHFNumberInput
          label="Snooze for"
          name={`${SNOOZE_REVIEW_PARAMS_PATH}.snoozeUntilDateRelativeIncrementValue`}
          control={control}
          numberProps={{
            ...naturalNumbers,
          }}
        />
        <HbRHFDropdown
          name={`${SNOOZE_REVIEW_PARAMS_PATH}.snoozeUntilDateRelativeIncrementUnit`}
          control={control}
          options={DATE_UNIT_SHORT_TO_LONG.map((unit) => ({
            value: unit,
            display: `${pluralize(capitalize(unit), dateVal || 0)}`,
          }))}
          label="Unit"
          hideLabel
          InputProps={{ sx: { marginTop: 2.9 } }}
        />
      </Container>
      <FormHelperText>
        When snoozed, a review will remain in the pending state until the end of the time period
      </FormHelperText>

      {showSnoozeTimingPolicy && (
        <>
          <Container>
            <HbRHFDropdown
              name={`${SNOOZE_REVIEW_PARAMS_PATH}.snoozeTimingPolicy`}
              control={control}
              options={SNOOZE_TIMING_POLICY_OPTIONS.map((policy) => ({
                value: policy,
                display: capitalize(policy.replace(/_/g, ' ')),
              }))}
              label="Snooze Timing Policy"
            />
          </Container>
          <FormHelperText>
            When snoozed, the Reset policy will remove the timeline started at and due at values from the review. When a
            Keep existing policy is selected, these values are retained.
          </FormHelperText>
        </>
      )}
    </div>
  )
}

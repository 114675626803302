import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchInformationRequestsResultFragment } from './searchInformationRequests.fragments'

export const SEARCH_INFORMATION_REQUESTS_QUERY = gql`
  query SearchInformationRequests($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchInformationRequests(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchInformationRequestsResultFragment
    }
  }
  ${SearchInformationRequestsResultFragment}
`

export const INFORMATION_REQUESTS_COLUMNS_QUERY_V2 = gql`
  query InformationRequestsColumnsV2($includeCustomColumns: Boolean!) {
    searchInformationRequestsMetadataV2(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const INFORMATION_REQUESTS_METADATA_QUERY_V2 = gql`
  query SearchInformationRequestsMetadataV2($columns: [String!], $query: String) {
    searchInformationRequestsMetadataV2(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

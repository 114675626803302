import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

// Used by Request For Information app
export const ForwardToInboxIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 34 34">
    <path
      d="M6.13799 28.0002C5.33609 28.0002 4.65409 27.7192 4.09199 27.1571C3.53099 26.5961 3.25049 25.9146 3.25049 25.1127V8.88665C3.25049 8.08475 3.53099 7.4033 4.09199 6.8423C4.65409 6.2802 5.33609 5.99915 6.13799 5.99915H27.8619C28.6638 5.99915 29.3458 6.2802 29.9079 6.8423C30.4689 7.4033 30.7494 8.08475 30.7494 8.88665V19.44C30.1081 19.0506 29.4321 18.7641 28.7215 18.5804C28.0109 18.3978 27.2893 18.3065 26.5567 18.3065C24.2643 18.3065 22.3162 19.1084 20.7124 20.7122C19.1086 22.316 18.3067 24.2641 18.3067 26.5565V28.0002H6.13799ZM17.0346 15.6929L7.99424 10.0565C7.53554 9.80349 7.11149 9.79745 6.72209 10.0383C6.33269 10.2792 6.13799 10.629 6.13799 11.0877C6.13799 11.3396 6.18914 11.5459 6.29144 11.7065C6.39484 11.8671 6.53839 12.0046 6.72209 12.119L16.2442 18.0656C16.4961 18.2031 16.7535 18.2718 17.0164 18.2718C17.2804 18.2718 17.5384 18.2031 17.7903 18.0656L27.3124 12.119C27.4961 12.0046 27.6391 11.861 27.7414 11.6883C27.8448 11.5167 27.8965 11.3165 27.8965 11.0877C27.8965 10.6059 27.6903 10.2506 27.2778 10.0218C26.8653 9.79194 26.4528 9.80349 26.0403 10.0565L17.0346 15.6929ZM26.6244 28.0679H22.638C22.2475 28.0679 21.9092 27.9249 21.6232 27.6389C21.3372 27.3529 21.1942 27.0146 21.1942 26.6241C21.1942 26.2347 21.3372 25.897 21.6232 25.611C21.9092 25.3239 22.2475 25.1804 22.638 25.1804H26.6244L25.5255 24.0815C25.2505 23.8065 25.1069 23.4627 25.0948 23.0502C25.0838 22.6377 25.2274 22.294 25.5255 22.019C25.8005 21.744 26.1382 21.612 26.5386 21.623C26.9401 21.634 27.2783 21.777 27.5533 22.052L31.1272 25.5929C31.4022 25.8679 31.5397 26.2116 31.5397 26.6241C31.5397 27.0366 31.4022 27.3804 31.1272 27.6554L27.5533 31.1963C27.2783 31.4713 26.9461 31.6148 26.5567 31.6269C26.1673 31.6379 25.8236 31.4944 25.5255 31.1963C25.2505 30.9213 25.113 30.5891 25.113 30.1997C25.113 29.8103 25.2505 29.4781 25.5255 29.2031L26.6244 28.0679Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default ForwardToInboxIcon

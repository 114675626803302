export const PDF_ATTACHMENT_TYPE = 'application/pdf'
export const IMAGE_ATTACHMENT_TYPES = ['image/png', 'image/jpeg', 'image/gif']
export const CSV_ATTACHMENT_TYPE = 'text/csv'
export const SPREADSHEET_ATTACHMENT_TYPES = [
  CSV_ATTACHMENT_TYPE,
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]
export const WORD_ATTACHMENT_TYPES = ['application/vnd.openxmlformats-officedocument.wordprocessingml.document']
export const SUPPORTED_ATTACHMENTS = [
  ...IMAGE_ATTACHMENT_TYPES,
  PDF_ATTACHMENT_TYPE,
  ...SPREADSHEET_ATTACHMENT_TYPES,
  ...WORD_ATTACHMENT_TYPES,
]

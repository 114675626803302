import { useState } from 'react'

import { Typography } from '@mui/material'

import { InputContainer, SelectBase } from 'components/material/Form'

import { makeRequiredStyles } from 'components/utils/styles'
import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { useHasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { BadgePermissionsEnum, BatchActionTypeEnum, FeatureFlag, UnassignStrategyEnum } from 'types/api'

const useStyles = makeRequiredStyles((theme) => ({
  content: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
}))

const useUnassignStrategies = () => {
  const canUnassignAssignee = useHasPermission(BadgePermissionsEnum.DeleteReviewAssignee)
  const canUnassignQueue = useHasPermission(BadgePermissionsEnum.DeleteReviewQueue)
  const strategies = []

  if (canUnassignAssignee) {
    strategies.push({ display: 'Assignee', value: UnassignStrategyEnum.Assignee })
  }
  if (canUnassignQueue) {
    strategies.push({ display: 'Queue', value: UnassignStrategyEnum.Queue })
  }
  if (canUnassignAssignee && canUnassignQueue) {
    strategies.push({ display: 'Both assignee and queue', value: UnassignStrategyEnum.Both })
  }
  return strategies
}

export const BatchUnassignDialog: DialogComponent<{ message?: string }> = (props) => {
  const { children, message = 'Are you sure you want to unassign these reviews?', ...dialogProps } = props
  const [unassignStrategy, setUnassignStrategy] = useState<UnassignStrategyEnum>(UnassignStrategyEnum.Assignee)
  const styles = useStyles()
  const isReviewsToQueuesEnabled = useFeatureFlag(FeatureFlag.ReviewsToQueues)
  const strategies = useUnassignStrategies()

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Unassign}
      batchActionParams={isReviewsToQueuesEnabled ? { unassignStrategy } : undefined}
      confirmEnabled
      title="Un-assign reviews"
      primaryText={
        isReviewsToQueuesEnabled
          ? 'When you unassign reviews, any previous assignments will be removed. Changes to assignments will appear in the case history.'
          : 'When you unassign reviews, any previous assignees will be removed. Changes to assignee will appear in the case history.'
      }
      confirmText="Yes, un-assign reviews."
    >
      <div className={styles.content}>
        <Typography variant="body2" paragraph display="inline" color="inherit">
          {message}
        </Typography>
      </div>
      {isReviewsToQueuesEnabled && (
        <InputContainer htmlFor="unassignStrategy" label="Unassign from:">
          <SelectBase
            margin="dense"
            name="unassignStrategy"
            options={strategies}
            onChange={(e) => {
              setUnassignStrategy(e.target.value as UnassignStrategyEnum)
            }}
            variant="outlined"
            value={unassignStrategy}
          />
        </InputContainer>
      )}
      {children}
    </BaseBatchActionDialog>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CaseAssignmentIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M9.17526447,14.2597884 C6.34210213,14.2597884 4.04537026,11.9630566 4.04537026,9.12989421 C4.04537026,6.29673187 6.34210213,4 9.17526447,4 C12.0084268,4 14.3051587,6.29673187 14.3051587,9.12989421 C14.3051587,11.9630566 12.0084268,14.2597884 9.17526447,14.2597884 Z M9.17526447,5.70996474 C7.28648958,5.70996474 5.755335,7.24111932 5.755335,9.12989421 C5.755335,11.0186691 7.28648958,12.5498237 9.17526447,12.5498237 C11.0640394,12.5498237 12.5951939,11.0186691 12.5951939,9.12989421 C12.5951939,7.24111932 11.0640394,5.70996474 9.17526447,5.70996474 Z M9.17526447,15.9697532 C11.5033759,15.9753503 13.7037805,17.034688 15.1601411,18.8510437 C15.3646466,19.1027044 15.4091786,19.4484003 15.2751283,19.7436732 C15.141078,20.0389462 14.8515202,20.2329693 14.5274541,20.244665 C14.2687463,20.2455447 14.0235532,20.1292351 13.8605679,19.9283215 C12.7246577,18.5124715 11.0075564,17.6886581 9.19236412,17.6886581 C7.37717184,17.6886581 5.66007053,18.5124715 4.52416039,19.9283215 C4.22667834,20.2966326 3.68694635,20.3540509 3.31863525,20.0565689 C2.95032414,19.7590868 2.89290585,19.2193548 3.19038789,18.8510437 C4.64674842,17.034688 6.84715303,15.9753503 9.17526447,15.9697532 Z M22,14.2597884 L22,15.9697532 L19.4350529,15.9697532 L19.4350529,18.5347003 L17.7250882,18.5347003 L17.7250882,15.9697532 L15.1601411,15.9697532 L15.1601411,14.2597884 L17.7250882,14.2597884 L17.7250882,11.6948413 L19.4350529,11.6948413 L19.4350529,14.2597884 L22,14.2597884 Z" />
  </SvgIcon>
)

export default CaseAssignmentIcon

import { ApolloClient, defaultDataIdFromObject, from, InMemoryCache, TypePolicies } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { HttpLink } from '@apollo/client/link/http/HttpLink'
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries'
import { relayStylePagination } from '@apollo/client/utilities'

import { generatePersistedQueryIdsFromManifest } from '@apollo/persisted-query-lists'

import { HbApolloCache } from 'actions/store'
import { InitialData } from 'types/hb'
import apolloPossibleTypes from 'utils/apolloPossibleTypes.generated'
import { scalarTypePolicies } from 'utils/apolloScalarTypePolicies.generated'

// See https://www.apollographql.com/docs/react/caching/advanced-topics/#advanced-connection-directive-usage
const relayStyleConnectionPagination = (keyArgs = ['key']) => relayStylePagination(['@connection', keyArgs])

const personAndBusinessSharedFields = () => ({
  organizationAlerts: relayStyleConnectionPagination(),
  businessOwnerRelationships: relayStyleConnectionPagination(),
  relationships: relayStyleConnectionPagination(),
  paginatedBankAccounts: relayStyleConnectionPagination(),
  paginatedPaymentCards: relayStyleConnectionPagination(),
  paginatedCryptoAddresses: relayStyleConnectionPagination(),
  paginatedDevices: relayStyleConnectionPagination(),
})

const persistedQueriesLink = createPersistedQueryLink(
  generatePersistedQueryIdsFromManifest({
    loadManifest: () => import('../persisted-query-manifest.json'),
  })
)

/**
 * Custom type polices. Mostly for relay pagination.
 */
function typePolicies(): TypePolicies {
  const policies: TypePolicies = {
    Query: {
      fields: {
        canonicalReviewTypes: relayStylePagination(),
        caseImports: relayStylePagination(),
        deployments: relayStylePagination(),
        organizationAlerts: relayStylePagination(),
        otherInfoLabels: relayStylePagination(),
        supportAccessRequests: relayStylePagination(),
        interpreterCsvTemplates: relayStylePagination(),
      },
    },
    LibraryObject: {
      fields: {
        relatedCases: relayStyleConnectionPagination(),
        informationRequests: relayStyleConnectionPagination(),
        revisions: relayStyleConnectionPagination(),
        attachments: relayStyleConnectionPagination(),
        screeningSearchResults: relayStyleConnectionPagination(),
      },
    },
    LibraryPerson: {
      fields: {
        ...personAndBusinessSharedFields(),
      },
    },
    LibraryBusiness: {
      fields: {
        ...personAndBusinessSharedFields(),
        businessRelationships: relayStyleConnectionPagination(),
      },
    },
    LibraryProduct: {
      fields: {
        relationships: relayStyleConnectionPagination(),
      },
    },
    LibraryFinancialInstitution: {
      fields: {
        relationships: relayStyleConnectionPagination(),
      },
    },
    Account: {
      fields: {
        badges: {
          merge: (_, incoming) => incoming,
        },
      },
    },
    CaseAlertGroup: {
      fields: {
        alerts: relayStylePagination(),
      },
    },
    Review: {
      fields: {
        alertGroups: relayStylePagination(),
      },
    },
    Investigation: {
      keyFields: ['token'],
      fields: {
        financialInstitutions: relayStylePagination(),
        people: relayStylePagination(),
        businesses: relayStylePagination(),
        products: relayStylePagination(),
        alertGroups: relayStylePagination(),
        transactions: relayStylePagination(),
        attachments: relayStylePagination(),
        timelineEvents: relayStylePagination(),
        informationRequests: relayStylePagination(['respondedOnly']),
        secondarySubjects: relayStylePagination(),
      },
    },
    Organization: {
      fields: {
        accounts: relayStylePagination(),
        badges: relayStylePagination(['asQueues']),
        relationshipDefinitions: relayStylePagination(),
      },
    },
    CommentThread: {
      keyFields: ['token'],
    },
    Comment: {
      keyFields: ['token'],
    },
    AutomationRule: {
      fields: {
        ruleExecutions: relayStylePagination(),
      },
    },
    TriggerAutomationRule: {
      fields: {
        pendingRuleExecutions: relayStylePagination(),
      },
    },
    ScheduleWithTriggerAutomationRule: {
      fields: {
        pendingRuleExecutions: relayStylePagination(),
        successfulTriggerResultsIncludedInNextRun: relayStylePagination(),
      },
    },
    // do not normalize survey response entries
    // since they do not have a unique token
    // and they are all unique per response.
    SurveyResponseEntryArray: {
      keyFields: false,
    },
    SurveyResponseEntryString: {
      keyFields: false,
    },
    SurveyResponseEntryBoolean: {
      keyFields: false,
    },
    DatasourceDatatable: {
      fields: {
        records: relayStylePagination(),
      },
    },
    ProviderContext: {
      keyFields: ['provider'],
      fields: {
        search: relayStylePagination(['searchQuery']),
      },
    },
    DatasourceWarehouse: {
      fields: {
        records: relayStylePagination(),
      },
    },
  }

  Object.entries(scalarTypePolicies).forEach(([typeName, typePolicy]) => {
    if (typeName in policies) {
      if (policies[typeName].fields) {
        policies[typeName].fields = { ...typePolicy.fields, ...policies[typeName].fields }
      } else {
        policies[typeName].fields = typePolicy.fields
      }
    } else {
      policies[typeName] = typePolicy
    }
  })

  return policies
}

export const createApolloClient = (
  { csrfToken, gitSha1: initialGitSha1, disablePersistedQueries }: InitialData,
  notifyOffline: () => unknown
) => {
  const chainLink = from([
    onError(({ networkError }) => {
      if (networkError && navigator.onLine === false) {
        notifyOffline()
      }
    }),
    new HttpLink({
      uri: '/graphql',
      headers: {
        'X-CSRF-Token': csrfToken,
        'X-Frontend-Version': initialGitSha1,
      },
    }),
  ])

  return new ApolloClient<HbApolloCache>({
    cache: new InMemoryCache({
      possibleTypes: apolloPossibleTypes.possibleTypes,
      typePolicies: typePolicies(),
      // This function generates custom cache ids for certain objects.
      dataIdFromObject: (o) => {
        const { __typename, token, txnToken } = o

        if (__typename?.startsWith('Library') && txnToken !== undefined) {
          return defaultDataIdFromObject({ ...o, id: `${txnToken}-${token}` })
        }

        // AutomationRules can change types (`{Blank,Trigger,Schedule}AutomationRule`) and this can throw off the cache.
        // This normalizes them all to `AutomationRule`, which is okay because the token is a unique and stable identifier.
        if (__typename?.endsWith('AutomationRule')) {
          return `AutomationRule:${token}`
        }

        return defaultDataIdFromObject({ ...o, _id: o._id ?? o.token })
      },
    }),
    assumeImmutableResults: true,
    link: disablePersistedQueries ? chainLink : persistedQueriesLink.concat(chainLink),
  })
}

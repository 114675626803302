import { gql } from '@apollo/client'

export const FilterableColumnFragment = gql`
  fragment FilterableColumnFragment on FilterableColumn {
    apiName
    filterPath
    hidden
    hideable
    sortable
    title
    valuePath
    type
    aggsKey
  }
`

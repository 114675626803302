import { useCallback, useMemo, useState } from 'react'

import { Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { useAssignableQueues } from 'components/cases/reviews/queues/useQueues'

import AccountMenuItem from 'components/collaboration/AccountMenuItem'
import { FilterableAccountList } from 'components/library/FilterableAccountList'
import {
  Option as QueueOrAssigneeOption,
  QueueOrAssigneeAutocomplete,
} from 'components/library/QueueOrAssigneeAutocomplete'
import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { getCurrentAccount, getOrganizationTeammates, useHasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { BadgePermissionsEnum, BatchActionTypeEnum, FeatureFlag } from 'types/api'
import { Account, Theme } from 'types/hb'

const useLegacyStyles = makeStyles((theme: Theme) => {
  const heightConstraint = theme.spacing(25)
  // TODO: we should really make FilterableAccountList a drop down so we don't need to use magic numbers here, but I
  //  don't want to drastically change such a widely used component, just yet
  const inputHeight = 50

  return {
    container: { marginTop: theme.spacing(2) },
    constrained: { height: heightConstraint + inputHeight, maxHeight: heightConstraint + inputHeight },
    // FilterableAccountList styles
    list: { height: heightConstraint, maxHeight: heightConstraint, overflowY: 'scroll' },

    confirmation: { margin: `${theme.spacing(2)} 0` },
  }
})

const NO_ACCOUNTS: Account[] = []

const useAssignableAccounts = () => {
  const accounts = useSelector(getOrganizationTeammates)

  if (useHasPermission(BadgePermissionsEnum.UpdateReviewAssignee)) {
    return accounts
  }
  return NO_ACCOUNTS
}

interface LegacyBatchAssignProps {
  loading?: boolean
  setAssigneeToken: (token?: string) => void
}

const LegacyBatchAssign = ({ loading, setAssigneeToken }: LegacyBatchAssignProps) => {
  const accounts = useSelector(getOrganizationTeammates)
  const [selected, setSelected] = useState<Account | undefined>()
  const handleSelection = (account?: Account | undefined) => {
    setSelected(account)
    setAssigneeToken(account?.token)
  }
  const styles = useLegacyStyles()
  return (
    <div className={styles.container}>
      <Typography variant="body2" color="textPrimary" paragraph>
        Please select a new assignee for these reviews:
      </Typography>
      {selected ? (
        <div className={styles.constrained}>
          <AccountMenuItem
            key={selected.token}
            disabled={false}
            account={selected}
            selectable
            canRemove
            onClick={() => handleSelection()}
          />
          <div className={styles.confirmation}>
            <Typography variant="body2" paragraph display="inline" color="inherit">
              Are you sure you want to assign these reviews to {selected.fullName}?
            </Typography>
          </div>
        </div>
      ) : (
        <FilterableAccountList
          accounts={accounts}
          disabled={loading}
          onClickItem={handleSelection}
          placeholder="Assign to..."
          selectable
          autoFocus={false}
          styleOverrides={{ ...styles }}
        />
      )}
    </div>
  )
}

interface BatchAssignWithQueuesProps {
  assigneeToken?: string
  queueToken?: string
  loading?: boolean
  setAssigneeToken: (assigneeToken?: string) => void
  setQueueToken: (queueToken?: string) => void
}

const BatchAssignWithQueues = ({
  assigneeToken,
  queueToken,
  setAssigneeToken,
  setQueueToken,
}: BatchAssignWithQueuesProps) => {
  const currentAccount = useSelector(getCurrentAccount)
  const accounts = useAssignableAccounts()
  const { queues } = useAssignableQueues()

  const [open, setOpen] = useState(false)

  const handleChange = useCallback(
    (_e, option: QueueOrAssigneeOption) => {
      switch (option.custom.type) {
        case 'account': {
          setAssigneeToken(option.value)
          setQueueToken()
          break
        }
        case 'queue': {
          setQueueToken(option.value)
          setAssigneeToken()
          break
        }
        default:
      }
    },
    [setAssigneeToken, setQueueToken]
  )

  const handleAssignToSelf = useCallback(() => {
    setAssigneeToken(currentAccount.token)
    setQueueToken()
    setOpen(false)
  }, [currentAccount, setAssigneeToken, setQueueToken])

  const value = useMemo(
    () => ({
      type: assigneeToken ? ('account' as const) : queueToken ? ('queue' as const) : null,
      value: assigneeToken ?? queueToken,
    }),
    [assigneeToken, queueToken]
  )

  return (
    <QueueOrAssigneeAutocomplete
      accounts={accounts}
      label="Assignee"
      handleChange={handleChange}
      handleAssignToSelf={handleAssignToSelf}
      open={open}
      queues={queues}
      setOpen={setOpen}
      value={value}
    />
  )
}

export const BatchAssignDialog: DialogComponent<{ loading?: boolean }> = (props) => {
  const { children, loading, ...dialogProps } = props
  const [assigneeToken, setAssigneeToken] = useState<string>()
  const [queueToken, setQueueToken] = useState<string>()

  const isReviewsToQueuesEnabled = useFeatureFlag(FeatureFlag.ReviewsToQueues)

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Assign}
      batchActionParams={{ assigneeToken, queueToken }}
      confirmEnabled={!!assigneeToken || !!queueToken}
      title="Assign reviews"
      primaryText={
        isReviewsToQueuesEnabled
          ? 'When you assign reviews, the previous assignment will be removed and replaced with the new assignment. Changes to assignments will appear in the case history.'
          : 'When you assign reviews, any previous assignees will be removed and replaced with the new assignee. Changes to assignee will appear in the case history.'
      }
      confirmText={isReviewsToQueuesEnabled ? 'Assign Reviews' : 'Yes, assign reviews.'}
    >
      {isReviewsToQueuesEnabled ? (
        <BatchAssignWithQueues
          assigneeToken={assigneeToken}
          queueToken={queueToken}
          setAssigneeToken={setAssigneeToken}
          setQueueToken={setQueueToken}
        />
      ) : (
        <LegacyBatchAssign loading={loading} setAssigneeToken={setAssigneeToken} />
      )}
      {children}
    </BaseBatchActionDialog>
  )
}

import React, { ReactNode, useContext, useState, useEffect } from 'react'

import { DeleteOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles, makeStyles, Styles } from '@mui/styles'

import VisuallyHidden from '@reach/visually-hidden'

import classnames from 'classnames'

import { FieldArray, useFormikContext } from 'formik'
import { isEmpty } from 'lodash'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { formikNameString } from 'components/material/Form/formikMaterial'
import { mergeOverrideStyles } from 'components/utils/styles'
import { withEmpty } from 'helpers/objHelpers'

import CircleAddIcon from 'icons/CircleAddIcon'
import { Theme, WithStyles } from 'types/hb'

import { InputError } from '../../HbComponents/Form/InputError'

import { FormConfigurationContext } from './FormConfiguration'

const legacyFieldsetStyles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
    '&:first-child': {
      marginTop: 0,
    },
  },
  inset: {
    marginLeft: theme.spacing(4),
  },
  withSubtitle: { marginBottom: theme.spacing(0.75) },
  insetChildren: {
    marginLeft: theme.spacing(4),
  },
  inner: {},
  legend: {},
})

const redesignedFieldsetStyles = (theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
    '&:first-child': {
      marginTop: 0,
    },
    '& > p': { marginBottom: theme.spacing(1), marginTop: theme.spacing(2) },
  },
  inset: {
    marginLeft: theme.spacing(4),
  },
  withSubtitle: { marginBottom: theme.spacing(0.75) },
  insetChildren: {
    marginLeft: theme.spacing(4),
  },
  inner: {},
  legend: {},
})

interface FieldSetProps extends WithStyles<typeof legacyFieldsetStyles> {
  inset?: boolean
  insetChildren?: boolean
  heading?: string | null
  subtitle?: string
  children?: ReactNode
  hideHeading?: boolean
}

const BaseFieldSet = ({
  classes,
  inset = false,
  insetChildren = false,
  heading,
  children,
  hideHeading,
  subtitle,
  ...otherProps
}: FieldSetProps) => {
  const HeadingContent = (
    <legend className={classes.legend}>
      <Typography
        className={classnames({ [classes.inset]: inset, [classes.withSubtitle]: !!subtitle })}
        variant="subtitle1"
        paragraph
      >
        {heading}
      </Typography>
      {!!subtitle && (
        <HbText color="secondary" size="s">
          {subtitle}
        </HbText>
      )}
    </legend>
  )

  return (
    <fieldset className={classes.root} {...otherProps}>
      {/** We want to make sure the legend is rendered for a11y */}
      {hideHeading ? <VisuallyHidden>{HeadingContent}</VisuallyHidden> : HeadingContent}
      <div className={classnames(classes.inner, { [classes.insetChildren]: insetChildren })}>{children}</div>
    </fieldset>
  )
}

export const Fieldset = withStyles(legacyFieldsetStyles)(BaseFieldSet)

const addMoreStyles = (theme: Theme) => ({
  button: {
    cursor: 'pointer',
    fontWeight: '500',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.accent,
    },
  },
})

interface BaseButtonProps {
  label: string
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void
}

interface ButtonProps extends WithStyles<typeof addMoreStyles>, BaseButtonProps {}

export const AddMoreButton = withStyles(addMoreStyles)(({ classes, onClick, label }: ButtonProps) => (
  <button className={classes.button} type="button" onClick={onClick}>
    {`+ ${label}`}
  </button>
))

const removeStyles: Styles<Theme, object> = (theme: Theme) => ({
  button: {
    cursor: 'pointer',
    position: 'absolute',
    right: 0,
    bottom: '-1.333rem',
    fontWeight: '500',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: theme.palette.accent,
    },
  },
})

export const RemoveButton = withStyles(removeStyles)(({ classes, onClick, label }: ButtonProps) => (
  <button className={classes.button} type="button" onClick={onClick}>
    {`- ${label}`}
  </button>
))

const useLegacyStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    paddingBottom: '2rem',
  },
  listItem: {
    marginTop: '2rem',
    position: 'relative',
  },
  first: {
    marginTop: 0,
  },
  inset: {
    marginLeft: theme.spacing(4),
  },
}))

interface MultivaluedFieldsetProps<T> {
  name: string
  heading: string
  addLabel: string
  removeLabel: string | ((index: number) => string)
  fields: (p: { values: T; index: number; name: (field: string) => string }) => ReactNode
  emptyValue?: T
  inset?: boolean
  classes?: Partial<ReturnType<typeof useRedesignStyles>>
  hideHeading?: boolean
  hideDelete?: boolean
}

export const LegacyMultivaluedFieldset = <T extends Record<string, unknown>>({
  heading,
  emptyValue = {} as T,
  name,
  addLabel,
  removeLabel,
  fields,
  inset = false,
}: MultivaluedFieldsetProps<T>) => {
  const classes = useLegacyStyles()
  const formConfiguration = useContext(FormConfigurationContext)
  const readOnly = formConfiguration?.readOnly

  return (
    <Fieldset inset={inset} heading={heading} classes={{ root: classes.fieldset }}>
      <FieldArray name={name}>
        {({ form: { values }, push, remove }) => {
          const list = values[name]
          return (
            <>
              {withEmpty(list).map((value: T, index) => {
                const removeLabelText = typeof removeLabel === 'function' ? removeLabel(index) : removeLabel
                return (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key
                    className={classnames(classes.listItem, {
                      [classes.first]: index === 0,
                    })}
                  >
                    {fields({
                      values: value,
                      index,
                      name: (field) => formikNameString(name, index, field),
                    })}
                    {!readOnly && !isEmpty(list) && (
                      <RemoveButton label={removeLabelText} onClick={() => remove(index)} />
                    )}
                  </div>
                )
              })}
              {!readOnly && (
                <AddMoreButton
                  classes={{ button: classnames({ [classes.inset]: inset }) }}
                  label={addLabel}
                  onClick={() => push(emptyValue)}
                />
              )}
            </>
          )
        }}
      </FieldArray>
    </Fieldset>
  )
}

export const AddMoreButtonRedesigned = ({ onClick, label }: BaseButtonProps) => (
  <HbButton label={label} variant="textSecondary" size="small" Icon={CircleAddIcon} onClick={onClick} />
)

const useButtonStyles = makeStyles(() => ({
  root: {
    alignSelf: 'center',
  },
}))
export const RemoveButtonRedesigned = ({ onClick, label }: BaseButtonProps) => {
  const classes = useButtonStyles()
  return (
    <HbButton className={classes.root} size="medium" onClick={onClick} label={label} Icon={DeleteOutlined} iconOnly />
  )
}

const useRedesignStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    paddingBottom: '2rem',
  },
  listItem: {
    position: 'relative',
    display: 'flex',
    gap: theme.spacing(),
    '& > div': { flex: 1 },
  },
  first: {
    marginTop: 0,
  },
  inset: {
    marginLeft: theme.spacing(4),
  },
  formError: {
    marginTop: theme.spacing(-1),
    marginBottom: theme.spacing(),
  },
}))

const RedesignedFieldSet = withStyles(redesignedFieldsetStyles)(BaseFieldSet)

export const MultivaluedFieldset = <T extends Record<string, unknown>>({
  heading,
  emptyValue = {} as T,
  name,
  addLabel,
  removeLabel,
  fields,
  inset = false,
  classes: overrideClasses,
  hideHeading,
  hideDelete,
}: MultivaluedFieldsetProps<T>) => {
  const formConfiguration = useContext(FormConfigurationContext)
  const readOnly = formConfiguration?.readOnly
  const { values } = useFormikContext<Record<string, any>>()
  const baseClasses = useRedesignStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)

  // Only show the header for the empty field array after the user has clicked AddMoreButton
  const listLength = values?.[name]?.length ?? 0
  const [showList, setShowList] = useState(!!listLength)

  useEffect(() => {
    if (!listLength) {
      setShowList(false)
    }
  }, [listLength])

  return (
    <RedesignedFieldSet inset={inset} heading={heading} hideHeading={hideHeading || !showList}>
      <FieldArray name={name}>
        {({ form: { values: innerValues, errors, touched }, push, remove }) => {
          const list = innerValues[name] ?? []
          const error = errors[name]
          const fieldErrorClientError = typeof error === 'string' ? error : undefined
          return (
            <>
              {list.map((value: T, index: number) => {
                const removeLabelText = typeof removeLabel === 'function' ? removeLabel(index) : removeLabel

                return (
                  <div
                    key={index} // eslint-disable-line react/no-array-index-key
                    className={classnames(classes.listItem, {
                      [classes.first]: index === 0,
                    })}
                  >
                    {fields({
                      values: value,
                      index,
                      name: (field) => formikNameString(name, index, field),
                    })}
                    {!readOnly && !isEmpty(list) && !hideDelete && (
                      <RemoveButtonRedesigned label={removeLabelText} onClick={() => remove(index)} />
                    )}
                  </div>
                )
              })}
              <InputError
                testId={`${name}_form-error`}
                isErroneous={!!(fieldErrorClientError && touched[name])}
                clientError={fieldErrorClientError}
                classes={{
                  root: classes.formError,
                }}
                fieldName={name}
              />
              {!readOnly && (
                <AddMoreButtonRedesigned
                  label={addLabel}
                  onClick={() => {
                    setShowList(true)
                    push(emptyValue)
                  }}
                />
              )}
            </>
          )
        }}
      </FieldArray>
    </RedesignedFieldSet>
  )
}

import { MergeEntity, MergeSubject } from './types'

export const getMergeEntityFromSubject = (subject: MergeSubject | undefined): MergeEntity | null => {
  switch (subject?.__typename) {
    case 'InvestigationSubjectPerson': {
      return subject.person
    }
    case 'InvestigationSubjectBusiness': {
      return subject.business
    }
    case 'InvestigationSubjectFinancialInstitution': {
      return subject.financialInstitution
    }
    default:
      return null
  }
}

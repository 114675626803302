import { HbTooltip } from 'components/HbComponents/HbTooltip'

import { accountAvatarDetailsFragment } from 'components/accounts/AccountAvatar'
import { userInitials } from 'helpers/uiHelpers'

import { DEFAULT_ASSIGNEE_TOOLTIP_DELAY, StyledAccountAvatar } from './shared'
import { AssigneeDetails } from './types'

export const AssigneeAvatar = ({
  assignee,
  className,
  tooltipDelay = DEFAULT_ASSIGNEE_TOOLTIP_DELAY,
}: {
  assignee: AssigneeDetails
  className?: string
  tooltipDelay?: number
}) => {
  return (
    <HbTooltip title={assignee.fullName} enterDelay={tooltipDelay} enterNextDelay={tooltipDelay}>
      <div className={className}>
        <StyledAccountAvatar color={assignee.avatarColor} variant={assignee.avatarVariant}>
          {userInitials(assignee)}
        </StyledAccountAvatar>
      </div>
    </HbTooltip>
  )
}

AssigneeAvatar.fragments = {
  assignee: accountAvatarDetailsFragment,
}

import { useEffect } from 'react'

import { ApolloError, QueryResult, gql, useLazyQuery } from '@apollo/client'

import { useParams } from 'react-router-dom'

import IconSheet from 'components/cases/report/shared/IconSheet'
import { GenerateDocumentUIWrapper } from 'components/cases/report/summary/GenerateDocument'

import { EmailDownloadQuery, EmailDownloadQueryVariables } from './__generated__/DownloadsPage.generated'

export const GET_EMAIL_DOWNLOAD = gql`
  query EmailDownload($token: ID!) {
    emailDownload(token: $token) {
      ... on EmailDownload {
        filename
        presignedUrl
      }
      ... on FileDownloadAuthorization {
        filename
        presignedUrl
      }
    }
  }
`

export const Content = ({
  data,
  loading,
  error,
}: Pick<QueryResult<EmailDownloadQuery, EmailDownloadQueryVariables>, 'data' | 'loading'> & {
  error?: Pick<ApolloError, 'message'>
}) => {
  const genericErrorMessage = 'Error downloading your file'
  if (loading) {
    return <IconSheet icon="access_time" title="Preparing your file..." />
  }
  if (error) {
    return <IconSheet icon="error" title={genericErrorMessage} status="error" subtext={error.message} />
  }
  if (data?.emailDownload) {
    return (
      <IconSheet
        icon="check_circle"
        title="Your file is ready for download"
        subtext={data.emailDownload.filename}
        actionButton={{
          url: data.emailDownload.presignedUrl,
          text: 'Download',
        }}
      />
    )
  }
  return <IconSheet icon="error" title={genericErrorMessage} status="error" />
}

export function DownloadsPage() {
  const { token } = useParams<{ token?: string }>()
  const [query, result] = useLazyQuery<EmailDownloadQuery, EmailDownloadQueryVariables>(GET_EMAIL_DOWNLOAD)

  useEffect(() => {
    if (token) {
      query({ variables: { token } })
    }
  }, [token, query])

  return (
    <GenerateDocumentUIWrapper loading={result.loading}>
      <Content {...result} />
    </GenerateDocumentUIWrapper>
  )
}

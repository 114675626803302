import { ComponentProps } from 'react'

import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import { PlainTextPlugin } from '@lexical/react/LexicalPlainTextPlugin'

import classNames from 'classnames'

import ErrorBoundary from './ErrorBoundary'

import type { Props as RootProps, BaseClasses } from './TypeAheadEditor'

export type Props = Pick<RootProps, 'className' | 'theme' | 'tabIndex'> & {
  classes: BaseClasses
  placeholder: ComponentProps<typeof PlainTextPlugin>['placeholder']
  readOnly?: boolean
}

export default function PlainTextEditView({ className, theme, classes, tabIndex, placeholder, readOnly }: Props) {
  return (
    <PlainTextPlugin
      ErrorBoundary={ErrorBoundary}
      contentEditable={
        <ContentEditable
          className={classNames(theme?.editorInput || classes.editorInput, className, {
            [classes.textCursor]: !readOnly,
          })}
          tabIndex={tabIndex}
        />
      }
      placeholder={placeholder}
    />
  )
}

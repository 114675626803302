import { useCallback } from 'react'

import { useSelector } from 'actions/store'
import {
  getCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken,
  isFiTokenInIndex,
} from 'helpers/stateHelpers'

export const useIsLinkedFi = () => {
  const tokenIndex = useSelector(getCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken)
  return useCallback((libraryFiToken: string) => isFiTokenInIndex(tokenIndex, libraryFiToken), [tokenIndex])
}

import { createElement } from 'react'

import classnames from 'classnames'
import PropTypes from 'prop-types'

const ROOT = 'hb-menu-list'
const DIVIDER_ROOT = `${ROOT}__list-item-divider`

export const MenuListItemDivider = ({ className, ...otherProps }) => {
  const classes = classnames(DIVIDER_ROOT, className)

  return createElement('li', {
    className: classes,
    ...otherProps,
  })
}

MenuListItemDivider.propTypes = {
  className: PropTypes.string,
}

MenuListItemDivider.defaultProps = {
  className: null,
}

export default MenuListItemDivider

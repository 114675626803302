import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { MiddeskCurrentSearch, MiddeskUIState, MiddeskUIStateUpdate } from 'components/cases/Tabs/Middesk/Middesk.types'

export type MiddeskState = {
  currentSearches: Array<MiddeskCurrentSearch>
  UIState: {
    [libraryBusinessToken: string]: MiddeskUIState
  }
}

export const initialState: MiddeskState = {
  currentSearches: [],
  UIState: {},
}

const MiddeskSlice = createSlice({
  name: 'Middesk',
  initialState,
  reducers: {
    updateUIState(
      state,
      action: PayloadAction<{
        libraryBusinessToken: string
        UIState: MiddeskUIStateUpdate
      }>
    ) {
      const { libraryBusinessToken, UIState: newUIState } = action.payload
      const existingUIState: MiddeskUIState = state.UIState[libraryBusinessToken] || {}

      const UIState =
        newUIState.type === 'search' ? newUIState : { ...newUIState, searchFields: existingUIState.searchFields || {} }

      return {
        ...state,
        UIState: {
          ...state.UIState,
          [libraryBusinessToken]: UIState,
        },
      }
    },
    addCurrentSearch(state, action: PayloadAction<MiddeskCurrentSearch>) {
      return {
        ...state,
        currentSearches: [...state.currentSearches, action.payload],
      }
    },
    removeCurrentSearch(state, action: PayloadAction<{ jobToken: string }>) {
      return {
        ...state,
        currentSearches: state.currentSearches.filter((search) => search.jobToken !== action.payload.jobToken),
      }
    },
  },
})

export const { updateUIState, addCurrentSearch, removeCurrentSearch } = MiddeskSlice.actions
export default MiddeskSlice.reducer

import moment from 'moment-timezone'

import { useSelector } from 'actions/store'
import { useDateFormatter } from 'hooks/DateFormatHooks'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { PredicateInput } from 'types/api'
import { SearchRequestWithId } from 'utils/query/api.types'

export const getDateRangeFromFilter = (
  validFilters: SearchRequestWithId,
  dateFormatter: ReturnType<typeof useDateFormatter>
) => {
  const appliedDates = validFilters.filter.children.reduce<PredicateInput[]>((acc, filter) => {
    if (filter._type === 'SearchFilterWithId') {
      if (filter.field !== 'createdAt') {
        return acc
      }
      acc?.push(filter.predicate)
    }
    return acc
  }, [])

  if (appliedDates.length === 0) {
    return null
  }

  let currentDate = ''
  let fromDate = ''
  let toDate = ''

  appliedDates.forEach((predicate) => {
    const value = predicate.values[0]
    if (predicate.operator === 'is') {
      currentDate = value
    } else if (predicate.operator === 'gte') {
      fromDate = value
    } else if (predicate.operator === 'lte') {
      toDate = value
    } else if (predicate.operator === 'gt') {
      fromDate = moment(value).add(1, 'days').format('YYYY-MM-DD')
    } else if (predicate.operator === 'lt') {
      toDate = moment(value).subtract(1, 'days').format('YYYY-MM-DD')
    }
  })

  if (currentDate) {
    return dateFormatter(currentDate)
  }

  if (fromDate && toDate) {
    return `${dateFormatter(fromDate)} - ${dateFormatter(toDate)}`
  }

  const dateStringElements = []

  if (fromDate) {
    dateStringElements.push(`From ${dateFormatter(fromDate)}`)
  }

  if (toDate) {
    dateStringElements.push(`Through ${dateFormatter(toDate)}`)
  }

  return dateStringElements.join(' ')
}

export const useReadCurrentDateFilter = () => {
  const dashboardsSelectors = useDashboardSelectors()

  const validFilters = useSelector(dashboardsSelectors.filters.applied.valid)
  const dateFormatter = useDateFormatter('LL')

  return getDateRangeFromFilter(validFilters, dateFormatter)
}

import { useFormikContext } from 'formik'

interface HbFormikContextProps {
  autosave?: boolean
}

export const useHbFormikContext = ({ autosave }: HbFormikContextProps) => {
  const form = useFormikContext()
  if (autosave) {
    return {
      ...form,
      isSubmitting: false, // override to prevent fields from blurring while autosaving
    }
  }
  return form
}

import { useState } from 'react'

import StorageIcon from '@mui/icons-material/Storage'
import { FormControl, InputLabel, Select, MenuItem, Box, Divider } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'
import { visuallyHidden } from '@mui/utils'

import { startCase } from 'lodash'
import { Controller } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { useSelector } from 'actions/store'

import { HbText } from 'components/HbComponents/Text/HbText'
import ConfirmDialog from 'components/library/ConfirmDialog'

import Loader from 'components/library/Loader'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'

import { AutomationDomainType } from 'types/api'

import { Theme } from 'types/hb'

import { checkIsRule, useAutomationRuleOrTemplate } from '../AutomationRuleOrTemplateContext'
import { getAvailableTriggerActions } from '../SelectActionDialog'
import { TriggerSchemaReturnType, getDefaultTriggerFilters } from '../formSchema'

import LogicalExpressionEditor from './LogicalExpressionEditor'

import { AutomationDomain, useDomainFieldSpecs, getIconForDomainType, getLabelForDomainType } from './fieldConfig'
import { useThinControlStyles } from './styles'

export function generateTriggerName(domainType: AutomationDomainType) {
  return `${getLabelForDomainType(domainType)} meets condition`
}

const useTriggerFilterEditorStyles = makeStyles((theme: Theme) => ({
  domainTypeRoot: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  domainTypeWhen: {
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
  },
  domainTypeDomain: {
    flexGrow: 1,
  },
  domainTypeMenuItem: {
    display: 'flex',
    alignItems: 'center',
  },
  domainTypeIcon: {
    marginRight: theme.spacing(1),
  },
  datasourcesLabel: {
    display: 'block',
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
    ...theme.typography.subtitle1,
  },
}))

function parseDomain(value: string): AutomationDomain {
  const [newDomainType, newDomainDatasourceToken = null] = value.split(':') as [AutomationDomainType, string | null]
  return { type: newDomainType, datasourceToken: newDomainDatasourceToken }
}

function serializeDomain(value: AutomationDomain): string {
  if (value.type === AutomationDomainType.Datasource) {
    return `${value.type}:${value.datasourceToken}`
  }
  return value.type
}

export function TriggerFilterEditor({ form }: { form: TriggerSchemaReturnType }) {
  const usage = useUsage()
  const ruleOrTemplate = useAutomationRuleOrTemplate()
  const [showConfirmDomainChange, setShowConfirmDomainChange] = useState<null | { domain: AutomationDomain }>(null)
  const classes = useTriggerFilterEditorStyles()
  const controlClasses = useThinControlStyles()

  const { watch, reset, setValue, getValues } = form

  const values = watch()
  const domainType = getValues('domain.type')
  invariant(domainType)
  const datasourceToken = getValues('domain.datasourceToken') ?? null

  const domain = { type: domainType, datasourceToken }
  const availableActions = useSelector(getAvailableTriggerActions)
  const { loading, getDomainFieldSpec, baseDomainFieldSpecs, datasourceDomainFieldSpecs } = useDomainFieldSpecs()

  const handleChangeDomainType = (newDomain: AutomationDomain, resetAction: boolean) => {
    setValue('domain.type', newDomain.type, { shouldDirty: true })
    setValue('domain.datasourceToken', newDomain.datasourceToken, { shouldDirty: true })

    const domainFieldSpec = getDomainFieldSpec(newDomain)
    invariant(domainFieldSpec)

    const newValues = {
      ...values,
      domain: newDomain,
      triggerFilters: getDefaultTriggerFilters(newDomain.type, domainFieldSpec),
      eventText: generateTriggerName(newDomain.type),
    }

    if (resetAction) {
      reset(
        {
          ...newValues,
          actionType: null,
          actionParams: {},
        },
        { keepDirty: true }
      )
      setValue('actionText', '')
    } else {
      reset(newValues, { keepDirty: true })
    }

    invariant(ruleOrTemplate)
    usage.logEvent({
      name: 'automations:editorTriggerDomain:updated',
      data: {
        ...(checkIsRule(ruleOrTemplate)
          ? { automationRuleToken: ruleOrTemplate.token }
          : { automationRuleTemplateToken: ruleOrTemplate.token }),
        oldDomainType: domain.type,
        newDomainType: newDomain.type,
      },
    })
  }

  if (loading) {
    return <Loader variant="global" />
  }

  return (
    <>
      <div className={classes.domainTypeRoot}>
        <HbText className={classes.domainTypeWhen}>If</HbText>
        <FormControl fullWidth className={classes.domainTypeDomain}>
          <InputLabel htmlFor="domain-type">
            <span style={visuallyHidden}>Domain Type</span>
          </InputLabel>
          <Controller
            name="domain"
            render={({ field: { onChange, ...field } }) => (
              <Select
                required
                onChange={(e) => {
                  const newDomain = parseDomain(e.target.value as string)
                  const newActions = availableActions.filter((a) => a.domainTypes.includes(newDomain.type))
                  if (values.actionType && !newActions.some((a) => a.actionType === values.actionType)) {
                    setShowConfirmDomainChange({ domain: newDomain })
                  } else {
                    handleChangeDomainType(newDomain, false)
                  }
                }}
                inputProps={{
                  ...field,
                  value: serializeDomain(field.value),
                }}
                variant="outlined"
                className={controlClasses.control}
                classes={{ root: controlClasses.selectRoot }}
                data-testid="domain-type"
              >
                {baseDomainFieldSpecs.map(([{ type }]) => {
                  const Icon = getIconForDomainType(type)
                  const label = getLabelForDomainType(type)
                  return (
                    <MenuItem key={type} value={type}>
                      <Box alignItems="center" display="flex">
                        {Icon && <Icon className={classes.domainTypeIcon} />}
                        {label}
                      </Box>
                    </MenuItem>
                  )
                })}
                {datasourceDomainFieldSpecs.length > 0 ? (
                  <>
                    <Divider />
                    <HbText className={classes.datasourcesLabel}>External Data</HbText>
                  </>
                ) : null}
                {datasourceDomainFieldSpecs?.map(([ds]) => {
                  return (
                    <MenuItem
                      key={ds.datasourceToken}
                      value={`${AutomationDomainType.Datasource}:${ds.datasourceToken}`}
                    >
                      <Box alignItems="center" display="flex">
                        <StorageIcon className={classes.domainTypeIcon} />
                        {ds.label}
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
            )}
          />
        </FormControl>
      </div>
      <LogicalExpressionEditor name="triggerFilters" domain={domain} form={form} />
      <ConfirmDialog
        confirmText="Confirm"
        confirmVariant="warning"
        onConfirm={() => {
          if (showConfirmDomainChange) {
            handleChangeDomainType(showConfirmDomainChange.domain, true)
            setShowConfirmDomainChange(null)
          }
        }}
        loading={false}
        open={!!showConfirmDomainChange}
        onClose={() => setShowConfirmDomainChange(null)}
        title="The current action is not compatible with this trigger category"
      >
        <HbText tag="p">
          The {startCase(values.actionType ?? undefined)} action is not supported for{' '}
          {startCase(showConfirmDomainChange?.domain?.type ?? undefined)} triggers. Proceeding with this change to your
          trigger category will reset your automation&apos;s actions.
        </HbText>
      </ConfirmDialog>
    </>
  )
}

import { gql } from '@apollo/client'

import { SearchReviewResultV2Fragment } from 'dashboards/reviews/gql/searchReviews.fragments'
import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

export const SEARCH_REVIEWS_V3_QUERY = gql`
  query SearchReviewsV3(
    $page: Int!
    $pageSize: Int!
    $appliedFilters: SearchRequestInput!
    $includeRecentAlertExternalId: Boolean!
  ) {
    searchReviewsV3(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchReviewResultV2Fragment
    }
  }

  ${SearchReviewResultV2Fragment}
`

export const REVIEW_COLUMNS_QUERY_V2 = gql`
  query ReviewColumnsV2($includeCustomColumns: Boolean!) {
    searchReviewMetadataV2(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`
export const REVIEW_METADATA_QUERY_V2 = gql`
  query ReviewMetadataV2($columns: [String!], $query: String) {
    searchReviewMetadataV2(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

export const GET_SAVED_SEARCH_QUERY = gql`
  query GetSavedSearchQuery($dashboardType: DashboardTypeEnum!) {
    currentAccount {
      savedSearchRequests(dashboardType: $dashboardType) {
        totalCount
        nodes {
          name
          token
          filters {
            field
            group
            predicate {
              operator
              values
            }
          }
          groups {
            combinator
            parent
          }
          query
          sorts {
            sortDir
            sortField
          }
        }
      }
    }
  }
`

export const UPSERT_SAVED_SEARCH_QUERY = gql`
  mutation UpsertSavedSearchQuery(
    $token: ID
    $name: String!
    $search: SearchRequestInput!
    $dashboardType: DashboardTypeEnum!
  ) {
    upsertSavedSearch(input: { token: $token, name: $name, search: $search, dashboardType: $dashboardType }) {
      savedSearchRequest {
        token
      }
    }
  }
`

export const DELETE_SAVED_SEARCH_QUERY = gql`
  mutation DeleteSavedSearchQuery($token: ID!) {
    deleteSavedSearch(input: { token: $token }) {
      deletedSearchRequest {
        token
      }
    }
  }
`

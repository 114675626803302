import { getCenter as _getCenter } from 'geolib'

import { orderBy } from 'lodash'

import { CirclePaint } from 'mapbox-gl'

import { HbColorsByHue } from 'components/colors'
import { max, min } from 'helpers/statistics'
import { Location } from 'reducers/investigationsReducer.types'

import { Geopoint } from 'types/api'

export const getCenter = _getCenter
export type Coordinate = [number, number]
export type MinOrMax = 'min' | 'max'
export type LatOrLng = 0 | 1

export type NavigatableLocation = 'individual' | 'business' | 'institution' | 'transaction' | 'device'
export type LocationType = NavigatableLocation | 'ip_address' | 'device_fingerprint'

export type LocationTypeAndLocation = LocationType | 'location'

export const canNavToLocation = (nodeType: LocationTypeAndLocation): nodeType is NavigatableLocation =>
  !['ip_address', 'device_fingerprint', 'location'].includes(nodeType)

export const locationTypeToDataType = (locationType: NavigatableLocation) =>
  locationType === 'transaction' ? 'transactions' : locationType

// This token lives under the `ryan@hummingbird.co` account.
export const MAPBOX_ACCESS_TOKEN =
  'pk.eyJ1IjoicnlhbmdlcmFyZCIsImEiOiJjamJkejR5aWsyY3ZuMndub21hZGRxbDN0In0.vILI5ddl6IdmsUygCvhQcw'

export const MAX_INITIAL_ZOOM = 15 // https://docs.mapbox.com/help/glossary/zoom-level/ - "You can see large buildings". Higher === more zoomed in.

export function getMinOrMax(coordinates: Coordinate[], minOrMax: MinOrMax, latOrLng: LatOrLng): number {
  if (minOrMax === 'max') {
    return max(coordinates, (value: Coordinate) => parseFloat(`${value[latOrLng]}`))
  }
  return min(coordinates, (value: Coordinate) => parseFloat(`${value[latOrLng]}`))
}

export function getBounds(locations: Location[]): [Coordinate, Coordinate] {
  const coordinates = locations.map((location) => location.coordinates)

  const maxLat = getMinOrMax(coordinates, 'max', 1)
  const minLat = getMinOrMax(coordinates, 'min', 1)
  const maxLng = getMinOrMax(coordinates, 'max', 0)
  const minLng = getMinOrMax(coordinates, 'min', 0)

  const southWest: Coordinate = [minLng, minLat]
  const northEast: Coordinate = [maxLng, maxLat]

  return [southWest, northEast]
}

export function getGeopointBounds(geopoints: Geopoint[]): [Coordinate, Coordinate] {
  const maxLat = max(geopoints, (geopoint: Geopoint) => geopoint.latitude)
  const minLat = min(geopoints, (geopoint: Geopoint) => geopoint.latitude)
  const maxLng = max(geopoints, (geopoint: Geopoint) => geopoint.longitude)
  const minLng = min(geopoints, (geopoint: Geopoint) => geopoint.longitude)

  const southWest: Coordinate = [minLng, minLat]
  const northEast: Coordinate = [maxLng, maxLat]

  return [southWest, northEast]
}

export function findCenterOfBounds(bounds: [Coordinate, Coordinate]): Coordinate {
  const geoLibCoords = bounds.map((bound) => ({
    longitude: bound[0],
    latitude: bound[1],
  }))
  const geoLibCenter = getCenter(geoLibCoords)
  if (geoLibCenter === false) {
    return bounds[0]
  }
  return [geoLibCenter.longitude, geoLibCenter.latitude]
}

// The radius of the circle can range from 10px - 26px.
// We bucket the relative percentages into 12.5% buckets, from 0 - 100%.
// Every bucket represents a 2px increase in radius size.
// Example: if the relative percentage is 0-12.5%, the radius is 10.
// If the relative percentage is 12.5-25%, the radius is 12.
export function determineRadiusFromRelativePercentage(relativePercentage: number): number {
  const minRadius = 10
  let currentRadius = minRadius
  for (let i = 0; i < 100; i += 12.5) {
    // If we've found the right bucket, stop searching.
    if (relativePercentage >= i && relativePercentage < i + 12.5) {
      break
    }
    currentRadius += 2
  }
  return currentRadius
}

// Create a map of transaction count to circle radius.
// This is done by using the largest transaction count as the max, and
// calculating the relative percentages of the remaining counts.
//
// These percentages are then mapped to a radius using a bucketing system.
// Example: if the relative percentage is 0-12.5%, the radius is 10.
// If the relative percentage is 12.5-25%, the radius is 12.
export function mapTransactionLocationToRadius(locationsForType: Location[]): Record<string, number> {
  // Sort transaction locations by transaction count in descending order
  const sortedLocations = orderBy(locationsForType, ['count'], ['desc'])
  const maxCount = sortedLocations[0].count
  return sortedLocations.reduce((accum, location) => {
    // Only add if we have not already mapped a specific transaction count -> radius size.
    if (accum[location.count] === undefined) {
      const relativePercentage = (location.count / maxCount) * 100
      const circleRadius = determineRadiusFromRelativePercentage(relativePercentage)

      accum[location.count] = circleRadius
    }
    return accum
  }, {} as Record<string, number>)
}

// This builds up the paint options needed for building a mapbox-gl Layer object.
// Transaction locations are given special consideration. The circle radius for
// transactions should be relatively sized according to the number of transactions involved.
export function buildLayerPaintOptions(locationType: LocationType): CirclePaint {
  let circleColor = HbColorsByHue.hbBlue
  const circleOpacity = 1.0
  const circleRadius: CirclePaint['circle-radius'] = 14

  switch (locationType) {
    case 'individual':
      circleColor = HbColorsByHue.lemon
      break
    case 'business':
      circleColor = HbColorsByHue.salmon
      break
    case 'institution':
      circleColor = HbColorsByHue.plum
      break
    case 'device':
      circleColor = HbColorsByHue.rose
      break
    case 'transaction':
      circleColor = HbColorsByHue.hbBlue
      break
    default:
      throw new Error(`Unknown locationType '${locationType}'`)
  }
  // Statements executed when the result of expression matches valueN
  return {
    'circle-color': circleColor.medium,
    'circle-radius': circleRadius,
    'circle-opacity': circleOpacity,
    'circle-stroke-color': circleColor.light,
    'circle-stroke-width': 5,
    'circle-stroke-opacity': 0.4,
  }
}

export function googleMapsURL(query: string, opts?: { lat?: number | string; lon?: number | string }): string {
  const u = new URL('https://www.google.com')
  u.searchParams.append('api', '1')

  if (opts?.lat && opts?.lon) {
    u.pathname = '/maps/@'
    u.searchParams.append('map_action', 'pano')
    u.searchParams.append('viewpoint', `${opts.lat},${opts.lon}`)
  } else {
    u.pathname = '/maps/search/'
    u.searchParams.append('query', query)
  }
  return u.href
}

import React, { ReactNode, useCallback, useMemo, useState } from 'react'

import { BeforeUnloadPrompt } from 'hooks/UseBeforeUnload'

interface SavingContextValue {
  // Mark the component as dirty, with intent to make a save call in the future
  markIntentToSave: (willSave: boolean) => void
  // Mark the component as actively saving
  markComponentSaving: (saving: boolean) => void
  trackSave: (savePromise: Promise<unknown>) => void
}

export const SavingContext = React.createContext<SavingContextValue>({
  markIntentToSave: () => {},
  markComponentSaving: () => {},
  trackSave: (_savePromise: Promise<unknown>) => {},
})

interface Props {
  children: (touched: boolean, saving: boolean) => ReactNode
  skipUnloadPrompt?: boolean
}

export function SavingTracker(props: Props) {
  const { children, skipUnloadPrompt } = props

  const [touched, setTouched] = useState(false)
  const [pendingSaves, setPendingSaves] = useState(0)
  const [saveIntents, setSaveIntents] = useState(0)

  const markIntentToSave = useCallback((willSave: boolean) => {
    setTouched(true)
    if (willSave) {
      setSaveIntents((save) => save + 1)
    } else {
      setSaveIntents((save) => save - 1)
    }
  }, [])

  const markComponentSaving = useCallback((saving: boolean) => {
    setTouched(true)

    if (saving) {
      setPendingSaves((save) => save + 1)
    } else {
      setPendingSaves((save) => save - 1)
    }
  }, [])

  const trackSave = useCallback(
    async (save: Promise<unknown>) => {
      markComponentSaving(true)
      try {
        await save
      } finally {
        markComponentSaving(false)
      }
    },
    [markComponentSaving]
  )

  const saving = pendingSaves > 0
  const isDirty = saveIntents > 0 || pendingSaves > 0

  const value = useMemo<SavingContextValue>(
    () => ({ markComponentSaving, markIntentToSave, trackSave }),
    [markComponentSaving, markIntentToSave, trackSave]
  )

  return (
    <SavingContext.Provider value={value}>
      <>
        {children(touched, saving)}
        <BeforeUnloadPrompt shouldShowPrompt={isDirty && !skipUnloadPrompt} />
      </>
    </SavingContext.Provider>
  )
}

import { OperatorEnum } from 'types/api'

export const makeKey = (apiName: string, token: string) => `${token}::${apiName}`

export const getDateFilter = (filterValue: string) => {
  const baseSearchFilter = {
    _type: 'SearchFilterWithId' as const,
    id: '',
    field: 'createdAt',
    predicate: {
      values: [],
      operator: OperatorEnum.Is,
    },
  }

  return {
    ...baseSearchFilter,
    predicate: {
      ...baseSearchFilter.predicate,
      values: [filterValue],
    },
  }
}

import { OperatorEnum } from 'types/api'
import { SearchFilterWithId } from 'utils/query/api.types'

export const canPerformNumericSearch = (number?: string) => {
  if (typeof number !== 'string' && typeof number !== 'number') {
    return false
  }
  if (typeof number === 'string' && !number.trim().length) {
    return false
  }

  // Check number is exactly 0 or truthy and coerces to a number, '0' is truthy.
  return (number as unknown as number) === 0 || !!(number && !Number.isNaN(Number(number)))
}

export const canPerformDateSearch = (date?: string): date is string => {
  if (!date) {
    return false
  }

  return new Date(date).toString() !== 'Invalid Date'
}

// TODO: https://thecharm.atlassian.net/browse/PROD-10993
// Figure out if, (and if if, how) this ought to be generalized and codified into search infrastructure.
// We explicitly do not want to provide users an aggregation of case names or profile names for data access reasons,
// this reason may be applicable to other fields in the future. Also, this direct search UI may be desireable instead of
// or in addition to dropdown selection in some cases.
export const fieldUsesSearchForIsOperator = (fieldApiName: string) =>
  fieldApiName === 'caseName' || fieldApiName === 'displayName'
export const isRangeOperator = (operator: OperatorEnum) =>
  [OperatorEnum.Gt, OperatorEnum.Gte, OperatorEnum.Lt, OperatorEnum.Lte].includes(operator)

type MultiSelectOperator = OperatorEnum.In | OperatorEnum.NotIn
export const isMultiSelectOperator = (operator: OperatorEnum): operator is MultiSelectOperator =>
  [OperatorEnum.In, OperatorEnum.NotIn].includes(operator)

const fieldsWithContainsDisabled = new Set([
  // These fields index the token and not the display value, so "contains" does not work.
  'sourceAccount', // Txns Dashboard
  'sourceEntity', // Txns Dashboard
  'destinationAccount', // Txns Dashboard
  'destinationEntity', // Txns Dashboard
])

// These are enum-y. "Contains" does not work because we index the enum value, not the display value.
// Filtering options via backend search also does not work for the same reason.
// Prevent `contains` as a selected operator, and permit client side filtering.
const fieldsWithUpperBound = new Set([
  'datatype', // Custom Fields Dashboard
  'displayAs', // Custom Fields Dashboard
  'direction', // Txns Dashboard
  'eventable', // Automations Dashboard -- this is the Associated Event field
  'filingStatus', // Case Dashboard -- yes, you read that right. This is NOT the Status field in the Filings Dashboard
  'filingType', // Filings Dashboard
  'fincenCTRTransactionType', // Txns Dashboard -- need to enable Enable CTR Filing FF for this
  'formType', // RFI Dashboard
  'instrumentType', // Txns Dashboard
  'jurisdiction', // Filings Dashboard
  'reviewStatus', // Case Dashboard -- this is 'state' in the backend
  'status', // All Dashboards except Case
  'type', // Profiles Dashboard; in the Case Dashboard, this is also review_type_canonical_id
])

export const fieldHasUpperBound = (apiName: string) => fieldsWithUpperBound.has(apiName)
export const fieldSupportsContainsOperator = (apiName: string) =>
  !fieldsWithUpperBound.has(apiName) && !fieldsWithContainsDisabled.has(apiName)

export const determineInitialFilterOperator = (filter: SearchFilterWithId | undefined): OperatorEnum => {
  if (!filter?.predicate?.operator) {
    return OperatorEnum.Is
  }

  return filter.predicate.operator
}

// These are the same set of fields list in NORMALIZED_COLS found in
// hummingbird-rails/app/graphql/graphql_types/dashboard/filter_option_v2.rb
const apiFieldsToNormalizeForContains = new Set([
  'alertExternalIds', // Case Dashboard
  'alertRules', // RFI, Txns Dashboard,
  'description', // Txns
  'triggers', // Case Dashboard -- in the backend, this is synonymous with case_alert_rules
  'filingInstitution', // Filings Dashboard
  'filingName', // Filings Dashboard
  'tags', // Case & Profiles Dashboard
])

export const processFieldForContains = (currentField: string, newValue: string) => {
  if (apiFieldsToNormalizeForContains.has(currentField)) {
    return newValue.toLowerCase()
  }
  // For all other values that are eligible for contains, return the value as is so we don't break
  // any pre-existing filters
  return newValue
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const UploadIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g id="content">
      <g>
        <rect x="4" y="18" width="16" height="2" />
        <path d="M11,6.41V16h2V6.41l3.29,3.3,1.42-1.42-5-5a1,1,0,0,0-1.42,0l-5,5L7.71,9.71Z" />
      </g>
    </g>
  </SvgIcon>
)

export default UploadIcon

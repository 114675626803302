import { ChangeEvent, useMemo } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { isEqual } from 'lodash'
import { Draggable } from 'react-beautiful-dnd'

import { useDispatch, useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { DeleteButton } from 'dashboards/reviews/components/Header/filtering/sharedComponents'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useDashboardFilters } from 'dashboards/shared/react/getNamedQueryGroups'
import { HandleBarsIcon } from 'icons/HandleBarsIcon'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { PartialSort } from 'reducers/dashboards/dashboards.types'
import { SortEnum } from 'types/api'
import { Theme } from 'types/hb'

interface SortEntryProps {
  sortEntry: PartialSort
  index: number
}

const useStyles = makeStyles((theme: Theme) => ({
  dragHandleBars: { justifySelf: 'center', alignSelf: 'center' },
  sortEntry: {
    display: 'grid',
    gap: theme.spacing(0.5),
    grid: '1fr / 28px auto auto 48px',
    marginBottom: theme.spacing(1),
  },
  resetButton: { marginLeft: theme.spacing() },
  field: { width: 150, maxHeight: 40, flex: '1 0 fit-content' },
  direction: { width: 150, maxHeight: 40, flex: '1 0 fit-content' },
}))

export const SortEntry = ({ sortEntry, index }: SortEntryProps) => {
  const { columns } = useDashboardColumns()
  const selectors = useDashboardSelectors()
  const actions = useDashboardActions()
  const { defaultSorts } = useDashboardFilters()
  const allSorts = useSelector((state) => selectors.sorts.all(state) ?? [])

  const classes = useStyles()
  const dispatch = useDispatch()

  const draggableId = sortEntry.sortField ?? `empty`

  const sortableColumns = useMemo(
    () =>
      columns
        .filter(
          (c) =>
            c.sortable && (c.apiName === sortEntry.sortField || !allSorts.some((sort) => sort.sortField === c.apiName))
        )
        .sort((a, b) => a.title.localeCompare(b.title)),
    [allSorts, columns, sortEntry.sortField]
  )

  return (
    <Draggable draggableId={draggableId} index={index}>
      {(taskDrag) => (
        <div className={classes.sortEntry} {...taskDrag.draggableProps} ref={taskDrag.innerRef}>
          <div {...taskDrag.dragHandleProps} className={classes.dragHandleBars}>
            <HandleBarsIcon />
          </div>
          <Select
            id="select-sortable-field"
            className={classes.field}
            value={sortEntry.sortField || ''}
            displayEmpty
            variant="outlined"
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              dispatch(
                actions.sorts.update.field.action({
                  newField: e.target.value,
                  oldField: sortEntry.sortField,
                })
              )
            }}
          >
            <MenuItem value="">
              <HbText color="disabled">Sort field</HbText>
            </MenuItem>
            {sortableColumns.map((c) => (
              <MenuItem key={c.apiName} value={c.apiName}>
                {c.title}
              </MenuItem>
            ))}
          </Select>
          <Select
            id="select-sort-direction"
            className={classes.direction}
            variant="outlined"
            value={sortEntry.sortDir}
            displayEmpty
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              e.stopPropagation()
              dispatch(
                actions.sorts.update.direction.action({
                  direction: e.target.value as SortEnum, // Cast since MUI types can't narrow this down from a string
                  field: sortEntry.sortField,
                })
              )
            }}
          >
            <MenuItem value="">
              <HbText color="disabled">Sort direction</HbText>
            </MenuItem>
            <MenuItem value={SortEnum.Asc}>Ascending</MenuItem>
            <MenuItem value={SortEnum.Desc}>Descending</MenuItem>
          </Select>
          <DeleteButton
            disabled={isEqual(allSorts, defaultSorts)}
            onClick={(e) => {
              e.stopPropagation()
              if (allSorts.length <= 1) {
                dispatch(actions.sorts.reset.action())
              } else {
                dispatch(actions.sorts.remove.action(sortEntry.sortField))
              }
            }}
          />
        </div>
      )}
    </Draggable>
  )
}

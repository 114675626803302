import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import { InternalLink } from 'components/library/InternalLink'

const EmptyContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: theme.shape.borderRadius,
}))

export const EmptyCustomFieldsMessage = () => (
  <EmptyContainer>
    <HbText color="disabled">
      Custom fields can be managed in <InternalLink title="settings" to="/settings/organization-settings/other-info" />
    </HbText>
  </EmptyContainer>
)

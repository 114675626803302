import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const CancelFilterListIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M4.9704 1.71158L3.41479 2.8025L5.65716 6.00001H3V8.00001H7.05973L9.16359 11H6V13H10.5662L12.67 16H10V18H14V17.8965L16.0465 20.8147L17.6021 19.7238L4.9704 1.71158ZM13.7297 11L15.1323 13H18V11H13.7297ZM10.2233 6.00001L11.6258 8.00001H21V6.00001H10.2233Z" />
  </SvgIcon>
)

export default CancelFilterListIcon

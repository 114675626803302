import { useCallback, useRef, useState } from 'react'

import { AddRounded } from '@mui/icons-material'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { MenuList } from 'components/library/Menu'

import { useDashboardConfig } from '../react/dashboards.config'

interface Props {
  openNewDialog: () => void
  iconOnly: boolean
}

export default function DashboardAddMenu(props: Props) {
  const { openNewDialog, iconOnly } = props

  const { header } = useDashboardConfig()
  const { AddMenuItems, AddMenuButton } = header
  const menuTrigger = useRef<HTMLButtonElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)

  const setMenuEvent = (openValue: boolean) => {
    setMenuOpen(openValue)
  }

  const closeMenu = useCallback(() => {
    setMenuEvent(false)
  }, [])

  if (!AddMenuItems && !AddMenuButton) {
    return null
  }

  if (AddMenuButton) {
    return <AddMenuButton openNewDialog={openNewDialog} iconOnly={iconOnly} />
  }

  if (AddMenuItems) {
    return (
      <>
        <HbButton
          label="Add"
          variant="primary"
          size="small"
          onClick={() => setMenuEvent(!menuOpen)}
          ref={menuTrigger}
          Icon={AddRounded}
          iconOnly
        />
        <HbPopper id="dashboard-add-popper" isOpen={menuOpen} onClose={closeMenu} anchorEl={menuTrigger.current}>
          <MenuList onClick={closeMenu}>
            <AddMenuItems openNewDialog={openNewDialog} />
          </MenuList>
        </HbPopper>
      </>
    )
  }
  return null
}

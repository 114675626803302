import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const FileIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g id="content">
      <path d="M19.68,7.27l-4.31-4A1,1,0,0,0,14.69,3H5A1,1,0,0,0,4,4V20a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V8A1,1,0,0,0,19.68,7.27ZM6,19V5h7.69V8a1,1,0,0,0,1,1H18V19Z" />
    </g>
  </SvgIcon>
)

export default FileIcon

import { ReactNode } from 'react'

import { ApolloError } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'
import { PageLoader } from 'components/HbComponents/PageLoader'
import Loader from 'components/library/Loader'
import { SearchNoResults } from 'components/library/Search/SearchNoResults'
import { mergeOverrideStyles } from 'components/utils/styles'
import { useFeatureFlag } from 'hooks'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'

import { FeatureFlag } from 'types/api'

import { useDashboardConfig } from '../react/dashboards.config'

export interface TableContainerProps {
  loading: boolean
  error?: ApolloError
  errorMessage?: string
  isEmpty: boolean
  initialFetchDone: boolean
  onEmptyStateButtonClick?: () => void
  children: ReactNode
  footer?: ReactNode
  overrideClasses?: {
    Layout?: LayoutProps['classes']
    localLoader?: string
  }
  emptyStateButtonRef?: React.MutableRefObject<HTMLButtonElement | null>
}

interface LayoutProps {
  children: ReactNode
  classes?: Partial<ReturnType<typeof useStyles>>
  footer: ReactNode
}

const useStyles = makeStyles(() => ({
  layout: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    overflow: 'auto',
  },
}))

const Layout = ({ children, classes: overrideClasses, footer }: LayoutProps) => {
  const baseClasses = useStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)

  return (
    <div className={classes.layout}>
      {children}
      {footer}
    </div>
  )
}

export function TableContainer(props: TableContainerProps) {
  const dashboardsSelectors = useDashboardSelectors()
  const { emptyState } = useDashboardConfig()
  const {
    children,
    loading,
    error,
    errorMessage = 'If this continues to happen, please contact support@hummingbird.co for help.',
    isEmpty,
    initialFetchDone,
    onEmptyStateButtonClick,
    overrideClasses,
    footer,
    emptyStateButtonRef,
  } = props

  const dashboardSearchQuery = useSelector(dashboardsSelectors.query)
  const showingSearchResults = !!dashboardSearchQuery
  const customReviewsFilter = useSelector(dashboardsSelectors.filters.custom)
  const isDashboardSearchEnabled = useFeatureFlag(FeatureFlag.EnableDashboardSearch)

  const query = dashboardSearchQuery

  // If we're on a custom column filter, we don't want
  // to show the full-screen empty state since it prevents
  // the user from further interacting with the table columns.
  const customFilter = customReviewsFilter

  if (!initialFetchDone) {
    return <PageLoader />
  }

  if (showingSearchResults && !loading && isEmpty && !customFilter && !isDashboardSearchEnabled) {
    return <SearchNoResults variant="large" query={query ?? ''} />
  }

  return (
    <>
      {loading && <Loader className={overrideClasses?.localLoader} variant="local" />}
      {(!isEmpty || customFilter) && (
        <Layout classes={overrideClasses?.Layout} footer={footer}>
          {children}
        </Layout>
      )}
      {isEmpty &&
        !loading &&
        !customFilter &&
        (error ? (
          <HbNonIdealState title="Something went wrong." message={errorMessage} />
        ) : (
          <HbNonIdealState
            title={emptyState.title}
            message={emptyState.message}
            buttonLabel={emptyState.buttonText || ''}
            onButtonClick={onEmptyStateButtonClick}
            buttonRef={emptyStateButtonRef}
          />
        ))}
    </>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const TransactionIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M12 2.5a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm1.4 16v.7c0 .7-.6 1.3-1.3 1.3-.8 0-1.4-.6-1.4-1.3v-.6c-1.3-.3-2.5-1-3-2.3-.2-.5.2-1.1.8-1.1h.3c.3 0 .6.2.8.6.3.7 1 1.2 2.5 1.2 2 0 2.4-1 2.4-1.6 0-.8-.4-1.6-2.7-2-2.5-.7-4.2-1.7-4.2-3.8C7.6 8 9 6.8 10.8 6.4v-.6c0-.7.5-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3v.7c1.4.3 2.3 1.2 2.7 2.2a.8.8 0 0 1-.9 1.1H15a.8.8 0 0 1-.8-.6C14 8.5 13.4 8 12.1 8c-1.5 0-2.4.7-2.4 1.6 0 .8.6 1.4 2.7 2 2 .4 4.1 1.3 4.1 3.8 0 1.9-1.3 2.9-3 3.2Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default TransactionIcon

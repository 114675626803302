import * as React from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import ReactMarkdown, { Components } from 'react-markdown'

import { HbTheme } from 'components/themeRedesign'

const DefaultMarkdownLinkRenderer: Components['a'] = ({ children, ...props }) => (
  <a target="_blank" rel="noopener noreferrer" {...props}>
    {children}
  </a>
)

const useStyles = makeStyles<HbTheme>((theme) => ({
  root: {
    '& h1': {
      ...theme.typography.sizes.xxl,
    },
    '& h2': {
      ...theme.typography.sizes.xl,
    },
    '& h3': {
      ...theme.typography.sizes.lg,
    },
    '& h4': {
      ...theme.typography.sizes.md,
      fontWeight: theme.fontWeight.bolder,
    },
    '& h5': {
      ...theme.typography.sizes.s,
      fontWeight: theme.fontWeight.bold,
    },
    '& p': {
      color: 'inherit',
    },
    '& strong': {
      fontWeight: theme.fontWeight.bold,
    },
    '& em': {
      fontStyle: 'italic',
    },
    '& ul, & ol': {
      marginLeft: '1.75em',
      marginBottom: '1.4em', // Default paragraph style
      '& li': {
        margin: '0.5em 0',
        lineHeight: '1.5',
      },
    },
    '& ul': {
      listStyle: 'disc',
    },
    '& ol': {
      listStyle: 'decimal',
    },
    '& code': {
      fontFamily: 'monospace',
    },
  },
}))

export type MarkdownProps = React.ComponentPropsWithoutRef<typeof ReactMarkdown>

export const Markdown = ({ className, children, components, ...props }: MarkdownProps) => {
  const { root } = useStyles()

  return (
    <ReactMarkdown
      className={classnames(className, root)}
      components={{ a: DefaultMarkdownLinkRenderer, ...components }}
      {...props}
    >
      {children}
    </ReactMarkdown>
  )
}

export default Markdown

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const FileSpreadsheetIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path d="M18,18H0V0H18ZM1,17H17V1H1Z" />
    <path d="M3,4V14H15V4Zm4,6V8H9v2Zm2,1v2H7V11ZM6,10H4V8H6ZM7,7V5H9V7ZM6,5V7H4V5ZM4,11H6v2H4Zm10,2H10V11h4Zm0-3H10V8h4ZM10,7V5h4V7Z" />
  </SvgIcon>
)

export default FileSpreadsheetIcon

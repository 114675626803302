import { gql, useQuery } from '@apollo/client'

import { useSelector } from 'actions/store'
import { getCurrentOrganizationToken, getOrganizations, hasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum, Organization } from 'types/api'

import { isTruthy } from 'utils'

import {
  AdminAutomationRuleOrganizationsQuery,
  AdminAutomationRuleOrganizationsQueryVariables,
} from './__generated__/AutomationAdminHelpers.generated'

const GET_AUTOMATION_ADMIN_ORGANIZATIONS = gql`
  query AdminAutomationRuleOrganizations {
    adminAutomationRuleOrganizations {
      token
      name
    }
  }
`

export const useAutomationAdminAccountCheck = () => {
  return useSelector((state) => hasPermission(state, BadgePermissionsEnum.AdminManageAutomations))
}

export const useAutomationAdminOperations = (automationRuleOrgToken?: string | null) => {
  const currentOrgToken = useSelector(getCurrentOrganizationToken)
  return useAutomationAdminAccountCheck() && !!automationRuleOrgToken && currentOrgToken !== automationRuleOrgToken
}

export const useAutomationRuleOrganizationName = (ruleOrgToken: string) => {
  const organizations = useSelector(getOrganizations)
  const org = organizations.find((o) => o.token === ruleOrgToken)

  return org?.name ?? 'this organization'
}

export const useGetAutomationAdminOrganizations = () => {
  const { data, error } = useQuery<
    AdminAutomationRuleOrganizationsQuery,
    AdminAutomationRuleOrganizationsQueryVariables
  >(GET_AUTOMATION_ADMIN_ORGANIZATIONS, { fetchPolicy: 'cache-and-network' })

  if (!data || error) return []

  return data.adminAutomationRuleOrganizations.filter((org): org is Organization => isTruthy(org))
}

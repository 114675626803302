import { styled } from '@mui/material'

import { StatusWithTooltip } from 'components/cases/reviews/Status'
import { ProgressContainer, ReviewWorkflowProgress } from 'components/cases/reviews/WorkflowProgress'
import { AssignmentDetails } from 'components/cases/reviews/assignment/AssignmentDetails'
import { ReviewStatesEnum } from 'types/api'

import { CardReview } from './ReviewsCard'

interface StatusDetailsProps {
  review: CardReview
}

const StatusDetailsContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  display: 'flex',
  alignItems: 'center',
  flexFlow: 'row wrap',
  columnGap: theme.spacing(),
  rowGap: theme.spacing(1.25),
  width: '100%',
  paddingBottom: theme.spacing(0.5),

  [`& ${ProgressContainer}`]: {
    maxWidth: 100,
  },
}))

export const StatusDetails = ({ review }: StatusDetailsProps) => (
  <StatusDetailsContainer>
    <StatusWithTooltip review={review} />
    <AssignmentDetails
      assignee={review.assignee}
      approvalAssignments={review.approvalAssignments}
      status={review.status}
      queue={review.queue}
    />
    {review.state !== ReviewStatesEnum.Cancelled && <ReviewWorkflowProgress reviewToken={review.token} />}
  </StatusDetailsContainer>
)

import favicon128 from '../images/favicon-128.png'
import favicon16 from '../images/favicon-16x16.png'
import favicon196 from '../images/favicon-196x196.png'
import favicon32 from '../images/favicon-32x32.png'
import favicon96 from '../images/favicon-96x96.png'
import faviconDark128 from '../images/favicon-dark-128.png'
import faviconDark16 from '../images/favicon-dark-16x16.png'
import faviconDark196 from '../images/favicon-dark-196x196.png'
import faviconDark32 from '../images/favicon-dark-32x32.png'
import faviconDark96 from '../images/favicon-dark-96x96.png'

const iconPairs: Array<[string, string]> = [
  [favicon128, faviconDark128],
  [favicon16, faviconDark16],
  [favicon196, faviconDark196],
  [favicon32, faviconDark32],
  [favicon96, faviconDark96],
]

function updateFavicons({ matches }: MediaQueryList | MediaQueryListEvent) {
  iconPairs.forEach(([light, dark]) => {
    // $= probably works as well, but *= seems safer
    const link = document.querySelector(`link[href*="${matches ? light : dark}"]`)
    if (link) {
      link.setAttribute('href', matches ? dark : light)
    }
  })
}

const query = window.matchMedia('(prefers-color-scheme: dark)')
updateFavicons(query)
query.addEventListener('change', updateFavicons)

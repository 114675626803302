import React from 'react'

import { MenuItem, Select, styled } from '@mui/material'

import { isOtherInfoColumn } from 'dashboards/shared/react/isOtherInfoColumn'

import { useFeatureFlag } from 'hooks'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { FeatureFlag, OperatorEnum } from 'types/api'

import { fieldSupportsContainsOperator } from './helpers'

import { MenuItemProps } from '.'

const baseOperators: MenuItemProps[] = [{ value: OperatorEnum.Is, title: 'Is' }]

// Only include on fields explicitly allowed.
// This is necessary because the default select logic for "Is" handles transforming display to value under the hood, which user typing comma separated values will not.

const inOperator = { value: OperatorEnum.In, title: 'Is One Of' }
const notInOperator = { value: OperatorEnum.NotIn, title: 'Is Not One Of' }
const multiSelectOperators = [inOperator, notInOperator]
export const apiNamesSupportingLegacyInOperatorUX = new Set(['alertExternalIds', 'internalControlNumber'])
export const apiNamesSupportingAutocompleteInOperatorUX = new Set(['reviewStatus'])

const supportsMultiSelectOperators = (apiName: string) => {
  return apiNamesSupportingAutocompleteInOperatorUX.has(apiName) || apiNamesSupportingLegacyInOperatorUX.has(apiName)
}

// Only include on non-relation fields until special case logic on BE implemented
const notOperator = { value: OperatorEnum.Not, title: 'Is Not' }

// If the title is a column type is a string, it _may_ be filterable by contains iff the field is not an enum
const containsOperator: MenuItemProps[] = [{ value: OperatorEnum.Contains, title: 'Contains' }]

// We have 3 buckets for `Contains`: can definitely support, can maybe support, and cannot support
// Everything else goes into the `maybe` bucket; we enable `Contains` for them just in case there are pre-existing
// clients that are using this operator for these fields (somehow).
const numericRangeOperators: MenuItemProps[] = [
  { value: OperatorEnum.Gt, title: 'Greater than' },
  { value: OperatorEnum.Gte, title: 'Greater than or equal to' },
  { value: OperatorEnum.Lt, title: 'Less than' },
  { value: OperatorEnum.Lte, title: 'Less than or equal to' },
]

const dateRangeOperators: MenuItemProps[] = [
  { value: OperatorEnum.Gt, title: 'Is after' },
  { value: OperatorEnum.Gte, title: 'Is after or on' },
  { value: OperatorEnum.Lt, title: 'Is before' },
  { value: OperatorEnum.Lte, title: 'Is before or on' },
]

const arrayFields: { [key: string]: boolean } = {
  tags: true,
  triggers: true,
  approvers: true,
}

const StyledSelect = styled(Select)(() => ({
  height: 40,
}))

function OperatorSelect({
  operator,
  apiName,
  onChange,
  filterableColumns,
}: {
  operator?: OperatorEnum
  apiName: string
  onChange: (value: string) => void
  filterableColumns: DashboardColumn[]
}) {
  const isOtherInfoContainsEnabled = useFeatureFlag(FeatureFlag.EnableOtherInfoContainsFiltering)
  const columnType = filterableColumns.find(({ apiName: columnApiName }) => columnApiName === apiName)?.type

  const operators = [...baseOperators]

  if (supportsMultiSelectOperators(apiName)) {
    operators.push(...multiSelectOperators)
  }

  operators.push(notOperator)

  if (!isOtherInfoColumn(apiName) || isOtherInfoContainsEnabled) {
    if (columnType === 'string' && fieldSupportsContainsOperator(apiName)) operators.push(...containsOperator)
  }

  if (!isOtherInfoColumn(apiName)) {
    if (columnType === 'number') operators.push(...numericRangeOperators)
    if (columnType === 'date') operators.push(...dateRangeOperators)
  }

  if (isOtherInfoColumn(apiName)) {
    operators.push(...numericRangeOperators)
  }

  const operatorTitle = (op: MenuItemProps) => {
    if (arrayFields[apiName] && op.value === OperatorEnum.Is) {
      return 'Includes'
    }
    if (arrayFields[apiName] && op.value === OperatorEnum.Not) {
      return 'Does not include'
    }
    return op.title
  }

  return (
    <StyledSelect
      id="operator-select"
      variant="outlined"
      value={operator}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e.target.value)}
      data-testid={`filter:operator:${operator || '__blank__'}`}
    >
      {operators.map((op) => (
        <MenuItem key={op.value} value={op.value}>
          {operatorTitle(op)}
        </MenuItem>
      ))}
    </StyledSelect>
  )
}

export default OperatorSelect

import React, { ReactNode } from 'react'

import { OpenInNewRounded } from '@mui/icons-material'
import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbText } from 'components/HbComponents/Text/HbText'
import { Theme } from 'types/hb'

export const rootFlexBasis = 214.5
export const rootDimensions = {
  maxWidth: 333,
  height: 240,
  flex: `1 1 214.5px`,
}

const SubtitleContentContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(0.5),
  columnGap: theme.spacing(0.75),
  flexFlow: 'row nowrap',
  alignItems: 'center',
}))

export const StyledSubtitleText = styled(HbText)(() => ({
  textTransform: 'uppercase',
  fontWeight: 600,
  minWidth: 0,
  flex: 1,
}))

const StyledSubtitleIcon = styled('svg')(({ theme }) => ({
  width: 14,
  height: 14,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}))

type SubtitleIconType = React.ComponentType<{ className?: string }>

interface SubtitleContentProps {
  className?: string
  Icon?: SubtitleIconType
  subtitle: string
}

export const SubtitleContent = ({ className, Icon, subtitle }: SubtitleContentProps) => (
  <SubtitleContentContainer className={className}>
    {Icon && <StyledSubtitleIcon as={Icon} />}
    <StyledSubtitleText color="secondary" size="s">
      {subtitle}
    </StyledSubtitleText>
  </SubtitleContentContainer>
)

const StyledCardTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
}))

export const StyledCardTitleText = styled(HbText)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.text.primary,
  fontWeight: theme.fontWeight.bolder,
  paddingRight: theme.spacing(2),
  '& svg': {
    paddingLeft: theme.spacing(0.5),
    height: theme.spacing(2),
    color: theme.palette.text.secondary,
  },
}))

const StyledCardTitleDescription = styled(HbText)(({ theme }) => ({
  marginTop: theme.spacing(0.5),
  height: 15,
  whiteSpace: 'pre',
}))

export function CardTitle({
  className,
  title,
  titleIcon,
  description,
  subtitle,
  SubtitleIcon,
}: {
  className?: string
  title: string
  titleIcon?: ReactNode
  description?: string
  subtitle: ReactNode
  SubtitleIcon?: SubtitleIconType
}) {
  return (
    <StyledCardTitleContainer className={className}>
      {typeof subtitle === 'string' ? <SubtitleContent subtitle={subtitle} Icon={SubtitleIcon} /> : subtitle}
      <StyledCardTitleText noWrap>
        {title} {titleIcon}
      </StyledCardTitleText>
      {description && (
        <StyledCardTitleDescription size="s" noWrap>
          {description}
        </StyledCardTitleDescription>
      )}
    </StyledCardTitleContainer>
  )
}

const StyledOpenIconContainer = styled('div')(({ theme }) => ({
  display: 'none',
  position: 'absolute',
  top: -1, // Blend with border of parent
  right: -1,

  placeItems: 'center',

  width: 36,
  height: 36,
  color: theme.palette.background.contrastDark,
  background: theme.palette.background.contrastLight,
  border: `1px solid ${theme.palette.dividers.medium}`,
  borderBottomLeftRadius: theme.shape.borderRadius,
  borderTopRightRadius: theme.shape.borderRadius,

  '& svg': {
    width: 18,
    height: 18,
  },
}))

const StyledOverviewCardContainer = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  display: 'flex',
  ...rootDimensions,
  flexDirection: 'column',
  cursor: 'pointer',

  padding: theme.hbUnit() + 1, // Account for thickened border when hovered
  margin: theme.spacing(2, 1),

  border: `1px solid ${theme.palette.dividers.light}`,
  borderRadius: theme.shape.borderRadius,

  '&:hover': {
    border: `2px solid ${theme.palette.action.active}`,
    padding: theme.spacing(),

    [`& ${StyledOpenIconContainer}`]: {
      display: 'grid',
      color: theme.palette.text.white,
      background: theme.palette.action.active,
      border: 'none',
    },
  },
}))

export const StyledOverviewCardContentContainer = styled('div')(() => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
}))

export interface Props {
  className?: string
  size?: 'small' | 'medium'
  testId?: string
  title?: string
  subtitle: ReactNode
  description?: string
  onClick: (e?: React.MouseEvent) => void
  Icon?: SubtitleIconType
  children?: ReactNode
}

export function OverviewCard(props: Props) {
  const { className, Icon, testId, title = '', subtitle = '', description, onClick, children } = props

  return (
    <StyledOverviewCardContainer
      role="button"
      tabIndex={0}
      onClick={onClick}
      data-testid={`case_card_${testId || subtitle}`}
      className={className}
    >
      <CardTitle title={title} description={description} subtitle={subtitle} SubtitleIcon={Icon} />

      <StyledOpenIconContainer>
        <OpenInNewRounded />
      </StyledOpenIconContainer>

      <StyledOverviewCardContentContainer>{children}</StyledOverviewCardContentContainer>
    </StyledOverviewCardContainer>
  )
}

const useDataCountStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)} ${theme.spacing(1.5)}`,
    marginTop: theme.spacing(),

    background: theme.palette.background.contrastLight,
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.dividers.light}`,
  },
  name: { flex: 1 },
}))

export function CardDataCount({
  classes: overrideClasses,
  name,
  count,
}: {
  classes?: { root?: string; count?: string }
  name: string
  count: number
}) {
  const classes = useDataCountStyles()
  return (
    <div className={classNames(classes.root, overrideClasses?.root)}>
      <HbText className={classes.name} color="secondary" noWrap>
        {name}
      </HbText>
      <HbText className={overrideClasses?.count} color="primary" bold>
        {count}
      </HbText>
    </div>
  )
}

import {
  type ComponentProps,
  createContext,
  useMemo,
  type ComponentType,
  ReactNode,
  useContext,
  useState,
  useEffect,
} from 'react'

import { ChevronLeft } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { NavLink, Link } from 'react-router-dom'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Theme } from 'types/hb'

const useStyles = makeStyles<Theme, { hasIcon: boolean; hasFooter: boolean }>((theme) => ({
  root: {
    flexGrow: 1,
    display: 'flex',
  },
  container: ({ hasFooter }) => ({
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
    backgroundColor: theme.palette.background.light,
    borderRadius: 10,
    margin: theme.spacing(2),
    padding: hasFooter ? theme.spacing(3, 3, 0, 3) : theme.spacing(3),
    position: 'relative',
    // Contain shadow of footer
    // Not `overflow: hidden;` so that position sticky can work for the footer
    contain: 'paint',
  }),
  containerWithFooter: {
    paddingBottom: theme.spacing(11),
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  headerLeft: ({ hasIcon }) => ({
    gap: hasIcon ? undefined : theme.spacing(2),
  }),
  footer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    boxShadow: '0px -4px 16px rgba(0, 0, 0, 0.1)',
    background: theme.palette.background.light,
    // Sticky Positioning
    position: 'sticky',
    bottom: 0, // Stick to the bottom of the parent element
    width: `calc(100% + ${theme.spacing(6)})`, // Compensate for padding of parent element
    minHeight: theme.spacing(9), // Footer shrinks without minHeight
    marginLeft: theme.spacing(-3), // Move to footer into the padding of the parent element
    marginTop: theme.spacing(2), // Space out footer from elements above
  },
  footerStart: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginLeft: theme.spacing(3),
    gap: theme.spacing(2),
  },
  footerEnd: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginRight: theme.spacing(3),
    gap: theme.spacing(2),
  },
  backTo: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textDecoration: 'none',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(3),
    '&:active': {
      color: 'inherit',
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  icon: {
    width: theme.spacing(7),
    height: theme.spacing(7),
    borderRadius: '50%',
    backgroundColor: theme.palette.background.medium,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  main: {
    height: '100%',
    marginTop: theme.spacing(1),
  },
}))

const useNavStyles = makeStyles<Theme>((theme) => ({
  tabs: {
    margin: theme.spacing(0, -3),
    padding: theme.spacing(0, 3),
    borderBottom: `1px solid ${theme.palette.dividers.light}`,
    display: 'flex',
    gap: theme.spacing(3),
  },
  tabLink: {
    display: 'inline-block',
    textDecoration: 'none',
    padding: theme.spacing(1.25),
    fontWeight: 500,
    color: theme.palette.styleguide.textGreyLight,
    position: 'relative',
    '&[aria-current="page"]': {
      color: theme.palette.accent,
      '&:after': {
        display: 'block',
        content: '""',
        height: '3px',
        width: '100%',
        position: 'absolute',
        left: 0,
        bottom: '-1px',
        backgroundColor: theme.palette.accent,
      },
    },
  },
}))

export type Tab = { title: string; href: string }

const PageWithTabsContext = createContext<{
  pushTitleOverride: (title: ReactNode) => void
  popTitleOverride: () => void
  pushTabsOverride: (tabs: Array<Tab>) => void
  popTabsOverride: () => void
  pushActionsOverride: (tabs: Array<ComponentProps<typeof HbButton>>) => void
  popActionsOverride: () => void
  pushFooterStartActionsOverride: (tabs: Array<ComponentProps<typeof HbButton>>) => void
  popFooterStartActionsOverride: () => void
  pushFooterEndActionsOverride: (tabs: Array<ComponentProps<typeof HbButton>>) => void
  popFooterEndActionsOverride: () => void
  pushFooterEndElementsOverride: (tabs: Array<ReactNode>) => void
  popFooterEndElementsOverride: () => void
  pushIconOverride: (node: ComponentType<{ className?: string }> | null) => void
  popIconOverride: () => void
  pushBackToOverride: (backTo: Tab) => void
  popBackToOverride: () => void
} | null>(null)

export function OverridePageTitle({ title }: { title: ReactNode }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushTitleOverride(title)
    return ctx?.popTitleOverride
  }, [ctx, title])

  return null
}

export function OverridePageTabs({ tabs }: { tabs: Array<Tab> }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushTabsOverride(tabs)
    return ctx?.popTabsOverride
  }, [ctx, tabs])

  return null
}

export function OverridePageActions({ actions }: { actions: Array<ComponentProps<typeof HbButton>> }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushActionsOverride(actions)
    return ctx?.popActionsOverride
  }, [ctx, actions])

  return null
}

export function OverrideFooterStartActions({ actions }: { actions: Array<ComponentProps<typeof HbButton>> }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushFooterStartActionsOverride(actions)
    return ctx?.popFooterStartActionsOverride
  }, [ctx, actions])

  return null
}

export function OverrideFooterEndActions({ actions }: { actions: Array<ComponentProps<typeof HbButton>> }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushFooterEndActionsOverride(actions)
    return ctx?.popFooterEndActionsOverride
  }, [ctx, actions])

  return null
}

export function OverrideFooterEndElements({ elements }: { elements: Array<ReactNode> }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushFooterEndElementsOverride(elements)
    return ctx?.popFooterEndElementsOverride
  }, [ctx, elements])

  return null
}

export function OverridePageIcon({ Icon = null }: { Icon?: ComponentType<{ className?: string }> | null }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushIconOverride(Icon)
    return ctx?.popIconOverride
  }, [ctx, Icon])

  return null
}

export function OverrideBackTo({ backTo }: { backTo: Tab }) {
  const ctx = useContext(PageWithTabsContext)

  useEffect(() => {
    ctx?.pushBackToOverride(backTo)
    return ctx?.popBackToOverride
  }, [ctx, backTo])

  return null
}

export function TabsNav({
  tabs,
  className: classNameProp,
  onClick,
}: {
  tabs: Array<Tab>
  className?: string
  onClick?: (tab: { title: string }) => void
}) {
  const styles = useNavStyles({ hasIcon: false, hasFooter: false })
  const className = classnames(styles.tabs, classNameProp)

  return (
    <nav>
      <ul className={className}>
        {tabs.map((tab) => (
          <li key={tab.href}>
            <NavLink exact to={tab.href} className={styles.tabLink} onClick={() => onClick && onClick(tab)}>
              {tab.title}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  )
}

export function PageWithTabs({
  Icon,
  title,
  tabs,
  actions,
  footerStartActions,
  footerEndActions,
  footerEndElements,
  backTo,
  children,
}: {
  Icon?: ComponentType<{ className?: string }>
  title: ReactNode
  tabs: Array<Tab>
  actions?: Array<ComponentProps<typeof HbButton>>
  footerStartActions?: Array<ComponentProps<typeof HbButton>>
  footerEndActions?: Array<ComponentProps<typeof HbButton>>
  footerEndElements?: Array<ReactNode>
  backTo?: Tab
  children: ReactNode
}) {
  const [titleOverrides, setTitleOverrides] = useState<Array<ReactNode>>([])
  const [tabOverrides, setTabOverrides] = useState<Array<Array<Tab>>>([])
  const [actionOverrides, setActionOverrides] = useState<Array<Array<ComponentProps<typeof HbButton>>>>([])
  const [footerStartActionOverrides, setFooterStartActionOverrides] = useState<
    Array<Array<ComponentProps<typeof HbButton>>>
  >([])
  const [footerEndActionOverrides, setFooterEndActionOverrides] = useState<
    Array<Array<ComponentProps<typeof HbButton>>>
  >([])
  const [footerEndElementOverrides, setFooterEndElementOverrides] = useState<Array<Array<ReactNode>>>([])
  const [iconOverrides, setIconOverrides] = useState<Array<ComponentType | null>>([])
  const [backToOverrides, setBackToOverrides] = useState<Array<Tab>>([])

  const ctx = useMemo(
    () => ({
      pushTitleOverride: (inTitle: ReactNode) => {
        setTitleOverrides((current) => [...current, inTitle])
      },
      popTitleOverride: () => {
        setTitleOverrides((current) => current.slice(0, -1))
      },
      pushTabsOverride: (inTabs: Array<Tab>) => {
        setTabOverrides((current) => [...current, inTabs])
      },
      popTabsOverride: () => {
        setTabOverrides((current) => current.slice(0, -1))
      },
      pushActionsOverride: (inActions: Array<ComponentProps<typeof HbButton>>) => {
        setActionOverrides((current) => [...current, inActions])
      },
      popActionsOverride: () => {
        setActionOverrides((current) => current.slice(0, -1))
      },
      pushFooterStartActionsOverride: (inActions: Array<ComponentProps<typeof HbButton>>) => {
        setFooterStartActionOverrides((current) => [...current, inActions])
      },
      popFooterStartActionsOverride: () => {
        setFooterStartActionOverrides((current) => current.slice(0, -1))
      },
      pushFooterEndActionsOverride: (inActions: Array<ComponentProps<typeof HbButton>>) => {
        setFooterEndActionOverrides((current) => [...current, inActions])
      },
      popFooterEndActionsOverride: () => {
        setFooterEndActionOverrides((current) => current.slice(0, -1))
      },
      pushFooterEndElementsOverride: (inElements: Array<ComponentProps<typeof HbButton>>) => {
        setFooterEndElementOverrides((current) => [...current, inElements])
      },
      popFooterEndElementsOverride: () => {
        setFooterEndElementOverrides((current) => current.slice(0, -1))
      },
      pushIconOverride: (node: ComponentType | null) => {
        setIconOverrides((current) => [...current, node])
      },
      popIconOverride: () => {
        setIconOverrides((current) => current.slice(0, -1))
      },
      pushBackToOverride: (tab: Tab) => {
        setBackToOverrides((current) => [...current, tab])
      },
      popBackToOverride: () => {
        setBackToOverrides((current) => current.slice(0, -1))
      },
    }),
    []
  )

  const IconOverride = iconOverrides.at(-1)
  const actualActions = actionOverrides.at(-1) || actions
  const actualFooterStartActions = footerStartActionOverrides.at(-1) || footerStartActions
  const actualFooterEndActions = footerEndActionOverrides.at(-1) || footerEndActions
  const actualFooterEndElements = footerEndElementOverrides.at(-1) || footerEndElements
  const actualBackTo = backToOverrides.at(-1) || backTo

  const hasIcon = IconOverride !== null || (IconOverride === undefined && !!Icon)
  const hasFooter =
    (actualFooterStartActions?.length ?? 0) > 0 ||
    (actualFooterEndActions?.length ?? 0) > 0 ||
    (actualFooterEndElements?.length ?? 0) > 0
  const styles = useStyles({ hasIcon, hasFooter })

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <header className={styles.header}>
          <div className={styles.headerLeft}>
            {actualBackTo ? (
              <Link to={actualBackTo.href} className={styles.backTo}>
                <ChevronLeft /> <HbText bold>{actualBackTo.title}</HbText>
              </Link>
            ) : null}
            <h1 className={styles.title}>
              {hasIcon ? (
                <div className={styles.icon} role="presentation">
                  {IconOverride ? <IconOverride /> : Icon ? <Icon /> : null}
                </div>
              ) : null}
              <HbText size="xl" bold>
                {titleOverrides.at(-1) ?? title}
              </HbText>
            </h1>
          </div>
          {(actualActions?.length ?? 0) > 0 ? (
            <aside>
              {actualActions?.map((action, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <HbButton {...action} key={i} />
              ))}
            </aside>
          ) : null}
        </header>

        <TabsNav tabs={tabOverrides.at(-1) ?? tabs} />

        <main className={styles.main}>
          <PageWithTabsContext.Provider value={ctx}>{children}</PageWithTabsContext.Provider>
        </main>
        {(actualFooterStartActions?.length ?? 0) > 0 || (actualFooterEndActions?.length ?? 0) > 0 || hasFooter ? (
          <footer className={styles.footer}>
            {(actualFooterStartActions?.length ?? 0) > 0 ? (
              <section className={styles.footerStart}>
                {actualFooterStartActions?.map((action, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <HbButton {...action} key={i} />
                ))}
              </section>
            ) : null}
            {(actualFooterStartActions?.length ?? 0) > 0 || (actualFooterEndActions?.length ?? 0) > 0 ? (
              <section className={styles.footerEnd}>
                {actualFooterEndElements}
                {actualFooterEndActions?.map((action, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <HbButton {...action} key={i} />
                ))}
              </section>
            ) : null}
          </footer>
        ) : null}
      </div>
    </div>
  )
}

import { gql } from '@apollo/client'

// TODO(anya) We might want some "it does not n+1" type of specs on the grapqhl query that this
// generates - it might be more expensive than anticipated.
export const SearchFilingsResultFragment = gql`
  fragment SearchFilingsResultFragment on SearchFilingsResult {
    entries {
      createdAt
      currentStateString
      filingInstitution {
        name
      }
      subdivisions
      response {
        bsaId
      }
      token
      investigationReview {
        activityEndedOnFromAlgorithm
        activityStartedOnFromAlgorithm
        filingType
        filingName
        amountInvolvedFromAlgorithmString
        jurisdiction
        internalControlNumber
        filingStatusString
        token
        investigation {
          token
        }
        sarFilingDecision {
          arbiter {
            fullName
          }
        }
        assignee {
          fullName
        }
      }
      updatedAt
    }
    page
    pageSize
    pageCount
    totalCount
  }
`

import { useCallback, useEffect, useRef } from 'react'

import { useLazyQuery } from '@apollo/client'

import { useDispatch, useSelector } from 'actions/store'
import { useLongProcessSnackbars } from 'components/HbComponents/HbNotistack/HbNotistackSnackbar.hooks'
import { removeCurrentSearch as _removeCurrentSearch } from 'reducers/MiddeskReducer'
import { openTab } from 'reducers/tabReducer'
import { JobStatus, MiddeskBusinessStatus } from 'types/api'
import { navigateToInternalUrl } from 'utils/navigationHelpers'
import { assertExhaustive } from 'utils/typeAssertions'

import { DEFAULT_REQUEST_ERROR_MSG, POLL_INTERVAL } from './Middesk.constants'

import { useUpdateUIState } from './Middesk.hooks'
import { MIDDESK_BUSINESS_JOB_QUERY } from './Middesk.queries'
import { MiddeskBusinessResult, MiddeskCurrentSearch } from './Middesk.types'
import { MiddeskBusinessJobQuery, MiddeskBusinessJobQueryVariables } from './__generated__/Middesk.queries.generated'

const useNavigateToCaseWithMiddeskTab = () => {
  const dispatch = useDispatch()
  return useCallback(
    async ({ libraryBusiness, caseToken }: MiddeskCurrentSearch) => {
      const businessEntityToken = libraryBusiness.token
      const tab = {
        type: 'middesk' as const,
        businessEntityToken,
      }
      if (window.location.pathname !== `/dashboard/cases/${caseToken}`) {
        // If not on the case that the search originated from, navigate to the case first
        await dispatch(navigateToInternalUrl(undefined, '/dashboard/cases/:caseToken', { caseToken }))
      }
      dispatch(openTab({ tab }))
    },
    [dispatch]
  )
}

const MiddeskSnackbar = (props: { currentSearch: MiddeskCurrentSearch }) => {
  const { currentSearch } = props

  const dispatch = useDispatch()
  const updateUIState = useUpdateUIState()
  const updateLongProcessSnackbars = useLongProcessSnackbars()
  const navigateToCaseWithMiddeskTab = useNavigateToCaseWithMiddeskTab()
  const [pollJob, { stopPolling }] = useLazyQuery<MiddeskBusinessJobQuery, MiddeskBusinessJobQueryVariables>(
    MIDDESK_BUSINESS_JOB_QUERY,
    {
      pollInterval: POLL_INTERVAL,
      fetchPolicy: 'network-only',
    }
  )

  const hasStartedSearch = useRef<boolean>(false)

  const removeCurrentSearch = useCallback(() => {
    dispatch(_removeCurrentSearch({ jobToken: currentSearch.jobToken }))
  }, [dispatch, currentSearch.jobToken])

  const handleLoading = useCallback(
    (snackbarID: string) => {
      const snackbarKey = updateLongProcessSnackbars(snackbarID, {
        status: 'loading',
        message: `Loading Middesk results for ${currentSearch.libraryBusiness.displayName}`,
      })
      updateUIState(currentSearch.libraryBusiness.token, {
        type: 'loading',
        jobToken: currentSearch.jobToken,
        snackbarKey,
      })
    },
    [
      currentSearch.jobToken,
      currentSearch.libraryBusiness.displayName,
      currentSearch.libraryBusiness.token,
      updateLongProcessSnackbars,
      updateUIState,
    ]
  )

  const handleError = useCallback(
    (message: string, snackbarID: string) => {
      removeCurrentSearch()
      updateLongProcessSnackbars(snackbarID, {
        status: 'error',
        message,
      })
    },
    [removeCurrentSearch, updateLongProcessSnackbars]
  )

  const handleSuccess = useCallback(
    (result: MiddeskBusinessResult, snackbarID: string) => {
      removeCurrentSearch()
      updateUIState(currentSearch.libraryBusiness.token, {
        type: 'processed',
        result,
      })
      updateLongProcessSnackbars(snackbarID, {
        status: 'success',
        message: `Middesk results for ${currentSearch.libraryBusiness.displayName} are ready`,
        buttonProps: {
          text: 'View results',
          onClick: () => {
            navigateToCaseWithMiddeskTab(currentSearch)
          },
        },
      })
    },
    [currentSearch, navigateToCaseWithMiddeskTab, removeCurrentSearch, updateLongProcessSnackbars, updateUIState]
  )

  useEffect(() => stopPolling, [stopPolling])

  useEffect(() => {
    const { libraryBusiness } = currentSearch
    const snackbarID = `${libraryBusiness.token}:${currentSearch.jobToken}`

    if (hasStartedSearch.current) {
      return
    }

    handleLoading(snackbarID)

    pollJob({
      variables: { token: currentSearch.jobToken },
      onError: (error) => {
        handleError(error.message || DEFAULT_REQUEST_ERROR_MSG, snackbarID)
      },
      onCompleted: ({ middeskBusinessJob }) => {
        const status = middeskBusinessJob?.status
        switch (status) {
          case JobStatus.Enqueued:
          case JobStatus.Started:
            break
          case JobStatus.Failed:
          case undefined: {
            handleError(middeskBusinessJob?.errorMessage || DEFAULT_REQUEST_ERROR_MSG, snackbarID)
            break
          }
          case JobStatus.Processed: {
            const result = middeskBusinessJob?.result
            if (
              result &&
              [MiddeskBusinessStatus.InReview, MiddeskBusinessStatus.Approved, MiddeskBusinessStatus.Rejected].includes(
                result.status
              )
            ) {
              handleSuccess(result, snackbarID)
            } else {
              handleError(middeskBusinessJob?.errorMessage || DEFAULT_REQUEST_ERROR_MSG, snackbarID)
            }
            break
          }
          default:
            assertExhaustive(status)
        }
      },
    })

    hasStartedSearch.current = true
  }, [currentSearch, handleError, handleLoading, handleSuccess, pollJob])

  // This component returns null and is just for running hooks, and so using
  // a hook instead seems prefereable. But A hook can't replace this because
  // we need to call `useLazyQuery` for each new Middesk search and hooks can't be called in loops.
  return null
}

export const MiddeskSnackbars = () => {
  const currentSearches = useSelector((state) => state.Middesk.currentSearches)
  return (
    <>
      {currentSearches.map((currentSearch) => (
        <MiddeskSnackbar key={currentSearch.jobToken} currentSearch={currentSearch} />
      ))}
    </>
  )
}

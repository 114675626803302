import { Description, Folder, InsertPhotoOutlined, PictureAsPdfOutlined, DataObject } from '@mui/icons-material'

import { SUPPORTED_ATTACHMENTS } from 'components/attachment/AttachmentViewerInternal.types'

import { CaseFile } from 'components/cases/Tabs/Files/caseFileTypes'
import { TableViewIcon } from 'icons/TableViewIcon'
import { XmlFileIcon } from 'icons/XmlFileIcon'
import { GeneratedDocumentType, ReviewDocumentFormat } from 'types/api'

export const FILE_DOWNLOADS_DISABLED_COPY =
  'You may still download select files, but not all files, due to the size of the case.'

export const filePickerOptions = { multiple: true }

export const newFormatIcon = (format: string) => {
  switch (format.toUpperCase()) {
    case ReviewDocumentFormat.Pdf:
      return PictureAsPdfOutlined
    case ReviewDocumentFormat.Csv:
      return TableViewIcon
    case ReviewDocumentFormat.Xml:
      return XmlFileIcon
    case ReviewDocumentFormat.Zip:
      return Folder
    case 'JPG':
    case 'JPEG':
    case 'PNG':
      return InsertPhotoOutlined
    case ReviewDocumentFormat.Json:
      return DataObject
    default:
      return Description
  }
}

export const formatDescription = (caseFile: CaseFile) => {
  switch (caseFile.type) {
    case 'Attachment':
    case 'RfiResponseFile':
    case 'InformationRequestResponse':
      return '' // we don't want to show the default description since it is redundant in the new table
    case 'SurveyResponseFileUpload':
    case 'ReviewDocument':
    default:
      return caseFile.description || 'Auto-generated on demand'
  }
}

export const isContentTypeViewable = (contentType: string) => {
  return SUPPORTED_ATTACHMENTS.includes(contentType)
}

export const VIEWABLE_TYPES = ['Attachment', 'SurveyResponseFileUpload', 'ReviewDocument']

const DISALLOWED_DOC_TYPES = [
  GeneratedDocumentType.CtrSummary,
  GeneratedDocumentType.FintracStrSummary,
  GeneratedDocumentType.FintracStrSummaryPreview,
  GeneratedDocumentType.SarPdf,
  GeneratedDocumentType.Summary,
  GeneratedDocumentType.TransactionsCsv,
  GeneratedDocumentType.TransactionsCsvAll,
  /*
    TODO: Support Survey file uploads (https://thecharm.atlassian.net/browse/PROD-17969)
    GeneratedDocumentType.SurveyFile
   */
]
export const isDocumentTypeUnavailableDueToCaseSize = (
  documentType: GeneratedDocumentType,
  downloadFilesDisabled: boolean | null
) => downloadFilesDisabled && DISALLOWED_DOC_TYPES.includes(documentType)

export const isFileUnavailableDueToCaseSize = (
  caseFile: Pick<CaseFile, 'type' | 'contentType' | 'reviewDocumentType'>,
  downloadFilesDisabled: boolean | null
) => isDocumentTypeUnavailableDueToCaseSize(caseFile.reviewDocumentType, downloadFilesDisabled)

export const isFileViewable = (
  caseFile: Pick<CaseFile, 'type' | 'contentType' | 'reviewDocumentType'>,
  downloadFilesDisabled: boolean | null
) => {
  if (isFileUnavailableDueToCaseSize(caseFile, downloadFilesDisabled)) {
    return false
  }
  if (VIEWABLE_TYPES.includes(caseFile.type || '')) {
    return isContentTypeViewable(caseFile.contentType || '')
  }

  return false
}

import { forwardRef, HTMLAttributes, ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(),
    minHeight: 24,
    '&:last-child': {
      marginBottom: theme.spacing(),
    },
  },
}))

interface Props extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode
}

const ReviewAspect = forwardRef<HTMLDivElement, Props>(({ children, className, ...restProps }, ref) => {
  const classes = useStyles()
  return (
    <div className={classNames(classes.root, className)} ref={ref} {...restProps}>
      {children}
    </div>
  )
})

export default ReviewAspect

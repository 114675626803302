import { CircularProgress } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
  },
}))

export const ButtonCircularProgress = () => {
  const styles = useStyles()
  return <CircularProgress className={styles.root} size="1.5rem" />
}

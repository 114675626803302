import { useCallback, useEffect, useState } from 'react'

import { StyledEngineProvider, ThemeProvider, useTheme } from '@mui/material'
import { ThemeProvider as StylesThemeProvider } from '@mui/styles'

import { render } from 'react-dom'

import { getMeasurableContainer } from 'helpers/uiHelpers'
import { Queue } from 'types/api'
import { Theme } from 'types/hb'

import { QueueTag } from './ResponsiveQueue'

export const MIN_RESPONSIVE_QUEUE_WIDTH = 28

const MeasurementQueueTag = ({ queue, theme }: { queue: Queue; theme: Theme }) => (
  <StyledEngineProvider injectFirst>
    {/* Two theme providers (one for @mui/material and one for @mui/styles),
      because there is some sort of communication issue between the two
      libraries and they're not using the same React context. */}
    <ThemeProvider theme={theme}>
      <StylesThemeProvider theme={theme}>
        <QueueTag queue={queue} />
      </StylesThemeProvider>
    </ThemeProvider>
  </StyledEngineProvider>
)

const renderTag = async ({ container, queue, theme }: { container: HTMLElement; queue: Queue; theme: Theme }) => {
  return new Promise((res) => {
    render(<MeasurementQueueTag queue={queue} theme={theme} />, container, () => res(null))
  })
}

/**
 * Track the queue tag's natural width without any constraints for where it's rendered,
 * so we can apply a flex-basis if needed.
 * Containers that use container queries cannot size themselves based on the
 * width of their content, which clashes with flex styling.
 */
export const useQueueNaturalWidth = ({ queue }: { queue?: Queue | null }) => {
  const theme = useTheme()

  const [naturalWidth, setNaturalWidth] = useState(MIN_RESPONSIVE_QUEUE_WIDTH)

  const measureQueueTag = useCallback(
    async (container: HTMLDivElement) => {
      if (!queue) return
      await renderTag({ container, queue, theme })
      const [tag] = [...container.children]
      const tagWidth = tag.clientWidth
      setNaturalWidth(tagWidth)
    },
    [queue, theme]
  )

  useEffect(() => {
    const measure = async () => {
      const { container, teardownMeasurableContainer } = getMeasurableContainer()
      await measureQueueTag(container)
      teardownMeasurableContainer(container)
    }
    measure()
  }, [measureQueueTag])

  const width = naturalWidth || MIN_RESPONSIVE_QUEUE_WIDTH

  return { naturalWidth, width }
}

import { Tooltip } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import { isEmpty } from 'lodash'

import { useFormattedDate } from 'hooks/DateFormatHooks'
import { Account, WithStyles, Theme } from 'types/hb'

const styles = (theme: Theme) => ({
  root: {
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
  },
})

interface Props extends WithStyles<typeof styles> {
  account: Account
}

function OutOfOfficeBadge(props: Props) {
  const { account, classes } = props

  const outOfOfficeUntil = useFormattedDate(account.outOfOfficeUntil, undefined, 'l LT')

  if (isEmpty(account.outOfOfficeUntil)) {
    return null
  }

  return (
    <span className={classes.root}>
      <Tooltip title={`Out of office until ${outOfOfficeUntil}`}>
        <span>(OOO)</span>
      </Tooltip>
    </span>
  )
}

export default withStyles(styles)(OutOfOfficeBadge)

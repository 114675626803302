import { gql } from '@apollo/client'

import { CUSTOM_FIELD_LABEL_ROW_FRAGMENT } from 'components/otherInfo/queries'
import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

const SearchCustomFieldsResultFragment = gql`
  fragment SearchCustomFieldsResultFragment on SearchCustomFieldsResult {
    entries {
      ...CustomFieldLabelRow
    }
    page
    pageSize
    pageCount
    totalCount
  }
  ${CUSTOM_FIELD_LABEL_ROW_FRAGMENT}
`

export const SEARCH_CUSTOM_FIELDS_QUERY = gql`
  query SearchCustomFields($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchCustomFields(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchCustomFieldsResultFragment
    }
  }
  ${SearchCustomFieldsResultFragment}
`

export const CUSTOM_FIELDS_COLUMNS_QUERY = gql`
  query CustomFieldsColumnsV2($includeCustomColumns: Boolean!) {
    searchCustomFieldsMetadata(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const CUSTOM_FIELDS_METADATA_QUERY = gql`
  query SearchCustomFieldsMetadata($columns: [String!], $query: String) {
    searchCustomFieldsMetadata(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

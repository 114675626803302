import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const DuplicateIndicatorIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 18 18">
    <path
      d="M18,1.58536585 C18,0.70979247 17.2902075,0 16.4146341,0 L6.58536585,0 C5.70979247,0 5,0.70979247 5,1.58536585 L5,11.4146341 C5,12.2902075 5.70979247,13 6.58536585,13 L16.4146341,13 C17.2902075,13 18,12.2902075 18,11.4146341 L18,1.58536585 Z M16.9430894,11.4146341 C16.9430894,11.7064919 16.7064919,11.9430894 16.4146341,11.9430894 L6.58536585,11.9430894 C6.29350806,11.9430894 6.05691057,11.7064919 6.05691057,11.4146341 L6.05691057,1.58536585 C6.05691057,1.29350806 6.29350806,1.05691057 6.58536585,1.05691057 L16.4146341,1.05691057 C16.7064919,1.05691057 16.9430894,1.29350806 16.9430894,1.58536585 L16.9430894,11.4146341 Z"
      id="Shape"
    />
    <path
      d="M1.58536585,6.05691057 L2.16666667,6.05691057 C2.45852446,6.05691057 2.69512195,5.82031308 2.69512195,5.52845528 C2.69512195,5.23659749 2.45852446,5 2.16666667,5 L1.58536585,5 C0.70979247,5 0,5.70979247 0,6.58536585 L0,16.4146341 C0,17.2902075 0.70979247,18 1.58536585,18 L11.4146341,18 C12.2902075,18 13,17.2902075 13,16.4146341 L13,15.7804878 C13,15.48863 12.7634025,15.2520325 12.4715447,15.2520325 C12.1796869,15.2520325 11.9430894,15.48863 11.9430894,15.7804878 L11.9430894,16.4146341 C11.9430894,16.7064919 11.7064919,16.9430894 11.4146341,16.9430894 L1.58536585,16.9430894 C1.29350806,16.9430894 1.05691057,16.7064919 1.05691057,16.4146341 L1.05691057,6.58536585 C1.05691057,6.29350806 1.29350806,6.05691057 1.58536585,6.05691057 Z"
      id="Shape"
    />
  </SvgIcon>
)

export default DuplicateIndicatorIcon

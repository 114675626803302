import { useEffect, useState } from 'react'

import { shallowEqual } from 'react-redux'

import { useDispatch } from 'actions/store'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { CombinatorEnum, OperatorEnum } from 'types/api'
import { isValidFilter, SearchFilterWithId } from 'utils/query/api.types'

import { DeleteButton } from '../sharedComponents'

import CombinatorSelect from './CombinatorSelect'
import FilterFieldSelect from './FilterFieldSelect'
import FilterValue from './FilterValue'
import OperatorSelect from './OperatorSelect'
import { determineInitialFilterOperator, processFieldForContains } from './helpers'

function FilterEntry({
  index,
  parentCombinator,
  onCombinatorChange,
  filter,
  parentID,
  deleteHandler,
}: {
  index: number
  parentCombinator: CombinatorEnum
  onCombinatorChange: (newCombo: CombinatorEnum) => void
  filter?: SearchFilterWithId
  parentID?: string
  deleteHandler: (id?: string) => void
}) {
  const dashboardsActions = useDashboardActions()
  const { filterableColumns } = useDashboardColumns()

  const dispatch = useDispatch()
  const [selectedField, setSelectedField] = useState<string>(filter?.field || '') // default to first entry

  const initialOperator = determineInitialFilterOperator(filter)
  const [selectedOp, setSelectedOp] = useState<OperatorEnum>(initialOperator)
  const [selectedFieldValues, setSelectedFieldValues] = useState<string[]>(filter?.predicate.values || [])
  const onFieldChange = (newField: string) => {
    if (newField !== selectedField) {
      // Reset filter and field values when selecting a new field
      setSelectedField(newField)
      setSelectedOp(OperatorEnum.Is)
      setSelectedFieldValues([])
    }
  }

  const onOperatorChange = (newOp: OperatorEnum) => {
    if (newOp !== selectedOp) setSelectedOp(newOp)
  }

  // Handles multiselect
  const onValueChange = (newValue: string | string[]) => {
    const newValueWrapper: string[] = []
    if (selectedOp === OperatorEnum.In && typeof newValue === 'string') {
      setSelectedFieldValues(
        newValue
          .split(',')
          .map((v) => v.trim())
          .filter((v) => !!v)
      )
    } else if (selectedOp === OperatorEnum.Contains && typeof newValue === 'string') {
      setSelectedFieldValues(newValueWrapper.concat(processFieldForContains(selectedField, newValue)))
    } else {
      setSelectedFieldValues(newValueWrapper.concat(newValue))
    }
  }

  useEffect(() => {
    // If all fields are populated, update the filter dispatch
    if (selectedField && selectedOp) {
      if (filter) {
        if (
          filter.field !== selectedField ||
          filter.predicate.operator !== selectedOp ||
          !shallowEqual(filter.predicate.values, selectedFieldValues)
        ) {
          dispatch(
            dashboardsActions.filters.updateComponentById.action({
              _type: 'SearchFilterWithId',
              id: filter.id,
              valid: isValidFilter(filter),
              field: selectedField,
              predicate: { values: selectedFieldValues, operator: selectedOp },
            })
          )
        }
      } else if (selectedFieldValues.length > 0) {
        dispatch(
          dashboardsActions.filters.createComponentById.action({
            _type: 'SearchFilterWithId',
            parentID,
            id: '', // ID will be created by the reducer
            field: selectedField,
            predicate: { values: selectedFieldValues, operator: selectedOp },
          })
        )
      }
    }
  }, [
    dispatch,
    deleteHandler,
    parentID,
    filter,
    parentCombinator,
    selectedField,
    selectedOp,
    selectedFieldValues,
    dashboardsActions,
  ])

  const aggsKey = filterableColumns.find((column) => column.apiName === selectedField)?.aggsKey ?? undefined

  return (
    <>
      <CombinatorSelect index={index} combinator={parentCombinator} onChange={onCombinatorChange} />
      <FilterFieldSelect filterField={selectedField} onChange={onFieldChange} />
      <OperatorSelect
        apiName={selectedField}
        operator={selectedOp}
        onChange={onOperatorChange}
        filterableColumns={filterableColumns}
      />
      <FilterValue
        fieldApiName={selectedField}
        operator={selectedOp}
        values={selectedFieldValues}
        onChange={onValueChange}
        aggsKey={aggsKey}
      />
      <DeleteButton onClick={() => deleteHandler(filter?.id)} />
    </>
  )
}

export default FilterEntry

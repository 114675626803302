import React, { useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { FlatSelectInput } from 'components/HbComponents/Form/FlatSelectInput'
import SARFieldAdornment from 'components/library/SARFieldAdornment'
import { TagGroup } from 'reducers/organizationReducer'
import { Theme } from 'types/hb'

import { SectionHeader } from '../shared/SectionHeader'

import { getNewTagState } from './applicableTagsHelpers'

export interface TagApplication {
  token: string
  applied: boolean
}

interface Props {
  editable: boolean
  applicableTagGroups: TagGroup[]
  save: (tags: TagApplication[]) => void
  selectedTags: TagApplication[]
  disabled?: boolean
}

const MESSAGES: { [key: string]: string } = {
  'ACTIVITIES.STRUCTURING': '32: Structuring',
  'ACTIVITIES.TERRORIST_FINANCING': '33: Terrorist Financing',
  'ACTIVITIES.FRAUD': '34: Fraud',
  'ACTIVITIES.GAMING_ACTIVITIES': '35: Gaming Activities',
  'ACTIVITIES.MONEY_LAUNDERING': '36: Money Laundering',
  'ACTIVITIES.IDENTIFICATION_DOCUMENTATION': '37: Identification / Documentation',
  'ACTIVITIES.OTHER_SUSPICIOUS_ACTIVITIES': '38: Other Suspicious Activities',
  'ACTIVITIES.INSURANCE': '39: Insurance',
  'ACTIVITIES.SECURITIES_FUTURES_OPTIONS': '40: Securities / Futures / Options',
  'ACTIVITIES.MORTGAGE_FRAUD': '41: Mortgage Fraud',
  'ACTIVITIES.CYBER_EVENTS': '42: Cyber Events',
  'PRODUCTS.PRODUCTS': '45: Product Types',
  'INSTRUMENTS.FINANCIAL_INSTRUMENTS': '46: Financial Instruments',
}

export interface ITagState {
  token: string
  applied: boolean
}

const useStyles = makeStyles<Theme>((theme) => ({
  tags: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(380px, 1fr))',
    marginRight: `-${theme.spacing()}`,
  },
}))

function ApplicableTags(props: Props) {
  const { applicableTagGroups, selectedTags, save, editable } = props

  const classes = useStyles()

  const [values, setValues] = useState<ITagState[]>(
    selectedTags.map((t) => {
      return { token: t.token, applied: true }
    })
  )

  const handleChange = (newValues: string[]) => {
    const updatedTagState = getNewTagState(values, newValues)

    setValues(updatedTagState)
    save(updatedTagState)
  }

  return (
    <>
      {applicableTagGroups.map((group) => (
        <React.Fragment key={group.groupName}>
          <SectionHeader>
            {group.groupName}
            {MESSAGES[`${group.category}.${group.structuredName}`] !== undefined && (
              <SARFieldAdornment field={MESSAGES[`${group.category}.${group.structuredName}`]} />
            )}
          </SectionHeader>
          <FlatSelectInput
            className={classes.tags}
            readOnly={!editable}
            disabled={Boolean(props.disabled)}
            options={group.tags.map((t) => ({
              display: t.name,
              value: t.token,
            }))}
            values={values.filter((v) => v.applied).map((v) => v.token)}
            onChange={handleChange}
            multiple
          />
        </React.Fragment>
      ))}
    </>
  )
}

export default ApplicableTags

import { FC } from 'react'

import { Typography } from '@mui/material'

import { useSelector } from 'actions/store'
import CommentEditor from 'components/collaboration/CommentEditor'
import { makeRequiredStyles } from 'components/utils/styles'
import { NoUndoWarning } from 'dashboards/shared/components/BatchActionsMenu/NoUndoWarning'
import { getCurrentAccount, getOrganizationTeammates } from 'helpers/stateHelpers'

const useStyles = makeRequiredStyles((theme) => ({
  comment: {
    backgroundColor: theme.palette.background.secondary,
  },
}))

export const CancelDialogBody: FC<{ setReason(reason: string): void; reason: string | undefined; batch?: boolean }> = (
  props
) => {
  const { children, batch = false, setReason } = props

  const styles = useStyles()
  const account = useSelector(getCurrentAccount)
  const teammates = useSelector(getOrganizationTeammates)

  return (
    <>
      <Typography variant="body2" color="textPrimary" paragraph>
        When you cancel {batch ? 'these reviews, they' : 'this review, it'} will appear as “canceled” and will not be
        counted in reporting for completed cases. {batch ? 'They' : 'It'} will appear in the audit trail.
      </Typography>
      <NoUndoWarning />
      <Typography variant="body2" color="textPrimary" paragraph>
        Why are you canceling {batch ? 'these reviews' : 'this review'}?
      </Typography>
      <CommentEditor
        className={styles.comment}
        author={account}
        teammates={teammates}
        placeholder="Enter a reason for cancellation (required)"
        onChange={setReason}
      />
      {children}
    </>
  )
}

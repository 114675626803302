import { useRef } from 'react'

import { CheckRounded, DensityMediumRounded, DensitySmallRounded, SettingsRounded } from '@mui/icons-material'

import { List, ListItem, ListItemIcon, ListItemText, ListSubheader, styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'actions/store'
import { updateSettings } from 'actions/userSettingsActions'
import { HbButton } from 'components/HbComponents/HbButton'

import { HbPopper } from 'components/HbComponents/HbPopper'
import { Placement } from 'components/HbComponents/HbTooltip'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useToggle } from 'hooks'

import { DashboardDensity } from 'reducers/userSettingsReducer'
import { Theme } from 'types/hb'

import { FilterControlProps } from './FilterControls'

const useStyles = makeStyles((theme: Theme) => ({
  icon: {
    color: 'inherit',
    width: 24,
    height: 24,
    minWidth: 'initial',
    marginRight: theme.spacing(),
  },
  listItem: {
    '&:hover': {
      background: theme.palette.background.contrastLight,
      cursor: 'pointer',
      color: theme.palette.text.primary,
    },
  },
  listItemPrimaryText: {
    fontWeight: theme.fontWeight.bold,
    ...theme.typography.md,
    color: 'inherit',
  },
  popper: {
    width: 250,
  },
  switchLabel: {
    marginBottom: 0,
  },
}))

const StyledButton = styled(HbButton)<{ matchOtherIconButtons: boolean }>(({ matchOtherIconButtons }) => ({
  alignSelf: 'center',
  // Hack to match the size of a regular small button.
  // Icon buttons at the same size are smaller, and so it looks mismatched next to regular buttons.
  // Icon button should probably be the same size as a regular button, but that's a bigger change.
  ...(!matchOtherIconButtons && {
    width: '37px !important',
    height: '37px !important',
  }),
}))

const densityOptionsConfig = [
  {
    Icon: DensityMediumRounded,
    label: 'Normal',
    value: 'normal' as const,
  },
  {
    Icon: DensitySmallRounded,
    label: 'Compact',
    value: 'compact' as const,
  },
]

export const ViewSettings = ({
  buttonActive,
  popoverShared,
  size = 'small',
  zIndex,
  iconOnly: matchOtherIconButtons,
}: FilterControlProps) => {
  const usage = useUsage()
  const dispatch = useDispatch()

  const ref = useRef<HTMLButtonElement>(null)

  const open = useToggle(false)

  const handleClick = () => {
    open.toggle()
  }

  const dashboardDensitySetting = useSelector((state) => state.userSettings.density)

  const setDashboardSetting = (density: DashboardDensity) => {
    usage.logEvent({ name: 'dashboard:viewSetting:clicked', data: { density } })
    dispatch(updateSettings({ density }))
  }

  const classes = useStyles()

  return (
    <>
      <StyledButton
        className={open.value ? buttonActive : undefined}
        matchOtherIconButtons={matchOtherIconButtons}
        iconOnly
        Icon={SettingsRounded}
        label="View"
        onClick={handleClick}
        ref={ref}
        size={size}
        tooltip
        tooltipPlacement={Placement.Top}
        variant="secondary"
      />
      <HbPopper
        classes={{ popper: classes.popper }}
        anchorEl={ref.current}
        isOpen={open.value}
        zIndex={zIndex}
        onClose={handleClick}
      >
        <div className={popoverShared}>
          <List dense>
            <ListSubheader>Density</ListSubheader>
            {densityOptionsConfig.map(({ label, value, Icon }) => (
              <ListItem
                onClick={() => {
                  setDashboardSetting(value)
                  handleClick()
                }}
                key={value}
                className={classes.listItem}
              >
                <ListItemIcon className={classes.icon}>
                  <Icon />
                </ListItemIcon>
                <ListItemText classes={{ primary: classes.listItemPrimaryText }} primary={label} />
                {dashboardDensitySetting === value && <CheckRounded />}
              </ListItem>
            ))}
          </List>
        </div>
      </HbPopper>
    </>
  )
}

import { useRef, useState } from 'react'

import { gql, useQuery } from '@apollo/client'
import { Menu, MenuItem } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles, useTheme } from '@mui/styles'

import classnames from 'classnames'

import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  type TooltipProps,
  XAxis,
  BarChart,
  Bar,
  YAxis,
  PieChart,
  Pie,
  Cell,
} from 'recharts'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import { AutomationStatsGranularity, AutomationStatsRange, AutomationStatsTaxonomy } from 'types/api'

import { assertExhaustive } from 'utils/typeAssertions'

import {
  UsagePageCategoriesQuery,
  UsagePageUsageQuery,
  UsagePageUsageQueryVariables,
  UsagePageCategoriesQueryVariables,
} from './__generated__/Usage.generated'

import type { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'grid',
    gap: theme.spacing(3),
    gridTemplateColumns: '2fr 1fr',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      "current current"
      "usage top"
    `,
  },
  section: {
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    border: `1px solid ${theme.palette.styleguide.borderLight}`,
  },
  sectionHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  sectionSubHeader: {
    display: 'flex',
    margin: theme.spacing(3, 0),
    alignItems: 'baseline',
  },
  sectionSubHeaderTrailer: {
    color: theme.palette.styleguide.textGreyLight,
    marginLeft: theme.spacing(1),
  },
  current: {
    gridArea: 'current',
    display: 'grid',
    gridTemplateRows: 'auto',
    gridTemplateAreas: `
      "header header"
      "runs remaining"
    `,
    // TODO: when limits come back use this for the gridTemplateColumns
    // gridTemplateColumns: 'calc(100% - 448px) auto',
    gridTemplateColumns: '100%',
  },
  currentHeader: {
    gridTemplateArea: 'header',
  },
  currentSection: {
    paddingRight: theme.spacing(3),
    '& + &': {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(0),
      borderLeft: `1px solid ${theme.palette.styleguide.borderLight}`,
    },
  },
  currentSectionRuns: {
    gridArea: 'runs',
  },
  currentSectionRemaining: {
    gridArea: 'remaining',
    display: 'flex',
    flexDirection: 'column',
  },
  currentSectionRemainingBar: {
    width: '100%',
    borderRadius: theme.spacing(25),
    backgroundColor: theme.palette.styleguide.backgroundDark,
    marginBottom: theme.spacing(3),
    height: theme.spacing(1),
  },
  currentSectionRemainingBarFill: {
    height: '100%',
    borderRadius: theme.spacing(25),
    backgroundColor: theme.palette.hues.hbBlue.medium,
  },
  currentSectionRemainingText: {
    marginBottom: theme.spacing(1),
  },
  currentSectionRemainingSubText: {
    marginBottom: theme.spacing(1),
    color: theme.palette.styleguide.textGreyLight,
  },
  currentChart: {
    height: theme.spacing(12.25),
  },
  usage: {
    gridArea: 'usage',
  },
  top: {
    gridArea: 'top',
  },
  optionsContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(1),
  },
  tooltip: {
    padding: theme.spacing(1, 2),
    background: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(0.5),
    borderRadius: theme.spacing(1),
  },
  legend: {
    columnCount: 2,
    listStyleType: 'none',
  },
  legendItem: {
    width: '50%',
    marginBottom: theme.spacing(1),
    cursor: 'default',
    '&::before': {
      content: '""',
      display: 'inline-block',
      marginRight: theme.spacing(1),
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(1),
    },
  },
  legendItemPercentage: {
    color: theme.palette.styleguide.textGreyLight,
    marginLeft: theme.spacing(1),
  },
  legendItemMint: {
    '&::before': {
      backgroundColor: theme.palette.hues.mint.medium,
    },
  },
  legendItemRose: {
    '&::before': {
      backgroundColor: theme.palette.hues.rose.medium,
    },
  },
  legendItemCyan: {
    '&::before': {
      backgroundColor: theme.palette.hues.cyan.medium,
    },
  },
  legendItemSea: {
    '&::before': {
      backgroundColor: theme.palette.hues.sea.medium,
    },
  },
  legendItemSalmon: {
    '&::before': {
      backgroundColor: theme.palette.hues.salmon.medium,
    },
  },
  legendItemCoral: {
    '&::before': {
      backgroundColor: theme.palette.hues.coral.medium,
    },
  },
  legendItemPlum: {
    '&::before': {
      backgroundColor: theme.palette.hues.plum.medium,
    },
  },
  legendItemLemon: {
    '&::before': {
      backgroundColor: theme.palette.hues.lemon.medium,
    },
  },
}))

const useOptionSelectorStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(0, 1),
  },
}))

type OptionSelectorProps<T> = {
  options: Array<{ label: string; value: T }>
  onSelect: (value: T) => void
  value: T
}

function OptionSelector<T>({ options, value, onSelect }: OptionSelectorProps<T>) {
  const styles = useOptionSelectorStyles()
  const [open, setOpen] = useState(false)
  const buttonRef = useRef<HTMLButtonElement>(null)

  return (
    <>
      <HbButton
        ref={buttonRef}
        label={options.find((o) => o.value === value)?.label}
        variant="textSecondary"
        dropdownCaret
        onClick={() => setOpen((o) => !o)}
        className={styles.root}
        disabled={options.length <= 1}
      />
      <Menu anchorEl={buttonRef.current} open={open} onClose={() => setOpen(false)}>
        {options.map((option) => (
          <MenuItem
            key={option.label}
            onClick={() => {
              onSelect(option.value)
              setOpen(false)
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

function labelForGranularity(granularity: AutomationStatsGranularity): string {
  switch (granularity) {
    case AutomationStatsGranularity.Daily:
      return 'Daily'
    case AutomationStatsGranularity.Weekly:
      return 'Weekly'
    case AutomationStatsGranularity.Monthly:
      return 'Monthly'
    default:
      return assertExhaustive(granularity)
  }
}

function getValidGranularitiesForRange(range: AutomationStatsRange): Array<AutomationStatsGranularity> {
  switch (range) {
    case AutomationStatsRange.LastWeek:
      return [AutomationStatsGranularity.Daily]
    case AutomationStatsRange.LastMonth:
      return [AutomationStatsGranularity.Weekly, AutomationStatsGranularity.Daily]
    case AutomationStatsRange.ThreeMonths:
      return [AutomationStatsGranularity.Monthly, AutomationStatsGranularity.Weekly]
    case AutomationStatsRange.SixMonths:
      return [AutomationStatsGranularity.Monthly, AutomationStatsGranularity.Weekly]
    case AutomationStatsRange.TwelveMonths:
      return [AutomationStatsGranularity.Monthly]
    default:
      return assertExhaustive(range)
  }
}

const USAGE_QUERY = gql`
  query UsagePageUsage($granularity: AutomationStatsGranularity!, $range: AutomationStatsRange!) {
    automationsUsageStats(granularity: $granularity, range: $range) {
      sum
      data {
        label
        value
      }
    }
  }
`

const USAGE_CATEGORIES_QUERY = gql`
  query UsagePageCategories($range: AutomationStatsRange!, $taxonomy: AutomationStatsTaxonomy!) {
    automationsUsageCategories(range: $range, taxonomy: $taxonomy) {
      sum
      data {
        label
        value
      }
    }
  }
`

export default function Usage() {
  const styles = useStyles()
  const theme = useTheme<Theme>()
  const [pieIndex, setPieIndex] = useState(-1)
  const colors = [
    theme.palette.hues.mint.medium,
    theme.palette.hues.rose.medium,
    theme.palette.hues.cyan.medium,
    theme.palette.hues.sea.medium,
    theme.palette.hues.salmon.medium,
    theme.palette.hues.coral.medium,
    theme.palette.hues.plum.medium,
    theme.palette.hues.lemon.medium,
  ]
  const legendClasses = [
    styles.legendItemMint,
    styles.legendItemRose,
    styles.legendItemCyan,
    styles.legendItemSea,
    styles.legendItemSalmon,
    styles.legendItemCoral,
    styles.legendItemPlum,
    styles.legendItemLemon,
  ]

  const [usageChartGranularity, setUsageChartGranularity] = useState(AutomationStatsGranularity.Monthly)
  const [usageChartRange, setUsageChartRange] = useState(AutomationStatsRange.SixMonths)
  const [categoryChartRange, setCategoryChartRange] = useState(AutomationStatsRange.SixMonths)
  const [categoryChartTaxonomy, setCategoryChartTaxonomy] = useState(AutomationStatsTaxonomy.Domains)

  const { data: currentMonthChartData } = useQuery<UsagePageUsageQuery, UsagePageUsageQueryVariables>(USAGE_QUERY, {
    variables: {
      granularity: AutomationStatsGranularity.Daily,
      // TODO: current month, not last month
      range: AutomationStatsRange.LastMonth,
    },
  })

  const { data: usageChartData } = useQuery<UsagePageUsageQuery, UsagePageUsageQueryVariables>(USAGE_QUERY, {
    variables: {
      granularity: usageChartGranularity,
      range: usageChartRange,
    },
  })

  const { data: usageCategoriesData } = useQuery<UsagePageCategoriesQuery, UsagePageCategoriesQueryVariables>(
    USAGE_CATEGORIES_QUERY,
    {
      variables: {
        range: categoryChartRange,
        taxonomy: categoryChartTaxonomy,
      },
    }
  )

  const validGranularities = getValidGranularitiesForRange(usageChartRange).map((g) => ({
    label: labelForGranularity(g),
    value: g,
  }))

  const updateRange = (range: AutomationStatsRange) => {
    const grans = getValidGranularitiesForRange(range)
    setUsageChartRange(range)

    if (!grans.includes(usageChartGranularity)) {
      setUsageChartGranularity(grans[0])
    }
  }

  return (
    <div className={styles.root}>
      <div className={classnames(styles.section, styles.current)}>
        <header className={classnames(styles.sectionHeader, styles.currentHeader)}>
          <HbText tag="h2" size="lg" bold>
            Current month usage
          </HbText>
        </header>
        <aside className={classnames(styles.currentSection, styles.currentSectionRuns)}>
          <div className={styles.sectionSubHeader}>
            <HbText size="xxl" tag="dd" bold>
              {currentMonthChartData?.automationsUsageStats.sum ?? 0}
            </HbText>
            <HbText size="s" tag="dt" className={styles.sectionSubHeaderTrailer}>
              runs since 10/1/2023
            </HbText>
          </div>
          <ResponsiveContainer width="100%" height={98}>
            <AreaChart
              data={currentMonthChartData?.automationsUsageStats.data}
              margin={{
                top: 0,
                right: 0,
                left: 0,
                bottom: 0,
              }}
            >
              <defs>
                <linearGradient id="fadeyboi" x1="0" y1="0" x2="0" y2="1">
                  <stop stopColor="#A5B5FF" />
                  <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
              </defs>
              <CartesianGrid stroke="#DDDDDD" strokeDasharray="2 2" vertical={false} />
              <XAxis
                dataKey="label"
                axisLine={false}
                ticks={[
                  currentMonthChartData?.automationsUsageStats.data.at(0)?.label,
                  currentMonthChartData?.automationsUsageStats.data.at(-1)?.label,
                ]}
                tickLine={false}
                tick={{ fill: '#8C8C8C', fontSize: '12px', fontWeight: 450 }}
                interval="preserveStartEnd"
              />
              <Tooltip
                cursor={{ stroke: '#DDDDDD', strokeWidth: 1, strokeDasharray: '2 2' }}
                content={({ payload, label }: TooltipProps) => (
                  <dl className={styles.tooltip}>
                    <dt>
                      <HbText size="s" bold color="white">
                        {label}
                      </HbText>
                    </dt>
                    <dd>
                      <HbText size="lg" bold color="white">
                        {payload?.at(0)?.value}
                      </HbText>
                    </dd>
                  </dl>
                )}
              />
              <Area type="monotone" dataKey="value" stroke="#4361E5" strokeWidth={2} fill="url(#fadeyboi)" />
            </AreaChart>
          </ResponsiveContainer>
        </aside>
        {/*
        <aside className={classnames(styles.currentSection, styles.currentSectionRemaining)}>
          <div className={styles.sectionSubHeader}>
            <HbText tag="dt" size="xxl" bold>
              3,904
            </HbText>
            <HbText size="s" tag="dd" className={styles.sectionSubHeaderTrailer}>
              remaining runs
            </HbText>
          </div>
          <div
            className={styles.currentSectionRemainingBar}
            role="progressbar"
            aria-label="asdf"
            aria-valuemin={0}
            aria-valuemax={9000}
            aria-valuenow={3904}
          >
            <div
              role="presentation"
              className={styles.currentSectionRemainingBarFill}
              style={{ width: `${((9000 - 3904) / 9000) * 100}%` }}
            />
          </div>
          <div className={styles.currentSectionRemainingText}>
            <HbText>Restarting on November 1st</HbText>
          </div>
          <div className={styles.currentSectionRemainingSubText}>
            <HbText color="inherit">10 days left</HbText>
          </div>
        </aside>
        */}
      </div>
      <aside className={classnames(styles.section, styles.usage)}>
        <header className={styles.sectionHeader}>
          <HbText tag="h2" size="lg" bold>
            Usage
          </HbText>
          <div className={styles.optionsContainer}>
            <OptionSelector
              options={[
                { label: 'Last 12 Months', value: AutomationStatsRange.TwelveMonths },
                { label: 'Last 6 Months', value: AutomationStatsRange.SixMonths },
                { label: 'Last 3 Months', value: AutomationStatsRange.ThreeMonths },
                { label: 'Last Month', value: AutomationStatsRange.LastMonth },
                { label: 'Last Week', value: AutomationStatsRange.LastWeek },
              ]}
              value={usageChartRange}
              onSelect={(r) => updateRange(r)}
            />
            <OptionSelector
              options={validGranularities}
              value={usageChartGranularity}
              onSelect={(g) => setUsageChartGranularity(g)}
            />
          </div>
        </header>
        <div className={styles.sectionSubHeader}>
          <HbText tag="dt" size="xxl" bold>
            {usageChartData?.automationsUsageStats.sum ?? 0}
          </HbText>
          <HbText size="s" tag="dd" className={styles.sectionSubHeaderTrailer}>
            automation runs
          </HbText>
        </div>
        <div>
          <ResponsiveContainer width="100%" height={215}>
            <BarChart
              width={150}
              height={40}
              data={usageChartData?.automationsUsageStats.data}
              barCategoryGap={4}
              margin={{ left: -15 }}
            >
              <CartesianGrid stroke="#DDDDDD" strokeDasharray="2 2" vertical={false} />
              <Bar dataKey="value" fill="#4361E5" radius={4} isAnimationActive />
              <XAxis
                dataKey="label"
                axisLine={false}
                tickLine={false}
                tick={{ fill: '#8C8C8C', fontSize: '12px', fontWeight: 450 }}
                interval="preserveStartEnd"
              />
              <YAxis tickLine={false} axisLine={false} tick={{ fill: '#8C8C8C', fontSize: '12px', fontWeight: 450 }} />
              <Tooltip
                cursor={false}
                content={({ payload, label }: TooltipProps) => (
                  <dl className={styles.tooltip}>
                    <dt>
                      <HbText size="s" bold color="white">
                        {label}
                      </HbText>
                    </dt>
                    <dd>
                      <HbText size="lg" bold color="white">
                        {payload?.at(0)?.value}
                      </HbText>
                    </dd>
                  </dl>
                )}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </aside>
      <aside className={classnames(styles.section, styles.top)}>
        <header className={styles.sectionHeader}>
          <HbText tag="h2" size="lg" bold>
            Top categories
          </HbText>
          <div className={styles.optionsContainer}>
            <OptionSelector
              options={[
                { label: 'Domains', value: AutomationStatsTaxonomy.Domains },
                { label: 'Actions', value: AutomationStatsTaxonomy.Actions },
              ]}
              value={categoryChartTaxonomy}
              onSelect={(t) => setCategoryChartTaxonomy(t)}
            />
            <OptionSelector
              options={[
                { label: 'Last 12 Months', value: AutomationStatsRange.TwelveMonths },
                { label: 'Last 6 Months', value: AutomationStatsRange.SixMonths },
                { label: 'Last 3 Months', value: AutomationStatsRange.ThreeMonths },
                { label: 'Last Month', value: AutomationStatsRange.LastMonth },
                { label: 'Last Week', value: AutomationStatsRange.LastWeek },
              ]}
              value={categoryChartRange}
              onSelect={(r) => setCategoryChartRange(r)}
            />
          </div>
        </header>
        <div>
          <ResponsiveContainer width="100%" height={208}>
            <PieChart width={160} height={160}>
              <Pie
                data={usageCategoriesData?.automationsUsageCategories.data}
                innerRadius={40}
                outerRadius={80}
                dataKey="value"
                nameKey="label"
                cornerRadius={4}
              >
                {usageCategoriesData?.automationsUsageCategories.data.map((entry, i) => (
                  <Cell
                    key={entry.label}
                    fill={colors[i % colors.length]}
                    strokeWidth={pieIndex === i ? 0 : 4}
                    style={{ position: 'relative', zIndex: pieIndex === i ? 1e3 : 1 }}
                  />
                ))}
              </Pie>
              <Tooltip
                cursor={false}
                content={({ payload }: TooltipProps) => (
                  <dl className={styles.tooltip}>
                    <dt>
                      <HbText size="s" bold color="white">
                        {payload?.at(0)?.name}
                      </HbText>
                    </dt>
                    <dd>
                      <HbText size="lg" bold color="white">
                        {payload?.at(0)?.value}
                      </HbText>
                    </dd>
                  </dl>
                )}
              />
            </PieChart>
          </ResponsiveContainer>
          <ul className={styles.legend}>
            {usageCategoriesData?.automationsUsageCategories.data.map((entry, i) => (
              <li
                className={classnames(styles.legendItem, legendClasses[i % legendClasses.length])}
                key={entry.label}
                onMouseEnter={() => setPieIndex(i)}
                onMouseLeave={() => setPieIndex(-1)}
              >
                <HbText>{entry.label}</HbText>
                <HbText className={styles.legendItemPercentage}>
                  {Math.round(
                    (entry.value /
                      (usageCategoriesData?.automationsUsageCategories.data.reduce(
                        (sum, { value }) => sum + value,
                        0
                      ) || 1)) *
                      1e4
                  ) / 100}
                  %
                </HbText>
              </li>
            ))}
          </ul>
        </div>
      </aside>
    </div>
  )
}

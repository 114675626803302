import { set } from 'lodash'
import { parse, stringify } from 'query-string'

import { ZodError } from 'zod'

import { RequestCompressor } from 'reducers/dashboards/queryStringTools'

import { isAnyObject } from 'utils/object'
import { NormalizedSearchRequest } from 'utils/query/api.types'
import {
  CaseOverviewRecord,
  HBQueryString,
  DashboardQueryString,
  DashboardRecordFullKeys,
  DashboardQueryStringFullKeys,
} from 'utils/query.types'

/**
 * Flattens an object like
 *
 * {
 *   "foo: {
 *     "bar": "baz"
 *   }
 * }
 *
 * into the following:
 *
 * {
 *   "foo.bar": 'baz"
 * }
 */
function flatten<T extends AnyObject>(obj: T, path: string | null = null, separator = '.'): AnyObject {
  return Object.entries(obj).reduce((acc, [key, value]): T => {
    const newPath = [path, key].filter(Boolean).join(separator)

    return isAnyObject(value) || Array.isArray(value)
      ? // we cast value as AnyObject here because we want arrays to be deconstructed, like an object
        { ...acc, ...flatten(value as T, newPath, separator) }
      : { ...acc, [newPath]: value }
  }, {} as T)
}

/**
 * Expands an object like
 *
 * {
 *   "foo.bar": 'baz"
 * }
 *
 * into the following:
 *
 * {
 *   "foo: {
 *     "bar": "baz"
 *   }
 * }
 */
const expand = <T extends Record<string, unknown>>(obj: T) => {
  const result = {} as T
  Object.keys(obj).forEach((key) => {
    set(result, key, obj[key])
  })
  return result
}

// Yeah I hate this too, but I have no better way to deal with the fact that there is only one high level configuration
// option for `parse` to handle numbers. We WANT it to normally parse strings that look like numbers as string, except
// for in the predicate values...so here we are
const stringifyPredicateValues = (fullQuery: DashboardQueryStringFullKeys): NormalizedSearchRequest => {
  const stringifiedFilters = fullQuery?.filters?.map((filter) => ({
    field: filter.field,
    group: filter.group,
    predicate: {
      operator: filter.predicate?.operator,
      values: filter.predicate?.values?.map((v) => `${v}`),
    },
  }))

  return { ...fullQuery, filters: stringifiedFilters }
}

export const serializeQuery =
  <Slice extends keyof HBQueryString>(slice: Slice) =>
  (query: Partial<HBQueryString[Slice]>): string =>
    stringify(flatten({ [slice]: query }))

export const deserializeQuery =
  <Slice extends keyof HBQueryString>(slice: Slice) =>
  (query: string | null): HBQueryString[Slice] | undefined => {
    if (!query) return undefined
    const decoded = decodeURIComponent(query)
    const parsed = parse(decoded, { parseNumbers: true, parseBooleans: true })
    const expanded = expand(parsed) as Record<string, unknown>
    const sliceData = expanded[slice] || expanded.db

    try {
      if (sliceData !== undefined) {
        if (slice === 'db' || slice === 'dashboard') {
          const isFullQueryVersion = query.startsWith('?dashboard') || query.startsWith('dashboard')
          const fullQueryVersion = isFullQueryVersion
            ? (sliceData as DashboardQueryStringFullKeys)
            : RequestCompressor.expand(sliceData as DashboardQueryString)

          const stringifiedSliceData = stringifyPredicateValues(fullQueryVersion)

          return DashboardRecordFullKeys.parse(stringifiedSliceData)
        }
        if (slice === 'case') {
          const data = CaseOverviewRecord.parse(sliceData)
          if (data.tabInfo?.pathname && data.tabInfo.pathname !== window.location.pathname) delete data.tabInfo
          return data
        }
      }
    } catch (e) {
      if (!(e instanceof ZodError)) throw e

      // NOTE: we specifically only log to the console here because there are cases where this type of failure may
      // occur on a frequent basis (since users can easily manipulate the query string, or bookmark bad versions)
      //
      // So we opt to simply log the error which will then be picked up in the next Sentry error as "breadcrumbs". This
      // will allow us to track these errors, but only if they actually result in a failure elsewhere. Parsing failrues
      // for the querystring will always result in "ignore and continue" so there is no need to log this futher.

      // eslint-disable-next-line no-console
      console.error({
        ...e,
        success: false,
        message: `Error while parsing ${JSON.stringify(sliceData)}: ${JSON.stringify(e.errors)}`,
      })
    }

    return undefined
  }

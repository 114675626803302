import { styled } from '@mui/material'

import pluralize from 'pluralize'

import { HbButton } from 'components/HbComponents/HbButton'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useFormattedDate } from 'hooks/DateFormatHooks'
import { AutomationDomainType, AutomationRuleUpcomingRunSummary } from 'types/api'

const Card = styled('div')(({ theme }) => ({
  border: `1px solid ${theme.palette.styleguide.borderLight}`,
  borderRadius: theme.shape.largeContainer.borderRadius,
  padding: theme.spacing(2),
  background: theme.palette.styleguide.backgroundMedium,
}))

const Content = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'row',
  gap: theme.spacing(),
}))

const StatusBar = styled('div')(({ theme }) => ({
  height: '100%',
  width: '6px',
  background: theme.palette.styleguide.seaMedium,
  borderRadius: '24px',
}))

const EmphasizedNumber = styled(HbText)(() => ({
  '&&': {
    fontSize: '24px',
    lineHeight: '28px',
  },
}))

const numberFormat = new Intl.NumberFormat('en-US')

export const UpcomingRunCard = ({
  run,
  domainType,
  onClick,
}: {
  run: AutomationRuleUpcomingRunSummary
  domainType: AutomationDomainType
  onClick?: () => void
}) => {
  const formattedMatchedEventsCount = numberFormat.format(run.totalEventsMatchedCount ?? 0)
  const formattedNextRunDate = useFormattedDate(run.nextRunDate, '', 'LL · LT z')

  return (
    <Card>
      <HbText bold block>
        {formattedNextRunDate}
      </HbText>
      <Content>
        <div>
          <StatusBar />
        </div>
        <div>
          <EmphasizedNumber bold>{formattedMatchedEventsCount}</EmphasizedNumber>{' '}
          {pluralize(domainType, run.totalEventsMatchedCount)} met the conditions so far
        </div>
      </Content>
      <div>
        <HbButton
          variant="secondary"
          label="View matches"
          fullWidth
          onClick={onClick}
          disabled={run.totalEventsMatchedCount <= 0}
        />
      </div>
    </Card>
  )
}

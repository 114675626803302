/* eslint-disable react/button-has-type */

import { HTMLProps } from 'react'

import classnames from 'classnames'

// TODO(ak): Update this type declaration when our version of typescript
// and React agree on the types of a button
type Props = HTMLProps<HTMLButtonElement> & {
  type?: 'button' | 'submit' | 'reset'
  testId?: string
  inputMode?: 'text' | 'none' | 'tel' | 'url' | 'email' | 'numeric' | 'decimal' | 'search'
}

function SimpleButton(props: Props) {
  const { type: buttonType = 'button', className, testId, children, ...otherProps } = props

  return (
    <button
      className={classnames(className, 'link-like')}
      data-testid={testId}
      tabIndex={-1}
      type={buttonType}
      {...otherProps}
    >
      {children}
    </button>
  )
}

export default SimpleButton

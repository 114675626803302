import React, { ComponentProps, useRef, useState, ReactElement } from 'react'

import { css } from '@emotion/react'

import { MoreHoriz } from '@mui/icons-material'
import { Collapse, useTheme } from '@mui/material'

import { FieldPathByValue, useFormContext } from 'react-hook-form'
import { MergeExclusive } from 'type-fest'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Menu, MenuListItem, MenuList } from 'components/library/Menu'

import { useToggle } from 'hooks'

import { RecipeStepIcon, RecipeStep } from '../AutomationIcon'

import { ExpandButton } from './buttons'
import { FormSchema } from './formSchema'

const useListCss = () => {
  const theme = useTheme()

  return {
    listItem: css({
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      '& + &': {
        marginTop: theme.spacing(6),
        '&:before': {
          content: '""',
          display: 'block',
          width: 0,
          height: theme.hbUnit(6) - 1,
          border: `0px dashed ${theme.palette.styleguide.borderDark}`,
          borderRightWidth: 1.5,
          position: 'absolute',
          top: theme.hbUnit(-6) + 0.5,
          left: 'calc(50% - 1px)',
        },
      },
    }),
    list: css({
      maxWidth: theme.breakpoints.values.md,
      margin: '0 auto',
    }),
  }
}

type ElementWithKey = ReactElement<{ key: string }>
interface Props {
  children: (ElementWithKey | null)[]
}

export function StepList({ children }: Props) {
  const styles = useListCss()

  return (
    <div css={styles.list}>
      {children
        .filter((child): child is ElementWithKey => !!child)
        .map((child) => (
          <div css={styles.listItem} key={child.key}>
            {child}
          </div>
        ))}
    </div>
  )
}

const useStepCss = ({ hasChildren, hasText, size }: { hasChildren: boolean; hasText: boolean; size: 'sm' | 'lg' }) => {
  const theme = useTheme()

  return {
    root: css({
      display: 'flex',
      flexDirection: 'column',
      border: `1px solid ${theme.palette.styleguide.borderLight}`,
      borderRadius: theme.spacing(1),
      overflow: 'hidden', // DO NOT REMOVE THIS OR YOU WILL BE FIRED (it will break the dotted lines)
      width: size === 'lg' ? '100%' : 'initial',
    }),
    section: css({
      padding: theme.spacing(2),
    }),
    header: css({
      display: 'grid',
      gridTemplate: `auto ${hasText ? 'auto' : ''} / 56px auto ${size === 'sm' ? 'auto' : '240px'}`,
      columnGap: theme.spacing(2),
      borderRadius: hasChildren ? theme.spacing(1, 1, 0, 0) : theme.spacing(1),
      background: theme.palette.styleguide.backgroundLight,
      ...(hasChildren
        ? {
            borderStyle: 'solid',
            borderWidth: '0px 0px 1px 0px',
            borderColor: theme.palette.styleguide.borderLight,
          }
        : {}),
      ...(hasText
        ? {
            rowGap: theme.spacing(1),
          }
        : {}),
    }),
    headerTitle: css({
      display: 'flex',
      alignItems: 'center',
      ...(!hasText
        ? {
            gridRowEnd: 'span 2',
          }
        : {}),
    }),
    icon: css({
      background: theme.palette.styleguide.white,
    }),
    iconContainer: css({
      gridRow: 1,
      gridRowEnd: 'span 2',
      gridColumn: 1,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }),
    title: css({
      gridRow: 1,
      gridColumn: 2,
      alignSelf: 'center',
      fontWeight: 600,
    }),
    text: css({
      gridRow: 2,
      gridColumn: 2,
      alignSelf: 'center',
    }),
    textField: css({
      background: 'none',
      outline: 'none',
      borderWidth: '0 0 1px 0',
      borderStyle: 'dashed',
      borderColor: theme.palette.styleguide.borderDark,
    }),
    menu: css({
      gridRow: 1,
      gridRowEnd: 'span 2',
      gridColumn: 3,
      display: 'flex',
      justifyContent: 'end',
      alignItems: 'center',
    }),
    menuButton: css({
      color: theme.palette.styleguide.textGreyLight,
    }),
  }
}

interface MenuListItemProps extends ComponentProps<typeof MenuListItem> {
  key: string
}

export default function Step({
  title,
  text,
  textField,
  type,
  children,
  contextMenuItems,
  compact,
  size,
  ActionButton,
  showCollapseButton,
}: React.PropsWithChildren<
  {
    title?: string
    type?: RecipeStep | null
    contextMenuItems?: Array<MenuListItemProps>
    compact?: boolean
    size?: 'sm' | 'lg'
    ActionButton?: React.ReactElement | null
    showCollapseButton?: boolean
  } & MergeExclusive<{ text?: string }, { textField?: FieldPathByValue<FormSchema, string | null> }>
>) {
  const styles = useStepCss({
    hasChildren: !!children && !compact,
    hasText: !!text || !!textField,
    size: size ?? 'lg',
  })
  const menuTrigger = useRef<HTMLButtonElement | null>(null)
  const [menuOpen, setMenuOpen] = useState(false)
  const { value: stepMenuOpen, toggle: toggleStepMenu } = useToggle(true)
  const { register } = useFormContext()

  return (
    <div css={styles.root}>
      <header css={[styles.header, styles.section]}>
        <div css={styles.iconContainer}>
          <RecipeStepIcon type={type} overrideCss={styles.icon} />
        </div>
        <div css={styles.headerTitle}>
          {compact && children}
          {title && (
            <HbText css={styles.title} size="md" tag="h3" bold>
              {title}
            </HbText>
          )}
        </div>
        {text ? (
          <HbText css={styles.text}>{text}</HbText>
        ) : textField ? (
          <HbText tag="input" {...register(textField)} css={styles.textField} />
        ) : null}
        <div css={styles.menu}>
          {ActionButton ??
            (!!contextMenuItems?.length && (
              <>
                <HbButton
                  css={styles.menuButton}
                  onClick={() => setMenuOpen((o) => !o)}
                  ref={menuTrigger}
                  aria-expanded={false}
                  Icon={MoreHoriz}
                  label={menuOpen ? 'Hide context menu' : 'Show context menu'}
                  variant="textSecondary"
                  iconOnly
                />
                <Menu
                  trigger={menuTrigger.current}
                  open={menuOpen}
                  onClose={() => setMenuOpen((o) => !o)}
                  popperMenu
                  placement="bottom-start"
                >
                  <MenuList onClick={() => setMenuOpen(false)}>
                    {contextMenuItems?.map((props) => (
                      <MenuListItem {...props} key={props.key} />
                    ))}
                  </MenuList>
                </Menu>
              </>
            ))}
          {showCollapseButton && <ExpandButton onClick={toggleStepMenu} expandMore={!stepMenuOpen} />}
        </div>
      </header>
      <Collapse in={stepMenuOpen} timeout="auto">
        {!compact && children && <main css={styles.section}>{children}</main>}
      </Collapse>
    </div>
  )
}

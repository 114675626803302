import { useEffect } from 'react'

import { useSelector } from 'actions/store'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'

import { useDashboardColumns } from './useDashboardColumns'

export function useUpdateDashboardColumns() {
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardColumnHookResult = useDashboardColumns()
  const { selectableColumns, selectedColumns, handleSingleColumnSelectionChange } = dashboardColumnHookResult

  const sorts = useSelector(dashboardsSelectors.sorts.valid)

  // If incoming URL has sort query and column isn't displayed, expose the column
  useEffect(() => {
    const columnsToShow = selectableColumns
      // remove already selected columns
      .filter((selectable) => !selectedColumns.some((selected) => selected.apiName === selectable.apiName))
      // keep any that are in sorts
      .filter((selectable) => sorts?.some((sort) => sort.sortField === selectable.apiName))

    if (columnsToShow.length) {
      columnsToShow.forEach((columnToShow) => handleSingleColumnSelectionChange(columnToShow))
    }
  }, [handleSingleColumnSelectionChange, selectableColumns, selectedColumns, sorts])

  return dashboardColumnHookResult
}

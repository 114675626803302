import { isEmpty, isEqual, isFunction, omit, zip } from 'lodash'

import { InstitutionLinkingFormData } from 'actions/investigationsActions'
import { FinancialInstitutionBranch } from 'reducers/investigationsReducer.types'

type ValidationResult = string | false
type BoolFunction = () => boolean
type Validator = (input: string) => ValidationResult

function testIsTrue(test: boolean | BoolFunction): boolean {
  if (isFunction(test)) {
    return test()
  }
  return test
}

// An empty validator to be used when the validation should be skipped.
export function pass(): ValidationResult {
  return false
}

// Validates email format.
export function isEmail(email: string): ValidationResult {
  // Taken from https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
  const re =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i // eslint-disable-line security/detect-unsafe-regex
  if (!isEmpty(email) && !re.test(email)) {
    return 'Invalid Email'
  }
  return false
}

// Validates URL website format.
export function isUrl(url: string): ValidationResult {
  const re = /[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/
  if (!isEmpty(url) && !re.test(url)) {
    return 'Invalid Website'
  }
  return false
}

// Validates Tax ID number format.
export function isTIN(tin: string): ValidationResult {
  const re = /(^[0-9]{9}$)|(^[0-9]{3}-[0-9]{2}-[0-9]{4}$)|(^[0-9]{2}-[0-9]{7}$)/
  if (!isEmpty(tin) && !re.test(tin)) {
    return 'Invalid TIN'
  }
  return false
}

// Validates phone number format.
export function isPhoneNumber(number: string): ValidationResult {
  if (isEmpty(number)) {
    return false
  }

  const digits = number.replace(/\D/g, '')
  if (digits.length < 5) {
    return 'Phone number must be at least 5 digits'
  }

  if (digits.length > 16) {
    return 'Phone number cannot be longer than 16 digits'
  }

  return false
}

// Validates value only includes numbers and dashes
export function isNumberWithDashes(number: string): ValidationResult {
  const re = /^[0-9-]+$/
  if (!isEmpty(number) && !re.test(number)) {
    return 'Value should be a number, optionally separated by dashes'
  }
  return false
}

function passesLuhnCheck(numbers: string): boolean {
  if (!/\d+/.test(numbers)) {
    return false
  }
  if (isEmpty(numbers)) {
    return true
  }
  const digits = numbers.split('').map((number) => parseInt(number, 10))
  const check = digits.pop()

  const sum = digits.reverse().reduce((accum, digit, index) => {
    let addendum = digit
    if (index % 2 === 0) {
      addendum = 2 * digit
    } // Double every other digit
    if (addendum >= 10) {
      addendum = 1 + (addendum % 10)
    } // Sum digits if > 10
    return accum + addendum
  }, 0)

  return check === (sum * 9) % 10
}

// Validates value only includes numbers and dashes
export function isCardNumber(number: string): ValidationResult {
  const re = /^[0-9-\s*•]+$/
  const numbersOnly = number.replace(/\D/g, '')
  if (isEmpty(number)) {
    return false
  }
  if (!re.test(number)) {
    return 'Card number should be all digits, dashes and * or • for masking'
  }
  if (number.length > 19 || number.length < 10 || numbersOnly.length < 10) {
    return 'Card numbers should be between 10 and 19 characters long'
  }

  if (numbersOnly.length >= 12 && !passesLuhnCheck(numbersOnly)) {
    return 'Does not look like a valid card number'
  }
  return false
}

// Validates value is a number
export function isNumber(number: string): ValidationResult {
  const re = /^[0-9]+$/
  if (!isEmpty(number) && !re.test(number)) {
    return 'Value should be a number'
  }
  return false
}

// Validates that the value is an alphanumeric string.
export function isAlphanumeric(value: string): ValidationResult {
  const re = /(^[0-9a-zA-Z]+$)/
  if (!isEmpty(value) && !re.test(value)) {
    return 'Value should be alphanumeric'
  }
  return false
}

// Validates ip address format
export function isIPAddress(ipAddress: string): ValidationResult {
  const re = /(^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}$)|(^$)/

  // IPv6 RegEx
  // Taken from https://stackoverflow.com/questions/53497/regular-expression-that-matches-valid-ipv6-addresses
  const ipv6 =
    /(([0-9a-fA-F]{1,4}:){7,7}[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,7}:|([0-9a-fA-F]{1,4}:){1,6}:[0-9a-fA-F]{1,4}|([0-9a-fA-F]{1,4}:){1,5}(:[0-9a-fA-F]{1,4}){1,2}|([0-9a-fA-F]{1,4}:){1,4}(:[0-9a-fA-F]{1,4}){1,3}|([0-9a-fA-F]{1,4}:){1,3}(:[0-9a-fA-F]{1,4}){1,4}|([0-9a-fA-F]{1,4}:){1,2}(:[0-9a-fA-F]{1,4}){1,5}|[0-9a-fA-F]{1,4}:((:[0-9a-fA-F]{1,4}){1,6})|:((:[0-9a-fA-F]{1,4}){1,7}|:)|fe80:(:[0-9a-fA-F]{0,4}){0,4}%[0-9a-zA-Z]{1,}|::(ffff(:0{1,4}){0,1}:){0,1}((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])|([0-9a-fA-F]{1,4}:){1,4}:((25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9])\.){3,3}(25[0-5]|(2[0-4]|1{0,1}[0-9]){0,1}[0-9]))/ // eslint-disable-line security/detect-unsafe-regex

  if (!isEmpty(ipAddress) && !re.test(ipAddress) && !ipv6.test(ipAddress)) {
    return 'Invalid IP Address'
  }
  return false
}

export function isMinLength(minLength: number): Validator {
  return (value) => {
    if (!isEmpty(value) && value.length < minLength) {
      return `Must be at least ${minLength} characters`
    }

    return false
  }
}

export function isMaxLength(maxLength: number): Validator {
  return (value) => {
    if (!isEmpty(value) && value.length > maxLength) {
      return `Must be at most ${maxLength} characters`
    }

    return false
  }
}

export function isExactLength(length: number): Validator {
  return (value) => {
    if (!isEmpty(value) && value.length !== length) {
      return `Must be ${length} characters`
    }

    return false
  }
}

// String for required field validation errors
export const FIELD_REQUIRED = 'Field is required'

// Validates that value is present
export function isRequired(value: string | undefined | null): ValidationResult {
  if (isEmpty(value)) {
    return FIELD_REQUIRED
  }

  return false
}

// Validates that the value equals the expected value
export function isEqualTo(expected: any): Validator {
  const expectedValue = isFunction(expected) ? expected() : expected

  return (value) => {
    if (value !== expectedValue) {
      return 'Value does not match'
    }
    return false
  }
}

// Validates a validation only if test evaluates to true. Test can be a raw value or a function.
export function validateIf(validation: Validator, test: boolean | BoolFunction): Validator {
  if (testIsTrue(test)) {
    return validation
  }
  return pass
}

// Allows redefinition of error messages.
export function customErrorMsg(validationFn: Validator, msg: string): Validator {
  return (value) => validationFn(value) && msg
}

export function areLibraryFinancialInstitutionsEqual(
  prev: InstitutionLinkingFormData,
  curr: InstitutionLinkingFormData
) {
  const addressFields = [
    'addressLine1',
    'addressLine2',
    'locality',
    'administrativeDistrictLevel1',
    'country',
    'postalCode',
  ]
  const branchExceptAddressIsEqual = (
    prevBranch?: FinancialInstitutionBranch,
    currBranch?: FinancialInstitutionBranch
  ) => isEqual(omit(prevBranch, addressFields), omit(currBranch, addressFields))

  const branchesAreEqual = (
    prevBranches: FinancialInstitutionBranch[] | undefined,
    currBranches: FinancialInstitutionBranch[] | undefined
  ) => {
    if (!prevBranches || !currBranches) {
      return true
    }
    if (isEqual(prevBranches, currBranches)) {
      return true
    }

    // don't autosave on branch change
    if (
      prevBranches.length === currBranches.length &&
      zip(prevBranches, currBranches).every(([prevBranch, currBranch]) =>
        branchExceptAddressIsEqual(prevBranch, currBranch)
      )
    ) {
      return true
    }
    if (currBranches.length === prevBranches.length + 1) {
      return (
        isEqual(currBranches.slice(0, currBranches.length - 1), prevBranches) &&
        isEmpty(currBranches[currBranches.length - 1])
      )
    }
    return false
  }
  return (
    isEqual(prev, curr) ||
    (isEqual(omit(prev, 'branches'), omit(curr, 'branches')) && branchesAreEqual(prev.branches, curr.branches))
  )
}

import { HbText } from 'components/HbComponents/Text/HbText'

export default function CustomizeNonConfigurableAction() {
  return (
    <div>
      <HbText size="md" tag="p">
        This action requires no configuration.
      </HbText>
    </div>
  )
}

import { createContext, useContext } from 'react'

interface CasePageContextValue {
  caseToken: string
}

export const CasePageContext = createContext<CasePageContextValue | undefined>(undefined)

export const useCasePageContext = () => {
  const context = useContext(CasePageContext)
  if (!context) {
    throw new Error('useCasePageContext must be used within a CasePageContextProvider')
  }
  return context
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CircleAddIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.8332 5.83341H9.1665v3.33334H5.83317v1.66665H9.1665v3.3333h1.6667v-3.3333h3.3333V9.16675h-3.3333V5.83341zm-.83336-4.16666c-4.6 0-8.33334 3.73333-8.33334 8.33335 0 4.6 3.73334 8.3333 8.33334 8.3333 4.59996 0 8.33336-3.7333 8.33336-8.3333 0-4.60002-3.7334-8.33335-8.33336-8.33335zm0 14.99995c-3.675 0-6.66667-2.9916-6.66667-6.6666 0-3.67502 2.99167-6.66669 6.66667-6.66669 3.67496 0 6.66666 2.99167 6.66666 6.66669 0 3.675-2.9917 6.6666-6.66666 6.6666z"
    />
  </SvgIcon>
)

export default CircleAddIcon

import { memo } from 'react'

import { gql } from '@apollo/client'

import { ExpandLess, ExpandMore } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { ListChildComponentProps } from 'react-window'

import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'

import { HbText } from 'components/HbComponents/Text/HbText'
import { DynamicVirtualListItem } from 'components/library/Virtualized/List'
import { EnhancedItemData } from 'components/library/Virtualized/types'
import { useToggle } from 'hooks'
import { useDateFormatter } from 'hooks/DateFormatHooks'
import { OpenInNewIcon } from 'icons'
import { CheckCircleOutlinedIcon } from 'icons/CheckCircleOutlinedIcon'
import ErrorOutlineIcon from 'icons/ErrorOutlineIcon'
import { AutomationRuleExecutionStatus } from 'types/api'
import { Theme } from 'types/hb'

import { ActivityLogItemAutomationRuleExecutionFragment as RuleExecution } from './__generated__/AutomationActivityLogItem.generated'

export interface ListData {
  listItems: RuleExecution[]
  isItemLoaded: (index: number) => boolean
}

type EventContainerProps = ListChildComponentProps<ListItemData>
type ListItemData = EnhancedItemData<ListData>

const useDetailsStyles = makeStyles((theme: Theme) => ({
  detailsContainer: {},
  detailsLink: {
    marginTop: theme.spacing(1),
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.styleguide.textGreyLight,
    ...theme.typography.sm,
  },
  expand: {
    marginLeft: theme.spacing(0.5),
    color: theme.palette.styleguide.textGreyLight,
    ...theme.typography.sm,
  },
  text: {
    marginTop: theme.spacing(1),
  },
}))

function EventableLink({ event }: { event: NonNullable<RuleExecution['event']> }) {
  return (
    <HbTooltip title="Opens a new browser tab" placement={Placement.Top} showArrow>
      {/* No need for no-referrer/no-opener here because we're opening our own page */}
      {/* eslint-disable-next-line react/jsx-no-target-blank */}
      <a href={event.eventableHref} target="_blank" onClick={(e) => e.stopPropagation()}>
        <OpenInNewIcon fontSize="small" />
      </a>
    </HbTooltip>
  )
}

function DetailsContainer({ ruleExecution }: { ruleExecution: RuleExecution }) {
  const { value: open, setValue } = useToggle(false)
  const classes = useDetailsStyles()

  return (
    <div className={classes.detailsContainer}>
      {open && (
        <>
          <HbText className={classes.text} block>
            {ruleExecution.details}
          </HbText>
          <button type="button" className={classes.detailsLink} onClick={() => setValue(false)}>
            Hide details
            <ExpandLess className={classes.expand} />
          </button>
        </>
      )}
      {!open && (
        <button type="button" className={classes.detailsLink} onClick={() => setValue(true)}>
          Show details
          <ExpandMore className={classes.expand} />
        </button>
      )}
    </div>
  )
}

const useExecutionContainerStyles = makeStyles((theme: Theme) => ({
  listItem: {
    display: 'flex',
  },
  listItemGutter: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItemContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'start',
    marginLeft: theme.spacing(0.5),
    marginBottom: theme.spacing(2),
  },
  listItemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  successIcon: {
    display: 'block',
    color: theme.palette.approval,
    height: '14px',
    marginTop: theme.spacing(0.5),
  },
  errorIcon: {
    display: 'block',
    color: theme.palette.error.main,
    height: '14px',
    marginTop: theme.spacing(0.5),
  },
  verticalRule: {
    marginTop: theme.spacing(0.5),
    width: '1px',
    height: '100%',
    borderLeft: `1px dashed ${theme.palette.grey[300]}`,
  },
}))
const ExecutionContainer = memo(({ data: { listItems }, index }: EventContainerProps) => {
  const execution = listItems[index]
  const classes = useExecutionContainerStyles()
  const formatTimestamp = useDateFormatter('LLL')

  const icon =
    execution.status === AutomationRuleExecutionStatus.Error ? (
      <ErrorOutlineIcon className={classes.errorIcon} />
    ) : (
      <CheckCircleOutlinedIcon className={classes.successIcon} />
    )

  return (
    <div className={classes.listItem}>
      <div className={classes.listItemGutter}>
        {execution.event ? <HbTooltip title={`Event Token: ${execution.event.token}`}>{icon}</HbTooltip> : icon}
        {index + 1 < listItems.length && <div className={classes.verticalRule} />}
      </div>
      <div className={classes.listItemContent}>
        <div className={classes.listItemTitle}>
          <HbText color="secondary" size="s" bold>
            {formatTimestamp(execution.createdAt)}
          </HbText>
          {execution.event && <EventableLink event={execution.event} />}
        </div>
        <HbText>{execution.ruleName}</HbText>
        {execution.details && <DetailsContainer ruleExecution={execution} />}
      </div>
    </div>
  )
})

const useActivityLogItemStyles = makeStyles((theme: Theme) => ({
  inner: {
    margin: theme.spacing(0, 1),
    flexFlow: 'row nowrap',
    overflowWrap: 'break-word',
    position: 'relative',
  },
}))
export default function ActivityLogItem(props: EventContainerProps) {
  const classes = useActivityLogItemStyles()
  return (
    <DynamicVirtualListItem {...props} classes={classes}>
      {ExecutionContainer}
    </DynamicVirtualListItem>
  )
}

ActivityLogItem.fragments = {
  ruleExecution: gql`
    fragment ActivityLogItemAutomationRuleExecution on AutomationRuleExecution {
      token
      status
      createdAt
      details
      ruleName
      event {
        __typename
        ... on BaseAutomationEvent {
          token
          eventableToken
          eventableHref
          domainType
        }
      }
    }
  `,
}

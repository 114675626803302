import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const FilteredListIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M13.6252 11.4294L21.5 4H2.5L10.3749 11.4295V20H13.6252V11.4294Z" />
  </SvgIcon>
)

export default FilteredListIcon

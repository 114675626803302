import { useCallback } from 'react'

import { useSelector } from 'actions/store'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'

import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'

import { useReviewsDashboardData } from './useReviewsDashboardData'

export const useGetIsRowSelected = () => {
  const selectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const { isRowSelected } = useDashboardConfig().table
  const getIsRowSelected = useCallback((row) => isRowSelected(row, selectedItems), [isRowSelected, selectedItems])
  return getIsRowSelected
}

export const useGetSelectedRows = () => {
  const { entries } = useReviewsDashboardData('cache-only')
  const getIsRowSelected = useGetIsRowSelected()

  return entries.filter(getIsRowSelected)
}

export const useGetSelectedRowsInformation = () => {
  const selectedRows = useGetSelectedRows()
  const batchSelectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const selectedRowTokens = new Set(selectedRows.flatMap((row) => row.reviews).map((review) => review.token))
  const batchSelectedNotOnPage = Object.entries(batchSelectedItems)
    .filter(([key, value]) => value && !selectedRowTokens.has(key))
    .map(([key]) => key)
  const numBatchSelectedNotOnPage = batchSelectedNotOnPage.length
  return { selectedRows, numBatchSelectedNotOnPage }
}

import pluralize from 'pluralize'

import { SubjectUnpaginated, SubjectsUnpaginated } from 'components/cases/report/case_information/PaginatedAssociations'
import { CaseOverviewSecondarySubject } from 'components/cases/report/case_information/types'
import { assertUnreachable } from 'types/hb'

import {
  CaseOverviewBusinessSubjectFragment,
  CaseOverviewFinancialInstitutionSubjectFragment,
  CaseOverviewPersonSubjectFragment,
  CaseOverviewProductSubjectFragment,
} from '../Overview/Subjects/__generated__/queries.generated'

export type FinancialInstitutionSubject = CaseOverviewFinancialInstitutionSubjectFragment

export type FinancialInstitutionSubjectUnpaginated = SubjectUnpaginated & {
  __typename: 'InvestigationSubjectFinancialInstitution'
}

export type FinancialInstitutionEntity = FinancialInstitutionSubject['financialInstitution']

export function financialInstitutionFromSubject(subject: Subject) {
  return subject.__typename === 'InvestigationSubjectFinancialInstitution' ? subject.financialInstitution : null
}

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export function financialInstitutionFromSubjectUnpaginated(subject: SubjectUnpaginated) {
  return subject.__typename === 'InvestigationSubjectFinancialInstitution' ? subject.financialInstitution : null
}

export const subjectHasFinancialInstitution = (subject: Subject): subject is FinancialInstitutionSubject =>
  !!financialInstitutionFromSubject(subject)

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const subjectHasFinancialInstitutionUnpaginated = (
  subject: SubjectUnpaginated
): subject is FinancialInstitutionSubjectUnpaginated => !!financialInstitutionFromSubjectUnpaginated(subject)

export type PersonSubject = CaseOverviewPersonSubjectFragment

export type PersonSubjectUnpaginated = SubjectUnpaginated & {
  __typename: 'InvestigationSubjectPerson'
}

export type PersonEntity = PersonSubject['person']

export type BusinessSubject = CaseOverviewBusinessSubjectFragment

export type BusinessSubjectUnpaginated = SubjectUnpaginated & {
  __typename: 'InvestigationSubjectBusiness'
}

export type BusinessEntity = BusinessSubject['business']

export type ProductSubject = CaseOverviewProductSubjectFragment

export type ProductSubjectUnpaginated = SubjectUnpaginated & {
  __typename: 'InvestigationSubjectProduct'
}

export type ProductEntity = ProductSubject['product']

type PrimarySubject = PersonSubject | BusinessSubject | ProductSubject | FinancialInstitutionSubject

export type Subject = PrimarySubject | CaseOverviewSecondarySubject
export type PrimaryEntity = PersonEntity | BusinessEntity | ProductEntity | FinancialInstitutionEntity

export const personEntityFromSubject = (subject: Subject) =>
  subject.__typename === 'InvestigationSubjectPerson' ? subject.person : null
export const businessEntityFromSubject = (subject: Subject) =>
  subject.__typename === 'InvestigationSubjectBusiness' ? subject.business : null
export const productEntityFromSubject = (subject: Subject) =>
  subject.__typename === 'InvestigationSubjectProduct' ? subject.product : null

export const personEntityFromSubjectUnpaginated = (subject: SubjectUnpaginated) =>
  subject.__typename === 'InvestigationSubjectPerson' ? subject.person : null
export const businessEntityFromSubjectUnpaginated = (subject: SubjectUnpaginated) =>
  subject.__typename === 'InvestigationSubjectBusiness' ? subject.business : null
export const productEntityFromSubjectUnpaginated = (subject: SubjectUnpaginated) =>
  subject.__typename === 'InvestigationSubjectProduct' ? subject.product : null

export const subjectHasPersonEntity = (subject: Subject): subject is PersonSubject => !!personEntityFromSubject(subject)
export const subjectHasBusinessEntity = (subject: Subject): subject is BusinessSubject =>
  !!businessEntityFromSubject(subject)
export const subjectHasProductEntity = (subject: Subject): subject is ProductSubject =>
  !!productEntityFromSubject(subject)

export const subjectHasPersonEntityUnpaginated = (subject: SubjectUnpaginated): subject is PersonSubjectUnpaginated =>
  !!personEntityFromSubjectUnpaginated(subject)
export const subjectHasBusinessEntityUnpaginated = (
  subject: SubjectUnpaginated
): subject is BusinessSubjectUnpaginated => !!businessEntityFromSubjectUnpaginated(subject)
export const subjectHasProductEntityUnpaginated = (subject: SubjectUnpaginated): subject is ProductSubjectUnpaginated =>
  !!productEntityFromSubjectUnpaginated(subject)

export type OtherEntity = CaseOverviewSecondarySubject

export type OtherEntityUnpaginated = SubjectUnpaginated & {
  __typename:
    | 'InvestigationSubjectBankAccount'
    | 'InvestigationSubjectCryptoAddress'
    | 'InvestigationSubjectDevice'
    | 'InvestigationSubjectPaymentCard'
}

export function otherEntityFromSubject(subject: Subject | CaseOverviewSecondarySubject) {
  switch (subject.__typename) {
    case 'InvestigationSubjectBankAccount':
      return subject.bankAccount
    case 'InvestigationSubjectCryptoAddress':
      return subject.cryptoAddress
    case 'InvestigationSubjectDevice':
      return subject.device
    case 'InvestigationSubjectPaymentCard':
      return subject.paymentCard
    default:
      return null
  }
}

export function otherEntityFromSubjectUnpaginated(subject: SubjectUnpaginated) {
  switch (subject.__typename) {
    case 'InvestigationSubjectBankAccount':
      return subject.bankAccount
    case 'InvestigationSubjectCryptoAddress':
      return subject.cryptoAddress
    case 'InvestigationSubjectDevice':
      return subject.device
    case 'InvestigationSubjectPaymentCard':
      return subject.paymentCard
    default:
      return null
  }
}

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const subjectHasOtherEntityUnpaginated = (subject: SubjectUnpaginated): subject is OtherEntityUnpaginated =>
  !!otherEntityFromSubjectUnpaginated(subject)

export function getEntityOwner(entity: ReturnType<typeof otherEntityFromSubject>) {
  if (!entity) {
    return null
  }

  switch (entity?.__typename) {
    case 'LibraryPaymentCard':
      return entity.accountHolder
    case 'LibraryDevice':
      return entity.owner
    case 'LibraryBankAccount':
      return entity.accountHolder
    case 'LibraryCryptoAddress':
      return entity.accountHolder
    default:
      return assertUnreachable(entity) as null
  }
}

export interface RelatedCaseSubjectShape {
  __typename: string
  token: string
}

export const getParentSubject = (subject: CaseOverviewSecondarySubject): RelatedCaseSubjectShape | null => {
  const otherEntity = otherEntityFromSubject(subject)
  const owner = getEntityOwner(otherEntity)
  return owner?.relatedCaseSubject || null
}
export const getEntityFromSubject = (s: Subject) =>
  financialInstitutionFromSubject(s) ||
  personEntityFromSubject(s) ||
  businessEntityFromSubject(s) ||
  productEntityFromSubject(s) ||
  otherEntityFromSubject(s)

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const getEntityFromSubjectUnpaginated = (s: SubjectUnpaginated) =>
  financialInstitutionFromSubjectUnpaginated(s) ||
  personEntityFromSubjectUnpaginated(s) ||
  businessEntityFromSubjectUnpaginated(s) ||
  productEntityFromSubjectUnpaginated(s) ||
  otherEntityFromSubjectUnpaginated(s)

export type Entity = NonNullable<ReturnType<typeof getEntityFromSubject>>

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const sortSubjectsUnpaginated = (subjects?: SubjectsUnpaginated) =>
  [...(subjects || [])].sort((a, b) => {
    const aAsEntity = getEntityFromSubjectUnpaginated(a)
    const bAsEntity = getEntityFromSubjectUnpaginated(b)
    if (!aAsEntity || !bAsEntity) return 0
    return aAsEntity.displayName.localeCompare(bAsEntity.displayName, 'en', { sensitivity: 'base' })
  })

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const categorizeSubjectsUnpaginated = (subjects?: SubjectsUnpaginated) => {
  const institutions: FinancialInstitutionSubjectUnpaginated[] = []
  const people: PersonSubjectUnpaginated[] = []
  const businesses: BusinessSubjectUnpaginated[] = []
  const products: ProductSubjectUnpaginated[] = []
  const other: OtherEntityUnpaginated[] = []
  subjects?.forEach((subject) => {
    if (subjectHasFinancialInstitutionUnpaginated(subject)) institutions.push(subject)
    if (subjectHasPersonEntityUnpaginated(subject)) people.push(subject)
    if (subjectHasBusinessEntityUnpaginated(subject)) businesses.push(subject)
    if (subjectHasProductEntityUnpaginated(subject)) products.push(subject)
    if (subjectHasOtherEntityUnpaginated(subject)) other.push(subject)
  })

  return { institutions, people, businesses, products, other }
}

/**
 * @deprecated
 * DO NOT USE. This relies on unpaginated case data.
 */
export const filterSubjectsUnpaginated = <S extends SubjectUnpaginated>(subjects: S[], searchTerm: string) => {
  const match = (subject: S) => {
    const asEntity = getEntityFromSubjectUnpaginated(subject)
    return asEntity?.displayName.toLowerCase().includes(searchTerm)
  }

  return subjects.filter(match)
}

export const getEntityCaseAndAlertCount = (entity: {
  relatedCases: { totalCount: number }
  organizationAlertCount?: number
}) => {
  const caseCount = entity.relatedCases.totalCount || 0
  let subtext = `${caseCount} ${pluralize('case', caseCount)}`
  if ('organizationAlertCount' in entity) {
    const alertCount = entity.organizationAlertCount
    subtext += ` • ${alertCount} ${pluralize('alert', alertCount)}`
  }
  return subtext
}

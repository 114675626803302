import { Fragment, useEffect } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { useFieldArray, FieldPathByValue, Controller, useFormContext } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'
import Loader from 'components/library/Loader'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { AddIcon, TrashOutlineIcon } from 'icons'
import { Theme, assertUnreachable } from 'types/hb'

import { checkIsRule, useAutomationRuleOrTemplate } from '../AutomationRuleOrTemplateContext'
import {
  type FormSchema,
  TriggerSchemaReturnType,
  getDefaultTriggerFilters,
  getDefaultTriggerGroupLine,
  getDefaultTriggerGroup,
} from '../formSchema'

import BinaryExpressionEditor, { FieldPath as BinaryExpressionFieldPath } from './BinaryExpressionEditor'
import { DottedLineLeaf } from './DottedLines'
import ListExpressionEditor, { FieldPath as ListExpressionFieldPath } from './ListExpressionEditor'
import { AutomationDomain, SingularFieldSpec, useDomainFieldSpecs, type FieldSpec } from './fieldConfig'

import { useThinControlStyles } from './styles'

import type {
  StrictExpressionValue,
  LogicalExpression,
  VariableNode,
  TypeCastVariable,
  TypeCastLiteral,
  LiteralNode,
  TypeCastType,
  TypeCastLiteralValue,
} from 'types/automations'

const useAddLogicBranchStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(1),
  },
}))

function AddLogicBranch({
  appendCondition,
  appendGroup,
}: {
  appendCondition: () => unknown
  appendGroup?: () => unknown
}) {
  const ruleOrTemplate = useAutomationRuleOrTemplate()
  const usage = useUsage()
  const classes = useAddLogicBranchStyles()

  const handleAppendCondition = () => {
    invariant(ruleOrTemplate)
    usage.logEvent({
      name: 'automations:editorAddCondition:clicked',
      data: checkIsRule(ruleOrTemplate)
        ? { automationRuleToken: ruleOrTemplate.token }
        : { automationRuleTemplateToken: ruleOrTemplate.token },
    })
    appendCondition()
  }

  const handleAppendGroup = () => {
    invariant(ruleOrTemplate)
    if (appendGroup) {
      usage.logEvent({
        name: 'automations:editorAddGroup:clicked',
        data: checkIsRule(ruleOrTemplate)
          ? { automationRuleToken: ruleOrTemplate.token }
          : { automationRuleTemplateToken: ruleOrTemplate.token },
      })
      appendGroup()
    }
  }

  return (
    <div className={classes.root}>
      <HbButton Icon={AddIcon} label="Condition" variant="textPrimary" size="small" onClick={handleAppendCondition} />
      {appendGroup ? (
        <HbButton Icon={AddIcon} label="Group" variant="textPrimary" size="small" onClick={handleAppendGroup} />
      ) : null}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  condition: {
    display: 'grid',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(-1),
  },
  firstCondition: {
    gridTemplateColumns: '1fr auto',
  },
  additionalCondition: {
    gridTemplateColumns: 'auto minmax(0, 1fr) auto',
  },
  nestedCondition: {
    position: 'relative',
  },
  operator: {
    flexShrink: 1,
    marginTop: theme.spacing(1),
  },
  deleteIcon: {
    position: 'relative',
    top: theme.spacing(1),
    color: theme.palette.styleguide.textGreyLight,
  },
}))

type GroupFieldPath = FieldPathByValue<FormSchema, LogicalExpression>
type LineFieldPath = FieldPathByValue<FormSchema, LogicalExpression['values'][number]>

function createDefaultLiteralNodeForFieldSpec(
  fieldSpec: SingularFieldSpec & { cast: NonNullable<TypeCastType> }
): TypeCastLiteralValue {
  switch (fieldSpec.cast) {
    case 'money':
      return {
        type: 'literal',
        value: {
          amount: '0',
          currency: 'USD',
        },
      }
    case 'date':
      return {
        type: 'literal',
        value: {
          day: 1,
          month: 1,
          year: 1950,
        },
      }
    case 'decimal':
      return {
        type: 'literal',
        value: 0,
      }
    default:
      return assertUnreachable(fieldSpec.cast)
  }
}

interface LineProps {
  name: LineFieldPath
  domain: AutomationDomain
  form: TriggerSchemaReturnType
  appendGroup?: () => unknown
  onRemove: () => unknown
  parentVariableNode?: VariableNode | null
  defaultPath?: string[] | null
  nested?: boolean
}

function LogicalExpressionCondition({
  name,
  domain,
  form,
  appendGroup,
  onRemove,
  parentVariableNode,
  defaultPath,
  nested,
}: LineProps) {
  const usage = useUsage()
  const ruleOrTemplate = useAutomationRuleOrTemplate()
  const classes = useStyles()
  const controlStyles = useThinControlStyles()
  const { loading, getDomainFieldSpec } = useDomainFieldSpecs()

  const conditionsArray = useFieldArray({ name: `${name}.values` })

  useEffect(() => {
    if (conditionsArray.fields.length === 0) {
      onRemove()
    }
  }, [conditionsArray.fields.length, onRemove])

  const { watch } = useFormContext<FormSchema>()
  const condition = watch(name)

  invariant('values' in condition && Array.isArray(condition.values))

  if (loading) {
    return <Loader variant="global" />
  }

  const domainFieldSpec = getDomainFieldSpec(domain)
  invariant(domainFieldSpec)

  return (
    <>
      {conditionsArray.fields.map((item: { id: string } & StrictExpressionValue, index) => {
        const conditionName = `${name}.values.${index}` as BinaryExpressionFieldPath | ListExpressionFieldPath

        const handleChangeExpression = (newNode: StrictExpressionValue) => {
          // .update does not correctly re-render the expression. Doing remove and insert to force an update.
          conditionsArray.remove(index)
          conditionsArray.insert(index, newNode)
        }

        const handleChangeToBinaryExpression = (variablePath: string[]) => {
          handleChangeExpression(
            getDefaultTriggerGroupLine(domain.type, domainFieldSpec, variablePath, !parentVariableNode)
          )
        }

        const handleChangeToListExpression = (variablePath: string[], expressionPath: string[] | null | undefined) => {
          handleChangeExpression({
            type: 'list_expression',
            operator: 'any',
            value: { type: 'variable', path: variablePath },
            expression: getDefaultTriggerFilters(domain.type, domainFieldSpec, expressionPath, false),
          })
        }

        const handleChangeToLinkedTrigger = (newPath: string[], subDomain: AutomationDomain) => {
          const subDomainFieldSpec = getDomainFieldSpec(subDomain)
          invariant(subDomainFieldSpec)

          // Bit of a weird one, but the variable we use for linked triggers uses "linked.<domain>"
          // instead of event.<domain>
          const newPathWithLinked = ['linked', ...newPath.slice(1)]

          handleChangeExpression({
            type: 'linked_trigger',
            operator: 'any',
            domain_type: subDomain.type,
            trigger_token: null,
            value: { type: 'variable', path: newPathWithLinked },
            expression: getDefaultTriggerFilters(subDomain.type, subDomainFieldSpec),
          })
        }

        const handleChangeToTypeCast = (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => {
          if (
            ('cast' in lastFieldSpec && 'cast' in newFieldSpec && lastFieldSpec.cast === newFieldSpec.cast) ||
            !('cast' in newFieldSpec) ||
            !newFieldSpec.cast
          ) {
            return
          }

          // TODO(ali): better handling of default values instead of nesting them within components
          // We can leverage the onChangeExpression API to handle better setting/clearing of literals/variables
          const newTypeCastLiteralNode: TypeCastLiteral = {
            type: 'type_cast',
            target_type: newFieldSpec.cast,
            value: createDefaultLiteralNodeForFieldSpec(newFieldSpec as any),
          }

          const newTypeCastVariableNode: TypeCastVariable = {
            type: 'type_cast',
            target_type: newFieldSpec.cast,
            value: { type: 'variable', path: newPath },
          }

          handleChangeExpression({
            type: 'binary_expression',
            operator: newFieldSpec.operators[0],
            left: newTypeCastVariableNode,
            right: newTypeCastLiteralNode,
          })
        }

        const handleChangeFromTypeCast = (newPath: string[], lastFieldSpec: FieldSpec, newFieldSpec: FieldSpec) => {
          if (!('cast' in lastFieldSpec) || !('operators' in newFieldSpec)) {
            return
          }

          const newLiteralNode: LiteralNode = {
            type: 'literal',
            value: '',
          }

          // TODO(ali): We should be able to handle changes to list expressions too. We don't have any list
          // fields on cases or reviews yet, so punting until we do
          handleChangeExpression({
            type: 'binary_expression',
            operator: newFieldSpec.operators[0],
            left: { type: 'variable', path: newPath },
            right: newLiteralNode,
          })
        }

        return (
          <DottedLineLeaf key={item.id} transform={(rect) => ({ x: rect.x, y: rect.y + 27 })}>
            {(dottedLineLeafRef) => (
              <div
                ref={dottedLineLeafRef}
                className={classNames(
                  classes.condition,
                  nested && classes.nestedCondition,
                  index === 0 ? classes.firstCondition : classes.additionalCondition
                )}
              >
                {index > 0 ? (
                  <Controller<FormSchema>
                    name={`${name}.operator` as FieldPathByValue<FormSchema, 'and' | 'or'>}
                    render={({ field }) => (
                      <Select
                        className={classNames(classes.operator, controlStyles.control)}
                        required
                        inputProps={field}
                        variant="outlined"
                        disabled={index > 1}
                      >
                        <MenuItem value="and">And</MenuItem>
                        <MenuItem value="or">Or</MenuItem>
                      </Select>
                    )}
                  />
                ) : null}
                {item.type === 'binary_expression' ? (
                  <BinaryExpressionEditor
                    name={conditionName}
                    domain={domain}
                    parentVariableNode={parentVariableNode}
                    form={form}
                    onChangeToListExpression={handleChangeToListExpression}
                    onChangeToLinkedTrigger={handleChangeToLinkedTrigger}
                    onChangeToBinaryExpression={handleChangeToBinaryExpression}
                    onChangeToTypeCast={handleChangeToTypeCast}
                    onChangeFromTypeCast={handleChangeFromTypeCast}
                  />
                ) : item.type === 'list_expression' || item.type === 'linked_trigger' ? (
                  <ListExpressionEditor
                    name={conditionName}
                    parentVariableNode={parentVariableNode}
                    domain={domain}
                    form={form}
                    onChangeToBinaryExpression={handleChangeToBinaryExpression}
                    onChangeToLinkedTrigger={handleChangeToLinkedTrigger}
                    onChangeToListExpression={handleChangeToListExpression}
                    onChangeToTypeCast={handleChangeToTypeCast}
                    onChangeFromTypeCast={handleChangeFromTypeCast}
                  />
                ) : null}
                <HbButton
                  Icon={TrashOutlineIcon}
                  onClick={() => {
                    invariant(ruleOrTemplate)
                    usage.logEvent({
                      name: 'automations:editorRemoveCondition:clicked',
                      data: checkIsRule(ruleOrTemplate)
                        ? { automationRuleToken: ruleOrTemplate.token }
                        : { automationRuleTemplateToken: ruleOrTemplate.token },
                    })
                    conditionsArray.remove(index)
                  }}
                  variant="textSecondary"
                  iconOnly
                  label="Delete"
                  className={classes.deleteIcon}
                />
              </div>
            )}
          </DottedLineLeaf>
        )
      })}
      <DottedLineLeaf transform={(rect) => ({ x: rect.x, y: rect.y + 18 })}>
        {(dottedLineLeafRef) => (
          <div ref={dottedLineLeafRef}>
            <AddLogicBranch
              appendCondition={() =>
                conditionsArray.append(
                  getDefaultTriggerGroupLine(domain.type, domainFieldSpec, defaultPath, !parentVariableNode),
                  {
                    shouldFocus: false,
                  }
                )
              }
              appendGroup={appendGroup}
            />
          </div>
        )}
      </DottedLineLeaf>
    </>
  )
}

interface Props {
  name: GroupFieldPath
  domain: AutomationDomain
  form: TriggerSchemaReturnType
  parentVariableNode?: VariableNode | null
  defaultPath?: string[] | null
  nested?: boolean
}

export default function LogicalExpressionEditor({
  name,
  domain,
  form,
  parentVariableNode,
  defaultPath,
  nested,
}: Props) {
  const usage = useUsage()
  const ruleOrTemplate = useAutomationRuleOrTemplate()
  const classes = useStyles()
  const controlStyles = useThinControlStyles()
  const groupsArray = useFieldArray({ name: `${name}.values` })

  const { loading, getDomainFieldSpec } = useDomainFieldSpecs()

  useEffect(() => {
    if (groupsArray.fields.length === 0 && !loading) {
      const domainFieldSpec = getDomainFieldSpec(domain)
      invariant(domainFieldSpec)

      groupsArray.append(getDefaultTriggerGroup(domain.type, domainFieldSpec, defaultPath, !parentVariableNode), {
        shouldFocus: false,
      })
    }
  }, [domain, loading, getDomainFieldSpec, groupsArray, defaultPath, parentVariableNode])

  const handleAppendGroup = () => {
    const domainFieldSpec = getDomainFieldSpec(domain)
    invariant(domainFieldSpec)

    groupsArray.append(getDefaultTriggerGroup(domain.type, domainFieldSpec, defaultPath, !parentVariableNode), {
      shouldFocus: false,
    })
  }

  if (loading) {
    return <Loader variant="global" />
  }

  return (
    <>
      {groupsArray.fields.map((item, index) => (
        <Fragment key={item.id}>
          {groupsArray.fields.length > 1 ? (
            index > 0 ? (
              <Controller<FormSchema>
                name={`${name}.operator` as FieldPathByValue<FormSchema, 'and' | 'or'>}
                render={({ field }) => (
                  <DottedLineLeaf key={item.id} transform={(rect) => ({ x: rect.x, y: rect.y + rect.height / 2 })}>
                    {(dottedLineLeafRef) => (
                      <Select
                        className={classNames(classes.operator, controlStyles.control)}
                        required
                        inputProps={field}
                        variant="outlined"
                        disabled={index > 1}
                        ref={dottedLineLeafRef}
                      >
                        <MenuItem value="and">And</MenuItem>
                        <MenuItem value="or">Or</MenuItem>
                      </Select>
                    )}
                  </DottedLineLeaf>
                )}
              />
            ) : null
          ) : null}
          <LogicalExpressionCondition
            parentVariableNode={parentVariableNode}
            name={`${name}.values.${index}` as LineFieldPath}
            domain={domain}
            form={form}
            defaultPath={defaultPath}
            appendGroup={index === groupsArray.fields.length - 1 ? handleAppendGroup : undefined}
            onRemove={() => {
              invariant(ruleOrTemplate)
              groupsArray.remove(index)
              usage.logEvent({
                name: 'automations:editorRemoveCondition:clicked',
                data: checkIsRule(ruleOrTemplate)
                  ? { automationRuleToken: ruleOrTemplate.token }
                  : { automationRuleTemplateToken: ruleOrTemplate.token },
              })
            }}
            nested={nested}
          />
        </Fragment>
      ))}
    </>
  )
}

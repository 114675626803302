import { getIn, FormikProps, FieldConfig } from 'formik'
import { values as objValues, isArray, isEmpty } from 'lodash'

interface ServerData {
  validation?: { errors?: { [key: string]: string[] } }
}

export function getServerError(data: ServerData, name: string) {
  if (!data.validation || !data.validation.errors) {
    return null
  }
  // The JS/Formik notation is name.0.field or name[0].field
  // in Ruby/Rails land, we use name[0][field]. This converts from the JS/Formik
  // notation to the Ruby/Rails notation so that we check both. We should probably move to the
  // JS notation universally, but this allows that to happen in piecemeal.
  const rubifiedName = name.replace(/\.([^[.]+)/g, '[$1]')
  const error = data.validation.errors[name] || data.validation.errors[rubifiedName]
  if (isEmpty(error)) {
    return null
  }
  if (isArray(error)) {
    return error.join(' ')
  }

  return objValues(error).join(' ')
}

export function getErrorDetails(field: FieldConfig, form: FormikProps<any>) {
  const { name } = field
  const { touched, errors, values, submitCount } = form
  const fieldTouched = getIn(touched, name)
  const serverError = getServerError(values, name)
  const clientError = getIn(errors, name)

  // If the field was touched, show the error immediately
  // If the user submitted show all errors, even the field hasn't been touched yet
  // If we're not showing a client error, always show a server error if there is one
  const showClientError = fieldTouched || submitCount > 0
  const fieldError = showClientError && clientError ? clientError : serverError
  const showError = !!fieldError

  return { fieldError, showError }
}

export const formikNameString = (name: string, index: number, field?: string) => {
  if (field) {
    return `${name}[${index}].${field}`
  }

  return `${name}.${index}`
}

export function touchedAndValid(field: { name: string }, form: FormikProps<any>) {
  const { name } = field
  const { touched, errors } = form
  return getIn(touched, name) && !getIn(errors, name)
}

import { useEffect } from 'react'

// Most browsers by default will not let you display this message, but put it in here in case.
export const optionalMessage =
  'If you navigate away from this page before submitting your data, the changes will be lost.'

/**
 * Show browser default prompt if page is unloaded due to a page refresh or navigation.
 * Forms would likely be the main use case.
 * Note that different browsers implement this slightly differently.
 * ie. Chrome won't show this prompt if it does not detect any prior user interaction.
 */
export function useBeforeUnloadPrompt(shouldShowPrompt: boolean = true) {
  useEffect(() => {
    const showPrompt = (e: BeforeUnloadEvent) => {
      if (shouldShowPrompt) {
        // Cancel the event
        e.preventDefault() // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        // Chrome requires returnValue to be set
        /* eslint-disable-next-line no-param-reassign */
        e.returnValue = optionalMessage
      } else {
        /* eslint-disable-next-line no-param-reassign */
        delete e.returnValue
      }
    }

    window.addEventListener('beforeunload', showPrompt)

    return () => {
      window.removeEventListener('beforeunload', showPrompt)
    }
  }, [shouldShowPrompt])
}

interface BeforeUnloadPrompt {
  shouldShowPrompt: boolean
}
/**
 * Convenience component for using BeforeUnloadPrompt hook
 */
export function BeforeUnloadPrompt({ shouldShowPrompt }: BeforeUnloadPrompt): null {
  useBeforeUnloadPrompt(shouldShowPrompt)
  return null
}

import { ReactNode } from 'react'

import { Divider, Slide } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import pluralize from 'pluralize'

import { useDispatch } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { PageHeader } from 'components/library/Page/PageHeader'
import { mergeOverrideStyles } from 'components/utils/styles'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { Theme } from 'types/hb'

import { useBatchActionSelection } from './useBatchActionSelection'

const useStyles = makeStyles((theme: Theme) => ({
  clearSelectionButton: {
    marginLeft: theme.spacing(),
    color: theme.palette.text.white,
  },
  actionsContainer: {
    display: 'flex',
    flexFlow: 'row wrap',
    flex: '1',
    justifyContent: 'space-between',
  },
  headerContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  divider: {
    backgroundColor: theme.palette.background.light,
    opacity: 0.25,
  },
}))

interface Props {
  batchActionsMenu: ReactNode
  overrideClasses?: Partial<ReturnType<typeof useStyles>>
  title: string
}

export const BatchActionsMenuHeader = ({ batchActionsMenu, overrideClasses, title }: Props) => {
  const dispatch = useDispatch()

  const { isBatchSelectMenuActive, numBatchItemsSelectedDisplay } = useBatchActionSelection()

  const handleClearSelection = () => {
    dispatch(batchActionsActions.batchSelectItems.clear())
  }

  const classes = useStyles()
  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)

  return (
    <Slide direction="down" in={isBatchSelectMenuActive} style={{ transformOrigin: '0 0 0' }}>
      <PageHeader
        absolute
        variant="inverted"
        title={`${numBatchItemsSelectedDisplay || 0} ${pluralize(title, numBatchItemsSelectedDisplay)} selected`}
        titleRow={
          <div className={mergedClasses.headerContainer}>
            <Divider orientation="vertical" variant="inset" className={mergedClasses.divider} />
            <div className={mergedClasses.actionsContainer}>
              {batchActionsMenu}
              <HbButton
                variant="textSecondary"
                className={mergedClasses.clearSelectionButton}
                label="Clear selection"
                onClick={handleClearSelection}
              />
            </div>
          </div>
        }
      />
    </Slide>
  )
}

import { FormControlLabel, Switch } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

export interface Props {
  value: boolean
  onChange: (value: boolean) => unknown
}

export default function ViewPlainTextPlugin({ value, onChange }: Props) {
  return (
    <FormControlLabel
      control={<Switch size="small" value={value} onChange={() => onChange(!value)} sx={{ ml: 2, mr: 1 }} />}
      label={<HbText color="secondary">View plain text</HbText>}
    />
  )
}

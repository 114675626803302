import { useCallback, useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'

import { enqueueReduxSnackbar } from 'actions/applicationActions'
import { useDispatch } from 'actions/store'
import { filterNullItem } from 'helpers/objHelpers'
import { displayAddressFragments } from 'helpers/uiHelpers'
import { LibraryTypeEnum } from 'types/api'

import { CompareStepDialog } from './ComparisonStep/ComparisonStepDialog'

import { ConfirmStepDialog } from './ConfirmStepDialog'
import { InitialStepDialog } from './InitialStepDialog'
import { GetSubjectsQuery, GetSubjectsQueryVariables } from './__generated__/MergeSubjectsDialog.generated'
import { getMergeEntityFromSubject } from './helpers'
import { ConfirmMerge, MergeStep, MergeSubject, SelectedSubjectsTokens } from './types'

const MERGE_ENTITY_BASE_FRAGMENT = gql`
  fragment MergeEntityBase on LibraryObject {
    __typename
    createdAt
    displayName
    externalId
    tags {
      ...EntityTag
    }
    relatedCases {
      totalCount
    }
    token
    updatedAt
  }

  fragment EntityTag on LibraryTag {
    token
    tagDefinition {
      token
      archived
      label
      color
    }
  }
`

export const MERGE_PERSON_FRAGMENT = gql`
  fragment MergePerson on LibraryPerson {
    ...MergeEntityBase
    organizationAlertCount
    birthdate
    gender
    tins {
      type
      number
    }
  }

  ${MERGE_ENTITY_BASE_FRAGMENT}
`

export const MERGE_BUSINESS_FRAGMENT = gql`
  fragment MergeBusiness on LibraryBusiness {
    ...MergeEntityBase
    organizationAlertCount
    businessType {
      label
      naicsCode
    }
    dbaNames
    legalNames
    tins {
      type
      number
    }
  }

  ${MERGE_ENTITY_BASE_FRAGMENT}
`

export const MERGE_FINANCIAL_INSTITUTION_FRAGMENT = gql`
  fragment MergeFinancialInstitution on LibraryFinancialInstitution {
    ...MergeEntityBase
    alternateNames
    name
    type
    address {
      ...displayAddressAddress
    }
    primaryFederalRegulator
  }
  ${MERGE_ENTITY_BASE_FRAGMENT}
  ${displayAddressFragments.address}
`

const GET_SUBJECTS_QUERY = gql`
  query GetSubjects($caseToken: String!, $libraryType: LibraryTypeEnum!, $firstToken: ID!, $secondToken: ID!) {
    investigation(token: $caseToken) {
      token
      firstSubject: subject(type: $libraryType, token: $firstToken) {
        ...MergeSubject
      }
      secondSubject: subject(type: $libraryType, token: $secondToken) {
        ...MergeSubject
      }
    }
  }

  fragment MergeSubject on InvestigationSubject {
    token
    ... on InvestigationSubjectPerson {
      corroborativeStatement
      roleInActivity
      person {
        ...MergePerson
      }
    }
    ... on InvestigationSubjectBusiness {
      roleInActivity
      business {
        ...MergeBusiness
      }
    }
    ... on InvestigationSubjectFinancialInstitution {
      roleInTransaction
      financialInstitution {
        ...MergeFinancialInstitution
      }
    }
  }

  ${MERGE_PERSON_FRAGMENT}
  ${MERGE_BUSINESS_FRAGMENT}
  ${MERGE_FINANCIAL_INSTITUTION_FRAGMENT}
`

interface InternalProps {
  caseToken: string
  confirmMerge: ConfirmMerge
  libraryType: LibraryTypeEnum
  mergeLoading: boolean
  onClose: () => void
  selectedSubjectsTokens: SelectedSubjectsTokens
  selectedPrimarySubjectToken: string | null
  setSelectedPrimarySubjectToken: (selected: string) => void
  setMergeStep: (step: MergeStep) => void
  mergeStep: MergeStep
}

const MergeSubjectsDialogInternal = ({
  caseToken,
  confirmMerge,
  libraryType,
  mergeLoading,
  mergeStep,
  onClose: handleClose,
  selectedSubjectsTokens,
  selectedPrimarySubjectToken,
  setMergeStep,
  setSelectedPrimarySubjectToken,
}: InternalProps) => {
  const onClose = useCallback(() => {
    handleClose()
    setMergeStep('initial')
  }, [handleClose, setMergeStep])

  const dispatch = useDispatch()

  const [firstToken, secondToken] = useMemo(() => Array.from(selectedSubjectsTokens), [selectedSubjectsTokens])

  const { data, error, loading } = useQuery<GetSubjectsQuery, GetSubjectsQueryVariables>(GET_SUBJECTS_QUERY, {
    variables: {
      caseToken,
      libraryType,
      firstToken,
      secondToken,
    },
    onError({ message: errorMessage }) {
      dispatch(enqueueReduxSnackbar(errorMessage, { variant: 'error' }))
      onClose()
    },
  })

  const selectedSubjects = useMemo<MergeSubject[]>(() => {
    const { firstSubject, secondSubject } = data?.investigation ?? {}
    return [firstSubject, secondSubject].filter(filterNullItem)
  }, [data?.investigation])

  const selectedEntities = useMemo(
    () => selectedSubjects.map(getMergeEntityFromSubject).filter(filterNullItem),
    [selectedSubjects]
  )

  const selectedPrimarySubject = useMemo(
    () => selectedSubjects.find((subject) => subject?.token === selectedPrimarySubjectToken),
    [selectedSubjects, selectedPrimarySubjectToken]
  )

  const selectedPrimaryEntity = useMemo(
    () => getMergeEntityFromSubject(selectedPrimarySubject),
    [selectedPrimarySubject]
  )

  const selectedPrimaryEntityToken = selectedPrimaryEntity?.token || null

  if (error || !selectedSubjects.length) return null

  const sharedDialogProps = {
    libraryType,
    onClose,
    open: true,
    setMergeStep,
  }

  if (mergeStep === 'initial') {
    return <InitialStepDialog loading={loading} selectedEntities={selectedEntities} {...sharedDialogProps} />
  }

  if (mergeStep === 'compare') {
    return (
      <CompareStepDialog
        {...sharedDialogProps}
        selectedSubjects={selectedSubjects}
        selectedPrimarySubjectToken={selectedPrimarySubjectToken}
        setSelectedPrimarySubjectToken={setSelectedPrimarySubjectToken}
      />
    )
  }

  if (mergeStep === 'confirm') {
    return (
      <ConfirmStepDialog
        {...sharedDialogProps}
        caseToken={caseToken}
        confirmMerge={confirmMerge}
        mergeLoading={mergeLoading}
        selectedEntities={selectedEntities}
        selectedPrimaryEntityToken={selectedPrimaryEntityToken}
      />
    )
  }

  return null
}

export interface Props extends InternalProps {
  open: boolean
}

export const MergeSubjectsDialog = ({ open, ...restProps }: Props) => {
  if (!open) return null

  return <MergeSubjectsDialogInternal {...restProps} />
}

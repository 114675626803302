import { useMemo } from 'react'

import { Work } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'

import { ManagedObjectIcon } from 'components/library/ManagedObjectIcon'
import { useIsLinkedFi } from 'dashboards/profiles/hooks/useIsLinkedFi'
import { getEnums } from 'helpers/stateHelpers'
import { displayAddress } from 'helpers/uiHelpers'
import { VerifiedUserIcon } from 'icons'
import { CalendarIcon } from 'icons/CalendarIcon'
import { IdFieldIcon } from 'icons/IdFieldIcon'

import { LocationFieldIcon } from 'icons/LocationFieldIcon'
import { Theme } from 'types/hb'

import { ContentComponentsMap, MergeFinancialInstitutionEntity, MergeFinancialInstitutionSubject } from '../types'
import { SetSelectedPrimarySubjectToken } from '../useSubjectMerging'

import { DetailsSection, SharedEntityHeader, SimpleDetail, CompareTableLayout } from './shared'

const useFinancialInstitutionRoleContentStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.styleguide.gray300}`,
    borderRadius: theme.shape.largeContainer.borderRadius,
  },
  detail: {
    padding: theme.spacing(1, 0),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      paddingBottom: theme.spacing(0),
    },
  },
}))

interface FinancialInstitutionRoleContentProps {
  subject: MergeFinancialInstitutionSubject
}

const FinancialInstitutionRoleContent = ({ subject }: FinancialInstitutionRoleContentProps) => {
  const enums = useSelector(getEnums)

  const classes = useFinancialInstitutionRoleContentStyles()

  const roleInTransaction = useMemo(
    () => enums.roleInTransaction.find(({ value }) => value === subject.roleInTransaction)?.display,
    [subject.roleInTransaction, enums]
  )

  return (
    <section className={classes.container} aria-label="Role">
      <HbText className={classes.detail} color={!roleInTransaction ? 'disabled' : 'primary'} tag="div">
        {roleInTransaction || 'Role in transaction'}
      </HbText>
    </section>
  )
}

interface FinancialInstitutionBasicInfoProps {
  entity: MergeFinancialInstitutionEntity
}

export const FinancialInstitutionBasicInfo = ({ entity }: FinancialInstitutionBasicInfoProps) => {
  if (!entity) return null
  return (
    <DetailsSection heading="Basic Information">
      <SimpleDetail Icon={IdFieldIcon} value={entity.displayName} />
      <SimpleDetail fallbackLabel="External ID" Icon={VerifiedUserIcon} value={entity.externalId} />
      <SimpleDetail fallbackLabel="Alternate Name" Icon={IdFieldIcon} value={entity.alternateNames} />
      <SimpleDetail
        fallbackLabel="Address"
        Icon={LocationFieldIcon}
        value={entity.address ? displayAddress(entity.address) : null}
      />
      <SimpleDetail fallbackLabel="Primary federal regulator" Icon={Work} value={entity.primaryFederalRegulator} />
    </DetailsSection>
  )
}

const useLinkedFinancialInstitutionBannerStyles = makeStyles((theme: Theme) => ({
  banner: {
    width: `calc(100% + ${theme.spacing(2)})`,
    padding: theme.spacing(),
    boxSizing: 'border-box',
    background: theme.palette.styleguide.backgroundDark,
    textAlign: 'center',
    left: theme.spacing(-1),
    borderTopLeftRadius: theme.shape.borderRadius,
    borderTopRightRadius: theme.shape.borderRadius,
    position: 'relative',
  },
}))

export const LinkedFinancialInstitutionBanner = () => {
  const classes = useLinkedFinancialInstitutionBannerStyles()

  return (
    <div className={classes.banner}>
      <HbText>Linked institutions must be primary</HbText>
    </div>
  )
}

interface FinancialInstitutionsCompareTableProps {
  selectedSubjects: MergeFinancialInstitutionSubject[]
  selectedPrimarySubjectToken: string | null
  setSelectedPrimarySubjectToken: SetSelectedPrimarySubjectToken
}

export const FinancialInstitutionsCompareTable = ({
  selectedSubjects,
  selectedPrimarySubjectToken,
  setSelectedPrimarySubjectToken,
}: FinancialInstitutionsCompareTableProps) => {
  const isLinkedFi = useIsLinkedFi()
  const linkedInstitutionSubjectToken = selectedSubjects.find((subject) => {
    const entity = subject.financialInstitution
    if (!entity) return false
    return isLinkedFi(entity.token)
  })?.token

  const rows = useMemo(() => {
    if (!linkedInstitutionSubjectToken) return ['header', 'role', 'basicInfo', 'createdAt', 'updatedAt'] as const
    // only show the banner row if one of the selected subjects is a linked fi
    return ['isLinked', 'header', 'role', 'basicInfo', 'createdAt', 'updatedAt'] as const
  }, [linkedInstitutionSubjectToken])

  const contentComponents = useMemo<ContentComponentsMap<typeof rows, MergeFinancialInstitutionSubject>>(
    () => ({
      isLinked: ({ datum }) => {
        const entity = datum.financialInstitution
        if (!entity || !linkedInstitutionSubjectToken) return null
        const isInstitutionLinked = isLinkedFi(entity.token)
        if (!isInstitutionLinked) return null
        return <LinkedFinancialInstitutionBanner />
      },
      header: ({ datum }) => {
        const entity = datum.financialInstitution
        if (!entity) return null
        const isInstitutionLinked = isLinkedFi(entity.token)
        const isLinkedInstitutionSubjectSelected = !!linkedInstitutionSubjectToken
        const isLinkedInstitutionSubject = linkedInstitutionSubjectToken === datum.token
        const canBePrimary = !isLinkedInstitutionSubjectSelected || isLinkedInstitutionSubject
        return (
          <SharedEntityHeader
            adornment={
              isInstitutionLinked ? (
                <ManagedObjectIcon managedObjectSubtitle={entity.displayName} managedObjectTitle="Filing Institution" />
              ) : null
            }
            disabled={!canBePrimary}
            isSelected={datum.token === selectedPrimarySubjectToken}
            setSelected={() => setSelectedPrimarySubjectToken(datum.token)}
            entity={entity}
          />
        )
      },
      role: ({ datum }) => <FinancialInstitutionRoleContent subject={datum} />,
      basicInfo: ({ datum }) => {
        const entity = datum.financialInstitution
        if (!entity) return null
        return <FinancialInstitutionBasicInfo entity={entity} />
      },
      createdAt: ({ datum }) => {
        const entity = datum.financialInstitution
        if (!entity) return null
        return (
          <DetailsSection heading="Created At">
            <SimpleDetail Icon={CalendarIcon} value={entity.createdAt} />
          </DetailsSection>
        )
      },
      updatedAt: ({ datum }) => {
        const entity = datum.financialInstitution
        if (!entity) return null
        return (
          <DetailsSection heading="Updated At">
            <SimpleDetail Icon={CalendarIcon} value={entity.updatedAt} />
          </DetailsSection>
        )
      },
    }),
    [isLinkedFi, linkedInstitutionSubjectToken, selectedPrimarySubjectToken, setSelectedPrimarySubjectToken]
  )

  return (
    <CompareTableLayout
      contentComponents={contentComponents}
      data={selectedSubjects}
      rows={rows}
      selectedIndex={selectedSubjects.findIndex((s) => s.token === selectedPrimarySubjectToken)}
    />
  )
}

import React, { ReactNode } from 'react'

import { Close } from '@mui/icons-material'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import Loader from 'components/library/Loader'
import { stopEventPropagation } from 'helpers/uiHelpers'
import { Theme } from 'types/hb'

const HORIZONTAL_PADDING_THEME_SIZE = 3

const useHeaderStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    columnGap: theme.spacing(1),
    padding: theme.spacing(2, HORIZONTAL_PADDING_THEME_SIZE),

    '&$noIcon': {
      gridTemplateColumns: 'auto',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    flex: '1 1 auto',
  },
  icon: {
    width: 22,
    placeSelf: 'center',
  },
  noIcon: {},
}))

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    width: '100vw',
    maxWidth: 'calc(100vw - 80px)',
    overflow: 'visible',
  },
  content: {
    padding: theme.spacing(3),
  },
  footer: {
    margin: 0,
    padding: theme.spacing(2, HORIZONTAL_PADDING_THEME_SIZE),
    borderTop: `1px solid ${theme.palette.dividers.light}`,
  },
}))

interface HeaderProps {
  title: string
  onClose: (event: React.MouseEvent) => void
  IconComponent?: React.ComponentType<{ className?: string }>
  headerContent?: ReactNode
  classes?: { header?: string }
}

export function ComplexDialogHeader(props: HeaderProps) {
  const { title, IconComponent, headerContent, classes = {}, onClose } = props

  const { header, icon: iconStyle, title: titleStyle, titleContainer, noIcon } = useHeaderStyles()

  return (
    <DialogTitle
      className={classnames(header, classes.header, {
        [noIcon]: !IconComponent,
      })}
    >
      {IconComponent && <IconComponent className={iconStyle} />}
      <div className={titleContainer}>
        <HbText className={titleStyle} size="xl" noWrap>
          {title}
        </HbText>
        <HbButton label="Close modal" iconOnly Icon={Close} onClick={onClose} />
      </div>
      {headerContent}
    </DialogTitle>
  )
}

export function ComplexDialogContent({ className, children }: { className?: string; children: ReactNode }) {
  const { content } = useStyles()

  return <DialogContent className={classnames(content, className)}>{children}</DialogContent>
}

export function ComplexDialogActions({ children, className }: { children: ReactNode; className?: string }) {
  const { footer: footerStyle } = useStyles()

  return <DialogActions classes={{ root: classnames(footerStyle, className) }}>{children}</DialogActions>
}

export interface Props {
  title: string
  onClose: () => void
  headerContent?: ReactNode
  IconComponent?: React.ComponentType<{ className: string }>
  footer: ReactNode
  children: ReactNode
  loading?: boolean
  classes?: { header?: string; content?: string; paper?: string; footer?: string }
}

export function ComplexDialog(props: Props) {
  const { IconComponent, title, onClose, footer, classes = {}, headerContent, loading, children } = props

  const { paper } = useStyles()

  return (
    <Dialog
      data-testid="information_dialog"
      open
      classes={{ paper: classnames(paper, classes.paper) }}
      onClose={onClose}
      onKeyDown={stopEventPropagation}
    >
      <ComplexDialogHeader
        title={title}
        IconComponent={IconComponent}
        headerContent={headerContent}
        classes={classes}
        onClose={onClose}
      />
      <ComplexDialogContent className={classes.content}>{children}</ComplexDialogContent>
      {loading && <Loader variant="local" />}
      <ComplexDialogActions className={classes.footer}>{footer}</ComplexDialogActions>
    </Dialog>
  )
}

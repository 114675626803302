import React, { ForwardedRef, PropsWithoutRef, ReactNode, type HTMLAttributes } from 'react'

// eslint-disable-next-line no-restricted-imports
import { useTheme, makeStyles, CreateCSSProperties } from '@mui/styles'

import classnames from 'classnames'

import { HbTheme, fontFamily } from 'components/themeRedesign'
import { Theme } from 'types/hb'

import { Size } from '../HbComponents.types'

import { SkeletonText } from './SkeletonText'

type Color = keyof Theme['palette']['text'] | 'inherit'
type Variant = 'upcase' | 'sectionHeader'

export type TitleTag = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6'

export type Tag =
  | 'span'
  | 'p'
  | 'dt'
  | 'dd'
  | 'label'
  | TitleTag
  | 'input' // TODO(yh): consider removing support and using styled HbInput/input instead
  | 'button' // TODO(yh): consider removing support and using styled HbButton/button instead
  | 'div' // TODO(yh): consider removing support and using `block` prop instead
  | 'header' // TODO(yh): consider removing support and wrapping HbText with title tag in a header instead

type BaseProps = {
  className?: string
  id?: string
  children?: ReactNode
  bold?: boolean
  block?: boolean
  size?: Size
  center?: boolean
  color?: Color
  variant?: Variant
  noWrap?: boolean
  breakWord?: boolean
  noSelection?: boolean
  loading?: boolean
  loadingWidth?: number | string
  ref?: ForwardedRef<HTMLElement>
}

export type HBNonInteractiveTextProps = {
  tag?: Exclude<Tag, 'label' | 'button'>
  onClick?: never
  htmlFor?: never
} & BaseProps

export type HBInteractiveTextProps = (
  | {
      tag: Extract<Tag, 'button'>
      onClick: HTMLAttributes<HTMLButtonElement>['onClick']
      htmlFor?: never
    }
  | {
      tag: Extract<Tag, 'label'>
      onClick?: never
      htmlFor?: string
    }
) &
  BaseProps

export type Props = HBNonInteractiveTextProps | HBInteractiveTextProps

interface StyleProps {
  size?: Size
  color?: Color
  variant?: Variant
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    fontFamily,
  },
  variant: ({ variant }: StyleProps) => {
    if (variant === 'sectionHeader') {
      return {
        ...theme.typography.sectionHeader,
      } as CreateCSSProperties<Props>
    }
    if (variant === 'upcase') {
      return {
        ...theme.typography.overline,
      } as CreateCSSProperties<Props>
    }

    return {}
  },
  bold: () => ({
    fontWeight: theme.fontWeight.bold,
  }),
  color: ({ color }: StyleProps) => {
    if (color && color !== 'inherit') {
      return { color: theme.palette.text[color] }
    }

    return { color }
  },
  size: ({ size }: StyleProps) => {
    if (!size || !theme.typography.sizes[size]) {
      return {}
    }

    return {
      ...theme.typography.sizes[size],
    }
  },
  block: {
    display: 'block',
  },
  noWrap: {
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  breakWord: {
    wordBreak: 'break-word',
  },
  noSelection: {
    userSelect: 'none',
  },
  center: {
    textAlign: 'center',
  },
}))

export const HbText = React.forwardRef<HTMLElement, PropsWithoutRef<Props>>((props, ref) => {
  const {
    center = false,
    color = 'primary',
    size = 'md',
    bold = false,
    block = false,
    tag = 'span',
    noWrap = false,
    breakWord = false,
    noSelection = false,
    variant,
    className: inputClassName,
    children,
    loading,
    loadingWidth = 100,
    ...otherProps
  } = props
  const styles = useStyles({ variant, color, size })

  const className = classnames(
    {
      [styles.bold]: bold,
      [styles.center]: center,
      [styles.color]: color,
      [styles.size]: size,
      [styles.block]: block,
      [styles.breakWord]: breakWord,
      [styles.noWrap]: noWrap,
      [styles.noSelection]: noSelection,
    },
    styles.root,
    styles.variant,
    inputClassName
  )

  const theme = useTheme<HbTheme>()

  const loadingHeight = theme ? 'auto' : undefined

  const content = loading ? <SkeletonText width={loadingWidth} height={loadingHeight} aria-hidden /> : children

  return React.createElement(tag, { ...otherProps, className, ref }, content)
})

import type * as Types from '../../../../../../types/api'

import type { StrictLogicalExpression } from 'types/automations'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type FetchAttachmentSummaryQueryVariables = Types.Exact<{
  investigationToken: Types.Scalars['String']['input']
  attachmentSummaryToken: Types.Scalars['String']['input']
}>

export type FetchAttachmentSummaryQuery = {
  __typename?: 'Query'
  investigation: {
    __typename?: 'Investigation'
    token: string
    attachmentSummary: {
      __typename?: 'AttachmentSummary'
      token: string
      tooBig: boolean
      status: Types.AttachmentSummaryStatus
      statements: Array<string> | null
      createdAt: string
      inProgress: boolean
      userFeedback: {
        __typename?: 'AttachmentSummaryFeedback'
        token: string
        rating: Types.AttachmentSummaryFeedbackRatingEnum
        createdAt: string
        updatedAt: string
      } | null
    }
  } | null
}

export const FetchAttachmentSummaryDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'FetchAttachmentSummary' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'investigationToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentSummaryToken' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'investigation' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'investigationToken' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'attachmentSummary' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'attachmentSummaryToken' },
                      value: { kind: 'Variable', name: { kind: 'Name', value: 'attachmentSummaryToken' } },
                    },
                  ],
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'tooBig' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'statements' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'inProgress' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'userFeedback' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'updatedAt' } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<FetchAttachmentSummaryQuery, FetchAttachmentSummaryQueryVariables>

import { useState } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import { useSelector } from 'actions/store'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import { getCurrentOrganizationToken } from 'helpers/stateHelpers'
import { Theme } from 'types/hb'

import { useGetAutomationAdminOrganizations } from '../../hooks/AutomationAdminHelpers'
import Step from '../Step'

interface Props {
  onClose: () => void
  onSelect: (orgToken: string) => void
  onConfirm: () => void
  open: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  dialog: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  dialogContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1.5fr',
    alignItems: 'center',
  },
  container: {
    display: 'grid',
    alignItems: 'center',
    justifyItems: 'center',
  },
  orgText: { color: theme.palette.hues.rose.medium },
  warningText: { color: 'red' },
}))

export function OrganizationSelectDialog({ open, onClose, onConfirm, onSelect }: Props) {
  const styles = useStyles()
  const currentOrgToken = useSelector(getCurrentOrganizationToken)
  const [selectedOrgToken, setSelectedOrgToken] = useState<string>(currentOrgToken)

  const organizations = useGetAutomationAdminOrganizations()

  const dialogTitle = 'Organization Selection'

  return (
    <HbDialog
      id="select-automation-org-dialog"
      loading={false}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      title={dialogTitle}
      content={
        <div className={styles.dialog}>
          <HbText className={styles.warningText} bold>
            You have permissions to create automation rules for other organizations. Before creating an automation rule,
            you must first select the organization for which this rule will be added. The organization cannot be changed
            once the rule is created.
          </HbText>
          <div className={styles.dialogContainer}>
            <HbText size="lg" bold>
              Create this automation rule for:{' '}
            </HbText>
            <Select
              variant="outlined"
              value={selectedOrgToken}
              onChange={(e) => {
                const newOrgToken = e.target.value as string
                setSelectedOrgToken(newOrgToken)
                onSelect(newOrgToken)
              }}
            >
              {organizations.map((o) => (
                <MenuItem value={o.token} key={o.token}>
                  {`${o.name} ${o.token === currentOrgToken ? '(this organization)' : ''}`}
                </MenuItem>
              ))}
            </Select>
          </div>
        </div>
      }
    />
  )
}

export default function SelectedOrganizationStep() {
  const styles = useStyles()
  const organizations = useGetAutomationAdminOrganizations()

  const stepText = 'This rule was created by an internal Hummingbird user for an organization'

  return (
    <Step key="orgStep" title="Organization Selected" text={stepText} type="select_organization">
      <div className={styles.container}>
        <Controller
          name="organizationToken"
          render={({ field }) => (
            <HbText bold className={styles.orgText}>
              This automation has been created for: {organizations.find((o) => o.token === field.value)?.name}
            </HbText>
          )}
        />
      </div>
    </Step>
  )
}

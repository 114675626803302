import { InputAdornment } from '@mui/material'

import { Formik, Form } from 'formik'
import * as Yup from 'yup'

import { deleteAttachment, renameAttachment } from 'actions/importingActions'
import { useDispatch, useSelector } from 'actions/store'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { TextInputV2 } from 'components/material/Form'
import { getLoadingBool } from 'helpers/stateHelpers'

import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { closeTab } from 'reducers/tabReducer'

import { DialogFile } from './caseFileTypes'

type SharedProps = {
  dialogFile: DialogFile | null
  setDialogFile: (dialogFile: DialogFile | null) => void
  caseToken: string
}

export type RenameFileFormSchema = { name?: string }

type RenameFileDialogInternalProps = {
  hbDialogId: string
  loading: boolean
  open: boolean
  initialFilename: string
  fileExtension: string
  onSubmit: ({ name }: RenameFileFormSchema) => void
  onClose: () => void
}

const renameFileFormValidationSchema = Yup.object().shape({
  name: Yup.string().min(1).required('Cannot be blank'),
})

export function RenameFileDialogInternal(props: RenameFileDialogInternalProps) {
  const { hbDialogId, loading, open, initialFilename, fileExtension, onSubmit, onClose } = props
  return (
    <Formik<RenameFileFormSchema>
      initialValues={{ name: initialFilename }}
      enableReinitialize
      onSubmit={onSubmit}
      validationSchema={renameFileFormValidationSchema}
      render={({ values, errors }) => {
        const hasErrors = !!Object.keys(errors).length
        return (
          <HbDialog
            id={hbDialogId}
            size="s"
            title="Rename attachment?"
            open={open}
            loading={loading}
            onClose={onClose}
            onConfirm={() => {
              if (hasErrors) {
                return
              }
              onSubmit({ name: values.name })
            }}
            confirmText="Yes, rename"
            confirmButtonDisabled={loading || hasErrors}
            content={
              <Form>
                <TextInputV2
                  name="name"
                  label="File name"
                  adornment={<InputAdornment position="end">{fileExtension}</InputAdornment>}
                />
              </Form>
            }
          />
        )
      }}
    />
  )
}

export function RenameFileDialog({
  dialogFile,
  dialogFileName,
  setDialogFile,
  caseToken,
}: SharedProps & { dialogFileName?: string }) {
  const loading = useSelector((state) => (dialogFile ? getLoadingBool(state, dialogFile.token) : false))
  const open = dialogFile?.action === 'edit'
  const initialFilename = dialogFileName || ''
  const initialFilenameExtension = initialFilename.match(/\.\w+$/)?.[0] || ''
  const initialFilenameWithoutExtension = initialFilename.substring(
    0,
    initialFilename.length - initialFilenameExtension.length
  )

  const dispatch = useDispatch()
  const onSubmit = async ({ name }: RenameFileFormSchema) => {
    if (dialogFile && name) {
      await dispatch(renameAttachment(caseToken, dialogFile.token, name + initialFilenameExtension))
      setDialogFile(null)
    }
  }
  const onClose = () => setDialogFile(null)

  return (
    <RenameFileDialogInternal
      hbDialogId="rename-case-attachment"
      loading={loading}
      open={open}
      initialFilename={initialFilenameWithoutExtension}
      fileExtension={initialFilenameExtension}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  )
}

export function DeleteFileDialogInternal({
  open,
  loading,
  onClose,
  onConfirm,
}: {
  open: boolean
  loading?: boolean
  onClose: () => void
  onConfirm: () => void
}) {
  return (
    <HbDialog
      id="delete-case-attachment"
      size="s"
      title="Delete attachment?"
      open={open}
      loading={loading}
      onClose={onClose}
      onConfirm={onConfirm}
      confirmText="Yes, delete"
      confirmButtonDisabled={loading}
      content={null}
    />
  )
}

export function DeleteFileDialog({ dialogFile, setDialogFile, caseToken }: SharedProps) {
  const loading = useSelector((state) => (dialogFile ? getLoadingBool(state, dialogFile.token) : false))

  const dispatch = useDispatch()

  const onClose = () => setDialogFile(null)

  const onConfirm = async () => {
    if (dialogFile) {
      await dispatch(deleteAttachment(caseToken, dialogFile.token))
      dispatch(batchActionsActions.batchSelectItems.set({ tokens: [dialogFile.token], value: false }))
      if (dialogFile.attachmentToken) {
        dispatch(closeTab({ tabId: dialogFile.attachmentToken }))
      }
      onClose()
    }
  }

  return (
    <DeleteFileDialogInternal
      open={dialogFile?.action === 'delete'}
      loading={loading}
      onClose={onClose}
      onConfirm={onConfirm}
    />
  )
}

import { Suspense } from 'react'

import { useSelector } from 'actions/store'

import Loader from 'components/library/Loader'

import { AttachmentContainer } from './AttachmentContainer'
import { AttachmentHeaderConnected } from './AttachmentHeader'
import { AttachmentViewerInternalConnected } from './AttachmentViewerInternal'

export function AttachmentViewerWithOverlay() {
  const attachment = useSelector((state) => state.view.displayAttachment)
  if (!attachment) {
    return null
  }

  return (
    <AttachmentContainer>
      <AttachmentHeaderConnected attachment={attachment} />
      <Suspense fallback={<Loader />}>
        <AttachmentViewerInternalConnected attachment={attachment} />
      </Suspense>
    </AttachmentContainer>
  )
}

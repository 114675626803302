import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

interface Props extends SvgProps {
  clipPathFill?: string
}

export const GenderIcon = ({ clipPathFill = '#fff', id = 'iconGender', ...restProps }: Props) => (
  <SvgIcon
    id={id}
    fill="none"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
    {...restProps}
  >
    <g clipPath={`url(#${id}_clipPath)`} fill="currentColor">
      <path d="M14.2 6.5H9.4C8.1 6.5 7 7.5 7 8.9v7.2h2.4v8.4h4.8v-8.4h2.4V8.9c0-1.3-1-2.4-2.4-2.4ZM11.8 5.3a2.4 2.4 0 1 0 0-4.8 2.4 2.4 0 0 0 0 4.8Z" />
    </g>
    <defs>
      <clipPath id={`${id}_clipPath`}>
        <path fill={clipPathFill} d="M0 .5h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

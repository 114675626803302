import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'

import { useSelector } from 'actions/store'

import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { TodoProfilesDashboardEntry } from 'reducers/dashboards/mappers'

import { useGetAllowedProfileTypesForMerging } from './hooks/useGetAllowedProfileTypesForMerging'

export type SelectedEntity = Pick<TodoProfilesDashboardEntry, 'token' | 'type'>
export type SetSelectedEntities = Dispatch<SetStateAction<SelectedEntity[]>>
export type SetSelectedPrimaryToken = Dispatch<SetStateAction<string>>

export interface ContextType {
  canMergeSelectedItems: boolean
  mergeAllowedForSelectedType: boolean
  numSelected: number
  selectedEntities: SelectedEntity[]
  selectedLibraryType: LibraryGqlQueryType | undefined
  selectedEntityTokens: string[]
  selectedEntitiesTokensSet: Set<string>
  selectedPrimaryEntityToken: string | null
  selectedProfilesHaveSameType: boolean
  setSelectedEntities: SetSelectedEntities
  setSelectedPrimaryEntityToken: SetSelectedPrimaryToken
  toggleEntitySelection: (selected: TodoProfilesDashboardEntry) => void
}

/**
 * This context tracks additional details in parallel to the dashboards infrastructure.
 * There are additional restrictions for selecting profiles for merging:
 * - currently only two profiles
 * - of the same type, e.g. two businesses, or two people.
 */
const ProfileMergingContext = createContext<ContextType>({
  canMergeSelectedItems: false,
  mergeAllowedForSelectedType: false,
  numSelected: 0,
  selectedEntities: [],
  selectedEntityTokens: [],
  selectedEntitiesTokensSet: new Set([]),
  selectedLibraryType: undefined,
  selectedPrimaryEntityToken: null,
  selectedProfilesHaveSameType: false,
  setSelectedEntities: (selectedEntities) => selectedEntities,
  setSelectedPrimaryEntityToken: (selectedPrimaryEntityToken) => selectedPrimaryEntityToken,
  toggleEntitySelection: () => {},
})

export const useProfileMergingContext = () => useContext(ProfileMergingContext)

export const ProfileMergingContextProvider = ({ children }: { children: ReactNode }) => {
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntity[]>([])
  const [selectedPrimaryEntityToken, setSelectedPrimaryEntityToken] = useState<string | null>(null)

  const selectedEntityTokens = useMemo(() => selectedEntities.map((e) => e.token), [selectedEntities])

  const selectedEntitiesTokensSet = useMemo(() => new Set(selectedEntityTokens), [selectedEntityTokens])
  const numSelected = selectedEntitiesTokensSet.size

  const { getIsAllowedMergeType } = useGetAllowedProfileTypesForMerging()

  const toggleEntitySelection = useCallback(
    ({ token, type }: TodoProfilesDashboardEntry) => {
      const _entities = [...selectedEntities]
      const i = _entities.findIndex((e) => e.token === token)
      if (i > -1) {
        _entities.splice(i, 1)
      } else {
        _entities.push({ token, type })
      }
      setSelectedEntities(_entities)
    },
    [selectedEntities]
  )

  const numBatchItemsSelected = useSelector(batchActionsSelectors.numBatchItemsSelected)

  // reset to keep in sync with the batch selection
  useEffect(() => {
    if (numBatchItemsSelected === 0) setSelectedEntities([])
  }, [numBatchItemsSelected])

  const { mergeAllowedForSelectedType, selectedLibraryType, selectedProfilesHaveSameType } = useMemo(() => {
    const types = selectedEntities.map((e) => e.type, [])
    const haveSameType = new Set(types).size === 1
    const type = haveSameType ? types[0] : undefined
    const isAllowedType = !!type && getIsAllowedMergeType(type)
    return {
      mergeAllowedForSelectedType: isAllowedType,
      selectedLibraryType: type,
      selectedProfilesHaveSameType: haveSameType,
    }
  }, [getIsAllowedMergeType, selectedEntities])

  const selectedCorrectNumberOfEntities = numBatchItemsSelected === 2
  const canMergeSelectedItems = selectedCorrectNumberOfEntities && mergeAllowedForSelectedType

  const value = useMemo(
    () => ({
      canMergeSelectedItems,
      mergeAllowedForSelectedType,
      numSelected,
      selectedEntities,
      selectedLibraryType,
      selectedEntityTokens,
      selectedEntitiesTokensSet,
      selectedPrimaryEntityToken,
      selectedProfilesHaveSameType,
      setSelectedEntities,
      setSelectedPrimaryEntityToken,
      toggleEntitySelection,
    }),
    [
      canMergeSelectedItems,
      mergeAllowedForSelectedType,
      numSelected,
      selectedEntities,
      selectedLibraryType,
      selectedEntityTokens,
      selectedEntitiesTokensSet,
      selectedPrimaryEntityToken,
      selectedProfilesHaveSameType,
      setSelectedEntities,
      setSelectedPrimaryEntityToken,
      toggleEntitySelection,
    ]
  )

  return <ProfileMergingContext.Provider value={value}>{children}</ProfileMergingContext.Provider>
}

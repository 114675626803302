import { useRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { isEqual } from 'lodash'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { useDispatch, useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { Placement } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import KeyCapture from 'components/library/KeyCapture'
import { SortEntry } from 'dashboards/reviews/components/Header/filtering/SortEntry'
import { useDashboardFilters } from 'dashboards/shared/react/getNamedQueryGroups'
import { createDragHandler } from 'helpers/DraggableHelpers'
import { useToggle } from 'hooks'
import { SortIcon } from 'icons'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { sortIsValid } from 'reducers/dashboards/dashboards.types'
import { Theme } from 'types/hb'

import { FilterControlProps } from './FilterControls'
import useLogDashboardFilterInteraction from './hooks/useLogDashboardFilterInteraction'
import { AddButton } from './sharedComponents'

const useStyles = makeStyles((theme: Theme) => ({
  sortBody: {},
  sortPopover: { minWidth: 355 },
  sortEntryContainer: {
    display: 'flex',
    paddingLeft: theme.spacing(2.5),
    paddingRight: theme.spacing(2.5),
    justifyContent: 'space-between',
  },
}))

function SortButton({
  buttonActive,
  popoverHeader,
  popoverShared,
  addButtons,
  disabled,
  iconOnly,
  size = 'small',
  zIndex,
}: FilterControlProps) {
  const logAction = useLogDashboardFilterInteraction()
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const { defaultSorts } = useDashboardFilters()
  const allSorts = useSelector((state) => dashboardsSelectors.sorts.all(state) ?? [])
  const validSorts = useSelector(dashboardsSelectors.sorts.valid)
  const sortMessage = 'Currently sorting by: '

  const dispatch = useDispatch()
  const open = useToggle(false)
  const handleClick = () => {
    if (!open.value) {
      logAction('dashboard:sort:clicked')
    }
    open.toggle()
  }
  const ref = useRef<HTMLButtonElement>(null)
  const classes = useStyles()

  const isDefaultSort = isEqual(validSorts, defaultSorts)

  const onDragEnd = createDragHandler(allSorts, (newSorts) => dispatch(dashboardsActions.sorts.set.action(newSorts)))

  return (
    <>
      <HbButton
        Icon={SortIcon}
        iconCount={validSorts.length > 1 ? validSorts.length : undefined}
        ref={ref}
        label="Sort"
        variant={isDefaultSort ? 'secondary' : 'primary'}
        size={size}
        onClick={handleClick}
        className={open.value ? buttonActive : ''}
        disabled={disabled}
        iconOnly={iconOnly}
        tooltip={iconOnly}
        tooltipPlacement={Placement.Top}
      />
      <KeyCapture active={open.value}>
        <HbPopper
          id="column-sort-popper"
          classes={{ content: classes.sortPopover }}
          isOpen={open.value}
          onClose={handleClick}
          anchorEl={ref.current}
          zIndex={zIndex}
        >
          <div className={popoverShared}>
            <div className={popoverHeader}>
              <HbText color="disabled" size="md" noSelection>
                {sortMessage}
              </HbText>
            </div>
            <div className={classes.sortEntryContainer}>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="sorts">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {allSorts.map((sortEntry, index) => (
                        <SortEntry key={sortEntry.sortField ?? 'empty'} sortEntry={sortEntry} index={index} />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </div>
          </div>
          <div className={addButtons}>
            <AddButton
              label="Add sort"
              disabled={allSorts?.some((sort) => !sortIsValid(sort))}
              onClick={(e) => {
                e.stopPropagation()
                dispatch(dashboardsActions.sorts.addEmpty())
              }}
            />
          </div>
        </HbPopper>
      </KeyCapture>
    </>
  )
}

export default SortButton

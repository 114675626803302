import { ChangeEvent } from 'react'

import { MenuItem, Select } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { capitalize } from 'lodash'

import { HbText } from 'components/HbComponents/Text/HbText'
import { CombinatorEnum } from 'types/api'

import { MenuItemProps } from '.'

const useStyles = makeStyles((theme) => ({
  text: {
    justifySelf: 'center',
    maxWidth: theme.spacing(2),
    marginTop: theme.spacing(),
  },

  combinator: {
    justifySelf: 'end',
    maxWidth: theme.spacing(10),
    maxHeight: 40,
  },
}))

const combinators: MenuItemProps[] = [
  { value: CombinatorEnum.And, title: 'And' },
  { value: CombinatorEnum.Or, title: 'Or' },
]

function CombinatorSelect({
  index,
  combinator,
  styles,
  onChange,
}: {
  index?: number
  combinator?: CombinatorEnum
  styles?: string
  onChange: (combinator: CombinatorEnum) => void
}) {
  const classes = useStyles()

  // Only the second filter entry has a selectable dropdown for combinator
  if (index !== 1) {
    const displayText = index === 0 ? 'Where' : capitalize(combinator)
    return (
      <span className={classnames(classes.text, styles)}>
        <HbText size="md">{displayText}</HbText>
      </span>
    )
  }

  return (
    <Select
      id="combinator-select"
      variant="outlined"
      value={combinator}
      className={classnames(classes.combinator, styles)}
      onChange={(e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value as CombinatorEnum)}
    >
      {combinators.map((combo) => (
        <MenuItem key={combo.value} value={combo.value}>
          {combo.title}
        </MenuItem>
      ))}
    </Select>
  )
}

export default CombinatorSelect

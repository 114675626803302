import { ComponentProps, FocusEvent, ForwardedRef, ReactElement, ReactNode, Ref, forwardRef, useMemo } from 'react'

import { Check } from '@mui/icons-material'
import { InputAdornment, TextField } from '@mui/material'
import MUIAutocomplete, {
  AutocompleteInputChangeReason,
  type AutocompleteProps as MUIAutocompleteProps,
} from '@mui/material/Autocomplete'

import classnames from 'classnames'

import { getIn } from 'formik'
import { TextFieldProps } from 'formik-mui'
import invariant from 'tiny-invariant'

import { InputContainer } from 'components/HbComponents/Form/Inputs/InputContainer'
import { HbTag } from 'components/HbComponents/HbTag'
import { HbColorsByHue } from 'components/colors'

import { makeRequiredStyles } from 'components/utils/styles'

import { Option as _Option } from 'types/hb'

import { useHbFormikContext } from '../../useHbFormikContext'

export type Option = _Option & { group?: string }

interface MultipleProps<T> {
  multiple: true
  onChange(option: T[]): void
}
interface SingleProps<T> {
  multiple?: false
  onChange(option?: T): void
}

const useStyles = makeRequiredStyles<string, { isErroneous: boolean }>((theme) => ({
  endAdornment: ({ isErroneous }: { isErroneous: boolean }) => {
    return {
      right: '2px !important',
      '&  .MuiSvgIcon-root': isErroneous ? { color: theme.palette.error.main } : {},
      '&  button:hover': { background: 'none' },
    }
  },
  inputRoot: {
    paddingRight: '24px !important',
  },
  inputText: {
    marginTop: theme.spacing(1),
  },
  option: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  optionSelected: {
    color: theme.palette.hues.hbBlue.medium,
  },
  optionReset: {
    fontStyle: 'italic',
  },
  root: {
    flex: '1 1',
    verticalAlign: 'baseline',
    width: '100%',
  },
}))

const getOptionKey = (value: string | Option | Array<string | Option>) => {
  // If a key isn't explicitly set, MUIAutocomplete uses label text,
  // which can cause issues if there are duplicate labels.
  if (typeof value === 'string') return value
  if ('value' in value) return value.value
  return value.map((v: string | Option, i) => (typeof v === 'string' ? v : 'value' in v ? v.value : i)).join(':')
}

export type AutocompleteProps<T> = Omit<
  MUIAutocompleteProps<T, boolean, true, boolean>,
  'onChange' | 'multiple' | 'ref' | 'renderTags' | 'renderOption' | 'renderInput' | 'onInputChange'
> & {
  startAdornment?: React.ReactNode
  label: React.ReactNode
  noSpacing?: boolean
  errorMessage?: string
  styles?: string
  hideLabel?: boolean
  onInputChange?(newValue: string, reason?: AutocompleteInputChangeReason): void
  testId?: string
  setValueOnBlur?: boolean
  variant?: TextFieldProps['variant']
  tagColor?: string
  sublabel?: string
  hideInputContainer?: boolean
  inputContainerClassName?: string
} & (MultipleProps<T> | SingleProps<T>)

// Wraps MUI Autocomplete and adds custom styles
function BaseAutocompleteInner<T extends string | Option = string | Option>(
  {
    className,
    styles,
    startAdornment,
    errorMessage,
    label,
    noSpacing,
    placeholder,
    hideLabel,
    onInputChange,
    onBlur: handleBlur,
    setValueOnBlur,
    variant = 'outlined',
    classes = {},
    tagColor = HbColorsByHue.cyan.medium,
    sublabel,
    hideInputContainer,
    inputContainerClassName,
    ...props
  }: AutocompleteProps<T>,
  ref: ForwardedRef<unknown>
) {
  const isErroneous = !!errorMessage
  const baseStyles = useStyles({ isErroneous })

  const { onChange, ...muiProps } = props

  const handleInputBlur = (inputProps: { value: string }) => {
    // This isn't supported by MUIAutocomplete, we want to set the value on blur instead of
    // only using the enter key
    if (props.freeSolo && props.multiple && setValueOnBlur && inputProps.value) {
      // Bit of a hack with typescript, but if using props.freeSolo, then T must be a string
      const existingValues = props.value as T[]
      props.onChange([...existingValues, inputProps.value as T])
    }
  }

  return (
    <MUIAutocomplete
      {...muiProps}
      ref={ref}
      className={classnames(baseStyles.root, styles, className)}
      classes={{
        endAdornment: baseStyles.endAdornment,
        inputRoot: baseStyles.inputRoot,
        ...classes,
      }}
      disableClearable
      size="small"
      renderTags={(tags: T[], getTagProps) =>
        tags.map((tag: T, index: number) => {
          if (typeof tag === 'string') {
            // The spread props add the key
            // eslint-disable-next-line react/jsx-key
            return <HbTag label={tag} color={tagColor} {...getTagProps({ index })} />
          }
          return (
            // The spread props add the key
            // eslint-disable-next-line react/jsx-key
            <HbTag label={tag.display} color={tagColor} {...getTagProps({ index })} />
          )
        })
      }
      renderInput={(params) => {
        const existingStartAdornment = params.InputProps.startAdornment as React.ReactNode[] | undefined
        const inputStartAdornment = [
          startAdornment ? <InputAdornment position="start">{startAdornment}</InputAdornment> : null,
          ...(existingStartAdornment || []),
        ].filter(Boolean)

        const textFieldParams = hideInputContainer && !hideLabel ? { label } : {}
        const InputProps = {
          ...params.InputProps,
          ...(sublabel && { className: baseStyles.inputText }),
          startAdornment: inputStartAdornment.length ? inputStartAdornment : null,
          ...textFieldParams,
          onBlur: (e: FocusEvent<HTMLInputElement>) => {
            handleBlur?.(e)
            handleInputBlur(params.inputProps as { value: string })
          },
        }
        const ariaLabel = typeof label === 'string' ? label : undefined
        const htmlFor = ariaLabel

        const textFieldComponent = (
          <TextField
            {...params}
            {...textFieldParams}
            placeholder={placeholder}
            error={isErroneous}
            aria-label={ariaLabel}
            variant={variant}
            InputProps={InputProps}
          />
        )

        if (hideInputContainer) {
          return textFieldComponent
        }

        return (
          <InputContainer
            {...props}
            testId={`autocomplete_${label}`}
            label={label}
            noSpacing={noSpacing}
            htmlFor={htmlFor}
            isErroneous={isErroneous}
            clientError={errorMessage}
            hideLabel={hideLabel}
            className={className}
            sublabel={sublabel}
          >
            {textFieldComponent}
          </InputContainer>
        )
      }}
      renderOption={(optionProps: React.HTMLAttributes<HTMLLIElement>, option: string | T) => {
        const optionString = typeof option === 'string' ? option : option.value
        const valueString =
          typeof props.value === 'string'
            ? props.value
            : Array.isArray(props.value)
            ? props.value.map((v) => (typeof v === 'string' ? v : v.value))
            : props.value?.value

        const isReset = optionString === ''
        const isSelected =
          !isReset &&
          valueString &&
          (Array.isArray(valueString) ? valueString.some((v) => optionString === v) : optionString === valueString)

        return (
          <li {...optionProps}>
            <div
              className={classnames(baseStyles.option, {
                [baseStyles.optionSelected]: isSelected,
                [baseStyles.optionReset]: isReset,
              })}
            >
              <span>{typeof option === 'string' ? option : option.display}</span>
              {isSelected && <Check fontSize="small" />}
            </div>
          </li>
        )
      }}
      onInputChange={(_e, newValue, reason) => {
        onInputChange?.(newValue, reason)
      }}
      getOptionKey={getOptionKey}
      onChange={(_e, option: T | T[]) => {
        if (props.multiple && Array.isArray(option)) {
          props.onChange(option)
        } else if (!props.multiple && !Array.isArray(option)) {
          props.onChange(option)
        }
      }}
    />
  )
}

export const BaseAutocomplete = forwardRef(BaseAutocompleteInner) as <T>(
  props: AutocompleteProps<T> & { ref?: Ref<unknown>; children?: ReactNode }
) => ReactElement

function matchValueToOptions(value: string | Option, option: Option) {
  if (!value) {
    return false
  }
  const valueString = (typeof value === 'string' ? value : value.value).toLowerCase()
  return option.value.toString().toLowerCase() === valueString
}

// Wrapped MUI autocomplete with extra logic to handle Option type values
function Autocomplete({
  value,
  loading = false,
  disabled = false,
  noSpacing = false,
  options: _options,
  testId,
  showResetOption,
  ...props
}: AutocompleteProps<Option> & { showResetOption?: boolean }) {
  const selectedOption = useMemo(() => {
    const emptyValue = { display: '', value: '' }

    if (props.multiple) {
      invariant(Array.isArray(value), 'Autocomplete: value must be an array when multiple is true')

      if (!(_options && value)) {
        return [emptyValue]
      }

      const match = _options.filter((option) => {
        return value.some((v) => matchValueToOptions(v, option))
      })

      return match || []
    }

    invariant(!Array.isArray(value), 'Autocomplete: value must not be an array when multiple is false')

    if (!(_options && value)) {
      return emptyValue
    }

    const match = _options.find((option) => matchValueToOptions(value, option))
    return match || emptyValue
  }, [_options, value, props.multiple])

  const options = useMemo(() => {
    if (!_options) {
      return []
    }
    return !showResetOption ? _options : [{ display: 'Reset field', value: '' }, ..._options]
  }, [_options, showResetOption])

  return (
    <BaseAutocomplete
      disableClearable
      size="small"
      noSpacing={noSpacing}
      loading={loading}
      disabled={disabled}
      options={options}
      value={selectedOption}
      getOptionLabel={(option: Option) => option.display}
      data-testid={testId}
      {...props}
    />
  )
}

export default Autocomplete

export function AutocompleteField({
  name,
  ...props
}: Omit<ComponentProps<typeof Autocomplete>, 'onChange' | 'value' | 'multiple'> & { name: string }) {
  const formik = useHbFormikContext({ autosave: true })

  const handleChange = (o?: Option) => {
    formik.setFieldValue(name, o?.value)
  }

  return <Autocomplete {...props} onChange={handleChange} value={getIn(formik.values, name)} />
}

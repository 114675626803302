import { css } from '@emotion/css'
import { IconButton, InputAdornment } from '@mui/material'

import { getExternalLinkHandler, valueIsHyperlink } from 'helpers/uiHelpers'

import { OpenInNewIcon } from 'icons'

const size = 18

const openInNewIconClassName = css({
  width: size,
  height: size,
})

export const InputLinkAdornment = ({ value }: { value: unknown }) => {
  const isLink = valueIsHyperlink(value)

  if (!isLink) return null

  return (
    <InputAdornment position="end">
      <IconButton onClick={getExternalLinkHandler(value)} size="large">
        <OpenInNewIcon className={openInNewIconClassName} />
      </IconButton>
    </InputAdornment>
  )
}

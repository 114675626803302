import { ReactNode } from 'react'

import { createPortal } from 'react-dom'

function PortalBuilder(containerId: string) {
  return ({ children }: { children: ReactNode }) => {
    const container = document.getElementById(containerId)

    if (container) {
      return createPortal(children, container)
    }

    return null
  }
}

export default PortalBuilder

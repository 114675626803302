import { ObservableQuery, TypedDocumentNode } from '@apollo/client'

import { OperationDefinitionNode } from 'graphql/index'

import { isTruthy } from 'utils'

type NullishNode<Node> = Node | null | undefined
type NullishEdge<Node> = { node: Node } | null | undefined

export const pluckEdgeNodes = <TNode>(edges: NullishEdge<NullishNode<TNode>>[] | null | undefined) =>
  edges?.map((edge) => edge?.node).filter(isTruthy) ?? []

const DELAY_FOR_ELASTICSEARCH_MS = 2000

// Be more robust to changes to the name of the query
const getQueryName = (node: TypedDocumentNode) =>
  node.definitions.find(
    (definition): definition is OperationDefinitionNode => definition.kind === 'OperationDefinition'
  )?.name?.value

interface DelayRefetchQueryProps {
  observableQuery: ObservableQuery
  query: TypedDocumentNode
  delayInMs?: number
}
export function delayRefetchedQuery(props: DelayRefetchQueryProps): boolean {
  const { observableQuery, query, delayInMs = DELAY_FOR_ELASTICSEARCH_MS } = props
  if (getQueryName(observableQuery.query) === getQueryName(query)) {
    // Delay until elasticsearch is *very probably* updated.
    setTimeout(() => observableQuery.refetch(), delayInMs)
  }
  // Don't do any refetching outside the above explicit refetch call, *including* the query itself
  // Returning true causes the query to be refetched immediately, in addition to any refetches above.
  // www.apollographql.com/docs/react/data/refetching/#refetching-selectively
  return false
}

import React, { useMemo } from 'react'

import { Close } from '@mui/icons-material'
import { Box, Collapse, styled } from '@mui/material'

import collapseClasses from '@mui/material/Collapse/collapseClasses'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { PANEL_WIDTH } from 'components/pages/automations/LayoutHelpers'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { titleCase } from 'helpers/uiHelpers'

const Sidebar = styled('nav')(({ theme }) => ({
  height: '100%',
  background: theme.palette.styleguide.backgroundLight,
  borderLeft: `1px solid ${theme.palette.styleguide.borderLight}`,
  padding: theme.spacing(3, 2),
  boxSizing: 'border-box',
  display: 'flex',
  flexFlow: 'column nowrap',
  justifyContent: 'space-between',
  alignItems: 'center',
  maxWidth: '72px',
}))

const SidebarButton = styled(HbButton)<{ active: boolean }>(({ theme, active }) => ({
  width: '40px',
  height: '40px',
  color: active ? theme.palette.styleguide.hbBlueMedium : theme.palette.styleguide.textGreyLight,
  backgroundColor: active ? theme.palette.styleguide.hbBlueLight : 'transparent',
  border: 'none',
  '&:hover, &:focus': {
    color: active ? theme.palette.styleguide.hbBlueMedium : theme.palette.text.secondary,
    backgroundColor: active ? theme.palette.styleguide.hbBlueLight : theme.palette.styleguide.backgroundMedium,
  },
}))

const StyledCollapse = styled(Collapse)({
  height: '100%',
  [`&.${collapseClasses.entered}`]: {
    minWidth: `${PANEL_WIDTH}px !important`,
  },
})

const Panel = styled('section')(({ theme }) => ({
  background: theme.palette.styleguide.backgroundLight,
  borderLeft: `1px solid ${theme.palette.styleguide.borderLight}`,
  width: `${PANEL_WIDTH}px`,
  height: '100%',
  overflowY: 'auto',
}))

const PanelHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2, 2, 0, 2),
}))

export type ButtonConfig<T extends string> = {
  type: T
  label: string
  Icon: React.ComponentType<{ className?: string }>
}

export function AutomationSidebar<T extends string>({
  currentPanel,
  children,
  setCurrentPanel,
  topButtonConfig,
  bottomButtonConfig,
}: {
  currentPanel: string | null
  children: React.ReactNode
  topButtonConfig: ButtonConfig<T>[]
  bottomButtonConfig: ButtonConfig<T>[]
  setCurrentPanel: (panel: T | null) => void
}) {
  const usage = useUsage()
  const handleSideBarClick = (panel: T) => () => {
    if (currentPanel === panel) {
      setCurrentPanel(null)
    } else {
      setCurrentPanel(panel)
      usage.logEvent({
        name: 'automations:drawerButton:clicked',
        data: { panel },
      })
    }
  }

  const labelMap = useMemo(
    () =>
      [...topButtonConfig, ...bottomButtonConfig].reduce(
        (acc: Record<string, string>, { type, label }) => ({ ...acc, [type]: label }),
        {}
      ),
    [topButtonConfig, bottomButtonConfig]
  )

  return (
    <>
      <StyledCollapse orientation="horizontal" in={Boolean(currentPanel)}>
        <Panel sx={{ position: 'relative' }}>
          <PanelHeader>
            <HbText tag="h2" bold size="lg">
              {currentPanel ? labelMap[currentPanel] : titleCase(currentPanel || '')}
            </HbText>
            <HbButton label="Close modal" iconOnly Icon={Close} onClick={() => setCurrentPanel(null)} />
          </PanelHeader>
          {children}
        </Panel>
      </StyledCollapse>
      <Sidebar>
        <Box component="ul" sx={{ display: 'flex', flexFlow: 'column nowrap', gap: 3 }}>
          {topButtonConfig.map(({ type, label, Icon }) => (
            <li key={label}>
              <SidebarButton
                label={label}
                Icon={Icon}
                iconOnly
                variant="textPrimary"
                onClick={handleSideBarClick(type)}
                active={currentPanel === type}
                tooltip
                tooltipText={label}
              />
            </li>
          ))}
        </Box>
        <ul>
          {bottomButtonConfig.map(({ type, label, Icon }) => (
            <li key={label}>
              <SidebarButton
                label={label}
                Icon={Icon}
                iconOnly
                variant="textPrimary"
                onClick={handleSideBarClick(type)}
                active={currentPanel === label}
                tooltip
                tooltipText={label}
              />
            </li>
          ))}
        </ul>
      </Sidebar>
    </>
  )
}

import { gql } from '@apollo/client'

import { AutomationActivityLogPanel } from 'components/pages/automations/ViewAutomation/AutomationActivityLogPanel/AutomationActivityLogPanel'
import { PreviewModeActivePanel } from 'components/pages/automations/ViewAutomation/PreviewModePanel/PreviewModeActivePanel'
import { ViewAutomationInfo } from 'components/pages/automations/editor/ViewAutomationInfo'

import { fragment as useAutomationRuleFormFragment } from 'components/pages/automations/editor/useAutomationRuleOrTemplateForm'

import { DatasourceTriggerDataFragment } from '../editor/TriggerFilterEditor/queries'

import { AutomationSettingsPanel } from './AutomationSettingsPanel'
import ViewAutomationActivityLog from './ViewAutomationActivityLog'

const ViewAutomationInnerFragments = gql`
  fragment ViewAutomationInnerAutomationRule on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      token
      name
      notes
      displayName
      executionMode
      enabledAt
    }
    ...ViewAutomationInfoAutomationRule
    ...ViewAutomationActivityLogAutomationRule
    ...UseAutomationRuleFormAutomationRule
    ...AutomationActivityLogPanelAutomationRule
    ...PreviewModePanelAutomationRule
  }
  ${ViewAutomationInfo.fragments.automationRule}
  ${ViewAutomationActivityLog.fragments.automationRule}
  ${useAutomationRuleFormFragment}
  ${AutomationActivityLogPanel.fragments.automationRule}
  ${PreviewModeActivePanel.fragments.automationRule}
`

export const AUTOMATION_QUERY = gql`
  query ViewAutomation(
    $token: ID!
    $organizationToken: ID
    $executionsSize: Int!
    $executionsCursor: String
    $datasourcesEnabled: Boolean!
  ) {
    ...DatasourceTriggerData
    automationRule(token: $token, organizationToken: $organizationToken) {
      ...ViewAutomationInnerAutomationRule
      ...ViewAutomationSettingsAutomationRule
    }
  }
  ${DatasourceTriggerDataFragment}
  ${ViewAutomationInnerFragments}
  ${AutomationSettingsPanel.fragments.automationRule}
`

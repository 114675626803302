import { HTMLProps, MouseEvent, ReactElement, useCallback, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbTooltip, Placement, TooltipProps as BaseTooltipProps } from 'components/HbComponents/HbTooltip'
import { mergeOverrideStyles } from 'components/utils/styles'
import { useCallbackRef } from 'hooks'
import { Theme } from 'types/hb'

const HANDLE_APPEAR_DELAY = 150
const HANDLE_APPEAR_DURATION = 150
const TOOLTIP_APPEAR_DURATION = 500
const TOOLTIP_APPEAR_DELAY = 1000

const useStyles = makeStyles((theme: Theme) => ({
  container: ({ resizeOrientation }: Props) => ({
    boxSizing: 'border-box',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: resizeOrientation === 'horizontal' ? theme.spacing(2) : '100%',
    height: resizeOrientation === 'horizontal' ? '100%' : theme.spacing(2),
    opacity: 0,
    transition: `opacity ${HANDLE_APPEAR_DURATION}ms ease-in-out`,
    '&:hover': {
      cursor: resizeOrientation === 'horizontal' ? 'col-resize' : 'row-resize',
      opacity: 0.5,
      transitionDelay: `${HANDLE_APPEAR_DELAY}ms`,
    },
    '&:active': {
      opacity: 0.8,
    },
  }),
  // visible component
  resizeHandleInner: ({ resizeOrientation }: Props) => ({
    width: resizeOrientation === 'horizontal' ? theme.spacing(0.5) : '100%',
    height: resizeOrientation === 'horizontal' ? '100%' : theme.spacing(0.5),
    backgroundColor: theme.palette.accent,
    borderRadius: theme.shape.borderRadius,
  }),
}))

// Default tooltip content styles
const useTooltipStyles = makeStyles((theme: Theme) => ({
  // title text
  dragText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
  },
  dragTextEmphasis: {
    color: theme.palette.text.white,
  },
}))

const defaultPlacements = {
  horizontal: Placement.Right,
  vertical: Placement.Top,
}

type ResizeOrientation = 'horizontal' | 'vertical'

// A faked anchor element reference object to update the position of the tooltip
interface ReferenceObject {
  clientWidth: number
  clientHeight: number
  getBoundingClientRect(): DOMRect
}

export interface Props extends HTMLProps<HTMLButtonElement> {
  classes?: Partial<ReturnType<typeof useStyles>>
  // direction of resizing
  resizeOrientation: ResizeOrientation
  // whether to show a hint over the rule, true by default
  hasTooltip?: boolean
  // can override tooltip behavior if needed, including passing a custom hint
  TooltipProps?: Partial<BaseTooltipProps>
}

const generateGetBoundingClientRect =
  (
    e: MouseEvent,
    ref: HTMLButtonElement,
    resizeOrientation: ResizeOrientation
  ): ReferenceObject['getBoundingClientRect'] =>
  () => {
    const domRect = ref.getBoundingClientRect()
    const staticMidPoint =
      resizeOrientation === 'horizontal' ? domRect.x + domRect.width / 2 : domRect.y + domRect.height / 2
    const staticCoords =
      resizeOrientation === 'horizontal'
        ? {
            left: staticMidPoint,
            right: staticMidPoint,
            x: staticMidPoint,
          }
        : {
            top: staticMidPoint,
            bottom: staticMidPoint,
            y: staticMidPoint,
          }
    const dynamicCoords =
      resizeOrientation === 'horizontal'
        ? {
            top: e.clientY,
            bottom: e.clientY,
            y: e.clientY,
          }
        : {
            left: e.clientX,
            right: e.clientX,
            x: e.clientX,
          }
    const fakedDomRect = {
      ...domRect,
      height: 0,
      width: 0,
      ...staticCoords,
      ...dynamicCoords,
    }
    return fakedDomRect
  }

interface TooltipProps extends Omit<Partial<BaseTooltipProps>, 'title' | 'classes'> {
  classes?: Partial<BaseTooltipProps['classes'] & ReturnType<typeof useTooltipStyles>>
  anchorEl: ReferenceObject | null
  children: ReactElement
  resizeOrientation: ResizeOrientation
}

export const ResizeTooltip = (props: TooltipProps) => {
  const tooltipClasses = useTooltipStyles()
  const {
    anchorEl,
    children,
    PopperProps: tooltipPopperProps,
    resizeOrientation,
    TransitionProps: tooltipTransitionProps,
    ...restTooltipProps
  } = props || {}
  return (
    <HbTooltip
      enterDelay={TOOLTIP_APPEAR_DELAY}
      enterNextDelay={TOOLTIP_APPEAR_DELAY}
      leaveDelay={0}
      title={
        <span className={tooltipClasses.dragText}>
          <em className={classNames(tooltipClasses.dragText, tooltipClasses.dragTextEmphasis)}>Drag</em> to resize
        </span>
      }
      placement={defaultPlacements[resizeOrientation]}
      PopperProps={{
        anchorEl,
        ...tooltipPopperProps,
      }}
      TransitionProps={{
        timeout: TOOLTIP_APPEAR_DURATION,
        ...tooltipTransitionProps,
      }}
      {...restTooltipProps}
    >
      {children}
    </HbTooltip>
  )
}

const useHandleTooltipAnchor = (resizeOrientation: ResizeOrientation, hasTooltip: boolean) => {
  const [handleRef, getHandleRef] = useCallbackRef<HTMLButtonElement>()

  const [anchorEl, setAnchorEl] = useState<ReferenceObject | null>(null)

  const updateMousePosition = useCallback(
    (e: MouseEvent) => {
      if (!handleRef) return
      const fakeAnchorEl = {
        getBoundingClientRect: generateGetBoundingClientRect(e, handleRef, resizeOrientation),
        clientWidth: 0,
        clientHeight: 0,
      }
      setAnchorEl(fakeAnchorEl)
    },
    [handleRef, resizeOrientation]
  )

  const handleMouseMove = (e: MouseEvent) => {
    updateMousePosition(e)
  }

  const handleMouseOver = (e: MouseEvent) => {
    updateMousePosition(e)
  }

  const eventHandlers = hasTooltip
    ? {
        handleMouseMove,
        handleMouseOver,
      }
    : undefined

  return {
    anchorEl,
    getHandleRef,
    handleRef,
    setAnchorEl,
    ...eventHandlers,
  }
}

export const ResizeHandle = (props: Props) => {
  const {
    className,
    classes: overrideClasses,
    hasTooltip = true,
    TooltipProps,
    resizeOrientation,
    ...restProps
  } = props
  const baseClasses = useStyles(props)
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)

  const { anchorEl, getHandleRef, handleMouseMove, handleMouseOver } = useHandleTooltipAnchor(
    resizeOrientation,
    hasTooltip
  )

  // renders the actual resize handle
  const handleEl = (
    // eslint-disable-next-line jsx-a11y/mouse-events-have-key-events
    <button
      className={classNames(classes.container, className)}
      onMouseOver={handleMouseOver}
      onMouseMove={handleMouseMove}
      ref={getHandleRef}
      {...restProps}
      type="button"
    >
      <div className={classes.resizeHandleInner} />
    </button>
  )

  return !hasTooltip ? (
    handleEl
  ) : (
    <ResizeTooltip {...TooltipProps} anchorEl={anchorEl} resizeOrientation={resizeOrientation}>
      {handleEl}
    </ResizeTooltip>
  )
}

import React from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CheckCircleOutlinedIcon = React.forwardRef((props: SvgProps, ref) => (
  // Cast ref to any due to material-ui type issue
  <SvgIcon ref={ref as any} {...props} viewBox="0 0 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M12,0 C5.376,0 0,5.376 0,12 C0,18.624 5.376,24 12,24 C18.624,24 24,18.624 24,12 C24,5.376 18.624,0 12,0 Z M12,22 C6.4875,22 2,17.5125 2,12 C2,6.4875 6.4875,2 12,2 C17.5125,2 22,6.4875 22,12 C22,17.5125 17.5125,22 12,22 Z M16.59,7.58 L10,14.17 L7.41,11.59 L6,13 L10,17 L18,9 L16.59,7.58 Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
))

export default CheckCircleOutlinedIcon

import { MouseEventHandler } from 'react'

import { useLinkStyles } from 'components/library/InternalLink'

export interface Props {
  label: string
  onClick: MouseEventHandler<HTMLElement>
  className?: string
  size?: 'md' | 'sm'
  disabled?: boolean
}

// A button that matches the styling of an InternalLink but takes an onClick
export function LinkButton({ onClick, label, size = 'md', disabled }: Props) {
  const { root, disabled: disabledClass } = useLinkStyles({ size })
  return (
    <button type="button" onClick={onClick} className={disabled ? disabledClass : root} disabled={disabled}>
      {label}
    </button>
  )
}

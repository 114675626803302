import { DefaultHeaderCellOverrides } from 'components/library/Table/DefaultHeaderCell'
import Table, { TableOverrides, TableProps } from 'components/library/Table/Table'
import {
  COLUMN_BOX_SHADOW,
  COLUMN_BOX_SHADOW_CLIP_PATH,
  MINIMUM_BATCH_COLUMN_WIDTH,
  TableRowOverrides,
} from 'components/library/Table/TableRow.styles'
import { TableWithReorderableColumnsOverrides } from 'components/library/Table/TableWithReorderableColumns'
import { makeStyleOverrides, mergeOverrideStyles } from 'components/utils/styles'

import { tableBodyCellHeights, TABLE_HEADER_HEIGHT } from './Tables.constants'

const useTableStyles = makeStyleOverrides<keyof Partial<TableOverrides>>(() => ({
  container: {
    position: 'relative',
    flex: 1,
    overflowX: 'scroll',
  },
  table: {
    minWidth: '100%',
    '&$rounded tbody': {
      '& tr:last-child': {
        borderBottom: 'none',
      },
    },
  },
  rounded: {},
}))

export const useDefaultHeaderCellStyles = makeStyleOverrides<keyof DefaultHeaderCellOverrides>((theme) => ({
  headerCellContent: {
    height: TABLE_HEADER_HEIGHT,
    padding: `0 ${theme.spacing(2)}`,
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
    borderRight: `1px solid ${theme.palette.dividers.medium}`,
    borderBottom: `1px solid ${theme.palette.dividers.medium}`,
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    ...theme.typography.sizes.s,
  },
}))

export const useTableRowStyles = makeStyleOverrides<keyof TableRowOverrides>((theme) => ({
  root: {
    borderBottom: '1px solid',
    borderBottomColor: theme.palette.dividers.light,
    overflowY: 'clip',

    '&$selectable:hover $bodyCell:not($stickyCell) > div': {
      backgroundColor: theme.palette.action.hover,
    },

    '&$keyboardFocused td:first-child > div::before': {
      backgroundColor: theme.palette.styleguide.blue,
      bottom: 0,
      content: '""',
      left: 0,
      position: 'absolute',
      top: 0,
      width: theme.spacing(0.5),
    },

    '&:hover $stickyCell': {
      display: 'table-cell',
    },
  },

  stickyCell: {
    display: 'none',
  },

  entering: {
    '$ $cellContent': {
      animationFillMode: 'forwards',
      animation: '$grow-height 0.5s ease',
    },
    '& $bodyCell, & $stickyCell': {
      animationFillMode: 'forwards',
      animation: '$flash 2.6s ease',
    },
  },

  exiting: {
    '& $cellContent': {
      animationFillMode: 'forwards',
      animation: '$grow-height 0.5s ease',
      animationDirection: 'reverse',
      overflow: 'hidden',
      borderBottom: 'none',
    },
  },

  exited: {
    display: 'none',
  },

  selectable: {
    cursor: 'pointer',
  },

  cellContent: {
    minHeight: tableBodyCellHeights.normal,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(0, 2),
    ...theme.typography.sizes.md,
    boxSizing: 'border-box',
  },

  bodyCell: {
    padding: 0,
    '&$stickyCell': {
      '& $cellContent': {
        padding: 0,
      },
    },
  },

  keyboardFocused: {},
}))

export const useTableWithReorderableColumnsStyles = makeStyleOverrides<keyof TableWithReorderableColumnsOverrides>(
  (theme) => ({
    table: {
      position: 'relative',
      borderCollapse: 'separate',
      '& tbody tr td': {
        borderBottom: `1px solid ${theme.palette.dividers.medium}`,
        borderRight: `1px solid ${theme.palette.dividers.medium}`,

        '&:first-child': {
          position: 'sticky',
          left: 0,
          zIndex: 2,
          backgroundColor: theme.palette.background.paper,
        },
        '&.hb-table__cell--selected': {
          backgroundColor: theme.palette.action.selected,
        },
        '&.hb-table__cell--batch': {
          padding: 0,
          width: MINIMUM_BATCH_COLUMN_WIDTH,
        },
      },
    },

    visibleTableHeader: {
      display: 'flex',
      minWidth: '100%',
      position: 'sticky',
      top: 0,
      zIndex: 3,

      borderTop: `1px solid ${theme.palette.dividers.light}`,

      '& th': {
        position: 'sticky',
        top: 0,
        zIndex: 1,

        background: theme.palette.background.contrastLight,
      },
      '& th:first-child': {
        position: 'sticky',
        left: 0,
        zIndex: 4,
      },
    },
    batchColumnEnabled: {
      width: 'fit-content',
      '& th:nth-child(2)': {
        position: 'sticky',
        zIndex: 3,
        // Using a pseudo-element to render the box shadow of the header cell
        // because clip path cuts off any overflow needed for the vertical resize bar to be seen
        '&:after': {
          position: 'absolute',
          content: '""',
          height: '100%',
          width: '100%',
          boxShadow: COLUMN_BOX_SHADOW,
          clipPath: COLUMN_BOX_SHADOW_CLIP_PATH,
          left: 0,
          top: 0,
          pointerEvents: 'none',
        },
      },
    },
    cellContent: {},
  })
)

export const StickyTable = ({ children, ...props }: Omit<TableProps, 'classes'>) => {
  const tableStyles = useTableStyles()
  const defaultHeaderCellStyles = useDefaultHeaderCellStyles()
  const tableRowStyles = useTableRowStyles()
  const tableWithReorderableColumnsStyles = useTableWithReorderableColumnsStyles()

  const mergedTableStyles = mergeOverrideStyles(tableStyles, props.styleOverrides?.Table)
  const mergedTableWithReorderableColumnsStyles = mergeOverrideStyles(
    tableWithReorderableColumnsStyles,
    props.styleOverrides?.TableWithReorderableColumns
  )
  const mergedTableRowStyles = mergeOverrideStyles(tableRowStyles, props.styleOverrides?.TableRow)
  const mergedDefaultHeaderCellStyles = mergeOverrideStyles(
    defaultHeaderCellStyles,
    props.styleOverrides?.DefaultHeaderCell
  )

  return (
    <Table
      {...props}
      hideArrows
      styleOverrides={{
        Table: mergedTableStyles,
        DefaultHeaderCell: mergedDefaultHeaderCellStyles,
        TableRow: mergedTableRowStyles,
        TableWithReorderableColumns: mergedTableWithReorderableColumnsStyles,
      }}
    >
      {children}
    </Table>
  )
}

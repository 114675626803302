import { MouseEvent, MouseEventHandler, ReactNode, useRef, useState } from 'react'

import { ButtonBase } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'
import { FileCopyIcon } from 'icons/FileCopyIcon'
import { Theme } from 'types/hb'

export const useClickToCopyTooltip = ({ delay = 1_000, initialTooltipText = 'Click to Copy' } = {}) => {
  const [tooltipText, setTooltipText] = useState(initialTooltipText)

  const copyTextToClipboard = (value: string) => navigator.clipboard.writeText(value)

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  const resetTooltipText = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current)
    }
    timeoutRef.current = setTimeout(() => {
      setTooltipText(initialTooltipText)
    }, delay)
  }

  const handleClickCopy = (value: string, tooltipCopiedText = 'Copied') => {
    copyTextToClipboard(value)
    setTooltipText(tooltipCopiedText)
    resetTooltipText()
  }

  return {
    copyTextToClipboard,
    initialTooltipText,
    resetTooltipText,
    setTooltipText,
    tooltipText,
    handleClickCopy,
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    height: 14,
    width: 14,
    marginLeft: theme.spacing(),
  },
}))

export interface ClickToCopyProps {
  className?: string
  value: string
  onClick?: MouseEventHandler
  label: ReactNode
  tooltipInitialText?: string
  tooltipCopiedText?: string
  hideCopyIcon?: boolean
  testId?: string
  tooltipPlacement?: Placement
  ariaLabel?: string
}

export const ClickToCopy = ({
  className,
  label,
  onClick,
  value,
  tooltipInitialText,
  tooltipCopiedText,
  hideCopyIcon,
  testId,
  tooltipPlacement = Placement.Top,
  ariaLabel,
}: ClickToCopyProps) => {
  const classes = useStyles()

  const { copyTextToClipboard, tooltipText, resetTooltipText, setTooltipText } = useClickToCopyTooltip({
    initialTooltipText: tooltipInitialText,
  })

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    onClick?.(e)
    copyTextToClipboard(value)
    setTooltipText(tooltipCopiedText || 'Copied')
    resetTooltipText()
  }

  return (
    <HbTooltip title={tooltipText} placement={tooltipPlacement}>
      <ButtonBase
        className={classNames(classes.button, className)}
        type="button"
        onClick={handleClick}
        aria-label={ariaLabel || `Click to copy ${value} to clipboard`}
        data-testid={testId}
      >
        {label}
        {!hideCopyIcon && <FileCopyIcon className={classes.icon} />}
      </ButtonBase>
    </HbTooltip>
  )
}

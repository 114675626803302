import { useState } from 'react'

import { useLazyQuery } from '@apollo/client'

import { QueryResult } from '@apollo/client/react/types/types'

import { useSnackbar } from 'notistack'

import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'
import { ExportDownloadLink } from 'components/ExportDownloadLink'
import {
  PollExportJobQuery,
  PollExportJobQueryVariables,
} from 'dashboards/shared/components/DashboardExport/__generated__/exportDashboardMutation.generated'
import { POLL_EXPORT_JOB } from 'dashboards/shared/components/DashboardExport/exportDashboardMutation'
import { Exact, JobStatus } from 'types/api'
import { assertExhaustive } from 'utils/typeAssertions'

const POLLING_WAIT_TIME = 30_000 // Poll for 30s
const POLL_INTERVAL = 1_000

export type PollJobStatusWithTimeout = ({
  jobToken,
}: {
  jobToken: string
}) => Promise<QueryResult<PollExportJobQuery, Exact<{ token: string }>>>

export const usePollExportJob = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar()
  const dispatch = useDispatch()
  const [exportStatus, setExportStatus] = useState<JobStatus | undefined>(undefined)
  const [pollingExceededTimeout, setPollingExceededTimeout] = useState(false)
  const [pollJobStatus, { stopPolling: stopPollingJobStatus }] = useLazyQuery<
    PollExportJobQuery,
    PollExportJobQueryVariables
  >(POLL_EXPORT_JOB, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => {
      const status = data.dashboardExportJob?.status
      setExportStatus(status)
      switch (status) {
        case JobStatus.Enqueued:
        case JobStatus.Started:
          break
        case JobStatus.Failed:
        case undefined:
          setExportStatus(JobStatus.Failed)
          stopPollingJobStatus()
          dispatch(setFlashError('Dashboard export error'))
          break
        case JobStatus.Processed: {
          stopPollingJobStatus()
          if (!data.dashboardExportJob?.result) {
            dispatch(setFlashError(`Dashboard export: finished without data`))
            break
          }
          const downloadURL = data.dashboardExportJob?.presignedUrl
          if (downloadURL) {
            const id = enqueueSnackbar('Your CSV is ready!', {
              variant: 'success',
              autoHideDuration: 60_000,
              testId: 'export-download-toast',
              action: <ExportDownloadLink downloadURL={downloadURL} handleClose={() => closeSnackbar(id)} />,
            })
          }
          break
        }
        default:
          assertExhaustive(status)
      }
    },
  })

  const pollJobStatusWithTimeout = ({ jobToken }: { jobToken: string }) => {
    setTimeout(() => {
      stopPollingJobStatus()
      setPollingExceededTimeout(true)
    }, POLLING_WAIT_TIME)
    return pollJobStatus({
      variables: { token: jobToken },
      pollInterval: POLL_INTERVAL,
    })
  }

  return {
    stopPollingJobStatus,
    pollJobStatus,
    pollJobStatusWithTimeout,
    exportStatus,
    pollingExceededTimeout,
  }
}

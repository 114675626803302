import { gql } from '@apollo/client'

export const UPSERT_LIBRARY_ENTITY_QUERY = gql`
  mutation UpsertLibraryEntity($input: RecordLibraryTransactionInput!) {
    recordLibraryTransaction(input: $input) {
      __typename
      people {
        token
      }
      businesses {
        token
      }
      devices {
        token
      }
      paymentCards {
        token
      }
      bankAccounts {
        token
      }
      cryptoAddresses {
        token
        address
      }
      financialInstitutions {
        token
      }
      products {
        token
      }
    }
  }
`

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const EditIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="-2 -2 20 20">
    <path d="M14,14H2V2H9.76l2-2H1A1,1,0,0,0,0,1V15a1,1,0,0,0,1,1H15a1,1,0,0,0,1-1V4.24l-2,2Z" />
    <path d="M9.29,5.29,7,7.59,6,10,8.41,9l2.3-2.29L12,5.41l3.71-3.7A1,1,0,1,0,14.29.29L9.59,5Z" />
  </SvgIcon>
)

export default EditIcon

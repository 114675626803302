import { Key } from 'react'

export default function useRangeSelection<T>(
  entries: T[],
  mostRecentlySelectedId: Key | null,
  itemKey: (row: T) => Key,
  isItemSelectable: (row: T) => boolean
): { getRangeSelection: (token: Key) => Key[] } {
  const getRangeSelection = (token: Key) => {
    if (!mostRecentlySelectedId) {
      return [token]
    }

    const findIndexOfToken = (tokenToFind: Key) => entries.findIndex((entry) => itemKey(entry) === tokenToFind)

    const startIndex = findIndexOfToken(mostRecentlySelectedId)
    const endIndex = findIndexOfToken(token)
    // should always be true, but if not, we should just select the current row
    if (startIndex !== -1 && endIndex !== -1) {
      const indices = [startIndex, endIndex].sort((a, b) => a - b) // could batch select up
      const selection = entries
        .slice(indices[0], indices[1] + 1)
        .filter(isItemSelectable)
        .map(itemKey)
        .filter((t) => t !== token)

      return [...selection, token] // token must be last to be most recent
    }
    return [token]
  }
  return { getRangeSelection }
}

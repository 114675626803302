import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const SharingIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 19 19">
    <path d="M15.5 12a3.48 3.48 0 0 0-2.5 1.11l-6-3a3.49 3.49 0 0 0 0-.61 3.49 3.49 0 0 0-.06-.6l6-3A3.49 3.49 0 1 0 12 3.5a3.49 3.49 0 0 0 .06.6l-6 3a3.5 3.5 0 1 0 0 4.78l6 3A3.5 3.5 0 1 0 15.5 12zm0-10A1.5 1.5 0 1 1 14 3.5 1.5 1.5 0 0 1 15.5 2zm-12 9A1.5 1.5 0 1 1 5 9.5 1.5 1.5 0 0 1 3.5 11zm12 6a1.5 1.5 0 1 1 1.5-1.5 1.5 1.5 0 0 1-1.5 1.5z" />
  </SvgIcon>
)

export default SharingIcon

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

export const useHeaderMenuStyles = makeStyles((theme: Theme) => ({
  header: {
    ...theme.typography.subtitle1,
    // ughhhh
    borderBottom: 'none !important',
    padding: `${theme.spacing()} ${theme.spacing(2)}`,
  },

  item: {
    // ughhhh
    borderBottom: 'none !important',
  },

  link: {
    // ughhh
    padding: `${theme.spacing(1.5)} !important`,
    // ughhh
    paddingLeft: `${theme.spacing(5)} !important`,
    // ughhh
    paddingRight: `${theme.spacing(2)} !important`,
  },

  divider: {
    margin: `${theme.spacing()} 0px`,
  },
}))

import { ApolloError } from '@apollo/client'
import { styled } from '@mui/material'

import { HbAlert } from 'components/HbComponents/HbAlert'

const StyledHbAlert = styled(HbAlert)<{ inline?: boolean }>(({ theme, inline }) => ({
  margin: inline ? 0 : theme.spacing(3),
}))

interface Props {
  error: ApolloError
  errorMessage?: string
  className?: string
  inline?: boolean
}

export function GQLError({ className: classNameProp, error, errorMessage = 'Failed to fetch data', inline }: Props) {
  if (!error) {
    return null
  }

  return (
    <StyledHbAlert severity="error" className={classNameProp} inline={inline}>
      {errorMessage}
    </StyledHbAlert>
  )
}

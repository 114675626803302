// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useDispatch } from 'actions/store'
import { Criteria } from 'components/library/Table/Criteria'
import { DefaultHeaderCellOverrides } from 'components/library/Table/DefaultHeaderCell'
import SortAndFilterHeaderCell from 'components/library/Table/SortAndFilterHeaderCell'
import { HeaderCellRenderProps } from 'components/library/Table/TableColumn'
import { BatchActionSelectUIState } from 'dashboards/shared/react/dashboards.config'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { toSortEnum } from 'utils/query/api.types'

const useStyles = makeStyles(() => ({
  root: {
    border: 'none',
  },
}))

interface Props {
  // TODO: revisit when updating to the new filters API
  handleFilterSortChange?: (newCriteria: Criteria) => void
  headerProps: HeaderCellRenderProps
  disableBatchAction?: boolean
  column: DashboardColumn
  loadingColumns?: boolean
  // TODO: revisit when updating to the new filters API
  clearOnAll?: boolean
  criteria: Criteria
  styleOverrides?: DefaultHeaderCellOverrides
  batchActionSelectUIState?: BatchActionSelectUIState
  onBatchSelectAll?: () => void
  onOpen?: () => void
}

export const DashboardHeaderCell = ({
  headerProps,
  disableBatchAction = false,
  clearOnAll = true,
  column,
  handleFilterSortChange,
  loadingColumns = true,
  criteria,
  styleOverrides,
  batchActionSelectUIState,
  onBatchSelectAll,
  onOpen,
}: Props) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const dashboardsActions = useDashboardActions()

  return (
    <SortAndFilterHeaderCell
      field={column.apiName}
      label={column.title}
      sortable={column.sortable}
      filterable={!!column.filterPath}
      isBatch={column.title === 'batch'} // TODO Figure out a way to not hardcode this
      disableBatchAction={disableBatchAction}
      batchActionSelectUIState={batchActionSelectUIState}
      onBatchSelectAll={onBatchSelectAll}
      clearOnAll={clearOnAll}
      criteria={criteria.direction || criteria.values ? criteria : undefined}
      loadingColumns={loadingColumns}
      onChange={(newCriteria: Criteria) => {
        if (handleFilterSortChange) {
          handleFilterSortChange(newCriteria)
        } else {
          const { direction, field } = newCriteria
          dispatch(dashboardsActions.sorts.set.action([{ sortDir: toSortEnum(direction), sortField: field }]))
        }
      }}
      onOpen={onOpen}
      {...headerProps}
      classes={{
        ...headerProps.classes,
        root: classnames(headerProps.classes?.root, classes.root),
      }}
      styleOverrides={{ DefaultHeaderCell: styleOverrides ?? {} }}
    />
  )
}

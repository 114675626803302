import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchAutomationRuleExecutionsResultFragment } from './searchAutomationRuleExecutions.fragments'

export const SEARCH_AUTOMATION_RULE_EXECUTIONS_QUERY = gql`
  query SearchAutomationRuleExecutions($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchAutomationRuleExecutions(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchAutomationRuleExecutionsResultFragment
    }
  }
  ${SearchAutomationRuleExecutionsResultFragment}
`
export const AUTOMATION_RULE_EXECUTIONS_COLUMNS_QUERY_V2 = gql`
  query AutomationRuleExecutionsColumnsV2($includeCustomColumns: Boolean!) {
    searchAutomationRuleExecutionsMetadataV2(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const AUTOMATION_RULE_EXECUTIONS_METADATA_QUERY_V2 = gql`
  query SearchAutomationRuleExecutionsMetadataV2($columns: [String!], $query: String) {
    searchAutomationRuleExecutionsMetadataV2(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

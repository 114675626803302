import { useMemo, useState } from 'react'

import { Assignment, Work } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import { formatBusinessType } from 'components/entities/Information/BusinessInformation'
import { getEnums } from 'helpers/stateHelpers'
import { VerifiedUserIcon } from 'icons'
import { CalendarIcon } from 'icons/CalendarIcon'
import { IdFieldIcon } from 'icons/IdFieldIcon'
import { Theme } from 'types/hb'

import { ContentComponentsMap, MergeBusinessEntity, MergeBusinessSubject } from '../types'
import { SetSelectedPrimarySubjectToken } from '../useSubjectMerging'

import { CompareTableLayout, DetailsSection, ListDetail, SharedEntityHeader, SimpleDetail, TinsDetail } from './shared'

const useBusinessRoleContentStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: theme.spacing(4),
    border: `1px solid ${theme.palette.styleguide.gray300}`,
    borderRadius: theme.shape.largeContainer.borderRadius,
  },
  detail: {
    padding: theme.spacing(1, 0),
    '&:first-of-type': {
      paddingTop: theme.spacing(0),
    },
    '&:last-of-type': {
      paddingBottom: theme.spacing(0),
    },
  },
}))

interface BusinessRoleContentProps {
  subject: MergeBusinessSubject
}

export const BusinessRoleContent = ({ subject }: BusinessRoleContentProps) => {
  const enums = useSelector(getEnums)

  const roleInActivity = useMemo(
    () => enums.roleInActivity.find(({ value }) => value === subject.roleInActivity)?.display,
    [subject.roleInActivity, enums]
  )

  const classes = useBusinessRoleContentStyles()

  return (
    <section className={classes.container} aria-label="Role">
      <HbText className={classes.detail} color={!roleInActivity ? 'disabled' : 'primary'} tag="div">
        {roleInActivity || 'Role in activity'}
      </HbText>
    </section>
  )
}

const useBusinessBasicInfoStyles = makeStyles(() => ({
  basicInfoSection: {},
}))

interface BusinessBasicInfoProps {
  entity: MergeBusinessEntity
  minLegalNamesHeight: number
  setMinLegalNamesHeight: (height: number) => void
  minDBANamesHeight: number
  setMinDBANamesHeight: (height: number) => void
}

export const BusinessBasicInfo = ({
  entity,
  minLegalNamesHeight,
  setMinLegalNamesHeight,
  minDBANamesHeight,
  setMinDBANamesHeight,
}: BusinessBasicInfoProps) => {
  const classes = useBusinessBasicInfoStyles()
  return (
    <DetailsSection className={classes.basicInfoSection} heading="Basic Information">
      <ListDetail
        fallbackLabel="Legal Names"
        Icon={IdFieldIcon}
        minHeight={minLegalNamesHeight}
        setMinHeight={setMinLegalNamesHeight}
        values={entity.legalNames}
      />
      <ListDetail
        fallbackLabel="DBA (Doing business as)"
        Icon={Assignment}
        minHeight={minDBANamesHeight}
        setMinHeight={setMinDBANamesHeight}
        values={entity.dbaNames}
      />
      <SimpleDetail fallbackLabel="External ID" Icon={VerifiedUserIcon} value={entity.externalId} />
      <SimpleDetail
        fallbackLabel="Type of Business"
        Icon={Work}
        value={entity.businessType && formatBusinessType(entity.businessType)}
      />
      <TinsDetail tins={entity.tins} />
    </DetailsSection>
  )
}

interface BusinessesCompareTableProps {
  selectedSubjects: MergeBusinessSubject[]
  selectedPrimarySubjectToken: string | null
  setSelectedPrimarySubjectToken: SetSelectedPrimarySubjectToken
}

const rows = ['header', 'role', 'basicInfo', 'createdAt', 'updatedAt'] as const

export const BusinessesCompareTable = ({
  selectedSubjects,
  selectedPrimarySubjectToken,
  setSelectedPrimarySubjectToken,
}: BusinessesCompareTableProps) => {
  const [minLegalNamesHeight, setMinLegalNamesHeight] = useState(0)
  const [minDBANamesHeight, setMinDBANamesHeight] = useState(0)

  const contentComponents = useMemo<ContentComponentsMap<typeof rows, MergeBusinessSubject>>(
    () => ({
      header: ({ datum }) => {
        const entity = datum.business
        if (!entity) return null
        return (
          <SharedEntityHeader
            isSelected={datum.token === selectedPrimarySubjectToken}
            setSelected={() => setSelectedPrimarySubjectToken(datum.token)}
            entity={entity}
          />
        )
      },
      role: ({ datum }) => <BusinessRoleContent subject={datum} />,
      basicInfo: ({ datum }) => {
        const entity = datum.business
        if (!entity) return null
        return (
          <BusinessBasicInfo
            entity={entity}
            minLegalNamesHeight={minLegalNamesHeight}
            setMinLegalNamesHeight={setMinLegalNamesHeight}
            minDBANamesHeight={minDBANamesHeight}
            setMinDBANamesHeight={setMinDBANamesHeight}
          />
        )
      },
      createdAt: ({ datum }) => {
        const entity = datum.business
        if (!entity) return null
        return (
          <DetailsSection heading="Created At">
            <SimpleDetail Icon={CalendarIcon} value={entity.createdAt} />
          </DetailsSection>
        )
      },
      updatedAt: ({ datum }) => {
        const entity = datum.business
        if (!entity) return null
        return (
          <DetailsSection heading="Updated At">
            <SimpleDetail Icon={CalendarIcon} value={entity.updatedAt} />
          </DetailsSection>
        )
      },
    }),
    [minDBANamesHeight, minLegalNamesHeight, selectedPrimarySubjectToken, setSelectedPrimarySubjectToken]
  )

  return (
    <CompareTableLayout
      contentComponents={contentComponents}
      data={selectedSubjects}
      rows={rows}
      selectedIndex={selectedSubjects.findIndex((s) => s.token === selectedPrimarySubjectToken)}
    />
  )
}

import { useCallback, useEffect, useRef } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { useLexicalNodeSelection } from '@lexical/react/useLexicalNodeSelection'
import { $wrapNodeInElement, mergeRegister } from '@lexical/utils'

import { styled } from '@mui/material'

import {
  $createParagraphNode,
  $getNodeByKey,
  $getSelection,
  $insertNodes,
  $isNodeSelection,
  $isRootOrShadowRoot,
  $setSelection,
  COMMAND_PRIORITY_EDITOR,
  COMMAND_PRIORITY_LOW,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  DecoratorNode,
  KEY_BACKSPACE_COMMAND,
  KEY_DELETE_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  LexicalCommand,
  NodeKey,
  SerializedLexicalNode,
  createCommand,
} from 'lexical'

import { getNarrativeSmartValueDomain } from 'components/pages/automations/editor/actions/smartValues'
import { useFeatureFlag } from 'hooks'
import { AutomationDomainType, FeatureFlag } from 'types/api'

import LiquidMarkdownEditor, { Api, Props as LiquidMarkdownEditorProps } from '../LiquidMarkdownEditor'

interface SerializedLiquidNode extends SerializedLexicalNode {
  type: 'liquid'
  text: string
  isRenameOtherInfoEnabled: boolean
}

const LiquidNodeContainer = styled('div')<{ selected?: boolean }>(({ theme, selected }) => ({
  fontSize: theme.typography.sizes.md.fontSize,
  padding: '0px 2px',
  backgroundColor: theme.palette.styleguide.backgroundMedium,
  backgroundClip: 'padding-box',
  borderRadius: '8px',
  border: selected ? `1.5px solid ${theme.palette.primary.main}` : '1.5px solid transparent',
}))

function LiquidNodeEditor({ nodeKey, ...props }: Omit<LiquidMarkdownEditorProps, 'onChange'> & { nodeKey: NodeKey }) {
  const [editor] = useLexicalComposerContext()
  const apiRef = useRef<Api>(null)

  const [isSelected, setSelected] = useLexicalNodeSelection(nodeKey)

  // Focus when initially added
  useEffect(() => {
    apiRef.current?.focus()
  }, [])

  const onDelete = useCallback(
    (event: KeyboardEvent) => {
      const node = $getNodeByKey(nodeKey) as LiquidNode

      // If the node is selected, delete it
      if (isSelected && $isNodeSelection($getSelection())) {
        event.preventDefault()
        node.remove()
        return true
      }

      // If we're in the code editor and it's empty, delete it
      if (apiRef.current?.hasFocus() && node.__text.length === 0) {
        node.remove()
        return true
      }

      return false
    },
    [isSelected, nodeKey]
  )

  const onEnter = useCallback(
    (event: KeyboardEvent) => {
      // Move focus into the code editor
      const latestSelection = $getSelection()
      if (isSelected && $isNodeSelection(latestSelection) && latestSelection.getNodes().length === 1) {
        event.preventDefault()
        apiRef.current?.focus()
      }
      return false
    },
    [isSelected]
  )

  const onEscape = useCallback(
    (event: KeyboardEvent) => {
      // Move focus out back to lexical
      if (apiRef.current?.hasFocus()) {
        event.preventDefault()
        event.stopImmediatePropagation()

        editor.update(() => {
          const parentRootElement = editor.getRootElement()
          if (parentRootElement !== null) {
            parentRootElement.focus()
          }
          setSelected(true)
        })
        return true
      }
      return false
    },
    [editor, setSelected]
  )

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(KEY_DELETE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_BACKSPACE_COMMAND, onDelete, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ENTER_COMMAND, onEnter, COMMAND_PRIORITY_LOW),
      editor.registerCommand(KEY_ESCAPE_COMMAND, onEscape, COMMAND_PRIORITY_LOW)
    )
  }, [editor, onDelete, onEnter, onEscape])

  // If many liquid editors becomes a perf issue, could render a static view here until it's focused.
  // Downside would be that we'd lose code highlighting without extra work.
  return (
    <LiquidNodeContainer selected={isSelected}>
      <LiquidMarkdownEditor
        ref={apiRef}
        {...props}
        onFocus={() => editor.update(() => $setSelection(null))}
        onChange={(newText) => {
          editor.update(() => {
            const node = $getNodeByKey(nodeKey) as LiquidNode
            node.setText(newText)
          })
        }}
      />
    </LiquidNodeContainer>
  )
}

function $convertLiquidElement(domNode: HTMLElement): DOMConversionOutput | null {
  const isLiquid = domNode.getAttribute('data-liquid') === 'true'
  const isRenameOtherInfoEnabled = domNode.getAttribute('data-isRenameOtherInfoEnabled') === 'true'
  if (isLiquid) {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const node = new LiquidNode(domNode.innerText, isRenameOtherInfoEnabled)
    return {
      node,
    }
  }

  return null
}

export class LiquidNode extends DecoratorNode<JSX.Element> {
  __text: string
  __isRenameOtherInfoEnabled: boolean

  constructor(text: string, isRenameOtherInfoEnabled: boolean, key?: NodeKey) {
    super(key)
    this.__text = text
    this.__isRenameOtherInfoEnabled = isRenameOtherInfoEnabled
  }

  setText(text: string) {
    const writable = this.getWritable()
    writable.__text = text
  }

  static getType(): string {
    return 'liquid'
  }

  static clone(node: LiquidNode): LiquidNode {
    return new LiquidNode(node.__text, node.__isRenameOtherInfoEnabled, node.__key)
  }

  static importJSON(serializedNode: SerializedLiquidNode): LiquidNode {
    return new LiquidNode(serializedNode.text, serializedNode.isRenameOtherInfoEnabled)
  }

  exportJSON(): SerializedLiquidNode {
    return {
      version: 1,
      text: this.__text,
      type: 'liquid',
      isRenameOtherInfoEnabled: this.__isRenameOtherInfoEnabled,
    }
  }

  static importDOM(): DOMConversionMap<HTMLDivElement> | null {
    return {
      span: (domNode: HTMLDivElement) => {
        if (!domNode.hasAttribute('data-liquid')) {
          return null
        }

        return {
          conversion: $convertLiquidElement,
          priority: 1,
        }
      },
    }
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement('span')
    element.innerText = this.__text
    element.setAttribute('data-liquid', 'true')
    element.setAttribute('data-isRenameOtherInfoEnabled', String(this.__isRenameOtherInfoEnabled))
    return { element }
  }

  // eslint-disable-next-line class-methods-use-this
  createDOM(): HTMLElement {
    const elem = document.createElement('div')
    elem.style.display = 'inline-block'
    elem.style.minWidth = '100px'
    return elem
  }

  // eslint-disable-next-line class-methods-use-this
  updateDOM(): boolean {
    return false
  }

  decorate() {
    const domainType = AutomationDomainType.Review

    return (
      <LiquidNodeEditor
        initialValue={this.__text}
        domain={domainType}
        variables={[getNarrativeSmartValueDomain(this.__isRenameOtherInfoEnabled)]}
        nodeKey={this.__key}
      />
    )
  }
}

export const INSERT_LIQUID_COMMAND: LexicalCommand<void> = createCommand('INSERT_LIQUID_COMMAND')

export function LiquidPlugin() {
  const [editor] = useLexicalComposerContext()
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)

  useEffect(() => {
    if (!editor.hasNodes([LiquidNode])) {
      throw new Error('LiquidPlugin: LiquidNode not registered on editor')
    }

    return editor.registerCommand(
      INSERT_LIQUID_COMMAND,
      () => {
        const liquidNode = new LiquidNode('', isRenameOtherInfoEnabled)

        $insertNodes([liquidNode])
        if ($isRootOrShadowRoot(liquidNode.getParentOrThrow())) {
          $wrapNodeInElement(liquidNode, $createParagraphNode).selectEnd()
        }

        return true
      },
      COMMAND_PRIORITY_EDITOR
    )
  }, [editor, isRenameOtherInfoEnabled])

  return null
}

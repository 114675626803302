import { gql } from '@apollo/client'

import { dataTypeOfEntry } from 'components/entities/LibraryQueries'
import { LibraryObjectIconLibraryObjectFragment } from 'components/library/__generated__/LibraryObjectIcon.generated'
import DataIcon from 'icons/DataIcon'

type Props = {
  libraryObject: LibraryObjectIconLibraryObjectFragment
  className?: string
  highlight?: boolean
  noHover?: boolean
}

export const LibraryObjectIcon = ({ libraryObject, ...rest }: Props) => (
  <DataIcon dataType={dataTypeOfEntry(libraryObject)} {...rest} />
)

LibraryObjectIcon.fragments = {
  libraryObject: gql`
    fragment LibraryObjectIconLibraryObject on LibraryObject {
      __typename
      token
    }
  `,
}

import { useMemo, useCallback } from 'react'

import { gql } from '@apollo/client'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'
import { DynamicVirtualList } from 'components/library/Virtualized/List'
import { Theme } from 'types/hb'

import ActivityLogItem, { ListData } from './AutomationActivityLogItem'

import { ActivityLogAutomationRuleFragment as AutomationRule } from './__generated__/AutomationActivityLog.generated'

export const PAGE_SIZE = 20

const useVirtualStyles = makeStyles<Theme>(() => ({
  container: {
    height: '95%',
  },
}))

interface Props {
  automationRule: AutomationRule
  loadMoreItems: (executionsCursor: string | null) => void
}

// TODO(ali): We should pull this out into a pagination helper once types are figured out
const useInfiniteLoaderProps = (list: AutomationRule['ruleExecutions']) => {
  const listItems = useMemo(() => {
    const filterEmpty = <Node,>(node: Node | null | undefined): node is Node => !!node
    return list?.edges?.map((edge) => edge?.node)?.filter(filterEmpty) || []
  }, [list])

  const numEvents = useMemo(() => {
    return list?.edges?.length || 0
  }, [list])

  const itemCount = list.pageInfo.hasNextPage ? numEvents + 1 : numEvents
  const isItemLoaded = useCallback(
    (index: number) => !list.pageInfo.hasNextPage || index < numEvents,
    [list, numEvents]
  )

  return {
    listItems,
    isItemLoaded,
    itemCount,
  }
}

export default function ActivityLog({ automationRule, loadMoreItems }: Props) {
  const { container } = useVirtualStyles()
  const { pageInfo } = automationRule.ruleExecutions

  const { listItems, isItemLoaded, itemCount } = useInfiniteLoaderProps(automationRule.ruleExecutions)

  const itemData = {
    listItems,
    isItemLoaded,
  }

  if (!listItems.length) {
    return <HbText>This Automation has not had any activity yet</HbText>
  }

  const infiniteLoaderProps = {
    isItemLoaded,
    itemCount,
    loadMoreItems: () => loadMoreItems(pageInfo.endCursor),
    threshold: PAGE_SIZE,
  }

  return (
    <div className={container}>
      <DynamicVirtualList<ListData>
        estimatedItemSize={200}
        fallbackItemSize={150}
        itemData={itemData}
        infiniteLoaderProps={infiniteLoaderProps}
      >
        {ActivityLogItem}
      </DynamicVirtualList>
    </div>
  )
}

ActivityLog.fragments = {
  automationRule: gql`
    fragment ActivityLogAutomationRule on CanonicalAutomationRule {
      ... on CanonicalAutomationRuleBase {
        __typename
        token
        ruleExecutions(first: $executionsSize, after: $executionsCursor) {
          edges {
            node {
              ...ActivityLogItemAutomationRuleExecution
            }
          }
          pageInfo {
            endCursor
            hasNextPage
          }
        }
      }
    }
    ${ActivityLogItem.fragments.ruleExecution}
  `,
}

import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionTypeEnum } from 'types/api'

export const BatchFlagDialog: DialogComponent = (props) => {
  const { children, ...dialogProps } = props

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Flag}
      confirmEnabled
      title="Flag transactions"
      primaryText="Would you like to flag the transactions selected?"
      confirmText="Yes, flag transactions."
    >
      {children}
    </BaseBatchActionDialog>
  )
}

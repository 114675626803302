import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { stringify } from 'query-string'

import { useHistory } from 'react-router-dom'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'

import { useFeatureFlag } from 'hooks'
import { AutomationRuleTemplateCategory, FeatureFlag } from 'types/api'
import { useParsedQuery } from 'utils/history'

import { getFilteredRecipeCategories, getRecipeTags } from '../recipeTags'

import { EXPLORE_AUTOMATIONS, AUTOMATION_RULE_TEMPLATE_CATEGORIES } from './ExploreAutomations.queries'
import {
  ExploreAutomationsQuery,
  AutomationRuleTemplateCategoriesQuery,
} from './__generated__/ExploreAutomations.queries.generated'

export type CategoryAndSearchQuery = ReturnType<typeof useCategoryAndSearchQueries>

export type CategoryWithCount = { category: AutomationRuleTemplateCategory | null; count: number }
export type CategoryWithCountBySlug = Record<string, CategoryWithCount>

export const useCategoryAndSearchQueries = () => useParsedQuery<{ category?: string; search: string | null }>()

export const useCategoryAndSearchChangeHandlers = ({ category, search }: CategoryAndSearchQuery) => {
  const history = useHistory()
  const usage = useUsage()

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // Setting undefined so the query param is removed if empty
    // Persisting category and search in the url if one or the other is changed
    history.replace({
      search: stringify({ search: event.target.value || undefined, category: category || undefined }),
    })
  }

  const handleCategoryChange = (newCategory: string) => {
    if (newCategory) {
      usage.logEvent({
        name: 'automations:exploreAutomationsCategory:clicked',
        data: { category: newCategory },
      })
    }

    history.replace({
      search: stringify({ category: newCategory, search: search || undefined }),
    })
  }

  const updateCategoryAndSearch = (newCategory?: string, newSearch?: string) => {
    history.replace({
      search: stringify({ category: newCategory, search: newSearch || undefined }),
    })
  }
  return { handleSearchChange, handleCategoryChange, updateCategoryAndSearch }
}

export const useAutomationRuleTemplateCategories = () =>
  useQuery<AutomationRuleTemplateCategoriesQuery>(AUTOMATION_RULE_TEMPLATE_CATEGORIES)

export const useExploreAutomationsQuery = () =>
  useQuery<ExploreAutomationsQuery>(EXPLORE_AUTOMATIONS, { fetchPolicy: 'cache-and-network' })

export const useExploreAutomationsData = ({ category, search }: CategoryAndSearchQuery) => {
  const { loading, data, error } = useExploreAutomationsQuery()

  const recipeCardData = useMemo(
    () =>
      data?.automationRuleTemplates.map((template) => {
        const tags = getRecipeTags(template)

        // Used for filtering/search below
        const tagValues = new Set(tags.map((t) => t.value))
        const templateCategory = template.automationTemplateCategory
        const searchValue = [template.name, templateCategory?.name, ...tagValues]
          .filter(Boolean)
          .join(' ')
          .toLowerCase()

        return { template, tags, searchValue, categorySlug: templateCategory?.slug, tagValues }
      }) || [],
    [data]
  )

  const categoryWithCountBySlug =
    useMemo(() => {
      return data?.automationRuleTemplates.reduce((acc: CategoryWithCountBySlug, template) => {
        const templateCategory = template.automationTemplateCategory
        if (templateCategory?.slug) {
          const key = templateCategory.slug
          acc[key] = {
            category: templateCategory,
            count: (acc[key]?.count || 0) + 1,
          }
        }
        return acc
      }, {})
    }, [data]) || {}

  // TODO(ali): This won't scale if we have many recipe templates. We can pass
  // the search params into the query to filter on the server side as a step before
  // introducing elasticsearch
  const filteredData = useMemo(
    () =>
      recipeCardData?.filter(({ searchValue, categorySlug }) => {
        const categoryFiltered = !category || category === 'all' || categorySlug === category

        const searchTerms = search?.split(' ') || []
        const searchFiltered = searchTerms.every((term) => searchValue.includes(term.toLowerCase()))

        return categoryFiltered && searchFiltered
      }) || [],
    [category, recipeCardData, search]
  )

  return {
    filteredData,
    data,
    loading,
    error,
    categoryWithCountBySlug,
  }
}

/**
 * @deprecated
 * This returns tags (which were used as categories) and not actual categories
 */
export const useCategoryOptions = (includeAll = true) => {
  const isMiddeskEnabled = useFeatureFlag(FeatureFlag.EnableMiddeskIntegration)
  const options = getFilteredRecipeCategories(isMiddeskEnabled)
  return includeAll ? [{ display: 'All Categories', value: 'all' }, ...options] : options
}

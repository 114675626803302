import { FieldPolicy } from '@apollo/client'

import { Big } from 'big.js'

export const decimalTypePolicy: FieldPolicy<Big, string> = {
  merge: (_, incoming) => {
    if (incoming === null || incoming === undefined) {
      // It's important for these methods to return null if passed null
      return incoming
    }

    return Big(incoming)
  },
}

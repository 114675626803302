import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const LocationIcon: FC<SvgProps> = ({ height = 24, width = 24, viewBox = '0 0 24 24', ...props }) => (
  <SvgIcon height={height} width={width} viewBox={viewBox} {...props}>
    <path
      d="M12 2.5a8 8 0 0 0-8 8.2c0 3.2 2.5 7 7.3 11.2.4.4 1 .4 1.4 0 4.9-4.3 7.3-8 7.3-11.2a8 8 0 0 0-8-8.2Zm0 10a2 2 0 0 1-2-2c0-1.1.9-2 2-2a2 2 0 0 1 2 2 2 2 0 0 1-2 2Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default LocationIcon

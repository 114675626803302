import { ApolloClient } from '@apollo/client'
import { composeWithDevTools } from '@redux-devtools/extension'
import * as Sentry from '@sentry/react'

import { routerMiddleware } from 'connected-react-router'

import { History } from 'history'
// Rule exists to guide people towards this file.
// eslint-disable-next-line no-restricted-imports
import { useDispatch as $useDispatch, useSelector as $useSelector } from 'react-redux'

import { Action, AnyAction, applyMiddleware, compose, createStore, Reducer, Store } from 'redux'

import thunk from 'redux-thunk'

import { UsageTracker } from 'helpers/SessionTracking/UsageTracker'
import Api from 'helpers/api'

import { actionSanitizer, stateSanitizer } from 'helpers/stateHelpers'

import createRootReducer from 'reducers'
import { yamlKitchenReducer } from 'reducers/YamlKitchen/yamlKitchenReducer'

import { InitialData } from 'types/hb'

import type Pilot from 'helpers/pilot'

// State is equivalent to the return type of the root reducer
export type State = ReturnType<ReturnType<typeof createRootReducer>> & {
  // Types for lazy-loaded reducers
  yamlKitchen?: ReturnType<typeof yamlKitchenReducer>
}

export type HbApolloCache = any

export type ThunkContext = {
  api: Api
  pilot: Pilot
  gqlClient: ApolloClient<HbApolloCache>
  usage: UsageTracker
}

export type Thunk<R> = (dispatch: Dispatch, getState: () => State, context: ThunkContext) => R

export type AsyncThunk<T> = Thunk<Promise<T | null | undefined>>

// Dispatch for our store takes a Thunk or an Action and returns the return value of the action
export type Dispatch = <R, A extends Action<string> | Thunk<R>>(
  action: A
) => A extends Thunk<infer Wrapped> ? Wrapped : void

// react-redux functions typed for our state to avoid the need to specify the types every time
export const useSelector = <T>(selector: (state: State) => T, equalityFn?: (left: T, right: T) => boolean) =>
  $useSelector<State, T>(selector, equalityFn)
export const useDispatch = () => $useDispatch<Dispatch>()

export interface EnhancedStore extends Store<State> {
  asyncReducers: Record<string, Reducer<unknown>>
  injectReducer: (key: string, reducer: Reducer<unknown>) => void
}

const composeEnhancers = DEBUG ? composeWithDevTools : compose

export function configureStore(
  initialData: InitialData,
  usage: UsageTracker,
  history: History<unknown>,
  gqlClient: ApolloClient<unknown>,
  pilot: Pilot
) {
  const { csrfToken, urls, gitSha1 } = initialData
  const api = new Api({
    csrfToken,
    urls,
    gitSha1,
  })

  const enhancers = [
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument({
        api,
        pilot,
        gqlClient,
        usage,
      })
    ),
    Sentry.createReduxEnhancer({
      actionTransformer: actionSanitizer,
      stateTransformer: stateSanitizer,
    }),
  ]

  if (DEBUG) {
    enhancers.push((createStoreEnh: any) => (...args: any[]) => {
      const enhancedStore = createStoreEnh(...args)
      const { dispatch } = enhancedStore
      return {
        ...enhancedStore,
        dispatch: (action: AnyAction) => {
          // Create a performance measure for each dispatched action
          // This will show up in the browser performance timeline
          const markName = `Redux - ${action.type}`
          performance.mark(markName)
          const result = dispatch(action)
          performance.measure(markName, markName)
          return result
        },
      }
    })
  }

  const store = createStore(createRootReducer(history), composeEnhancers(...enhancers)) as EnhancedStore

  // Allow dynamic injection of reducers to the store
  store.asyncReducers = {}
  store.injectReducer = (key, asyncReducer) => {
    store.asyncReducers[key] = asyncReducer
    store.replaceReducer(createRootReducer(history, store.asyncReducers))
  }

  if (DEBUG) {
    ;(window as any)[`__store_${Math.random().toString(36).substring(7)}`] = store
  }

  return store
}

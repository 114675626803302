import { calcDaysUntilTimestamp } from 'helpers/uiHelpers'
import { Workflow } from 'reducers/reviewsReducer'

import { ReviewStatesEnum } from 'types/api'

export const getReviewWorkflowProgressCounts = (workflow: Workflow | null) => {
  if (!workflow) return { completed: 0, total: 0 }
  const { actions = [] } = workflow
  const total = actions.map(({ tasks }) => tasks).flat().length
  const completed = actions.reduce((_completed, _nextAction) => {
    if (_nextAction.complete) {
      return _completed + _nextAction.tasks.length
    }
    return _completed + _nextAction.tasks.filter((task) => task.status === 'VALID').length
  }, 0)
  return { completed, total }
}

export const getTimeLeftAbbreviatedText = (
  review: { dueAt?: string | null; openAt?: string | null; state: ReviewStatesEnum },
  orgTimeZone: string
) => {
  if ([ReviewStatesEnum.Completed, ReviewStatesEnum.Cancelled].includes(review.state)) {
    return null
  }

  if (review.state === 'pending') {
    const daysToOpen = calcDaysUntilTimestamp(review, 'openAt', orgTimeZone)
    return daysToOpen !== null ? `${daysToOpen}d` : ''
  }

  const daysToFile = calcDaysUntilTimestamp(review, 'dueAt', orgTimeZone)
  return daysToFile !== null ? `${daysToFile}d` : ''
}

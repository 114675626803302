import { State } from 'actions/store'

import { simpleSelector } from 'reducers/utils'

export const batchActionsSelectors = {
  batchSelectedItems: simpleSelector((state) => state.batchActions.batchSelectedItems),
  isBatchSelectedByToken: (state: State, token: string): boolean => !!state.batchActions.batchSelectedItems[token],
  isRecentlyUpdatedByToken: (state: State, token?: string): boolean =>
    token ? !!state.batchActions.recentlyUpdatedItems[token] : false,
  isSomeItemBatchSelected: simpleSelector(
    (state) => Object.values(state.batchActions.batchSelectedItems).filter((isSelected) => isSelected).length > 0
  ),
  numBatchItemsSelected: simpleSelector(
    (state) => Object.values(state.batchActions.batchSelectedItems).filter((isSelected) => isSelected).length
  ),
  batchSelectedItemsArray: simpleSelector((state) =>
    Object.entries(state.batchActions.batchSelectedItems)
      .filter(([_key, value]) => value)
      .map(([key]) => key)
  ),
  mostRecentlyBatchSelectedItem: simpleSelector((state) => state.batchActions.mostRecentlyBatchSelectedItem),
  mostRecentBatchJobId: simpleSelector((state) => state.batchActions.mostRecentBatchJobId),
}

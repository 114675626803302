import type * as Types from '../../../../../../../types/api'

import type { StrictLogicalExpression } from 'types/automations'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type PollNarrativeGenerationQueryVariables = Types.Exact<{
  token: Types.Scalars['ID']['input']
}>

export type PollNarrativeGenerationQuery = {
  __typename?: 'Query'
  reviewNarrativeGenerationJob: {
    __typename?: 'ReviewNarrativeGenerationJob'
    token: string
    enqueuedAt: string
    errorMessage: string | null
    status: Types.JobStatus
    generatedNarrative: string | null
  }
}

export const PollNarrativeGenerationDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'PollNarrativeGeneration' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
          type: { kind: 'NonNullType', type: { kind: 'NamedType', name: { kind: 'Name', value: 'ID' } } },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'reviewNarrativeGenerationJob' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'token' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'token' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                { kind: 'Field', name: { kind: 'Name', value: 'enqueuedAt' } },
                { kind: 'Field', name: { kind: 'Name', value: 'errorMessage' } },
                { kind: 'Field', name: { kind: 'Name', value: 'status' } },
                { kind: 'Field', name: { kind: 'Name', value: 'generatedNarrative' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<PollNarrativeGenerationQuery, PollNarrativeGenerationQueryVariables>

import { HbTag, Props as HbTagProps } from 'components/HbComponents/HbTag'
import { Hue } from 'components/colors'

import { AutomationRuleExecutionMode } from 'types/api'

export const getAutomationEnabledPillProps = (
  enabled: boolean,
  executionMode: AutomationRuleExecutionMode
): HbTagProps => {
  let label: string
  let themeColor: Hue

  if (enabled && executionMode === AutomationRuleExecutionMode.Automatic) {
    label = 'Enabled'
    themeColor = 'mint'
  } else if (enabled && executionMode === AutomationRuleExecutionMode.Preview) {
    label = 'Preview'
    themeColor = 'sea'
  } else {
    label = 'Disabled'
    themeColor = 'lemon'
  }

  return {
    label,
    themeColor,
  }
}

function AutomationEnabledPill({
  enabled,
  executionMode,
  className,
}: {
  enabled: boolean
  executionMode: AutomationRuleExecutionMode
  className?: string
}) {
  return <HbTag className={className} {...getAutomationEnabledPillProps(enabled, executionMode)} />
}

export default AutomationEnabledPill

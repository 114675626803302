import { useQuery } from '@apollo/client'

import { FormControl, MenuItem, Select, FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import invariant from 'tiny-invariant'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useIsAutomationRule } from 'components/pages/automations/editor/AutomationRuleOrTemplateContext'
import { Theme } from 'types/hb'

import { GetOrganizationTagsDocument } from 'utils/__generated__/GetOrganizationTagsQuery.generated'

import type { FormSchemaReturnType, FormSchema } from '../formSchema'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  select: {
    margin: theme.spacing(2, 0, 1, 0),
  },
  selectRoot: {
    width: '100%',
  },
  label: {
    paddingBottom: theme.spacing(),
  },
}))

export default function CustomizeTagAction({ form }: { form: FormSchemaReturnType }) {
  const classes = useStyles()

  const { data: tagData } = useQuery(GetOrganizationTagsDocument, {
    fetchPolicy: 'cache-first',
  })

  const { setValue } = form
  const isRule = useIsAutomationRule()

  return tagData ? (
    <div className={classes.root}>
      <Controller<FormSchema>
        name="actionParams.tagParams.tagDefinitionToken"
        render={({ field: { name, value, onChange, ...inputProps }, fieldState }) => (
          <FormControl error={fieldState.invalid}>
            <HbText size="s" color="secondary" bold className={classes.label}>
              Select Tag
            </HbText>
            <Select
              disabled={!isRule}
              name={name}
              value={isRule ? value : ''}
              onChange={(e) => {
                const tagToken = e.target.value
                const tag = tagData?.currentOrganization.tagDefinitions.find((def) => def.token === tagToken)
                invariant(tag, 'Tag not found')
                setValue('actionText', `Tag as ${tag.label}`)
                onChange(tag.token)
              }}
              inputProps={inputProps}
            >
              {tagData.currentOrganization.tagDefinitions.map((def) => (
                <MenuItem value={def.token} key={def.token}>
                  {def.label}
                </MenuItem>
              ))}
            </Select>
            <FormHelperText>{fieldState.error?.message}</FormHelperText>
          </FormControl>
        )}
      />
    </div>
  ) : null
}

/**
 *
 * @param prefix should be unique to the code path that is using this factory
 */
const loadingTokenPrefixFactory = (prefix: string) => {
  const prefixSerializer = {
    serialize: (token: string) => `${prefix}@@${token}@@`,
    match: (loadingToken: string, token: string) => loadingToken.startsWith(prefixSerializer.serialize(token)),
  }
  return prefixSerializer
}

export const createLibraryDataLoadingPrefixSerializer = loadingTokenPrefixFactory('attachLibraryDataToInvestigation')

import { ArrowDropDown, FilterList } from '@mui/icons-material'
import { Popover } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'actions/store'
import { supportedTypes } from 'dashboards/reviews/components/Header/filtering/FilterButton/FilterFieldSelect'
import useLogDashboardFilterInteraction from 'dashboards/reviews/components/Header/filtering/hooks/useLogDashboardFilterInteraction'
import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { ArrowDownwardIcon, ArrowUpwardIcon, VisibilityOffIcon } from 'icons'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { OperatorEnum, SortEnum, SortValueInput } from 'types/api'
import { Theme } from 'types/hb'

import { HbButton, HbButtonSizes, HbButtonVariants } from '../../HbComponents/HbButton'

const useStyles = makeStyles((theme: Theme) => ({
  controlsList: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: theme.spacing(0.5),
    padding: `${theme.spacing()} ${theme.spacing()}`,
  },
  control: { justifyContent: 'flex-start', marginRight: theme.spacing(5) },
}))

interface ControlProps {
  size: HbButtonSizes
  variant: HbButtonVariants
  column: DashboardColumn
}

function FilterControl(props: ControlProps & { closeControls: () => void }) {
  const dispatch = useDispatch()
  const dashboardsActions = useDashboardActions()
  const logAction = useLogDashboardFilterInteraction()

  return (
    <HbButton
      {...props}
      label="Add filter"
      Icon={FilterList}
      onClick={(e) => {
        e.stopPropagation()
        dispatch(
          dashboardsActions.filters.createComponentById.action({
            _type: 'SearchFilterWithId',
            id: '',
            field: props.column.apiName,
            predicate: { values: [], operator: OperatorEnum.Is },
          })
        )
        dispatch(dashboardsActions.filters.remoteOpen(true))
        logAction('dashboard:filter:clicked')
        props.closeControls()
      }}
      className={useStyles().control}
      fullWidth
    />
  )
}

function SortControl({
  sortDir,
  closeControls,
  ...props
}: ControlProps & { sortDir: SortEnum; closeControls: () => void }) {
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const isAscending = sortDir === 'asc'
  const label = isAscending ? 'Sort ascending' : 'Sort descending'
  const icon = isAscending ? ArrowUpwardIcon : ArrowDownwardIcon
  const dispatch = useDispatch()
  const currentSorts = useSelector(dashboardsSelectors.sorts.valid) as SortValueInput[]

  const setSort = () => {
    if (currentSorts.some((sort) => sort.sortField === props.column.apiName)) {
      dispatch(dashboardsActions.sorts.update.direction.action({ direction: sortDir, field: props.column.apiName }))
    } else {
      dispatch(dashboardsActions.sorts.set.action([{ sortDir, sortField: props.column.apiName }]))
    }
  }

  return (
    <HbButton
      {...props}
      label={label}
      Icon={icon}
      onClick={(e) => {
        e.stopPropagation()
        setSort()
        closeControls()
      }}
      fullWidth
      className={useStyles().control}
    />
  )
}

function HideControl(props: ControlProps) {
  const { handleSingleColumnSelectionChange } = useDashboardColumns()

  return (
    <HbButton
      {...props}
      Icon={VisibilityOffIcon}
      label="Hide column"
      onClick={(e) => {
        e.stopPropagation()
        handleSingleColumnSelectionChange(props.column)
      }}
      fullWidth
      className={useStyles().control}
    />
  )
}

const AdvancedFilterControls = ({
  field,
  isOpen,
  onClose,
  anchorEl,
  sortable,
}: {
  field: string
  isOpen: boolean
  onClose: () => void
  anchorEl?: HTMLElement
  sortable?: boolean
}) => {
  const classes = useStyles()
  const { filterableColumns, filteredColumns } = useDashboardColumns()

  const column =
    filterableColumns.find((c) => c.apiName === field) || filteredColumns.find((c) => c.apiName === field && c.sortable)
  const config = useDashboardConfig()

  if (!column) return null

  const defaultControlProps: ControlProps = {
    size: 'medium',
    variant: 'textSecondary',
    column,
  }

  const isFilterableField = filterableColumns.some(
    (c) => c.filterPath && c.type && supportedTypes.includes(c.type) && c.apiName === field
  )

  return (
    <>
      <ArrowDropDown />
      <Popover
        onClick={(e) => e.stopPropagation()}
        onClose={onClose}
        anchorEl={anchorEl}
        open={isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <div className={classes.controlsList}>
          {isFilterableField && <FilterControl {...defaultControlProps} closeControls={() => onClose()} />}
          {sortable && (
            <>
              <SortControl {...defaultControlProps} sortDir={SortEnum.Asc} closeControls={() => onClose()} />
              <SortControl {...defaultControlProps} sortDir={SortEnum.Desc} closeControls={() => onClose()} />
            </>
          )}
          {!config.table.forcedVisibilityColumns.includes(field) && <HideControl {...defaultControlProps} />}
        </div>
      </Popover>
    </>
  )
}

export default AdvancedFilterControls

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { capitalize } from 'lodash'

import { sentenceCase } from 'sentence-case'

import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import { getEntityCaseAndAlertCount } from 'components/cases/Tabs/Library/CaseLibraryHelpers'
import { Theme } from 'types/hb'

import { MergeEntity, SharedStepProps } from './types'

const useInitialStepDialogStyles = makeStyles((theme: Theme) => ({
  subheading: {
    margin: theme.spacing(2, 0, 1),
  },
  listItem: {
    margin: theme.spacing(0.5, 0),
  },
  subtext: {
    marginLeft: theme.spacing(1.25),
  },
}))

type InitialStepDialogProps = SharedStepProps & {
  selectedEntities: MergeEntity[]
  loading: boolean
}

export const InitialStepDialog = ({
  libraryType,
  loading,
  open,
  selectedEntities,
  onClose,
  setMergeStep,
}: InitialStepDialogProps) => {
  const classes = useInitialStepDialogStyles()
  return (
    <HbDialog
      id="subjectMergeInitialDialog"
      confirmText="Continue"
      content={
        <>
          <HbText>You have selected to merge the below profiles.</HbText>
          <HbText className={classes.subheading} tag="p" color="secondary" size="s">
            {sentenceCase(capitalize(libraryType))}
          </HbText>
          <ul>
            {selectedEntities.map((entity) => (
              <li key={entity.token} className={classes.listItem}>
                <HbText>{entity.displayName}</HbText>
                <HbText className={classes.subtext} color="secondary" size="s">
                  {getEntityCaseAndAlertCount(entity)}
                </HbText>
              </li>
            ))}
          </ul>
        </>
      }
      onConfirm={() => setMergeStep('compare')}
      onClose={onClose}
      open={open}
      loading={loading}
      title="Merge profiles"
    />
  )
}

import { useEffect } from 'react'

import { Close } from '@mui/icons-material'

import { HbAnimatedLogo } from 'components/HbComponents/HbAnimatedLogo'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import { CaseFile } from 'components/cases/Tabs/Files/caseFileTypes'

import { AttachmentSummaryFooter } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/AttachmentSummaryFooter'
import { FadeBar } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/FadeBar'
import { makeRequiredStyles, mergeOverrideStyles } from 'components/utils/styles'
import AutoAwesome from 'icons/AutoAwesome'
import { AttachmentSummaryStatus } from 'types/api'
import { Theme } from 'types/hb'

export const SUMMARY_PANEL_WIDTH = 480

type AttachmentSummaryStyleKeys =
  | 'pane'
  | 'header'
  | 'title'
  | 'loader'
  | 'summaryText'
  | 'ellipsisText'
  | 'poweredByText'
  | 'content'
  | 'statements'

const useStyles = makeRequiredStyles<AttachmentSummaryStyleKeys>((theme: Theme) => ({
  pane: {
    display: 'flex',
    flexDirection: 'column',
    width: SUMMARY_PANEL_WIDTH,
    borderLeft: `1px solid ${theme.palette.divider}`,
    // height: '100%',
    background: 'white',
  },
  header: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  title: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: theme.spacing(),
    '& svg': {
      color: theme.palette.styleguide.hbBlueMedium,
    },
  },
  ellipsisText: {
    textAlign: 'center',
  },
  summaryText: {
    color: theme.palette.styleguide.hbBlueMedium,
  },
  poweredByText: {
    display: 'inline-flex',
    gap: theme.spacing(),
  },
  content: {
    display: 'flex',
    minHeight: '100px',
    flexDirection: 'column',
    overflowY: 'scroll',
  },
  loader: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(4),
    overflow: 'hidden',
  },
  statements: {
    flex: 1,
    listStyle: 'none',
    padding: theme.spacing(3),
    '& li + li': {
      paddingTop: theme.spacing(3),
    },
  },
}))

export type AttachmentSummaryProps = {
  caseToken: string
  attachmentToken: string
  classes?: Partial<ReturnType<typeof useStyles>>
  displayName: string
  onClose: () => void
  showDisplayNameInHeader?: boolean
  summary: CaseFile['summary'] | null
}

function AttachmentSummary({
  caseToken,
  attachmentToken,
  classes: classesProp,
  displayName,
  onClose,
  showDisplayNameInHeader = true,
  summary,
}: AttachmentSummaryProps) {
  const classes = useStyles()
  const mergedClasses = mergeOverrideStyles(classes, classesProp)

  useEffect(() => {
    if (summary?.status === AttachmentSummaryStatus.Failed) {
      onClose()
    }
  }, [summary?.status, onClose])

  const renderContent = () => {
    if (summary?.status === AttachmentSummaryStatus.Pending) {
      return (
        <div className={mergedClasses.loader}>
          <HbAnimatedLogo />
          <HbText bold size="lg" className={mergedClasses.ellipsisText}>
            Writing summary for {displayName}...
          </HbText>
          <br />
          <HbText bold color="blue" size="lg" className={mergedClasses.poweredByText}>
            Powered by Hummingbird AI <AutoAwesome />
          </HbText>
        </div>
      )
    }

    if (summary?.status === AttachmentSummaryStatus.Complete) {
      return (
        <div className={mergedClasses.content}>
          <FadeBar position="top" />
          <ul className={mergedClasses.statements}>
            {(summary?.statements || []).map((statement) => (
              <li key={statement}>
                <HbText>{statement}</HbText>
              </li>
            ))}
          </ul>
          <FadeBar position="bottom" />
          {summary && (
            <AttachmentSummaryFooter caseToken={caseToken} summary={summary} attachmentToken={attachmentToken} />
          )}
        </div>
      )
    }

    return null
  }

  return (
    <section className={mergedClasses.pane} data-testid={`attachmentSummary:${summary?.token || 'empty'}`}>
      <div className={mergedClasses.header}>
        <span className={mergedClasses.title}>
          <AutoAwesome />
          <span>
            <HbText bold className={mergedClasses.summaryText} size="lg">
              Summary
            </HbText>
            {showDisplayNameInHeader && (
              <HbText block size="md" color="secondary">
                {displayName}
              </HbText>
            )}
          </span>
        </span>
        <HbButton label="Close" iconOnly Icon={Close} onClick={onClose} />
      </div>
      {renderContent()}
    </section>
  )
}
export default AttachmentSummary

import { mapValues } from 'lodash'

import { setFlashError } from 'actions/errorActions'
import { setLoading } from 'actions/viewActions'
import {
  getDemoMode,
  getMetricsLoadingKeyByToken,
  getMetricsSelectorsByToken,
  getOrganizationToken,
} from 'helpers/stateHelpers'
import { getOrganizationTimezone } from 'helpers/time'

import { Thunk } from './store'

export const METRICS_LOADING_TOKEN = 'metrics-dashboard'

export const SET_METRICS_DATA = 'SET_METRICS_DATA'
export const SET_SELECTORS = 'SET_SELECTORS'

export function setMetricsData(data: any) {
  return { type: SET_METRICS_DATA, data }
}

export function setSelectors(token: string, selectors: { [key: string]: string }) {
  return { type: SET_SELECTORS, token, selectors }
}

export function fetchOpsMetrics(dataQueries: { token: string }[] = []): Thunk<Promise<any>> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, METRICS_LOADING_TOKEN))
    const organizationToken = getOrganizationToken(getState())
    const demoMode = getDemoMode(getState())
    const pathName = demoMode ? 'fakeApiOrganizationMetricPath' : 'apiOrganizationMetricPath'

    return api.debounce(`fetch-ops-metrics`, (resolve) =>
      api
        .get(pathName, {
          urlParams: { organizationToken, token: 'activity' },
          query: { dataQueries, timezone: getOrganizationTimezone(getState()) },
        })
        .then((json: any) => {
          if (json.success) {
            dispatch(setMetricsData(json.metrics.chartData))
            dispatch(setLoading(false, METRICS_LOADING_TOKEN))
            return resolve(json)
          }

          if (json.error) {
            dispatch(setFlashError(json.error.message))
            dispatch(setLoading(false, METRICS_LOADING_TOKEN))
          }
          return resolve(json)
        })
    )
  }
}

// Action for refetching the data associated to a single chart.
export function refetchOpsMetrics(token: string, newSelectors: { [key: string]: string }): Thunk<Promise<any>> {
  return (dispatch, getState, { api }) => {
    const key = getMetricsLoadingKeyByToken(token)

    dispatch(setLoading(true, key))
    dispatch(setSelectors(token, newSelectors))

    const organizationToken = getOrganizationToken(getState())
    const selectors = mapValues(getMetricsSelectorsByToken(getState(), token), 'value')
    const demoMode = getDemoMode(getState())
    const pathName = demoMode ? 'fakeApiOrganizationMetricPath' : 'apiOrganizationMetricPath'

    return api.debounce(key, (resolve) =>
      api
        .get(pathName, {
          urlParams: { organizationToken, token: 'activity' },
          query: {
            dataQueries: [{ token, selectors }],
            timezone: getOrganizationTimezone(getState()),
          },
        })
        .then((json) => {
          dispatch(setLoading(false, key))
          if (json.success) {
            dispatch(setMetricsData(json.metrics.chartData))
            return resolve(json)
          }

          if (json.error) {
            dispatch(setFlashError(json.error.message))
          }
          return resolve(json)
        })
    )
  }
}

import { ExpandLess, ExpandMore } from '@mui/icons-material'
import { IconButton } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import type { Theme } from 'types/hb'

const useButtonStyles = makeStyles((theme: Theme) => ({
  defaultStyle: { backgroundColor: 'inherit' },
  expandButtonStyle: {
    color: theme.palette.styleguide.textGreyLight,
  },
}))

export const ExpandButton = ({
  onClick,
  expandMore,
}: {
  onClick?: React.MouseEventHandler<HTMLElement> | undefined
  expandMore: boolean
}) => {
  const styles = useButtonStyles()
  return (
    <IconButton className={styles.expandButtonStyle} onClick={onClick} size="large">
      {expandMore ? <ExpandMore /> : <ExpandLess />}
    </IconButton>
  )
}

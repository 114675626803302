import { ReactNode, useRef, useState, useCallback, useEffect } from 'react'

import { Fab } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { useOnWindowResize } from 'helpers/UseOnWindowResize'
import { useEffectOnce } from 'hooks'
import { Icon } from 'icons'
import { IconName } from 'icons/types'
import { Theme } from 'types/hb'

import { TABLE_HEADER_HEIGHT } from './Table/Tables.constants'

const FUDGE_FACTOR = 10

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    overflowX: 'auto',
    overflowY: 'visible',
  },
  fab: {
    position: 'absolute',
    top: '50%',
    color: theme.palette.styleguide.mediumGray2,
    backgroundColor: theme.palette.styleguide.white,
    '&:hover': {
      backgroundColor: theme.palette.styleguide.white,
    },
    '&$left': {
      left: 0,
      transform: 'translateY(-50%) translateX(-50%)',
    },
    '&$right': {
      right: 0,
      transform: 'translateY(-50%) translateX(50%)',
    },
  },
  left: {},
  right: {},
  disabled: {},
}))

export interface Props {
  className?: string
  scrollAmount: number
  children: ReactNode
  snapOverlayToWindowScroll?: boolean
  hideArrows?: boolean
}

export default function HorizontalScrollContainer(props: Props) {
  const { children, scrollAmount, className, snapOverlayToWindowScroll, hideArrows = false } = props
  const classes = useStyles()

  const container = useRef<HTMLDivElement | null>(null)
  const arrowLeft = useRef<HTMLDivElement>()
  const arrowRight = useRef<HTMLDivElement>()
  const [scrollWidth, setScrollWidth] = useState(0)
  const [containerWidth, setContainerWidth] = useState(0)
  const [canScrollLeft, setCanScrollLeft] = useState(!hideArrows)
  const [canScrollRight, setCanScrollRight] = useState(!hideArrows)

  useEffect(() => {
    if (!snapOverlayToWindowScroll || hideArrows) {
      return () => {}
    }

    const cont = container.current

    const updateArrows = () => {
      // By default, place either in the center of the viewport,
      // or (if the parent is not tall enough to fill the viewport),
      // in the center of the parent (accounting for the table header height)
      let newTop = `min((100% - ${TABLE_HEADER_HEIGHT}px) / 2 + ${TABLE_HEADER_HEIGHT}px, 40vh)`

      // If the container is taller than the window, need to adjust
      // arrow positions when scrolling the window
      if (cont && cont.clientHeight > window.innerHeight) {
        newTop = `${window.scrollY + window.innerHeight / 2}px`
      }

      if (arrowLeft.current) arrowLeft.current.style.top = newTop
      if (arrowRight.current) arrowRight.current.style.top = newTop
    }

    updateArrows()

    window.addEventListener('scroll', updateArrows)
    return () => {
      window.removeEventListener('scroll', updateArrows)
    }
  }, [children, canScrollLeft, canScrollRight, hideArrows, snapOverlayToWindowScroll])

  const updateScrollState = useCallback(
    (scrollLeft, scrollW = scrollWidth, containerW = containerWidth) => {
      setCanScrollLeft(scrollLeft > 0)
      setCanScrollRight(scrollLeft + FUDGE_FACTOR < scrollW - containerW)
    },
    [containerWidth, scrollWidth]
  )

  const onResize = useCallback(() => {
    const scrollW = container.current?.scrollWidth
    const containerW = container.current?.getBoundingClientRect().width
    setScrollWidth(scrollW ?? 0)
    setContainerWidth(containerW ?? 0)
    return { scrollW, containerW }
  }, [])

  useOnWindowResize(onResize)
  useEffectOnce(() => {
    const { scrollW, containerW } = onResize()
    updateScrollState(0, scrollW, containerW)
  })

  const handleScroll = () => {
    updateScrollState(container.current?.scrollLeft)
  }

  const handleClickArrow = (change: -1 | 1) => {
    return () => {
      // eslint-disable-next-line no-unused-expressions
      container.current?.scrollBy({
        left: change * scrollAmount,
        behavior: 'smooth',
      })
    }
  }

  const renderArrow = (change: -1 | 1, enabled: boolean) => {
    if (!enabled) {
      return null
    }

    const direction = change > 0 ? 'right' : 'left'
    const iconName = `chevron_${direction}` as IconName

    return (
      <HbTooltip title={`Scroll ${direction}`}>
        <Fab
          className={classnames(classes.fab, classes[direction], {
            [classes.disabled]: !enabled,
          })}
          // Cast since MUI types for refs are out of date
          ref={(change > 0 ? arrowRight : arrowLeft) as any}
          disabled={!enabled}
          size="small"
          onClick={handleClickArrow(change)}
        >
          <Icon name={iconName} />
        </Fab>
      </HbTooltip>
    )
  }

  return (
    <div
      className={classnames(className, { [classes.container]: !hideArrows })}
      ref={container}
      onScroll={handleScroll}
    >
      {children}
      {renderArrow(-1, !hideArrows && canScrollLeft)}
      {renderArrow(1, !hideArrows && canScrollRight)}
    </div>
  )
}

import { useQuery } from '@apollo/client'

import { useSelector } from 'actions/store'

import { useSyncPagination } from 'dashboards/shared/hooks/useSyncPagination'
import { prependFilterWithCanonicalAutomationRuleFilter } from 'dashboards/shared/react/useDashboardImplicitFilters'
import { DashboardDataResult } from 'dashboards/shared/types/DashboardData'

import { NarrowedFetchPolicy } from 'hooks/ApolloHelpers'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { mapAutomationExecutionsEntries } from 'reducers/dashboards/mappers'
import { normalizeRequest } from 'utils/query/normalization'

import {
  SearchAutomationRuleExecutionsQuery,
  SearchAutomationRuleExecutionsQueryVariables,
} from '../gql/__generated__/searchAutomationRuleExecutions.queries.generated'

import { SEARCH_AUTOMATION_RULE_EXECUTIONS_QUERY } from '../gql/searchAutomationRuleExecutions.queries'

export const useAutomationRuleExecutionsDashboardData = (
  fetchPolicy: NarrowedFetchPolicy = 'cache-and-network',
  canonicalAutomationRuleToken?: string
): DashboardDataResult<ReturnType<typeof mapAutomationExecutionsEntries>> => {
  const dashboardsSelectors = useDashboardSelectors()
  const page = useSelector(dashboardsSelectors.page)

  const validFilters = useSelector(dashboardsSelectors.filters.applied.valid)
  const filtersWithImplicitFilters = prependFilterWithCanonicalAutomationRuleFilter(
    validFilters || {},
    canonicalAutomationRuleToken
  )

  const appliedFilters = normalizeRequest(filtersWithImplicitFilters || validFilters)

  const { data, previousData, loading, refetch, error } = useQuery<
    SearchAutomationRuleExecutionsQuery,
    SearchAutomationRuleExecutionsQueryVariables
  >(SEARCH_AUTOMATION_RULE_EXECUTIONS_QUERY, {
    variables: { page: page.number, pageSize: page.size, appliedFilters },
    fetchPolicy,
  })

  const searchAutomationRuleExecutionsEntries = (data ?? previousData)?.searchAutomationRuleExecutions

  useSyncPagination(searchAutomationRuleExecutionsEntries)

  return {
    loading,
    refetch,
    entries: mapAutomationExecutionsEntries(searchAutomationRuleExecutionsEntries),
    totalCount: searchAutomationRuleExecutionsEntries?.totalCount ?? 0,
    error,
  }
}

import { gql } from '@apollo/client'

export const SearchProfilesResultFragment = gql`
  fragment SearchProfilesResultFragment on SearchProfilesResult {
    entries {
      ... on LibraryObject {
        tags {
          token
          tagDefinition {
            label
            color
            token
          }
        }
        token
        type
        displayName
        createdAt
        updatedAt
        alertsCount
        attachmentsCount
        devicesCount
        subAccountsCount
        otherInfo {
          label
          value
        }
        relatedCases {
          totalCount
        }
      }
    }
    page
    pageSize
    pageCount
    totalCount
  }
`

import { useState } from 'react'

import { Form, Formik } from 'formik'

import { setFlashError } from 'actions/errorActions'
import { useDispatch, useSelector } from 'actions/store'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { DashboardExportActions } from 'dashboards/shared/components/DashboardExport/DashboardExportActions'
import { DashboardExportDialogContent } from 'dashboards/shared/components/DashboardExport/DashboardExportDialogContent'
import {
  EnqueueDashboardExportJobMutation,
  EnqueueDashboardExportJobMutationVariables,
} from 'dashboards/shared/components/DashboardExport/__generated__/exportDashboardMutation.generated'
import { EXPORT_DASHBOARD } from 'dashboards/shared/components/DashboardExport/exportDashboardMutation'
import { PollJobStatusWithTimeout } from 'dashboards/shared/components/DashboardExport/usePollExportJob'
import { useHbMutation } from 'hooks/ApolloHelpers'

import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { normalizeRequest } from 'utils/query/normalization'

const initialValues = { agreement: null }

export const DashboardExportDialog = ({
  open,
  closeModal,
  startPolling,
}: {
  open: boolean
  closeModal: () => void
  startPolling?: PollJobStatusWithTimeout
}) => {
  const dispatch = useDispatch()
  const selectors = useDashboardSelectors()
  const appliedFilters = useSelector(selectors.filters.applied.valid)
  const dashboardType = useDashboardContext()
  const [exportMutation] = useHbMutation<EnqueueDashboardExportJobMutation, EnqueueDashboardExportJobMutationVariables>(
    EXPORT_DASHBOARD
  )
  const [startedPolling, setStartedPolling] = useState(false)

  const enqueueExportJob = async () => {
    const mutationResult = await exportMutation({
      variables: { appliedFilters: normalizeRequest(appliedFilters), type: dashboardType },
    })

    const jobToken = mutationResult.data?.enqueueDashboardExportJob?.job.token

    if (!jobToken) {
      dispatch(setFlashError('Sorry, we had trouble with your export request. Please try again.'))
    } else if (startPolling) {
      await startPolling({ jobToken })
      setStartedPolling(true)
    }
    return null
  }

  if (!open) {
    return null
  }

  return (
    <Formik initialValues={initialValues} onSubmit={enqueueExportJob}>
      {({ values, submitForm, isSubmitting }) => (
        <Form>
          <HbDialog
            open={open}
            id="csv_export"
            title="Export to CSV"
            onClose={closeModal}
            content={<DashboardExportDialogContent isLoading={startedPolling} />}
            actions={
              <DashboardExportActions
                closeModal={closeModal}
                submitForm={submitForm}
                isSubmitting={isSubmitting}
                disableSubmit={!values.agreement}
                isLoading={startedPolling}
              />
            }
          />
        </Form>
      )}
    </Formik>
  )
}

import React, { ReactElement } from 'react'

import { MenuItem, Paper, TextField, Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import classnames from 'classnames'
import { FastField, FormikProps, FieldInputProps, FieldAttributes } from 'formik'
import Select from 'react-select'

import { ControlProps } from 'react-select/lib/components/Control'
import { MenuProps } from 'react-select/lib/components/Menu'

import { PlaceholderProps } from 'react-select/lib/components/Placeholder'
import { ValueContainerProps } from 'react-select/lib/components/containers'
import { IndicatorProps } from 'react-select/lib/components/indicators'
import { OptionProps } from 'react-select/lib/types'

import { MuiSelectIconStyle } from 'components/themeRedesign'
import { Theme } from 'types/hb'

import { NO_AUTOFILLS } from './FormConstants'
import { SelectIcon } from './SelectInput'
// Handling errors
// Proptypes enforce options are passed in of certain shape { value, label }

/*
 * Autocomplete component
 * ===========================
 * Essentially a `select` component that allows for text input in order
 * to search through list of options. Weirdly MUI doesn't have such a
 * component but does provide some guidance on implementing one
 * (https://material-ui.com/components/autocomplete/#react-select).
 */

const autocompleteStyles: Styles<Theme, object> = (theme: Theme) => ({
  /* Styles applied to root React-Select element */
  root: {},
  /* Styles applied to root FormField element */
  controlRoot: {},
  inputRoot: {},
  /* Styles applied to the form control input element */
  input: {
    display: 'flex',
    padding: '0',
  },
  /* Styles applied to the value container */
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
  },
  /* Styles applied to placeholder that appear when field is focused and empty */
  placeholder: {},
  /* Styles applied to dropdown icon that trigger dropdown */
  dropdownIcon: {
    cursor: 'pointer',
    ...MuiSelectIconStyle,
    width: theme.hbUnit(3),
    height: theme.hbUnit(3),
    right: theme.hbUnit(3),
    top: `calc(50% - ${theme.spacing(1.5)})`,
    '&[disabled]': {
      color: theme.palette.action.disabledIcon,
    },
  },
  /* Styles applied to dropdown icon that trigger dropdown */
  menu: {},
  /* Styles applied to menu option */
  option: {
    '&$focused': {
      backgroundColor: theme.palette.action.hover,
    },
    '&$selected': {
      backgroundColor: theme.palette.action.selected,
    },
  },
  /* Styles applied when menu option is selected */
  selected: {},
  /*
   * Styles applied when menu option is focused (hover or
   * cycled through using arrow keys).
   */
  focused: {},
  italicized: {
    fontStyle: 'italic',
  },
})

/*
 * Injected components
 *
 * React-Select allows customization via component injection
 * (https://react-select.com/components). We override default
 * components with our own leveraging MUI components in order for
 * consistent styling and functionality.
 */

/*
 * Override Control to use TextField's FormControl component
 */
function inputComponent({
  inputRef,
  ...props
}: {
  inputRef: React.Ref<HTMLDivElement>
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
  return <div ref={inputRef} {...props} />
}

function Control<T>(props: ControlProps<T>) {
  const {
    children,
    innerProps,
    innerRef,
    isDisabled,
    selectProps: { classes, TextFieldProps, InputProps = {}, label, value },
  } = props

  return (
    <TextField
      className={classes.controlRoot}
      fullWidth
      disabled={isDisabled}
      label={label}
      InputLabelProps={{
        /*
         * Necessary because the FormControl isn't always great about detecting
         * if there's an inputted value and the label should always shrink if value
         * is present.
         */
        shrink: !!value || undefined,
      }}
      {...TextFieldProps}
      InputProps={{
        inputComponent,
        ...TextFieldProps?.InputProps,
        ...InputProps,
        inputProps: {
          ...TextFieldProps?.InputProps.inputProps,
          className: classes.input,
          ref: innerRef,
          children,
          ...innerProps,
        },
        value,
      }}
    />
  )
}

/*
 * Override ValueContainer so that `valueContainer` class can be used for styling.
 */
function ValueContainer<T>({ selectProps: { classes }, children }: ValueContainerProps<T>) {
  return <div className={classes.valueContainer}>{children}</div>
}

/*
 * Override Placeholder with a component that can only appear when input is focused.
 * When not focused, we want the InputLabel or selected value to appear.
 */
function Placeholder<T>({
  isFocused,
  selectProps: { classes, placeholderWithoutFocus },
  innerProps,
  children,
}: PlaceholderProps<T> & any) {
  if (isFocused || placeholderWithoutFocus) {
    return (
      <Typography variant="body1" color="textSecondary" className={classes.placeholder} {...innerProps}>
        {children}
      </Typography>
    )
  }

  return null
}

/*
 * Override IndicatorSeparator to not appear.
 */
function IndicatorSeparator(): ReactElement | null {
  return null
}

/*
 * Override DropdownIndicator to use MUI ExpandMoreIcon
 */
function DropdownIndicator<T>({ innerProps, selectProps: { classes } }: IndicatorProps<T>) {
  return <SelectIcon className={classes.dropdownIcon} {...innerProps} />
}

/*
 * Override option so that we can use MUI MenuItem and style with `option`,
 * `selected`, and `focused` classes
 */
function Option({
  selectProps: { classes },
  isSelected,
  isFocused,
  innerRef,
  innerProps,
  children,
  data,
}: OptionProps & any) {
  const className = classnames(classes.option, {
    [classes.selected]: isSelected,
    [classes.focused]: isFocused,
    [classes.italicized]: data?.value === '',
  })

  return (
    <MenuItem ref={innerRef} selected={isSelected} className={className} {...innerProps}>
      {children}
    </MenuItem>
  )
}

/*
 * Override menu so that MUI styles are used and `menu` class can be used for styling
 */
function Menu<T>({ innerProps, selectProps: { classes }, children }: MenuProps<T>) {
  return (
    <Paper className={classes.menu} {...innerProps}>
      {children}
    </Paper>
  )
}

const defaultComponents = {
  Control,
  ValueContainer,
  Placeholder,
  IndicatorSeparator,
  DropdownIndicator,
  Option,
  Menu,
}

const defaultSelectProps = {
  openMenuOnClick: false,
  placeholder: 'Start typing to select option...',
}

type AutocompleteProps = any // TODO(jsu): should come from react-select but those types are quite bad. Revisit after lib upgrade.

export const AutocompleteBase = withStyles(autocompleteStyles)(
  ({ components, className, options, disableReset, ...props }: AutocompleteProps) => {
    // Add blank option for clearing input
    const getOptions = () => (disableReset ? options : [{ label: '(Reset field)', value: '' }].concat(options))

    return (
      <Select
        className={classnames(props.classes.root, className)}
        {...defaultSelectProps}
        components={{ ...defaultComponents, ...components }}
        {...props}
        isDisabled={props.disabled || props.isDisabled}
        options={getOptions()}
        onFocus={(e) => {
          // react-select doesn't provide us a way to override the autocomplete property,
          // so we're doing it manually whenever the input is focused
          /* eslint-disable-next-line no-param-reassign */
          ;(e.target as HTMLInputElement).autocomplete = NO_AUTOFILLS
        }}
      />
    )
  }
)

type FieldToAutocompleteInput = {
  form: FormikProps<any>
  field: FieldInputProps<any>
} & AutocompleteProps

type Option = { value: string }

/*
 * Maps Formik Field props to props that work with React-Select
 */
const fieldToAutocomplete = ({ field, form, options, ...props }: FieldToAutocompleteInput) => ({
  name: field.name,
  value: (options && options.find((option: Option) => option.value === field.value)) || '',
  onChange: (option: Option) => form.setFieldValue(field.name, option.value),
  onBlur: field.onBlur,
  options,
  ...props,
})

/**
 * @deprecated Please use components/HbComponents/Form/Inputs/Autocomplete/Autocomplete instead
 */
export const AutocompleteInput = (props: FieldToAutocompleteInput) => (
  <AutocompleteBase {...fieldToAutocomplete(props)} />
)

/**
 * @deprecated Please use components/HbComponents/Form/Inputs/Autocomplete/Autocomplete instead
 */
export const AutocompleteField = (props: FieldAttributes<unknown> & FieldToAutocompleteInput) => (
  <FastField {...props} component={AutocompleteInput} />
)

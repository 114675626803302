import { Store } from 'redux'

import { State } from 'actions/store'
import { getCurrentAccount, getCurrentOrganization } from 'helpers/stateHelpers'

import { UsageEventProperties } from './UsageEvents'

export const BATCH_SIZE = 10
export const DELAY_FAST = 1000
export const DELAY_SLOW = 30000
export const MAX_FAILURES = 5

interface QueuedEvent {
  eventName: string
  timestamp: string
  properties: UsageEventProperties
}

interface EventContext {
  customerId?: string
  userEmail?: string
  userOrg?: string
}

export class RudderstackSender {
  private endpoint: string
  private authorization: string

  private failures = 0
  private queue: QueuedEvent[] = []
  private scheduledCallback = -1

  private eventContext: EventContext = {}

  public initialize(store: Store<State>) {
    const state = store.getState()
    const { rudderstackUrl, rudderstackWriteKey } = state.application

    this.endpoint = `${rudderstackUrl}/v1/batch`
    // Http basic auth with blank password and write key as the username
    this.authorization = `Basic ${btoa(`${rudderstackWriteKey}:`)}`

    const customerId = getCurrentOrganization(state)?.customerId
    const userEmail = getCurrentAccount(state)?.email
    const userOrg = getCurrentOrganization(state)?.token
    this.eventContext = { customerId, userEmail, userOrg }
  }

  public trackEvent(eventName: string, timestamp: Date, properties: UsageEventProperties = {}) {
    this.queue.push({
      eventName,
      timestamp: timestamp.toISOString(),
      properties,
    })
    this.flushEvents()
  }

  private flush() {
    const events = this.queue
    this.queue = []

    const data = {
      batch: [
        ...events.map((event) => ({
          type: 'track',
          anonymousId: this.eventContext.userEmail,
          event: event.eventName,
          properties: event.properties,
          context: this.eventContext,
          timestamp: event.timestamp,
        })),
      ],
    }

    const retry = () => {
      this.failures += 1
      // Stop retrying if failed too many times
      if (this.failures < MAX_FAILURES) {
        this.queue = this.queue.concat(events)
        this.flushEvents()
      }
    }

    fetch(this.endpoint, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: this.authorization,
      },
      body: JSON.stringify(data),
    }).then((response) => {
      if (!response.ok) {
        retry()
      } else {
        this.failures = 0
      }
    }, retry)
  }

  private scheduleFlush(slow = false) {
    // If there's already a queued flush, we don't need to override it for slow flushes
    if (slow && this.scheduledCallback !== -1) {
      return
    }

    clearTimeout(this.scheduledCallback)
    this.scheduledCallback = setTimeout(() => this.flush(), slow ? DELAY_SLOW : DELAY_FAST) as unknown as number
  }

  public flushEvents(force = false) {
    if (this.queue.length === 0) {
      return
    }

    // If forced, flush instantly
    if (force) {
      this.flush()
      return
    }

    if (this.queue.length < BATCH_SIZE) {
      // Schedule a slow flush since we have events but not a full batch yet
      this.scheduleFlush(true)
      return
    }

    // Schedule a fast flush
    this.scheduleFlush()
  }
}

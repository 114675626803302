import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { OperationVariables, QueryHookOptions, TypedDocumentNode, useQuery } from '@apollo/client'

import { DocumentNode } from 'graphql'
import { VariableSizeList } from 'react-window'

import { useResizeObserver } from 'components/library/Resize/hooks'

import { PaginatedQuery } from 'hooks/ApolloHelpers'

import { Dimension, GetItemSize, IsItemLoaded, SetItemSize } from './types'

/**
 * Track an `isResizing` prop based on whether `AutoSizer` is updating dimensions.
 */
export const useIsAutoSizerResizing = <T extends (...args: unknown[]) => unknown>(_onResize?: T) => {
  const [isResizing, setIsResizing] = useState<boolean>(false)
  const resizingTimeoutRef = useRef<ReturnType<typeof setTimeout>>()
  const clearResizingTimeoutRef = useCallback(() => {
    if (resizingTimeoutRef.current) clearTimeout(resizingTimeoutRef.current)
    resizingTimeoutRef.current = undefined
  }, [])
  const handleResize = useCallback(
    (...args: Parameters<T>) => {
      _onResize?.(args[0])
      clearResizingTimeoutRef()
      setIsResizing(true)
      resizingTimeoutRef.current = setTimeout(() => {
        setIsResizing(false)
        clearResizingTimeoutRef()
      }, 200)
    },
    [_onResize, clearResizingTimeoutRef]
  )

  return { isResizing, handleResize }
}

const clientDimensionAttrs = {
  width: 'clientWidth',
  height: 'clientHeight',
} as const

/**
 * Measure an individual list item's size
 */
export const useListItemContentSize = (
  index: number,
  setItemSize: SetItemSize,
  getItemSize: GetItemSize,
  dimension: Dimension = 'height'
) => {
  const listItemRef = useRef<HTMLDivElement>(null)

  // Observes resizing in the contents of a list item itself,
  // allowing more selective updates for stored item sizes,
  // as opposed to doing it from the top-down via re-renders.
  // So if a list item resizes due to reflow (content wraps while resizing),
  // only that item's size is updated from here.
  const handleItemSizeChange = useCallback(
    ([{ target }]: ResizeObserverEntry[]) => {
      const currSize = getItemSize(index)
      const attr = clientDimensionAttrs[dimension]
      const changedSize = target[attr]
      if (currSize === changedSize) return
      setItemSize(index, changedSize)
    },
    [dimension, getItemSize, setItemSize, index]
  )
  const { getRef } = useResizeObserver(handleItemSizeChange)

  useEffect(() => {
    getRef(listItemRef.current)
  }, [getRef])

  useEffect(() => {
    if (!listItemRef.current) return
    const { [dimension]: size } = listItemRef.current.getBoundingClientRect()
    setItemSize(index, size)
  }, [dimension, index, setItemSize])

  return listItemRef
}

/**
 * Keeps track of variable item sizes in a virtual list.
 */
export const useVariableListItemSizes = <ItemData>(fallbackSize: number) => {
  // https://github.com/bvaughn/react-window/issues/417#issuecomment-583867845
  // The VariableSizeList's `ref` prop exposes the actual component instance,
  // which then exposes a method called `resetAfterIndex`:
  // https://react-window.vercel.app/#/api/VariableSizeList#methods,
  // which allows clearing any prior cached offsets/measurements after
  // a mounted list item's size is measured.
  // https://github.com/bvaughn/react-window/issues/199#issuecomment-479957451
  const listRef = useRef<VariableSizeList<ItemData> | null>(null)
  const sizeMap = useRef<number[]>([])
  const setItemSize = useCallback((index, size) => {
    sizeMap.current[index] = size
    listRef.current?.resetAfterIndex(index)
  }, [])

  // once list item sizes are measured and cached, this function can access a particular item's size as needed
  const getItemSize = useCallback((index: number) => sizeMap.current[index] || fallbackSize, [fallbackSize])

  return { getItemSize, listRef, setItemSize }
}

export const useInfiniteListData = <TNode, TData, TVariables = OperationVariables>({
  options,
  query,
  selector,
  variables,
}: {
  query: DocumentNode | TypedDocumentNode<TData, TVariables>
  variables: TVariables
  selector: (d?: TData) => PaginatedQuery<TNode> | null | undefined
  options?: Exclude<QueryHookOptions<TData, TVariables>, 'variables'>
}) => {
  const [loadingMore, setLoadingMore] = useState(false)

  const { data, error, loading, fetchMore } = useQuery<TData, TVariables>(query, {
    variables,
    fetchPolicy: 'network-only',
    ...options,
  })

  const connectionData = selector(data)
  const totalCount = connectionData?.totalCount

  const edges = connectionData?.edges
  const numItems = edges?.length || 0

  const pageInfo = connectionData?.pageInfo
  const endCursor = pageInfo?.endCursor ?? null
  const hasNextPage = pageInfo?.hasNextPage

  const itemCount = totalCount || hasNextPage ? numItems + 1 : numItems

  const loadMoreItems = useCallback(async () => {
    setLoadingMore(true)
    const fetchMoreVariables: TVariables = {
      ...variables,
      after: endCursor,
    }
    await fetchMore({ variables: fetchMoreVariables })
    setLoadingMore(false)
  }, [endCursor, fetchMore, variables])

  const filteredData = useMemo(() => {
    const filterEmpty = <Node>(node: Node | null | undefined): node is Node => !!node
    return edges?.map((edge) => edge?.node).filter(filterEmpty)
  }, [edges])

  const isItemLoaded: IsItemLoaded = useCallback((index) => !hasNextPage || index < numItems, [hasNextPage, numItems])

  return {
    data,
    error,
    filteredData,
    hasNextPage,
    isItemLoaded,
    itemCount,
    loading,
    loadingMore,
    loadMoreItems,
    numItems,
  }
}

import React, { ReactElement } from 'react'

import { connect, FormikContextType } from 'formik'
import { debounce, isEqual } from 'lodash'

import { SavingContext } from './SavingTracker'

interface OuterProps<T = unknown> {
  onSave?: (values: T, formik?: FormikContextType<T>) => Promise<unknown>
  disableSetSubmitting?: boolean
  interval?: number
  skipDebounceForFields?: string[]
  /** Custom comparison of previous and current values. Return true to avoid triggering autosave. This defaults to lodash isEqual if not provided. */
  equalityComparison?: (prev: T, curr: T) => boolean
}

type Props = OuterProps & {
  formik: FormikContextType<unknown>
}

class AutoSaveInternal extends React.Component<Props> {
  static contextType = SavingContext
  context!: React.ContextType<typeof SavingContext>

  static defaultProps = {
    interval: 1000,
    disableSetSubmitting: false,
    skipDebounceForFields: [] as string[],
  }

  saveQueued = false

  _save = async () => {
    const { formik, onSave, disableSetSubmitting } = this.props
    if (!formik.isValid) {
      return
    }

    this.saveQueued = false

    this.context.markComponentSaving(true)

    if (onSave) {
      if (!disableSetSubmitting) {
        formik.setSubmitting(true)
      }
      try {
        await onSave(formik.values, formik)
      } finally {
        this.context.markComponentSaving(false)
        this.context.markIntentToSave(false)
        if (!disableSetSubmitting) {
          formik.setSubmitting(false)
        }
      }
    } else {
      try {
        await formik.submitForm()
      } finally {
        this.context.markComponentSaving(false)
        this.context.markIntentToSave(false)
      }
    }
  }

  debouncedSave = debounce(this._save, this.props.interval)

  queueSave = async (skipDebounce = false) => {
    if (!this.saveQueued) {
      this.context.markIntentToSave(true)
    }

    this.saveQueued = true
    if (skipDebounce) {
      this.debouncedSave.cancel()
      this._save()
    } else {
      this.debouncedSave()
    }
  }

  componentDidUpdate({ formik: prevFormik, skipDebounceForFields }: Props) {
    const { formik: curFormik, equalityComparison = isEqual } = this.props
    if (curFormik.dirty && !equalityComparison(prevFormik.values, curFormik.values)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore: `values` is `any`
      this.queueSave(skipDebounceForFields.some((field) => !isEqual(prevFormik.values[field], curFormik.values[field])))
    }
  }

  componentWillUnmount() {
    const { formik } = this.props

    if (formik.dirty) {
      this.queueSave(true)
    }
  }

  render(): ReactElement | null {
    return null
  }
}

export const AutoSave: <T = unknown>(props: OuterProps<T>) => ReactElement | null = connect(AutoSaveInternal) as <T>(
  props: OuterProps<T>
) => ReactElement | null

import { useContext } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { Comment } from '@mui/icons-material'

import { HbButton } from 'components/HbComponents/HbButton'

import { Placement } from 'components/HbComponents/HbTooltip'

import { CommentContext } from './CommentContext'

export type Props = {
  className?: string
}

export default function CommentButtonPlugin({ className }: Props) {
  const [editor] = useLexicalComposerContext()
  const commentContext = useContext(CommentContext)

  return (
    <HbButton
      label="View All Comments"
      tooltip
      tooltipPlacement={Placement.Right}
      className={className}
      size="small"
      onClick={(e) => {
        e.stopPropagation()
        commentContext?.openCommentPane(editor)
      }}
      data-testid="add-comment"
      Icon={Comment}
      iconOnly
    />
  )
}

import { gql } from '@apollo/client'

export const DatasourceTriggerDataFragment = gql`
  fragment DatasourceTriggerData on Query {
    datasourceWarehouses @include(if: $datasourcesEnabled) {
      token
      datasources {
        token
        name
        columns {
          name
          type
        }
      }
    }
  }
`

export const TriggerFilterEditorDataSources = gql`
  ${DatasourceTriggerDataFragment}
  query TriggerFilterEditorDataSources($datasourcesEnabled: Boolean!) {
    ...DatasourceTriggerData
  }
`

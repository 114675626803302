import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const AddCommentIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M9 12h2V9h3V7h-3V4H9v3H6v2h3v3" />
    <path d="M19 0H1a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h2v3a1 1 0 0 0 1.58.81L9.92 16H19a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1zm-1 14H9l-4 3.06V14H2V2h16z" />
  </SvgIcon>
)

export default AddCommentIcon

import { useMemo } from 'react'

import { useField, useFormikContext } from 'formik'
import { isEmpty, isEqual, isNil } from 'lodash'

import { IsErroneousChecker } from './InputTypes'

interface ValidationErrorState {
  validation: {
    errors?: Record<string, string[]>
  }
}

type FormValueState = Record<string, unknown>

type FormValuesWithValidations = FormValueState & Partial<ValidationErrorState>

interface UseIsErroneousParams {
  name: string
  autosave?: boolean
  errors?: string[]
  isErroneousChecker?: IsErroneousChecker
}

const isEmptyValue = (val: unknown) => isNil(val) || val === '' || Boolean(typeof val === 'object' && isEmpty(val))

const isPristine = (initial: unknown, current: unknown) => {
  // if initial and current are equal OR if initial and current are both empty values (null, undefined, `''`)
  return isEqual(initial, current) || Boolean(isEmptyValue(initial) && isEmptyValue(current))
}

export const useIsErroneous = ({ name, autosave, errors, isErroneousChecker }: UseIsErroneousParams) => {
  const [field, meta] = useField(name)
  const { submitCount, initialValues } = useFormikContext<FormValuesWithValidations>()
  const pristine = isPristine(meta.initialValue, field.value)
  const { touched, error: clientError } = meta
  // api errors can be passed directly via prop or accessed via formik initialValues
  const apiErrors = errors || initialValues?.validation?.errors?.[name]

  return useMemo(() => {
    // If a custom function is provided for determining if `isErroneous` use it
    if (typeof isErroneousChecker === 'function') {
      return isErroneousChecker({ autosave, errors: apiErrors, meta, field, pristine, submitCount })
    }

    if (autosave) {
      return { isErroneous: Boolean(touched && pristine && !!apiErrors?.length), clientError, apiErrors }
    }

    // If the form is not using autosave,
    // then we want to show client-side validation errors
    //  as well as server-side validation errors inline if any are thrown
    // upon form submission.
    let validClientError = clientError
    if (clientError && typeof clientError === 'object') {
      validClientError = clientError[0] // eslint-disable-line prefer-destructuring
    }
    const hasClientError = Boolean(validClientError && (submitCount > 0 || touched))
    const hasApiSubmittedError = !!apiErrors?.length

    return { isErroneous: hasClientError || hasApiSubmittedError, apiErrors, clientError }
  }, [autosave, apiErrors, pristine, touched, clientError, submitCount, meta, field, isErroneousChecker])
}

import { DashboardContextProvider } from 'dashboards/shared/components/DashboardContextProvider'

import { ReportDashboardHeader } from 'dashboards/shared/components/Reporting/ReportDashboardHeader'
import { useUpdateDashboardColumns } from 'dashboards/shared/hooks/useUpdateDashboardColumns'
import { DashboardTypeEnum } from 'types/api'

import { AutomationRuleExecutionsTable } from './AutomationRuleExecutionsTable'

function AutomationRuleExecutionsInternal() {
  const { loadingColumns, initialLoadComplete, handleColumnOrderChange, filteredColumns } = useUpdateDashboardColumns()

  return (
    <AutomationRuleExecutionsTable
      header={<ReportDashboardHeader />}
      loadingColumns={loadingColumns}
      initialLoadComplete={initialLoadComplete}
      columns={filteredColumns}
      batchColumnEnabled={false}
      handleColumnOrderChange={handleColumnOrderChange}
    />
  )
}

export function AutomationRuleExecutions() {
  return (
    <DashboardContextProvider value={DashboardTypeEnum.AutomationExecutions}>
      <AutomationRuleExecutionsInternal />
    </DashboardContextProvider>
  )
}

import { getIn } from 'formik'

import { useSelector } from 'actions/store'

import { useHbFormikContext } from 'components/HbComponents/Form/useHbFormikContext'
import { getCountries } from 'helpers/stateHelpers'

import { UseCountryAndStateInputs } from './types'

export const useCountryAndStateInputs = ({ autosave, countryName = '', USOnly }: UseCountryAndStateInputs) => {
  const formik = useHbFormikContext({ autosave })
  const countries = useSelector(getCountries)

  const currentCountry = USOnly ? 'US' : getIn(formik.values, countryName)
  const subdivisions = countries[currentCountry]?.subdivisions || []

  return { formik, countries, currentCountry, subdivisions }
}

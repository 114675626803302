import { ChangeEvent } from 'react'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import HbSwitch, { HbSwitchProps } from './HbSwitch'

/**
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
function HbRHFSwitch<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ control, name, rules, onChange, ...rest }: UseControllerProps<TFieldValues, TName> & HbSwitchProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <HbSwitch
            {...rest}
            {...field}
            onChange={
              onChange
                ? (value: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(value)
                    onChange(value, value.target.checked)
                  }
                : field.onChange
            }
            checked={field.value}
            ref={null}
            inputRef={field.ref}
            // errors={fieldState.error?.message} // TODO errors
          />
        )
      }}
    />
  )
}

export default HbRHFSwitch

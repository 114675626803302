import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const PaymentCardIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g transform="translate(1.000000, 5.000000)">
      <path d="M21,0 C21.5522847,3.38176876e-17 22,0.44771525 22,1 L22,14 C22,14.5522847 21.5522847,15 21,15 L1,15 C0.44771525,15 6.76353751e-17,14.5522847 0,14 L0,1 C6.76353751e-17,0.44771525 0.44771525,-3.38176876e-17 1,0 L21,0 Z M20,2 L2,2 L2,3 L20,3 L20,2 Z M2,13 L20,13 L20,6 L2,6 L2,13 Z M4,9 L11,9 L11,11 L4,11 L4,9 Z" />
    </g>
  </SvgIcon>
)

export default PaymentCardIcon

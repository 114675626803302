import { useParams } from 'react-router-dom'

import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { CombinatorEnum, DashboardTypeEnum, OperatorEnum } from 'types/api'
import { SearchRequestWithId } from 'utils/query/api.types'

import makeDashboardConfig from './makeDashboardConfig'

export type SearchRequestWithIdOrNull = SearchRequestWithId | null
type FilterConfig = {
  filterCreator?: (routeParams: ReturnType<typeof useParams>) => SearchRequestWithIdOrNull
  nonFilterableColumns?: string[]
}

const ARBITRARY_PARENT_FILTER_TOKEN = 'ARBITRARY_PARENT_FILTER_TOKEN'
const ARBITRARY_FILTER_TOKEN = 'ARBITRARY_FILTER_TOKEN'
const INVESTIGATION_FIELD = 'investigationToken'
const CANONICAL_RULE_FIELD = 'canonicalRule.token'

export const prependFilterWithInvestigationFilter = (
  parentFilter: SearchRequestWithIdOrNull,
  caseToken?: string
): SearchRequestWithIdOrNull => {
  if (!caseToken) {
    return parentFilter
  }
  const { filter, sorts } = parentFilter || {}
  const prependedFilter: SearchRequestWithId = {
    filter: {
      id: ARBITRARY_PARENT_FILTER_TOKEN,
      _type: 'SearchGroupWithId',
      children: [
        {
          id: ARBITRARY_FILTER_TOKEN,
          _type: 'SearchFilterWithId',
          field: INVESTIGATION_FIELD,
          predicate: { values: [caseToken], operator: OperatorEnum.Is },
        },
      ],
      combinator: CombinatorEnum.And,
    },
    sorts: sorts || [],
  }
  if (filter) {
    prependedFilter.filter.children.push(filter)
  }
  return prependedFilter
}

export const prependFilterWithCanonicalAutomationRuleFilter = (
  parentFilter: SearchRequestWithIdOrNull,
  canonicalRuleToken?: string
): SearchRequestWithIdOrNull => {
  if (!canonicalRuleToken) {
    return parentFilter
  }
  const { filter, sorts } = parentFilter || {}
  const prependedFilter: SearchRequestWithId = {
    filter: {
      id: ARBITRARY_PARENT_FILTER_TOKEN,
      _type: 'SearchGroupWithId',
      children: [
        {
          id: ARBITRARY_FILTER_TOKEN,
          _type: 'SearchFilterWithId',
          field: CANONICAL_RULE_FIELD,
          predicate: { values: [canonicalRuleToken], operator: OperatorEnum.Is },
        },
      ],
      combinator: CombinatorEnum.And,
    },
    sorts: sorts || [],
  }
  if (filter) {
    prependedFilter.filter.children.push(filter)
  }
  return prependedFilter
}

export const implicitFilters = makeDashboardConfig<FilterConfig>(
  {},
  {
    [DashboardTypeEnum.Transactions]: {
      filterCreator: (routeParams) => {
        return prependFilterWithInvestigationFilter(null, routeParams.caseToken)
      },
    },
    [DashboardTypeEnum.AutomationExecutions]: {
      filterCreator: (routeParams) => {
        return prependFilterWithCanonicalAutomationRuleFilter(null, routeParams.token)
      },
      nonFilterableColumns: ['ruleName'],
    },
    [DashboardTypeEnum.CaseFiles]: {
      filterCreator: (routeParams) => {
        return prependFilterWithInvestigationFilter(null, routeParams.caseToken)
      },
    },
  }
)

export const useDashboardImplicitFilters = (): {
  implicitFilter: SearchRequestWithIdOrNull
  nonFilterableColumns?: string[] | null
} => {
  const dashboardContextValue = useDashboardContext()

  const params = useParams()
  const { filterCreator, nonFilterableColumns } = implicitFilters[dashboardContextValue]

  const implicitFilter = filterCreator?.(params) ?? null
  const hasImplicitFilter = !!implicitFilter

  return { implicitFilter, nonFilterableColumns: hasImplicitFilter ? nonFilterableColumns : null }
}

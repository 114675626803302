import { useCallback } from 'react'

import { gql } from '@apollo/client'

import { enqueueSnackbar } from 'notistack'

import { PreviewModeActivePanel } from 'components/pages/automations/ViewAutomation/PreviewModePanel/PreviewModeActivePanel'
import { useFeatureFlag } from 'hooks'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { AutomationRuleExecutionMode, FeatureFlag } from 'types/api'

import {
  UseAutomationRuleToggleAutomationRuleFragment as AutomationRule,
  DisableAutomationRuleMutation,
  DisableAutomationRuleMutationVariables,
  EnableAutomationRuleMutation,
  EnableAutomationRuleMutationVariables,
} from './__generated__/useAutomationRuleToggle.generated'

export const fragment = gql`
  fragment UseAutomationRuleToggleAutomationRule on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      token
      disabled
      enabled
      enabledAt
      actionType
      executionMode
    }
    ... on TriggerAutomationRule {
      primaryRuleTrigger {
        domainType
        datasource @include(if: $datasourcesEnabled) {
          token
        }
      }
    }
    ...PreviewModePanelAutomationRule
  }
  ${PreviewModeActivePanel.fragments.automationRule}
`

const DISABLE_RULE_MUTATION = gql`
  mutation DisableAutomationRule($input: DisableAutomationRuleInput!, $datasourcesEnabled: Boolean!) {
    disableAutomationRule(input: $input) {
      automationRule {
        ...UseAutomationRuleToggleAutomationRule
      }
    }
  }
  ${fragment}
`

const ENABLE_RULE_MUTATION = gql`
  mutation EnableAutomationRule($input: EnableAutomationRuleInput!, $datasourcesEnabled: Boolean!) {
    enableAutomationRule(input: $input) {
      automationRule {
        ...UseAutomationRuleToggleAutomationRule
      }
    }
  }
  ${fragment}
`

export function useAutomationRuleToggle(automationRule: AutomationRule) {
  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)
  const [disableRuleMutation, { loading: disableLoading }] = useHbMutation<
    DisableAutomationRuleMutation,
    DisableAutomationRuleMutationVariables
  >(DISABLE_RULE_MUTATION, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation successfully disabled', {
        variant: 'success',
        preventDuplicate: false,
        autoHideDuration: 1000,
      })
    },
    variables: {
      input: {
        token: automationRule.token,
      },
      datasourcesEnabled,
    },
    logEvent: {
      name: 'automations:disableRule:clicked',
      data:
        automationRule.__typename === 'TriggerAutomationRule' &&
        automationRule.primaryRuleTrigger?.domainType &&
        automationRule.actionType
          ? {
              domainType: automationRule.primaryRuleTrigger.domainType,
              actionType: automationRule.actionType,
            }
          : undefined,
    },
  })

  const [enableRuleMutation, { loading: enableLoading }] = useHbMutation<
    EnableAutomationRuleMutation,
    EnableAutomationRuleMutationVariables
  >(ENABLE_RULE_MUTATION, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation succesfully enabled', {
        variant: 'success',
        preventDuplicate: false,
        autoHideDuration: 1000,
      })
    },
    variables: {
      input: {
        token: automationRule.token,
        executionMode: AutomationRuleExecutionMode.Automatic,
      },
      datasourcesEnabled,
    },
    logEvent: {
      name: 'automations:enableRule:clicked',
      data:
        automationRule.__typename === 'TriggerAutomationRule' &&
        automationRule.primaryRuleTrigger?.domainType &&
        automationRule.actionType
          ? {
              domainType: automationRule.primaryRuleTrigger.domainType,
              actionType: automationRule.actionType,
            }
          : undefined,
    },
  })

  const toggleRule = useCallback(() => {
    if (automationRule.disabled) {
      return enableRuleMutation()
    }
    return disableRuleMutation()
  }, [enableRuleMutation, disableRuleMutation, automationRule.disabled])

  return {
    disableRule: disableRuleMutation,
    enableRule: enableRuleMutation,
    toggleRule,
    loading: disableLoading || enableLoading,
  }
}

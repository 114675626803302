import React, { Suspense } from 'react'

import Loader from 'components/library/Loader'

const YamlKitchenInternal = React.lazy(() => import('./YamlKitchenInternal'))

export function YamlKitchen() {
  return (
    <Suspense fallback={<Loader />}>
      <YamlKitchenInternal />
    </Suspense>
  )
}

import { ArrowRightAlt } from '@mui/icons-material'

import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import AutomationsIcon from 'icons/Automations'
import { Zap } from 'icons/Zap'

import { Card } from './RecipeCard.styles'

const Root = styled(Card)(() => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}))

const IconsWrapper = styled('span')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.styleguide.textGreyLight,
  gap: theme.spacing(2),
}))

const StyledArrow = styled(ArrowRightAlt)(({ theme }) => ({
  color: theme.palette.styleguide.borderDark,
}))

export const CustomAutomationCard = ({ onClick }: { onClick: () => void }) => (
  <Root onClick={onClick}>
    <IconsWrapper>
      <Zap /> <StyledArrow /> <AutomationsIcon />
    </IconsWrapper>
    <HbText color="disabled" bold block>
      Create custom automation
    </HbText>
  </Root>
)

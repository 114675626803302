import { useMemo } from 'react'

import { gql, useQuery } from '@apollo/client'

import { useHasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum } from 'types/api'

import { isTruthy } from 'utils'

import { ListQueuesQuery } from './__generated__/useQueues.generated'
import { queueDetailsFragment } from './fragments'

const LIST_QUEUES_QUERY = gql`
  query ListQueues {
    queues {
      nodes {
        ...QueueDetails
      }
    }
  }
  ${queueDetailsFragment}
`

const noQueuesResult = {
  queues: [],
  error: undefined,
  loading: false,
}

export const useQueues = () => {
  const { data, error, loading } = useQuery<ListQueuesQuery>(LIST_QUEUES_QUERY)

  return {
    queues: useMemo(() => data?.queues?.nodes?.filter(isTruthy) ?? [], [data]),
    error,
    loading,
  }
}

export const useAssignableQueues = () => {
  const result = useQueues()

  if (useHasPermission(BadgePermissionsEnum.UpdateReviewQueue)) {
    return result
  }

  return noQueuesResult
}

import * as Z from 'zod'

import { DatatableTabType } from 'actions/entityActions.helpers'
import {
  CombinatorEnum,
  GeneratedDocumentType,
  LibraryTypeEnum,
  OperatorEnum,
  SortEnum,
  ScreeningSearchProvider,
} from 'types/api'
import { NormalizedSearchRequest } from 'utils/query/api.types'

const OverviewTab = Z.object({ type: Z.literal('overview') })
const FilesTab = Z.object({ type: Z.literal('files') })
const AlertsTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('alerts') })
const TransactionsTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('transactions'),
  viewing: Z.enum(['transactions', 'overview']).optional(),
  criteria: Z.array(
    Z.object({
      field: Z.union([
        Z.custom<`otherInfo.${string}`>((s) => typeof s === 'string' && s.startsWith('otherInfo.')),
        Z.custom<`events.${string}`>((s) => typeof s === 'string' && s.startsWith('events.')),
        Z.literal('alertRules'),
        Z.literal('amount'),
        Z.literal('amountReceived'),
        Z.literal('amountSent'),
        Z.literal('amountLocal'),
        Z.literal('date'),
        Z.literal('description'),
        Z.literal('direction'),
        Z.literal('externalId'),
        Z.literal('flagged'),
        Z.literal('instrumentType'),
        Z.literal('isoMessageType'),
        Z.literal('mcc'),
        Z.literal('notes'),
        Z.literal('recipient'),
        Z.literal('responseCode'),
        Z.literal('purchaseDate'),
        Z.literal('sourceSummary'),
        Z.literal('status'),
        Z.literal('reviewInternalControlNumbers'),
        Z.literal('alertExternalIds'),
        Z.literal('currencyIssuingCountryCode'),
        Z.literal('fincenCTRTransactionType'),
        Z.literal('fincenCTRTransactionTypeOther'),
      ]),
      display: Z.string(),
      direction: Z.union([Z.literal(SortEnum.Asc), Z.literal(SortEnum.Desc)]).optional(),
      values: Z.array(Z.string()).optional(),
    })
  ).optional(),
})
const LocationsTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('locations') })
const ConnectionsTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('connections') })
const OtherInfoTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('otherInfo') })
const InformationRequestTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('informationRequest'),
  requestToken: Z.string(),
})
const InboundRequestTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('inboundRequest'),
  inboundToken: Z.string(),
})
const InformationRequestsTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('informationRequests') })
const NewInformationRequestTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('newInformationRequest'),
})

const ReviewTab = Z.object({
  action: Z.string().optional(),
  pinned: Z.boolean().optional(),
  reviewToken: Z.string(),
  task: Z.string().optional(),
  type: Z.literal('review'),
})
const ReviewsTab = Z.object({ pinned: Z.boolean().optional(), type: Z.literal('reviews') })

const DatatableTab = Z.custom<DatatableTabType>((val) => /^datatables-.+$/g.test(val as string))

const LibraryTab = Z.object({
  linkToken: Z.string().optional(),
  libraryAttachmentToken: Z.string().nullable().optional(),
  entityToken: Z.string(),
  entityType: Z.union([
    Z.literal(LibraryTypeEnum.BankAccounts),
    Z.literal(LibraryTypeEnum.Businesses),
    Z.literal(LibraryTypeEnum.CryptoAddresses),
    Z.literal(LibraryTypeEnum.Devices),
    Z.literal(LibraryTypeEnum.FinancialInstitutions),
    Z.literal(LibraryTypeEnum.PaymentCards),
    Z.literal(LibraryTypeEnum.People),
    Z.literal(LibraryTypeEnum.Products),
  ]),
  pinned: Z.boolean().optional(),
  tab: Z.union([
    Z.literal('information'),
    Z.literal('relatedcases'),
    Z.literal('accounts'),
    Z.literal('alert_snooze_rules'),
    Z.literal('alerts'),
    Z.literal('related_information_requests'),
    Z.literal('related_profiles'),
    Z.literal('attachments'),
    DatatableTab,
  ]),
  type: Z.literal('library'),
})

const LibraryCreateTab = Z.object({
  currentStep: Z.union([Z.literal('attach-to-case'), Z.literal('create-entity')]).optional(),
  entityType: Z.union([
    Z.literal('individual'),
    Z.literal('bank_account'),
    Z.literal('business'),
    Z.literal('crypto_address'),
    Z.literal('device'),
    Z.literal('institution'),
    Z.literal('product'),
    Z.literal('payment_card'),
  ]),
  libraryToken: Z.string().optional(),
  pinned: Z.boolean().optional(),
  type: Z.literal('libraryCreate'),
})

const ThomsonReutersClearTab = Z.object({ type: Z.literal('thomsonReutersClear') })
const MiddeskTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('middesk'),
  businessEntityToken: Z.string(),
})
const SanctionsScreeningTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('sanctionsScreening'),
  entityToken: Z.string(),
  label: Z.string(),
  provider: Z.nativeEnum(ScreeningSearchProvider),
})
const KybAssistantTab = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('kybAssistant'),
  name: Z.string(),
})

const AttachmentTabBase = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('attachment'),
})

const AttachmentTab = Z.discriminatedUnion('attachmentType', [
  Z.object({ attachmentType: Z.literal('attachment'), attachmentToken: Z.string() }).merge(AttachmentTabBase),
  Z.object({ attachmentType: Z.literal('library'), attachmentToken: Z.string(), attachmentFilename: Z.string() }).merge(
    AttachmentTabBase
  ),
  Z.object({ attachmentType: Z.literal('surveyFileUpload'), questionToken: Z.string(), clientId: Z.string() }).merge(
    AttachmentTabBase
  ),
  Z.object({
    attachmentType: Z.literal('reviewDocument'),
    reviewToken: Z.string(),
    documentName: Z.enum([
      GeneratedDocumentType.Summary,
      GeneratedDocumentType.CtrResponse,
      GeneratedDocumentType.CtrSummary,
      GeneratedDocumentType.CtrXml,
      GeneratedDocumentType.FintracStrJson,
      GeneratedDocumentType.FintracStrJsonPreview,
      GeneratedDocumentType.FintracStrSummary,
      GeneratedDocumentType.FintracStrSummaryPreview,
      GeneratedDocumentType.GoamlXml,
      GeneratedDocumentType.InformationRequestResponse,
      GeneratedDocumentType.InvestigationAttachment,
      GeneratedDocumentType.InvestigationDocuments,
      GeneratedDocumentType.RequestForInformationResponse,
      GeneratedDocumentType.SarPdf,
      GeneratedDocumentType.SarResponse,
      GeneratedDocumentType.SarXml,
      GeneratedDocumentType.SurveyFile,
      GeneratedDocumentType.TipSubmission,
      GeneratedDocumentType.TransactionsCsv,
      GeneratedDocumentType.TransactionsCsvAll,
    ]),
    displayName: Z.string(),
  }).merge(AttachmentTabBase),
])

const AITabBase = Z.object({
  pinned: Z.boolean().optional(),
  type: Z.literal('ai'),
  reviewToken: Z.string(),
})

const AITab = Z.discriminatedUnion('action', [
  // We'll eventually be adding more actions here
  Z.object({ action: Z.literal('generateReviewNarrative') }).merge(AITabBase),
])

const CaseTab = Z.union([
  LibraryTab,
  LibraryCreateTab,
  OverviewTab,
  FilesTab,
  AlertsTab,
  TransactionsTab,
  LocationsTab,
  ConnectionsTab,
  OtherInfoTab,
  InformationRequestTab,
  InboundRequestTab,
  InformationRequestsTab,
  NewInformationRequestTab,
  ReviewTab,
  ReviewsTab,
  ThomsonReutersClearTab,
  MiddeskTab,
  SanctionsScreeningTab,
  KybAssistantTab,
  AttachmentTab,
  AITab,
])

const TabInfoRecord = Z.object({
  activeTabIndex: Z.number(), // Temporarily here to support legacy urls
  activeTabId: Z.string(),
  pathname: Z.string(),
  tabs: Z.array(CaseTab),
}).partial()

export const CaseOverviewRecord = Z.object({
  drawer: Z.union([Z.literal('comments'), Z.literal('history'), Z.literal('automations')]).optional(),
  tabInfo: Z.optional(TabInfoRecord),
  highlightComment: Z.string().optional(),
})

const Predicate = Z.object({
  o: Z.union([
    Z.literal(OperatorEnum.Contains),
    Z.literal(OperatorEnum.Is),
    Z.literal(OperatorEnum.In),
    Z.literal(OperatorEnum.Not),
    Z.literal(OperatorEnum.NotIn),
    Z.literal(OperatorEnum.Gte),
    Z.literal(OperatorEnum.Gt),
    Z.literal(OperatorEnum.Lte),
    Z.literal(OperatorEnum.Lt),
  ]),
  v: Z.array(Z.string()),
})

const PredicateFullKeys = Z.object({
  operator: Z.union([
    Z.literal(OperatorEnum.Contains),
    Z.literal(OperatorEnum.Is),
    Z.literal(OperatorEnum.In),
    Z.literal(OperatorEnum.Not),
    Z.literal(OperatorEnum.NotIn),
    Z.literal(OperatorEnum.Gte),
    Z.literal(OperatorEnum.Gt),
    Z.literal(OperatorEnum.Lte),
    Z.literal(OperatorEnum.Lt),
  ]),
  values: Z.array(Z.string()),
})

const Filter = Z.object({
  f: Z.string(),
  g: Z.number(),
  p: Predicate,
})

const FilterFullKeys = Z.object({
  field: Z.string(),
  group: Z.number(),
  predicate: PredicateFullKeys,
})

const Group = Z.object({
  c: Z.union([Z.literal(CombinatorEnum.And), Z.literal(CombinatorEnum.Or)]),
  p: Z.number().nullable().optional(),
})

const GroupFullKeys = Z.object({
  combinator: Z.union([Z.literal(CombinatorEnum.And), Z.literal(CombinatorEnum.Or)]),
  parent: Z.number().nullable().optional(),
})

const Sort = Z.object({
  sd: Z.union([Z.literal(SortEnum.Asc), Z.literal(SortEnum.Desc)]),
  sf: Z.string(),
})

const SortFullKeys = Z.object({
  sortDir: Z.union([Z.literal(SortEnum.Asc), Z.literal(SortEnum.Desc)]),
  sortField: Z.string(),
})

export const DashboardRecord = Z.object({
  // filters
  f: Z.array(Filter).nullable().optional(),
  g: Z.array(Group).nullable().optional(),
  q: Z.string().nullable().optional(),
  s: Z.array(Sort).nullable().optional(),
  // special query params
  c: Z.boolean().optional(),
})

export const DashboardRecordFullKeys = Z.object({
  // filters
  filters: Z.array(FilterFullKeys).nullable().optional(),
  groups: Z.array(GroupFullKeys).nullable().optional(),
  sorts: Z.array(SortFullKeys).nullable().optional(),
  query: Z.string().nullable().optional(),
  // special query params
  custom: Z.boolean().optional(),
})

export const HBQueryStringRecord = Z.object({
  case: CaseOverviewRecord,
  db: DashboardRecord,
  dashboard: DashboardRecordFullKeys,
})

export type HBQueryString = Z.infer<typeof HBQueryStringRecord>
export type CaseTab = Z.infer<typeof CaseTab>

export type DashboardQueryString = Z.infer<typeof DashboardRecord>
export type DashboardQueryStringFullKeys = Z.infer<typeof DashboardRecordFullKeys>

export type DashboardQueryStringType = DashboardQueryString | DashboardQueryStringFullKeys
export const isQueryStringWithFullKeys = (
  queryString: DashboardQueryString | DashboardQueryStringFullKeys
): queryString is DashboardQueryStringFullKeys => 'filters' in queryString

// type tests
if (DEBUG) {
  /* eslint-disable unused-imports/no-unused-vars */
  const normalRequest: NormalizedSearchRequest = {} as DashboardQueryStringFullKeys
  const queryString: DashboardQueryStringFullKeys = {} as NormalizedSearchRequest
}

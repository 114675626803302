import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const EmojiIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 15 15">
    <circle cx="5" cy="6" r="1" />
    <circle cx="10" cy="6" r="1" />
    <path d="M7.5 0A7.5 7.5 0 1 0 15 7.5 7.5 7.5 0 0 0 7.5 0zm0 14A6.5 6.5 0 1 1 14 7.5 6.51 6.51 0 0 1 7.5 14z" />
    <path d="M7.5 12a3.49 3.49 0 0 0 3.45-3h-6.9a3.49 3.49 0 0 0 3.45 3z" />
  </SvgIcon>
)

export default EmojiIcon

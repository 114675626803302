import { each } from 'lodash'

import { AnyAction } from 'redux'

import * as actions from 'actions/metricsActions'

export interface MetricsEntry {
  date: string
  entries: { [key: string]: number }[]
}

export interface MetricsSelector {
  options: { display: string; value: string }[]
  value: string
}

export interface MetricsData {
  data: MetricsEntry[]
  selectors: { [selector: string]: MetricsSelector }
}

/* eslint-disable camelcase */
export interface MetricsState {
  case_triggers_and_outcomes?: MetricsData
  review_type_actions?: MetricsData
  review_types_by_date_created?: MetricsData
  sars_filed?: MetricsData
  ctrs_filed?: MetricsData
  total_reviews_by_date?: MetricsData
  open_reviews?: MetricsData
  reviews_completed?: MetricsData
  reviews_created?: MetricsData
  time_sensitive_reviews?: MetricsData
}
/* eslint-enable camelcase */

const getInitialState = (): MetricsState => ({})

const metricsReducer = (state = getInitialState(), action: AnyAction): MetricsState => {
  switch (action.type) {
    case actions.SET_METRICS_DATA: {
      const { data } = action
      const updates: Partial<MetricsState> = {}

      // Update only the data we receive.
      data.forEach(({ token, data: dataUpdate, selectors }: any) => {
        ;(updates as any)[token] = {
          data: dataUpdate,
          selectors,
        }
      })

      return { ...state, ...updates }
    }
    // Merges in newly set selectors for one specific chart data
    case actions.SET_SELECTORS: {
      const { token, selectors } = action
      const existingMetricsForToken = (state as any)[token] || {}
      const updatedSelectors = { ...existingMetricsForToken.selectors }
      each(selectors, (value, key) => {
        updatedSelectors[key] = { ...updatedSelectors[key], value }
      })

      return {
        ...state,
        [token]: { ...existingMetricsForToken, selectors: updatedSelectors },
      }
    }
    default: {
      return state
    }
  }
}

export default metricsReducer

import { useState, useEffect } from 'react'

import { gql } from '@apollo/client'

import { FormProvider, type SubmitHandler } from 'react-hook-form'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import ConfirmDialog from 'components/library/ConfirmDialog'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useFeatureFlag } from 'hooks'
import { AutomationActionType, FeatureFlag } from 'types/api'

import { useAutomationRuleToggle, fragment as toggleFragment } from '../hooks/useAutomationRuleToggle'
import { useDeleteAutomationRule, fragment as deleteFragment } from '../hooks/useDeleteAutomationRule'

import { CreateAutomationSelectType } from './CreateAutomationSelectType'

import SaveEnableDisableDialog from './SaveEnableDisableDialog'
import SelectActionDialog from './SelectActionDialog'
import { ViewAutomationSteps } from './ViewAutomationSteps'
import { ViewAutomationInfoAutomationRuleFragment as AutomationRule } from './__generated__/ViewAutomationInfo.generated'

import { type FormSchema, type FormSchemaReturnType } from './formSchema'
import { setActionParams } from './util'

interface Props {
  automationRule: AutomationRule
  form: FormSchemaReturnType
  defaultValues: FormSchema
  onSaveAutomationRule: SubmitHandler<FormSchema>
}

export function ViewAutomationInfo({ automationRule, form, defaultValues, onSaveAutomationRule }: Props) {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const usage = useUsage()
  const [deleting, setDeleting] = useState(false)
  const [actionModalOpen, setActionModalOpen] = useState(false)
  const [enablingOrDisabling, setEnablingOrDisabling] = useState<boolean>(false)
  const [ruleNameError, setRuleNameError] = useState(false)

  const { handleSubmit, reset, formState, getValues, watch } = form
  const { isDirty, isSubmitting } = formState

  const [domainType, automationType] = watch(['domain.type', 'automationType', 'actionType', 'name'])

  useEffect(() => {
    if (isDirty && automationRule.enabled) {
      setEnablingOrDisabling(true)
    }
  }, [automationRule.enabled, isDirty])

  const { enableRule, disableRule, loading: toggleRuleLoading } = useAutomationRuleToggle(automationRule)
  const { deleteRule, loading: deleteRuleLoading } = useDeleteAutomationRule(automationRule)

  const loading = isSubmitting || toggleRuleLoading || deleteRuleLoading

  const handleSave: SubmitHandler<FormSchema> = async (values) => {
    await onSaveAutomationRule(values)
    setEnablingOrDisabling(false)
  }

  const handleSaveAndEnable: SubmitHandler<FormSchema> = async (values) => {
    const result = await onSaveAutomationRule(values)
    setEnablingOrDisabling(false)

    if (result) {
      await enableRule()
      reset(getValues())
    }
  }

  const handleSubmitAndSave = handleSubmit(handleSave)
  const handleSubmitAndSaveAndEnable = handleSubmit(handleSaveAndEnable)

  const handleSelectAction = (newActionType: AutomationActionType) => {
    setActionModalOpen(false)
    setActionParams({ newActionType, form, isRenameOtherInfoEnabled })
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmitAndSave}>
        <HbDialog
          id="name_error"
          open={ruleNameError}
          onClose={() => {
            setRuleNameError(false)
          }}
          title="Please Add a Title"
          content={<HbText tag="p">All automation rules must have a title before enabling.</HbText>}
          actions={<HbButton label="Continue" variant="warning" onClick={() => setRuleNameError(false)} />}
        />
        <ConfirmDialog
          confirmText="Yes, Delete Automation"
          confirmVariant="warning"
          onConfirm={deleteRule}
          loading={loading}
          open={deleting}
          onClose={() => setDeleting(false)}
          title="Delete Automation"
        >
          <HbText tag="p">Are you sure you want to delete this automation recipe?</HbText>
        </ConfirmDialog>
        <SaveEnableDisableDialog
          onSaveAndEnable={handleSubmitAndSaveAndEnable}
          onDisable={disableRule}
          onEnable={enableRule}
          loading={loading}
          onClose={() => setEnablingOrDisabling(false)}
          onReset={() => reset(defaultValues, { keepDirty: false })}
          open={enablingOrDisabling}
          automationRule={automationRule}
          automationRuleDirty={isDirty}
        />
        <SelectActionDialog
          open={actionModalOpen}
          onClose={() => setActionModalOpen(false)}
          onSelectAction={(newActionType) => {
            usage.logEvent({
              name: 'automations:editorChangeAction:clicked',
              data: { automationRuleToken: automationRule.token, newAction: newActionType },
            })
            handleSelectAction(newActionType)
          }}
          domainType={domainType || undefined}
          automationType={automationType || undefined}
        />
        {!domainType && !automationType ? (
          <CreateAutomationSelectType form={form} />
        ) : (
          <ViewAutomationSteps form={form} setActionModalOpen={setActionModalOpen} />
        )}
      </form>
    </FormProvider>
  )
}

ViewAutomationInfo.fragments = {
  automationRule: gql`
    fragment ViewAutomationInfoAutomationRule on CanonicalAutomationRule {
      ... on CanonicalAutomationRuleBase {
        token
        disabled
        enabled
        name
        displayName
        organizationToken
      }
      ...SaveEnableDisableDialogAutomationRule
      ...UseAutomationRuleToggleAutomationRule
      ...UseDeleteAutomationRuleAutomationRule
    }
    ${SaveEnableDisableDialog.fragments.automationRule}
    ${toggleFragment}
    ${deleteFragment}
  `,
}

import { PropsWithoutRef, ReactElement, forwardRef, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { AllInbox, ArrowDropDownRounded, Group, PersonAddAlt1 } from '@mui/icons-material'
import { Autocomplete } from '@mui/lab'
import {
  ButtonBase,
  ListSubheader,
  Popover,
  PopoverProps as MuiPopoverProps,
  AutocompleteProps as MuiAutocompleteProps,
  OutlinedInput,
  outlinedInputClasses,
  OutlinedInputProps,
  styled,
  AutocompleteRenderGroupParams,
  Paper,
  PopoverActions,
  popoverClasses,
  autocompleteClasses,
} from '@mui/material'
import { createFilterOptions } from '@mui/material/useAutocomplete'

import { useFormikContext } from 'formik'

import { sortBy } from 'lodash'

import { useSelector } from 'actions/store'
import * as CollapseCard from 'components/HbComponents/CollapseCard'
import { useIsErroneous } from 'components/HbComponents/Form/Inputs/useIsErroneous'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbUnstyledPopperComponent } from 'components/HbComponents/HbUnstyledPopper'
import { HbText } from 'components/HbComponents/Text/HbText'

import {
  AssigneeOption,
  AssigneeOptionCustomProps as BaseAssigneeOptionCustomProps,
  OptionText,
} from 'components/cases/reviews/assignment/AssigneeOption'
import { QueueOption } from 'components/cases/reviews/queues/QueueSelectField'
import Loader from 'components/library/Loader'
import { InputContainer } from 'components/material/Form'
import { getCurrentAccount } from 'helpers/stateHelpers'
import { useCallbackRef } from 'hooks'
import { CheckIcon, CloseIcon, SearchIcon } from 'icons'
import { ColorNameEnum } from 'types/api'
import { Account, WithOptional } from 'types/hb'

export type QueueOrAssigneeType = 'account' | 'queue'

export interface FormValue {
  type: QueueOrAssigneeType | null
  value: string | undefined
}

type CommonAssigneeOptionCustomProps = {
  type: 'account'
}

type AutoAssignOptionCustomProps = CommonAssigneeOptionCustomProps & {
  isAutoAssign: true
}

type AccountAssigneeOptionCustomProps = CommonAssigneeOptionCustomProps & {
  isAutoAssign?: false
} & BaseAssigneeOptionCustomProps

type QueueOptionCustomProps = {
  type: 'queue'
  color: string
}

interface BaseOption {
  display: string
  value: string
}

type AutoAssignOptionType = BaseOption & {
  custom: AutoAssignOptionCustomProps
}

type BaseAssigneeOptionType = BaseOption & {
  custom: AccountAssigneeOptionCustomProps
}

type AssigneeOptionType = AutoAssignOptionType | BaseAssigneeOptionType

interface QueueOptionType extends BaseOption {
  custom: QueueOptionCustomProps
}

export type Option = AssigneeOptionType | QueueOptionType

// tsc helpers for narrowing option types depending on nested custom props
// e.g. `option.custom.type` or `option.custom.isAutoAssign`
type OptionFromType<OptionType, O = Option> = O extends { custom: { type: OptionType } } ? O : never
const fromOptionType = <OptionType extends Option['custom']['type']>(
  option: Option,
  type: OptionType
): option is OptionFromType<OptionType> => option.custom.type === type
const isAssignee = (option: Option): option is AssigneeOptionType => fromOptionType(option, 'account')

type FromIsAutoAssign<IsAutoAssign, O = AssigneeOptionType> = O extends { custom: { isAutoAssign: IsAutoAssign } }
  ? O
  : never
const fromIsAutoAssign = <IsAutoAssign extends AssigneeOptionType['custom']['isAutoAssign']>(
  option: AssigneeOptionType,
  isAutoAssign: IsAutoAssign
): option is FromIsAutoAssign<IsAutoAssign> => option.custom.isAutoAssign === isAutoAssign
const isAutoAssign = (option: AssigneeOptionType): option is AutoAssignOptionType => fromIsAutoAssign(option, true)

export interface Queue {
  color: ColorNameEnum
  name: string
  token: string
}

export const FIELD_NAME = 'queueOrAssigneeToken'
const AUTO_ASSIGNMENT_TOKEN = 'AUTO'
const OPTION_HEIGHT = 40

const AutoAssignText = styled(HbText)(() => ({
  lineHeight: 14,
}))

interface OptionContentProps {
  className?: string
  option: Option
}

const OptionContent = ({ className, option }: OptionContentProps) => {
  if (isAssignee(option)) {
    if (isAutoAssign(option)) {
      return <AutoAssignText className={className}>{option.display}</AutoAssignText>
    }
    return <AssigneeOption className={className} option={option} />
  }
  return <QueueOption option={option} />
}

const Selection = ({ selected }: { selected?: Option }) => {
  if (!selected) return null
  return <OptionContent option={selected} />
}

const AutocompleteInputContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 2, 1),
}))

export const StyledManualAssignOutlinedInput = styled(OutlinedInput)(({ theme }) => ({
  [`&.${outlinedInputClasses.root}`]: {
    width: '100%',
    boxSizing: 'border-box',
    borderRadius: theme.shape.smallContainer.borderRadius,
    '&&': {
      paddingTop: theme.spacing(),
      paddingRight: theme.spacing(2),
      paddingBottom: theme.spacing(),
      paddingLeft: theme.spacing(2),
    },
  },
}))

const ClearButton = styled(ButtonBase)(({ theme }) => ({
  borderRadius: theme.shape.roundedRadius,
  '& svg': {
    size: 20,
    height: 20,
    fill: theme.palette.styleguide.textGreyLight,
  },
}))

export type AutocompleteInputProps = OutlinedInputProps & {
  clearInput?: () => void
}

export const AutocompleteInput = forwardRef<HTMLInputElement, PropsWithoutRef<AutocompleteInputProps>>(
  ({ clearInput, ...props }, ref) => {
    return (
      <StyledManualAssignOutlinedInput
        autoFocus
        startAdornment={<SearchIcon />}
        endAdornment={
          clearInput &&
          props.inputProps?.value && (
            <ClearButton aria-label="Clear input" onClick={clearInput}>
              <CloseIcon />
            </ClearButton>
          )
        }
        placeholder="Search"
        ref={ref}
        {...props}
      />
    )
  }
)

const AssignToMeContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 2),
  marginBottom: theme.spacing(0.5),
}))

const AssignToMeButton = styled(HbButton)(({ theme }) => ({
  ...theme.typography.sizes.md,
}))

export const AssignToMe = ({ handleAssignToSelf, loading }: { handleAssignToSelf: () => void; loading?: boolean }) => (
  <AssignToMeContainer>
    <AssignToMeButton
      disabled={loading}
      Icon={PersonAddAlt1}
      label="Assign to me"
      onClick={handleAssignToSelf}
      size="small"
      variant="textPrimary"
    />
  </AssignToMeContainer>
)

const renderTags = () => null

const StyledAutocomplete = styled(Autocomplete)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  background: theme.palette.styleguide.white,
  flex: 0,
})) as typeof Autocomplete

const StyledPopover = styled(Popover)(() => ({
  [`& .${popoverClasses.paper}`]: {
    margin: 0,
    display: 'flex',
    flexFlow: 'column',
    maxHeight: 'min(800px, 90vh)',
  },
}))

export const PopoverAutocompletePaperComponent = styled(Paper)(() => ({
  display: 'flex',
  flexDirection: 'column',
  margin: 0,
  borderRadius: 0,
  boxShadow: 'none',
  height: '100%',
  '&&': {
    overflow: 'initial',
  },

  [`& .${autocompleteClasses.listbox}`]: {
    padding: 0,
    maxHeight: '100%',
    position: 'initial',
    overflow: 'initial',
  },
}))

const StyledAutocompleteDisabledPopperComponent = styled(HbUnstyledPopperComponent)(() => ({
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    padding: 0,
    maxHeight: '100%',
    flex: 1,
    position: 'relative',
    width: '100%',
    overflow: 'auto',
    minHeight: 0,
  },
}))

const GroupSubheader = styled(ListSubheader)(({ theme }) => ({
  background: theme.palette.styleguide.white,
  borderBottom: `1px solid ${theme.palette.dividers.light}`,
  bottom: 0,
  lineHeight: '40px',
}))

const StyledCollapseToggle = styled(CollapseCard.Toggle)(({ theme }) => ({
  '&&': {
    padding: theme.spacing(1, 0),
    '&:hover': {
      border: '1px solid transparent',
    },
  },
}))

const ToggleLabel = styled('div')(({ theme }) => ({
  columnGap: theme.spacing(),
  display: 'flex',
  alignItems: 'center',
  color: theme.palette.styleguide.textGreyDark,
}))

const OptionListItem = styled('li')(({ theme }) => ({
  '&&': {
    padding: theme.spacing(1, 2),
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  '&[aria-selected="true"]': {
    background: theme.palette.background.contrastMedium,
  },
  '&[data-focus="true"]': {
    background: theme.palette.background.contrastLight,
  },
  '&:hover': {
    cursor: 'pointer',
  },

  // these first and last items determine the spacing between list groups
  // to simplify the styling since the sections are collapsible
  '&:first-of-type': {
    marginTop: theme.spacing(0.5),
  },
  '&:last-of-type': {
    marginBottom: theme.spacing(2),
  },
}))

const NoOptionsText = styled(HbText)(({ theme }) => ({
  marginLeft: theme.spacing(),
}))

const InputLoader = styled(Loader)(() => ({
  top: -2,
}))

const ListItemOptionContent = styled(OptionContent)<{ isSelected: boolean }>(({ isSelected, theme }) => ({
  justifyContent: 'space-between',
  [`& ${OptionText}`]: {
    color: isSelected ? theme.palette.action.active : 'initial',
  },
}))

const SelectedItemIcon = styled(CheckIcon)(({ theme }) => ({
  color: theme.palette.action.active,
}))

const GroupCollapsingListItem = styled('li')(() => ({
  '&:last-of-type': {
    [`& ${OptionListItem}`]: {
      marginBottom: 0,
    },
  },
}))

const groupIcons: Record<QueueOrAssigneeType, ReactElement> = {
  account: <Group />,
  queue: <AllInbox />,
}

// Hoisted slot props from MUI Popover,
// accessible without nesting in the component props
type FirstLevelPopoverPropNames = 'anchorEl' | 'className' | 'container' | 'onClose' | 'open' | 'slotProps'
type FirstLevelPopoverProps = Pick<MuiPopoverProps, FirstLevelPopoverPropNames>

// Hoisted slot props from MUI Autocomplete
// accessible without nesting in the component props
type FirstLevelAutocompletePropNames =
  | 'filterOptions'
  | 'inputValue'
  | 'onChange'
  | 'onInputChange'
  | 'options'
  | 'value'

type FirstLevelAutocompleteProps<
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Pick<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, FirstLevelAutocompletePropNames>

// Required slot props that are internally defined in the component,
// but which may be overridden if needed
type OptionalAutocompleteOverridePropNames = 'renderInput'

export interface QueueOrAssigneeAutocompletePopoverProps<
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> extends FirstLevelPopoverProps,
    FirstLevelAutocompleteProps<T, Multiple, DisableClearable, FreeSolo> {
  AutocompleteProps?: WithOptional<
    Omit<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, FirstLevelAutocompletePropNames>,
    OptionalAutocompleteOverridePropNames
  >
  clearInput?: () => void
  handleAssignToSelf?: () => void
  loading?: boolean
  PopoverProps?: Omit<MuiPopoverProps, FirstLevelPopoverPropNames>
  slotProps?: MuiPopoverProps['slotProps']
}

export const QueueOrAssigneeAutocompletePopover = <
  T extends Option,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
>({
  anchorEl,
  AutocompleteProps,
  className,
  clearInput,
  container,
  handleAssignToSelf,
  inputValue,
  loading,
  onClose,
  onChange,
  onInputChange,
  open,
  options,
  PopoverProps,
  slotProps,
  value,
}: QueueOrAssigneeAutocompletePopoverProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const actionRef = useRef<PopoverActions | null>(null)

  /**
   * The popover position needs to be recomputed whenever the menu dimensions change,
   * e.g. when the collapsible list sections are toggled.
   */
  const triggerPopoverReposition = useCallback(() => {
    actionRef.current?.updatePosition()
  }, [])

  const getOptionLabel = useCallback((option: T) => option.display, [])

  const groupBy = useCallback((option: T) => option.custom.type, [])

  // track the states of the collapsible accounts/queues list sections
  // so they can be opened/closed programmatically while filtering
  const [accountsListExpanded, setAccountsListExpanded] = useState(true)
  const [queuesListExpanded, setQueuesListExpanded] = useState(true)

  const renderGroup = useCallback<
    NonNullable<MuiAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>['renderGroup']>
  >(
    (params) => {
      const { group } = params as AutocompleteRenderGroupParams & { group: QueueOrAssigneeType }
      const expandedByGroup: Record<
        QueueOrAssigneeType,
        { expanded: boolean; heading: string; setExpanded: (expanded: boolean) => void }
      > = {
        account: {
          expanded: accountsListExpanded,
          heading: 'Accounts',
          setExpanded: setAccountsListExpanded,
        },
        queue: {
          expanded: queuesListExpanded,
          heading: 'Queues',
          setExpanded: setQueuesListExpanded,
        },
      }
      const { expanded, heading, setExpanded } = expandedByGroup[group]
      return (
        <CollapseCard.Provider
          expanded={expanded}
          setExpanded={setExpanded}
          rootId={`${group}_autocompleteListCollapsibleSection`}
        >
          <GroupSubheader>
            <StyledCollapseToggle>
              <ToggleLabel>
                {groupIcons[group]}
                <HbText bold>{heading}</HbText>
              </ToggleLabel>
              <CollapseCard.ExpandIcon />
            </StyledCollapseToggle>
          </GroupSubheader>
          <GroupCollapsingListItem>
            <CollapseCard.Collapse
              unmountOnExit
              onEntered={triggerPopoverReposition}
              onExited={triggerPopoverReposition}
            >
              <ul>{params.children}</ul>
            </CollapseCard.Collapse>
          </GroupCollapsingListItem>
        </CollapseCard.Provider>
      )
    },
    [accountsListExpanded, queuesListExpanded, triggerPopoverReposition]
  )

  const renderOption = useCallback(
    (optionProps: React.HTMLAttributes<HTMLLIElement>, option: T, { selected: isSelected }) => (
      <OptionListItem {...optionProps} key={option.value}>
        <ListItemOptionContent isSelected={isSelected} option={option} />
        {isSelected && <SelectedItemIcon fontSize="small" />}
      </OptionListItem>
    ),
    []
  )

  const onCustomAutocompleteInputChange = useCallback(() => {
    if (!accountsListExpanded) setAccountsListExpanded(true)
    if (!queuesListExpanded) setQueuesListExpanded(true)
  }, [accountsListExpanded, queuesListExpanded])

  const renderInput = useCallback(
    (params) => (
      <>
        <AutocompleteInputContainer>
          <AutocompleteInput
            clearInput={clearInput}
            inputProps={params.inputProps}
            onChange={onCustomAutocompleteInputChange}
            ref={params.InputProps.ref}
          />
        </AutocompleteInputContainer>
        {handleAssignToSelf && <AssignToMe handleAssignToSelf={handleAssignToSelf} loading={loading} />}
        {loading && <InputLoader loading={loading} />}
      </>
    ),
    [clearInput, handleAssignToSelf, loading, onCustomAutocompleteInputChange]
  )

  return (
    <StyledPopover
      action={actionRef}
      anchorEl={anchorEl}
      className={className}
      container={container}
      open={open}
      onClose={onClose}
      slotProps={slotProps}
      {...PopoverProps}
    >
      <StyledAutocomplete<T, Multiple, DisableClearable, FreeSolo>
        clearOnEscape
        disableCloseOnSelect
        disablePortal
        getOptionLabel={getOptionLabel}
        groupBy={groupBy}
        inputValue={inputValue}
        noOptionsText={<NoOptionsText color="disabled">No options</NoOptionsText>}
        PaperComponent={PopoverAutocompletePaperComponent}
        PopperComponent={StyledAutocompleteDisabledPopperComponent}
        onChange={onChange}
        onInputChange={onInputChange}
        open
        options={options}
        renderGroup={renderGroup}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        value={value}
        {...AutocompleteProps}
      />
    </StyledPopover>
  )
}

export const wrappedFilterOptions = createFilterOptions<Option>()

export const useAnchorElForMinWidth = ({ anchorEl, menuOpen }: { anchorEl: HTMLElement | null; menuOpen: boolean }) => {
  const [minWidth, setMinWidth] = useState(0)
  // query the anchor element width once when opening the menu
  useEffect(() => {
    if (!anchorEl || !menuOpen) return
    setMinWidth(anchorEl.clientWidth)
  }, [anchorEl, menuOpen])

  return { minWidth }
}

export const getQueueOptions = (queues: Queue[]) =>
  queues.map((queue) => ({
    custom: { color: queue.color, type: 'queue' as const },
    display: queue.name,
    value: queue.token,
  }))

export const getAccountOptions = (accounts: Account[], includeAutoOption?: boolean) => {
  const _options: AssigneeOptionType[] = sortBy(accounts, ['firstName', 'lastName']).map((account) => ({
    custom: {
      avatarColor: account.avatarColor,
      avatarVariant: account.avatarVariant,
      firstName: account.firstName,
      lastName: account.lastName,
      type: 'account' as const,
    },
    display: account.fullName,
    value: account.token,
  }))

  if (includeAutoOption) {
    _options.unshift({
      custom: { isAutoAssign: true, type: 'account' },
      display: 'Auto Assign',
      value: AUTO_ASSIGNMENT_TOKEN,
    })
  }

  return _options
}

const StyledQueueOrAssigneeAutocompletePopover = styled(QueueOrAssigneeAutocompletePopover)(() => ({
  [`& .${popoverClasses.paper}`]: {
    maxHeight: 600,
  },
})) as typeof QueueOrAssigneeAutocompletePopover

const SelectionContainer = styled(ButtonBase)(({ theme }) => ({
  minHeight: OPTION_HEIGHT,
  cursor: 'pointer',
  justifyContent: 'space-between',
  marginTop: theme.spacing(),
  padding: theme.spacing(1, 4, 1, 1.75),
  border: `1px solid ${theme.palette.styleguide.borderDark}`,
  borderRadius: theme.shape.largeContainer.borderRadius,
  '&:hover': {
    border: `1px solid ${theme.palette.styleguide.black}`,
  },
}))

const StyledArrowDropDownRounded = styled(ArrowDropDownRounded)(({ theme }) => ({
  position: 'absolute',
  right: 7,
  color: theme.palette.styleguide.greyB,
  width: 24,
  height: 24,
}))

interface QueueOrAssigneeAutocompleteProps {
  accounts: Account[]
  apiError?: string | string[]
  AutocompleteProps?: QueueOrAssigneeAutocompletePopoverProps<Option, false, false, false>['AutocompleteProps']
  className?: string
  clientError?: string | string[] | undefined
  handleChange: NonNullable<QueueOrAssigneeAutocompletePopoverProps<Option, false, false, false>['onChange']>
  handleAssignToSelf?: () => void
  includeAutoOption?: boolean
  isErroneous?: boolean
  label?: string
  open?: boolean
  queues: Queue[]
  setOpen?: (open: boolean) => void
  value?: FormValue
  disabled?: boolean
}

export const QueueOrAssigneeAutocomplete = ({
  accounts,
  apiError,
  AutocompleteProps,
  className,
  clientError,
  handleChange,
  handleAssignToSelf,
  includeAutoOption,
  isErroneous,
  label,
  open: openProp = false,
  queues,
  setOpen: setOpenProp,
  value,
  disabled = false,
}: QueueOrAssigneeAutocompleteProps) => {
  const [menuOpen, setMenuOpen] = useState(openProp)

  const handleSetOpen = useCallback(
    (isOpen) => {
      if (typeof isOpen !== 'boolean') return
      setOpenProp?.(isOpen)
      setMenuOpen(isOpen)
    },
    [setOpenProp]
  )

  useEffect(() => handleSetOpen(openProp), [handleSetOpen, openProp])

  const [filter, setFilter] = useState('')
  const handleFilterInputChange = useCallback((_e, updatedFilter) => {
    setFilter(updatedFilter)
  }, [])

  const clearInput = useCallback(() => setFilter(''), [])

  const unfilteredQueueOptions = useMemo(() => getQueueOptions(queues), [queues])
  const unfilteredAccountOptions = useMemo(
    () => getAccountOptions(accounts, includeAutoOption),
    [accounts, includeAutoOption]
  )

  const allUnfilteredOptions = useMemo(
    () => [...unfilteredQueueOptions, ...unfilteredAccountOptions],
    [unfilteredQueueOptions, unfilteredAccountOptions]
  )

  const selectedOption = useMemo(
    () => allUnfilteredOptions.find((o) => o.value === value?.value),
    [allUnfilteredOptions, value]
  )

  const labelId = `${FIELD_NAME}-label`

  const handleCloseMenu = useCallback(() => {
    if (filter !== selectedOption?.display) {
      setFilter(selectedOption?.display ?? '')
    }
    handleSetOpen(false)
  }, [filter, handleSetOpen, selectedOption])

  const onChange = useCallback<
    NonNullable<QueueOrAssigneeAutocompletePopoverProps<Option, false, false, false>['onChange']>
  >(
    (e, updatedValue, reason, details) => {
      handleChange(e, updatedValue, reason, details)
      if (reason === 'selectOption') handleCloseMenu()
    },
    [handleChange, handleCloseMenu]
  )

  const [anchorEl, setAnchorEl] = useCallbackRef<HTMLDivElement>()
  const { minWidth } = useAnchorElForMinWidth({ anchorEl, menuOpen })

  return (
    <InputContainer
      apiError={apiError}
      clientError={clientError}
      className={className}
      htmlFor={FIELD_NAME}
      isErroneous={isErroneous}
      labelId={labelId}
      label={label}
      noSpacing
      ref={setAnchorEl}
      testId="autocomplete_queueOrAssignee"
    >
      <SelectionContainer
        disabled={disabled}
        className={className}
        aria-labelledby={labelId}
        disableRipple
        disableTouchRipple
        onClick={() => handleSetOpen(true)}
      >
        {selectedOption && <Selection selected={selectedOption} />}
        <StyledArrowDropDownRounded />
      </SelectionContainer>
      <StyledQueueOrAssigneeAutocompletePopover
        anchorEl={anchorEl}
        AutocompleteProps={AutocompleteProps}
        clearInput={clearInput}
        handleAssignToSelf={handleAssignToSelf}
        onInputChange={handleFilterInputChange}
        onChange={onChange}
        onClose={handleCloseMenu}
        open={menuOpen}
        options={allUnfilteredOptions}
        slotProps={{
          paper: {
            style: { width: minWidth },
          },
        }}
        inputValue={filter}
        value={selectedOption}
      />
    </InputContainer>
  )
}

const EMPTY_VALUE = { type: undefined, value: undefined }

interface QueueOrAssigneeAutocompleteFormikFieldProps {
  accounts: Account[]
  enableAssignToSelf?: boolean
  includeAutoOption?: boolean
  label: string
  queues: Queue[]
}

export const QueueOrAssigneeAutocompleteFormikField = ({
  accounts,
  enableAssignToSelf,
  includeAutoOption,
  label,
  queues,
}: QueueOrAssigneeAutocompleteFormikFieldProps) => {
  const currentAccount = useSelector(getCurrentAccount)
  const formik = useFormikContext<{ [FIELD_NAME]: FormValue }>()

  const { values } = formik
  const formValue = values[FIELD_NAME]

  const { apiErrors, clientError, isErroneous } = useIsErroneous({ autosave: true, name: FIELD_NAME })

  const handleChange = useCallback<QueueOrAssigneeAutocompleteProps['handleChange']>(
    (_e, option) => {
      const updatedValue = option ? { type: option.custom.type, value: option.value } : EMPTY_VALUE
      formik.setFieldValue(FIELD_NAME, updatedValue)
      formik.setFieldTouched(FIELD_NAME) // useIsErroneous autosave did not work properly w/o this
    },
    [formik]
  )

  const handleAssignToSelf = useCallback(() => {
    formik.setFieldValue(FIELD_NAME, { type: 'account', value: currentAccount.token })
    formik.setFieldTouched(FIELD_NAME)
  }, [currentAccount, formik])

  return (
    <QueueOrAssigneeAutocomplete
      accounts={accounts}
      apiError={apiErrors}
      clientError={clientError}
      handleChange={handleChange}
      handleAssignToSelf={enableAssignToSelf ? handleAssignToSelf : undefined}
      includeAutoOption={includeAutoOption}
      isErroneous={isErroneous}
      label={label}
      queues={queues}
      value={formValue}
    />
  )
}

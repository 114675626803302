import { DISPLAY_TYPES } from 'actions/importingFields.types'
import { useDispatch } from 'actions/store'
import { setShowDisplay } from 'actions/viewActions'
import Feature from 'components/Feature'
import { MenuListItem } from 'components/library/Menu'
import { FeatureFlag, CaseImportCategory } from 'types/api'

interface Props {
  openNewDialog: () => void
}

export default function ReviewsAddMenuItems(props: Props) {
  const { openNewDialog } = props
  const dispatch = useDispatch()
  const openCaseImport = () => {
    dispatch(setShowDisplay('case_import', DISPLAY_TYPES.RAW))
  }
  const openCRMImport = () => {
    dispatch(setShowDisplay('case_import', DISPLAY_TYPES.RAW, CaseImportCategory.LibraryData))
  }

  return (
    <>
      <MenuListItem testId="menu_item_new_case" onClick={openNewDialog}>
        New Case
      </MenuListItem>
      <Feature name={FeatureFlag.EnableBulkCaseImport}>
        <MenuListItem testId="menu_item_case_import" onClick={openCaseImport}>
          Bulk Case Import
        </MenuListItem>
      </Feature>
      <Feature name={FeatureFlag.EnableBulkCrmImport}>
        <MenuListItem testId="menu_item_crm_import" onClick={openCRMImport}>
          Bulk CRM Import
        </MenuListItem>
      </Feature>
    </>
  )
}

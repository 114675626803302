import { gql } from '@apollo/client'
import { styled } from '@mui/system'

import { SubmitHandler, useForm } from 'react-hook-form'

import HbRHFDropdown from 'components/HbComponents/Form/HbInputs/HbDropdown/HbRHFDropdown'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import { useUpdateAutomationRuleSettings } from 'components/pages/automations/hooks/useUpdateAutomationRuleSettings'
import { AutomationFailureNotificationSetting } from 'types/api'

import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from './__generated__/ViewAutomation.queries.generated'

const Root = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  display: 'flex',
  flexFlow: 'column nowrap',
  gap: theme.spacing(2),
}))

type FormInputs = { failureNotificationSetting: AutomationFailureNotificationSetting }
export const AutomationSettingsPanel = ({ automationRule }: { automationRule: AutomationRule }) => {
  const { updateRuleSettings, loading } = useUpdateAutomationRuleSettings(automationRule)
  const { control, handleSubmit } = useForm<FormInputs>({
    defaultValues: { failureNotificationSetting: automationRule.failureNotificationSetting },
  })
  const onSubmit: SubmitHandler<FormInputs> = (data, e) => {
    e?.preventDefault()
    updateRuleSettings(data.failureNotificationSetting)
  }

  return (
    <Root>
      <HbText tag="h2" size="lg" bold block>
        Notifications
      </HbText>
      <HbRHFDropdown
        label="On error"
        control={control}
        name="failureNotificationSetting"
        options={[
          {
            display: 'Do nothing',
            value: 'no_notifications',
          },
          {
            display: 'Email recipe owner every time recipe fails',
            value: 'after_every_failure',
          },
          {
            display: 'Email recipe owner once recipe starts failing after success',
            value: 'after_previous_success',
          },
        ]}
      />
      <HbButton label="Save settings" onClick={handleSubmit(onSubmit)} variant="secondary" loading={loading} />
    </Root>
  )
}

AutomationSettingsPanel.fragments = {
  automationRule: gql`
    fragment ViewAutomationSettingsAutomationRule on CanonicalAutomationRuleBase {
      token
      createdAt
      failureNotificationSetting
      createdBy {
        fullName
      }
    }
  `,
}

import { CombinatorEnum } from 'types/api'
import { NormalizedSearchRequest, SearchRequest, SearchGroup, isSearchFilter } from 'utils/query/api.types'

const logNormalizationError = (query: NormalizedSearchRequest | SearchRequest) => {
  if (Object.keys(query).length > 0) {
    /* eslint-disable no-console */
    console.error('Malformed query. Cannot denormalize!')
    console.debug('Malformed query:', query)
    /* eslint-enable no-console */
  }
}

const normalizeQueryRecursive = ({
  request = { filter: { combinator: CombinatorEnum.Or, children: [] } },
  parent = -1,
  normalQuery = { groups: [], filters: [] },
  subgroupIndex = 0,
}: {
  request: SearchRequest
  parent?: number
  normalQuery?: NormalizedSearchRequest
  subgroupIndex?: number
}): NormalizedSearchRequest => {
  const { query, filter, sorts } = request
  const { combinator, children } = filter ?? {}

  const groups = normalQuery?.groups ?? []
  const filters = normalQuery?.filters ?? []

  groups.push(parent === -1 ? { combinator } : { combinator, parent })
  const currentGroup = parent + subgroupIndex + 1

  let subgroups = 0
  children.forEach((child) => {
    if (isSearchFilter(child)) {
      filters.push({ field: child.field, predicate: child.predicate, group: currentGroup })
    } else {
      // normalize the child group and increment the subgroup count for the current group by 1
      normalizeQueryRecursive({
        request: { filter: child },
        parent: currentGroup,
        normalQuery,
        subgroupIndex: subgroups,
      })
      subgroups += 1
    }
  })

  const result = query ? { query, filters, groups } : { filters, groups }
  return (sorts?.length ?? 0) > 0 ? { sorts, ...result } : result
}

export const normalizeRequest = (request: SearchRequest) => normalizeQueryRecursive({ request })

export const makeEmptySearchRequest: (base: Partial<SearchRequest>) => SearchRequest = (base) => ({
  ...base,
  filter: { combinator: CombinatorEnum.And, children: [] },
})

export const denormalizeRequest = (request: NormalizedSearchRequest): SearchRequest => {
  const { groups, filters, query, sorts } = request
  const base: Partial<SearchRequest> = sorts
    ? { sorts: sorts.map(({ sortDir, sortField }) => ({ sortDir, sortField })) }
    : {}
  const emptySearchRequest = makeEmptySearchRequest(base)

  // if no groups or no filters just log an error and return something sane
  if (!groups || !filters || groups.length === 0) {
    logNormalizationError(request)
    // make sure to keep the sorts around, since they are still likely valid
    return emptySearchRequest
  }

  const groupMap: { [parent: number]: SearchGroup } = {}

  groups.forEach(({ parent = -1, combinator }, index) => {
    groupMap[index] = {
      combinator,
      children: [],
    }

    const parentGroup = groupMap[parent ?? -1]
    parentGroup?.children.push(groupMap[index])
  })

  const wellFormedData = filters
    .map((filter) => filter.group)
    // unless every group in the filter map is included in the groupMap, we have malformed data
    .every((group) => Object.keys(groupMap).includes(`${group}`))

  if (!wellFormedData) {
    logNormalizationError(request)
    return emptySearchRequest
  }

  filters.forEach((filter) => {
    const parentGroup = groupMap[filter.group]
    parentGroup?.children.push({
      field: filter.field,
      predicate: { operator: filter.predicate.operator, values: filter.predicate.values },
    })
  })

  const firstGroup = groupMap[0]

  if (query && firstGroup) {
    return { ...base, query, filter: { ...firstGroup } }
  }

  if (firstGroup) {
    return { ...base, filter: { ...firstGroup } }
  }

  if (query) {
    return { ...base, query, filter: emptySearchRequest.filter }
  }

  return emptySearchRequest
}

import { NumberFormatProps } from 'react-number-format'

import { NumberFormattedInput } from 'components/material/Form'

import HbTextInput, { HbTextInputProps } from '../HbTextInput/HbTextInput'

// Common number input props
export const naturalNumbers = {
  allowNegative: false,
  decimalScale: 0,
  thousandSeparator: true,
  allowLeadingZeros: false,
  thousandsGroupStyle: 'thousand' as const,
}

// Util to convert the input value to a number
export const convertToNumber = (value?: string) => {
  return value ? Number(value) : null
}

export type HbNumberInputProps = {
  numberProps: NumberFormatProps
} & HbTextInputProps

export function HbNumberInput(props: HbNumberInputProps) {
  const { numberProps, inputProps, InputProps, ...rest } = props

  return (
    <HbTextInput
      InputProps={{
        inputComponent: NumberFormattedInput,
        ...InputProps,
      }}
      inputProps={{ numberProps, ...inputProps }}
      {...rest}
    />
  )
}

export default HbNumberInput

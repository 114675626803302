import { ErrorOutline } from '@mui/icons-material'
import { FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

import { FormErrorMessages } from './formErrorMessages'

const useStyles = makeStyles((theme: Theme) => ({
  errorIcon: {
    marginRight: theme.spacing(),
    color: theme.palette.error.main,
    height: 15,
    width: 15,
  },
  root: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 0,
    visibility: 'hidden',
    height: 0,
  },
  error: {
    visibility: 'visible',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1),
    height: 'auto',
  },
  plainError: {
    display: 'flex',
    alignItems: 'center',
    margin: 0,
    height: 'auto',
  },
}))

const getMessage = (errors: string | string[]) => {
  let message = errors

  if (Array.isArray(errors) && !errors[0]) {
    return ''
  }

  if (Array.isArray(errors) && errors[0]) {
    message = errors.join(', ')
  }

  // just prettify the error message in this case
  if (message === 'REQUIRED') {
    return FormErrorMessages.Required
  }

  return message
}

export interface Props {
  isErroneous?: boolean
  clientError?: string | string[]
  apiError?: string | string[]
  errors?: string | string[]
  tag?: keyof JSX.IntrinsicElements
  testId?: string
  classes?: Partial<ReturnType<typeof useStyles>>
  fieldName?: string
}

const getCombinedMessage = (
  apiError?: string | string[],
  clientError?: string | string[],
  errors?: string | string[]
) => {
  if (errors) {
    return getMessage(errors)
  }
  const clientErrorMessage = clientError ? getMessage(clientError) : ''
  const apiErrorMessage = apiError ? getMessage(apiError) : ''

  if (apiErrorMessage && clientErrorMessage) {
    return `${apiErrorMessage}, ${clientErrorMessage}`
  }
  return clientErrorMessage || apiErrorMessage
}

const getErrorHelperTextId = (fieldName: string) => `form_helper_text_error_${fieldName}`

export const InputError = ({
  apiError,
  clientError,
  errors,
  isErroneous,
  tag: Component,
  testId,
  classes: overrideClasses,
  fieldName,
}: Props) => {
  const message = getCombinedMessage(apiError, clientError, errors)
  const classes = useStyles()

  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)

  if (Component) {
    return (
      <Component className={mergedClasses.plainError} data-testid={testId}>
        <ErrorOutline className={mergedClasses.errorIcon} />
        {message}
      </Component>
    )
  }

  return (
    <FormHelperText
      data-testid={testId}
      id={fieldName ? getErrorHelperTextId(fieldName) : undefined}
      classes={{
        root: mergedClasses.root,
        error: mergedClasses.error,
      }}
      error={Boolean(isErroneous && message)}
    >
      <ErrorOutline className={mergedClasses.errorIcon} />
      {message || 'Please provide a valid answer'}
    </FormHelperText>
  )
}

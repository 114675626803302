import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchFilingsResultFragment } from './searchFilings.fragments'

export const SEARCH_FILINGS_QUERY_V2 = gql`
  query SearchFilingsV2($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!, $dashboardType: String!) {
    searchFilingsV2(page: $page, pageSize: $pageSize, search: $appliedFilters, dashboardType: $dashboardType) {
      ...SearchFilingsResultFragment
    }
  }
  ${SearchFilingsResultFragment}
`

export const FILINGS_COLUMNS_QUERY_V2 = gql`
  query FilingsColumnsV2($includeCustomColumns: Boolean!) {
    searchFilingsMetadataV2(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const FILINGS_METADATA_QUERY_V2 = gql`
  query SearchFilingsMetadataV2($columns: [String!], $query: String) {
    searchFilingsMetadataV2(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

export const CTR_FILINGS_COLUMNS_QUERY = gql`
  query CtrFilingsColumns($includeCustomColumns: Boolean!) {
    searchCtrFilingsMetadata(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const CTR_FILINGS_METADATA_QUERY = gql`
  query SearchCtrFilingsMetadata($columns: [String!], $query: String) {
    searchCtrFilingsMetadata(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

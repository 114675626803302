import { Action } from 'redux'

type Filter<T, U> = T extends U ? T : never

export type ReducerObject<S, A extends Action<string>> = {
  [P in A['type']]: (state: S, action: Filter<A, { type: P }>) => S
}
export type Reducer<S, A extends Action<string>> = (state: S, action: A) => S

/* `createReducer` is a constructor for type-safe reducers. It enforces
 * all actions declared as an Action type to be implemented. It also
 * enforces that the action type recieved by the implementation is
 * correct for that action key.
 *
 * An example:
 * ```typescript
 * type State = { counter: number }
 * type IncrementAction = { type: 'INCREMENT' }
 * type DecrementAction = { type: 'DECREMENT' }
 * type CounterAction = IncrementAction | DecrementAction
 *
 * const initialState = { counter: 0 }
 * const reducer: Reducer<State, CounterAction> = createReducer(
 *   {
 *     INCREMENT({ counter }, action: IncrementAction) {
 *       return { counter: counter + 1 }
 *     },
 *     DECREMENT({ counter }, action: DecrementAction) {
 *       return { counter: counter - 1 }
 *     }
 *   },
 *   initialState,
 * )
 * ```
 */
export function createReducer<S, A extends Action<string>>(
  obj: ReducerObject<S, A>,
  getInitialState: () => S
): Reducer<S, A | Action<string>> {
  return (state = getInitialState(), action) => {
    if ((obj as any)[action.type]) {
      return (obj as any)[action.type](state, action)
    }

    return state
  }
}

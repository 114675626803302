import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const GoogleIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path d="M10.495 0c-2.65 0-5.193 1.05-7.067 2.92A9.956 9.956 0 0 0 .5 9.97a9.958 9.958 0 0 0 2.909 7.085A10.006 10.006 0 0 0 10.495 20a10.028 10.028 0 0 0 7.093-2.941 9.978 9.978 0 0 0 2.912-7.09 9.967 9.967 0 0 0-2.934-7.05A10.018 10.018 0 0 0 10.495 0Zm3.469 13.768a4.804 4.804 0 0 1-3.335 1.211 5.024 5.024 0 0 1-3.549-1.468 5 5 0 0 1 .004-7.077 5.02 5.02 0 0 1 3.545-1.465 4.865 4.865 0 0 1 3.366 1.294L12.553 7.68a2.676 2.676 0 0 0-1.925-.75 3.01 3.01 0 0 0-2.82 2.054 3.073 3.073 0 0 0 0 1.92 3 3 0 0 0 2.81 2.053 2.97 2.97 0 0 0 1.708-.483 2.292 2.292 0 0 0 1.03-1.52h-2.718V9.067h4.735c.062.338.093.682.092 1.026a4.855 4.855 0 0 1-1.502 3.686v-.01Z" />
  </SvgIcon>
)

import { createReducer } from '@reduxjs/toolkit'

import { narrativeAIActions as actions } from 'reducers/narrativeAI/narrativeAI.actions'
import { NarrativeAIState } from 'reducers/narrativeAI/narrativeAI.types'

export const initialState: NarrativeAIState = {
  generations: {},
}

export const makeNarrativeAIReducer = (customState?: typeof initialState) =>
  createReducer<NarrativeAIState>(customState ?? initialState, (builder) => {
    builder
      .addCase(actions.generation.start.fulfilled, (state, action) => {
        state.generations[action.meta.arg.reviewToken] = action.payload
      })
      .addCase(actions.generation.update.fulfilled, (state, action) => {
        const generation = state.generations[action.meta.arg.reviewToken]
        if (generation) generation.job = action.payload
      })
      .addCase(actions.generation.stopPolling.fulfilled, (state, action) => {
        const generation = state.generations[action.meta.arg.reviewToken]
        if (generation) generation.timeoutId = undefined
      })
      .addCase(actions.generation.remove.fulfilled, (state, action) => {
        const { reviewToken } = action.meta.arg
        delete state.generations[reviewToken]
      })
  })

export const narrativeAIReducer = makeNarrativeAIReducer()

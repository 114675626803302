import { AnyAction } from 'redux'

import { ErrorType, SET_DOCUMENT_ERROR, SET_DOCUMENT_URL } from 'actions/generateDocActions'

export type State = StatePending | StateReady | StateError

/** `PENDING` is the initial state of document generation. Nothing is available yet. */
export interface StatePending {
  status: 'PENDING'
  downloadURL: undefined
  error: undefined
}

/** `READY` means we have successfully generated the document and it is ready for download. */
export interface StateReady {
  status: 'READY'
  downloadURL: string
  error: undefined
}

/** `ERROR` contains the error that occurred during the document generation process. */
export interface StateError {
  status: 'ERROR'
  downloadURL: undefined
  error: DocError
}

export interface DocError {
  type: ErrorType
  messages: string[]
}

const getInitialState = (): StatePending => ({ status: 'PENDING', downloadURL: undefined, error: undefined })

const generateDocReducer = (state: State = getInitialState(), action: AnyAction): State => {
  switch (action.type) {
    case SET_DOCUMENT_ERROR: {
      return {
        status: 'ERROR',
        downloadURL: undefined,
        error: action.error,
      }
    }
    case SET_DOCUMENT_URL: {
      return {
        status: 'READY',
        downloadURL: action.downloadURL,
        error: undefined,
      }
    }
    default: {
      return state
    }
  }
}

export default generateDocReducer

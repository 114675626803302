import { useRef, useState } from 'react'

import { AddRounded } from '@mui/icons-material'
import { Backdrop, ButtonBase, darken, Popover, Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { startCase } from 'lodash'

import { dataTypeOf } from 'actions/importingFields'
import { SnakeDataTypeKey, toDataTypeKey } from 'actions/importingFields.types'
import { HbButton } from 'components/HbComponents/HbButton'
import { HEADER_ZINDEX } from 'components/themeRedesign'
import { opacify } from 'helpers/colors'
import { AddNewIcon } from 'icons/AddNewIcon'
import DataIcon from 'icons/DataIcon'

import { ImportableType } from 'reducers/applicationReducer'
import { Theme } from 'types/hb'

import { CloseButton } from './CloseButton'

interface ItemProps {
  dataType: SnakeDataTypeKey
  onClick: () => void
  className?: string
  noColor?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    minWidth: 292,

    padding: '5px 8px',
    paddingTop: 0,
    paddingBottom: theme.spacing(2),
    borderRadius: 24,
    background: theme.palette.background.light,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(),
    padding: theme.hbUnit() + 2, // title icon is 4px smaller, align with the others
  },
  icon: {
    width: 14,
    marginRight: theme.spacing(1.5),
  },
  backdrop: {
    backgroundColor: 'rgba(0,0,0,0.15)',
    zIndex: HEADER_ZINDEX + 1,
  },
  text: {
    flex: 1,
  },
}))

const useItemStyles = makeStyles((theme: Theme) => ({
  item: (props: ItemProps) => {
    const color = props.noColor
      ? theme.palette.background.dark
      : theme.palette.dataTypes[toDataTypeKey(props.dataType)] ?? theme.palette.background.dark
    return {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      color: theme.palette.text.medium,
      borderRadius: 8,
      padding: theme.spacing(),

      '&:hover': {
        backgroundColor: opacify(color, 0.06),
      },
      '&:hover $text': {
        color: darken(color, 0.3),
      },
    }
  },
  text: (_props) => ({}), // Need to have this consume props too due to a material-ui bug
  icon: {
    width: 18,
    marginRight: theme.spacing(1.5),
  },
}))

export function EntityTypeItem(props: ItemProps) {
  const { dataType, onClick, className } = props
  const { item, text, icon } = useItemStyles(props)

  const info = dataTypeOf(dataType)
  if (!info) {
    return null
  }

  return (
    <ButtonBase data-testid={`button_add_${dataType}`} className={classnames(item, className)} onClick={onClick}>
      <DataIcon className={icon} dataType={dataType} />
      <Typography className={text} variant="body2" noWrap>
        {startCase(info.name)}
      </Typography>
    </ButtonBase>
  )
}

interface Props {
  dataTypes: SnakeDataTypeKey[]
  add: (dataType: SnakeDataTypeKey) => void
  open: boolean
  anchorEl: Element | null
  close: () => void
}

export function AddNewMenu(props: Props) {
  const { dataTypes, add, open, anchorEl, close } = props
  const { title, icon, paper, backdrop, text } = useStyles()

  return (
    <Backdrop className={backdrop} open={open} onClick={close}>
      <Popover classes={{ paper }} open={open} anchorEl={anchorEl} onClose={close}>
        <div className={title}>
          <AddNewIcon className={icon} />
          <Typography className={text} variant="body2" noWrap>
            Add new
          </Typography>
          <CloseButton close={close} />
        </div>
        {dataTypes.map((dataType) => (
          <EntityTypeItem key={dataType} dataType={dataType} onClick={() => add(dataType as ImportableType)} />
        ))}
      </Popover>
    </Backdrop>
  )
}

interface ButtonProps {
  dataTypes: SnakeDataTypeKey[]
  add: (dataType?: SnakeDataTypeKey) => void
  disabled?: boolean
}

export function AddNewMenuButton({ dataTypes, add, disabled }: ButtonProps) {
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement | null>(null)

  return (
    <>
      <HbButton ref={anchorRef} label="Add new" Icon={AddRounded} onClick={() => setOpen(!open)} disabled={disabled} />
      <AddNewMenu
        anchorEl={anchorRef.current}
        open={open}
        close={() => setOpen(false)}
        dataTypes={dataTypes}
        add={add}
      />
    </>
  )
}

import { State } from 'actions/store'
import { NarrativeGenerationJob, ReviewTokenParam } from 'reducers/narrativeAI/narrativeAI.types'
import { simpleSelector } from 'reducers/utils'

const makeGenerationJobSelector =
  <T extends keyof NarrativeGenerationJob>(field: T) =>
  ({ reviewToken }: ReviewTokenParam) =>
  (state: State) =>
    state.narrativeAI.generations[reviewToken]?.job?.[field] ?? undefined

export const narrativeAISelectors = {
  generation: {
    job: {
      isPolling: ({ reviewToken }: ReviewTokenParam) =>
        simpleSelector((state) => !!state.narrativeAI.generations[reviewToken]?.timeoutId),
      timeoutId: ({ reviewToken }: ReviewTokenParam) =>
        simpleSelector((state) => state.narrativeAI.generations[reviewToken]?.timeoutId),
      status: makeGenerationJobSelector('status'),
      startTime: makeGenerationJobSelector('enqueuedAt'),
      narrative: makeGenerationJobSelector('generatedNarrative'),
      error: makeGenerationJobSelector('errorMessage'),
    },
  },
}

import { useHistory } from 'react-router-dom'

import { HbButton } from 'components/HbComponents/HbButton'

import { CREATE_AUTOMATION_RULE_TEMPLATE } from 'components/pages/automations/AutomationRuleTemplate/AutomationRuleTemplate.queries'
import {
  CreateAutomationTemplateMutation,
  CreateAutomationTemplateMutationVariables,
} from 'components/pages/automations/AutomationRuleTemplate/__generated__/AutomationRuleTemplate.queries.generated'
import { useHbMutation } from 'hooks/ApolloHelpers'

export const NewTemplateButton = () => {
  const history = useHistory()

  const [createTemplate, { loading }] = useHbMutation<
    CreateAutomationTemplateMutation,
    CreateAutomationTemplateMutationVariables
  >(CREATE_AUTOMATION_RULE_TEMPLATE)

  const handleClick = async () => {
    const { data } = await createTemplate({ variables: { input: {} } })
    history.push(`/automations/recipes/${data?.createAutomationTemplate?.automationTemplate?.token}`)
  }
  return <HbButton label="Create Recipe Template" variant="secondary" onClick={handleClick} loading={loading} />
}

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

const useClasses = makeStyles((theme: Theme) => ({
  seperator: {
    width: 1,
    height: 15,
    margin: theme.spacing(0, 0.5),
    background: theme.palette.dividers.medium,
  },
}))

export const Seperator = () => {
  const classes = useClasses()
  return <div role="separator" className={classes.seperator} />
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const TechnologyIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 22 16">
    <path d="M20 14H0a2 2 0 0 0 2 2h18a2 2 0 0 0 2-2zM4 13h16V1a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v12h2zM4 2h14v9H4z" />
  </SvgIcon>
)

export default TechnologyIcon

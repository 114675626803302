import { isEmpty } from 'lodash'

import { setLoading } from 'actions/viewActions'

import { getOrganizationToken } from 'helpers/stateHelpers'
import { Template, TemplateError, TemplateSaveResult } from 'reducers/narrativeTemplatesReducer'

import { Thunk, AsyncThunk } from './store'

interface Response {
  templates: Template[]
  success: boolean
  error: Error
}

interface Error {
  message: string
  error: TemplateError
}

export const SET_TEMPLATES = 'SET_TEMPLATES'
export const SET_ACTIVE_TEMPLATE = 'SET_ACTIVE_TEMPLATE'
export const SET_TEMPLATES_ERROR = 'SET_TEMPLATES_ERROR'

export function setTemplates(templates: Template[]) {
  return { type: SET_TEMPLATES, templates, error: '' }
}

export function setTemplatesError(error: string) {
  return { type: SET_TEMPLATES_ERROR, error }
}

export function setActiveTemplate(template: Template | null) {
  return { type: SET_ACTIVE_TEMPLATE, template }
}

export function fetchTemplates(): AsyncThunk<Template[]> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'narrativeTemplates'))

    const json: Response = await api.get('apiOrganizationNarrativeTemplatesPath', {
      urlParams: {
        organizationToken: getOrganizationToken(getState()),
      },
    })

    dispatch(setLoading(false, 'narrativeTemplates'))

    if (json.success) {
      dispatch(setTemplates(json.templates))
      return json.templates
    }
    // TODO(jr): Figure out error condition
    return null
  }
}

export function saveTemplate(template: Template): AsyncThunk<TemplateSaveResult> {
  return async (dispatch, getState, { api }) => {
    if (isEmpty(template)) {
      return null
    }

    dispatch(setLoading(true, 'narrativeTemplates'))

    const json: Response = await api.post('upsertApiOrganizationNarrativeTemplatesPath', {
      data: { template },
      urlParams: {
        organizationToken: getOrganizationToken(getState()),
      },
    })

    dispatch(setLoading(false, 'narrativeTemplates'))

    if (json.success) {
      dispatch(setTemplates(json.templates))
      return { templates: json.templates }
    }

    dispatch(setTemplatesError(json.error.message))
    return { error: json.error.message }
  }
}

export function updateTemplate(updates: Partial<Template>): Thunk<void> {
  return (dispatch, getState) => {
    const activeTemplate = getState().narrativeTemplates.activeTemplate || {}
    const updatedTemplate = { ...activeTemplate, ...updates } as Template

    dispatch(setActiveTemplate(updatedTemplate))
  }
}

export function deleteTemplate(template: Template): AsyncThunk<Template[]> {
  return async (dispatch, getState, { api }) => {
    if (isEmpty(template)) {
      return Promise.resolve(null)
    }
    const { activeTemplate } = getState().narrativeTemplates
    dispatch(setLoading(true, 'narrativeTemplates'))
    if (activeTemplate?.token === template.token) {
      dispatch(setActiveTemplate(null))
    }
    const json: Response = await api.delete('apiOrganizationNarrativeTemplatePath', {
      urlParams: {
        organizationToken: getOrganizationToken(getState()),
        token: template.token as string,
      },
    })

    dispatch(setLoading(false, 'narrativeTemplates'))

    if (json.success) {
      dispatch(setTemplates(json.templates))
      return json.templates
    }
    // TODO(jr): Figure out error condition
    return null
  }
}

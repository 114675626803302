import { gql } from '@apollo/client'

import { CUSTOM_FIELD_ENTRY_FRAGMENT } from 'components/otherInfo/queries'

export const TransactionFragment = gql`
  fragment TransactionFragment on Transaction {
    alertExternalIds
    alertRules
    reviewInternalControlNumbers
    token
    updatedAt
    amount {
      amount
      formatted
      currency
      isoCurrency
    }
    amountLocal {
      amount
      formatted
      currency
      isoCurrency
    }
    completedAt {
      date
      localize
      string
      time
      timestamp
    }
    createdAt
    description
    endpoints {
      description
      endpointType
      entityName
      entity {
        ... on LibraryObject {
          token
          displayName
        }
      }
      account {
        ... on LibraryObject {
          token
          displayName
        }
      }
      name
      summary
      token
      amount {
        amount
        formatted
        currency
        isoCurrency
      }
    }
    direction
    events {
      externalId
      description
      occurredAt {
        date
        localize
        string
        time
        timestamp
      }
    }
    externalId
    flaggedAt
    import {
      token
    }
    initiatedAt {
      date
      localize
      string
      time
      timestamp
    }
    instrumentType
    instrumentTypeDescription
    iso18245MerchantCategoryCode
    iso8583MessageType
    iso8583MessageTypeDescription
    notes
    currencyIssuingCountryCode
    fincenCtrTransactionType
    fincenCtrTransactionTypeOther
    otherInfo {
      ...CustomFieldEntryRow
    }
    responseCode
    status
    tags
    timestamp {
      date
      localize
      string
      time
      timestamp
    }
  }
  ${CUSTOM_FIELD_ENTRY_FRAGMENT}
`
export const SearchTransactionsResultFragment = gql`
  fragment SearchTransactionsResultFragment on SearchTransactionsResult {
    entries {
      ...TransactionFragment
    }
    page
    pageSize
    pageCount
    totalCount
  }
  ${TransactionFragment}
`

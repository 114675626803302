// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

export const useThinControlStyles = makeStyles((theme: Theme) => ({
  control: {
    height: theme.spacing(5),
  },
  selectRoot: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  textFieldRoot: {
    // 🤮
    '& > .MuiInputBase-root': {
      height: theme.spacing(5),
    },
  },
  numberFormatRoot: {
    margin: 0,
    '& > div': { marginTop: 0 },
  },
}))

/* eslint-disable camelcase */
import { ComponentProps, ComponentType, useCallback, useEffect, useMemo, useState } from 'react'

import { gql, useLazyQuery, useQuery } from '@apollo/client'

import { WorkOutline, Inbox as CaseIcon, DescriptionOutlined } from '@mui/icons-material'
import StorageIcon from '@mui/icons-material/Storage'
import { MenuItem, Select, TextField, FormControl, FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { DatePicker } from '@mui/x-date-pickers'

import classNames from 'classnames'
import { compact, startCase } from 'lodash'

import moment, { Moment } from 'moment'
import { type ControllerRenderProps, type ControllerFieldState } from 'react-hook-form'
import invariant from 'tiny-invariant'

import { type State, useSelector } from 'actions/store'
import Autocomplete, {
  Option,
  BaseAutocomplete,
  AutocompleteProps,
} from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { useQueues } from 'components/cases/reviews/queues/useQueues'
import OtherInfoLabelInput from 'components/library/OtherInfoLabelInput'
import { MoneyInputField } from 'components/material/Form/MoneyWithCurrencyPickerField'
import { useIsAutomationRule } from 'components/pages/automations/editor/AutomationRuleOrTemplateContext'
import { useGetHeaderFilterValuesV2 } from 'dashboards/shared/hooks/useGetDashboardTableHeaderFilterValuesV2'
import {
  getOrganizationFeatureFlag,
  getCountries,
  getOrganizationReviewTypes,
  getEnum,
  getOrganizationTeammates,
} from 'helpers/stateHelpers'

import { useFeatureFlag } from 'hooks'
import { useOrganizationBadges } from 'hooks/gql/useOrganizationBadges'
import ForwardToInboxOutlinedIcon from 'icons/ForwardToInboxOutlinedIcon'
import { MiddeskIcon } from 'icons/Logos/MiddeskIcon'
import PersonIcon from 'icons/PersonIcon'
import {
  FeatureFlag,
  AutomationDomainType,
  FilingStateEnum,
  LibraryGenderEnum,
  ReviewStatesEnum,
  PersonAddressTypeEnum,
  BusinessAddressTypeEnum,
  TinTypeEnum,
  PhoneNumberEnum,
  IdentityDocumentTypeEnum,
  MiddeskBusinessStatus,
  OtherInfoLabelTypeEnum,
  InvestigationReviewApprovalStatusEnum,
  DatasourceColumnEnum,
} from 'types/api'
import { Theme, assertUnreachable } from 'types/hb'

import { GetOrganizationTagsDocument } from 'utils/__generated__/GetOrganizationTagsQuery.generated'

import { assertExhaustive } from 'utils/typeAssertions'

import {
  AutomationsReviewDecisionsQuery,
  AutomationsReviewDecisionsQueryVariables,
} from './__generated__/fieldConfig.generated'
import {
  TriggerFilterEditorDataSourcesQuery,
  TriggerFilterEditorDataSourcesQueryVariables,
} from './__generated__/queries.generated'
import { TriggerFilterEditorDataSources } from './queries'
import { useThinControlStyles } from './styles'

import type { Operator, TypeCastType, BinaryExpression } from 'types/automations'

interface EditProps {
  className?: string
  inputProps: ControllerRenderProps
  operator: BinaryExpression['operator']
  label: string
  fieldState: ControllerFieldState
}

export type AutomationDomain = {
  type: AutomationDomainType
  datasourceToken: string | null
}

export type LabeledAutomationDomain = AutomationDomain & { label: string }

const TEMPLATE_PLACEHOLDER = 'Placeholder for Hummingbird Template'

export const getLabelForDomainType = (domainType: AutomationDomainType) => {
  switch (domainType) {
    case AutomationDomainType.Filing:
      return 'FinCEN Filing'
    default:
      return startCase(domainType)
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  autocomplete: {
    minWidth: theme.spacing(30),
    width: '100%',
    height: 'auto',
  },
  autocompleteInner: {
    paddingTop: 0,
    minHeight: theme.spacing(5),
  },
  autocompleteTag: {
    '&[role=button]': {
      background: theme.palette.styleguide.backgroundDark,
      color: theme.palette.styleguide.plumDark,
    },
  },
  otherInfoLabelInputContainer: {
    width: '100%',
  },
}))

export const operatorLabels: Record<Operator, string> = {
  equal: 'Is',
  not_equal: 'Is Not',
  contains: 'Contains',
  not_contains: 'Does Not Contain',
  in: 'Is',
  not_in: 'Is Not',
  contained_in: 'Contains',
  not_contained_in: 'Does Not Contain',
  greater_than: 'Is Greater Than',
  greater_than_equal_to: 'Is Greater Than Or Equal To',
  less_than: 'Is Less Than',
  less_than_equal_to: 'Is Less Than Or Equal To',
  any: 'Any',
  all: 'All',
  none: 'None',
  or: 'Or',
  and: 'And',
  exists: 'Exists',
  not_exists: 'Does Not Exist',
}

const listOperators: Array<Operator> = ['in', 'not_in', 'contained_in', 'not_contained_in']

export function isExistenceOperator(op: string): boolean {
  return ['exists', 'not_exists'].includes(op)
}

export function isListOperator(op: string): boolean {
  return (listOperators as Array<string>).includes(op)
}

export function isSingleOperator(op: string): boolean {
  return !isListOperator(op) && !isExistenceOperator(op)
}

function MultiTextField({
  className,
  inputProps,
  operator,
  fieldState,
  ...props
}: EditProps & Omit<AutocompleteProps<string>, 'onChange' | 'multiple' | 'options' | 'value'>) {
  const classes = useStyles()
  const { onChange, ref, ...restInputProps } = inputProps

  const allProps = {
    className: classNames(classes.autocompleteInner, className),
    hideLabel: true,
    noSpacing: true,
    ...props,
    ...restInputProps,
  }

  const handleChange = (value: string[]) => {
    onChange({ target: { value } })
  }

  return (
    <div className={classes.autocomplete}>
      <BaseAutocomplete
        clearOnBlur
        limitTags={2}
        multiple
        setValueOnBlur
        onChange={handleChange}
        options={[]}
        ref={ref}
        {...allProps}
        freeSolo
      />
      <FormHelperText error>{fieldState.error?.message}</FormHelperText>
    </div>
  )
}

function AutocompleteEdit({
  className,
  inputProps,
  operator,
  fieldState,
  options: originalOpts,
  ...props
}: EditProps & Omit<AutocompleteProps<Option>, 'onChange' | 'multiple'>) {
  const classes = useStyles()

  const { ref, onChange, ...restInputProps } = inputProps

  const isRule = useIsAutomationRule()
  const handleChangeMultiple = (options: Option[]) => {
    onChange({ target: { value: options.map((o) => o.value) } })
  }

  const validOpts = !isRule ? [{ display: TEMPLATE_PLACEHOLDER, value: 'disabled' }] : originalOpts

  const { value, ...restProps } = {
    className: classNames(classes.autocompleteInner, className),
    classes: { tag: classes.autocompleteTag },
    hideLabel: true,
    noSpacing: true,
    limitTags: 2,
    innerRef: ref,
    errorMessage: fieldState.error?.message,
    options: validOpts,
    ...props,
    ...restInputProps,
  }

  return (
    <div className={classes.autocomplete}>
      <Autocomplete
        onChange={handleChangeMultiple}
        multiple
        disableCloseOnSelect
        {...restProps}
        value={[].concat(value)}
      />
    </div>
  )
}

function GenderValueEdit({ className, ...props }: EditProps) {
  const options = Object.entries(LibraryGenderEnum).map(([key, value]) => ({ display: startCase(key), value }))

  return <AutocompleteEdit options={options} {...props} />
}

function TrueFalseValueEdit({
  className,
  label,
  fieldState,
  inputProps: { onChange, ...inputProps },
  ...props
}: EditProps & ComponentProps<typeof Select>) {
  const classes = useThinControlStyles()

  const handleChange: Exclude<ComponentProps<typeof Select>['inputProps'], undefined>['onChange'] = (e) => {
    invariant('value' in e.target)

    if (e.target.value === 'true') {
      onChange(true)
    } else if (e.target.value === 'false') {
      onChange(false)
    } else {
      onChange(e.target.value)
    }
  }

  return (
    <FormControl error={fieldState.invalid}>
      <Select
        required
        className={classNames(classes.control, classes.selectRoot, className)}
        {...props}
        inputProps={{ ...inputProps, onChange: handleChange }}
        variant="outlined"
      >
        <MenuItem value="true">True</MenuItem>
        <MenuItem value="false">False</MenuItem>
      </Select>
      <FormHelperText>{fieldState.error?.message}</FormHelperText>
    </FormControl>
  )
}

function TextValueEdit({ className, operator, label, fieldState, ...props }: EditProps) {
  const classes = useThinControlStyles()
  const multiple = isListOperator(operator)

  if (multiple) {
    return <MultiTextField label={label} operator={operator} fieldState={fieldState} {...props} />
  }

  return (
    <TextField
      required
      error={fieldState.invalid}
      helperText={fieldState.invalid ? fieldState.error?.message : undefined}
      className={classNames(classes.control, className)}
      classes={{ root: classes.textFieldRoot }}
      variant="outlined"
      {...props}
    />
  )
}

function NumberValueEdit({
  className,
  label,
  inputProps: { onChange, ...inputProps },
  fieldState,
  ...props
}: EditProps) {
  const classes = useThinControlStyles()

  const handleChange: Exclude<ComponentProps<typeof TextField>['inputProps'], undefined>['onChange'] = (e) => {
    invariant('value' in e.target && typeof e.target.value === 'string')
    onChange(parseInt(e.target.value, 10))
  }

  return (
    <TextField
      required
      className={classNames(classes.control, className)}
      classes={{ root: classes.textFieldRoot }}
      type="number"
      variant="outlined"
      {...props}
      inputProps={{ ...inputProps, onChange: handleChange }}
      error={fieldState.invalid}
      helperText={fieldState.error?.message}
    />
  )
}
function MoneyEdit({ className, label, inputProps, fieldState, ...props }: EditProps) {
  const options = useSelector((state) => getEnum(state, 'fiatCurrencyInputCode'))
  const classes = useThinControlStyles()

  const { onChange, value } = inputProps

  const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value as string
    onChange({ target: { value: { amount: newValue, currency: value?.currency } } })
  }

  const handleChangeCurrency = (option: Option) => {
    onChange({ target: { value: { amount: value?.amount, currency: option.value } } })
  }

  // This is incorrectly typed from react hook form's end
  const errorObject = fieldState.error as { amount?: { message: string }; currency?: { message: string } } | undefined

  return (
    <>
      <MoneyInputField
        required
        className={classNames(classes.control, className)}
        error={!!errorObject?.amount?.message}
        helperText={errorObject?.amount?.message}
        size="small"
        variant="outlined"
        value={value?.amount}
        onChange={handleChangeAmount}
        {...props}
      />
      <Autocomplete
        label={label}
        onChange={handleChangeCurrency}
        hideLabel
        noSpacing
        errorMessage={errorObject?.currency?.message}
        value={value?.currency}
        multiple={false}
        options={options}
      />
    </>
  )
}

const DateEdit = ({ inputProps }: EditProps) => {
  const classes = useThinControlStyles()
  const { onChange, value } = inputProps

  // note: months are zero based from moments but not for our date parts so it requires some manipulation
  // of the trigger to read correctly on the frontend and backend
  const handleDateChange = (d: Moment) => {
    onChange({ target: { value: { month: d.month() + 1, day: d.date(), year: d.year() } } })
  }

  const getDate = () => {
    return {
      day: value.day,
      month: value.month - 1,
      year: value.year,
    }
  }

  return (
    <DatePicker<Moment>
      value={moment(getDate())}
      onChange={handleDateChange}
      format="MM/DD/YYYY"
      className={classes.textFieldRoot}
      slotProps={{
        textField: {
          fullWidth: true,
          variant: 'outlined',
        },
      }}
    />
  )
}

function CountryEdit(props: EditProps) {
  const countries = useSelector(getCountries)

  const options = Object.entries(countries).map(([key, value]) => ({ display: value.name, value: key }))

  return <AutocompleteEdit options={options} {...props} />
}

function ReviewTypeEdit(props: EditProps) {
  const options = useSelector(getOrganizationReviewTypes).map((type) => ({ display: type.name, value: type.name }))

  return <AutocompleteEdit options={options} {...props} />
}

function ReviewStageEdit(props: EditProps) {
  const { getHeaderFilterValues } = useGetHeaderFilterValuesV2('stage')
  const createValues = useMemo(getHeaderFilterValues, [getHeaderFilterValues])
  const options = useMemo(() => createValues?.() ?? [], [createValues])

  return <AutocompleteEdit options={options} {...props} />
}

function ReviewAssigneeEdit(props: EditProps) {
  const accounts = useSelector(getOrganizationTeammates).map((account) => ({
    display: account.fullName,
    value: account.token,
  }))
  return <AutocompleteEdit options={accounts} {...props} />
}

function QueueAssigneeEdit(props: EditProps) {
  const { queues } = useQueues()
  const options = queues.map((queue) => ({
    display: queue.name,
    value: queue.token,
  }))
  return <AutocompleteEdit options={options} {...props} />
}

function BadgesAssigneeEdit(props: EditProps) {
  const { badges } = useOrganizationBadges()
  const options = badges.map((badge) => ({
    display: badge.displayName,
    value: badge.token,
  }))
  return <AutocompleteEdit options={options} {...props} />
}

// TODO(ali): better model these option fields without using a new component for each field
function ReviewStateEdit(props: EditProps) {
  const options = Object.entries({ ...ReviewStatesEnum, ...InvestigationReviewApprovalStatusEnum })
    .filter(([_, value]) => value !== ReviewStatesEnum.Created)
    .map(([key, value]) => ({ display: startCase(key), value }))

  return <AutocompleteEdit options={options} {...props} />
}

function FilingStateEdit(props: EditProps) {
  const options = Object.entries(FilingStateEnum).map(([key, value]) => ({ display: startCase(key), value }))

  return <AutocompleteEdit options={options} {...props} />
}

function AddressTypeEdit(props: EditProps) {
  const options = Object.entries({ ...PersonAddressTypeEnum, ...BusinessAddressTypeEnum }).map(([key, value]) => ({
    display: startCase(key),
    value,
  }))

  return <AutocompleteEdit options={options} {...props} />
}

const ORGANIZATION_REVIEW_DECISIONS = gql`
  query AutomationsReviewDecisions($labelFilter: String) {
    organizationReviewDecisions(labelFilter: $labelFilter) {
      label
    }
  }
`

function DecisionMadeEdit({
  className,
  inputProps,
  ...props
}: EditProps & Omit<AutocompleteProps<string>, 'options' | 'value' | 'onChange'>) {
  const classes = useStyles()
  const isRule = useIsAutomationRule()
  const [searchValue, setSearchValue] = useState<string>('')
  const { onChange, ref, value, ...restInputProps } = inputProps
  const allProps = {
    className: classNames(classes.autocompleteInner, className),
    hideLabel: true,
    noSpacing: true,
    ...props,
    ...restInputProps,
  }

  const [options, setOptions] = useState<string[]>([])
  const [searchQuery] = useLazyQuery<AutomationsReviewDecisionsQuery, AutomationsReviewDecisionsQueryVariables>(
    ORGANIZATION_REVIEW_DECISIONS
  )

  const handleSearch = useCallback(
    async (query: string) => {
      if (isRule) {
        const { data } = await searchQuery({ variables: { labelFilter: query } })
        setOptions(data?.organizationReviewDecisions?.map((result) => result.label) || [])
      } else {
        setOptions([TEMPLATE_PLACEHOLDER])
      }
    },
    [searchQuery, isRule]
  )

  useEffect(() => {
    handleSearch(searchValue)
  }, [searchValue, handleSearch])

  // Makes use of a hummingbird specific template string that we can use for templates and filter out for rules
  const currentValue = value.filter((v: string) => (isRule && v !== TEMPLATE_PLACEHOLDER) || !isRule)

  return (
    <div className={classNames(classes.autocomplete)}>
      <BaseAutocomplete
        onInputChange={setSearchValue}
        value={currentValue}
        options={options}
        setValueOnBlur
        freeSolo
        multiple
        {...allProps}
        onChange={onChange}
        ref={ref}
        placeholder="Type to search values"
      />
    </div>
  )
}

function TagEdit(props: EditProps) {
  const { data: tagData } = useQuery(GetOrganizationTagsDocument, {
    fetchPolicy: 'cache-first',
  })

  const tags = tagData?.currentOrganization.tagDefinitions
  const options = tags
    ? Object.values(tags).map((value) => ({ display: startCase(value.label), value: value.token }))
    : []

  return tagData ? <AutocompleteEdit options={options} {...props} /> : null
}

function TinTypeEdit(props: EditProps) {
  const options = Object.entries(TinTypeEnum).map(([key, value]) => ({
    display: startCase(key),
    value,
  }))

  return <AutocompleteEdit options={options} {...props} />
}

function PhoneNumberTypeEdit(props: EditProps) {
  const options = Object.entries(PhoneNumberEnum).map(([key, value]) => ({
    display: startCase(key),
    value,
  }))

  return <AutocompleteEdit options={options} {...props} />
}

function IdentityDocumentTypeEdit(props: EditProps) {
  const options = Object.entries(IdentityDocumentTypeEnum).map(([key, value]) => ({
    display: startCase(key),
    value,
  }))

  return <AutocompleteEdit options={options} {...props} />
}

function MiddeskBusinessStatusEdit(props: EditProps) {
  const options = Object.entries(MiddeskBusinessStatus).map(([key, value]) => ({ display: startCase(key), value }))

  return <AutocompleteEdit options={options} {...props} />
}

export type SingularFieldSpec = {
  type: 'field' | 'fieldList'
  cast?: TypeCastType
  label?: string
  Component: ComponentType<EditProps | (EditProps & (ComponentProps<typeof Select> | ComponentProps<typeof TextField>))>
  operators: Array<BinaryExpression['operator']>
}

type LinkedField = {
  type: 'linkedField'
  label?: string
  singular?: boolean
  domainType: AutomationDomainType
}

type NestedFieldSpec = {
  type: 'nested' | 'nestedList'
  label?: string
  values: Record<string, FieldSpec>
}

export type DomainFieldSpec = Omit<NestedFieldSpec, 'label'>

type NestedCustomFieldSpec = {
  type: 'nestedCustom'
  label?: string
  Component: ComponentType<{
    onChange: (value: string) => void
    value: string
    domain: AutomationDomain
  }>
  fieldSpec: FieldSpec
}

export type FieldSpec = SingularFieldSpec | NestedFieldSpec | LinkedField | NestedCustomFieldSpec

const textValueFieldSettings: Pick<SingularFieldSpec, 'type' | 'Component' | 'operators'> = {
  type: 'field',
  Component: TextValueEdit,
  operators: ['in', 'not_in', 'contained_in', 'not_contained_in', 'exists', 'not_exists'],
}

const ADDRESSES_FIELD: NestedFieldSpec = {
  type: 'nestedList',
  values: {
    country: {
      type: 'field',
      Component: CountryEdit,
      operators: ['in', 'not_in'],
    },
    locality: {
      label: 'City',
      ...textValueFieldSettings,
    },
    address_line_1: textValueFieldSettings,
    address_line_2: textValueFieldSettings,
    administrative_district_level_1: {
      label: 'State',
      ...textValueFieldSettings,
    },
    postal_code: textValueFieldSettings,
    address_type: {
      type: 'field',
      Component: AddressTypeEdit,
      operators: ['in', 'not_in'],
    },
    address_type_other: textValueFieldSettings,
  },
}

const TINS_FIELD: NestedFieldSpec = {
  type: 'nestedList',
  label: 'TINs',
  values: {
    type: {
      type: 'field',
      Component: TinTypeEdit,
      operators: ['in', 'not_in'],
    },
    number: textValueFieldSettings,
  },
}

const PHONE_NUMBERS_FIELD: NestedFieldSpec = {
  type: 'nestedList',
  values: {
    type: {
      type: 'field',
      Component: PhoneNumberTypeEdit,
      operators: ['in', 'not_in'],
    },
    number: textValueFieldSettings,
    extension: textValueFieldSettings,
  },
}

const DOMAIN_TYPE_OTHER_INFO_LABEL_MAPPING: Partial<Record<AutomationDomainType, OtherInfoLabelTypeEnum>> = {
  [AutomationDomainType.Person]: OtherInfoLabelTypeEnum.Person,
  [AutomationDomainType.Business]: OtherInfoLabelTypeEnum.Business,
  [AutomationDomainType.Review]: OtherInfoLabelTypeEnum.Review,
}

const createOtherInfoField = (isRenameOtherInfoEnabled: boolean): NestedCustomFieldSpec => ({
  type: 'nestedCustom',
  label: isRenameOtherInfoEnabled ? 'Custom Fields' : 'Other Info',
  Component: ({ onChange, value, domain }) => {
    const classes = useStyles()
    const isRule = useIsAutomationRule()

    const labelType = DOMAIN_TYPE_OTHER_INFO_LABEL_MAPPING[domain.type]
    invariant(
      labelType,
      `${isRenameOtherInfoEnabled ? 'Custom' : 'Other info'} field must be used with a supported domain type`
    )

    return (
      <div className={classNames(classes.otherInfoLabelInputContainer)}>
        <OtherInfoLabelInput
          label={isRenameOtherInfoEnabled ? 'Name' : 'Label'}
          type={labelType}
          onChange={onChange}
          value={value}
          noSpacing
          hideLabel
          freeSolo={!isRule}
          disableSearch={!isRule}
        />
      </div>
    )
  },
  fieldSpec: {
    label: 'Value',
    ...textValueFieldSettings,
  },
})

// TODO: remove when `RenameOtherInfo` feature flag is removed
// https://thecharm.atlassian.net/browse/PROD-18955
const OTHER_INFO_FIELD_OLD = createOtherInfoField(false)
const OTHER_INFO_FIELD_W_CUSTOM_FIELDS_RENAME = createOtherInfoField(true)

const getOtherInfoField = (isRenameOtherInfoEnabled: boolean) =>
  isRenameOtherInfoEnabled ? OTHER_INFO_FIELD_W_CUSTOM_FIELDS_RENAME : OTHER_INFO_FIELD_OLD

const IDENTITY_DOCUMENTS_FIELD: NestedFieldSpec = {
  type: 'nestedList',
  values: {
    type: {
      type: 'field',
      Component: IdentityDocumentTypeEdit,
      operators: ['in', 'not_in'],
    },
    type_other: textValueFieldSettings,
    number: textValueFieldSettings,
    issuing_country_code: {
      type: 'field',
      label: 'Issuing Country',
      Component: CountryEdit,
      operators: ['in', 'not_in'],
    },
    // TODO(ali): Use the enum to populate this field
    issuing_country_subdivision_code: {
      label: 'Issuing State',
      ...textValueFieldSettings,
    },
    expires_on: {
      type: 'field',
      label: 'Expiration Date',
      cast: 'date',
      Component: DateEdit,
      operators: ['greater_than_equal_to', 'less_than_equal_to', 'greater_than', 'less_than'],
    },
  },
}

/**
 * NOTE: Use the statically generated DOMAIN_TYPE_FIELD_MAPPINGS config below.
 * If this needs to be called directly in a render, make sure to memoize the resulting object as needed
 */
const makeDomainTypeFieldMappings = (
  isRenameOtherInfoEnabled: boolean
): Record<Exclude<AutomationDomainType, AutomationDomainType.Datasource>, DomainFieldSpec> => {
  return {
    [AutomationDomainType.Person]: {
      type: 'nested',
      values: {
        external_id: {
          label: 'ID',
          ...textValueFieldSettings,
        },
        name: {
          type: 'nested',
          values: {
            first_name: textValueFieldSettings,
            middle_name: textValueFieldSettings,
            last_name: textValueFieldSettings,
            suffix: textValueFieldSettings,
          },
        },
        gender: {
          type: 'field',
          Component: GenderValueEdit,
          operators: ['in', 'not_in'],
        },
        notes: textValueFieldSettings,
        tags: {
          type: 'fieldList',
          Component: TagEdit,
          operators: ['in', 'not_in'],
        },
        addresses: ADDRESSES_FIELD,
        tins: TINS_FIELD,
        phone_numbers: PHONE_NUMBERS_FIELD,
        other_info_hash: getOtherInfoField(isRenameOtherInfoEnabled),
        identity_documents: IDENTITY_DOCUMENTS_FIELD,
        websites: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        email_addresses: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        usernames: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        alternate_names: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        occupations: {
          type: 'nestedList',
          values: {
            company: textValueFieldSettings,
            industry: textValueFieldSettings,
            occupation: textValueFieldSettings,
            company_website: textValueFieldSettings,
            naics_code: textValueFieldSettings,
          },
        },
        birthdate: {
          type: 'field',
          cast: 'date',
          Component: DateEdit,
          operators: ['greater_than_equal_to', 'less_than_equal_to', 'greater_than', 'less_than'],
        },
        cases: {
          type: 'linkedField',
          label: 'Involved Cases',
          domainType: AutomationDomainType.Case,
        },
        reviews: {
          type: 'linkedField',
          label: 'Involved Reviews',
          domainType: AutomationDomainType.Review,
        },
      },
    },
    [AutomationDomainType.Business]: {
      type: 'nested',
      values: {
        external_id: {
          label: 'ID',
          ...textValueFieldSettings,
        },
        notes: textValueFieldSettings,
        tags: {
          type: 'fieldList',
          Component: TagEdit,
          operators: ['in', 'not_in'],
        },
        type: {
          type: 'nested',
          values: {
            label: textValueFieldSettings,
            naics_code: textValueFieldSettings,
          },
        },
        addresses: ADDRESSES_FIELD,
        tins: TINS_FIELD,
        phone_numbers: PHONE_NUMBERS_FIELD,
        other_info_hash: getOtherInfoField(isRenameOtherInfoEnabled),
        identity_documents: IDENTITY_DOCUMENTS_FIELD,
        websites: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        email_addresses: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        usernames: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        legal_names: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        dba_names: {
          ...textValueFieldSettings,
          type: 'fieldList',
        },
        cases: {
          type: 'linkedField',
          label: 'Involved Cases',
          domainType: AutomationDomainType.Case,
        },
        reviews: {
          type: 'linkedField',
          label: 'Involved Reviews',
          domainType: AutomationDomainType.Review,
        },
      },
    },
    [AutomationDomainType.Filing]: {
      type: 'nested',
      values: {
        status: {
          type: 'field',
          Component: FilingStateEdit,
          operators: ['in', 'not_in'],
        },
        business_days_stale: {
          type: 'field',
          Component: NumberValueEdit,
          operators: ['greater_than_equal_to'],
        },
        review: {
          type: 'linkedField',
          label: 'Review',
          singular: true,
          domainType: AutomationDomainType.Review,
        },
      },
    },
    [AutomationDomainType.Case]: {
      type: 'nested',
      values: {
        external_id: {
          label: 'ID',
          ...textValueFieldSettings,
        },
        recently_created: {
          type: 'field',
          Component: TrueFalseValueEdit,
          operators: ['equal', 'not_equal'],
        },
        sum_of_all_transactions: {
          type: 'field',
          cast: 'money',
          Component: MoneyEdit,
          operators: ['greater_than_equal_to', 'less_than_equal_to'],
        },
        sum_of_flagged_transactions: {
          type: 'field',
          cast: 'money',
          Component: MoneyEdit,
          operators: ['greater_than_equal_to', 'less_than_equal_to'],
        },
        people: {
          type: 'linkedField',
          label: 'Involved People',
          domainType: AutomationDomainType.Person,
        },
        businesses: {
          type: 'linkedField',
          label: 'Involved Businesses',
          domainType: AutomationDomainType.Business,
        },
        reviews: {
          type: 'linkedField',
          label: 'Reviews',
          domainType: AutomationDomainType.Review,
        },
      },
    },
    [AutomationDomainType.MiddeskBusiness]: {
      type: 'nested',
      values: {
        status: {
          type: 'field',
          Component: MiddeskBusinessStatusEdit,
          operators: ['in', 'not_in'],
        },
        was_in_audit: {
          type: 'field',
          Component: TrueFalseValueEdit,
          operators: ['equal', 'not_equal'],
        },
      },
    },
    [AutomationDomainType.Review]: {
      type: 'nested',
      values: {
        type: {
          type: 'field',
          Component: ReviewTypeEdit,
          operators: ['in', 'not_in'],
        },
        stage: {
          type: 'field',
          Component: ReviewStageEdit,
          operators: ['in', 'not_in'],
        },
        days_until_due_date: {
          type: 'field',
          Component: NumberValueEdit,
          operators: ['less_than_equal_to'],
        },
        days_past_due_date: {
          type: 'field',
          Component: NumberValueEdit,
          operators: ['greater_than_equal_to'],
        },
        days_since_completion_date: {
          type: 'field',
          Component: NumberValueEdit,
          operators: ['less_than_equal_to', 'greater_than_equal_to'],
        },
        days_since_creation_date: {
          type: 'field',
          Component: NumberValueEdit,
          operators: ['less_than_equal_to', 'greater_than_equal_to'],
        },
        cancelled: {
          type: 'field',
          Component: TrueFalseValueEdit,
          operators: ['equal', 'not_equal'],
        },
        status: {
          type: 'field',
          Component: ReviewStateEdit,
          operators: ['in', 'not_in'],
        },
        net_activity: {
          type: 'field',
          cast: 'money',
          Component: MoneyEdit,
          operators: ['greater_than_equal_to', 'less_than_equal_to'],
        },
        assignee: {
          type: 'nested',
          values: {
            token: {
              type: 'field',
              label: 'Account',
              Component: ReviewAssigneeEdit,
              operators: ['in', 'not_in', 'exists', 'not_exists'],
            },
            queue_token: {
              type: 'field',
              label: 'Queue',
              Component: QueueAssigneeEdit,
              operators: ['in', 'not_in', 'exists', 'not_exists'],
            },
            badge_tokens: {
              type: 'fieldList',
              label: 'Badges',
              Component: BadgesAssigneeEdit,
              operators: ['in', 'not_in'],
            },
          },
        },
        latest_action_decisions: {
          label: 'Decision Made',
          type: 'nestedList',
          values: {
            choice: {
              label: 'Choice',
              type: 'field',
              Component: DecisionMadeEdit,
              operators: ['in', 'not_in', 'contained_in', 'not_contained_in'],
            },
          },
        },
        case_alert_rules: {
          label: 'Alert Rule Triggers',
          type: 'nestedList',
          values: {
            rule: {
              label: 'Name',
              type: 'field',
              Component: TextValueEdit,
              operators: ['in', 'not_in', 'contained_in', 'not_contained_in'],
            },
            // TODO: disabling this for now due to ambiguity of what count can represent
            // in a conditional
            // count: {
            //   type: 'field',
            //   Component: NumberValueEdit,
            //   operators: ['less_than_equal_to', 'greater_than_equal_to'],
            // },
          },
        },
        case_alert_rule_count: {
          label: 'Total Alert Count',
          type: 'field',
          Component: NumberValueEdit,
          operators: ['less_than_equal_to', 'greater_than_equal_to'],
        },
        other_info_hash: getOtherInfoField(isRenameOtherInfoEnabled),
        filings: {
          type: 'linkedField',
          label: 'Filings',
          domainType: AutomationDomainType.Filing,
        },
        case: {
          type: 'linkedField',
          label: 'Case',
          singular: true,
          domainType: AutomationDomainType.Case,
        },
        people: {
          type: 'linkedField',
          label: 'Involved People',
          domainType: AutomationDomainType.Person,
        },
        businesses: {
          type: 'linkedField',
          label: 'Involved Businesses',
          domainType: AutomationDomainType.Business,
        },
      },
    },
  }
}

// TODO: remove when `RenameOtherInfo` feature flag is removed
// https://thecharm.atlassian.net/browse/PROD-18955
const DOMAIN_TYPE_FIELD_MAPPINGS_OLD = makeDomainTypeFieldMappings(false)
const DOMAIN_TYPE_FIELD_MAPPINGS_W_CUSTOM_FIELDS_RENAME = makeDomainTypeFieldMappings(true)

type UseDomainFieldSpecs = {
  baseDomainFieldSpecs: [AutomationDomain, DomainFieldSpec][]
  datasourceDomainFieldSpecs: [LabeledAutomationDomain, DomainFieldSpec][]
  getDomainFieldSpec(domain: AutomationDomain): DomainFieldSpec | undefined
  loading: boolean
}

function buildTriggerEditorColumnSpecs(columns: { name: string; type: DatasourceColumnEnum }[]) {
  const entries: ([string, FieldSpec] | undefined)[] = columns.map((col) => {
    switch (col.type) {
      case DatasourceColumnEnum.Integer:
        return [
          col.name,
          {
            type: 'field',
            Component: NumberValueEdit,
            operators: [
              'equal',
              'not_equal',
              'greater_than',
              'greater_than_equal_to',
              'less_than',
              'less_than_equal_to',
              'exists',
              'not_exists',
            ],
          },
        ]
      case DatasourceColumnEnum.Decimal:
        return [
          col.name,
          {
            type: 'field',
            cast: 'decimal',
            Component: NumberValueEdit,
            operators: [
              'equal',
              'not_equal',
              'greater_than',
              'greater_than_equal_to',
              'less_than',
              'less_than_equal_to',
              'exists',
              'not_exists',
            ],
          },
        ]
      case DatasourceColumnEnum.String:
        return [
          col.name,
          {
            type: 'field',
            Component: TextValueEdit,
            operators: ['in', 'not_in', 'contained_in', 'not_contained_in', 'exists', 'not_exists'],
          },
        ]
      case DatasourceColumnEnum.Date:
      case DatasourceColumnEnum.Timestamp:
        // Date / Timestamp fields are unsupported
        return undefined
      case DatasourceColumnEnum.Boolean:
        return [
          col.name,
          {
            type: 'field',
            Component: TrueFalseValueEdit,
            operators: ['equal', 'not_equal', 'exists', 'not_exists'],
          },
        ]
      default:
        return assertExhaustive(col.type)
    }
  })

  return Object.fromEntries(compact(entries))
}

export const getDomainTypeFieldMappings = (isRenameOtherInfoEnabled: boolean) =>
  isRenameOtherInfoEnabled ? DOMAIN_TYPE_FIELD_MAPPINGS_W_CUSTOM_FIELDS_RENAME : DOMAIN_TYPE_FIELD_MAPPINGS_OLD

export function useDomainFieldSpecs(): UseDomainFieldSpecs {
  const isRenameOtherInfoEnabled = useFeatureFlag(FeatureFlag.RenameOtherInfo)
  const mappings = getDomainTypeFieldMappings(isRenameOtherInfoEnabled)

  const baseDomainFieldSpecs = useMemo<Array<[AutomationDomain, DomainFieldSpec]>>(
    () =>
      Object.entries(mappings).map(([k, v]) => {
        return [{ type: k, datasourceToken: null }, v]
      }),
    [mappings]
  )

  const datasourcesEnabled = useFeatureFlag(FeatureFlag.EnableDatasources)

  const { data: datasourcesData, loading } = useQuery<
    TriggerFilterEditorDataSourcesQuery,
    TriggerFilterEditorDataSourcesQueryVariables
  >(TriggerFilterEditorDataSources, {
    variables: {
      datasourcesEnabled,
    },
    fetchPolicy: 'cache-only',
    skip: !datasourcesEnabled,
  })

  const datasourceDomainFieldSpecs = useMemo<Array<[LabeledAutomationDomain, DomainFieldSpec]>>(() => {
    return (
      datasourcesData?.datasourceWarehouses
        ?.map((dw) => {
          return dw.datasources.map((dd) => {
            const values = buildTriggerEditorColumnSpecs(dd.columns)

            return [
              { type: AutomationDomainType.Datasource, label: dd.name, datasourceToken: dd.token },
              {
                type: 'nested',
                values,
              },
            ] as [LabeledAutomationDomain, DomainFieldSpec]
          })
        })
        .flat() ?? []
    )
  }, [datasourcesData?.datasourceWarehouses])

  const getDomainFieldSpec = useCallback(
    (domain: AutomationDomain) => {
      if (domain.datasourceToken) {
        const found = datasourceDomainFieldSpecs.find(
          (e) => e[0].type === domain.type && e[0].datasourceToken === domain.datasourceToken
        )

        if (found) {
          return found[1]
        }
      }

      const found = baseDomainFieldSpecs.find((e) => e[0].type === domain.type)

      if (found) {
        return found[1]
      }

      return undefined
    },
    [baseDomainFieldSpecs, datasourceDomainFieldSpecs]
  )

  return {
    loading,
    baseDomainFieldSpecs,
    datasourceDomainFieldSpecs,
    getDomainFieldSpec,
  }
}

export type FieldSpecConfig = Record<string, FieldSpecConfigEntry>

export type FieldSpecConfigEntry = {
  possibleFields: Record<string, FieldSpec>
  fieldSpec: FieldSpec
  parentFieldSpec?: FieldSpec
}

const DOMAIN_TYPE_FEATURES: Partial<Record<AutomationDomainType, FeatureFlag>> = {
  [AutomationDomainType.MiddeskBusiness]: FeatureFlag.EnableMiddeskIntegration,
  [AutomationDomainType.Datasource]: FeatureFlag.EnableDatasources,
}

export const getBaseDomainTypes = (state: State) => {
  return Object.values(AutomationDomainType).filter((t) => {
    const feature = DOMAIN_TYPE_FEATURES[t]
    if (feature !== undefined) {
      return getOrganizationFeatureFlag(state, feature)
    }

    return true
  })
}

export const getDefaultOperatorForField = (domainFieldSpec: DomainFieldSpec, field?: string) => {
  if (!field) {
    return 'in'
  }

  const spec = domainFieldSpec.values[field]

  if (spec && 'operators' in spec) {
    return spec.operators[0]
  }

  return 'in'
}

export const getDefaultRightValueForOperator = (operator: string) => {
  switch (operator) {
    case 'equal':
    case 'not_equal':
    case 'greater_than':
    case 'less_than':
    case 'greater_than_equal_to':
    case 'less_than_equal_to':
      return { type: 'literal' as const, value: '' }
    case 'in':
    case 'not_in':
    case 'contained_in':
    case 'not_contained_in':
      return { type: 'literal' as const, value: [] as string[] }
    default:
      return { type: 'literal' as const, value: '' }
  }
}

export const getIconForDomainType = (domainType: AutomationDomainType): ComponentType<{ className?: string }> => {
  switch (domainType) {
    case AutomationDomainType.Person:
      return PersonIcon
    case AutomationDomainType.Business:
      return WorkOutline
    case AutomationDomainType.Datasource:
      return StorageIcon
    case AutomationDomainType.Filing:
      return ForwardToInboxOutlinedIcon
    case AutomationDomainType.Case:
      return CaseIcon
    case AutomationDomainType.MiddeskBusiness:
      return MiddeskIcon
    case AutomationDomainType.Review:
      return DescriptionOutlined
    default:
      return assertUnreachable(domainType)
  }
}

export const fieldSpecConfigForVariablePath = (
  domain: AutomationDomain,
  getDomainFieldSpec: UseDomainFieldSpecs['getDomainFieldSpec'],
  path: string[]
) => {
  let fieldMappingSoFar: FieldSpec = getDomainFieldSpec(domain) as DomainFieldSpec

  let possibleFields: Record<string, FieldSpec> = {}
  const config: FieldSpecConfig = {
    [domain.type]: {
      possibleFields,
      fieldSpec: fieldMappingSoFar,
    },
  }

  path.forEach((pathValue: string) => {
    if (pathValue === 'event' || pathValue === 'linked' || pathValue === domain.type) {
      return
    }

    const oldFieldMappingSoFar = fieldMappingSoFar

    if (fieldMappingSoFar.type === 'nested' || fieldMappingSoFar.type === 'nestedList') {
      possibleFields = fieldMappingSoFar.values
      fieldMappingSoFar = fieldMappingSoFar.values[pathValue]
    } else if (fieldMappingSoFar.type === 'linkedField') {
      fieldMappingSoFar = getDomainFieldSpec({
        type: fieldMappingSoFar.domainType,
        datasourceToken: null,
      }) as DomainFieldSpec
      possibleFields = fieldMappingSoFar.values
      fieldMappingSoFar = fieldMappingSoFar.values[pathValue]
    } else if (fieldMappingSoFar.type === 'nestedCustom') {
      possibleFields = {}
      fieldMappingSoFar = fieldMappingSoFar.fieldSpec
    }

    config[pathValue] = {
      possibleFields,
      fieldSpec: fieldMappingSoFar,
      parentFieldSpec: oldFieldMappingSoFar,
    }
  })

  return config
}

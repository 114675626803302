import { Box, styled } from '@mui/material'

import { useClickToCopyTooltip } from 'components/HbComponents/ClickToCopy'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { AttachmentSummaryFeedback } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummaryFooter/AttachmentSummaryFeedback'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import ContentCopyIcon from 'icons/ContentCopyIcon'
import { AttachmentSummary } from 'types/api'

export interface AttachmentSummaryFooterProps {
  caseToken: string
  attachmentToken: string
  summary: AttachmentSummary
}

const FooterContainer = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: theme.spacing(1),
  display: 'flex',
  gap: theme.spacing(1),
  // allow for button borders to be visible
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  '& > *': {
    marginLeft: theme.spacing(0.5),
  },
}))

const CopyTooltip = styled(HbTooltip)(() => ({
  alignSelf: 'flex-end',
  height: '100%',
}))

const CopyButton = styled(HbButton)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  maxHeight: theme.spacing(6),
  padding: theme.spacing(2.5),
  borderRadius: 8,
  background: '#FFF',
  boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.16)',
}))

export const AttachmentSummaryFooter = ({ caseToken, attachmentToken, summary }: AttachmentSummaryFooterProps) => {
  const usage = useUsage()

  const { copyTextToClipboard, tooltipText, resetTooltipText, setTooltipText } = useClickToCopyTooltip({
    initialTooltipText: 'Copy summary text',
  })

  const handleCopyContentClick = async () => {
    if (summary?.statements?.length === 0) return

    usage.logEvent({
      name: 'fileSummarization:copyText:clicked',
      data: { attachmentToken },
    })

    await copyTextToClipboard(summary?.statements?.join('\n\n') ?? '')
    setTooltipText('Copied')
    resetTooltipText()
  }

  return (
    <FooterContainer>
      <AttachmentSummaryFeedback caseToken={caseToken} summary={summary} attachmentToken={attachmentToken} />
      <CopyTooltip title={tooltipText} placement="top">
        <CopyButton
          label="Copy summary"
          iconOnly
          Icon={ContentCopyIcon}
          onClick={handleCopyContentClick}
          variant="textPrimary"
        />
      </CopyTooltip>
    </FooterContainer>
  )
}

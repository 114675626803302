import { useCallback } from 'react'

import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'

export const useSearchOnError = () => {
  const dispatch = useDispatch()
  return useCallback(
    (error) => {
      const message = error.message.split('\n')[0]
      dispatch(setFlashError(`[Error] ${message}`))
    },
    [dispatch]
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CryptoAddressIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M20.7747203,18.3547203 L13.8847203,11.4647203 C15.5753752,9.07958396 15.3008369,5.82313783 13.2347203,3.75472027 C10.8921799,1.41509324 7.09726064,1.41509324 4.75472027,3.75472027 C2.41509324,6.09726064 2.41509324,9.89217989 4.75472027,12.2347203 C6.82313783,14.3008369 10.079584,14.5753752 12.4647203,12.8847203 L17.2347203,17.6547203 L15.1147203,19.7747203 L16.5347203,21.1847203 L18.6547203,19.0647203 L19.3547203,19.7747203 C19.5434391,19.9619068 19.7989173,20.066257 20.0647203,20.0647203 C20.3305233,20.066257 20.5860014,19.9619068 20.7747203,19.7747203 C20.9640329,19.5869537 21.0705186,19.3313578 21.0705186,19.0647203 C21.0705186,18.7980827 20.9640329,18.5424869 20.7747203,18.3547203 Z M11.7747203,10.8247203 C10.1906328,12.3358339 7.69880774,12.3358339 6.11472027,10.8247203 C4.55390163,9.26216425 4.55484052,6.73031336 6.11681763,5.16891538 C7.67879474,3.6075174 10.2106458,3.6075174 11.7726229,5.16891538 C13.3346,6.73031336 13.3355389,9.26216425 11.7747203,10.8247203 Z M12.9983339,17.6443606 L15.1196542,15.5230403 L16.5338678,16.9372538 L14.4125474,19.0585742 L12.9983339,17.6443606 Z" />
  </SvgIcon>
)

export default CryptoAddressIcon

import { createContext, useCallback, useContext, useMemo, useState } from 'react'
import type { PropsWithChildren } from 'react'

import {
  CANCEL_COMMENT_THREAD_EDITOR_COMMAND,
  COMMIT_COMMENT_THREAD_EDITOR_COMMAND,
  REMOVE_COMMENT_MARK_EDITOR_COMMAND,
} from './CommentPlugin'

import type { LexicalEditor } from 'lexical'

export type CommentContextType = {
  openCommentPane: (editor: LexicalEditor, quoteText?: string | null, newThread?: boolean) => unknown
  closeCommentPane: () => unknown
  commitCommentThread: (threadToken: Promise<string>) => Promise<unknown>
  removeCommentMark: (threadToken: string) => unknown
  commentPaneOpen: boolean
  activeCommentThreadTokens: Array<string>
  setActiveCommentThreadTokens: (tokens: Array<string> | ((tokens: Array<string>) => Array<string>)) => void
  creatingNewThread: boolean
  submittingComment: boolean
  quoteText: string | null
}

export const CommentContext = createContext<CommentContextType | null>(null)

export function CommentProvider({ children }: PropsWithChildren<Record<string, unknown>>): JSX.Element {
  const [targetEditor, setTargetEditor] = useState<LexicalEditor | null>(null)
  const [quoteText, setQuoteText] = useState<string | null>(null)
  const [commentPaneOpen, setCommentPaneOpen] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [activeCommentThreadTokens, setActiveCommentThreadTokens] = useState<Array<string>>([])
  const [creatingNewThread, setCreatingNewThread] = useState(false)

  const openCommentPane = useCallback((editor: LexicalEditor, text: string | null = null, newThread = false) => {
    setTargetEditor(editor)
    setQuoteText(text)
    setCommentPaneOpen(true)
    setCreatingNewThread(newThread)
    if (!newThread) {
      editor.dispatchCommand(CANCEL_COMMENT_THREAD_EDITOR_COMMAND, undefined)
    }
  }, [])

  const closeCommentPane = useCallback(() => {
    targetEditor?.dispatchCommand(CANCEL_COMMENT_THREAD_EDITOR_COMMAND, undefined)
    setTargetEditor(null)
    setCommentPaneOpen(false)
  }, [targetEditor])

  const commitCommentThread = useCallback(
    async (threadToken: Promise<string>) => {
      setSubmitting(true)
      try {
        targetEditor?.dispatchCommand(COMMIT_COMMENT_THREAD_EDITOR_COMMAND, await threadToken)
        setQuoteText(null)
      } finally {
        setSubmitting(false)
        setCreatingNewThread(false)
      }
    },
    [targetEditor]
  )

  const removeCommentMark = useCallback(
    async (threadToken: string) => {
      targetEditor?.dispatchCommand(REMOVE_COMMENT_MARK_EDITOR_COMMAND, threadToken)
    },
    [targetEditor]
  )

  const contextValue = useMemo(
    () => ({
      commitCommentThread,
      removeCommentMark,
      openCommentPane,
      closeCommentPane,
      commentPaneOpen,
      activeCommentThreadTokens,
      setActiveCommentThreadTokens,
      creatingNewThread,
      submittingComment: submitting,
      quoteText,
    }),
    [
      commitCommentThread,
      removeCommentMark,
      openCommentPane,
      closeCommentPane,
      commentPaneOpen,
      activeCommentThreadTokens,
      creatingNewThread,
      submitting,
      quoteText,
    ]
  )

  return <CommentContext.Provider value={contextValue}>{children}</CommentContext.Provider>
}

export type CommentPaneRendererProps = {
  children: (
    p: Pick<
      CommentContextType,
      | 'commitCommentThread'
      | 'removeCommentMark'
      | 'closeCommentPane'
      | 'creatingNewThread'
      | 'submittingComment'
      | 'activeCommentThreadTokens'
      | 'setActiveCommentThreadTokens'
      | 'quoteText'
    >
  ) => JSX.Element
}

export function CommentPaneRenderer({ children }: CommentPaneRendererProps) {
  const ctx = useContext(CommentContext)

  if (!ctx) {
    throw new Error('CommentBoxRenderer must be used within a CommentProvider')
  }

  const renderProps = useMemo(
    () => ({
      commitCommentThread: ctx.commitCommentThread,
      removeCommentMark: ctx.removeCommentMark,
      closeCommentPane: ctx.closeCommentPane,
      creatingNewThread: ctx.creatingNewThread,
      submittingComment: ctx.submittingComment,
      activeCommentThreadTokens: ctx.activeCommentThreadTokens,
      setActiveCommentThreadTokens: ctx.setActiveCommentThreadTokens,
      quoteText: ctx.quoteText,
    }),
    [
      ctx.commitCommentThread,
      ctx.removeCommentMark,
      ctx.closeCommentPane,
      ctx.creatingNewThread,
      ctx.submittingComment,
      ctx.activeCommentThreadTokens,
      ctx.setActiveCommentThreadTokens,
      ctx.quoteText,
    ]
  )

  return ctx.commentPaneOpen ? children(renderProps) : null
}

import React, {
  useCallback,
  useRef,
  useEffect,
  useState,
  MutableRefObject,
  useLayoutEffect,
  CSSProperties,
} from 'react'

// eslint-disable-next-line no-restricted-imports
import { withStyles, StyleRules, Styles } from '@mui/styles'

import classnames from 'classnames'

import { Resizable } from 're-resizable'

import { TableComponentOverrides } from 'components/library/Table/Table'
import { StyleClass } from 'components/utils/styles'
import { usePrevious } from 'hooks'
import { Theme } from 'types/hb'

import { useResizeObserver } from '../Resize/hooks'

import DefaultHeaderCell from './DefaultHeaderCell'
import { DraggableTableHeader } from './DraggableTableHeader'
import { TableColumnElement, HeaderCellRenderProp } from './TableColumn'
import { MINIMUM_BATCH_COLUMN_WIDTH } from './TableRow.styles'
import {
  getField,
  getIsColumnResizeDisabled,
  haveColumnsChanged,
  RenderMeasurementColumn,
  SetTableColWidthsState,
  sumAllColWidths,
  TableColWidthsState,
  TableWithReorderableColumnsClassKey,
} from './helpers'

export type TableWithReorderableColumnsOverrides = StyleClass<TableWithReorderableColumnsClassKey>

const styles: Styles<Theme, object> = (theme: Theme): StyleRules<object, TableWithReorderableColumnsClassKey> => ({
  dragging: {
    backgroundColor: theme.palette.styleguide.lightGray2,
    borderRight: '1px solid',
  },

  table: {},

  autoTableLayout: {
    tableLayout: 'auto',
  },

  fixedTableLayout: {
    tableLayout: 'fixed',
    minWidth: '100%',
  },

  draggable: {
    '&:first-child': {
      borderLeftStyle: 'hidden',
    },
    '&$lastNonStickyHeader': {
      borderRight: 'none',
    },
    borderLeft: '1px solid',
  },

  lastNonStickyHeader: {},

  // visibility: 'collapse' is specific to table tags (in most browsers),
  // and allows the invisible header to participate in table layout calculations
  // while not displaying to the user
  hiddenTableHeader: { visibility: 'collapse' },

  visibleTableHeader: {
    display: 'flex',
  },

  batchColumnEnabled: {},
})

interface Props {
  classes: Record<TableWithReorderableColumnsClassKey, string>
  allColsWidth: number
  uniqueKey: string
  columns: TableColumnElement[]
  containerEl: HTMLDivElement | null
  /**
   * Stable (memoized) object of fixed column sizes if we want to be specific about widths.
   * Note: this is independent of column resizing.
   * Fixed column widths could apply to either mode and to any column.
   */
  fixedColumnSizes?: TableColWidthsState
  rightStickyColumn?: TableColumnElement
  renderMeasurementColumn: RenderMeasurementColumn
  reorderableColumns?: boolean
  className: string
  onColumnChange?: (arg: any) => void
  children: React.ReactNode
  styleOverrides?: TableComponentOverrides
  batchColumnEnabled?: boolean
  /**
   * If enabled, the table uses state-controlled column widths
   */
  columnResizingEnabled?: boolean
  tableColWidths: TableColWidthsState
  setTableColWidths: SetTableColWidthsState
}

const useUncontrolledTableDimensions = ({
  batchColumnEnabled,
  columnResizingEnabledForTable,
  draggableHeaderRef,
  tableHeaderRef,
}: {
  batchColumnEnabled: boolean
  columnResizingEnabledForTable: boolean
  draggableHeaderRef: MutableRefObject<HTMLDivElement | null>
  tableHeaderRef: MutableRefObject<HTMLTableRowElement | null>
}) => {
  const updateHeaderLayout = useCallback(() => {
    // Prior context:
    // After rendering update the widths of draggable column headers to match the current table layout.
    // This is a hack to work around the fact that it's not possible to make table columns
    // draggable without introducing significant layout jank.
    if (!tableHeaderRef.current || !draggableHeaderRef.current) return

    const headers = [...tableHeaderRef.current.childNodes] as HTMLElement[]
    const draggableHeader = [...draggableHeaderRef.current.childNodes] as HTMLElement[]

    // TODO: follow up on why these don't match sometimes
    // Context: PROD-17420 - seeing a Sentry error where the # of nodes don't seem to match.
    // Adding a quick fix to prevent this from erroring out:
    if (headers.length !== draggableHeader.length) return

    let headerRowWidth = 0
    for (let i = 0; i < headers.length; i += 1) {
      const headerCellWidth = headers[i].getBoundingClientRect().width
      draggableHeader[i].style.flex = `0 0 ${headerCellWidth}px`
      headerRowWidth += headerCellWidth
    }
    if (batchColumnEnabled && draggableHeader[1]) {
      draggableHeader[1].style.left = `${headers[0].getBoundingClientRect().width}px`
    }
    // eslint-disable-next-line no-param-reassign
    draggableHeaderRef.current.style.width = `${headerRowWidth}px`
  }, [batchColumnEnabled, tableHeaderRef, draggableHeaderRef])

  // Backwards compatibility with non-resizable tables:
  // On renders and window resize, update the header layout
  useLayoutEffect(() => {
    if (columnResizingEnabledForTable) return undefined
    // Use rAF to ensure the layout is up to date before resizing the header.
    const id = requestAnimationFrame(updateHeaderLayout)
    return () => cancelAnimationFrame(id)
  })

  return updateHeaderLayout
}

/**
 * Table layout options for managing column and table widths:
 * 1. 'auto': uncontrolled dimensions, allowing non-resizable columns to occupy their natural/defined width
 * 2. 'fixed': in a resizable table, this means the column widths are state-controlled
 */
type TableLayout = Extract<CSSProperties['tableLayout'], 'auto' | 'fixed'>

const getTableLayoutClass = (tableLayout: TableLayout) => `${tableLayout}TableLayout` as const

function Table(props: Props) {
  const {
    allColsWidth,
    children,
    classes: propClasses,
    columns,
    containerEl,
    onColumnChange,
    uniqueKey,
    rightStickyColumn,
    renderMeasurementColumn,
    reorderableColumns,
    className,
    styleOverrides,
    batchColumnEnabled = false,
    columnResizingEnabled: columnResizingEnabledForTable = false,
    fixedColumnSizes,
    tableColWidths,
    setTableColWidths,
  } = props

  const [tableLayout, setTableLayout] = useState<TableLayout>('fixed')

  const classes = {
    ...propClasses,
    ...styleOverrides?.TableWithReorderableColumns,
    batchColumnEnabled: classnames(
      propClasses.batchColumnEnabled,
      styleOverrides?.TableWithReorderableColumns?.batchColumnEnabled
    ),
    table: classnames(
      propClasses.table,
      styleOverrides?.TableWithReorderableColumns?.table,
      propClasses[getTableLayoutClass(tableLayout)]
    ),
  }

  const getHeaderRenderProp = useCallback(
    (column: TableColumnElement): HeaderCellRenderProp => {
      if (typeof column.props.header === 'string' || column.props.header === undefined) {
        return (headerProps) => (
          <DefaultHeaderCell
            {...headerProps}
            styleOverrides={styleOverrides?.DefaultHeaderCell}
            label={column.props.header as string}
          />
        )
      }

      return column.props.header
    },
    [styleOverrides?.DefaultHeaderCell]
  )

  const [initializedTableColWidths, setInitializedTableColWidths] = useState(false)

  const tableHeaderRef = useRef<HTMLTableRowElement>(null)
  const draggableHeaderRef = useRef<HTMLDivElement>(null)

  // effect: initialize table column widths
  useEffect(() => {
    // this effect is only relevant for resizable tables
    if (!columnResizingEnabledForTable) return
    const thRow = tableHeaderRef.current
    // no node yet to measure
    if (!thRow || !columns.length) return
    // already been measured
    if (initializedTableColWidths) return
    const resizableColumns = columns.filter((column) => !getIsColumnResizeDisabled(column))
    let colWidths = [...thRow.childNodes].reduce((acc, node: HTMLElement, i) => {
      const column = columns[i]
      const columnFieldName = getField(column)
      const isBatch = columnFieldName === 'batch'
      // subtracting the extra right-hand border from the measured width,
      // as that would keep adding a pixel each time this effect runs
      const measuredColWidth = node.getBoundingClientRect().width - 1
      acc[columnFieldName] = isBatch
        ? MINIMUM_BATCH_COLUMN_WIDTH
        : fixedColumnSizes?.[columnFieldName] || measuredColWidth
      return acc
    }, {} as TableColWidthsState)

    // sum of all col widths
    const newAllColWidths = sumAllColWidths(colWidths)
    // if there is a mismatch between available and occupied space,
    // we make adjustments to control the relative sizes of columns next
    const availableWidth = containerEl?.clientWidth ?? 0
    const remainingWidth = availableWidth - newAllColWidths

    if (remainingWidth > 0) {
      const numColsToDistributeRemainingWidth = resizableColumns.length
      if (numColsToDistributeRemainingWidth !== 0) {
        const availableWidthPerCol = remainingWidth / numColsToDistributeRemainingWidth
        resizableColumns.forEach((resizableColumn) => {
          const resizableColumnFieldName = getField(resizableColumn)
          colWidths[resizableColumnFieldName] += availableWidthPerCol
        })
        if (tableLayout === 'auto') {
          setTableLayout('fixed')
        }
      } else {
        setTableLayout('auto')
        // clear measurements
        colWidths = {}
      }
    }

    setTableColWidths(colWidths)
    setInitializedTableColWidths(true)
  }, [
    columnResizingEnabledForTable,
    columns,
    containerEl,
    fixedColumnSizes,
    initializedTableColWidths,
    setTableColWidths,
    tableHeaderRef,
    tableColWidths,
    tableLayout,
  ])

  // When the table measurements are "uncontrolled", i.e. the browser handles the computations,
  // e.g. when the table is non-resizable or layout is set to "auto".
  // We manually invoke this to keep the table and draggable header cell widths in sync:
  const updateHeaderLayout = useUncontrolledTableDimensions({
    batchColumnEnabled,
    columnResizingEnabledForTable,
    draggableHeaderRef,
    tableHeaderRef,
  })

  const prevTableLayout = usePrevious(tableLayout)
  useEffect(() => {
    if (prevTableLayout !== 'auto' && tableLayout === 'auto') {
      updateHeaderLayout()
    }
  }, [prevTableLayout, tableLayout, updateHeaderLayout])

  const prevColumns = usePrevious(columns)
  // effect: check if columns have changed and re-initialize the column widths
  useEffect(() => {
    const columnsHaveChanged = prevColumns && haveColumnsChanged(prevColumns, columns)
    if (!columnsHaveChanged) return
    setInitializedTableColWidths(false)
  }, [columns, prevColumns])

  const onColumnResizeStop = useCallback(
    (fieldName: string, resizeAmount: number, computedMinWidth: number, resizableRef: Resizable | null) => {
      if (!tableHeaderRef.current) return
      const prevThWidth = tableColWidths[fieldName]

      const resizableColumns = columns.filter((column) => !getIsColumnResizeDisabled(column))
      const resizableColumnFieldNames = resizableColumns.map((column) => getField(column))

      const computedNewThWidth = prevThWidth + resizeAmount

      // new width must be at least the minimum width specified for the column
      const newThWidth = Math.max(computedNewThWidth, computedMinWidth)

      const newTableColWidths = { ...tableColWidths, [fieldName]: newThWidth }

      const availableWidth = containerEl?.clientWidth ?? 0

      // sum of all col widths
      // need to adjust the relative sizes next
      const newAllColWidths = sumAllColWidths(newTableColWidths)

      // since the table should occupy at least the full width of its container,
      // if resizing a column makes the sum of all columns fall below the available container width,
      // then we adjust the width of an adjacent column to make up for the difference
      if (newAllColWidths < availableWidth) {
        const remainingAvailableWidth = availableWidth - newAllColWidths
        const indexOfFieldName = resizableColumnFieldNames.indexOf(fieldName)
        const prevFieldName = resizableColumnFieldNames[indexOfFieldName - 1]
        const nextFieldName = resizableColumnFieldNames[indexOfFieldName + 1]
        // add space to any next resizable column
        if (nextFieldName) {
          newTableColWidths[nextFieldName] += remainingAvailableWidth
        } else if (prevFieldName) {
          // or if there is a resizable previous column instead,
          // add space to that as long as there are 2+ columns,
          if (resizableColumnFieldNames.length > 1) {
            newTableColWidths[prevFieldName] += remainingAvailableWidth
          } else {
            // and if not, just set the resized column to available space
            const newWidth = availableWidth - tableColWidths[prevFieldName]
            newTableColWidths[fieldName] = newWidth
            // synchronously update the resizable to sync it with the clamped cell width
            // since the header cell itself will only do this if the col width changes
            resizableRef?.updateSize({ width: newWidth, height: 'initial' })
          }
        } else {
          // if it's the only field column, only let it shrink enough to fill the available scroll space
          newTableColWidths[fieldName] = availableWidth
        }
      }
      setTableColWidths(newTableColWidths)
    },
    [columns, containerEl, setTableColWidths, tableColWidths, tableHeaderRef]
  )

  const updateResponsiveColWidths = useCallback(() => {
    if (!containerEl) return
    // Ignores if the columns exceed the available width, in which case there is no need to adjust anything
    if (containerEl.clientWidth <= allColsWidth) return
    // Reinitialize the col widths when the columns need to expand to fill the available space
    setInitializedTableColWidths(false)
  }, [allColsWidth, containerEl])

  const [tableHeight, setTableHeight] = useState(0)

  const handleTableDimensionsUpdate = useCallback(
    ([{ target }]) => {
      const headerHeight = draggableHeaderRef.current?.clientHeight ?? 0
      // update the table height
      // which is used to set the column resize bar height
      setTableHeight(target.clientHeight + headerHeight)
      // backwards compatibility for non-resizable tables
      if (columnResizingEnabledForTable) return
      updateHeaderLayout()
    },
    [columnResizingEnabledForTable, updateHeaderLayout]
  )

  // table dimensions can be independent of container dimensions
  const { getRef: getTableRef } = useResizeObserver(handleTableDimensionsUpdate)

  // tracking the previous size lets us only observe width changes in the resize observer,
  // since it also runs on other layout effects
  const prevContainerSizeRef = useRef({ width: containerEl?.clientWidth ?? 0 })
  const handleContainerDimensionsUpdate = useCallback(
    ([{ target }]) => {
      if (!initializedTableColWidths) return
      if (prevContainerSizeRef.current.width !== target.clientWidth) {
        prevContainerSizeRef.current.width = target.clientWidth
        updateResponsiveColWidths()
      }
    },
    [initializedTableColWidths, updateResponsiveColWidths]
  )

  // observe the container element for changes in size
  const { getRef: getContainerRef } = useResizeObserver(handleContainerDimensionsUpdate)

  useEffect(() => {
    getContainerRef(containerEl)
  }, [containerEl, getContainerRef])

  // Resizing: This style has to be `undefined` when initializing table cells
  // so that default measurements of the column widths can be sampled on first render.
  // This style then enforces table width for subsequent renders after column widths have been measured.
  let tableWidthStyle = undefined
  if (columnResizingEnabledForTable) {
    tableWidthStyle = initializedTableColWidths ? { width: allColsWidth } : undefined
  }

  // This component renders two headers:
  //  - The first uses flexbox layout with draggable items that can be reordered. This one is displayed to the user.
  //  - The second is used as a header within the table layout and is invisible.
  //    It's used to compute how wide each header item should be in order to fit the current table layout.
  //
  // The reason for this setup is that reordering columns within a table layout is tricky,
  // because a column doesn't collapse if you drag a header cell out.
  // So, the visible flexbox header is used for dragging instead.
  return (
    <>
      <DraggableTableHeader
        allColsWidth={allColsWidth}
        availableWidth={containerEl?.clientWidth}
        batchColumnEnabled={batchColumnEnabled}
        classes={classes}
        columns={columns}
        columnResizingEnabled={columnResizingEnabledForTable}
        draggableHeaderRef={draggableHeaderRef}
        getHeaderRenderProp={getHeaderRenderProp}
        renderMeasurementColumn={renderMeasurementColumn}
        reorderableColumns={reorderableColumns}
        rightStickyColumn={rightStickyColumn}
        onColumnChange={onColumnChange}
        onColumnResizeStop={onColumnResizeStop}
        tableColWidths={tableColWidths}
        tableHeight={tableHeight}
        uniqueKey={uniqueKey}
      />
      <table ref={getTableRef} className={classnames(className, classes.table)} style={tableWidthStyle}>
        <thead className={classes.hiddenTableHeader}>
          <tr ref={tableHeaderRef} className="hb-table__header-row">
            {columns.length === 0 && (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <th className="hb-table__header hb-table__header--empty" />
            )}
            {columns.map((column, columnIndex) => {
              const fieldName = getField(column)
              const isLastNonSticky = rightStickyColumn ? columnIndex === columns.length - 1 : false

              return getHeaderRenderProp(column)({
                column,
                classes: {
                  root: classnames(column.props.headerClassName, {
                    [classes.draggable]: reorderableColumns,
                  }),
                  lastNonStickyHeader: classes.lastNonStickyHeader,
                },
                lastNonSticky: isLastNonSticky,
                style: columnResizingEnabledForTable ? { width: tableColWidths[fieldName] } : undefined,
              })
            })}
            {rightStickyColumn &&
              getHeaderRenderProp(rightStickyColumn)({
                column: rightStickyColumn,
              })}
          </tr>
        </thead>
        {children}
      </table>
    </>
  )
}

export const TableWithReorderableColumns = withStyles(styles)(Table)

import { Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import pluralize from 'pluralize'

import Feature from 'components/Feature'
import { HbButtonSizes } from 'components/HbComponents/HbButton'
import { TitleTag } from 'components/HbComponents/Text/HbText'
import { PageHeader } from 'components/library/Page/PageHeader'
import { useContainerDimensions } from 'components/library/Resize/hooks'
import { mergeOverrideStyles } from 'components/utils/styles'
import FilterControls from 'dashboards/reviews/components/Header/filtering/FilterControls'
import FilterSummaryBanner from 'dashboards/reviews/components/Header/filtering/FilterSummaryBanner'
import { ExportDropdownMenu } from 'dashboards/shared/components/DashboardExport/ExportDropdownMenu'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { titleCase } from 'helpers/uiHelpers'
import { FeatureFlag } from 'types/api'
import { Theme } from 'types/hb'

import { DashboardViewSelector } from '../Dashboard/DashboardViewSelector'
import DashboardAddMenu from '../DashboardAddMenu'

import { DashboardHeaderOverrides } from './types'

interface SizeProps {
  size?: HbButtonSizes
  iconOnly?: boolean
}

export interface Props {
  openNewDialog?: (...args: unknown[]) => void
  titleTag?: TitleTag
  styleOverrides?: DashboardHeaderOverrides
  FilterControlsComponent?: (props: SizeProps) => JSX.Element
  ViewSelectorComponent?: (props: SizeProps) => JSX.Element
  className?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    flex: '1',
    alignItems: 'center',
  },
  stripPadding: {
    padding: 0,
  },
  buttonSpacing: {
    marginRight: theme.spacing(2),
  },
}))

export const FilterDashboardHeader = ({
  openNewDialog,
  styleOverrides,
  titleTag,
  FilterControlsComponent = FilterControls,
  ViewSelectorComponent = DashboardViewSelector,
  className,
}: Props) => {
  const { header } = useDashboardConfig()
  const { title, hideTitleInHeader, exportCsv } = header
  const { containerWidth, getContainerRef } = useContainerDimensions()
  const classes = useStyles()
  const mergedClasses = mergeOverrideStyles(classes, styleOverrides?.DashboardHeader)
  const iconOnly = !!containerWidth && containerWidth < 900
  return (
    <div className={className}>
      <PageHeader
        title={titleCase(pluralize(title))}
        hideTitleInHeader={hideTitleInHeader}
        titleTag={titleTag}
        styleOverrides={styleOverrides?.PageHeader}
        titleRow={
          <div className={mergedClasses.headerContainer} ref={getContainerRef}>
            <ViewSelectorComponent iconOnly={iconOnly} />
            <Box flex={1} />
            <FilterControlsComponent iconOnly={iconOnly} />
            <Feature name={FeatureFlag.EnableCsvExport}>{exportCsv && <ExportDropdownMenu />}</Feature>
            {openNewDialog && <DashboardAddMenu openNewDialog={openNewDialog} iconOnly={iconOnly} />}
          </div>
        }
      />
      <FilterSummaryBanner />
    </div>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const ThumbsUpIcon: FC<SvgProps> = ({ style = { fill: 'none' }, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.1 20H16.4724C17.3743 20 18.1646 19.3964 18.4019 18.5262L20.311 11.5262C20.658 10.254 19.7002 8.99999 18.3815 8.99999H13.8064C13.4045 8.99999 13.1162 8.61255 13.2317 8.22758L14.2541 4.81955C14.4081 4.30634 14.3002 3.75024 13.9655 3.33185C13.2554 2.44421 11.8779 2.53687 11.293 3.5116L8.5826 8.02898C8.22116 8.63139 7.57015 8.99999 6.86762 8.99999H4.1C3.76863 8.99999 3.5 9.26862 3.5 9.59999V19.4C3.5 19.7314 3.76863 20 4.1 20Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M7 20V9" stroke="black" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
)

export const ThumbsUpGreenIcon: FC<SvgProps> = ({ style = { fill: 'none' }, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 24 24" style={style}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M4.1 20H16.4724C17.3743 20 18.1646 19.3964 18.4019 18.5262L20.311 11.5262C20.658 10.254 19.7002 9 18.3815 9H13.8064C13.4045 9 13.1162 8.61256 13.2317 8.22759L14.2541 4.81956C14.4081 4.30635 14.3002 3.75025 13.9655 3.33186C13.2554 2.44422 11.8779 2.53688 11.293 3.51161L8.5826 8.02899C8.22116 8.6314 7.57015 9 6.86762 9H4.1C3.76863 9 3.5 9.26863 3.5 9.6V19.4C3.5 19.7314 3.76863 20 4.1 20Z"
        fill="#E7F5E8"
        stroke="#4BA94E"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path d="M7 20V9V20Z" fill="#E7F5E8" />
      <path d="M7 20V9" stroke="#4BA94E" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  </SvgIcon>
)

export default ThumbsUpIcon

import { useCallback, useMemo, useState } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { useCasePageContext } from 'components/cases/Tabs/CasePageContext'
import { useCaseLock } from 'components/cases/hooks/useCaseLock'
import { useEffectOnce } from 'hooks'
import { LibraryEntryTypeEnum } from 'types/api'
import { Option } from 'types/hb'

import { MultiSelectSearchableField, MultiSelectSearchableFieldProps } from './SearchableFilterField'
import {
  GetLibraryObjectByTokenQuery,
  GetLibraryObjectByTokenQueryVariables,
  SearchCaseDataQuery,
  SearchCaseDataQueryVariables,
} from './__generated__/CaseDataFilterField.generated'

const SEARCH_CASE_DATA_QUERY = gql`
  query SearchCaseData(
    $caseToken: String!
    $query: String!
    $caseLocked: Boolean!
    $subjectTypes: [LibraryEntryTypeEnum!]
  ) {
    investigation(token: $caseToken) {
      token

      searchLibrarySubjects(query: $query, includeOwned: true, subjectTypes: $subjectTypes, first: 20) {
        edges {
          node {
            ... on LibraryObject {
              token
              txnToken @include(if: $caseLocked)
              displayName
            }
          }
        }
      }
    }
  }
`

const GET_LIBRARY_OBJECT_BY_TOKEN = gql`
  query GetLibraryObjectByToken($token: String!, $txnToken: String, $caseLocked: Boolean!) {
    people: libraryByToken(token: $token, txnToken: $txnToken, type: people) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    businesses: libraryByToken(token: $token, txnToken: $txnToken, type: businesses) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    financialInstitutions: libraryByToken(token: $token, txnToken: $txnToken, type: financial_institutions) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    devices: libraryByToken(token: $token, txnToken: $txnToken, type: devices) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    bankAccounts: libraryByToken(token: $token, txnToken: $txnToken, type: bank_accounts) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    paymentCards: libraryByToken(token: $token, txnToken: $txnToken, type: payment_cards) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    cryptoAddresses: libraryByToken(token: $token, txnToken: $txnToken, type: crypto_addresses) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }

    products: libraryByToken(token: $token, txnToken: $txnToken, type: products) {
      ... on LibraryObject {
        token
        txnToken @include(if: $caseLocked)
        displayName
      }
    }
  }
`

export const CaseDataFilterField = (
  props: Omit<MultiSelectSearchableFieldProps, 'options' | 'search' | 'loading'> & {
    subjectTypes: LibraryEntryTypeEnum[]
  }
) => {
  const { subjectTypes, currentValue } = props
  const { caseToken } = useCasePageContext()
  const caseLock = useCaseLock(caseToken)
  const [searchQuery, { data, loading }] = useLazyQuery<SearchCaseDataQuery, SearchCaseDataQueryVariables>(
    SEARCH_CASE_DATA_QUERY,
    {
      variables: { caseToken, query: '', caseLocked: caseLock.isLocked, subjectTypes },
      fetchPolicy: 'network-only',
    }
  )

  const [resolvedValueOption, setResolvedValueOption] = useState<Option | null>(null)

  const [getLibraryObject] = useLazyQuery<GetLibraryObjectByTokenQuery, GetLibraryObjectByTokenQueryVariables>(
    GET_LIBRARY_OBJECT_BY_TOKEN
  )

  // If the initial value is a token, resolve it to a display name
  useEffectOnce(() => {
    const resolveCurrentValue = async () => {
      if (currentValue && typeof currentValue === 'string') {
        const { data: libraryData } = await getLibraryObject({
          variables: {
            token: currentValue,
            caseLocked: caseLock.isLocked,
            txnToken: caseLock.activeLock?.txn?.token ?? null,
          },
        })

        const object =
          libraryData?.people ||
          libraryData?.businesses ||
          libraryData?.financialInstitutions ||
          libraryData?.devices ||
          libraryData?.bankAccounts ||
          libraryData?.paymentCards ||
          libraryData?.cryptoAddresses ||
          libraryData?.products

        if (object) {
          setResolvedValueOption({
            display: object.displayName,
            value: object.token,
          })
        }
      }
    }

    resolveCurrentValue()
  })

  const search = useCallback(
    (query) => searchQuery({ variables: { caseToken, query, caseLocked: caseLock.isLocked, subjectTypes } }),
    [caseLock.isLocked, caseToken, searchQuery, subjectTypes]
  )

  const edges = data?.investigation?.searchLibrarySubjects.edges
  const options: Option[] = useMemo(() => {
    const fromSearch =
      edges?.map((e) => ({
        display: e?.node?.displayName ?? '',
        value: e?.node?.token ?? '',
      })) ?? []

    if (resolvedValueOption && !fromSearch.find((o) => o.value === resolvedValueOption.value)) {
      return [resolvedValueOption, ...fromSearch]
    }

    return fromSearch
  }, [edges, resolvedValueOption])

  return <MultiSelectSearchableField {...props} options={options} search={search} loading={loading} />
}

import { isCancelled } from 'components/cases/Tabs/InformationRequestsApp/helpers/informationRequestStatusHelpers'
import { InboundRequest, InformationRequest } from 'types/api'

type InformationRequestWithTitle = Pick<InformationRequest, 'title'>

export const getInboundRequestTitle = (
  inboundRequest: Pick<InboundRequest, 'token' | 'status'> & { informationRequest?: InformationRequestWithTitle | null }
) => {
  if (!inboundRequest) {
    return '...'
  }
  if (isCancelled(inboundRequest.status)) {
    return 'Cancelled request'
  }
  const { informationRequest } = inboundRequest

  return informationRequest?.title || `Inbound Request #${inboundRequest?.token}`
}

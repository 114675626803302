import { useCallback } from 'react'

import { DashboardTypeEnum, LibraryEntryTypeEnum } from 'types/api'

import { useDashboardContext } from '../components/DashboardContextProvider'
import makeDashboardConfig from '../react/makeDashboardConfig'

const ENTITY_TYPES = [LibraryEntryTypeEnum.People, LibraryEntryTypeEnum.Businesses]
const ACCOUNT_TYPES = [
  LibraryEntryTypeEnum.BankAccounts,
  LibraryEntryTypeEnum.CryptoAddresses,
  LibraryEntryTypeEnum.PaymentCards,
]

export const caseSubjectFieldsMap = makeDashboardConfig<Record<string, LibraryEntryTypeEnum[]>>(
  {},
  {
    [DashboardTypeEnum.Transactions]: {
      sourceEntity: ENTITY_TYPES,
      sourceAccount: ACCOUNT_TYPES,
      destinationEntity: ENTITY_TYPES,
      destinationAccount: ACCOUNT_TYPES,
    },
  }
)

export const useCaseSubjectFieldMap = () => {
  const dashboardContextValue = useDashboardContext()
  return caseSubjectFieldsMap[dashboardContextValue]
}

export const useCaseSubjectField = () => {
  const filtersMap = useCaseSubjectFieldMap()
  return useCallback<(field: string) => LibraryEntryTypeEnum[] | null>(
    (fieldApiName) => (fieldApiName in filtersMap ? filtersMap[fieldApiName] ?? null : null),
    [filtersMap]
  )
}

import { AnyAction } from 'redux'

import * as actions from 'actions/settingsActions'
import { Account } from 'types/hb'

export interface EmailSetting {
  topic: string
  setting: 'all' | 'none'
  name: string
}

interface Import {
  token: string
  filename: string
  failureMessage: string
  createdAt: string
  importedAt: string
  category: string
  status: string
  importerName: string
  downloadPath: string
}

export interface ImportDashboard {
  page: number
  pageCount: number
  pageSize: number
  updatedAt?: number
  imports: Import[]
}

export type HistoryEvent = {
  token: string
  createdAt: string
  ipAddress: {
    family: number
    addr: number
    // eslint-disable-next-line camelcase
    mask_addr: number
  }
  userAgent: string
  description: string
  meta: {
    assigneeName: string
    changedByName: string
    previousAssigneeName: string
  }
  eventCategoryName: string
  eventName: string
  account: Account
}

export interface History {
  page: number
  pageSize: number
  pageCount: number
  totalCount: number
  events: HistoryEvent[]
  lastUpdatedAt: number | undefined
}

export interface SettingsState {
  emailSettings: EmailSetting[]
  importDashboard: ImportDashboard
  history: History
  mfaProvisioningURI: string | null
  filingInstitutionErrors: actions.FilingInstitutionErrors
}

const getInitialState = (): SettingsState => ({
  emailSettings: [],
  importDashboard: {
    imports: [],
    page: 1,
    pageSize: 25,
    pageCount: 0,
  },
  filingInstitutionErrors: {},
  history: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
    pageSize: 25,
    events: [],
    lastUpdatedAt: undefined,
  },
  mfaProvisioningURI: null,
})

const settingsReducer = (state = getInitialState(), action: AnyAction): SettingsState => {
  switch (action.type) {
    case actions.SET_HISTORY: {
      return { ...state, history: action.history }
    }
    case actions.SET_MFA_PROVISIONING_URI: {
      return { ...state, mfaProvisioningURI: action.uri }
    }
    case actions.SET_EMAIL_NOTIFICATION_SETTINGS: {
      return { ...state, emailSettings: action.settings }
    }
    case actions.UPDATE_EMAIL_NOTIFICATION_SETTING: {
      const { topic, setting } = action
      const { emailSettings } = state
      const updateIndex = emailSettings.findIndex((s) => s.topic === topic)
      const updatedSettings = [
        ...emailSettings.slice(0, updateIndex),
        { ...emailSettings[updateIndex], setting },
        ...emailSettings.slice(updateIndex + 1),
      ]
      return { ...state, emailSettings: updatedSettings }
    }
    case actions.SET_FILING_INSTITUTION_ERRORS: {
      return { ...state, filingInstitutionErrors: action.errors }
    }
    default: {
      return state
    }
  }
}

export default settingsReducer

import { GQLError } from 'components/GQLError'

import { HbTag } from 'components/HbComponents/HbTag'
import Loader from 'components/library/Loader'
import { HbSelectFieldProps, Option, SelectField } from 'components/material/Form/SelectInput'

import { Maybe } from 'types/api'

import { useAssignableQueues } from './useQueues'

type Props = Pick<HbSelectFieldProps, 'fullWidth' | 'label' | 'margin' | 'name' | 'placeholder' | 'required' | 'testId'>

export type QueueOptionType = Option & {
  custom: {
    color: string
  }
}

export const QueueOption = ({ option }: { option: QueueOptionType }) => {
  return <HbTag color={option.custom?.color ?? undefined} label={option.display} />
}

// renders an individual option in the list
const customComponent = (option: QueueOptionType) => {
  return <QueueOption option={option} />
}

// renders the selected value in the input
export function renderValue(_multiple: boolean, options: QueueOptionType[], _placeholder?: Option | null) {
  return (selected: string | number | Array<Maybe<string> | Maybe<number>>) => {
    const option = options.find(({ value }) => selected === value)

    if (!option) {
      return undefined
    }

    return <QueueOption option={option} />
  }
}

// A formik-compatible select field for choosing a Queue
export function QueueSelectField(props: Props) {
  const { queues, error, loading } = useAssignableQueues()

  if (loading) {
    return <Loader variant="local" />
  }
  if (error) {
    return <GQLError error={error} />
  }
  if (!queues.length) {
    return null
  }

  const options = queues.map((q) => ({
    display: q.name,
    value: q.token,
    custom: {
      color: q.color,
    },
  }))

  return <SelectField customComponent={customComponent} options={options} renderValue={renderValue} {...props} />
}

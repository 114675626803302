import { v4 as uuidv4 } from 'uuid'

import { SortValueInput } from 'types/api'
import {
  SearchFilter,
  SearchFilterWithId,
  SearchGroup,
  SearchRequest,
  SearchRequestWithId,
  SearchGroupWithId,
  isSearchFilter,
} from 'utils/query/api.types'

export const addIdToSearchFilter = (filter: SearchFilter): SearchFilterWithId => ({
  ...filter,
  _type: 'SearchFilterWithId',
  id: uuidv4(),
})

export const addIdToSearchGroup = (group: SearchGroup): SearchGroupWithId => ({
  ...group,
  _type: 'SearchGroupWithId',
  id: uuidv4(),
  children: group.children.map((filterOrGroup) =>
    isSearchFilter(filterOrGroup) ? addIdToSearchFilter(filterOrGroup) : addIdToSearchGroup(filterOrGroup)
  ),
})

export const addIdToSearchRequest = (request: SearchRequest): SearchRequestWithId => {
  return {
    ...request,
    filter: addIdToSearchGroup(request.filter),
    sorts: request.sorts?.filter((sort): sort is SortValueInput => !!(sort.sortDir && sort.sortField)) ?? [],
  }
}

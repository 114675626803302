import { useCallback } from 'react'

import moment, { MomentInput } from 'moment-timezone'

import { useSelector } from 'actions/store'
import { getCurrentOrganization } from 'helpers/stateHelpers'

export type DateFormatter<DefaultValue extends string | null = string | null> = (
  datetime: MomentInput,
  defaultValue?: DefaultValue,
  format?: string
) => DefaultValue extends string ? string : string | null

export function useOrgTimeZone() {
  return useSelector((state) => {
    const org = getCurrentOrganization(state)
    return org ? org.timezone : 'Etc/UTC'
  })
}

export function formatDate(
  orgTimeZone: string,
  datetime: MomentInput,
  defaultValue: string | null | undefined = '-',
  format = 'l'
) {
  if (datetime) {
    const isDateOnly = typeof datetime === 'string' && datetime.length === 10 // check whether datetime is a YYYY-MM-DD date

    let formatted = moment(datetime)
    if (orgTimeZone && !isDateOnly) {
      formatted = formatted.tz(orgTimeZone)
    }

    return formatted.format(format)
  }

  return defaultValue
}

export function useDateFormatter(defaultFormat?: string): DateFormatter {
  const orgTimeZone = useOrgTimeZone()
  return useCallback(
    (datetime: MomentInput, defaultValue?: string | null, format?: string) => {
      return formatDate(orgTimeZone, datetime, defaultValue, defaultFormat ?? format)
    },
    [defaultFormat, orgTimeZone]
  )
}

export function useFormattedDate(datetime: MomentInput, defaultValue?: string | null, format?: string) {
  const orgTimeZone = useOrgTimeZone()
  return formatDate(orgTimeZone, datetime, defaultValue, format)
}

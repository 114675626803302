import { darken } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Placement, HbTooltip } from 'components/HbComponents/HbTooltip'
import { HbText } from 'components/HbComponents/Text/HbText'
import { InformationIcon } from 'icons/InformationIcon'
import { VerifiedIcon } from 'icons/VerifiedIcon'
import { Theme } from 'types/hb'

export interface ManagedObjectIconProps {
  managedObjectTitle?: string
  managedObjectSubtitle?: string
  iconClass?: string
  tooltipNoticeText?: string
}

const useStyles = makeStyles((theme: Theme) => ({
  tooltip: {
    borderRadius: theme.spacing(),
    padding: theme.spacing(2),
  },
  arrow: {
    color: theme.palette.styleguide.black,
    '&:before': {
      height: theme.spacing(0.3),
    },
  },
  tooltipTitle: {
    color: theme.palette.styleguide.nearWhite,
    display: 'block',
    textTransform: 'uppercase',
    ...theme.typography.sizes.s,
  },
  tooltipSubtitle: {
    color: theme.palette.styleguide.nearWhite,
    display: 'block',
    ...theme.typography.sizes.lg,
  },
  tooltipNotice: {
    marginTop: theme.spacing(),
    display: 'flex',
    gap: theme.spacing(0.5),
  },
  tooltipNoticeIcon: {
    color: darken(theme.palette.styleguide.nearWhite, 0.3),
    width: '14px',
    height: '14px',
    padding: 0,
  },
  tooltipNoticeText: {
    color: darken(theme.palette.styleguide.nearWhite, 0.3),
    ...theme.typography.sizes.s,
  },
}))

export const ManagedObjectIcon = ({ managedObjectTitle, managedObjectSubtitle, iconClass }: ManagedObjectIconProps) => {
  const classes = useStyles()
  const manageObjectTitleEl = managedObjectTitle ? (
    <HbText className={classes.tooltipTitle}>{managedObjectTitle}</HbText>
  ) : null
  const managedObjectSubtitleEl = managedObjectSubtitle ? (
    <HbText className={classes.tooltipSubtitle}>{managedObjectSubtitle}</HbText>
  ) : null

  const tooltipContent = (
    <div>
      {manageObjectTitleEl}
      {managedObjectSubtitleEl}
      <div className={classes.tooltipNotice}>
        <InformationIcon className={classes.tooltipNoticeIcon} />
        <HbText className={classes.tooltipNoticeText}>Changes must be made by an Admin.</HbText>
      </div>
    </div>
  )

  return (
    <HbTooltip classes={classes} title={tooltipContent} placement={Placement.Top} showArrow>
      <VerifiedIcon className={iconClass} />
    </HbTooltip>
  )
}

import React from 'react'

import { Done as DoneIcon } from '@mui/icons-material'
import { InputAdornment, TextFieldProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import { useFormikContext } from 'formik'

import { TextField as BaseTextField } from 'components/material/Form'
import { touchedAndValid } from 'components/material/Form/formikMaterial'
import { WithStyles, Theme } from 'types/hb'

const styles: Styles<Theme, object> = {
  root: {
    paddingBottom: '1.5rem',

    '&:last-child': {
      paddingBottom: '0',
    },
  },
}

const StyledDoneIcon = withStyles((theme: Theme) => ({
  root: {
    color: theme.palette.styleguide.green,
  },
}))(({ classes }: WithStyles<typeof styles>) => <DoneIcon className={classes.root} />)

interface Props extends WithStyles<typeof styles> {
  label: string
  name: string
  type: string
  inputProps?: any
  showValidCheck?: boolean
  autoFocus?: boolean
  fullWidth?: boolean
  autoComplete?: string
  Icon?: React.ComponentType
}

/*
 * Wrapper around MUI TextField for consistent styling across onboarding forms.
 * Additionally adds showValidCheckProp for showing input with checkmark adornment
 * when input contains a valid value.
 */
const TextField = React.forwardRef<unknown, Props>(
  ({ classes, label, name, showValidCheck = false, inputProps, Icon, ...fieldProps }, ref) => {
    const formik = useFormikContext()

    const InputProps: TextFieldProps['InputProps'] = {
      size: 'medium',
      endAdornment: (
        <>
          {showValidCheck && touchedAndValid({ name }, formik) && (
            <InputAdornment position="end">
              <StyledDoneIcon />
            </InputAdornment>
          )}
          {Icon && (
            <InputAdornment position="end">
              <Icon />
            </InputAdornment>
          )}
        </>
      ),
    }

    return (
      <BaseTextField
        ref={ref}
        classes={classes}
        label={label}
        InputProps={InputProps}
        // eslint-disable-next-line react/jsx-no-duplicate-props
        inputProps={{ 'aria-label': label, ...inputProps }}
        name={name}
        variant="outlined"
        size="medium"
        {...fieldProps}
      />
    )
  }
)

export default withStyles(styles)(TextField)

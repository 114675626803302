import classnames from 'classnames'
import { isEmpty } from 'lodash'
import PropTypes from 'prop-types'

export const MenuListItemGroup = ({ label, children }) => {
  const labeled = !isEmpty(label)
  const containerClasses = classnames('hb-menu-list__list-group', {
    'hb-menu-list__list-group--labeled': labeled,
  })
  return (
    <ul className={containerClasses}>
      {labeled && <li className="hb-menu-list__list-item hb-menu-list__list-item--group-label">{label}</li>}
      {children}
    </ul>
  )
}

MenuListItemGroup.propTypes = {
  label: PropTypes.string,
  children: PropTypes.node.isRequired,
}

MenuListItemGroup.defaultProps = {
  label: null,
}

export default MenuListItemGroup

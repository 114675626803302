import { ComponentProps } from 'react'

import { Menu, MenuItem } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  smartValuesMenuPaper: {
    maxHeight: '300px',
    overflowY: 'auto',
  },
  smartValuesMenuEntry: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    padding: theme.spacing(1, 2),
    borderRadius: theme.spacing(1),
  },
}))

export type VariableEntry = { label: string; insert: string }

export default function SmartValuesMenu({
  anchorEl,
  open,
  variables,
  onClose,
  onSelect,
}: Pick<ComponentProps<typeof Menu>, 'anchorEl' | 'open' | 'onClose'> & {
  variables: Array<VariableEntry>
  onSelect: (entry: VariableEntry) => unknown
}) {
  const classes = useStyles()
  const usage = useUsage()

  const handleSelect = (option: VariableEntry) => () => {
    usage.logEvent({ name: 'automations:smartValuesMenuItem:clicked', data: { value: option.insert } })
    onSelect(option)
  }

  return (
    <Menu
      classes={{ paper: classes.smartValuesMenuPaper }}
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
    >
      {variables.map((option) => (
        <MenuItem
          key={option.label}
          className={classes.smartValuesMenuEntry}
          onClick={handleSelect(option)}
          sx={(theme) => ({
            margin: theme.spacing(0, 1),
          })}
        >
          <HbText size="md">{option.label}</HbText>
          <HbText size="s" color="secondary">
            {option.insert}
          </HbText>
        </MenuItem>
      ))}
    </Menu>
  )
}

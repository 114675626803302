import { FC } from 'react'

import { SvgIcon } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { SvgProps } from './SvgProps'

const useDefaultStyles = makeStyles(() => ({
  root: {
    fill: 'none',
  },
}))

interface Props extends SvgProps {
  className?: string
}

export const TrashOutlineIcon: FC<Props> = ({ className, viewBox = '0 0 18 18', ...props }) => {
  const defaultClasses = useDefaultStyles()
  return (
    <SvgIcon className={classNames(defaultClasses.root, className)} viewBox={viewBox} fill="none" {...props}>
      <path
        d="M2.25 4.5h13.5M6 4.5V3a1.5 1.5 0 0 1 1.5-1.5h3A1.5 1.5 0 0 1 12 3v1.5m2.25 0V15a1.5 1.5 0 0 1-1.5 1.5h-7.5a1.5 1.5 0 0 1-1.5-1.5V4.5h10.5ZM7.5 8.25v4.5M10.5 8.25v4.5"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

export default TrashOutlineIcon

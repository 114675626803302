import { Store } from 'redux'

import { State } from 'actions/store'

import { getChurnzeroAppKey, getCurrentAccount, getCurrentOrganization } from '../stateHelpers'

declare global {
  interface Window {
    ChurnZero: any
  }
}

/**
 * Sends usage events to ChurnZero.
 */
export class ChurnZeroSender {
  private walkthroughEnabled = false
  private trackingEnabled = false
  private appkey: string | undefined
  private store: Store<State>

  public initialize(store: Store<State>) {
    this.store = store

    this.appkey = getChurnzeroAppKey(store.getState())

    if (this.appkey) {
      const env = this.environment()
      this.walkthroughEnabled = env === 'sandbox'
      this.trackingEnabled = env === 'sandbox' || env === 'production'
      this.intializeChurnZeroWalkthroughs()
    }
  }

  private intializeChurnZeroWalkthroughs() {
    // Walkthroughs are only enabled in sandbox.
    // We don't want to load the CZ JS in prod.
    if (!this.appkey || !this.walkthroughEnabled) {
      return
    }

    const customer = this.shortCode()
    const user = this.userEmail()

    if (!customer || !user) {
      // if we're not logged in, don't initialize
      return
    }

    window.ChurnZero = window.ChurnZero || []
    const cz = document.createElement('script')
    cz.type = 'text/javascript'
    cz.async = true
    cz.src = 'https://hummingbird.us2app.churnzero.net/churnzero.js'
    const s = document.getElementsByTagName('script')[0]
    s.parentNode?.insertBefore(cz, s)

    window.ChurnZero.push(['urltracking', false])
    window.ChurnZero.push(['setAppKey', this.appkey])
    window.ChurnZero.push(['setContact', customer, user])
  }

  private userEmail() {
    return getCurrentAccount(this.store.getState())?.email
  }

  private shortCode() {
    return getCurrentOrganization(this.store.getState())?.shortCode
  }

  private environment() {
    return this.store.getState().application.environment
  }

  public trackEvent(event: string, message = '') {
    if (!this.appkey || !this.trackingEnabled) return

    const env = this.environment()
    this.sendToCZ('trackEvent', {
      eventName: `${env}_${event}`,
      description: message,
    })
  }

  private sendToCZ(action: 'trackEvent' | 'setAttribute', bodyContent: Record<string, string>) {
    if (!this.appkey || !this.trackingEnabled) return

    fetch('https://hummingbird.us2app.churnzero.net/i', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
      },
      body: JSON.stringify({
        ...bodyContent,
        appKey: this.appkey,
        accountExternalId: this.shortCode(),
        contactExternalId: this.userEmail(),
        action,
        cf_Environment: this.environment(),
      }),
    }).catch((e) => e)
  }

  public sendTimeInApp(start: Date, end: Date) {
    if (!this.appkey || !this.trackingEnabled) return

    const url = new URL('https://hummingbird.us2app.churnzero.net/i')
    const params: Record<string, string> = {
      appKey: this.appkey,
      accountExternalId: this.shortCode(),
      contactExternalId: this.userEmail(),
      action: 'trackTimeInApp',
      startDate: start.toISOString(),
      endDate: end.toISOString(),
      module: this.environment() ?? '',
    }

    Object.keys(params).forEach((key) => url.searchParams.append(key, params[key]))

    fetch(url.toString(), {
      method: 'GET',
      keepalive: true,
      headers: {
        'Cache-Control': 'no-cache',
      },
    }).catch((e) => e)
  }
}

import { ReactNode } from 'react'

import { OutlinedInput, TextFieldProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { displayStringValue } from 'components/HbComponents/Form/HbInputs/hbInputUtils'
import { getHelperTextId, InputContainer, InputResponse } from 'components/HbComponents/Form/Inputs'
import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {},
  multiline: {},
  input: {},
  inputContainer: {
    marginBottom: 0,
  },
}))

export type HbTextAreaProps = Omit<
  TextFieldProps,
  | 'color' // component has a set color
  | 'variant' // component has set variant
  | 'select' // select should be its own separate component
  | 'SelectProps' // select should be its own separate component
  | 'placeholder' // placeholders are an anti-pattern we would like to deprecate
  | 'InputLabelProps' // separate handling of InputContainer props
  | 'margin' // component has a set margin
  | 'size' // component has a set size
  | 'rows' // deprecated
  | 'rowsMax' // deprecated
  | 'multiline' // will always be true for textarea
  | 'FormHelperTextProps' // separate handling of InputContainer props
> & {
  name: string
  testId?: string
  label: string | ReactNode
  sublabel?: string
  inputContainerClassName?: string
  noSpacing?: boolean
  errors?: string | string[]
  hideLabel?: boolean
  readOnly?: boolean
}

/** *
 * This is the HB equivalent of a multiline MUI TextInput
 * with some styling changes and adjustment of props.
 * It can be used without any form library.
 */
function HbTextArea(props: HbTextAreaProps) {
  const {
    autoComplete,
    autoFocus = false,
    children,
    classes: overrideClasses,
    className: classNameProp,
    defaultValue,
    disabled = false,
    error = false,
    errors,
    fullWidth = false,
    helperText,
    hideLabel,
    inputProps,
    InputProps,
    inputRef,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    required = false,
    type,
    value,
    sublabel,
    noSpacing,
    inputContainerClassName: inputContainerClassNameProp,
    testId,
    readOnly,
    minRows,
    maxRows,
    id,
    ...rest
  } = props

  const classes = useStyles()
  const className = classNames(classNameProp)
  const mergedClasses = mergeOverrideStyles(classes, overrideClasses)
  // Custom override for the bottom margin for InputContainer.
  // We probably shouldn't have built-in margins for core components
  // since we usually have to adjust them based on the the context
  // of where they are rendered. Kind of hard going through every example
  // of InputContainer, but there aren't that many of HbTextInput
  // so I figured this would be a more reasonable approach for the moment.
  const inputContainerClassName = classNames(inputContainerClassNameProp, mergedClasses.inputContainer)

  const hasErrors = error || Boolean(errors?.length) || Boolean(typeof errors === 'string' && errors)

  if (readOnly) {
    const readOnlyValue = value || inputProps?.value
    return (
      <InputResponse className={inputContainerClassName} label={label} hideLabel={hideLabel}>
        {displayStringValue({ value: readOnlyValue })}
      </InputResponse>
    )
  }

  const htmlId = id || name

  return (
    <InputContainer
      isErroneous={hasErrors}
      required={required}
      htmlFor={htmlId}
      label={label}
      sublabel={sublabel}
      className={inputContainerClassName}
      noSpacing={noSpacing}
      errors={errors}
      helperText={helperText}
      testId={testId}
      hideLabel={hideLabel}
      {...rest}
    >
      <OutlinedInput
        classes={mergedClasses}
        aria-describedby={getHelperTextId(htmlId)}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        name={name}
        type={type}
        value={value}
        id={htmlId}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        inputProps={inputProps}
        disabled={disabled}
        className={className}
        margin="dense"
        multiline
        minRows={minRows || 10}
        maxRows={maxRows || minRows}
        {...InputProps}
      />
    </InputContainer>
  )
}

export default HbTextArea

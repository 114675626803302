// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

const INPUT_HEIGHT = 32

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    padding: theme.spacing(1, 1, 1, 2),
  },
  textColor: {
    color: theme.palette.text.secondary,
  },
  input: {
    height: `${INPUT_HEIGHT}px !important`,
    paddingTop: 0,
    paddingBottom: 0,
  },
  numberInputWrapper: {
    '& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button': {
      appearance: 'none',
      margin: 0,
    },
    '& input[type=number]': {
      paddingRight: theme.spacing(1.25),
      appearance: 'textfield',
    },
  },
  section: {
    marginRight: theme.spacing(3),
  },
  refreshButton: () => {
    const resetRules = {
      borderColor: 'transparent',
      textDecoration: 'underline',
      background: 'none',
    }
    return {
      ...resetRules,
      padding: 0,
      margin: 0,
      fontWeight: 'normal',
      '&:hover': resetRules,
    }
  },
  truncate: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: 'box',
    boxOrient: 'vertical',
    lineClamp: 2,
  },
  fitContent: {
    minWidth: 'fit-content',
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  smallLeftMargin: {
    marginLeft: theme.spacing(0.5),
  },
  smallRightMargin: {
    marginRight: theme.spacing(0.5),
  },
}))

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CustomDocSearchIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M18,14 L18,21 L4,21 L4,5 L9.02,5 C9.07,4.29 9.24,3.62 9.5,3 L4,3 C2.9,3 2,3.9 2,5 L2,21 C2,22.1 2.9,23 4,23 L18,23 C19.1,23 20,22.1 20,21 L20,16 L18,14 Z M6,18 L16,18 L16,16 L6,16 L6,18 Z M6,14 L6,12 L16,12 L16,14 L6,14 Z M6,10 L6,8 L10,8 L10,10 L6,10 Z M19.3,7.89 C19.74,7.19 20,6.38 20,5.5 C20,3.01 17.99,1 15.5,1 C13.01,1 11,3.01 11,5.5 C11,7.99 13.01,10 15.49,10 C16.37,10 17.19,9.74 17.88,9.3 L21,12.42 L22.42,11 L19.3,7.89 Z M15.5,8 C14.12,8 13,6.88 13,5.5 C13,4.12 14.12,3 15.5,3 C16.88,3 18,4.12 18,5.5 C18,6.88 16.88,8 15.5,8 Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)

export default CustomDocSearchIcon

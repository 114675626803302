// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import Feature from 'components/Feature'
import { Placement, HbTooltip } from 'components/HbComponents/HbTooltip'
import { VerifiedUserIcon } from 'icons'
import { FeatureFlag } from 'types/api'
import { Theme, WithStyles } from 'types/hb'

const styles = (theme: Theme) => ({
  icon: {
    height: 18,
    color: theme.palette.styleguide.lightGray1,

    '&:hover': {
      color: theme.palette.styleguide.blue,
    },
  },
  strong: {
    fontWeight: 500,
  },
})

type Props = WithStyles<typeof styles> & {
  field?: string
  text?: string
}

const SARFieldAdornment = withStyles(styles)((props: Props) => {
  const { classes, field, text } = props
  if (!field && !text) {
    return null
  }

  return (
    <Feature name={FeatureFlag.EnableSarAdornments}>
      <HbTooltip
        placement={Placement.Top}
        title={
          field ? (
            <>
              Maps to U.S. SAR PDF item:
              <br />
              <span className={classes.strong}>{field}</span>
            </>
          ) : text ? (
            <span>{text}</span>
          ) : undefined
        }
      >
        <VerifiedUserIcon className={classes.icon} />
      </HbTooltip>
    </Feature>
  )
})

export default SARFieldAdornment

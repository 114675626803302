import { useEffect, useRef } from 'react'

import { isEqual } from 'lodash'

import { usePendingChanges } from 'components/cases/Tabs/PendingChanges'

interface Props {
  currentValues: any
  initialValues: any
  preserveFormState?: boolean
  dirty?: boolean
}
export const PreserveFormState = ({ currentValues, initialValues, preserveFormState = true, dirty }: Props) => {
  const currentValuesRef = useRef(currentValues)
  const initialValuesRef = useRef(initialValues)
  const dirtyRef = useRef(dirty)
  const {
    setPendingChanges,
    sliceHasPendingChanges,
    setHasPendingChanges,
    uiSection,
    _saveOverriddenRef,

    _isLockedRef,
  } = usePendingChanges()

  useEffect(() => {
    currentValuesRef.current = currentValues
  }, [currentValues])
  useEffect(() => {
    initialValuesRef.current = initialValues
  }, [initialValues])
  useEffect(() => {
    dirtyRef.current = dirty
  }, [dirty])

  useEffect(() => {
    return () => {
      if (preserveFormState && uiSection) {
        if (
          dirtyRef.current === false || // if not defined, do not use
          _saveOverriddenRef[uiSection] ||
          // we want the value of the ref at time of cleanup, removing this line causes an unhelpful linter suggestion to copy ref.current in the effect phase
          // eslint-disable-next-line react-hooks/exhaustive-deps
          _isLockedRef.current ||
          isEqual(initialValuesRef.current, currentValuesRef.current) // check last to bail early in most cases instead of always deep comparing
        ) {
          return
        }
        setPendingChanges(currentValuesRef.current)
      }
    }
    // Use refs to explicitly avoid running this on dependency changes. This should *only* run when unmounting.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (
      !isEqual(initialValuesRef.current, currentValuesRef.current) &&
      dirty &&
      !sliceHasPendingChanges &&
      !_isLockedRef.current
    ) {
      setHasPendingChanges()
    }
  }, [dirty, setHasPendingChanges, sliceHasPendingChanges, _saveOverriddenRef, uiSection, _isLockedRef])
  return null
}

import { useEffect, useRef } from 'react'

import { getIn } from 'formik'

import Autocomplete from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'

import { getErrorDetails } from 'components/material/Form/formikMaterial'
import { Option } from 'types/hb'

import { useCountryAndStateInputs } from './hooks'
import { StateInputProps } from './types'

export function StateInput(props: StateInputProps) {
  const { countryName, stateName, autosave, USOnly, ...rest } = props

  const { formik, currentCountry, subdivisions } = useCountryAndStateInputs({ autosave, countryName, USOnly })

  const value = getIn(formik.values, stateName)

  const initializedRef = useRef(false)
  useEffect(() => {
    if (initializedRef.current) return
    initializedRef.current = true
    const match = subdivisions.map((s) => s.value).includes(value)
    // on initialization, clear field value if invalid
    if (value && !match) formik.setFieldValue(stateName, undefined, false)
  }, [value, formik, stateName, subdivisions])

  const { fieldError, showError } = getErrorDetails({ name: stateName }, formik)

  const handleChange = (o?: Option) => formik.setFieldValue(stateName, o?.value)

  return (
    <Autocomplete
      value={value}
      errorMessage={showError ? fieldError : ''}
      options={subdivisions}
      onChange={handleChange}
      disabled={!currentCountry}
      {...rest}
    />
  )
}

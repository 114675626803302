import {
  DatePicker,
  DateTimePicker,
  TimePicker,
  DatePickerProps,
  TimePickerProps,
  DateTimePickerProps,
  DateValidationError,
} from '@mui/x-date-pickers'

import { Field, FieldAttributes, FieldProps, FieldConfig } from 'formik'
import { isString } from 'lodash'
import moment, { Moment } from 'moment'

import { formatDateTimeValidationError, orgDateTime, stampDateTimeWithOrgTz } from 'helpers/uiHelpers'
import { useAutoFocus } from 'hooks'
import { useOrgTimeZone } from 'hooks/DateFormatHooks'

import { getErrorDetails } from './formikMaterial'

interface FieldToPickerInput {
  disabled?: boolean
}

function fieldToPickerProps<T>({
  field,
  form,
  disabled = false,
  ...props
}: FieldToPickerInput & FieldProps<Moment> & T) {
  const { isSubmitting, setFieldValue, setFieldError, setFieldTouched } = form
  const { name, value } = field
  const { showError } = getErrorDetails(field, form)
  const momentValue = value ? (moment.isMoment(value) ? value : moment(value)) : null

  const pickerProps: DateTimePickerProps<Moment> = {
    disabled,
    name,
    value: momentValue,
    onChange: (date, { validationError }) => {
      if (validationError) {
        return
      }
      setFieldValue(name, date)
    },
    onError: (error) => {
      if (isString(error)) {
        setFieldError(name, formatDateTimeValidationError(error))
      }
    },
    onClose: () => {
      setFieldTouched(name, true)
    },
    slotProps: {
      textField: {
        onBlur: () => setFieldTouched(name, true),
        // TODO: add a prop so that showing field errors is opt-in
        // helperText: fieldError,
        disabled: isSubmitting || disabled,
        error: showError,
      },
    },
  }

  return {
    ...pickerProps,
    ...props,
  }
}

type TextFieldProps = {
  margin?: 'none' | 'dense' | 'normal'
  size?: 'small' | 'medium'
  variant?: 'standard' | 'outlined' | 'filled'
  fullWidth?: boolean
}

// By default, ignore the change event
// if there is any validation error
// for parity with earlier functionality
const defaultHandleOnChangeValidationError = (validationError: DateValidationError | undefined) => !!validationError

type HandleOnChangeValidationError = (validationError: DateValidationError | undefined) => boolean

/** *****************
 * Date picker
 ***************** */

export type FormikDatePickerProps = {
  orgtime?: string
  testId?: string
  dateOnly?: boolean
  handleOnChangeValidationError?: HandleOnChangeValidationError
} & Omit<DatePickerProps<Moment>, 'value' | 'onChange'> &
  FieldToPickerInput &
  TextFieldProps

export const datePickerTextFieldRootClassName = 'date-picker__textfield-root'

// Date Picker
export const FormikDatePicker = ({
  orgtime = '12:00',
  testId,
  dateOnly = false,
  margin = 'none',
  variant,
  size,
  fullWidth,
  slotProps,
  label,
  handleOnChangeValidationError = defaultHandleOnChangeValidationError,
  ...restProps
}: FormikDatePickerProps & FieldProps<Moment>) => {
  const orgTimeZone = useOrgTimeZone()
  const withAutoFocusProps = useAutoFocus(restProps)
  const { slotProps: pickerSlotProps, ...restPickerProps } = fieldToPickerProps(withAutoFocusProps)

  return (
    <DatePicker
      format="MM/DD/YYYY"
      label={label}
      {...restPickerProps}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          ...pickerSlotProps?.textField,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore This is allowed on a DOM element, but the types don't allow it
          'data-testid': testId,
          className: datePickerTextFieldRootClassName,
          margin,
          fullWidth,
          size,
          variant,
        },
      }}
      onChange={(date, { validationError }) => {
        if (handleOnChangeValidationError(validationError)) return
        // (kc): sometimes we only want the date
        // (as is the case for survey date inputs)
        // and other times we care about the date
        // with the org's local time.
        // (rg): To be clear, this should be cleaned up, but I believe there
        // are users of this picker that depend on the time still.
        const value = date ? (dateOnly ? date?.format('YYYY-MM-DD') : orgDateTime(date, orgtime, orgTimeZone)) : null

        withAutoFocusProps.form.setFieldValue(withAutoFocusProps.field.name, value)
      }}
    />
  )
}

type DateFieldProps = FormikDatePickerProps & FieldAttributes<unknown>

export const DateField = (props: DateFieldProps) => <Field {...props} component={FormikDatePicker} />

/** *****************
 * Time picker
 ***************** */

export type FormikTimePickerProps = Omit<TimePickerProps<Moment>, 'value' | 'onChange'> &
  FieldToPickerInput &
  TextFieldProps
// Time Picker
export const FormikTimePicker = ({
  format = 'HH:mm:ss',
  slotProps,
  variant,
  margin,
  fullWidth,
  size,
  ...props
}: FormikTimePickerProps & FieldProps<Moment>) => {
  const { slotProps: pickerSlotProps, ...restPickerProps } = fieldToPickerProps(props)
  return (
    <TimePicker
      {...restPickerProps}
      format={format}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          ...pickerSlotProps?.textField,
          margin,
          fullWidth,
          size,
          variant,
        },
      }}
    />
  )
}

type TimeFieldProps = FormikTimePickerProps & FieldAttributes<unknown>

export const TimeField = (props: TimeFieldProps) => <Field {...props} component={FormikTimePicker} />

/** *****************
 * DateTime picker
 ***************** */

export type FormikDateTimePickerProps = {
  testId?: string
} & Omit<DateTimePickerProps<Moment>, 'value' | 'onChange'> &
  FieldToPickerInput &
  TextFieldProps

// DateTime Picker
export const FormikDateTimePicker = ({
  testId,
  margin,
  size,
  fullWidth,
  slotProps,
  ...props
}: FormikDateTimePickerProps & FieldProps<Moment>) => {
  const pickerProps = fieldToPickerProps(props)

  // Convert the value to a moment object that is set to use the organization time zone.
  const orgTimeZone = useOrgTimeZone()
  pickerProps.value = pickerProps.value ? moment(pickerProps.value).tz(orgTimeZone) : null

  const { slotProps: pickerSlotProps, ...restPickerProps } = pickerProps

  return (
    <DateTimePicker
      format="MM/DD/YYYY HH:mm:ss"
      {...restPickerProps}
      slotProps={{
        ...slotProps,
        textField: {
          ...slotProps?.textField,
          ...pickerSlotProps?.textField,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore This is allowed on a DOM element, but the types don't allow it
          'data-testid': testId,
          margin,
          fullWidth: true,
          size,
        },
      }}
      onChange={(date, { validationError }) => {
        if (validationError) {
          return
        }
        // The picker operates in the browser timezone, we need to overwrite with the org time zone
        const orgDate = date ? stampDateTimeWithOrgTz(date, orgTimeZone) : null

        props.form.setFieldValue(props.field.name, orgDate)
      }}
    />
  )
}

export type DateTimeFieldProps = FormikDateTimePickerProps & FieldConfig<Moment>

export const DateTimeField = (props: DateTimeFieldProps) => <Field {...props} component={FormikDateTimePicker} />

import React from 'react'

import NumberFormat, { NumberFormatProps } from 'react-number-format'

export type FormattedInputProps = Omit<NumberFormatProps, 'getInputRef' | 'name' | 'onValueChange'> & {
  name: string
  inputRef?: React.Ref<HTMLInputElement>
  onChange?: (e: { target: { name: string; value: string | undefined | null } }) => void
  transformValue?: (newFormattedValue: string) => string | undefined | null
}

// This provides a Material-UI compatible NumberFormat component from react-number-format.
// Can be used directly as a replacement for Material-UI Input.
export function FormattedInput({ inputRef, name, onChange, transformValue, ...otherProps }: FormattedInputProps) {
  return (
    <NumberFormat
      getInputRef={inputRef}
      name={name}
      onValueChange={(values) => {
        if (onChange) {
          const value = transformValue ? transformValue(values.formattedValue) : values.value

          onChange({
            target: {
              name,
              value,
            },
          })
        }
      }}
      {...otherProps}
    />
  )
}

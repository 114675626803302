import React from 'react'

import { IconButton, InputAdornment } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import { CloseIcon } from 'icons'
import { Theme, WithStyles } from 'types/hb'
import { assertExhaustive } from 'utils/typeAssertions'

const styles = (theme: Theme) => ({
  clearIcon: {
    color: theme.palette.text.secondary,
    '&:hover': {
      background: 'none',
    },
  },
})

interface Props extends WithStyles<typeof styles> {
  mode: 'clear' | 'none'
  onClearClick: (event: React.MouseEvent) => void
}

export const SearchInputEndAdornment = withStyles(styles)(({ classes, mode, onClearClick }: Props) => {
  switch (mode) {
    case 'clear':
      return (
        <InputAdornment position="end">
          <IconButton className={classes.clearIcon} onClick={onClearClick} data-testid="search-clear" size="large">
            <CloseIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      )
    case 'none':
      return <span />
    default:
      return assertExhaustive(mode)
  }
})

export default SearchInputEndAdornment

import { gql } from '@apollo/client'
import { Avatar } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import classnames from 'classnames'

import { camelCase } from 'lodash'

import { legacyColors, randomLegacyColor } from 'components/colors'
import { isDark } from 'helpers/colors'
import AvatarBg from 'images/avatar-bg.svg'

import { WithStyles, Theme } from 'types/hb'

enum BackgroundPosition {
  TopLeft = 'top left',
  TopCenter = 'top center',
  TopRight = 'top right',
  CenterLeft = 'center left',
  CenterCenter = 'center center',
  CenterRight = 'center right',
  BottomLeft = 'bottom left',
  BottomCenter = 'bottom center',
  BottomRight = 'bottom right',
}

const styles = (theme: Theme) => ({
  root: {
    fontSize: '15px',
    fontWeight: 500,
  },
  tiny: {
    width: '24px',
    height: '24px',
    fontSize: '12px',
  },
  small: {
    width: '30px',
    height: '30px',
    fontSize: '12px',
  },
  xs: {
    width: 18,
    height: 18,
    fontSize: 8,
  },
  ...Object.values(BackgroundPosition).reduce(
    (positions, backgroundPosition, i) => ({
      ...positions,
      [`variant${i}`]: {
        backgroundImage: `url(${AvatarBg})`,
        backgroundSize: '100px',
        backgroundBlendMode: 'soft-light',
        backgroundPosition,
      },
    }),
    {}
  ),
  ...Object.keys(legacyColors.palette.styleguide).reduce(
    (colors, color) => ({
      ...colors,
      [camelCase(color)]: {
        backgroundColor: (legacyColors.palette.styleguide as Record<string, string>)[color],
        color: isDark((legacyColors.palette.styleguide as Record<string, string>)[color])
          ? legacyColors.palette.styleguide.white
          : legacyColors.palette.styleguide.nearBlack,
      },
    }),
    {}
  ),
  ...Object.keys(theme.palette.styleguide).reduce(
    (colors, color) => ({
      ...colors,
      [camelCase(color)]: {
        backgroundColor: (theme.palette.styleguide as Record<string, string>)[color],
        color: isDark((theme.palette.styleguide as Record<string, string>)[color])
          ? theme.palette.styleguide.white
          : theme.palette.styleguide.nearBlack,
      },
    }),
    {}
  ),
})

export interface Props extends WithStyles<typeof styles> {
  children: any
  className?: string
  color?: string
  variant?: number
  size?: 'small' | 'tiny' | 'xs'
}

// TODO: convert to use `styled`, named export, encapsulated gql fragment
const AccountAvatar = withStyles(styles)(function AccountAvatar({
  children,
  classes,
  className,
  color = 'lightGray2',
  variant,
  size,
}: Props) {
  const avatarClasses = classnames(
    classes.root,
    (classes as { [key: string]: string })[color === 'random' ? randomLegacyColor() : camelCase(color)],
    (classes as { [key: string]: string })[`variant${variant}`],
    {
      [classes.small]: size === 'small',
      [classes.tiny]: size === 'tiny',
      [classes.xs]: size === 'xs',
    },
    className
  )

  return <Avatar className={avatarClasses}>{children}</Avatar>
})

export const accountAvatarDetailsFragment = gql`
  fragment AccountAvatarDetails on Account {
    avatarColor
    avatarVariant
    firstName
    fullName
    lastName
    token
  }
`

export default AccountAvatar

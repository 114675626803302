import { Autorenew } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Form, Formik, FormikHelpers } from 'formik'

import { useDispatch, useSelector } from 'actions/store'
import { useDashboardUserSettingsActions } from 'actions/userSettingsActions'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import ConfirmDialog from 'components/library/ConfirmDialog'
import { LinkButton } from 'components/library/LinkButton'
import { TextField } from 'components/material/Form'

import {
  DeleteSavedSearchQueryMutation,
  DeleteSavedSearchQueryMutationVariables,
  UpsertSavedSearchQueryMutation,
  UpsertSavedSearchQueryMutationVariables,
} from 'dashboards/reviews/gql/__generated__/searchReviews.queries.generated'
import {
  GET_SAVED_SEARCH_QUERY,
  DELETE_SAVED_SEARCH_QUERY,
  UPSERT_SAVED_SEARCH_QUERY,
} from 'dashboards/reviews/gql/searchReviews.queries'

import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { useSearchRequestInfo, useDashboardFilters } from 'dashboards/shared/react/getNamedQueryGroups'
import { getCurrentAccount, getCurrentOrganization } from 'helpers/stateHelpers'
import { useToggle } from 'hooks'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { RenameIcon, SaveIcon, TrashOutlineIcon } from 'icons'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { CurrentAccount, Theme } from 'types/hb'
import { normalizeRequest } from 'utils/query/normalization'

import { useFetchCustomViews } from './hooks/fetchCustomViews'

const useStyles = makeStyles((theme: Theme) => ({
  dialogInput: {
    padding: `${theme.spacing()} 0px`,
  },
}))

export type FilterAction = 'save' | 'clear' | 'rename' | 'delete' | 'update' | 'save-as-new'
interface SharedButtonProps {
  actionType: FilterAction
}
interface IconOnlyProp {
  iconOnly: boolean
}

const ClearFiltersButton = () => {
  const dispatch = useDispatch()

  const { updateDashboardUserSettings } = useDashboardUserSettingsActions()

  const dashboardsActions = useDashboardActions()
  const { unfilteredFilter } = useDashboardFilters()

  const onClick = () => {
    const filterName = unfilteredFilter.name
    dispatch(
      dashboardsActions.filters.changeView.action({ ...unfilteredFilter.request, title: unfilteredFilter.title })
    )

    dispatch(
      updateDashboardUserSettings({
        recentDashboardView: filterName,
      })
    )
  }

  return <LinkButton label="Clear filters" onClick={onClick} />
}

const UpsertConfirmDialog = ({
  actionType,
  isOpen,
  toggleDialog,
}: SharedButtonProps & { isOpen: boolean; toggleDialog: () => void }) => {
  const { dialogInput } = useStyles()
  const { refetch: refetchCustomViews } = useFetchCustomViews()
  const [upsertSavedSearch, { loading }] = useHbMutation<
    UpsertSavedSearchQueryMutation,
    UpsertSavedSearchQueryMutationVariables
  >(UPSERT_SAVED_SEARCH_QUERY)
  const dispatch = useDispatch()

  const dashboardType = useDashboardContext()
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const {
    filterBannerActionButton: { placeholderText },
  } = useDashboardConfig()

  const validAppliedFilters = useSelector(dashboardsSelectors.filters.applied.valid)
  const currentViewTitle = useSelector(dashboardsSelectors.currentViewTitle)
  const currentViewToken = useSelector(dashboardsSelectors.currentViewToken)
  const isRenaming = actionType === 'rename'

  const handleSave = async (newFilterName: string, formik: FormikHelpers<{ filterName: string }>) => {
    if (!validAppliedFilters) return

    // Token is used when renaming, as it is an update
    const viewToken = actionType !== 'save-as-new' && currentViewToken
    const { errors } = await upsertSavedSearch({
      variables: {
        token: viewToken || '',
        name: newFilterName,
        search: normalizeRequest(validAppliedFilters),
        dashboardType,
      },
    })

    if (!errors) {
      toggleDialog()
      dispatch(dashboardsActions.currentViewTitle.set(newFilterName))
      refetchCustomViews()
    }
    formik.setSubmitting(false)
  }

  return (
    <Formik
      initialValues={{ filterName: '' }}
      enableReinitialize
      onSubmit={(values, formik) => {
        handleSave(values.filterName, formik)
      }}
    >
      {({ values, submitForm, resetForm }) => (
        <HbDialog
          id={`${isRenaming ? 'rename' : 'save'}-filter-view`}
          title={isRenaming ? 'Rename filter view' : 'Save filter view'}
          confirmText="Save & continue"
          open={isOpen}
          confirmButtonDisabled={
            loading || values.filterName === currentViewTitle || values.filterName.trim().length === 0
          }
          cancelButtonDisabled={loading}
          onClose={() => {
            toggleDialog()
            resetForm()
          }}
          onConfirm={submitForm}
          loading={loading}
          content={
            <Form>
              <HbText color="secondary">{`${isRenaming ? 'Rename' : 'Name'} your filter`}</HbText>
              <TextField
                fullWidth
                className={dialogInput}
                name="filterName"
                size="small"
                variant="outlined"
                placeholder={isRenaming ? currentViewTitle : placeholderText}
                autoFocus
                data-testid="upsert_custom_view_name"
              />
            </Form>
          }
        />
      )}
    </Formik>
  )
}

export const SaveButton = ({ actionType, iconOnly }: SharedButtonProps & IconOnlyProp) => {
  const dialogOpen = useToggle(false)

  return (
    <>
      <HbButton
        Icon={SaveIcon}
        variant="textSecondary"
        label={actionType === 'save-as-new' ? 'Save as new' : 'Save filter view'}
        onClick={() => dialogOpen.toggle()}
        iconOnly={iconOnly}
        tooltip
      />
      <UpsertConfirmDialog isOpen={dialogOpen.value} actionType={actionType} toggleDialog={() => dialogOpen.toggle()} />
    </>
  )
}

const RenameButton = ({ iconOnly }: IconOnlyProp) => {
  const dialogOpen = useToggle(false)

  return (
    <>
      <HbButton
        Icon={RenameIcon}
        variant="textSecondary"
        label="Rename filter view"
        onClick={() => dialogOpen.toggle()}
        iconOnly={iconOnly}
        tooltip
      />
      <UpsertConfirmDialog isOpen={dialogOpen.value} actionType="rename" toggleDialog={() => dialogOpen.toggle()} />
    </>
  )
}

const UpdateButton = ({ iconOnly }: IconOnlyProp) => {
  const [upsertSavedSearch] = useHbMutation<UpsertSavedSearchQueryMutation, UpsertSavedSearchQueryMutationVariables>(
    UPSERT_SAVED_SEARCH_QUERY
  )
  const { refetch: refetchCustomViews } = useFetchCustomViews()
  const dashboardType = useDashboardContext()
  const dashboardsSelectors = useDashboardSelectors()
  const validAppliedFilters = useSelector(dashboardsSelectors.filters.applied.valid)
  const currentViewTitle = useSelector(dashboardsSelectors.currentViewTitle)
  const currentViewToken = useSelector(dashboardsSelectors.currentViewToken)

  const handleUpdate = async () => {
    // Token is used when renaming, as it is an update
    await upsertSavedSearch({
      variables: {
        token: currentViewToken || '',
        name: currentViewTitle,
        search: normalizeRequest(validAppliedFilters),
        dashboardType,
      },
    })

    refetchCustomViews()
  }

  return (
    <HbButton
      Icon={Autorenew}
      variant="textSecondary"
      label="Update filter view"
      onClick={handleUpdate}
      iconOnly={iconOnly}
      tooltip
    />
  )
}

const DeleteButton = ({ iconOnly }: IconOnlyProp) => {
  const dispatch = useDispatch()
  const [deleteSavedSearch, { loading }] = useHbMutation<
    DeleteSavedSearchQueryMutation,
    DeleteSavedSearchQueryMutationVariables
  >(DELETE_SAVED_SEARCH_QUERY)

  const showDeleteWarning = useToggle(false)
  const currentAccount = useSelector(getCurrentAccount)
  const currentOrganization = useSelector(getCurrentOrganization)
  const dashboardType = useDashboardContext()
  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const { defaultFilter } = useDashboardFilters()

  const customViews = useSelector(dashboardsSelectors.customViews)

  const { refetch: refetchCustomViews } = useFetchCustomViews()

  const { viewTitle } = useSearchRequestInfo()

  const handleDelete = async () => {
    const viewToken = customViews?.find((view) => view.name === viewTitle)?.token
    if (!viewToken) return

    const { errors } = await deleteSavedSearch({
      variables: { token: viewToken },
      refetchQueries: [{ query: GET_SAVED_SEARCH_QUERY, variables: { dashboardType } }],
      awaitRefetchQueries: true,
    })

    if (!errors) {
      showDeleteWarning.toggle()
      refetchCustomViews()
    }

    // reset the standard filter to the  default. On the reviews dashboard this is 'My Cases'.
    const { request, title } = defaultFilter(currentAccount, currentOrganization)
    dispatch(dashboardsActions.filters.changeView.action({ ...request, title }))
  }

  return (
    <>
      <HbButton
        Icon={TrashOutlineIcon}
        variant="textSecondary"
        label="Delete filter view"
        onClick={() => showDeleteWarning.toggle()}
        data-testid="delete_custom_view"
        iconOnly={iconOnly}
        tooltip
      />
      <ConfirmDialog
        title="Delete filter view"
        confirmText="Yes, delete"
        open={showDeleteWarning.value}
        onClose={() => showDeleteWarning.toggle()}
        onConfirm={handleDelete}
        loading={loading}
        confirmDisabled={loading}
        cancelDisabled={loading}
      >
        <HbText>
          Are you sure you want to delete <HbText bold>{`"${viewTitle}"`}</HbText>?
        </HbText>
      </ConfirmDialog>
    </>
  )
}

export function FilterBannerActionButton({
  filterAction,
  iconOnly,
}: {
  filterAction: FilterAction
  iconOnly: boolean
  currentAccount?: CurrentAccount
  onClick?: () => void
}) {
  switch (filterAction) {
    case 'clear':
      return <ClearFiltersButton />
    case 'delete':
      return <DeleteButton iconOnly={iconOnly} />
    case 'rename':
      return <RenameButton iconOnly={iconOnly} />
    case 'save':
    case 'save-as-new':
      return <SaveButton actionType={filterAction} iconOnly={iconOnly} />
    case 'update':
      return <UpdateButton iconOnly={iconOnly} />
    default:
      return null
  }
}

export default FilterBannerActionButton

import React from 'react'

import classnames from 'classnames'
import { camelCase, upperFirst } from 'lodash'

import { getIconForDataType } from './DataIcon/helpers'

import * as all from './all'
import { IconName } from './types'

interface IconProps {
  name: IconName
  alt?: string
  classes?: any
  className?: string
  fontSize?: string
}
/**
 *
 * This is a deprecated approach.
 * Should prefer to import the icon directly from its file
 */
export const Icon = ({ name, ...rest }: IconProps) => {
  // Override icons for HBv2 redesign
  // Use the helper to determine if there's a data type icon, hack the type
  const IconForDataType = getIconForDataType(name as any)
  if (IconForDataType) {
    return <IconForDataType className={rest.className} />
  }

  const Component = (all as any)[`${upperFirst(camelCase(name))}Icon`]
  if (!Component) {
    return null
  }

  return <Component {...rest} />
}

/**
 *
 * This is a deprecated approach.
 * Should prefer to import the icon directly from its file
 */
type WrappedIconProps = {
  name: IconName
  className?: string
  size?: 'large' | 'medium' | 'small'
  button?: boolean
} & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export const WrappedIcon = ({ name, className, size, button, ...otherProps }: WrappedIconProps) => {
  const classes = classnames(
    'hb-icon__wrap',
    {
      'hb-icon__wrap--large': size === 'large',
      'hb-icon__wrap--medium': size === 'medium',
      'hb-icon__wrap--small': size === 'small',
      'hb-icon__wrap--button': button,
    },
    `hb-icon__wrap--${name.replace('_', '-')}`,
    className
  )

  return (
    <div className={classes} {...otherProps}>
      <Icon name={name} />
    </div>
  )
}

export * from './all'

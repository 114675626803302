import { MouseEvent } from 'react'

import { GetApp } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { getHbGradient } from 'components/colors'
import { hasPermission } from 'helpers/stateHelpers'
import AutoAwesome from 'icons/AutoAwesome'
import { BadgePermissionsEnum } from 'types/api'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  buttonWrapper: {
    background: getHbGradient(1),
    borderRadius: theme.spacing(1),
    padding: 1,
  },
  button: {
    background: 'white',
    border: 'none',
    color: theme.palette.styleguide.hbBlueMedium,
    '&:hover': {
      border: 'none',
    },
  },
}))
function AttachmentHeaderActionButtons({
  onDownload,
  toggleSummary,
  isSummarizable,
  iconOnly,
}: {
  onDownload?: (_e: MouseEvent) => void
  toggleSummary?: () => void
  isSummarizable?: boolean
  iconOnly?: boolean
}) {
  const userCanDownloadCaseData = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.DownloadCaseDocuments)
  )
  const classes = useStyles()

  return (
    <div className={classes.actions}>
      {userCanDownloadCaseData && onDownload && (
        <HbButton Icon={GetApp} iconOnly={iconOnly} label="Download" onClick={onDownload} variant="secondary" />
      )}
      {isSummarizable && toggleSummary && (
        <div className={classes.buttonWrapper}>
          <HbButton
            label="Summarize"
            Icon={AutoAwesome}
            onClick={toggleSummary}
            variant="secondary"
            className={classes.button}
          />
        </div>
      )}
    </div>
  )
}

export default AttachmentHeaderActionButtons

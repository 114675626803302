import { useCallback, useEffect, useRef } from 'react'

import { useLazyQuery } from '@apollo/client'
import * as Sentry from '@sentry/react'

import { useDispatch, useSelector } from 'actions/store'
import { BlueSuccessIcon, HbLogoIcon } from 'components/HbComponents/HbNotistack/HbNotistackSnackbar'
import { useLongProcessSnackbars } from 'components/HbComponents/HbNotistack/HbNotistackSnackbar.hooks'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useViewAttachment } from 'components/cases/Tabs/Files/caseFile.hooks'
import { CaseFile } from 'components/cases/Tabs/Files/caseFileTypes'
import { FetchAttachmentSummaryDocument } from 'components/cases/Tabs/shared/AttachmentSummary/__generated__/FetchAttachmentSummaryQuery.generated'
import AutoAwesome from 'icons/AutoAwesome'
import {
  AttachmentSummaryItem,
  removeCurrentAttachment as _removeCurrentAttachment,
  setCaseTableAttachmentSummaryToken,
} from 'reducers/attachmentSummary.reducer'
import { openTab } from 'reducers/tabReducer'
import { AttachmentSummaryStatus } from 'types/api'
import { navigateToInternalUrl } from 'utils/navigationHelpers'
import { assertExhaustive } from 'utils/typeAssertions'

import { isContentTypeViewable } from '../../Files/caseFilesTableHelpers'

import { POLL_INTERVAL } from './AttachmentSummary.constants'

const REPORT_PENDING_AS_FAILURE_AFTER = 5 * 60 * 1000

const useNavigateToCaseWithAttachmentSummaryTab = ({
  type,
  caseFileToken,
  questionToken,
  clientId,
  contentType,
}: {
  type: CaseFile['type']
  caseFileToken: string
  questionToken?: string
  clientId?: string
  contentType?: string | null
}) => {
  const dispatch = useDispatch()
  const viewAttachment = useViewAttachment()
  return useCallback(
    async ({ caseToken }: { caseToken: string }) => {
      if (window.location.pathname !== `/dashboard/cases/${caseToken}`) {
        // If not on the case that the search originated from, navigate to the case first
        await dispatch(navigateToInternalUrl(undefined, '/dashboard/cases/:caseToken', { caseToken }))
      }
      if (!isContentTypeViewable(contentType || '')) {
        dispatch(openTab({ tab: { type: 'files' } }))
        // text files are not viewable in the attachment tab
        dispatch(setCaseTableAttachmentSummaryToken(caseFileToken))
        return
      }

      // open the attachment
      viewAttachment({
        type,
        contentType: contentType ?? '',
        attachmentToken: caseFileToken,
        questionToken,
        clientId,
        summaryOpen: true,
      })
    },
    [dispatch, type, caseFileToken, questionToken, clientId, viewAttachment, contentType]
  )
}
/**
 * This component is responsible for polling the server for the status of an attachment summary.
 * It's modeled after MiddeskSnackbars.tsx
 */
const AttachmentSummarySnackbar = (props: { attachment: AttachmentSummaryItem }) => {
  const { attachment } = props

  const { attachmentSummaryToken, investigationToken, displayName } = attachment
  const dispatch = useDispatch()
  const updateLongProcessSnackbars = useLongProcessSnackbars()
  const navigateToCaseWithAttachmentSummaryTab = useNavigateToCaseWithAttachmentSummaryTab(attachment)

  const [pollAttachmentSummary, { stopPolling }] = useLazyQuery(FetchAttachmentSummaryDocument, {
    pollInterval: POLL_INTERVAL,
    fetchPolicy: 'network-only',
  })

  const hasStartedPolling = useRef<boolean>(false)
  const startedPollingTs = useRef<number | null>(null)
  const displayNameWithEllipsis = displayName.length > 20 ? `${displayName.slice(0, 20)}...` : displayName

  const removeCurrentAttachment = useCallback(() => {
    dispatch(_removeCurrentAttachment({ attachmentSummaryToken }))
  }, [dispatch, attachmentSummaryToken])

  const handleLoading = useCallback(
    (snackbarID: string) => {
      updateLongProcessSnackbars(snackbarID, {
        status: 'loading',
        message: (
          <div>
            <HbText bold size="md" noWrap>
              Writing summary for {displayNameWithEllipsis}
            </HbText>
            <HbText bold color="blue" size="md" block>
              Powered by Hummingbird AI <AutoAwesome />
            </HbText>
          </div>
        ),
        variant: 'white',
        iconOverride: HbLogoIcon,
      })
    },
    [displayNameWithEllipsis, updateLongProcessSnackbars]
  )

  const reportSummaryFailureToSentry = useCallback(
    (attachmentIsTooBig: boolean, message: string) => {
      Sentry.withScope((scope) => {
        scope.setExtra('investigationToken', investigationToken)
        scope.setExtra('attachmentToken', attachment.caseFileToken)
        scope.setExtra('attachmentSummaryToken', attachmentSummaryToken)
        scope.setExtra('attachmentIsTooBig', attachmentIsTooBig)
        if (startedPollingTs.current) {
          const latencyInSeconds = (Date.now() - startedPollingTs.current) / 1000
          scope.setExtra('attachmentSummaryLatencyInSeconds', latencyInSeconds)
        }
        Sentry.captureMessage(`AttachmentSummaryError: ${message}`, 'fatal')
      })
    },
    [investigationToken, attachmentSummaryToken, attachment.caseFileToken]
  )

  const handleError = useCallback(
    (message: string, snackbarID: string) => {
      removeCurrentAttachment()
      updateLongProcessSnackbars(snackbarID, {
        status: 'error',
        message,
      })
    },
    [removeCurrentAttachment, updateLongProcessSnackbars]
  )

  const handleSuccess = useCallback(
    (snackbarID: string) => {
      updateLongProcessSnackbars(snackbarID, {
        status: 'success',
        message: (
          <div>
            <HbText bold size="md">
              Summary for {displayNameWithEllipsis} is ready!
            </HbText>
            <HbText bold color="blue" size="md" block>
              Powered by Hummingbird AI <AutoAwesome />
            </HbText>
          </div>
        ),
        variant: 'white',
        iconOverride: BlueSuccessIcon,
        buttonProps: {
          text: 'View',
          onClick: () => {
            navigateToCaseWithAttachmentSummaryTab({ caseToken: investigationToken })
          },
        },
      })
      removeCurrentAttachment()
    },
    [
      displayNameWithEllipsis,
      updateLongProcessSnackbars,
      removeCurrentAttachment,
      navigateToCaseWithAttachmentSummaryTab,
      investigationToken,
    ]
  )

  useEffect(() => stopPolling, [stopPolling])

  useEffect(() => {
    const snackbarID = attachmentSummaryToken

    if (hasStartedPolling.current || !investigationToken || !attachmentSummaryToken) {
      return
    }

    handleLoading(snackbarID)

    pollAttachmentSummary({
      variables: { investigationToken, attachmentSummaryToken },
      onError: (error) => {
        reportSummaryFailureToSentry(false, error.message || 'Attachment summary Error')
        handleError(error.message || 'Something went wrong', snackbarID)
      },
      onCompleted: ({ investigation }) => {
        if (!investigation) {
          return
        }
        const { attachmentSummary } = investigation
        switch (attachmentSummary.status) {
          case AttachmentSummaryStatus.Pending:
            // if it takes more than 5 minutes
            if (startedPollingTs.current && Date.now() - startedPollingTs.current > REPORT_PENDING_AS_FAILURE_AFTER) {
              reportSummaryFailureToSentry(attachmentSummary.tooBig, 'Attachment summary took too long')
            }
            break
          case AttachmentSummaryStatus.Complete: {
            handleSuccess(snackbarID)
            break
          }
          case AttachmentSummaryStatus.Failed: {
            reportSummaryFailureToSentry(attachmentSummary.tooBig, 'Attachment summary failed')
            handleError(
              attachmentSummary.tooBig
                ? 'Sorry, but that document is too large to process'
                : 'Attachment summary has failed, please try again later',
              snackbarID
            )
            break
          }
          default:
            assertExhaustive(attachmentSummary.status)
        }
      },
    })

    hasStartedPolling.current = true
    startedPollingTs.current = Date.now()
  }, [
    investigationToken,
    attachmentSummaryToken,
    handleError,
    handleLoading,
    handleSuccess,
    pollAttachmentSummary,
    attachment.caseFileToken,
    reportSummaryFailureToSentry,
  ])

  return null
}

export const AttachmentSummarySnackbars = () => {
  const currentAttachments = useSelector((state) => state.attachmentSummaries.currentAttachments)
  return (
    <>
      {Object.values(currentAttachments).map((attachment) => (
        <AttachmentSummarySnackbar key={attachment.attachmentSummaryToken} attachment={attachment} />
      ))}
    </>
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const RenameIcon: FC<SvgProps> = ({ style = { fill: 'none' }, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 18 18" style={style}>
    <path d="M9 15H15.75" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    <path
      d="M12.375 2.62493C12.6734 2.32656 13.078 2.15894 13.5 2.15894C13.7089 2.15894 13.9158 2.20009 14.1088 2.28004C14.3019 2.36 14.4773 2.47719 14.625 2.62493C14.7727 2.77266 14.8899 2.94805 14.9699 3.14108C15.0498 3.33411 15.091 3.54099 15.091 3.74993C15.091 3.95886 15.0498 4.16574 14.9699 4.35877C14.8899 4.5518 14.7727 4.72719 14.625 4.87493L5.25 14.2499L2.25 14.9999L3 11.9999L12.375 2.62493Z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default RenameIcon

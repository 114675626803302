// A small library for resolving urls with :tokens in them

export type UrlResolverTokenData = { [k: string]: string }
export type UrlResolverQuery = { [k: string]: any }

class UrlResolver {
  // Borrowed and seriously modified from StackOverflow:
  // https://stackoverflow.com/questions/1714786/query-string-encoding-of-a-javascript-object
  public serialize(object: any, prefix = ''): string {
    const parts = []
    if (object === null || object === undefined) {
      return ''
    }

    if (Array.isArray(object) && object.length > 0) {
      for (let index = 0; index < object.length; index += 1) {
        parts.push(this.serialize(object[index], `${prefix}[]`))
      }
    } else if (Array.isArray(object)) {
      parts.push(this.serialize('', `${prefix}[]`))
    } else if (typeof object === 'object') {
      Object.keys(object).forEach((property) => {
        parts.push(this.serialize(object[property], prefix ? `${prefix}[${property}]` : property))
      })
    } else {
      return `${encodeURIComponent(prefix)}=${encodeURIComponent(object)}`
    }

    return parts.filter((part) => part && part.length > 0).join('&')
  }

  public resolve(urlPattern: string, tokenData: UrlResolverTokenData = {}, query: UrlResolverQuery = {}): string {
    const tokenMatch = urlPattern.match(/:(\w+)/g)
    const queryString = Object.keys(query ?? {}).length > 0 ? this.serialize(query) : ''
    const fullUrlPattern = queryString.length > 0 ? `${urlPattern}?${queryString}` : urlPattern

    if (!tokenMatch) {
      return fullUrlPattern
    }
    const missing = tokenMatch.filter((t) => Object.keys(tokenData).indexOf(t.slice(1)) === -1)

    if (missing.length > 0) {
      throw new Error(`${fullUrlPattern} is missing tokens: [${missing.join(', ')}]`)
    }

    return Object.keys(tokenData).reduce(
      (pattern, key: string) => pattern.replace(`:${key}`, tokenData[key]),
      fullUrlPattern
    )
  }
}

export default UrlResolver

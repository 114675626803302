// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { SnakeDataTypeKey } from 'actions/importingFields.types'
import { useCreatableLibraryTypes } from 'components/cases/Tabs/AddToCaseButton.hooks'
import { EntityTypeItem } from 'components/cases/information/AddNewMenu'
import { MenuListItem } from 'components/library/Menu'
import { ImportableType } from 'reducers/applicationReducer'
import { Theme } from 'types/hb'

interface Props {
  openNewDialog: (dataType: SnakeDataTypeKey) => void
}

const useItemStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(0),
    margin: theme.spacing(1.5),
  },
  fullWidthItem: {
    width: '100%',
    borderRadius: theme.spacing(),
  },
}))

const supportedTypes: ImportableType[] = ['individual', 'institution', 'business', 'product'] as const
export default function ProfilesAddMenuItems(props: Props) {
  const { openNewDialog } = props
  const classes = useItemStyles()
  const dataTypes = useCreatableLibraryTypes().filter((t) => supportedTypes.includes(t))

  return (
    <>
      {dataTypes.map((dataType) => (
        <MenuListItem key={dataType} testId={`menu_item_new_${dataType}`} className={classes.menuItem}>
          <EntityTypeItem
            dataType={dataType}
            onClick={() => openNewDialog(dataType)}
            className={classes.fullWidthItem}
            noColor
          />
        </MenuListItem>
      ))}
    </>
  )
}

import { createAction, createAsyncThunk } from '@reduxjs/toolkit'

import { State } from 'actions/store'
import { dashboardSelectorsMap } from 'reducers/dashboards/dashboards.selectors'
import { DashboardState } from 'reducers/dashboards/dashboards.types'

import { DashboardSlice } from './dashboards.constants'
import { pushQuery } from './queryStringTools'

type Prefix<T extends string> = `dashboards/${DashboardSlice}/${T}`

export const generateNameMaker =
  (slice: DashboardSlice) =>
  <T extends string>(name: T) =>
    `dashboards/${slice}/${name}` as const

export const generateActionMaker = <T extends DashboardSlice>(slice: T) => {
  const makeName = generateNameMaker(slice)
  return <U>() =>
    <R extends string>(name: R) =>
      createAction<U extends keyof DashboardState<T> ? DashboardState<T>[U] : U, Prefix<R>>(makeName(name))
}

/**
 * In order to update the query string, we need certain actions to "report their results" so to speak. This thunk wraps
 * simple actions (from `makeAction`) and FIRST performs their reduction. Once they are finished reducing, the thunk
 * then selects the new filters from redux and updates the query string with those values.
 *
 * The return object has two keys:
 *
 *   - `.action` is the thunk. It should be dispatched to kick off the execution of the full thunk.
 *
 *   - `.fulfilled` is the for the reducer to use. It is the ORIGINAL WRAPPED action, so the reducer can update before
 *      the thunk is finished.
 *
 * @param name the action name to show in redux
 */

export const makePushQueryThunk = (slice: DashboardSlice) => {
  const dashboardsSelectors = dashboardSelectorsMap[slice]
  const makeName = generateNameMaker(slice)
  const makeAction = generateActionMaker(slice)
  const pushQueryThunk = <Payload>(name: string) => {
    const originalAction = makeAction<Payload>()(`${name}/action`)

    const asyncThunk = createAsyncThunk<void, Payload, { state: State }>(
      makeName(name),
      async (payload, { dispatch, getState }) => {
        await dispatch(originalAction(payload))
        const state = getState()
        const appliedFilters = dashboardsSelectors.filters.applied.valid(state)
        const sorts = dashboardsSelectors.sorts.valid(state)

        if (appliedFilters) pushQuery({ request: appliedFilters, sorts })
      }
    )

    return { fulfilled: originalAction, action: asyncThunk }
  }
  return pushQueryThunk
}

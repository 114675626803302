import { useMemo } from 'react'

import { gql } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { BatchActionMenuItem } from 'dashboards/shared/components/BatchActionsMenu/BatchActionMenuItem'
import { useBatchActionLookups } from 'dashboards/shared/components/BatchActionsMenu/useBatchActionLookups'
import { hasPermission } from 'helpers/stateHelpers'
import { useFeatureFlag } from 'hooks'
import { useHbMutation } from 'hooks/ApolloHelpers'
import CaseStatusBatchActionIcon from 'icons/CaseStatusBatchActionIcon'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { BadgePermissionsEnum, BatchActionTypeEnum, FeatureFlag } from 'types/api'

import { Theme } from 'types/hb'

import { BatchActionContext } from '../../shared/components/BatchActionsMenu/BaseBatchActionDialog'

import {
  PerformReviewBatchActionMutation,
  PerformReviewBatchActionMutationVariables,
} from './__generated__/ReviewsBatchActionsMenu.generated'
import { canAssignSelector, canUnassignSelector } from './selectors'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 16,
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(0.5),
  },
}))

const useUserPermissions = () => {
  return {
    canComplete: useSelector((state) => hasPermission(state, BadgePermissionsEnum.UpdateCompleteReview)),
    canReopen: useSelector((state) => hasPermission(state, BadgePermissionsEnum.UpdateUncompleteReview)),
    canCancel: useSelector((state) => hasPermission(state, BadgePermissionsEnum.UpdateCancelReview)),
    canAssign: useSelector(canAssignSelector),
    canUnassign: useSelector(canUnassignSelector),
  }
}

export const PERFORM_BATCH_ACTION = gql`
  mutation PerformReviewBatchAction(
    $tokens: [ID!]!
    $batchActionName: BatchActionTypeEnum!
    $parameters: BatchActionParameters
  ) {
    performReviewBatchAction(input: { tokens: $tokens, batchActionName: $batchActionName, parameters: $parameters }) {
      bid
    }
  }
`

const assignmentLabel = (
  actions: BatchActionTypeEnum[],
  labelGetter: (action: BatchActionTypeEnum) => string | undefined
): string | undefined => {
  if (actions.length === 1) {
    return labelGetter(actions[0])
  }
  return 'Assignment'
}

const bidSelector = (data: PerformReviewBatchActionMutation) => data.performReviewBatchAction?.bid || null

const hasBatchActions = (actions: BatchActionTypeEnum[]): actions is [BatchActionTypeEnum, ...BatchActionTypeEnum[]] =>
  actions.length > 0

export function ReviewsBatchActionsMenu() {
  const classes = useStyles()

  const numBatchItemsSelected = useSelector(batchActionsSelectors.numBatchItemsSelected)

  const permissions = useUserPermissions()
  const [performBatchAction, { loading }] = useHbMutation<
    PerformReviewBatchActionMutation,
    PerformReviewBatchActionMutationVariables
  >(PERFORM_BATCH_ACTION)

  const batchActionContext = useMemo(
    () => ({
      mutate: performBatchAction,
      loading,
      bidSelector,
    }),
    [loading, performBatchAction]
  )
  const caseStatusActions: BatchActionTypeEnum[] = []
  if (permissions.canComplete) caseStatusActions.push(BatchActionTypeEnum.Complete)
  if (permissions.canReopen) caseStatusActions.push(BatchActionTypeEnum.Uncomplete)

  const assignActions: BatchActionTypeEnum[] = []
  if (permissions.canAssign) assignActions.push(BatchActionTypeEnum.Assign)
  if (permissions.canUnassign) assignActions.push(BatchActionTypeEnum.Unassign)

  const entityType = 'review'

  const isBatchSelectEnabled = useFeatureFlag(FeatureFlag.EnableCaseMerging)
  const hasMergeCasesPermission = useSelector((state) => hasPermission(state, BadgePermissionsEnum.MergeCases))
  const canMergeCases = isBatchSelectEnabled && hasMergeCasesPermission
  const { getLabel, getIcon } = useBatchActionLookups()

  return (
    <BatchActionContext.Provider value={batchActionContext}>
      <div className={classes.root} data-testid="reviews-batch-actions-menu">
        {hasBatchActions(caseStatusActions) && (
          <BatchActionMenuItem
            label="Case Status"
            Icon={CaseStatusBatchActionIcon}
            actions={caseStatusActions}
            entityType={entityType}
          />
        )}
        {permissions.canCancel && (
          <BatchActionMenuItem
            Icon={getIcon(BatchActionTypeEnum.Cancel)}
            actions={[BatchActionTypeEnum.Cancel]}
            entityType={entityType}
          />
        )}
        {hasBatchActions(assignActions) && (
          <BatchActionMenuItem
            label={assignmentLabel(assignActions, getLabel)}
            Icon={getIcon(BatchActionTypeEnum.Assign)}
            actions={assignActions}
            entityType={entityType}
          />
        )}
        {canMergeCases && (
          <BatchActionMenuItem
            label={getLabel(BatchActionTypeEnum.Merge)}
            Icon={getIcon(BatchActionTypeEnum.Merge)}
            actions={[BatchActionTypeEnum.Merge]}
            disabled={numBatchItemsSelected < 2}
            entityType={entityType}
          />
        )}
      </div>
    </BatchActionContext.Provider>
  )
}

import { ComponentProps } from 'react'

import { Field, FastField, FieldAttributes, FieldProps } from 'formik'

import { QuestionMatcherProps } from 'components/cases/report/survey/questions/QuestionTypes'

import { FormattedInput, FormattedInputProps } from './FormattedInput'
import { TextInput, TextInputProps } from './TextInput'

type NumberFormattedInputProps = Omit<FormattedInputProps, 'thousandSeparator' | 'decimalSeparator'> & {
  numberProps?: QuestionMatcherProps['numberProps']
}

const isThousandsGroupStyle = (
  value?: string
): value is ComponentProps<typeof FormattedInput>['thousandsGroupStyle'] => {
  if (!value) return false
  return ['thousand', 'lakh', 'wan'].includes((value as any) ?? 'unknown')
}

export const NumberFormattedInput = ({ value, numberProps, ...props }: NumberFormattedInputProps) => {
  const thousandSeparator = numberProps?.thousandSeparator ?? ','
  const thousandsGroupStyle = isThousandsGroupStyle(numberProps?.thousandsGroupStyle)
    ? numberProps?.thousandsGroupStyle
    : undefined

  return (
    <FormattedInput
      {...numberProps}
      thousandSeparator={thousandSeparator === 'none' ? undefined : thousandSeparator}
      thousandsGroupStyle={thousandsGroupStyle}
      value={value}
      {...props}
    />
  )
}

type NumberInputProps = TextInputProps & {
  numberProps?: QuestionMatcherProps['numberProps']
}

export const NumberInput = ({ InputProps, numberProps, ...props }: NumberInputProps & FieldProps<unknown>) => (
  <TextInput
    InputProps={{
      inputComponent: NumberFormattedInput,
      ...InputProps,
    }}
    inputProps={{ numberProps }}
    {...props}
  />
)

type NumberFieldProps = NumberInputProps & FieldAttributes<unknown>

export const NumberField = (props: NumberFieldProps) => <Field {...props} component={NumberInput} />

export const FastNumberField = (props: NumberFieldProps) => <FastField {...props} component={NumberInput} />

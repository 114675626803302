import { useMemo } from 'react'

import { useQuery } from '@apollo/client'

import { useSelector } from 'actions/store'

import { useDashboardColumns } from 'dashboards/shared/hooks/useDashboardColumns'
import { useSearchOnError } from 'dashboards/shared/hooks/useSearchOnError'
import { useSyncPagination } from 'dashboards/shared/hooks/useSyncPagination'
import { DashboardDataResult } from 'dashboards/shared/types/DashboardData'

import { NarrowedFetchPolicy } from 'hooks/ApolloHelpers'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { mapEntries } from 'reducers/dashboards/mappers'

import { DashboardColumn } from 'reducers/reviewsReducer'
import { normalizeRequest } from 'utils/query/normalization'

import {
  SearchReviewsV3Query,
  SearchReviewsV3QueryVariables,
} from '../gql/__generated__/searchReviews.queries.generated'
import { SEARCH_REVIEWS_V3_QUERY } from '../gql/searchReviews.queries'

// TODO: Generalize this. https://thecharm.atlassian.net/browse/PROD-10038
const getIncludeDirectives = (selectedColumns: DashboardColumn[]) => {
  const columnsShownByApiName = selectedColumns.reduce<Record<string, boolean>>((acc, cur) => {
    acc[cur.apiName] = true
    return acc
  }, {})

  return {
    includeRecentAlertExternalId: !!columnsShownByApiName.alertExternalIds,
  }
}

export const useReviewsDashboardData = (
  fetchPolicy: NarrowedFetchPolicy = 'cache-and-network'
): DashboardDataResult<ReturnType<typeof mapEntries>> => {
  const dashboardsSelectors = useDashboardSelectors()
  const page = useSelector(dashboardsSelectors.page)
  const query = useSelector(dashboardsSelectors.query)
  const validFilters = useSelector(dashboardsSelectors.filters.applied.valid)

  const allFiltersApplied = useSelector(dashboardsSelectors.filters.name) === 'all-cases'
  const hasFilters = allFiltersApplied || Object.values(validFilters ?? {}).some((value) => value !== undefined)

  const skip = !hasFilters && !query

  const appliedFilters = useMemo(() => (validFilters ? normalizeRequest(validFilters) : {}), [validFilters])

  const { selectedColumns } = useDashboardColumns()
  const includeDirectives = getIncludeDirectives(selectedColumns)

  const onError = useSearchOnError()
  // "V3" is the updated "V2". TODO - migrate the _names_ of queries back to "V2" once "V2" namespace is cleared
  const { data, previousData, loading, refetch, error } = useQuery<SearchReviewsV3Query, SearchReviewsV3QueryVariables>(
    SEARCH_REVIEWS_V3_QUERY,
    {
      variables: {
        page: page.number,
        pageSize: page.size,
        appliedFilters,
        ...includeDirectives,
      },
      fetchPolicy,
      skip,
      onError,
    }
  )

  const searchReviews = (data ?? previousData)?.searchReviewsV3

  useSyncPagination(searchReviews)

  return { loading, refetch, entries: mapEntries(searchReviews), totalCount: searchReviews?.totalCount ?? 0, error }
}

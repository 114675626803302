import { useEffect, useState } from 'react'

import { useSelector } from 'actions/store'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'

export const useBatchActionSelection = () => {
  const isBatchSelectMenuActive = useSelector(batchActionsSelectors.isSomeItemBatchSelected)
  const numBatchItemsSelected = useSelector(batchActionsSelectors.numBatchItemsSelected)
  const [nonZeroBatchSelectedDisplay, setNonZeroBatchSelectedDisplay] = useState<number>()
  useEffect(() => {
    if (numBatchItemsSelected) {
      // prevent flashing 0
      setNonZeroBatchSelectedDisplay(numBatchItemsSelected)
    }
  }, [numBatchItemsSelected])
  const numBatchItemsSelectedDisplay = nonZeroBatchSelectedDisplay

  return {
    isBatchSelectMenuActive,
    numBatchItemsSelectedDisplay,
  }
}

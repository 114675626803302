import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const NoSendIcon = (props: SvgProps): JSX.Element => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M1.01019305,19.3474903 L1,12.2123552 L16.2895753,10.1737452 L1,8.13513514 L1.01019305,1 L22.4054054,10.1737452 L1.01019305,19.3474903 Z M23,16.7640007 L20.6288656,19.1351351 L23,21.5062695 L21.5062695,23 L19.1351351,20.6288656 L16.7640007,23 L15.2702703,21.5062695 L17.6414047,19.1351351 L15.2702703,16.7640007 L16.7640007,15.2702703 L19.1351351,17.6414047 L21.5062695,15.2702703 L23,16.7640007 Z" />
  </SvgIcon>
)

export default NoSendIcon

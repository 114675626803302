import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const WarningOutlinedIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 14 12">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <polygon points="0 0 24 0 24 24 0 24" />
      <path
        d="M7 1.99329L12.02 10.6666H1.98L7 1.99329ZM0.826666 9.99996C0.313333 10.8866 0.953333 12 1.98 12H12.02C13.0467 12 13.6867 10.8866 13.1733 9.99996L8.15333 1.32662C7.64 0.439954 6.36 0.439954 5.84667 1.32662L0.826666 9.99996ZM6.33333 5.33329V6.66662C6.33333 7.03329 6.63333 7.33329 7 7.33329C7.36667 7.33329 7.66667 7.03329 7.66667 6.66662V5.33329C7.66667 4.96662 7.36667 4.66662 7 4.66662C6.63333 4.66662 6.33333 4.96662 6.33333 5.33329ZM6.33333 8.66662H7.66667V9.99996H6.33333V8.66662Z"
        fill="currentColor"
      />
    </g>
  </SvgIcon>
)

export default WarningOutlinedIcon

import React from 'react'

import { assignReview, unassignReview, LOADING_ASSIGNMENT_TOKEN } from 'actions/reviewsActions'
import { State, useDispatch, useSelector } from 'actions/store'
import { FilterableAccountList } from 'components/library/FilterableAccountList'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import {
  getLoadingBool,
  getOrganizationFeatureFlag,
  getOrganizationTeammates,
  hasPermission,
} from 'helpers/stateHelpers'
import { stopEvent } from 'helpers/uiHelpers'
import { ReviewPreview } from 'reducers/investigationsReducer.types'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'
import { BasicAccount } from 'types/hb'

interface Props {
  handleClickAccount: (account: BasicAccount) => void
  disabled?: boolean
  autoFocus?: boolean
}

export function AssignmentList(props: Props) {
  const { handleClickAccount, disabled, autoFocus } = props
  const accounts = useSelector(getOrganizationTeammates)
  const loading = useSelector((state) => getLoadingBool(state, LOADING_ASSIGNMENT_TOKEN))

  return (
    <FilterableAccountList
      accounts={accounts}
      disabled={loading || disabled}
      onClickItem={handleClickAccount}
      placeholder="Assign to..."
      selectable={false}
      autoFocus={autoFocus}
    />
  )
}

export function useAssignment(review: ReviewPreview) {
  const dispatch = useDispatch()
  const usage = useUsage()

  const loading = useSelector((state) => getLoadingBool(state, LOADING_ASSIGNMENT_TOKEN))

  const assignee = review ? review.assignee : null

  const handleClickAccount = (account: BasicAccount) => {
    usage.logEvent({ name: 'review:assign:clicked', data: { userEmail: account.email, strategy: 'assignee' } })
    dispatch(assignReview(review.token, account))
  }

  const handleClickUnassign = (event: React.MouseEvent) => {
    usage.logEvent({ name: 'review:unassign:clicked', data: { strategy: 'assignee' } })
    stopEvent(event)
    dispatch(unassignReview(review.token))
  }

  return {
    assignee,
    loading,
    handleClickUnassign,
    handleClickAccount,
  }
}

export function canAssignSelector(state: State): boolean {
  if (getOrganizationFeatureFlag(state, FeatureFlag.EnableReviewAssignmentPermissions)) {
    return hasPermission(state, BadgePermissionsEnum.UpdateReviewAssignee)
  }
  return true // the original behavior always allowed it
}

export function canUnassignSelector(state: State): boolean {
  if (getOrganizationFeatureFlag(state, FeatureFlag.EnableReviewAssignmentPermissions)) {
    return hasPermission(state, BadgePermissionsEnum.DeleteReviewAssignee)
  }
  return true // the original behavior always allowed it
}

import { CSSObject } from '@emotion/react'
import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'

const Card = styled('button')(({ theme }) => {
  return {
    display: 'grid',
    gridTemplateColumns: `${theme.spacing(6)} auto`,
    gridTemplateRows: 'min-content auto',
    gap: theme.spacing(0.5, 2),
    padding: theme.spacing(3),
    borderRadius: theme.spacing(1),
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    maxWidth: theme.spacing(50),
    overflow: 'visible',
  }
})

const IconContainer = styled('div')({
  gridRow: '1 / span 2',
  gridColumn: '1',
})

const Title = styled(HbText)(() => ({
  gridRow: '1',
  gridColumn: '2',
  fontWeight: 600,
}))

const Body = styled(HbText)(() => ({
  gridRow: '2',
  gridColumn: '2',
}))

const Content = styled('div')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(0.5),
  flexDirection: 'column',
}))

interface Props {
  title: string
  icon: React.ReactNode
  description: string
  onClick: () => void
  css?: CSSObject
}

export function ActionCard({ title, description, icon, onClick }: Props) {
  return (
    <Card type="button" onClick={onClick}>
      <IconContainer>{icon}</IconContainer>
      <Content>
        <Title variant="sectionHeader">{title}</Title>
        <Body>{description}</Body>
      </Content>
    </Card>
  )
}

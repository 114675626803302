import { gql } from '@apollo/client'
import { Close } from '@mui/icons-material'
import { dialogClasses, DialogTitle } from '@mui/material'
import { Box, styled } from '@mui/system'

import { useHistory, useLocation } from 'react-router-dom'

import { useDispatch } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AutomationRuleExecutionsActivityLog } from 'dashboards/automationRuleExecutions/AutomationRuleExecutionsActivityLog'
import { filtersMap } from 'dashboards/shared/react/getNamedQueryGroups'
import { useFeatureFlag } from 'hooks'

import { dashboardActionsMap } from 'reducers/dashboards/dashboards.actions'
import { FeatureFlag, DashboardTypeEnum } from 'types/api'

import ActivityLog from '../AutomationActivityLog'
import { ViewAutomationInnerAutomationRuleFragment as AutomationRule } from '../__generated__/ViewAutomation.queries.generated'

import { ActivityLogDay } from './ActivityLogDay'

const OldAutomationActivityLogPanel = ({
  automationRule,
  loadMoreActivity,
}: {
  automationRule: AutomationRule
  loadMoreActivity: (cursor: string | null) => void
}) => {
  return (
    <Box sx={{ paddingLeft: 2, height: '95%', width: '100%', boxSizing: 'border-box' }}>
      <ActivityLog automationRule={automationRule} loadMoreItems={loadMoreActivity} />
    </Box>
  )
}

const StyledDialogHeader = styled(DialogTitle)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(2, 2, 2, 3),
  boxShadow: `0px 4px 7px 0px #0000001A`,
  marginBottom: theme.spacing(3),
}))

export const AutomationActivityLogPanel = ({
  automationRule,
  loadMoreActivity,
}: {
  automationRule: AutomationRule
  loadMoreActivity: (cursor: string | null) => void
}) => {
  const history = useHistory()
  const location = useLocation()
  const isTableOpen = location.pathname.includes('activity_log/table')
  const isNewActivityLogEnabled = useFeatureFlag(FeatureFlag.EnableNewAutomationsActivityLog)

  const { unfilteredFilter } = filtersMap[DashboardTypeEnum.AutomationExecutions]
  const dispatch = useDispatch()
  const actions = dashboardActionsMap[DashboardTypeEnum.AutomationExecutions]

  const openTable = async () => {
    if (!isTableOpen) {
      history.push(`${location.pathname}/table`)
    }
  }

  const clearFilters = () => {
    return dispatch(actions.filters.changeView.action({ ...unfilteredFilter.request, title: unfilteredFilter.title }))
  }

  const closeTable = async () => {
    const pathnameWithoutTable = location.pathname.replace('/table', '')
    history.push(pathnameWithoutTable)
  }

  if (!isNewActivityLogEnabled) {
    return <OldAutomationActivityLogPanel automationRule={automationRule} loadMoreActivity={loadMoreActivity} />
  }

  const hasNoRecentActivity = !automationRule.recentActivity?.executionsByDay.length
  return (
    <Box sx={{ px: 2, height: '95%', width: '100%', boxSizing: 'border-box' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <HbText bold size="md">
          Last 15 days
        </HbText>
        <HbButton label="See all activity" variant="textPrimary" onClick={openTable} size="small" />
      </Box>
      {automationRule.recentActivity?.executionsByDay.map((day) => (
        <ActivityLogDay
          key={day.date}
          runs={day.count}
          date={day.date}
          openTable={openTable}
          type={automationRule.__typename}
        />
      ))}
      {hasNoRecentActivity && <HbText size="md">No recent activity</HbText>}

      <HbDialog
        id="activity-log"
        title="Activity"
        open={isTableOpen}
        onClose={closeTable}
        sx={{
          [`& .${dialogClasses.paper}`]: {
            width: 1200,
          },
        }}
        TransitionProps={{ onExited: clearFilters }}
        header={
          <StyledDialogHeader id="activity-log">
            <HbText size="xl">Activity</HbText>
            <HbButton label="Close modal" iconOnly Icon={Close} onClick={closeTable} />
          </StyledDialogHeader>
        }
      >
        <AutomationRuleExecutionsActivityLog canonicalAutomationRuleToken={automationRule.token} />
      </HbDialog>
    </Box>
  )
}

AutomationActivityLogPanel.fragments = {
  automationRule: gql`
    fragment AutomationActivityLogPanelAutomationRule on CanonicalAutomationRule {
      ... on CanonicalAutomationRuleBase {
        __typename
        token
        recentActivity {
          executionsByDay {
            date
            count
          }
        }
      }
    }
  `,
}

import { Key } from 'react'

import { createAction } from '@reduxjs/toolkit'

import { BatchActionsState } from 'reducers/batchActions/batchActions.types'

type Prefix<T extends string> = `batchActions/${T}`
const makeName = <T extends string>(name: T) => `batchActions/${name}` as Prefix<T>
const makeAction =
  <T>() =>
  <R extends string>(name: R) =>
    createAction<T extends keyof BatchActionsState ? BatchActionsState[T] : T, Prefix<R>>(makeName(name))

export const batchActionsActions = {
  batchSelectItems: {
    set: makeAction<{ tokens: Key[]; value: boolean }>()('batchSelectItems/set'),
    clear: makeAction<void>()('batchSelectItems/clear'),
  },
  recentlyUpdatedItems: {
    set: makeAction<{ tokens: Key[]; value: boolean }>()('recentlyUpdatedItems/set'),
    clear: makeAction<void>()('recentlyUpdatedItems/clear'),
  },
  jobStatus: {
    set: makeAction<{ bid: string }>()('jobStatus/set'),
    clear: makeAction<void>()('jobStatus/clear'),
  },
}

import moment from 'moment'

import { AnyAction } from 'redux'

import {
  UPDATE_INVESTIGATION,
  RECORD_INVESTIGATION_NOT_FOUND,
  SET_INVESTIGATION_LOCATIONS,
  SET_INVESTIGATION_CONNECTIONS,
} from 'actions/investigationsActions'

import { InvestigationsState } from './investigationsReducer.types'

const getInitialState = (): InvestigationsState => ({
  byToken: {},
  locationsByToken: {},
  connectionsByToken: {},
})

export const investigationsReducer = (state = getInitialState(), action: AnyAction): InvestigationsState => {
  switch (action.type) {
    case UPDATE_INVESTIGATION: {
      const { investigation } = action
      const updates = {
        byToken: {
          ...state.byToken,
          [investigation.token]: {
            ...investigation,
            lastFetchedAt: moment.utc().valueOf(),
          },
        },
      }

      return { ...state, ...updates }
    }
    case RECORD_INVESTIGATION_NOT_FOUND: {
      const updates = {
        byToken: { ...state.byToken, [action.token]: false },
      }
      return { ...state, ...updates }
    }
    case SET_INVESTIGATION_LOCATIONS: {
      const { token, locations } = action
      const locationsByToken = {
        ...state.locationsByToken,
        [token]: locations,
      }
      return { ...state, locationsByToken }
    }
    case SET_INVESTIGATION_CONNECTIONS: {
      const { token, connections } = action
      const connectionsByToken = {
        ...state.connectionsByToken,
        [token]: connections,
      }
      return { ...state, connectionsByToken }
    }
    default: {
      return state
    }
  }
}

import { googleMapsURL } from 'helpers/locations'
import { displayAddress } from 'helpers/uiHelpers'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'

export function addressGoogleMapsURL(address: DisplayAddressAddressFragment): string {
  return googleMapsURL(displayAddress(address), {
    lat: address.geopoint?.latitude,
    lon: address.geopoint?.longitude,
  })
}

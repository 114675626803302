// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { SnackbarProvider } from 'notistack'

import { Theme } from 'types/hb'

import { HbNotistackSnackbar, HbNotistackSnackbarProps } from './HbNotistackSnackbar'

const useStyles = makeStyles((theme: Theme) => ({
  containerRoot: {
    left: theme.spacing(7),
    bottom: theme.spacing(2),
  },
}))

export const HbNotistackProvider = ({ children }: { children: React.ReactNode }) => {
  const classes = useStyles()
  return (
    <SnackbarProvider
      // See https://notistack.com/api-reference#snackbarprovider-props
      Components={{
        default: HbNotistackSnackbar,
        error: HbNotistackSnackbar,
        warning: HbNotistackSnackbar,
        success: HbNotistackSnackbar,
        loading: HbNotistackSnackbar,
        white: HbNotistackSnackbar,
      }}
      // These properties can be overridden for inidividual snackbars.
      // For example: `enqueueSnackbar('foo', {autoHideDuration: 3_000})`
      autoHideDuration={10_000}
      preventDuplicate
      classes={classes}
      maxSnack={5}
    >
      {children}
    </SnackbarProvider>
  )
}

// See https://notistack.com/features/customization#custom-variant-(typescript)
declare module 'notistack' {
  interface VariantOverrides {
    info: false
    default: HbNotistackSnackbarProps
    error: HbNotistackSnackbarProps
    warning: HbNotistackSnackbarProps
    success: HbNotistackSnackbarProps
    loading: HbNotistackSnackbarProps
    white: HbNotistackSnackbarProps
  }
}

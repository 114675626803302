import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CaseStatusBatchActionIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} width="17" height="16" viewBox="0 0 17 16" fill="none">
    <path
      d="M2.10492 1.0182C1.81242 1.3107 1.81242 1.7832 2.10492 2.0757L9.35742 9.3282L8.29992 10.3857L5.64492 7.7382C5.35242 7.4457 4.87992 7.4457 4.58742 7.7382C4.29492 8.0307 4.29492 8.5032 4.58742 8.7957L7.76742 11.9757C8.05992 12.2682 8.53242 12.2682 8.82492 11.9757L10.4149 10.3857L14.8324 14.8032C15.1249 15.0957 15.5974 15.0957 15.8899 14.8032C16.1824 14.5107 16.1824 14.0382 15.8899 13.7457L3.16242 1.0182C2.86992 0.725703 2.39742 0.725703 2.10492 1.0182ZM12.5374 8.2707L15.7174 5.0907C16.0099 4.7982 16.0099 4.3182 15.7099 4.0257C15.4174 3.7407 14.9449 3.7407 14.6524 4.0332L11.4724 7.2132L12.5374 8.2707ZM11.4799 4.0257C11.1874 3.7332 10.7149 3.7332 10.4224 4.0257L9.35742 5.0907L10.4149 6.1482L11.4799 5.0832C11.7724 4.7907 11.7724 4.3182 11.4799 4.0257ZM0.342422 8.7957L4.05492 12.5082L5.11242 11.4507L1.39992 7.7382C1.10742 7.4457 0.634922 7.4457 0.342422 7.7382C0.0499219 8.0307 0.0499219 8.5032 0.342422 8.7957Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default CaseStatusBatchActionIcon

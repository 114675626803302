import { useCallback, useMemo } from 'react'

import { EditRounded, Visibility, GetApp } from '@mui/icons-material'

import { useSnackbar } from 'notistack'

import { useDispatch, useSelector } from 'actions/store'
import { downloadFile } from 'actions/viewActions'
import { isFileUnavailableDueToCaseSize, isFileViewable } from 'components/cases/Tabs/Files/caseFilesTableHelpers'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { hasPermission } from 'helpers/stateHelpers'
import { noPropagate } from 'helpers/uiHelpers'
import { InvestigationAttachment } from 'reducers/investigationsReducer.types'
import { openTab } from 'reducers/tabReducer'

import { BadgePermissionsEnum, GeneratedDocumentType } from 'types/api'

import TrashOutlineIcon from '../../../../icons/TrashOutlineIcon'

import { CaseFile, CaseFileActionMenuLayout, CaseFileMenuAction, DialogFile } from './caseFileTypes'

export const useViewInvestigationAttachment = () => {
  const usage = useUsage()
  const dispatch = useDispatch()

  const viewAttachment = useCallback(
    ({
      attachmentToken,
      contentType,
      summaryOpen,
    }: {
      attachmentToken: string
      contentType: string
      summaryOpen?: boolean
    }) => {
      usage.logEvent({ name: 'case:viewFile:clicked', data: { attachmentType: 'attachment', contentType } })

      dispatch(
        openTab({
          tab: {
            type: 'attachment',
            attachmentToken,
            attachmentType: 'attachment',
            summaryOpen,
          },
        })
      )
    },
    [dispatch, usage]
  )
  return viewAttachment
}

export const useViewReviewDocument = () => {
  const usage = useUsage()
  const dispatch = useDispatch()
  const viewReviewDocument = useCallback(
    ({
      contentType,
      displayName,
      documentName,
      reviewToken,
      summaryOpen,
    }: {
      contentType: string
      displayName: string | undefined
      documentName: GeneratedDocumentType | undefined
      reviewToken: string | undefined
      summaryOpen?: boolean
    }) => {
      if (!reviewToken || !documentName || !displayName) {
        throw new Error('Invalid review document')
      }

      usage.logEvent({ name: 'case:viewFile:clicked', data: { attachmentType: 'reviewDocument', contentType } })

      dispatch(
        openTab({
          tab: {
            type: 'attachment',
            attachmentType: 'reviewDocument',
            reviewToken,
            documentName,
            displayName,
            summaryOpen,
          },
        })
      )
    },
    [dispatch, usage]
  )
  return viewReviewDocument
}

export const useViewSurveyResponseFileUpload = () => {
  const usage = useUsage()
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar()

  const viewSurveyResponseFileUpload = useCallback(
    ({
      contentType,
      clientId,
      questionToken,
      summaryOpen,
    }: {
      clientId: string | undefined
      contentType: string
      questionToken: string | undefined
      summaryOpen?: boolean
    }) => {
      if (questionToken && clientId) {
        usage.logEvent({ name: 'case:viewFile:clicked', data: { attachmentType: 'surveyFileUpload', contentType } })

        dispatch(
          openTab({
            tab: {
              type: 'attachment',
              attachmentType: 'surveyFileUpload',
              questionToken,
              clientId,
              summaryOpen,
            },
          })
        )
      } else {
        enqueueSnackbar("Sorry, we couldn't open this file", { variant: 'error' })
      }
    },
    [dispatch, enqueueSnackbar, usage]
  )
  return viewSurveyResponseFileUpload
}

export const useViewAttachment = () => {
  const viewInvestigationAttachment = useViewInvestigationAttachment()
  const viewReviewDocument = useViewReviewDocument()
  const viewSurveyResponseFileUpload = useViewSurveyResponseFileUpload()

  return ({
    type,
    contentType = '',
    attachmentToken,
    questionToken,
    clientId,
    summaryOpen,
    documentName,
    displayName,
    reviewToken,
  }: {
    type: CaseFile['type']
    contentType: string | undefined
    attachmentToken: string
    questionToken?: string
    clientId?: string
    summaryOpen?: boolean
    documentName?: GeneratedDocumentType
    displayName?: string
    reviewToken?: string
  }) => {
    switch (type) {
      case 'Attachment':
        viewInvestigationAttachment({ attachmentToken, contentType, summaryOpen })
        break
      case 'ReviewDocument':
        viewReviewDocument({ contentType, displayName, documentName, reviewToken, summaryOpen })
        break
      case 'SurveyResponseFileUpload':
        viewSurveyResponseFileUpload({ clientId, contentType, questionToken, summaryOpen })
        break
      default:
        throw new Error(`Unexpected attachment type: ${type}`)
    }
  }
}

export const partitionActions = (
  actionMenuLayout: CaseFileActionMenuLayout,
  availableActions: CaseFileMenuAction[]
) => {
  let hoverActions: CaseFileMenuAction[] = []
  let ellipsisActions: CaseFileMenuAction[] = []

  if (actionMenuLayout === 'small') {
    ellipsisActions = availableActions
  } else if (actionMenuLayout === 'medium') {
    hoverActions = availableActions.slice(0, 2)
    ellipsisActions = availableActions.slice(2)
  } else {
    hoverActions = availableActions
  }

  return { hoverActions, ellipsisActions }
}

/**
 * Hook to determine what actions are available for a case file,
 * given the file type, permissions, etc.,
 * and whether they are in the hover or ellipsis menu.
 */
export const useCaseFileActions = ({
  caseFile,
  setDialogFile,
  actionMenuLayout,
  caseFileDownloadDisabled,
}: {
  caseFile?: CaseFile
  setDialogFile: (file: DialogFile) => void
  actionMenuLayout: CaseFileActionMenuLayout
  caseFileDownloadDisabled: boolean | null
}): { hoverActions: CaseFileMenuAction[]; ellipsisActions: CaseFileMenuAction[] } => {
  const usage = useUsage()
  const dispatch = useDispatch()
  const userCanDownloadCaseData = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.DownloadCaseDocuments)
  )
  const viewAttachment = useViewAttachment()

  return useMemo(() => {
    if (!caseFile) return { hoverActions: [], ellipsisActions: [] }
    const isUserAttachment = caseFile?.type === 'Attachment'
    const canView = !!caseFile && isFileViewable(caseFile, caseFileDownloadDisabled)
    const fileUnavailableDueToCaseSize = isFileUnavailableDueToCaseSize(caseFile, caseFileDownloadDisabled)
    const availableActions = [
      canView && {
        label: 'Open',
        Icon: Visibility,
        onClick: noPropagate(() =>
          viewAttachment({
            documentName: caseFile.reviewDocumentType,
            displayName: caseFile.displayName,
            type: caseFile.type,
            reviewToken: caseFile.reviewToken,
            contentType: caseFile.contentType,
            attachmentToken: (caseFile.legacyItem as InvestigationAttachment)?.token,
            questionToken: caseFile.downloadData.tokenData?.questionToken,
            clientId: caseFile.downloadData.tokenData?.clientId,
          })
        ),
      },
      userCanDownloadCaseData &&
        !fileUnavailableDueToCaseSize && {
          label: 'Download',
          Icon: GetApp,
          onClick: noPropagate(() => {
            usage.trackChurnZeroEvent('CaseDataDownload')
            dispatch(downloadFile(caseFile.downloadData))
          }),
        },
      isUserAttachment && {
        label: 'Rename',
        Icon: EditRounded,
        onClick: noPropagate(() => {
          setDialogFile({ token: caseFile.token, action: 'edit' })
        }),
      },
      isUserAttachment && {
        label: 'Delete',
        Icon: TrashOutlineIcon,
        onClick: noPropagate(() => {
          setDialogFile({ token: caseFile.token, action: 'delete' })
        }),
      },
    ].filter(Boolean) as CaseFileMenuAction[]

    return partitionActions(actionMenuLayout, availableActions)
  }, [
    caseFile,
    caseFileDownloadDisabled,
    userCanDownloadCaseData,
    actionMenuLayout,
    viewAttachment,
    usage,
    dispatch,
    setDialogFile,
  ])
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const PersonIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12.2395406,15 C8.9258321,15 6.23954059,12.3137085 6.23954059,9 C6.23954059,5.6862915 8.9258321,3 12.2395406,3 C15.5532491,3 18.2395406,5.6862915 18.2395406,9 C18.2395406,12.3137085 15.5532491,15 12.2395406,15 Z M12.2395406,5 C10.0304016,5 8.23954059,6.790861 8.23954059,9 C8.23954059,11.209139 10.0304016,13 12.2395406,13 C14.4486796,13 16.2395406,11.209139 16.2395406,9 C16.2395406,6.790861 14.4486796,5 12.2395406,5 Z M19.2395406,19.37 C19.5448467,19.6289096 19.6663315,20.0449419 19.548328,20.4274614 C19.4303246,20.8099809 19.0955741,21.0852691 18.697467,21.1271827 C18.29936,21.1690962 17.9146187,20.9695576 17.7195406,20.62 C16.3909633,18.964001 14.3826163,18.0004566 12.2595406,18.0004566 C10.1364649,18.0004566 8.12811787,18.964001 6.79954059,20.62 C6.58048127,20.9115983 6.22004244,21.0608109 5.85897391,21.0093711 C5.49790538,20.9579312 5.19347639,20.7139977 5.06456513,20.3728256 C4.93565386,20.0316534 5.00270701,19.6473561 5.23954059,19.37 C6.94821921,17.2556181 9.52104956,16.0268542 12.2395406,16.0268542 C14.9580316,16.0268542 17.530862,17.2556181 19.2395406,19.37 Z" />
  </SvgIcon>
)

export default PersonIcon

import { Select, MenuItem, SelectChangeEvent, Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { startCase } from 'lodash'

import { Controller, FieldPathByValue } from 'react-hook-form'
import invariant from 'tiny-invariant'

import { Theme } from 'types/hb'

import { FormSchema } from '../formSchema'

import { getIconForDomainType, FieldSpec, FieldSpecConfig, FieldSpecConfigEntry, AutomationDomain } from './fieldConfig'
import { useThinControlStyles } from './styles'

import type { VariableNode, TypeCastVariable } from 'types/automations'

const useStyles = makeStyles((theme: Theme) => ({
  left: {
    flexGrow: 1,
  },
  domainTypeIcon: {
    marginRight: theme.spacing(1),
  },
}))

export type FieldPath =
  | FieldPathByValue<FormSchema, VariableNode | TypeCastVariable>
  | FieldPathByValue<FormSchema, VariableNode>

interface Props {
  name: FieldPath
  domain: AutomationDomain
  config: FieldSpecConfig
  onChangeToBinaryExpression: (defaultPath: string[] | null | undefined) => void
  onChangeToListExpression: (newPath: string[], defaultPath: string[] | null | undefined) => void
  onChangeToLinkedTrigger: (newPath: string[], subDomain: AutomationDomain) => void
  onChangeToTypeCast: (newPath: string[], newFieldSpec: FieldSpec) => void
  onChangeFromTypeCast: (newPath: string[], newFieldSpec: FieldSpec) => void
}

export function VariableEditor({
  name,
  domain,
  config,
  onChangeToBinaryExpression,
  onChangeToLinkedTrigger,
  onChangeToListExpression,
  onChangeToTypeCast,
  onChangeFromTypeCast,
}: Props) {
  const styles = useStyles()
  const controlStyles = useThinControlStyles()

  return (
    <Controller
      name={`${name}.path`}
      render={({ field }) => {
        const handleChange = (e: SelectChangeEvent | string, index: number, fieldConfig?: FieldSpecConfigEntry) => {
          const newValue = e instanceof Object ? e.target.value : e
          let newPathValue = field.value
          newPathValue[index] = newValue

          // If changing a value in the middle of the path, remove all values after it
          if (index < field.value.length - 1) {
            newPathValue = field.value.slice(0, index + 1)
          } else if (index === field.value.length - 1 && !newValue) {
            newPathValue = field.value.slice(0, index)
          }

          const newFieldSpec = fieldConfig?.possibleFields[newValue]

          if (newFieldSpec?.type === 'nestedList') {
            const defaultPath = Object.keys(newFieldSpec.values)[0]
            onChangeToListExpression(newPathValue, [defaultPath])
          }

          if (newFieldSpec?.type === 'nested') {
            const defaultPath = Object.keys(newFieldSpec.values)[0]

            onChangeToBinaryExpression([...newPathValue, defaultPath])
          }

          if (newFieldSpec?.type === 'linkedField') {
            onChangeToLinkedTrigger(newPathValue, { type: newFieldSpec.domainType, datasourceToken: null })
          }

          if (newFieldSpec?.type === 'fieldList') {
            onChangeToListExpression(newPathValue, null)
          }

          if (newFieldSpec?.type && ['field', 'nestedCustom'].includes(newFieldSpec.type)) {
            onChangeToBinaryExpression(newPathValue)
          }

          if (newFieldSpec && 'cast' in newFieldSpec && newFieldSpec.cast) {
            onChangeToTypeCast(newPathValue, newFieldSpec)
          } else if (newFieldSpec) {
            onChangeFromTypeCast(newPathValue, newFieldSpec)
          }

          field.onChange(newPathValue)
        }

        return field.value.flatMap((pathValue: string, i: number) => {
          if (pathValue === 'event' || pathValue === 'linked') {
            return null
          }

          const fieldConfig = config[pathValue]
          invariant(fieldConfig, `Expected field config for ${pathValue}`)

          return [
            pathValue !== domain.type && fieldConfig?.parentFieldSpec?.type !== 'nestedCustom' && (
              <Select
                key={pathValue}
                className={classNames(styles.left, controlStyles.control)}
                required
                value={pathValue}
                onChange={(e) => handleChange(e, i, fieldConfig)}
                variant="outlined"
                data-testid="path"
                classes={{ root: controlStyles.selectRoot }}
              >
                {Object.entries(fieldConfig.possibleFields).map(([p, spec]) => {
                  const Icon = spec.type === 'linkedField' && getIconForDomainType(spec.domainType)
                  return (
                    <MenuItem key={p} value={p}>
                      <Box alignItems="center" display="flex">
                        {Icon && <Icon className={styles.domainTypeIcon} />}
                        {spec.label || startCase(p)}
                      </Box>
                    </MenuItem>
                  )
                })}
              </Select>
            ),
            i === field.value.length - 1 && fieldConfig.fieldSpec?.type === 'nested' ? (
              <Select
                key={`${pathValue}-nested`}
                className={classNames(styles.left, controlStyles.control)}
                required
                value={field.value[i + 1]}
                onChange={(e) => handleChange(e, i + 1, fieldConfig)}
                variant="outlined"
              >
                {Object.entries(fieldConfig.fieldSpec.values).map(([p, spec]) => (
                  <MenuItem key={p} value={p}>
                    {spec.label || startCase(p)}
                  </MenuItem>
                ))}
              </Select>
            ) : fieldConfig.fieldSpec?.type === 'nestedCustom' ? (
              <fieldConfig.fieldSpec.Component
                onChange={(value) => handleChange(value, i + 1)}
                value={field.value[i + 1]}
                domain={domain}
              />
            ) : null,
          ]
        })
      }}
    />
  )
}

import React from 'react'

import { IconButton, OutlinedInput, OutlinedInputProps } from '@mui/material'

import { CloseIcon, SearchIcon } from 'icons'

type SimpleSearchInputProps = Omit<OutlinedInputProps, 'startAdornment' | 'endAdornment'> & {
  value: string
  onClear: () => void
}

export const SimpleSearchInput: React.FC<SimpleSearchInputProps> = ({ onClear, value, ...props }) => {
  return (
    <OutlinedInput
      startAdornment={<SearchIcon />}
      endAdornment={
        value.length > 0 ? (
          <IconButton size="small" onClick={onClear}>
            <CloseIcon fontSize="small" />
          </IconButton>
        ) : null
      }
      value={value}
      {...props}
    />
  )
}

import { useCallback, useState } from 'react'

export function useIntersectionObserver(): [(arg0: HTMLElement) => void, boolean] {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false)

  const refCallback = useCallback((node: HTMLElement) => {
    if (node !== null) {
      const options = {
        root: null as any,
        threshold: [0, 1.0],
      }
      const observer = new IntersectionObserver((entries) => {
        const entry = entries.find((e) => e.isIntersecting)

        if (entry) {
          setIsIntersecting(true)
        } else {
          setIsIntersecting(false)
        }
      }, options)
      observer.observe(node)
      return observer.disconnect
    }

    return () => {}
  }, [])

  return [refCallback, isIntersecting]
}

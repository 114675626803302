import { ReactNode, useCallback } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { ChangedColumns } from 'components/library/Table/Table'
import { DashboardTableCard } from 'dashboards/shared/components/Reporting/DashboardTableCard'
import { AutomationExecutionsDashboardEntry } from 'reducers/dashboards/mappers'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { Theme } from 'types/hb'

import { useAutomationRuleExecutionsDashboardData } from './hooks/useAutomationRuleExecutionsDashboardData'
import { useAutomationRuleExecutionsTableCellRenderer } from './hooks/useGetAutomationRuleExecutionsTableCellRenderer'

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    marginTop: theme.spacing(0),
    boxShadow: 'none',
  },
}))

export interface Props {
  loadingColumns: boolean
  initialLoadComplete: boolean
  batchColumnEnabled?: boolean
  columns: DashboardColumn[]
  handleColumnOrderChange: (changed: ChangedColumns) => void
  header: ReactNode
  canonicalAutomationRuleToken?: string
}

export function AutomationRuleExecutionsTable({ canonicalAutomationRuleToken, ...rest }: Props) {
  const classes = useStyles()

  const itemKey = useCallback((row: AutomationExecutionsDashboardEntry) => row.token, [])
  const { loading, error, refetch, entries, totalCount } = useAutomationRuleExecutionsDashboardData(
    'cache-and-network',
    canonicalAutomationRuleToken
  )
  const getCellRenderer = useAutomationRuleExecutionsTableCellRenderer()

  return (
    <DashboardTableCard
      {...rest}
      topOffset={0}
      handleRowClick={() => {}}
      itemKey={itemKey}
      loading={loading}
      error={error}
      refetch={refetch}
      entries={entries}
      totalCount={totalCount}
      getCellRenderer={getCellRenderer}
      isRowSelectable={() => false}
      dashboardStyleOverrides={{
        paper: classes.paper,
      }}
    />
  )
}

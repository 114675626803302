import { useEffect } from 'react'

import * as Sentry from '@sentry/react'

import moment from 'moment'

import { setIntercomHasBooted } from 'actions/applicationActions'
import { useDispatch, useSelector } from 'actions/store'

import { getCurrentOrganization } from 'helpers/stateHelpers'
import history from 'utils/history'

export const CUSTOM_INTERCOM_LAUNCHER_ID = 'customIntercomLauncher'

export const useIntercom = () => {
  const currentAccount = useSelector((state) => state.application.currentAccount)
  const organization = useSelector(getCurrentOrganization)
  const intercomAppId = useSelector((state) => state.application.intercomAppId)
  const intercomHasBooted = useSelector((state) => state.application.intercomHasBooted)
  const dispatch = useDispatch()

  useEffect(() => {
    if (intercomHasBooted || !(currentAccount && intercomAppId)) {
      return
    }

    // See https://developers.intercom.com/installing-intercom/web/attributes-objects/ for API documentation
    try {
      window.Intercom('boot', {
        app_id: intercomAppId,
        email: currentAccount.email,
        user_id: currentAccount.token,
        name: currentAccount.fullName,
        created_at: moment(currentAccount.createdAt).unix(),
        user_hash: currentAccount.intercomId,
        alignment: 'left',
        horizontal_padding: 90,
        vertical_padding: 0,
        hide_default_launcher: true,
        custom_launcher_selector: `#${CUSTOM_INTERCOM_LAUNCHER_ID}`,
        // Company Attributes
        // https://www.intercom.com/help/en/articles/186-group-your-users-by-company
        company: {
          id: organization.token, // company id should never change
          name: organization.name,
        },
        // Custom Attributes
        // https://www.intercom.com/help/en/articles/179-send-custom-user-attributes-to-intercom
        admin: currentAccount.badges.some((badge) => badge.name.toUpperCase() === 'ADMIN'),
      })

      window.Intercom('onShow', () => {
        document.getElementById(CUSTOM_INTERCOM_LAUNCHER_ID)?.blur()
      })

      history.listen(() => window.Intercom('update'))
      dispatch(setIntercomHasBooted())
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [currentAccount, intercomAppId, intercomHasBooted, dispatch, organization])
}

import { useContext, createContext } from 'react'

import { RuleOrTemplateFields, RuleFields } from './editor.types'

export const AutomationRuleOrTemplateContext = createContext<RuleOrTemplateFields | null>(null)

export const checkIsRule = (ruleOrTemplate: RuleOrTemplateFields): ruleOrTemplate is RuleFields => {
  switch (ruleOrTemplate.__typename) {
    case 'TriggerAutomationRule':
    case 'ScheduleAutomationRule':
    case 'ScheduleWithTriggerAutomationRule':
    case 'BlankAutomationRule':
      return true
    default:
      return false
  }
}

export function useAutomationRuleOrTemplate() {
  return useContext(AutomationRuleOrTemplateContext)
}

export function useIsAutomationRule() {
  const ruleOrTemplate = useContext(AutomationRuleOrTemplateContext)
  return Boolean(ruleOrTemplate && checkIsRule(ruleOrTemplate))
}

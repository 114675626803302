import { useCallback } from 'react'

import { gql, useQuery } from '@apollo/client'

import { uniqBy } from 'lodash'
import { useParams } from 'react-router-dom'

import { useSelector } from 'actions/store'

import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { RelatedCase, useRelatedCases } from 'components/entities/RelatedCases'

import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'

import { GetCaseNameQuery, GetCaseNameQueryVariables } from './__generated__/UseGetIsInvestigationRowSelected.generated'

const useGetIsInvestigationRowSelected = () => {
  const selectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const { caseToken } = useParams<{ caseToken?: string }>()
  const getIsRowSelected = useCallback(
    (token: string | undefined) => (token ? !!selectedItems[token] || token === caseToken : false),
    [selectedItems, caseToken]
  )
  return getIsRowSelected
}

const GET_CASE_NAME = gql`
  query GetCaseName($caseToken: String!) {
    investigation(token: $caseToken) {
      token
      name
    }
  }
`

export const useGetSelectedInvestigationRows = (type: LibraryGqlQueryType, token: string) => {
  const { displayedData } = useRelatedCases(type, token, undefined, 'cache-only')
  const { caseToken } = useParams<{ caseToken?: string }>()

  const { data } = useQuery<GetCaseNameQuery, GetCaseNameQueryVariables>(GET_CASE_NAME, {
    variables: { caseToken: caseToken || '' },
    fetchPolicy: 'cache-first',
    skip: !caseToken,
  })

  const getIsRowSelected = useGetIsInvestigationRowSelected()

  const relatedCases = displayedData.filter((investigation): investigation is RelatedCase =>
    getIsRowSelected(investigation?.token)
  )
  const thisCase = data ? data.investigation : null
  return uniqBy([thisCase, ...relatedCases], (c) => c?.token).filter((c) => !!c)
}

export const useGetSelectedInvestigationRowsInformation = (type: LibraryGqlQueryType, token: string) => {
  const selectedRows = useGetSelectedInvestigationRows(type, token)
  const batchSelectedItems = useSelector(batchActionsSelectors.batchSelectedItems)
  const selectedRowTokens = new Set(selectedRows.map((row) => row?.token))
  const batchSelectedNotOnPage = Object.entries(batchSelectedItems)
    .filter(([key, value]) => value && !selectedRowTokens.has(key))
    .map(([key]) => key)
  const numBatchSelectedNotOnPage = batchSelectedNotOnPage.length
  return { selectedRows, numBatchSelectedNotOnPage }
}

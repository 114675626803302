import { FC } from 'react'

import { Warning } from '@mui/icons-material'
import { Typography } from '@mui/material'

import { makeRequiredStyles } from 'components/utils/styles'

const useStyles = makeRequiredStyles((theme) => ({
  warning: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.error.main,
    marginBottom: theme.spacing(2),
  },

  icon: {
    marginRight: theme.spacing(),
  },
}))

export const NoUndoWarning: FC<{ message?: string }> = (props) => {
  const { children, message = 'Important: you cannot undo this action!' } = props
  const styles = useStyles()
  return (
    <div className={styles.warning}>
      <Warning className={styles.icon} />
      <Typography variant="body2" paragraph display="inline" color="inherit">
        {message}
      </Typography>
      {children}
    </div>
  )
}

import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchFileFragment } from './searchFiles.fragments'

const SearchFilesResultFragment = gql`
  fragment SearchFilesResultFragment on SearchFilesResult {
    entries {
      ...SearchFileFragment
    }
    page
    pageSize
    pageCount
    totalCount
  }
  ${SearchFileFragment}
`

export const SEARCH_FILES_QUERY = gql`
  query SearchFiles($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchFiles(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchFilesResultFragment
    }
  }
  ${SearchFilesResultFragment}
`

export const SEARCH_FILES_COLUMNS_QUERY = gql`
  query SearchFilesColumns($includeCustomColumns: Boolean!, $search: SearchRequestInput!) {
    searchFilesMetadata(columns: [], search: $search) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const SEARCH_FILES_METADATA_QUERY = gql`
  query SearchFilesMetadata($columns: [String!], $query: String, $search: SearchRequestInput) {
    searchFilesMetadata(columns: $columns, query: $query, search: $search) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

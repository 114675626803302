/**
 * This file implements shared utilities for the lexer, parser, assist service, and diagnostic service.
 */

export type ObjectVariable = {
  name: string
  type: 'object'
  properties: Array<Variable>
  label: string
  info: string
}

export type ArrayVariable = {
  name: string
  type: 'array'
  members: Omit<Extract<Variable, { type: 'object' }>, 'name'> | Omit<Extract<Variable, { type: 'value' }>, 'name'>
  label: string
  info: string
}

export type ValueVariable = { name: string; type: 'value'; label: string; info: string }

export type Variable = ObjectVariable | ArrayVariable | ValueVariable

export function pathToSegments(path: string): Array<string> {
  return path.split('.').flatMap((s1) =>
    s1
      .split(/\[|\]/g)
      .filter((s2) => s2 !== '')
      .map((s) => s.replace(/["']/g, ''))
  )
}

export function digPathInVariables(path: string, variables: Array<Variable>): Variable | null {
  const segments = pathToSegments(path)

  if (segments.length === 0) {
    return null
  }

  let currentVar: Variable | undefined = variables.find((v) => v.name === segments[0])

  for (const segment of segments.slice(1)) {
    if (!currentVar) {
      return null
    }

    // other_info has dynamic keys that will not be included
    // in the currentVar properties but should not give a warning
    if (path.includes('other_info')) {
      return currentVar
    }

    if (currentVar.type === 'array') {
      if (!/^[0-9]+$/.test(segment)) {
        return null
      }
      currentVar = { ...currentVar.members, name: 'member' }
      // eslint-disable-next-line no-continue
      continue
    }
    if (currentVar.type !== 'object') {
      return null
    }
    currentVar = currentVar.properties?.find((e) => e.name === segment)
  }

  return currentVar ?? null
}

export function flattenVariablesToSuggestions(
  variables: Array<Variable>,
  terminal: Variable['type'] = 'value',
  parentPath = ''
): Array<{ path: string; info: string }> {
  return variables
    .flatMap((v) => {
      if (v.type === terminal) {
        return { path: `${parentPath}.${v.name}`, info: v.info }
      }

      const path = v.name === '0' ? `${parentPath}[${v.name}]` : `${parentPath}.${v.name}`

      if (v.type === 'array') {
        return flattenVariablesToSuggestions([{ ...v.members, name: '0' }], terminal, path)
      }

      if (v.type === 'object') {
        return flattenVariablesToSuggestions(v.properties, terminal, path)
      }

      return []
    })
    .map(({ path, ...rest }) => ({ path: path.replace(/^\./, ''), ...rest }))
}

export function pathDefinedInVariables(path: string, variables: Array<Variable>): boolean {
  return Boolean(digPathInVariables(path, variables))
}

import { ReactNode } from 'react'

import { ArrowDropDown } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { isEmpty } from 'lodash'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import {
  SelectBase,
  InputContainer,
  getHelperTextId,
  RenderValue,
  InputOptionWithNumbers,
  HbSelectProps,
  SelectedValue,
} from 'components/material/Form'
import { Theme } from 'types/hb'

function renderValue(
  multiple: boolean,
  options: InputOptionWithNumbers[],
  placeholder?: InputOptionWithNumbers | null
) {
  return (selected: SelectedValue) => {
    // if we allow multiple selections, `selected` may be an array
    if (Array.isArray(selected)) {
      if (isEmpty(selected)) {
        return placeholder?.display || ''
      }

      return options
        .filter(({ value }) => selected.includes(value))
        .map(({ display }) => display)
        .join(', ')
    }

    // otherwise we are only expecting a single selection
    if (!options || !selected || placeholder?.value === selected) {
      return placeholder?.display || ''
    }
    return options.filter(({ value }) => selected === value).map(({ display }) => display)
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  redesignSelectRootClass: (hasAdornment) => ({
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    ...(hasAdornment
      ? {
          '&&': {
            paddingRight: theme.spacing(4),
          },
        }
      : {}),
  }),
  redesignRoot: (hasAdornment) => ({
    margin: 0,
    ...(hasAdornment
      ? {
          // only reposition an svg if there is more than one.
          '& svg + svg': {
            position: 'absolute',
            right: theme.spacing(3.5),
          },
        }
      : {}),
  }),
  inputContainer: {
    marginBottom: 0,
  },
}))

export type HbDropdownProps = HbSelectProps & {
  name: string
  testId?: string
  label: string | ReactNode
  sublabel?: string
  inputContainerClassName?: string
  noSpacing?: boolean
  errors?: string | string[]
  hideLabel?: boolean
  readOnly?: boolean
  adornment?: ReactNode
  multivalued?: boolean
  renderValue?: RenderValue
}
/**
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
function HbDropdown(props: HbDropdownProps) {
  const {
    name,
    label,
    sublabel,
    required,
    noSpacing,
    helperText,
    testId,
    hideLabel,
    onChange,
    inputContainerClassName: inputContainerClassNameProp,
    error,
    errors,
    adornment,
    multivalued,
    placeholder,
    disabled,
    disablePlaceholderSelect,
    customComponent,
    disableOptions,
    renderValue: renderValueProp,
    ...rest
  } = props
  const hasErrors = error || Boolean(errors?.length) || Boolean(typeof errors === 'string' && errors)
  const inputTestId = getInputTestId(label, testId)
  const classes = useStyles(!!adornment)

  // Custom override for the bottom margin for InputContainer.
  // We probably shouldn't have built-in margins for core components
  // since we usually have to adjust them based on the the context
  // of where they are rendered. Kind of hard going through every example
  // of InputContainer, but there aren't that many of HbTextInput
  // so I figured this would be a more reasonable approach for the moment.
  const inputContainerClassName = classNames(classes.inputContainer, inputContainerClassNameProp)

  return (
    <InputContainer
      label={label}
      sublabel={sublabel}
      htmlFor={name}
      labelId={`label_${name}`}
      className={inputContainerClassName}
      isErroneous={hasErrors}
      required={required}
      noSpacing={noSpacing}
      errors={errors}
      helperText={helperText}
      testId={testId}
      hideLabel={hideLabel}
    >
      <SelectBase
        name={name}
        variant="outlined"
        classes={{ root: classes.redesignRoot }}
        testId={inputTestId}
        InputProps={{
          error: hasErrors,
          endAdornment: adornment,
        }}
        SelectProps={{
          labelId: `label_${name}`,
          IconComponent: ArrowDropDown,
          classes: { root: classes.redesignSelectRootClass },
          'aria-describedby': getHelperTextId(name),
        }}
        CheckboxProps={{ color: 'primary' }}
        placeholder={placeholder || (multivalued ? 'Select any that apply' : 'Select one')}
        disablePlaceholderSelect={disablePlaceholderSelect}
        multiple={multivalued}
        margin="dense"
        disabled={disabled}
        disableOptions={disableOptions}
        renderValue={renderValueProp || renderValue}
        customComponent={customComponent}
        onChange={onChange}
        {...rest}
      />
    </InputContainer>
  )
}

export default HbDropdown

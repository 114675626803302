import { Add } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useFieldArray, useFormContext } from 'react-hook-form'

import HbRHFTextInput from 'components/HbComponents/Form/HbInputs/HbTextInput/HbRHFTextInput'
import { HbButton } from 'components/HbComponents/HbButton'

import { TrashOutlineIcon } from 'icons'

import { AutomationActionType } from 'types/api'

import { FormSchema } from '../../formSchema'
import { getActionParamsPath } from '../../util'

import CheckboxSection from './CheckboxSection'

const useStyles = makeStyles((theme) => ({
  emailFields: {
    display: 'grid',
    gridTemplateRows: '1fr',
    gap: theme.spacing(),
    gridTemplateColumns: '1fr 1fr auto',
    alignItems: 'center',
    marginBottom: theme.spacing(1),
  },
  deleteButton: {
    marginTop: theme.spacing(3),
    '& svg': {
      color: theme.palette.styleguide.textGreyLight,
    },
  },
}))

export type Props = {
  className?: string
}

const getEmailRecipientsPath = (actionType: AutomationActionType | null) =>
  `${getActionParamsPath(actionType)}.recipientEmails` as const

function RecipientEntry({
  index,
  onDelete,
  actionType,
}: {
  index: number
  onDelete: (index: number) => void
  actionType: AutomationActionType | null
}) {
  const { emailFields, deleteButton } = useStyles()
  const emailRecipientsPath = getEmailRecipientsPath(actionType)

  return (
    <div className={emailFields}>
      <HbRHFTextInput name={`${emailRecipientsPath}.${index}.name`} label="Name" rules={{ required: true }} />
      <HbRHFTextInput name={`${emailRecipientsPath}.${index}.email`} label="Email" rules={{ required: true }} />
      <HbButton
        className={deleteButton}
        iconOnly
        Icon={TrashOutlineIcon}
        label="Delete email recipient"
        onClick={() => onDelete(index)}
      />
    </div>
  )
}

export default function EmailRecipients({ className }: Props) {
  const { control, getValues } = useFormContext<FormSchema>()
  const actionType = getValues('actionType')
  const emailRecipientsPath = getEmailRecipientsPath(actionType)
  const { fields: recipientEmails, append, remove } = useFieldArray({ control, name: emailRecipientsPath })

  const handleCreateBlankRecipient = () => {
    append({ email: '', name: '' })
  }

  return (
    <CheckboxSection
      label="Email Recipients"
      handleUncheck={remove}
      handleCheck={handleCreateBlankRecipient}
      isChecked={recipientEmails.length > 0}
    >
      <div className={className}>
        {recipientEmails.map((entry, index) => (
          <RecipientEntry index={index} key={entry.id} onDelete={remove} actionType={actionType} />
        ))}
        <HbButton
          variant="textPrimary"
          Icon={Add}
          label="Add Email Recipient"
          size="small"
          onClick={handleCreateBlankRecipient}
        />
      </div>
    </CheckboxSection>
  )
}

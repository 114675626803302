import { gql } from '@apollo/client'

import { useSnackbar } from 'notistack'
import { useHistory } from 'react-router-dom'

import { useHbMutation } from 'hooks/ApolloHelpers'

import {
  CreateAutomationRuleAsAdminMutation,
  CreateAutomationRuleAsAdminMutationVariables,
  CreateAutomationRuleMutation,
  CreateAutomationRuleMutationVariables,
} from './__generated__/useCreateAutomationRule.generated'

const CREATE_AUTOMATION_RULE_MUTATION = gql`
  mutation CreateAutomationRule($input: CreateAutomationRuleInput!) {
    createAutomationRule(input: $input) {
      automationRule {
        ... on CanonicalAutomationRuleBase {
          token
        }
      }
    }
  }
`

const CREATE_AUTOMATION_RULE_MUTATION_AS_ADMIN = gql`
  mutation CreateAutomationRuleAsAdmin($input: CreateAutomationRuleAsAdminInput!) {
    createAutomationRuleAsAdmin(input: $input) {
      automationRule {
        ... on CanonicalAutomationRuleBase {
          token
          organizationToken
        }
      }
    }
  }
`

export function useCreateAutomationRule(
  templateSlug: string | null,
  createAsAdminConfig?: {
    selectedOrgToken: string | null
    getAutomationRulePath: (automationRuleOrgToken: string, ruleToken: string) => string
  }
) {
  const { enqueueSnackbar } = useSnackbar()
  const history = useHistory()

  const [createAutomationRule, { loading }] = useHbMutation<
    CreateAutomationRuleMutation,
    CreateAutomationRuleMutationVariables
  >(CREATE_AUTOMATION_RULE_MUTATION, {
    flashError: true,
    onCompleted: (result) => {
      enqueueSnackbar('Automation successfully created', { variant: 'success', autoHideDuration: 1000 })
      history.push(`/automations/${result.createAutomationRule?.automationRule?.token}`)
    },
    variables: {
      input: { templateSlug },
    },
  })

  const [createAutomationRuleAsAdmin, { loading: loadingAsAdmin }] = useHbMutation<
    CreateAutomationRuleAsAdminMutation,
    CreateAutomationRuleAsAdminMutationVariables
  >(CREATE_AUTOMATION_RULE_MUTATION_AS_ADMIN, {
    flashError: true,
    onCompleted: (result) => {
      if (result.createAutomationRuleAsAdmin?.automationRule) {
        enqueueSnackbar('Automation successfully created', { variant: 'success', autoHideDuration: 2000 })
        const { organizationToken, token } = result.createAutomationRuleAsAdmin.automationRule
        if (createAsAdminConfig) {
          history.push(`${createAsAdminConfig.getAutomationRulePath(organizationToken, token)}/overview`)
        }
      } else {
        enqueueSnackbar('Error occurred when trying to create automation rule as an admin', {
          variant: 'error',
          autoHideDuration: 2000,
        })
      }
    },
    variables: {
      input: { organizationToken: createAsAdminConfig?.selectedOrgToken },
    },
  })

  return {
    createAutomationRule,
    createAutomationRuleAsAdmin: createAsAdminConfig ? createAutomationRuleAsAdmin : undefined,
    loading: loading || loadingAsAdmin,
  }
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const InstitutionIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M12.37,5.08096825 L2.74,8.93096825 L2,7.07096825 L12,3.07096825 C12.2375919,2.97634392 12.5024081,2.97634392 12.74,3.07096825 L22.74,7.07096825 L22,8.93096825 L12.37,5.08096825 Z M13.37,9.00096825 L15.37,9.00096825 L15.37,18.0009682 L13.37,18.0009682 L13.37,9.00096825 Z M9.37,9.00096825 L11.37,9.00096825 L11.37,18.0009682 L9.37,18.0009682 L9.37,9.00096825 Z M5.37,9.00096825 L7.37,9.00096825 L7.37,18.0009682 L5.37,18.0009682 L5.37,9.00096825 Z M17.37,9.00096825 L19.37,9.00096825 L19.37,18.0009682 L17.37,18.0009682 L17.37,9.00096825 Z M2.37,19.0009682 L22.37,19.0009682 L22.37,21.0009682 L2.37,21.0009682 L2.37,19.0009682 Z" />
  </SvgIcon>
)

export default InstitutionIcon

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { TrcSearchInput } from 'components/cases/Tabs/ThomsonReutersClear/TRC.types'

import { EntityType } from './investigationsReducer.types'

type FormValues = Omit<TrcSearchInput, 'investigationToken'>

interface TRClearFormValues {
  person?: FormValues
  business?: FormValues
}

type TRClearState = {
  // Form values are scoped to case token
  formValues: Record<string, TRClearFormValues>
}

const initialState: TRClearState = {
  formValues: {},
}

const TRClearSlice = createSlice({
  name: 'TRClear',
  initialState,
  reducers: {
    updateFormValues(
      state,
      action: PayloadAction<{
        entityType?: EntityType
        caseToken: string
        formValues?: FormValues
      }>
    ) {
      const { entityType, caseToken, formValues } = action.payload
      if (!entityType) {
        return state
      }
      const caseFormValues = { ...(state.formValues[caseToken] || {}) }
      if (entityType === 'business') {
        caseFormValues.business = formValues
      } else {
        caseFormValues.person = formValues
      }
      return {
        ...state,
        formValues: {
          ...state.formValues,
          [caseToken]: caseFormValues,
        },
      }
    },
  },
})

export const { updateFormValues } = TRClearSlice.actions
export default TRClearSlice.reducer

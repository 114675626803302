import { gql } from '@apollo/client'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'

import { properCase, fromNow } from 'helpers/uiHelpers'
import { useDateFormatter } from 'hooks/DateFormatHooks'

import AutomationEnabledPill from '../AutomationEnabledPill'

import { ActivityLogDetailsAutomationRuleFragment as AutomationRule } from './__generated__/AutomationActivityLogDetails.generated'

import type { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  section: {
    margin: theme.spacing(2, 1),
    border: `1px solid ${theme.palette.dividers.light}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionTitle: {
    borderBottom: `1px solid ${theme.palette.dividers.light}`,
    padding: theme.spacing(2),
  },
  sectionDetails: {
    padding: theme.spacing(2),
  },
  usageDt: {
    color: theme.palette.styleguide.textGreyLight,
  },
  usageDd: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(0.5),
    '&:last-child': {
      marginBottom: theme.spacing(0),
    },
  },
}))

function UsageSection({ automationRule }: { automationRule: AutomationRule }) {
  const { latestExecution, usageCount, usagePercentage, enabled, unsampledCount, sampleRate } = automationRule
  const styles = useStyles()

  const usageText = usagePercentage === 0 ? usageCount : `${usageCount} (${usagePercentage}% of Total Usage)`
  const effectiveSamplingRateText =
    usageCount > 0 ? `${((usageCount / (usageCount + unsampledCount)) * 100).toFixed()}%` : '-'

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <HbText size="lg" tag="h2" bold>
          Usage
        </HbText>
      </div>
      <div className={styles.sectionDetails}>
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Status
        </HbText>
        <AutomationEnabledPill
          className={styles.usageDd}
          enabled={enabled}
          executionMode={automationRule.executionMode}
        />
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Usage
        </HbText>
        <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
          {usageText}
        </HbText>
        {sampleRate != null && (
          <>
            <HbText tag="dt" size="s" bold className={styles.usageDt}>
              Unsampled Count
            </HbText>
            <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
              {unsampledCount}
            </HbText>
            <HbText tag="dt" size="s" bold className={styles.usageDt}>
              Effective Sampling Rate
            </HbText>
            <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
              {effectiveSamplingRateText}
            </HbText>
          </>
        )}
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Last Usage
        </HbText>
        <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
          {latestExecution ? properCase(fromNow(latestExecution.createdAt)) : 'Never'}
        </HbText>
      </div>
    </div>
  )
}

function DetailsSection({ automationRule }: { automationRule: AutomationRule }) {
  const { createdAt, createdBy, updatedAt } = automationRule
  const formatDate = useDateFormatter('MMMM d, yyyy')
  const styles = useStyles()

  return (
    <div className={styles.section}>
      <div className={styles.sectionTitle}>
        <HbText size="lg" tag="h2" bold>
          Automation Details
        </HbText>
      </div>
      <div className={styles.sectionDetails}>
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Added
        </HbText>
        <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
          {formatDate(createdAt)}
        </HbText>
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Added By
        </HbText>
        <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
          {createdBy.fullName}
        </HbText>
        <HbText tag="dt" size="s" bold className={styles.usageDt}>
          Last Edit
        </HbText>
        <HbText tag="dd" size="s" className={styles.usageDd} color="emphasized">
          {formatDate(updatedAt)}
        </HbText>
      </div>
    </div>
  )
}

function ActivityLogDetails({ automationRule }: { automationRule: AutomationRule }) {
  const styles = useStyles()

  return (
    <div className={styles.root}>
      <UsageSection automationRule={automationRule} />
      <DetailsSection automationRule={automationRule} />
    </div>
  )
}

ActivityLogDetails.fragments = {
  automationRule: gql`
    fragment ActivityLogDetailsAutomationRule on CanonicalAutomationRule {
      ... on CanonicalAutomationRuleBase {
        token
        sampleRate
        usageCount
        usagePercentage
        unsampledCount
        enabled
        executionMode
        latestExecution {
          createdAt
        }
        createdAt
        createdBy {
          fullName
        }
        updatedAt
      }
    }
  `,
}

export default ActivityLogDetails

import { HbNonIdealState } from 'components/HbComponents/HbNonIdealState'

interface Props {
  details?: string
  fullPage?: boolean
}

// CI dumps html and screenshots on failure, if there's an unrecoverable message we want to see it.
// Without this errors are hidden behind a "Show details" button
const AUTO_DISPLAY_ERROR_MESSAGE_IN_CI = 'Cypress' in window

export const UnrecoverableErrorMessage = ({ details, fullPage }: Props) => {
  return (
    <HbNonIdealState
      padding={fullPage ? 6 : undefined}
      title="Something went wrong on our end."
      message="Please try refreshing the page in a few minutes."
      buttonLabel="Refresh"
      details={details}
      onButtonClick={() => window.location.reload()}
      noButtonIcon
      startOpen={AUTO_DISPLAY_ERROR_MESSAGE_IN_CI}
    />
  )
}

export default UnrecoverableErrorMessage

import { Key } from 'react'

import { QueryName } from 'dashboards/shared/components/Dashboard/NamedQueryGroups.types'
import { SavedSearchRequest, SortValueInput } from 'types/api'
import { SearchRequestWithId } from 'utils/query/api.types'

import { DashboardSlice } from './dashboards.constants'

export type PartialSort = Partial<SortValueInput>
export type AppliedFilters = MakeOptional<Replace<SearchRequestWithId, 'sorts', PartialSort[]>, 'sorts'>
export type DashboardState<K extends DashboardSlice> = {
  query?: string
  filters: {
    // allow partial sorts to be stored in redux, for UI purposes
    applied: AppliedFilters
    /**
     * @deprecated
     */
    name?: QueryName<K>
    /**
     * @deprecated
     */
    custom: boolean
    remoteOpen: boolean
  }
  customViews?: SavedSearchRequest[]
  currentViewTitle: string
  page: {
    number: number
    size: number
    count: number
  }
  batchSelectedItems: { [key: string]: boolean }
  recentlyUpdatedItems: { [key: string]: boolean }
  mostRecentlyBatchSelectedItem: Key | null
  mostRecentBatchJobId: string | null
}

export const sortIsValid = (sort: PartialSort): sort is SortValueInput => !!(sort.sortField && sort.sortDir)

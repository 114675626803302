import { push } from 'connected-react-router'

import { setLoading } from 'actions/viewActions'
import { Notification } from 'reducers/notificationsReducer'

import { AsyncThunk, Thunk } from './store'

export const SET_NOTIFICATIONS = 'SET_NOTIFICATIONS'
export const MARK_NOTIFICATION_ACTIONED = 'MARK_NOTIFICATION_ACTIONED'
export const SET_FETCHING_STARTED = 'SET_FETCHING_STARTED'

// Fetch notifications every minute
const FETCHING_INTERVAL_LENGTH = 60000

export function setNotifications(notifications: Notification[]) {
  return { type: SET_NOTIFICATIONS, notifications }
}

export function setFetchingStarted() {
  return { type: SET_FETCHING_STARTED }
}

export function markNotificationActioned(notification: Notification) {
  return { type: MARK_NOTIFICATION_ACTIONED, notification }
}

export function fetchNotifications(limit?: number | 'no_limit'): AsyncThunk<Notification[]> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'notifications'))
    const json = await api.get('apiNotificationsPath', {
      query: { limit: limit || 5 },
    })
    dispatch(setLoading(false, 'notifications'))
    if (json?.success) {
      dispatch(setNotifications(json.notifications))
      return json.notifications
    }
    return null
  }
}

export function fetchAllNotifications() {
  return fetchNotifications(100)
}

export function startFetchingNotifications(): Thunk<void> {
  return (dispatch, getState) => {
    const { fetchingStarted } = getState().notifications
    if (fetchingStarted) {
      return
    }

    dispatch(fetchNotifications())

    let interval = -1
    const update = () => {
      if (interval) {
        clearInterval(interval)
      }

      // If we're no longer the active tab, stop fetching notifications
      if (document.hidden) {
        return
      }

      // Cast since our typings are incorrectly set to NodeJS
      interval = setInterval(() => {
        dispatch(fetchNotifications())
      }, FETCHING_INTERVAL_LENGTH) as unknown as number
    }

    document.addEventListener('visibilitychange', () => {
      update()
    })

    update()
    dispatch(setFetchingStarted())
  }
}

export function actionNotification(notification: Notification): AsyncThunk<Notification[]> {
  return async (dispatch, getState, { api }) => {
    dispatch(markNotificationActioned(notification))
    const json = await api.put('actionApiNotificationPath', {
      urlParams: { token: notification.token },
    })
    if (json.success) {
      dispatch(setNotifications(json.notifications))
      return json.notifications
    }
    return null
  }
}

export function actionAllNotifications(): AsyncThunk<Notification[]> {
  return async (dispatch, getState, { api }) => {
    const json = await api.put('actionAllApiNotificationsPath')
    if (json.success) {
      dispatch(setNotifications(json.notifications))
      return json.notifications
    }
    return null
  }
}

export function notificationAction(notification: Notification): Thunk<void> {
  return (dispatch) => {
    dispatch(actionNotification(notification))
    dispatch(push(notification.actionPath))
  }
}

import { ChangeEvent, useCallback } from 'react'

import { TextFieldProps } from '@mui/material'

import { Field, FastField, FieldAttributes, FieldProps, FieldInputProps } from 'formik'
import { isEmpty } from 'lodash'

import { HbFormikTextInput } from 'components/HbComponents/Form/HbInputs/HbTextInput/HbFormikTextInput'

import { HbTextInputProps } from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'

import { TextInput, TextInputProps } from './TextInput'

export type SanitizerFn = (value: string) => string

type SanitizedInputProps = TextInputProps & {
  variant?: TextFieldProps['variant']
  pattern: RegExp
  sanitizer: SanitizerFn
}

const useSanitizedHandleChange = ({
  onChange,
  pattern,
  sanitizer,
  value,
}: {
  onChange: FieldInputProps<string>['onChange']
  pattern: RegExp
  sanitizer: SanitizerFn
  value: string
}) => {
  const previousValueMatches = isEmpty(value) || !!value.match(pattern)
  return useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { name, id, value: newValue } = event.target
      // If the previous value matched the pattern, ensure the new value continues to match.
      // Otherwise, we just display the value.
      const updateValue = previousValueMatches ? sanitizer(newValue) : newValue
      onChange({ ...event, target: { name, id, value: updateValue } })
    },
    [onChange, sanitizer, previousValueMatches]
  )
}

const SanitizedFieldInput = ({ sanitizer, pattern, field, ...props }: SanitizedInputProps & FieldProps<string>) => {
  const handleSanitizedChange = useSanitizedHandleChange({
    onChange: field.onChange,
    pattern,
    sanitizer,
    value: field.value,
  })
  return (
    <TextInput
      field={{
        ...field,
        value: field.value,
        onChange: handleSanitizedChange,
      }}
      {...props}
    />
  )
}

type AlphanumericInputProps = Omit<SanitizedInputProps, 'pattern' | 'sanitizer'> & {
  allowAsterisk?: boolean
}

export const AlphanumericInput = (props: AlphanumericInputProps & FieldProps<string>) => {
  const { allowAsterisk = false, ...otherProps } = props
  return (
    <SanitizedFieldInput
      pattern={allowAsterisk ? /^[a-zA-Z0-9*]*$/ : /^[a-zA-Z0-9]*$/}
      sanitizer={(value) => value.replace(allowAsterisk ? /[^a-zA-Z0-9*]/g : /[^a-zA-Z0-9]/g, '')}
      {...otherProps}
    />
  )
}

export type AlphanumericFieldProps = AlphanumericInputProps & FieldAttributes<unknown>

export const AlphanumericField = (props: AlphanumericFieldProps) => <Field {...props} component={AlphanumericInput} />

export const FastAlphanumericField = (props: AlphanumericFieldProps) => (
  <FastField {...props} component={AlphanumericInput} />
)

/**
 * Alphanumeric field that works with `HbTextInput` instead:
 */

type HbSanitizedFormikTextInputProps = HbTextInputProps & {
  pattern: RegExp
  sanitizer: (value: string) => string
}

const HbSanitizedFormikTextInput = ({
  sanitizer,
  pattern,
  field,
  ...props
}: HbSanitizedFormikTextInputProps & FieldProps<string>) => {
  const handleSanitizedChange = useSanitizedHandleChange({
    onChange: field.onChange,
    pattern,
    sanitizer,
    value: field.value,
  })

  return (
    <HbFormikTextInput
      {...props}
      field={{
        ...field,
        onChange: handleSanitizedChange,
      }}
    />
  )
}

type HbFormikAlphanumericInputProps = Omit<HbSanitizedFormikTextInputProps, 'pattern' | 'sanitizer'> & {
  allowAsterisk?: boolean
}

export const HbFormikAlphanumericInput = (props: HbFormikAlphanumericInputProps & FieldProps<string>) => {
  const { allowAsterisk = false, ...otherProps } = props
  return (
    <HbSanitizedFormikTextInput
      pattern={allowAsterisk ? /^[a-zA-Z0-9*]*$/ : /^[a-zA-Z0-9]*$/}
      sanitizer={(value) => value.replace(allowAsterisk ? /[^a-zA-Z0-9*]/g : /[^a-zA-Z0-9]/g, '')}
      {...otherProps}
    />
  )
}

type HbFormikAlphanumericFieldProps = HbFormikAlphanumericInputProps & FieldAttributes<unknown> & { name: string }

export const HbFormikFastAlphanumericField = (props: HbFormikAlphanumericFieldProps) => (
  <FastField {...props} component={HbFormikTextInput} />
)

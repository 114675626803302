import { AnyAction, createReducer, isAnyOf } from '@reduxjs/toolkit'

import { batchActionsActions as actions } from './batchActions.actions'
import { BatchActionsState } from './batchActions.types'

export const initialState: BatchActionsState = {
  batchSelectedItems: {},
  recentlyUpdatedItems: {},
  mostRecentlyBatchSelectedItem: null,
  mostRecentBatchJobId: null,
}

const dashboardCriteriaChangeActions = [
  'query/set',
  'sort/set/pending',
  'criteria/change/pending',
  'filters/setApplied/pending',
]

function isClearBatchActionsAction(action: AnyAction): action is AnyAction {
  return dashboardCriteriaChangeActions.some((actionTypeSuffix) => action.type.endsWith(actionTypeSuffix))
}

export const makeBatchActionsReducer = (customState?: typeof initialState) =>
  createReducer<BatchActionsState>(customState ?? initialState, (builder) => {
    builder
      .addCase(actions.batchSelectItems.set, (state, action) => {
        const { tokens, value } = action.payload
        tokens.forEach((token) => {
          state.batchSelectedItems[token] = value
        })
        state.mostRecentlyBatchSelectedItem = tokens[tokens.length - 1]
      })
      .addCase(actions.recentlyUpdatedItems.set, (state, action) => {
        const { tokens, value } = action.payload
        tokens.forEach((token) => {
          state.recentlyUpdatedItems[token] = value
        })
      })
      .addCase(actions.jobStatus.set, (state, action) => {
        state.mostRecentBatchJobId = action.payload.bid
      })
      .addCase(actions.jobStatus.clear, (state) => {
        state.mostRecentBatchJobId = null
      })
      .addMatcher(
        isAnyOf(actions.batchSelectItems.clear, actions.jobStatus.clear, isClearBatchActionsAction),
        (state) => {
          state.batchSelectedItems = {}
          state.mostRecentlyBatchSelectedItem = null
        }
      )
      .addMatcher(isAnyOf(actions.recentlyUpdatedItems.clear, isClearBatchActionsAction), (state) => {
        state.recentlyUpdatedItems = {}
      })
  })

export const batchActionsReducer = makeBatchActionsReducer()

import { FastField, FieldAttributes, FieldProps } from 'formik'
import { AsYouType, CountryCode } from 'libphonenumber-js'

import { FormattedInput, FormattedInputProps } from './FormattedInput'
import { TextInput, TextInputProps } from './TextInput'

export const PHONE_EXT_FORMAT = '######'

type PhoneNumberFormattedInputProps = Omit<FormattedInputProps, 'format'> & {
  country: CountryCode
}

export const PhoneNumberFormattedInput = ({ country, ...props }: PhoneNumberFormattedInputProps) => (
  <FormattedInput format={(number) => new AsYouType(country || 'US').input(number)} {...props} />
)

type PhoneNumberInputProps = TextInputProps

export const PhoneNumberInput = ({ InputProps, ...props }: PhoneNumberInputProps & FieldProps<unknown>) => (
  <TextInput
    InputProps={{
      inputComponent: PhoneNumberFormattedInput,
      ...InputProps,
    }}
    {...props}
  />
)

type PhoneNumberFieldProps = FieldAttributes<unknown> & PhoneNumberInputProps

export const PhoneNumberField = (props: PhoneNumberFieldProps) => <FastField {...props} component={PhoneNumberInput} />

export const FastPhoneNumberField = (props: PhoneNumberFieldProps) => (
  <FastField {...props} component={PhoneNumberInput} />
)

type PhoneExtFormattedInputProps = Omit<FormattedInputProps, 'format'>

export const PhoneExtFormattedInput = (props: PhoneExtFormattedInputProps) => (
  <FormattedInput format={PHONE_EXT_FORMAT} {...props} />
)

type PhoneExtInputProps = TextInputProps

export const PhoneExtInput = ({ InputProps, ...props }: PhoneExtInputProps & FieldProps<unknown>) => (
  <TextInput
    InputProps={{
      inputComponent: PhoneExtFormattedInput,
      ...InputProps,
    }}
    {...props}
  />
)

type PhoneExtFieldProps = PhoneExtInputProps & FieldAttributes<unknown>

export const PhoneExtField = (props: PhoneExtFieldProps) => <FastField {...props} component={PhoneExtInput} />

export const FastPhoneExtField = (props: PhoneExtFieldProps) => <FastField {...props} component={PhoneExtInput} />

import { useMemo } from 'react'

import Autocomplete from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'
import { useGetHeaderFilterValuesV2 } from 'dashboards/shared/hooks/useGetDashboardTableHeaderFilterValuesV2'

// TODO: the backend provides this info in `column.type`. We should use it
export const RelationFieldOptions = ['assignee', 'approvers', 'createdBy', 'filer', 'queue'] as const
export type RelationField = (typeof RelationFieldOptions)[number]

export function isRelation(apiName: string): apiName is RelationField {
  return RelationFieldOptions.includes(apiName as RelationField)
}

interface RelationFieldProps {
  currentValue?: string
  styles?: string
  onChange(newValue: string): void
  fieldName: RelationField
}

export const RelationField = ({ styles, onChange, currentValue, fieldName }: RelationFieldProps) => {
  const { getHeaderFilterValues } = useGetHeaderFilterValuesV2(fieldName)
  const createValues = useMemo(getHeaderFilterValues, [getHeaderFilterValues])
  const selectorValues = useMemo(() => createValues?.() ?? [], [createValues])

  return (
    <Autocomplete
      styles={styles}
      noSpacing
      hideLabel
      options={selectorValues}
      value={currentValue}
      onChange={(newValue) => {
        if (newValue) {
          onChange(newValue.value.toString())
        }
      }}
      label={fieldName}
    />
  )
}

import { CSSProperties, ReactElement, ReactNode, Ref } from 'react'

import { DraggableProvidedDraggableProps, DraggableProvidedDragHandleProps } from 'react-beautiful-dnd'

import { OnColumnResizeStop, RenderMeasurementColumn } from './helpers'

export interface Props<Row = unknown> {
  value?: (entry: any) => ReactNode
  header?: HeaderCellRenderProp | string
  /** A unique key for this column. May also be used to find the value for each cell if value function not specified. */
  field: string
  align?: 'right' | 'center' | 'left'
  headerClassName?: string
  cellClassName?: string
  multiline?: boolean
  permanent?: boolean
  isDragDisabled?: boolean
  isResizeDisabled?: boolean
  isReorderable?: boolean
  handleClick?: (e: React.MouseEvent, column: TableColumnElement<Row>, row: Row) => void
}

function TableColumn<Row = unknown>(_props: Props<Row>): JSX.Element | null {
  return null
}

export type TableColumnElement<Row = unknown> = ReactElement<Props<Row>, typeof TableColumn>

export interface BaseHeaderCellRenderProps {
  column: TableColumnElement<never>
  classes?: any
  headerCellRef?: Ref<HTMLTableCellElement>
  lastNonSticky?: boolean
  sticky?: boolean
  columnResizingEnabled?: boolean
  style?: CSSProperties
}

export interface DraggableHeaderCellRenderProps
  extends Omit<BaseHeaderCellRenderProps, 'style'>,
    DraggableProvidedDragHandleProps,
    DraggableProvidedDraggableProps {
  onColumnResizeStop?: OnColumnResizeStop
  isLast?: boolean
  tableHeight?: number
  colWidth?: number
  naturalWidth?: number
  renderMeasurementColumn?: RenderMeasurementColumn
  availableWidth?: number
}

export type HeaderCellRenderProps = BaseHeaderCellRenderProps | DraggableHeaderCellRenderProps

export type HeaderCellRenderProp = (props: HeaderCellRenderProps) => any

TableColumn.defaultProps = {
  align: 'center',
  multiline: false,
  permanent: false,
}

export default TableColumn

import { combineReducers } from 'redux'

import parsingReducer, { ParsingState } from './parsingReducer'

export interface ImportingState {
  parsing: ParsingState
}

export default combineReducers({
  parsing: parsingReducer,
})

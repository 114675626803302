import { useMemo } from 'react'

import { css } from '@emotion/react'
import { Box, useTheme } from '@mui/material'

import moment from 'moment'
import { RRule } from 'rrule'

import { HbText } from 'components/HbComponents/Text/HbText'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useToggle } from 'hooks'

const useCss = () => {
  const theme = useTheme()

  return {
    firstSampleRun: css({
      marginBottom: theme.spacing(1),
    }),
    sampleListToggleButtonToggled: css({
      marginBottom: theme.spacing(3),
    }),
    sampleListHeading: css({
      marginBottom: theme.spacing(1),
    }),
    sampleList: css({
      listStyle: 'disc',
    }),
    sampleListItem: css({
      display: 'list-item',
      marginLeft: theme.spacing(4),
      lineHeight: theme.spacing(3),
    }),
    sampleListContainer: css({
      marginTop: theme.spacing(2),
      background: theme.palette.styleguide.backgroundLight,
      padding: theme.spacing(2),
    }),
  }
}

const generateScheduleSample = (schedule: RRule, timezone: string, maxCount = 11) => {
  // Hacky, but RRule does not handle timezones very well and assumes all times are UTC
  // This method works around this by converting the times via offsets and manually
  // appending the correct timezone abbreviation
  const z = moment.tz(timezone).format('z')

  const now = moment().toDate()

  // RRule is does not take into account the timezone in Date objects and assumes whatever
  // time is in UTC. This is a hack to "convert" our time into UTC via offset
  now.setMinutes(now.getMinutes() - now.getTimezoneOffset())

  const dates = []
  let current = schedule.after(now)

  while (dates.length < maxCount && current) {
    dates.push(current)
    current = schedule.after(current)
  }

  return dates.map((d) => moment(d).tz('Etc/GMT+0').format(`dddd, MMMM D, YYYY h:mm A [${z}]`))
}

interface Props {
  schedule: RRule
  timezone: string
}

export default function NextScheduledRuns({ schedule, timezone }: Props) {
  const styles = useCss()
  const usage = useUsage()
  const { value: showRuns, toggle: toggleRuns } = useToggle(false, (toggled) =>
    usage.logEvent({ name: 'automations:editorToggleRuns:clicked', data: { toggled } })
  )

  const sampleDates = useMemo(() => generateScheduleSample(schedule, timezone), [schedule, timezone])

  return (
    <Box css={styles.sampleListContainer}>
      <HbText bold tag="h3" css={styles.sampleListHeading}>
        Next run
      </HbText>
      <HbText tag="p" css={styles.firstSampleRun}>
        {sampleDates.at(0)}
      </HbText>
      {sampleDates.length > 1 ? (
        <>
          <HbText
            bold
            tag="button"
            color="blue"
            css={showRuns ? styles.sampleListToggleButtonToggled : null}
            onClick={(e) => {
              e.preventDefault()
              toggleRuns()
            }}
          >
            {`${showRuns ? 'Hide' : 'Show'} next ${sampleDates.length - 1} runs`}
          </HbText>
          {showRuns ? (
            <>
              <HbText bold tag="h4" css={styles.sampleListHeading}>
                Next {sampleDates.length - 1} runs
              </HbText>
              <ul css={styles.sampleList}>
                {sampleDates.slice(1).map((s) => (
                  <li key={s} css={styles.sampleListItem}>
                    {s}
                  </li>
                ))}
              </ul>
            </>
          ) : null}
        </>
      ) : null}
    </Box>
  )
}

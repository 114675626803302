import { createSelector } from '@reduxjs/toolkit'

import { isEmpty, isNil, flatten, every } from 'lodash'

import { SnakeDataTypeKey } from 'actions/importingFields.types'
import { State, useSelector } from 'actions/store'
import { setActive } from 'actions/viewActions'
import { useFeatureFlag } from 'hooks'
import {
  FilingInstitution,
  ImportableType,
  Organization,
  OrganizationReviewTypes,
  ReviewType,
} from 'reducers/applicationReducer'
import { ParsingState, ParsingOptions } from 'reducers/importing/parsingReducer'
import { MetricsData } from 'reducers/metricsReducer'
import { TagGroup } from 'reducers/organizationReducer'
import { Review, Task, Workflow } from 'reducers/reviewsReducer'
import { BadgePermissionsEnum, FeatureFlag } from 'types/api'
import { CurrentAccount } from 'types/hb'

import { createLibraryDataLoadingPrefixSerializer } from './loadingTokenHelpers'

// Collection of functions for helping to deal with Redux state.

type Token = string

export function getOperationsPending(state: State, opKey: string) {
  return state.view.pendingOps[opKey] ?? 0
}

export function getInvestigationByToken(state: State, investigationToken: Token) {
  return state.investigations.byToken[investigationToken]
}

export function getIsLibraryDataCreating(state: State, investigationToken: Token) {
  return Object.entries(state.view.loading).some(
    ([key, value]) => createLibraryDataLoadingPrefixSerializer.match(key, investigationToken) && !!value
  )
}

export function getActiveDataType(state: State) {
  return state.view.activeEntityType
}

export function getTransactionsFullScreen(state: State) {
  return state.transactions.fullScreen
}

export function getReviewByToken(state: State, reviewToken: Token) {
  return state.reviews.byToken[reviewToken]
}

export function getReviewWorkflow(state: State, reviewToken: Token) {
  return state.reviews.workflowsByToken[reviewToken] || null // Return null instead of undefined
}

export function getReviewWorkflowTask(state: State, reviewToken: Token, taskToken: Token) {
  const workflow = getReviewWorkflow(state, reviewToken)

  if (!workflow) {
    return null
  }

  const tasks = flatten([workflow.tasks].concat(workflow.actions.map((a: any) => a.tasks)))

  return tasks.find((t) => t.meta.token === taskToken)
}

export function getTaskBySlugFromWorkflow(workflow: Workflow, taskSlug: string) {
  if (isEmpty(workflow) || isEmpty(taskSlug)) {
    return undefined
  }

  const allTasks = workflow.actions.reduce((accum, a) => accum.concat(a.tasks), [] as Task[]).concat(workflow.tasks)
  const task = allTasks.find((t) => t.meta.slug === taskSlug)

  return task
}

export function getLoadingState(state: State) {
  return state.view.loading
}

export function getLoadingBool(state: State, ...loadingTokens: string[]): boolean {
  const loadingState = getLoadingState(state)
  return !!loadingTokens.reduce((accum, token) => accum || loadingState[token], false)
}

export function getCurrentAccount(state: State): CurrentAccount {
  // Making the assumption here that this selector is only called when authenticated.
  // Setting TS types to not allow null/undefined as return values for convenience.
  return state.application?.currentAccount as CurrentAccount
}

export function getCurrentOrganizationToken(state: State): string {
  // Making the assumption here that this selector is only called when authenticated.
  // Setting TS types to not allow null/undefined as return values for convenience.
  return state.application.currentOrganizationToken ?? ''
}

export function getCurrentOrganization(state: State): Organization {
  // Making the assumption here that this selector is only called when authenticated.
  // Setting TS types to not allow null/undefined as return values for convenience.
  return state.application.organizations.find(
    (org) => org.token === state.application.currentOrganizationToken
  ) as Organization
}

export function getSupportedLibraryDataTypes(state: State) {
  return getCurrentOrganization(state)?.supportedLibraryDataTypes
}

export function getImportableTypes(state: State): ImportableType[] {
  return state.application?.importableTypes
}

function makeGetCurrentFilingInstitutions() {
  let lastFilingInstitutionsByToken: { [key: string]: FilingInstitution }
  let lastFilingInstitutions: FilingInstitution[]

  // Memoize the selector so that it doesn't get executed on every action.
  return (state: State) => {
    const { filingInstitutionsByToken } = state.application
    if (filingInstitutionsByToken === lastFilingInstitutionsByToken) {
      return lastFilingInstitutions
    }
    lastFilingInstitutionsByToken = filingInstitutionsByToken

    const allFilingInstitutions = Object.values(filingInstitutionsByToken)
    const currentFilingInstitutions = allFilingInstitutions.filter(
      (fi) => fi.organizationToken === state.application.currentOrganizationToken
    )
    lastFilingInstitutions = currentFilingInstitutions

    return lastFilingInstitutions
  }
}

export function getOrganizations(state: State) {
  return state.application.organizations
}

export function getOrganizationFeatureFlag(state: State, flagName: FeatureFlag): boolean {
  const organization = getCurrentOrganization(state)
  return organization ? organization.featureFlags[flagName] === true : false
}

export const getCurrentFilingInstitutions = makeGetCurrentFilingInstitutions()

type TokenByToken = { [key: Token]: Token }
function makeCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken() {
  let lastFilingInstitutions: FilingInstitution[]
  // For now, we only need to know that a library_financial_institution_token
  // is linked to a FilingInstitution.  So, we'll just index the filing institution token
  // instead of the entire object.
  let lastFilingInstitutionsByLibraryFinancialInstitutionToken: TokenByToken

  // Memoize the selector so that it doesn't get executed on every action.
  return (state: State) => {
    const currentFilingInstitution = getCurrentFilingInstitutions(state)
    if (currentFilingInstitution === lastFilingInstitutions) {
      return lastFilingInstitutionsByLibraryFinancialInstitutionToken
    }
    lastFilingInstitutions = currentFilingInstitution
    // NOTE: currentFilingInstitution already filters on
    // fi.organizationToken === state.application.currentOrganizationToken
    const currentFilingInstitutionsByLibraryFinancialInstitutionToken = currentFilingInstitution.reduce(
      (accum: TokenByToken, fi: FilingInstitution) => {
        if (fi.libraryFinancialInstitutionToken) {
          accum[fi.libraryFinancialInstitutionToken] = fi.token
        }
        return accum
      },
      {} as TokenByToken
    )

    lastFilingInstitutionsByLibraryFinancialInstitutionToken =
      currentFilingInstitutionsByLibraryFinancialInstitutionToken
    return lastFilingInstitutionsByLibraryFinancialInstitutionToken
  }
}

export const getCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken =
  makeCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken()

export function getOrganizationTeammates(state: State) {
  return state.application.userAccounts
}

export function getAccount(state: State, token: Token) {
  return state.application.userAccounts.find((a: any) => a.token === token)
}

const getFilingInstitutionTokenFromLibraryFinancialInstitutionToken = (
  tokenIndex: TokenByToken | undefined,
  token: string
) => tokenIndex?.[token]

export const isFiTokenInIndex = (tokenIndex: TokenByToken | undefined, token: string) =>
  !!getFilingInstitutionTokenFromLibraryFinancialInstitutionToken(tokenIndex, token)

export function getLibraryFinancialInstitutionFilingInstitutionToken(state: State, financialInstitutionToken: Token) {
  const tokenIndex = getCurrentFilingInstitutionTokenByLibraryFinancialInstitutionToken(state)
  return getFilingInstitutionTokenFromLibraryFinancialInstitutionToken(tokenIndex, financialInstitutionToken)
}

export function isTokenLinkedToFilingInstitution(state: State, financialInstitutionToken: Token) {
  return !!getLibraryFinancialInstitutionFilingInstitutionToken(state, financialInstitutionToken)
}

export function getCurrentPermissions(state: State) {
  const currentOrg = getCurrentOrganization(state)
  if (isNil(currentOrg)) {
    return []
  }

  const currentOrgToken = currentOrg.token
  const currentOrgPermission = state.application.organizationPermissions.find(
    (orgRole: any) => orgRole.organizationToken === currentOrgToken
  )
  if (isNil(currentOrgPermission) || isNil(currentOrgPermission.permissions)) {
    return []
  }

  return currentOrgPermission.permissions
}

export function hasPermission(state: State, permission: BadgePermissionsEnum): boolean {
  const permissions = getCurrentPermissions(state)
  return permissions.includes(permission)
}

export function useHasPermission(permission: BadgePermissionsEnum) {
  return useSelector((state) => hasPermission(state, permission))
}

export function hasPermissions(state: State, permissions: BadgePermissionsEnum[]) {
  const currentPermissions = getCurrentPermissions(state)
  return every(permissions, (perm) => currentPermissions.includes(perm))
}

export function hasAnyPermissions(state: State, permissions: BadgePermissionsEnum[]) {
  const currentPermissions = getCurrentPermissions(state)
  return permissions.some((perm) => currentPermissions.includes(perm))
}

function getReviewTypes(base: OrganizationReviewTypes[], state: State) {
  const currentOrgToken = getCurrentOrganization(state).token
  const currentOrgReviewTypes = base.find((orgReviewType) => orgReviewType.organizationToken === currentOrgToken)

  return currentOrgReviewTypes?.reviewTypes
}

const noReviewTypes: ReviewType[] = []
const sortReviewTypes = (reviewTypes: ReviewType[]) => {
  return reviewTypes.slice().sort((a, b) => a.name.localeCompare(b.name))
}

function getUnsortedAccountReviewTypes(state: State): ReviewType[] {
  return getReviewTypes(state.application.reviewTypes, state) ?? noReviewTypes
}

export const getAccountReviewTypes = createSelector(getUnsortedAccountReviewTypes, sortReviewTypes)

function getUnsortedOrganizationReviewTypes(state: State): ReviewType[] {
  return getReviewTypes(state.application.organizationReviewTypes, state) ?? noReviewTypes
}

export const getOrganizationReviewTypes = createSelector(getUnsortedOrganizationReviewTypes, sortReviewTypes)

export function getCurrentTriggerRules(state: State) {
  const currentOrgToken = getCurrentOrganization(state).token
  const currentOrgTriggerRules = state.application.triggerRules.find(
    (orgTriggerRule) => orgTriggerRule.organizationToken === currentOrgToken
  )

  return currentOrgTriggerRules?.triggerRules
}

export function getOrganizationToken(state: State): string {
  const organization = getCurrentOrganization(state)
  // Making the assumption here that this selector is only called when authenticated.
  // Setting TS types to not allow null/undefined as return values for convenience.
  return organization ? organization.token : (null as unknown as string)
}

export function stateSanitizer(_state: unknown) {
  return {}
}

export function actionSanitizer(action: { type: string }) {
  return {
    type: action.type,
  }
}

export function getParsingOptions(
  parsingState: ParsingState,
  dataType: SnakeDataTypeKey | 'case_import'
): ParsingOptions {
  return (
    parsingState.optionsByType[dataType] || {
      hasHeaders: true,
    }
  )
}

export function getMetricsByToken(state: State, token: Token) {
  return ((state.metrics || {}) as { [key: string]: MetricsData })[token]
}

export function getMetricsDataByToken(state: State, token: Token) {
  return getMetricsByToken(state, token)?.data
}

export function getMetricsSelectorsByToken(state: State, token: Token) {
  return getMetricsByToken(state, token)?.selectors
}

export function getMetricsLoadingKeyByToken(token: Token): string {
  return `metrics-${token}`
}

export function getIsAssignee(state: State, review: Review) {
  if (!review) {
    return false
  }

  const assigneeToken = review.assignee && review.assignee.token
  const currentAccount = getCurrentAccount(state)

  return !!(currentAccount && assigneeToken && assigneeToken === currentAccount.token)
}

export function getEnums(state: State) {
  return (state.application && state.application.enums) || {}
}

export function getEnum(state: State, name: string) {
  return getEnums(state)[name]
}

export function getDataMappings(state: State) {
  return state.application && state.application.dataMappings
}

export function getDataMapping(state: State, name: string) {
  return getDataMappings(state)[name]
}

export function getTagGroups(state: State, key: string): TagGroup[] {
  return state.organization.tagGroupsByKey[key]
}

export function getLoginBannerMessage(state: State) {
  const organization = getCurrentOrganization(state)
  return organization ? organization.loginBannerMessage : null
}

export function getCountries(state: State) {
  return state.application.countries
}

export function getCountryByCode(state: State, postalAbbrev: string) {
  return state.application.countries[postalAbbrev]
}

export function getEnvironment(state: State) {
  return state.application.environment
}

export function getActive(state: State, token: string) {
  return state.view.active[token]
}

export function canEditFormContext(state: State, contextName: string) {
  return !getActive(state, `form_${contextName}`)
}

export function setEditingFormContext(contextName: string, editing: boolean) {
  return setActive(`form_${contextName}`, editing)
}

export function getChurnzeroAppKey(state: State) {
  return state.application.churnzeroAppkey
}

export function getDemoMode(state: State): boolean {
  return getOrganizationFeatureFlag(state, FeatureFlag.EnableDemoMode) && getEnvironment(state) !== 'production'
}

export const useCanSearchLibrarySubjects = () => {
  const ffEnabled = useFeatureFlag(FeatureFlag.CrmSearchPermission)
  const hasPermissionToSearch = useHasPermission(BadgePermissionsEnum.SearchLibrarySubjects)
  return ffEnabled ? hasPermissionToSearch : true
}

export const useInformationRequestsEnabled = () => {
  const hasReadAllRequestsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ReadAllInformationRequests)
  )
  return useFeatureFlag(FeatureFlag.EnableInformationRequests) && hasReadAllRequestsPermission
}

import React, { forwardRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: 11,
    ...theme.typography.noWrap,
    padding: `${theme.spacing(0.5)} ${theme.spacing(1.5)}`,
    background: theme.palette.background.medium,
    borderRadius: theme.shape.largeContainer.borderRadius,
  },
}))

export type Props = React.HTMLProps<HTMLSpanElement>
export const CountPill = forwardRef((props: Props, ref?: React.ForwardedRef<HTMLSpanElement>) => {
  const { className, ...otherProps } = props
  const { root } = useStyles()

  return <span ref={ref} className={classnames(root, className)} {...otherProps} />
})

import { useCallback, useMemo } from 'react'

import { useFeatureFlag } from 'hooks'
import { FeatureFlag, LibraryTypeEnum } from 'types/api'

import { ProfileMergeType } from '../types'

export const useGetAllowedProfileTypesForMerging = () => {
  const peopleMergeEnabled = useFeatureFlag(FeatureFlag.EnableLibraryMerging)
  const businessMergeEnabled = useFeatureFlag(FeatureFlag.EnableLibraryMergingBusinesses)
  const financialInstitutionsMergeEnabled = useFeatureFlag(FeatureFlag.EnableLibraryMergingFinancialInstitutions)

  const enabledTypesForMerging = useMemo(() => {
    const types: ProfileMergeType[] = []
    if (peopleMergeEnabled) types.push(LibraryTypeEnum.People)
    if (businessMergeEnabled) types.push(LibraryTypeEnum.Businesses)
    if (financialInstitutionsMergeEnabled) types.push(LibraryTypeEnum.FinancialInstitutions)
    return types
  }, [businessMergeEnabled, financialInstitutionsMergeEnabled, peopleMergeEnabled])

  const getIsAllowedMergeType = useCallback(
    (libraryType: LibraryTypeEnum): libraryType is ProfileMergeType =>
      enabledTypesForMerging.includes(libraryType as ProfileMergeType),
    [enabledTypesForMerging]
  )

  const hasEnabledTypesForMerging = !!enabledTypesForMerging.length

  return { enabledTypesForMerging, getIsAllowedMergeType, hasEnabledTypesForMerging }
}

import React, { CSSProperties, ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { OpenInNewIcon } from 'icons'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    '&, &:link, &:visited, &:active, &:hover': {
      color: theme.palette.action.active,
    },

    '&$small': {
      ...theme.typography.sm,
    },
  },
  small: {},
  icon: {
    fontSize: '0.8rem',
  },
  // Variants:
  minimal: {
    alignItems: 'center',
    display: 'flex',
    textDecoration: 'none',

    '& $icon': {
      marginLeft: '3px',
    },

    '&:hover, &:focus': {
      textDecoration: 'underline',
    },
  },
  text: {
    '&, &:link, &:visited': {
      color: theme.palette.text.primary,
      textDecoration: 'none',
    },
    '&:active, &:hover': {
      color: theme.palette.action.active,
    },
  },
}))

export interface Props {
  to: string
  size?: 'md' | 'sm'
  hideIcon?: boolean
  variant?: 'minimal' | 'text'
  children: ReactNode
  onClick?: (e: React.MouseEvent) => void
  classes?: {
    root: string
    icon?: string
  }
  style?: CSSProperties
  className?: string
}

function ExternalLink(props: Props) {
  const { classes, to, variant, hideIcon, size = 'md', children, onClick, style, className: classNameProp } = props

  // Links that are relative should be treated as absolute
  const link = to?.includes('://') ? to : `//${to}`
  const { root, icon, small, minimal, text } = useStyles()

  const className = classnames(
    root,
    {
      [small]: size === 'sm',
      [minimal]: variant === 'minimal',
      [text]: variant === 'text',
    },
    classes?.root,
    classNameProp
  )

  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      onClick(e)
    }

    e.stopPropagation()
  }

  return (
    <a className={className} href={link} target="_blank" rel="noopener noreferrer" onClick={handleClick} style={style}>
      {children} {!hideIcon && <OpenInNewIcon classes={{ root: classnames(icon, classes?.icon) }} />}
    </a>
  )
}

export default ExternalLink

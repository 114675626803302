import { CallMade, Cancel, Check, Timer, NotInterested } from '@mui/icons-material'

import { HbTag, Props as HbTagProps } from 'components/HbComponents/HbTag'
import { HbTooltip, Placement } from 'components/HbComponents/HbTooltip'
import { DateCell } from 'dashboards/shared/components/Cells'
import ReviewAspect from 'dashboards/shared/components/Dashboard/ReviewAspect'
import { dig } from 'helpers/objHelpers'

import { DateFormatter, useDateFormatter } from 'hooks/DateFormatHooks'
import { OpenInNewIcon } from 'icons'
import { AutomationExecutionsDashboardEntry } from 'reducers/dashboards/mappers'
import { DashboardColumn } from 'reducers/reviewsReducer'
import { AutomationRuleExecutionStatus } from 'types/api'

const defaultDateFormat = 'L · LT'

interface TagConfig {
  label: string
  props: Pick<HbTagProps, 'themeColor' | 'icon'>
}

const StatusCellConfig: Record<AutomationRuleExecutionStatus, TagConfig> = {
  [AutomationRuleExecutionStatus.Completed]: {
    label: 'Success',
    props: {
      themeColor: 'mint',
      icon: <Check />,
    },
  },
  [AutomationRuleExecutionStatus.Error]: {
    label: 'Error',
    props: {
      themeColor: 'coral',
      icon: <Cancel />,
    },
  },
  [AutomationRuleExecutionStatus.Pending]: {
    label: 'Pending',
    props: {
      themeColor: 'salmon',
      icon: <Timer />,
    },
  },
  [AutomationRuleExecutionStatus.Started]: {
    label: 'Started',
    props: {
      themeColor: 'sea',
      icon: <CallMade />,
    },
  },
  [AutomationRuleExecutionStatus.NotSampled]: {
    label: 'Not Sampled',
    props: {
      themeColor: 'linen',
      icon: <NotInterested />,
    },
  },
}

const StatusCell = ({ entry }: { entry: AutomationExecutionsDashboardEntry }) => {
  const config = StatusCellConfig[entry.status]

  if (config.label === 'Error') {
    return (
      <ReviewAspect>
        <HbTooltip title={entry.details} placement={Placement.Top}>
          <HbTag {...config.props} label={config.label} />
        </HbTooltip>
      </ReviewAspect>
    )
  }
  return (
    <ReviewAspect>
      <HbTag {...config.props} label={config.label} />
    </ReviewAspect>
  )
}

const EventCell = ({ entry }: { entry: AutomationExecutionsDashboardEntry }) => {
  const { event } = entry

  // We can't link out to a Middesk Business, it's not an entity in our system
  if (!event || !event.__typename || event.__typename === 'AutomationMiddeskBusinessEvent') {
    return (
      <ReviewAspect key={entry.token}>
        <span>-</span>
      </ReviewAspect>
    )
  }

  return (
    <ReviewAspect key={entry.token}>
      <HbTooltip title="Opens a new browser tab" placement={Placement.Top}>
        {/* No need for no-referrer/no-opener here because we're opening our own page */}
        {/* eslint-disable-next-line react/jsx-no-target-blank */}
        <a
          href={event.eventableHref}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span style={{ marginRight: 5, textTransform: 'capitalize' }}>{event.domainType}</span>
          <OpenInNewIcon fontSize="small" />
        </a>
      </HbTooltip>
    </ReviewAspect>
  )
}

function renderCell(column: DashboardColumn, formatDate: DateFormatter) {
  const makeKey = (token: string) => `${token}::${column.apiName}`
  switch (column.apiName) {
    case 'createdAt':
      return ({ createdAt }: AutomationExecutionsDashboardEntry) => (
        <DateCell date={createdAt} formatDate={formatDate} />
      )
    case 'status':
      return (e: AutomationExecutionsDashboardEntry) => <StatusCell entry={e} />
    case 'ruleName':
      return (e: AutomationExecutionsDashboardEntry) => e.ruleName
    case 'eventable':
      return (e: AutomationExecutionsDashboardEntry) => <EventCell entry={e} />
    default:
      return (e: AutomationExecutionsDashboardEntry) => {
        return <ReviewAspect key={makeKey(e.token)}>{dig(e, column.attribute)}</ReviewAspect>
      }
  }
}

export const useAutomationRuleExecutionsTableCellRenderer = () => {
  const formatDate = useDateFormatter(defaultDateFormat)
  return (column: DashboardColumn) => renderCell(column, formatDate)
}

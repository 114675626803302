import { combineReducers, Reducer } from 'redux'

import makeDashboardConfig from 'dashboards/shared/react/makeDashboardConfig'
import { DashboardTypeEnum } from 'types/api'

import { DashboardSlice } from './dashboards.constants'

import { makeDashboardReducer } from './dashboards.reducer'
import { DashboardState } from './dashboards.types'

export interface DashboardsState {
  [DashboardTypeEnum.Reviews]: DashboardState<DashboardTypeEnum.Reviews>
  [DashboardTypeEnum.Profiles]: DashboardState<DashboardTypeEnum.Profiles>
  [DashboardTypeEnum.InformationRequests]: DashboardState<DashboardTypeEnum.InformationRequests>
  [DashboardTypeEnum.Transactions]: DashboardState<DashboardTypeEnum.Transactions>
  [DashboardTypeEnum.Filings]: DashboardState<DashboardTypeEnum.Filings>
  [DashboardTypeEnum.CtrFilings]: DashboardState<DashboardTypeEnum.CtrFilings>
  [DashboardTypeEnum.AutomationExecutions]: DashboardState<DashboardTypeEnum.AutomationExecutions>
  [DashboardTypeEnum.CustomFields]: DashboardState<DashboardTypeEnum.CustomFields>
  [DashboardTypeEnum.CaseFiles]: DashboardState<DashboardTypeEnum.CaseFiles>
}

// adds some bonus type safety for combineReducers in case it's not clear a slice needs to be added here
const getReducers = <T extends { [K in DashboardSlice]: Reducer }>(dashboardReducers: T) => dashboardReducers

// used for injecting initial state for tests
export const makeCombineReducers = (initialState: DashboardsState) =>
  combineReducers(
    getReducers({
      [DashboardTypeEnum.Reviews]: makeDashboardReducer(
        DashboardTypeEnum.Reviews,
        initialState[DashboardTypeEnum.Reviews]
      ),
      [DashboardTypeEnum.Profiles]: makeDashboardReducer(
        DashboardTypeEnum.Profiles,
        initialState[DashboardTypeEnum.Profiles]
      ),
      [DashboardTypeEnum.InformationRequests]: makeDashboardReducer(
        DashboardTypeEnum.InformationRequests,
        initialState[DashboardTypeEnum.InformationRequests]
      ),
      [DashboardTypeEnum.Transactions]: makeDashboardReducer(
        DashboardTypeEnum.Transactions,
        initialState[DashboardTypeEnum.Transactions]
      ),
      [DashboardTypeEnum.Filings]: makeDashboardReducer(
        DashboardTypeEnum.Filings,
        initialState[DashboardTypeEnum.Filings]
      ),
      [DashboardTypeEnum.CtrFilings]: makeDashboardReducer(
        DashboardTypeEnum.CtrFilings,
        initialState[DashboardTypeEnum.CtrFilings]
      ),
      [DashboardTypeEnum.AutomationExecutions]: makeDashboardReducer(
        DashboardTypeEnum.AutomationExecutions,
        initialState[DashboardTypeEnum.AutomationExecutions]
      ),
      [DashboardTypeEnum.CustomFields]: makeDashboardReducer(
        DashboardTypeEnum.CustomFields,
        initialState[DashboardTypeEnum.CustomFields]
      ),
      [DashboardTypeEnum.CaseFiles]: makeDashboardReducer(
        DashboardTypeEnum.CaseFiles,
        initialState[DashboardTypeEnum.CaseFiles]
      ),
    })
  )

// Create one reducer per dashboard slice.
export default combineReducers(makeDashboardConfig(makeDashboardReducer))

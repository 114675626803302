import { ChangeEvent } from 'react'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import HbDropdown, { HbDropdownProps } from 'components/HbComponents/Form/HbInputs/HbDropdown/HbDropdown'

export type HbRHFDropdownProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
> = MakeRequired<UseControllerProps<TFieldValues, TName>, 'control'> & HbDropdownProps

/**
 * Drop-in component for react-hook-form
 * It expects `control` as well as the other `Controller` props
 */
function HbRHFDropdown<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ control, name, rules, onChange, label, ...rest }: HbRHFDropdownProps<TFieldValues, TName>) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field, fieldState }) => {
        return (
          <HbDropdown
            label={label}
            variant="outlined"
            {...rest}
            {...field}
            onChange={
              onChange
                ? (value: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(value)
                    onChange(value)
                  }
                : field.onChange
            }
            ref={null}
            inputRef={field.ref}
            errors={fieldState.error?.message}
          />
        )
      }}
    />
  )
}

export default HbRHFDropdown

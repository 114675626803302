import { useState, useEffect, ReactElement } from 'react'

export interface Props {
  children: ReactElement
  delay?: number
}

export function ShowAfterDelay({ children, delay = 500 }: Props) {
  const [showing, setShowing] = useState(false)
  useEffect(() => {
    const id = setTimeout(() => setShowing(true), delay)
    return () => clearTimeout(id)
  }, [delay])

  if (!showing) {
    return null
  }

  return children
}

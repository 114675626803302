import { useEffect } from 'react'

import { useSelector } from 'actions/store'

// See https://developer.mozilla.org/en-US/docs/Web/API/Broadcast_Channel_API
const bc = 'BroadcastChannel' in window ? new BroadcastChannel('hb-broadcast') : null

export type PostMessage = 'SIGN_OUT'

export const broadcastMessage = (message: PostMessage) => {
  bc?.postMessage(message)
}

export const useBroadcastChannelMessageHandler = () => {
  const signOutPath = useSelector((state) => state.application.urls).destroyCredentialSessionPath
  useEffect(() => {
    if (bc) {
      bc.onmessage = (event: MessageEvent<PostMessage>) => {
        if (event.origin !== document.location.origin) {
          return
        }
        switch (event.data) {
          case 'SIGN_OUT':
            document.location = signOutPath
            break
          default:
            break
        }
      }
    }
    return () => {
      // Cleanup
      if (bc) {
        bc.close()
      }
    }
  }, [signOutPath])
}

import { DashboardTypeEnum } from 'types/api'

export const dashboardSlices = [
  DashboardTypeEnum.Reviews,
  DashboardTypeEnum.Profiles,
  DashboardTypeEnum.InformationRequests,
  DashboardTypeEnum.Transactions,
  DashboardTypeEnum.Filings,
  DashboardTypeEnum.CtrFilings,
  DashboardTypeEnum.AutomationExecutions,
  DashboardTypeEnum.CustomFields,
  DashboardTypeEnum.CaseFiles,
] as const
export type DashboardSlice = (typeof dashboardSlices)[number]

export const NUM_DASHBOARD_ROWS_UPPER_LIMIT = 10_000

export const DEFAULT_PAGE_SIZE = 50

const dashboardSliceToPageSizeStorageKey = (slice: DashboardSlice) => `${slice}_pageSize`

export const getStoredPageSize = (slice: DashboardSlice) => {
  let value: string | null = null
  try {
    value = localStorage.getItem(dashboardSliceToPageSizeStorageKey(slice))
  } catch (e) {
    // If localStorage isn't available, don't use it
    return DEFAULT_PAGE_SIZE
  }

  return Number(value) || DEFAULT_PAGE_SIZE
}

export const storePageSize = (slice: DashboardSlice, pageSize: number) => {
  try {
    localStorage.setItem(dashboardSliceToPageSizeStorageKey(slice), pageSize.toString())
  } catch (e) {
    // If localStorage isn't available, don't use it
  }
}

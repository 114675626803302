import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CommentIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 15 16">
    <path d="M3 4h9v1H3zm0 3h9v1H3z" />
    <path d="M14.5 0H.5a.5.5 0 0 0-.5.5v11a.5.5 0 0 0 .5.5H3v3a.5.5 0 0 0 .81.39L8.05 12h6.45a.5.5 0 0 0 .5-.5V.5a.5.5 0 0 0-.5-.5zM14 10v1H8l-4 3v-3H1V1h13z" />
  </SvgIcon>
)

export default CommentIcon

// Based on https://github.com/facebook/lexical/blob/fc5c5b13b070fc747e0ee549453243fed792f65b/packages/lexical-playground/src/plugins/MentionsPlugin.tsx

import { ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { mergeRegister } from '@lexical/utils'
// TODO: Use HbPopper.
// eslint-disable-next-line no-restricted-imports
import { Paper, Popper } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import {
  $createTextNode,
  $getRoot,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_LOW,
  COMMAND_PRIORITY_NORMAL,
  KEY_ARROW_DOWN_COMMAND,
  KEY_ARROW_UP_COMMAND,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
  KEY_TAB_COMMAND,
} from 'lexical'

import { Theme } from 'types/hb'

import MentionNode, { $createMentionNode } from './MentionNode'

import type { LexicalEditor, RangeSelection } from 'lexical'
import type { UpdateListener } from 'lexical/LexicalEditor'

type TypeAheadMatch = {
  leadOffset: number
  matchingString: string
  replaceableString: string
}

type Resolution = {
  match: TypeAheadMatch
  range: Range
}

const PUNCTUATION = '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;'

// eslint-disable-next-line security/detect-non-literal-regexp
const bareSuggestionsRegex = new RegExp(`(^|[^#])((?:\\b[A-Z][^\\s${PUNCTUATION}]{1,})$)`, 'i')

const TRIGGERS = ['@', '\\uff20'].join('')

// Chars we expect to see in a suggestion (non-space, non-punctuation).
const VALID_CHARS = `[^${TRIGGERS}${PUNCTUATION}\\s]`

// Non-standard series of chars. Each series must be preceded and followed by
// a valid char.
const VALID_JOINS =
  `${
    '(?:' +
    '\\.[ |$]|' + // E.g. "r. " in "Mr. Smith"
    ' |' + // E.g. " " in "Josh Duck"
    '['
  }${PUNCTUATION}]|` + // E.g. "-' in "Salier-Hellendag"
  `)`

const LENGTH_LIMIT = 75

// eslint-disable-next-line security/detect-non-literal-regexp
const suggestionRegex = new RegExp(
  // eslint-disable-next-line no-useless-concat
  `${'(^|\\s|\\()(' + '['}${TRIGGERS}]` + `((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}})` + `)$`
)

export type Suggestion = {
  value: string
  additionalSearch?: Array<string>
  type: 'mention' | 'text'
  priority?: number
  renderListItem?: () => ReactNode
}

function compareSuggestions(s1: Suggestion, s2: Suggestion) {
  if (s1.priority === s2.priority) {
    if (s1.value.length !== s2.value.length) {
      return s1.value.length - s2.value.length
    }

    return s1.value.localeCompare(s2.value)
  }

  return (s1.priority || 0) - (s2.priority || 0)
}

function search(input: string, suggestions: Array<Suggestion>) {
  if (!input) {
    return suggestions.sort(compareSuggestions)
  }

  const lowerInput = input.toLocaleLowerCase()
  const results = suggestions.filter(
    (suggestion) =>
      input.localeCompare(suggestion.value, 'en', { sensitivity: 'base' }) !== 0 &&
      (suggestion.value.toLocaleLowerCase().includes(lowerInput) ||
        suggestion.additionalSearch?.some((additional) => additional.toLocaleLowerCase().includes(lowerInput)))
  )

  if (results.length === 0) {
    return null
  }

  return results.sort(compareSuggestions)
}

function useFilteredSuggestions(
  input: string,
  suggestions: Array<Suggestion>,
  types: readonly Suggestion['type'][] = []
) {
  const [results, setResults] = useState<Array<Suggestion> | null>(null)

  useEffect(() => {
    const newResults = search(
      input,
      suggestions.filter((s) => types.includes(s.type))
    )
    setResults(newResults)
  }, [input, suggestions, types])

  return results?.length ? results : null
}

const useSuggestionsListStyles = makeStyles((theme: Theme) => ({
  popper: {
    zIndex: theme.zIndex.tooltip,
  },
  root: {
    background: theme.palette.background.paper,
    maxHeight: 300,
  },
  list: {
    maxHeight: 'inherit',
    overflowY: 'auto',
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  menu: {
    padding: theme.spacing(),
    borderRadius: '12px',
  },
  item: {
    padding: theme.spacing(),
    borderRadius: '12px',

    '&:focus, &:hover': {
      background: theme.palette.action.hover,
    },

    '&:not(:last-child)': {
      marginBottom: theme.spacing(),
    },
  },
  itemActive: {
    background: theme.palette.action.hover,
  },
}))

const textSuggestionType = ['text'] as const
const mentionAndTextSuggestionTypes = ['mention', 'text'] as const

function SuggestionsList({
  close,
  onComplete,
  editor,
  resolution,
  suggestions,
}: {
  close: () => void
  onComplete: () => void
  editor: LexicalEditor
  resolution: Resolution
  suggestions: Array<Suggestion>
}) {
  const classes = useSuggestionsListStyles()
  const listRef = useRef<HTMLUListElement>(null)
  const { match } = resolution
  const results = useFilteredSuggestions(
    match.matchingString,
    suggestions,
    match.replaceableString.startsWith('@') ? mentionAndTextSuggestionTypes : textSuggestionType
  )
  const [selectedIndex, setSelectedIndex] = useState<null | number>(null)

  const applyCurrentSelected = useCallback(() => {
    if (results === null || selectedIndex === null) {
      return
    }
    const selectedEntry = results[selectedIndex]

    close()
    onComplete()

    if (selectedEntry) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      insertNodeFromSearchResult(editor, selectedEntry, match)
    }
  }, [close, onComplete, match, editor, results, selectedIndex])

  const updateSelectedIndex = useCallback(
    (index) => {
      const rootElem = editor.getRootElement()
      if (rootElem !== null) {
        rootElem.setAttribute('aria-activedescendant', `typeahead-item-${index}`)
        setSelectedIndex(index)
      }
    },
    [editor]
  )

  useEffect(() => {
    return () => {
      const rootElem = editor.getRootElement()
      if (rootElem !== null) {
        rootElem.removeAttribute('aria-activedescendant')
      }
    }
  }, [editor])

  useEffect(() => {
    if (results === null) {
      setSelectedIndex(null)
    } else if (selectedIndex === null) {
      updateSelectedIndex(0)
    }
  }, [results, selectedIndex, updateSelectedIndex])

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_DOWN_COMMAND,
        (payload) => {
          const event = payload
          if (results !== null && selectedIndex !== null) {
            if (selectedIndex !== results.length - 1) {
              updateSelectedIndex(selectedIndex + 1)
            } else {
              updateSelectedIndex(0)
            }
            event.preventDefault()
            event.stopImmediatePropagation()
          }
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ARROW_UP_COMMAND,
        (payload) => {
          const event = payload
          if (results !== null && selectedIndex !== null) {
            if (selectedIndex !== 0) {
              updateSelectedIndex(selectedIndex - 1)
            } else {
              updateSelectedIndex(results.length - 1)
            }
            event.preventDefault()
            event.stopImmediatePropagation()
          }
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_ESCAPE_COMMAND,
        (payload) => {
          const event = payload
          if (results === null || selectedIndex === null) {
            return false
          }
          event.preventDefault()
          event.stopImmediatePropagation()
          close()
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand<KeyboardEvent>(
        KEY_TAB_COMMAND,
        (payload) => {
          const event = payload
          if (results === null || selectedIndex === null) {
            return false
          }
          event.preventDefault()
          event.stopImmediatePropagation()
          applyCurrentSelected()
          return true
        },
        COMMAND_PRIORITY_LOW
      ),
      editor.registerCommand(
        KEY_ENTER_COMMAND,
        (event: KeyboardEvent | null) => {
          if (results === null || selectedIndex === null) {
            return false
          }
          if (event !== null) {
            event.preventDefault()
            event.stopImmediatePropagation()
          }
          applyCurrentSelected()
          return true
        },
        COMMAND_PRIORITY_NORMAL // Ensure higher priority for enter here than for SubmitCommandPlugin
      )
    )
  }, [applyCurrentSelected, close, editor, results, selectedIndex, updateSelectedIndex])

  // Scroll list to match up with currently selected element
  useEffect(() => {
    const selectedItem = typeof selectedIndex === 'number' ? listRef.current?.children.item(selectedIndex) : null

    if (selectedItem) {
      selectedItem.scrollIntoView({ block: 'nearest', inline: 'nearest' })
    }
  }, [selectedIndex])

  if (results === null) {
    return null
  }

  const position = {
    clientWidth: 200,
    clientHeight: 300,
    getBoundingClientRect: () => resolution.range.getBoundingClientRect(),
  }

  return (
    <Popper open anchorEl={position} placement="bottom-start" className={classes.popper}>
      <Paper elevation={10} className={classnames(classes.root, classes.menu)}>
        <ul ref={listRef} role="menu" className={classes.list} data-testid="typeahead-menu">
          {results.map((s, index) => {
            const isSelected = selectedIndex === index

            return (
              <li
                key={index}
                role="menuitem"
                onMouseDownCapture={(e) => {
                  // Prevent clicks inside this popper from triggering the
                  // mousedown handler in components/library/Menu/Menu.jsx
                  e.stopPropagation()
                }}
                onClick={() => {
                  setSelectedIndex(index)
                  applyCurrentSelected()
                }}
                onMouseEnter={() => {
                  setSelectedIndex(index)
                }}
                // This will prevent a blur event from being fired and closing the popup
                onMouseDown={(e) => e.preventDefault()}
                className={classnames(s.renderListItem ? null : classes.item, isSelected && classes.itemActive)}
              >
                {s.renderListItem?.() ?? s.value}
              </li>
            )
          })}
        </ul>
      </Paper>
    </Popper>
  )
}

function checkForBareSuggestions(text: string): TypeAheadMatch | null {
  const match = bareSuggestionsRegex.exec(text)

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1]

    const matchingString = match[2]
    if (matchingString.length >= 3) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString,
      }
    }
  }
  return null
}

function checkForAtSignSuggestions(text: string): TypeAheadMatch | null {
  const match = suggestionRegex.exec(text)

  if (match !== null) {
    // The strategy ignores leading whitespace but we need to know it's
    // length to add it to the leadOffset
    const maybeLeadingWhitespace = match[1]

    const matchingString = match[3]
    return {
      leadOffset: match.index + maybeLeadingWhitespace.length,
      matchingString,
      replaceableString: match[2],
    }
  }
  return null
}

function getPossibleSuggestionsMatch(text: string): TypeAheadMatch | null {
  const match = checkForAtSignSuggestions(text)
  return match === null ? checkForBareSuggestions(text) : match
}

function getTextUpToAnchor(selection: RangeSelection): string | null {
  const { anchor } = selection
  if (anchor.type !== 'text') {
    return null
  }
  const anchorNode = anchor.getNode()
  // We should not be attempting to extract suggestions out of nodes
  // that are already being used for other core things. This is
  // especially true for token nodes, which can't be mutated at all.
  if (!anchorNode.isSimpleText()) {
    return null
  }
  const anchorOffset = anchor.offset
  return anchorNode.getTextContent().slice(0, anchorOffset)
}

function tryToPositionRange(match: TypeAheadMatch, range: Range): boolean {
  const domSelection = window.getSelection()
  if (domSelection === null || !domSelection.isCollapsed) {
    return false
  }
  const { anchorNode } = domSelection
  const startOffset = match.leadOffset
  const endOffset = domSelection.anchorOffset
  try {
    if (anchorNode) {
      range.setStart(anchorNode, startOffset)
      range.setEnd(anchorNode, endOffset)
    }
  } catch (error) {
    return false
  }

  return true
}

function getTextToSearch(editor: LexicalEditor): string | null {
  let text = null
  editor.getEditorState().read(() => {
    const selection = $getSelection()
    if (!$isRangeSelection(selection)) {
      return
    }
    text = getTextUpToAnchor(selection)
  })
  return text
}

function getFullText(editor: LexicalEditor): string | null {
  let text = null
  editor.getEditorState().read(() => {
    text = $getRoot().getTextContent()
  })
  return text
}

/**
 * Walk backwards along user input and forward through entity title to try
 * and replace more of the user's text with entity.
 *
 * E.g. User types "Hello Sarah Smit" and we match "Smit" to "Sarah Smith".
 * Replacing just the match would give us "Hello Sarah Sarah Smith".
 * Instead we find the string "Sarah Smit" and replace all of it.
 */
function getInsertionOffset(documentText: string, entryText: string, offset: number): number {
  let triggerOffset = offset
  for (let ii = triggerOffset; ii <= entryText.length; ii += 1) {
    if (documentText.substr(-ii) === entryText.substr(0, ii)) {
      triggerOffset = ii
    }
  }

  return triggerOffset
}

function insertNodeFromSearchResult(editor: LexicalEditor, suggestion: Suggestion, match: TypeAheadMatch): void {
  editor.update(() => {
    const selection = $getSelection()
    if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
      return
    }
    const { anchor } = selection
    if (anchor.type !== 'text') {
      return
    }
    const anchorNode = anchor.getNode()
    // We should not be attempting to extract suggestions out of nodes
    // that are already being used for other core things. This is
    // especially true for token nodes, which can't be mutated at all.
    if (!anchorNode.isSimpleText()) {
      return
    }
    const selectionOffset = anchor.offset
    const textContent = anchorNode.getTextContent().slice(0, selectionOffset)
    const characterOffset = match.replaceableString.length

    // Given a known offset for the suggestion match, look backward in the
    // text to see if there's a longer match to replace.
    const insertionOffset = getInsertionOffset(textContent, suggestion.value, characterOffset)
    const startOffset = selectionOffset - insertionOffset
    if (startOffset < 0) {
      return
    }

    let nodeToReplace
    if (startOffset === 0) {
      ;[nodeToReplace] = anchorNode.splitText(selectionOffset)
    } else {
      ;[, nodeToReplace] = anchorNode.splitText(startOffset, selectionOffset)
    }

    const nodeToInsert =
      suggestion.type === 'mention' ? $createMentionNode(suggestion.value) : $createTextNode(suggestion.value)
    nodeToReplace.replace(nodeToInsert)
    const nextSibling = nodeToInsert.getNextSibling()

    // If the inserted node is the last child, or the next node does not start with a space
    if (
      !nodeToInsert.getNextSibling() ||
      (nextSibling?.getType() === 'text' && !nextSibling.getTextContent().startsWith(' '))
    ) {
      // Insert a space
      const spaceNode = $createTextNode(' ')
      nodeToInsert.insertAfter(spaceNode)
      spaceNode.select()
    } else {
      // Otherwise, just place the caret after the inserted node
      nodeToInsert.select()
    }
  })
}

function isSelectionOnEntityBoundary(editor: LexicalEditor, offset: number): boolean {
  if (offset !== 0) {
    return false
  }
  return editor.getEditorState().read(() => {
    const selection = $getSelection()
    if ($isRangeSelection(selection)) {
      const { anchor } = selection
      const anchorNode = anchor.getNode()
      const prevSibling = anchorNode.getPreviousSibling()
      return $isTextNode(prevSibling) && prevSibling.isTextEntity()
    }
    return false
  })
}

function useTypeAhead(editor: LexicalEditor, suggestions: Array<Suggestion>) {
  const [resolution, setResolution] = useState<Resolution | null>(null)

  // Track if we just inserted some text
  const [inserted, setInserted] = useState(false)
  const insertedRef = useRef(inserted)
  // Track it on a ref so we don't have to rerun the effect below
  insertedRef.current = inserted

  useEffect(() => {
    if (!editor.hasNodes([MentionNode])) {
      throw new Error('TypeAheadPlugin: MentionNode not registered on editor')
    }
  }, [editor])

  useEffect(() => {
    let activeRange: Range | null = document.createRange()
    let previousFullText: string | null

    const updateListener: UpdateListener = () => {
      const range = activeRange
      const textToSearch = getTextToSearch(editor)
      const fullText = getFullText(editor)

      if (insertedRef.current) {
        setInserted(false)
        previousFullText = fullText
        return
      }

      if (fullText === previousFullText || range === null) {
        // Text hasn't changed, user is just moving around
        setResolution(null)
        return
      }

      previousFullText = fullText

      if (!textToSearch) {
        // There is no text to search, e.g., user pressed cmd+delete
        setResolution(null)
        return
      }

      const match = getPossibleSuggestionsMatch(textToSearch)
      if (match !== null && !isSelectionOnEntityBoundary(editor, match.leadOffset)) {
        const isRangePositioned = tryToPositionRange(match, range)
        if (isRangePositioned !== null) {
          // Move to startTransition in React 18
          setResolution({
            match,
            range,
          })
          return
        }
      }
      // Move to startTransition in React 18
      setResolution(null)
    }

    const removeUpdateListener = editor.registerUpdateListener(updateListener)

    return () => {
      activeRange = null
      removeUpdateListener()
    }
  }, [editor])

  const closeTypeahead = useCallback(() => {
    setResolution(null)
  }, [])

  const handleComplete = useCallback(() => {
    setInserted(true)
  }, [])

  return resolution === null || editor === null || inserted ? null : (
    <SuggestionsList
      close={closeTypeahead}
      onComplete={handleComplete}
      resolution={resolution}
      editor={editor}
      suggestions={suggestions}
    />
  )
}

export type Props = {
  suggestions?: Array<Partial<Suggestion>>
}

function ensureSafeSuggestions(suggestions: Array<Partial<Suggestion>>): Array<Suggestion> {
  return suggestions.filter((s): s is Suggestion => !!(s.value && s.type))
}

export default function TypeAheadPlugin({ suggestions = [] }: Props) {
  const [editor] = useLexicalComposerContext()
  const safeSuggestions = useMemo(() => ensureSafeSuggestions(suggestions), [suggestions])

  return useTypeAhead(editor, safeSuggestions)
}

import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionTypeEnum } from 'types/api'

export const BatchDeleteDialog: DialogComponent = (props) => {
  const { children, ...dialogProps } = props

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Delete}
      confirmEnabled
      title="Delete transactions"
      primaryText="Would you like to delete the transactions selected?"
      confirmText="Yes, delete transactions."
    >
      {children}
    </BaseBatchActionDialog>
  )
}

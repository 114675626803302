import { ReactElement, ReactNode } from 'react'

import { ArrowDropDown } from '@mui/icons-material'
import { TextField, TextFieldProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { useField } from 'formik'

import { get, includes, isEmpty } from 'lodash'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import { RenderValue, SelectInput, LabeledFieldProps, getErrorDetails, TextInputV2 } from 'components/material/Form'
import { Maybe } from 'types/api'

import { Theme } from 'types/hb'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputProps, InputOptionWithNumbers } from './InputTypes'
import { SelectReadOnly } from './SelectReadOnly'
import { useIsErroneous } from './useIsErroneous'

/**
 * Give `SelectDropdownInput` its own `renderValue` function
 * since it renders a placeholder. Other select inputs have labels
 * that are rendered where a placeholder would be.
 *
 * @param multiple
 * @param options
 * @param placeholder
 */
function renderValue(
  multiple: boolean,
  options: InputOptionWithNumbers[],
  placeholder?: InputOptionWithNumbers | null
) {
  return (selected: string | number | boolean | Array<Maybe<string> | Maybe<number> | Maybe<boolean>>) => {
    // if we allow multiple selections, `selected` may be an array
    if (Array.isArray(selected)) {
      if (isEmpty(selected)) {
        return placeholder?.display || ''
      }

      return options
        .filter(({ value }) => selected.includes(value))
        .map(({ display }) => display)
        .join(', ')
    }

    // otherwise we are only expecting a single selection
    if (!options || !selected || placeholder?.value === selected) {
      return placeholder?.display || ''
    }
    return options.filter(({ value }) => selected === value).map(({ display }) => display)
  }
}

const useStyles = makeStyles((theme: Theme) => ({
  redesignSelectRootClass: (hasAdornment) => ({
    wordWrap: 'break-word',
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
    textOverflow: 'ellipsis',
    ...(hasAdornment
      ? {
          '&&': {
            paddingRight: theme.spacing(4),
          },
        }
      : {}),
  }),
  redesignRoot: (hasAdornment) => ({
    margin: 0,
    ...(hasAdornment
      ? {
          // only reposition an svg if there is more than one.
          '& svg + svg': {
            position: 'absolute',
            right: theme.spacing(3.5),
          },
        }
      : {}),
  }),
}))
export interface SelectDropdownInputProps extends InputProps {
  multivalued?: boolean
  options?: InputOptionWithNumbers[] | null
  adornment?: ReactNode
  customComponent?: (option: InputOptionWithNumbers) => ReactElement
  renderValue?: RenderValue
  disablePlaceholderSelect?: boolean
  defaultOptionValue?: InputOptionWithNumbers['value'] | null
  disableOptions?: boolean
  variant?: TextFieldProps['variant']
  className?: string
}

export const SelectDropdownInput = (props: SelectDropdownInputProps) => {
  const {
    label,
    sublabel,
    name,
    disabled,
    readOnly,
    errors,
    variant = 'outlined',
    autosave,
    isErroneousChecker,
    multivalued,
    options,
    testId,
    className,
    inputContainerClassName,
    adornment,
    placeholder,
    disablePlaceholderSelect,
    customComponent,
    disableOptions,
    renderValue: renderValueProp,
  } = props

  const styles = useStyles(!!adornment)
  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })

  if (!options) {
    return null
  }

  if (readOnly) {
    return <SelectReadOnly {...props} value={field.value} />
  }

  const inputTestId = getInputTestId(label, testId)

  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      testId={testId}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={classNames(className, inputContainerClassName)}
      htmlFor={name}
      labelId={`label_${name}`}
    >
      <SelectInput
        field={
          multivalued
            ? {
                ...field,
                value: field.value || [],
              }
            : field
        }
        form={form}
        meta={meta}
        name={name}
        testId={inputTestId}
        classes={{ root: styles.redesignRoot }}
        InputProps={{
          error: isErroneous,
          endAdornment: adornment,
        }}
        SelectProps={{
          labelId: `label_${name}`,
          IconComponent: ArrowDropDown,
          classes: { root: styles.redesignSelectRootClass },
          'aria-describedby': getHelperTextId(name),
        }}
        CheckboxProps={{ color: 'primary' }}
        placeholder={placeholder || (multivalued ? 'Select any that apply' : 'Select one')}
        disablePlaceholderSelect={disablePlaceholderSelect}
        options={options}
        multiple={multivalued}
        fullWidth={false}
        margin="dense"
        size="small"
        disabled={disabled}
        disableOptions={disableOptions}
        variant={variant}
        renderValue={renderValueProp || renderValue}
        customComponent={customComponent}
      />
    </InputContainer>
  )
}

export const SelectDropdownInputWithOther = ({
  adornment,
  autosave,
  disabled,
  label,
  multivalued,
  name,
  otherFieldClasses,
  otherPlaceholder,
  otherHelperText,
  otherInputContainerClassName,
  otherLabel,
  otherName,
  otherVariant = 'outlined',
  placeholder,
  ...props
}: SelectDropdownInputProps & {
  otherFieldClasses?: LabeledFieldProps<typeof TextField>['classes']
  otherHelperText?: string
  otherInputContainerClassName?: string
  otherPlaceholder?: string
  otherLabel?: string
  otherName?: string
  otherVariant?: LabeledFieldProps<typeof TextField>['variant']
}) => {
  const form = useHbFormikContext({ autosave })
  const { values, handleChange, handleBlur, isSubmitting } = form

  const value = get(values, name)

  const otherSelection = 'OTHER'
  const isOtherSelected = multivalued ? includes(value || [], otherSelection) : value === otherSelection
  const otherNameWithDefault = otherName || `${name}Other`
  const otherValue = get(values, otherNameWithDefault)
  const otherLabelWithDefault = otherLabel || `${label} Other`
  const otherPlaceholderWithDefault = otherPlaceholder || placeholder ? `${placeholder} Other` : 'Enter other value'
  const { showError: showOtherError } = getErrorDetails({ name: otherNameWithDefault }, form)

  return (
    <>
      <SelectDropdownInput
        adornment={adornment}
        autosave={autosave}
        disabled={isSubmitting || disabled}
        label={label}
        multivalued={multivalued}
        name={name}
        placeholder={placeholder}
        {...props}
      />
      {isOtherSelected && (
        <TextInputV2
          adornment={adornment}
          classes={otherFieldClasses}
          disabled={isSubmitting || disabled}
          error={showOtherError}
          helperText={otherHelperText}
          inputContainerClassName={otherInputContainerClassName}
          isButton={false}
          label={otherLabelWithDefault}
          name={otherNameWithDefault}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder={otherPlaceholderWithDefault}
          required
          value={otherValue || ''}
          variant={otherVariant}
        />
      )}
    </>
  )
}

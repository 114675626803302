import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const InfoDocumentOutlinedIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M14 2l6 6v12c0 1.1-.9 2-2 2H5.99C4.89 22 4 21.1 4 20l.01-16c0-1.1.89-2 1.99-2h8zM6 20h12V9h-5V4H6v16zm5-10h2v2h-2v-2zm0 4h2v4h-2v-4z" />
  </SvgIcon>
)

export default InfoDocumentOutlinedIcon

type HexString = string
interface RGBA {
  r: number
  g: number
  b: number
  a: number
}
type UInt8 = number
type Opacity = number

const mul = (a: number, b: number) => Math.floor(a * b)

const parseHex = (hex: HexString) => parseInt(hex, 16)
const uint8ToHex = (uint8: UInt8) => `00${uint8.toString(16)}`.substr(-2).toUpperCase()

const createRgba = (color: HexString): RGBA => {
  if (!/^#[0-9A-F]{6,8}$/.test(color.toUpperCase())) {
    throw new Error('Only 6 or 8 character hex rbg values supported')
  }

  const red = parseHex(color.slice(1, 3))
  const green = parseHex(color.slice(3, 5))
  const blue = parseHex(color.slice(5, 7))
  const alpha = color.slice(7).length === 2 ? parseHex(color.slice(7)) : 255

  return { r: red, g: green, b: blue, a: alpha }
}

const rgbaToString = (color: RGBA) =>
  ['#', uint8ToHex(color.r), uint8ToHex(color.g), uint8ToHex(color.b), uint8ToHex(color.a)].join('')

export const opacify = (color: HexString, opacity: Opacity) => {
  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity must be provided as a value between 0 and 1 inclusive')
  }

  const rgb = createRgba(color)

  const opacityInt = mul(opacity, 255)

  return rgbaToString({ ...rgb, a: opacityInt })
}

// http://www.w3.org/TR/AERT#color-contrast
export const brightness = (color: HexString) => {
  const { r, g, b, a } = createRgba(color)
  // Here we multiply by alpha to get a rough
  // approximation of brightness. This isn't perfect
  // because it doesn't mix in the true background
  // color, effectively making this assume a white
  // background.
  return ((r * 299 + g * 587 + b * 114) / 1000) * (255 / a)
}

export const isDark = (color: HexString) => brightness(color) < 128

export const blend = (first: HexString, second: HexString, ratio: number = 0.5) => {
  const { r: fr, g: fg, b: fb, a: fa } = createRgba(first)
  const { r: sr, g: sg, b: sb, a: sa } = createRgba(second)

  if (fa !== 255 || sa !== 255) {
    throw new Error('Only opaque values supported')
  }

  const blendedRgba = {
    r: mul(fr, ratio) + mul(sr, 1 - ratio),
    g: mul(fg, ratio) + mul(sg, 1 - ratio),
    b: mul(fb, ratio) + mul(sb, 1 - ratio),
    a: 255,
  }

  return rgbaToString(blendedRgba)
}

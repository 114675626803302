import React, { ReactNode } from 'react'

import { ArrowBackIos } from '@mui/icons-material'
import { IconButton, styled, Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { HbText, TitleTag } from 'components/HbComponents/Text/HbText'
import { BUTTON_SHADOW } from 'components/themeRedesign'
import { mergeOverrideStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

import PageTitle, { PageTitleLabelOverrides } from '../PageTitle'

export interface Props {
  title: string
  hideTitleInHeader?: boolean
  icon?: ReactNode
  adornment?: ReactNode
  subtitle?: ReactNode
  onTitleChange?: (a: string) => void
  goBack?: () => void
  titleRow?: ReactNode
  children?: ReactNode
  absolute?: boolean
  variant?: 'normal' | 'inverted'
  titleTag?: TitleTag
  styleOverrides?: PageHeaderOverrides
  titleLabel?: string
  pageTitleLabelStyleOverrides?: PageTitleLabelOverrides
  className?: string
}

export type PageHeaderOverrides = Partial<ReturnType<typeof useStyles>>
const useStyles = makeStyles((theme: Theme) => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  absolute: {
    position: 'absolute',
    color: theme.palette.text.white,
    backgroundColor: theme.palette.background.dark,
    left: 0,
    right: 0,
    zIndex: 200,
    height: 70,
    justifyContent: 'center',
    '& $titleRow': {
      marginLeft: 0,
    },
    '& .MuiButton-contained': {
      boxShadow: 'none',
      '&:hover': {
        boxShadow: BUTTON_SHADOW,
      },
    },
  },
  title: {
    fontWeight: 700,
  },
  subtitle: {
    ...theme.typography.sizes.s,
    color: theme.palette.text.secondary,
  },
  toolrow: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing(1.5)} 0`,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  adornmentIcon: {
    marginLeft: theme.spacing(2),
  },
  titleRow: {},
}))

const TitleRow = styled('div', {
  shouldForwardProp: (prop) => prop !== 'hideTitleInHeader',
})<{ hideTitleInHeader?: boolean }>(({ theme, hideTitleInHeader }) => ({
  flex: 1,
  display: 'flex',
  justifyContent: 'flex-end',
  marginLeft: theme.spacing(hideTitleInHeader ? 1 : 4),
}))

export const PageHeader = React.forwardRef((props: Props, ref: React.Ref<HTMLDivElement> | undefined) => {
  const {
    title,
    hideTitleInHeader,
    icon,
    adornment,
    subtitle,
    goBack,
    onTitleChange,
    titleRow,
    children,
    absolute,
    variant,
    titleTag,
    styleOverrides,
    titleLabel,
    pageTitleLabelStyleOverrides,
    className: classNameProp,
  } = props
  const classes = useStyles({ hideTitleInHeader })
  const mergedClasses = mergeOverrideStyles(classes, styleOverrides)
  const className = classNames(
    mergedClasses.main,
    {
      [mergedClasses.absolute]: absolute,
    },
    classNameProp
  )

  return (
    <div className={className} ref={ref}>
      <div className={mergedClasses.toolrow}>
        {goBack && (
          <IconButton onClick={goBack} size="large">
            <ArrowBackIos />
          </IconButton>
        )}
        {icon && <div className={mergedClasses.icon}>{icon}</div>}
        <HbText size="lg" className={mergedClasses.title} block tag={titleTag}>
          {!hideTitleInHeader && (
            <PageTitle
              onChange={onTitleChange}
              value={title}
              variant={variant}
              label={titleLabel}
              styleOverrides={pageTitleLabelStyleOverrides}
            />
          )}
          {subtitle && <Typography className={mergedClasses.subtitle}>{subtitle}</Typography>}
        </HbText>
        {adornment && <div className={mergedClasses.adornmentIcon}>{adornment}</div>}
        <TitleRow className={styleOverrides?.titleRow} hideTitleInHeader={hideTitleInHeader}>
          {titleRow}
        </TitleRow>
      </div>
      {children}
    </div>
  )
})

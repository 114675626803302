import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const XmlFileIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path
      d="M6.008 2.014A1.995 1.995 0 0 0 4.021 4.01l-.01 15.978c0 1.099.889 1.997 1.987 1.997h11.994a2.003 2.003 0 0 0 1.997-1.997V8.005l-5.992-5.991Zm6.99 1.498 5.493 5.492H13zm-.711 9.108h.987l-1.56 5.797h-.988zm-1.863 1.186v1.077l-2.225.829v.047l2.225.832v1.073l-3.425-1.437v-.983Zm3.152 0 3.425 1.438v.983l-3.425 1.437v-1.073l2.227-.834v-.043l-2.227-.831z"
      fill="currentColor"
    />
  </SvgIcon>
)

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const BoltOutlinedIcon: FC<SvgProps> = (props) => (
  <SvgIcon fill="none" width={18} height={18} {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.295 17.25h1.35l.142-.17c.582-.694 2.954-3.525 7.133-8.488l.039-.046.111-.133.54-.641.095-.138c.121-.165.027-.467-.635-.467h-4.725L11.695.75h-1.35l-.142.17A8511.834 8511.834 0 0 1 6.51 5.33a4039.936 4039.936 0 0 0-3.415 4.078l-.06.071-.114.138-.419.501c-.004.006-.003.005 0 0a21.68 21.68 0 0 0-.095.11c-.256.312-.27.605.514.605h4.725l-1.35 6.417ZM4.951 9.408h4.45L8.41 14.12l.131-.157c1.072-1.276 2.576-3.067 4.516-5.371H8.589l.99-4.708L7.6 6.248 4.95 9.408Z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default BoltOutlinedIcon

import React, { AriaRole, CSSProperties, ReactNode, forwardRef } from 'react'

import { Interpolation } from '@emotion/react'
import { ExpandMore } from '@mui/icons-material'
import { Button, buttonClasses, ButtonProps as BaseButtonProps } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import VisuallyHidden from '@reach/visually-hidden'

import classNames from 'classnames'

import { Link } from 'react-router-dom'

import { Placement, HbTooltip } from 'components/HbComponents/HbTooltip'
import { Theme } from 'types/hb'

import { ButtonCircularProgress } from '../library/Primitives/ButtonCircularProgress'

const ICON_SIZE_MD = 20
const ICON_SIZE_LG = 22
const MAX_ICON_COUNT_VALUE = 99

const getSizeProps = (sizePx: number) => ({
  width: sizePx,
  height: sizePx,
})

const getIconCountString = (iconCount: number) =>
  iconCount > MAX_ICON_COUNT_VALUE ? `+${MAX_ICON_COUNT_VALUE}` : iconCount

// HbButtonTypes correspond to button designs in Figma
export const HbButtonVariantOptions = [
  'primary',
  'secondary',
  'supplementary',
  'textPrimary',
  'textSecondary',
  'warning',
  'confirmation',
] as const
export type HbButtonVariants = (typeof HbButtonVariantOptions)[number]
export const HbButtonSizeOptions = ['small', 'medium'] as const
export type HbButtonSizes = (typeof HbButtonSizeOptions)[number]
type MuiVariantTypes = 'text' | 'contained' | 'outlined'
type ColorTypes = 'primary' | 'secondary' | 'inherit'

type HbCustomButtonProps = 'variant' | 'fullWidth' | 'loading'

// Props used for styling
type StyleProps = {
  loading?: boolean
  iconSizePx?: number
  iconOnly?: boolean
  iconCount?: number
  iconPlacement?: 'left' | 'right' | 'center'
  variant?: HbButtonVariants
} & Pick<BaseButtonProps, 'fullWidth'> & { fitContent?: boolean }

type ExternalLinkProps = {
  href?: string
  target?: string
  to?: never
}

type InteralLinkProps = {
  href?: never
  target?: never
  to: string
}

export type Props = Omit<BaseButtonProps, HbCustomButtonProps | 'classes'> &
  StyleProps & {
    role?: AriaRole
    'aria-label'?: string
    'aria-expanded'?: boolean
    'aria-controls'?: string
    'aria-current'?: boolean
    'aria-pressed'?: boolean
    'aria-selected'?: boolean
    label: string | ReactNode
    testId?: string
    className?: string
    css?: Interpolation<Theme>
    disabled?: boolean
    autoFocus?: boolean
    type?: 'submit' | 'reset' | 'button'
    // Icon placed before the label (or based on iconPlacement)
    Icon?: React.ComponentType<{ className?: string }>
    iconCss?: Interpolation<Theme>
    id?: string
    // If true, a dropdown caret is displayed after the label
    dropdownCaret?: boolean
    size?: HbButtonSizes
    style?: CSSProperties | undefined
    tooltip?: boolean
    tooltipText?: string
    tooltipPlacement?: Placement
  } & (InteralLinkProps | ExternalLinkProps)

const getSvgDimensions = (defaultSizePx: number, iconSizePx?: number) => {
  const sizePx = iconSizePx !== undefined ? iconSizePx : defaultSizePx
  return getSizeProps(sizePx)
}

const getBackgroundColors = (theme: Theme, variant?: HbButtonVariants) => {
  let backgroundColor
  let backgroundColorDark
  switch (variant) {
    case 'warning':
      backgroundColor = theme.palette.styleguide.errorMedium
      backgroundColorDark = theme.palette.styleguide.errorDark
      break
    case 'confirmation':
      backgroundColor = theme.palette.styleguide.successMedium
      backgroundColorDark = theme.palette.styleguide.successDark
      break
    default:
      break
  }
  return {
    backgroundColor,
    backgroundColorDark,
  }
}

const getMuiVariantAndColor = (variant?: HbButtonVariants): { color: ColorTypes; variant: MuiVariantTypes } => {
  if (variant === 'primary') {
    return {
      color: 'primary',
      variant: 'contained',
    }
  }
  if (variant === 'secondary') {
    return {
      color: 'primary',
      variant: 'outlined',
    }
  }
  if (variant === 'supplementary') {
    return {
      color: 'secondary',
      variant: 'contained',
    }
  }
  if (variant === 'textPrimary') {
    return {
      color: 'primary',
      variant: 'text',
    }
  }
  if (variant === 'textSecondary') {
    return {
      color: 'secondary',
      variant: 'text',
    }
  }
  return {
    color: 'primary',
    variant: 'contained',
  }
}

const useCss = ({
  iconCount,
  iconOnly,
  iconSizePx,
  iconPlacement,
  variant,
  fullWidth,
  loading,
  fitContent,
}: StyleProps) => {
  const theme = useTheme()
  const iconOnlyStyles = iconOnly
    ? {
        minWidth: 0,
        minHeight: 0,
        padding: theme.spacing(1),
        height: theme.spacing(5),
        width: theme.spacing(5),
        '& svg': getSvgDimensions(theme.hbUnit(2.5), iconSizePx),
        [`&.${buttonClasses.sizeSmall}`]: {
          padding: theme.spacing(0.95),
          height: theme.spacing(4),
          width: theme.spacing(4),
          '& svg': getSvgDimensions(theme.hbUnit(2), iconSizePx),
        },
      }
    : {}
  const { backgroundColor, backgroundColorDark } = getBackgroundColors(theme, variant)
  return {
    root: {
      ...iconOnlyStyles,
      backgroundColor,
      borderColor: backgroundColor,
      '&:hover, &[aria-current="true"]': {
        backgroundColor: backgroundColorDark,
        borderColor: backgroundColorDark,
      },
      width: fullWidth ? '100%' : fitContent ? 'fit-content' : undefined,
      [`&.${buttonClasses.disabled}`]: {
        color: loading ? 'transparent' : undefined,
      },
    },
    icon: {
      ...getSizeProps(iconCount ? ICON_SIZE_LG : ICON_SIZE_MD),
      margin:
        iconPlacement === 'left'
          ? theme.spacing(-0.5, 1, -0.5, -0.5)
          : iconPlacement === 'right'
          ? theme.spacing(-0.5, -0.5, -0.5, 1)
          : undefined,
    },
    iconCount: {
      display: 'flex',
      flexShrink: 0,
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '50%',
      backgroundColor: theme.palette.background.paper,
      color: theme.palette.text.primary,
      fontSize: iconCount && iconCount > MAX_ICON_COUNT_VALUE ? 10 : 12,
    },
    caret: {
      ...getSizeProps(ICON_SIZE_MD),
      margin: theme.spacing(-0.5, 0, -0.5, 1),
    },
    rotate: {
      // when the button is being used as a dropdown, the caret is rotated 180 degrees
      transform: 'rotate(180deg)',
    },
  }
}

export const HbButton = forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const {
    role,
    'aria-label': ariaLabel,
    'aria-expanded': ariaExpanded,
    'aria-controls': ariaControls,
    'aria-current': ariaCurrent,
    'aria-pressed': ariaPressed,
    'aria-selected': ariaSelected,
    label,
    testId,
    onClick,
    className,
    css: cssOverride,
    disabled,
    autoFocus,
    dropdownCaret = false,
    Icon = null,
    iconPlacement: iconPlacementProp = 'left',
    size = 'medium',
    fullWidth = false,
    fitContent = false,
    type = 'button',
    href,
    target,
    loading,
    iconOnly,
    iconSizePx,
    iconCount,
    iconCss,
    id,
    style,
    tooltip = false,
    tooltipText,
    tooltipPlacement = Placement.Right,
    variant: propVariant,
    to,
    ...restProps
  } = props
  const iconPlacement = iconOnly ? 'center' : iconPlacementProp

  const variant = propVariant || (iconOnly ? 'textSecondary' : 'primary')

  const muiVariantAndColor = getMuiVariantAndColor(variant)

  const linkProps = href
    ? {
        href,
        rel: target === '_blank' ? 'noopener noreferrer' : undefined,
        target,
      }
    : {}

  const css = useCss({
    iconCount,
    iconOnly,
    iconSizePx,
    iconPlacement,
    variant,
    fullWidth,
    fitContent,
    loading,
  })

  const iconElement = iconCount ? (
    <div css={[css.icon, css.iconCount]}>{getIconCountString(iconCount)}</div>
  ) : Icon ? (
    <Icon css={[css.icon, iconCss]} />
  ) : undefined

  const button = (
    <Button
      css={[css.root, cssOverride]}
      role={role}
      aria-label={ariaLabel}
      aria-expanded={ariaExpanded}
      aria-controls={ariaControls}
      aria-current={ariaCurrent}
      aria-pressed={ariaPressed}
      aria-selected={ariaSelected}
      ref={ref}
      type={type}
      disabled={disabled || loading}
      autoFocus={autoFocus}
      size={size}
      style={style}
      data-testid={`button_${testId ?? label}`}
      onClick={onClick}
      className={classNames(className, {
        // This prevents google translate from attempting to translate the button.
        // Do not remove unless you have a fix for https://github.com/facebook/react/issues/11538.
        notranslate: !!iconElement,
      })}
      id={id}
      {...linkProps}
      {...muiVariantAndColor}
      {...restProps}
    >
      {iconPlacement === 'left' ? iconElement : undefined}
      {iconOnly ? <VisuallyHidden>{label}</VisuallyHidden> : label}
      {iconPlacement === 'right' ? iconElement : undefined}
      {iconOnly && iconElement}
      {dropdownCaret ? <ExpandMore css={[css.caret, ariaExpanded && css.rotate]} /> : undefined}
      {loading && <ButtonCircularProgress />}
    </Button>
  )

  const maybeWrappedButton = tooltip ? (
    <HbTooltip title={tooltipText || label} placement={tooltipPlacement}>
      {button}
    </HbTooltip>
  ) : (
    button
  )

  return to ? <Link to={to}>{maybeWrappedButton}</Link> : maybeWrappedButton
})

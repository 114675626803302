import { useEffect, useState, useContext, useRef } from 'react'

import { computePosition } from '@floating-ui/dom'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { createDOMRange, createRectsFromDOMRange } from '@lexical/selection'
import { mergeRegister, registerNestedElementResolver } from '@lexical/utils'

import {
  $getNodeByKey,
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_EDITOR,
  createCommand,
} from 'lexical'

import { PointType } from 'lexical/LexicalSelection'

import { CommentContext } from './CommentContext'
import {
  $createCommentMarkNode,
  $getCommentMarkIDs,
  $isCommentMarkNode,
  wrapTargetWithCommentMarkNode,
  CommentMarkTarget,
  CommentMarkNode,
  $unwrapCommentMarkNode,
} from './CommentMarkNode'

import type { NodeKey, LexicalCommand } from 'lexical'

export const START_NEW_COMMENT_THREAD_EDITOR_COMMAND: LexicalCommand<void> = createCommand()
export const COMMIT_COMMENT_THREAD_EDITOR_COMMAND: LexicalCommand<string> = createCommand()
export const CANCEL_COMMENT_THREAD_EDITOR_COMMAND: LexicalCommand<void> = createCommand()
export const REMOVE_COMMENT_MARK_EDITOR_COMMAND: LexicalCommand<string> = createCommand()

export type Props = {
  onComment: (commentThreadToken: string) => unknown
}

export default function CommentPlugin({ onComment: reportCommentThreadCreated }: Props) {
  const [editor] = useLexicalComposerContext()
  const [commentMarkNodeMap] = useState<Map<string, Set<NodeKey>>>(() => {
    return new Map()
  })

  const [activeTarget, setActiveTarget] = useState<CommentMarkTarget | null>(null)
  // Don't know if the following states are being used for anything
  // TODO: figure out if these states are actually being used and remove if not
  // https://thecharm.atlassian.net/browse/PROD-8458
  const [, setActiveAnchorKey] = useState<NodeKey | null>()
  const [, setShowCommentInput] = useState(false)
  const [, setCommentingAnchor] = useState<PointType | null>(null)
  const [commentingInputRects, setCommentingInputRects] = useState<Array<ClientRect> | null>(null)

  const { setActiveCommentThreadTokens, activeCommentThreadTokens, openCommentPane, commentPaneOpen } =
    useContext(CommentContext) || {}
  const lastSelectionChangeFromMouseRef = useRef(false)
  const lastSelectionChangeFromLocalEventRef = useRef(false)

  const activeCommentThreadTokensRef = useRef(activeCommentThreadTokens)
  activeCommentThreadTokensRef.current = activeCommentThreadTokens

  const commmentPaneOpenRef = useRef(commentPaneOpen)
  commmentPaneOpenRef.current = commentPaneOpen

  useEffect(() => {
    if ((activeCommentThreadTokens?.length ?? 0) > 0) {
      openCommentPane?.(editor)
    }

    let commentMarkElement: HTMLElement | null = null

    editor.update(() => {
      const firstThreadToken = activeCommentThreadTokens?.[0]

      const markNodeKeys = firstThreadToken ? commentMarkNodeMap.get(firstThreadToken) : null
      const firstMarkNodeKey = markNodeKeys ? Array.from(markNodeKeys)[0] : null

      commentMarkElement = firstMarkNodeKey ? editor.getElementByKey(firstMarkNodeKey) : null
      commentMarkElement?.classList.add('active')

      const commentMarkNode = firstMarkNodeKey ? $getNodeByKey(firstMarkNodeKey) : null

      if ($isCommentMarkNode(commentMarkNode) && !lastSelectionChangeFromLocalEventRef.current) {
        commentMarkNode.selectStart()
      }

      lastSelectionChangeFromLocalEventRef.current = false
    })

    return () => {
      commentMarkElement?.classList.remove('active')
    }
  }, [activeCommentThreadTokens, commentMarkNodeMap, editor, openCommentPane])

  useEffect(() => {
    const handleMouseUp = () => {
      lastSelectionChangeFromMouseRef.current = true
      lastSelectionChangeFromLocalEventRef.current = true
    }

    const handleKeyUp = () => {
      lastSelectionChangeFromMouseRef.current = false
      lastSelectionChangeFromLocalEventRef.current = true
    }

    document.addEventListener('mouseup', handleMouseUp, true)
    document.addEventListener('keyup', handleKeyUp, true)

    return () => {
      document.removeEventListener('mouseup', handleMouseUp, true)
      document.removeEventListener('keyup', handleKeyUp, true)
    }
  }, [])

  useEffect(() => {
    const markNodeKeysToIDs: Map<NodeKey, Array<string>> = new Map()

    return mergeRegister(
      registerNestedElementResolver<CommentMarkNode>(
        editor,
        CommentMarkNode,
        (from: CommentMarkNode) => {
          return $createCommentMarkNode(from.getIDs())
        },
        (from: CommentMarkNode, to: CommentMarkNode) => {
          // Merge the IDs
          const ids = from.getIDs()
          ids.forEach((id) => {
            to.addID(id)
          })
        }
      ),
      editor.registerMutationListener(CommentMarkNode, (mutations) => {
        editor.getEditorState().read(() => {
          for (const [key, mutation] of mutations) {
            const node: null | CommentMarkNode = $getNodeByKey(key)
            let ids: NodeKey[] = []

            if (mutation === 'destroyed') {
              ids = markNodeKeysToIDs.get(key) || []
            } else if ($isCommentMarkNode(node)) {
              ids = node.getIDs()
            }

            for (let i = 0; i < ids.length; i += 1) {
              const id = ids[i]
              let markNodeKeys = commentMarkNodeMap.get(id)
              markNodeKeysToIDs.set(key, ids)

              if (mutation === 'destroyed') {
                if (markNodeKeys !== undefined) {
                  markNodeKeys.delete(key)
                  if (markNodeKeys.size === 0) {
                    commentMarkNodeMap.delete(id)
                  }
                }
              } else {
                if (markNodeKeys === undefined) {
                  markNodeKeys = new Set()
                  commentMarkNodeMap.set(id, markNodeKeys)
                }
                if (!markNodeKeys.has(key)) {
                  markNodeKeys.add(key)
                }
              }
            }
          }
        })
      }),
      editor.registerUpdateListener(({ editorState, tags }) => {
        editorState.read(() => {
          const selection = $getSelection()
          let hasActiveIds = false
          let hasAnchorKey = false

          if (!lastSelectionChangeFromMouseRef.current && !commmentPaneOpenRef.current) {
            return
          }

          if ($isRangeSelection(selection)) {
            const anchorNode = selection.anchor.getNode()

            if ($isTextNode(anchorNode)) {
              const commentThreadTokens = $getCommentMarkIDs(anchorNode, selection.anchor.offset)
              if (commentThreadTokens !== null) {
                setActiveCommentThreadTokens?.(commentThreadTokens)
                hasActiveIds = true
              }
              if (!selection.isCollapsed()) {
                setActiveAnchorKey(anchorNode.getKey())
                hasAnchorKey = true
              }
            }
          }
          if (!hasActiveIds) {
            setActiveCommentThreadTokens?.((_activeTokens) => (_activeTokens.length === 0 ? _activeTokens : []))
          }
          if (!hasAnchorKey) {
            setActiveAnchorKey(null)
          }
        })
        if (!tags.has('collaboration')) {
          setShowCommentInput(false)
        }
      }),
      editor.registerCommand(
        START_NEW_COMMENT_THREAD_EDITOR_COMMAND,
        () => {
          editor.getEditorState().read(() => {
            const selection = $getSelection()
            if ($isRangeSelection(selection)) {
              const { anchor, focus } = selection
              setActiveTarget({
                nodes: selection.getNodes(),
                anchorOffset: anchor.offset,
                focusOffset: focus.offset,
                isBackward: selection.isBackward(),
              })
              const range = createDOMRange(editor, anchor.getNode(), anchor.offset, focus.getNode(), focus.offset)
              if (range) {
                const selectionRects = createRectsFromDOMRange(editor, range)
                setCommentingInputRects(selectionRects)
                setCommentingAnchor(anchor)
                openCommentPane?.(editor, selection.getTextContent(), true)

                const domSelection = window.getSelection()
                if (domSelection !== null) {
                  domSelection.removeAllRanges()
                }
              }
            }
          })

          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        COMMIT_COMMENT_THREAD_EDITOR_COMMAND,
        (threadToken) => {
          if (activeTarget) {
            wrapTargetWithCommentMarkNode(activeTarget, threadToken)
          }

          setCommentingAnchor(null)
          setCommentingInputRects(null)
          setActiveTarget(null)

          reportCommentThreadCreated(threadToken)

          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        CANCEL_COMMENT_THREAD_EDITOR_COMMAND,
        () => {
          setCommentingAnchor(null)
          setCommentingInputRects(null)

          return true
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_COMMENT_MARK_EDITOR_COMMAND,
        (token) => {
          const keySet = commentMarkNodeMap.get(token)

          if (!keySet) {
            return false
          }

          const nodeKeys = Array.from(keySet)

          editor.update(() => {
            for (const key of nodeKeys) {
              const node: null | CommentMarkNode = $getNodeByKey(key)
              if ($isCommentMarkNode(node)) {
                node.deleteID(token)
                if (node.getIDs().length === 0) {
                  $unwrapCommentMarkNode(node)
                }
              }
            }
          })

          return true
        },
        COMMAND_PRIORITY_EDITOR
      )
    )
  }, [
    editor,
    commentMarkNodeMap,
    activeTarget,
    setActiveCommentThreadTokens,
    openCommentPane,
    reportCommentThreadCreated,
  ])

  return commentingInputRects ? (
    <>
      {commentingInputRects.map((r, i) => (
        <span
          key={i}
          style={{ position: 'absolute', backgroundColor: 'rgba(193, 33, 149, 0.3)', width: r.width, height: r.height }}
          ref={async (el) => {
            if (!(el instanceof HTMLElement)) {
              return
            }

            const pos = await computePosition(
              {
                getBoundingClientRect: () => r,
              },
              el,
              {
                placement: 'top-start',
              }
            )

            Object.assign(el.style, {
              top: `${pos.y + r.height}px`,
              left: `${pos.x}px`,
            })
          }}
        />
      ))}
    </>
  ) : null
}

import { BatchActionSelectUIState } from 'dashboards/shared/react/dashboards.config'

export const getBatchActionSelectUIState = (
  allVisibleSelected: boolean,
  someSelected: boolean
): BatchActionSelectUIState => {
  if (allVisibleSelected) {
    return 'all-selected'
  }
  if (someSelected) {
    return 'some-selected'
  }
  return 'none-selected'
}

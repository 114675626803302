import moment from 'moment'

import { setFlashError } from 'actions/errorActions'
import { loadInvestigation } from 'actions/investigationsActions'
import { AsyncThunk, Thunk } from 'actions/store'
import { addPendingOperation, removePendingOperation, setLoading } from 'actions/viewActions'
import { Page } from 'components/cases/Tabs/Review/types'
import { getOrganizationToken, getReviewByToken, getReviewWorkflowTask } from 'helpers/stateHelpers'

import { Action, ActionSlug, Review, Task, TaskSlug, Token, Workflow } from 'reducers/reviewsReducer'
import { BasicAccount, KeyboardOrMouseEvent } from 'types/hb'
import { navigateToInternalUrl } from 'utils/navigationHelpers'
import { serializeQuery } from 'utils/query.serializer'

export const UPDATE_REVIEW = 'UPDATE_REVIEW'
export const SET_REVIEW_WORKFLOW = 'SET_REVIEW_WORKFLOW'
export const SET_REVIEW_WORKFLOW_TASK = 'SET_REVIEW_WORKFLOW_TASK'
export const SET_REVIEW_WORKFLOW_TASK_MERGE = 'SET_REVIEW_WORKFLOW_TASK_MERGE'
export const SET_REVIEW_WORKFLOW_ACTION = 'SET_REVIEW_WORKFLOW_ACTION'
export const RECORD_REVIEW_NOT_FOUND = 'RECORD_REVIEW_NOT_FOUND'

export function updateReview(token: Token, updates: Partial<Review>) {
  return { type: UPDATE_REVIEW, token, updates }
}

export function setReviewWorkflow(token: Token, workflow: Workflow) {
  return { type: SET_REVIEW_WORKFLOW, token, workflow }
}

export function setReviewWorkflowTask(token: Token, task: Task) {
  return { type: SET_REVIEW_WORKFLOW_TASK, token, task }
}

export function setReviewWorkflowAction(token: Token, action: Action) {
  return { type: SET_REVIEW_WORKFLOW_ACTION, token, action }
}

export function recordReviewNotFound(token: Token) {
  return { type: RECORD_REVIEW_NOT_FOUND, token }
}

export function navigateToWorkflowOverview({
  event,
  reviewToken,
}: {
  event?: KeyboardOrMouseEvent
  reviewToken: string
}): Thunk<void> {
  return (dispatch) => {
    dispatch(
      navigateToInternalUrl(event, '/dashboard/reviews/:token/overview', {
        token: reviewToken,
      })
    )
  }
}

export function navigateToCaseWithReview({
  event,
  reviewToken,
  caseToken,
}: {
  event?: KeyboardOrMouseEvent
  reviewToken: string
  caseToken: string
}): Thunk<void> {
  return (dispatch, _getState) => {
    const query = serializeQuery('case')({
      tabInfo: {
        tabs: [
          {
            type: 'review',
            reviewToken,
            pinned: true,
          },
        ],
      },
    })
    dispatch(navigateToInternalUrl(event, `/dashboard/cases/:caseToken?${query}`, { caseToken }))
  }
}

export function navigateToWorkflowTask(review: Pick<Review, 'token'>, taskSlug: TaskSlug): Thunk<void> {
  return (dispatch, getState, { pilot }) =>
    dispatch(
      pilot.go('/dashboard/reviews/:token/tasks/:slug', {
        token: review.token,
        slug: taskSlug,
      })
    )
}

export function navigateToWorkflowAction(review: Pick<Review, 'token'>, actionSlug: ActionSlug): Thunk<void> {
  return (dispatch, getState, { pilot }) =>
    dispatch(
      pilot.go('/dashboard/reviews/:token/actions/:slug', {
        token: review.token,
        slug: actionSlug,
      })
    )
}

export function navigateToWorkflowPage(reviewToken: string, page: Page): Thunk<void> {
  return (dispatch) => {
    if (page.type === 'action') {
      dispatch(navigateToWorkflowAction({ token: reviewToken }, page.slug))
    } else {
      dispatch(navigateToWorkflowTask({ token: reviewToken }, page.slug))
    }
  }
}

export function startReview(
  caseToken: string,
  params: Record<string, unknown>,
  loadingToken = 'case'
): AsyncThunk<Review | null> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, loadingToken))
    const json = await api.post('apiInvestigationReviewsPath', {
      data: { review: params },
      urlParams: {
        investigationToken: caseToken,
      },
    })
    dispatch(setLoading(false, loadingToken))
    if (json.success) {
      const query = serializeQuery('case')({
        tabInfo: {
          tabs: [
            {
              type: 'review',
              reviewToken: json.review.token,
              pinned: true,
            },
          ],
        },
      })
      dispatch(navigateToInternalUrl(undefined, `/dashboard/cases/:caseToken?${query}`, { caseToken }))
      dispatch(navigateToWorkflowOverview({ reviewToken: json.review.token }))
    }

    if (json.error && json.error.message) {
      dispatch(setFlashError(json.error.message))
    }

    return null
  }
}

export const getCaseReviewLoadOp = (token: Token) => `caseReview::${token}`

export function fetchReview(token: Token, loadingToken?: string): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    if (loadingToken) {
      dispatch(setLoading(true, loadingToken))
    }
    const json = await api.get('apiOrganizationReviewPath', {
      urlParams: {
        organizationToken: getOrganizationToken(getState()),
        token,
      },
    })
    if (json.success) {
      dispatch(updateReview(token, json.review))
    } else {
      dispatch(setFlashError('Sorry, we could not find that review.'))
      dispatch(recordReviewNotFound(token))
    }
    if (loadingToken) {
      dispatch(setLoading(false, loadingToken))
    }
  }
}

export const REVIEW_WORKFLOW_LOAD_OP = 'reviewWorkflow'
export const getReviewWorkflowLoadOp = (token: Token) => `${REVIEW_WORKFLOW_LOAD_OP}::${token}`

export function fetchReviewWorkflow(token: Token, runValidations: boolean): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    const loadOp = getReviewWorkflowLoadOp(token)
    dispatch(addPendingOperation(loadOp))
    const json = await api.get('apiReviewWorkflowPath', {
      urlParams: { reviewToken: token },
      query: { runValidations: runValidations.toString() },
    })
    if (json.success) {
      dispatch(setReviewWorkflow(token, json.workflow))
    } else {
      // TODO(jr): Need to do something more forceful.
      dispatch(setFlashError('Sorry, we had trouble preparing reports.'))
    }
    dispatch(removePendingOperation(loadOp))
  }
}

export function fetchReviewWorkflowTask(reviewToken: Token, taskToken: Token): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    const loadOp = getReviewWorkflowLoadOp(reviewToken)
    dispatch(addPendingOperation(loadOp))
    const json = await api.get('apiReviewWorkflowTaskPath', {
      urlParams: { reviewToken, token: taskToken },
    })
    if (json.success) {
      dispatch(setReviewWorkflowTask(reviewToken, json.task))
    } else {
      dispatch(setFlashError('Sorry, we had trouble fetching details, please refresh.'))
    }
    dispatch(removePendingOperation(loadOp))
  }
}

export const SAVE_REVIEW_WORKFLOW_ACTION_LOAD_OP = 'saveReviewWorkflowAction'
export const getSaveReviewWorkflowActionLoadOp = (token: Token) => `${SAVE_REVIEW_WORKFLOW_ACTION_LOAD_OP}::${token}`

export function saveReviewWorkflowAction(
  reviewToken: Token,
  actionToken: Token,
  result: Record<string, unknown>
): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    const loadOp = getSaveReviewWorkflowActionLoadOp(reviewToken)
    dispatch(setLoading(true, loadOp))

    const json = await api.put('apiReviewWorkflowActionPath', {
      urlParams: { reviewToken, token: actionToken },
      data: result,
    })

    if (json.success) {
      const review = getReviewByToken(getState(), reviewToken)

      await dispatch(fetchReview(reviewToken))
      await dispatch(loadInvestigation({ token: review.investigationToken }))
      dispatch(setReviewWorkflowAction(reviewToken, json.action))
    }

    if (json.error) {
      dispatch(setFlashError(json.error.message))
    }

    dispatch(setLoading(false, loadOp))

    return null
  }
}

const PENDING: any = {}
const QUEUED: any = {}
function serializeAndDropStale(key: string, thunk: Thunk<any>): AsyncThunk<any> {
  return async (dispatch) => {
    QUEUED[key] = thunk

    // Wait for anything running to finish
    if (PENDING[key]) {
      await PENDING[key]
    }

    // One waiter will handle the latest queued
    // thunk for the key
    const queuedThunk = QUEUED[key]
    if (queuedThunk) {
      delete QUEUED[key]

      PENDING[key] = dispatch(queuedThunk)
    }

    // All waiters wait for the currently running
    // thunk to finish
    const result = await PENDING[key]
    delete PENDING[key]
    return result
  }
}

export const UPDATE_REVIEW_WORKFLOW_TASK_LOAD_OP = 'updateReviewWorkflowTask'
export const getUpdateReviewWorkflowTaskLoadOp = (token: Token) => `${UPDATE_REVIEW_WORKFLOW_TASK_LOAD_OP}::${token}`

export function saveReviewWorkflowTask<T>({
  reviewToken,
  taskToken,
  state,
  optimisticClientSideStateTransform,
  action,
  onSuccess,
  mergeState,
}: {
  reviewToken: Token
  taskToken: Token
  state: T
  optimisticClientSideStateTransform?: (state: T) => Partial<Task['state']>
  action?: { type: 'edit' } | { type: 'comment'; commentThreadId: string }
  onSuccess?: () => void
  mergeState?: boolean // shallow merges new and existing task states
}): AsyncThunk<void> {
  const key = [UPDATE_REVIEW_WORKFLOW_TASK_LOAD_OP, reviewToken, taskToken].join('::')
  return serializeAndDropStale(key, async (dispatch, getState, { api, usage }) => {
    const loadOp = getUpdateReviewWorkflowTaskLoadOp(reviewToken)
    dispatch(setLoading(true, loadOp))

    // This pulls the latest version number from the redux store and increments
    // it to indicate to the server the ner version. If the version is not correct
    // the client will get a 400 series error.

    const task = getReviewWorkflowTask(getState(), reviewToken, taskToken)

    if (task) {
      // only optimistically update if a client side transform is explicitly defined. If not, wait for backend update.
      if (optimisticClientSideStateTransform) {
        const newTask = {
          ...task,
          version: task.version + 1,
          state: optimisticClientSideStateTransform(state),
        }
        dispatch(setReviewWorkflowTask(reviewToken, newTask))
      }
      const json = await api.put('apiReviewWorkflowTaskPath', {
        urlParams: { reviewToken, token: taskToken },
        data: { version: task.version + 1, state: mergeState ? { ...task.state, ...state } : state },
      })

      if (json.success) {
        // TODO(ak): we shouldn't need to refresh the review, but decisions
        // take the dirty client state and clobber the server state
        await dispatch(fetchReview(reviewToken))
        dispatch(setReviewWorkflowTask(reviewToken, json.task))
        if (onSuccess) {
          onSuccess()
        }
      }

      if (json.error) {
        if (
          json.error.type === 'INVALID' &&
          json.error.message === 'Your client is out of sync and cannot make changes. Please refresh and try again.'
        ) {
          usage.logEvent({
            name: 'review:clientOutOfSync:failure',
            data: {
              slug: task.meta.slug,
              action: action?.type ?? 'unknown',
              commentThreadId: action && 'commentThreadId' in action ? action.commentThreadId : 'unknown',
            },
          })
        }

        dispatch(setFlashError(json.error.message))
        // reset to original task - we updated optimistically but it did not succeed.
        if (optimisticClientSideStateTransform) {
          dispatch(setReviewWorkflowTask(reviewToken, task))
        }
      }

      dispatch(setLoading(false, loadOp))

      return json
    }

    return null
  })
}

export function completeReview(review: Review): AsyncThunk<void> {
  return async (dispatch, getState, { api, usage }) => {
    const loadOp = getReviewWorkflowLoadOp(review.token)
    dispatch(addPendingOperation(loadOp))
    dispatch(
      updateReview(review.token, {
        isCompleted: true,
        completedAt: moment().toISOString(),
      })
    )
    const json = await api.post('completeApiReviewPath', {
      urlParams: {
        token: review.token,
      },
    })
    dispatch(removePendingOperation(loadOp))
    if (json.success) {
      dispatch(updateReview(review.token, json.review))
      usage.trackChurnZeroEvent('ReviewCompleted')
      return json.review
    }

    if (json.error) {
      dispatch(
        updateReview(review.token, {
          isCompleted: false,
          completedAt: null,
        })
      )
      dispatch(setFlashError(json.error.message))
    }
    return null
  }
}

export function uncompleteReview(review: Review): AsyncThunk<void> {
  return async (dispatch, getState, { api, usage }) => {
    const loadOp = getReviewWorkflowLoadOp(review.token)
    dispatch(addPendingOperation(loadOp))
    dispatch(updateReview(review.token, { isCompleted: false, completedAt: null }))
    const json = await api.post('uncompleteApiReviewPath', {
      urlParams: {
        token: review.token,
      },
    })
    dispatch(removePendingOperation(loadOp))
    if (json.success) {
      usage.trackChurnZeroEvent('ReviewUncompleted')
      dispatch(updateReview(review.token, json.review))
      return json.review
    }

    if (json.error) {
      dispatch(
        updateReview(review.token, {
          isCompleted: true,
          completedAt: moment().toISOString(),
        })
      )
      dispatch(setFlashError(json.error.message))
    }
    return null
  }
}

export function markReviewReadyForReview(review: Review): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'readyForReview'))
    const json = await api.post('readyForReviewApiReviewPath', {
      urlParams: {
        token: review.token,
      },
    })
    dispatch(setLoading(false, 'readyForReview'))
    if (json.success) {
      dispatch(updateReview(review.token, json.review))
      return json.review
    }

    if (json.error) {
      dispatch(setFlashError(json.error.message))
    }
    return null
  }
}

export const LOADING_ASSIGNMENT_TOKEN = 'assignment'

export function assignReview(reviewToken: string, account: BasicAccount): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    const data = {
      accountToken: account.token,
    }
    dispatch(setLoading(true, LOADING_ASSIGNMENT_TOKEN))
    return api.debounce(reviewToken, async (resolve) => {
      const json = await api.put('apiReviewAssignmentPath', {
        urlParams: { reviewToken },
        data,
      })
      dispatch(setLoading(false, LOADING_ASSIGNMENT_TOKEN))
      if (json.success) {
        dispatch(updateReview(json.review.token, json.review))
        return resolve(json.review)
      }

      if (json.error) {
        dispatch(setFlashError(json.error.message))
      }
      return resolve(null)
    })
  }
}

export enum AssignmentStrategy {
  Assignee = 'assignee',
  Queue = 'queue',
}

export const assignReviewToQueueOrAssignee = (
  reviewToken: string,
  { accountToken, queueToken }: { accountToken?: string; queueToken?: string }
): AsyncThunk<void> => {
  return (dispatch, _getState, { api }) => {
    const data = {
      accountToken,
      queueToken,
    }

    dispatch(setLoading(true, LOADING_ASSIGNMENT_TOKEN))
    return api.debounce(reviewToken, async (resolve) => {
      const json = await api.put('apiReviewAssignmentPath', {
        urlParams: { reviewToken },
        data,
      })
      dispatch(setLoading(false, LOADING_ASSIGNMENT_TOKEN))
      if (json.success) {
        dispatch(updateReview(json.review.token, json.review))
        return resolve(json.review)
      }

      if (json.error) {
        dispatch(setFlashError(json.error.message))
      }
      return resolve(null)
    })
  }
}

export function unassignReview(
  reviewToken: string,
  strategy: AssignmentStrategy = AssignmentStrategy.Assignee
): AsyncThunk<void> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoading(true, LOADING_ASSIGNMENT_TOKEN))
    return api.debounce(reviewToken, async (resolve) => {
      const json = await api.delete('apiReviewAssignmentPath', {
        urlParams: { reviewToken },
        data: { strategy },
      })
      dispatch(setLoading(false, LOADING_ASSIGNMENT_TOKEN))
      if (json.success) {
        dispatch(updateReview(json.review.token, json.review))
        return resolve(json.review)
      }
      if (json.error) {
        dispatch(setFlashError(json.error.message))
      }
      return resolve(null)
    })
  }
}

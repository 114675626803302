import { ApolloClient, gql, useQuery } from '@apollo/client'

import { FetchCaseLockQuery, FetchCaseLockQueryVariables } from './__generated__/useCaseLock.generated'

export const CASE_LOCK_FRAGMENT = gql`
  fragment CaseLock on Investigation {
    __typename
    token
    activeLock {
      __typename
      createdAt
      txn {
        token
      }
    }
  }
`
export const FETCH_CASE_LOCK_QUERY = gql`
  query FetchCaseLock($caseToken: String!) {
    investigation(token: $caseToken) {
      ...CaseLock
    }
  }
  ${CASE_LOCK_FRAGMENT}
`

export type ActiveLock = NonNullable<FetchCaseLockQuery['investigation']>['activeLock']

type Result = { isLocked: boolean; activeLock?: ActiveLock }

export function useCaseLock(caseToken: string): Result
export function useCaseLock(caseToken: string | undefined): Result | undefined

export function useCaseLock(caseToken: string | undefined): Result | undefined {
  const { data } = useQuery<FetchCaseLockQuery, FetchCaseLockQueryVariables>(FETCH_CASE_LOCK_QUERY, {
    variables: { caseToken: caseToken as string },
    fetchPolicy: 'cache-only',
    skip: !caseToken,
  })

  if (!caseToken) {
    return undefined
  }

  return {
    isLocked: !!data?.investigation?.activeLock,
    activeLock: data?.investigation?.activeLock,
  }
}

export async function getCaseLock(client: ApolloClient<any>, caseToken: string): Promise<Result>
export async function getCaseLock(client: ApolloClient<any>, caseToken: string | undefined): Promise<Result | undefined>

export async function getCaseLock(
  client: ApolloClient<any>,
  caseToken: string | undefined
): Promise<Result | undefined> {
  if (!caseToken) {
    return undefined
  }

  const { data } = await client.query({
    query: FETCH_CASE_LOCK_QUERY,
    fetchPolicy: 'cache-only',
    variables: { caseToken },
  })

  return {
    isLocked: !!data?.investigation?.activeLock,
    activeLock: data?.investigation?.activeLock,
  }
}

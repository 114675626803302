import { useMemo } from 'react'

import { SkeletonProps } from '@mui/lab'
import { Box, Skeleton } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { Theme } from 'types/hb'

const defaultProps: Pick<SkeletonProps, 'variant' | 'animation' | 'height'> = {
  variant: 'text',
  animation: 'wave',
  height: 22,
}

const useStyles = makeStyles((theme: Theme) => ({
  skeleton: {
    '& + &': {
      marginTop: theme.spacing(),
    },
  },
}))

export const SkeletonText = ({ className, ...props }: Partial<SkeletonProps>) => {
  const classes = useStyles()
  return <Skeleton {...defaultProps} width={100} className={classNames(classes.skeleton, className)} {...props} />
}

export const SkeletonList = ({
  gap = 0,
  count = 10,
  widthRange = [100, 200],
  ...props
}: Omit<Partial<SkeletonProps>, 'width'> & {
  gap?: number | string
  count?: number
  widthRange?: [number, number]
}) => {
  const [minWidth, maxWidth] = widthRange

  const randomWidthsInRange = useMemo(
    () => new Array(count).fill(null).map(() => Math.random() * (maxWidth - minWidth) + minWidth),
    [count, minWidth, maxWidth]
  )

  return (
    <Box display="flex" flexDirection="column" gap={gap}>
      {randomWidthsInRange.map((width, i) => (
        <Skeleton key={i} width={width} {...defaultProps} {...props} />
      ))}
    </Box>
  )
}

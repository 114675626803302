import { useContext } from 'react'

import { useFeatureFlag } from 'hooks'
import { FeatureFlag } from 'types/api'

import { CommentContext } from './CommentContext'

export function useCommentsEnabled() {
  const commentContext = useContext(CommentContext)
  const commentsFlag = useFeatureFlag(FeatureFlag.EnableTextEntryInlineComments)
  return commentsFlag && !!commentContext
}

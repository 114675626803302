import { gql } from '@apollo/client'

const ScheduledUpcomingRunRowFragment = gql`
  fragment ScheduledUpcomingRunRow on AutomationRuleTriggerResult {
    token
    createdAt
    event {
      __typename
      ... on BaseAutomationEvent {
        token
        eventableToken
        eventableHref
        domainType
      }
    }
  }
`

export const SCHEDULED_UPCOMING_RUNS_TABLE_PAGINATED = gql`
  query ScheduledUpcomingRunsTablePaginated($token: ID!, $organizationToken: ID, $pageSize: Int!, $after: String) {
    automationRule(token: $token, organizationToken: $organizationToken) {
      ... on CanonicalAutomationRuleBase {
        __typename
        token
      }
      __typename
      ... on ScheduleWithTriggerAutomationRule {
        successfulTriggerResultsIncludedInNextRun(first: $pageSize, after: $after) {
          totalCount
          edges {
            node {
              ...ScheduledUpcomingRunRow
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
  ${ScheduledUpcomingRunRowFragment}
`

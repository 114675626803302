import { useMemo } from 'react'

import { useSelector } from 'actions/store'

import Autocomplete from 'components/HbComponents/Form/Inputs/Autocomplete/Autocomplete'

import { Option } from 'components/library/Table/ValueSelector'
import { getErrorDetails } from 'components/material/Form/formikMaterial'
import { getCurrentFilingInstitutions } from 'helpers/stateHelpers'

import { useCountryAndStateInputs } from './hooks'
import { CountryInputProps } from './types'

export function CountryInput(props: CountryInputProps) {
  const { countryName, stateName, countryAndStateName, autosave, errorMessage, ...rest } = props

  const { formik, countries, currentCountry } = useCountryAndStateInputs({
    autosave,
    countryName,
  })

  const filingInstitutionCountry = useSelector(getCurrentFilingInstitutions)[0].country

  const countryOptions = useMemo(() => {
    // Move the organization country to the top of the list for easy access
    return [filingInstitutionCountry, ...Object.keys(countries).filter((c) => c !== filingInstitutionCountry)].map(
      (countryCode) => ({
        display: countries[countryCode].name,
        value: countryCode,
      })
    )
  }, [countries, filingInstitutionCountry])

  const handleCountryChange = (o?: Option) => {
    formik.setFieldValue(countryName, o?.value)
    // Need to clear the state value as it's almost certainly not a valid choice in new country
    if (stateName) {
      formik.setFieldValue(stateName, '')
    }

    if (!o?.value && countryAndStateName) {
      formik.setFieldValue(countryAndStateName, null)
    }
  }

  const { fieldError, showError } = getErrorDetails({ name: countryName }, formik)

  return (
    <Autocomplete
      showResetOption
      errorMessage={errorMessage || (showError ? fieldError : '')}
      value={currentCountry}
      options={countryOptions}
      onChange={handleCountryChange}
      {...rest}
    />
  )
}

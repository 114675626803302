import { isNil, isEmpty } from 'lodash'
import { AnyAction } from 'redux'

import * as actions from 'actions/investigatingActions'

export const TRANSACTION_DIRECTIONS = ['credit', 'debit', 'transfer'] as const
export type TransactionDirection = (typeof TRANSACTION_DIRECTIONS)[number]

export interface VisibleDataFilters {
  selectedTxnTypes: TransactionDirection[]
  amountRange?: [number, number]
  dateRange?: [number, number]
  amountExtent?: [number, number]
  dateExtent?: [number, number]
}

export const CHART_TYPES = ['amount', 'count'] as const
export type ChartType = (typeof CHART_TYPES)[number]

export interface InvestigatingState {
  visibleData: any[]
  dataFilters: VisibleDataFilters
  pageSize: number
  page: number
  timeseriesChartType: ChartType
}

export const getInitialState = (): InvestigatingState => ({
  visibleData: [],
  dataFilters: {
    selectedTxnTypes: [...TRANSACTION_DIRECTIONS],
  },
  pageSize: 25,
  page: 1,
  timeseriesChartType: 'amount',
})

const investigatingReducer = (state = getInitialState(), action: AnyAction): InvestigatingState => {
  switch (action.type) {
    case actions.SET_VISIBLE_DATA: {
      return {
        ...state,
        visibleData: action.data || state.visibleData,
        dataFilters: action.filters ? { ...state.dataFilters, ...action.filters } : state.dataFilters,
        page: 1,
      }
    }
    case actions.SET_VISIBLE_DATA_FILTERS: {
      return {
        ...state,
        dataFilters: { ...state.dataFilters, ...action.filters },
      }
    }
    case actions.SET_DATA_PAGE: {
      return { ...state, page: action.page }
    }
    case actions.SET_DATA_PAGE_SIZE: {
      return { ...state, pageSize: action.size }
    }
    case actions.SET_TIMESERIES_CHART_TYPE: {
      return { ...state, timeseriesChartType: action.chartType }
    }
    case actions.UPDATE_VISIBLE_DATA: {
      const newVisibleData = state.visibleData.slice()
      let entry = newVisibleData.find((e) => e.token === action.data.token)
      if (isEmpty(entry) || isNil(entry)) {
        return state
      }
      entry = Object.assign(entry, action.data)
      return { ...state, visibleData: newVisibleData }
    }
    case actions.RESET: {
      return { ...getInitialState() }
    }
    default: {
      return state
    }
  }
}

export default investigatingReducer

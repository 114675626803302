import { ElementType, ReactNode } from 'react'

import { InputBaseComponentProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { useField } from 'formik'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'
import { TextInput, TextInputProps, MultiLabeledField } from 'components/material/Form'
import { Theme } from 'types/hb'

import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer, getHelperTextId } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps as HBInputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

const useStyles = makeStyles((theme: Theme) => ({
  redesignTextRoot: {
    margin: 0,
    padding: 0,
    // Input type button is used here to mimic form regular input styles, without allowing typing
    // These styles bring the button inline with the default input[type="text"]. Otherwise text aligns to middle which looks odd.
    '& input[type="button"]': {
      textAlign: 'left',
      cursor: 'pointer',
    },
  },
  multilabeled: {
    padding: 0,
    '& + &': {
      paddingTop: theme.spacing(2),
    },
  },
}))

export const convertNewlinesToJSX = (text: string) => {
  if (!text) {
    return text
  }
  return text
    .trim()
    .split('\n')
    .reduce((acc, curr, index, _array) => {
      const trimmedValue = curr.trim()
      if (index === 0) {
        return [trimmedValue]
      }
      // dedupe 2+ blank lines
      if (trimmedValue.length === 0 && acc[acc.length - 1] === '') {
        return acc
      }
      // eslint-disable-next-line react/no-array-index-key
      return [...acc, <br key={index} />, trimmedValue]
    }, [])
}

interface TextInputV2ButtonProps extends HBInputProps {
  multivalued?: boolean
  multiline?: boolean
  adornment?: ReactNode
  isButton: true
  onClick?: () => void
  inputComponent?: ElementType<InputBaseComponentProps>
  startAdornment?: ReactNode
  noSpacing?: boolean
  helperText?: string
}

export interface TextInputV2BaseProps extends HBInputProps {
  multivalued?: boolean
  multiline?: boolean
  adornment?: ReactNode
  isButton?: undefined | false
  onClick?: never
  inputComponent?: ElementType<InputBaseComponentProps>
  startAdornment?: ReactNode
  minRows?: number
  noSpacing?: boolean
  helperText?: string
}

export type TextInputV2Props = TextInputV2BaseProps | TextInputV2ButtonProps

/**
 * @deprecated Prefer using HbTextInput or HbRHFTextInput for react-hook-form
 */
export const TextInputV2 = (props: TextInputV2Props & TextInputProps) => {
  const {
    readOnly,
    label,
    multivalued,
    sublabel,
    name,
    multiline,
    minRows,
    errors,
    disabled,
    autosave,
    isErroneousChecker,
    testId,
    inputContainerClassName,
    placeholder,
    adornment = null,
    isButton,
    onClick,
    inputComponent,
    startAdornment,
    InputProps,
    className: classNameProp,
    noSpacing,
    type,
    helperText,
    linkUrls = true,
  } = props
  const styles = useStyles(props)
  const [field, meta] = useField(name)
  const form = useHbFormikContext({ autosave })
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })
  const inputTestId = getInputTestId(label, testId)

  if (readOnly) {
    return (
      <InputResponse className={inputContainerClassName} label={label}>
        {multivalued && (
          <ul>
            {field.value.map((v: string, i: number) => (
              <li key={`${name}-${i}`}>{v}</li> // eslint-disable-line react/no-array-index-key
            ))}
          </ul>
        )}
        {!multivalued && (convertNewlinesToJSX(field.value) || '-')}
        {adornment}
      </InputResponse>
    )
  }

  const className = classNames(classNameProp, styles.redesignTextRoot)

  if (multivalued) {
    return (
      <InputContainer
        clientError={clientError}
        isErroneous={isErroneous}
        label={label}
        sublabel={sublabel}
        apiError={apiErrors}
        className={inputContainerClassName}
        htmlFor={name}
        noSpacing={noSpacing}
      >
        <MultiLabeledField
          name={name}
          list={field.value}
          adornment="NONE"
          classes={{ formControl: styles.redesignTextRoot, root: styles.multilabeled }}
          variant="outlined"
          InputProps={{
            error: isErroneous,
            ...InputProps,
          }}
          disabled={disabled}
          size="small"
        />
      </InputContainer>
    )
  }

  return (
    <InputContainer
      clientError={clientError}
      isErroneous={isErroneous}
      label={label}
      sublabel={sublabel}
      apiError={apiErrors}
      className={inputContainerClassName}
      testId={testId}
      htmlFor={name}
      noSpacing={noSpacing}
      helperText={helperText}
    >
      <TextInput
        onClick={onClick}
        className={className}
        minRows={minRows || (multiline ? 10 : 1)}
        variant="outlined"
        field={field}
        form={form}
        meta={meta}
        placeholder={placeholder}
        InputProps={{
          error: isErroneous,
          inputProps: isButton ? { type: 'button' } : {},
          endAdornment: adornment,
          inputComponent,
          startAdornment,
          ...InputProps,
          'aria-describedby': getHelperTextId(name),
        }}
        name={name}
        id={name}
        multiline={multiline}
        testId={inputTestId}
        fullWidth
        linkUrls={linkUrls}
        margin="normal"
        disabled={disabled}
        size="small"
        type={type}
      />
    </InputContainer>
  )
}

import { FormHelperText, MenuItem, Select, SelectChangeEvent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbTag } from 'components/HbComponents/HbTag'
import { InputContainer } from 'components/material/Form'
import { ColorNameEnum } from 'types/api'

export type Badge = {
  token: string
  color: ColorNameEnum
  displayName: string
}

export type Props = {
  className?: string
  label?: string
  helperText?: string
  availableBadges: Array<Badge>
  selectedBadges: Array<Badge>
  onChange: (arr: Array<Badge>) => unknown
  disabled?: boolean
  isBadgeSelectable?: (badge: Badge) => boolean
}

const useStyles = makeStyles((theme) => ({
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: theme.spacing(),
    rowGap: theme.spacing(),
  },
}))

export default function BadgeSelect({
  className,
  label,
  availableBadges,
  selectedBadges,
  helperText,
  onChange,
  disabled = false,
  isBadgeSelectable,
}: Props) {
  const classes = useStyles()

  return (
    <InputContainer label={label} sublabel={helperText} htmlFor="" className={className}>
      <Select
        disabled={disabled}
        multiple
        variant="outlined"
        onChange={(event: SelectChangeEvent<string[]>) => {
          const newBadges: Array<Badge> = []
          const newValue = event.target.value
          if (Array.isArray(newValue) && newValue.length > 0) {
            newValue.forEach((token) => {
              const newBadge = availableBadges.find((b) => b.token === token)
              if (newBadge) {
                newBadges.push(newBadge)
              }
            })
          }
          onChange(newBadges)
        }}
        value={selectedBadges.map(({ token }) => token) ?? []}
        renderValue={(tokens: string[]) => (
          <div className={classes.chips}>
            {tokens.map((token) => {
              const badge = availableBadges.find((b) => b.token === token)
              if (!badge) return null
              return <HbTag key={badge.token} color={badge.color} label={badge.displayName} />
            })}
          </div>
        )}
      >
        {availableBadges?.map((badge) => {
          if (!badge) return null
          return (
            <MenuItem
              key={badge.token}
              value={badge.token}
              disabled={isBadgeSelectable ? !isBadgeSelectable(badge) : false}
            >
              <HbTag color={badge.color} label={badge.displayName} />
            </MenuItem>
          )
        })}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </InputContainer>
  )
}

import { enqueueSnackbar } from 'notistack'
import { Switch, Redirect } from 'react-router-dom'

import { Thunk, useSelector } from 'actions/store'
import { HbRoute } from 'components/library/Page/HbRoute'
import AutomationTemplates from 'components/pages/automations/AutomationTemplates'
import { AutomationsTabLayout } from 'components/pages/automations/AutomationsTabLayout'
import ViewAutomation from 'components/pages/automations/ViewAutomation/ViewAutomation'
import { UsageArea } from 'helpers/SessionTracking/UsageTracker'
import { hasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum } from 'types/api'
import { KeyboardOrMouseEvent } from 'types/hb'
import { navigateToInternalUrl } from 'utils/navigationHelpers'

import { AutomationRuleTemplate } from './AutomationRuleTemplate/AutomationRuleTemplate'
import ExploreAutomations from './ExploreAutomations/ExploreAutomations'
import MyAutomations from './MyAutomations'
import Usage from './Usage'
import ViewOrganizationAutomationsActivityLog from './ViewOrganizationAutomationsActivityLog'
import { useAutomationAdminAccountCheck } from './hooks/AutomationAdminHelpers'

export const automationActions = {
  navigateToAutomations(event: KeyboardOrMouseEvent | undefined): Thunk<void> {
    return (dispatch, _getState, { pilot: _pilot }) => {
      dispatch(navigateToInternalUrl(event, '/automations'))
    }
  },
}
export default function AutomationsPage() {
  const hasViewAutomationsPermission = useSelector((state) =>
    hasPermission(state, BadgePermissionsEnum.ViewAutomations)
  )
  const hasAutomationRuleAdminPermission = useAutomationAdminAccountCheck()

  if (hasViewAutomationsPermission) {
    return (
      <UsageArea eventPrefix="Automations">
        <Switch>
          <HbRoute
            path="/automations/explore"
            wrapper={{ Component: AutomationsTabLayout, props: {} }}
            component={ExploreAutomations}
          />
          {hasAutomationRuleAdminPermission ? (
            <HbRoute path="/automations/recipes/:token" component={AutomationRuleTemplate} />
          ) : null}
          {hasAutomationRuleAdminPermission ? (
            <HbRoute
              path="/automations/recipes"
              exact
              wrapper={{ Component: AutomationsTabLayout, props: {} }}
              component={AutomationTemplates}
            />
          ) : null}

          <HbRoute
            path="/automations/usage"
            wrapper={{ Component: AutomationsTabLayout, props: {} }}
            component={Usage}
          />

          <HbRoute
            path="/automations/activity_log"
            wrapper={{ Component: AutomationsTabLayout, props: {} }}
            component={ViewOrganizationAutomationsActivityLog}
          />
          <HbRoute path="/automations/:token/activity_log/table" component={ViewAutomation} />
          <HbRoute path="/automations/:token/activity_log" component={ViewAutomation} />
          {hasAutomationRuleAdminPermission ? (
            <HbRoute path="/automations/:organizationToken/:token/:tab" component={ViewAutomation} />
          ) : null}
          {hasAutomationRuleAdminPermission ? (
            <HbRoute path="/automations/:organizationToken/:token/:tab/table" component={ViewAutomation} />
          ) : null}
          <HbRoute path="/automations/:token" component={ViewAutomation} />
          <HbRoute
            path="/automations"
            exact
            wrapper={{ Component: AutomationsTabLayout, props: {} }}
            component={MyAutomations}
          />
        </Switch>
      </UsageArea>
    )
  }

  return (
    <UsageArea eventPrefix="Automations">
      <Switch>
        <HbRoute
          path="/automations/explore"
          wrapper={{ Component: AutomationsTabLayout, props: {} }}
          component={ExploreAutomations}
        />
        <HbRoute
          path="/automations/:token/:tab"
          render={() => {
            enqueueSnackbar('You do not have permission to view this page', {
              variant: 'error',
              autoHideDuration: 2000,
            })
            return <Redirect to="/automations/explore" />
          }}
        />
        <HbRoute path="/automations" render={(routeProps) => <Redirect to={`${routeProps.match.url}/explore`} />} />
      </Switch>
    </UsageArea>
  )
}

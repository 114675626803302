import { useEffect, useState } from 'react'

import { setFlashError } from 'actions/errorActions'
import { useDispatch } from 'actions/store'
import { resolveAttachmentDownloadUrl, SurveyFileUpload } from 'actions/viewActions'
import { InvestigationAttachment } from 'reducers/investigationsReducer.types'

export function useAttachmentUrl(attachment: InvestigationAttachment | SurveyFileUpload) {
  const dispatch = useDispatch()
  const [url, setUrl] = useState<string | null>(null)

  useEffect(() => {
    if (attachment.type === 'surveyFileUpload') {
      setUrl(attachment.url)
      return
    }
    dispatch(resolveAttachmentDownloadUrl(attachment)).then((newUrl) => {
      if (!newUrl) {
        dispatch(setFlashError('Failed to download attachment'))
      }

      setUrl(newUrl ?? null)
    })
  }, [dispatch, attachment])

  return url
}

import { DialogActions, dialogClasses } from '@mui/material'

import { useTheme } from '@mui/styles'

import { useHistory } from 'react-router-dom'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbDialog, HbDialogContent } from 'components/HbComponents/HbDialog'
import { HbText } from 'components/HbComponents/Text/HbText'
import { RecommendedAutomations } from 'components/pages/automations/RecommendedAutomations'
import { getCurrentOrganization } from 'helpers/stateHelpers'

export const NewAutomationModal = ({
  open,
  onClose,
  onCreateFromBlank,
}: {
  open: boolean
  onClose: () => void
  onCreateFromBlank: () => void
}) => {
  const currentOrganizationName = useSelector((state) => getCurrentOrganization(state)?.name)
  const theme = useTheme()
  const history = useHistory()
  return (
    <HbDialog
      open={open}
      onClose={onClose}
      title="New Automation"
      size="md"
      sx={{
        [`& .${dialogClasses.paper}`]: {
          width: 800,
        },
      }}
    >
      <HbDialogContent>
        <HbText size="lg" bold block css={{ marginBottom: theme.spacing(2) }}>
          Recommended for {currentOrganizationName}
        </HbText>
        <RecommendedAutomations numberOfRecipes={5} onCreateFromBlank={onCreateFromBlank} />
      </HbDialogContent>
      <DialogActions
        sx={{
          margin: theme.spacing(3, 0, 3),
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <HbButton
          label="Explore all recipes"
          onClick={() => {
            history.push('/automations/explore')
            onClose()
          }}
          variant="secondary"
        />
      </DialogActions>
    </HbDialog>
  )
}

import { ArrowBackRounded } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'
import ConfirmDialog from 'components/library/ConfirmDialog'
import { LibraryTypeEnum } from 'types/api'

import { ConfirmMerge, MergeEntity, SharedStepProps } from './types'

const getSourceTokens = (selectedEntities: MergeEntity[], selectedPrimaryEntityToken: string | null) =>
  selectedEntities.filter((e) => e.token !== selectedPrimaryEntityToken).map((e) => e.token)

const getTargetToken = (selectedEntities: MergeEntity[], selectedPrimaryEntityToken: string | null) => {
  const target = selectedEntities.find((entity) => entity.token === selectedPrimaryEntityToken)
  return target?.token
}

const getTokensForMerge = (selectedEntities: MergeEntity[], selectedPrimaryEntityToken: string | null) => {
  const sourceTokens = getSourceTokens(selectedEntities, selectedPrimaryEntityToken)
  const targetToken = getTargetToken(selectedEntities, selectedPrimaryEntityToken)
  return { sourceTokens, targetToken }
}

const useConfirmStepDialogStyles = makeStyles(() => ({
  actionsComponent: {
    borderTop: 'none',
  },
  backButtonContainer: {
    flex: 1,
  },
}))

interface ConfirmStepDialogProps extends SharedStepProps {
  caseToken?: string | null
  confirmMerge: ConfirmMerge
  libraryType: LibraryTypeEnum
  mergeLoading: boolean
  selectedEntities: MergeEntity[]
  selectedPrimaryEntityToken: string | null
}

export const ConfirmStepDialog = ({
  caseToken = null,
  confirmMerge,
  libraryType,
  mergeLoading,
  onClose,
  open,
  selectedEntities,
  selectedPrimaryEntityToken,
  setMergeStep,
}: ConfirmStepDialogProps) => {
  const handleBackClick = () => setMergeStep('compare')

  const handleConfirm = () => {
    if (!selectedPrimaryEntityToken) return
    const { sourceTokens, targetToken } = getTokensForMerge(selectedEntities, selectedPrimaryEntityToken)
    if (!sourceTokens.length || !targetToken) return
    confirmMerge({ variables: { libraryType, sourceTokens, targetToken, mergeContextInvestigationToken: caseToken } })
  }
  const classes = useConfirmStepDialogStyles()
  return (
    <ConfirmDialog
      classes={{ actionsComponent: classes.actionsComponent }}
      confirmText="Confirm and Save"
      confirmVariant="confirmation"
      footerContent={
        <div className={classes.backButtonContainer}>
          <HbButton Icon={ArrowBackRounded} label="Back to editing" onClick={handleBackClick} variant="textSecondary" />
        </div>
      }
      loading={mergeLoading}
      open={open}
      onClose={onClose}
      onConfirm={handleConfirm}
      title="Are you sure you want to merge these subjects?"
      variant="complex"
    >
      <HbText>This decision cannot be undone once it has been made.</HbText>
    </ConfirmDialog>
  )
}

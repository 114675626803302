import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchTransactionsResultFragment } from './searchTransactions.fragments'

export const SEARCH_TRANSACTIONS_QUERY = gql`
  query SearchTransactions($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchTransactions(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchTransactionsResultFragment
    }
    searchTransactionsStats(search: $appliedFilters) {
      sum
      currency
      isoCurrency
      byDirection {
        key
        count
        value
      }
      byDate {
        key
        count
        value
        buckets {
          key
          count
          value
        }
      }
    }
  }

  ${SearchTransactionsResultFragment}
`

export const TRANSACTIONS_COLUMNS_QUERY_V2 = gql`
  query TransactionColumnsV2($includeCustomColumns: Boolean!, $search: SearchRequestInput!) {
    searchTransactionsMetadataV2(columns: [], search: $search) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const TRANSACTIONS_METADATA_QUERY_V2 = gql`
  query TransactionsMetadataV2($columns: [String!], $query: String, $search: SearchRequestInput) {
    searchTransactionsMetadataV2(columns: $columns, query: $query, search: $search) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

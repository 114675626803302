import { matchPath } from 'react-router-dom'

import { DashboardView } from 'dashboards/shared/react/queryGroups/types'
import { addIdToSearchRequest } from 'reducers/dashboards/addIdToSearchRequest'
import { DashboardState } from 'reducers/dashboards/dashboards.types'
import {
  CombinatorEnum,
  FilterInput,
  GroupInput,
  OperatorEnum,
  PredicateInput,
  SearchRequestInput,
  SortEnum,
  SortValueInput,
} from 'types/api'
import history, { readQuery } from 'utils/history'
import { NormalizedSearchRequest, SearchRequest } from 'utils/query/api.types'
import { denormalizeRequest, normalizeRequest } from 'utils/query/normalization'
import { deserializeQuery, serializeQuery } from 'utils/query.serializer'

import { HBQueryString } from 'utils/query.types'

import { DashboardSlice } from './dashboards.constants'

export const buildValues = <T extends string>(input: T | T[]): { values: T[] } | undefined =>
  Array.isArray(input) && input.length > 0
    ? // if it's a non-empty array return this:
      { values: input }
    : !Array.isArray(input) && !!input
    ? // if we have a non-empty string return this: (wrap it in an array)
      { values: [input] }
    : // otherwise return this:
      undefined

const readDashboardQuery = <T extends DashboardSlice>(
  search = window.location.search
): {
  applied: DashboardState<T>['filters']['applied']
  name: undefined
} => {
  const normalizedRequest: NormalizedSearchRequest = deserializeQuery('dashboard')(search) ?? {}
  const request = denormalizeRequest(normalizedRequest)
  return {
    name: undefined,
    applied: addIdToSearchRequest(request),
  }
}

export const readQueryOrProvideDefaultFilters = <T extends DashboardSlice>(
  dashboardPathMatches: readonly string[],
  defaultFilter?: DashboardView<T>
) => {
  const isOnCurrentDashboard = dashboardPathMatches.some((slice) =>
    matchPath(window.location.pathname, { path: slice })
  )

  const queryOverride = isOnCurrentDashboard ? window.location.search : undefined

  if (queryOverride || !defaultFilter) {
    return readDashboardQuery(queryOverride)
  }
  return {
    name: defaultFilter.title,
    applied: addIdToSearchRequest(denormalizeRequest(defaultFilter.request)),
  }
}

type CompressedSortValueInput = { sf: string; sd: SortEnum }
type CompressedGroupInput = { c: CombinatorEnum; p?: number | null }
type CompressedPredicateInput = { o: OperatorEnum; v: string[] }
type CompressedFilterInput = { f: string; g: number; p: CompressedPredicateInput }
type CompressedSearchRequestInput = {
  q?: SearchRequestInput['query'] | null
  f?: CompressedFilterInput[] | null
  g?: CompressedGroupInput[] | null
  s?: CompressedSortValueInput[] | null
}

interface Compressor<Expanded, Compressed> {
  compress: (expanded: Expanded) => Compressed
  expand: (compressed: Compressed) => Expanded
}

export const SortCompressor: Compressor<SortValueInput, CompressedSortValueInput> = {
  compress: ({ sortDir, sortField }) => ({
    sd: sortDir,
    sf: sortField,
  }),
  expand: ({ sd, sf }) => ({ sortDir: sd, sortField: sf }),
}
export const GroupCompressor: Compressor<GroupInput, CompressedGroupInput> = {
  compress: ({ combinator, parent }) => ({
    c: combinator,
    p: parent ?? undefined,
  }),
  expand: ({ c, p }) => ({ combinator: c, parent: p }),
}
const PredicateCompressor: Compressor<PredicateInput, CompressedPredicateInput> = {
  compress: ({ operator, values }) => ({ o: operator, v: values }),
  expand: ({ o, v }) => ({ operator: o, values: v }),
}
export const FilterCompressor: Compressor<FilterInput, CompressedFilterInput> = {
  compress: ({ field, group, predicate }) => {
    return {
      f: field,
      g: group,
      p: PredicateCompressor.compress(predicate),
    }
  },
  expand: ({ f, g, p }) => ({
    field: f,
    group: g,
    predicate: PredicateCompressor.expand(p),
  }),
}
export const RequestCompressor: Compressor<SearchRequestInput, CompressedSearchRequestInput> = {
  // converts normalized search request data into single-letter param form for shortened query strings
  compress: ({ query, groups, sorts, filters }) => ({
    q: query,
    f: filters?.map(FilterCompressor.compress),
    g: groups?.map(GroupCompressor.compress),
    s: sorts?.map(SortCompressor.compress),
  }),
  expand: ({ q, f, g, s }) => ({
    query: q,
    sorts: s?.map(SortCompressor.expand),
    groups: g?.map(GroupCompressor.expand),
    filters: f?.map(FilterCompressor.expand),
  }),
}

export const calculateNextQuery = ({
  request,
  sorts,
  currentPathName,
  existingCaseQuery,
}: {
  request: SearchRequest
  sorts?: SearchRequest['sorts']
  currentPathName: string
  existingCaseQuery?: HBQueryString['case']
}): string => {
  const normalized = normalizeRequest(request)
  const shortened = RequestCompressor.compress(normalized)

  const caseMatch = matchPath<{ caseToken: string }>(currentPathName, {
    path: '/dashboard/cases/:caseToken',
  })
  const newQuery = serializeQuery('db')(sorts ? { ...shortened, s: sorts.map(SortCompressor.compress) } : shortened)
  const queries = [newQuery]

  if (existingCaseQuery && caseMatch) {
    // Don't wipe out case context if we're on a case.
    // Do wipe it out if we are not on a case.
    queries.unshift(serializeQuery('case')(existingCaseQuery))
  }

  return queries.join('&')
}

export const pushQuery = ({
  request,
  sorts,
  replace = true,
}: {
  request: SearchRequest
  sorts?: SearchRequest['sorts']
  replace?: boolean
}) => {
  const action = replace ? history.replace : history.push
  const existingCaseQuery = readQuery('case')
  action({
    pathname: history.location.pathname,
    search: calculateNextQuery({ request, sorts, currentPathName: history.location.pathname, existingCaseQuery }),
  })
}

import { gql } from '@apollo/client'

export const AttachmentFragment = gql`
  fragment CaseFileAttachment on Attachment {
    contentType
    filename
    token
    uploadedAt
    uploadedBy {
      fullName
    }
  }
`

export const InformationRequestResponseFragment = gql`
  fragment CaseFileInformationRequestResponse on InformationRequestResponse {
    informationRequestRecipient {
      email
    }
    surveyResponse {
      createdAt
    }
    token
  }
`

export const ReviewDocumentFragment = gql`
  fragment CaseFileReviewDocument on ReviewDocument {
    description
    format
    path
    title
    token
    type
  }
`

export const SurveyResponseFileUploadFragment = gql`
  fragment CaseFileSurveyResponseFileUpload on SurveyResponseFileUpload {
    clientId
    contentType
    question {
      token
    }
    title
    token
  }
`

export const SearchFileFragment = gql`
  fragment SearchFileFragment on CaseFile {
    ... on Attachment {
      ...CaseFileAttachment
    }
    ... on InformationRequestResponse {
      ...CaseFileInformationRequestResponse
    }
    ... on ReviewDocument {
      ...CaseFileReviewDocument
    }
    ... on SurveyResponseFileUpload {
      ...CaseFileSurveyResponseFileUpload
    }
  }
  ${AttachmentFragment}
  ${InformationRequestResponseFragment}
  ${ReviewDocumentFragment}
  ${SurveyResponseFileUploadFragment}
`

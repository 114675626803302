import { gql } from '@apollo/client'

export const SearchAutomationRuleExecutionsResultFragment = gql`
  fragment SearchAutomationRuleExecutionsResultFragment on SearchAutomationRuleExecutionsResult {
    entries {
      createdAt
      status
      ruleName
      details
      event {
        __typename
        ... on BaseAutomationEvent {
          token
          eventableToken
          eventableHref
          domainType
        }
        ... on AutomationFilingEvent {
          reviewToken
        }
      }
      token
    }
    page
    pageSize
    pageCount
    totalCount
  }
`

import { createNextState } from '@reduxjs/toolkit'

import { keyBy } from 'lodash'
import { AnyAction } from 'redux'

import { ReduxSnackbar } from 'actions/applicationActions'
import { SnakeDataTypeKey, SnakeLibraryDataTypeKey } from 'actions/importingFields.types'
import { createReducer } from 'helpers/reducers'
import { TriggerRule } from 'types/alert'
import { BadgePermissionsEnum, FeatureFlag, Queue } from 'types/api'
import { Account, CurrentAccount, InitialData } from 'types/hb'

export type SdtmCredentialState = 'untested' | 'invalid' | 'valid'
export type FiuCredentialState = SdtmCredentialState

export type NonLibraryImportableTypes = 'import_template' | 'transaction_imports' | 'attachment'
export type ImportableType = SnakeLibraryDataTypeKey | NonLibraryImportableTypes

export type Organization = {
  token: string
  name: string
  customerId: string
  shortCode: string
  institutionType: string
  institutionTypeOther: string
  primaryFederalRegulator: string
  tin: string
  tinType: string
  identificationNumber: string
  identificationType: string
  timezone: string
  contactOffice: string
  contactPhone: string
  alternateNames: string
  addressLine1: string
  addressLine2: string
  locality: string
  sublocality: string
  administrativeDistrictLevel1: string
  postalCode: string
  country: string
  fincenUsername: string
  sdtmCredentialState?: SdtmCredentialState
  loginBannerMessage: string
  supportedLibraryDataTypes: SnakeDataTypeKey[]
  hasFincenPassword: boolean
  featureFlags: { [flag in FeatureFlag]: boolean }
}

export type FilingInstitution = {
  token: string
  name: string
  externalId: string
  defaultForOrganization: boolean
  organizationToken: string
  institutionType: string
  institutionTypeOther: string
  primaryFederalRegulator: string
  jurisdiction: string
  tin: string
  tinType: string
  identificationNumber: string
  identificationType: string
  contactOffice: string
  contactPhone: string
  email: string
  alternateNames: string
  addressLine1: string
  addressLine2: string
  locality: string
  sublocality: string
  administrativeDistrictLevel1: string
  postalCode: string
  country: string
  fincenUsername: string
  sdtmCredentialState?: SdtmCredentialState
  hasFincenPassword: boolean
  fiuUsername: string
  fiuCredentialState?: FiuCredentialState
  hasFiuPassword: boolean
  libraryFinancialInstitutionToken?: string
}

export interface OrganizationPermissions {
  organizationToken: string
  permissions: BadgePermissionsEnum[]
}

export interface ReviewType {
  token: string
  name: string
  initial: boolean
  allowManualCreation: boolean
  displayColor: string
  canonicalId: string
  defaultQueue?: Queue
  /** If true, a queue is required on manual creation of this review type */
  queueRequired: boolean
}

export interface OrganizationReviewTypes {
  organizationToken: string
  reviewTypes: ReviewType[]
}

export interface OrganizationTriggerRules {
  organizationToken: string
  triggerRules: TriggerRule[]
}

export interface EnumEntry {
  display: string
  value: string
}

export type Enums = { [key: string]: EnumEntry[] }

export type ApplicationTheme =
  | 'default'
  | 'mastercard'
  | 'visa'
  | 'fis'
  | 'arbys'
  | 'square'
  | 'usps'
  | 'moneygram'
  | 'westernunion'
  | 'ebay'
  | 'silvergate'
  | 'diem'
  | 'kraken'
  | 'gemini'
  | 'checkout'
  | 'sumup'
  | 'capgemini'
  | 'starbucks'
  | 'abrigo'
  | 'marqeta'
  | 'finastra'
  | 'legitscript'
  | 'middesk'
  | 'kpmg'
  | 'grasshopper'

export interface CountrySubdivision {
  display: string
  value: string
}

export interface Country {
  name: string
  subdivisions: CountrySubdivision[]
}

export interface ApplicationState {
  urls: { [key: string]: string }
  organizations: Organization[]
  userAccounts: Account[]
  filingInstitutionsByToken: { [key: string]: FilingInstitution }
  organizationPermissions: OrganizationPermissions[]
  reviewTypes: OrganizationReviewTypes[]
  organizationReviewTypes: OrganizationReviewTypes[]
  triggerRules: OrganizationTriggerRules[]
  importableTypes: ImportableType[]
  csrfToken?: string
  snackbars: ReduxSnackbar[]
  currentAccount?: CurrentAccount
  signedIn: boolean
  enums: Enums
  dataMappings: {
    [key: string]: { [key: string]: string[] }
  }
  environment?: 'sandbox' | 'development' | 'staging' | 'production'
  theme: ApplicationTheme
  countries: { [postalAbbrev: string]: Country }
  loginBannerDismissed: boolean
  currentOrganizationToken?: string
  currentOrganizationBannerImageUrl?: string
  minimumPasswordScore: number
  churnzeroAppkey?: string
  rudderstackUrl?: string
  rudderstackWriteKey?: string
  intercomHasBooted: boolean
  intercomAppId?: string
  isCustomerDomain: InitialData['isCustomerDomain']
}

const getInitialState = (): ApplicationState => ({
  urls: {},
  organizations: [],
  userAccounts: [],
  filingInstitutionsByToken: {},
  organizationPermissions: [],
  reviewTypes: [],
  organizationReviewTypes: [],
  triggerRules: [],
  csrfToken: undefined,
  snackbars: [],
  currentAccount: undefined,
  theme: 'default',
  signedIn: false,
  enums: {},
  dataMappings: {},
  environment: undefined,
  countries: {},
  loginBannerDismissed: false,
  importableTypes: [],
  minimumPasswordScore: 0,
  intercomHasBooted: false,
  isCustomerDomain: false,
})

function handleUpsertReviewType(applicationState: ApplicationState, action: AnyAction) {
  const performUpsert = (state: ApplicationState, key: 'reviewTypes' | 'organizationReviewTypes') => {
    const orgReviewTypes = state[key].find((org) => org.organizationToken === action.organizationToken)
    if (orgReviewTypes) {
      const reviewTypeIdx = orgReviewTypes.reviewTypes.findIndex(
        (reviewType) => reviewType.canonicalId === action.reviewType.canonicalId
      )
      const reviewType = orgReviewTypes?.reviewTypes[reviewTypeIdx]
      if (reviewType) {
        orgReviewTypes.reviewTypes[reviewTypeIdx] = action.reviewType
      } else {
        orgReviewTypes.reviewTypes.push(action.reviewType)
      }
    }
  }
  return createNextState(
    createNextState(applicationState, (draftState) => performUpsert(draftState, 'organizationReviewTypes')),
    (draftState: ApplicationState) => performUpsert(draftState, 'reviewTypes')
  )
}

const applicationReducer = createReducer<ApplicationState, AnyAction>(
  {
    INITIALIZE_APPLICATION(state: ApplicationState, action: AnyAction) {
      // Mutate filingInstitution to save filingInstitutionsByToken
      const byToken = keyBy(action.state.filingInstitutions, 'token')

      return {
        ...state,
        ...action.state,
        filingInstitutionsByToken: byToken,
      }
    },
    SET_INTERCOM_HAS_BOOTED(state: ApplicationState) {
      return {
        ...state,
        intercomHasBooted: true,
      }
    },
    SET_CURRENT_ACCOUNT(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        currentAccount: action.account,
      }
    },
    SET_ORGANIZATIONS(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        organizations: action.organizations,
      }
    },
    UPDATE_FILING_INSTITUTIONS(state: ApplicationState, action: AnyAction) {
      const byToken = { ...state.filingInstitutionsByToken }

      action.filingInstitutions.forEach((filingInstitution: FilingInstitution) => {
        // Replace the token map
        const updatedFilingInstitution = {
          ...(byToken[filingInstitution.token] || {}),
          ...filingInstitution,
        }
        byToken[filingInstitution.token] = updatedFilingInstitution
      })

      return {
        ...state,
        filingInstitutionsByToken: byToken,
      }
    },
    DISMISS_LOGIN_BANNER(state: ApplicationState) {
      return {
        ...state,
        loginBannerDismissed: true,
      }
    },
    ENQUEUE_SNACKBAR(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        snackbars: [...state.snackbars, action.snackbar],
      }
    },
    ENQUEUE_SNACKBARS(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        snackbars: [...state.snackbars, ...action.snackbars],
      }
    },
    CLOSE_SNACKBAR(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        snackbars: state.snackbars.map((snackbar) =>
          !action.key || snackbar.key === action.key ? { ...snackbar, dismissed: true } : { ...snackbar }
        ),
      }
    },
    REMOVE_SNACKBAR(state: ApplicationState, action: AnyAction) {
      return {
        ...state,
        snackbars: state.snackbars.filter((snackbar) => snackbar.key !== action.key),
      }
    },
    UPSERT_ORGANIZATION_CURRENT_REVIEW_TYPE(state: ApplicationState, action: AnyAction) {
      return handleUpsertReviewType(state, action)
    },
  },
  getInitialState
)

export default applicationReducer

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const AutomationsIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      d="M18.055 2.535c-1.67-1.39-3.74-2.3-6.03-2.51v2.01c1.73.19 3.31.88 4.61 1.92l1.42-1.42ZM10.025 2.035V.025c-2.29.2-4.36 1.12-6.03 2.51l1.42 1.42a8.928 8.928 0 0 1 4.61-1.92ZM4.005 5.365l-1.42-1.42c-1.39 1.67-2.3 3.74-2.51 6.03h2.01c.19-1.73.88-3.31 1.92-4.61ZM19.965 9.975h2.01c-.21-2.29-1.12-4.36-2.51-6.03l-1.42 1.42a8.928 8.928 0 0 1 1.92 4.61ZM6.025 10.975l3.44 1.56 1.56 3.44 1.56-3.44 3.44-1.56-3.44-1.56-1.56-3.44-1.56 3.44-3.44 1.56Z"
      fill="currentColor"
    />
    <path
      d="M11.025 19.975a8.96 8.96 0 0 1-7.46-4h2.46v-2h-6v6h2v-2.7c1.99 2.84 5.27 4.7 9 4.7 4.87 0 9-3.17 10.44-7.56l-1.96-.45c-1.23 3.49-4.56 6.01-8.48 6.01Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default AutomationsIcon

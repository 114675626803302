import React from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const VerifiedIcon = React.forwardRef<SVGSVGElement, SvgProps>((props, ref) => (
  <SvgIcon ref={ref} {...props} viewBox="0 0 16 14">
    <path
      d="M15.3334 7L13.7067 5.14L13.9334 2.68L11.5267 2.13333L10.2667 0L8.00002 0.973333L5.73335 0L4.47335 2.12667L2.06669 2.66667L2.29335 5.13333L0.666687 7L2.29335 8.86L2.06669 11.3267L4.47335 11.8733L5.73335 14L8.00002 13.02L10.2667 13.9933L11.5267 11.8667L13.9334 11.32L13.7067 8.86L15.3334 7ZM6.25335 9.67333L4.66669 8.07333C4.40669 7.81333 4.40669 7.39333 4.66669 7.13333L4.71335 7.08667C4.97335 6.82667 5.40002 6.82667 5.66002 7.08667L6.73335 8.16667L10.1667 4.72667C10.4267 4.46667 10.8534 4.46667 11.1134 4.72667L11.16 4.77333C11.42 5.03333 11.42 5.45333 11.16 5.71333L7.21335 9.67333C6.94002 9.93333 6.52002 9.93333 6.25335 9.67333Z"
      fill="currentColor"
    />
  </SvgIcon>
))

import { FormHelperText } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Controller } from 'react-hook-form'

import HbTextArea from 'components/HbComponents/Form/HbInputs/HbTextArea/HbTextArea'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Theme } from 'types/hb'

import type { FormSchema } from '../formSchema'

const useStyles = makeStyles((_theme: Theme) => ({
  root: {
    width: '100%',
  },
}))

export default function CustomizeCancelReviewAction() {
  const classes = useStyles()

  return (
    <Controller<FormSchema>
      name="actionParams.cancelReviewParams.cancellationReason"
      render={({ field, fieldState }) => (
        <>
          <HbText bold size="s" color="secondary">
            Cancellation Reason
          </HbText>
          <HbTextArea
            className={classes.root}
            label="Cancellation Reason"
            minRows={4}
            {...field}
            inputProps={{ placeholder: 'Write a cancellation reason...' }}
            error={Boolean(fieldState.error)}
            hideLabel
          />
          <FormHelperText error={fieldState.invalid}>
            {fieldState.error?.message || 'Max length 4,000 characters'}
          </FormHelperText>
        </>
      )}
    />
  )
}

import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

import { CaseFile } from 'components/cases/Tabs/Files/caseFileTypes'

export type AttachmentSummaryItem = {
  attachmentSummaryToken: string
  caseFileToken: string
  investigationToken: string
  displayName: string
  clientId?: string
  questionToken?: string
  type: CaseFile['type']
  contentType?: string | null
}

export type AttachmentSummaryState = {
  currentAttachments: {
    [attachmentSummaryToken: string]: AttachmentSummaryItem
  }
  caseTableAttachmentSummaryToken: string | null
}

export const initialState: AttachmentSummaryState = {
  currentAttachments: {},
  caseTableAttachmentSummaryToken: null,
}

const AttachmentSummarySlice = createSlice({
  name: 'AttachmentSummary',
  initialState,
  reducers: {
    addCurrentAttachment(state, action: PayloadAction<AttachmentSummaryItem>) {
      const { attachmentSummaryToken } = action.payload
      return {
        ...state,
        currentAttachments: { ...state.currentAttachments, [attachmentSummaryToken]: action.payload },
      }
    },
    removeCurrentAttachment(state, action: PayloadAction<{ attachmentSummaryToken: string }>) {
      // state mutation is okay since we're using immer under the hood
      delete state.currentAttachments[action.payload.attachmentSummaryToken]
    },
    setCaseTableAttachmentSummaryToken(state, action: PayloadAction<string | null>) {
      state.caseTableAttachmentSummaryToken = action.payload
    },
  },
})

export const { addCurrentAttachment, removeCurrentAttachment, setCaseTableAttachmentSummaryToken } =
  AttachmentSummarySlice.actions
export default AttachmentSummarySlice.reducer

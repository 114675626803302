import { Flag, Group } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { differenceWith, isEqual } from 'lodash'

import { SnakeDataTypeKey } from 'actions/importingFields.types'

import { useSelector } from 'actions/store'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { ParentGroup } from 'dashboards/shared/react/queryGroups/parentGroup'
import { AllInboxIcon, CustomInvestigateIcon, ListIcon } from 'icons'
import DataIcon from 'icons/DataIcon'
import { Organization } from 'reducers/applicationReducer'
import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import {
  CombinatorEnum,
  DashboardTypeEnum,
  FeatureFlag,
  FilterInput,
  InvestigationReviewStatusEnum,
  OperatorEnum,
  SavedSearchRequest,
  SearchRequestInput,
  SortEnum,
  SortValueInput,
} from 'types/api'
import { CurrentAccount, Theme } from 'types/hb'
import { SearchRequestWithId } from 'utils/query/api.types'
import { normalizeRequest } from 'utils/query/normalization'

import {
  allRequestsFilter,
  defaultInformationRequestsSortOption,
  defaultInformationRequestsSorts,
  getInformationRequestsNamedQueriesList,
} from './queryGroups/informationRequestQueryGroups'
import { DashboardView, NamedQueryListParams, NamedQuerySubList } from './queryGroups/types'

export const DEFAULT_FILTER_TITLE = 'Filters'

const getOpenFilters = (): FilterInput => {
  const values = [
    InvestigationReviewStatusEnum.Opened,
    InvestigationReviewStatusEnum.WorkStarted,
    InvestigationReviewStatusEnum.ReadyForReview,
    InvestigationReviewStatusEnum.Approved,
    InvestigationReviewStatusEnum.ChangesRequested,
  ]
  const openFilters: FilterInput = {
    field: 'reviewStatus',
    predicate: {
      operator: OperatorEnum.In,
      values,
    },
    group: 0,
  }
  return openFilters
}

const defaultSortDirection = SortEnum.Asc

const defaultReviewSortOption = { direction: defaultSortDirection, field: 'timeline', display: 'Timeline' }
const defaultReviewsSorts = [{ sortDir: defaultSortDirection, sortField: 'timeline' }]

export const myCasesFilter = (currentAccount?: CurrentAccount): DashboardView<DashboardTypeEnum.Reviews> => {
  const filters: FilterInput[] = [{ ...getOpenFilters(), group: 2 }]
  if (currentAccount) {
    filters.push(
      {
        field: 'assignee',
        predicate: {
          operator: OperatorEnum.Is,
          values: [currentAccount.token],
        },
        group: 1,
      },
      {
        field: 'approvers',
        predicate: {
          operator: OperatorEnum.Is,
          values: [currentAccount.token],
        },
        group: 1,
      }
    )
  }

  return {
    name: 'my-cases',
    title: 'My Cases',
    Icon: CustomInvestigateIcon,
    request: {
      filters,
      groups: [ParentGroup, { combinator: CombinatorEnum.Or, parent: 0 }, { combinator: CombinatorEnum.Or, parent: 0 }],
      sorts: defaultReviewsSorts,
    },
  }
}

const myQueuesFilter = (currentAccount: CurrentAccount): DashboardView<DashboardTypeEnum.Reviews> => {
  return {
    name: 'my-queues',
    title: 'My Queues',
    Icon: Group,
    request: {
      filters: [
        { ...getOpenFilters(), group: 2 },
        ...currentAccount.queues.map((queue) => ({
          field: 'queue',
          predicate: {
            operator: OperatorEnum.Is,
            values: [queue.token],
          },
          group: 1,
        })),
        ...currentAccount.queues.map((queue) => ({
          field: 'approvers',
          predicate: {
            operator: OperatorEnum.Is,
            values: [queue.token],
          },
          group: 1,
        })),
      ],
      groups: [ParentGroup, { combinator: CombinatorEnum.Or, parent: 0 }, { combinator: CombinatorEnum.Or, parent: 0 }],
      sorts: defaultReviewsSorts,
    },
  }
}

const openCasesFilter: DashboardView<DashboardTypeEnum.Reviews> = {
  name: 'open-cases',
  title: 'Open Cases',
  Icon: AllInboxIcon,
  request: {
    filters: [getOpenFilters()],
    groups: [ParentGroup],
    sorts: defaultReviewsSorts,
  },
}

const allCasesFilter: DashboardView<DashboardTypeEnum.Reviews> = {
  name: 'all-cases',
  title: 'All Cases',
  Icon: ListIcon,
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultReviewsSorts,
  },
}

const getNamedReviewsQueries = (
  currentAccount: CurrentAccount,
  currentOrganization: Organization
): DashboardView<DashboardTypeEnum.Reviews>[] => {
  const filters = [myCasesFilter(currentAccount)]
  // doesn't make sense to show if there are no queues
  if (currentOrganization?.featureFlags[FeatureFlag.ReviewsToQueues] && currentAccount.queues.length > 0) {
    filters.push(myQueuesFilter(currentAccount))
  }
  filters.push(openCasesFilter, allCasesFilter)
  return filters
}

const defaultProfileSortOption = { direction: defaultSortDirection, field: 'displayName', display: 'Profile Name' }
const defaultProfilesSorts = [{ sortDir: defaultSortDirection, sortField: 'displayName' }]

const allProfilesFilter: () => DashboardView<DashboardTypeEnum.Profiles> = () => ({
  name: 'all-profiles',
  title: 'All Profiles',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultProfilesSorts,
  },
})

const defaultTransactionsSortOption = { direction: defaultSortDirection, field: 'date', display: 'Date' }
const defaultTransactionsSorts = [{ sortDir: defaultSortDirection, sortField: 'date' }]

const allTransactionsFilter: () => DashboardView<DashboardTypeEnum.Transactions> = () => ({
  name: 'all-transactions',
  title: 'All Transactions',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultTransactionsSorts,
  },
})
const flaggedTransactionsFilter: () => DashboardView<DashboardTypeEnum.Transactions> = () => ({
  name: 'flagged-transactions',
  title: 'Flagged',
  Icon: Flag,
  request: {
    filters: [
      {
        field: 'flagged',
        predicate: {
          operator: OperatorEnum.Is,
          values: ['true'],
        },
        group: 0,
      },
    ],
    groups: [ParentGroup],
    sorts: defaultTransactionsSorts,
  },
})

export const byAlertRuleFilter: (rule: string) => Pick<DashboardView<DashboardTypeEnum.Transactions>, 'request'> = (
  rule: string
) => ({
  request: {
    filters: [
      {
        field: 'alertRules',
        predicate: {
          operator: OperatorEnum.Is,
          values: [rule],
        },
        group: 0,
      },
    ],
    groups: [ParentGroup],
    sorts: defaultTransactionsSorts,
  },
})

const defaultFilingsSortOption = { direction: defaultSortDirection, field: 'filedAt', display: 'Filed At' }
const defaultFilingsSorts = [{ sortDir: defaultSortDirection, sortField: 'filedAt' }]

const allFilingsFilter: () => DashboardView<DashboardTypeEnum.Filings> = () => ({
  name: 'all-filings',
  title: 'All Filings',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultFilingsSorts,
  },
})

const defaultCtrFilingsSortOption = { direction: defaultSortDirection, field: 'filedAt', display: 'Filed At' }
const defaultCtrFilingsSorts = [{ sortDir: defaultSortDirection, sortField: 'filedAt' }]
const allCtrFilingsFilter: () => DashboardView<DashboardTypeEnum.CtrFilings> = () => ({
  name: 'all-filings',
  title: 'All Filings',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultCtrFilingsSorts,
  },
})

const defaultAutomationExecutionsSortOption = { direction: SortEnum.Desc, field: 'createdAt', display: 'Created At' }
const defaultAutomationExecutionsSorts = [{ sortDir: SortEnum.Desc, sortField: 'createdAt' }]

const allAutomationExecutionsFilter: () => DashboardView<DashboardTypeEnum.AutomationExecutions> = () => ({
  name: 'all-runs',
  title: 'All Runs',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultAutomationExecutionsSorts,
  },
})

// TODO: [PROD-18184] Sort by type then label; address when we are actually tackling sort/filter
const defaultCustomFieldsSortOption = { direction: SortEnum.Desc, field: 'createdAt', display: 'Created At' }
const defaultCustomFieldsSorts = [{ sortDir: SortEnum.Desc, sortField: 'createdAt' }]

const allCustomFieldsFilter: () => DashboardView<DashboardTypeEnum.CustomFields> = () => ({
  name: 'all-custom-fields',
  title: 'All Custom Fields',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultCustomFieldsSorts,
  },
})

const defaultCaseFilesSortOption = { direction: SortEnum.Desc, field: 'createdAt', display: 'Created At' }
const defaultCaseFilesSorts = [{ sortDir: SortEnum.Desc, sortField: 'createdAt' }]

const allCaseFilesFilter: () => DashboardView<DashboardTypeEnum.CaseFiles> = () => ({
  name: 'all-case-files',
  title: 'All Case Files',
  request: {
    filters: [],
    groups: [ParentGroup],
    sorts: defaultCaseFilesSorts,
  },
})

const useItemStyles = makeStyles((theme: Theme) => ({
  icon: {
    width: 18,
    marginRight: theme.spacing(1.5),
  },
}))
const makeAccountFilterIcon = (dataType: SnakeDataTypeKey) => {
  const Component = () => {
    const { icon } = useItemStyles()
    return <DataIcon dataType={dataType} className={icon} />
  }
  Component.displayName = `AccountFilterIcon_${dataType}`
  return Component
}

const makeProfilesTypeFilter: (value: string) => FilterInput = (value: string) => ({
  field: 'type',
  predicate: {
    operator: OperatorEnum.Is,
    values: [value], // metadata will control this in Phase 2
  },
  group: 0,
})
const peopleFilter: DashboardView<DashboardTypeEnum.Profiles> = {
  name: 'people',
  title: 'People',
  Icon: makeAccountFilterIcon('individual'),
  request: {
    filters: [makeProfilesTypeFilter('people')],
    groups: [ParentGroup],
    sorts: defaultProfilesSorts,
  },
}
const institutionsFilter: DashboardView<DashboardTypeEnum.Profiles> = {
  name: 'financial-institutions',
  title: 'Institutions',
  Icon: makeAccountFilterIcon('institution'),
  request: {
    filters: [makeProfilesTypeFilter('financial_institutions')],
    groups: [ParentGroup],
    sorts: defaultProfilesSorts,
  },
}
const businessesFilter: DashboardView<DashboardTypeEnum.Profiles> = {
  name: 'businesses',
  title: 'Businesses',
  Icon: makeAccountFilterIcon('business'),
  request: {
    filters: [makeProfilesTypeFilter('businesses')],
    groups: [ParentGroup],
    sorts: defaultProfilesSorts,
  },
}
const productsFilter: DashboardView<DashboardTypeEnum.Profiles> = {
  name: 'products',
  title: 'Products',
  Icon: makeAccountFilterIcon('product'),
  request: {
    filters: [makeProfilesTypeFilter('products')],
    groups: [ParentGroup],
    sorts: defaultProfilesSorts,
  },
}

function getNamedProfilesQueries(): DashboardView<DashboardTypeEnum.Profiles>[] {
  return [peopleFilter, institutionsFilter, businessesFilter, productsFilter]
}

function getReviewsNamedQueriesList({
  currentAccount,
  currentOrganization,
}: NamedQueryListParams): NamedQuerySubList<DashboardTypeEnum.Reviews>[] {
  return [{ title: null, queries: getNamedReviewsQueries(currentAccount, currentOrganization), key: 'defaultQueries' }]
}

function getProfilesNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.Profiles>[] {
  return [
    { title: null, queries: [allProfilesFilter()], key: 'all' },
    { title: null, queries: getNamedProfilesQueries(), key: 'defaultQueries' },
  ]
}

function getTransactionsNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.Transactions>[] {
  return [{ title: null, queries: [allTransactionsFilter(), flaggedTransactionsFilter()], key: 'all' }]
}

function getFilingsNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.Filings>[] {
  return [
    { title: null, queries: [allFilingsFilter()], key: 'all' },
    // TODO
    // { title: null, queries: getNamedProfilesQueries(''), key: 'defaultQueries' },
  ]
}

function getCtrFilingsNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.CtrFilings>[] {
  return [{ title: null, queries: [allCtrFilingsFilter()], key: 'all' }]
}

function getAutomationExecutionsNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.AutomationExecutions>[] {
  return [{ title: null, queries: [allAutomationExecutionsFilter()], key: 'all' }]
}

function getCustomFieldsNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.CustomFields>[] {
  return [{ title: null, queries: [allCustomFieldsFilter()], key: 'all' }]
}

function getCaseFilesNamedQueriesList(): NamedQuerySubList<DashboardTypeEnum.CaseFiles>[] {
  return [{ title: null, queries: [allCaseFilesFilter()], key: 'all' }]
}

export const namedQueriesListMap: Record<
  DashboardSlice,
  ({ currentAccount, currentOrganization }: NamedQueryListParams) => NamedQuerySubList<DashboardSlice>[]
> = {
  [DashboardTypeEnum.Reviews]: getReviewsNamedQueriesList,
  [DashboardTypeEnum.Profiles]: getProfilesNamedQueriesList,
  [DashboardTypeEnum.InformationRequests]: getInformationRequestsNamedQueriesList,
  [DashboardTypeEnum.Transactions]: getTransactionsNamedQueriesList,
  [DashboardTypeEnum.Filings]: getFilingsNamedQueriesList,
  [DashboardTypeEnum.CtrFilings]: getCtrFilingsNamedQueriesList,
  [DashboardTypeEnum.AutomationExecutions]: getAutomationExecutionsNamedQueriesList,
  [DashboardTypeEnum.CustomFields]: getCustomFieldsNamedQueriesList,
  [DashboardTypeEnum.CaseFiles]: getCaseFilesNamedQueriesList,
}

const filterInputsEqual = (a: FilterInput, b: FilterInput): boolean => {
  return (
    a.field === b.field &&
    a.group === b.group &&
    isEqual(a.predicate.values, b.predicate.values) &&
    a.predicate.operator === b.predicate.operator
  )
}

const sortInputsEqual = (a: SortValueInput, b: SortValueInput): boolean =>
  a.sortDir === b.sortDir && a.sortField === b.sortField

const filterInputsMatch = (view: SearchRequestInput | SavedSearchRequest, normalizedFilters: FilterInput[]): boolean =>
  view.filters?.length === normalizedFilters.length &&
  differenceWith(view.filters, normalizedFilters, filterInputsEqual).length === 0

const sortInputsMatch = (view: SearchRequestInput | SavedSearchRequest, normalizedSorts: SortValueInput[]): boolean =>
  view.sorts?.length === normalizedSorts.length &&
  differenceWith(view.sorts, normalizedSorts, sortInputsEqual).length === 0

interface GetFilterProps {
  request?: SearchRequestWithId
  namedQueries: DashboardView[]
  customViews?: SavedSearchRequest[]
  currentViewTitle?: string
}

export function getFilterTitle({ request, namedQueries, customViews = [], currentViewTitle }: GetFilterProps): string {
  if (!request) return DEFAULT_FILTER_TITLE

  const { filters, sorts } = normalizeRequest(request)
  if (!filters) return DEFAULT_FILTER_TITLE

  for (const query of namedQueries) {
    if (filterInputsMatch(query.request, filters) && sortInputsMatch(query.request, sorts ?? [])) {
      return query.title
    }
  }

  for (const view of customViews) {
    // If a custom view matches the current stored value, then the new request is an edit on a custom filter
    if (view.name === currentViewTitle) return currentViewTitle

    if (filterInputsMatch(view, filters) && sortInputsMatch(view, sorts ?? [])) {
      return view.name
    }
  }

  return DEFAULT_FILTER_TITLE
}

/**
 * Checks for the presence of an existing filter matching the current filter criteria
 * @param GetFilterProps
 * @returns DashboardView | SavedSearchRequest | undefined
 */
export function checkExistingFilter({
  request,
  namedQueries,
  customViews = [],
}: GetFilterProps): DashboardView | SavedSearchRequest | undefined {
  if (request) {
    const { filters, sorts } = normalizeRequest(request)
    if (filters) {
      for (const query of namedQueries) {
        if (filterInputsMatch(query.request, filters) && sortInputsMatch(query.request, sorts ?? [])) {
          return query
        }
      }

      for (const view of customViews) {
        if (filterInputsMatch(view, filters) && sortInputsMatch(view, sorts ?? [])) {
          return view
        }
      }
    }
  }

  return undefined
}

export interface SearchRequestInfo {
  viewTitle: string
  isCustom: boolean
  isCreating: boolean
  isEditing: boolean
}

/**
 * Checks if a user's to-be-saved search request is different from currently named filters
 * @param currentAccountToken user's account token
 * @param request SearchRequestWithId for applied search
 * @returns Object with currentFilterTitle string, and flags for isCustom and isCreating
 */
export function useSearchRequestInfo(): SearchRequestInfo {
  const dashboardSelectors = useDashboardSelectors()
  return useSelector(dashboardSelectors.searchRequestInfo)
}

type FilterConfig<K extends DashboardSlice> = {
  dashboardPathMatches: string[]
  defaultFilter: (currentAccount?: CurrentAccount, currentOrganization?: Organization) => DashboardView<K>
  unfilteredFilter: DashboardView<K>
  defaultSort: { direction: SortEnum; field: string; display: string }
  defaultSorts: { sortDir: SortEnum; sortField: string }[]
}

export const filtersMap: Record<DashboardSlice, FilterConfig<DashboardSlice>> = {
  [DashboardTypeEnum.Reviews]: {
    dashboardPathMatches: ['/dashboard/cases', '/dashboard/reviews'],
    defaultFilter: myCasesFilter,
    unfilteredFilter: allCasesFilter,
    defaultSort: defaultReviewSortOption,
    defaultSorts: defaultReviewsSorts,
  },
  [DashboardTypeEnum.Profiles]: {
    dashboardPathMatches: ['/dashboard/library'],
    defaultFilter: allProfilesFilter,
    unfilteredFilter: allProfilesFilter(),
    defaultSort: defaultProfileSortOption,
    defaultSorts: defaultProfilesSorts,
  },
  [DashboardTypeEnum.InformationRequests]: {
    dashboardPathMatches: ['/dashboard/requests'],
    defaultFilter: () => allRequestsFilter,
    unfilteredFilter: allRequestsFilter,
    defaultSort: defaultInformationRequestsSortOption,
    defaultSorts: defaultInformationRequestsSorts,
  },
  [DashboardTypeEnum.Transactions]: {
    dashboardPathMatches: [],
    defaultFilter: allTransactionsFilter,
    unfilteredFilter: allTransactionsFilter(),
    defaultSort: defaultTransactionsSortOption,
    defaultSorts: defaultTransactionsSorts,
  },
  [DashboardTypeEnum.Filings]: {
    dashboardPathMatches: ['/dashboard/filings'],
    defaultFilter: allFilingsFilter,
    unfilteredFilter: allFilingsFilter(),
    defaultSort: defaultFilingsSortOption,
    defaultSorts: defaultFilingsSorts,
  },
  [DashboardTypeEnum.CtrFilings]: {
    dashboardPathMatches: ['/dashboard/ctr_filings'],
    defaultFilter: allCtrFilingsFilter,
    unfilteredFilter: allCtrFilingsFilter(),
    defaultSort: defaultCtrFilingsSortOption,
    defaultSorts: defaultCtrFilingsSorts,
  },
  [DashboardTypeEnum.AutomationExecutions]: {
    dashboardPathMatches: ['automation_executions', '/automations/:token/activity_log/table'],
    defaultFilter: allAutomationExecutionsFilter,
    unfilteredFilter: allAutomationExecutionsFilter(),
    defaultSort: defaultAutomationExecutionsSortOption,
    defaultSorts: defaultAutomationExecutionsSorts,
  },
  [DashboardTypeEnum.CustomFields]: {
    dashboardPathMatches: ['/settings/organization-settings/other-info'],
    defaultFilter: allCustomFieldsFilter,
    unfilteredFilter: allCustomFieldsFilter(),
    defaultSort: defaultCustomFieldsSortOption,
    defaultSorts: defaultCustomFieldsSorts,
  },
  [DashboardTypeEnum.CaseFiles]: {
    dashboardPathMatches: [],
    defaultFilter: allCaseFilesFilter,
    unfilteredFilter: allCaseFilesFilter(),
    defaultSort: defaultCaseFilesSortOption,
    defaultSorts: defaultCaseFilesSorts,
  },
}

export const useDashboardFilters = () => {
  const dashboardContextValue = useDashboardContext()
  return filtersMap[dashboardContextValue]
}

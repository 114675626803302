import { useCallback, useEffect, useRef, useState } from 'react'

import { computePosition, offset, shift } from '@floating-ui/dom'

import {
  $isListNode,
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  ListNode,
  REMOVE_LIST_COMMAND,
} from '@lexical/list'
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin'
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils'

import {
  AddComment,
  FormatBold,
  FormatUnderlined,
  FormatItalic,
  FormatListNumbered,
  FormatListBulleted,
  FormatClear,
  SmartButtonRounded,
} from '@mui/icons-material'
import { Paper, ClickAwayListener, List, ListItemButton } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { CSSProperties, makeStyles } from '@mui/styles'

import classNames from 'classnames'

import {
  $getSelection,
  $isRangeSelection,
  $isTextNode,
  COMMAND_PRIORITY_CRITICAL,
  createCommand,
  EditorState,
  FORMAT_TEXT_COMMAND,
  LexicalCommand,
  LexicalEditor,
  SELECTION_CHANGE_COMMAND,
} from 'lexical'

import pluralize from 'pluralize'

import { HbButton } from 'components/HbComponents/HbButton'

import { HbText } from 'components/HbComponents/Text/HbText'
import {
  aiInstructionOptions,
  AiInstructionsPopover,
  INSERT_AI_INSTRUCTION_COMMAND,
} from 'components/library/TypeAheadEditor/AiInstructionsPlugin'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import AiEditIcon from 'icons/AiEditIcon'
import AutoAwesomeAiGradientIcon from 'icons/AutoAwesomeAiGradientIcon'
import { Theme } from 'types/hb'

import { Placement } from '../../HbComponents/HbTooltip'

import { START_NEW_COMMENT_THREAD_EDITOR_COMMAND } from './CommentPlugin'
import { INSERT_LIQUID_COMMAND } from './LiquidPlugin'
import { Seperator } from './Seperator'
import { useCommentsEnabled } from './TypeAheadEditor.hooks'
import { countWords, editorStateToPlainText } from './utils'

const useClasses = makeStyles<Theme>((theme: Theme) => {
  const flexRowCenter: CSSProperties = {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  }
  const tools: CSSProperties = {
    ...flexRowCenter,
    gap: theme.spacing(0.75),
  }

  return {
    root: {
      ...flexRowCenter,
      justifyContent: 'space-between',
      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.08)',
      background: theme.palette.background.paper, // Design White
      padding: theme.spacing(0.75, 1.25),
      borderRadius: theme.spacing(1, 1, 0, 0),
    },
    tools: {
      ...tools,
      flexGrow: 1,
    },
    tool: {
      width: 28,
      height: 28,
      borderRadius: 6,
      color: theme.palette.text.secondary, // Design Grey A
    },
    activeTool: {
      background: theme.palette.background.medium, // Design Grey D
      color: theme.palette.text.primary, // Design Black
    },
    '@keyframes fadeIn': {
      from: {
        opacity: 0,
      },
      to: {
        opacity: 1,
      },
    },
    hoverToolbar: {
      ...tools,
      position: 'absolute',
      padding: theme.spacing(),
      animation: '$fadeIn 250ms cubic-bezier(0.19, 1, 0.22, 1) forwards', // The world's greatest easing curve
    },
  }
})

export const CLOSE_FLOATING_TOOLBAR_COMMAND: LexicalCommand<void> = createCommand()

function getWindowSelection() {
  const nativeSelection = window.getSelection()
  const nativeRange = (nativeSelection?.rangeCount ?? 0) > 0 && nativeSelection?.getRangeAt(0)

  return nativeRange && !nativeRange.collapsed ? nativeRange : null
}

const useToolbarProps = () => {
  const [editor] = useLexicalComposerContext()
  const [wordCount, setWordCount] = useState(0)

  // Handle word count initial state and updates
  useEffect(() => {
    setWordCount(countWords(editorStateToPlainText(editor.getEditorState())))
    return editor.registerUpdateListener(() => {
      setWordCount(countWords(editorStateToPlainText(editor.getEditorState())))
    })
  }, [editor])

  const [isBold, setIsBold] = useState(false)
  const [isItalic, setIsItalic] = useState(false)
  const [isUnderline, setIsUnderline] = useState(false)
  const [canClearFormat, setCanClearFormat] = useState(false)
  const [position, setPosition] = useState<{
    clientWidth: number
    clientHeight: number
    getBoundingClientRect: () => DOMRect
  } | null>(null)
  const [blockType, setBlockType] = useState<string>()

  // Handle text changes
  const handleTextChange = useCallback((editorState: EditorState, _newEditor: LexicalEditor) => {
    const text = editorStateToPlainText(editorState)
    setWordCount(countWords(text))
  }, [])

  // Handle updating the toolbar buttons when necessary
  const $updateToolbar = useCallback(() => {
    const selection = $getSelection()

    if (!selection || !$isRangeSelection(selection)) {
      setIsBold(false)
      setIsItalic(false)
      setIsUnderline(false)
      setBlockType(undefined)
      setCanClearFormat(false)
      return
    }

    setIsBold(selection.hasFormat('bold'))
    setIsItalic(selection.hasFormat('italic'))
    setIsUnderline(selection.hasFormat('underline'))

    // The ability to clear formatting is determined by whether or not the
    // nodes in the selection have any formatting, which is a different
    // heuristic than determining if the selection has any formatting
    setCanClearFormat(
      selection
        .getNodes()
        .filter($isTextNode)
        .some((node) => node.hasFormat('bold') || node.hasFormat('italic') || node.hasFormat('underline'))
    )

    const anchorNode = selection.anchor.getNode()
    const element = anchorNode.getKey() === 'root' ? anchorNode : anchorNode.getTopLevelElementOrThrow()
    const elementKey = element.getKey()
    const elementDom = editor.getElementByKey(elementKey)

    if (elementDom) {
      if ($isListNode(element)) {
        const parentList = $getNearestNodeOfType<ListNode>(anchorNode, ListNode)
        const type = parentList ? parentList.getListType() : element.getListType()
        setBlockType(type)
      } else {
        // If we ever support headings:
        // const type = $isHeadingNode(element) ? element.getTag() : element.getType()
        setBlockType(element.getType())
      }
    }
  }, [editor])

  // Handle updating the popover position when necessary
  useEffect(() => {
    const editorElement = editor.getRootElement()
    const handleMouseUp = () => {
      editor.getEditorState().read(() => {
        const selection = $getSelection()

        if (!selection || !$isRangeSelection(selection)) {
          editor.dispatchCommand(CLOSE_FLOATING_TOOLBAR_COMMAND, undefined)

          return
        }

        const nativeRange = getWindowSelection()
        if (nativeRange) {
          const cachedRect = nativeRange.getBoundingClientRect()
          setPosition({
            clientWidth: 0,
            clientHeight: 0,
            getBoundingClientRect: () => cachedRect,
          })
        } else {
          editor.dispatchCommand(CLOSE_FLOATING_TOOLBAR_COMMAND, undefined)
        }
      })
    }

    if (editorElement) {
      editorElement.addEventListener('mouseup', handleMouseUp, true)
    }

    return () => {
      if (editorElement) {
        editorElement.removeEventListener('mouseup', handleMouseUp, true)
      }
    }
  }, [$updateToolbar, editor])

  // Register editor listeners
  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(() => {
        editor.getEditorState().read(() => {
          $updateToolbar()
        })
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          editor.getEditorState().read(() => {
            $updateToolbar()
          })

          return false
        },
        COMMAND_PRIORITY_CRITICAL
      ),
      editor.registerCommand(
        CLOSE_FLOATING_TOOLBAR_COMMAND,
        () => {
          setPosition(null)

          return true
        },
        COMMAND_PRIORITY_CRITICAL
      )
    )
  }, [editor, $updateToolbar])

  // Handle clicking clear format
  const clearFormatting = useCallback(() => {
    editor.update(() => {
      const selection = $getSelection()
      if ($isRangeSelection(selection)) {
        // Lexical does not have a built-in way to clear formatting from a
        // selection spanning only part of a text node. The playground demo
        // code clears the formatting from the entire editor. Additionally,
        // turning on a format for a given selection can either enable or
        // disable that format depending on what the selection _starts_ with.
        // The simplest way I have found to make the clear format button work
        // the way I'd expect is to toggle each format, then check the
        // selection to see if the format is on, and if so, toggle it off.
        ;(['bold', 'italic', 'underline'] as const).forEach((format) => {
          // Step 1. Toggle the format on. If the selection doesn't start with
          // the format but contains the format then the whole selection will
          // now contain the format (including the start).
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)
          // Step 2: If the selection now starts with the format, toggle it off.
          if (selection.hasFormat(format)) {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, format)
          }
        })
      }
    })
  }, [editor])

  return {
    isBold,
    isUnderline,
    isItalic,
    blockType,
    clearFormatting,
    canClearFormat,
    editor,
    position,
    wordCount,
    handleTextChange,
  }
}

type ToolbarProps = ReturnType<typeof useToolbarProps>

type ToolbarButtonsProps = Pick<
  ToolbarProps,
  'isBold' | 'isUnderline' | 'isItalic' | 'blockType' | 'clearFormatting' | 'canClearFormat' | 'editor'
> & { liquid?: boolean; instructions?: boolean }

const ToolbarButtons = ({
  isBold,
  isUnderline,
  isItalic,
  blockType,
  clearFormatting,
  canClearFormat,
  editor,
  liquid,
  instructions,
}: ToolbarButtonsProps) => {
  const ref = useRef<HTMLButtonElement>(null)
  const classes = useClasses()
  const usage = useUsage()
  const [aiInstructionsOpen, setAiInstructionsOpen] = useState(false)
  const onAiInstructionClick = (event: React.MouseEvent<HTMLElement>) => {
    usage.logEvent({ name: 'narrative:insertAiInstruction:clicked' })
    const value = event.currentTarget.getAttribute('data-value')
    if (value === null) {
      throw new Error('Missing data-value attribute from AI Instruction element')
    }

    editor.dispatchCommand(INSERT_AI_INSTRUCTION_COMMAND, value)
    setAiInstructionsOpen(false)
  }

  return (
    <>
      <HbButton
        label="Bold"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classNames(classes.tool, isBold ? classes.activeTool : null)}
        onClick={() => {
          usage.logEvent({ name: 'narrative:formattingBold:clicked' })
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold')
        }}
        testId="format-bold"
        aria-pressed={isBold}
        Icon={FormatBold}
        iconOnly
      />
      <HbButton
        label="Underline"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classNames(classes.tool, isUnderline ? classes.activeTool : null)}
        onClick={() => {
          usage.logEvent({ name: 'narrative:formattingUnderline:clicked' })
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline')
        }}
        testId="format-underline"
        aria-pressed={isUnderline}
        Icon={FormatUnderlined}
        iconOnly
      />
      <HbButton
        label="Italic"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classNames(classes.tool, isItalic ? classes.activeTool : null)}
        onClick={() => {
          usage.logEvent({ name: 'narrative:formattingItalic:clicked' })
          editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic')
        }}
        testId="format-italic"
        aria-pressed={isItalic}
        Icon={FormatItalic}
        iconOnly
      />
      <Seperator />
      <HbButton
        label="Numbered List"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classes.tool}
        onClick={() => {
          if (blockType !== 'number') {
            usage.logEvent({ name: 'narrative:formattingNumberedList:clicked' })
            editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          }
        }}
        testId="format-ordered-list"
        Icon={FormatListNumbered}
        iconOnly
      />
      <HbButton
        label="Bulleted List"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classes.tool}
        onClick={() => {
          if (blockType !== 'bullet') {
            usage.logEvent({ name: 'narrative:formattingBulletedList:clicked' })
            editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)
          } else {
            editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined)
          }
        }}
        testId="format-unordered-list"
        Icon={FormatListBulleted}
        iconOnly
      />
      {(liquid || instructions) && <Seperator />}
      {liquid && (
        <HbButton
          label="Add smart value"
          tooltip
          tooltipPlacement={Placement.Top}
          className={classes.tool}
          onClick={() => {
            usage.logEvent({ name: 'narrative:addSmartValue:clicked' })
            editor.dispatchCommand(INSERT_LIQUID_COMMAND, undefined)
          }}
          testId="add-smart-value"
          iconOnly
          Icon={SmartButtonRounded}
        />
      )}
      {instructions && (
        <>
          <HbButton
            ref={ref}
            label="Add AI instructions"
            tooltip
            tooltipPlacement={Placement.Top}
            className={classes.tool}
            onClick={() => {
              usage.logEvent({ name: 'narrative:addAiInstruction:clicked' })
              setAiInstructionsOpen(!aiInstructionsOpen)
            }}
            testId="add-ai-instruction"
            iconOnly
            Icon={AutoAwesomeAiGradientIcon}
          />
          <AiInstructionsPopover
            open={aiInstructionsOpen}
            anchorEl={ref.current}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'left' }}
            onClose={() => setAiInstructionsOpen(false)}
          >
            <HbText>Insert AI Instruction</HbText>
            <List>
              {aiInstructionOptions().map((option) => {
                return (
                  <ListItemButton key={option.label} data-value={option.value} onClick={onAiInstructionClick}>
                    <AiEditIcon />
                    <HbText noWrap>{option.label}</HbText>
                  </ListItemButton>
                )
              })}
            </List>
          </AiInstructionsPopover>
        </>
      )}
      <Seperator />
      <HbButton
        label="Clear Formatting"
        tooltip
        tooltipPlacement={Placement.Top}
        className={classes.tool}
        onClick={clearFormatting}
        testId="format-clear"
        disabled={!canClearFormat}
        Icon={FormatClear}
        iconOnly
      />
    </>
  )
}

export function ToolbarPopover(
  props: ToolbarButtonsProps & Pick<ToolbarProps, 'position'> & { commentsEnabled: boolean }
) {
  const { position, commentsEnabled, ...toolbarButtonsProps } = props
  const { editor } = toolbarButtonsProps
  const classes = useClasses()
  if (!position) {
    return null
  }
  return (
    <ClickAwayListener
      onClickAway={() => {
        // Close the editor if the click-away removed the selection.
        // If we still have a selection, the position will update to the new location.
        if (!getWindowSelection()) {
          editor.dispatchCommand(CLOSE_FLOATING_TOOLBAR_COMMAND, undefined)
        }
      }}
    >
      <Paper
        elevation={10}
        className={classes.hoverToolbar}
        data-testid="floating-toolbar"
        ref={async (el) => {
          if (!(el instanceof HTMLElement)) {
            return
          }

          const pos = await computePosition(position, el, {
            placement: 'top-start',
            middleware: [offset(20), shift()],
          })

          Object.assign(el.style, { zIndex: 1, top: `${pos.y}px`, left: `${pos.x}px` })
        }}
      >
        <ToolbarButtons {...toolbarButtonsProps} />

        {commentsEnabled ? (
          <>
            <Seperator />
            <HbButton
              label="Add Comment"
              tooltip
              tooltipPlacement={Placement.Top}
              className={classes.tool}
              onClick={() => {
                editor.dispatchCommand(START_NEW_COMMENT_THREAD_EDITOR_COMMAND, undefined)
                editor.dispatchCommand(CLOSE_FLOATING_TOOLBAR_COMMAND, undefined)
              }}
              testId="add-comment"
              Icon={AddComment}
              iconOnly
            />
          </>
        ) : null}
      </Paper>
    </ClickAwayListener>
  )
}

export default function ToolbarPlugin({
  hideToolbar,
  liquid,
  instructions,
}: {
  hideToolbar?: boolean
  liquid?: boolean
  instructions?: boolean
}) {
  const classes = useClasses()
  const { editor, position, handleTextChange, wordCount, ...restToolbarProps } = useToolbarProps()
  const commentsEnabled = useCommentsEnabled()
  const toolbarButtonsProps = { editor, ...restToolbarProps }

  const popover = <ToolbarPopover {...toolbarButtonsProps} commentsEnabled={commentsEnabled} position={position} />

  return (
    <>
      {popover}
      {hideToolbar ? null : (
        <div className={classes.root}>
          <div className={classes.tools}>
            <ToolbarButtons {...toolbarButtonsProps} liquid={liquid} instructions={instructions} />
          </div>
          {wordCount ? (
            <HbText color="disabled">
              {wordCount} {pluralize('word', wordCount)}
            </HbText>
          ) : null}
        </div>
      )}
      <OnChangePlugin onChange={handleTextChange} />
    </>
  )
}

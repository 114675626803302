import { useCallback } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import {
  OtherInfoLabelSearchQuery,
  OtherInfoLabelSearchQueryVariables,
} from 'components/library/__generated__/OtherInfoLabelInput.generated'
import { AutomationDomainType, OtherInfoLabelTypeEnum } from 'types/api'

const SEARCH_QUERY = gql`
  query AutomationOtherInfoLabelSearch($labelType: OtherInfoLabelTypeEnum!, $query: String) {
    otherInfoLabelSearch(labelType: $labelType, query: $query) {
      token
      label
    }
  }
`

function getQueryType(domainType: string | null): OtherInfoLabelTypeEnum {
  switch (domainType) {
    case AutomationDomainType.Business:
      return OtherInfoLabelTypeEnum.Business
    case AutomationDomainType.Person:
      return OtherInfoLabelTypeEnum.Person
    default:
      return OtherInfoLabelTypeEnum.Review
  }
}

export function useOtherInfoSearchQuery(domainType: string | null) {
  const type = getQueryType(domainType)
  const [searchQuery] = useLazyQuery<OtherInfoLabelSearchQuery, OtherInfoLabelSearchQueryVariables>(SEARCH_QUERY, {
    fetchPolicy: 'network-only',
  })

  const otherInfoLabels = useCallback(
    async (query: string) => {
      const { data } = await searchQuery({
        variables: { query, labelType: type, useVisibilitySettings: false },
      })
      return data?.otherInfoLabelSearch?.map((result) => result.label) || []
    },
    [searchQuery, type]
  )

  return {
    otherInfoLabels,
  }
}

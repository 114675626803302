import { ComponentType, useMemo } from 'react'

import { gql } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useSelector } from 'actions/store'
import { HbTooltip } from 'components/HbComponents/HbTooltip'
import { BatchActionMenuItem } from 'dashboards/shared/components/BatchActionsMenu/BatchActionMenuItem'
import { useBatchActionLookups } from 'dashboards/shared/components/BatchActionsMenu/useBatchActionLookups'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'
import { BatchActionTypeEnum } from 'types/api'

import { Theme } from 'types/hb'

import { BatchActionContext } from '../../shared/components/BatchActionsMenu/BaseBatchActionDialog'

import { useProfileMergingContext } from '../ProfileMergingContext'

import {
  PerformLibraryProfilesBatchActionMutation,
  PerformLibraryProfilesBatchActionMutationVariables,
} from './__generated__/BatchActionsMenu.generated'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginLeft: 16,
    display: 'flex',
    flexWrap: 'nowrap',
    gap: theme.spacing(0.5),
  },
}))

export const PERFORM_BATCH_ACTION = gql`
  mutation PerformLibraryProfilesBatchAction(
    $tokens: [ID!]!
    $batchActionName: BatchActionTypeEnum!
    $parameters: BatchActionParameters
  ) {
    performLibraryProfilesBatchAction(
      input: { tokens: $tokens, batchActionName: $batchActionName, parameters: $parameters }
    ) {
      bid
    }
  }
`

const mergeDisabledErrors = {
  tooManySelected: 'Only 2 profiles can be merged at a time.',
  unsupportedType: 'Merging is not supported for the selected types.',
  typeMismatch: 'Only profiles with the same type can be merged.',
}

const dashboardEntityType = 'profile'

interface MergeBatchActionMenuItemProps {
  getIcon: (batchActionType: BatchActionTypeEnum) => ComponentType<{ className?: string }> | undefined
}

const getMergeDisabledTitle = ({
  mergeAllowedForSelectedType,
  numBatchItemsSelected,
  selectedProfilesHaveSameType,
}: {
  mergeAllowedForSelectedType: boolean
  numBatchItemsSelected: number
  selectedProfilesHaveSameType: boolean
}) => {
  if (numBatchItemsSelected > 2) return mergeDisabledErrors.tooManySelected
  if (!selectedProfilesHaveSameType) return mergeDisabledErrors.typeMismatch
  if (!mergeAllowedForSelectedType) return mergeDisabledErrors.unsupportedType
  return null
}

const MergeBatchActionMenuItem = ({ getIcon }: MergeBatchActionMenuItemProps) => {
  const numBatchItemsSelected = useSelector(batchActionsSelectors.numBatchItemsSelected)

  const { canMergeSelectedItems, mergeAllowedForSelectedType, selectedLibraryType, selectedProfilesHaveSameType } =
    useProfileMergingContext()

  const disabledTitle = getMergeDisabledTitle({
    mergeAllowedForSelectedType,
    numBatchItemsSelected,
    selectedProfilesHaveSameType,
  })

  const mergeDisabled = !canMergeSelectedItems

  const mergeItem = (
    <BatchActionMenuItem
      label="Merge"
      Icon={getIcon(BatchActionTypeEnum.MergeLibraryProfiles)}
      actions={[BatchActionTypeEnum.MergeLibraryProfiles]}
      entityType={dashboardEntityType}
      libraryEntityType={selectedLibraryType}
      disabled={!canMergeSelectedItems}
    />
  )

  return mergeDisabled && disabledTitle ? (
    <HbTooltip title={disabledTitle}>
      <div>{mergeItem}</div>
    </HbTooltip>
  ) : (
    mergeItem
  )
}

const bidSelector = (data: PerformLibraryProfilesBatchActionMutation) =>
  data.performLibraryProfilesBatchAction?.bid || null

export function ProfilesBatchActionsMenu() {
  const [performBatchAction, { loading }] = useHbMutation<
    PerformLibraryProfilesBatchActionMutation,
    PerformLibraryProfilesBatchActionMutationVariables
  >(PERFORM_BATCH_ACTION)

  const batchActionContext = useMemo(
    () => ({
      mutate: performBatchAction,
      loading,
      bidSelector,
    }),
    [loading, performBatchAction]
  )

  const { getIcon } = useBatchActionLookups()

  const classes = useStyles()

  return (
    <BatchActionContext.Provider value={batchActionContext}>
      <div className={classes.root} data-testid="profiles-batch-actions-menu">
        <MergeBatchActionMenuItem getIcon={getIcon} />
      </div>
    </BatchActionContext.Provider>
  )
}

import { useCallback } from 'react'

import { gql } from '@apollo/client'

import { useSnackbar } from 'notistack'

import { useHbMutation } from 'hooks/ApolloHelpers'

import {
  UpdateAutomationRuleNotesMutation,
  UpdateAutomationRuleNotesMutationVariables,
} from './__generated__/useUpdateAutomationNotes.generated'

export const fragment = gql`
  fragment UseUpdateAutomationNotes on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      token
      notes
    }
  }
`

const UPDATE_AUTOMATION_NOTES = gql`
  mutation UpdateAutomationRuleNotes($input: UpdateAutomationRuleNotesInput!) {
    updateAutomationRuleNotes(input: $input) {
      automationRule {
        ...UseUpdateAutomationNotes
      }
    }
  }
  ${fragment}
`

export function useUpdateAutomationNotes(automationRule: { token: string; notes: string | null; name: string | null }) {
  const { enqueueSnackbar } = useSnackbar()

  const [updateAutomationNotes, { loading: updateAutomationNotesLoading }] = useHbMutation<
    UpdateAutomationRuleNotesMutation,
    UpdateAutomationRuleNotesMutationVariables
  >(UPDATE_AUTOMATION_NOTES, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Recipe notes successfully updated', {
        variant: 'success',
        preventDuplicate: false,
        autoHideDuration: 1000,
      })
    },
    logEvent: {
      name: 'automations:updateAutomationNotes:clicked',
      data: {
        recipeName: automationRule.name || '',
      },
    },
  })

  const handleUpdateNotes = useCallback(
    (notes: string) =>
      updateAutomationNotes({
        variables: {
          input: {
            token: automationRule.token,
            notes,
          },
        },
      }),
    [updateAutomationNotes, automationRule]
  )

  return {
    updateAutomationNotes: handleUpdateNotes,
    loading: updateAutomationNotesLoading,
  }
}

import { gql } from '@apollo/client'
import { red } from '@mui/material/colors'

import ReactMapboxGl from 'react-mapbox-gl'

import ErrorBoundary from 'components/ErrorBoundary'
import { HbText } from 'components/HbComponents/Text/HbText'
import { AddressMarker } from 'components/entities/AddressMarker'
import { getGeopointBounds, MAPBOX_ACCESS_TOKEN, MAX_INITIAL_ZOOM } from 'helpers/locations'
import { DisplayAddressAddressFragment } from 'helpers/uiHelpers/__generated__/index.generated'

import 'mapbox-gl/dist/mapbox-gl.css'

import { LocationFieldIcon } from 'icons/LocationFieldIcon'
import { Geopoint } from 'types/api'

const Map = ReactMapboxGl({
  accessToken: MAPBOX_ACCESS_TOKEN,
})

type AddressMapProps = {
  addresses: (DisplayAddressAddressFragment | null)[] | null
}

export function AddressMap({ addresses }: AddressMapProps) {
  if (!addresses || addresses.length === 0) {
    return null
  }

  const geocodedAddresses = addresses.filter((address) => address && address.geopoint)
  if (geocodedAddresses.length === 0) {
    // TODO: queue a refresh for some seconds & use the loader
    // return <Loader />
    return <p>(We&apos;re currently geocoding these addresses, come back soon).</p>
  }

  const initialBounds = getGeopointBounds(
    geocodedAddresses.map((address) => address?.geopoint).filter((g) => !!g) as Geopoint[] // cannot be null due to filter
  )
  return (
    <ErrorBoundary renderError={() => <HbText color="secondary">There was an error rendering this map.</HbText>}>
      <Map
        containerStyle={{
          borderRadius: 8,
          minWidth: 200,
          maxWidth: 500,
          height: 250,
          marginBottom: 16,
        }}
        fitBounds={initialBounds}
        movingMethod="jumpTo"
        fitBoundsOptions={{ padding: 50, duration: 0, maxZoom: MAX_INITIAL_ZOOM }}
        style="mapbox://styles/mapbox/streets-v11"
      >
        {geocodedAddresses.map((address, index) => (
          <AddressMarker address={address} anchor="bottom" key={`address-${index}`}>
            <LocationFieldIcon fill={red[700]} />
          </AddressMarker>
        ))}
      </Map>
    </ErrorBoundary>
  )
}

AddressMap.fragments = {
  entry: gql`
    fragment AddressGeopoint on BaseAddress {
      geopoint {
        latitude
        longitude
      }
    }
  `,
}

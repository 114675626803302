import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import LabeledValue from 'components/HbComponents/LabeledValue'
import Markdown from 'components/utils/Markdown'
import { Theme } from 'types/hb'

const useStyles = makeStyles<Theme>((theme) => ({
  inputResponseLabel: {
    color: theme.palette.styleguide.textGreyLight,
    ...theme.typography.sm,
    fontWeight: 500,
    '& p': {
      ...theme.typography.sm,
      marginBottom: theme.spacing(),
      fontWeight: 500,
    },
    '& p:last-child': {
      marginBottom: 0,
    },
    '& ul:last-child': {
      marginBottom: 0,
    },
  },
}))

interface InputResponseProps {
  label: ReactNode
  hideLabel?: boolean
  children: ReactNode
  className?: string
}

export const InputResponse = ({ label, hideLabel, children, className }: InputResponseProps) => {
  const classes = useStyles()

  return (
    <LabeledValue
      className={className}
      testId={typeof label === 'string' ? label : undefined}
      label={
        !hideLabel &&
        (typeof label === 'string' ? <Markdown className={classes.inputResponseLabel}>{label}</Markdown> : label)
      }
    >
      {children}
    </LabeledValue>
  )
}

/**
 * Assert that some branch of code is unreachable, using `never`.
 *
 * Useful at the end of switch/case statements and if/else blocks to
 * get compiler-enforced checking that all possible values have been
 * accounted for.
 *
 * @param value The thing that should be `never`.
 * @param message The error message, optional.
 */
export const assertExhaustive = (
  value: never,
  message: string = 'Reached unexpected case: non-exhaustive type handling'
): never => {
  throw new Error(message)
}

/**
 * Assert that a property exists on an object. Useful for cases
 * where a union type means that a property may or may not exist, and
 * a type assertion is needed to disambiguate.
 *
 * Based on code from https://fettblog.eu/typescript-hasownproperty/ .
 * @param obj
 * @param prop
 */
export function assertHasOwnProperty<X extends Record<PropertyKey, unknown>, Y extends PropertyKey>(
  obj: X,
  prop: Y
): obj is X & Record<Y, unknown> {
  return Object.prototype.hasOwnProperty.call(obj, prop)
}

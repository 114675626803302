import { useState, useEffect, useRef, ReactEventHandler, forwardRef } from 'react'

import { TextField } from '@mui/material'

import classnames from 'classnames'

import AccountMenuItem from 'components/collaboration/AccountMenuItem'
import { MenuListItem } from 'components/library/Menu'

import MenuList from 'components/library/Menu/MenuList'
import { makeRequiredStyles, StyleClass } from 'components/utils/styles'
import { filterAccountsByName, stopEvent } from 'helpers/uiHelpers'
import { Account } from 'types/hb'

type FilterableAccountListClassKey = 'menu' | 'input' | 'list'
export type FilterableAccountListOverrides = StyleClass<FilterableAccountListClassKey>

interface FilterableAccountListProps {
  accounts: Account[]
  onClickItem(account: Account): unknown
  styleOverrides?: FilterableAccountListOverrides
  autoFocus?: boolean
  disabled?: boolean
  placeholder?: string
  selectable?: boolean
}

const useStyles = makeRequiredStyles<FilterableAccountListClassKey>(() => ({
  input: {},
  list: {},
  menu: {},
}))

export const FilterableAccountList = forwardRef<HTMLDivElement, FilterableAccountListProps>(
  (
    {
      accounts,
      onClickItem,
      styleOverrides,
      autoFocus = false,
      disabled = false,
      placeholder = undefined,
      selectable = true,
    }: FilterableAccountListProps,
    ref
  ) => {
    const [filter, setFilter] = useState('')
    const inputRef = useRef<HTMLInputElement | null>(null)
    const classes = { ...useStyles(), ...styleOverrides }

    useEffect(() => {
      if (autoFocus && inputRef.current) {
        inputRef.current.focus()
      }
    }, [autoFocus, inputRef])

    const clickHandlerFactory =
      (account: Account): ReactEventHandler =>
      (event) => {
        onClickItem(account)
        stopEvent(event)
      }

    return (
      <div className={classes.menu} ref={ref}>
        <MenuListItem selectable={selectable}>
          <TextField
            variant="standard"
            fullWidth
            inputRef={inputRef}
            type="text"
            className={classnames('mdc-textfield__input', classes.input)}
            placeholder={placeholder}
            onChange={({ target: { value } }) => setFilter(value)}
            value={filter}
          />
        </MenuListItem>
        <MenuList className={classes.list} separated={false}>
          {filterAccountsByName(accounts, filter).map((account) => (
            <AccountMenuItem
              key={account.token}
              disabled={disabled}
              account={account}
              onClick={clickHandlerFactory(account)}
            />
          ))}
        </MenuList>
      </div>
    )
  }
)

import React from 'react'

import { useFeatureFlag } from 'hooks'
import { FeatureFlag } from 'types/api'

interface Props {
  children?: any
  otherwise?: any
  name: FeatureFlag
}

export function Feature({ children = null, otherwise = null, name }: Props) {
  const isFeatureEnabled = useFeatureFlag(name)

  if (!isFeatureEnabled) {
    return otherwise
  }

  return children
}

export default Feature

// Branches a component for feature flag
export function flagSwitch<OldProps, NewProps>(
  flag: FeatureFlag,
  Legacy: React.ComponentType<OldProps>,
  New: React.ComponentType<NewProps>
) {
  return React.forwardRef((props: OldProps & NewProps, ref) => {
    const isFeatureEnabled = useFeatureFlag(flag)

    if (isFeatureEnabled) {
      return <New ref={ref} {...props} />
    }

    return <Legacy ref={ref} {...props} />
  })
}

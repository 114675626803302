import { useState } from 'react'

import { ButtonBase } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { DashboardExportDialog } from 'dashboards/shared/components/DashboardExport/DashboardExportDialog'
import { PollJobStatusWithTimeout } from 'dashboards/shared/components/DashboardExport/usePollExportJob'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  triggerButton: {
    width: '100%',
    justifyContent: 'flex-start',
    padding: theme.spacing(2),
    '&:hover, &:focus': {
      background: theme.palette.background.medium,
    },
    '&:disabled': {
      color: '#bababa',
    },
  },
}))

export const ExportCsvButton = ({
  closeMenu,
  startPolling,
}: {
  closeMenu: () => void
  startPolling?: PollJobStatusWithTimeout
}) => {
  const classes = useStyles()
  const [modalOpen, setModalOpen] = useState(false)

  const closeModalAndMenu = () => {
    setModalOpen(false)
    closeMenu()
  }

  return (
    <>
      <DashboardExportDialog open={modalOpen} closeModal={closeModalAndMenu} startPolling={startPolling} />
      <ButtonBase className={classes.triggerButton} onClick={() => setModalOpen(true)} autoFocus>
        Export to CSV
      </ButtonBase>
    </>
  )
}

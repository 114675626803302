import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CustomInvestigateIcon = (props: SvgProps): JSX.Element => (
  <SvgIcon {...props}>
    <g transform="translate(1.000000, 4.000000)">
      <path d="M12.4200863,7.18564434 C11.7482612,7.69669611 10.9097137,8 10,8 C7.79,8 6,6.21 6,4 C6,1.79 7.79,0 10,0 C10.9594369,0 11.8397153,0.337366008 12.5287385,0.90000128 C13.2292555,0.336927753 14.1192868,0 15.0880503,0 C17.3459119,0 19.1761006,1.83018868 19.1761006,4.08805031 C19.1761006,5.10062893 18.8050314,6.03144654 18.1886792,6.74842767 L18.3647799,6.91823899 L18.8616352,6.91823899 L22,10.0628931 L21.0628931,11 L17.918239,7.86163522 L17.918239,7.36477987 L17.7484277,7.18867925 C17.0314465,7.80503145 16.1006289,8.17610063 15.0880503,8.17610063 C14.0684724,8.17610063 13.1361028,7.80290025 12.4200863,7.18564434 Z M13.1994889,6.40177795 C13.7005727,6.77760408 14.3237316,7 15,7 C16.66,7 18,5.66 18,4 C18,2.34 16.66,1 15,1 C14.3237316,1 13.7005727,1.22239592 13.1994889,1.59822205 C13.7021692,2.26694512 14,3.09853152 14,4 C14,4.90146848 13.7021692,5.73305488 13.1994889,6.40177795 Z M10,10 C12.67,10 18,11.34 18,14 L18,16 L2,16 L2,14 C2,11.34 7.33,10 10,10 Z M12.4200863,7.18564434 C12.714614,6.96159964 12.9770986,6.69762706 13.1994889,6.40177795 C12.4705891,5.8550837 12,4.9837316 12,4 C12,3.0162684 12.4705891,2.1449163 13.1994889,1.59822205 C13.0048254,1.33925835 12.779442,1.10471817 12.5287385,0.90000128 C11.5965861,1.64926256 11,2.79895222 11,4.08805031 C11,5.32633401 11.55048,6.43598322 12.4200863,7.18564434 Z" />
    </g>
  </SvgIcon>
)

export default CustomInvestigateIcon

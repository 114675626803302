import { gql } from '@apollo/client'

const PreviewModeExecutionRowFragment = gql`
  fragment PreviewModeExecutionRow on AutomationRulePendingExecution {
    token
    createdAt
    event {
      __typename
      ... on BaseAutomationEvent {
        token
        eventableToken
        eventableHref
        domainType
      }
    }
  }
`

export const PREVIEW_MODE_TABLE_PAGINATED = gql`
  query PreviewModeTablePaginated($token: ID!, $organizationToken: ID, $pageSize: Int!, $after: String) {
    automationRule(token: $token, organizationToken: $organizationToken) {
      ... on CanonicalAutomationRuleBase {
        __typename
        token
      }
      ... on TriggerAutomationRule {
        pendingRuleExecutions(first: $pageSize, after: $after) {
          totalCount
          edges {
            node {
              ...PreviewModeExecutionRow
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
      __typename
      ... on ScheduleWithTriggerAutomationRule {
        pendingRuleExecutions(first: $pageSize, after: $after) {
          totalCount
          edges {
            node {
              ...PreviewModeExecutionRow
            }
          }
          pageInfo {
            startCursor
            endCursor
            hasNextPage
            hasPreviousPage
          }
        }
      }
    }
  }
  ${PreviewModeExecutionRowFragment}
`

import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { CheckboxInput } from 'components/HbComponents/Form/Inputs'
import { HbText } from 'components/HbComponents/Text/HbText'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  warning: {
    marginBottom: theme.spacing(2),
  },
  content: {
    display: 'block',
    paddingBottom: theme.spacing(3),
    lineHeight: '22px',
  },
}))

const Content = ({ children }: { children: ReactNode }) => {
  const classes = useStyles()
  return (
    <HbText tag="p" className={classes.content}>
      {children}
    </HbText>
  )
}

export const DashboardExportDialogContent = ({
  isLoading,
  children,
  loadingMessage = `We're working on it! We'll prepare this in the background and let you know when it's ready. We'll
  email you a download link.`,
  confirmMessage = 'This decision will export the current dashboard view into a CSV.',
}: {
  isLoading: boolean
  children?: React.ReactNode
  loadingMessage?: string
  confirmMessage?: string | null
}) => {
  const classes = useStyles()
  if (isLoading) {
    return <Content>{loadingMessage}</Content>
  }

  return (
    <>
      {confirmMessage && (
        <HbText block size="lg" className={classes.warning}>
          {confirmMessage}
        </HbText>
      )}
      {children}

      <CheckboxInput
        name="agreement"
        label="I understand that the data I am about to download may include sensitive or confidential information."
      />
    </>
  )
}

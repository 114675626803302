import { ReactNode } from 'react'

import { HbText } from 'components/HbComponents/Text/HbText'

export interface Props {
  title: ReactNode
  bold?: boolean
  size?: 'normal' | 'large'
  className?: string
}

export function SectionTitle(props: Props) {
  const { title, size = 'normal', className } = props

  return (
    <HbText className={className} size={size === 'normal' ? 'md' : 'xl'} variant="sectionHeader">
      {title}
    </HbText>
  )
}

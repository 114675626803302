import { setFlashError } from 'actions/errorActions'
import { setLoading } from 'actions/viewActions'
import { getOrganizationToken } from 'helpers/stateHelpers'
import { TagGroup } from 'reducers/organizationReducer'

import { AsyncThunk } from './store'

export const SET_TAG_GROUPS_SEARCH_RESULT = 'SET_TAG_GROUPS_SEARCH_RESULT'

export function setTagGroupsSearchResult(key: string, tagGroups: TagGroup[]) {
  return { type: SET_TAG_GROUPS_SEARCH_RESULT, key, tagGroups }
}

export function fetchTagGroups(
  key: string,
  categories: string[],
  groups: string[],
  excludeGroups: string[],
  includeOnly: string[],
  query: string
): AsyncThunk<void> {
  return async (dispatch, getState, { api }) => {
    dispatch(setLoading(true, 'orgTagGroups'))

    const requestQuery: {
      categories?: string[]
      query?: string
      includeOnly?: string[]
      groups?: string[]
      excludeGroups?: string[]
    } = {}

    if (categories?.length) {
      requestQuery.categories = categories
    }
    if (groups?.length) {
      requestQuery.groups = groups
    }
    if (excludeGroups?.length) {
      requestQuery.excludeGroups = excludeGroups
    }
    if (includeOnly?.length) {
      requestQuery.includeOnly = includeOnly
    }
    if (query) {
      requestQuery.query = query
    }

    const json = await api.get('apiOrganizationTagsPath', {
      urlParams: {
        organizationToken: getOrganizationToken(getState()),
      },
      query: requestQuery,
    })

    dispatch(setLoading(false, 'orgTagGroups'))

    if (json.success) {
      dispatch(setTagGroupsSearchResult(key, json.tagGroups))
    }

    if (json.error && json.error.message) {
      dispatch(setFlashError(json.error.message))
    }
  }
}

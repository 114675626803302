import { ReactNode, useState, useCallback, useMemo, WheelEvent, MouseEvent, useRef } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'
import { clamp } from 'lodash'

const useStyles = makeStyles({
  root: { position: 'absolute', width: '100%', height: '100%' },
})

export interface PanAndZoomUtils {
  canZoomIn: boolean
  zoomIn: () => void
  canZoomOut: boolean
  zoomOut: () => void
}

interface Props {
  containerClassName: string
  contentClassName: string
  children: ReactNode
  utilities: (utils: PanAndZoomUtils) => ReactNode
}

const MIN_SCALE = 1
const MAX_SCALE = 8
const clampScale = (scale: number) => clamp(scale, MIN_SCALE, MAX_SCALE)

type Delta = { dx: number; dy: number }

export function PanAndZoom(props: Props) {
  const { containerClassName, contentClassName, children, utilities } = props
  const { root } = useStyles()

  const container = useRef<HTMLDivElement | null>(null)
  const [scale, setScale] = useState(1)
  const [mouseActive, setMouseActive] = useState(false)
  const [{ dx, dy }, setDelta] = useState({ dx: 0, dy: 0 })
  const [{ startX, startY }, setStart] = useState({ startX: 0, startY: 0 })

  const canZoomIn = scale < MAX_SCALE
  const canZoomOut = scale > MIN_SCALE

  const clampDelta = useCallback(
    (d: Delta) => {
      const { width, height } = container.current?.getBoundingClientRect() ?? { width: 0, height: 0 }
      const padding = 150
      const scaledWidth = width * scale - padding
      const scaledHeight = height * scale - padding
      return {
        dx: clamp(d.dx, -scaledWidth, scaledWidth),
        dy: clamp(d.dy, -scaledHeight, scaledHeight),
      }
    },
    [scale]
  )

  const zoom = useCallback(
    (desiredScale: number, originX?: number, originY?: number) => {
      const newScale = clampScale(desiredScale)

      const { width, height } = container.current?.getBoundingClientRect() ?? { width: 0, height: 0 }
      if (!originX || !originY) {
        originX = width / 2 // eslint-disable-line no-param-reassign
        originY = height / 2 // eslint-disable-line no-param-reassign
      }

      const scaleRatio = newScale / scale
      setScale(newScale)
      // Scale relative to the origin position
      const mx = originX - dx
      const my = originY - dy
      setDelta(
        clampDelta({
          dx: originX - mx * scaleRatio,
          dy: originY - my * scaleRatio,
        })
      )
    },
    [clampDelta, dx, dy, scale]
  )

  const zoomIn = useCallback(() => {
    zoom(scale * 1.3)
  }, [scale, zoom])

  const zoomOut = useCallback(() => {
    zoom(scale * 0.7)
  }, [scale, zoom])

  const utils = useMemo(
    () => ({
      canZoomIn,
      zoomIn,
      canZoomOut,
      zoomOut,
    }),
    [canZoomIn, canZoomOut, zoomIn, zoomOut]
  )

  const handleWheel = useCallback(
    (event: WheelEvent) => {
      event.nativeEvent.preventDefault()
      const { clientX, clientY, deltaY } = event
      // Zoom towards the mouse pointer
      zoom(scale - deltaY * 0.01, clientX, clientY)
    },
    [scale, zoom]
  )

  const mouseDown = useCallback(
    (event: MouseEvent) => {
      // left mouse button activates panning
      if (event.button === 0) {
        event.stopPropagation()
        event.preventDefault()
        setMouseActive(true)
        setStart({ startX: event.pageX - dx, startY: event.pageY - dy })
      }
    },
    [dx, dy]
  )

  const mouseUp = useCallback(() => {
    setMouseActive(false)
  }, [])

  const mouseMove = useCallback(
    (event: MouseEvent) => {
      if (mouseActive) {
        event.stopPropagation()
        event.preventDefault()
        setDelta(clampDelta({ dx: event.pageX - startX, dy: event.pageY - startY }))
      }
    },
    [clampDelta, mouseActive, startX, startY]
  )

  return (
    <div
      ref={container}
      className={classnames(containerClassName, root)}
      role="presentation"
      onWheel={handleWheel}
      onMouseDown={mouseDown}
      onMouseMove={mouseMove}
      onMouseUp={mouseUp}
      style={{ cursor: mouseActive ? 'grabbing' : 'grab' }}
    >
      <div
        className={contentClassName}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          transform: `translate(${dx}px, ${dy}px) scale(${scale})`,
          transformOrigin: `top left`,
        }}
      >
        {children}
      </div>
      {utilities(utils)}
    </div>
  )
}

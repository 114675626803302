import { styled } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import { Option } from 'components/material/Form'
import { userInitials } from 'helpers/uiHelpers'

import { StyledAccountAvatar } from './shared'

const OptionContent = styled('div')(({ theme }) => ({
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItems: 'center',
  columnGap: theme.spacing(),
}))

const OptionAccountAvatar = styled(StyledAccountAvatar)(({ theme }) => ({
  borderRadius: theme.shape.roundedRadius,
}))

export const OptionText = styled(HbText)``

export type AssigneeOptionCustomProps = {
  avatarColor: string
  avatarVariant: number
  firstName: string
  lastName: string
}

export interface AssigneeOptionType extends Option {
  custom: AssigneeOptionCustomProps
}

interface AssigneeOptionProps {
  className?: string
  option: AssigneeOptionType
}

export const AssigneeOption = ({ className, option }: AssigneeOptionProps) => {
  return (
    <OptionContent className={className}>
      <OptionAccountAvatar color={option.custom.avatarColor} variant={Number(option.custom.avatarVariant)} size="small">
        {userInitials(option.custom)}
      </OptionAccountAvatar>
      <OptionText>{option.display}</OptionText>
    </OptionContent>
  )
}

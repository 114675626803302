import React, { ChangeEvent, useState } from 'react'

import { gql, useQuery } from '@apollo/client'
// eslint-disable-next-line no-restricted-imports

import { MenuItem, Select, styled } from '@mui/material'

import { useFieldArray } from 'react-hook-form'

import { GQLError } from 'components/GQLError'
import { InputContainer } from 'components/HbComponents/Form/Inputs'
import { HbButton } from 'components/HbComponents/HbButton'
import { HbText } from 'components/HbComponents/Text/HbText'

import Loader from 'components/library/Loader'

import OtherInfoLabelInput from 'components/library/OtherInfoLabelInput'

import { CustomFieldLabelRowFragment } from 'components/otherInfo/__generated__/queries.generated'
import { AddIcon } from 'icons'
import { OtherInfoLabelTypeEnum, UniversalInterpreterTypeEnum } from 'types/api'

import {
  DataImportActionDataSourceQuery,
  DataImportActionDataSourceQueryVariables,
} from './__generated__/CustomizeDataImportAction.generated'

import type { FormSchemaReturnType } from '../formSchema'

const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(),
}))

const MappingsContainer = styled('div')(({ theme }) => ({
  display: 'grid',
  gap: theme.spacing(),
  gridTemplateColumns: '1fr 1fr',
}))

const DATASOURCE_QUERY = gql`
  query DataImportActionDataSource($token: ID!) {
    datasource(token: $token) {
      token
      name
      columns {
        token
        name
        type
      }
      datatable {
        token
        location
        filterClauseColumn {
          token
          name
        }
      }
    }
    otherInfoLabelSearch(labelType: person, query: null) {
      token
      label
    }
  }
`

interface CustomFieldInputProps {
  initialValue: string
  onChange: (token: string) => void
}

function CustomFieldInput({ initialValue, onChange }: CustomFieldInputProps) {
  const [value, setValue] = useState(initialValue)

  const handleChange = (newValue: string, labelData?: CustomFieldLabelRowFragment) => {
    setValue(newValue)
    if (labelData) {
      onChange(labelData.token)
    }
  }

  return (
    <OtherInfoLabelInput
      size="small"
      label="Map to"
      type={OtherInfoLabelTypeEnum.Person}
      value={value}
      onChange={handleChange}
    />
  )
}

const CREATE_DATA_IMPORT_PARAMS_PATH = 'actionParams.dataImportParams'

export default function CustomizeDataImportAction({ form }: { form: FormSchemaReturnType }) {
  const { setValue, control, watch } = form

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: `${CREATE_DATA_IMPORT_PARAMS_PATH}.fieldMappings`,
  })

  const interpreterType = watch(`${CREATE_DATA_IMPORT_PARAMS_PATH}.interpreterType`)

  const datasourceToken = watch(`domain.datasourceToken`)
  const { data, loading, error } = useQuery<DataImportActionDataSourceQuery, DataImportActionDataSourceQueryVariables>(
    DATASOURCE_QUERY,
    {
      variables: { token: datasourceToken ?? '' },
      fetchPolicy: 'cache-first',
    }
  )

  const getHydraColumnName = (hydraField: string) => {
    // TODO hardcoded to 'person' for CRM, need a more elaborate UI for full field mapping
    return `${interpreterType === UniversalInterpreterTypeEnum.Case ? 'case' : 'person'} ${hydraField}`
  }

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <GQLError error={error} />
  }

  const datasource = data?.datasource
  if (!datasource) {
    return null
  }

  const columns = data?.datasource.columns

  // TODO hack,to be proper we should retrieve more label info when fetching the automation action (not only the token),
  // but this messes with how automation forms are set up at the moment
  const otherInfoLabels = data?.otherInfoLabelSearch || []

  const externalIdField = fields[0]

  return (
    <Root>
      <InputContainer
        htmlFor="interpreterType"
        label={
          <HbText color="secondary">
            Import <HbText bold>{datasource.name}</HbText> data to
          </HbText>
        }
      >
        <Select
          size="small"
          required
          variant="outlined"
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            const newType = e.target.value as unknown as UniversalInterpreterTypeEnum
            setValue(`${CREATE_DATA_IMPORT_PARAMS_PATH}.interpreterType`, newType)

            if (newType !== interpreterType) {
              // Clear field mappings
              remove()

              // Add external ID field and one mapping
              if (newType === UniversalInterpreterTypeEnum.Crm) {
                append({ datasourceColumnToken: '', hydraColumnName: '' })
              }
            }
          }}
          value={interpreterType}
        >
          <MenuItem key="case" value={UniversalInterpreterTypeEnum.Case}>
            Case
          </MenuItem>
          <MenuItem key="crm" value={UniversalInterpreterTypeEnum.Crm}>
            CRM
          </MenuItem>
        </Select>
      </InputContainer>

      {interpreterType === UniversalInterpreterTypeEnum.Crm && (
        <>
          <InputContainer htmlFor="externalIdColumn" label="External ID Column">
            <Select
              size="small"
              required
              variant="outlined"
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                update(0, {
                  ...externalIdField,
                  datasourceColumnToken: e.target.value,
                  hydraColumnName: getHydraColumnName('id'),
                })
              }}
              value={externalIdField?.datasourceColumnToken}
            >
              {columns.map((column) => (
                <MenuItem key={column.token} value={column.token}>
                  {column.name}
                </MenuItem>
              ))}
            </Select>
          </InputContainer>

          <HbText color="secondary">
            Map columns to <HbText bold>Custom Fields</HbText>
          </HbText>
          <MappingsContainer>
            {fields.map((field, index) => {
              // Skip external ID field
              if (index === 0) {
                return null
              }

              return (
                <React.Fragment key={field.id}>
                  <InputContainer htmlFor="column" label="Column">
                    <Select
                      size="small"
                      required
                      variant="outlined"
                      onChange={(e: ChangeEvent<HTMLInputElement>) => {
                        update(index, {
                          ...field,
                          datasourceColumnToken: e.target.value,
                          hydraColumnName: getHydraColumnName('other info'),
                        })
                      }}
                      value={field.datasourceColumnToken}
                    >
                      {columns.map((column) => (
                        <MenuItem key={column.token} value={column.token}>
                          {column.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </InputContainer>

                  <CustomFieldInput
                    initialValue={
                      otherInfoLabels.find((label) => label.token === field.otherInfoLabelToken)?.label || ''
                    }
                    onChange={(token: string) => {
                      update(index, { ...field, otherInfoLabelToken: token })
                    }}
                  />
                </React.Fragment>
              )
            })}
          </MappingsContainer>
          <div>
            <HbButton
              Icon={AddIcon}
              label="Add field"
              size="small"
              onClick={() => append({ datasourceColumnToken: '', otherInfoLabelToken: '', hydraColumnName: '' })}
            />
          </div>
        </>
      )}
    </Root>
  )
}

import { useContext } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { useSelector } from 'actions/store'
import { DashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { ArrowDownwardIcon, ArrowUpwardIcon, FilteredListIcon, FilterListIcon } from 'icons'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
  },
  superScript: {
    ...theme.typography.sizes.xs,
  },
}))

interface Props {
  className?: string
  iconRef: any
  label: string
  filtering?: boolean
  filterEnabled?: boolean
  direction?: 'asc' | 'desc'
  directionIndex?: number
}

function FilterControl(props: Props & { sortLength: number }) {
  const {
    className,
    iconRef,
    filtering,
    label,
    direction,
    directionIndex = -1,
    filterEnabled = true,
    sortLength,
  } = props
  const classes = useStyles()

  const showIndex = directionIndex && sortLength > 1

  if (filtering === undefined) {
    return null
  }

  return (
    <div
      aria-label={
        filterEnabled
          ? `Open ${label} filters`
          : `Change ${label} sort to ${direction === 'asc' ? 'descending' : 'ascending'}`
      }
      data-testid={`sort:${label}:${directionIndex}:${direction}`}
      className={classnames(classes.root, className)}
      ref={iconRef}
    >
      {direction === 'asc' ? (
        <>
          <ArrowUpwardIcon />
          {showIndex && <sup className={classes.superScript}>{directionIndex}</sup>}
        </>
      ) : direction === 'desc' ? (
        <>
          <ArrowDownwardIcon />
          {showIndex && <sup className={classes.superScript}>{directionIndex}</sup>}
        </>
      ) : null}
      {filterEnabled ? filtering ? <FilteredListIcon /> : <FilterListIcon /> : null}
    </div>
  )
}

const DashboardFilterControl = (props: Props) => {
  const dashboardsSelectors = useDashboardSelectors()
  const sortLength = useSelector(dashboardsSelectors.sorts.valid)?.length ?? 0
  return <FilterControl {...props} sortLength={sortLength} />
}
const NonDashboardFilterControl = (props: Props) => {
  return <FilterControl {...props} sortLength={0} />
}

const FilterControlDashboardContextGuard = (props: Props) => {
  const isInDashboardContext = !!useContext(DashboardContext)
  const Component = isInDashboardContext ? DashboardFilterControl : NonDashboardFilterControl
  return <Component {...props} />
}

export default FilterControlDashboardContextGuard

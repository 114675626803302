import { useMemo } from 'react'

import { createBrowserHistory } from 'history'
import { parse, stringify } from 'query-string'
import { useLocation } from 'react-router-dom'

import { deserializeQuery, serializeQuery } from 'utils/query.serializer'
import { HBQueryString } from 'utils/query.types'

const history = createBrowserHistory()

export default history

export function replace(pathname: string, query: Record<string, unknown> = {}) {
  const search = stringify(query)

  history.replace({
    pathname,
    search,
  })
}

export function push(pathname: string, query: Record<string, unknown> = {}) {
  const search = stringify(query)

  history.push({
    pathname,
    search,
  })
}

export const updateQuery =
  <Slice extends keyof HBQueryString>(slice: Slice) =>
  (query: Partial<HBQueryString[Slice]>) => {
    let tabInfo
    if (slice === 'case') {
      // pull out pathname from the query string
      const { pathname, ...rest } = (query as HBQueryString['case'])?.tabInfo ?? {}
      tabInfo = rest
    } else {
      tabInfo = undefined
    }

    const search = serializeQuery(slice)({ ...query, tabInfo })
    history.replace({
      pathname: history.location.pathname,
      search,
    })
  }

const readQueryValue = <Slice extends keyof HBQueryString>(
  slice: Slice,
  search: Location['search'] = window.location.search
) => {
  let query: HBQueryString[Slice] | undefined
  try {
    query = deserializeQuery(slice)(search)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('[Query string read error]', e)
  }
  return query
}

export const readQuery = <Slice extends keyof HBQueryString>(slice: Slice): HBQueryString[Slice] | undefined => {
  const query = readQueryValue(slice)
  if (!query) return undefined
  return query
}

/**
 * A hook for the `readQuery` utility above,
 * which re-samples values based on updates to the search string returned by useLocation
 */
export function useReadQuery<Slice extends keyof HBQueryString>(slice: Slice): HBQueryString[Slice] | undefined {
  const { search } = useLocation()
  const query = useMemo(() => readQueryValue(slice, search), [search, slice])

  if (!query) return undefined

  return query
}

export function useParsedQuery<T = unknown>(): T {
  const { search } = useLocation()

  return useMemo(() => parse(search), [search]) as any as T
}

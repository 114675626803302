import { ComponentType, FC, useRef, useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton, HbButtonSizes, HbButtonVariants } from 'components/HbComponents/HbButton'
import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import { Menu, MenuList, MenuListItem } from 'components/library/Menu'
import { remToPx } from 'helpers/themeHelpers'
import { useToggle } from 'hooks'
import { BatchActionTypeEnum } from 'types/api'
import { Theme } from 'types/hb'

import { useBatchActionLookups } from './useBatchActionLookups'

const useStyles = makeStyles((theme: Theme) => ({
  menuItem: {
    color: theme.palette.text.white,
  },
}))

export const BatchActionMenuItem: FC<{
  label?: string
  Icon?: ComponentType<{ className?: string }>
  actions: [BatchActionTypeEnum, ...BatchActionTypeEnum[]]
  disabled?: boolean
  variant?: HbButtonVariants
  entityType: 'review' | 'investigation' | 'transaction' | 'profile'
  handleSetCurrentAction?: (currentAction: BatchActionTypeEnum | undefined) => void
  libraryEntityType?: LibraryGqlQueryType
  libraryEntityToken?: string
  size?: HbButtonSizes
}> = (props) => {
  const {
    label,
    Icon,
    actions,
    disabled = false,
    variant,
    entityType,
    handleSetCurrentAction,
    libraryEntityType,
    libraryEntityToken,
    size = 'medium',
  } = props

  const menu = useToggle(false)
  const menuTrigger = useRef<HTMLButtonElement>(null)
  const classes = useStyles()
  const [currentAction, setCurrentAction] = useState<BatchActionTypeEnum>()
  const { getDialog, getLabel } = useBatchActionLookups()
  const Dialog = currentAction ? getDialog(currentAction) : null

  const singleAction = actions?.length === 1

  const updateCurrentAction = (action: BatchActionTypeEnum | undefined) => {
    setCurrentAction(action)
    handleSetCurrentAction?.(action)
  }

  return (
    <>
      <HbButton
        variant={variant || (disabled ? 'textPrimary' : 'textSecondary')}
        label={label ?? getLabel(actions[0])}
        ref={menuTrigger}
        className={classes.menuItem}
        size={size}
        onClick={() => {
          menu.toggle()
          if (singleAction) updateCurrentAction(actions[0])
        }}
        Icon={Icon}
        dropdownCaret={!singleAction}
        disabled={disabled}
      />
      {!singleAction && (
        <Menu
          trigger={menuTrigger.current}
          open={menu.value}
          onClose={() => {
            menu.setValue(false)
          }}
          popperMenu
          popperProps={{
            modifiers: {
              flip: { enabled: false },
              inner: { enabled: true },
              offset: { offset: `0, ${remToPx(1)}` },
            },
          }}
          placement="top-start"
        >
          <MenuList>
            {actions.map((action) => (
              <MenuListItem
                key={action}
                testId={`menu_item_${action}`}
                onClick={() => {
                  menu.setValue(false)
                  updateCurrentAction(action)
                }}
              >
                {getLabel(action)}
              </MenuListItem>
            ))}
          </MenuList>
        </Menu>
      )}
      {!!Dialog && (
        <Dialog
          libraryEntityType={libraryEntityType}
          libraryEntityToken={libraryEntityToken}
          entityType={entityType}
          open
          onClose={() => {
            updateCurrentAction(undefined)
          }}
        />
      )}
    </>
  )
}

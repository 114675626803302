import { Formik, FormikHelpers, FormikValues } from 'formik'

import Step, { Props as StepProps } from './Step'

interface Props<Values = unknown> extends Omit<StepProps, 'onNext'> {
  initialValues?: Values
  onNext: (values: Values, helpers: FormikHelpers<Values>) => void
  validationSchema: unknown
}

/*
 * Wrapper component around `Step` for implementing onboarding steps that require form
 * submission. Leverages `formik`
 */
export default function FormStep<Values extends FormikValues>({
  initialValues = {} as Values,
  onNext,
  validationSchema,
  ...stepProps
}: Props<Values>) {
  return (
    <Formik<Values>
      initialValues={initialValues}
      onSubmit={(...formikArgs) => onNext(...formikArgs)}
      render={({ handleSubmit }) => <Step onNext={handleSubmit} {...stepProps} />}
      validationSchema={validationSchema}
      validateOnBlur={false}
    />
  )
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const SortIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.391 2.891a.625.625 0 0 1 .884 0L9.61 6.225a.625.625 0 1 1-.884.884L6.458 4.842v8.491a.625.625 0 1 1-1.25 0v-8.49L2.942 7.108a.625.625 0 1 1-.884-.884L5.391 2.89Zm8.776 3.15c.345 0 .625.28.625.626v8.49l2.266-2.266a.625.625 0 1 1 .884.884l-3.333 3.334a.625.625 0 0 1-.884 0l-3.334-3.334a.625.625 0 1 1 .884-.884l2.267 2.267V6.667c0-.346.28-.625.625-.625Z"
      clipRule="evenodd"
    />
  </SvgIcon>
)

export default SortIcon

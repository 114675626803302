import { ApprovalAssignmentDetails, ApprovalAssignmentWithAssignee, ApprovalAssignmentWithQueue } from './types'

export const hasAssignee = (assignment: ApprovalAssignmentDetails): assignment is ApprovalAssignmentWithAssignee =>
  !!assignment.assignee

export const hasQueue = (assignment: ApprovalAssignmentDetails): assignment is ApprovalAssignmentWithQueue =>
  !!assignment.queue

export const getAssignmentsOverflow = <ApprovalAssignment extends ApprovalAssignmentDetails>({
  assignments,
  limit,
}: {
  assignments: ApprovalAssignment[]
  limit: number
}) => {
  // only classify multiple items as overflowing,
  // since if there is only one extra item
  // it can take the spot of the overflow placeholder avatar
  const hasOverflow = assignments.length - limit > 1
  const actualLimit = hasOverflow ? limit : limit + 1
  const overflowAssignments = assignments.slice(actualLimit)
  const visibleAssignments = assignments.slice(0, actualLimit)

  return {
    hasOverflow,
    overflowAssignments,
    visibleAssignments,
  }
}

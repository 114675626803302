import type * as Types from '../../types/api'

import type { StrictLogicalExpression } from 'types/automations'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type GetOrganizationTagsQueryVariables = Types.Exact<{ [key: string]: never }>

export type GetOrganizationTagsQuery = {
  __typename?: 'Query'
  currentOrganization: {
    __typename?: 'Organization'
    tagDefinitions: Array<{ __typename?: 'LibraryTagDefinition'; token: string; label: string }>
  }
}

export const GetOrganizationTagsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'GetOrganizationTags' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'currentOrganization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'tagDefinitions' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'label' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetOrganizationTagsQuery, GetOrganizationTagsQueryVariables>

import { useCallback, useEffect } from 'react'

import { gql, useLazyQuery } from '@apollo/client'

import { navigateToInvestigation } from 'actions/investigationsActions'

import { useDispatch } from 'actions/store'

import {
  GetMergedCaseTokenQuery,
  GetMergedCaseTokenQueryVariables,
} from './__generated__/UseRedirectToMergedCaseIfOnCase.generated'

export const GET_MERGED_CASE_TOKEN = gql`
  query GetMergedCaseToken($caseToken: String!) {
    investigation(token: $caseToken) {
      token
    }
  }
`

export const useRedirectToMergedCaseIfOnCase = (caseToken: string | undefined) => {
  const dispatch = useDispatch()
  const [loadMergeInvestigationToken, { data }] = useLazyQuery<
    GetMergedCaseTokenQuery,
    GetMergedCaseTokenQueryVariables
  >(GET_MERGED_CASE_TOKEN, {
    variables: { caseToken: caseToken || '' },
    fetchPolicy: 'network-only',
  })

  const redirectToMergedCase = useCallback(() => {
    if (caseToken) {
      loadMergeInvestigationToken()
    }
  }, [caseToken, loadMergeInvestigationToken])

  useEffect(() => {
    if (caseToken && data?.investigation?.token && caseToken !== data.investigation.token) {
      dispatch(navigateToInvestigation(undefined, data.investigation.token))
    }
  }, [data, caseToken, dispatch])
  return redirectToMergedCase
}

import React, { MouseEventHandler, ReactNode, useMemo, useRef } from 'react'

import { AllInbox, CheckRounded } from '@mui/icons-material'
import { Divider, useTheme } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import * as Sentry from '@sentry/react'

import { useDispatch, useSelector } from 'actions/store'
import { useDashboardUserSettingsActions } from 'actions/userSettingsActions'
import { HbButton, HbButtonSizes } from 'components/HbComponents/HbButton'
import { HbPopper } from 'components/HbComponents/HbPopper'
import { HbText } from 'components/HbComponents/Text/HbText'
import { QueryName } from 'dashboards/shared/components/Dashboard/NamedQueryGroups.types'
import { useFetchDashboardCustomViews } from 'dashboards/shared/hooks/useDashboardColumns'
import { useDashboardConfig } from 'dashboards/shared/react/dashboards.config'
import { QuerySubList } from 'dashboards/shared/react/queryGroups/types'
import { useToggle } from 'hooks'
import { FilterListIcon } from 'icons'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'
import { useDashboardSelectors } from 'reducers/dashboards/dashboards.selectors'
import { SavedSearchRequest } from 'types/api'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  popover: { minWidth: 240, maxWidth: 300 },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(),
    paddingBottom: 0,
  },
  listItem: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1, 2),
    marginBottom: theme.spacing(),
  },
  title: {
    flex: 1,
  },
  customViewTitle: { padding: theme.spacing() },
  listSeparator: { marginBottom: theme.spacing() },
}))

const SelectorLabel = ({ isSelected, filterTitle }: { isSelected: boolean; filterTitle: string }) => (
  <>
    <HbText className={useStyles().title}>{filterTitle}</HbText>
    {isSelected && <CheckRounded />}
  </>
)

const SelectorButton = ({
  filterTitle,
  currentFilterTitle,
  onClick,
  icon,
}: {
  filterTitle: string
  currentFilterTitle: string
  onClick?: MouseEventHandler<HTMLElement>
  icon?: React.ComponentType<{ className?: string }>
}) => (
  <HbButton
    className={useStyles().listItem}
    onClick={onClick}
    Icon={icon}
    variant="textSecondary"
    testId={`Select ${filterTitle}`}
    label={<SelectorLabel isSelected={currentFilterTitle === filterTitle} filterTitle={filterTitle} />}
  />
)

interface ViewSelectorProps<T extends string> {
  additionalMenuItems?: ReactNode
  currentFilterTitle: string
  customViews?: SavedSearchRequest[]
  handleCustomFilterSelect?: (view: SavedSearchRequest) => void
  handleFilterSelect: (filterName: T) => void
  isCustomSelected?: boolean
  isDisabled?: boolean
  size?: HbButtonSizes
  viewLists: QuerySubList<T>[]
}

export const ViewSelector = <T extends string>({
  additionalMenuItems,
  currentFilterTitle,
  customViews,
  handleCustomFilterSelect,
  handleFilterSelect,
  isCustomSelected = false,
  isDisabled = false,
  size = 'small',
  viewLists,
}: ViewSelectorProps<T>) => {
  const theme = useTheme()
  const classes = useStyles()
  const ref = useRef<HTMLButtonElement>(null)

  const { value: open, toggle: toggleOpen, setValue: setOpen } = useToggle(false)
  const handleClose = () => setOpen(false)

  const onFilterSelect = (filterName: T) => {
    handleClose()
    handleFilterSelect(filterName)
  }

  const onCustomFilterSelect = (view: SavedSearchRequest) => {
    handleClose()
    handleCustomFilterSelect?.(view)
  }

  const sortedCustomViews = useMemo(
    () => customViews?.slice().sort((a, b) => a.name.localeCompare(b.name)),
    [customViews]
  )

  return (
    <>
      <HbButton
        ref={ref}
        label={currentFilterTitle}
        variant="secondary"
        size={size}
        onClick={!isDisabled ? toggleOpen : undefined}
        Icon={isCustomSelected ? FilterListIcon : AllInbox}
        dropdownCaret
        disabled={isDisabled}
      />
      <HbPopper
        id="dashboard-view-selector-popper"
        classes={{ content: classes.popover }}
        anchorEl={ref.current}
        isOpen={!isDisabled ? open : false}
        onClose={handleClose}
        placement="bottom-start"
        zIndex={theme.zIndex.modal}
      >
        <div className={classes.list}>
          {viewLists.map((viewList, index, viewListsArray) => {
            const isLast = index === viewListsArray.length - 1
            return (
              <React.Fragment key={viewList.key}>
                {viewList.title && (
                  <HbText variant="sectionHeader" className={classes.listItem}>
                    {viewList.title}
                  </HbText>
                )}
                {viewList.queries.map((filter) => (
                  <SelectorButton
                    key={filter.name}
                    filterTitle={filter.title}
                    currentFilterTitle={currentFilterTitle}
                    icon={filter.Icon}
                    onClick={() => onFilterSelect(filter.name)}
                  />
                ))}
                {!isLast && <Divider className={classes.listSeparator} />}
              </React.Fragment>
            )
          })}
        </div>

        {sortedCustomViews && sortedCustomViews.length > 0 && (
          <>
            <Divider />

            <div className={classes.list}>
              <HbText variant="sectionHeader" className={classes.listItem}>
                CUSTOM VIEWS
              </HbText>
              {sortedCustomViews?.map((view) => (
                <SelectorButton
                  key={view.token}
                  filterTitle={view.name}
                  currentFilterTitle={currentFilterTitle}
                  icon={FilterListIcon}
                  onClick={() => onCustomFilterSelect(view)}
                />
              ))}
            </div>
          </>
        )}

        {additionalMenuItems}
      </HbPopper>
    </>
  )
}

interface Props {
  size?: HbButtonSizes
}

export function DashboardViewSelector({ size = 'small' }: Props) {
  const { updateDashboardUserSettings, setDashboardUserSettings } = useDashboardUserSettingsActions()

  const dashboardConfig = useDashboardConfig()
  const dispatch = useDispatch()
  const { filters: filtersConfig, filterControls } = dashboardConfig
  const { ExportAction } = filtersConfig
  const { isFiltersEnabled } = filterControls

  const dashboardsSelectors = useDashboardSelectors()
  const dashboardsActions = useDashboardActions()
  const viewLists = useSelector(dashboardsSelectors.getNamedQueryLists)
  const defaultViews = useSelector(dashboardsSelectors.namedQueries)
  const currentFilterTitle = useSelector(dashboardsSelectors.currentViewTitle)
  const isCustomSelected = !!useSelector(dashboardsSelectors.currentViewToken)

  const customViews = useFetchDashboardCustomViews()

  const onFilterSelect = (name: QueryName<DashboardSlice>) => {
    const filter = defaultViews.find((f) => f.name === name)
    if (!filter) {
      Sentry.withScope((scope) => {
        scope.setExtra('name', name)
        scope.setExtra('namedQueries', defaultViews)
        Sentry.captureMessage(`Cannot find default view with name: ${name}!`)
      })
      return
    }

    const { request, title } = filter

    dispatch(dashboardsActions.filters.changeView.action({ ...request, title }))

    dispatch(
      setDashboardUserSettings({
        recentDashboardView: name,
      })
    )
  }

  const onCustomFilterSelect = ({ groups, filters, name, sorts, query }: SavedSearchRequest) => {
    dispatch(dashboardsActions.filters.changeView.action({ sorts, groups, filters, query, title: name }))
    dispatch(
      updateDashboardUserSettings({
        recentDashboardView: name,
      })
    )
  }

  const classes = useStyles()

  return (
    <ViewSelector
      additionalMenuItems={
        ExportAction && (
          <>
            <Divider />
            <div className={classes.list}>
              <ExportAction className={classes.listItem} />
            </div>
          </>
        )
      }
      currentFilterTitle={currentFilterTitle}
      customViews={customViews}
      handleFilterSelect={onFilterSelect}
      handleCustomFilterSelect={onCustomFilterSelect}
      isCustomSelected={isCustomSelected}
      isDisabled={!isFiltersEnabled}
      size={size}
      viewLists={viewLists}
    />
  )
}

import { useField } from 'formik'
import { isNil } from 'lodash'

import { getInputTestId } from 'components/HbComponents/Form/Inputs/getInputTestId'

import { CheckboxWithLabel } from '../HbCheckbox'
import { useHbFormikContext } from '../useHbFormikContext'

import { InputContainer } from './InputContainer'
import { InputResponse } from './InputResponse'
import { InputProps } from './InputTypes'
import { useIsErroneous } from './useIsErroneous'

export const CheckboxInput = (props: InputProps) => {
  const { readOnly, label, name, disabled, errors, autosave, isErroneousChecker, testId } = props

  const [field, meta] = useField({ name, type: 'checkbox' })
  const form = useHbFormikContext({ autosave })
  const { isSubmitting } = form
  const { isErroneous, apiErrors, clientError } = useIsErroneous({ name, autosave, errors, isErroneousChecker })
  const inputTestId = getInputTestId(label, testId)

  if (readOnly) {
    return (
      <InputResponse label={label}>
        {isNil(field.value) && '-'}
        {field.value === true && 'Yes'}
        {field.value === false && 'No'}
      </InputResponse>
    )
  }

  return (
    <InputContainer
      isErroneous={isErroneous}
      apiError={apiErrors}
      clientError={clientError}
      htmlFor={name}
      testId={inputTestId}
    >
      <CheckboxWithLabel
        type="checkbox"
        indeterminate={false}
        field={field}
        form={form}
        meta={meta}
        Label={{
          label,
        }}
        data-testid="survey_checkbox"
        disabled={disabled || isSubmitting}
        color="primary"
        uncheckedBackgroundColor="contrastMedium"
        error={isErroneous}
      />
    </InputContainer>
  )
}

import { gql } from '@apollo/client'

export const TAG_FRAGMENT = gql`
  fragment ObjectTag on Tag {
    token
    tagDefinition {
      token
      label
      color
      archived
    }
  }
`

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const CloseThinIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 9 9">
    <polygon points="8.85 0.85 8.15 0.15 4.5 3.79 0.85 0.15 0.15 0.85 3.79 4.5 0.15 8.15 0.85 8.85 4.5 5.21 8.15 8.85 8.85 8.15 5.21 4.5 8.85 0.85" />
  </SvgIcon>
)

export default CloseThinIcon

// Disabling this since we want to assign to state in reducers
/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State } from 'actions/store'

// TODO (sky): PROD-13410 -- replace with SectionTypeEnum
export type SubjectType = 'institutions' | 'people' | 'businesses' | 'products' | 'other'

type SubjectsSectionsExpanded = Record<SubjectType, boolean>

interface SectionPaginationProps {
  page: number
}

type SubjectsSectionPages = Partial<Record<SubjectType, SectionPaginationProps>>

export type Exclusion = { type: string; token: string; library?: boolean }
export type ExclusionIndex = Map<string, Exclusion>

export interface SubjectsSectionState {
  animating: boolean
  expandedCards: SubjectsSectionsExpanded
  exclusionIndex: ExclusionIndex | null
  isResetting?: boolean
  lastKnownExpandedCards: SubjectsSectionsExpanded
  lastKnownPages: SubjectsSectionPages
  searchTerm: string
}

export interface SubjectsFiltersState {
  bySection: Record<string, SubjectsSectionState>
}

export const defaultInitialFilteringExpandedState: SubjectsSectionsExpanded = {
  institutions: true,
  people: true,
  businesses: true,
  products: true,
  other: true,
}

export const defaultInitialSectionState: SubjectsSectionState = {
  animating: true,
  // tracks
  lastKnownPages: {
    institutions: { page: 1 },
    people: { page: 1 },
    businesses: { page: 1 },
    products: { page: 1 },
    other: { page: 1 },
  },
  // tracks which subject type sections are currently expanded
  expandedCards: {
    institutions: false,
    people: false,
    businesses: false,
    products: false,
    other: false,
  },
  exclusionIndex: null,
  // differentiate between closing a tab and unmounting it
  isResetting: false,
  // tracks which subject type sections were expanded last before a search filter was applied
  lastKnownExpandedCards: {
    institutions: false,
    people: false,
    businesses: false,
    products: false,
    other: false,
  },
  searchTerm: '',
}

export const getSectionFilterState = (state: State, uiSection: string): SubjectsSectionState | undefined =>
  state.subjectsFilters.bySection[uiSection]

const subjectsFiltersSlice = createSlice({
  name: 'subjectsFilters',
  initialState: {
    bySection: {},
  } as SubjectsFiltersState,
  reducers: {
    // initialize a new subjects section filter state
    initSection(state, action: PayloadAction<{ initialState?: SubjectsSectionState; uiSection: string }>) {
      const { uiSection, initialState = defaultInitialSectionState } = action.payload
      const sectionState = state.bySection[uiSection]
      // ignore if the section state has already been initialized
      if (sectionState) return
      state.bySection[uiSection] = initialState
    },
    // removes the specified subjects section from state
    // e.g. when a review tab is closed
    teardownSection(state, action: PayloadAction<{ uiSection: string }>) {
      const { uiSection } = action.payload
      delete state.bySection[uiSection]
    },
    setSearchTerm(
      state,
      action: PayloadAction<{
        searchTerm: string
        initialFilteringExpandedState?: SubjectsSectionsExpanded
        uiSection: string
      }>
    ) {
      const {
        searchTerm,
        uiSection,
        initialFilteringExpandedState = defaultInitialFilteringExpandedState,
      } = action.payload
      const isSearching = !!searchTerm
      const initialSearch = searchTerm.length === 1

      const sectionState = state.bySection[uiSection]
      // ignore if the section state has been removed or not initialized yet
      if (!sectionState) return

      const { lastKnownExpandedCards: _lastKnown, expandedCards: _current } = sectionState

      sectionState.searchTerm = searchTerm
      // should not animate when initially searching,
      // so available results are immediately visible
      sectionState.animating = !initialSearch
      // expands sections while filtering or restores to last user-driven state
      sectionState.expandedCards = isSearching ? initialFilteringExpandedState : _lastKnown
      // tracks last user-driven state
      sectionState.lastKnownExpandedCards = initialSearch ? _current : _lastKnown
    },
    setSectionExpanded(
      state,
      action: PayloadAction<{ expanded: boolean; subjectType: SubjectType; uiSection: string }>
    ) {
      const { expanded, subjectType, uiSection } = action.payload
      const sectionState = state.bySection[uiSection]
      // ignore if the section state has been removed or not initialized yet
      if (!sectionState) return
      sectionState.expandedCards[subjectType] = expanded
    },
    // determine whether or not to show section collapse animations, which can be jarring if not used sparingly
    setCardsAnimating(state, action: PayloadAction<{ animating: boolean; uiSection: string }>) {
      const { animating, uiSection } = action.payload
      const sectionState = state.bySection[uiSection]
      // ignore if the section state has been removed or not initialized yet
      if (!sectionState) return
      sectionState.animating = animating
    },
    // cache the page since that state is currently local and may be lost when pinning/switching tabs
    setLastKnownPages(state, action: PayloadAction<{ lastKnownPages: SubjectsSectionPages; uiSection: string }>) {
      const { lastKnownPages, uiSection } = action.payload
      const sectionState = state.bySection[uiSection]
      // ignore if the section state has been removed or not initialized yet
      if (!sectionState) return
      sectionState.lastKnownPages = lastKnownPages
    },
  },
})

export const subjectsFiltersReducer = subjectsFiltersSlice.reducer
export const { initSection, teardownSection, setSearchTerm, setSectionExpanded, setCardsAnimating, setLastKnownPages } =
  subjectsFiltersSlice.actions

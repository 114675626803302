/**
 * This file implements a parser for the liquid language service. A parser takes a stream of tokens from the lexer and
 * turns them into a concrete syntax tree. The parser is also capable to providing candidate tokens which should come
 * next, which is useful for providing completion suggestions.
 *
 * The way the parser works is that there are rules which define what patterns of tokens compose various structures of
 * the concrete syntax tree. The parser is able to try multiple paths unitl it finds that that works, and if it doesn't
 * find a path that works it can report syntax errors and otherwise recover from errors in order to provide diagnostic
 * feedback we can render in the editor.
 */

import { CstParser } from 'chevrotain'

import lexer, {
  BooleanToken,
  ControlAssign,
  ControlBreak,
  ControlCapture,
  ControlCase,
  ControlComment,
  ControlContinue,
  ControlCycle,
  ControlDecrement,
  ControlElse,
  ControlElsif,
  ControlEndcapture,
  ControlEndcase,
  ControlEndcomment,
  ControlEndfor,
  ControlEndif,
  ControlEndraw,
  ControlEndtablerow,
  ControlEndunless,
  ControlFor,
  ControlIf,
  ControlIn,
  ControlIncrement,
  ControlRaw,
  ControlTablerow,
  ControlUnless,
  ControlWhen,
  ControlLiquid,
  ControlEcho,
  Identifier,
  InlineComment,
  NumberToken,
  ObjectEnd,
  ObjectStart,
  OperatorComma,
  OperatorColon,
  OperatorEqual,
  OperatorNotEqual,
  OperatorGreaterThan,
  OperatorLessThan,
  OperatorGreaterThanEqual,
  OperatorLessThanEqual,
  OperatorAssign,
  OperatorOr,
  OperatorAnd,
  OperatorContains,
  OperatorAs,
  Pipe,
  RangeToken,
  StringToken,
  TagEnd,
  TagStart,
  TextToken,
  allTokens,
  ControlRender,
  OperatorWith,
} from './lexer'

class LiquidParser extends CstParser {
  constructor(options: ConstructorParameters<typeof CstParser>[1]) {
    super(allTokens, options)
    this.performSelfAnalysis()
  }

  public root = this.RULE('root', () => {
    this.SUBRULE(this.doc)
  })

  public doc = this.RULE('doc', (loop = false) => {
    this.MANY({
      GATE: () =>
        ![
          ControlEndif,
          ControlEndunless,
          ControlEndraw,
          ControlEndcapture,
          ControlEndcomment,
          ControlEndfor,
          ControlEndtablerow,
          ControlEndcase,
          ControlElse,
          ControlElsif,
          ControlWhen,
        ].includes(this.LA(2).tokenType),
      DEF: () => {
        this.OR([
          // Control constructs
          { ALT: () => this.SUBRULE(this.controlFor, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlIf, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlUnless, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlCase, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlRaw, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlCapture, { ARGS: [loop] }) },
          { ALT: () => this.SUBRULE(this.controlComment, { ARGS: [loop] }) },
          // Other constructs
          { ALT: () => this.CONSUME(TextToken) },
          { ALT: () => this.SUBRULE(this.object) },
          { GATE: () => loop, ALT: () => this.SUBRULE(this.controlCycle) },
          { ALT: () => this.SUBRULE(this.controlAssign) },
          { ALT: () => this.SUBRULE(this.controlIncrement) },
          { ALT: () => this.SUBRULE(this.controlDecrement) },
          { ALT: () => this.SUBRULE(this.inlineComment) },
          { ALT: () => this.SUBRULE(this.liquidTag) },
          { ALT: () => this.SUBRULE(this.renderTag) },
          { GATE: () => loop, ALT: () => this.SUBRULE(this.controlBreak) },
          { GATE: () => loop, ALT: () => this.SUBRULE(this.controlContinue) },
        ])
      },
    })
  })

  private object = this.RULE('object', () => {
    this.CONSUME(ObjectStart)
    this.SUBRULE(this.expression)
    this.CONSUME(ObjectEnd, { ERR_MSG: 'Unclosed object' })
  })

  private controlFor = this.RULE('controlFor', (parentLoop = false) => {
    this.CONSUME(TagStart)
    const ctrl = this.OR([{ ALT: () => this.CONSUME(ControlFor) }, { ALT: () => this.CONSUME(ControlTablerow) }])
    this.CONSUME(Identifier, { ERR_MSG: `Expected identifier after \`${ctrl.image}\`` })
    this.CONSUME(ControlIn, { ERR_MSG: 'Expected `in` after identifier' })
    this.SUBRULE(this.forInValue)
    this.CONSUME(TagEnd, { ERR_MSG: `Unclosed \`${ctrl.image}\` tag` })

    this.SUBRULE(this.doc, { ARGS: [true] })

    this.OPTION(() => {
      this.SUBRULE(this.controlElse)
      this.SUBRULE2(this.doc, { ARGS: [parentLoop] })
    })

    this.CONSUME2(TagStart, { ERR_MSG: `Expected \`end${ctrl.image}\` tag` })
    const closeCtrl = this.OR2([
      { ALT: () => this.CONSUME(ControlEndfor) },
      { ALT: () => this.CONSUME(ControlEndtablerow) },
    ])
    this.CONSUME2(TagEnd, { ERR_MSG: `Unclosed \`${closeCtrl.image}\` tag` })
  })

  private controlBreak = this.RULE('controlBreak', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlBreak)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `break` tag' })
  })

  private controlContinue = this.RULE('controlContinue', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlContinue)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `continue` tag' })
  })

  private controlIf = this.RULE('controlIf', (loop = false) => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlIf)
    this.SUBRULE(this.expression)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `if` tag' })

    this.SUBRULE(this.doc, { ARGS: [loop] })

    this.OPTION1(() => {
      this.MANY(() => {
        this.SUBRULE1(this.controlElsif)
        this.SUBRULE2(this.doc, { ARGS: [loop] })
      })
    })

    this.OPTION2(() => {
      this.SUBRULE3(this.controlElse)
      this.SUBRULE4(this.doc, { ARGS: [loop] })
    })

    const ERR_MSG = 'Expected "endif" or "else" or "elsif" to close "if" block'
    this.CONSUME2(TagStart, { ERR_MSG })
    this.CONSUME(ControlEndif, { ERR_MSG })
    this.CONSUME2(TagEnd, { ERR_MSG })
  })

  private controlUnless = this.RULE('controlUnless', (loop = false) => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlUnless)
    this.SUBRULE(this.expression)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `unless` tag' })

    this.SUBRULE(this.doc, { ARGS: [loop] })

    this.OPTION(() => {
      this.SUBRULE(this.controlElse)
      this.SUBRULE2(this.doc, { ARGS: [loop] })
    })

    this.CONSUME2(TagStart)
    this.CONSUME(ControlEndunless)
    this.CONSUME2(TagEnd)
  })

  // TODO: maybe change consume behavior for {{{ this }}}?
  private controlRaw = this.RULE('controlRaw', (loop = false) => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlRaw)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `raw` tag' })

    this.SUBRULE(this.doc, { ARGS: [loop] })

    const ERR_MSG = 'Expected "endraw" to close "raw" block'
    this.CONSUME2(TagStart, { ERR_MSG })
    this.CONSUME(ControlEndraw, { ERR_MSG })
    this.CONSUME2(TagEnd, { ERR_MSG })
  })

  private controlCapture = this.RULE('controlCapture', (loop = false) => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlCapture)
    this.CONSUME(Identifier, { ERR_MSG: 'Expected identifier after `capture`' })
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `capture` tag' })

    this.SUBRULE(this.doc, { ARGS: [loop] })

    const ERR_MSG = 'Expected "endcapture" to close "capture" block'
    this.CONSUME2(TagStart, { ERR_MSG })
    this.CONSUME(ControlEndcapture, { ERR_MSG })
    this.CONSUME2(TagEnd, { ERR_MSG })
  })

  // TODO: maybe change consume behavior to be nothing?
  private controlComment = this.RULE('controlComment', (loop = false) => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlComment)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `comment` tag' })

    this.SUBRULE(this.doc, { ARGS: [loop] })

    const ERR_MSG = 'Expected "endcomment" to close "comment" block'
    this.CONSUME2(TagStart, { ERR_MSG })
    this.CONSUME(ControlEndcomment, { ERR_MSG })
    this.CONSUME2(TagEnd, { ERR_MSG })
  })

  private controlElsif = this.RULE('controlElsif', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlElsif)
    this.SUBRULE(this.expression)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `elsif` tag' })
  })

  private controlElse = this.RULE('controlElse', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlElse)
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `else` tag' })
  })

  private controlCase = this.RULE('controlCase', (loop = false) => {
    this.CONSUME1(TagStart)
    this.CONSUME(ControlCase)
    this.SUBRULE1(this.expression)
    this.CONSUME1(TagEnd, { ERR_MSG: 'Unclosed `case` tag' })

    this.SUBRULE1(this.doc, { ARGS: [loop] })

    this.MANY(() => {
      this.CONSUME2(TagStart)
      this.CONSUME(ControlWhen)
      this.MANY_SEP({
        SEP: OperatorComma,
        DEF: () => {
          this.OR([{ ALT: () => this.CONSUME(Identifier) }, { ALT: () => this.SUBRULE(this.primitive) }])
        },
      })
      this.CONSUME2(TagEnd, { ERR_MSG: 'Unclosed `when` tag' })
      this.SUBRULE2(this.doc, { ARGS: [loop] })
    })

    this.OPTION(() => {
      this.CONSUME3(TagStart)
      this.CONSUME(ControlElse)
      this.CONSUME3(TagEnd, { ERR_MSG: 'Unclosed `else` tag' })
      this.SUBRULE3(this.doc, { ARGS: [loop] })
    })

    const ERR_MSG = 'Expected "endcase" or "when" to close "case" block'
    this.CONSUME4(TagStart, { ERR_MSG })
    this.CONSUME(ControlEndcase, { ERR_MSG })
    this.CONSUME4(TagEnd, { ERR_MSG })
  })

  private controlAssign = this.RULE('controlAssign', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlAssign)

    this.CONSUME(Identifier, { ERR_MSG: 'Expected identifier after `assign`' })
    this.CONSUME(OperatorAssign, { ERR_MSG: 'Expected `=` after identifier' })
    this.SUBRULE(this.expression)

    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `assign` tag' })
  })

  private controlIncrement = this.RULE('controlIncrement', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlIncrement)

    this.CONSUME(Identifier, { ERR_MSG: 'Expected identifier after `increment`' })

    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `increment` tag' })
  })

  private controlDecrement = this.RULE('controlDecrement', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlDecrement)

    this.CONSUME(Identifier, { ERR_MSG: 'Expected identifier after `decrement`' })

    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `decrement` tag' })
  })

  private inlineComment = this.RULE('controlInlineComment', () => {
    this.CONSUME(TagStart)
    this.MANY(() => {
      this.CONSUME(InlineComment)
    })
    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed inline comment' })
  })

  // TODO: real liquid tag parsing? It's an advanced feature and almost amounts to an entirely different language.
  private liquidTag = this.RULE('liquidTag', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlLiquid)
    this.MANY(() => {
      this.OR([
        { ALT: () => this.CONSUME(InlineComment) },
        { ALT: () => this.CONSUME(ControlCase) },
        { ALT: () => this.CONSUME(ControlEndcase) },
        { ALT: () => this.CONSUME(ControlWhen) },
        { ALT: () => this.CONSUME(Identifier) },
        { ALT: () => this.CONSUME(ControlAssign) },
        { ALT: () => this.CONSUME(ControlElse) },
        { ALT: () => this.CONSUME(ControlElsif) },
        { ALT: () => this.CONSUME(ControlFor) },
        { ALT: () => this.CONSUME(ControlIn) },
        { ALT: () => this.CONSUME(ControlEndfor) },
        { ALT: () => this.CONSUME(ControlEcho) },
        { ALT: () => this.CONSUME(Pipe) },
        { ALT: () => this.SUBRULE(this.primitive) },
        { ALT: () => this.SUBRULE(this.operator) },
      ])
    })
    this.CONSUME(TagEnd)
  })

  // Even though we don't support render, this is nice to have for language compatiability
  private renderTag = this.RULE('renderTag', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlRender)
    this.CONSUME(StringToken, { ERR_MSG: 'Expected string after `render`' })

    this.OR1([
      {
        ALT: () => {
          this.CONSUME(OperatorComma)
          this.MANY_SEP({
            SEP: OperatorComma,
            DEF: () => {
              this.CONSUME1(Identifier)
              this.CONSUME(OperatorColon)
              this.OR2([{ ALT: () => this.SUBRULE(this.primitive) }, { ALT: () => this.CONSUME2(Identifier) }])
            },
          })
        },
      },
      {
        ALT: () => {
          this.OR([{ ALT: () => this.CONSUME(OperatorWith) }, { ALT: () => this.CONSUME(ControlFor) }])
          this.CONSUME3(Identifier)
          this.CONSUME(OperatorAs)
          this.CONSUME4(Identifier)
        },
      },
    ])

    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `render` tag' })
  })

  private controlCycle = this.RULE('controlCycle', () => {
    this.CONSUME(TagStart)
    this.CONSUME(ControlCycle)

    this.OPTION(() => {
      this.SUBRULE1(this.primitive)
      this.CONSUME(OperatorColon)
    })

    this.MANY_SEP({
      SEP: OperatorComma,
      DEF: () => {
        this.SUBRULE2(this.primitive)
      },
    })

    this.CONSUME(TagEnd, { ERR_MSG: 'Unclosed `cycle` tag' })
  })

  private expression = this.RULE('expression', () => {
    this.OR1([
      { ALT: () => this.CONSUME1(Identifier) },
      { ALT: () => this.SUBRULE1(this.primitive) },
      { ALT: () => this.CONSUME1(RangeToken) },
    ])

    this.OPTION1(() => {
      this.MANY(() => {
        this.SUBRULE(this.operator)
        this.OR2([
          { ALT: () => this.CONSUME2(Identifier) },
          { ALT: () => this.SUBRULE2(this.primitive) },
          { ALT: () => this.CONSUME2(RangeToken) },
        ])
      })
    })

    this.OPTION2(() => {
      this.CONSUME3(Pipe)
      this.MANY_SEP({
        SEP: Pipe,
        DEF: () => {
          this.SUBRULE(this.filterInstance)
        },
      })
    })
  })

  private forInValue = this.RULE('forInValue', () => {
    this.OR([{ ALT: () => this.CONSUME(Identifier) }, { ALT: () => this.CONSUME(RangeToken) }])
    this.OPTION(() => {
      this.MANY(() => {
        this.CONSUME2(Identifier)
        this.OPTION2(() => {
          this.SUBRULE(this.operator)
          this.CONSUME(NumberToken)
        })
      })
    })
  })

  private primitive = this.RULE('primitive', () => {
    this.OR({
      DEF: [
        { ALT: () => this.CONSUME(StringToken) },
        { ALT: () => this.CONSUME(NumberToken) },
        { ALT: () => this.CONSUME(BooleanToken) },
      ],
      ERR_MSG: 'Invalid primitive (expected string, number, or boolean)',
    })
  })

  private filterInstance = this.RULE('filterInstance', () => {
    this.CONSUME1(Identifier, { ERR_MSG: 'Expected filter identifier after pipe' })
    this.OPTION1(() => {
      this.CONSUME2(OperatorColon)
      this.MANY_SEP({
        SEP: OperatorComma,
        DEF: () => {
          this.OR1([{ ALT: () => this.SUBRULE1(this.primitive) }, { ALT: () => this.CONSUME3(Identifier) }])
          this.OPTION2(() => {
            this.CONSUME4(OperatorColon)
            this.OR2([{ ALT: () => this.SUBRULE2(this.primitive) }, { ALT: () => this.CONSUME5(Identifier) }])
          })
        },
      })
    })
  })

  private operator = this.RULE('operator', () => {
    this.OR({
      DEF: [
        { ALT: () => this.CONSUME(OperatorComma) },
        { ALT: () => this.CONSUME(OperatorColon) },
        { ALT: () => this.CONSUME(OperatorEqual) },
        { ALT: () => this.CONSUME(OperatorNotEqual) },
        { ALT: () => this.CONSUME(OperatorGreaterThan) },
        { ALT: () => this.CONSUME(OperatorLessThan) },
        { ALT: () => this.CONSUME(OperatorGreaterThanEqual) },
        { ALT: () => this.CONSUME(OperatorLessThanEqual) },
        { ALT: () => this.CONSUME(OperatorAssign) },
        { ALT: () => this.CONSUME(OperatorOr) },
        { ALT: () => this.CONSUME(OperatorAnd) },
        { ALT: () => this.CONSUME(OperatorContains) },
        { ALT: () => this.CONSUME(OperatorAs) },
      ],
      ERR_MSG: 'Invalid operator',
    })
  })
}

export const recoveringParser = new LiquidParser({ recoveryEnabled: true })
export const nonRecoveringParser = new LiquidParser({ recoveryEnabled: false })

export default function parseText(text: string) {
  const lexResult = lexer.tokenize(text)
  recoveringParser.input = lexResult.tokens
  const cst = recoveringParser.doc()

  nonRecoveringParser.input = lexResult.tokens
  nonRecoveringParser.doc()

  return { cst, lexErrors: lexResult.errors, parseErrors: nonRecoveringParser.errors }
}

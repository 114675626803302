/* eslint-disable camelcase */
/**
 * This file implements a lexer for the liquid language service. A lexer takes a string of text (a liquid document) and
 * turns it into a stream of tokens representing liquid syntax.
 */

import { createToken, Lexer } from 'chevrotain'

const MODE_MAIN = 'main'
const MODE_OBJECT = 'object'
const MODE_TAG = 'tag'

// Generic tokens
// Since whitespace is common, putting it first in each non-main mode will speed up lexing
export const Whitespace = createToken({
  name: 'Whitespace',
  pattern: /\s+/,
  group: Lexer.SKIPPED,
})

// Using a function here ensures that there is no interference between different uses of the identifier pattern
export const getIdentifierPattern = () => /[a-z_-](?:[a-z0-9._-]|\[(?:-?\d+|".+"|[a-z_-]+)])*/i

export const Identifier = createToken({
  name: 'Identifier',
  pattern: getIdentifierPattern(),
})

// TODO: maybe this can be quote tokens and then the parser can parse strings with escapes and stuff
export const StringToken = createToken({
  name: 'String',
  pattern: /".*?"|'.*?'/,
})

export const BooleanToken = createToken({
  name: 'Boolean',
  pattern: /true|false/,
  longer_alt: Identifier,
})

export const NumberToken = createToken({
  name: 'Number',
  pattern: /-?(?:\d+\.\d+|\d+)/,
})

export const RangeToken = createToken({
  name: 'Range',
  pattern: /\((?:\d+|[a-z_-]+)\.\.(?:\d+|[a-z_-]+)\)/i,
})

// Main mode tokens
export const ObjectStart = createToken({
  name: 'ObjectStart',
  pattern: /{{{?-?/,
  push_mode: MODE_OBJECT,
})

export const TagStart = createToken({
  name: 'TagStart',
  pattern: /{%-?/,
  push_mode: MODE_TAG,
})

export const TextToken = createToken({
  name: 'Text',
  line_breaks: true,
  pattern: /([^{]|({[^%{]))+/,
})

// Object mode tokens
export const ObjectEnd = createToken({
  name: 'ObjectEnd',
  pattern: /-?}?}}/,
  pop_mode: true,
})

export const Pipe = createToken({
  name: 'Pipe',
  pattern: '|',
})

// Tag mode tokens
export const TagEnd = createToken({
  name: 'TagEnd',
  pattern: /-?%}/,
  pop_mode: true,
})

export const InlineComment = createToken({
  name: 'InlineComment',
  line_breaks: true,
  pattern: /#[^\n%}-]+/,
})

export const ControlFor = createToken({
  name: 'ControlFor',
  pattern: 'for',
  longer_alt: Identifier,
})

export const ControlTablerow = createToken({
  name: 'ControlTablerow',
  pattern: 'tablerow',
  longer_alt: Identifier,
})

export const ControlIn = createToken({
  name: 'ControlIn',
  pattern: 'in',
  longer_alt: Identifier,
})

export const ControlEndfor = createToken({
  name: 'ControlEndFor',
  pattern: 'endfor',
  longer_alt: Identifier,
})

export const ControlEndtablerow = createToken({
  name: 'ControlEndtablerow',
  pattern: 'endtablerow',
  longer_alt: Identifier,
})

export const ControlIf = createToken({
  name: 'ControlIf',
  pattern: 'if',
  longer_alt: Identifier,
})

export const ControlElsif = createToken({
  name: 'ControlElsif',
  pattern: 'elsif',
  longer_alt: Identifier,
})

export const ControlElse = createToken({
  name: 'ControlElse',
  pattern: 'else',
  longer_alt: Identifier,
})

export const ControlEndif = createToken({
  name: 'ControlEndif',
  pattern: 'endif',
  longer_alt: Identifier,
})

export const ControlUnless = createToken({
  name: 'ControlUnless',
  pattern: 'unless',
  longer_alt: Identifier,
})

export const ControlEndunless = createToken({
  name: 'ControlEndunless',
  pattern: 'endunless',
  longer_alt: Identifier,
})

export const ControlCase = createToken({
  name: 'ControlCase',
  pattern: 'case',
  longer_alt: Identifier,
})

export const ControlWhen = createToken({
  name: 'ControlWhen',
  pattern: 'when',
  longer_alt: Identifier,
})

export const ControlEndcase = createToken({
  name: 'ControlEndcase',
  pattern: 'endcase',
  longer_alt: Identifier,
})

export const ControlAssign = createToken({
  name: 'ControlAssign',
  pattern: 'assign',
  longer_alt: Identifier,
})

export const ControlBreak = createToken({
  name: 'ControlBreak',
  pattern: 'break',
  longer_alt: Identifier,
})

export const ControlContinue = createToken({
  name: 'ControlContinue',
  pattern: 'continue',
  longer_alt: Identifier,
})

export const ControlCycle = createToken({
  name: 'ControlCycle',
  pattern: 'cycle',
  longer_alt: Identifier,
})

export const ControlInclude = createToken({
  name: 'ControlInclude',
  pattern: 'include',
  longer_alt: Identifier,
})

export const ControlIncrement = createToken({
  name: 'ControlIncrement',
  pattern: 'increment',
  longer_alt: Identifier,
})

export const ControlDecrement = createToken({
  name: 'ControlDecrement',
  pattern: 'decrement',
  longer_alt: Identifier,
})

export const ControlRaw = createToken({
  name: 'ControlRaw',
  pattern: 'raw',
  longer_alt: Identifier,
})

export const ControlEndraw = createToken({
  name: 'ControlEndraw',
  pattern: 'endraw',
  longer_alt: Identifier,
})

export const ControlCapture = createToken({
  name: 'ControlCapture',
  pattern: 'capture',
  longer_alt: Identifier,
})

export const ControlEndcapture = createToken({
  name: 'ControlEndcapture',
  pattern: 'endcapture',
  longer_alt: Identifier,
})

export const ControlComment = createToken({
  name: 'ControlComment',
  pattern: 'comment',
  longer_alt: Identifier,
})

export const ControlEndcomment = createToken({
  name: 'ControlEndcomment',
  pattern: 'endcomment',
  longer_alt: Identifier,
})

export const ControlLiquid = createToken({
  name: 'ControlLiquid',
  pattern: 'liquid',
  longer_alt: Identifier,
})

export const ControlEcho = createToken({
  name: 'ControlEcho',
  pattern: 'echo',
  longer_alt: Identifier,
})

export const ControlRender = createToken({
  name: 'ControlRender',
  pattern: 'render',
  longer_alt: Identifier,
})

export const OperatorComma = createToken({
  name: 'OperatorComma',
  pattern: ',',
  longer_alt: Identifier,
})

export const OperatorColon = createToken({
  name: 'OperatorColon',
  pattern: ':',
  longer_alt: Identifier,
})

export const OperatorEqual = createToken({
  name: 'OperatorEqual',
  pattern: '==',
  longer_alt: Identifier,
})

export const OperatorNotEqual = createToken({
  name: 'OperatorNotEqual',
  pattern: '!=',
  longer_alt: Identifier,
})

export const OperatorGreaterThan = createToken({
  name: 'OperatorGreaterThan',
  pattern: '>',
  longer_alt: Identifier,
})

export const OperatorLessThan = createToken({
  name: 'OperatorLessThan',
  pattern: '<',
  longer_alt: Identifier,
})

export const OperatorGreaterThanEqual = createToken({
  name: 'OperatorGreaterThanEqual',
  pattern: '>=',
  longer_alt: Identifier,
})

export const OperatorLessThanEqual = createToken({
  name: 'OperatorLessThanEqual',
  pattern: '<=',
  longer_alt: Identifier,
})

export const OperatorAssign = createToken({
  name: 'OperatorAssign',
  pattern: '=',
  longer_alt: Identifier,
})

export const OperatorOr = createToken({
  name: 'OperatorOr',
  pattern: 'or',
  longer_alt: Identifier,
})

export const OperatorAnd = createToken({
  name: 'OperatorAnd',
  pattern: 'and',
  longer_alt: Identifier,
})

export const OperatorContains = createToken({
  name: 'OperatorContains',
  pattern: 'contains',
  longer_alt: Identifier,
})

export const OperatorWith = createToken({
  name: 'OperatorWith',
  pattern: 'with',
  longer_alt: Identifier,
})

export const OperatorAs = createToken({
  name: 'OperatorAs',
  pattern: 'as',
  longer_alt: Identifier,
})

export const allTokens = [
  // Generic tokens
  Whitespace,
  Identifier,
  StringToken,
  BooleanToken,
  NumberToken,
  RangeToken,
  // Main mode tokens
  ObjectStart,
  TagStart,
  TextToken,
  // Object mode tokens
  ObjectEnd,
  Pipe,
  // Tag mode tokens
  TagEnd,
  InlineComment,
  ControlFor,
  ControlIn,
  ControlEndfor,
  ControlIf,
  ControlElsif,
  ControlElse,
  ControlEndif,
  ControlUnless,
  ControlEndunless,
  ControlCase,
  ControlWhen,
  ControlEndcase,
  ControlAssign,
  ControlBreak,
  ControlContinue,
  ControlCycle,
  ControlInclude,
  ControlIncrement,
  ControlDecrement,
  ControlRaw,
  ControlEndraw,
  ControlCapture,
  ControlEndcapture,
  ControlComment,
  ControlEndcomment,
  OperatorComma,
  OperatorColon,
  OperatorEqual,
  OperatorNotEqual,
  OperatorGreaterThan,
  OperatorLessThan,
  OperatorGreaterThanEqual,
  OperatorLessThanEqual,
  OperatorAssign,
  OperatorOr,
  OperatorAnd,
  OperatorContains,
  ControlLiquid,
  ControlEcho,
  ControlRender,
  OperatorWith,
  OperatorAs,
]

// NB: Tokens that potentially conflict should be listed in longest to shortest order, e.g., `increment` before `in`, because otherwise `in` will longer_alt to `increment` as an Identifier, skipping the `ControlIncrement` token entirely.
const lexer = new Lexer({
  modes: {
    [MODE_MAIN]: [TextToken, ObjectStart, TagStart],
    [MODE_OBJECT]: [
      Whitespace,
      OperatorComma,
      OperatorColon,
      OperatorEqual,
      OperatorNotEqual,
      OperatorGreaterThan,
      OperatorLessThan,
      OperatorGreaterThanEqual,
      OperatorLessThanEqual,
      OperatorAssign,
      OperatorOr,
      OperatorAnd,
      OperatorContains,
      ControlLiquid,
      ControlEcho,
      ControlRender,
      OperatorWith,
      OperatorAs,
      StringToken,
      BooleanToken,
      NumberToken,
      RangeToken,
      ObjectEnd,
      Pipe,
      Identifier,
    ],
    [MODE_TAG]: [
      Whitespace,
      StringToken,
      BooleanToken,
      NumberToken,
      RangeToken,
      Pipe,
      TagEnd,
      InlineComment,
      ControlFor,
      ControlEndfor,
      ControlTablerow,
      ControlEndtablerow,
      ControlEndunless,
      ControlEndif,
      ControlElsif,
      ControlElse,
      ControlUnless,
      ControlCase,
      ControlWhen,
      ControlEndcase,
      ControlAssign,
      ControlBreak,
      ControlContinue,
      ControlCycle,
      ControlDecrement,
      ControlIncrement,
      ControlInclude,
      ControlIn,
      ControlIf,
      ControlRaw,
      ControlEndraw,
      ControlCapture,
      ControlEndcapture,
      ControlComment,
      ControlEndcomment,
      OperatorComma,
      OperatorColon,
      OperatorEqual,
      OperatorNotEqual,
      OperatorGreaterThan,
      OperatorLessThan,
      OperatorGreaterThanEqual,
      OperatorLessThanEqual,
      OperatorAssign,
      OperatorOr,
      OperatorAnd,
      OperatorContains,
      ControlLiquid,
      ControlEcho,
      ControlRender,
      OperatorWith,
      OperatorAs,
      Identifier,
    ],
  },
  defaultMode: MODE_MAIN,
})

export default lexer

import { forwardRef } from 'react'

import { CheckCircleRounded as Success, Close, Warning, Error } from '@mui/icons-material'
import { IconButton, CircularProgress } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'
import { closeSnackbar, CustomContentProps, SnackbarAction, SnackbarKey } from 'notistack'

import { CheckCircleOutlineIcon } from 'icons'
import { Theme } from 'types/hb'

import { HbAnimatedLogo } from '../HbAnimatedLogo'
import { HbButton } from '../HbButton'
import { HbText } from '../Text/HbText'

export type AllVariants = CustomContentProps['variant'] | 'loading' | 'white'

export type AllowedVariants = Exclude<AllVariants, 'info'>

export type HbNotistackSnackbarProps = {
  testId?: string
  onClose?: () => void
  action?: SnackbarAction
  buttonProps?: ButtonProps
  iconOverride?: React.FC<{ className?: string }>
}

type ButtonProps = {
  text: string
  onClick: (id: SnackbarKey) => void
}

export type Props = HbNotistackSnackbarProps &
  Pick<CustomContentProps, 'message' | 'id'> & {
    variant?: AllowedVariants
  }

type StyleProps = { variant: AllVariants }

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.38)',
    padding: theme.spacing(1),
    minHeight: theme.spacing(5),
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 10,
    minWidth: 300,
    maxWidth: 550,
    flexWrap: 'nowrap',
  },
  variant: ({ variant }: StyleProps) => {
    let backgroundColor = theme.palette.grey[900]
    let color = 'white'

    switch (variant) {
      case 'success':
        backgroundColor = theme.palette.styleguide.green
        break
      case 'warning':
        backgroundColor = theme.palette.styleguide.yellow
        color = theme.palette.text.primary
        break
      case 'error':
        backgroundColor = theme.palette.styleguide.errorDark
        break
      case 'white':
        backgroundColor = theme.palette.styleguide.white
        color = theme.palette.text.primary
        break
      default:
        break
    }

    return {
      color,
      backgroundColor,
      '& button': {
        color,
      },
    }
  },
  iconAndMessage: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(0, 0.75, 0, 1),
    maxWidth: 20,
  },
  hbLogo: {
    margin: theme.spacing(0, -1),
    height: 0,
    maxWidth: 80,
  },
  button: {
    '&, &:hover, &:focus, &:focus:hover': {
      textDecoration: 'underline',
      border: 0,
      background: 'none',
      color: 'inherit',
    },
  },
  actionButtons: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  message: ({ variant }: StyleProps) => {
    return {
      color: ['warning', 'white'].includes(variant) ? undefined : 'white',
      padding: theme.spacing(1),
    }
  },
}))

const Loading = ({ className }: { className: string }) => {
  return <CircularProgress className={className} color="inherit" />
}

export const HbLogoIcon = ({ className }: { className: string }) => {
  const classes = useStyles({ variant: 'default' })
  return (
    <div className={classNames(className, classes.hbLogo)} color="inherit">
      <HbAnimatedLogo />
    </div>
  )
}

export const BlueSuccessIcon = ({ className }: { className: string }) => {
  return (
    <div className={className}>
      <CheckCircleOutlineIcon color="action" />
    </div>
  )
}

const Button = ({ text, onClick, className, id }: ButtonProps & { className: string; id: SnackbarKey }) => {
  return <HbButton variant="textPrimary" className={className} onClick={() => onClick(id)} label={text} />
}

const iconMapping = {
  default: undefined,
  success: Success,
  warning: Warning,
  error: Error,
  loading: Loading,
  white: undefined,
}

// This component is only meant to be passed to the SnackbarProvider,
// and shouldn't be used directly.
export const HbNotistackSnackbar = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { message, id, testId, action: _action, onClose, variant = 'default', buttonProps, iconOverride } = props

  const classes = useStyles({ variant })

  const Icon = iconOverride ?? iconMapping[variant]

  const handleClose = () => {
    closeSnackbar(id)
    if (onClose) {
      onClose()
    }
  }

  const action = _action || (buttonProps ? <Button className={classes.button} {...buttonProps} id={id} /> : null)

  return (
    <div data-testid={testId} ref={ref} className={classNames(classes.root, classes.variant)}>
      <div className={classes.iconAndMessage}>
        {Icon && <Icon className={classes.icon} />}
        <HbText tag="p" className={classes.message}>
          {message}
        </HbText>
      </div>
      <div className={classes.actionButtons}>
        {action}
        <IconButton onClick={handleClose} size="small">
          <Close />
        </IconButton>
      </div>
    </div>
  )
})

type SupportedMimeTypes = 'text/plain' | 'text/csv' | 'application/json'

export function downloadData({
  data,
  filename,
  type = 'text/plain',
}: {
  data: string
  filename: string
  type?: SupportedMimeTypes
}): void {
  const url = URL.createObjectURL(new Blob([data], { type }))
  const a = document.createElement('a')
  a.href = url
  a.download = filename
  a.style.display = 'none'
  document.body.appendChild(a)
  a.click()
  setTimeout(() => {
    URL.revokeObjectURL(url)
    document.body.removeChild(a)
  }, 0)
}

import React, { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { isEmpty, union, difference } from 'lodash'

import { HbCheckbox } from 'components/material/Form'
import { opacify } from 'helpers/colors'
import { Theme } from 'types/hb'

import { BoxedFormControlLabel } from './BoxedFormControlLabel'
import { HbRadio } from './HbRadio'

const useStyles = makeStyles<Theme>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    width: '100%',
  },
  redesignRoot: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginRight: `-${theme.spacing()}`,
  },
  option: {
    display: 'flex',
    flex: '1 1 260px',
    minWidth: 0,
    paddingRight: 8,
    borderRadius: theme.shape.smallContainer.borderRadius,
    backgroundColor: theme.palette.styleguide.lightGray1,
    alignItems: 'center',
    margin: theme.spacing(),
    cursor: 'pointer',
    '&$checked': {
      backgroundColor: opacify(theme.palette.styleguide.blue, 0.2),
    },
    outline: 'none',
  },
  input: {
    flex: '0 1 auto',
  },
  text: {
    flex: '1 1 auto',
  },
  checked: {},
  checkbox: {
    marginLeft: theme.spacing(-0.1),
  },
}))

interface BaseProps {
  className?: string
  options: { display: ReactNode; value: string; disabled?: boolean }[]
  values: string[]
  readOnly?: boolean
  testId?: string
  name?: string
  disabled?: boolean
}

interface SingleProps extends BaseProps {
  multiple: false
  onChange: (value: string) => void
  labelHasBorder?: boolean
  isErroneous?: boolean
  isBoxed?: boolean
}

interface MultipleProps extends BaseProps {
  multiple: true
  onChange: (values: string[]) => void
  labelHasBorder?: boolean
  isErroneous?: boolean
  isBoxed?: boolean
}

type Props = SingleProps | MultipleProps

export const FlatSelectInput = (props: Props) => {
  const {
    className,
    values,
    options,
    onChange,
    testId,
    readOnly = false,
    multiple = false,
    name,
    disabled,
    labelHasBorder,
    isErroneous,
    isBoxed = true,
  } = props

  const classes = useStyles()

  const handleChange = (checked: boolean, checkboxValue: string) => {
    if (readOnly) {
      return
    }

    if (multiple) {
      const onChangeCasted = onChange as (argValues: string[]) => void

      if (checked) {
        onChangeCasted(union(values, [checkboxValue]))
      } else {
        onChangeCasted(difference(values, [checkboxValue]))
      }
    } else {
      const onChangeCasted = onChange as (argValues: string | undefined) => void

      if (checked) {
        onChangeCasted(checkboxValue)
      } else {
        onChangeCasted(undefined)
      }
    }
  }

  const handleKeydown = (event: React.KeyboardEvent, checked: boolean, checkboxValue: string) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleChange(checked, checkboxValue)
      event.preventDefault()
    }
  }

  const isChecked = (optionValue: string) => {
    return values.indexOf(optionValue) > -1
  }

  if (isEmpty(options)) {
    return null
  }

  return (
    <div className={className || classes.redesignRoot} data-testid={testId}>
      {options.map(({ display, value: optionValue, disabled: disabledOption }) => (
        <BoxedFormControlLabel
          isBoxed={isBoxed}
          label={display}
          name={name}
          value={optionValue}
          data-testid={`option_${display}`}
          checked={isChecked(optionValue)}
          disabled={disabled || disabledOption}
          onChange={() => handleChange(!isChecked(optionValue), optionValue)}
          error={isErroneous}
          control={
            multiple ? (
              <HbCheckbox
                value={optionValue}
                name={name}
                classes={{ root: classes.checkbox }}
                uncheckedBackgroundColor={isBoxed ? 'contrastMedium' : 'light'}
                error={isErroneous}
              />
            ) : (
              <HbRadio
                className={classes.input}
                color="primary"
                value={optionValue}
                name={name}
                error={isErroneous}
                checked={isChecked(optionValue)}
              />
            )
          }
          key={optionValue}
          onKeyDown={(event: React.KeyboardEvent<HTMLLabelElement>) =>
            handleKeydown(event, !isChecked(optionValue), optionValue)
          }
          hasBorder={labelHasBorder}
        />
      ))}
    </div>
  )
}

import { useEffect } from 'react'

export function useOnWindowResize(handler: () => void, cleanup?: () => void) {
  useEffect(() => {
    window.addEventListener('resize', handler)
    return () => {
      window.removeEventListener('resize', handler)
      if (cleanup) {
        cleanup()
      }
    }
  }, [cleanup, handler])
}

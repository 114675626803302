import React, { useState } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { useDispatch, useSelector } from 'actions/store'
import { Placement, HbTooltip } from 'components/HbComponents/HbTooltip'
import Loader from 'components/library/Loader'
import { actions, DateRangeType, ExportType } from 'dashboards/shared/react/export'
import { hasPermission } from 'helpers/stateHelpers'
import { DownloadIcon } from 'icons'
import { BadgePermissionsEnum } from 'types/api'

import ExportCSVDialog from './ExportCSVDialog'

export type RenderButton = (
  onClick: () => void,
  Icon: React.ComponentType<{ className?: string }>,
  disabled?: boolean
) => React.ReactElement

interface Props {
  renderButton: RenderButton
}

const useStyles = makeStyles(() => ({
  buttonWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
}))

export default function ExportCSV(props: Props) {
  const { renderButton } = props
  const dispatch = useDispatch()
  const loading = useSelector((state) => state.export.isDownloading)
  const isDownloaded = useSelector((state) => state.export.isDownloaded)
  const isErrored = useSelector((state) => state.export.isErrored)

  const [open, setOpen] = useState(false)

  const classes = useStyles()

  const handleOnClick = () => {
    setOpen(true)
  }

  const handleOnClose = () => {
    setOpen(false)
    dispatch(actions.resetDownload())
  }

  const handleSubmit = (fromDate: string, toDate: string, dateRangeType: DateRangeType, type: ExportType) => {
    dispatch(actions.exportCSV(fromDate, toDate, dateRangeType, type))
  }

  const icon = loading
    ? ({ className }: { className?: string }) => (
        <Loader className={className} size={24} variant="local" indicatorType="circular" />
      )
    : DownloadIcon

  const canExportCases = useSelector((state) => hasPermission(state, BadgePermissionsEnum.ExportCases))
  const permissionMessage = canExportCases ? '' : 'Please contact your administrator for permission'

  return (
    <>
      <HbTooltip title={permissionMessage} placement={Placement.Top}>
        <span className={classes.buttonWrapper} data-testid="button_wrapper_hover_target">
          {renderButton(handleOnClick, icon, loading || !canExportCases)}
        </span>
      </HbTooltip>
      <ExportCSVDialog
        open={open}
        loading={loading}
        onSubmit={handleSubmit}
        onClose={handleOnClose}
        isDownloaded={isDownloaded}
        isErrored={isErrored}
      />
    </>
  )
}

import {
  deleteAttachment,
  deleteBankAccount,
  deleteBusinessEntity,
  deleteCryptoAddress,
  deleteDevice,
  deleteInstitution,
  deletePaymentCard,
  deletePersonEntity,
  deleteProduct,
  deleteTransactionBlock,
} from 'actions/importingActions'

import { DataType, DataTypes, DataTypeKey, SnakeDataTypeKey, toDataTypeKey } from './importingFields.types'
import { Thunk } from './store'

export const DATA_TYPES: DataTypes = {
  individual: {
    type: 'individual',
    icon: 'individual',
    name: 'person',
    key: 'entities',
    category: 'individuals',
    allowTextImport: true,

    deleteFn: deletePersonEntity,
  },
  business: {
    type: 'business',
    icon: 'business',
    name: 'business',
    key: 'entities',
    category: 'businesses',
    allowTextImport: true,

    deleteFn: deleteBusinessEntity,
  },
  institution: {
    type: 'financial_institution',
    icon: 'financial_institution',
    name: 'financial institution',
    key: 'institutions',
    category: 'institutions',
    allowTextImport: true,

    deleteFn: deleteInstitution,
  },
  transactionImports: {
    type: 'transactions',
    icon: 'transactions',
    name: 'transactions',
    key: 'transactionImports',
    category: 'transactions',
    allowTextImport: true,

    deleteFn: deleteTransactionBlock,
  },
  bankAccount: {
    type: 'bank_account',
    icon: 'bank_account',
    name: 'bank account',
    key: 'bankAccounts',
    category: 'bank_accounts',
    allowTextImport: true,

    deleteFn: deleteBankAccount,
  },
  paymentCard: {
    type: 'payment_card',
    icon: 'payment_card',
    name: 'payment card',
    key: 'paymentCards',
    category: 'payment_cards',
    allowTextImport: true,

    deleteFn: deletePaymentCard,
  },
  device: {
    type: 'device',
    icon: 'device',
    name: 'device',
    key: 'devices',
    category: 'devices',
    allowTextImport: true,

    deleteFn: deleteDevice,
  },
  cryptoAddress: {
    type: 'crypto_address',
    icon: 'crypto_address',
    name: 'crypto address',
    key: 'cryptoAddresses',
    category: 'crypto_addresses',
    allowTextImport: true,

    deleteFn: deleteCryptoAddress,
  },
  attachment: {
    type: 'attachment',
    icon: 'attachment',
    name: 'attachment',
    key: 'attachments',
    category: 'attachments',
    allowTextImport: false,

    deleteFn: deleteAttachment,
  },
  product: {
    type: 'product',
    icon: 'product',
    name: 'product',
    key: 'products',
    category: 'products',
    allowTextImport: true,

    deleteFn: deleteProduct,
  },
  importTemplate: {
    type: 'import_template',
    icon: 'import_template',
    name: 'custom import',
    key: null,
    category: 'custom_imports',
    allowTextImport: true,
  },
}

export const dataTypeOf = (key: SnakeDataTypeKey | DataTypeKey): DataType => DATA_TYPES[toDataTypeKey(key)]

export const nameFromDataType = (key?: SnakeDataTypeKey): string | null => {
  if (!key) {
    return null
  }
  const dataTypeObject = dataTypeOf(key)
  return dataTypeObject ? dataTypeObject.name : key
}

export function deleteItem(caseToken: string, itemType: SnakeDataTypeKey, itemToken: string): Thunk<void> {
  return (dispatch, getState, { api }) => {
    api.clearCallbacks(`${caseToken}-${itemType}-${itemToken}`)
    if (dataTypeOf(itemType).deleteFn) {
      // @ts-expect-error this existed before strictBindCallApply was turned on. "Fixing" requires a more thorough investigation.
      return dispatch(dataTypeOf(itemType).deleteFn.call(this, caseToken, itemToken))
    }
    return undefined
  }
}

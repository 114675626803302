import { ReactNode } from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { Theme } from 'types/hb'

const useStyles = makeStyles<Theme, { maxWidth?: number }>(() => ({
  cell: ({ maxWidth }) => ({
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    maxWidth,
  }),
}))

interface Props {
  maxWidth?: number
  children: ReactNode
  className?: string
}

/**
 * Note: caution using param `maxWidth` with a measured & calculated value.
 */
export const TextCell = ({ maxWidth, children, className }: Props) => {
  const classes = useStyles({ maxWidth })

  return <span className={classnames(classes.cell, className)}>{children}</span>
}

import { gql, useQuery } from '@apollo/client'

import { useHistory } from 'react-router-dom'

import { GQLError } from 'components/GQLError'

import { TableColumn } from 'components/library/Table'
import { RoundedTable } from 'components/library/Table/RoundedTable'

import ToggleAutomationTemplateVisible from './AutomationRuleTemplate/ToggleAutomationTemplateVisible'

import {
  AutomationTemplatesQuery as Query,
  AutomationTemplatesQueryVariables as QueryVariables,
} from './__generated__/AutomationTemplates.generated'

const QUERY = gql`
  fragment AutomationTemplatesAutomationRuleTemplate on AutomationRuleTemplate {
    ... on AutomationRuleTemplateBase {
      token
      name
      enabled
    }
    ...ToggleAutomationTemplateVisibleAutomationRuleTemplate
  }

  query AutomationTemplates {
    automationRuleTemplates(enabledOnly: false) {
      ...AutomationTemplatesAutomationRuleTemplate
    }
  }

  ${ToggleAutomationTemplateVisible.fragments.AutomationRuleTemplate}
`

export default function AutomationTemplates() {
  const { loading, error, data } = useQuery<Query, QueryVariables>(QUERY, {
    fetchPolicy: 'cache-and-network',
  })
  const history = useHistory()

  if (error) {
    return <GQLError error={error} />
  }

  if (!data) {
    return null
  }

  const tableData = data.automationRuleTemplates || []

  return (
    <RoundedTable
      uniqueKey="token"
      data={tableData}
      loading={loading}
      onRowClick={(template: (typeof tableData)[number]) => {
        history.push(`/automations/recipes/${template.token}`)
      }}
    >
      <TableColumn
        align="left"
        field="name"
        header="Recipe"
        value={(template: (typeof tableData)[number]) => template.name || 'Blank template'}
      />
      <TableColumn
        align="center"
        field="enabled"
        header="Visibility"
        value={(template: (typeof tableData)[number]) => (
          <div>
            <ToggleAutomationTemplateVisible
              showLabel={false}
              template={template}
              onClick={(e: React.MouseEvent) => {
                e.stopPropagation()
              }}
            />
          </div>
        )}
      />
    </RoundedTable>
  )
}

import React from 'react'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { useSelector } from 'actions/store'
import { HbButton, Props as HBButtonProps } from 'components/HbComponents/HbButton'
import { CSS_SELECTED_SELECTORS } from 'components/themeRedesign'
import { opacify } from 'helpers/colors'
import { ApplicationTheme } from 'reducers/applicationReducer'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => {
  const border = '1px solid transparent'
  return {
    root: {
      marginBottom: theme.spacing(0.5),
      borderRadius: '50%',
      color: theme.palette.text.secondary,
      border,
      minHeight: '40px',
      minWidth: '40px',

      [CSS_SELECTED_SELECTORS]: {
        color: theme.palette.text.primary,
        background: opacify(theme.palette.background.light, 0.55),
        border,
      },

      '&$mastercard': {
        color: '#818181',

        [CSS_SELECTED_SELECTORS]: {
          color: theme.palette.text.white,
          background: opacify(theme.palette.background.light, 0.15),
          border,
        },
      },

      '&$darkMode': {
        color: theme.palette.styleguide.gray300,

        [CSS_SELECTED_SELECTORS]: {
          color: theme.palette.text.white,
          background: opacify(theme.palette.background.light, 0.15),
          border,
        },
      },
    },
    darkMode: {},
    mastercard: {},
  }
})

type Props = Pick<HBButtonProps, 'label' | 'aria-current' | 'Icon' | 'onClick' | 'testId' | 'id'>

function themeNeedsDarkMode(theme: ApplicationTheme) {
  return theme === 'fis' || theme === 'marqeta' || theme === 'finastra' || theme === 'visa'
}

// Wraps HbButton and applies custom styles specific to the app nav
const AppNavButton = React.forwardRef<HTMLButtonElement, Props>((props: Props, ref) => {
  const classes = useStyles()
  const theme = useSelector((state) => state.application.theme)
  const themeClass = themeNeedsDarkMode(theme) ? classes.darkMode : theme === 'mastercard' ? classes.mastercard : ''

  return <HbButton ref={ref} {...props} className={classNames(classes.root, themeClass)} tooltip iconOnly />
})

export default AppNavButton

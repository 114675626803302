import { ReactNode } from 'react'

import { OutlinedInput, TextFieldProps } from '@mui/material'

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classNames from 'classnames'

import { displayStringValue } from 'components/HbComponents/Form/HbInputs/hbInputUtils'
import { getHelperTextId, InputContainer, InputResponse } from 'components/HbComponents/Form/Inputs'

const useStyles = makeStyles(() => ({
  inputContainer: {
    marginBottom: 0,
  },
}))
export type HbTextInputProps = Omit<
  TextFieldProps,
  | 'color' // component has a set color
  | 'variant' // component has set variant
  | 'select' // select should be its own separate component
  | 'SelectProps' // select should be its own separate component
  | 'id' // id is handled internally by component
  | 'placeholder' // placeholders are an anti-pattern we would like to deprecate
  | 'InputLabelProps' // separate handling of InputContainer props
  | 'margin' // component has a set margin
  | 'size' // component has a set size
  | 'rows' // deprecated
  | 'rowsMax' // deprecated
  | 'maxRows' // textarea should be its own separate component
  | 'minRows' // textarea should be its own separate component
  | 'multiline' // textarea should be its own separate component
  | 'FormHelperTextProps' // separate handling of InputContainer props
> & {
  name: string
  testId?: string
  label: string | ReactNode
  sublabel?: string
  inputContainerClassName?: string
  noSpacing?: boolean
  errors?: string | string[]
  hideLabel?: boolean
  readOnly?: boolean
}

/** *
 * This is the HB equivalent of MUI TextInput
 * with some styling changes and adjustment of props.
 * It can be used without any form library.
 * If you'd like to use react-hook-form
 * see HbRHFTextInput.
 * If you'd like a multiline input try
 * HbTextArea
 */
function HbTextInput(props: HbTextInputProps) {
  const {
    autoComplete,
    autoFocus = false,
    children,
    classes,
    className: classNameProp,
    defaultValue,
    disabled = false,
    error = false,
    errors,
    fullWidth = false,
    helperText,
    hideLabel,
    inputProps,
    InputProps,
    inputRef,
    label,
    name,
    onBlur,
    onChange,
    onFocus,
    required = false,
    type,
    value,
    sublabel,
    noSpacing,
    inputContainerClassName: inputContainerClassNameProp,
    testId,
    readOnly,
    ...rest
  } = props

  const className = classNames(classNameProp)
  const { inputContainer } = useStyles()

  // Custom override for the bottom margin for InputContainer.
  // We probably shouldn't have built-in margins for core components
  // since we usually have to adjust them based on the the context
  // of where they are rendered. Kind of hard going through every example
  // of InputContainer, but there aren't that many of HbTextInput
  // so I figured this would be a more reasonable approach for the moment.
  const inputContainerClassName = classNames(inputContainerClassNameProp, inputContainer)
  const hasErrors = error || Boolean(errors?.length) || Boolean(typeof errors === 'string' && errors)

  if (readOnly) {
    const readOnlyValue = value || inputProps?.value
    return (
      <InputResponse className={inputContainerClassName} label={label}>
        {displayStringValue({ value: readOnlyValue })}
        {InputProps?.endAdornment}
      </InputResponse>
    )
  }

  return (
    <InputContainer
      isErroneous={hasErrors}
      required={required}
      htmlFor={name}
      label={label}
      sublabel={sublabel}
      className={inputContainerClassName}
      noSpacing={noSpacing}
      errors={errors}
      helperText={helperText}
      testId={testId}
      hideLabel={hideLabel}
      {...rest}
    >
      <OutlinedInput
        aria-describedby={getHelperTextId(name)}
        autoComplete={autoComplete}
        autoFocus={autoFocus}
        defaultValue={defaultValue}
        fullWidth={fullWidth}
        name={name}
        type={type}
        value={value}
        id={name}
        inputRef={inputRef}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        inputProps={inputProps}
        disabled={disabled}
        className={className}
        margin="dense"
        {...InputProps}
      />
    </InputContainer>
  )
}

export default HbTextInput

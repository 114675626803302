import { ReactNode } from 'react'

import { Typography } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { Theme } from 'types/hb'

import { HbButton } from '../../HbComponents/HbButton'

import { SearchIllustration } from './SearchIllustration'

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => {
    const large = props.variant === 'large'
    const newSmall = props.variant === 'newSmall'

    return {
      display: 'flex',
      alignItems: 'center',
      flexDirection: large ? 'column' : 'row',
      justifyContent: 'space-between',
      borderTop: newSmall ? `1px solid ${theme.palette.dividers.medium}` : 'none',
      padding: theme.spacing(large ? 2 : newSmall ? 2.5 : 5),
      paddingTop: theme.spacing(large ? 7 : newSmall ? 2.5 : 5),
    }
  },
  illustration: (props: Props) => {
    if (props.variant === 'large') {
      return {
        width: 380,
        marginTop: theme.spacing(6),
      }
    }

    if (props.variant === 'newSmall') {
      return {
        height: 150,
      }
    }

    return {
      height: 180,
    }
  },
  text: (props: Props) => {
    const isLarge = props.variant === 'large'
    return {
      alignSelf: isLarge ? 'flex-start' : 'auto',
      maxWidth: isLarge ? 300 : 228,
    }
  },
  heading: (props: Props) => ({
    fontSize: props.variant === 'large' ? 28 : 22,
    marginBottom: 20,
  }),
  instructions: {
    fontSize: 15,
  },
  addNewButton: {
    margin: 0,
    marginTop: theme.spacing(2),
  },
}))

interface Props {
  query: string
  variant: 'large' | 'small' | 'newSmall'
  noResultsInstructions?: ReactNode
  addNewLabel?: string
  addNew?: () => void
}

export function SearchNoResults(props: Props) {
  const { query, addNew } = props
  const {
    noResultsInstructions = 'Try searching for names, identifiers, or comment text.',
    addNewLabel = '+ Add new',
  } = props
  const { root, illustration, text, heading, instructions, addNewButton } = useStyles(props)

  return (
    <div className={root} data-testid="search-results-blank">
      <div className={text}>
        <Typography className={heading} variant="h5">
          No results for “{query}”
        </Typography>
        <Typography className={instructions} variant="body2">
          {noResultsInstructions}
        </Typography>
        {addNew && <HbButton className={addNewButton} size="small" label={addNewLabel} onClick={addNew} />}
      </div>
      <SearchIllustration className={illustration} />
    </div>
  )
}

import React from 'react'

import { IconButton } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { CloseThinIcon } from 'icons'
import { Theme } from 'types/hb'

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    width: 30,
    height: 30,
  },
  icon: {
    width: 12,
    color: theme.palette.link.primary,
  },
}))

export function CloseButton({ close }: { close: (event: React.MouseEvent) => void }) {
  const { button, icon } = useStyles()

  return (
    <IconButton className={button} size="small" onClick={close}>
      <CloseThinIcon classes={{ root: icon }} />
    </IconButton>
  )
}

import { css } from '@emotion/css'

import classNames from 'classnames'

import { useField } from 'formik'

import { HbFormikTextAreaField } from 'components/HbComponents/Form/HbInputs/HbTextArea/HbFormikTextArea'

import { InputLinkAdornment } from 'components/material/Form/InputLinkAdornment'

import { otherInfoTextAreaClassName } from './shared'

const baseInputContainerClassName = css({ wordBreak: 'break-word', '&&': { marginBottom: 16 } })

const inputContainerClassName = classNames(baseInputContainerClassName, otherInfoTextAreaClassName)

interface FormikOtherInfoTextAreaProps {
  className?: string
  disabled?: boolean
  hideLabel?: boolean
  inputContainerClassName?: string
  label: string
  minRows?: number
  maxRows?: number
  name: string
}

export const FormikOtherInfoTextArea = ({
  className,
  disabled,
  hideLabel,
  inputContainerClassName: propInputContainerClassName,
  label,
  minRows = 1,
  maxRows = 4,
  name,
}: FormikOtherInfoTextAreaProps) => {
  const [{ value }] = useField(name)
  return (
    <HbFormikTextAreaField
      className={className}
      disabled={disabled}
      inputContainerClassName={classNames(inputContainerClassName, propInputContainerClassName)}
      hideLabel={hideLabel}
      label={label}
      name={name}
      minRows={minRows}
      maxRows={maxRows}
      InputProps={{
        endAdornment: <InputLinkAdornment value={value} />,
      }}
    />
  )
}

import { InputAdornment } from '@mui/material'

import { Field, FieldAttributes, FieldProps } from 'formik'

import HbTextInput, { HbTextInputProps } from 'components/HbComponents/Form/HbInputs/HbTextInput/HbTextInput'

import { FormattedInput, FormattedInputProps } from './FormattedInput'
import { TextInput, TextInputProps } from './TextInput'

type MoneyFormattedInputProps = {
  startAdornment?: string
} & Omit<FormattedInputProps, 'thousandSeparator' | 'decimalSeparator' | 'size'>

export const MoneyFormattedInput = ({ allowNegative = false, ...props }: MoneyFormattedInputProps) => (
  <FormattedInput
    thousandSeparator=","
    decimalSeparator="."
    decimalScale={2}
    fixedDecimalScale
    allowNegative={allowNegative}
    {...props}
  />
)

type MoneyInputProps = MoneyFormattedInputProps & TextInputProps

/**
 * @deprecated Prefer HbMoneyInput instead
 */
export const MoneyInput = ({ InputProps, ...props }: MoneyInputProps & FieldProps<unknown>) => (
  <TextInput
    InputProps={{
      inputComponent: MoneyFormattedInput,
      startAdornment: props.startAdornment || <InputAdornment position="start">$</InputAdornment>,
      ...InputProps,
    }}
    {...props}
  />
)

export type HbMoneyInputProps = MoneyFormattedInputProps & HbTextInputProps

export const HbMoneyInput = ({ InputProps, ...props }: HbMoneyInputProps) => (
  <HbTextInput
    InputProps={{
      ...InputProps,
      inputProps: {
        fixedDecimalScale: false,
        decimalScale: 12,
        autoComplete: 'off',
        ...props.inputProps,
      },
      inputComponent: MoneyFormattedInput,
      startAdornment: <InputAdornment position="start">$</InputAdornment>,
    }}
    {...props}
  />
)

type MoneyFieldProps = MoneyInputProps & FieldAttributes<unknown>

// 16 9s - max amount storable in a numeric(24, 8)
// eslint-disable-next-line @typescript-eslint/no-loss-of-precision
const MAX_MONEY_RANGE = 9999999999999999

function validateMaxSafeMoney(input: string) {
  const float = parseFloat(input)

  if (float > MAX_MONEY_RANGE) {
    return 'Amount is too large.'
  }

  return undefined
}

export const MoneyField = (props: MoneyFieldProps) => (
  <Field {...props} component={MoneyInput} validate={validateMaxSafeMoney} />
)

/**
 * This file uses the error-recovering parser and token prediction to suggest completions for liquid documents. The
 * complete document *up to the current cursor position* should be passed in to get completion suggestions.
 */

import invariant from 'tiny-invariant'

import { isTruthy } from 'utils'

import lexer from './lexer'
import { recoveringParser } from './parser'
import { Variable, flattenVariablesToSuggestions } from './util'

import type { ISyntacticContentAssistPath } from 'chevrotain'

function processSuggestionsForIdentifiers(suggestions: Array<ISyntacticContentAssistPath>, variables: Array<Variable>) {
  // If the next token type is not an identifier, we don't need to suggest anything
  if (!suggestions.find((s) => s.nextTokenType.name === 'Identifier')) {
    return []
  }

  // If we are defining the controlFor loop variable, we don't need to suggest anything
  if (suggestions.find((s) => s.ruleStack.at(-1) === 'controlFor')) {
    return []
  }

  // If we are defining the forIn loop variable, only suggest array variables
  if (suggestions.find((s) => s.ruleStack.at(-1) === 'forInValue')) {
    return flattenVariablesToSuggestions(variables, 'array')
  }

  // Otherwise, suggest all variables
  return flattenVariablesToSuggestions(variables)
}

export default function assist(text: string, currentId = '', variables: Array<Variable> = []) {
  const lexResult = lexer.tokenize(text)

  if (lexResult.errors.length > 0) {
    throw new Error(lexResult.errors[0].message)
  }

  const suggestions = recoveringParser.computeContentAssist('root', lexResult.tokens)
  recoveringParser.input = lexResult.tokens
  const cst = recoveringParser.root()

  const scopedIdentifiers = suggestions
    .filter((s) => s.nextTokenType.name === 'Identifier')
    .flatMap(({ ruleStack }) => {
      const names: Array<string> = []
      const rules = ruleStack.slice(1)
      let node = cst

      for (const rule of rules) {
        const next = node.children[rule].at(-1)
        invariant(next && 'children' in next)
        node = next

        if (rule === 'doc') {
          // Assignments
          names.push(
            ...(node.children.controlAssign?.map((assign) => {
              invariant('children' in assign)
              const id = assign.children.Identifier?.at(0)
              invariant(id && 'image' in id)
              return id.image
            }) ?? [])
          )

          // Captures
          names.push(
            ...(node.children.controlCapture?.map((capture) => {
              invariant('children' in capture)
              const id = capture.children.Identifier?.at(0)
              invariant(id && 'image' in id)
              return id.image
            }) ?? [])
          )
        } else if (rule === 'controlFor') {
          names.push(
            ...(node.children.Identifier?.map((I) => {
              invariant('image' in I)
              return I.image
            }) ?? [])
          )
        }
      }

      return names
    })
    .map((name): Variable => ({ type: 'value', name, label: name, info: '' }))

  const identifierSuggestions = processSuggestionsForIdentifiers(suggestions, [...variables, ...scopedIdentifiers])

  const keywordSuggestions = suggestions
    .filter((s) => s.nextTokenType.name.startsWith('Control'))
    .map((s) => {
      switch (s.nextTokenType.name) {
        case 'ControlFor':
          return 'for'
        case 'ControlTablerow':
          return 'tablerow'
        case 'ControlIf':
          return 'if'
        case 'ControlUnless':
          return 'unless'
        case 'ControlCase':
          return 'case'
        case 'ControlRaw':
          return 'raw'
        case 'ControlCapture':
          return 'capture'
        case 'ControlAssign':
          return 'assign'
        case 'ControlIncrement':
          return 'increment'
        case 'ControlDecrement':
          return 'decrement'
        case 'ControlLiquid':
          return 'liquid'
        case 'ControlCycle':
          return s.ruleStack.includes('controlCycle') ? 'cycle' : null
        case 'ControlBreak':
          return s.ruleStack.includes('controlFor') ? 'break' : null
        case 'ControlContinue':
          return s.ruleStack.includes('controlFor') ? 'continue' : null
        default:
          return null
      }
    })
    .filter(isTruthy)
    .filter((kw) => kw.includes(currentId))

  return { identifierSuggestions, keywordSuggestions }
}

import { Dialog, DialogProps } from '@mui/material'

import KeyCapture from 'components/library/KeyCapture'

// Override default DialogTitle behavior to reset which variant we use for dialog titles.
const KeyCaptureDialog = (props: DialogProps) => (
  <KeyCapture active={props.open}>
    <Dialog {...props} />
  </KeyCapture>
)

export default KeyCaptureDialog

import { z } from 'zod'

import {
  variableNodeSchema,
  domainTypeSchema,
  binaryExpressionSchema,
  strictLogicalExpressionSchema,
} from './formSchema'

import type {
  LogicalExpression,
  StrictLogicalExpression,
  ListExpression,
  LinkedTrigger,
  ExpressionNode,
  StrictListExpression,
  StrictLinkedTrigger,
} from 'types/automations'

// The schemas in this file are not used anywhere in the form. It represents a non-strict trigger filter,
// which can be passed in from the backend or via templates.
// This schema will be called only on load and used for transforming trigger filters into strict
// trigger filters. See formSchema.ts for the strict version.

const listExpressionSchema: z.ZodType<StrictListExpression, z.ZodTypeDef, ListExpression> = z.lazy(() =>
  z.object({
    type: z.literal('list_expression'),
    operator: z.union([z.literal('any'), z.literal('all'), z.literal('none')]),
    value: variableNodeSchema,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    expression: strictTransformedExpressionNodeSchema,
  })
)

const linkedTriggerSchema: z.ZodType<StrictLinkedTrigger, z.ZodTypeDef, LinkedTrigger> = z.lazy(() =>
  z.object({
    type: z.literal('linked_trigger'),
    operator: z.union([z.literal('any'), z.literal('all'), z.literal('none')]),
    domain_type: domainTypeSchema,
    trigger_token: z.string().nullable(),
    value: variableNodeSchema,
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    expression: strictTransformedExpressionNodeSchema,
  })
)

const logicalExpressionSchema: z.ZodType<LogicalExpression> = z.lazy(() =>
  z.object({
    type: z.literal('logical_expression'),
    operator: z.union([z.literal('or'), z.literal('and')]),
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    values: z.array(expressionNodeSchema),
  })
)

export const expressionNodeSchema: z.ZodType<ExpressionNode> = z.union([
  binaryExpressionSchema,
  listExpressionSchema,
  logicalExpressionSchema,
  linkedTriggerSchema,
])

function transformExpressionNodeToStrict(input: ExpressionNode): StrictLogicalExpression {
  // Base case
  const parsed = strictLogicalExpressionSchema.safeParse(input)
  if (parsed.success) {
    return parsed.data
  }

  // Expand a logical expression that doesn't have branches
  if (input.type === 'logical_expression') {
    return strictLogicalExpressionSchema.parse({
      type: 'logical_expression',
      operator: 'or',
      values: [input],
    })
  }

  // Expand anything else
  return strictLogicalExpressionSchema.parse({
    type: 'logical_expression',
    operator: 'or',
    values: [
      {
        type: 'logical_expression',
        operator: 'and',
        values: [input],
      },
    ],
  })
}

export const strictTransformedExpressionNodeSchema: z.ZodType<StrictLogicalExpression, z.ZodTypeDef, ExpressionNode> =
  expressionNodeSchema.refine((v) => v !== null).transform(transformExpressionNodeToStrict)

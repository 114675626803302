import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

// This icon is from mui 5
export const AutoAwesome: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <path
      d="M15.9218 6.66672L16.5802 5.20839L18.0385 4.55006C18.3635 4.40006 18.3635 3.94172 18.0385 3.79172L16.5802 3.13339L15.9218 1.66672C15.7718 1.34172 15.3135 1.34172 15.1635 1.66672L14.5052 3.12506L13.0385 3.78339C12.7135 3.93339 12.7135 4.39172 13.0385 4.54172L14.4968 5.20006L15.1552 6.66672C15.3052 6.99172 15.7718 6.99172 15.9218 6.66672ZM9.28851 7.91672L7.96351 5.00006C7.67185 4.35006 6.73851 4.35006 6.44685 5.00006L5.12185 7.91672L2.20518 9.24172C1.55518 9.54172 1.55518 10.4667 2.20518 10.7584L5.12185 12.0834L6.44685 15.0001C6.74685 15.6501 7.67185 15.6501 7.96351 15.0001L9.28851 12.0834L12.2052 10.7584C12.8552 10.4584 12.8552 9.53339 12.2052 9.24172L9.28851 7.91672ZM15.1552 13.3334L14.4968 14.7917L13.0385 15.4501C12.7135 15.6001 12.7135 16.0584 13.0385 16.2084L14.4968 16.8667L15.1552 18.3334C15.3052 18.6584 15.7635 18.6584 15.9135 18.3334L16.5718 16.8751L18.0385 16.2167C18.3635 16.0667 18.3635 15.6084 18.0385 15.4584L16.5802 14.8001L15.9218 13.3334C15.7718 13.0084 15.3052 13.0084 15.1552 13.3334Z"
      fill="currentColor"
    />
  </SvgIcon>
)

export default AutoAwesome

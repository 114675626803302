import { useMemo } from 'react'

import { styled } from '@mui/material'

import { fetchReviewWorkflow, getReviewWorkflowLoadOp } from 'actions/reviewsActions'
import { useDispatch, useSelector } from 'actions/store'
import { HbLinearProgressWithCount, StyledCountText } from 'components/HbComponents/Progress/HbLinearProgressWithCount'
import { getLoadingBool, getReviewWorkflow } from 'helpers/stateHelpers'
import { useEffectOnce } from 'hooks'
import { Workflow } from 'reducers/reviewsReducer'

export const getWorkflowProgressCounts = (workflow: Workflow | null) => {
  if (!workflow) return { completed: 0, total: 0 }
  const { actions = [] } = workflow
  const total = actions.map(({ tasks }) => tasks).flat().length
  const completed = actions.reduce((_completed, _nextAction) => {
    if (_nextAction.complete) {
      return _completed + _nextAction.tasks.length
    }
    return _completed + _nextAction.tasks.filter((task) => task.status === 'VALID').length
  }, 0)
  return { completed, total }
}

const MIN_LINEAR_PROGRESS_WIDTH = 20
const MIN_COUNT_TEXT_WIDTH = 34

export const ProgressContainer = styled('div')(({ theme }) => ({
  flexGrow: 1,
  flexFlow: 'row nowrap',
  display: 'flex',
  alignItems: 'center',
  columnGap: theme.spacing(),
  padding: theme.spacing(0, 0.5, 0, 0),
  maxWidth: '100%',
  minWidth: MIN_LINEAR_PROGRESS_WIDTH + MIN_COUNT_TEXT_WIDTH,
}))

const StyledLinearProgress = styled(HbLinearProgressWithCount)(({ theme }) => ({
  flex: '1 1 100px',
  margin: theme.spacing(0, 0, 0, 0.5),
  height: 7,
  minWidth: MIN_LINEAR_PROGRESS_WIDTH,

  [`& ${StyledCountText}`]: {
    ...theme.typography.sizes.s,
    color: theme.palette.styleguide.textGreyLight,
    flexShrink: 0,
  },
}))

interface ProgressInnerProps {
  workflow: Workflow | null
}

export const WorkflowProgress = ({ workflow }: ProgressInnerProps) => {
  const { completed, total } = useMemo(() => getWorkflowProgressCounts(workflow), [workflow])

  return (
    <ProgressContainer>
      <StyledLinearProgress count={completed} format="numeric" total={total} />
    </ProgressContainer>
  )
}

interface ProgressIndicatorProps {
  reviewToken: string
}

export const ReviewWorkflowProgress = ({ reviewToken }: ProgressIndicatorProps) => {
  const dispatch = useDispatch()
  const loading = useSelector((state) => getLoadingBool(state, getReviewWorkflowLoadOp(reviewToken)))
  const workflow = useSelector((state) => getReviewWorkflow(state, reviewToken))

  // note: remove when review workflow is queried via gql.
  // while we still need to rely on the api for getting the workflow,
  // we can fetch the data on mount if it doesn't already exist in the store,
  // assuming the workflow state value is up-to-date
  useEffectOnce(() => {
    if (workflow) return
    dispatch(fetchReviewWorkflow(reviewToken, false))
  })

  if (loading || !workflow) return null

  return <WorkflowProgress workflow={workflow} />
}

import 'isomorphic-fetch'
import UrlResolver, { UrlResolverTokenData, UrlResolverQuery } from './urlResolver'

// A light library for calls to the API. Incorporates the
// {@link https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API|Fetch API}
// The library handles the request data formatting, headers, and credentials.

export const GENERIC_ERROR = {
  success: false,
  status: 500,
  error: {
    type: 'GENERIC',
    message: 'Something went wrong. Please try again.',
  },
  errors: ['Something went wrong. Please try again.'],
}

const jsonParser = (response: Response) => {
  if (response.ok || response.status === 403) {
    return response.json()
  }

  return Promise.resolve(GENERIC_ERROR)
}

const handleRedirect = (json: { status: string; url: string }) => {
  if (json.status === 'redirect') {
    // TODO(rg): In the future, we don't want to do a full page refresh.
    window.location.href = json.url
    return true
  }

  return false
}

export type Urls = { [key: string]: string }
type Init = {
  csrfToken: string
  gitSha1: string
  urls: Urls
}

type ApiRequest = {
  urlName: string
  urlParams?: UrlResolverTokenData
  query?: UrlResolverQuery
  returnResponseDetails?: boolean
} & RequestInit

type ApiGet = {
  urlParams?: UrlResolverTokenData
  query?: UrlResolverQuery
  returnResponseDetails?: boolean
}

type ApiWithData = ApiGet & {
  // Must be serializable to JSON
  data?: any
}

class Api {
  private csrfToken: string
  private gitSha1: string
  private urls: Urls
  private urlResolver = new UrlResolver()

  private timeoutsByToken: {
    [token: string]: ReturnType<typeof setTimeout>
  } = {}

  private callsByToken: { [token: string]: number } = {}
  private callbackTokensRegistered: { [token: string]: boolean }

  constructor({ csrfToken, urls, gitSha1 }: Init) {
    this.urls = urls
    this.csrfToken = csrfToken
    this.gitSha1 = gitSha1

    this.clearDebouncer()
    this.clearAllCallbacks()
  }

  clearDebouncer() {
    this.timeoutsByToken = {}
    this.callsByToken = {}
  }

  // Api holds a set of callbacks that are outstanding. This allows other parts of the code
  // to let a callback know that it's actions are no longer needed. This is useful if a UI change
  // makes the result of an API call no longer relevant. Callbacks must implement their own logic
  // around handling cleared callbacks, as callback action relevance is highly situational.
  clearCallbacks(token: string) {
    delete this.callbackTokensRegistered[token]
  }

  // Allows callback to query whether the callback should still be processed. If another part of
  // the code has called `clearCallbacks` then the callback should consider not proceeding.
  callbackStillRegistered(token: string) {
    return this.callbackTokensRegistered[token]
  }

  // Private APIs for accessing callbacks, mostly used internally or for testing.
  clearAllCallbacks() {
    this.callbackTokensRegistered = {}
  }

  registerCallback(token: string) {
    this.callbackTokensRegistered[token] = true
  }

  headers() {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': this.csrfToken,
      'X-Frontend-Version': this.gitSha1,
    }
  }

  resolveUrl(name: string, tokens: UrlResolverTokenData | undefined, query: UrlResolverQuery | undefined) {
    if (!this.urls[name]) {
      throw new Error(`unknown url name: ${name}`)
    }
    return this.urlResolver.resolve(this.urls[name], tokens || {}, query)
  }

  debounce<T>(token: string, action: (resolve: (d?: T) => void) => void, wait = 1000) {
    this.registerCallback(token)
    const timeout = this.timeoutsByToken[token]
    clearTimeout(timeout)
    return new Promise<T | undefined>((resolve) => {
      this.timeoutsByToken[token] = setTimeout(() => {
        const call = this.callsByToken[token]
        if (!call) {
          this.callsByToken[token] = new Date().getTime()
          action(resolve)
        }
      }, wait)
    }).then((data) => {
      this.clearCallbacks(token)
      delete this.callsByToken[token]
      delete this.timeoutsByToken[token]
      return data
    })
  }

  async request({ urlName, urlParams, query, returnResponseDetails, ...otherParams }: ApiRequest) {
    const url = this.resolveUrl(urlName, urlParams, query)
    const headers = this.headers()
    try {
      const response = await fetch(url, {
        headers,
        credentials: 'same-origin',
        ...otherParams,
      })

      const json = await jsonParser(response)

      // Check if redirect is needed
      if (handleRedirect(json)) {
        // If we redirected, return a promise that never resolves
        // since we don't want to continue executing the promise chain
        return new Promise(() => {})
      }

      return returnResponseDetails ? { json, headers: response.headers } : json
    } catch {
      // We'll end up here if:
      // - fetch fails (e.g. CORS error, network issues)
      // - json parsing fails (e.g. bad server response)
      return Promise.resolve(GENERIC_ERROR)
    }
  }

  get(urlName: string, { urlParams = {}, query = {} }: ApiGet = {}) {
    return this.request({ urlName, urlParams, query })
  }

  post(urlName: string, { urlParams = {}, data = {}, query = {}, returnResponseDetails = false }: ApiWithData = {}) {
    return this.request({
      urlName,
      urlParams,
      query,
      returnResponseDetails,
      method: 'POST',
      body: JSON.stringify(data),
    })
  }

  put(urlName: string, { urlParams = {}, data = {}, query = {} }: ApiWithData = {}) {
    return this.request({
      urlName,
      urlParams,
      query,
      method: 'PUT',
      body: JSON.stringify(data),
    })
  }

  delete(urlName: string, { urlParams = {}, data = {}, query = {} }: ApiWithData = {}) {
    return this.request({
      urlName,
      urlParams,
      query,
      method: 'DELETE',
      body: JSON.stringify(data),
    })
  }
}

export default Api

import React from 'react'

import { ThemeProvider, StyledEngineProvider } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { theme as redesignTheme } from 'components/themeRedesign'
import AuthHero from 'images/auth-hero.png'
import HbLogo from 'images/logotype.png'
import { Theme } from 'types/hb'

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const useStyles = makeStyles((theme: Theme) => ({
  /* Styles applied to root div element */
  root: {
    // CSS for filling entire viewport
    top: '0',
    left: '0',
    position: 'fixed',
    width: '100vw',
    height: '100vh',

    display: 'flex',
    flexDirection: 'row',

    background: theme.gradients.adminOnboarding,
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    background: theme.palette.background.light,
    overflowY: 'auto',
  },
  content: {
    maxWidth: 550,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingTop: theme.spacing(14),
    paddingBottom: theme.spacing(10),
  },
  logo: {
    height: 35,
    alignSelf: 'flex-start',
    marginBottom: theme.spacing(16),
  },
  graphic: {
    flex: 1,
    display: 'grid',
    placeItems: 'center',
    background: theme.palette.background.medium,

    // Hide if window is narrow
    '@media (max-width: 1000px)': {
      display: 'none',
    },
  },
  heroImage: {
    maxWidth: '65%',
    maxHeight: '80vh',
  },
}))

interface Props {
  children: React.ReactNode
  className?: string
}

function AuthBackgroundInternal(props: Props) {
  const { children } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.content}>
          <img src={HbLogo} alt="Hummingbird Logo" className={classes.logo} />
          {children}
        </div>
      </div>
      <div className={classes.graphic}>
        <img className={classes.heroImage} src={AuthHero} alt="Hummingbird" />
      </div>
    </div>
  )
}

export function AuthBackground(props: Props) {
  // Unsafe cast of the theme, since we are optimizing our theme types for usage in CSS hooks,
  // not for matching optional value definitions in m-ui.
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={redesignTheme as unknown as Theme}>
        <AuthBackgroundInternal {...props} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}

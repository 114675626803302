import { PageTabDefinition } from 'reducers/tabReducer'
import { CaseTab } from 'utils/query.types'

export const getQueryTabFromTabInfo = (tabInfo: PageTabDefinition): CaseTab => {
  if (tabInfo.type === 'overview' || tabInfo.type === 'files') {
    return { type: tabInfo.type }
  }
  if (tabInfo.type === 'library') {
    return {
      tab: tabInfo.tab,
      type: tabInfo.type,
      pinned: tabInfo.pinned,
      entityType: tabInfo.entityType,
      entityToken: tabInfo.entityToken,
      linkToken: tabInfo.linkToken,
      libraryAttachmentToken: tabInfo.attachmentToken,
    }
  }
  if (tabInfo.type === 'libraryCreate') {
    return {
      currentStep: tabInfo.currentStep,
      entityType: tabInfo.entityType,
      libraryToken: tabInfo.libraryToken,
      pinned: tabInfo.pinned,
      type: tabInfo.type,
    }
  }
  if (tabInfo.type === 'review') {
    return {
      action: tabInfo.action,
      pinned: tabInfo.pinned,
      reviewToken: tabInfo.reviewToken,
      task: tabInfo.task,
      type: tabInfo.type,
    }
  }
  if (tabInfo.type === 'informationRequest') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      requestToken: tabInfo.requestToken,
    }
  }
  if (tabInfo.type === 'inboundRequest') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      inboundToken: tabInfo.inboundToken,
    }
  }
  if (tabInfo.type === 'transactions') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      viewing: tabInfo.viewing,
    }
  }
  if (tabInfo.type === 'middesk') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      businessEntityToken: tabInfo.businessEntityToken,
    }
  }
  if (tabInfo.type === 'sanctionsScreening') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      entityToken: tabInfo.entityToken,
      provider: tabInfo.provider,
      label: tabInfo.label,
    }
  }
  if (tabInfo.type === 'kybAssistant') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      name: tabInfo.name,
    }
  }
  if (tabInfo.type === 'ai') {
    return {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
      action: tabInfo.action,
      reviewToken: tabInfo.reviewToken,
    }
  }
  if (tabInfo.type === 'attachment') {
    const base = {
      pinned: tabInfo.pinned,
      type: tabInfo.type,
    }
    if (tabInfo.attachmentType === 'attachment') {
      return {
        ...base,
        attachmentType: 'attachment',
        attachmentToken: tabInfo.attachmentToken,
      }
    }
    if (tabInfo.attachmentType === 'library') {
      return {
        ...base,
        attachmentType: 'library',
        attachmentToken: tabInfo.attachmentToken,
        attachmentFilename: tabInfo.attachmentFilename,
      }
    }
    if (tabInfo.attachmentType === 'reviewDocument') {
      return {
        ...base,
        attachmentType: 'reviewDocument',
        displayName: tabInfo.displayName,
        documentName: tabInfo.documentName,
        reviewToken: tabInfo.reviewToken,
      }
    }
    return {
      ...base,
      attachmentType: 'surveyFileUpload',
      questionToken: tabInfo.questionToken,
      clientId: tabInfo.clientId,
    }
  }
  return { pinned: tabInfo.pinned, type: tabInfo.type }
}

import { Field, FieldAttributes, FieldProps } from 'formik'

import { FormattedInput, FormattedInputProps } from './FormattedInput'
import { TextInput, TextInputProps } from './TextInput'

type SparseDateFormattedInputProps = Omit<FormattedInputProps, 'format' | 'mask'> & {
  legacyPartialDateFormat?: boolean
}

// Converts YYYY-MM-DD partial date to MMDDYYYY
export function partialDateToSparseDateInput(value: string | undefined) {
  const parts = (value ?? '').split('-')
  const year = parts[0]
  const month = parts[1]
  const day = parts[2]
  return `${month}${day}${year}`
}

// Converts MM/DD/YYYY partial date to YYYY-MM-DD
export function sparseDateOutputToPartialDate(value: string) {
  const parts = value.split('/')
  const month = parts[0]
  const day = parts[1]
  const year = parts[2]

  return [year, month, day].join('-')
}

/**
 * This component is designed to be used with date values that contain partial dates
 * (0 values for day, month, and/or year).
 *
 * It accepts two formats:
 * - YYYY-MM-DD (PREFERRED)
 * - MMDDYYYY (library people birthdate and identity document expiration date)
 */
export const SparseDateFormattedInput = ({
  value,
  legacyPartialDateFormat,
  ...props
}: SparseDateFormattedInputProps) => {
  let processedValue = value
  let transformValue: ((newValue: string) => string) | undefined = undefined

  // If given a value in format YYYY-MM-DD, convert to MMDDYYYY for the FormattedInput
  if (!legacyPartialDateFormat) {
    processedValue = partialDateToSparseDateInput(value as string)

    // When given a new value, convert back from MM/DD/YYYY to YYYY-MM-DD
    transformValue = sparseDateOutputToPartialDate
  }

  return (
    <FormattedInput
      format="##/##/####"
      mask={['M', 'M', 'D', 'D', 'Y', 'Y', 'Y', 'Y']}
      transformValue={transformValue}
      value={processedValue}
      {...props}
    />
  )
}

export type SparseDateInputProps = TextInputProps & {
  legacyPartialDateFormat?: boolean
}

const SparseDateInput = ({
  InputProps,
  legacyPartialDateFormat,
  ...props
}: SparseDateInputProps & FieldProps<unknown>) => (
  <TextInput
    InputProps={{
      inputComponent: SparseDateFormattedInput,
      inputProps: {
        legacyPartialDateFormat,
      },
      ...InputProps,
    }}
    {...props}
  />
)

export type SparseDateFieldProps = SparseDateInputProps & FieldAttributes<unknown>

export const SparseDateField = (props: SparseDateFieldProps) => (
  <Field {...props} placeholder="MM/DD/YYYY" component={SparseDateInput} />
)

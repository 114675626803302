// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import { shortName } from 'helpers/uiHelpers'
import { WithStyles, BasicAccount } from 'types/hb'

const styles = () => ({
  root: {
    fontWeight: 500,
    flexGrow: 0,
  },
})

interface Props extends WithStyles<typeof styles> {
  author: BasicAccount
}

export const AuthorName = ({ author, classes }: Props) => <div className={classes.root}>{shortName(author)}</div>

export default withStyles(styles)(AuthorName)

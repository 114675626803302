import React, { ReactNode, useState } from 'react'

import { DialogActions, DialogContent } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import Loader from 'components/library/Loader'
import DialogTitle from 'components/material/DialogTitle'
import KeyCaptureDialog from 'components/material/KeyCaptureDialog'
import { mergeOverrideStyles } from 'components/utils/styles'
import { useIsMountedRef } from 'hooks'
import { IconName } from 'icons/types'

import { KeyboardOrMouseEvent, Theme } from 'types/hb'

import { HbButton, HbButtonVariants } from '../HbComponents/HbButton'
import { HbText } from '../HbComponents/Text/HbText'

import { ComplexDialogActions, ComplexDialogContent, ComplexDialogHeader } from './Dialog/ComplexDialog'

const useStyles = makeStyles((theme: Theme) => ({
  actionsComponent: {},
  dialog: {},
  content: {
    paddingBottom: theme.spacing(2),
  },
}))

export interface Props {
  classes?: Partial<ReturnType<typeof useStyles>>
  variant?: 'simple' | 'complex'
  title: string
  icon?: IconName
  IconComponent?: React.ComponentType<{ className?: string }>
  confirmText: string
  open: boolean
  loading?: boolean
  onClose: (event: React.MouseEvent) => void
  ConfirmIconComponent?: React.ComponentType<{ className?: string }>
  confirmDisabled?: boolean
  confirmVariant?: HbButtonVariants
  cancelDisabled?: boolean
  onConfirm: (event: React.MouseEvent) => unknown
  children: any
  footerContent?: ReactNode
}

function ConfirmDialog(props: Props) {
  const {
    classes: overrideClasses,
    variant = 'simple',
    title,
    icon,
    IconComponent,
    ConfirmIconComponent,
    open,
    loading = false,
    onClose,
    cancelDisabled = false,
    confirmDisabled = false,
    confirmVariant = 'primary',
    confirmText,
    onConfirm,
    children,
    footerContent,
  } = props

  const baseClasses = useStyles()
  const classes = mergeOverrideStyles(baseClasses, overrideClasses)

  const ActionsComponent = variant === 'simple' ? DialogActions : ComplexDialogActions

  const ContentComponent = variant === 'simple' ? DialogContent : ComplexDialogContent

  const header =
    variant === 'simple' ? (
      <DialogTitle IconComponent={IconComponent} icon={icon} value={title} />
    ) : (
      <ComplexDialogHeader IconComponent={IconComponent} title={title} onClose={onClose} />
    )

  return (
    <KeyCaptureDialog className={classes.dialog} open={open} onClose={onClose} fullWidth>
      {header}
      <ContentComponent className={classes.content}>{children}</ContentComponent>
      {loading && <Loader variant="local" />}
      <ActionsComponent className={classes.actionsComponent}>
        {footerContent}
        <HbButton
          disabled={cancelDisabled}
          variant="textSecondary"
          onClick={onClose}
          label="Cancel"
          data-testid="dialog_cancel"
        />
        <HbButton
          Icon={ConfirmIconComponent}
          variant={confirmVariant}
          disabled={confirmDisabled}
          onClick={onConfirm}
          label={confirmText}
        />
      </ActionsComponent>
    </KeyCaptureDialog>
  )
}

/** Wraps a component, adding a confirmation dialog
before the action is performed. */
export function ConfirmAction(props: {
  title: string
  message: string
  onConfirm: (e: KeyboardOrMouseEvent) => void | Promise<void | null | undefined>
  onCancel?: (e: KeyboardOrMouseEvent) => void | Promise<void | null | undefined>
  children: (onClick: () => void) => ReactNode
}) {
  const mounted = useIsMountedRef()

  const { title, message, onConfirm, onCancel, children } = props
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  const onAction = () => {
    setOpen(!open)
  }

  const confirm = async (e: KeyboardOrMouseEvent) => {
    setLoading(true)
    await onConfirm(e)
    if (!mounted.current) {
      return
    }
    setLoading(false)
    setOpen(false)
  }

  const cancel = (e: KeyboardOrMouseEvent) => {
    onCancel?.(e)
    setOpen(false)
  }

  return (
    <>
      {children(onAction)}
      <ConfirmDialog
        title={title}
        open={open}
        confirmText="Ok"
        onClose={cancel}
        onConfirm={confirm}
        cancelDisabled={loading}
        confirmDisabled={loading}
      >
        <HbText data-testid="confirm-dialog-message">{message}</HbText>
      </ConfirmDialog>
    </>
  )
}

export default ConfirmDialog

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const MentionIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 15 15">
    <path d="M12.4 1.86l-.08-.06a7.51 7.51 0 1 0-2.07 12.68l-.3-1A6.51 6.51 0 1 1 8.51 1.09l.32.06.38.1a7 7 0 0 1 3.58 2.43 4.67 4.67 0 0 1-1.13 6.75 1.24 1.24 0 0 1-1.52-.19A1.81 1.81 0 0 1 9.91 9q.14-2 .38-4a.79.79 0 0 0 0-.43.74.74 0 0 0-.35-.3 3.49 3.49 0 0 0-2.45-.44 3.35 3.35 0 0 0-1.92 1.52 6.24 6.24 0 0 0-.87 3.88 2.68 2.68 0 0 0 1.07 2.07 2 2 0 0 0 1.73.11 3.13 3.13 0 0 0 1.37-1.11c-.12.16.51.77.62.85a2.06 2.06 0 0 0 1.05.4c2.87.25 4.08-3 4-5.35a5.82 5.82 0 0 0-2.14-4.34zm-3.65 7v.04A2.93 2.93 0 0 1 8 10a1.33 1.33 0 0 1-.9.4 1.06 1.06 0 0 1-1-.59 3 3 0 0 1-.21-1.7 5.64 5.64 0 0 1 .78-2.51 1.76 1.76 0 0 1 1.51-.93 2 2 0 0 1 .94.23z" />
  </SvgIcon>
)

export default MentionIcon

import { styled, Box } from '@mui/material'

import { HbText } from 'components/HbComponents/Text/HbText'
import { AuthBackground } from 'components/library/Onboarding/Background'

import MultiFactorAuthForm from './MultiFactorAuthForm'

const ControlsBox = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  width: '450px',
}))

export default function MultiFactorAuthSetup() {
  return (
    <AuthBackground>
      <HbText tag="h3" bold block>
        Your organization administrator has required you to set up Multi-Factor Authentication (MFA) for your account.
      </HbText>
      <ControlsBox>
        <MultiFactorAuthForm requireMfaMethod />
      </ControlsBox>
    </AuthBackground>
  )
}

import { AlertProps } from '@mui/lab/Alert/Alert'
// eslint-disable-next-line no-restricted-imports
import { Alert, alertClasses, styled } from '@mui/material'

const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.spacing(),
  [`&.${alertClasses.standard}`]: {
    // info variant
    [`&.${alertClasses.colorInfo}`]: {
      backgroundColor: theme.palette.styleguide.backgroundMedium,
      [`& .${alertClasses.icon}`]: {
        color: theme.palette.styleguide.textGreyDark,
      },
    },
    [`&.${alertClasses.colorError}`]: {
      backgroundColor: theme.palette.styleguide.errorLight,
    },
    [`&.${alertClasses.colorSuccess}`]: {
      backgroundColor: theme.palette.styleguide.successLight,
      color: theme.palette.styleguide.successDark,
    },
  },
}))

/** *
 * HbAlert is a thin wrapper around MUI Alert
 * https://v5.mui.com/api/alert/
 */
export const HbAlert = ({ className, ...props }: AlertProps) => {
  return <StyledAlert className={className} {...props} />
}

import { createStyles } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import classnames from 'classnames'
import { sortBy } from 'lodash'

import { swapToOrganization } from 'actions/applicationActions'
import { useDispatch, useSelector } from 'actions/store'
import { MenuListItem, MenuListItemDivider } from 'components/library/Menu'
import { getOrganizations } from 'helpers/stateHelpers'
import { DoneIcon } from 'icons'
import { WithStyles, Theme } from 'types/hb'

import { useHeaderMenuStyles } from './HeaderMenuStyles'

// We inject these from HeaderAccountMenu to keep
// the styles consistent.
const styles: Styles<Theme, object> = (theme: Theme) =>
  createStyles({
    item: {
      position: 'relative',
    },
    icon: {
      position: 'absolute',
      left: theme.spacing(),
      top: theme.spacing(),
      width: theme.spacing(3),
      height: theme.spacing(3),
      color: theme.palette.styleguide.blue,
    },
  })

type Props = WithStyles<typeof styles>

function OrganizationSwapper(props: Props) {
  const { classes } = props
  const menuClasses = useHeaderMenuStyles()

  const dispatch = useDispatch()
  const currentOrganizationToken = useSelector((state) => state.application.currentOrganizationToken)
  const organizations = useSelector(getOrganizations)

  if (organizations.length < 1) {
    return null
  }

  const onSwapClick = (token: string) => () => dispatch(swapToOrganization(token))

  return (
    <>
      <MenuListItemDivider className={menuClasses.divider} />
      <MenuListItem className={menuClasses.header} title>
        Organizations
      </MenuListItem>
      {sortBy(organizations, 'name').map((organization) => (
        <MenuListItem className={classnames(menuClasses.item, classes.item)} key={organization.token} link>
          {organization.token === currentOrganizationToken && <DoneIcon className={classes.icon} />}
          <button className={menuClasses.link} onClick={onSwapClick(organization.token)} type="button">
            {organization.name}
          </button>
        </MenuListItem>
      ))}
    </>
  )
}

export default withStyles(styles)(OrganizationSwapper)

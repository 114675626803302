import React, { ReactNode } from 'react'

import { DialogTitle as MuiDialogTitle } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'
import { Icon } from 'icons'
import { IconName } from 'icons/types'
import { Theme, WithStyles } from 'types/hb'

const styles = (theme: Theme) => ({
  headline: {
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItems: 'center',
  },
  icon: {
    marginRight: theme.spacing(2),
  },
})

interface Props extends WithStyles<typeof styles> {
  value: string
  IconComponent?: React.ComponentType<{ className?: string }>
  icon?: IconName
}

// Override default DialogTitle behavior to reset which variant we use for dialog titles.
function DialogTitle(props: Props) {
  const { value, IconComponent, icon, classes } = props

  let iconComponent: ReactNode
  if (IconComponent) {
    iconComponent = <IconComponent className={classes.icon} />
  } else if (icon) {
    iconComponent = <Icon classes={{ root: classes.icon }} name={icon} />
  }

  return (
    <MuiDialogTitle>
      <HbText size="xl" className={classes.headline}>
        {iconComponent}
        {value}
      </HbText>
    </MuiDialogTitle>
  )
}

export default withStyles(styles)(DialogTitle)

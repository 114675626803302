import { ReactNode } from 'react'

import { Fab } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbText } from 'components/HbComponents/Text/HbText'
import { opacify } from 'helpers/colors'
import { Theme } from 'types/hb'

const getGreyBackground = (theme: Theme) => opacify(theme.palette.background.dark, 0.6)
const BUTTON_SIZE = 36

const useControlsStyles = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(2),
  },
}))
interface ControlsProps {
  children: ReactNode
}
export function AttachmentViewerControls({ children }: ControlsProps) {
  const { root } = useControlsStyles()
  return <div className={root}>{children}</div>
}

const useButtonStyles = makeStyles((theme: Theme) => ({
  root: {
    // Hack for precedence since we're using the deprecated makeStyles API
    width: `${BUTTON_SIZE}px !important`,
    height: `${BUTTON_SIZE}px !important`,
    minWidth: `${BUTTON_SIZE}px !important`,
    color: theme.palette.styleguide.white,
    backgroundColor: getGreyBackground(theme),
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
    },
  },
}))
interface ButtonProps {
  onClick: () => void
  children: NonNullable<ReactNode>
}
export function AttachmentViewerButton({ children, onClick }: ButtonProps) {
  const { root } = useButtonStyles()
  return (
    <Fab className={root} onClick={onClick} size="small">
      {children}
    </Fab>
  )
}

const usePageStyles = makeStyles((theme: Theme) => ({
  root: {
    height: BUTTON_SIZE,
    borderRadius: 20,
    padding: theme.spacing(0, 1),
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    backgroundColor: getGreyBackground(theme),
  },
}))

export function AttachmentViewerPages({ currentPage, totalPages }: { currentPage: number; totalPages: number }) {
  const { root } = usePageStyles()
  const text = `${currentPage} / ${totalPages > 0 ? totalPages : ''}`
  return (
    <HbText color="white" className={root}>
      {/* the styles below give the element a more consistent width as the numbers change */}
      <span style={{ minWidth: `${text.length * 0.6}em` }}>{text}</span>
    </HbText>
  )
}

import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Box } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { isEqual } from 'lodash'

import { fetchTagGroups } from 'actions/organizationActions'
import { useDispatch, useSelector } from 'actions/store'
import { HbText } from 'components/HbComponents/Text/HbText'
import ApplicableTags from 'components/cases/report/tagging/ApplicableTags'

import { SimpleSearchInput } from 'components/library/Search/SimpleSearchInput'
import { TagGroup } from 'reducers/organizationReducer'
import { Theme } from 'types/hb'

import { TaskTypeTaggingFragment } from './__generated__/useReviewTypePrepopulatedFields.generated'
import ToggleSection from './components/ToggleSection'

const useStyles = makeStyles((theme: Theme) => ({
  count: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: theme.spacing(0.5),
    minWidth: 20,
    minHeight: 20,
    background: theme.palette.styleguide.backgroundDark,
    borderRadius: '50%',
    textAlign: 'center',
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.styleguide.textGreyDark,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(),
  },
}))

const TaskActivityTags = ({
  task,
  value,
  onChange,
  applicableTagGroups,
}: {
  task: TaskTypeTaggingFragment
  applicableTagGroups: TagGroup[]
  value: string[] | null
  onChange: (value: string[]) => void
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const [tagQuery, setTagQuery] = useState('')
  const clearQuery = () => setTagQuery('')
  const { title: taskTitle, token: taskToken } = task
  const tagsForThisTask = useMemo(
    () =>
      applicableTagGroups?.reduce((acc, group) => {
        const tokens = group.tags.map((tag) => tag.token)
        return [...acc, ...tokens]
      }, []),
    [applicableTagGroups]
  )

  const selectedTags = value?.map((t: string) => ({ token: t, applied: true }))

  const count = useMemo(() => {
    if (!value || !value.length) return 0
    return value.filter((token: string) => tagsForThisTask?.includes(token)).length
  }, [value, tagsForThisTask])

  const searchTags = useCallback(
    (searchTerm: string) => {
      dispatch(
        fetchTagGroups(
          taskToken,
          task.params.categories,
          task.params.groups,
          task.params.excludeGroups,
          task.params.includeOnly,
          searchTerm
        )
      )
    },
    [dispatch, taskToken, task]
  )

  useEffect(() => {
    searchTags(tagQuery)
  }, [searchTags, tagQuery])

  return (
    <ToggleSection
      toggle={
        <span className={classes.title}>
          <HbText>{taskTitle}</HbText>
          <span className={classes.count}>{count}</span>
        </span>
      }
      content={
        <>
          <Box maxWidth={300}>
            <SimpleSearchInput
              value={tagQuery}
              placeholder={`Search ${taskTitle.toLowerCase()}`}
              autoFocus
              onClear={clearQuery}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setTagQuery(e.target.value)}
            />
          </Box>
          <ApplicableTags
            key={taskToken}
            selectedTags={selectedTags || []}
            applicableTagGroups={applicableTagGroups || []}
            save={(newTagState) => {
              // need to update the form state with the tags that are included
              // in this task, while not blowing away the tags from other tasks
              const thisTaskTags = newTagState
                .filter((t) => t.applied && tagsForThisTask?.includes(t.token))
                .map((t) => t.token)
              const otherTasksTags = value
                ? value.filter((token: string) => tagsForThisTask?.includes(token) === false)
                : []

              onChange([...otherTasksTags, ...thisTaskTags])
            }}
            editable
          />
        </>
      }
      id={taskToken}
    />
  )
}

type Props = {
  tagggingTasks: TaskTypeTaggingFragment[]
  value: string[] | null
  onChange: (value: string[]) => void
}
const ActivityTagsByTask = ({ tagggingTasks, value, onChange }: Props) => {
  const tagGroups = useSelector((state) => state.organization.tagGroupsByKey)
  return (
    <>
      {tagggingTasks.map((task) => (
        <TaskActivityTags
          key={task.token}
          task={task}
          applicableTagGroups={tagGroups[task.token]}
          value={value}
          onChange={onChange}
        />
      ))}
    </>
  )
}

const areValuesEqual = (prevProps: Props, nextProps: Props) => {
  if (prevProps === nextProps) return true
  if (prevProps.tagggingTasks !== nextProps.tagggingTasks) return false
  if (prevProps.onChange !== nextProps.onChange) return false
  return isEqual(prevProps.value, nextProps.value)
}

export default React.memo(ActivityTagsByTask, areValuesEqual)

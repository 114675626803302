import { styled } from '@mui/material'

import AccountAvatar from 'components/accounts/AccountAvatar'

export const ASSIGNMENT_AVATAR_SIZE = 24

export const StyledAccountAvatar = styled(AccountAvatar)(({ theme }) => ({
  '&&': {
    boxSizing: 'border-box',
    width: ASSIGNMENT_AVATAR_SIZE,
    height: ASSIGNMENT_AVATAR_SIZE,
    ...theme.typography.sizes.xs,
    fontWeight: 600,
  },
}))

export const DEFAULT_ASSIGNEE_TOOLTIP_DELAY = 250

import React, { useContext } from 'react'

import { push } from 'connected-react-router'

import invariant from 'tiny-invariant'

import { Thunk } from 'actions/store'

import { getTabForLink, openTab } from 'reducers/tabReducer'
import { KeyboardOrMouseEvent } from 'types/hb'
import { shouldOpenNewTab } from 'utils/navigationHelpers'

import UrlResolver, { UrlResolverQuery } from './urlResolver'

interface StringMap {
  [key: string]: string
}

// A super light library for navigating around the site
class Pilot {
  private urls: StringMap
  private urlResolver: UrlResolver

  public constructor({ urls }: { urls: StringMap }) {
    this.urls = urls
    this.urlResolver = new UrlResolver()
  }

  // eslint-disable-next-line class-methods-use-this
  public goToUrl(to: string, event?: KeyboardOrMouseEvent): Thunk<void> {
    return (dispatch, getState) => {
      const tab = getTabForLink(getState(), to)
      const openNewTab = shouldOpenNewTab(event)

      if (tab) {
        dispatch(openTab({ tab, openInBackground: openNewTab }))
        return
      }
      if (openNewTab) {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window.open(to)
        return
      }

      dispatch(push(to))
    }
  }

  public go(urlPattern: string, options: StringMap = {}, query: UrlResolverQuery = {}, event?: KeyboardOrMouseEvent) {
    return this.goToUrl(this.urlResolver.resolve(urlPattern, options, query), event)
  }

  public compile(urlPattern: string, options: StringMap, query: UrlResolverQuery = {}) {
    return this.urlResolver.resolve(urlPattern, options, query)
  }

  public resolve(name: string, options: StringMap, query: UrlResolverQuery = {}) {
    const urlPattern = this.urls[name]

    if (!urlPattern) {
      throw new Error(`Cannot find url pattern ${name}`)
    }

    return this.urlResolver.resolve(urlPattern, options, query)
  }
}

export default Pilot

export const Context = React.createContext<Pilot | null>(null)

export function usePilot() {
  const pilot = useContext(Context)

  invariant(pilot, 'usePilot must be used within a PilotProvider')

  return pilot
}

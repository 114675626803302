import { AddOutlined } from '@mui/icons-material'

import invariant from 'tiny-invariant'

import { useSelector } from 'actions/store'
import { HbButton } from 'components/HbComponents/HbButton'
import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { ChangeCircleOutlinedIcon } from 'icons'

import { ActionConfig } from '../ActionConfig'
import { checkIsRule, useAutomationRuleOrTemplate } from '../AutomationRuleOrTemplateContext'
import { canSelectNewActionForDomainType } from '../SelectActionDialog'
import Step from '../Step'
import { FormSchemaReturnType } from '../formSchema'

interface Props {
  setActionModalOpen: (open: boolean) => void
  form: FormSchemaReturnType
  isTriggerType: boolean
}

export function ViewActionAutomationStep({ setActionModalOpen, form, isTriggerType }: Props) {
  const ruleOrTemplate = useAutomationRuleOrTemplate()
  const usage = useUsage()

  const { watch } = form

  const [domainType, actionType, automationType] = watch(['domain.type', 'actionType', 'automationType'])

  if (isTriggerType) {
    invariant(domainType, 'Missing domain type!')
  }

  const canSelect = useSelector((state) => canSelectNewActionForDomainType(state, domainType, automationType))

  const handleChangeAction = () => {
    invariant(ruleOrTemplate, 'Missing rule or template!')
    usage.logEvent({
      name: 'automations:editorChangeActionButton:clicked',
      data: checkIsRule(ruleOrTemplate)
        ? { automationRuleToken: ruleOrTemplate.token }
        : { automationRuleTemplateToken: ruleOrTemplate.token },
    })
    setActionModalOpen(true)
  }

  return (
    <Step
      key="thenStep"
      title="Then..."
      textField="actionText"
      type={actionType}
      showCollapseButton={!!actionType}
      ActionButton={
        !actionType ? (
          <HbButton
            Icon={AddOutlined}
            variant="secondary"
            size="small"
            label="Add Action"
            onClick={() => setActionModalOpen(true)}
          />
        ) : isTriggerType && canSelect ? (
          <HbButton
            variant="secondary"
            Icon={ChangeCircleOutlinedIcon}
            label="Change Action"
            size="small"
            onClick={handleChangeAction}
          />
        ) : null
      }
    >
      {actionType && <ActionConfig actionType={actionType} form={form} />}
    </Step>
  )
}

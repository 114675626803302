import {
  DEFAULT_FILTER_TITLE,
  checkExistingFilter,
  getFilterTitle,
  namedQueriesListMap,
} from 'dashboards/shared/react/getNamedQueryGroups'
import { Organization } from 'reducers/applicationReducer'
import { SavedSearchRequest } from 'types/api'
import { CurrentAccount } from 'types/hb'
import { SearchRequestWithId, removeInvalidFilters } from 'utils/query/api.types'

import { DashboardSlice } from './dashboards.constants'
import { AppliedFilters } from './dashboards.types'

export const makeGetNamedQueriesList =
  (dashboardSlice: DashboardSlice) => (currentAccount: CurrentAccount, currentOrganization: Organization) => {
    const getNamedQueriesList = namedQueriesListMap[dashboardSlice]
    const queryList = getNamedQueriesList({ currentAccount, currentOrganization })
    return queryList
  }

export const getNamedQueries = (queryList: ReturnType<ReturnType<typeof makeGetNamedQueriesList>>) =>
  queryList.flatMap((queryListItem) => queryListItem.queries)

export const getValidFilterOrDefaultFilterIfEmpty = (
  allFilters: AppliedFilters,
  defaultFilters: ReturnType<typeof getNamedQueries>[number]
) => {
  return removeInvalidFilters(allFilters) || defaultFilters?.request
}

export const getSearchRequestInfo = (
  request: SearchRequestWithId,
  customViews: SavedSearchRequest[] = [],
  currentViewTitle: string | undefined,
  namedQueries: ReturnType<typeof getNamedQueries>
) => {
  const filterProps = { request, namedQueries, customViews, currentViewTitle }
  // If the current title matches the default, then it's a new filter
  const existingFilter = checkExistingFilter(filterProps)
  const viewTitle = getFilterTitle(filterProps)
  const isCustom = namedQueries.findIndex((query) => query.title === viewTitle) === -1
  const isNew = viewTitle === DEFAULT_FILTER_TITLE

  return {
    viewTitle,
    isCreating: !existingFilter && isNew,
    isEditing: !existingFilter && isCustom && !isNew,
    isCustom,
  }
}

export const getViewTitle = (currentViewTitle: string, searchRequestInfo: ReturnType<typeof getSearchRequestInfo>) => {
  const { viewTitle: expectedViewTitle, isCustom, isEditing } = searchRequestInfo
  if (expectedViewTitle !== currentViewTitle && (!isCustom || (isCustom && !isEditing))) {
    return expectedViewTitle
  }
  return currentViewTitle
}

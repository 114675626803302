import { styled } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { ShowAfterDelay } from 'components/library/ShowAfterDelay'

import { HbAnimatedLogo } from './HbAnimatedLogo'
import { HbText } from './Text/HbText'

const useStyles = makeStyles(() => ({
  root: {
    display: 'grid',
    placeItems: 'center',
    boxSizing: 'border-box',

    paddingBottom: 80, // Move the loader slightly up, which looks nicer than perfect centering due to its shape
    height: '100%',
    width: '100%',
  },
}))

export const InlinePageLoader = function InnerInlinePageLoader() {
  return <HbAnimatedLogo />
}

const Text = styled(HbText)(() => ({
  alignSelf: 'start',
}))

export function PageLoader({ text }: { text?: string }) {
  const { root } = useStyles()

  return (
    <ShowAfterDelay delay={500}>
      <div className={root}>
        <div style={{ alignSelf: 'end' }}>
          <InlinePageLoader />
        </div>
        <Text>{text}</Text>
      </div>
    </ShowAfterDelay>
  )
}

import { gql } from '@apollo/client'

import { PAGE_INFO_FRAGMENT } from 'hooks/ApolloHelpers.fragments'

export const ENQUEUE_MIDDESK_BUSINESS_JOB_MUTATION = gql`
  mutation EnqueueMiddeskBusinessJob($input: EnqueueMiddeskBusinessJobInput!) {
    enqueueMiddeskBusinessJob(input: $input) {
      job {
        ... on MiddeskBusinessJob {
          token
          errorMessage
        }
      }
    }
  }
`

const MIDDESK_BUSINESS_JOB = gql`
  fragment MiddeskBusinessJob on MiddeskBusinessJob {
    status
    errorMessage
    middeskBusinessStatus
    result {
      name
      status
      addresses {
        addressLine1
        addressLine2
        city
        postalCode
        state
      }
      names {
        name
        type
      }
      people {
        name
        titles {
          title
        }
      }
      formation {
        formationDate
        formationState
      }
      industryClassification {
        categories {
          category
        }
      }
      phoneNumbers {
        phoneNumber
      }
      registrations {
        entityType
        fileNumber
        registrationDate
        state
      }
      tin {
        tin
      }
      watchlist {
        hitCount
      }
      website {
        domain {
          domain
        }
      }
    }
  }
`

export const MIDDESK_BUSINESS_JOB_QUERY = gql`
  query MiddeskBusinessJob($token: ID!) {
    middeskBusinessJob(token: $token) {
      ...MiddeskBusinessJob
    }
  }
  ${MIDDESK_BUSINESS_JOB}
`

const MIDDESK_BUSINESS = gql`
  fragment MiddeskBusiness on MiddeskBusiness {
    token
    status
    middeskBusinessId
    libraryToken
    wasInAudit
    createdAt
    updatedAt
    account {
      token
    }
  }
`

export const MIDDESK_BUSINESS_BY_LIBRARY_TOKEN = gql`
  query MiddeskBusinessByLibraryToken($libraryToken: ID!) {
    middeskBusinessesByLibraryToken(libraryToken: $libraryToken) {
      totalCount
      pageInfo {
        ...PageInfoFragment
      }
      edges {
        cursor
        node {
          ...MiddeskBusiness
        }
      }
    }
  }
  ${MIDDESK_BUSINESS}
  ${PAGE_INFO_FRAGMENT}
`

import { useEffect } from 'react'

import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext'

import { COMMAND_PRIORITY_LOW, KEY_ENTER_COMMAND } from 'lexical'

import { editorStateToHtml, editorStateToPlainText } from './utils'

export type Props = {
  onSubmit: (value: string) => unknown
  richText?: boolean
}

export default function SubmitCommandPlugin({ onSubmit, richText }: Props) {
  const [editor] = useLexicalComposerContext()

  useEffect(() => {
    const unregister = editor.registerCommand(
      KEY_ENTER_COMMAND,
      (event: KeyboardEvent | null) => {
        if (event?.metaKey || event?.shiftKey) {
          event.preventDefault()
          event.stopImmediatePropagation()
          const editorState = editor.getEditorState()
          const valuePromise = Promise.resolve(
            richText ? editorStateToHtml(editorState) : editorStateToPlainText(editorState)
          )
          valuePromise.then(onSubmit)
          return true
        }

        return false
      },
      COMMAND_PRIORITY_LOW // Ensure lower priority for enter here than for TypeAheadPlugin
    )

    return () => unregister()
  }, [editor, onSubmit, richText])

  return null
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const SaveIcon: FC<SvgProps> = ({ style = { fill: 'none' }, ...props }) => (
  <SvgIcon {...props} viewBox="0 0 18 18" style={style}>
    <path
      d="M14.4165 15.9959H4.38263C4.00246 15.9959 3.63787 15.8449 3.36905 15.576C3.10024 15.3072 2.94922 14.9426 2.94922 14.5625V4.52862C2.94922 4.14846 3.10024 3.78387 3.36905 3.51505C3.63787 3.24623 4.00246 3.09521 4.38263 3.09521H12.2664L15.8499 6.67873V14.5625C15.8499 14.9426 15.6989 15.3072 15.43 15.576C15.1612 15.8449 14.7966 15.9959 14.4165 15.9959Z"
      stroke="currentColor"
      strokeWidth="1.43341"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.9834 15.9958V10.2622H5.81641V15.9958"
      stroke="currentColor"
      strokeWidth="1.43341"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.81641 3.09521V6.67873H11.55"
      stroke="currentColor"
      strokeWidth="1.43341"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

export default SaveIcon

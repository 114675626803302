import { type HTMLProps, useRef, useEffect, forwardRef, ForwardedRef } from 'react'

import {
  Completion,
  CompletionContext,
  CompletionResult,
  autocompletion,
  closeBrackets,
  closeBracketsKeymap,
  completionKeymap,
} from '@codemirror/autocomplete'
import { defaultKeymap, history, historyKeymap } from '@codemirror/commands'
import { liquid } from '@codemirror/lang-liquid'
import { markdown, markdownLanguage } from '@codemirror/lang-markdown'
import { bracketMatching, defaultHighlightStyle, indentOnInput, syntaxHighlighting } from '@codemirror/language'
import { linter, type Diagnostic } from '@codemirror/lint'
import { EditorState } from '@codemirror/state'
import {
  EditorView,
  drawSelection,
  highlightSpecialChars,
  keymap,
  rectangularSelection,
  tooltips,
} from '@codemirror/view'

import { noop } from 'lodash'

import { useOtherInfoSearchQuery } from 'components/pages/automations/hooks/useOtherInfoSearchQuery'

import assist from './lang/assist'
import diagnose from './lang/diagnostics'
import { getIdentifierPattern } from './lang/lexer'
import { Variable } from './lang/util'

function getCompletionSource(
  getVariables: () => Array<Variable>,
  otherInfoLabels: (query: string | null) => Promise<Array<string>>
) {
  const identifierPattern = getIdentifierPattern()

  return async function completionSource(context: CompletionContext): Promise<CompletionResult | null> {
    const id = context.matchBefore(identifierPattern)

    const textUpToId = context.state.sliceDoc(0, context.pos - (id?.text.length ?? 0))
    const variables = getVariables()
    try {
      const suggestions = Array<Completion>()
      // If the user has started typing the label name, id will not be null and we can just check that other_info
      // precedes it. If not we need to check the text immediately preceding the current position, otherwise
      // the other info labels will randomly pop up when the user is typing if they already have an other info label present
      const shouldFetchOtherInfoLabels =
        id !== null ? textUpToId.includes('.other_info') : textUpToId.slice(-12).includes('other_info[')
      // retrieves the other info label variables based on what the user is typing
      if (shouldFetchOtherInfoLabels) {
        const labels = await otherInfoLabels(id?.text ?? null)
        labels.forEach((label) => {
          // TODO: There is probably a more robust way of showing these labels
          suggestions.push({
            label: textUpToId.includes('["') ? label : `"${label}"`,
            info: `Get value associated with the ${label} label`,
            type: 'variable',
          })
        })
      } else {
        const assistSuggestions = assist(textUpToId, id?.text ?? '', variables)
        assistSuggestions.identifierSuggestions.forEach((suggestion) => {
          suggestions.push({
            label: suggestion.path,
            info: suggestion.info,
            type: 'variable',
          })
        })
        assistSuggestions.keywordSuggestions.forEach((suggestion) => {
          suggestions.push({
            label: suggestion,
            type: 'keyword',
          })
        })
      }

      return { from: id?.from ?? context.pos, options: suggestions }
    } catch (e) {
      return null
    }
  }
}

function getDiagnostics(getVariables: () => Array<Variable>, domain: () => string | null | undefined) {
  return function boundGetDiagnostics(view: EditorView): Array<Diagnostic> {
    const text = view.state.sliceDoc(0)

    const diagnostics: Array<Diagnostic> = diagnose(text, domain(), getVariables())

    return diagnostics
  }
}

function getMentionCompletionSource(getMentions: () => Array<Mention>) {
  return function boundGetMentions(context: CompletionContext): CompletionResult | null {
    const match = context.matchBefore(/@\w*/)

    if (!match) {
      return null
    }

    const needle = match.text.slice(1)
    const options = getMentions()
      .filter((m) => m.value.includes(needle) || m.additionalSearch?.some((s) => s.includes(needle)))
      .map((m) => ({ label: `@${m.value}`, info: m.info, type: 'text' }))

    if (options.length > 0) {
      return { options, from: match.from }
    }

    return null
  }
}

export type Mention = { info: string; value: string; additionalSearch?: Array<string> }

export type Api = { replaceSelection: (str: string) => void; focus: () => void; hasFocus: () => boolean }

export type Props = Pick<HTMLProps<HTMLDivElement>, 'className'> & {
  initialValue?: string
  variables?: Array<Variable>
  mentions?: Array<Mention>
  domain?: string | null
  onChange?: (value: string) => unknown
  onFocus?: (e: React.FocusEvent<HTMLDivElement>) => unknown
}

const theme = EditorView.theme(
  {
    '&.cm-focused': { outline: 'none' },
    '& .cm-line': { paddingLeft: 0 },
    '& .cm-tooltip-autocomplete': { zIndex: 2000 }, // more than m-ui dialog 1300
    '& .cm-cursor': { marginLeft: `1px` }, // makes the cursor show up when the content is empty
  },
  { dark: false }
)

function LiquidMarkdownEditor(
  { className, initialValue = '', variables = [], mentions = [], domain, onChange, onFocus }: Props,
  apiRef: ForwardedRef<Api>
) {
  const rootRef = useRef<HTMLDivElement | null>(null)
  const stableRef = useRef({ initialValue, onChange, variables, domain, mentions, apiRef })
  stableRef.current = { initialValue, onChange, variables, domain, mentions, apiRef }

  const { otherInfoLabels } = useOtherInfoSearchQuery(domain || null)

  useEffect(() => {
    const { current: root } = rootRef

    if (!root) {
      return noop
    }

    const ed = new EditorView({
      parent: root,
      state: EditorState.create({
        doc: stableRef.current.initialValue,
        extensions: [
          highlightSpecialChars(),
          history(),
          drawSelection(),
          EditorState.allowMultipleSelections.of(true),
          indentOnInput(),
          syntaxHighlighting(defaultHighlightStyle, { fallback: true }),
          bracketMatching(),
          closeBrackets(),
          rectangularSelection(),
          keymap.of([...closeBracketsKeymap, ...defaultKeymap, ...historyKeymap, ...completionKeymap]),
          liquid({ base: markdown({ base: markdownLanguage }) }),
          tooltips({ parent: document.body }),
          autocompletion({
            override: [
              getCompletionSource(() => stableRef.current.variables, otherInfoLabels),
              getMentionCompletionSource(() => stableRef.current.mentions),
            ],
          }),
          linter((view) => {
            return getDiagnostics(
              () => stableRef.current.variables,
              () => stableRef.current.domain
            )(view)
          }),
          EditorView.lineWrapping,
          theme,
          EditorView.updateListener.of((update) => {
            if (update.docChanged) {
              stableRef.current.onChange?.(update.state.doc.toString())
            }
          }),
        ],
      }),
    })

    const api: Api = {
      replaceSelection: (str: string) => {
        const transaction = ed.state.replaceSelection(str)
        ed.dispatch(transaction)
        setTimeout(() => ed.focus(), 50)
      },
      focus: () => {
        ed.focus()
      },
      hasFocus: () => {
        return ed.hasFocus
      },
    }

    if (stableRef.current.apiRef) {
      if (typeof stableRef.current.apiRef === 'function') {
        stableRef.current.apiRef(api)
      } else {
        // eslint-disable-next-line no-param-reassign
        stableRef.current.apiRef.current = api
      }
    }

    return () => {
      ed.destroy()
    }
  }, [otherInfoLabels])

  return <div className={className} ref={rootRef} onFocus={onFocus} />
}

export default forwardRef<Api, Props>(LiquidMarkdownEditor)

import { ComponentType } from 'react'

import {
  ArrowDropDownCircleOutlined,
  CalendarTodayOutlined,
  LensOutlined,
  MonetizationOnOutlined,
  NumbersOutlined,
  TextFieldsOutlined,
} from '@mui/icons-material'

import { Option } from 'components/material/Form'
import { CustomFieldDatatypeEnum, OtherInfoLabelDisplayAsEnum, OtherInfoLabelTypeEnum } from 'types/api'

export const labelTypeOptions: Option[] = [
  { display: 'Bank Account', value: OtherInfoLabelTypeEnum.BankAccount },
  { display: 'Business', value: OtherInfoLabelTypeEnum.Business },
  { display: 'Crypto Address', value: OtherInfoLabelTypeEnum.CryptoAddress },
  { display: 'Device', value: OtherInfoLabelTypeEnum.Device },
  { display: 'Institution', value: OtherInfoLabelTypeEnum.FinancialInstitution },
  { display: 'Payment Card', value: OtherInfoLabelTypeEnum.PaymentCard },
  { display: 'Person', value: OtherInfoLabelTypeEnum.Person },
  { display: 'Product', value: OtherInfoLabelTypeEnum.Product },
  { display: 'Review', value: OtherInfoLabelTypeEnum.Review },
  { display: 'Transaction', value: OtherInfoLabelTypeEnum.Transaction },
]

export const deserializeLabelType = (type: string | undefined): OtherInfoLabelTypeEnum | null => {
  switch (type) {
    case 'bank_account':
      return OtherInfoLabelTypeEnum.BankAccount
    case 'business':
      return OtherInfoLabelTypeEnum.Business
    case 'crypto_address':
      return OtherInfoLabelTypeEnum.CryptoAddress
    case 'device':
      return OtherInfoLabelTypeEnum.Device
    case 'financial_institution':
      return OtherInfoLabelTypeEnum.FinancialInstitution
    case 'payment_card':
      return OtherInfoLabelTypeEnum.PaymentCard
    case 'person':
      return OtherInfoLabelTypeEnum.Person
    case 'product':
      return OtherInfoLabelTypeEnum.Product
    case 'review':
      return OtherInfoLabelTypeEnum.Review
    case 'review_data':
      return OtherInfoLabelTypeEnum.ReviewData
    case 'transaction':
      return OtherInfoLabelTypeEnum.Transaction
    case '':
      return null
    default:
      throw new Error(`Unknown field type '${type}'`)
  }
}

export const supportedOnDashboards = (type: OtherInfoLabelTypeEnum): boolean => {
  switch (type) {
    case OtherInfoLabelTypeEnum.Transaction:
    case OtherInfoLabelTypeEnum.Review:
    case OtherInfoLabelTypeEnum.ReviewData:
      return true
    default:
      return false
  }
}

export const additionalDetailsDisplayAs = (isRenameOtherInfoEnabled: boolean): Option => ({
  display: isRenameOtherInfoEnabled ? 'Additional Details' : 'Other Info',
  value: OtherInfoLabelDisplayAsEnum.OtherInfo,
})
export const defaultFieldsDisplayAs = (isRenameOtherInfoEnabled: boolean): Option => ({
  display: isRenameOtherInfoEnabled ? 'Default Fields' : 'Custom Field',
  value: OtherInfoLabelDisplayAsEnum.CustomInfo,
})

export const displayAsOptionsByLabelType = (
  type: OtherInfoLabelTypeEnum,
  transactionsSuperInfoEnabled: boolean,
  isRenameOtherInfoEnabled: boolean
): Option[] => {
  const additionalDetailsDisplayAsValue = additionalDetailsDisplayAs(isRenameOtherInfoEnabled)
  const defaultFieldsDisplayAsValue = defaultFieldsDisplayAs(isRenameOtherInfoEnabled)
  switch (type) {
    case OtherInfoLabelTypeEnum.BankAccount:
    case OtherInfoLabelTypeEnum.Business:
    case OtherInfoLabelTypeEnum.CryptoAddress:
    case OtherInfoLabelTypeEnum.Device:
    case OtherInfoLabelTypeEnum.FinancialInstitution:
    case OtherInfoLabelTypeEnum.PaymentCard:
    case OtherInfoLabelTypeEnum.Person:
    case OtherInfoLabelTypeEnum.Product:
      return [defaultFieldsDisplayAsValue, additionalDetailsDisplayAsValue]
    case OtherInfoLabelTypeEnum.Transaction:
      return transactionsSuperInfoEnabled
        ? [defaultFieldsDisplayAsValue, additionalDetailsDisplayAsValue]
        : [additionalDetailsDisplayAsValue]
    default:
      return [additionalDetailsDisplayAsValue]
  }
}

export const currentDisplayOptionIsSupported = (
  type: OtherInfoLabelTypeEnum,
  displayAs: OtherInfoLabelDisplayAsEnum,
  transactionsSuperInfoEnabled: boolean,
  isRenameOtherInfoEnabled: boolean
): boolean => {
  const currentOptions = displayAsOptionsByLabelType(type, transactionsSuperInfoEnabled, isRenameOtherInfoEnabled).map(
    (option) => option.value
  )
  return currentOptions.includes(displayAs)
}

export const datatypeOptions: Option[] = [
  { display: 'Text', value: CustomFieldDatatypeEnum.Text },
  { display: 'Dropdown', value: CustomFieldDatatypeEnum.Dropdown },
  { display: 'Yes/No', value: CustomFieldDatatypeEnum.Yesno },
  { display: 'Date', value: CustomFieldDatatypeEnum.Date },
  { display: 'Number', value: CustomFieldDatatypeEnum.Number },
  { display: 'Currency', value: CustomFieldDatatypeEnum.Currency },
]

const dataTypeIconsConfig: Record<CustomFieldDatatypeEnum, ComponentType> = {
  [CustomFieldDatatypeEnum.Currency]: MonetizationOnOutlined,
  [CustomFieldDatatypeEnum.Date]: CalendarTodayOutlined,
  [CustomFieldDatatypeEnum.Dropdown]: ArrowDropDownCircleOutlined,
  [CustomFieldDatatypeEnum.Number]: NumbersOutlined,
  [CustomFieldDatatypeEnum.Text]: TextFieldsOutlined,
  [CustomFieldDatatypeEnum.Yesno]: LensOutlined,
}

export const getDataTypeIcon = (dataType: CustomFieldDatatypeEnum | null | undefined) =>
  dataTypeIconsConfig[dataType || CustomFieldDatatypeEnum.Text]

export const isWithinRange = (minInclusive: number, maxInclusive: number, value: number) =>
  value >= minInclusive && value <= maxInclusive

export const getPageCount = (pageSize: number, totalEntries: number) => Math.ceil(totalEntries / pageSize)

export const getPageRange = ({
  page,
  pageSize,
  totalEntries,
}: {
  page: number
  pageSize: number
  totalEntries: number
}) => {
  if (page === 0 || totalEntries === 0) {
    return [0, 0]
  }
  const start = (page - 1) * pageSize + 1
  const end = Math.min(start - 1 + pageSize, totalEntries)
  if (start > totalEntries) {
    const lastLowerPage = Math.floor(totalEntries / pageSize) * pageSize + 1
    return [lastLowerPage, end]
  }
  return [start, end]
}

export const getPageBasedOnPageSize = ({
  start,
  pageSize,
  totalEntries,
}: {
  start: number
  pageSize: number
  totalEntries: number
}) => {
  for (let i = 1; i <= getPageCount(pageSize, totalEntries); i += 1) {
    const [currentLower, currentUpper] = getPageRange({ page: i, pageSize, totalEntries })
    if (isWithinRange(currentLower, currentUpper, start)) {
      return i
    }
  }
  return 1
}

export const filterPageSizeChoices = ({
  pageSizeChoices,
  pageSize,
  totalEntries,
}: {
  pageSizeChoices: Array<number>
  pageSize: number
  totalEntries: number
}) => {
  const sorted = pageSizeChoices.sort((a, b) => a - b)
  const filtered = []
  for (let i = 0; i <= sorted.length; i += 1) {
    const size = sorted[i]
    if (
      i === 0 ||
      size <= totalEntries ||
      (size >= totalEntries && sorted[i - 1] < totalEntries) ||
      size === pageSize
    ) {
      filtered.push(size)
    } else {
      return filtered
    }
  }
  return filtered
}

export const getEntriesCountText = ({
  start,
  end,
  totalEntries,
  maxEntries,
}: {
  start: number
  end: number
  totalEntries: number
  maxEntries: number
}) => {
  switch (totalEntries) {
    case 0:
      return undefined
    case 1:
      return 'Showing 1 of 1'
    default:
      return `Showing ${start}–${end} of ${totalEntries}${totalEntries >= maxEntries ? '+' : ''}`
  }
}

import { useEffect } from 'react'

import { useQuery } from '@apollo/client'

import { useDispatch } from 'actions/store'

import {
  GetSavedSearchQueryQuery,
  GetSavedSearchQueryQueryVariables,
} from 'dashboards/reviews/gql/__generated__/searchReviews.queries.generated'
import { GET_SAVED_SEARCH_QUERY } from 'dashboards/reviews/gql/searchReviews.queries'
import { useDashboardContext } from 'dashboards/shared/components/DashboardContextProvider'
import { useDashboardActions } from 'reducers/dashboards/dashboards.actions'
import { SavedSearchRequest } from 'types/api'

export function useFetchCustomViews() {
  const dispatch = useDispatch()
  const dashboardsActions = useDashboardActions()
  const dashboardType = useDashboardContext()

  const { data, loading, refetch } = useQuery<GetSavedSearchQueryQuery, GetSavedSearchQueryQueryVariables>(
    GET_SAVED_SEARCH_QUERY,
    { variables: { dashboardType } }
  )
  const updatedCustomViews = data?.currentAccount.savedSearchRequests.nodes as SavedSearchRequest[]

  useEffect(() => {
    if (!loading) {
      dispatch(dashboardsActions.customViews.set(updatedCustomViews))
    }
  }, [updatedCustomViews, dispatch, loading, dashboardsActions.customViews])

  return { refetch }
}

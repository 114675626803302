import { enqueueReduxSnackbar } from 'actions/applicationActions'

import { Thunk } from './store'

export interface ErrorAction {
  type:
    | 'SET_FORM_ERROR_MESSAGE'
    | 'DISMISS_FORM_ERROR_MESSAGE'
    | 'CLEAR_FORM_ERROR_DISMISSAL'
    | 'CLEAR_FORM_ERROR'
    | 'CLEAR_ALL_FORM_ERRORS'
  message?: string
  token?: string
}

export const CLEAR_ALL_FORM_ERRORS = 'CLEAR_ALL_FORM_ERRORS'
export const SET_FORM_ERROR_MESSAGE = 'SET_FORM_ERROR_MESSAGE'
export const DISMISS_FORM_ERROR_MESSAGE = 'DISMISS_FORM_ERROR_MESSAGE'
export const CLEAR_FORM_ERROR_DISMISSAL = 'CLEAR_FORM_ERROR_DISMISSAL'
export const CLEAR_FORM_ERROR = 'CLEAR_FORM_ERROR'

export function setFormErrorMessage(message: string | null, token: string) {
  return { type: SET_FORM_ERROR_MESSAGE, message, token }
}

export function dismissFormErrorMessage(token: string) {
  return { type: DISMISS_FORM_ERROR_MESSAGE, token }
}

export function clearFormErrorDismissal(token: string) {
  return { type: CLEAR_FORM_ERROR_DISMISSAL, token }
}

export function clearFormError(token: string) {
  return { type: CLEAR_FORM_ERROR, token }
}

export function clearAllFormErrors() {
  return { type: CLEAR_ALL_FORM_ERRORS }
}

/**
 * NOTE: This is not an error action, but an application action
 * @return {Object} an action to be handled by the applicationReducer
 */
export function setFlashError(message: string): Thunk<void> {
  return (dispatch, getState, { usage }) => {
    usage.trackChurnZeroEvent('ErrorToast')
    usage.logEvent({ name: 'application:errorToast:failure' })
    dispatch(enqueueReduxSnackbar(message, { variant: 'error' }))
  }
}

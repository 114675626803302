/* eslint-disable camelcase */

import { useMemo } from 'react'

import { customAlphabet, nanoid } from 'nanoid'

import { OrganizationBadges } from 'components/settings/ReviewTypes/ReviewType/types'

import { EditReviewTypeState } from 'reducers/editReviewType.reducer'

import {
  StageEditTaskFragment,
  StageEditTask_TaskTypeSurvey_Fragment,
  OrgBadgeFragment,
  GetOrgInfoQuery,
  StageEditPageFragment,
} from './__generated__/queries.generated'

export const isTaskPredefined = (typeName: StageEditTaskFragment['__typename']) => {
  switch (typeName) {
    case 'TaskTypeRequestForInformationPrepopulation':
    case 'TaskTypeDisputeSurvey':
    case 'TaskTypeSurvey':
    case 'TaskTypeResearch':
      return false
    default:
      return true
  }
}

export const isTaskEditable = (typeName: StageEditTaskFragment['__typename']) => {
  switch (typeName) {
    case 'TaskTypeResearch':
    case 'TaskTypeRequestForInformationPrepopulation':
    case 'TaskTypeDisputeSurvey':
    case 'TaskTypeSurvey':
      return true
    default:
      return false
  }
}

export const isTaskSurvey = (
  task?: StageEditTaskFragment | null
): task is StageEditTaskFragment & {
  survey: NonNullable<StageEditTask_TaskTypeSurvey_Fragment['survey']>
  __typename: 'TaskTypeDisputeSurvey' | 'TaskTypeSurvey' | 'TaskTypeRequestForInformationPrepopulation'
} => {
  if (task === null || task === undefined) return false

  switch (task.__typename) {
    case 'TaskTypeRequestForInformationPrepopulation':
    case 'TaskTypeDisputeSurvey':
    case 'TaskTypeSurvey':
      return !!task.survey
    default:
      return false
  }
}

export const generateSlug = (label: string) => {
  const LETTER_MATCH = /[^\p{L}0-9-& ]+/gu
  const customNanoid = customAlphabet('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz', 10)
  const slug = label.slice(0, 20).replace(/&/g, 'and').replace(LETTER_MATCH, '').toLowerCase().trim().replace(/ /g, '-')
  return `${slug}-${customNanoid()}`
}

/**
 * Check that the required badge is present in the organization badges
 * If it is not, it has probably been deleted
 * and we should not use it
 */
export const getRequiredBadge = (badgeName: string | null, badges: OrganizationBadges) => {
  return badges.find((b) => b?.name === badgeName) ? badgeName : null
}

export const useGetAvailableBadges = (badges?: GetOrgInfoQuery['currentOrganization']['badges']) => {
  // easier to work with an array of badges
  // vs edges/nodes
  return useMemo(
    () =>
      badges?.edges?.reduce(
        (
          acc: OrgBadgeFragment[],
          edge: NonNullable<GetOrgInfoQuery['currentOrganization']['badges']['edges']>[number]
        ) => {
          if (edge?.node) {
            acc.push(edge.node)
          }
          return acc
        },
        []
      ) || [],
    [badges?.edges]
  )
}

export const getOtherTaskTitlesForAction = ({
  state,
  actionSlug,
  currentTaskToken,
}: {
  state: EditReviewTypeState
  actionSlug: string
  currentTaskToken: string
}) => {
  const tasks = state.reviewType?.actions.find((a) => a.slug === actionSlug)?.tasks
  return (
    tasks?.reduce<string[]>((acc, task) => {
      if (task.token !== currentTaskToken) {
        acc.push(task.title)
      }
      return acc
    }, []) || []
  )
}

export const getOtherActionTitles = ({ state, actionSlug }: { state: EditReviewTypeState; actionSlug: string }) => {
  return (
    state.reviewType?.actions?.reduce<string[]>((acc, action) => {
      if (action.slug !== actionSlug) {
        acc.push(action.title)
      }
      return acc
    }, []) || []
  )
}

const duplicateTasks = (
  tasks: StageEditTaskFragment[],
  taskRequirements: StageEditPageFragment['taskRequirements']
): [StageEditTaskFragment[], StageEditPageFragment['taskRequirements']] => {
  const duplicatedTasks: StageEditTaskFragment[] = []
  let duplicatedTaskRequirements: StageEditPageFragment['taskRequirements'] = taskRequirements
  tasks.forEach((task) => {
    const originalSlug = task.slug
    const duplicatedTask = {
      ...task,
      token: nanoid(),
      slug: generateSlug(task.slug),
    }
    duplicatedTasks.push(duplicatedTask)

    // update task requirements
    duplicatedTaskRequirements = duplicatedTaskRequirements.map((requirement) => {
      if (requirement.task?.slug === originalSlug) {
        return {
          ...requirement,
          task: {
            ...requirement.task,
            slug: duplicatedTask.slug,
          },
        }
      }
      return requirement
    })
  })
  return [duplicatedTasks, duplicatedTaskRequirements]
}

export const duplicateReviewType = (reviewType: NonNullable<EditReviewTypeState['reviewType']>) => {
  const reviewTypeToken = nanoid()

  const duplicatedActions: StageEditPageFragment[] = []
  let duplicatedActionRequirements = [...reviewType.actionRequirements]
  reviewType.actions.forEach((action) => {
    const originalSlug = action.slug

    // copy over the tasks
    const [duplicatedTasks, duplicatedTaskRequirements] = duplicateTasks(action.tasks, action.taskRequirements)

    // copy over the action
    const duplicateAction = {
      ...action,
      token: nanoid(),
      slug: generateSlug(action.slug),
      tasks: duplicatedTasks,
      taskRequirements: duplicatedTaskRequirements,
    }

    // update action requirements
    duplicatedActionRequirements = duplicatedActionRequirements.map((requirement) => {
      if (requirement.requiredAction?.slug === originalSlug) {
        return {
          ...requirement,
          requiredAction: {
            ...requirement.requiredAction,
            slug: duplicateAction.slug,
          },
        }
      }
      if (requirement.action?.slug === originalSlug) {
        return {
          ...requirement,
          action: {
            ...requirement.action,
            slug: duplicateAction.slug,
          },
        }
      }
      return requirement
    })

    duplicatedActions.push(duplicateAction)
  })

  return {
    ...reviewType,
    name: `${reviewType.name} (duplicate)`,
    token: reviewTypeToken,
    canonicalReviewType: {
      ...reviewType.canonicalReviewType,
      canonicalId: generateSlug(reviewType.name),
      currentReviewType: {
        ...reviewType.canonicalReviewType.currentReviewType,
        token: reviewTypeToken,
      },
    },
    actions: duplicatedActions,
    actionRequirements: duplicatedActionRequirements,
  }
}

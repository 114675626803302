import { useCallback } from 'react'

import { gql } from '@apollo/client'

import { useSnackbar } from 'notistack'

import { useHbMutation } from 'hooks/ApolloHelpers'

import {
  UseTriggerAutomationRulePreviewModeRunFragment as AutomationRule,
  TriggerAutomationPreviewModeRunMutation,
  TriggerAutomationPreviewModeRunMutationVariables,
} from './__generated__/useTriggerPreviewModeRun.generated'

export const fragment = gql`
  fragment UseTriggerAutomationRulePreviewModeRun on CanonicalAutomationRule {
    ... on CanonicalAutomationRuleBase {
      __typename
      token
      ... on TriggerAutomationRule {
        previewModePendingRun {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
        previewModeManualRuns {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
      }
      ... on ScheduleWithTriggerAutomationRule {
        upcomingScheduledRunSummary {
          nextRunDate
          totalEventsMatchedCount
        }
        previewModePendingRun {
          startingAtDate
          totalEventsMatchedCount
          domainType
        }
        previewModeManualRuns {
          startingAtDate
          totalEventsMatchedCount
          totalEventsActionedCount
          domainType
          ranAt
        }
      }
    }
  }
`

const TRIGGER_AUTOMATION_RULE_PREVIEW_MODE_RUN = gql`
  mutation TriggerAutomationPreviewModeRun($input: TriggerPreviewModeRunInput!) {
    triggerAutomationRulePreviewModeRun(input: $input) {
      automationRule {
        ...UseTriggerAutomationRulePreviewModeRun
      }
    }
  }
  ${fragment}
`

export function useTriggerPreviewModeRun(automationRule: AutomationRule) {
  const { enqueueSnackbar } = useSnackbar()

  const [triggerAutomationRulePreviewModeRun, { loading: triggerAutomationRulePreviewModeRunLoading }] = useHbMutation<
    TriggerAutomationPreviewModeRunMutation,
    TriggerAutomationPreviewModeRunMutationVariables
  >(TRIGGER_AUTOMATION_RULE_PREVIEW_MODE_RUN, {
    flashError: true,
    onCompleted: () => {
      enqueueSnackbar('Automation successfully ran', {
        variant: 'success',
        preventDuplicate: false,
        autoHideDuration: 1000,
      })
    },
    refetchQueries: ['ViewAutomation', 'PreviewModeTablePaginated'],
    logEvent: {
      name: 'automations:triggerPreviewModeRun:clicked',
      data: {
        automationRuleToken: automationRule.token,
      },
    },
  })

  const handleTriggerPreviewModeRun = useCallback(() => {
    triggerAutomationRulePreviewModeRun({
      variables: {
        input: {
          token: automationRule.token,
        },
      },
    })
  }, [triggerAutomationRulePreviewModeRun, automationRule])

  return {
    triggerAutomationPreviewModeRun: handleTriggerPreviewModeRun,
    loading: triggerAutomationRulePreviewModeRunLoading,
  }
}

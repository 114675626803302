import React from 'react'

import { AddCircleOutline } from '@mui/icons-material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { HbButton } from 'components/HbComponents/HbButton'
import { TrashOutlineIcon } from 'icons'
import { Theme } from 'types/hb'

const useSharedStyles = makeStyles((theme: Theme) => ({
  addButton: {
    display: 'flex',
    color: theme.palette.action.active,
    borderRadius: 8,
    alignItems: 'center',
    backgroundColor: 'inherit',

    '&:hover': { backgroundColor: theme.palette.background.selected, color: theme.palette.action.active },
  },
  deleteIcon: {
    marginLeft: theme.spacing(),
  },
}))

interface AddButtonProps {
  label: string
  onClick: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
}

export const AddButton = (props: AddButtonProps) => {
  const { addButton } = useSharedStyles()

  return <HbButton {...props} variant="textSecondary" className={addButton} Icon={AddCircleOutline} />
}

export const DeleteButton = ({
  onClick,
  disabled,
}: {
  onClick?: React.MouseEventHandler<HTMLElement>
  disabled?: boolean
}) => (
  <HbButton
    label="Delete"
    disabled={disabled}
    className={useSharedStyles().deleteIcon}
    onClick={
      onClick ||
      ((e) => {
        e.stopPropagation()
      })
    }
    Icon={TrashOutlineIcon}
    iconOnly
  />
)

import { ReactElement, ReactNode } from 'react'

import { isArray, keyBy } from 'lodash'

import { InputResponse } from './InputResponse'

const getRenderedValue = ({
  customComponent,
  options,
  value,
}: Pick<SelectReadOnlyProps, 'customComponent'> & {
  value: string | number | boolean
  options: Record<string, { display: ReactNode; value: string | number | boolean }>
}) => {
  const valueKey = String(value)
  return customComponent
    ? customComponent({ display: options?.[valueKey]?.display, value })
    : options[valueKey]?.display || '-'
}

export interface SelectReadOnlyProps {
  value: string | string[] | boolean
  label?: ReactNode
  options?: { display: ReactNode; value: string | number | boolean }[] | null
  className?: string
  customComponent?: (option: { display: ReactNode; value: string | number | boolean }) => ReactElement
  hideLabel?: boolean
}

export const SelectReadOnly = ({
  value,
  label,
  className,
  customComponent,
  hideLabel,
  ...rest
}: SelectReadOnlyProps) => {
  const options = keyBy(rest.options, 'value')
  if (!options) {
    return null
  }

  if (isArray(value)) {
    return (
      <InputResponse className={className} label={hideLabel ? '' : label}>
        <ul>
          {value.map((v) => {
            return <li key={`select-${v}`}>{getRenderedValue({ customComponent, options, value: v })}</li>
          })}
        </ul>
      </InputResponse>
    )
  }

  return (
    <InputResponse className={className} label={label}>
      {getRenderedValue({ customComponent, options, value })}
    </InputResponse>
  )
}

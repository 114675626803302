import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const LabelUnusualIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M17.63,5.84 L22,12 L17.63,18.16 C17.27,18.67 16.67,19 16,19 L5,18.99 C3.9,18.99 3,18.1 3,17 L3,7 C3,5.9 3.9,5.01 5,5.01 L16,5 C16.67,5 17.27,5.33 17.63,5.84 Z M11.9090909,15.5454545 L11.9090909,13.7272727 L10.0909091,13.7272727 L10.0909091,15.5454545 L11.9090909,15.5454545 Z M11.9090909,12.272727 L11.9090909,8.272727 L10.0909091,8.272727 L10.0909091,12.272727 L11.9090909,12.272727 Z" />
  </SvgIcon>
)

export default LabelUnusualIcon

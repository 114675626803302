import { dismissLoginBanner } from 'actions/applicationActions'
import { useSelector, useDispatch } from 'actions/store'
import HeaderBanner from 'components/library/HeaderBanner'

import { getLoginBannerMessage } from 'helpers/stateHelpers'

/**
 * Component that displays a by-organizaiton configurable banner
 * upon login. Banner can be dismissed and the boolean state of if it's
 * been dismissed is stored in session so that banner only reappears
 * upon new sign-in
 */
export function LoginBanner() {
  const dispatch = useDispatch()
  const open = useSelector((state) => !state.application.loginBannerDismissed)
  const message = useSelector(getLoginBannerMessage)

  if (!message) {
    return null
  }

  const handleClose = () => dispatch(dismissLoginBanner())

  return <HeaderBanner message={message} open={open} onClose={handleClose} />
}

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const ProductsInstrumentsIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M22,10 C20.9,10 20,10.9 20,12 C20,13.1 20.9,14 22,14 L22,18 C22,19.1 21.1,20 20,20 L4,20 C2.9,20 2,19.1 2,18 L2,14 C3.11,14 4,13.1 4,12 C4,10.9 3.11,10 2.01,10 L2.01,6 C2.01,4.89 2.9,4 4,4 L20,4 C21.1,4 22,4.89 22,6 L22,10 Z M12.2298625,11.3888889 C10.891945,11.0611111 10.4616896,10.7222222 10.4616896,10.1944444 C10.4616896,9.58888889 11.0569745,9.16666667 12.0530452,9.16666667 C13.1021611,9.16666667 13.4911591,9.63888889 13.5265226,10.3333333 L14.8290766,10.3333333 C14.7878193,9.37777778 14.1689587,8.5 12.9371316,8.21666667 L12.9371316,7 L11.1689587,7 L11.1689587,8.2 C10.0255403,8.43333333 9.10609037,9.13333333 9.10609037,10.2055556 C9.10609037,11.4888889 10.2318271,12.1277778 11.8762279,12.5 C13.3497053,12.8333333 13.6444008,13.3222222 13.6444008,13.8388889 C13.6444008,14.2222222 13.3555992,14.8333333 12.0530452,14.8333333 C10.8388998,14.8333333 10.3614931,14.3222222 10.2966601,13.6666667 L9,13.6666667 C9.07072692,14.8833333 10.0373281,15.5666667 11.1689587,15.7944444 L11.1689587,17 L12.9371316,17 L12.9371316,15.8055556 C14.086444,15.6 15,14.9722222 15,13.8333333 C15,12.2555556 13.56778,11.7166667 12.2298625,11.3888889 Z" />
  </SvgIcon>
)

export default ProductsInstrumentsIcon

import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const Zap: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 22 22">
    <path
      d="M11.8333 2.66669L3.5 12.6667H11L10.1667 19.3334L18.5 9.33335H11L11.8333 2.66669Z"
      fill="transparent"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
)

/**
 * PII occurs in our URLs in one of two ways; as tokens, or in query strings.
 * To scrube these out, we split the URL into the base, and the query.
 * Tokens are Base58 strings, matched using the regex below and replaced with
 * '<secure-token>'.
 * We replace all values in the query string with their respective key (so
 * 'name=Gandalf%20Grey' would become 'name=FILTERED'). We do want to keep the
 * keys, though, since they may be relevant for investigation.
 */
export const sanitizeUrl = (url: string) => {
  let parsedUrl: URL

  try {
    parsedUrl = new URL(url)
  } catch (_) {
    return url
  }

  parsedUrl.searchParams.forEach((_, key) => parsedUrl.searchParams.set(key, 'FILTERED'))
  return parsedUrl.toString()
}

// remove filenames from the payload to avoid PII
export const redactFile = (message?: string) =>
  message?.replace(/(['"]?)\(?(?:file:\/\/\S*|\/\S*)+\)?(?:\1| )/gim, '<redacted-file-path>')

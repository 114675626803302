import { AnyAction } from 'redux'

import * as actions from 'actions/organizationActions'

export interface Tag {
  name: string
  token: string
  category: string
}

export interface TagGroup {
  groupToken: string
  groupName: string
  structuredName: string
  category: string
  tags: Tag[]
}

export interface OrganizationState {
  tagGroupsByKey: { [key: string]: TagGroup[] }
}

const getInitialState = (): OrganizationState => ({
  tagGroupsByKey: {},
})

const organizationReducer = (state = getInitialState(), action: AnyAction): OrganizationState => {
  switch (action.type) {
    case actions.SET_TAG_GROUPS_SEARCH_RESULT: {
      const tagGroupsByKey = {
        ...state.tagGroupsByKey,
        [action.key]: action.tagGroups,
      }
      return { ...state, tagGroupsByKey }
    }
    default: {
      return state
    }
  }
}

export default organizationReducer

import { useQuery } from '@apollo/client'

import { useHasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum, OtherInfoLabelTypeEnum } from 'types/api'

import { CustomFieldLabelSearchQuery, CustomFieldLabelSearchQueryVariables } from './__generated__/queries.generated'
import { CUSTOM_FIELD_LABEL_SEARCH_QUERY } from './queries'

export const useCustomFieldLabelsQuery = (labelType: OtherInfoLabelTypeEnum) => {
  const result = useQuery<CustomFieldLabelSearchQuery, CustomFieldLabelSearchQueryVariables>(
    CUSTOM_FIELD_LABEL_SEARCH_QUERY,
    {
      fetchPolicy: 'network-only',
      variables: { labelType },
    }
  )
  return result
}

export const useHasPermissionToManageCustomFields = () => {
  const orgUpdatePermission = useHasPermission(BadgePermissionsEnum.UpdateOrganization)
  return orgUpdatePermission
}

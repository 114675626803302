import React from 'react'

// TODO: Use HbPopper.
// eslint-disable-next-line no-restricted-imports
import { Grow, Paper, Popper } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@mui/styles'

import classnames from 'classnames'
import { isNaN } from 'lodash'
import PropTypes from 'prop-types'

import * as hbPropTypes from 'helpers/propTypes'

const ROOT = 'hb-simple-menu'
const PARENT_ROOT = `${ROOT}__parent`
const GRANDPARENT_ROOT = `${ROOT}__grandparent`
const FUDGE_FACTOR = 10

class Menu extends React.PureComponent {
  constructor(props) {
    super(props)
    this.menuContainer = React.createRef()
    this.handleDocumentClick = this.handleDocumentClick.bind(this)
    this.handleKeydown = this.handleKeydown.bind(this)
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleDocumentClick)
    const bindElement = this.props.trigger || document
    bindElement.addEventListener('keydown', this.handleKeydown)
  }

  componentDidUpdate(prevProps) {
    const prevTrigger = prevProps.trigger
    const { trigger } = this.props

    /* Unbind and then rebind to new trigger */
    if (prevTrigger && prevTrigger !== trigger) {
      const unbindElement = prevTrigger || document
      const bindElement = trigger || document
      unbindElement.removeEventListener('keydown', this.handleKeydown)
      bindElement.addEventListener('keydown', this.handleKeydown)
    }
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleDocumentClick)
    const unbindElement = this.props.trigger || document
    unbindElement.removeEventListener('keydown', this.handleKeydown)
  }

  menuStyle() {
    const { autoPosition, position, trigger } = this.props

    // If not setup to auto position, or not setup with a trigger from which to position
    // then don't do anything.
    if (!autoPosition || !trigger) {
      return {}
    }

    // Find the position of the trigger and adjust the container to move it just beneath
    // the trigger.
    const triggerPos = trigger.getBoundingClientRect()
    const container = this.menuContainer.current
    const containerPos = container.getBoundingClientRect()
    const containerStyle = window.getComputedStyle(container)
    let existingLeft = parseFloat(containerStyle.left, 10)
    let existingTop = parseFloat(containerStyle.top, 10)
    // top and left can be 'auto' which parses to NaN - if that's the case, set to 0.
    existingLeft = isNaN(existingLeft) ? 0 : existingLeft
    existingTop = isNaN(existingTop) ? 0 : existingTop
    // The position is the existing given position + the delta between desired position
    // and current actual position. There's also a little fudge factor (10px).
    const positionStyle = {
      left: existingLeft + triggerPos.left - containerPos.left - FUDGE_FACTOR,
      top: existingTop + triggerPos.top - containerPos.top,
    }

    // If we want it positioned 'inline', then move it up to be right on top of the trigger.
    if (position === 'inline') {
      positionStyle.top -= triggerPos.height
    }

    // check if menu is off screen on the right. If so, move it left
    const containerRight = triggerPos.left + triggerPos.width + containerPos.width
    const overflowsRight = containerRight > window.innerWidth
    if (overflowsRight) {
      positionStyle.left -= containerPos.width - triggerPos.width - FUDGE_FACTOR
    }

    return positionStyle
  }

  handleDocumentClick(event) {
    // If we're open, and the click is outside the menu and the click is outside the
    // trigger for the menu, then close the menu.
    const { open, trigger, onClose } = this.props

    if (!open) {
      return true
    }

    if (!this.menuContainer.current || this.menuContainer.current.contains(event.target)) {
      return true
    }

    if (trigger && trigger.contains(event.target)) {
      return true
    }

    if (onClose) {
      this.props.onClose()
    }
    return false
  }

  handleKeydown(event) {
    const { open, onClose } = this.props
    const isEscape = event.key === 'Escape' || event.keyCode === 27

    if (open && isEscape && onClose) {
      event.stopPropagation()
      onClose()
    }
  }

  render() {
    const {
      open,
      children,
      autoPosition,
      position,
      modifier,
      className,
      classes,
      trigger,
      onClose,
      popperMenu,
      popperProps,
      placement: popperPlacement,
      ...otherProps
    } = this.props
    const grandparentClasses = classnames(
      GRANDPARENT_ROOT,
      {
        [`${GRANDPARENT_ROOT}--expanded`]: open,
        [`${GRANDPARENT_ROOT}--collapsed`]: !open,
      },
      className
    )
    const parentClasses = classnames(PARENT_ROOT, {
      [`${PARENT_ROOT}--${position}`]: position && position.length,
    })
    const menuClasses = classnames(
      ROOT,
      {
        [`${ROOT}--${modifier}`]: modifier && modifier.length,
      },
      classes.menu
    )

    if (popperMenu) {
      return (
        <Popper
          className={classes.popper}
          open={open}
          anchorEl={trigger}
          placement={popperPlacement}
          transition
          disablePortal
          {...popperProps}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center right',
              }}
            >
              {/* Wrapping div is needed because Paper does not correctly forward ref */}
              <div ref={this.menuContainer}>
                <Paper className={classes.popperMenu}>{children}</Paper>
              </div>
            </Grow>
          )}
        </Popper>
      )
    }

    return React.createElement(
      'div',
      {
        className: grandparentClasses,
        ...otherProps,
      },
      <div className={parentClasses}>
        <div ref={this.menuContainer} className={menuClasses} style={this.menuStyle()}>
          {children}
        </div>
      </div>
    )
  }
}

Menu.propTypes = {
  autoPosition: PropTypes.bool,
  position: PropTypes.oneOf(['inline', 'right', 'select']),
  // Trigger should be an HTML DOM element, but there's no PropType for that
  trigger: PropTypes.any,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  modifier: PropTypes.oneOf(['secondary']),
  className: PropTypes.string,
  classes: hbPropTypes.CLASSES.isRequired,
  popperMenu: PropTypes.bool,
  popperProps: PropTypes.object,
  placement: PropTypes.string,
}

Menu.defaultProps = {
  autoPosition: false,
  position: null,
  trigger: null,
  open: false,
  onClose: null,
  modifier: null,
  className: null,
  /*
   * Whether or not to use a Popper (https://github.com/FezVrasta/popper.js)
   * for positioning of the menu. We should gradually transition our menus
   * to use a popper as it handles a lot of the complexity with parent
   * overflow CSS issues (and menu positioning in general).
   */
  popperMenu: false,
  popperProps: {},
  placement: 'bottom',
}

export default withStyles((theme) => ({
  /* Styles to be applied to menu when old (in-house) positioning implementation is used */
  menu: {
    borderRadius: theme.shape.smallContainer.borderRadius,
  },

  /* Styles for the `Popper` component */
  popper: {
    zIndex: '100',
    transform: 'translateX(100px)',
  },

  /* Styles to be applied to menu when a `Popper` component is used for positioning */
  popperMenu: {
    overflowX: 'visible',
    overflowY: 'auto',
    maxWidth: '500px',
    maxHeight: '75vh',
  },
}))(Menu)

import React, {
  useContext,
  createContext,
  useState,
  useMemo,
  useCallback,
  ReactNode,
  Dispatch,
  SetStateAction,
  useRef,
  useEffect,
} from 'react'

import { Action, Location } from 'history'
import { Prompt, useLocation } from 'react-router-dom'

import { usePrevious } from 'hooks'
import { useBeforeUnloadPrompt } from 'hooks/UseBeforeUnload'

/**
 * Get target slices at the time of update, including nested ones if needed
 */
const getSlicesToUpdate = (
  prevState: Record<string, unknown> | undefined,
  pendingChangesKey: string,
  matchExactSlice: boolean = false
) => {
  if (!prevState) return []
  return Object.keys(prevState).filter((potentialSlice) =>
    matchExactSlice ? pendingChangesKey : potentialSlice.startsWith(pendingChangesKey)
  )
}

export const UISectionContext = React.createContext('')

interface UISectionProviderProps {
  children: ReactNode
  uiSection: string
}

export const UISectionProvider = ({ children, uiSection }: UISectionProviderProps) => {
  const parentUISection = useContext(UISectionContext)
  return (
    <UISectionContext.Provider value={[parentUISection, uiSection].filter((a) => a).join('@@')}>
      {children}
    </UISectionContext.Provider>
  )
}

interface PendingChangesContextInterface {
  pendingChanges: Record<string, undefined | Record<string, unknown>>
  hasPendingChanges: Record<string, boolean>
  hasAnyPendingChanges: boolean
  saveOverriddenRef: React.MutableRefObject<Record<string, boolean>>
  isLockedRef: React.MutableRefObject<boolean>
  setHasPendingChanges: Dispatch<SetStateAction<undefined | Record<string, boolean>>>
  setPendingChanges: Dispatch<SetStateAction<undefined | Record<string, unknown>>>
  setPendingChangesBySlice: (slice: string) => Dispatch<SetStateAction<undefined | Record<string, unknown>>>
  reset: (shouldLock: boolean) => void
}
export const PendingChangesContext = createContext<PendingChangesContextInterface>({
  pendingChanges: {},
  hasPendingChanges: {},
  hasAnyPendingChanges: false,
  saveOverriddenRef: { current: {} },
  isLockedRef: { current: false },
  setHasPendingChanges: () => {},
  setPendingChanges: () => {},
  setPendingChangesBySlice: () => () => {},
  reset: () => {},
})

export type Message = string | ((location: Location, action: Action) => string | boolean)
interface Props {
  children: ReactNode
  showPromptOnNav: boolean
  showPromptOnUnload: boolean
  message?: Message
}
export const PendingChangesProvider = ({ children, showPromptOnNav, showPromptOnUnload, message }: Props) => {
  const [pendingChanges, setPendingChanges] = useState({})
  const [hasPendingChanges, setHasPendingChanges] = useState({})

  const isLockedRef = useRef(false)
  // This is necessary for having updated values in cleanup effect in PreserveFormState instead of the next render. Yes this is technically derivable from state but it's derivable too late.
  const saveOverriddenRef = useRef<Record<string, boolean>>({})

  const setPendingChangesBySlice = useCallback((slice: string) => {
    return (pendingChangesForSlice: Record<string, unknown>) => {
      setPendingChanges((prevPendingChanges) => ({ ...prevPendingChanges, [slice]: pendingChangesForSlice }))
    }
  }, [])

  const reset = useCallback((shouldLock: boolean) => {
    isLockedRef.current = shouldLock
    setPendingChanges({})
    setHasPendingChanges({})
  }, [])

  const hasAnyPendingChanges = Object.values(hasPendingChanges).some((value) => value)

  const valueMemo = useMemo(
    () => ({
      pendingChanges,
      setPendingChanges,
      setPendingChangesBySlice,
      hasPendingChanges,
      saveOverriddenRef,
      isLockedRef,
      setHasPendingChanges,
      reset,
      hasAnyPendingChanges,
    }),
    [pendingChanges, setPendingChangesBySlice, hasPendingChanges, reset, hasAnyPendingChanges]
  )
  const shouldShowPromptOnNav = !!(showPromptOnNav && hasAnyPendingChanges && !!message)
  const shouldShowPromptOnUnload = !!(showPromptOnUnload && hasAnyPendingChanges)

  useBeforeUnloadPrompt(shouldShowPromptOnUnload)

  return (
    <PendingChangesContext.Provider value={valueMemo}>
      {children}
      <Prompt when={shouldShowPromptOnNav} message={message || ''} />
    </PendingChangesContext.Provider>
  )
}

interface UsePendingChangesOptions {
  slice?: string
  clearChangesOnPristine?: boolean
  matchExactSlice?: boolean
  dirty?: boolean
}

export function usePendingChanges<T>(
  options: UsePendingChangesOptions = { clearChangesOnPristine: false, matchExactSlice: false }
) {
  const uiSection = useContext(UISectionContext)
  const { clearChangesOnPristine, dirty, matchExactSlice = false, slice } = options

  const {
    pendingChanges: _pendingChanges,
    setPendingChanges: _setPendingChanges,
    setPendingChangesBySlice,
    hasPendingChanges,
    setHasPendingChanges,
    saveOverriddenRef,
    reset,
    isLockedRef,
  } = useContext(PendingChangesContext)

  const pendingChangesKey = slice || uiSection

  const setPendingChanges = useCallback(
    (changes) => {
      setPendingChangesBySlice?.(pendingChangesKey)(changes)
      setHasPendingChanges((prevState) => ({ ...prevState, [pendingChangesKey]: true }))
      delete saveOverriddenRef.current[pendingChangesKey]
    },
    [setPendingChangesBySlice, pendingChangesKey, setHasPendingChanges, saveOverriddenRef]
  )
  const hasAnyPendingChanges = Object.values(hasPendingChanges).some((value) => !!value)
  const sliceHasPendingChanges =
    hasPendingChanges[pendingChangesKey] ||
    // check if a top-level section has pending changes if there are nested ui sections:
    Object.entries(hasPendingChanges).some(([key, value]) => key.startsWith(pendingChangesKey) && value) ||
    Object.entries(_pendingChanges || {}).some(
      ([key, value]) =>
        (key as string).startsWith(pendingChangesKey) && Object.values(value || {}).some((innerValue) => !!innerValue)
    )

  const setHasPendingChangesBySlice = useCallback(() => {
    setHasPendingChanges((prevState) => ({ ...prevState, [pendingChangesKey]: true }))
    delete saveOverriddenRef.current[pendingChangesKey]
  }, [saveOverriddenRef, pendingChangesKey, setHasPendingChanges])

  const trackSaveOverriddenForSlices = useCallback(
    (slicesToOverride: string[] = []) => {
      slicesToOverride.forEach((overriddenSlice) => {
        saveOverriddenRef.current[overriddenSlice] = true
      })
    },
    [saveOverriddenRef]
  )

  const clearPendingChanges = useCallback(() => {
    _setPendingChanges((prevPendingChanges) => {
      const slices = getSlicesToUpdate(prevPendingChanges, pendingChangesKey, matchExactSlice)
      const clearedPendingChanges = slices.reduce((acc, next) => {
        acc[next] = undefined
        return acc
      }, {} as Record<string, unknown>)
      return { ...prevPendingChanges, ...clearedPendingChanges }
    })
    setHasPendingChanges((prevHasPendingChanges) => {
      const slices = getSlicesToUpdate(prevHasPendingChanges, pendingChangesKey, matchExactSlice)
      const clearedHasPendingChanges = slices.reduce((acc, next) => {
        acc[next] = false
        return acc
      }, {} as Record<string, boolean>)
      trackSaveOverriddenForSlices(slices)
      return { ...prevHasPendingChanges, ...clearedHasPendingChanges }
    })
  }, [matchExactSlice, pendingChangesKey, setHasPendingChanges, _setPendingChanges, trackSaveOverriddenForSlices])

  const wasDirty = usePrevious(dirty)
  useEffect(() => {
    if (clearChangesOnPristine && wasDirty && !dirty) {
      clearPendingChanges()
    }
  }, [clearChangesOnPristine, clearPendingChanges, dirty, wasDirty])

  return {
    _pendingChanges,
    _saveOverriddenRef: saveOverriddenRef.current,
    _isLockedRef: isLockedRef,
    pendingChanges: _pendingChanges[pendingChangesKey] as T | undefined,
    setPendingChanges,
    setHasPendingChanges: setHasPendingChangesBySlice,
    sliceHasPendingChanges,
    hasAnyPendingChanges,
    clearPendingChanges,
    reset,
    uiSection,
  }
}

const defaultComparator = (location: Location, nextLocation: Location) => location.pathname === nextLocation.pathname

export function usePromptMessage(comparator = defaultComparator, messageString = 'There may be some unsaved changes.') {
  const location = useLocation()
  const message = useCallback(
    (nextLocation: Location) => {
      return comparator(location, nextLocation) ? true : messageString
    },
    [comparator, location, messageString]
  )

  return message
}

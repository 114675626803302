import { State } from 'actions/store'
import {
  canAssignSelector as canAssignUserSelector,
  canUnassignSelector as canUnassignUserSelector,
} from 'components/cases/callReturns/Assignment'
import { hasPermission } from 'helpers/stateHelpers'
import { BadgePermissionsEnum } from 'types/api'

/** Returns true if the user can do _any_ assignment action */
export function canAssignSelector(state: State) {
  return canAssignUserSelector(state) || hasPermission(state, BadgePermissionsEnum.UpdateReviewQueue)
}

/** Returns true if the user can do _any_ unassignment action */
export function canUnassignSelector(state: State) {
  return canUnassignUserSelector(state) || hasPermission(state, BadgePermissionsEnum.DeleteReviewQueue)
}

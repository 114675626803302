import { useRef, useEffect } from 'react'

import { SnackbarKey, useSnackbar } from 'notistack'

import { removeReduxSnackbar } from 'actions/applicationActions'
import { useSelector, useDispatch } from 'actions/store'

// Hook for showing/hiding redux snackbars
const useReduxSnackbars = () => {
  const dispatch = useDispatch()

  const snackbars = useSelector((store) => store.application.snackbars)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const displayedKeysRef = useRef<SnackbarKey[]>([])

  const storedisplayedKeys = (key: SnackbarKey) => {
    displayedKeysRef.current = [...displayedKeysRef.current, key]
  }

  const removedisplayedKeys = (keyToRemove: SnackbarKey) => {
    displayedKeysRef.current = [...displayedKeysRef.current.filter((key) => key !== keyToRemove)]
  }

  useEffect(() => {
    snackbars.forEach(({ key, message, dismissed = false, ...options }) => {
      if (dismissed) {
        // dismiss snackbar using notistack
        closeSnackbar(key)
        return
      }

      // do nothing if snackbar is already displayed
      if (displayedKeysRef.current.includes(key)) return

      // display snackbar using notistack
      enqueueSnackbar(message, {
        ...options,
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeReduxSnackbar(myKey))
          removedisplayedKeys(myKey)
        },
      })

      // keep track of snackbars that we've displayed
      storedisplayedKeys(key)
    })
  }, [snackbars, closeSnackbar, enqueueSnackbar, dispatch])
}

export default useReduxSnackbars

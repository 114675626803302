import { CircularProgress } from '@mui/material'

import classnames from 'classnames'

import { HbLinearProgress } from 'components/HbComponents/Progress/HbLinearProgress'

import { makeRequiredStyles } from 'components/utils/styles'
import { Theme } from 'types/hb'

const useLoaderStyles = makeRequiredStyles((theme: Theme) => ({
  linear: {
    height: '2px',
  },
  circularSecondary: {
    color: theme.palette.text.secondary,
  },
  circularPrimary: {
    color: theme.palette.primary.main,
  },
  global: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
  },
  local: {
    position: 'relative',
    height: 0,
    maxHeight: 0,
  },
  sticky: {
    position: 'sticky',
    zIndex: 1,
    height: 0,
    maxHeight: 0,
    top: 0,
  },
}))

interface LoaderContentProps {
  className: string
  indicatorType: 'linear' | 'circular'
  size: number
  testId?: string
  colorVariant?: 'primary' | 'secondary'
}

const LoaderContent = (props: LoaderContentProps) => {
  const styles = useLoaderStyles()
  const { className, indicatorType, size, testId, colorVariant } = props
  return (
    <div className={className}>
      {indicatorType === 'circular' ? (
        <CircularProgress
          className={colorVariant === 'secondary' ? styles.circularSecondary : styles.circularPrimary}
          size={size}
          data-testid={testId}
        />
      ) : (
        <HbLinearProgress data-testid={testId} size="xs" variant="indeterminate" />
      )}
    </div>
  )
}

export interface Props {
  loading?: boolean
  className?: string
  indicatorType?: 'linear' | 'circular'
  size?: number
  testId?: string
  variant?: 'global' | 'local' | 'sticky'
  colorVariant?: 'primary' | 'secondary'
}

function Loader(props: Props) {
  const styles = useLoaderStyles()
  const {
    loading = true,
    className = null,
    indicatorType = 'linear',
    size = 40,
    testId,
    variant = 'local',
    colorVariant = 'secondary',
  } = props

  const is: { [key in typeof variant]: boolean } = {
    global: variant === 'global',
    local: variant === 'local',
    sticky: variant === 'sticky',
  }

  const classes = classnames(
    {
      [styles.global]: is.global,
      [styles.local]: is.local,
      [styles.sticky]: is.sticky,
    },
    className
  )

  if (is.global) {
    return (
      <LoaderContent
        size={size}
        className={classes}
        indicatorType={indicatorType}
        testId={testId}
        colorVariant={colorVariant}
      />
    )
  }

  if (is.local || is.sticky) {
    if (loading) {
      return (
        <LoaderContent
          size={size}
          className={classes}
          indicatorType={indicatorType}
          testId={testId}
          colorVariant={colorVariant}
        />
      )
    }

    return null
  }

  throw new Error(`Unexpected Loader variant: ${variant}`)
}

export default Loader

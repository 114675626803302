import { ChangeEvent } from 'react'

import { Radio as BaseRadio, RadioGroup, RadioGroupProps, RadioProps } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import classnames from 'classnames'

import { Controller, FieldPath, FieldValues, UseControllerProps } from 'react-hook-form'

import { Theme } from 'types/hb'

const useStyles = makeStyles<Theme, { error?: boolean }>((theme) => ({
  root: ({ error }) => {
    return {
      backgroundColor: error ? theme.palette.styleguide.lightRed : theme.palette.background.contrastMedium,
      borderRadius: '50%',
      width: 18,
      height: 18,
      border: `1px solid ${error ? theme.palette.error.main : theme.palette.styleguide.greyD}`,
      'input:hover ~ &': {
        border: `1px solid ${error ? theme.palette.error.main : theme.palette.styleguide.dark}`,
        backgroundColor: theme.palette.styleguide.greyF,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: theme.palette.input.disabled,
        border: `1px solid ${theme.palette.input.disabled}`,
      },
      '.Mui-focusVisible &': {
        border: `1px solid ${theme.palette.primary.main}`,
      },
    }
  },
  checkedIcon: ({ error }) => {
    return {
      border: `1px solid ${error ? theme.palette.error.main : theme.palette.primary.main}`,
      backgroundColor: error ? theme.palette.error.main : theme.palette.primary.main,
      '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage: 'radial-gradient(#fff,#fff 25%,transparent 10%)',
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: error ? theme.palette.error.main : theme.palette.styleguide.dark,
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: theme.palette.input.disabled,
        border: `1px solid ${theme.palette.input.disabled}`,
      },
    }
  },
}))

function RadioIcon(props: { error?: boolean; checked: boolean }) {
  const { checked, error } = props
  const classes = useStyles({ error })
  const className = classnames(classes.root, {
    [classes.checkedIcon]: checked === true,
  })
  return <span className={className} />
}

interface Props extends RadioProps {
  error?: boolean
}

export const HbRadio = (props: Props) => {
  const { error, ...rest } = props

  return (
    <BaseRadio
      icon={<RadioIcon checked={false} error={error} />}
      checkedIcon={<RadioIcon checked error={error} />}
      disableRipple
      {...rest}
    />
  )
}

const useRadioGroupStyles = makeStyles(() => ({
  root: {
    display: 'block',
  },
}))

export const HbRadioGroup = ({ className, ...props }: RadioGroupProps) => {
  const classes = useRadioGroupStyles()
  return <RadioGroup {...props} className={classnames(classes.root, className)} />
}

export function HbRHFRadioGroup<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>
>({ control, name, rules, onChange, children, ...rest }: UseControllerProps<TFieldValues, TName> & RadioGroupProps) {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field }) => {
        return (
          <HbRadioGroup
            {...rest}
            {...field}
            onChange={
              onChange
                ? (value: ChangeEvent<HTMLInputElement>) => {
                    field.onChange(value)
                    onChange(value, value.target.value)
                  }
                : field.onChange
            }
          >
            {children}
          </HbRadioGroup>
        )
      }}
    />
  )
}

import React, { useRef } from 'react'

import { MoreVert } from '@mui/icons-material'
// TODO: Use HbPopper.
// eslint-disable-next-line no-restricted-imports
import { ClickAwayListener, Grow, MenuList, Paper, Popper, createStyles } from '@mui/material'
// eslint-disable-next-line no-restricted-imports
import { Styles, withStyles } from '@mui/styles'

import classnames from 'classnames'

import { MomentInput } from 'moment'

import { HbButton } from 'components/HbComponents/HbButton'
import { fromNow } from 'helpers/uiHelpers'

import { WithStyles, Theme, BasicAccount } from 'types/hb'

import AuthorIcon from './AuthorIcon'
import AuthorName from './AuthorName'

const styles: Styles<Theme, object> = (theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: 30,
      marginLeft: theme.spacing(2),
      color: theme.palette.styleguide.dark,
      '&.notification-content': {
        paddingLeft: theme.spacing(2),
      },
    },

    header: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'center',
      lineHeight: '34px',
    },

    timestamp: {
      ...theme.typography.subtitle2,
      color: theme.palette.styleguide.mediumGray2,
      display: 'inline-block',
      paddingLeft: theme.spacing(0.5),
      flexGrow: 1,
    },

    body: {
      whiteSpace: 'normal',
      lineHeight: '22px',
      paddingBottom: theme.spacing(),

      '&$highlight': {
        borderBottom: '1px solid',
        borderBottomColor: 'red',
      },
    },

    highlight: {},
  })

interface Props extends WithStyles<typeof styles> {
  author: BasicAccount
  createdAt: MomentInput
  toggleMenu?: () => void
  showActions?: boolean
  component?: string
  menuOpen?: boolean
  className?: string
  actions?: any[]
  content?: any
  highlight?: boolean
}

function AuthoredContent(props: Props) {
  const {
    author,
    createdAt,
    classes,
    className = null,
    component = 'div',
    actions = null,
    content = null,
    showActions = false,
    menuOpen = false,
    toggleMenu = () => {},
    highlight = false,
  } = props

  const menuTrigger = useRef<HTMLButtonElement | null>(null)

  const body = () => (
    <div>
      <AuthorIcon author={author} />
      <div className={classes.header}>
        <AuthorName author={author} />
        <div className={classes.timestamp}>{fromNow(createdAt)}</div>
        {showActions && actions && (
          <div className="authored-content__actions">
            <HbButton ref={menuTrigger} onClick={toggleMenu} label="More" Icon={MoreVert} iconOnly />

            <Popper open={menuOpen} style={{ zIndex: 100 }} anchorEl={menuTrigger.current} transition disablePortal>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={toggleMenu}>
                      <MenuList>{actions}</MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        )}
      </div>
      <div className={classnames(classes.body, { [classes.highlight]: highlight })}>{content}</div>
    </div>
  )

  return React.createElement(
    component,
    {
      className: classnames(classes.root, className),
    },
    body()
  )
}

export default withStyles(styles)(AuthoredContent)

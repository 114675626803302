import { useState, FocusEvent } from 'react'

import invariant from 'tiny-invariant'

import { HbButton } from 'components/HbComponents/HbButton'
import { FormSchemaReturnType, FormSchemaReturnTypeForTemplate } from 'components/pages/automations/editor/formSchema'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'

import { ViewAutomationHeaderBase } from '../ViewAutomationHeaderBase'

import ToggleAutomationTemplateVisible from './ToggleAutomationTemplateVisible'
import { AutomationRuleTemplateQuery } from './__generated__/AutomationRuleTemplate.queries.generated'

interface Props {
  template: AutomationRuleTemplateQuery['automationRuleTemplate']
  form: FormSchemaReturnTypeForTemplate
  onSubmitAndSave: () => void
  loading: boolean
}

export const AutomationRuleTemplateHeader = ({ template, form, onSubmitAndSave, loading }: Props) => {
  const usage = useUsage()
  const [ruleNameError, setRuleNameError] = useState(false)

  const { formState } = form
  const { isDirty } = formState

  const handleOnBlur = (event: FocusEvent<HTMLInputElement>) => {
    invariant(template)

    if (event.target.value !== template.name) {
      usage.logEvent({
        name: 'automations:editorEditName:updated',
        data: {
          automationRuleTemplateToken: template.token,
          newName: event.target.value ?? '',
          oldName: template.name ?? '',
        },
      })
    }
  }

  return (
    <ViewAutomationHeaderBase
      name={template.name}
      nameError={ruleNameError}
      setNameError={setRuleNameError}
      tagProps={{ themeColor: 'cyan', label: 'Template' }}
      form={form as FormSchemaReturnType}
      onBlur={handleOnBlur}
      actions={
        <>
          <ToggleAutomationTemplateVisible template={template} />
          <HbButton label="Save template" disabled={!isDirty} loading={loading} onClick={onSubmitAndSave} />
        </>
      }
      template
    />
  )
}

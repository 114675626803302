import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const AiEditIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 20 20">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path
        d="M4 11.75V10.25H10V11.75H4ZM4 8.625V7.125H13V8.625H4ZM4 5.5V4H13V5.5H4ZM11 16V13.6458L15.375 9.29167C15.4758 9.18875 15.5878 9.11437 15.711 9.06854C15.8342 9.02285 15.9567 9 16.0783 9C16.211 9 16.3381 9.02431 16.4598 9.07292C16.5815 9.12153 16.6921 9.19444 16.7917 9.29167L17.7083 10.2292C17.7999 10.33 17.8713 10.442 17.9227 10.5652C17.9742 10.6884 18 10.8108 18 10.9325C18 11.0542 17.9772 11.1786 17.9315 11.3058C17.8856 11.4329 17.8113 11.5463 17.7083 11.6458L13.3542 16H11ZM12 15H12.9375L15.3333 12.6042L14.875 12.125L14.4167 11.6667L12 14.0625V15ZM14.875 12.125L14.4167 11.6667L15.3333 12.6042L14.875 12.125Z"
        fill="url(#linear_gradient)"
      />
      <defs>
        <linearGradient id="linear_gradient" x1="4" y1="4" x2="15.2412" y2="0.674493" gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF8989" />
          <stop offset="1" stopColor="#7F00FF" />
        </linearGradient>
      </defs>
    </g>
  </SvgIcon>
)

export default AiEditIcon

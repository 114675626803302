import { FC, useCallback, useContext, createContext } from 'react'

import { FetchResult } from '@apollo/client'
import { Typography } from '@mui/material'

import { useDispatch, useSelector } from 'actions/store'
import { LibraryGqlQueryType } from 'components/entities/LibraryQueries'
import ConfirmDialog from 'components/library/ConfirmDialog'

import { useUsage } from 'helpers/SessionTracking/UsageTracker'
import { useHbMutation } from 'hooks/ApolloHelpers'
import { batchActionsActions } from 'reducers/batchActions/batchActions.actions'
import { batchActionsSelectors } from 'reducers/batchActions/batchActions.selectors'

import { BatchActionParameters, BatchActionTypeEnum } from 'types/api'

import { useBatchActionLookups } from './useBatchActionLookups'

export type DialogComponent<
  T = unknown,
  U extends string = 'review' | 'investigation' | 'transaction' | 'profile'
> = FC<
  T & {
    open: boolean
    onClose: () => void
    entityType: U
    libraryEntityType?: LibraryGqlQueryType
    libraryEntityToken?: string
  }
>

interface CancelDialogProps {
  open: boolean
  onClose: () => void
  onConfirm?: () => void
  title: string
  confirmEnabled: boolean
  confirmText: string
  batchActionName: BatchActionTypeEnum
  batchActionParams?: BatchActionParameters
  primaryText?: string
  shouldSetRecentlyUpdatedItems?: boolean
}
interface BatchActionContextValue {
  mutate: ReturnType<typeof useHbMutation>[0]
  bidSelector: (data: FetchResult<unknown, Record<string, any>, Record<string, any>>['data']) => string | null
  loading: boolean
}

export const BatchActionContext = createContext<BatchActionContextValue>({
  mutate: Promise.resolve,
  bidSelector: () => null,
  loading: false,
})

export const BaseBatchActionDialog: FC<CancelDialogProps> = (props) => {
  const {
    open,
    onClose,
    primaryText,
    title,
    confirmEnabled,
    confirmText,
    batchActionName,
    children,
    batchActionParams = {},
    onConfirm,
    shouldSetRecentlyUpdatedItems = false,
  } = props
  const dispatch = useDispatch()

  const { mutate, bidSelector, loading } = useContext(BatchActionContext)
  const usage = useUsage()

  const tokens = useSelector(batchActionsSelectors.batchSelectedItemsArray)
  const { getDialogIcon } = useBatchActionLookups()

  const Icon = getDialogIcon(batchActionName)

  const handleConfirmBatchAction = useCallback(async () => {
    // clear the selection immediately so you can see the loader
    if (onConfirm) {
      onConfirm()
      return
    }

    usage.logEvent({ name: 'dashboard:batchActionConfirm:clicked', data: { batchAction: batchActionName } })

    dispatch(batchActionsActions.batchSelectItems.clear())
    const result = await mutate({
      variables: {
        tokens,
        batchActionName,
        parameters: batchActionParams,
      },
    })

    if (result?.data) {
      const bid = bidSelector(result.data)

      if (bid) {
        dispatch(batchActionsActions.jobStatus.set({ bid }))
      }
    }
    if (shouldSetRecentlyUpdatedItems) {
      dispatch(
        batchActionsActions.recentlyUpdatedItems.set({
          tokens,
          value: true,
        })
      )
    }
    onClose()
  }, [
    onConfirm,
    usage,
    batchActionName,
    dispatch,
    mutate,
    tokens,
    batchActionParams,
    shouldSetRecentlyUpdatedItems,
    onClose,
    bidSelector,
  ])

  return (
    <ConfirmDialog
      IconComponent={Icon}
      title={title}
      confirmText={confirmText}
      open={open}
      loading={loading}
      confirmDisabled={loading || !confirmEnabled}
      cancelDisabled={loading}
      onClose={onClose}
      onConfirm={handleConfirmBatchAction}
    >
      {primaryText && (
        <Typography variant="body2" color="textPrimary" paragraph>
          {primaryText}
        </Typography>
      )}
      {children}
    </ConfirmDialog>
  )
}

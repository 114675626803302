import { nanoid } from 'nanoid'

import { SnackbarKey } from 'notistack'

import { startFetchingNotifications } from 'actions/notificationsActions'
import { getCurrentAccount } from 'helpers/stateHelpers'
import { FilingInstitution, Organization, ReviewType } from 'reducers/applicationReducer'
import { InitialData, isSuccessResult, CurrentAccount } from 'types/hb'

import { Props as HbNotistackSnackbarProps } from '../components/HbComponents/HbNotistack/HbNotistackSnackbar'

import {
  CLOSE_SNACKBAR,
  DISMISS_LOGIN_BANNER,
  ENQUEUE_SNACKBAR,
  REMOVE_SNACKBAR,
  SET_CURRENT_ACCOUNT,
  SET_INITIAL_STATE,
  SET_INTERCOM_HAS_BOOTED,
  SET_ORGANIZATIONS,
  UPDATE_FILING_INSTITUTIONS,
  UPSERT_ORGANIZATION_CURRENT_REVIEW_TYPE,
} from './applicationActionTypes'
import { Thunk } from './store'
import { fetchUserSettings } from './userSettingsActions'

// Translate rails flashes to snackbars
const flashesToSnackbars = (flashes?: InitialData['flashes']): Pick<ReduxSnackbar, 'message' | 'variant' | 'key'>[] => {
  if (!flashes) {
    return []
  }
  const entries = Object.entries(flashes)
  if (!entries.length) {
    return []
  }
  const [flashVariant, message] = entries[0]
  if (!message) {
    return []
  }
  let variant: ReduxSnackbar['variant']
  switch (flashVariant) {
    case 'error':
      variant = 'error'
      break
    case 'alert':
      variant = 'warning'
      break
    default:
      variant = 'default'
  }
  return [
    {
      message,
      variant,
      key: nanoid(),
    },
  ]
}

export function setInitialState(state: InitialData) {
  const { flashes, ...rest } = state

  return {
    type: SET_INITIAL_STATE,
    state: {
      ...rest,
      snackbars: flashesToSnackbars(flashes),
    },
  }
}

export function setIntercomHasBooted() {
  return {
    type: SET_INTERCOM_HAS_BOOTED,
  }
}

export function setCurrentAccount(account: CurrentAccount) {
  return { type: SET_CURRENT_ACCOUNT, account }
}

export function setOrganizations(organizations: Organization[]) {
  return { type: SET_ORGANIZATIONS, organizations }
}

export function updateFilingInstitutions(filingInstitutions: FilingInstitution[]) {
  return { type: UPDATE_FILING_INSTITUTIONS, filingInstitutions }
}

export function upsertOrganizationCurrentReviewType(organizationToken: string, reviewType: ReviewType) {
  return { type: UPSERT_ORGANIZATION_CURRENT_REVIEW_TYPE, organizationToken, reviewType }
}

function setLoginBannerAsDismissed() {
  return { type: DISMISS_LOGIN_BANNER }
}

export type ReduxSnackbar = Omit<HbNotistackSnackbarProps, 'action' | 'onClose' | 'buttonProps'> & {
  dismissed?: boolean
  key: SnackbarKey
}

export const enqueueReduxSnackbar = (message: string, options: Partial<ReduxSnackbar> = {}) => {
  return {
    type: ENQUEUE_SNACKBAR,
    snackbar: {
      ...options,
      message,
      key: options.key || nanoid(),
    },
  }
}

export const closeReduxSnackbar = (key?: SnackbarKey) => ({
  type: CLOSE_SNACKBAR,
  key,
})

// This action is only used in `useReduxSnackbar` and shouldn't be used
// elsewhere. Using this will cause the Snackbar to disappear w/o transistions.
// Use `closeReduxSnackbar` instead.
export const removeReduxSnackbar = (key: SnackbarKey) => ({
  type: REMOVE_SNACKBAR,
  key,
})

/**
 *
 * @deprecated use enqueueReduxSnackbar instead
 */
export function setFlashNotice(message: string) {
  return enqueueReduxSnackbar(message)
}

export function dismissLoginBanner(): Thunk<Promise<void>> {
  return (dispatch, getState, { api }) => {
    dispatch(setLoginBannerAsDismissed())
    return api.post('dismissLoginBannerApiAccountPath', {
      data: {
        accountToken: getCurrentAccount(getState()).token,
      },
    })
  }
}

export function swapToOrganization(organizationToken: string): Thunk<Promise<void>> {
  return async (dispatch, getState, { api }) => {
    const json = await api.post('authOrganizationsPath', {
      data: { organizationToken },
    })

    if (isSuccessResult(json)) {
      window.location.href = '/'
    } else {
      dispatch(setFlashNotice('Unable to switch to that organization. Try again or contact Hummingbird support.'))
    }
  }
}

export function initializeApplication(data: InitialData): Thunk<void> {
  return (dispatch, getState) => {
    dispatch(setInitialState(data))
    const state = getState().application

    if (state.currentAccount) {
      // this only kicks off requests.
      // error handling presumed to happen internally, ignore promise rejections
      dispatch(startFetchingNotifications())
      dispatch(fetchUserSettings()).catch(() => {})
    }
  }
}

import { SurveyFileUpload } from 'actions/viewActions'
import { useAttachmentUrl } from 'hooks/UseAttachmentUrl'
import { InvestigationAttachment } from 'reducers/investigationsReducer.types'

import AttachmentImageViewer from './AttachmentImageViewer'
import { IMAGE_ATTACHMENT_TYPES, PDF_ATTACHMENT_TYPE } from './AttachmentViewerInternal.types'
import { AttachmentPdfViewerConnected } from './pdf/AttachmentPdfViewer'

interface Props {
  attachment: InvestigationAttachment | SurveyFileUpload
}

export function AttachmentViewerInternalConnected({ attachment }: Props) {
  const attachmentUrl = useAttachmentUrl(attachment)
  if (!attachmentUrl) return null

  if (IMAGE_ATTACHMENT_TYPES.includes(attachment.contentType)) {
    const altText = attachment.type === 'attachment' ? attachment.dataName : attachment.filename
    return <AttachmentImageViewer imageUrl={attachmentUrl} altText={altText} />
  }
  if (attachment.contentType === PDF_ATTACHMENT_TYPE) {
    return <AttachmentPdfViewerConnected pdfUrl={attachmentUrl} />
  }

  return null
}

import { gql } from '@apollo/client'

import { FilterableColumnFragment } from 'dashboards/shared/gql/search.fragments'

import { SearchProfilesResultFragment } from './searchProfiles.fragments'

export const SEARCH_PROFILES_QUERY = gql`
  query SearchProfiles($page: Int!, $pageSize: Int!, $appliedFilters: SearchRequestInput!) {
    searchProfiles(page: $page, pageSize: $pageSize, search: $appliedFilters) {
      ...SearchProfilesResultFragment
    }
  }

  ${SearchProfilesResultFragment}
`

export const PROFILES_COLUMNS_QUERY_V2 = gql`
  query ProfileColumnsV2($includeCustomColumns: Boolean!) {
    searchProfilesMetadataV2(columns: []) {
      token
      staticColumns {
        ...FilterableColumnFragment
      }
      columns @include(if: $includeCustomColumns) {
        ...FilterableColumnFragment
      }
    }
  }
  ${FilterableColumnFragment}
`

export const PROFILES_METADATA_QUERY_V2 = gql`
  query ProfileMetadataV2($columns: [String!], $query: String) {
    searchProfilesMetadataV2(columns: $columns, query: $query) {
      filterOptions {
        displayName
        key
        column
        count
      }
    }
  }
`

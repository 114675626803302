import { DashboardSlice } from 'reducers/dashboards/dashboards.constants'
import { DashboardState, sortIsValid } from 'reducers/dashboards/dashboards.types'
import { FilterInput, GroupInput, SearchRequestInput, SortEnum, SortValueInput } from 'types/api'

export type NormalizedSearchRequest = SearchRequestInput

type Children = SearchFilter | SearchGroup

export type SearchFilter = Omit<FilterInput, 'group'>
export type SearchGroup = Omit<GroupInput, 'parent'> & { children: Children[] }
export type SearchRequest = {
  filter: SearchGroup
  query?: string
  sorts?: SortValueInput[]
}

type WithId<T> = T & { id: string; valid?: boolean }

export type ChildrenWithId = SearchFilterWithId | SearchGroupWithId
export type SearchFilterWithId = WithId<SearchFilter> & { _type: 'SearchFilterWithId' }
export type SearchGroupWithId = Replace<WithId<SearchGroup>, 'children', ChildrenWithId[]> & {
  _type: 'SearchGroupWithId'
}
export type SearchRequestWithId = Replace<SearchRequest, 'filter', SearchGroupWithId>

export const isSearchFilter = (maybeFilter: Children): maybeFilter is SearchFilter => {
  const asFilter = maybeFilter as SearchFilter
  return asFilter.field !== undefined && asFilter.predicate !== undefined
}

export const isSearchFilterWithId = (maybeFilter: ChildrenWithId): maybeFilter is SearchFilterWithId => {
  const asFilter = maybeFilter as SearchFilterWithId
  return asFilter.field !== undefined && asFilter.predicate !== undefined
}

export const toSortEnum = (direction?: 'asc' | 'desc'): SortEnum | undefined =>
  direction === 'asc' ? SortEnum.Asc : direction === 'desc' ? SortEnum.Desc : undefined
/**
 * Recursively extracts all unique filter values and adds them to the provided set
 * @param child Children
 * @param filterKeySet Set<string> unique filter key values
 */
const extractFilterKeys = (child: Children, filterKeySet?: Set<string>): Set<string> => {
  if (!filterKeySet) {
    return extractFilterKeys(child, new Set<string>())
  }

  if (isSearchFilter(child)) {
    filterKeySet.add(child.field)
  } else {
    for (const grandchild of child.children) {
      extractFilterKeys(grandchild, filterKeySet)
    }
  }

  return filterKeySet
}

/**
 * Extracts all unique filter values from a SearchRequest
 * @param request SearchRequest
 * @returns string array of unique values
 */
export const mapSearchRequestFilterKeys = <T extends DashboardSlice>(
  request: DashboardState<T>['filters']['applied'] | undefined
): string[] => {
  if (!request) return []

  const filterKeys = extractFilterKeys(request.filter)

  return Array.from(filterKeys)
}

export const isValidFilter = (filter: SearchFilterWithId): boolean => {
  return !!(filter.field && filter.predicate.operator && filter.predicate.values.length > 0)
}

const getValidChildren = (group: SearchGroupWithId): ChildrenWithId[] => {
  return group.children
    .map((child) => {
      if (child._type === 'SearchGroupWithId') return { ...child, children: getValidChildren(child) }

      return isValidFilter(child) ? child : null
    })
    .filter((child): child is ChildrenWithId => !!child)
}

/**
 * Maps all valid filters within children for use in a search query
 * @param request SearchRequestWithId
 * @returns SearchRequestWithId containing 'valid' children
 */
export const removeInvalidFilters = <T extends DashboardSlice>(
  request: DashboardState<T>['filters']['applied']
): SearchRequestWithId => {
  const validChildren = getValidChildren(request.filter)

  return {
    ...request,
    filter: { ...request.filter, children: validChildren },
    sorts: request.sorts?.filter(sortIsValid),
  }
}

/**
 * Returns the parent object of the component ID being searched for
 * @param idToFind UUID for the filter entry or filter group
 * @param findParent if true, will return the parent object of child with idToFind
 * @param currentObject start object for locating the ID
 * @returns SearchGroupWithId object, or undefined if initial parent is the expected object
 */
export const findFilterComponent = (
  idToFind: string,
  findParent: boolean,
  currentObject?: SearchGroupWithId
): SearchGroupWithId | undefined => {
  if (!currentObject) return currentObject

  if (currentObject.id === idToFind && !findParent) return currentObject

  for (const child of currentObject.children) {
    if (child.id === idToFind) {
      return findParent ? currentObject : (child as SearchGroupWithId)
    }

    if (!isSearchFilterWithId(child)) {
      const found = findFilterComponent(idToFind, findParent, child as SearchGroupWithId)
      if (found) return found
    }
  }

  return undefined
}

import { ReactNode } from 'react'

import { Collapse, CollapseProps } from '@mui/material'

type CollapseRegionProps = {
  isExpanded: boolean
  children: ReactNode
  id: string
  className?: string
} & CollapseProps

/*
 * This component is a wrapper around the MUI Collapse component that adds the
 * role and aria-labelledby attributes to the content div. This is necessary for
 * screen readers to announce the content when the toggle button is clicked.
 * This can be used with the ExpandCollapseToggle component to create an accessible
 * expand/collapse section.
 */
function CollapseRegion({ isExpanded, children, id, className, ...rest }: CollapseRegionProps) {
  return (
    <Collapse in={isExpanded} timeout="auto" {...rest}>
      <div role="region" id={`${id}_content`} aria-labelledby={`${id}_toggle`} className={className}>
        {children}
      </div>
    </Collapse>
  )
}

export default CollapseRegion

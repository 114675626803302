import { useState } from 'react'

import { CompleteDialogBody } from 'components/cases/report/dialogs/CompleteDialogBody'
import {
  BaseBatchActionDialog,
  DialogComponent,
} from 'dashboards/shared/components/BatchActionsMenu/BaseBatchActionDialog'
import { BatchActionTypeEnum } from 'types/api'

export const BatchCompleteDialog: DialogComponent = (props) => {
  const { children, ...dialogProps } = props

  const [reason, setReason] = useState<string>('')

  return (
    <BaseBatchActionDialog
      {...dialogProps}
      batchActionName={BatchActionTypeEnum.Complete}
      batchActionParams={{ reason }}
      confirmEnabled
      title="Complete reviews"
      primaryText={
        'Would you like to complete the reviews selected? This action can be undone by "reopening" the review.'
      }
      confirmText="Yes, complete reviews."
    >
      <CompleteDialogBody setReason={setReason} reason={reason} batch>
        {children}
      </CompleteDialogBody>
    </BaseBatchActionDialog>
  )
}

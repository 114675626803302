import type * as Types from '../../../../../../types/api'

import type { StrictLogicalExpression } from 'types/automations'
import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core'
export type ProvideSummaryFeedbackMutationVariables = Types.Exact<{
  input: Types.CreateAttachmentSummaryFeedbackInput
}>

export type ProvideSummaryFeedbackMutation = {
  __typename?: 'Mutation'
  createAttachmentSummaryFeedback: {
    __typename?: 'CreateAttachmentSummaryFeedbackPayload'
    summaryFeedback: {
      __typename?: 'AttachmentSummaryFeedback'
      token: string
      rating: Types.AttachmentSummaryFeedbackRatingEnum
      createdAt: string
    } | null
  } | null
}

export const ProvideSummaryFeedbackDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'mutation',
      name: { kind: 'Name', value: 'ProvideSummaryFeedback' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'CreateAttachmentSummaryFeedbackInput' } },
          },
        },
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'createAttachmentSummaryFeedback' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'input' },
                value: { kind: 'Variable', name: { kind: 'Name', value: 'input' } },
              },
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'summaryFeedback' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'token' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'rating' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'createdAt' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ProvideSummaryFeedbackMutation, ProvideSummaryFeedbackMutationVariables>

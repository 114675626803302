import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

const BankAccountIcon: FC<SvgProps> = (props) => (
  <SvgIcon {...props} viewBox="0 0 24 24">
    <path d="M22,4 C22.5522847,4 23,4.44771525 23,5 L23,19 C23,19.5522847 22.5522847,20 22,20 L2,20 C1.44771525,20 1,19.5522847 1,19 L1,5 C1,4.44771525 1.44771525,4 2,4 L22,4 Z M21,18 L21,6 L3,6 L3,18 L21,18 Z M10,8 L10,13 L5,13 L5,8 L10,8 Z M8,11 L8,10 L7,10 L7,11 L8,11 Z M12,8 L19,8 L19,10 L12,10 L12,8 Z M12,11 L19,11 L19,13 L12,13 L12,11 Z M12,14 L17,14 L17,16 L12,16 L12,14 Z" />
  </SvgIcon>
)

export default BankAccountIcon

import { createElement } from 'react'

import classnames from 'classnames'

const ROOT = 'hb-menu-list'

const MenuList = ({ separated, children, className, ...otherProps }) => {
  const classes = classnames(
    ROOT,
    {
      [`${ROOT}--separated`]: separated,
      [`${ROOT}--unseparated`]: !separated,
    },
    className
  )

  return createElement(
    'ul',
    {
      className: classes,
      ...otherProps,
    },
    children
  )
}

export default MenuList

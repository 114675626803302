import { FC } from 'react'

import { SvgIcon } from '@mui/material'

import { SvgProps } from './SvgProps'

export const OtherInfoIcon: FC<SvgProps> = ({ width = '24', height = '24', viewBox = '0 0 24 24', ...props }) => (
  <SvgIcon width={width} height={height} viewBox={viewBox} {...props}>
    <g clipPath="url(#a)">
      <path
        d="M19 4H5a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h14a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Zm-6 14H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h5a1 1 0 0 1 1 1 1 1 0 0 1-1 1Zm3-4H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h8a1 1 0 0 1 1 1 1 1 0 0 1-1 1Zm0-4H8a1 1 0 0 1-1-1 1 1 0 0 1 1-1h8a1 1 0 0 1 1 1 1 1 0 0 1-1 1Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="transparent" transform="translate(0 .5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgIcon>
)

export default OtherInfoIcon

import { State } from 'actions/store'

type OnlyStrings<T> = T extends string ? T : never
export type SimpleSelector<R> = (store: Pick<State, OnlyStrings<keyof State>>) => R
export const simpleSelector = <R>(selector: SimpleSelector<R>): SimpleSelector<R> => selector

export const wait = async (ms: number) =>
  new Promise<void>((resolve) => {
    setTimeout(() => resolve(), ms)
  })

// eslint-disable-next-line no-restricted-imports
import { makeStyles } from '@mui/styles'

import { SUMMARY_PANEL_WIDTH } from 'components/cases/Tabs/shared/AttachmentSummary/AttachmentSummary'
import { Theme } from 'types/hb'

import { ATTACHMENT_HEADER_HEIGHT } from './AttachmentHeader'

export const useViewerRootStyle = makeStyles((theme: Theme) => ({
  root: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: `calc(100% - ${ATTACHMENT_HEADER_HEIGHT}px)`,
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  loader: {
    position: 'absolute',
    width: 50,
    height: 50,
    left: 'calc(50% - 20px)',
    top: 'calc(50% - 20px)',
    color: theme.palette.styleguide.nearWhite,
  },
  hasSidePane: {
    width: `calc(100% - ${SUMMARY_PANEL_WIDTH}px)`,
  },
}))
